import { memo, useState } from "react";
import { AutoComplete } from "./Autocomplete";
// import { ListCountries } from "../common/ListCountries";
import { RangePicker } from "../common/RangePicker";
import moment from "moment";
import { useStoreFly } from "../../store";
import { POST } from "../../utils/api";
import {
  GET_HOTEL_SEARCH_PARAMS,
  RATE_HAWK_API_KEY,
  RATE_HAWK_ENDPOINT_HOTEL_REGION_SEARCH,
  RATE_HAWK_KEY,
} from "../../utils/constants";
import { useNavigate } from "react-router-dom";
const RangePickerMemo = memo(() => (
  <RangePicker isSingleSelect={false} dateFormat={"yyyy-MM-DD"} />
));

const HotelBanner = ({ translation }) => {
  const navigate = useNavigate();
  const [destinationValue, setDestinationValue] = useState("");
  const [destinationSelectedValue, setDestinationSelectedValue] = useState("");
  const [regionValue, setRegionValue] = useState("");
  const [regionSelectedValue, setRegionSelectedValue] = useState("");
  const currentFilter = useStoreFly((state) => state.filterRoute);
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [rooms, setRooms] = useState(1);

  const checkInDate = currentFilter?.dateFrom;
  const checkOutDate = currentFilter?.dateTo;

  return (
    <section
      class="banner"
      style={{
        background: `url(${require("../../assets/img/bg-banner-location.png")}) 50% 50% no-repeat`,
        backgroundSize: "cover",
        paddingTop: "145px",
      }}
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-12 bot-minus col-md-12 col-12">
            <ul class="nav nav-tabs">
              <li >
                <a href="/" >
                  <img
                    src={require("../../assets/img/img-flight.png")}
                    alt=""
                  />{" "}
                  Flights
                </a>
              </li>
              <li>
                <a href="/hotels" class="active">
                  <img src={require("../../assets/img/img-bed.png")} alt="" />{" "}
                  Hotels
                </a>
              </li>
            </ul>
            <div class="tab-content">
              <div id="tab-1" class="tab-pane fade">
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="col-lg-12 radiobg p-0 col-md-12 col-12">
                      <div class="custom-control custom-radio">
                        <input
                          type="radio"
                          id="customRadio1"
                          name="customRadio"
                          class="custom-control-input return-radio"
                          checked
                        />
                        <label class="custom-control-label" for="customRadio1">
                          Return
                        </label>
                      </div>
                      <div class="custom-control custom-radio">
                        <input
                          type="radio"
                          id="customRadio2"
                          name="customRadio"
                          class="custom-control-input oneway-radio"
                        />
                        <label class="custom-control-label" for="customRadio2">
                          One Way
                        </label>
                      </div>
                      <div class="custom-control custom-radio">
                        <input
                          type="radio"
                          id="multi-city-radio"
                          name="customRadio"
                          class="custom-control-input"
                        />
                        <label
                          class="custom-control-label"
                          for="multi-city-radio"
                        >
                          Multi-city
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row sprdiv align-items-end">
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="row one-way d-none ml-auto mr-auto w-100">
                      <div class="row sprdiv">
                        <div class="col-lg-3 col-md-3 col-12">
                          <div class="form-group">
                            <input
                              type="text"
                              class="form-control flight1 custom-dropdown"
                              open-this="pickup-dropdown2"
                              placeholder="Prishtina"
                            />
                            <div class="pickup-dropdown2">
                              <ul class="list-group">
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-12">
                          <div class="form-group">
                            <input
                              type="text"
                              class="form-control flight2 custom-dropdown"
                              open-this="departure-dropdown2"
                              placeholder="Dallas"
                            />
                            <div class="departure-dropdown2">
                              <ul class="list-group">
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-12">
                          <div class="row sprdiv">
                            <div class="col-lg-12 col-md-12 col-12">
                              <div class="form-group">
                                <input
                                  type="text"
                                  class="form-control bg-calendar from-date"
                                  placeholder="23 Sep"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-12">
                          <div class="form-group">
                            <input
                              type="text"
                              class="form-control bg-user custom-dropdown"
                              open-this="traveller-dropdown44"
                              placeholder="1 Traveler, Economy"
                            />
                            <div class="traveller-dropdown traveller-dropdown44 p-class">
                              <ul class="list-group">
                                <h6 class="px-3 pt-3 pb-0">
                                  Passengers and Class{" "}
                                  <a class="close-anchor d-inline-block d-lg-none d-md-none">
                                    <img
                                      src="assets/img/icon-cancel.png"
                                      alt=""
                                    />
                                  </a>
                                </h6>
                                <hr class="mb-0" />
                                <li>
                                  <div class="list-group-item d-flex justify-content-between align-items-center">
                                    <span class="nme">
                                      Adults
                                      <br />
                                      <span class="s2">12 years and older</span>
                                    </span>
                                    <br />
                                    <div class="quantity">
                                      <button class="disabled">-</button>
                                      <input
                                        data-value="true"
                                        type="text"
                                        readonly=""
                                        value="1"
                                      />
                                      <button class="active">+</button>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div class="list-group-item d-flex justify-content-between align-items-center">
                                    <span class="nme">
                                      Children
                                      <br />
                                      <span class="s2">2 to 11 years</span>
                                    </span>
                                    <div class="quantity">
                                      <button class="disabled">-</button>
                                      <input
                                        data-value="true"
                                        type="text"
                                        readonly=""
                                        value="1"
                                      />
                                      <button class="active">+</button>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div class="list-group-item d-flex justify-content-between align-items-center">
                                    <span class="nme">
                                      Babies
                                      <br />
                                      <span class="s2">Under 2 years old</span>
                                    </span>
                                    <div class="quantity">
                                      <button class="disabled">-</button>
                                      <input
                                        data-value="true"
                                        type="text"
                                        readonly=""
                                        value="1"
                                      />
                                      <button class="active">+</button>
                                    </div>
                                  </div>
                                </li>
                                <li class="custom-control mt-sp custom-radio normal-radio">
                                  <input
                                    type="radio"
                                    id="customRadio-val-1"
                                    name="customRadio"
                                    class="custom-control-input"
                                  />
                                  <label
                                    class="custom-control-label"
                                    for="customRadio-val-1"
                                  >
                                    {" "}
                                    Economy
                                  </label>
                                </li>
                                <li class="custom-control custom-radio normal-radio">
                                  <input
                                    type="radio"
                                    id="customRadio-val-1"
                                    name="customRadio"
                                    class="custom-control-input"
                                  />
                                  <label
                                    class="custom-control-label"
                                    for="customRadio-val-1"
                                  >
                                    {" "}
                                    Premium Economy
                                  </label>
                                </li>
                                <li class="custom-control custom-radio normal-radio">
                                  <input
                                    type="radio"
                                    id="customRadio-val-1"
                                    name="customRadio"
                                    class="custom-control-input"
                                  />
                                  <label
                                    class="custom-control-label"
                                    for="customRadio-val-1"
                                  >
                                    {" "}
                                    Business
                                  </label>
                                </li>
                                <li class="custom-control custom-radio normal-radio">
                                  <input
                                    type="radio"
                                    id="customRadio-val-1"
                                    name="customRadio"
                                    class="custom-control-input"
                                  />
                                  <label
                                    class="custom-control-label"
                                    for="customRadio-val-1"
                                  >
                                    {" "}
                                    First
                                  </label>
                                </li>
                                <li class="d-block d-lg-none d-md-none">
                                  <button type="submit" class="btn-submit">
                                    Done
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row multi-city d-none ml-auto mr-auto w-100">
                      <div class="row sprdiv">
                        <div class="col-lg-3 col-md-3 col-12">
                          <div class="form-group">
                            <h4 class="d-lg-none d-md-none d-sm-none">
                              Flight 1
                            </h4>
                            <input
                              type="text"
                              class="form-control flight1 custom-dropdown"
                              open-this="pickup-dropdown2"
                              placeholder="Prishtina"
                            />
                            <div class="pickup-dropdown2">
                              <ul class="list-group">
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-12">
                          <div class="form-group">
                            <input
                              type="text"
                              class="form-control flight2 custom-dropdown"
                              open-this="departure-dropdown2"
                              placeholder="Dallas"
                            />
                            <div class="departure-dropdown2">
                              <ul class="list-group">
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-12">
                          <div class="row sprdiv">
                            <div class="col-lg-12 col-md-12 col-12">
                              <div class="form-group">
                                <input
                                  type="text"
                                  id="from-date3"
                                  class="form-control bg-calendar from-date"
                                  placeholder="23 Sep"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-12">&nbsp;</div>
                      </div>
                      <div class="row sprdiv">
                        <h4 class="d-lg-none d-md-none d-sm-none">Flight 2</h4>
                        <div class="col-lg-3 col-md-3 col-12">
                          <div class="form-group">
                            <input
                              type="text"
                              class="form-control flight1 custom-dropdown"
                              open-this="pickup-dropdown2"
                              placeholder="Prishtina"
                            />
                            <div class="pickup-dropdown2">
                              <ul class="list-group">
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-12">
                          <div class="form-group">
                            <input
                              type="text"
                              class="form-control flight2 custom-dropdown"
                              open-this="departure-dropdown2"
                              placeholder="Dallas"
                            />
                            <div class="departure-dropdown2">
                              <ul class="list-group">
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Amsterdam
                                    <span class="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-12">
                          <div class="row sprdiv">
                            <div class="col-lg-12 col-md-12 col-12">
                              <div class="form-group">
                                <input
                                  type="text"
                                  class="form-control bg-calendar from-date"
                                  placeholder="23 Sep"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-12">
                          <div class="form-group">
                            <input
                              type="text"
                              class="form-control bg-user custom-dropdown"
                              open-this="traveller-dropdown44"
                              placeholder="1 Traveler, Economy"
                            />
                            <div class="traveller-dropdown traveller-dropdown44 p-class">
                              <ul class="list-group">
                                <h6 class="px-3 pt-3 pb-0">
                                  Passengers and Class{" "}
                                  <a class="close-anchor d-inline-block d-lg-none d-md-none">
                                    <img
                                      src="assets/img/icon-cancel.png"
                                      alt=""
                                    />
                                  </a>
                                </h6>
                                <hr class="mb-0" />
                                <li>
                                  <div class="list-group-item d-flex justify-content-between align-items-center">
                                    <span class="nme">
                                      Adults
                                      <br />
                                      <span class="s2">12 years and older</span>
                                    </span>
                                    <div class="quantity">
                                      <button class="disabled">-</button>
                                      <input
                                        data-value="true"
                                        type="text"
                                        readonly=""
                                        value="1"
                                      />
                                      <button class="active">+</button>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div class="list-group-item d-flex justify-content-between align-items-center">
                                    <span class="nme">
                                      Children
                                      <br />
                                      <span class="s2">2 to 11 years</span>
                                    </span>
                                    <div class="quantity">
                                      <button class="disabled">-</button>
                                      <input
                                        data-value="true"
                                        type="text"
                                        readonly=""
                                        value="1"
                                      />
                                      <button class="active">+</button>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <a class="list-group-item d-flex justify-content-between align-items-center">
                                    <span class="nme">
                                      Babies
                                      <br />
                                      <span class="s2">Under 2 years old</span>
                                    </span>
                                    <div class="quantity">
                                      <button class="disabled">-</button>
                                      <input
                                        data-value="true"
                                        type="text"
                                        readonly=""
                                        value="1"
                                      />
                                      <button class="active">+</button>
                                    </div>
                                  </a>
                                </li>
                                <li class="custom-control mt-sp custom-radio normal-radio">
                                  <input
                                    type="radio"
                                    id="customRadio-val-1"
                                    name="customRadio"
                                    class="custom-control-input"
                                  />
                                  <label
                                    class="custom-control-label"
                                    for="customRadio-val-1"
                                  >
                                    {" "}
                                    Economy
                                  </label>
                                </li>
                                <li class="custom-control custom-radio normal-radio">
                                  <input
                                    type="radio"
                                    id="customRadio-val-1"
                                    name="customRadio"
                                    class="custom-control-input"
                                  />
                                  <label
                                    class="custom-control-label"
                                    for="customRadio-val-1"
                                  >
                                    {" "}
                                    Premium Economy
                                  </label>
                                </li>
                                <li class="custom-control custom-radio normal-radio">
                                  <input
                                    type="radio"
                                    id="customRadio-val-1"
                                    name="customRadio"
                                    class="custom-control-input"
                                  />
                                  <label
                                    class="custom-control-label"
                                    for="customRadio-val-1"
                                  >
                                    {" "}
                                    Business
                                  </label>
                                </li>
                                <li class="custom-control custom-radio normal-radio">
                                  <input
                                    type="radio"
                                    id="customRadio-val-1"
                                    name="customRadio"
                                    class="custom-control-input"
                                  />
                                  <label
                                    class="custom-control-label"
                                    for="customRadio-val-1"
                                  >
                                    {" "}
                                    First
                                  </label>
                                </li>
                                <li class="d-block d-lg-none d-md-none">
                                  <button type="submit" class="btn-submit">
                                    Done
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row sprdiv">
                        <div class="col-lg-12 mb-3 mt-1 col-md-12 col-12">
                          <a class="text-white" href="#">
                            <i class="fa mr-1 fa-plus"></i> Add another flight
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-9 col-md-9 col-12">
                    <div class="row default-div sprdiv">
                      <div class="col-lg-6 ftinput col-md-6 col-12">
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control flight1 custom-dropdown"
                            open-this="pickup-dropdown"
                            placeholder="Prishtina"
                          />
                          <div class="pickup-dropdown">
                            <ul class="list-group">
                              <li>
                                <a
                                  href="#"
                                  class="list-group-item d-flex justify-content-between align-items-center"
                                >
                                  Amsterdam
                                  <span class="badge badge-primary badge-pill">
                                    AMS
                                  </span>
                                </a>
                              </li>
                              <li>
                                <a
                                  href="#"
                                  class="list-group-item d-flex justify-content-between align-items-center"
                                >
                                  Amsterdam
                                  <span class="badge badge-primary badge-pill">
                                    AMS
                                  </span>
                                </a>
                              </li>
                              <li>
                                <a
                                  href="#"
                                  class="list-group-item d-flex justify-content-between align-items-center"
                                >
                                  Amsterdam
                                  <span class="badge badge-primary badge-pill">
                                    AMS
                                  </span>
                                </a>
                              </li>
                              <li>
                                <a
                                  href="#"
                                  class="list-group-item d-flex justify-content-between align-items-center"
                                >
                                  Amsterdam
                                  <span class="badge badge-primary badge-pill">
                                    AMS
                                  </span>
                                </a>
                              </li>
                              <li>
                                <a
                                  href="#"
                                  class="list-group-item d-flex justify-content-between align-items-center"
                                >
                                  Amsterdam
                                  <span class="badge badge-primary badge-pill">
                                    AMS
                                  </span>
                                </a>
                              </li>
                              <li>
                                <a
                                  href="#"
                                  class="list-group-item d-flex justify-content-between align-items-center"
                                >
                                  Amsterdam
                                  <span class="badge badge-primary badge-pill">
                                    AMS
                                  </span>
                                </a>
                              </li>
                              <li>
                                <a
                                  href="#"
                                  class="list-group-item d-flex justify-content-between align-items-center"
                                >
                                  Amsterdam
                                  <span class="badge badge-primary badge-pill">
                                    AMS
                                  </span>
                                </a>
                              </li>
                              <li>
                                <a
                                  href="#"
                                  class="list-group-item d-flex justify-content-between align-items-center"
                                >
                                  Amsterdam
                                  <span class="badge badge-primary badge-pill">
                                    AMS
                                  </span>
                                </a>
                              </li>
                              <li>
                                <a
                                  href="#"
                                  class="list-group-item d-flex justify-content-between align-items-center"
                                >
                                  Amsterdam
                                  <span class="badge badge-primary badge-pill">
                                    AMS
                                  </span>
                                </a>
                              </li>
                              <li>
                                <a
                                  href="#"
                                  class="list-group-item d-flex justify-content-between align-items-center"
                                >
                                  Amsterdam
                                  <span class="badge badge-primary badge-pill">
                                    AMS
                                  </span>
                                </a>
                              </li>
                              <li>
                                <a
                                  href="#"
                                  class="list-group-item d-flex justify-content-between align-items-center"
                                >
                                  Amsterdam
                                  <span class="badge badge-primary badge-pill">
                                    AMS
                                  </span>
                                </a>
                              </li>
                              <li>
                                <a
                                  href="#"
                                  class="list-group-item d-flex justify-content-between align-items-center"
                                >
                                  Amsterdam
                                  <span class="badge badge-primary badge-pill">
                                    AMS
                                  </span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 ltinput col-md-6 col-12">
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control flight2 custom-dropdown"
                            open-this="departure-dropdown"
                            placeholder="Paris"
                          />
                          <div class="departure-dropdown">
                            <ul class="list-group">
                              <li>
                                <a
                                  href="#"
                                  class="list-group-item d-flex justify-content-between align-items-center"
                                >
                                  Amsterdam
                                  <span class="badge badge-primary badge-pill">
                                    AMS
                                  </span>
                                </a>
                              </li>
                              <li>
                                <a
                                  href="#"
                                  class="list-group-item d-flex justify-content-between align-items-center"
                                >
                                  Amsterdam
                                  <span class="badge badge-primary badge-pill">
                                    AMS
                                  </span>
                                </a>
                              </li>
                              <li>
                                <a
                                  href="#"
                                  class="list-group-item d-flex justify-content-between align-items-center"
                                >
                                  Amsterdam
                                  <span class="badge badge-primary badge-pill">
                                    AMS
                                  </span>
                                </a>
                              </li>
                              <li>
                                <a
                                  href="#"
                                  class="list-group-item d-flex justify-content-between align-items-center"
                                >
                                  Amsterdam
                                  <span class="badge badge-primary badge-pill">
                                    AMS
                                  </span>
                                </a>
                              </li>
                              <li>
                                <a
                                  href="#"
                                  class="list-group-item d-flex justify-content-between align-items-center"
                                >
                                  Amsterdam
                                  <span class="badge badge-primary badge-pill">
                                    AMS
                                  </span>
                                </a>
                              </li>
                              <li>
                                <a
                                  href="#"
                                  class="list-group-item d-flex justify-content-between align-items-center"
                                >
                                  Amsterdam
                                  <span class="badge badge-primary badge-pill">
                                    AMS
                                  </span>
                                </a>
                              </li>
                              <li>
                                <a
                                  href="#"
                                  class="list-group-item d-flex justify-content-between align-items-center"
                                >
                                  Amsterdam
                                  <span class="badge badge-primary badge-pill">
                                    AMS
                                  </span>
                                </a>
                              </li>
                              <li>
                                <a
                                  href="#"
                                  class="list-group-item d-flex justify-content-between align-items-center"
                                >
                                  Amsterdam
                                  <span class="badge badge-primary badge-pill">
                                    AMS
                                  </span>
                                </a>
                              </li>
                              <li>
                                <a
                                  href="#"
                                  class="list-group-item d-flex justify-content-between align-items-center"
                                >
                                  Amsterdam
                                  <span class="badge badge-primary badge-pill">
                                    AMS
                                  </span>
                                </a>
                              </li>
                              <li>
                                <a
                                  href="#"
                                  class="list-group-item d-flex justify-content-between align-items-center"
                                >
                                  Amsterdam
                                  <span class="badge badge-primary badge-pill">
                                    AMS
                                  </span>
                                </a>
                              </li>
                              <li>
                                <a
                                  href="#"
                                  class="list-group-item d-flex justify-content-between align-items-center"
                                >
                                  Amsterdam
                                  <span class="badge badge-primary badge-pill">
                                    AMS
                                  </span>
                                </a>
                              </li>
                              <li>
                                <a
                                  href="#"
                                  class="list-group-item d-flex justify-content-between align-items-center"
                                >
                                  Amsterdam
                                  <span class="badge badge-primary badge-pill">
                                    AMS
                                  </span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row default-div sprdiv">
                      <div class="col-lg-6 col-md-6 col-12">
                        <div class="row daterow sprdiv">
                          <div class="col-lg-12 row ml-auto mr-auto w-100 datebox whitebox p-0">
                            <div class="col-lg-6 d-none d-md-block d-lg-block col-md-6 col-6">
                              <div class="form-group m-0">
                                <input
                                  type="text"
                                  id="from-date"
                                  class="form-control bg-calendar from-date custom-dropdown"
                                  open-this="traveller-dropdowndate"
                                  placeholder="23 Sep"
                                />
                              </div>
                            </div>
                            <div class="col-lg-6 d-none d-md-block d-lg-block col-md-6 col-6">
                              <div class="form-group m-0">
                                <input
                                  type="text"
                                  id="to-date"
                                  class="form-control bg-calendar to-date"
                                  placeholder="27 Sep"
                                />
                              </div>
                            </div>

                            <div class="col-lg-12 d-block d-md-none d-lg-none col-md-12 col-12">
                              <div class="form-group m-0">
                                <input
                                  type="text"
                                  class="form-control bg-calendar custom-dropdown"
                                  open-this="traveller-dropdowndate"
                                  placeholder="Choose dates"
                                />
                              </div>
                            </div>
                            <div class="choose-date traveller-dropdowndate">
                              <ul
                                class="list-group"
                                style={{ display: "none" }}
                              >
                                <h6>
                                  Choose dates{" "}
                                  <a class="close-anchor">
                                    <img
                                      src="assets/img/icon-cancel.png"
                                      alt=""
                                    />
                                  </a>
                                </h6>
                                <ul class="nav nav-tabs">
                                  <li>
                                    <a
                                      data-toggle="tab"
                                      href="#departure"
                                      class="active"
                                    >
                                      <img
                                        src="assets/img/icon-calendar-white.png"
                                        alt=""
                                      />{" "}
                                      Departure
                                    </a>
                                  </li>
                                  <li>
                                    <a data-toggle="tab" href="#return">
                                      <img
                                        src="assets/img/icon-calendar-white.png"
                                        alt=""
                                      />{" "}
                                      Return
                                    </a>
                                  </li>
                                </ul>
                                <div class="tab-content">
                                  <div
                                    id="departure"
                                    class="tab-pane fade in show active"
                                  >
                                    <div class="app">
                                      <div class="app__main">
                                        <div class="calendar">
                                          <div id="calendar"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div id="return" class="tab-pane fade">
                                    <div class="app">
                                      <div class="app__main">
                                        <div class="calendar">
                                          <div id="calendar2"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-12">
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control bg-user custom-dropdown"
                            open-this="traveller-dropdown"
                            placeholder="1 Traveler, Economy"
                          />
                          <div class="traveller-dropdown p-class">
                            <ul class="list-group">
                              <h6 class="px-3 pt-3 pb-0">
                                Passengers and Class{" "}
                                <a class="close-anchor d-inline-block d-lg-none d-md-none">
                                  <img
                                    src="assets/img/icon-cancel.png"
                                    alt=""
                                  />
                                </a>
                              </h6>
                              <hr class="mb-0" />
                              <li>
                                <div class="list-group-item d-flex justify-content-between align-items-center">
                                  <span class="nme">
                                    Adults
                                    <br />
                                    <span class="s2">12 years and older</span>
                                  </span>
                                  <br />
                                  <span class="quantity">
                                    <button data-decrease>-</button>
                                    <input
                                      data-value
                                      type="text"
                                      value="1"
                                      disabled
                                    />
                                    <button class="active" data-increase>
                                      +
                                    </button>
                                  </span>
                                </div>
                              </li>
                              <li>
                                <div class="list-group-item d-flex justify-content-between align-items-center">
                                  <span class="nme">
                                    Children
                                    <br />
                                    <span class="s2">2 to 11 years</span>
                                  </span>
                                  <span class="quantity">
                                    <button data-decrease>-</button>
                                    <input
                                      data-value
                                      type="text"
                                      value="1"
                                      disabled
                                    />
                                    <button class="active" data-increase>
                                      +
                                    </button>
                                  </span>
                                </div>
                              </li>
                              <li>
                                <div class="list-group-item d-flex justify-content-between align-items-center">
                                  <span class="nme">
                                    Babies
                                    <br />
                                    <span class="s2">Under 2 years old</span>
                                  </span>
                                  <span class="quantity">
                                    <button data-decrease>-</button>
                                    <input
                                      data-value
                                      type="text"
                                      value="1"
                                      disabled
                                    />
                                    <button class="active" data-increase>
                                      +
                                    </button>
                                  </span>
                                </div>
                              </li>
                              <li class="custom-control mt-sp custom-radio normal-radio">
                                <input
                                  type="radio"
                                  id="customRadio-val-1"
                                  name="customRadio"
                                  class="custom-control-input"
                                />
                                <label
                                  class="custom-control-label"
                                  for="customRadio-val-1"
                                >
                                  {" "}
                                  Economy
                                </label>
                              </li>
                              <li class="custom-control custom-radio normal-radio">
                                <input
                                  type="radio"
                                  id="customRadio-val-1"
                                  name="customRadio"
                                  class="custom-control-input"
                                />
                                <label
                                  class="custom-control-label"
                                  for="customRadio-val-1"
                                >
                                  {" "}
                                  Premium Economy
                                </label>
                              </li>
                              <li class="custom-control custom-radio normal-radio">
                                <input
                                  type="radio"
                                  id="customRadio-val-1"
                                  name="customRadio"
                                  class="custom-control-input"
                                />
                                <label
                                  class="custom-control-label"
                                  for="customRadio-val-1"
                                >
                                  {" "}
                                  Business
                                </label>
                              </li>
                              <li class="custom-control custom-radio normal-radio">
                                <input
                                  type="radio"
                                  id="customRadio-val-1"
                                  name="customRadio"
                                  class="custom-control-input"
                                />
                                <label
                                  class="custom-control-label"
                                  for="customRadio-val-1"
                                >
                                  {" "}
                                  First
                                </label>
                              </li>
                              <li class="d-block d-lg-none d-md-none">
                                <button type="submit" class="btn-submit">
                                  Done
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-3 col-12">
                    <div class="form-group">
                      <button type="submit" class="btn-submit">
                        Search{" "}
                        <img
                          src={require("../../assets/img/icon-search.png")}
                          alt=""
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div id="tab-2" class="tab-pane fade in show active">
                <div class="row pt-4 mt-2 sprdiv align-items-end">
                  <div class="col-lg-9 col-md-9 col-12">
                    <div class="row sprdiv">
                      <div class="col-lg-6 col-md-6 col-12">
                        <div className="form-group">
                          <input
                            type="search"
                            class="form-control bg-desti custom-dropdown"
                            open-this="pickup-dropdown2"
                            value={
                              destinationSelectedValue?.name || destinationValue
                            }
                            defaultValue={
                              destinationSelectedValue?.name || destinationValue
                            }
                            onChange={(event) => {
                              setDestinationSelectedValue("");
                              setDestinationValue(event.target.value);
                            }}
                            placeholder={translation("destination")}
                          />
                          <AutoComplete
                            searchValue={destinationValue}
                            onClickHandler={(autoCompleteItem) => {
                              setDestinationSelectedValue(autoCompleteItem);
                            }}
                            openClass="pickup-dropdown2"
                          />
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-12">
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control bg-user custom-dropdown"
                            open-this="departure-dropdown2"
                            value={regionSelectedValue || regionValue}
                            defaultValue={regionSelectedValue || regionValue}
                            onChange={(event) => {
                              setRegionSelectedValue("");
                              setRegionValue(event.target.value);
                            }}
                            placeholder={translation("guests_citizenship")}
                          />
                          {/* <ListCountries
                            searchValue={regionValue}
                            onClickHandler={(region) => {
                              console.log({ region });
                              // setRegionSelectedValue(region?.name);
                            }}
                            openClass="departure-dropdown2"
                          /> */}
                        </div>
                      </div>
                    </div>
                    <div class="row sprdiv">
                      <div class="col-lg-6 col-md-6 col-12">
                        <div class="row daterow sprdiv">
                          <div class="col-lg-12  col-md-6 row ml-auto mr-auto w-100 datebox whitebox p-0">
                            <div className="col-lg-12 col-md-6 col-12">
                              <div class="form-group m-0">
                                <RangePickerMemo dateFormat="YYYY-MM-DD" />
                              </div>
                            </div>
                          </div>
                          {/* <div class="col-lg-6 d-none d-md-block d-lg-block col-md-6 col-6">
                            <div class="form-group m-0">
                              <input
                                type="text"
                                id="to-date"
                                class="form-control bg-calendar to-date"
                                placeholder="27 Sep"
                              />
                            </div>
                          </div> */}

                          <div class="col-lg-12 d-block d-md-none d-lg-none col-md-12 col-12">
                            <div class="form-group m-0">
                              <input
                                type="text"
                                class="form-control bg-calendar custom-dropdown"
                                open-this="traveller-dropdowndate"
                                placeholder="Choose dates"
                              />
                            </div>
                          </div>
                          <div class="choose-date traveller-dropdowndate">
                            <ul class="list-group" style={{ display: "none" }}>
                              <h6>
                                Choose dates{" "}
                                <a class="close-anchor">
                                  <img
                                    src="assets/img/icon-cancel.png"
                                    alt=""
                                  />
                                </a>
                              </h6>
                              <ul class="nav nav-tabs">
                                <li>
                                  <a
                                    data-toggle="tab"
                                    href="#departure"
                                    class="active"
                                  >
                                    <img
                                      src="assets/img/icon-calendar-white.png"
                                      alt=""
                                    />{" "}
                                    Departure
                                  </a>
                                </li>
                                <li>
                                  <a data-toggle="tab" href="#return">
                                    <img
                                      src="assets/img/icon-calendar-white.png"
                                      alt=""
                                    />{" "}
                                    Return
                                  </a>
                                </li>
                              </ul>
                              <div class="tab-content">
                                <div
                                  id="departure"
                                  class="tab-pane fade in show active"
                                >
                                  <div class="app">
                                    <div class="app__main">
                                      <div class="calendar">
                                        <div id="calendar"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div id="return" class="tab-pane fade">
                                  <div class="app">
                                    <div class="app__main">
                                      <div class="calendar">
                                        <div id="calendar2"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-12">
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control bg-user custom-dropdown"
                            open-this="traveller-dropdown2"
                            placeholder={`${adults} Adults | ${children} Children | ${rooms} Room`} // here
                          />
                          <div class="traveller-dropdown2 p-class">
                            <ul class="list-group">
                              <h6 class="px-3 d-block d-lg-none d-md-none pt-3 pb-0">
                                Passengers and Class{" "}
                                <a class="close-anchor d-inline-block d-lg-none d-md-none">
                                  <img
                                    src="assets/img/icon-cancel.png"
                                    alt=""
                                  />
                                </a>
                              </h6>
                              <li>
                                <div
                                  href="#"
                                  class="list-group-item d-flex justify-content-between align-items-center"
                                >
                                  <span class="nme">
                                    Adults
                                    <br />
                                    <span class="s2">12 years and older</span>
                                  </span>
                                  <br />
                                  <span class="quantity">
                                    <button
                                      disabled={adults === 1}
                                      onClick={() => {
                                        if (adults !== 1) {
                                          setAdults(adults - 1);
                                        }
                                      }}
                                    >
                                      -
                                    </button>
                                    <input
                                      value={adults}
                                      type="text"
                                      disabled
                                    />
                                    <button
                                      class="active"
                                      disabled={adults === 6}
                                      onClick={() => {
                                        // console.log("adults +");
                                        setAdults(adults + 1);
                                      }}
                                    >
                                      +
                                    </button>
                                  </span>
                                </div>
                              </li>
                              <li>
                                <div
                                  href="#"
                                  class="list-group-item d-flex justify-content-between align-items-center"
                                >
                                  <span class="nme">
                                    Children
                                    <br />
                                    <span class="s2">2 to 11 years</span>
                                  </span>
                                  <span class="quantity">
                                    <button
                                      onClick={() => {
                                        if (children !== 1) {
                                          setChildren(children - 1);
                                        }
                                      }}
                                    >
                                      -
                                    </button>
                                    <input
                                      value={children}
                                      type="text"
                                      disabled
                                    />
                                    <button
                                      class="active"
                                      disabled={children === 4}
                                      onClick={() => {
                                        if (children !== 4) {
                                          setChildren(children + 1);
                                        }
                                      }}
                                    >
                                      +
                                    </button>
                                  </span>
                                </div>
                              </li>
                              {/* <li>
                                <div
                                  href="#"
                                  class="list-group-item d-flex justify-content-between align-items-center"
                                >
                                  <span class="nme">
                                    Babies
                                    <br />
                                    <span class="s2">Under 2 years old</span>
                                  </span>
                                  <span class="quantity">
                                    <button data-decrease>-</button>
                                    <input
                                      data-value
                                      type="text"
                                      value="1"
                                      disabled
                                    />
                                    <button class="active" data-increase>
                                      +
                                    </button>
                                  </span>
                                </div>
                              </li> */}
                              <li>
                                <div
                                  href="#"
                                  class="list-group-item d-flex justify-content-between align-items-center"
                                >
                                  <span class="nme">
                                    Children and Rooms
                                    <br />
                                    <span class="s2">Under 2 years old</span>
                                  </span>
                                  <span class="quantity">
                                    <button data-decrease>-</button>
                                    <input
                                      data-value
                                      type="text"
                                      value="1"
                                      disabled
                                    />
                                    <button class="active" data-increase>
                                      +
                                    </button>
                                  </span>
                                </div>
                              </li>
                              <li class="d-block d-lg-none d-md-none">
                                <button type="submit" class="btn-submit">
                                  Done
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-3 col-12">
                    <div class="form-group">
                      <button
                        type="submit"
                        class="btn-submit"
                        onClick={async () => {
                          const searchParams = GET_HOTEL_SEARCH_PARAMS(
                            adults,
                            children,
                            checkInDate,
                            checkOutDate,
                            destinationSelectedValue?.region_id ||
                              destinationSelectedValue?.id,
                            "EUR"
                          );

                          if (destinationSelectedValue?.type) {
                            // console.log({ destinationSelectedValue });
                            navigate(`/hotels/search?${searchParams}`);
                          } else {
                            navigate(
                              `/hotels/details?id=${destinationSelectedValue?.id}&${searchParams}`
                            );
                          }
                        }}
                      >
                        {translation("search")}{" "}
                        <img
                          src={require("../../assets/img/icon-search.png")}
                          alt=""
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HotelBanner;
