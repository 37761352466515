import { Alert } from "@mui/material";
import Banner from "../components/Banner";
import Copyrights from "../components/Copyrights";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Styles from "../components/Styles";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const FailedPayment = () => {
  const translationContext = useTranslation();
  return (
    <div>
      <Styles />
      <Header
        translation={translationContext.t}
        translationContext={translationContext}
      />
      <Banner showSearchBar={false} minHeight="300px" />
      <PaymentContent />
      <Footer />
      <Copyrights />
    </div>
  );
};

const PaymentContent = () => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <section className="content">
        <div
          className="container"
        // style={{ padding: "30px" }}
        >
          <div className="row">
            <div className="col-lg-10 mt-lg-0 mt-md-0 mt-4 valuetabs col-md-8 col-12 whitebox">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  minHeight: "300px",
                  justifyContent: "center",
                }}
              >
                <img src={require("../assets/img/red.png")} alt="failed" />
                <h5 class="okPaymentFont">Oops..</h5>
                <p class="">The payment failed.</p>
                <button 
                onClick={() => navigate("/")}
                type="submit"
                className="btn-submit default-yellow-btn okPaymentCustom "
                >
                Continue searching
                <img
                  src={require("../assets/img/icon-search.png")}
                  alt="search"
                  width={25}
                  height={25}
                />
              </button>
            </div>
          </div>
          <div class="col-lg-2 mt-lg-0 mt-md-4  col-md-6 ml-auto col-12 ">
            <div class="col-lg-12 logobox text-center bg-white col-md-12 col-12 sticky">
              <img
                src={require("../assets/img/sims_marketing_agency_logo.png")}
                alt="agency"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    </Fragment >
  );
};

export default FailedPayment;
