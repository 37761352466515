import { Fragment } from "react";

const FilterAndMap = () => {
  return (
    <Fragment>
      <section class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-3 filterbar col-md-4 col-12">
              <a class="maplink mb-3" href="#">
                <img src="assets/img/icon-map.png" alt="" /> Show in map
              </a>
              <label class="headi">Filter by:</label>
              <div class="custom-control custom-radio">
                <input
                  type="radio"
                  id="customRadio-filter-1"
                  name="customRadio"
                  class="custom-control-input"
                />
                <label class="custom-control-label" for="customRadio-filter-1">
                  € 0 - € 50
                </label>
              </div>
              <div class="custom-control custom-radio">
                <input
                  type="radio"
                  id="customRadio-1"
                  name="customRadio"
                  class="custom-control-input"
                />
                <label class="custom-control-label" for="customRadio-1">
                  € 200 +
                </label>
              </div>
              <label class="headi">Filter by:</label>
              <div class="custom-control custom-radio">
                <input
                  type="radio"
                  id="customRadioin-1"
                  name="customRadioin"
                  class="custom-control-input"
                />
                <label class="custom-control-label" for="customRadioin-1">
                  Breakfast included
                </label>
              </div>
              <div class="custom-control custom-radio">
                <input
                  type="radio"
                  id="customRadioin-2"
                  name="customRadioin"
                  class="custom-control-input"
                />
                <label class="custom-control-label" for="customRadioin-2">
                  Hotels
                </label>
              </div>
              <div class="custom-control custom-radio">
                <input
                  type="radio"
                  id="customRadioin-3"
                  name="customRadioin"
                  class="custom-control-input"
                />
                <label class="custom-control-label" for="customRadioin-3">
                  Superb: 9+
                </label>
              </div>
              <div class="custom-control custom-radio">
                <input
                  type="radio"
                  id="customRadioin-4"
                  name="customRadioin"
                  class="custom-control-input"
                />
                <label class="custom-control-label" for="customRadioin-4">
                  Free WiFi
                </label>
              </div>
              <div class="custom-control custom-radio">
                <input
                  type="radio"
                  id="customRadioin-5"
                  name="customRadioin"
                  class="custom-control-input"
                />
                <label class="custom-control-label" for="customRadioin-5">
                  Apartments
                </label>
              </div>
              <label class="headi">Health & safety</label>
              <div class="custom-control custom-radio">
                <input
                  type="radio"
                  id="customRadiohealth-1"
                  name="customRadiohealth"
                  class="custom-control-input"
                />
                <label class="custom-control-label" for="customRadiohealth-1">
                  Properties that take health & safety measures
                </label>
              </div>
              <label class="headi">Sustainability</label>
              <div class="custom-control custom-radio">
                <input
                  type="radio"
                  id="customRadiosustain-1"
                  name="customRadiosustain"
                  class="custom-control-input"
                />
                <label class="custom-control-label" for="customRadiosustain-1">
                  Travel Sustainable properties
                </label>
              </div>
              <label class="headi">Distance from centre of Paris</label>
              <div class="custom-control custom-radio">
                <input
                  type="radio"
                  id="customRadiodistance-1"
                  name="customRadiodistance"
                  class="custom-control-input"
                />
                <label class="custom-control-label" for="customRadiodistance-1">
                  Less than 1 km
                </label>
              </div>
              <div class="custom-control custom-radio">
                <input
                  type="radio"
                  id="customRadiodistance-2"
                  name="customRadiodistance"
                  class="custom-control-input"
                />
                <label class="custom-control-label" for="customRadiodistance-2">
                  Less than 3 km
                </label>
              </div>
              <div class="custom-control custom-radio">
                <input
                  type="radio"
                  id="customRadiodistance-3"
                  name="customRadiodistance"
                  class="custom-control-input"
                />
                <label class="custom-control-label" for="customRadiodistance-3">
                  Less than 5 km
                </label>
              </div>

              <label class="headi">Bed Filters</label>
              <div class="custom-control custom-radio">
                <input
                  type="radio"
                  id="customRadio-bed1"
                  name="customRadiobed"
                  class="custom-control-input"
                />
                <label class="custom-control-label" for="customRadio-bed1">
                  Twin beds
                </label>
              </div>
              <div class="custom-control custom-radio">
                <input
                  type="radio"
                  id="customRadio-bed2"
                  name="customRadiobed"
                  class="custom-control-input"
                />
                <label class="custom-control-label" for="customRadio-bed2">
                  Double beds
                </label>
              </div>
              <div class="custom-control custom-radio">
                <input
                  type="radio"
                  id="customRadio-bed3"
                  name="customRadiobed"
                  class="custom-control-input"
                />
                <label class="custom-control-label" for="customRadio-bed3">
                  Separate beds
                </label>
              </div>

              <label class="headi">Meals</label>
              <div class="custom-control custom-radio">
                <input
                  type="radio"
                  id="customRadio-meal1"
                  name="customRadiomeals"
                  class="custom-control-input"
                />
                <label
                  class="custom-control-label w-100 d-block"
                  for="customRadio-meal1"
                >
                  <span class="fs-13 text-dark pb-1">
                    No meals included <i class="fa fa-question-circle-o"></i>
                  </span>
                  <span class="star-count text-grey mt-1 fs-10 float-right">
                    1701
                  </span>
                </label>
              </div>
              <div class="custom-control custom-radio">
                <input
                  type="radio"
                  id="customRadio-meal2"
                  name="customRadiomeals"
                  class="custom-control-input"
                />
                <label
                  class="custom-control-label w-100 d-block"
                  for="customRadio-meal2"
                >
                  <span class="fs-13 text-dark pb-1">
                    Breakfast Included <i class="fa fa-question-circle-o"></i>
                  </span>
                  <span class="star-count text-grey mt-1 fs-10 float-right">
                    1549
                  </span>
                </label>
              </div>
              <div class="custom-control custom-radio">
                <input
                  type="radio"
                  id="customRadio-meal3"
                  name="customRadiomeals"
                  class="custom-control-input"
                />
                <label
                  class="custom-control-label w-100 d-block"
                  for="customRadio-meal3"
                >
                  <span class="fs-13 text-dark pb-1">
                    Breakfast + dinner or
                    <br /> lunch included{" "}
                    <i class="fa fa-question-circle-o"></i>
                  </span>
                  <span class="star-count text-grey mt-1 fs-10 float-right">
                    77
                  </span>
                </label>
              </div>
              <div class="custom-control custom-radio">
                <input
                  type="radio"
                  id="customRadio-meal4"
                  name="customRadiomeals"
                  class="custom-control-input"
                />
                <label
                  class="custom-control-label w-100 d-block"
                  for="customRadio-meal4"
                >
                  <span class="fs-13 text-dark pb-1">
                    Breakfast,lunch and
                    <br />
                    dinner included <i class="fa fa-question-circle-o"></i>
                  </span>
                  <span class="star-count text-grey mt-1 fs-10 float-right">
                    15
                  </span>
                </label>
              </div>
              <div class="custom-control custom-radio">
                <input
                  type="radio"
                  id="customRadio-meal5"
                  name="customRadiomeals"
                  class="custom-control-input"
                />
                <label
                  class="custom-control-label w-100 d-block"
                  for="customRadio-meal5"
                >
                  <span class="fs-13 text-dark pb-1">
                    All-inclusive <i class="fa fa-question-circle-o"></i>
                  </span>
                  <span class="star-count text-grey mt-1 fs-10 float-right">
                    2
                  </span>
                </label>
              </div>

              <label class="headi">Star Rating</label>
              <div class="custom-control custom-radio">
                <input
                  type="radio"
                  id="customRadio-fivestar"
                  name="customRadiorating"
                  class="custom-control-input"
                />
                <label
                  class="custom-control-label w-100 d-block"
                  for="customRadio-fivestar"
                >
                  <span class="stars fs-17 pb-1">
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                  </span>
                  <span class="star-count text-grey mt-1 fs-10 float-right">
                    214
                  </span>
                </label>
              </div>
              <div class="custom-control custom-radio">
                <input
                  type="radio"
                  id="customRadio-fourstar"
                  name="customRadiorating"
                  class="custom-control-input"
                />
                <label
                  class="custom-control-label w-100 d-block"
                  for="customRadio-fourstar"
                >
                  <span class="stars fs-17 pb-1">
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                  </span>
                  <span class="star-count text-grey mt-1 fs-10 float-right">
                    692
                  </span>
                </label>
              </div>
              <div class="custom-control custom-radio">
                <input
                  type="radio"
                  id="customRadio-threestar"
                  name="customRadiorating"
                  class="custom-control-input"
                />
                <label
                  class="custom-control-label w-100 d-block"
                  for="customRadio-threestar"
                >
                  <span class="stars fs-17 pb-1">
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                  </span>
                  <span class="star-count text-grey mt-1 fs-10 float-right">
                    808
                  </span>
                </label>
              </div>
              <div class="custom-control custom-radio">
                <input
                  type="radio"
                  id="customRadio-twostar"
                  name="customRadiorating"
                  class="custom-control-input"
                />
                <label
                  class="custom-control-label w-100 d-block"
                  for="customRadio-twostar"
                >
                  <span class="stars fs-17 pb-1">
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                  </span>
                  <span class="star-count text-grey mt-1 fs-10 float-right">
                    280
                  </span>
                </label>
              </div>
              <div class="custom-control custom-radio">
                <input
                  type="radio"
                  id="customRadio-onestar"
                  name="customRadiorating"
                  class="custom-control-input"
                />
                <label
                  class="custom-control-label w-100 d-block"
                  for="customRadio-onestar"
                >
                  <span class="stars fs-17 pb-1">
                    <i class="fa fa-star"></i>
                  </span>
                  <span class="star-count text-grey mt-1 fs-10 float-right">
                    434
                  </span>
                </label>
              </div>
              <div class="custom-control custom-radio">
                <input
                  type="radio"
                  id="customRadio-onestar"
                  name="customRadiorating"
                  class="custom-control-input"
                />
                <label
                  class="custom-control-label w-100 d-block"
                  for="customRadio-onestar"
                >
                  <span class="stars fs-17 pb-1">
                    <span class="star-count text-dark mt-1 fs-13">
                      Without star rating
                    </span>
                  </span>
                  <span class="star-count text-grey mt-1 fs-10 float-right">
                    404
                  </span>
                </label>
              </div>
              <a class="default-white-btn mt-5" href="#">
                Clear All Filters
              </a>
            </div>
            <div class="col-lg-9 text-right pr-lg-0 pr-md-0 col-md-9 col-12">
              <a class="backbtn" href="#">
                Back to Hotel List
              </a>
              <iframe
                class="large-iframe"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d412378.05723580474!2d-115.42568629889564!3d36.149430199685014!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80beb782a4f57dd1%3A0x3accd5e6d5b379a3!2sLas%20Vegas%2C%20NV%2C%20USA!5e0!3m2!1sen!2sin!4v1671703805588!5m2!1sen!2sin"
                width="100%"
                height=""
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default FilterAndMap;
