import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const Copyrights = ({ style }) => {
  const translationContext = useTranslation();
  const translation = translationContext.t;
  const currentLang = translationContext?.i18n?.language; 

  return (
    <section class="copyrights" style={style}>
      <div class="container">
        <div class="row">
          <div
            className="justify-content-center navbar-collapse"
            // id="navbarSupportedContent"
          >
            <ul
              className="navbar-nav col-lg-12 col-md-12 col-12"
              style={{ flexDirection: "row", gap: "10px", paddingLeft: "15px" }}
            >
              <li className="nav-item">
                <a
                  className="nav-link"
                  href={currentLang === "en-US"||currentLang === "en"?'/about-us':currentLang === "al"?'/rreth-nesh':'/about-us-de'}
                  // (currentLang === "en" && "/about-us") ||
                  // (currentLang === "al" && "rreth-nesh") ||
                  // (currentLang === "de" && "about-us-de")
                >
                  {translation("about_us")}
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/tours">
                  {translation("tours")}
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/rental-cars">
                  {translation("rental_cars")}
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/how-to">
                  {translation("howto")}
                </a>
              </li>
              {/* <li className="nav-item">
                <a className="nav-link" href="/visit-dubai">
                  {translation("visit_dubai")}
                </a>
              </li> */}
              <li className="nav-item">
                <a className="nav-link" href="/blog">
                  {translation("blog")}
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/contact-us">
                  {translation("contact_us")}
                </a>
              </li>
            </ul>
          </div>
          <div class="col-lg-12 col-md-12 col-12">
            <h5>Copyright © 2022 Fluturolire. All Rights Reserved</h5>
            <p>
              This website is property of FluturoLire L.L.C., headquartered in
              Prishtina, MATI 1,N.N, KS (company registration number 812101236;
              and VAT number 33045290). All rights reserved © 2011 – 2020
              Fluturolire is a registered trademark ®. The available services on
              this website are provided by FluturoLire L.L.C . and its Partners.
              This website is not, neither does it intend to be, for persons
              subject to the laws or regulations of the United States and/or
              Canada. The information contained in this website are published in
              English and in dollars because that meets international standards.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Copyrights;
