import { Fragment } from "react"; 
import { useStaticImages } from "../../../hooks/useStaticImages";
export const AddedBaggageSection = ({ baggageList  }) => { 
   
  const { luggage, suitcase } = useStaticImages();
    
   function returnBaggageCalc(bagg){
      let price=bagg.ancillary.baggagePrice
      price=Number(price + (price * 10) / 100).toFixed(2);
      const [firstPrice, secondPrice] = Number(price)?.toFixed(2)
      ?.toString()
      ?.split(".") || [];
      return{
        firstPrice,secondPrice
      }
    };

    function returnImageCondition(string=''){
      let finString='';
      for (let i = 0; i <= string.length; i++) {
         
        if(!isNaN(Number(string.charAt(i)))){
          finString+=''+string.charAt(i) 
        } 
        
      } 
      return Number(finString)<=20
    }
  
  return (
    <Fragment>
      {baggageList.map((baggage) => (
        <div class="itemdetail">
          
          {returnImageCondition(baggage.ancillary.baggageWeight) ? (
                              <img srcSet={suitcase} alt="suitcase" />
                            ) : (
                              <img srcSet={luggage} alt="luggage" />
                            )}
           
         
          {String(baggage.ancillary.baggageWeight).toLocaleLowerCase()}{" "}
          
          <span>
          {returnBaggageCalc(baggage).firstPrice}
                    <sup>{returnBaggageCalc(baggage).secondPrice || ""}</sup>{baggage.ancillary.baggageCurrency}
          </span>
        </div>
      ))}
    </Fragment>
  );
};
