import { useLocation } from "react-router-dom";
import Banner from "../components/Banner";
import Copyrights from "../components/Copyrights";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Styles from "../components/Styles";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

export const InformationPage = () => {
  const translationContext = useTranslation();
  return (
    <div>
      <Styles />
      <Header
        translation={translationContext.t}
        translationContext={translationContext}
      />
      <Banner showSearchBar={false} minHeight="300px" />
      <Content />
      <Footer />
      <Copyrights />
    </div>
  );
};

const Content = () => {
  const location = useLocation();
  const path = location.pathname;
  
  return (
    <Fragment>
      <section className="content">
        <div className="container">
          <div className="row">
            {path === "/about-us" && <AboutUs />}
            {path === "/tours" && <Tours />}
            {path === "/rental-cars" && <RentalCars />}
            {path === "/visit-dubai" && <VisitDubai />}
            {path === "/blog" && <Blog />}
            {path === "/contact-us" && <ContactUs />}
          </div>
        </div>
      </section>
    </Fragment>
  );
};

const AboutUs = () => {
  return (
    <Fragment>
      <h1>About us</h1>
    </Fragment>
  );
};

const Tours = () => {
  return (
    <Fragment>
      <h1>Tours</h1>
    </Fragment>
  );
};

const RentalCars = () => {
  return (
    <Fragment>
      <h1>Rental Cars</h1>
    </Fragment>
  );
};

const VisitDubai = () => {
  return (
    <Fragment>
      <h1>Visit Dubai</h1>
    </Fragment>
  );
};

const Blog = () => {
  return (
    <Fragment>
      <h1>Blog</h1>
    </Fragment>
  );
};

const ContactUs = () => {
  return (
    <Fragment>
      <h1>Contact us</h1>
    </Fragment>
  );
};

export default InformationPage;
