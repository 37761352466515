// import { guid } from "../common/methods";
let guid = Math.floor((1 + Math.random()) * 0x10000)
.toString(16)
.substring(1);

export const SORT_BY = {
  PRICE: "price",
  DURATION: "duration",
  DATE: "date",
  QUALITY: "quality",
};

export const FLIGHT_RETURN_TYPE = {
  0: "inbound",
  1: "outbound",
};

export const RETURN_TYPE = {
  INBOUND: 1,
  OUTBOUND: 0,
};

export const IMAGE_FORMAT = {
  32: "32x32",
  64: "64x64",
};

export const SELECTED_CABINS = {
  M: "Economy",
  W: "Economy Premium",
  C: "Business",
  F: "First Class",
};

export const SELECTED_CLASS = {
  Economy: "M",
  "Economy Premium": "W",
  Business: "C",
  "First Class": "F",
};

export const SORT_TO_STORE_PATH = {
  price: "flightsByPrice",
  duration: "flightsByDuration",
  date: "flightsByDate",
  quality: "flightsByQuality",
};

export const VEHICLE_TYPE = {
  aircraft: "Aircraft",
};

export const MAXIMUM_STOPS = {
  nonstop: 0,
  upto1: 1,
  upto2: 2,
};

export const FLIGHT_TIMES = {
  departure: {
    early_morning: {
      dtime_from: "12:00",
      dtime_to: "5:00",
      atime_from: "12:00",
      atime_to: "5:00",
    },
    morning: {
      dtime_from: "5:00",
      dtime_to: "12:00",
      atime_from: "5:00",
      atime_to: "12:00",
    },
    afternoon: {
      dtime_from: "12:00",
      dtime_to: "18:00",
      atime_from: "12:00",
      atime_to: "18:00",
    },
    evening: {
      dtime_from: "18:00",
      dtime_to: "24:00",
      atime_from: "18:00",
      atime_to: "24:00",
    },
  },
  arrival: {
    early_morning: {
      ret_dtime_from: "12:00",
      ret_dtime_to: "5:00",
      ret_atime_from: "12:00",
      ret_atime_to: "5:00",
    },
    morning: {
      ret_dtime_from: "5:00",
      ret_dtime_to: "12:00",
      ret_atime_from: "5:00",
      ret_atime_to: "12:00",
    },
    afternoon: {
      ret_dtime_from: "12:00",
      ret_dtime_to: "18:00",
      ret_atime_from: "12:00",
      ret_atime_to: "18:00",
    },
    evening: {
      ret_dtime_from: "18:00",
      ret_dtime_to: "24:00",
      ret_atime_from: "18:00",
      ret_atime_to: "24:00",
    },
  },
};

export const IMAGE_NAME = {
  SUIT_CASE: "suitcase",
  LUGGAGE: "luggage",
  MORNING: "morning",
  AFTERNOON: "afternoon",
  EVENING: "evening",
};

export const SIGNATURE ="4b1efdbd2ba23da8b99d40f06c451b92"

export const PARTNERID ="GGm1EWFW9I4TwWDzm0RZI/1JTmQ="

export const GEOLOCATION_COORDINATES = {
  EUROPE: {
    NORTH_EAST: {
      latitude: 71.1855,
      longitude: 66.6521,
    },
    SOUTH_WEST: {
      latitude: 36.1378,
      longitude: -11.4677,
    },
  },
  ASIA: {
    NORTH_EAST: {
      latitude: 61.0,
      longitude: 180,
    },
    SOUTH_WEST: {
      latitude: -9.75,
      longitude: 25.0,
    },
  },
  WORLD: {
    NORTH_EAST: {
      latitude: 90,
      longitude: 160,
    },
    SOUTH_WEST: {
      latitude: -90,
      longitude: -160,
    },
  },
};

export const CURRENCY = {
  EUR: "Euro €",
  USD: "Dollar $",
  GBP: "Poung £",
  CHF: "Franc ₣",
};

export const CURRENCY_FULL_NAME = {
  EUR: "Euro",
  USD: "Dollar",
  GBP: "Pound",
  CHF: "Franc",
};

export const CURRENCY_SIGN = {
  EUR: "€",
  USD: "$",
  GBP: "£",
  CHF: "₣",
};

export const CURRENCY_ISO_CODE = {
  EUR: "978",
  USD: "840",
  GBP: "826",
  CHF: "756",
};

export const BAGGAGE_CATEGORY = {
  hand_bag: "Personal Baggage",
  hold_bag: "Checked Baggage",
};

export const PASSENGER_TYPE = {
  adult: "Adult (12+ years)",
  child: "Child (2-14 years)",
  infant: "Infant (under 2 years)",
};

export const MONTH_INDEX = {
  JANUARY: "01",
  FEBRUARY: "02",
  MARCH: "03",
  APRIL: "04",
  MAY: "05",
  JUNE: "06",
  JULY: "07",
  AUGUST: "08",
  SEPTEMBER: "09",
  OCTOBER: "10",
  NOVEMBER: "11",
  DECEMBER: "12",
};

export const CODE_TO_SEVERITY = {
  200: "success",
  400: "error",
  500: "error",
};

export const TRANSLATED_LOCATIONS = {
  "abu dabi": "abu dabi",
  athinë: "athens",
  barcelonë: "barcelona",
  bolonjë: "bologna",
  bruksel: "brussels",
  dyseldorf: "dusseldorf",
  merimgen: "memingen",
  mynih: "mynih",
  mynhen: "mynih",
  ohër: "ohrid",
  "nju jork": "new york",
  dallas: "dallas",
  çikago: "chicago",
  uashington: "washington",
  londër: "london",
  vjenë: "vienna",
  hanover: "hanover",
  stokholm: "stockholm",
  stamboll: "istanbul",
  shtutgart: "stuttgart",
  kopenhagë: "copenhagen",
  alania: "alania",
  goteborg: "goteborg",
  zyrih: "zyrih",
  bazel: "basel",
  gjenevë: "geneva",
  gjenev: "geneva",
  prishtina: "prishtina",
  tirana: "tirana",
  milano: "milano",
  bukuresht: "bucharest",
  varshavë: "warsaw",
  beograd: "belegrade",
  pragë: "prague",
  venecia: "venezia",
  sevilje: "sevilla",
  moskë: "moscow",
  romë: "rome",
  firencë: "firenze",
  torino: "torino",
  napoli: "napoli",
  ankona: "ankona",
  riad: "riad",
  kuvajt: "kuwait",
  lion: "lyon",
  kretë: "crete",
  "los angjelos": "los angeles",
  "san francisko": "san francisco",
  feniks: "phoenix",
  hjuston: "houston",
  sharlote: "charlotte",
};

export const TEB_PAYMENT_PARAMS = {
  OID: guid,
  CLIENT_ID: "290000050",
  ENCODING: "utf-8",
  RND: "87954458746",
  STORE_TYPE: "3d_pay_hosting",
  // STORE_TYPE: "3D_PAY_HOSTING",
  TRAN_TYPE: "Auth",
  // OK_URL: FLUTURO_LIRE_REDIRECT,
  // FAIL_URL: FLUTURO_LIRE_REDIRECT,
  HASH_ALGORITHM: "ver1",
  // CALLBACK_URL: FLUTURO_LIRE_APPROVE,
};
 

/* 
{
      id: "YCB",
      name:"Cambridge Bay",
      city_name: "Cambridge Bay",
      country_name: "Canada",
      country_code: "CA",
      latitude: 69.116667,
      longitude: -105.133333,
      world_area_code: 906,
      city_name_geo_name_id: "\\N",
      country_name_geo_name_id: 6251999,
      coordinates: {
        lon: -105.133333,
        lat: 69.116667
      }
    },

*/

export const airports =
[
  {
      id: "TIA",
      name: "Tirana Airport",
      area_code: "TIA",
      city_name: "Tirana",
      country_code: "AL"
  },
  {
      id: "CZL",
      name: "Mohamed Boudiaf Airport",
      area_code: "CZL",
      city_name: "Constantine",
      country_code: "DZ"
  },
  {
      id: "DJG",
      name: "Tiska Airport",
      area_code: "DJG",
      city_name: "Djanet",
      country_code: "DZ"
  },
  {
      id: "GHA",
      name: "Noumerat Airport",
      area_code: "GHA",
      city_name: "Ghardaia",
      country_code: "DZ"
  },
  {
      id: "OGX",
      name: "Ain el Beida Airport",
      area_code: "OGX",
      city_name: "Ouargla",
      country_code: "DZ"
  },
  {
      id: "ORN",
      name: "Oran Es Senia Airport",
      area_code: "ORN",
      city_name: "Oran",
      country_code: "DZ"
  },
  {
      id: "TIN",
      name: "TINDOUF AIRPORT",
      area_code: "TIN",
      city_name: "Tindouf",
      country_code: "DZ"
  },
  {
      id: "AAE",
      name: "Rabah Bitat Airport",
      area_code: "AAE",
      city_name: "Annaba",
      country_code: "DZ"
  },
  {
      id: "ALG",
      name: "Houari Boumediene Airport",
      area_code: "ALG",
      city_name: "Algiers",
      country_code: "DZ"
  },
  {
      id: "AZR",
      name: "Adrar Airport",
      area_code: "AZR",
      city_name: "Adrar",
      country_code: "DZ"
  },
  {
      id: "BSK",
      name: "Biskra Airport",
      area_code: "BSK",
      city_name: "Biskra",
      country_code: "DZ"
  },
  {
      id: "KBL",
      name: "Kabul International Airport",
      area_code: "KBL",
      city_name: "Kabul",
      country_code: "AF"
  },
  {
      id: "KDH",
      name: "Kandahar International Airport",
      area_code: "KDH",
      city_name: "Kandahar",
      country_code: "AF"
  },
  {
      id: "CNQ",
      name: "Corrientes Airport",
      area_code: "CNQ",
      city_name: "Corrientes",
      country_code: "AR"
  },
  {
      id: "COR",
      name: "A.L.V. Taravella Airport",
      area_code: "COR",
      city_name: "Cordoba",
      country_code: "AR"
  },
  {
      id: "CPC",
      name: "Chapelco Airport",
      area_code: "CPC",
      city_name: "San Martin los Andes",
      country_code: "AR"
  },
  {
      id: "CRD",
      name: "Comodoro Rivadavia Airport",
      area_code: "CRD",
      city_name: "Comodoro Rivadavia",
      country_code: "AR"
  },
  {
      id: "CTC",
      name: "CATAMARCA AIRPORT",
      area_code: "CTC",
      city_name: "Catamarca",
      country_code: "AR"
  },
  {
      id: "EQS",
      name: "ESQUEL AIRPORT",
      area_code: "EQS",
      city_name: "Esquel",
      country_code: "AR"
  },
  {
      id: "FMA",
      name: "Formosa Airport",
      area_code: "FMA",
      city_name: "Formosa",
      country_code: "AR"
  },
  {
      id: "RGA",
      name: "Rio Grande Airport",
      area_code: "RGA",
      city_name: "Rio Grande",
      country_code: "AR"
  },
  {
      id: "ROS",
      name: "Islas Malvinas Airport",
      area_code: "ROS",
      city_name: "Rosario",
      country_code: "AR"
  },
  {
      id: "RSA",
      name: "SANTA ROSA AIRPORT",
      area_code: "RSA",
      city_name: "Santa Rosa",
      country_code: "AR"
  },
  {
      id: "SFN",
      name: "Santa Fe Airport",
      area_code: "SFN",
      city_name: "Santa Fe",
      country_code: "AR"
  },
  {
      id: "SLA",
      name: "Martin Miguel de Guemes Airport",
      area_code: "SLA",
      city_name: "Salta",
      country_code: "AR"
  },
  {
      id: "TDL",
      name: "Tandil",
      area_code: "TDL",
      city_name: "Tandil",
      country_code: "AR"
  },
  {
      id: "TUC",
      name: "Benjamin Matienzo Airport",
      area_code: "TUC",
      city_name: "Tucuman",
      country_code: "AR"
  },
  {
      id: "UAQ",
      name: "San Juan Airport",
      area_code: "UAQ",
      city_name: "San Juan",
      country_code: "AR"
  },
  {
      id: "VDM",
      name: "VIEDMA AIRPORT",
      area_code: "VDM",
      city_name: "Viedma",
      country_code: "AR"
  },
  {
      id: "BHI",
      name: "Comandante Espora Airport",
      area_code: "BHI",
      city_name: "Bahia Blanca",
      country_code: "AR"
  },
  {
      id: "EZE",
      name: "Ministro Pistarini Airport",
      area_code: "BUE",
      city_name: "Buenos Aires",
      country_code: "AR"
  },
  {
      id: "DXB",
      name: "Dubai International Airport",
      area_code: "DXB",
      city_name: "Dubai",
      country_code: "AE"
  },
  {
      id: "SHJ",
      name: "Sharjah Airport",
      area_code: "SHJ",
      city_name: "Sharjah",
      country_code: "AE"
  },
  {
      id: "AAN",
      name: "AL AIN AIRPORT",
      area_code: "AAN",
      city_name: "Al Ain",
      country_code: "AE"
  },
  {
      id: "AUH",
      name: "Abu Dhabi International Airport",
      area_code: "AUH",
      city_name: "Abu Dhabi",
      country_code: "AE"
  },
  {
      id: "AUA",
      name: "REINA BEATRIX AIRPORT",
      area_code: "AUA",
      city_name: "Aruba",
      country_code: "AW"
  },
  {
      id: "MCT",
      name: "Muscat International Airport",
      area_code: "MCT",
      city_name: "Muscat",
      country_code: "OM"
  },
  {
      id: "SLL",
      name: "SALALAH AIRPORT",
      area_code: "SLL",
      city_name: "Salalah",
      country_code: "OM"
  },
  {
      id: "BAK",
      name: "Baku Airport",
      area_code: "BAK",
      city_name: "Baku",
      country_code: "AZ"
  },
  {
      id: "LXR",
      name: "Luxor International Airport",
      area_code: "LXR",
      city_name: "Luxor",
      country_code: "EG"
  },
  {
      id: "ASW",
      name: "Aswan International Airport",
      area_code: "ASW",
      city_name: "Aswan",
      country_code: "EG"
  },
  {
      id: "CAI",
      name: "Cairo International Airport",
      area_code: "CAI",
      city_name: "Cairo",
      country_code: "EG"
  },
  {
      id: "DIR",
      name: "ABA TENNA D YILMA AIRPORT",
      area_code: "DIR",
      city_name: "Dire Dawa",
      country_code: "ET"
  },
  {
      id: "GDQ",
      name: "GONDAR AIRPORT",
      area_code: "GDQ",
      city_name: "Gonder",
      country_code: "ET"
  },
  {
      id: "ADD",
      name: "Bole International Airport",
      area_code: "ADD",
      city_name: "Addis Ababa",
      country_code: "ET"
  },
  {
      id: "CFN",
      name: "DONEGAL AIRPORT",
      area_code: "CFN",
      city_name: "Donegal",
      country_code: "IE"
  },
  {
      id: "DUB",
      name: "Dublin Airport",
      area_code: "DUB",
      city_name: "Dublin",
      country_code: "IE"
  },
  {
      id: "GWY",
      name: "Galway",
      area_code: "GWY",
      city_name: "Galway",
      country_code: "IE"
  },
  {
      id: "SNN",
      name: "Shannon International Airport",
      area_code: "SNN",
      city_name: "Shannon",
      country_code: "IE"
  },
  {
      id: "SXL",
      name: "Airport",
      area_code: "SXL",
      city_name: "Sligo",
      country_code: "IE"
  },
  {
      id: "TLL",
      name: "Lennart Meri Airport",
      area_code: "TLL",
      city_name: "Tallinn",
      country_code: "EE"
  },
  {
      id: "ALV",
      name: "ANDORRA LA VELLA HELIPORT",
      area_code: "ALV",
      city_name: "ANDORRA LA VELLA",
      country_code: "AD"
  },
  {
      id: "LAD",
      name: "4 DE FEVEREIRO AIRPORT",
      area_code: "LAD",
      city_name: "Luanda",
      country_code: "AO"
  },
  {
      id: "UGO",
      name: "Uige",
      area_code: "UGO",
      city_name: "Uige",
      country_code: "AO"
  },
  {
      id: "BUG",
      name: "17 de Setembro",
      area_code: "BUG",
      city_name: "Benguela",
      country_code: "AO"
  },
  {
      id: "CAB",
      name: "CABINDA AIRPORT",
      area_code: "CAB",
      city_name: "Cabinda",
      country_code: "AO"
  },
  {
      id: "AXA",
      name: "Clayton J. Lloyd International Airport",
      area_code: "AXA",
      city_name: "Anguilla",
      country_code: "AI"
  },
  {
      id: "ANU",
      name: "V.C. Bird International Airport",
      area_code: "ANU",
      city_name: "Antigua",
      country_code: "AG"
  },
  {
      id: "BBQ",
      name: "Codrington",
      area_code: "BBQ",
      city_name: "Barbuda",
      country_code: "AG"
  },
  {
      id: "INN",
      name: "Innsbruck Airport",
      area_code: "INN",
      city_name: "Innsbruck",
      country_code: "AT"
  },
  {
      id: "GRZ",
      name: "Graz Airport",
      area_code: "GRZ",
      city_name: "Graz",
      country_code: "AT"
  },
  {
      id: "LNZ",
      name: "Linz Airport",
      area_code: "LNZ",
      city_name: "Linz",
      country_code: "AT"
  },
  {
      id: "SZG",
      name: "W.A. Mozart Airport",
      area_code: "SZG",
      city_name: "Salzburg",
      country_code: "AT"
  },
  {
      id: "VIE",
      name: "Vienna International Airport",
      area_code: "VIE",
      city_name: "Vienna",
      country_code: "AT"
  },
  {
      id: "CED",
      name: "CEDUNA AIRPORT",
      area_code: "CED",
      city_name: "Ceduna",
      country_code: "AU"
  },
  {
      id: "CFS",
      name: "COFFS HARBOUR AIRPORT",
      area_code: "CFS",
      city_name: "Coffs Harbour",
      country_code: "AU"
  },
  {
      id: "CMA",
      name: "CUNNAMULLA AIRPORT",
      area_code: "CMA",
      city_name: "Cunnamulla",
      country_code: "AU"
  },
  {
      id: "CMD",
      name: "Cootamundra",
      area_code: "CMD",
      city_name: "Cootamundra",
      country_code: "AU"
  },
  {
      id: "CNB",
      name: "Coonamble",
      area_code: "CNB",
      city_name: "Coonamble",
      country_code: "AU"
  },
  {
      id: "CNJ",
      name: "CLONCURRY AIRPORT",
      area_code: "CNJ",
      city_name: "Cloncurry",
      country_code: "AU"
  },
  {
      id: "CNS",
      name: "Cairns International Airport",
      area_code: "CNS",
      city_name: "Cairns",
      country_code: "AU"
  },
  {
      id: "CPD",
      name: "COOBER PEDY AIRPORT",
      area_code: "CPD",
      city_name: "Coober Pedy",
      country_code: "AU"
  },
  {
      id: "CSI",
      name: "Casino",
      area_code: "CSI",
      city_name: "Casino",
      country_code: "AU"
  },
  {
      id: "CTL",
      name: "Charleville Airport",
      area_code: "CTL",
      city_name: "Charleville",
      country_code: "AU"
  },
  {
      id: "CTN",
      name: "COOKTOWN AIRPORT",
      area_code: "CTN",
      city_name: "Cooktown",
      country_code: "AU"
  },
  {
      id: "CUY",
      name: "Cue",
      area_code: "CUY",
      city_name: "Cue",
      country_code: "AU"
  },
  {
      id: "CVQ",
      name: "CARNARVON AIRPORT",
      area_code: "CVQ",
      city_name: "Carnarvon",
      country_code: "AU"
  },
  {
      id: "CWT",
      name: "Cowra",
      area_code: "CWT",
      city_name: "Cowra",
      country_code: "AU"
  },
  {
      id: "DBO",
      name: "Dubbo City Airport",
      area_code: "DBO",
      city_name: "Dubbo",
      country_code: "AU"
  },
  {
      id: "DNQ",
      name: "Deniliquin",
      area_code: "DNQ",
      city_name: "Deniliquin",
      country_code: "AU"
  },
  {
      id: "DPO",
      name: "DEVONPORT AIRPORT",
      area_code: "DPO",
      city_name: "Devonport",
      country_code: "AU"
  },
  {
      id: "DRB",
      name: "Derby Airport",
      area_code: "DRB",
      city_name: "Derby",
      country_code: "AU"
  },
  {
      id: "DRW",
      name: "Darwin International Airport",
      area_code: "DRW",
      city_name: "Darwin",
      country_code: "AU"
  },
  {
      id: "EMD",
      name: "EMERALD AIRPORT",
      area_code: "EMD",
      city_name: "Emerald",
      country_code: "AU"
  },
  {
      id: "EPR",
      name: "ESPERANCE AIRPORT",
      area_code: "EPR",
      city_name: "Esperance",
      country_code: "AU"
  },
  {
      id: "FLS",
      name: "FLINDERS ISLAND AIRPORT",
      area_code: "FLS",
      city_name: "Flinders Island",
      country_code: "AU"
  },
  {
      id: "FRB",
      name: "Forbes",
      area_code: "FRB",
      city_name: "Forbes",
      country_code: "AU"
  },
  {
      id: "GET",
      name: "GERALDTON AIRPORT",
      area_code: "GET",
      city_name: "Geraldton",
      country_code: "AU"
  },
  {
      id: "GFF",
      name: "GRIFFITH AIRPORT",
      area_code: "GFF",
      city_name: "Griffith",
      country_code: "AU"
  },
  {
      id: "GFN",
      name: "Grafton Airport",
      area_code: "GFN",
      city_name: "Grafton",
      country_code: "AU"
  },
  {
      id: "GLI",
      name: "Glen Innes",
      area_code: "GLI",
      city_name: "Glen Innes",
      country_code: "AU"
  },
  {
      id: "GLT",
      name: "Gladstone Airport",
      area_code: "GLT",
      city_name: "Gladstone",
      country_code: "AU"
  },
  {
      id: "GTE",
      name: "Groote Eylandt Airport",
      area_code: "GTE",
      city_name: "Groote Eylandt",
      country_code: "AU"
  },
  {
      id: "HAP",
      name: "LONG ISLAND",
      area_code: "HAP",
      city_name: "Long Island",
      country_code: "AU"
  },
  {
      id: "HBA",
      name: "Hobart Airport",
      area_code: "HBA",
      city_name: "Hobart",
      country_code: "AU"
  },
  {
      id: "HGD",
      name: "HUGHENDEN AIRPORT",
      area_code: "HGD",
      city_name: "Hughenden",
      country_code: "AU"
  },
  {
      id: "HTI",
      name: "Great Barrier Reef Airport",
      area_code: "HTI",
      city_name: "Hamilton Island",
      country_code: "AU"
  },
  {
      id: "KGI",
      name: "Kalgoorlie Boulder Airport",
      area_code: "KGI",
      city_name: "Kalgoorlie-Boulder",
      country_code: "AU"
  },
  {
      id: "KNS",
      name: "KING ISLAND AIRPORT",
      area_code: "KNS",
      city_name: "King Island",
      country_code: "AU"
  },
  {
      id: "KNX",
      name: "KUNUNURRA AIRPORT",
      area_code: "KNX",
      city_name: "Kununurra",
      country_code: "AU"
  },
  {
      id: "KRB",
      name: "Karumba",
      area_code: "KRB",
      city_name: "Karumba",
      country_code: "AU"
  },
  {
      id: "KTR",
      name: "Tindal Airport",
      area_code: "KTR",
      city_name: "Katherine",
      country_code: "AU"
  },
  {
      id: "LGH",
      name: "Leigh Creek",
      area_code: "LGH",
      city_name: "Leigh Creek",
      country_code: "AU"
  },
  {
      id: "LRE",
      name: "LONGREACH AIRPORT",
      area_code: "LRE",
      city_name: "Longreach",
      country_code: "AU"
  },
  {
      id: "LVO",
      name: "Laverton Airport",
      area_code: "LVO",
      city_name: "Laverton",
      country_code: "AU"
  },
  {
      id: "MBH",
      name: "Maryborough",
      area_code: "MBH",
      city_name: "Maryborough",
      country_code: "AU"
  },
  {
      id: "MEL",
      name: "Melbourne Airport",
      area_code: "MEL",
      city_name: "Melbourne",
      country_code: "AU"
  },
  {
      id: "MKR",
      name: "Meekatharra Airport",
      area_code: "MKR",
      city_name: "Meekatharra",
      country_code: "AU"
  },
  {
      id: "MKY",
      name: "MACKAY AIRPORT",
      area_code: "MKY",
      city_name: "Mackay",
      country_code: "AU"
  },
  {
      id: "NAA",
      name: "NARRABRI AIRPORT",
      area_code: "NAA",
      city_name: "Narrabri",
      country_code: "AU"
  },
  {
      id: "KPS",
      name: "Kempsey",
      area_code: "KPS",
      city_name: "Kempsey",
      country_code: "AU"
  },
  {
      id: "NTL",
      name: "Williamtown Airport",
      area_code: "NTL",
      city_name: "Newcastle",
      country_code: "AU"
  },
  {
      id: "NYN",
      name: "Nyngan",
      area_code: "NYN",
      city_name: "Nyngan",
      country_code: "AU"
  },
  {
      id: "OOL",
      name: "Coolangatta Airport",
      area_code: "OOL",
      city_name: "Gold Coast",
      country_code: "AU"
  },
  {
      id: "PER",
      name: "Perth Airport",
      area_code: "PER",
      city_name: "Perth",
      country_code: "AU"
  },
  {
      id: "PLO",
      name: "PORT LINCOLN AIRPORT",
      area_code: "PLO",
      city_name: "Port Lincoln",
      country_code: "AU"
  },
  {
      id: "RMA",
      name: "ROMA AIRPORT",
      area_code: "RMA",
      city_name: "Roma",
      country_code: "AU"
  },
  {
      id: "RMK",
      name: "Renmark",
      area_code: "RMK",
      city_name: "Renmark",
      country_code: "AU"
  },
  {
      id: "ROK",
      name: "ROCKHAMPTON AIRPORT",
      area_code: "ROK",
      city_name: "Rockhampton",
      country_code: "AU"
  },
  {
      id: "SGO",
      name: "St George Airport",
      area_code: "SGO",
      city_name: "St George",
      country_code: "AU"
  },
  {
      id: "SIX",
      name: "Singleton",
      area_code: "SIX",
      city_name: "Singleton",
      country_code: "AU"
  },
  {
      id: "SQC",
      name: "Southern Cross",
      area_code: "SQC",
      city_name: "Southern Cross",
      country_code: "AU"
  },
  {
      id: "SRN",
      name: "Strahan",
      area_code: "SRN",
      city_name: "Strahan",
      country_code: "AU"
  },
  {
      id: "SXE",
      name: "West Sale",
      area_code: "SXE",
      city_name: "Sale",
      country_code: "AU"
  },
  {
      id: "SYD",
      name: "Kingsford Smith Airport",
      area_code: "SYD",
      city_name: "Sydney",
      country_code: "AU"
  },
  {
      id: "TIS",
      name: "THURSDAY ISLAND",
      area_code: "TIS",
      city_name: "Thursday Island",
      country_code: "AU"
  },
  {
      id: "TMW",
      name: "TAMWORTH AIRPORT",
      area_code: "TMW",
      city_name: "Tamworth",
      country_code: "AU"
  },
  {
      id: "TRO",
      name: "TAREE AIRPORT",
      area_code: "TRO",
      city_name: "Taree",
      country_code: "AU"
  },
  {
      id: "TSV",
      name: "Townsville Airport",
      area_code: "TSV",
      city_name: "Townsville",
      country_code: "AU"
  },
  {
      id: "TWB",
      name: "Toowoomba Airport",
      area_code: "TWB",
      city_name: "Toowoomba",
      country_code: "AU"
  },
  {
      id: "WWY",
      name: "West Wyalong",
      area_code: "WWY",
      city_name: "West Wyalong",
      country_code: "AU"
  },
  {
      id: "WYN",
      name: "Wyndham",
      area_code: "WYN",
      city_name: "Wyndham",
      country_code: "AU"
  },
  {
      id: "ABX",
      name: "ALBURY AIRPORT",
      area_code: "ABX",
      city_name: "Albury",
      country_code: "AU"
  },
  {
      id: "ADL",
      name: "Adelaide Airport",
      area_code: "ADL",
      city_name: "Adelaide",
      country_code: "AU"
  },
  {
      id: "ALH",
      name: "ALBANY AIRPORT",
      area_code: "ALH",
      city_name: "Albany",
      country_code: "AU"
  },
  {
      id: "ARM",
      name: "ARMIDALE AIRPORT",
      area_code: "ARM",
      city_name: "Armidale",
      country_code: "AU"
  },
  {
      id: "ASP",
      name: "Alice Springs Airport",
      area_code: "ASP",
      city_name: "Alice Springs",
      country_code: "AU"
  },
  {
      id: "AYQ",
      name: "Connellan Airport",
      area_code: "AYQ",
      city_name: "Ayers Rock",
      country_code: "AU"
  },
  {
      id: "BCI",
      name: "BARCALDINE AIRPORT",
      area_code: "BCI",
      city_name: "Barcaldine",
      country_code: "AU"
  },
  {
      id: "BDB",
      name: "BUNDABERG AIRPORT",
      area_code: "BDB",
      city_name: "Bundaberg",
      country_code: "AU"
  },
  {
      id: "BEO",
      name: "Aeropelican",
      area_code: "NTL",
      city_name: "Newcastle",
      country_code: "AU"
  },
  {
      id: "BHQ",
      name: "BROKEN HILL AIRPORT",
      area_code: "BHQ",
      city_name: "Broken Hill",
      country_code: "AU"
  },
  {
      id: "BHS",
      name: "Bathurst Airport",
      area_code: "BHS",
      city_name: "Bathurst",
      country_code: "AU"
  },
  {
      id: "BKQ",
      name: "BLACKALL AIRPORT",
      area_code: "BKQ",
      city_name: "Blackall",
      country_code: "AU"
  },
  {
      id: "BME",
      name: "Broome International Airport",
      area_code: "BME",
      city_name: "Broome",
      country_code: "AU"
  },
  {
      id: "BNE",
      name: "Brisbane Airport",
      area_code: "BNE",
      city_name: "Brisbane",
      country_code: "AU"
  },
  {
      id: "BNK",
      name: "Byron Gateway Airport",
      area_code: "BNK",
      city_name: "Ballina",
      country_code: "AU"
  },
  {
      id: "BOX",
      name: "Borroloola",
      area_code: "BOX",
      city_name: "Borroloola",
      country_code: "AU"
  },
  {
      id: "BRK",
      name: "Bourke",
      area_code: "BRK",
      city_name: "Bourke",
      country_code: "AU"
  },
  {
      id: "BUC",
      name: "BURKETOWN AIRPORT",
      area_code: "BUC",
      city_name: "Burketown",
      country_code: "AU"
  },
  {
      id: "BVI",
      name: "BIRDSVILLE AIRPORT",
      area_code: "BVI",
      city_name: "Birdsville",
      country_code: "AU"
  },
  {
      id: "BWQ",
      name: "Brewarrina",
      area_code: "BWQ",
      city_name: "Brewarrina",
      country_code: "AU"
  },
  {
      id: "BWT",
      name: "Burnie Airport",
      area_code: "BWT",
      city_name: "Burnie",
      country_code: "AU"
  },
  {
      id: "CAZ",
      name: "Cobar",
      area_code: "CAZ",
      city_name: "Cobar",
      country_code: "AU"
  },
  {
      id: "CBI",
      name: "Cape Barren Island",
      area_code: "CBI",
      city_name: "Cape Barren Island",
      country_code: "AU"
  },
  {
      id: "CBR",
      name: "Canberra Airport",
      area_code: "CBR",
      city_name: "Canberra",
      country_code: "AU"
  },
  {
      id: "BGI",
      name: "Grantley Adams International Airport",
      area_code: "BGI",
      city_name: "Bridgetown",
      country_code: "BB"
  },
  {
      id: "GAR",
      name: "Garaina",
      area_code: "GAR",
      city_name: "Garaina",
      country_code: "PG"
  },
  {
      id: "KKD",
      name: "Kokoda",
      area_code: "KKD",
      city_name: "Kokoda",
      country_code: "PG"
  },
  {
      id: "KRI",
      name: "Kikori",
      area_code: "KRI",
      city_name: "Kikori",
      country_code: "PG"
  },
  {
      id: "POM",
      name: "Jacksons International Airport",
      area_code: "POM",
      city_name: "Port Moresby",
      country_code: "PG"
  },
  {
      id: "WWK",
      name: "Boram Airport",
      area_code: "WWK",
      city_name: "Wewak",
      country_code: "PG"
  },
  {
      id: "MAG",
      name: "Madang Airport",
      area_code: "MAGa",
      city_name: "Madang",
      country_code: "PG"
  },
  {
      id: "FPO",
      name: "GRAND BAHAMA INTERNATIONAL AIRPORT",
      area_code: "FPO",
      city_name: "Freeport",
      country_code: "BS"
  },
  {
      id: "GGT",
      name: "Exuma International Airport",
      area_code: "GGT",
      city_name: "George Town",
      country_code: "BS"
  },
  {
      id: "NAS",
      name: "Lynden Pindling International Airport",
      area_code: "NAS",
      city_name: "Nassau",
      country_code: "BS"
  },
  {
      id: "ZSA",
      name: "San Salvador Airport",
      area_code: "ZSA",
      city_name: "San Salvador",
      country_code: "BS"
  },
  {
      id: "ASD",
      name: "International",
      area_code: "ASD",
      city_name: "Andros Town",
      country_code: "BS"
  },
  {
      id: "CJL",
      name: "CHITRAL AIRPORT",
      area_code: "CJL",
      city_name: "Chitral",
      country_code: "PK"
  },
  {
      id: "DBA",
      name: "DALBANDIN AIRPORT",
      area_code: "DBA",
      city_name: "Dalbandin",
      country_code: "PK"
  },
  {
      id: "GIL",
      name: "GILGIT AIRPORT",
      area_code: "GIL",
      city_name: "Gilgit",
      country_code: "PK"
  },
  {
      id: "GWD",
      name: "GWADAR AIRPORT",
      area_code: "GWD",
      city_name: "Gwadar",
      country_code: "PK"
  },
  {
      id: "HDD",
      name: "Hyderabad",
      area_code: "HDD",
      city_name: "Hyderabad",
      country_code: "PK"
  },
  {
      id: "JAG",
      name: "Jacobabad",
      area_code: "JAG",
      city_name: "Jacobabad",
      country_code: "PK"
  },
  {
      id: "KHI",
      name: "Jinnah International Airport",
      area_code: "KHI",
      city_name: "Karachi",
      country_code: "PK"
  },
  {
      id: "KUI",
      name: "kawau island airport",
      area_code: "KUI",
      city_name: "Kawau Island",
      country_code: "NZ"
  },
  {
      id: "LHE",
      name: "Allama Iqbal International Airport",
      area_code: "LHE",
      city_name: "Lahore",
      country_code: "PK"
  },
  {
      id: "ORW",
      name: "Ormara",
      area_code: "ORW",
      city_name: "Ormara",
      country_code: "PK"
  },
  {
      id: "ISB",
      name: "Islamabad International Airport",
      area_code: "ISB",
      city_name: "Islamabad",
      country_code: "PK"
  },
  {
      id: "PZH",
      name: "ZHOB AIRPORT",
      area_code: "PZH",
      city_name: "Zhob",
      country_code: "PK"
  },
  {
      id: "UET",
      name: "QUETTA AIRPORT",
      area_code: "UET",
      city_name: "Quetta",
      country_code: "PK"
  },
  {
      id: "WNS",
      name: "Nawabshah",
      area_code: "WNS",
      city_name: "Nawabshah",
      country_code: "PK"
  },
  {
      id: "LYP",
      name: "Faisalabad International Airport",
      area_code: "LYP",
      city_name: "Faisalabad",
      country_code: "PK"
  },
  {
      id: "MUX",
      name: "Multan Airport",
      area_code: "MUX",
      city_name: "Multan",
      country_code: "PK"
  },
  {
      id: "PEW",
      name: "Peshawar Airport",
      area_code: "PEW",
      city_name: "Peshawar",
      country_code: "PK"
  },
  {
      id: "ENO",
      name: "R.Amin Ayub Gonzalez",
      area_code: "ENO",
      city_name: "Encarnacion",
      country_code: "PY"
  },
  {
      id: "ASU",
      name: "Silvio Pettirossi International Airport",
      area_code: "ASU",
      city_name: "Asuncion",
      country_code: "PY"
  },
  {
      id: "JRS",
      name: "Jerusalem",
      area_code: "JRS",
      city_name: "Jerusalem",
      country_code: "IL"
  },
  {
      id: "BAH",
      name: "Bahrain International Airport",
      area_code: "BAH",
      city_name: "Bahrain",
      country_code: "BH"
  },
  {
      id: "ELE",
      name: "El Real",
      area_code: "ELE",
      city_name: "El Real",
      country_code: "PA"
  },
  {
      id: "PAC",
      name: "Marcos A. Gelabert Albrook International Airport",
      area_code: "PTY",
      city_name: "Panama City",
      country_code: "PA"
  },
  {
      id: "PTY",
      name: "Tocumen International Airport",
      area_code: "PTY",
      city_name: "Panama City",
      country_code: "PA"
  },
  {
      id: "CGB",
      name: "Marechal Rondon Airport",
      area_code: "CGB",
      city_name: "Cuiaba",
      country_code: "BR"
  },
  {
      id: "CGH",
      name: "CONGONHAS AIRPORT",
      area_code: "SAO",
      city_name: "Sao Paulo",
      country_code: "BR"
  },
  {
      id: "CGR",
      name: "Campo Grande Internacional Airport",
      area_code: "CGR",
      city_name: "Campo Grande",
      country_code: "BR"
  },
  {
      id: "CPQ",
      name: "Campinas Airport",
      area_code: "CPQ",
      city_name: "CAMPINAS",
      country_code: "BR"
  },
  {
      id: "CPV",
      name: "Joao Suassuna Airport",
      area_code: "CPV",
      city_name: "Campina Grande",
      country_code: "BR"
  },
  {
      id: "CRQ",
      name: "Caravelas",
      area_code: "CRQ",
      city_name: "Caravelas",
      country_code: "BR"
  },
  {
      id: "CWB",
      name: "Afonso Pena International Airport",
      area_code: "CWB",
      city_name: "Curitiba",
      country_code: "BR"
  },
  {
      id: "CZS",
      name: "Cruzeiro do Sul International Airport",
      area_code: "CZS",
      city_name: "Cruzeiro do Sul",
      country_code: "BR"
  },
  {
      id: "ERN",
      name: "Amaury Feitosa Tomaz",
      area_code: "ERN",
      city_name: "Eirunepe",
      country_code: "BR"
  },
  {
      id: "GIG",
      name: "Rio De Janeiro Internacional Airport",
      area_code: "RIO",
      city_name: "Rio de Janeiro",
      country_code: "BR"
  },
  {
      id: "GRU",
      name: "Guarulhos International Airport",
      area_code: "SAO",
      city_name: "Sao Paulo",
      country_code: "BR"
  },
  {
      id: "GYN",
      name: "SANTA GENOVEVA AIRPORT",
      area_code: "GYN",
      city_name: "Goiania",
      country_code: "BR"
  },
  {
      id: "JDO",
      name: "Juazeiro Do Norte Airport",
      area_code: "JDO",
      city_name: "Juazeiro do Norte",
      country_code: "BR"
  },
  {
      id: "JPA",
      name: "Castro Pinto International Airport",
      area_code: "JPA",
      city_name: "Joao Pessoa",
      country_code: "BR"
  },
  {
      id: "IOS",
      name: "Jorge Amado Airport",
      area_code: "IOS",
      city_name: "Ilheus",
      country_code: "BR"
  },
  {
      id: "MAO",
      name: "EDUARDO GOMES INTERNATIONAL AIRPORT",
      area_code: "MAO",
      city_name: "Manaus",
      country_code: "BR"
  },
  {
      id: "MCP",
      name: "Macapa International Airport",
      area_code: "MCP",
      city_name: "Macapa",
      country_code: "BR"
  },
  {
      id: "MCZ",
      name: "Palmares Airport",
      area_code: "MCZ",
      city_name: "Maceio",
      country_code: "BR"
  },
  {
      id: "POA",
      name: "Salgado Filho International Airport",
      area_code: "POA",
      city_name: "Porto Alegre",
      country_code: "BR"
  },
  {
      id: "REC",
      name: "Guararapes International Airport",
      area_code: "REC",
      city_name: "Recife",
      country_code: "BR"
  },
  {
      id: "RIA",
      name: "Santa Maria Airport",
      area_code: "RIA",
      city_name: "Santa Maria",
      country_code: "BR"
  },
  {
      id: "RIG",
      name: "Regional",
      area_code: "RIG",
      city_name: "Rio Grande",
      country_code: "BR"
  },
  {
      id: "VCP",
      name: "Viracopos Airport",
      area_code: "SAO",
      city_name: "Sao Paulo",
      country_code: "BR"
  },
  {
      id: "SDU",
      name: "SANTOS DUMONT AIRPORT",
      area_code: "RIO",
      city_name: "Rio de Janeiro",
      country_code: "BR"
  },
  {
      id: "SLZ",
      name: "Marechal Cunha Machado International Airport",
      area_code: "SLZ",
      city_name: "Sao Luis",
      country_code: "BR"
  },
  {
      id: "SRA",
      name: "Luis Alberto Lehr",
      area_code: "SRA",
      city_name: "Santa Rosa",
      country_code: "BR"
  },
  {
      id: "SSA",
      name: "Deputado Luiz Eduardo Magalhaes Airport",
      area_code: "SSA",
      city_name: "Salvador",
      country_code: "BR"
  },
  {
      id: "STM",
      name: "Maestro Wilson Fonseca Airport",
      area_code: "STM",
      city_name: "Santarem",
      country_code: "BR"
  },
  {
      id: "TOW",
      name: "Luis dal Canalle Filho",
      area_code: "TOW",
      city_name: "Toledo",
      country_code: "BR"
  },
  {
      id: "TUR",
      name: "Tucurui",
      area_code: "TUR",
      city_name: "Tucurui",
      country_code: "BR"
  },
  {
      id: "UBA",
      name: "Uberaba Airport",
      area_code: "UBA",
      city_name: "Uberaba",
      country_code: "BR"
  },
  {
      id: "UDI",
      name: "Uberlandia Airport",
      area_code: "UDI",
      city_name: "Uberlandia",
      country_code: "BR"
  },
  {
      id: "VIX",
      name: "Eurico de Aguiar Salles Airport",
      area_code: "VIX",
      city_name: "Vitoria",
      country_code: "BR"
  },
  {
      id: "IGU",
      name: "Cataratas Airport",
      area_code: "IGU",
      city_name: "Foz do Iguacu",
      country_code: "BR"
  },
  {
      id: "SSZ",
      name: "AFB",
      area_code: "SSZ",
      city_name: "Santos",
      country_code: "BR"
  },
  {
      id: "AJU",
      name: "Santa Maria Airport",
      area_code: "AJU",
      city_name: "Aracaju",
      country_code: "BR"
  },
  {
      id: "AUX",
      name: "ARAGUAINA AIRPORT",
      area_code: "AUX",
      city_name: "Araguaina",
      country_code: "BR"
  },
  {
      id: "BEL",
      name: "Val de Caes International Airport",
      area_code: "BEL",
      city_name: "Belem",
      country_code: "BR"
  },
  {
      id: "BNU",
      name: "Blumenau",
      area_code: "BNU",
      city_name: "Blumenau",
      country_code: "BR"
  },
  {
      id: "BRA",
      name: "BARREIRAS AIRPORT",
      area_code: "BRA",
      city_name: "Barreiras",
      country_code: "BR"
  },
  {
      id: "BSB",
      name: "Brasilia International Airport",
      area_code: "BSB",
      city_name: "Brasilia",
      country_code: "BR"
  },
  {
      id: "BVB",
      name: "Boa Vista Airport",
      area_code: "BVB",
      city_name: "Boa Vista",
      country_code: "BR"
  },
  {
      id: "CAW",
      name: "BARTOLOMEU LISANDRO AIRPORT",
      area_code: "CAW",
      city_name: "Campos dos Goytacazes",
      country_code: "BR"
  },
  {
      id: "GME",
      name: "GOMEL AIRPORT",
      area_code: "GME",
      city_name: "Gomel",
      country_code: "BY"
  },
  {
      id: "MSQ",
      name: "Minsk International Airport",
      area_code: "MSQ",
      city_name: "Minsk",
      country_code: "BY"
  },
  {
      id: "BQT",
      name: "Brest Airport",
      area_code: "BQT",
      city_name: "Brest",
      country_code: "BY"
  },
  {
      id: "BDA",
      name: "L F Wade International Airport",
      area_code: "BDA",
      city_name: "Bermuda",
      country_code: "BM"
  },
  {
      id: "SOF",
      name: "Sofia Airport",
      area_code: "SOF",
      city_name: "Sofia",
      country_code: "BG"
  },
  {
      id: "VAR",
      name: "Varna Airport",
      area_code: "VAR",
      city_name: "Varna",
      country_code: "BG"
  },
  {
      id: "COO",
      name: "Cadjehoun Airport",
      area_code: "COO",
      city_name: "Cotonou",
      country_code: "BJ"
  },
  {
      id: "ANR",
      name: "Antwerp International Airport",
      area_code: "ANR",
      city_name: "Antwerp",
      country_code: "BE"
  },
  {
      id: "BRU",
      name: "Brussels Airport",
      area_code: "BRU",
      city_name: "Brussels",
      country_code: "BE"
  },
  {
      id: "IFJ",
      name: "ISAFJORDUR AIRPORT",
      area_code: "IFJ",
      city_name: "Isafjordur",
      country_code: "IS"
  },
  {
      id: "KEF",
      name: "Keflavik International Airport",
      area_code: "REK",
      city_name: "Reykjavik",
      country_code: "IS"
  },
  {
      id: "RKV",
      name: "REYKJAVIK DOMESTIC AIRPORT",
      area_code: "REK",
      city_name: "Reykjavik",
      country_code: "IS"
  },
  {
      id: "AEY",
      name: "AKUREYRI AIRPORT",
      area_code: "AEY",
      city_name: "Akureyri",
      country_code: "IS"
  },
  {
      id: "SIG",
      name: "Fernando Luis Ribas Dominicci Airport",
      area_code: "SJU",
      city_name: "San Juan",
      country_code: "PR"
  },
  {
      id: "GDN",
      name: "Lech Walesa Airport",
      area_code: "GDN",
      city_name: "Gdansk",
      country_code: "PL"
  },
  {
      id: "KTW",
      name: "Pyrzowice Airport",
      area_code: "KTW",
      city_name: "Katowice",
      country_code: "PL"
  },
  {
      id: "SZZ",
      name: "GOLENIOW AIRPORT",
      area_code: "SZZ",
      city_name: "Szczecin",
      country_code: "PL"
  },
  {
      id: "WAW",
      name: "Chopin Airport",
      area_code: "WAW",
      city_name: "Warsaw",
      country_code: "PL"
  },
  {
      id: "WRO",
      name: "Copernicus Airport",
      area_code: "WRO",
      city_name: "Wroclaw",
      country_code: "PL"
  },
  {
      id: "SJJ",
      name: "Sarajevo International Airport",
      area_code: "SJJ",
      city_name: "Sarajevo",
      country_code: "BA"
  },
  {
      id: "CIJ",
      name: "Capitan Anibal Arab Airport",
      area_code: "CIJ",
      city_name: "Cobija",
      country_code: "BO"
  },
  {
      id: "GYA",
      name: "GUAYARAMERIN AIRPORT",
      area_code: "GYA",
      city_name: "Guayaramerin",
      country_code: "BO"
  },
  {
      id: "LPB",
      name: "El Alto International Airport",
      area_code: "LPB",
      city_name: "La Paz",
      country_code: "BO"
  },
  {
      id: "MGD",
      name: "Magdalena",
      area_code: "MGD",
      city_name: "Magdalena",
      country_code: "BO"
  },
  {
      id: "SRE",
      name: "Sucre Airport",
      area_code: "SRE",
      city_name: "Sucre",
      country_code: "BO"
  },
  {
      id: "SRZ",
      name: "El Trompillo",
      area_code: "SRZ",
      city_name: "Santa Cruz",
      country_code: "BO"
  },
  {
      id: "TDD",
      name: "Trinidad Airport",
      area_code: "TDD",
      city_name: "Trinidad",
      country_code: "BO"
  },
  {
      id: "CBB",
      name: "J Wilsterman Airport",
      area_code: "CBB",
      city_name: "Cochabamba",
      country_code: "BO"
  },
  {
      id: "CZH",
      name: "COROZAL AIRPORT",
      area_code: "CZH",
      city_name: "Corozal",
      country_code: "BZ"
  },
  {
      id: "SPR",
      name: "SAN PEDRO AIRPORT",
      area_code: "SPR",
      city_name: "San Pedro",
      country_code: "BZ"
  },
  {
      id: "BCV",
      name: "Hector Silva Airport",
      area_code: "BCV",
      city_name: "Belmopan",
      country_code: "BZ"
  },
  {
      id: "BZE",
      name: "Philip S. W. Goldson International Airport",
      area_code: "BZE",
      city_name: "Belize City",
      country_code: "BZ"
  },
  {
      id: "FRW",
      name: "Francistown Airport",
      area_code: "FRW",
      city_name: "Francistown",
      country_code: "BW"
  },
  {
      id: "GBE",
      name: "Sir Seretse Khama International Airport",
      area_code: "GBE",
      city_name: "Gaborone",
      country_code: "BW"
  },
  {
      id: "QJC",
      name: "THIMPHU",
      area_code: "QJC",
      city_name: "THIMPHU",
      country_code: "BT"
  },
  {
      id: "OUA",
      name: "Ouagadougou Airport",
      area_code: "OUA",
      city_name: "Ouagadougou",
      country_code: "BF"
  },
  {
      id: "BJM",
      name: "BUJUMBURA INTERNATIONAL AIRPORT",
      area_code: "BJM",
      city_name: "Bujumbura",
      country_code: "BI"
  },
  {
      id: "FNJ",
      name: "Sunan Airport",
      area_code: "FNJ",
      city_name: "Pyongyang",
      country_code: "KP"
  },
  {
      id: "SSG",
      name: "Malabo International Airport",
      area_code: "SSG",
      city_name: "Malabo",
      country_code: "GQ"
  },
  {
      id: "BSG",
      name: "BATA AIRPORT",
      area_code: "BSG",
      city_name: "Bata",
      country_code: "GQ"
  },
  {
      id: "CPH",
      name: "Kastrup Airport",
      area_code: "CPH",
      city_name: "Copenhagen",
      country_code: "DK"
  },
  {
      id: "EBJ",
      name: "Esbjerg Airport",
      area_code: "EBJ",
      city_name: "Esbjerg",
      country_code: "DK"
  },
  {
      id: "ODE",
      name: "Airport",
      area_code: "ODE",
      city_name: "Odense",
      country_code: "DK"
  },
  {
      id: "RNN",
      name: "Bornholm Airport",
      area_code: "RNN",
      city_name: "Bornholm",
      country_code: "DK"
  },
  {
      id: "CGN",
      name: "Cologne Airport",
      area_code: "CGN",
      city_name: "Cologne/Bonn",
      country_code: "DE"
  },
  {
      id: "DRS",
      name: "Dresden Airport",
      area_code: "DRS",
      city_name: "Dresden",
      country_code: "DE"
  },
  {
      id: "DTM",
      name: "Dortmund Airport",
      area_code: "DTM",
      city_name: "Dortmund",
      country_code: "DE"
  },
  {
      id: "DUS",
      name: "International",
      area_code: "DUS",
      city_name: "Duesseldorf",
      country_code: "DE"
  },
  {
      id: "EME",
      name: "Emden",
      area_code: "EME",
      city_name: "Emden",
      country_code: "DE"
  },
  {
      id: "ERF",
      name: "Erfurt Airport",
      area_code: "ERF",
      city_name: "Erfurt",
      country_code: "DE"
  },
  {
      id: "ESS",
      name: "Muelheim",
      area_code: "ESS",
      city_name: "Essen",
      country_code: "DE"
  },
  {
      id: "FMO",
      name: "Munster International Airport",
      area_code: "FMO",
      city_name: "Muenster/Osnabrueck",
      country_code: "DE"
  },
  {
      id: "FRA",
      name: "Frankfurt Airport",
      area_code: "FRA",
      city_name: "Frankfurt",
      country_code: "DE"
  },
  {
      id: "HAJ",
      name: "Hannover Airport",
      area_code: "HAJ",
      city_name: "Hannover",
      country_code: "DE"
  },
  {
      id: "HAM",
      name: "Hamburg Airport",
      area_code: "HAM",
      city_name: "Hamburg",
      country_code: "DE"
  },
  {
      id: "HGL",
      name: "Duene",
      area_code: "HGL",
      city_name: "Helgoland",
      country_code: "DE"
  },
  {
      id: "KEL",
      name: "Holtenau",
      area_code: "KEL",
      city_name: "Kiel",
      country_code: "DE"
  },
  {
      id: "LEJ",
      name: "Leipzig Airport",
      area_code: "LEJ",
      city_name: "Leipzig/Halle",
      country_code: "DE"
  },
  {
      id: "MUC",
      name: "International",
      area_code: "MUC",
      city_name: "Munich",
      country_code: "DE"
  },
  {
      id: "NUE",
      name: "Nuremberg Airport",
      area_code: "NUE",
      city_name: "Nuremberg",
      country_code: "DE"
  },
  {
      id: "SCN",
      name: "Saarbrucken Airport",
      area_code: "SCN",
      city_name: "Saarbruecken",
      country_code: "DE"
  },
  {
      id: "STR",
      name: "Stuttgart Airport",
      area_code: "STR",
      city_name: "Stuttgart",
      country_code: "DE"
  },
  {
      id: "SXF",
      name: "Schonefeld Airport",
      area_code: "BER",
      city_name: "Berlin",
      country_code: "DE"
  },
  {
      id: "TXL",
      name: "Tegel Airport",
      area_code: "BER",
      city_name: "Berlin",
      country_code: "DE"
  },
  {
      id: "BFE",
      name: "Windelsbleiche",
      area_code: "BFE",
      city_name: "Bielefeld",
      country_code: "DE"
  },
  {
      id: "DUI",
      name: "DUISBURG",
      area_code: "DUI",
      city_name: "SAAS FEE",
      country_code: "CH"
  },
  {
      id: "AGB",
      name: "Augsburg Airport",
      area_code: "MUC",
      city_name: "Munich",
      country_code: "DE"
  },
  {
      id: "BER",
      name: "Brandenburg",
      area_code: "BER",
      city_name: "Berlin",
      country_code: "DE"
  },
  {
      id: "BNJ",
      name: "BONN",
      area_code: "BNJ",
      city_name: "Bonn",
      country_code: "GH"
  },
  {
      id: "BRE",
      name: "Bremen Airport",
      area_code: "BRE",
      city_name: "Bremen",
      country_code: "DE"
  },
  {
      id: "BWE",
      name: "Braunschweig/Wolfsburg",
      area_code: "BWE",
      city_name: "Braunschweig/Wolfsburg",
      country_code: "DE"
  },
  {
      id: "DIL",
      name: "Presidente Nicolau Lobato Airport",
      area_code: "DIL",
      city_name: "Dili",
      country_code: "TL"
  },
  {
      id: "LFW",
      name: "Lome Airport",
      area_code: "LFW",
      city_name: "Lome",
      country_code: "TG"
  },
  {
      id: "SDQ",
      name: "Las Americas International Airport",
      area_code: "SDQ",
      city_name: "Santo Domingo",
      country_code: "DO"
  },
  {
      id: "EGO",
      name: "BELGOROD AIRPORT",
      area_code: "EGO",
      city_name: "Belgorod",
      country_code: "RU"
  },
  {
      id: "ESL",
      name: "ELISTA AIRPORT",
      area_code: "ESL",
      city_name: "Elista",
      country_code: "RU"
  },
  {
      id: "GDX",
      name: "Sokol Airport",
      area_code: "GDX",
      city_name: "Magadan",
      country_code: "RU"
  },
  {
      id: "IJK",
      name: "IZHEVSK AIRPORT",
      area_code: "IJK",
      city_name: "Izhevsk",
      country_code: "RU"
  },
  {
      id: "IKT",
      name: "Irkutsk Airport",
      area_code: "IKT",
      city_name: "Irkutsk",
      country_code: "RU"
  },
  {
      id: "KRO",
      name: "Kurgan Airport",
      area_code: "KRO",
      city_name: "Kurgan",
      country_code: "RU"
  },
  {
      id: "KSZ",
      name: "Kotlas Airport",
      area_code: "KSZ",
      city_name: "Kotlas",
      country_code: "RU"
  },
  {
      id: "KZN",
      name: "Kazan International Airport",
      area_code: "KZN",
      city_name: "Kazan",
      country_code: "RU"
  },
  {
      id: "LED",
      name: "Pulkovo Airport",
      area_code: "LED",
      city_name: "St Petersburg",
      country_code: "RU"
  },
  {
      id: "MCX",
      name: "Makhachkala Airport",
      area_code: "MCX",
      city_name: "Makhachkala",
      country_code: "RU"
  },
  {
      id: "NYM",
      name: "NADYM AIRPORT",
      area_code: "NYM",
      city_name: "Nadym",
      country_code: "RU"
  },
  {
      id: "OSW",
      name: "ORSK AIRPORT",
      area_code: "OSW",
      city_name: "Orsk",
      country_code: "RU"
  },
  {
      id: "REN",
      name: "Tsentralny Airport",
      area_code: "REN",
      city_name: "Orenburg",
      country_code: "RU"
  },
  {
      id: "ROV",
      name: "Platov Airport",
      area_code: "ROV",
      city_name: "Rostov",
      country_code: "RU"
  },
  {
      id: "SVO",
      name: "Sheremetyevo Airport",
      area_code: "MOW",
      city_name: "Moscow",
      country_code: "RU"
  },
  {
      id: "TJM",
      name: "Roshchino International Airport",
      area_code: "TJM",
      city_name: "Tyumen",
      country_code: "RU"
  },
  {
      id: "TOF",
      name: "Bogashevo Airport",
      area_code: "TOF",
      city_name: "Tomsk",
      country_code: "RU"
  },
  {
      id: "UCT",
      name: "UKHTA AIRPORT",
      area_code: "UCT",
      city_name: "Ukhta",
      country_code: "RU"
  },
  {
      id: "UFA",
      name: "Ufa Airport",
      area_code: "UFA",
      city_name: "Ufa",
      country_code: "RU"
  },
  {
      id: "YKS",
      name: "Yakutsk Airport",
      area_code: "YKS",
      city_name: "Yakutsk",
      country_code: "RU"
  },
  {
      id: "KHV",
      name: "Novy Airport",
      area_code: "KHV",
      city_name: "Khabarovsk",
      country_code: "RU"
  },
  {
      id: "OVB",
      name: "Tolmachevo Airport",
      area_code: "OVB",
      city_name: "Novosibirsk",
      country_code: "RU"
  },
  {
      id: "VVO",
      name: "Vladivostok Airport",
      area_code: "VVO",
      city_name: "Vladivostok",
      country_code: "RU"
  },
  {
      id: "HTA",
      name: "Kadala Airport",
      area_code: "HTA",
      city_name: "Chita",
      country_code: "RU"
  },
  {
      id: "OMS",
      name: "OMSK AIRPORT",
      area_code: "OMS",
      city_name: "Omsk",
      country_code: "RU"
  },
  {
      id: "ABA",
      name: "ABAKAN AIRPORT",
      area_code: "ABA",
      city_name: "Abakan",
      country_code: "RU"
  },
  {
      id: "ASF",
      name: "Astrakhan Airport",
      area_code: "ASF",
      city_name: "Astrakhan",
      country_code: "RU"
  },
  {
      id: "BAX",
      name: "BARNAUL AIRPORT",
      area_code: "BAX",
      city_name: "Barnaul",
      country_code: "RU"
  },
  {
      id: "CUE",
      name: "Cuenca Airport",
      area_code: "CUE",
      city_name: "Cuenca",
      country_code: "EC"
  },
  {
      id: "GYE",
      name: "Jose Joaquin de Olmedo International Airport",
      area_code: "GYE",
      city_name: "Guayaquil",
      country_code: "EC"
  },
  {
      id: "UIO",
      name: "Mariscal Sucre International Airport",
      area_code: "UIO",
      city_name: "Quito",
      country_code: "EC"
  },
  {
      id: "ASA",
      name: "Assab",
      area_code: "ASA",
      city_name: "Assab",
      country_code: "ER"
  },
  {
      id: "ASM",
      name: "Asmara International Airport",
      area_code: "ASM",
      city_name: "Asmara",
      country_code: "ER"
  },
  {
      id: "CDG",
      name: "Charles de Gaulle Airport",
      area_code: "PAR",
      city_name: "Paris",
      country_code: "FR"
  },
  {
      id: "CEQ",
      name: "Mandelieu",
      area_code: "CEQ",
      city_name: "Cannes",
      country_code: "FR"
  },
  {
      id: "CER",
      name: "Maupertus",
      area_code: "CER",
      city_name: "Cherbourg",
      country_code: "FR"
  },
  {
      id: "CFE",
      name: "Auvergne Airport",
      area_code: "CFE",
      city_name: "Clermont-Ferrand",
      country_code: "FR"
  },
  {
      id: "CFR",
      name: "Carpiquet Airport",
      area_code: "CFR",
      city_name: "Caen",
      country_code: "FR"
  },
  {
      id: "DIJ",
      name: "Longvic",
      area_code: "DIJ",
      city_name: "Dijon",
      country_code: "FR"
  },
  {
      id: "EBU",
      name: "Boutheon Airport",
      area_code: "EBU",
      city_name: "St-Etienne",
      country_code: "FR"
  },
  {
      id: "ETZ",
      name: "Metz Nancy Lorraine Airport",
      area_code: "ETZ",
      city_name: "Metz/Nancy",
      country_code: "FR"
  },
  {
      id: "FNI",
      name: "GARONS AIRPORT",
      area_code: "FNI",
      city_name: "Nimes",
      country_code: "FR"
  },
  {
      id: "GNB",
      name: "Grenoble Airport",
      area_code: "LYS",
      city_name: "Lyon",
      country_code: "FR"
  },
  {
      id: "JCA",
      name: "CANNES",
      area_code: "JCA",
      city_name: "CANNES",
      country_code: "FR"
  },
  {
      id: "LEH",
      name: "Octeville",
      area_code: "LEH",
      city_name: "Le Havre",
      country_code: "FR"
  },
  {
      id: "LIG",
      name: "Bellegarde Airport",
      area_code: "LIG",
      city_name: "Limoges",
      country_code: "FR"
  },
  {
      id: "LIL",
      name: "Lesquin Airport",
      area_code: "LIL",
      city_name: "Lille",
      country_code: "FR"
  },
  {
      id: "LRH",
      name: "La Rochelle Airport",
      area_code: "LRH",
      city_name: "La Rochelle",
      country_code: "FR"
  },
  {
      id: "LRT",
      name: "Lann Bihoue Airport",
      area_code: "LRT",
      city_name: "Lorient",
      country_code: "FR"
  },
  {
      id: "LYS",
      name: "Saint Exupery Airport",
      area_code: "LYS",
      city_name: "Lyon",
      country_code: "FR"
  },
  {
      id: "MCU",
      name: "Gueret",
      area_code: "MCU",
      city_name: "Montlucon",
      country_code: "FR"
  },
  {
      id: "MPL",
      name: "Mediterranee Airport",
      area_code: "MPL",
      city_name: "Montpellier",
      country_code: "FR"
  },
  {
      id: "MRS",
      name: "Provence Airport",
      area_code: "MRS",
      city_name: "Marseille",
      country_code: "FR"
  },
  {
      id: "NCE",
      name: "Cote d'Azur International Airport",
      area_code: "NCE",
      city_name: "Nice",
      country_code: "FR"
  },
  {
      id: "NTE",
      name: "Atlantique Airport",
      area_code: "NTE",
      city_name: "Nantes",
      country_code: "FR"
  },
  {
      id: "ORY",
      name: "Orly Airport",
      area_code: "PAR",
      city_name: "Paris",
      country_code: "FR"
  },
  {
      id: "PGF",
      name: "Rivesaltes Airport",
      area_code: "PGF",
      city_name: "Perpignan",
      country_code: "FR"
  },
  {
      id: "PIS",
      name: "Biard Airport",
      area_code: "PIS",
      city_name: "Poitiers",
      country_code: "FR"
  },
  {
      id: "PUF",
      name: "Pyrenees Airport",
      area_code: "PUF",
      city_name: "Pau",
      country_code: "FR"
  },
  {
      id: "RHE",
      name: "Champagne",
      area_code: "RHE",
      city_name: "Reims",
      country_code: "FR"
  },
  {
      id: "RNS",
      name: "Saint Jacques Airport",
      area_code: "RNS",
      city_name: "Rennes",
      country_code: "FR"
  },
  {
      id: "SXB",
      name: "Entzheim Airport",
      area_code: "SXB",
      city_name: "Strasbourg",
      country_code: "FR"
  },
  {
      id: "TLN",
      name: "Hyeres Airport",
      area_code: "TLN",
      city_name: "Toulon/Hyeres",
      country_code: "FR"
  },
  {
      id: "TLS",
      name: "Blagnac Airport",
      area_code: "TLS",
      city_name: "Toulouse",
      country_code: "FR"
  },
  {
      id: "TUF",
      name: "Val de Loire Airport",
      area_code: "TUF",
      city_name: "Tours",
      country_code: "FR"
  },
  {
      id: "VNE",
      name: "Meucon",
      area_code: "VNE",
      city_name: "Vannes",
      country_code: "FR"
  },
  {
      id: "BIQ",
      name: "Biarritz Airport",
      area_code: "BIQ",
      city_name: "Biarritz",
      country_code: "FR"
  },
  {
      id: "MLH",
      name: "EuroAirport French FR Basel Mulhouse Freiburg",
      area_code: "EAP",
      city_name: "Basel/Mulhouse",
      country_code: "CH"
  },
  {
      id: "CLY",
      name: "SAINTE CATHERINE AIRPORT",
      area_code: "CLY",
      city_name: "Calvi",
      country_code: "FR"
  },
  {
      id: "LAI",
      name: "Lannion",
      area_code: "LAI",
      city_name: "Lannion",
      country_code: "FR"
  },
  {
      id: "NCY",
      name: "Meythet",
      area_code: "NCY",
      city_name: "Annecy",
      country_code: "FR"
  },
  {
      id: "RDZ",
      name: "Marcillac Airport",
      area_code: "RDZ",
      city_name: "Rodez",
      country_code: "FR"
  },
  {
      id: "UIP",
      name: "Quimper Airport",
      area_code: "UIP",
      city_name: "Quimper",
      country_code: "FR"
  },
  {
      id: "AGF",
      name: "LA GARENNE AIRPORT",
      area_code: "AGF",
      city_name: "Agen",
      country_code: "FR"
  },
  {
      id: "AJA",
      name: "Napoleon Bonaparte Airport",
      area_code: "AJA",
      city_name: "Ajaccio",
      country_code: "FR"
  },
  {
      id: "ANG",
      name: "Brie-Champniers",
      area_code: "ANG",
      city_name: "Angouleme",
      country_code: "FR"
  },
  {
      id: "AVN",
      name: "Avignon Airport",
      area_code: "AVN",
      city_name: "Avignon",
      country_code: "FR"
  },
  {
      id: "BES",
      name: "Bretagne Airport",
      area_code: "BES",
      city_name: "Brest",
      country_code: "FR"
  },
  {
      id: "BIA",
      name: "Poretta Airport",
      area_code: "BIA",
      city_name: "Bastia",
      country_code: "FR"
  },
  {
      id: "BOD",
      name: "Merignac Airport",
      area_code: "BOD",
      city_name: "Bordeaux",
      country_code: "FR"
  },
  {
      id: "TUB",
      name: "Tubuai Airport",
      area_code: "TUB",
      city_name: "Tubuai",
      country_code: "PF"
  },
  {
      id: "PPT",
      name: "Faaa Airport",
      area_code: "PPT",
      city_name: "Tahiti",
      country_code: "PF"
  },
  {
      id: "CAY",
      name: "Felix Eboue Airport",
      area_code: "CAY",
      city_name: "Cayenne",
      country_code: "GF"
  },
  {
      id: "CEB",
      name: "Mactan International Airport",
      area_code: "CEB",
      city_name: "Cebu",
      country_code: "PH"
  },
  {
      id: "CUJ",
      name: "Culion",
      area_code: "CUJ",
      city_name: "Culion",
      country_code: "PH"
  },
  {
      id: "DGT",
      name: "Sibulan Airport",
      area_code: "DGT",
      city_name: "Dumaguete",
      country_code: "PH"
  },
  {
      id: "ILO",
      name: "Iloilo International Airport",
      area_code: "ILO",
      city_name: "Iloilo",
      country_code: "PH"
  },
  {
      id: "LAO",
      name: "LAOAG AIRPORT",
      area_code: "LAO",
      city_name: "Laoag",
      country_code: "PH"
  },
  {
      id: "LBX",
      name: "Lubang",
      area_code: "LBX",
      city_name: "Lubang",
      country_code: "PH"
  },
  {
      id: "LGP",
      name: "Legaspi Airport",
      area_code: "LGP",
      city_name: "Legazpi",
      country_code: "PH"
  },
  {
      id: "MNL",
      name: "Ninoy Aquino International Airport",
      area_code: "MNL",
      city_name: "Manila",
      country_code: "PH"
  },
  {
      id: "RXS",
      name: "Roxas Airport",
      area_code: "RXS",
      city_name: "Roxas",
      country_code: "PH"
  },
  {
      id: "TAC",
      name: "Daniel Z. Romualdez Airport",
      area_code: "TAC",
      city_name: "Tacloban",
      country_code: "PH"
  },
  {
      id: "TAG",
      name: "Bohol-anglao International Airport",
      area_code: "TAG",
      city_name: "Panglao",
      country_code: "PH"
  },
  {
      id: "TUG",
      name: "TUGUEGARAO AIRPORT",
      area_code: "TUG",
      city_name: "Tuguegarao",
      country_code: "PH"
  },
  {
      id: "ZAM",
      name: "Zamboanga International Airport",
      area_code: "ZAM",
      city_name: "Zamboanga",
      country_code: "PH"
  },
  {
      id: "DVO",
      name: "Davao Airport",
      area_code: "DVO",
      city_name: "Davao",
      country_code: "PH"
  },
  {
      id: "BAG",
      name: "Loakan",
      area_code: "BAG",
      city_name: "Baguio",
      country_code: "PH"
  },
  {
      id: "BCD",
      name: "Silay International Airport",
      area_code: "BCD",
      city_name: "Bacolod",
      country_code: "PH"
  },
  {
      id: "BXU",
      name: "BUTUAN AIRPORT",
      area_code: "BXU",
      city_name: "Butuan",
      country_code: "PH"
  },
  {
      id: "CBO",
      name: "AWANG AIRPORT",
      area_code: "CBO",
      city_name: "Cotabato",
      country_code: "PH"
  },
  {
      id: "RTA",
      name: "ROTUMA ISLAND AIRPORT",
      area_code: "RTA",
      city_name: "Rotuma Island",
      country_code: "FJ"
  },
  {
      id: "SUV",
      name: "Nausori Airport",
      area_code: "SUV",
      city_name: "Suva",
      country_code: "FJ"
  },
  {
      id: "NAN",
      name: "Nadi International Airport",
      area_code: "NAN",
      city_name: "Nadi",
      country_code: "FJ"
  },
  {
      id: "HEL",
      name: "Helsinki Vantaa Airport",
      area_code: "HEL",
      city_name: "Helsinki",
      country_code: "FI"
  },
  {
      id: "JYV",
      name: "Jyvaskyla Airport",
      area_code: "JYV",
      city_name: "Jyvaskyla",
      country_code: "FI"
  },
  {
      id: "KEM",
      name: "Kemi/Tornio Airport",
      area_code: "KEM",
      city_name: "Kemi/Tornio",
      country_code: "FI"
  },
  {
      id: "KOK",
      name: "Kokkola Airport",
      area_code: "KOK",
      city_name: "Kokkola/Pietarsaari",
      country_code: "FI"
  },
  {
      id: "KUO",
      name: "Kuopio Airport",
      area_code: "KUO",
      city_name: "Kuopio",
      country_code: "FI"
  },
  {
      id: "OUL",
      name: "Oulu Airport",
      area_code: "OUL",
      city_name: "Oulu",
      country_code: "FI"
  },
  {
      id: "TKU",
      name: "Turku Airport",
      area_code: "TKU",
      city_name: "Turku",
      country_code: "FI"
  },
  {
      id: "TMP",
      name: "Pirkkala Airport",
      area_code: "TMP",
      city_name: "Tampere",
      country_code: "FI"
  },
  {
      id: "VAA",
      name: "Vaasa Airport",
      area_code: "VAA",
      city_name: "Vaasa",
      country_code: "FI"
  },
  {
      id: "RAI",
      name: "Praia International Airport",
      area_code: "RAI",
      city_name: "Praia",
      country_code: "CV"
  },
  {
      id: "BVC",
      name: "Rabil Airport",
      area_code: "BVC",
      city_name: "Boa Vista Island",
      country_code: "CV"
  },
  {
      id: "BJL",
      name: "Banjul International Airport",
      area_code: "BJL",
      city_name: "Banjul",
      country_code: "GM"
  },
  {
      id: "OUE",
      name: "Ouesso",
      area_code: "OUE",
      city_name: "Ouesso",
      country_code: "CG"
  },
  {
      id: "BZV",
      name: "Maya Maya Airport",
      area_code: "BZV",
      city_name: "Brazzaville",
      country_code: "CG"
  },
  {
      id: "FBM",
      name: "Luano Airport",
      area_code: "FBM",
      city_name: "Lubumbashi",
      country_code: "CD"
  },
  {
      id: "FIH",
      name: "N'djili Airport",
      area_code: "FIH",
      city_name: "Kinshasa",
      country_code: "CD"
  },
  {
      id: "CLO",
      name: "Alfonso B. Aragon Airport",
      area_code: "CLO",
      city_name: "Cali",
      country_code: "CO"
  },
  {
      id: "CTG",
      name: "RAFAEL NUNEZ AIRPORT",
      area_code: "CTG",
      city_name: "Cartagena",
      country_code: "CO"
  },
  {
      id: "CZU",
      name: "Corozal Airport",
      area_code: "CZU",
      city_name: "Corozal",
      country_code: "CO"
  },
  {
      id: "IBE",
      name: "Perales Airport",
      area_code: "IBE",
      city_name: "Ibague",
      country_code: "CO"
  },
  {
      id: "MDE",
      name: "Jose Marie Cordova Airport",
      area_code: "MDE",
      city_name: "Medellin",
      country_code: "CO"
  },
  {
      id: "MTR",
      name: "Monteria Airport",
      area_code: "MTR",
      city_name: "Monteria",
      country_code: "CO"
  },
  {
      id: "NVA",
      name: "Benito Salas Airport",
      area_code: "NVA",
      city_name: "Neiva",
      country_code: "CO"
  },
  {
      id: "RCH",
      name: "Riohacha Airport",
      area_code: "RCH",
      city_name: "Riohacha",
      country_code: "CO"
  },
  {
      id: "SOX",
      name: "Alberto Lleras Camargo",
      area_code: "SOX",
      city_name: "Sogamoso",
      country_code: "CO"
  },
  {
      id: "TCO",
      name: "La Florida Airport",
      area_code: "TCO",
      city_name: "Tumaco",
      country_code: "CO"
  },
  {
      id: "ADZ",
      name: "Gustavo Rojas Pinilla International Airport",
      area_code: "ADZ",
      city_name: "San Andres Island",
      country_code: "CO"
  },
  {
      id: "AXM",
      name: "El Eden International Airport",
      area_code: "AXM",
      city_name: "Armenia",
      country_code: "CO"
  },
  {
      id: "BAQ",
      name: "Ernesto Cortissoz Airport",
      area_code: "BAQ",
      city_name: "Barranquilla",
      country_code: "CO"
  },
  {
      id: "BGA",
      name: "Palonegro International Airport",
      area_code: "BGA",
      city_name: "Bucaramanga",
      country_code: "CO"
  },
  {
      id: "BOG",
      name: "El Dorado Airport",
      area_code: "BOG",
      city_name: "Bogota",
      country_code: "CO"
  },
  {
      id: "LIR",
      name: "Liberia Airport",
      area_code: "LIR",
      city_name: "Liberia",
      country_code: "CR"
  },
  {
      id: "SJO",
      name: "Juan Santamaria International Airport",
      area_code: "SJO",
      city_name: "San Jose",
      country_code: "CR"
  },
  {
      id: "CRU",
      name: "Carriacou",
      area_code: "CRU",
      city_name: "Carriacou",
      country_code: "GD"
  },
  {
      id: "GND",
      name: "Maurice Bishop International Airport",
      area_code: "GND",
      city_name: "Grenada",
      country_code: "GD"
  },
  {
      id: "JUV",
      name: "Upernavik Airport",
      area_code: "JUV",
      city_name: "Upernavik",
      country_code: "GL"
  },
  {
      id: "TBS",
      name: "Tbilisi International Airport",
      area_code: "TBS",
      city_name: "Tbilisi",
      country_code: "GE"
  },
  {
      id: "BUS",
      name: "Batumi Airport",
      area_code: "BUS",
      city_name: "Batumi",
      country_code: "GE"
  },
  {
      id: "CMW",
      name: "Ignacio Agramonte International Airport",
      area_code: "CMW",
      city_name: "Camaguey",
      country_code: "CU"
  },
  {
      id: "HAV",
      name: "Jose Marti International Airport",
      area_code: "HAV",
      city_name: "Havana",
      country_code: "CU"
  },
  {
      id: "HOG",
      name: "Frank Pais Airport",
      area_code: "HOG",
      city_name: "Holguin",
      country_code: "CU"
  },
  {
      id: "SCU",
      name: "Antonio Maceo Airport",
      area_code: "SCU",
      city_name: "Santiago de Cuba",
      country_code: "CU"
  },
  {
      id: "AVI",
      name: "Maximo Gomez",
      area_code: "AVI",
      city_name: "Ciego de Avila",
      country_code: "CU"
  },
  {
      id: "BCA",
      name: "Gustavo Rizo Airport",
      area_code: "BCA",
      city_name: "Baracoa",
      country_code: "CU"
  },
  {
      id: "PTP",
      name: "Le Raizet Airport",
      area_code: "PTP",
      city_name: "Pointe-a-Pitre",
      country_code: "GP"
  },
  {
      id: "BBR",
      name: "Baillif",
      area_code: "BBR",
      city_name: "Basse-Terre",
      country_code: "GP"
  },
  {
      id: "GUM",
      name: "Antonio B. Won Pat International Airport",
      area_code: "GUM",
      city_name: "Guam",
      country_code: "GU"
  },
  {
      id: "GEO",
      name: "Cheddi Jagan International Airport",
      area_code: "GEO",
      city_name: "Georgetown",
      country_code: "GY"
  },
  {
      id: "KOV",
      name: "Kokshetau Airport",
      area_code: "KOV",
      city_name: "Kokshetau",
      country_code: "KZ"
  },
  {
      id: "SCO",
      name: "AKTAU AIRPORT",
      area_code: "SCO",
      city_name: "Aktau",
      country_code: "KZ"
  },
  {
      id: "ALA",
      name: "Almaty International Airport",
      area_code: "ALA",
      city_name: "Almaty",
      country_code: "KZ"
  },
  {
      id: "TSE",
      name: "Astana Airport",
      area_code: "TSE",
      city_name: "Astana",
      country_code: "KZ"
  },
  {
      id: "BXH",
      name: "Balhash",
      area_code: "BXH",
      city_name: "Balkhash",
      country_code: "KZ"
  },
  {
      id: "PAP",
      name: "Port Au Prince Airport",
      area_code: "PAP",
      city_name: "Port-au-Prince",
      country_code: "HT"
  },
  {
      id: "CAP",
      name: "Cap Haitien Airport",
      area_code: "CAP",
      city_name: "Cap-Haitien",
      country_code: "HT"
  },
  {
      id: "CJU",
      name: "Jeju International Airport",
      area_code: "CJU",
      city_name: "Jeju",
      country_code: "KR"
  },
  {
      id: "GMP",
      name: "Gimpo International Airport",
      area_code: "SEL",
      city_name: "Seoul",
      country_code: "KR"
  },
  {
      id: "HIN",
      name: "Sacheon Airport",
      area_code: "HIN",
      city_name: "Jinju",
      country_code: "KR"
  },
  {
      id: "ICN",
      name: "Incheon International Airport",
      area_code: "SEL",
      city_name: "Seoul",
      country_code: "KR"
  },
  {
      id: "PUS",
      name: "Gimhae International Airport",
      area_code: "PUS",
      city_name: "Busan",
      country_code: "KR"
  },
  {
      id: "TAE",
      name: "Daegu International Airport",
      area_code: "TAE",
      city_name: "Daegu",
      country_code: "KR"
  },
  {
      id: "USN",
      name: "Ulsan Airport",
      area_code: "USN",
      city_name: "Ulsan",
      country_code: "KR"
  },
  {
      id: "EIN",
      name: "Eindhoven Airport",
      area_code: "EIN",
      city_name: "Eindhoven",
      country_code: "NL"
  },
  {
      id: "ENS",
      name: "Twenthe",
      area_code: "ENS",
      city_name: "Enschede",
      country_code: "NL"
  },
  {
      id: "GRQ",
      name: "Eelde Airport",
      area_code: "GRQ",
      city_name: "Groningen",
      country_code: "NL"
  },
  {
      id: "RTM",
      name: "Rotterdam Airport",
      area_code: "RTM",
      city_name: "Rotterdam",
      country_code: "NL"
  },
  {
      id: "AMS",
      name: "Schiphol Airport",
      area_code: "AMS",
      city_name: "Amsterdam",
      country_code: "NL"
  },
  {
      id: "BON",
      name: "FLAMINGO INTERNATIONAL AIRPORT",
      area_code: "BON",
      city_name: "Bonaire",
      country_code: "AN"
  },
  {
      id: "TGU",
      name: "Toncontin International Airport",
      area_code: "TGU",
      city_name: "Tegucigalpa",
      country_code: "HN"
  },
  {
      id: "TRW",
      name: "Bonriki Airport",
      area_code: "TRW",
      city_name: "Tarawa",
      country_code: "KI"
  },
  {
      id: "JIB",
      name: "AMBOULI AIRPORT",
      area_code: "JIB",
      city_name: "Djibouti",
      country_code: "DJ"
  },
  {
      id: "FRU",
      name: "Manas International Airport",
      area_code: "FRU",
      city_name: "Bishkek",
      country_code: "KG"
  },
  {
      id: "OSS",
      name: "Osh Airport",
      area_code: "OSS",
      city_name: "Osh",
      country_code: "KG"
  },
  {
      id: "CKY",
      name: "Conakry Airport",
      area_code: "CKY",
      city_name: "Conakry",
      country_code: "GN"
  },
  {
      id: "OXB",
      name: "Osvaldo Vieira International Airport",
      area_code: "OXB",
      city_name: "Bissau",
      country_code: "GW"
  },
  {
      id: "XLB",
      name: "Lac Brochet Airport",
      area_code: "XLB",
      city_name: "Lac Brochet",
      country_code: "CA"
  },
  {
      id: "XRR",
      name: "Ross River",
      area_code: "XRR",
      city_name: "Ross River",
      country_code: "CA"
  },
  {
      id: "YAB",
      name: "ARCTIC BAY AIRPORT",
      area_code: "YAB",
      city_name: "Arctic Bay",
      country_code: "CA"
  },
  {
      id: "YAM",
      name: "SAULT STE. MARIE AIRPORT",
      area_code: "YAM",
      city_name: "Sault Ste Marie",
      country_code: "CA"
  },
  {
      id: "YEG",
      name: "Edmonton International Airport",
      area_code: "YEA",
      city_name: "Edmonton",
      country_code: "CA"
  },
  {
      id: "YGK",
      name: "Kingston Airport",
      area_code: "YGK",
      city_name: "Kingston",
      country_code: "CA"
  },
  {
      id: "YHZ",
      name: "Stanfield International Airport",
      area_code: "YHZ",
      city_name: "Halifax",
      country_code: "CA"
  },
  {
      id: "YMX",
      name: "Mirabel International Airport",
      area_code: "YMQ",
      city_name: "Montreal",
      country_code: "CA"
  },
  {
      id: "YOW",
      name: "Macdonald-Cartier International Airport",
      area_code: "YOW",
      city_name: "Ottawa",
      country_code: "CA"
  },
  {
      id: "YQB",
      name: "Jean Lesage International Airport",
      area_code: "YQB",
      city_name: "Quebec",
      country_code: "CA"
  },
  {
      id: "YQG",
      name: "Windsor Airport",
      area_code: "YQG",
      city_name: "Windsor",
      country_code: "CA"
  },
  {
      id: "YQR",
      name: "Regina International Airport",
      area_code: "YQR",
      city_name: "Regina",
      country_code: "CA"
  },
  {
      id: "YQT",
      name: "Thunder Bay Airport",
      area_code: "YQT",
      city_name: "Thunder Bay",
      country_code: "CA"
  },
  {
      id: "YQX",
      name: "Gander International Airport",
      area_code: "YQX",
      city_name: "Gander",
      country_code: "CA"
  },
  {
      id: "YQY",
      name: "Sydney Airport",
      area_code: "YQY",
      city_name: "Sydney",
      country_code: "CA"
  },
  {
      id: "YSJ",
      name: "Saint John Airport",
      area_code: "YSJ",
      city_name: "Saint John",
      country_code: "CA"
  },
  {
      id: "YUL",
      name: "Pierre Elliott Trudeau International Airport",
      area_code: "YMQ",
      city_name: "Montreal",
      country_code: "CA"
  },
  {
      id: "YVR",
      name: "Vancouver International Airport",
      area_code: "YVR",
      city_name: "Vancouver",
      country_code: "CA"
  },
  {
      id: "YXD",
      name: "City Centre Airport",
      area_code: "YEA",
      city_name: "Edmonton",
      country_code: "CA"
  },
  {
      id: "YXE",
      name: "John G.Diefenbaker International Airport",
      area_code: "YXE",
      city_name: "Saskatoon",
      country_code: "CA"
  },
  {
      id: "YXJ",
      name: "Fort St John Airport",
      area_code: "YXJ",
      city_name: "Fort St John",
      country_code: "CA"
  },
  {
      id: "YXY",
      name: "Erik Nielsen International Airport",
      area_code: "YXY",
      city_name: "Whitehorse",
      country_code: "CA"
  },
  {
      id: "YYC",
      name: "Calgary International Airport",
      area_code: "YYC",
      city_name: "Calgary",
      country_code: "CA"
  },
  {
      id: "YYJ",
      name: "International",
      area_code: "YYJ",
      city_name: "Victoria",
      country_code: "CA"
  },
  {
      id: "YYZ",
      name: "Pearson International Airport",
      area_code: "YTO",
      city_name: "Toronto",
      country_code: "CA"
  },
  {
      id: "ZBF",
      name: "BATHURST AIRPORT",
      area_code: "ZBF",
      city_name: "Bathurst",
      country_code: "CA"
  },
  {
      id: "ZFD",
      name: "Fond-du-Lac",
      area_code: "ZFD",
      city_name: "Fond-du-Lac",
      country_code: "CA"
  },
  {
      id: "ZFM",
      name: "FORT MCPHERSON AIRPORT",
      area_code: "ZFM",
      city_name: "Fort McPherson",
      country_code: "CA"
  },
  {
      id: "ZWL",
      name: "Wollaston Lake",
      area_code: "ZWL",
      city_name: "Wollaston Lake",
      country_code: "CA"
  },
  {
      id: "YXU",
      name: "London International Airport",
      area_code: "YXU",
      city_name: "London",
      country_code: "CA"
  },
  {
      id: "YWG",
      name: "James Armstrong Richardson International Airport",
      area_code: "YWG",
      city_name: "Winnipeg",
      country_code: "CA"
  },
  {
      id: "YYT",
      name: "St. John's International Airport",
      area_code: "YYT",
      city_name: "St John's",
      country_code: "CA"
  },
  {
      id: "YCD",
      name: "NANAIMO AIRPORT",
      area_code: "YCD",
      city_name: "Nanaimo",
      country_code: "CA"
  },
  {
      id: "YFC",
      name: "Fredericton International Airport",
      area_code: "YFC",
      city_name: "Fredericton",
      country_code: "CA"
  },
  {
      id: "YKA",
      name: "Kamloops Airport",
      area_code: "YKA",
      city_name: "Kamloops",
      country_code: "CA"
  },
  {
      id: "YLW",
      name: "Kelowna International Airport",
      area_code: "YLW",
      city_name: "Kelowna",
      country_code: "CA"
  },
  {
      id: "YXS",
      name: "PRINCE GEORGE AIRPORT",
      area_code: "YXS",
      city_name: "Prince George",
      country_code: "CA"
  },
  {
      id: "ACC",
      name: "Kotoka International Airport",
      area_code: "ACC",
      city_name: "Accra",
      country_code: "GH"
  },
  {
      id: "LBV",
      name: "Libreville Airport",
      area_code: "LBV",
      city_name: "Libreville",
      country_code: "GA"
  },
  {
      id: "PNH",
      name: "Phnom Penh International Airport",
      area_code: "PNH",
      city_name: "Phnom Penh",
      country_code: "KH"
  },
  {
      id: "TNX",
      name: "Stung Treng",
      area_code: "TNX",
      city_name: "Stung Treng",
      country_code: "KH"
  },
  {
      id: "BBM",
      name: "Battambang",
      area_code: "BBM",
      city_name: "Battambang",
      country_code: "KH"
  },
  {
      id: "OSR",
      name: "Leos Janacek Airport",
      area_code: "OSR",
      city_name: "Ostrava",
      country_code: "CZ"
  },
  {
      id: "PRG",
      name: "Vaclav Havel Airport",
      area_code: "PRG",
      city_name: "Prague",
      country_code: "CZ"
  },
  {
      id: "BRQ",
      name: "Brno-Turany Airport",
      area_code: "BRQ",
      city_name: "Brno",
      country_code: "CZ"
  },
  {
      id: "HRE",
      name: "Harare International Airport",
      area_code: "HRE",
      city_name: "Harare",
      country_code: "ZW"
  },
  {
      id: "KAB",
      name: "Kariba",
      area_code: "KAB",
      city_name: "Kariba",
      country_code: "ZW"
  },
  {
      id: "VFA",
      name: "VICTORIA FALLS AIRPORT",
      area_code: "VFA",
      city_name: "Victoria Falls",
      country_code: "ZW"
  },
  {
      id: "DLA",
      name: "Douala Airport",
      area_code: "DLA",
      city_name: "Douala",
      country_code: "CM"
  },
  {
      id: "DOH",
      name: "Hamad International Airport",
      area_code: "DOH",
      city_name: "Doha",
      country_code: "QA"
  },
  {
      id: "CYB",
      name: "Charles Kirkconnell International Airport",
      area_code: "CYB",
      city_name: "Cayman Brac",
      country_code: "KY"
  },
  {
      id: "GCM",
      name: "OWEN ROBERTS INTERNATIONAL AIRPORT",
      area_code: "GCM",
      city_name: "Grand Cayman",
      country_code: "KY"
  },
  {
      id: "HAH",
      name: "Prince Said Ibrahim Airport",
      area_code: "YVA",
      city_name: "Moroni",
      country_code: "KM"
  },
  {
      id: "YVA",
      name: "Iconi",
      area_code: "YVA",
      city_name: "Moroni",
      country_code: "KM"
  },
  {
      id: "ABJ",
      name: "Felix Houphouet Boigny Airport",
      area_code: "ABJ",
      city_name: "Abidjan",
      country_code: "CI"
  },
  {
      id: "ASK",
      name: "Yamoussoukro",
      area_code: "ASK",
      city_name: "Yamoussoukro",
      country_code: "CI"
  },
  {
      id: "KWI",
      name: "Kuwait International Airport",
      area_code: "KWI",
      city_name: "Kuwait",
      country_code: "KW"
  },
  {
      id: "CCK",
      name: "COCOS ISLANDS AIRPORT",
      area_code: "CCK",
      city_name: "Cocos Islands",
      country_code: "CC"
  },
  {
      id: "OSI",
      name: "OSIJEK AIRPORT",
      area_code: "OSI",
      city_name: "Osijek",
      country_code: "HR"
  },
  {
      id: "RJK",
      name: "RIJEKA AIRPORT",
      area_code: "RJK",
      city_name: "Rijeka",
      country_code: "HR"
  },
  {
      id: "SPU",
      name: "Split Airport",
      area_code: "SPU",
      city_name: "Split",
      country_code: "HR"
  },
  {
      id: "ZAD",
      name: "ZADAR AIRPORT",
      area_code: "ZAD",
      city_name: "Zadar",
      country_code: "HR"
  },
  {
      id: "ZAG",
      name: "Pleso Airport",
      area_code: "ZAG",
      city_name: "Zagreb",
      country_code: "HR"
  },
  {
      id: "KIS",
      name: "Kisumu Airport",
      area_code: "KIS",
      city_name: "Kisumu",
      country_code: "KE"
  },
  {
      id: "MBA",
      name: "Moi International Airport",
      area_code: "MBA",
      city_name: "Mombasa",
      country_code: "KE"
  },
  {
      id: "NBO",
      name: "Jomo Kenyatta International Airport",
      area_code: "NBO",
      city_name: "Nairobi",
      country_code: "KE"
  },
  {
      id: "RIX",
      name: "Riga International Airport",
      area_code: "RIX",
      city_name: "Riga",
      country_code: "LV"
  },
  {
      id: "MSU",
      name: "Moshoeshoe I International Airport",
      area_code: "MSU",
      city_name: "Maseru",
      country_code: "LS"
  },
  {
      id: "VTE",
      name: "Wattay International Airport",
      area_code: "VTE",
      city_name: "Vientiane",
      country_code: "LA"
  },
  {
      id: "BEY",
      name: "Rafic Hariri International Airport",
      area_code: "BEY",
      city_name: "Beirut",
      country_code: "LB"
  },
  {
      id: "VNO",
      name: "Vilnius International Airport",
      area_code: "VNO",
      city_name: "Vilnius",
      country_code: "LT"
  },
  {
      id: "MLW",
      name: "Spriggs Payne Airport",
      area_code: "MLW",
      city_name: "Monrovia",
      country_code: "LR"
  },
  {
      id: "TIP",
      name: "International",
      area_code: "TIP",
      city_name: "Tripoli",
      country_code: "LY"
  },
  {
      id: "QVU",
      name: "VADUZ",
      area_code: "QVU",
      city_name: "VADUZ",
      country_code: "LI"
  },
  {
      id: "LUX",
      name: "Luxembourg Airport",
      area_code: "LUX",
      city_name: "Luxembourg",
      country_code: "LU"
  },
  {
      id: "KGL",
      name: "Kigali International Airport",
      area_code: "KGL",
      city_name: "Kigali",
      country_code: "RW"
  },
  {
      id: "CLJ",
      name: "Cluj Napoca Airport",
      area_code: "CLJ",
      city_name: "Cluj-Napoca",
      country_code: "RO"
  },
  {
      id: "CND",
      name: "Mihail Kogalniceanu Airport",
      area_code: "CND",
      city_name: "Constanta",
      country_code: "RO"
  },
  {
      id: "IAS",
      name: "IASI AIRPORT",
      area_code: "IAS",
      city_name: "Iasi",
      country_code: "RO"
  },
  {
      id: "SBZ",
      name: "Sibiu International Airport",
      area_code: "SBZ",
      city_name: "Sibiu",
      country_code: "RO"
  },
  {
      id: "SCV",
      name: "Suceava Airport",
      area_code: "SCV",
      city_name: "Suceava",
      country_code: "RO"
  },
  {
      id: "TSR",
      name: "Traian Vuia International Airport",
      area_code: "TSR",
      city_name: "Timisoara",
      country_code: "RO"
  },
  {
      id: "ARW",
      name: "Arad International",
      area_code: "ARW",
      city_name: "Arad",
      country_code: "RO"
  },
  {
      id: "BBU",
      name: "Baneasa - Aurel Vlaicu",
      area_code: "BUH",
      city_name: "Bucharest",
      country_code: "RO"
  },
  {
      id: "OTP",
      name: "Henri Coanda Airport",
      area_code: "BUH",
      city_name: "Bucharest",
      country_code: "RO"
  },
  {
      id: "SMS",
      name: "Sainte Marie Airport",
      area_code: "SMS",
      city_name: "Sainte-Marie",
      country_code: "MG"
  },
  {
      id: "TLE",
      name: "Toliara Airport",
      area_code: "TLE",
      city_name: "Toliara",
      country_code: "MG"
  },
  {
      id: "TNR",
      name: "Ivato International Airport",
      area_code: "TNR",
      city_name: "Antananarivo",
      country_code: "MG"
  },
  {
      id: "MLE",
      name: "Ibrahim Nasir International Airport",
      area_code: "MLE",
      city_name: "Male",
      country_code: "MV"
  },
  {
      id: "MLA",
      name: "Luqa International Airport",
      area_code: "MLA",
      city_name: "Malta",
      country_code: "MT"
  },
  {
      id: "LLW",
      name: "Lilongwe International Airport",
      area_code: "LLW",
      city_name: "Lilongwe",
      country_code: "MW"
  },
  {
      id: "BLZ",
      name: "Chileka International Airport",
      area_code: "BLZ",
      city_name: "Blantyre",
      country_code: "MW"
  },
  {
      id: "IPH",
      name: "IPOH AIRPORT",
      area_code: "IPH",
      city_name: "Ipoh",
      country_code: "MY"
  },
  {
      id: "JHB",
      name: "Johor Bahru Airport",
      area_code: "JHB",
      city_name: "Johor Bahru",
      country_code: "MY"
  },
  {
      id: "JHG",
      name: "Gasa International Airport",
      area_code: "JHG",
      city_name: "Jinghong",
      country_code: "CN"
  },
  {
      id: "KBR",
      name: "Sultan Ismail Petra Airport",
      area_code: "KBR",
      city_name: "Kota Bharu",
      country_code: "MY"
  },
  {
      id: "KCH",
      name: "Kuching International Airport",
      area_code: "KCH",
      city_name: "Kuching",
      country_code: "MY"
  },
  {
      id: "KUA",
      name: "Sultan Haji Ahmad Shah Airport",
      area_code: "KUA",
      city_name: "Kuantan",
      country_code: "MY"
  },
  {
      id: "KUD",
      name: "KUDAT AIRPORT",
      area_code: "KUD",
      city_name: "Kudat",
      country_code: "MY"
  },
  {
      id: "KUL",
      name: "Kuala Lumpur International Airport",
      area_code: "KUL",
      city_name: "Kuala Lumpur",
      country_code: "MY"
  },
  {
      id: "LDU",
      name: "LAHAD DATU AIRPORT",
      area_code: "LDU",
      city_name: "Lahad Datu",
      country_code: "MY"
  },
  {
      id: "LGK",
      name: "Langkawi International Airport",
      area_code: "LGK",
      city_name: "Langkawi",
      country_code: "MY"
  },
  {
      id: "MKZ",
      name: "Malacca Airport",
      area_code: "MKZ",
      city_name: "Malacca",
      country_code: "MY"
  },
  {
      id: "PEN",
      name: "Penang International Airport",
      area_code: "PEN",
      city_name: "Penang",
      country_code: "MY"
  },
  {
      id: "SDK",
      name: "Sandakan Airport",
      area_code: "SDK",
      city_name: "Sandakan",
      country_code: "MY"
  },
  {
      id: "TOD",
      name: "Tioman Island",
      area_code: "TOD",
      city_name: "Tioman Island",
      country_code: "MY"
  },
  {
      id: "TWU",
      name: "Tawau Airport",
      area_code: "TWU",
      city_name: "Tawau",
      country_code: "MY"
  },
  {
      id: "AOR",
      name: "Sultan Abdul Halim Airport",
      area_code: "AOR",
      city_name: "Alor Setar",
      country_code: "MY"
  },
  {
      id: "BKI",
      name: "Kota Kinabalu International Airport",
      area_code: "BKI",
      city_name: "Kota Kinabalu",
      country_code: "MY"
  },
  {
      id: "BLG",
      name: "Belaga",
      area_code: "BLG",
      city_name: "Belaga",
      country_code: "MY"
  },
  {
      id: "BTU",
      name: "Bintulu Airport",
      area_code: "BTU",
      city_name: "Bintulu",
      country_code: "MY"
  },
  {
      id: "GAQ",
      name: "Korogoussou",
      area_code: "GAQ",
      city_name: "Gao",
      country_code: "ML"
  },
  {
      id: "TOM",
      name: "Tombouctou",
      area_code: "TOM",
      city_name: "Tombouctou",
      country_code: "ML"
  },
  {
      id: "BKO",
      name: "Senou International Airport",
      area_code: "BKO",
      city_name: "Bamako",
      country_code: "ML"
  },
  {
      id: "SKP",
      name: "Skopje Airport",
      area_code: "SKP",
      city_name: "Skopje",
      country_code: "MK"
  },
  {
      id: "MAJ",
      name: "Amata Kabua International Airport",
      area_code: "MAJ",
      city_name: "Majuro",
      country_code: "MH"
  },
  {
      id: "FDF",
      name: "Martinique Aime Cesaire Airport",
      area_code: "FDF",
      city_name: "Fort-de-France",
      country_code: "MQ"
  },
  {
      id: "MRU",
      name: "Sir Seewoosagur Ramgoolam International Airport",
      area_code: "MRU",
      city_name: "Mauritius",
      country_code: "MU"
  },
  {
      id: "EMN",
      name: "Nema",
      area_code: "EMN",
      city_name: "Nema",
      country_code: "MR"
  },
  {
      id: "KFA",
      name: "Kiffa",
      area_code: "KFA",
      city_name: "Kiffa",
      country_code: "MR"
  },
  {
      id: "SEY",
      name: "Selibabi",
      area_code: "SEY",
      city_name: "Selibabi",
      country_code: "MR"
  },
  {
      id: "TIY",
      name: "Tidjikja",
      area_code: "TIY",
      city_name: "Tidjikja",
      country_code: "MR"
  },
  {
      id: "NKC",
      name: "Nouakchott Airport",
      area_code: "NKC",
      city_name: "Nouakchott",
      country_code: "MR"
  },
  {
      id: "ATR",
      name: "International",
      area_code: "ATR",
      city_name: "Atar",
      country_code: "MR"
  },
  {
      id: "CDC",
      name: "CEDAR CITY AIRPORT",
      area_code: "CDC",
      city_name: "Cedar City",
      country_code: "US"
  },
  {
      id: "CDR",
      name: "Chadron Municipal Airport",
      area_code: "CDR",
      city_name: "Chadron",
      country_code: "US"
  },
  {
      id: "CEZ",
      name: "Cortez Municipal Airport",
      area_code: "CEZ",
      city_name: "Cortez",
      country_code: "US"
  },
  {
      id: "CGX",
      name: "MERRILL C MEIGS FIELD",
      area_code: "CHI",
      city_name: "Chicago",
      country_code: "US"
  },
  {
      id: "CHA",
      name: "Chattanooga Metropolitan Airport",
      area_code: "CHA",
      city_name: "Chattanooga",
      country_code: "US"
  },
  {
      id: "CHS",
      name: "Charleston International Airport",
      area_code: "CHS",
      city_name: "Charleston",
      country_code: "US"
  },
  {
      id: "CIC",
      name: "Municipal",
      area_code: "CIC",
      city_name: "Chico",
      country_code: "US"
  },
  {
      id: "CID",
      name: "The Eastern Iowa Airport",
      area_code: "CID",
      city_name: "Cedar Rapids",
      country_code: "US"
  },
  {
      id: "CIU",
      name: "CHIPPEWA COUNTY AIRPORT",
      area_code: "SSM",
      city_name: "Sault Ste Marie",
      country_code: "US"
  },
  {
      id: "CKB",
      name: "North Central West Virginia Airport",
      area_code: "CKB",
      city_name: "Clarksburg",
      country_code: "US"
  },
  {
      id: "CLD",
      name: "Carlsbad Airport",
      area_code: "SAN",
      city_name: "San Diego",
      country_code: "US"
  },
  {
      id: "CLE",
      name: "Hopkins International Airport",
      area_code: "CLE",
      city_name: "Cleveland",
      country_code: "US"
  },
  {
      id: "CLT",
      name: "Douglas International Airport",
      area_code: "CLT",
      city_name: "Charlotte",
      country_code: "US"
  },
  {
      id: "CMH",
      name: "John Glenn Intl",
      area_code: "CMH",
      city_name: "Columbus",
      country_code: "US"
  },
  {
      id: "CMX",
      name: "Houghton County Memorial Airport",
      area_code: "CMX",
      city_name: "Hancock",
      country_code: "US"
  },
  {
      id: "CNM",
      name: "Carlsbad Airport",
      area_code: "CNM",
      city_name: "Carlsbad",
      country_code: "US"
  },
  {
      id: "COS",
      name: "Colorado Springs Airport",
      area_code: "COS",
      city_name: "Colorado Springs",
      country_code: "US"
  },
  {
      id: "COU",
      name: "COLUMBIA REGIONAL AIRPORT",
      area_code: "COU",
      city_name: "Columbia",
      country_code: "US"
  },
  {
      id: "CPR",
      name: "CASPER AIRPORT",
      area_code: "CPR",
      city_name: "Casper",
      country_code: "US"
  },
  {
      id: "CRW",
      name: "Yeager Airport",
      area_code: "CRW",
      city_name: "Charleston",
      country_code: "US"
  },
  {
      id: "CSG",
      name: "Columbus Airport",
      area_code: "CSG",
      city_name: "Columbus",
      country_code: "US"
  },
  {
      id: "CVG",
      name: "Northern Kentucky International Airport",
      area_code: "CVG",
      city_name: "Cincinnati",
      country_code: "US"
  },
  {
      id: "CVN",
      name: "CLOVIS MUNICIPAL AIRPORT",
      area_code: "CVN",
      city_name: "Clovis",
      country_code: "US"
  },
  {
      id: "CYS",
      name: "CHEYENNE AIRPORT",
      area_code: "CYS",
      city_name: "Cheyenne",
      country_code: "US"
  },
  {
      id: "DAL",
      name: "Love Field Airport",
      area_code: "DFW",
      city_name: "Dallas",
      country_code: "US"
  },
  {
      id: "DAY",
      name: "James M. Cox International Airport",
      area_code: "DAY",
      city_name: "Dayton",
      country_code: "US"
  },
  {
      id: "DBQ",
      name: "Dubuque Regional Airport",
      area_code: "DBQ",
      city_name: "Dubuque",
      country_code: "US"
  },
  {
      id: "DCA",
      name: "RONALD REAGAN NATIONAL AIRPORT",
      area_code: "WAS",
      city_name: "Washington",
      country_code: "US"
  },
  {
      id: "DEC",
      name: "DECATUR AIRPORT",
      area_code: "DEC",
      city_name: "Decatur",
      country_code: "US"
  },
  {
      id: "DEN",
      name: "Denver International Airport",
      area_code: "DEN",
      city_name: "Denver",
      country_code: "US"
  },
  {
      id: "DFW",
      name: "Dallas/Fort Worth International Airport",
      area_code: "DFW",
      city_name: "Dallas",
      country_code: "US"
  },
  {
      id: "DHN",
      name: "Dothan Regional Airport",
      area_code: "DHN",
      city_name: "Dothan",
      country_code: "US"
  },
  {
      id: "DIK",
      name: "Dickinson Airport",
      area_code: "DIK",
      city_name: "Dickinson",
      country_code: "US"
  },
  {
      id: "DLH",
      name: "Duluth International Airport",
      area_code: "DLH",
      city_name: "Duluth",
      country_code: "US"
  },
  {
      id: "DNV",
      name: "Vermilion Regional",
      area_code: "DNV",
      city_name: "Danville",
      country_code: "US"
  },
  {
      id: "DRO",
      name: "La Plata County Airport",
      area_code: "DRO",
      city_name: "Durango",
      country_code: "US"
  },
  {
      id: "DSM",
      name: "Des Moines International Airport",
      area_code: "DSM",
      city_name: "Des Moines",
      country_code: "US"
  },
  {
      id: "DTW",
      name: "Detroit Metropolitan Wayne County Airport",
      area_code: "DTT",
      city_name: "Detroit",
      country_code: "US"
  },
  {
      id: "EAU",
      name: "Chippewa Valley Regional Airport",
      area_code: "EAU",
      city_name: "Eau Claire",
      country_code: "US"
  },
  {
      id: "EFD",
      name: "Ellington",
      area_code: "HOU",
      city_name: "Houston",
      country_code: "US"
  },
  {
      id: "EKO",
      name: "Elko Airport",
      area_code: "EKO",
      city_name: "Elko",
      country_code: "US"
  },
  {
      id: "ELD",
      name: "South Arkansas Regional Airport",
      area_code: "ELD",
      city_name: "El Dorado",
      country_code: "US"
  },
  {
      id: "ELI",
      name: "ELIM AIRPORT",
      area_code: "ELI",
      city_name: "Elim",
      country_code: "US"
  },
  {
      id: "ELP",
      name: "El Paso International Airport",
      area_code: "ELP",
      city_name: "El Paso",
      country_code: "US"
  },
  {
      id: "ELY",
      name: "Yelland Field",
      area_code: "ELY",
      city_name: "Ely",
      country_code: "US"
  },
  {
      id: "ERI",
      name: "Erie International Airport",
      area_code: "ERI",
      city_name: "Erie",
      country_code: "US"
  },
  {
      id: "EUG",
      name: "Eugene Airport",
      area_code: "EUG",
      city_name: "Eugene",
      country_code: "US"
  },
  {
      id: "EVV",
      name: "Evansville Regional Airport",
      area_code: "EVV",
      city_name: "Evansville",
      country_code: "US"
  },
  {
      id: "EWB",
      name: "New Bedford Regional Airport",
      area_code: "EWB",
      city_name: "New Bedford/Fall River",
      country_code: "US"
  },
  {
      id: "EWN",
      name: "Coastal Carolina Regional Airport",
      area_code: "EWN",
      city_name: "New Bern",
      country_code: "US"
  },
  {
      id: "EWR",
      name: "Newark Liberty International Airport",
      area_code: "NYC",
      city_name: "New York",
      country_code: "US"
  },
  {
      id: "EYW",
      name: "Key West International Airport",
      area_code: "EYW",
      city_name: "Key West",
      country_code: "US"
  },
  {
      id: "FAI",
      name: "Fairbanks International Airport",
      area_code: "FAI",
      city_name: "Fairbanks",
      country_code: "US"
  },
  {
      id: "FAR",
      name: "Hector International Airport",
      area_code: "FAR",
      city_name: "Fargo",
      country_code: "US"
  },
  {
      id: "FAT",
      name: "Yosemite International Airport",
      area_code: "FAT",
      city_name: "Fresno",
      country_code: "US"
  },
  {
      id: "FAY",
      name: "Fayetteville Regional Airport",
      area_code: "FAY",
      city_name: "Fayetteville",
      country_code: "US"
  },
  {
      id: "FLL",
      name: "Fort Lauderdale International Airport",
      area_code: "FLL",
      city_name: "Fort Lauderdale",
      country_code: "US"
  },
  {
      id: "FMN",
      name: "Farmington Airport",
      area_code: "FMN",
      city_name: "Farmington",
      country_code: "US"
  },
  {
      id: "FNT",
      name: "Bishop International Airport",
      area_code: "FNT",
      city_name: "Flint",
      country_code: "US"
  },
  {
      id: "FSD",
      name: "Sioux Falls Regional Airport",
      area_code: "FSD",
      city_name: "Sioux Falls",
      country_code: "US"
  },
  {
      id: "FSM",
      name: "Fort Smith Regional Airport",
      area_code: "FSM",
      city_name: "Fort Smith",
      country_code: "US"
  },
  {
      id: "FWA",
      name: "Fort Wayne International Airport",
      area_code: "FWA",
      city_name: "Fort Wayne",
      country_code: "US"
  },
  {
      id: "FYU",
      name: "FORT YUKON AIRPORT",
      area_code: "FYU",
      city_name: "Fort Yukon",
      country_code: "US"
  },
  {
      id: "FYV",
      name: "Drake Field",
      area_code: "FYV",
      city_name: "Fayetteville/Springd.",
      country_code: "US"
  },
  {
      id: "GBG",
      name: "Municipal",
      area_code: "GBG",
      city_name: "Galesburg",
      country_code: "US"
  },
  {
      id: "GCK",
      name: "Garden City Municipal Airport",
      area_code: "GCK",
      city_name: "Garden City",
      country_code: "US"
  },
  {
      id: "GCN",
      name: "National Park",
      area_code: "GCN",
      city_name: "Grand Canyon",
      country_code: "US"
  },
  {
      id: "GDV",
      name: "Dawson Community Airport",
      area_code: "GDV",
      city_name: "Glendive",
      country_code: "US"
  },
  {
      id: "GEG",
      name: "Spokane International Airport",
      area_code: "GEG",
      city_name: "Spokane",
      country_code: "US"
  },
  {
      id: "GFK",
      name: "Grand Forks International Airport",
      area_code: "GFK",
      city_name: "Grand Forks",
      country_code: "US"
  },
  {
      id: "GGG",
      name: "East Texas Regional Airport",
      area_code: "GGG",
      city_name: "Longview",
      country_code: "US"
  },
  {
      id: "GGW",
      name: "Glasgow International Airport",
      area_code: "GGW",
      city_name: "Glasgow",
      country_code: "US"
  },
  {
      id: "GLH",
      name: "Greenville Airport",
      area_code: "GLH",
      city_name: "Greenville",
      country_code: "US"
  },
  {
      id: "GPZ",
      name: "Itasca County",
      area_code: "GPZ",
      city_name: "Grand Rapids",
      country_code: "US"
  },
  {
      id: "GRB",
      name: "Austin Straubel Airport",
      area_code: "GRB",
      city_name: "Green Bay",
      country_code: "US"
  },
  {
      id: "GRI",
      name: "Central Nebraska Regional Airport",
      area_code: "GRI",
      city_name: "Grand Island",
      country_code: "US"
  },
  {
      id: "GRR",
      name: "Gerald R Ford International Airport",
      area_code: "GRR",
      city_name: "Grand Rapids",
      country_code: "US"
  },
  {
      id: "GSO",
      name: "Piedmont Triad International Airport",
      area_code: "GSO",
      city_name: "Greensboro/High Point",
      country_code: "US"
  },
  {
      id: "GSP",
      name: "Greenville Spartanburg Airport",
      area_code: "GSP",
      city_name: "Greenville/Spartanburg",
      country_code: "US"
  },
  {
      id: "GTF",
      name: "GREAT FALLS INTERNATIONAL AIRPORT",
      area_code: "GTF",
      city_name: "Great Falls",
      country_code: "US"
  },
  {
      id: "GUP",
      name: "Municipal",
      area_code: "GUP",
      city_name: "Gallup",
      country_code: "US"
  },
  {
      id: "GYY",
      name: "Gary Regional Airport",
      area_code: "GYY",
      city_name: "GARY",
      country_code: "US"
  },
  {
      id: "MDT",
      name: "Harrisburg International Airport",
      area_code: "HAR",
      city_name: "Harrisburg",
      country_code: "US"
  },
  {
      id: "HCR",
      name: "HOLY CROSS AIRPORT",
      area_code: "HCR",
      city_name: "Holy Cross",
      country_code: "US"
  },
  {
      id: "HFD",
      name: "Brainard",
      area_code: "HFD",
      city_name: "Hartford",
      country_code: "US"
  },
  {
      id: "HGR",
      name: "Hagerstown Regional Airport",
      area_code: "HGR",
      city_name: "Hagerstown",
      country_code: "US"
  },
  {
      id: "HIB",
      name: "Chisholm-Hibbing Airport",
      area_code: "HIB",
      city_name: "Hibbing/Chisholm",
      country_code: "US"
  },
  {
      id: "HLN",
      name: "Helena Regional Airport",
      area_code: "HLN",
      city_name: "Helena",
      country_code: "US"
  },
  {
      id: "HNL",
      name: "Honolulu International Airport",
      area_code: "HNL",
      city_name: "Honolulu",
      country_code: "US"
  },
  {
      id: "HNM",
      name: "HANA AIRPORT",
      area_code: "HNM",
      city_name: "Hana",
      country_code: "US"
  },
  {
      id: "HOB",
      name: "Lea County Regional Airport",
      area_code: "HOB",
      city_name: "Hobbs",
      country_code: "US"
  },
  {
      id: "HOM",
      name: "HOMER AIRPORT",
      area_code: "HOM",
      city_name: "Homer",
      country_code: "US"
  },
  {
      id: "HON",
      name: "Regional",
      area_code: "HON",
      city_name: "Huron",
      country_code: "US"
  },
  {
      id: "HOT",
      name: "Memorial Field Airport",
      area_code: "HOT",
      city_name: "Hot Springs",
      country_code: "US"
  },
  {
      id: "HOU",
      name: "HOBBY AIRPORT",
      area_code: "HOU",
      city_name: "Houston",
      country_code: "US"
  },
  {
      id: "HRL",
      name: "VALLEY INTERNATIONAL AIRPORT",
      area_code: "HRL",
      city_name: "Harlingen",
      country_code: "US"
  },
  {
      id: "HSH",
      name: "Henderson Executive",
      area_code: "LAS",
      city_name: "Las Vegas",
      country_code: "US"
  },
  {
      id: "HSI",
      name: "Municipal",
      area_code: "HSI",
      city_name: "Hastings",
      country_code: "US"
  },
  {
      id: "HTS",
      name: "Tri State Airport",
      area_code: "HTS",
      city_name: "Huntington",
      country_code: "US"
  },
  {
      id: "HUF",
      name: "Intl (Hulman Field)",
      area_code: "HUF",
      city_name: "Terre Haute",
      country_code: "US"
  },
  {
      id: "HVR",
      name: "Havre City County Airport",
      area_code: "HVR",
      city_name: "Havre",
      country_code: "US"
  },
  {
      id: "IAD",
      name: "Dulles International Airport",
      area_code: "WAS",
      city_name: "Washington",
      country_code: "US"
  },
  {
      id: "IAH",
      name: "George Bush Intercontinental Airport",
      area_code: "HOU",
      city_name: "Houston",
      country_code: "US"
  },
  {
      id: "IBR",
      name: "IBARAKI AIRPORT",
      area_code: "IBR",
      city_name: "Ibaraki",
      country_code: "JP"
  },
  {
      id: "ICT",
      name: "Mid Continent Airport",
      area_code: "ICT",
      city_name: "Wichita",
      country_code: "US"
  },
  {
      id: "IDA",
      name: "Idaho Falls Regional Airport",
      area_code: "IDA",
      city_name: "Idaho Falls",
      country_code: "US"
  },
  {
      id: "IGM",
      name: "Kingman",
      area_code: "IGM",
      city_name: "Kingman",
      country_code: "US"
  },
  {
      id: "IMT",
      name: "FORD AIRPORT",
      area_code: "IMT",
      city_name: "Iron Mountain/Kingsfd.",
      country_code: "US"
  },
  {
      id: "IPT",
      name: "Williamsport Regional Airport",
      area_code: "IPT",
      city_name: "Williamsport",
      country_code: "US"
  },
  {
      id: "IRK",
      name: "Kirksville Regional Airport",
      area_code: "IRK",
      city_name: "Kirksville",
      country_code: "US"
  },
  {
      id: "ISN",
      name: "SLOULIN FIELD INTERNATIONAL AIRPORT",
      area_code: "ISN",
      city_name: "Williston",
      country_code: "US"
  },
  {
      id: "ISP",
      name: "LONG ISLAND MACARTHUR AIRPORT",
      area_code: "ISP",
      city_name: "Islip",
      country_code: "US"
  },
  {
      id: "ITO",
      name: "Hilo International Airport",
      area_code: "ITO",
      city_name: "Hilo",
      country_code: "US"
  },
  {
      id: "IWD",
      name: "Gogebic County Airport",
      area_code: "IWD",
      city_name: "Ironwood",
      country_code: "US"
  },
  {
      id: "JAC",
      name: "Jackson Hole Airport",
      area_code: "JAC",
      city_name: "Jackson",
      country_code: "US"
  },
  {
      id: "JAN",
      name: "Jackson Evers International Airport",
      area_code: "JAN",
      city_name: "Jackson",
      country_code: "US"
  },
  {
      id: "JAX",
      name: "Jacksonville International Airport",
      area_code: "JAX",
      city_name: "Jacksonville",
      country_code: "US"
  },
  {
      id: "JFK",
      name: "John F. Kennedy International Airport",
      area_code: "NYC",
      city_name: "New York",
      country_code: "US"
  },
  {
      id: "JGC",
      name: "GRAND CANYON HELIPORT",
      area_code: "GCN",
      city_name: "Grand Canyon",
      country_code: "US"
  },
  {
      id: "JHW",
      name: "Chautauqua County Airport",
      area_code: "JHW",
      city_name: "Jamestown",
      country_code: "US"
  },
  {
      id: "JMS",
      name: "Jamestown Regional Airport",
      area_code: "JMS",
      city_name: "Jamestown",
      country_code: "US"
  },
  {
      id: "JNU",
      name: "JUNEAU INTERNATIONAL AIRPORT",
      area_code: "JNU",
      city_name: "Juneau",
      country_code: "US"
  },
  {
      id: "JST",
      name: "Cambria County Airport",
      area_code: "JST",
      city_name: "Johnstown",
      country_code: "US"
  },
  {
      id: "KPV",
      name: "Perryville Airport",
      area_code: "KPV",
      city_name: "Perryville",
      country_code: "US"
  },
  {
      id: "KTN",
      name: "KETCHIKAN INTERNATIONAL AIRPORT",
      area_code: "KTN",
      city_name: "Ketchikan",
      country_code: "US"
  },
  {
      id: "LAA",
      name: "Municipal",
      area_code: "LAA",
      city_name: "Lamar",
      country_code: "US"
  },
  {
      id: "LAF",
      name: "Purdue University",
      area_code: "LAF",
      city_name: "Lafayette",
      country_code: "US"
  },
  {
      id: "LAN",
      name: "Capital Region International Airport",
      area_code: "LAN",
      city_name: "Lansing",
      country_code: "US"
  },
  {
      id: "LAR",
      name: "Laramie Regional Airport",
      area_code: "LAR",
      city_name: "Laramie",
      country_code: "US"
  },
  {
      id: "LAS",
      name: "MCCARRAN INTERNATIONAL AIRPORT",
      area_code: "LAS",
      city_name: "Las Vegas",
      country_code: "US"
  },
  {
      id: "LAW",
      name: "Lawton Regional Airport",
      area_code: "LAW",
      city_name: "Lawton/Fort Sill",
      country_code: "US"
  },
  {
      id: "LAX",
      name: "Los Angeles International Airport",
      area_code: "LAX",
      city_name: "Los Angeles",
      country_code: "US"
  },
  {
      id: "LBB",
      name: "Preston Smith International Airport",
      area_code: "LBB",
      city_name: "Lubbock",
      country_code: "US"
  },
  {
      id: "LBF",
      name: "Lee Bird Field",
      area_code: "LBF",
      city_name: "North Platte",
      country_code: "US"
  },
  {
      id: "LBL",
      name: "LIBERAL MUNICIPAL AIRPORT",
      area_code: "LBL",
      city_name: "Liberal",
      country_code: "US"
  },
  {
      id: "LCH",
      name: "Lake Charles Regional Airport",
      area_code: "LCH",
      city_name: "Lake Charles",
      country_code: "US"
  },
  {
      id: "LEB",
      name: "Lebanon Municipal Airport",
      area_code: "LEB",
      city_name: "Lebanon",
      country_code: "US"
  },
  {
      id: "LFT",
      name: "Lafayette Regional Airport",
      area_code: "LFT",
      city_name: "Lafayette",
      country_code: "US"
  },
  {
      id: "LGA",
      name: "La Guardia Airport",
      area_code: "NYC",
      city_name: "New York",
      country_code: "US"
  },
  {
      id: "LGB",
      name: "Long Beach Municipal Airport",
      area_code: "LGB",
      city_name: "Long Beach",
      country_code: "US"
  },
  {
      id: "LIH",
      name: "Lihue Airport",
      area_code: "LIH",
      city_name: "Lihue",
      country_code: "US"
  },
  {
      id: "LIT",
      name: "Clinton National Airport",
      area_code: "LIT",
      city_name: "Little Rock",
      country_code: "US"
  },
  {
      id: "LNS",
      name: "LANCASTER AIRPORT",
      area_code: "LNS",
      city_name: "Lancaster",
      country_code: "US"
  },
  {
      id: "LNY",
      name: "Lanai Airport",
      area_code: "LNY",
      city_name: "Lanai City",
      country_code: "US"
  },
  {
      id: "LRD",
      name: "Laredo International Airport",
      area_code: "LRD",
      city_name: "Laredo",
      country_code: "US"
  },
  {
      id: "LWT",
      name: "Municipal",
      area_code: "LWT",
      city_name: "Lewistown",
      country_code: "US"
  },
  {
      id: "LYH",
      name: "Preston Glenn Field",
      area_code: "LYH",
      city_name: "Lynchburg",
      country_code: "US"
  },
  {
      id: "LYU",
      name: "Municipal",
      area_code: "LYU",
      city_name: "Ely",
      country_code: "US"
  },
  {
      id: "MAF",
      name: "Midland International Airport",
      area_code: "MAF",
      city_name: "Midland",
      country_code: "US"
  },
  {
      id: "MBL",
      name: "Blacker Airport",
      area_code: "MBL",
      city_name: "Manistee",
      country_code: "US"
  },
  {
      id: "MBS",
      name: "MBS International Airport",
      area_code: "MBS",
      city_name: "Saginaw/Midland/Bay C.",
      country_code: "US"
  },
  {
      id: "MCG",
      name: "McGrath Airport",
      area_code: "MCG",
      city_name: "McGrath",
      country_code: "US"
  },
  {
      id: "MCI",
      name: "Kansas City International Airport",
      area_code: "MKC",
      city_name: "Kansas City",
      country_code: "US"
  },
  {
      id: "MCK",
      name: "Mccook Airport",
      area_code: "MCK",
      city_name: "McCook",
      country_code: "US"
  },
  {
      id: "MCN",
      name: "Lewis B. Wilson Airport",
      area_code: "MCN",
      city_name: "Macon",
      country_code: "US"
  },
  {
      id: "MCO",
      name: "Orlando International Airport",
      area_code: "ORL",
      city_name: "Orlando",
      country_code: "US"
  },
  {
      id: "HAR",
      name: "Capital City",
      area_code: "HAR",
      city_name: "Harrisburg",
      country_code: "US"
  },
  {
      id: "MDW",
      name: "Midway International Airport",
      area_code: "CHI",
      city_name: "Chicago",
      country_code: "US"
  },
  {
      id: "MEI",
      name: "Key Field",
      area_code: "MEI",
      city_name: "Meridian",
      country_code: "US"
  },
  {
      id: "MEM",
      name: "Memphis International Airport",
      area_code: "MEM",
      city_name: "Memphis",
      country_code: "US"
  },
  {
      id: "MFR",
      name: "Rogue Valley International Airport",
      area_code: "MFR",
      city_name: "Medford",
      country_code: "US"
  },
  {
      id: "MGM",
      name: "Dannelly Field Airport",
      area_code: "MGM",
      city_name: "Montgomery",
      country_code: "US"
  },
  {
      id: "MHE",
      name: "Municipal",
      area_code: "MHE",
      city_name: "Mitchell",
      country_code: "US"
  },
  {
      id: "MHT",
      name: "Boston Regional Airport",
      area_code: "MHT",
      city_name: "Manchester Boston Regional Airport",
      country_code: "US"
  },
  {
      id: "MIA",
      name: "Miami International Airport",
      area_code: "MIA",
      city_name: "Miami",
      country_code: "US"
  },
  {
      id: "MIE",
      name: "Delaware County Rgnl",
      area_code: "MIE",
      city_name: "Muncie",
      country_code: "US"
  },
  {
      id: "MKC",
      name: "C.B. Wheeler Downtown",
      area_code: "MKC",
      city_name: "Kansas City",
      country_code: "US"
  },
  {
      id: "MKE",
      name: "General Mitchell International Airport",
      area_code: "MKE",
      city_name: "Milwaukee",
      country_code: "US"
  },
  {
      id: "MKG",
      name: "Muskegon County Airport",
      area_code: "MKG",
      city_name: "Muskegon",
      country_code: "US"
  },
  {
      id: "MKL",
      name: "McKellar-Sipes Regional Airport",
      area_code: "MKL",
      city_name: "Jackson",
      country_code: "US"
  },
  {
      id: "MLB",
      name: "Melbourne International Airport",
      area_code: "MLB",
      city_name: "Melbourne",
      country_code: "US"
  },
  {
      id: "MLI",
      name: "Quad City International Airport",
      area_code: "MLI",
      city_name: "Moline",
      country_code: "US"
  },
  {
      id: "MLL",
      name: "Don Hunter Sr. Airport",
      area_code: "MLL",
      city_name: "Marshall",
      country_code: "US"
  },
  {
      id: "MLS",
      name: "Frank Wiley Field",
      area_code: "MLS",
      city_name: "Miles City",
      country_code: "US"
  },
  {
      id: "MLU",
      name: "Monroe Regional Airport",
      area_code: "MLU",
      city_name: "Monroe",
      country_code: "US"
  },
  {
      id: "MOT",
      name: "Minot International Airport",
      area_code: "MOT",
      city_name: "Minot",
      country_code: "US"
  },
  {
      id: "MPB",
      name: "Sea Plane Base",
      area_code: "MIA",
      city_name: "Miami",
      country_code: "US"
  },
  {
      id: "MQT",
      name: "Sawyer International Airport",
      area_code: "MQT",
      city_name: "Marquette",
      country_code: "US"
  },
  {
      id: "MRY",
      name: "Monterey Regional Airport",
      area_code: "MRY",
      city_name: "Monterey/Carmel",
      country_code: "US"
  },
  {
      id: "MSN",
      name: "Dane County Regional Airport",
      area_code: "MSN",
      city_name: "Madison",
      country_code: "US"
  },
  {
      id: "MSO",
      name: "Missoula International Airport",
      area_code: "MSO",
      city_name: "Missoula",
      country_code: "US"
  },
  {
      id: "MSY",
      name: "Louis Armstrong International Airport",
      area_code: "MSY",
      city_name: "New Orleans",
      country_code: "US"
  },
  {
      id: "MTO",
      name: "Coles County Memorial",
      area_code: "MTO",
      city_name: "Mattoon/Charleston",
      country_code: "US"
  },
  {
      id: "MWA",
      name: "Williamson County Airport",
      area_code: "MWA",
      city_name: "Marion",
      country_code: "US"
  },
  {
      id: "MWH",
      name: "Grant County Intl",
      area_code: "MWH",
      city_name: "Moses Lake",
      country_code: "US"
  },
  {
      id: "NNK",
      name: "Naknek",
      area_code: "NNK",
      city_name: "Naknek",
      country_code: "US"
  },
  {
      id: "OAG",
      name: "Orange Airport",
      area_code: "OAG",
      city_name: "Orange",
      country_code: "AU"
  },
  {
      id: "OAJ",
      name: "Albert J Ellis Airport",
      area_code: "OAJ",
      city_name: "Jacksonville",
      country_code: "US"
  },
  {
      id: "OAK",
      name: "Oakland International Airport",
      area_code: "OAK",
      city_name: "Oakland",
      country_code: "US"
  },
  {
      id: "OFK",
      name: "Regional",
      area_code: "OFK",
      city_name: "Norfolk",
      country_code: "US"
  },
  {
      id: "OGS",
      name: "Ogdensburg International Airport",
      area_code: "OGS",
      city_name: "Ogdensburg",
      country_code: "US"
  },
  {
      id: "OKC",
      name: "Will Rogers World Airport",
      area_code: "OKC",
      city_name: "Oklahoma City",
      country_code: "US"
  },
  {
      id: "OMA",
      name: "Eppley Airfield",
      area_code: "OMA",
      city_name: "Omaha",
      country_code: "US"
  },
  {
      id: "OME",
      name: "NOME AIRPORT",
      area_code: "OME",
      city_name: "Nome",
      country_code: "US"
  },
  {
      id: "ONT",
      name: "Ontario International Airport",
      area_code: "LAX",
      city_name: "Los Angeles",
      country_code: "US"
  },
  {
      id: "ORD",
      name: "O'Hare International Airport",
      area_code: "CHI",
      city_name: "Chicago",
      country_code: "US"
  },
  {
      id: "ORF",
      name: "Norfolk International Airport",
      area_code: "ORF",
      city_name: "Norfolk",
      country_code: "US"
  },
  {
      id: "ORH",
      name: "Worcester Airport",
      area_code: "ORH",
      city_name: "Worcester",
      country_code: "US"
  },
  {
      id: "ORL",
      name: "Executive",
      area_code: "ORL",
      city_name: "Orlando",
      country_code: "US"
  },
  {
      id: "OSH",
      name: "Wittman Regional",
      area_code: "OSH",
      city_name: "Oshkosh",
      country_code: "US"
  },
  {
      id: "OTZ",
      name: "Ralph Wien Memorial Airport",
      area_code: "OTZ",
      city_name: "Kotzebue",
      country_code: "US"
  },
  {
      id: "PBI",
      name: "West Palm Beach International Airport",
      area_code: "PBI",
      city_name: "West Palm Beach",
      country_code: "US"
  },
  {
      id: "PDT",
      name: "Eastern Oregon Regional Airport",
      area_code: "PDT",
      city_name: "Pendleton",
      country_code: "US"
  },
  {
      id: "PDX",
      name: "Portland International Airport",
      area_code: "PDX",
      city_name: "Portland",
      country_code: "US"
  },
  {
      id: "PFN",
      name: "PANAMA CITY",
      area_code: "PFN",
      city_name: "PANAMA CITY",
      country_code: "US"
  },
  {
      id: "PGV",
      name: "PITT-GREENVILLE AIRPORT",
      area_code: "PGV",
      city_name: "Greenville",
      country_code: "US"
  },
  {
      id: "PHF",
      name: "Newport News International Airport",
      area_code: "PHF",
      city_name: "Newport News/Williamsb",
      country_code: "US"
  },
  {
      id: "PHL",
      name: "Philadelphia International Airport",
      area_code: "PHL",
      city_name: "Philadelphia",
      country_code: "US"
  },
  {
      id: "PHX",
      name: "Sky Harbor International Airport",
      area_code: "PHX",
      city_name: "Phoenix",
      country_code: "US"
  },
  {
      id: "PIA",
      name: "General Wayne A. Downing International Airport",
      area_code: "PIA",
      city_name: "Peoria",
      country_code: "US"
  },
  {
      id: "PIE",
      name: "St. Pete-Clearwater International Airport",
      area_code: "TPA",
      city_name: "Tampa",
      country_code: "US"
  },
  {
      id: "PIH",
      name: "Pocatello Regional Airport",
      area_code: "PIH",
      city_name: "Pocatello",
      country_code: "US"
  },
  {
      id: "PIR",
      name: "Pierre Regional Airport",
      area_code: "PIR",
      city_name: "Pierre",
      country_code: "US"
  },
  {
      id: "PIT",
      name: "Pittsburgh International Airport",
      area_code: "PIT",
      city_name: "Pittsburgh",
      country_code: "US"
  },
  {
      id: "PNS",
      name: "Pensacola International Airport",
      area_code: "PNS",
      city_name: "Pensacola",
      country_code: "US"
  },
  {
      id: "POU",
      name: "Dutchess County",
      area_code: "POU",
      city_name: "Poughkeepsie",
      country_code: "US"
  },
  {
      id: "PUB",
      name: "Pueblo Memorial Airport",
      area_code: "PUB",
      city_name: "Pueblo",
      country_code: "US"
  },
  {
      id: "PVD",
      name: "Theodore Francis Green State Airport",
      area_code: "PVD",
      city_name: "Providence",
      country_code: "US"
  },
  {
      id: "PWK",
      name: "Executive",
      area_code: "CHI",
      city_name: "Chicago",
      country_code: "US"
  },
  {
      id: "PWM",
      name: "Portland International Jetport",
      area_code: "PWM",
      city_name: "Portland",
      country_code: "US"
  },
  {
      id: "RAP",
      name: "RAPID CITY REGIONAL AIRPORT",
      area_code: "RAP",
      city_name: "Rapid City",
      country_code: "US"
  },
  {
      id: "RDD",
      name: "Redding Municipal Airport",
      area_code: "RDD",
      city_name: "Redding",
      country_code: "US"
  },
  {
      id: "RDU",
      name: "Raleigh International Airport",
      area_code: "RDU",
      city_name: "Raleigh/Durham",
      country_code: "US"
  },
  {
      id: "RFD",
      name: "Rockford International Airport",
      area_code: "CHI",
      city_name: "Chicago",
      country_code: "US"
  },
  {
      id: "RHI",
      name: "Oneida County Airport",
      area_code: "RHI",
      city_name: "Rhinelander",
      country_code: "US"
  },
  {
      id: "RIC",
      name: "Richmond International Airport",
      area_code: "RIC",
      city_name: "Richmond",
      country_code: "US"
  },
  {
      id: "RIW",
      name: "Regional",
      area_code: "RIW",
      city_name: "Riverton",
      country_code: "US"
  },
  {
      id: "RNO",
      name: "Reno Tahoe International Airport",
      area_code: "RNO",
      city_name: "Reno",
      country_code: "US"
  },
  {
      id: "ROA",
      name: "Roanoke Regional Airport",
      area_code: "ROA",
      city_name: "Roanoke",
      country_code: "US"
  },
  {
      id: "ROC",
      name: "Greater Rochester International Airport",
      area_code: "ROC",
      city_name: "Rochester",
      country_code: "US"
  },
  {
      id: "ROW",
      name: "Roswell International Air Center",
      area_code: "ROW",
      city_name: "Roswell",
      country_code: "US"
  },
  {
      id: "RSJ",
      name: "Sea Plane Base",
      area_code: "RSJ",
      city_name: "Rosario",
      country_code: "US"
  },
  {
      id: "RWI",
      name: "Wilson Regional",
      area_code: "RWI",
      city_name: "Rocky Mount",
      country_code: "US"
  },
  {
      id: "SAC",
      name: "Executive",
      area_code: "SAC",
      city_name: "Sacramento",
      country_code: "US"
  },
  {
      id: "SAF",
      name: "Santa Fe Municipal Airport",
      area_code: "SAF",
      city_name: "Santa Fe",
      country_code: "US"
  },
  {
      id: "SAN",
      name: "San Diego International Airport",
      area_code: "SAN",
      city_name: "San Diego",
      country_code: "US"
  },
  {
      id: "SAT",
      name: "San Antonio International Airport",
      area_code: "SAT",
      city_name: "San Antonio",
      country_code: "US"
  },
  {
      id: "SAV",
      name: "Hilton Head Airport",
      area_code: "SAV",
      city_name: "Savannah",
      country_code: "US"
  },
  {
      id: "SBA",
      name: "Santa Barbara Municipal Airport",
      area_code: "SBA",
      city_name: "Santa Barbara",
      country_code: "US"
  },
  {
      id: "SBN",
      name: "South Bend International Airport",
      area_code: "SBN",
      city_name: "South Bend",
      country_code: "US"
  },
  {
      id: "SBP",
      name: "San Luis County Regional Airport",
      area_code: "SBP",
      city_name: "Saarbruecken",
      country_code: "US"
  },
  {
      id: "SCC",
      name: "Deadhorse Airport",
      area_code: "SCC",
      city_name: "Prudhoe Bay/Deadhorse",
      country_code: "US"
  },
  {
      id: "SDF",
      name: "STANDIFORD FIELD",
      area_code: "SDF",
      city_name: "Louisville",
      country_code: "US"
  },
  {
      id: "SEA",
      name: "Tacoma International Airport",
      area_code: "SEA",
      city_name: "Seattle",
      country_code: "US"
  },
  {
      id: "SFO",
      name: "San Francisco International Airport",
      area_code: "SFO",
      city_name: "San Francisco",
      country_code: "US"
  },
  {
      id: "SGU",
      name: "Saint George Municipal Airport",
      area_code: "SGU",
      city_name: "St George",
      country_code: "US"
  },
  {
      id: "SGY",
      name: "Skagway Airport",
      area_code: "SGY",
      city_name: "Skagway",
      country_code: "US"
  },
  {
      id: "SHD",
      name: "Shenandoah Valley Regional Airport",
      area_code: "SHD",
      city_name: "Staunton/Waynesboro",
      country_code: "US"
  },
  {
      id: "SHR",
      name: "Sheridan County",
      area_code: "SHR",
      city_name: "Sheridan",
      country_code: "US"
  },
  {
      id: "SHV",
      name: "Shreveport Regional Airport",
      area_code: "SHV",
      city_name: "Shreveport",
      country_code: "US"
  },
  {
      id: "SIT",
      name: "Rocky Gutierrez Airport",
      area_code: "SIT",
      city_name: "Sitka",
      country_code: "US"
  },
  {
      id: "SJC",
      name: "San Jose International Airport",
      area_code: "SJC",
      city_name: "San Jose",
      country_code: "US"
  },
  {
      id: "SJT",
      name: "Mathis Field",
      area_code: "SJT",
      city_name: "San Angelo",
      country_code: "US"
  },
  {
      id: "SJU",
      name: "Luis Munoz Marin International Airport",
      area_code: "SJU",
      city_name: "San Juan",
      country_code: "PR"
  },
  {
      id: "SLC",
      name: "Salt Lake City International Airport",
      area_code: "SLC",
      city_name: "Salt Lake City",
      country_code: "US"
  },
  {
      id: "SLN",
      name: "Salina Regional Airport",
      area_code: "SLN",
      city_name: "Salina",
      country_code: "US"
  },
  {
      id: "SMF",
      name: "Sacramento International Airport",
      area_code: "SAC",
      city_name: "Sacramento",
      country_code: "US"
  },
  {
      id: "SMK",
      name: "St. Michael Airport",
      area_code: "SMK",
      city_name: "St Michael",
      country_code: "US"
  },
  {
      id: "SMX",
      name: "Santa Maria Public Airport",
      area_code: "SMX",
      city_name: "Santa Maria",
      country_code: "US"
  },
  {
      id: "SNA",
      name: "John Wayne Airport",
      area_code: "SNA",
      city_name: "Santa Ana",
      country_code: "US"
  },
  {
      id: "SNP",
      name: "Saint Paul Island Airport",
      area_code: "SNP",
      city_name: "St Paul Island",
      country_code: "US"
  },
  {
      id: "SPI",
      name: "Abraham Lincoln Capital Airport",
      area_code: "SPI",
      city_name: "Springfield",
      country_code: "US"
  },
  {
      id: "SPN",
      name: "Saipan International Airport",
      area_code: "SPN",
      city_name: "Saipan",
      country_code: "US"
  },
  {
      id: "SPW",
      name: "Municipal",
      area_code: "SPW",
      city_name: "Spencer",
      country_code: "US"
  },
  {
      id: "SQI",
      name: "Whiteside County",
      area_code: "SQI",
      city_name: "Sterling/Rock Falls",
      country_code: "US"
  },
  {
      id: "SRQ",
      name: "Bradenton International Airport",
      area_code: "SRQ",
      city_name: "Sarasota/Bradenton",
      country_code: "US"
  },
  {
      id: "SSI",
      name: "McKinnon St Simons Is.",
      area_code: "SSI",
      city_name: "Brunswick",
      country_code: "US"
  },
  {
      id: "STG",
      name: "Saint George Island Airport",
      area_code: "STG",
      city_name: "St George Island",
      country_code: "US"
  },
  {
      id: "STL",
      name: "Lambert St.Louis International Airport",
      area_code: "STL",
      city_name: "St Louis",
      country_code: "US"
  },
  {
      id: "STS",
      name: "Charles M. Schulz Airport",
      area_code: "STS",
      city_name: "Santa Rosa",
      country_code: "US"
  },
  {
      id: "SUX",
      name: "Sioux Gateway Airport",
      area_code: "SUX",
      city_name: "Sioux City",
      country_code: "US"
  },
  {
      id: "SVC",
      name: "Grant County Airport",
      area_code: "SVC",
      city_name: "Silver City",
      country_code: "US"
  },
  {
      id: "SWD",
      name: "Seward",
      area_code: "SWD",
      city_name: "Seward",
      country_code: "US"
  },
  {
      id: "SWF",
      name: "STEWART AIRPORT",
      area_code: "NYC",
      city_name: "New York",
      country_code: "US"
  },
  {
      id: "SYR",
      name: "Hancock International Airport",
      area_code: "SYR",
      city_name: "Syracuse",
      country_code: "US"
  },
  {
      id: "TAL",
      name: "Ralph M. Calhoun Memorial Airport",
      area_code: "TAL",
      city_name: "Tanana",
      country_code: "US"
  },
  {
      id: "TLH",
      name: "Tallahassee International Airport",
      area_code: "TLH",
      city_name: "Tallahassee",
      country_code: "US"
  },
  {
      id: "TOL",
      name: "Express Airport",
      area_code: "TOL",
      city_name: "Toledo",
      country_code: "US"
  },
  {
      id: "TOP",
      name: "Philip Billard Muni",
      area_code: "TOP",
      city_name: "Topeka",
      country_code: "US"
  },
  {
      id: "TPA",
      name: "Tampa International Airport",
      area_code: "TPA",
      city_name: "Tampa",
      country_code: "US"
  },
  {
      id: "TRI",
      name: "Tri-Cities Regional Airport",
      area_code: "TRI",
      city_name: "Bristol/Johnson/Kingsp",
      country_code: "US"
  },
  {
      id: "TTN",
      name: "Trenton Mercer Airport",
      area_code: "PHL",
      city_name: "Philadelphia",
      country_code: "US"
  },
  {
      id: "TUL",
      name: "Tulsa International Airport",
      area_code: "TUL",
      city_name: "Tulsa",
      country_code: "US"
  },
  {
      id: "TUS",
      name: "Tucson International Airport",
      area_code: "TUS",
      city_name: "Tucson",
      country_code: "US"
  },
  {
      id: "TVC",
      name: "Cherry Capital Airport",
      area_code: "TVC",
      city_name: "Traverse City",
      country_code: "US"
  },
  {
      id: "TWF",
      name: "Magic Valley Regional Airport",
      area_code: "TWF",
      city_name: "Twin Falls",
      country_code: "US"
  },
  {
      id: "TXK",
      name: "Texarkana Regional Airport",
      area_code: "TXK",
      city_name: "Texarkana",
      country_code: "US"
  },
  {
      id: "TYS",
      name: "McGhee Tyson Airport",
      area_code: "TYS",
      city_name: "Knoxville",
      country_code: "US"
  },
  {
      id: "UBS",
      name: "Lowndes County",
      area_code: "UBS",
      city_name: "Columbus",
      country_code: "US"
  },
  {
      id: "UCA",
      name: "Oneida County Airport",
      area_code: "UCA",
      city_name: "UTICA",
      country_code: "US"
  },
  {
      id: "UIN",
      name: "Quincy Regional Airport",
      area_code: "UIN",
      city_name: "Quincy",
      country_code: "US"
  },
  {
      id: "VCT",
      name: "Victoria Regional Airport",
      area_code: "VCT",
      city_name: "Victoria",
      country_code: "US"
  },
  {
      id: "VDZ",
      name: "Valdez Airport",
      area_code: "VDZ",
      city_name: "Valdez",
      country_code: "US"
  },
  {
      id: "VGT",
      name: "North Las Vegas Arpt",
      area_code: "LAS",
      city_name: "Las Vegas",
      country_code: "US"
  },
  {
      id: "VPS",
      name: "Destin-Ft Walton Beach",
      area_code: "VPS",
      city_name: "Destin/Ft Walton Beach",
      country_code: "US"
  },
  {
      id: "WAA",
      name: "WALES AIRPORT",
      area_code: "WAA",
      city_name: "Wales",
      country_code: "US"
  },
  {
      id: "WDG",
      name: "Woodring Regional",
      area_code: "WDG",
      city_name: "Enid",
      country_code: "US"
  },
  {
      id: "WRG",
      name: "Wrangell Airport",
      area_code: "WRG",
      city_name: "Wrangell",
      country_code: "US"
  },
  {
      id: "WTL",
      name: "TUNTUTULIAK AIRPORT",
      area_code: "WTL",
      city_name: "Tuntutuliak",
      country_code: "US"
  },
  {
      id: "YAK",
      name: "YAKUTAT AIRPORT",
      area_code: "YAK",
      city_name: "Yakutat",
      country_code: "US"
  },
  {
      id: "YKM",
      name: "Yakima Air Terminal",
      area_code: "YKM",
      city_name: "Yakima",
      country_code: "US"
  },
  {
      id: "YKN",
      name: "Chan Gurney Municipal",
      area_code: "YKN",
      city_name: "Yankton",
      country_code: "US"
  },
  {
      id: "YNG",
      name: "Regional",
      area_code: "YNG",
      city_name: "Youngstown/Warren",
      country_code: "US"
  },
  {
      id: "ZSM",
      name: "SANTA CLARA",
      area_code: "ZSM",
      city_name: "SANTA CLARA",
      country_code: "US"
  },
  {
      id: "ACK",
      name: "NANTUCKET MEMORIAL AIRPORT",
      area_code: "ACK",
      city_name: "Nantucket",
      country_code: "US"
  },
  {
      id: "ALW",
      name: "Walla Walla Regional Airport",
      area_code: "ALW",
      city_name: "Walla Walla",
      country_code: "US"
  },
  {
      id: "HSV",
      name: "Huntsville International Airport",
      area_code: "HSV",
      city_name: "Huntsville",
      country_code: "US"
  },
  {
      id: "IND",
      name: "Indianapolis International Airport",
      area_code: "IND",
      city_name: "Indianapolis",
      country_code: "US"
  },
  {
      id: "LEX",
      name: "Blue Grass Airport",
      area_code: "LEX",
      city_name: "Lexington",
      country_code: "US"
  },
  {
      id: "LNK",
      name: "Lincoln Airport",
      area_code: "LNK",
      city_name: "Lincoln",
      country_code: "US"
  },
  {
      id: "LSE",
      name: "La Crosse Regional Airport",
      area_code: "LSE",
      city_name: "La Crosse",
      country_code: "US"
  },
  {
      id: "LWS",
      name: "Nez Perce County Regional Airport",
      area_code: "LWS",
      city_name: "Lewiston",
      country_code: "US"
  },
  {
      id: "MOB",
      name: "Mobile Regional Airport",
      area_code: "MOB",
      city_name: "Mobile",
      country_code: "US"
  },
  {
      id: "MSP",
      name: "St Paul International Airport",
      area_code: "MSP",
      city_name: "Minneapolis/St Paul",
      country_code: "US"
  },
  {
      id: "PSC",
      name: "Tri Cities Airport",
      area_code: "PSC",
      city_name: "Pasco",
      country_code: "US"
  },
  {
      id: "RST",
      name: "Rochester International Airport",
      area_code: "RST",
      city_name: "Rochester",
      country_code: "US"
  },
  {
      id: "SGF",
      name: "Branson National Airport",
      area_code: "SGF",
      city_name: "Springfield",
      country_code: "US"
  },
  {
      id: "BNA",
      name: "Nashville International Airport",
      area_code: "BNA",
      city_name: "Nashville",
      country_code: "US"
  },
  {
      id: "CLM",
      name: "W.R. Fairchild Intl",
      area_code: "CLM",
      city_name: "Port Angeles",
      country_code: "US"
  },
  {
      id: "CMI",
      name: "University of Illinois Willard Airport",
      area_code: "CMI",
      city_name: "Champaign/Urbana",
      country_code: "US"
  },
  {
      id: "CWA",
      name: "Central Wisconsin Airport",
      area_code: "AUW",
      city_name: "Wausau",
      country_code: "US"
  },
  {
      id: "EAT",
      name: "Pangborn Memorial Airport",
      area_code: "EAT",
      city_name: "Wenatchee",
      country_code: "US"
  },
  {
      id: "BJI",
      name: "Bemidji Regional Airport",
      area_code: "BJI",
      city_name: "Bemidji",
      country_code: "US"
  },
  {
      id: "BRD",
      name: "Brainerd Lakes Regional Airport",
      area_code: "BRD",
      city_name: "Brainerd",
      country_code: "US"
  },
  {
      id: "ESC",
      name: "DELTA COUNTY AIRPORT",
      area_code: "ESC",
      city_name: "Escanaba",
      country_code: "US"
  },
  {
      id: "FCA",
      name: "Glacier Park International Airport",
      area_code: "FCA",
      city_name: "Kalispell",
      country_code: "US"
  },
  {
      id: "FOD",
      name: "Fort Dodge Regional Airport",
      area_code: "FOD",
      city_name: "Fort Dodge",
      country_code: "US"
  },
  {
      id: "GPT",
      name: "Gulfport Biloxi International Airport",
      area_code: "GPT",
      city_name: "Gulfport/Biloxi",
      country_code: "US"
  },
  {
      id: "HYA",
      name: "Barnstable Municipal Airport",
      area_code: "HYA",
      city_name: "Hyannis",
      country_code: "US"
  },
  {
      id: "INL",
      name: "FALLS INTERNATIONAL AIRPORT",
      area_code: "INL",
      city_name: "International Falls",
      country_code: "US"
  },
  {
      id: "JLN",
      name: "Joplin Regional Airport",
      area_code: "JLN",
      city_name: "Joplin",
      country_code: "US"
  },
  {
      id: "LMT",
      name: "Klamath Falls Airport",
      area_code: "LMT",
      city_name: "Klamath Falls",
      country_code: "US"
  },
  {
      id: "MCW",
      name: "Mason City Municipal Airport",
      area_code: "MCW",
      city_name: "Mason City",
      country_code: "US"
  },
  {
      id: "OTH",
      name: "Southwest Oregon Regional Airport",
      area_code: "OTH",
      city_name: "North Bend",
      country_code: "US"
  },
  {
      id: "OWB",
      name: "DAVIESS COUNTY AIRPORT",
      area_code: "OWB",
      city_name: "Owensboro",
      country_code: "US"
  },
  {
      id: "PAH",
      name: "BARKLEY REGIONAL AIRPORT",
      area_code: "PAH",
      city_name: "Paducah",
      country_code: "US"
  },
  {
      id: "PLN",
      name: "Pellston Regional Airport",
      area_code: "PLN",
      city_name: "Pellston",
      country_code: "US"
  },
  {
      id: "PQI",
      name: "Presque Isle Municipal Airport",
      area_code: "PQI",
      city_name: "Presque Isle",
      country_code: "US"
  },
  {
      id: "PSP",
      name: "Palm Springs International Airport",
      area_code: "PSP",
      city_name: "Palm Springs",
      country_code: "US"
  },
  {
      id: "PUW",
      name: "Moscow Regional Airport",
      area_code: "PUW",
      city_name: "Pullman WA/Moscow ID",
      country_code: "US"
  },
  {
      id: "RDM",
      name: "Roberts Field",
      area_code: "RDM",
      city_name: "Redmond/Bend",
      country_code: "US"
  },
  {
      id: "ROX",
      name: "Municipal",
      area_code: "ROX",
      city_name: "Roseau",
      country_code: "US"
  },
  {
      id: "SCE",
      name: "University Park Airport",
      area_code: "SCE",
      city_name: "State College",
      country_code: "US"
  },
  {
      id: "SLE",
      name: "McNary Field",
      area_code: "SLE",
      city_name: "Salem",
      country_code: "US"
  },
  {
      id: "STC",
      name: "St. Cloud Regional Airport",
      area_code: "STC",
      city_name: "St Cloud",
      country_code: "US"
  },
  {
      id: "TUP",
      name: "Tupelo Regional Airport",
      area_code: "TUP",
      city_name: "Tupelo",
      country_code: "US"
  },
  {
      id: "TVF",
      name: "THIEF RIVER FALLS REGIONAL AIRPORT",
      area_code: "TVF",
      city_name: "Thief River Falls",
      country_code: "US"
  },
  {
      id: "ABE",
      name: "Lehigh Valley International Airport",
      area_code: "ABE",
      city_name: "Allentown",
      country_code: "US"
  },
  {
      id: "ABI",
      name: "Abilene Regional Airport",
      area_code: "ABI",
      city_name: "Abilene",
      country_code: "US"
  },
  {
      id: "ABQ",
      name: "Albuquerque International Airport",
      area_code: "ABQ",
      city_name: "Albuquerque",
      country_code: "US"
  },
  {
      id: "ABR",
      name: "Aberdeen Regional Airport",
      area_code: "ABR",
      city_name: "Aberdeen",
      country_code: "US"
  },
  {
      id: "ABY",
      name: "Southwest Georgia Regional Airport",
      area_code: "ABY",
      city_name: "Albany",
      country_code: "US"
  },
  {
      id: "ACT",
      name: "Waco Regional Airport",
      area_code: "ACT",
      city_name: "Waco",
      country_code: "US"
  },
  {
      id: "ACV",
      name: "ARCATA AIRPORT",
      area_code: "ACV",
      city_name: "Arcata/Eureka",
      country_code: "US"
  },
  {
      id: "ACY",
      name: "Atlantic City International Airport",
      area_code: "ACY",
      city_name: "Atlantic City",
      country_code: "US"
  },
  {
      id: "ADQ",
      name: "KODIAK AIRPORT",
      area_code: "ADQ",
      city_name: "Kodiak",
      country_code: "US"
  },
  {
      id: "AGS",
      name: "Bush Field",
      area_code: "AGS",
      city_name: "Augusta",
      country_code: "US"
  },
  {
      id: "AIA",
      name: "Alliance Municipal Airport",
      area_code: "AIA",
      city_name: "Alliance",
      country_code: "US"
  },
  {
      id: "AIN",
      name: "WAINWRIGHT AIRPORT",
      area_code: "AIN",
      city_name: "Wainwright",
      country_code: "US"
  },
  {
      id: "AIY",
      name: "Atlantic City Intl Airport",
      area_code: "AIY",
      city_name: "Atlantic City",
      country_code: "US"
  },
  {
      id: "AKC",
      name: "Akron Fulton Intl",
      area_code: "CAK",
      city_name: "Akron/Canton",
      country_code: "US"
  },
  {
      id: "ALB",
      name: "Albany International Airport",
      area_code: "ALB",
      city_name: "Albany",
      country_code: "US"
  },
  {
      id: "ALM",
      name: "White Sands Regional",
      area_code: "ALM",
      city_name: "Alamogordo",
      country_code: "US"
  },
  {
      id: "ALO",
      name: "Waterloo Regional Airport",
      area_code: "ALO",
      city_name: "Waterloo",
      country_code: "US"
  },
  {
      id: "ALY",
      name: "El Nouzha",
      area_code: "ALY",
      city_name: "Alexandria",
      country_code: "EG"
  },
  {
      id: "AMA",
      name: "Rick Husband International Airport",
      area_code: "AMA",
      city_name: "Amarillo",
      country_code: "US"
  },
  {
      id: "ANB",
      name: "Regional",
      area_code: "ANB",
      city_name: "Anniston",
      country_code: "US"
  },
  {
      id: "ANC",
      name: "Ted Stevens International Airport",
      area_code: "ANC",
      city_name: "Anchorage",
      country_code: "US"
  },
  {
      id: "AOO",
      name: "Altoona Airport",
      area_code: "AOO",
      city_name: "Altoona",
      country_code: "US"
  },
  {
      id: "ARC",
      name: "ARCTIC VILLAGE AIRPORT",
      area_code: "ARC",
      city_name: "ARCTIC VILLAGE",
      country_code: "US"
  },
  {
      id: "ART",
      name: "Watertown International Airport",
      area_code: "ART",
      city_name: "Watertown",
      country_code: "US"
  },
  {
      id: "ATL",
      name: "Hartsfield-Jackson International Airport",
      area_code: "ATL",
      city_name: "Atlanta",
      country_code: "US"
  },
  {
      id: "ATW",
      name: "Outagamie County Airport",
      area_code: "ATW",
      city_name: "Appleton",
      country_code: "US"
  },
  {
      id: "ATY",
      name: "Watertown Regional Airport",
      area_code: "ATY",
      city_name: "Watertown",
      country_code: "US"
  },
  {
      id: "AUG",
      name: "Augusta State Airport",
      area_code: "AUG",
      city_name: "Augusta",
      country_code: "US"
  },
  {
      id: "AUS",
      name: "Austin-Bergstrom International Airport",
      area_code: "AUS",
      city_name: "Austin",
      country_code: "US"
  },
  {
      id: "AVL",
      name: "Asheville Regional Airport",
      area_code: "AVL",
      city_name: "Asheville/Hendersonv.",
      country_code: "US"
  },
  {
      id: "AZO",
      name: "Kalamazoo Airport",
      area_code: "AZO",
      city_name: "Kalamazoo/Battle Creek",
      country_code: "US"
  },
  {
      id: "BDR",
      name: "Igor I. Sikorsky Mem.",
      area_code: "BDR",
      city_name: "Bridgeport",
      country_code: "US"
  },
  {
      id: "BEH",
      name: "Southwest Michigan Rgl",
      area_code: "BEH",
      city_name: "Benton Harbor",
      country_code: "US"
  },
  {
      id: "BFL",
      name: "Meadows Field",
      area_code: "BFL",
      city_name: "Bakersfield",
      country_code: "US"
  },
  {
      id: "BGM",
      name: "Binghamton Airport",
      area_code: "BGM",
      city_name: "Binghamton",
      country_code: "US"
  },
  {
      id: "BGR",
      name: "Bangor International Airport",
      area_code: "BGR",
      city_name: "Bangor",
      country_code: "US"
  },
  {
      id: "BHM",
      name: "Shuttlesworth International Airport",
      area_code: "BHM",
      city_name: "Birmingham",
      country_code: "US"
  },
  {
      id: "BIL",
      name: "Logan International Airport",
      area_code: "BIL",
      city_name: "Billings",
      country_code: "US"
  },
  {
      id: "BIS",
      name: "Bismarck Municipal Airport",
      area_code: "BIS",
      city_name: "Bismarck",
      country_code: "US"
  },
  {
      id: "BKW",
      name: "Raleigh County Memorial Airport",
      area_code: "BKW",
      city_name: "Beckley",
      country_code: "US"
  },
  {
      id: "BLF",
      name: "Bailing",
      area_code: "BLF",
      city_name: "Bluefield/Princeton",
      country_code: "US"
  },
  {
      id: "BLI",
      name: "BELLINGHAM AIRPORT",
      area_code: "BLI",
      city_name: "Bellingham",
      country_code: "US"
  },
  {
      id: "BMG",
      name: "Monroe County",
      area_code: "BMG",
      city_name: "Bloomington",
      country_code: "US"
  },
  {
      id: "BOI",
      name: "Boise Air Terminal",
      area_code: "BOI",
      city_name: "Boise",
      country_code: "US"
  },
  {
      id: "BOS",
      name: "LOGAN INTERNATIONAL AIRPORT",
      area_code: "BOS",
      city_name: "Boston",
      country_code: "US"
  },
  {
      id: "BPT",
      name: "Jefferson Counnty Airport",
      area_code: "BPT",
      city_name: "Beaumont/Port Arthur",
      country_code: "US"
  },
  {
      id: "BRL",
      name: "Burlington Airport",
      area_code: "BRL",
      city_name: "Burlington",
      country_code: "US"
  },
  {
      id: "BRO",
      name: "South Padre Island International Airport",
      area_code: "BRO",
      city_name: "Brownsville",
      country_code: "US"
  },
  {
      id: "BRW",
      name: "Wiley Post Will Rogers Memorial Airport",
      area_code: "BRW",
      city_name: "Barrow",
      country_code: "US"
  },
  {
      id: "BTM",
      name: "Bert Mooney Airport",
      area_code: "BTM",
      city_name: "Butte",
      country_code: "US"
  },
  {
      id: "BTR",
      name: "Ryan Airport",
      area_code: "BTR",
      city_name: "Baton Rouge",
      country_code: "US"
  },
  {
      id: "BTV",
      name: "Burlington International Airport",
      area_code: "BTV",
      city_name: "Burlington",
      country_code: "US"
  },
  {
      id: "BUF",
      name: "Niagara International Airport",
      area_code: "BUF",
      city_name: "Buffalo",
      country_code: "US"
  },
  {
      id: "BUR",
      name: "BOB HOPE AIRPORT",
      area_code: "BUR",
      city_name: "Burbank",
      country_code: "US"
  },
  {
      id: "BWI",
      name: "Baltimore Airport",
      area_code: "BWI",
      city_name: "Baltimore",
      country_code: "US"
  },
  {
      id: "BZN",
      name: "Gallatin Field Airport",
      area_code: "BZN",
      city_name: "Bozeman",
      country_code: "US"
  },
  {
      id: "CAE",
      name: "Columbia Metropolitan Airport",
      area_code: "CAE",
      city_name: "Columbia",
      country_code: "US"
  },
  {
      id: "CAK",
      name: "Akron Canton Regional Airport",
      area_code: "CAK",
      city_name: "Akron/Canton",
      country_code: "US"
  },
  {
      id: "CBE",
      name: "Greater Cumberland Rgl",
      area_code: "CBE",
      city_name: "Cumberland",
      country_code: "US"
  },
  {
      id: "PPG",
      name: "Pago Pago International Airport",
      area_code: "PPG",
      city_name: "Pago Pago",
      country_code: "AS"
  },
  {
      id: "ULN",
      name: "Chinggis Khaan International Airport",
      area_code: "ULN",
      city_name: "Ulaanbaatar",
      country_code: "MN"
  },
  {
      id: "MNI",
      name: "John A. Osborne Airport",
      area_code: "MNI",
      city_name: "Montserrat",
      country_code: "MS"
  },
  {
      id: "CGP",
      name: "Chittagong Airport",
      area_code: "CGP",
      city_name: "Chittagong",
      country_code: "BD"
  },
  {
      id: "SPD",
      name: "SAIDPUR AIRPORT",
      area_code: "SPD",
      city_name: "Saidpur",
      country_code: "BD"
  },
  {
      id: "BZL",
      name: "Barisal Airpor",
      area_code: "BZL",
      city_name: "Barisal",
      country_code: "BD"
  },
  {
      id: "DAC",
      name: "Hazrat Shahjalal International Airport",
      area_code: "DAC",
      city_name: "Dhaka",
      country_code: "BD"
  },
  {
      id: "CHM",
      name: "J. Montreuil Morales",
      area_code: "CHM",
      city_name: "Chimbote",
      country_code: "PE"
  },
  {
      id: "CIX",
      name: "Chiclayo Airport",
      area_code: "CIX",
      city_name: "Chiclayo",
      country_code: "PE"
  },
  {
      id: "CJA",
      name: "Cajamarca Airport",
      area_code: "CJA",
      city_name: "Cajamarca",
      country_code: "PE"
  },
  {
      id: "CUZ",
      name: "VELAZCO ASTETE AIRPORT",
      area_code: "CUZ",
      city_name: "Cusco",
      country_code: "PE"
  },
  {
      id: "HUU",
      name: "Huanuco Airport",
      area_code: "HUU",
      city_name: "Huanuco",
      country_code: "PE"
  },
  {
      id: "IQT",
      name: "Secada Airport",
      area_code: "IQT",
      city_name: "Iquitos",
      country_code: "PE"
  },
  {
      id: "JUL",
      name: "Juliaca Airport",
      area_code: "JUL",
      city_name: "Juliaca",
      country_code: "PE"
  },
  {
      id: "LIM",
      name: "Jorge Chavez International Airport",
      area_code: "LIM",
      city_name: "Lima",
      country_code: "PE"
  },
  {
      id: "MBP",
      name: "Moyobamba",
      area_code: "MBP",
      city_name: "Moyobamba",
      country_code: "PE"
  },
  {
      id: "TCQ",
      name: "Tacna Airport",
      area_code: "TCQ",
      city_name: "Tacna",
      country_code: "PE"
  },
  {
      id: "TRU",
      name: "Trujillo Airport",
      area_code: "TRU",
      city_name: "Trujillo",
      country_code: "PE"
  },
  {
      id: "TYL",
      name: "Talara Airport",
      area_code: "TYL",
      city_name: "Talara",
      country_code: "PE"
  },
  {
      id: "AQP",
      name: "Rodriguez Ballon International Airport",
      area_code: "AQP",
      city_name: "Arequipa",
      country_code: "PE"
  },
  {
      id: "AYP",
      name: "Ayacucho Airport",
      area_code: "AYP",
      city_name: "Ayacucho",
      country_code: "PE"
  },
  {
      id: "KSA",
      name: "Kosrae Airport",
      area_code: "KSA",
      city_name: "Kosrae",
      country_code: "FM"
  },
  {
      id: "KET",
      name: "Keng Tung Airport",
      area_code: "KET",
      city_name: "Kengtung",
      country_code: "MM"
  },
  {
      id: "MDL",
      name: "Mandalay International Airport",
      area_code: "MDL",
      city_name: "Mandalay",
      country_code: "MM"
  },
  {
      id: "RGN",
      name: "Yangon International Airport",
      area_code: "RGN",
      city_name: "Yangon",
      country_code: "MM"
  },
  {
      id: "AKY",
      name: "Sittwe Airport",
      area_code: "AKY",
      city_name: "Sittwe",
      country_code: "MM"
  },
  {
      id: "BMO",
      name: "BHAMO AIRPORT",
      area_code: "BMO",
      city_name: "Bhamo",
      country_code: "MM"
  },
  {
      id: "CMN",
      name: "Mohammed Airport",
      area_code: "CMN",
      city_name: "Casablanca",
      country_code: "MA"
  },
  {
      id: "FEZ",
      name: "Saiss Airport",
      area_code: "FEZ",
      city_name: "Fes",
      country_code: "MA"
  },
  {
      id: "OUD",
      name: "Angads Airport",
      area_code: "OUD",
      city_name: "Oujda",
      country_code: "MA"
  },
  {
      id: "RAK",
      name: "MENARA AIRPORT",
      area_code: "RAK",
      city_name: "Marrakech",
      country_code: "MA"
  },
  {
      id: "RBA",
      name: "Sale Airport",
      area_code: "RBA",
      city_name: "Rabat",
      country_code: "MA"
  },
  {
      id: "TNG",
      name: "Ibn Battouta Airport",
      area_code: "TNG",
      city_name: "Tangier",
      country_code: "MA"
  },
  {
      id: "TTU",
      name: "Sania Ramel Airport",
      area_code: "TTU",
      city_name: "Tetouan",
      country_code: "MA"
  },
  {
      id: "VIL",
      name: "DAKHLA AIRPORT",
      area_code: "VIL",
      city_name: "Dakhla",
      country_code: "MA"
  },
  {
      id: "AGA",
      name: "Al Massira Airport",
      area_code: "AGA",
      city_name: "Agadir",
      country_code: "MA"
  },
  {
      id: "MCM",
      name: "MONTE CARLO HELIPORT",
      area_code: "MCM",
      city_name: "MONTE CARLO",
      country_code: "MC"
  },
  {
      id: "XMM",
      name: "MONACO",
      area_code: "XMM",
      city_name: "MONACO",
      country_code: "MC"
  },
  {
      id: "MPM",
      name: "Maputo International Airport",
      area_code: "MPM",
      city_name: "Maputo",
      country_code: "MZ"
  },
  {
      id: "UEL",
      name: "Quelimane Airport",
      area_code: "UEL",
      city_name: "Quelimane",
      country_code: "MZ"
  },
  {
      id: "APL",
      name: "NAMPULA AIRPORT",
      area_code: "APL",
      city_name: "Nampula",
      country_code: "MZ"
  },
  {
      id: "BEW",
      name: "BEIRA AIRPORT",
      area_code: "BEW",
      city_name: "Beira",
      country_code: "MZ"
  },
  {
      id: "CEN",
      name: "CIUDAD OBREGON AIRPORT",
      area_code: "CEN",
      city_name: "Ciudad Obregon",
      country_code: "MX"
  },
  {
      id: "CJS",
      name: "Abraham Gonzalez International Airport",
      area_code: "CJS",
      city_name: "Ciudad Juarez",
      country_code: "MX"
  },
  {
      id: "CME",
      name: "Ciudad del Carmen International Airport",
      area_code: "CME",
      city_name: "Ciudad del Carmen",
      country_code: "MX"
  },
  {
      id: "CPE",
      name: "CAMPECHE INTERNATIONAL AIRPORT",
      area_code: "CPE",
      city_name: "Campeche",
      country_code: "MX"
  },
  {
      id: "CUL",
      name: "Federal de Bachigualato International Airport",
      area_code: "CUL",
      city_name: "Culiacan",
      country_code: "MX"
  },
  {
      id: "CUU",
      name: "General Roberto Fierro Villalobos Int'l Airport",
      area_code: "CUU",
      city_name: "Chihuahua",
      country_code: "MX"
  },
  {
      id: "CVM",
      name: "General Pedro J. Mendez International Airport",
      area_code: "CVM",
      city_name: "Ciudad Victoria",
      country_code: "MX"
  },
  {
      id: "CZM",
      name: "Cozumel International Airport",
      area_code: "CZM",
      city_name: "Cozumel",
      country_code: "MX"
  },
  {
      id: "DGO",
      name: "Guadalupe Victoria Airport",
      area_code: "DGO",
      city_name: "Durango",
      country_code: "MX"
  },
  {
      id: "GDL",
      name: "Miguel Hidalgo International Airport",
      area_code: "GDL",
      city_name: "Guadalajara",
      country_code: "MX"
  },
  {
      id: "GYM",
      name: "Gen Jose M Yanez",
      area_code: "GYM",
      city_name: "Guaymas",
      country_code: "MX"
  },
  {
      id: "HMO",
      name: "Ignacio Pesqueira Garcia Airport",
      area_code: "HMO",
      city_name: "Hermosillo",
      country_code: "MX"
  },
  {
      id: "LAP",
      name: "LEON AIRPORT",
      area_code: "LAP",
      city_name: "La Paz",
      country_code: "MX"
  },
  {
      id: "MEX",
      name: "Juarez International Airport",
      area_code: "MEX",
      city_name: "Mexico City",
      country_code: "MX"
  },
  {
      id: "MID",
      name: "Manuel Crescencio Rejon International Airport",
      area_code: "MID",
      city_name: "Merida",
      country_code: "MX"
  },
  {
      id: "MLM",
      name: "General Francisco J.Mujica International Airport",
      area_code: "MLM",
      city_name: "Morelia",
      country_code: "MX"
  },
  {
      id: "MTY",
      name: "General Mariano Escobedo International Airport",
      area_code: "MTY",
      city_name: "Monterrey",
      country_code: "MX"
  },
  {
      id: "MZT",
      name: "General Rafael Buelna International Airport",
      area_code: "MZT",
      city_name: "Mazatlan",
      country_code: "MX"
  },
  {
      id: "OAX",
      name: "Xoxocotlan International Airport",
      area_code: "OAX",
      city_name: "Oaxaca",
      country_code: "MX"
  },
  {
      id: "PBC",
      name: "Puebla Airport",
      area_code: "PBC",
      city_name: "Puebla",
      country_code: "MX"
  },
  {
      id: "QRO",
      name: "Queretaro Intercontinental Airport",
      area_code: "QRO",
      city_name: "Queretaro",
      country_code: "MX"
  },
  {
      id: "REX",
      name: "General Lucio Blanco International Airport",
      area_code: "REX",
      city_name: "Reynosa",
      country_code: "MX"
  },
  {
      id: "SLW",
      name: "Plan de Guadalupe International Airport",
      area_code: "SLW",
      city_name: "Saltillo",
      country_code: "MX"
  },
  {
      id: "TAM",
      name: "Francisco Javier Mina International Airport",
      area_code: "TAM",
      city_name: "Tampico",
      country_code: "MX"
  },
  {
      id: "TAP",
      name: "TAPACHULA INTERNATIONAL AIRPORT",
      area_code: "TAP",
      city_name: "Tapachula",
      country_code: "MX"
  },
  {
      id: "TIJ",
      name: "General A. L. Rodriguez International Airport",
      area_code: "TIJ",
      city_name: "Tijuana",
      country_code: "MX"
  },
  {
      id: "TRC",
      name: "Francisco Sarabia International Airport",
      area_code: "TRC",
      city_name: "Torreon",
      country_code: "MX"
  },
  {
      id: "VER",
      name: "General Heriberto Jara International Airport",
      area_code: "VER",
      city_name: "Veracruz",
      country_code: "MX"
  },
  {
      id: "ZCL",
      name: "Zacatecas Airport",
      area_code: "ZCL",
      city_name: "Zacatecas",
      country_code: "MX"
  },
  {
      id: "ZLO",
      name: "Manzanillo Airport",
      area_code: "ZLO",
      city_name: "Manzanillo",
      country_code: "MX"
  },
  {
      id: "MXL",
      name: "General Rodolfo Sanchez International Airport",
      area_code: "MXL",
      city_name: "Mexicali",
      country_code: "MX"
  },
  {
      id: "ACA",
      name: "Juan N. Alvarez International Airport",
      area_code: "ACA",
      city_name: "Acapulco",
      country_code: "MX"
  },
  {
      id: "BJX",
      name: "Del Bajio International Airport",
      area_code: "BJX",
      city_name: "Leon/Guanajuato",
      country_code: "MX"
  },
  {
      id: "ERS",
      name: "EROS AIRPORT",
      area_code: "WDH",
      city_name: "Windhoek",
      country_code: "NA"
  },
  {
      id: "LUD",
      name: "Luderitz Airport",
      area_code: "LUD",
      city_name: "Luderitz",
      country_code: "NA"
  },
  {
      id: "WDH",
      name: "Hosea Kutako International Airport",
      area_code: "WDH",
      city_name: "Windhoek",
      country_code: "NA"
  },
  {
      id: "CPT",
      name: "Cape Town International Airport",
      area_code: "CPT",
      city_name: "Cape Town",
      country_code: "ZA"
  },
  {
      id: "DUR",
      name: "King Shaka International Airport",
      area_code: "DUR",
      city_name: "Durban",
      country_code: "ZA"
  },
  {
      id: "ELS",
      name: "East London Airport",
      area_code: "ELS",
      city_name: "East London",
      country_code: "ZA"
  },
  {
      id: "JNB",
      name: "O. R. Tambo International Airport",
      area_code: "JNB",
      city_name: "Johannesburg",
      country_code: "ZA"
  },
  {
      id: "PRY",
      name: "Wonderboom Airport",
      area_code: "PRY",
      city_name: "Pretoria",
      country_code: "ZA"
  },
  {
      id: "PTG",
      name: "Polokwane Airport",
      area_code: "PTG",
      city_name: "Polokwane",
      country_code: "ZA"
  },
  {
      id: "ALJ",
      name: "Alexander Bay",
      area_code: "ALJ",
      city_name: "Alexander Bay",
      country_code: "ZA"
  },
  {
      id: "BFN",
      name: "Bloemfontein International Airport",
      area_code: "BFN",
      city_name: "Bloemfontein",
      country_code: "ZA"
  },
  {
      id: "INU",
      name: "Nauru Island International Airport",
      area_code: "INU",
      city_name: "Nauru Island",
      country_code: "NR"
  },
  {
      id: "JUM",
      name: "Jumla",
      area_code: "JUM",
      city_name: "Jumla",
      country_code: "NP"
  },
  {
      id: "KTM",
      name: "Tribhuvan International Airport",
      area_code: "KTM",
      city_name: "Kathmandu",
      country_code: "NP"
  },
  {
      id: "NCR",
      name: "San Carlos",
      area_code: "NCR",
      city_name: "San Carlos",
      country_code: "NI"
  },
  {
      id: "MGA",
      name: "Augusto C. Sandino Airport",
      area_code: "MGA",
      city_name: "Managua",
      country_code: "NI"
  },
  {
      id: "BEF",
      name: "Bluefields",
      area_code: "BEF",
      city_name: "Bluefields",
      country_code: "NI"
  },
  {
      id: "NIM",
      name: "Diori Hamani International Airport",
      area_code: "NIM",
      city_name: "Niamey",
      country_code: "NE"
  },
  {
      id: "ENU",
      name: "Enugu Airport",
      area_code: "ENU",
      city_name: "Enugu",
      country_code: "NG"
  },
  {
      id: "JOS",
      name: "Jos Airport",
      area_code: "JOS",
      city_name: "Jos",
      country_code: "NG"
  },
  {
      id: "KAD",
      name: "KADUNA AIRPORT",
      area_code: "KAD",
      city_name: "Kaduna",
      country_code: "NG"
  },
  {
      id: "KAN",
      name: "Mallam Aminu Kano International Airport",
      area_code: "KAN",
      city_name: "Kano",
      country_code: "NG"
  },
  {
      id: "LOS",
      name: "Murtala Muhammed International Airport",
      area_code: "LOS",
      city_name: "Lagos",
      country_code: "NG"
  },
  {
      id: "SKO",
      name: "Sadiq Abubakar III International Airport",
      area_code: "SKO",
      city_name: "Sokoto",
      country_code: "NG"
  },
  {
      id: "ABV",
      name: "Nnamdi Azikiwe International Airport",
      area_code: "ABV",
      city_name: "Abuja",
      country_code: "NG"
  },
  {
      id: "FBU",
      name: "OSLO",
      area_code: "OSL",
      city_name: "Oslo",
      country_code: "NO"
  },
  {
      id: "HAU",
      name: "Haugesund Airport",
      area_code: "HAU",
      city_name: "Haugesund",
      country_code: "NO"
  },
  {
      id: "HFT",
      name: "Hammerfest Airport",
      area_code: "HFT",
      city_name: "Hammerfest",
      country_code: "NO"
  },
  {
      id: "KKN",
      name: "Hoybuktmoen Airport",
      area_code: "KKN",
      city_name: "Kirkenes",
      country_code: "NO"
  },
  {
      id: "NVK",
      name: "Narvik-Framnes",
      area_code: "EVE",
      city_name: "Harstad/Narvik",
      country_code: "NO"
  },
  {
      id: "OSL",
      name: "Gardermoen",
      area_code: "OSL",
      city_name: "Oslo",
      country_code: "NO"
  },
  {
      id: "OSY",
      name: "NAMSOS AIRPORT",
      area_code: "OSY",
      city_name: "Namsos",
      country_code: "NO"
  },
  {
      id: "SVG",
      name: "Stavanger Airport",
      area_code: "SVG",
      city_name: "Stavanger",
      country_code: "NO"
  },
  {
      id: "TOS",
      name: "Langnes Airport",
      area_code: "TOS",
      city_name: "Tromso",
      country_code: "NO"
  },
  {
      id: "TRD",
      name: "Vaernes Airport",
      area_code: "TRD",
      city_name: "Trondheim",
      country_code: "NO"
  },
  {
      id: "VDS",
      name: "Vadso Airport",
      area_code: "VDS",
      city_name: "Vadso",
      country_code: "NO"
  },
  {
      id: "BGO",
      name: "Flesland Airport",
      area_code: "BGO",
      city_name: "Bergen",
      country_code: "NO"
  },
  {
      id: "BOO",
      name: "BODO AIRPORT",
      area_code: "BOO",
      city_name: "Bodo",
      country_code: "NO"
  },
  {
      id: "ROR",
      name: "Roman Tmetuchl International Airport",
      area_code: "ROR",
      city_name: "Koror",
      country_code: "PW"
  },
  {
      id: "LIS",
      name: "Lisbon Airport",
      area_code: "LIS",
      city_name: "Lisbon",
      country_code: "PT"
  },
  {
      id: "OPO",
      name: "Francisco Sa Carneiro Airport",
      area_code: "OPO",
      city_name: "Porto",
      country_code: "PT"
  },
  {
      id: "PXO",
      name: "PORTO SANTO AIRPORT",
      area_code: "PXO",
      city_name: "Porto Santo",
      country_code: "PT"
  },
  {
      id: "CTS",
      name: "New Chitose Airport",
      area_code: "SPK",
      city_name: "Sapporo",
      country_code: "JP"
  },
  {
      id: "FUJ",
      name: "Fukue Airport",
      area_code: "FUJ",
      city_name: "Fukue",
      country_code: "JP"
  },
  {
      id: "FUK",
      name: "Fukuoka Airport",
      area_code: "FUK",
      city_name: "Fukuoka",
      country_code: "JP"
  },
  {
      id: "GAJ",
      name: "Yamagata Airport",
      area_code: "GAJ",
      city_name: "Yamagata",
      country_code: "JP"
  },
  {
      id: "HIJ",
      name: "Hiroshima Airport",
      area_code: "HIJ",
      city_name: "Hiroshima",
      country_code: "JP"
  },
  {
      id: "HKD",
      name: "Hakodate Airport",
      area_code: "HKD",
      city_name: "Hakodate",
      country_code: "JP"
  },
  {
      id: "HNA",
      name: "HANAMAKI AIRPORT",
      area_code: "HNA",
      city_name: "Hanamaki",
      country_code: "JP"
  },
  {
      id: "IKI",
      name: "Iki Airport",
      area_code: "IKI",
      city_name: "Iki",
      country_code: "JP"
  },
  {
      id: "ITM",
      name: "Itami Airport",
      area_code: "OSA",
      city_name: "Osaka",
      country_code: "JP"
  },
  {
      id: "KCZ",
      name: "Kochi Airport",
      area_code: "KCZ",
      city_name: "Kochi",
      country_code: "JP"
  },
  {
      id: "KIX",
      name: "Kansai International Airport",
      area_code: "OSA",
      city_name: "Osaka",
      country_code: "JP"
  },
  {
      id: "KKJ",
      name: "Kitakyushu Airport",
      area_code: "KKJ",
      city_name: "Kitakyushu",
      country_code: "JP"
  },
  {
      id: "KMI",
      name: "Miyazaki Airport",
      area_code: "KMI",
      city_name: "Miyazaki",
      country_code: "JP"
  },
  {
      id: "KMJ",
      name: "Kumamoto Airport",
      area_code: "KMJ",
      city_name: "Kumamoto",
      country_code: "JP"
  },
  {
      id: "KMQ",
      name: "Komatsu Airport",
      area_code: "KMQ",
      city_name: "Komatsu",
      country_code: "JP"
  },
  {
      id: "KOJ",
      name: "Kagoshima Airport",
      area_code: "KOJ",
      city_name: "Kagoshima",
      country_code: "JP"
  },
  {
      id: "KUH",
      name: "Kushiro Airport",
      area_code: "KUH",
      city_name: "Kushiro",
      country_code: "JP"
  },
  {
      id: "KUM",
      name: "Yakushima Airport",
      area_code: "KUM",
      city_name: "Yakushima",
      country_code: "JP"
  },
  {
      id: "KUN",
      name: "Kaunas International Airport",
      area_code: "KUN",
      city_name: "Kaunas",
      country_code: "LT"
  },
  {
      id: "MBE",
      name: "Monbetsu Airport",
      area_code: "MBE",
      city_name: "Monbetsu",
      country_code: "JP"
  },
  {
      id: "MMJ",
      name: "MATSUMOTO AIRPORT",
      area_code: "MMJ",
      city_name: "Matsumoto",
      country_code: "JP"
  },
  {
      id: "MMY",
      name: "Miyako Airport",
      area_code: "MMY",
      city_name: "Miyako",
      country_code: "JP"
  },
  {
      id: "NGO",
      name: "Chubu Centrair International Airport",
      area_code: "NGO",
      city_name: "Nagoya",
      country_code: "JP"
  },
  {
      id: "NRT",
      name: "Narita International Airport",
      area_code: "TYO",
      city_name: "Tokyo",
      country_code: "JP"
  },
  {
      id: "OBO",
      name: "OBIHIRO AIRPORT",
      area_code: "OBO",
      city_name: "Obihiro",
      country_code: "JP"
  },
  {
      id: "OIT",
      name: "Oita Airport",
      area_code: "OIT",
      city_name: "Oita",
      country_code: "JP"
  },
  {
      id: "OKA",
      name: "Naha Airport",
      area_code: "OKA",
      city_name: "Okinawa",
      country_code: "JP"
  },
  {
      id: "OKJ",
      name: "Okayama Airport",
      area_code: "OKJ",
      city_name: "Okayama",
      country_code: "JP"
  },
  {
      id: "SDJ",
      name: "Sendai Airport",
      area_code: "SDJ",
      city_name: "Sendai",
      country_code: "JP"
  },
  {
      id: "NGS",
      name: "Nagasaki Airport",
      area_code: "NGS",
      city_name: "Nagasaki",
      country_code: "JP"
  },
  {
      id: "TAK",
      name: "TAKAMATSU AIRPORT",
      area_code: "TAK",
      city_name: "Takamatsu",
      country_code: "JP"
  },
  {
      id: "TKS",
      name: "Tokushima Airport",
      area_code: "TKS",
      city_name: "Tokushima",
      country_code: "JP"
  },
  {
      id: "TNE",
      name: "Tanegashima Airport",
      area_code: "TNE",
      city_name: "Tanegashima",
      country_code: "JP"
  },
  {
      id: "TOY",
      name: "Toyama Airport",
      area_code: "TOY",
      city_name: "Toyama",
      country_code: "JP"
  },
  {
      id: "TSJ",
      name: "TSUSHIMA AIRPORT",
      area_code: "TSJ",
      city_name: "Tsushima",
      country_code: "JP"
  },
  {
      id: "TTJ",
      name: "TOTTORI AIRPORT",
      area_code: "TTJ",
      city_name: "Tottori",
      country_code: "JP"
  },
  {
      id: "UBJ",
      name: "Yamaguchi Ube Airport",
      area_code: "UBJ",
      city_name: "Ube",
      country_code: "JP"
  },
  {
      id: "YOH",
      name: "Oxford House Airport",
      area_code: "YOH",
      city_name: "Oxford House",
      country_code: "CA"
  },
  {
      id: "KIJ",
      name: "Niigata Airport",
      area_code: "KIJ",
      city_name: "Niigata",
      country_code: "JP"
  },
  {
      id: "QFY",
      name: "FUKUYAMA",
      area_code: "QFY",
      city_name: "FUKUYAMA",
      country_code: "JP"
  },
  {
      id: "QUT",
      name: "UTSUNOMIYA",
      area_code: "QUT",
      city_name: "UTSUNOMIYA",
      country_code: "JP"
  },
  {
      id: "UKB",
      name: "Kobe Airport",
      area_code: "OSA",
      city_name: "Osaka",
      country_code: "JP"
  },
  {
      id: "UKY",
      name: "KYOTO AIRPORT",
      area_code: "UKY",
      city_name: "KYOTO",
      country_code: "JP"
  },
  {
      id: "AKJ",
      name: "Asahikawa Airport",
      area_code: "AKJ",
      city_name: "Asahikawa",
      country_code: "JP"
  },
  {
      id: "AOJ",
      name: "AOMORI AIRPORT",
      area_code: "AOJ",
      city_name: "Aomori",
      country_code: "JP"
  },
  {
      id: "AXT",
      name: "Akita Airport",
      area_code: "AXT",
      city_name: "Akita",
      country_code: "JP"
  },
  {
      id: "GEV",
      name: "GALLIVARE AIRPORT",
      area_code: "GEV",
      city_name: "Gallivare",
      country_code: "SE"
  },
  {
      id: "GVX",
      name: "Sandviken",
      area_code: "GVX",
      city_name: "Gavle",
      country_code: "SE"
  },
  {
      id: "KRN",
      name: "KIRUNA AIRPORT",
      area_code: "KRN",
      city_name: "Kiruna",
      country_code: "SE"
  },
  {
      id: "KSD",
      name: "Karlstad Airport",
      area_code: "KSD",
      city_name: "Karlstad",
      country_code: "SE"
  },
  {
      id: "LLA",
      name: "KALLAX AIRPORT",
      area_code: "LLA",
      city_name: "Lulea",
      country_code: "SE"
  },
  {
      id: "MMA",
      name: "MALMO AIRPORT",
      area_code: "MMA",
      city_name: "Malmo",
      country_code: "SE"
  },
  {
      id: "ORB",
      name: "Orebro Airport",
      area_code: "ORB",
      city_name: "Orebro",
      country_code: "SE"
  },
  {
      id: "OSD",
      name: "Are Ostersund Airport",
      area_code: "OSD",
      city_name: "Are/Ostersund",
      country_code: "SE"
  },
  {
      id: "OSK",
      name: "Oskarshamn",
      area_code: "OSK",
      city_name: "Oskarshamn",
      country_code: "SE"
  },
  {
      id: "SDL",
      name: "Timra Airport",
      area_code: "SDL",
      city_name: "Sundsvall/Harnosand",
      country_code: "SE"
  },
  {
      id: "SFT",
      name: "SKELLEFTEA AIRPORT",
      area_code: "SFT",
      city_name: "Skelleftea",
      country_code: "SE"
  },
  {
      id: "SOO",
      name: "Soderhamn",
      area_code: "SOO",
      city_name: "Zug Ch",
      country_code: "CH"
  },
  {
      id: "THN",
      name: "Trollhattan Airport",
      area_code: "THN",
      city_name: "Trollhattan/Vanersborg",
      country_code: "SE"
  },
  {
      id: "VBY",
      name: "VISBY AIRPORT",
      area_code: "VBY",
      city_name: "Visby",
      country_code: "SE"
  },
  {
      id: "VST",
      name: "Vasteras Airport",
      area_code: "STO",
      city_name: "Stockholm",
      country_code: "SE"
  },
  {
      id: "GOT",
      name: "Landvetter Airport",
      area_code: "GOT",
      city_name: "Goteborg",
      country_code: "SE"
  },
  {
      id: "ARN",
      name: "Arlanda Airport",
      area_code: "STO",
      city_name: "Stockholm",
      country_code: "SE"
  },
  {
      id: "GVA",
      name: "Geneva Airport",
      area_code: "GVA",
      city_name: "Geneva",
      country_code: "CH"
  },
  {
      id: "ZRH",
      name: "Zurich Airport",
      area_code: "ZRH",
      city_name: "Zurich",
      country_code: "CH"
  },
  {
      id: "BRN",
      name: "Belp Airport",
      area_code: "BRN",
      city_name: "Bern",
      country_code: "CH"
  },
  {
      id: "BSL",
      name: "EuroAirport Swiss Basel Mulhouse Freiburg",
      area_code: "EAP",
      city_name: "Basel/Mulhouse",
      country_code: "CH"
  },
  {
      id: "SAL",
      name: "El Salvador International Airport",
      area_code: "SAL",
      city_name: "San Salvador",
      country_code: "SV"
  },
  {
      id: "BEG",
      name: "Nikola Tesla Airport",
      area_code: "BEG",
      city_name: "Belgrade",
      country_code: "RS"
  },
  {
      id: "FNA",
      name: "Lungi International Airport",
      area_code: "FNA",
      city_name: "Freetown",
      country_code: "SL"
  },
  {
      id: "DKR",
      name: "Leopold Sedar Senghor Airport",
      area_code: "DKR",
      city_name: "Dakar",
      country_code: "SN"
  },
  {
      id: "XLS",
      name: "St-Louis",
      area_code: "XLS",
      city_name: "St-Louis",
      country_code: "SN"
  },
  {
      id: "ZIG",
      name: "Ziguinchor Airport",
      area_code: "ZIG",
      city_name: "Ziguinchor",
      country_code: "SN"
  },
  {
      id: "LCA",
      name: "Larnaca Airport",
      area_code: "LCA",
      city_name: "Larnaca",
      country_code: "CY"
  },
  {
      id: "NIC",
      name: "Nicosia",
      area_code: "NIC",
      city_name: "Nicosia",
      country_code: "CY"
  },
  {
      id: "DHA",
      name: "King Abdulaziz",
      area_code: "DHA",
      city_name: "Dhahran",
      country_code: "SA"
  },
  {
      id: "HAS",
      name: "HAIL AIRPORT",
      area_code: "HAS",
      city_name: "Hail",
      country_code: "SA"
  },
  {
      id: "JED",
      name: "King Abdulaziz International Airport",
      area_code: "JED",
      city_name: "Jeddah",
      country_code: "SA"
  },
  {
      id: "RUH",
      name: "King Khalid International Airport",
      area_code: "RUH",
      city_name: "Riyadh",
      country_code: "SA"
  },
  {
      id: "TUU",
      name: "Tabuk Airport",
      area_code: "TUU",
      city_name: "Tabuk",
      country_code: "SA"
  },
  {
      id: "YNB",
      name: "Yanbu Airport",
      area_code: "YNB",
      city_name: "Yanbu al Bahr",
      country_code: "SA"
  },
  {
      id: "AHB",
      name: "ABHA AIRPORT",
      area_code: "AHB",
      city_name: "Abha",
      country_code: "SA"
  },
  {
      id: "BHH",
      name: "BISHA AIRPORT",
      area_code: "BHH",
      city_name: "Bisha",
      country_code: "SA"
  },
  {
      id: "TMS",
      name: "Sao Tome International Airport",
      area_code: "TMS",
      city_name: "Sao Tome",
      country_code: "ST"
  },
  {
      id: "SLU",
      name: "George F.L. Charles",
      area_code: "SLU",
      city_name: "St Lucia",
      country_code: "LC"
  },
  {
      id: "CMB",
      name: "Bandaranaike International Airport",
      area_code: "CMB",
      city_name: "Colombo",
      country_code: "LK"
  },
  {
      id: "BTS",
      name: "M. R. Stefanik Airport",
      area_code: "BTS",
      city_name: "Bratislava",
      country_code: "SK"
  },
  {
      id: "LJU",
      name: "Ljubljana Airport",
      area_code: "LJU",
      city_name: "Ljubljana",
      country_code: "SI"
  },
  {
      id: "QMN",
      name: "MBABANE",
      area_code: "QMN",
      city_name: "MBABANE",
      country_code: "SZ"
  },
  {
      id: "DOG",
      name: "Dongola",
      area_code: "DOG",
      city_name: "Dongola",
      country_code: "SD"
  },
  {
      id: "EBD",
      name: "EL OBEID AIRPORT",
      area_code: "EBD",
      city_name: "El Obeid",
      country_code: "SD"
  },
  {
      id: "EGN",
      name: "GENEINA AIRPORT",
      area_code: "EGN",
      city_name: "Geneina",
      country_code: "SD"
  },
  {
      id: "KRT",
      name: "Khartoum International Airport",
      area_code: "KRT",
      city_name: "Khartoum",
      country_code: "SD"
  },
  {
      id: "ELF",
      name: "EL FASHER AIRPORT",
      area_code: "ELF",
      city_name: "El Fasher",
      country_code: "SD"
  },
  {
      id: "PZU",
      name: "Port Sudan New International Airport",
      area_code: "PZU",
      city_name: "Port Sudan",
      country_code: "SD"
  },
  {
      id: "ATB",
      name: "Atbara",
      area_code: "ATB",
      city_name: "Atbara",
      country_code: "SD"
  },
  {
      id: "PBM",
      name: "Johan Adolf Pengel International Airport",
      area_code: "PBM",
      city_name: "Paramaribo",
      country_code: "SR"
  },
  {
      id: "CHY",
      name: "CHOISEUL BAY AIRPORT",
      area_code: "CHY",
      city_name: "Choiseul Bay",
      country_code: "SB"
  },
  {
      id: "HIR",
      name: "Honiara International Airport",
      area_code: "HIR",
      city_name: "Honiara",
      country_code: "SB"
  },
  {
      id: "MGQ",
      name: "Aden Adde International Airport",
      area_code: "MGQ",
      city_name: "Mogadishu",
      country_code: "SO"
  },
  {
      id: "BBO",
      name: "BERBERA AIRPORT",
      area_code: "BBO",
      city_name: "Berbera",
      country_code: "SO"
  },
  {
      id: "DYU",
      name: "Dushanbe Airport",
      area_code: "DYU",
      city_name: "Dushanbe",
      country_code: "TJ"
  },
  {
      id: "CEI",
      name: "Chiang Rai Airport",
      area_code: "CEI",
      city_name: "Chiang Rai",
      country_code: "TH"
  },
  {
      id: "CNX",
      name: "Chiang Mai International Airport",
      area_code: "CNX",
      city_name: "Chiang Mai",
      country_code: "TH"
  },
  {
      id: "HKT",
      name: "Phuket International Airport",
      area_code: "HKT",
      city_name: "Phuket",
      country_code: "TH"
  },
  {
      id: "KKC",
      name: "Khon Kaen Airport",
      area_code: "KKC",
      city_name: "Khon Kaen",
      country_code: "TH"
  },
  {
      id: "LPT",
      name: "LAMPANG AIRPORT",
      area_code: "LPT",
      city_name: "Lampang",
      country_code: "TH"
  },
  {
      id: "SNO",
      name: "SAKON NAKHON AIRPORT",
      area_code: "SNO",
      city_name: "Sakon Nakhon",
      country_code: "TH"
  },
  {
      id: "UBP",
      name: "Ubon Ratchathani Airport",
      area_code: "UBP",
      city_name: "Ubon Ratchathani",
      country_code: "TH"
  },
  {
      id: "USM",
      name: "Samui International Airport",
      area_code: "USM",
      city_name: "Ko Samui",
      country_code: "TH"
  },
  {
      id: "BKK",
      name: "Suvarnabhumi International Airport",
      area_code: "BKK",
      city_name: "Bangkok",
      country_code: "TH"
  },
  {
      id: "JRO",
      name: "Kilimanjaro International Airport",
      area_code: "JRO",
      city_name: "Kilimanjaro",
      country_code: "TZ"
  },
  {
      id: "LDI",
      name: "Kikwetu",
      area_code: "LDI",
      city_name: "Lindi",
      country_code: "TZ"
  },
  {
      id: "TBO",
      name: "TABORA AIRPORT",
      area_code: "TBO",
      city_name: "Tabora",
      country_code: "TZ"
  },
  {
      id: "TKQ",
      name: "KIGOMA AIRPORT",
      area_code: "TKQ",
      city_name: "Kigoma",
      country_code: "TZ"
  },
  {
      id: "ZNZ",
      name: "Zanzibar Airport",
      area_code: "ZNZ",
      city_name: "Zanzibar",
      country_code: "TZ"
  },
  {
      id: "DAR",
      name: "Julius Nyerere International Airport",
      area_code: "DAR",
      city_name: "Dar es Salaam",
      country_code: "TZ"
  },
  {
      id: "TAB",
      name: "A.N.R. Robinson International Airport",
      area_code: "TAB",
      city_name: "Tobago",
      country_code: "TT"
  },
  {
      id: "SFA",
      name: "Sfax El Maou Airport",
      area_code: "SFA",
      city_name: "Sfax",
      country_code: "TN"
  },
  {
      id: "TOE",
      name: "Nefta Airport",
      area_code: "TOE",
      city_name: "Tozeur",
      country_code: "TN"
  },
  {
      id: "TUN",
      name: "Carthage International Airport",
      area_code: "TUN",
      city_name: "Tunis",
      country_code: "TN"
  },
  {
      id: "FUN",
      name: "Funafuti Atol International Airport",
      area_code: "FUN",
      city_name: "Funafuti",
      country_code: "TV"
  },
  {
      id: "DIY",
      name: "Diyarbakir Airport",
      area_code: "DIY",
      city_name: "Diyarbakir",
      country_code: "TR"
  },
  {
      id: "ERZ",
      name: "Erzurum Airport",
      area_code: "ERZ",
      city_name: "Erzurum",
      country_code: "TR"
  },
  {
      id: "ESB",
      name: "Esenboga Airport",
      area_code: "ANK",
      city_name: "Ankara",
      country_code: "TR"
  },
  {
      id: "IST",
      name: "Istanbul Airport",
      area_code: "IST",
      city_name: "Istanbul",
      country_code: "TR"
  },
  {
      id: "MLX",
      name: "Malatya Erhac Airport",
      area_code: "MLX",
      city_name: "Malatya",
      country_code: "TR"
  },
  {
      id: "SSX",
      name: "Singita Safari Lodge",
      area_code: "SSX",
      city_name: "Singita Safari Lodge",
      country_code: "TR"
  },
  {
      id: "VAN",
      name: "Ferit Melen Airport",
      area_code: "VAN",
      city_name: "Van",
      country_code: "TR"
  },
  {
      id: "BSA",
      name: "Bossaso Airport",
      area_code: "BSA",
      city_name: "Bosaso",
      country_code: "SO"
  },
  {
      id: "GZT",
      name: "Oguzeli Airport",
      area_code: "GZT",
      city_name: "Gaziantep",
      country_code: "TR"
  },
  {
      id: "ADA",
      name: "Sakirpasa Airport",
      area_code: "ADA",
      city_name: "Adana",
      country_code: "TR"
  },
  {
      id: "ADB",
      name: "Adnan Menderes Airport",
      area_code: "IZM",
      city_name: "Izmir",
      country_code: "TR"
  },
  {
      id: "ASR",
      name: "Erkilet Airport",
      area_code: "ASR",
      city_name: "Kayseri",
      country_code: "TR"
  },
  {
      id: "AYT",
      name: "Antalya Airport",
      area_code: "AYT",
      city_name: "Antalya",
      country_code: "TR"
  },
  {
      id: "ASB",
      name: "Ashgabat Airport",
      area_code: "ASB",
      city_name: "Ashgabat",
      country_code: "TM"
  },
  {
      id: "TOH",
      name: "Torres Airport",
      area_code: "TOH",
      city_name: "Torres Islands",
      country_code: "VU"
  },
  {
      id: "VLI",
      name: "Bauerfield Airport",
      area_code: "VLI",
      city_name: "Port Vila",
      country_code: "VU"
  },
  {
      id: "GUA",
      name: "La Aurora International Airport",
      area_code: "GUA",
      city_name: "Guatemala City",
      country_code: "GT"
  },
  {
      id: "CCS",
      name: "Simon Bolivar International Airport",
      area_code: "CCS",
      city_name: "Caracas",
      country_code: "VE"
  },
  {
      id: "CUM",
      name: "Cumana Airport",
      area_code: "CUM",
      city_name: "Cumana",
      country_code: "VE"
  },
  {
      id: "CZE",
      name: "Jose Leonardo Chirinos",
      area_code: "CZE",
      city_name: "Villars",
      country_code: "CH"
  },
  {
      id: "STD",
      name: "Santo Domingo Airport",
      area_code: "STD",
      city_name: "Santo Domingo",
      country_code: "VE"
  },
  {
      id: "SVZ",
      name: "Juan V. Gomez Intl",
      area_code: "SVZ",
      city_name: "San Antonio Tachira",
      country_code: "VE"
  },
  {
      id: "VLN",
      name: "Arturo Michelena Airport",
      area_code: "VLN",
      city_name: "Valencia",
      country_code: "VE"
  },
  {
      id: "MAR",
      name: "La Chinita Airport",
      area_code: "MAR",
      city_name: "Maracaibo",
      country_code: "VE"
  },
  {
      id: "BNS",
      name: "BARINAS AIRPORT",
      area_code: "BNS",
      city_name: "Barinas",
      country_code: "VE"
  },
  {
      id: "MRD",
      name: "Alberto Carnevalli",
      area_code: "MRD",
      city_name: "Merida",
      country_code: "VE"
  },
  {
      id: "SFD",
      name: "Las Flecheras",
      area_code: "SFD",
      city_name: "San Fernando Apure",
      country_code: "VE"
  },
  {
      id: "AGV",
      name: "Oswaldo Guevara Mujica",
      area_code: "AGV",
      city_name: "Acarigua",
      country_code: "VE"
  },
  {
      id: "BLA",
      name: "General Jose Antonio Anzoategui Airport",
      area_code: "BLA",
      city_name: "Barcelona",
      country_code: "VE"
  },
  {
      id: "BRM",
      name: "Barquisimeto Airport",
      area_code: "BRM",
      city_name: "Barquisimeto",
      country_code: "VE"
  },
  {
      id: "CBL",
      name: "Ciudad Bolivar Airport",
      area_code: "CBL",
      city_name: "Ciudad Bolivar",
      country_code: "VE"
  },
  {
      id: "BWN",
      name: "BRUNEI INTERNATIONAL AIRPORT",
      area_code: "BWN",
      city_name: "Bandar Seri Begawan",
      country_code: "BN"
  },
  {
      id: "EBB",
      name: "Entebbe International Airport",
      area_code: "EBB",
      city_name: "Entebbe",
      country_code: "UG"
  },
  {
      id: "JIN",
      name: "Jinja Airport",
      area_code: "JIN",
      city_name: "Jinja",
      country_code: "UG"
  },
  {
      id: "IEV",
      name: "Zhulhany International Airport",
      area_code: "IEV",
      city_name: "Kiev",
      country_code: "UA"
  },
  {
      id: "KBP",
      name: "Boryspil International Airport",
      area_code: "IEV",
      city_name: "Kiev",
      country_code: "UA"
  },
  {
      id: "KHE",
      name: "Kherson Airport",
      area_code: "KHE",
      city_name: "Kherson",
      country_code: "UA"
  },
  {
      id: "MPW",
      name: "International",
      area_code: "MPW",
      city_name: "Mariupol",
      country_code: "UA"
  },
  {
      id: "SIP",
      name: "Simferopol International Airport",
      area_code: "SIP",
      city_name: "Simferopol",
      country_code: "UA"
  },
  {
      id: "MVD",
      name: "Carrasco International Airport",
      area_code: "MVD",
      city_name: "Montevideo",
      country_code: "UY"
  },
  {
      id: "FEG",
      name: "Fergana Airport",
      area_code: "FEG",
      city_name: "Fergana",
      country_code: "UZ"
  },
  {
      id: "KSQ",
      name: "Karshi Airport",
      area_code: "KSQ",
      city_name: "Karshi",
      country_code: "UZ"
  },
  {
      id: "SKD",
      name: "Samarkand International Airport",
      area_code: "SKD",
      city_name: "Samarkand",
      country_code: "UZ"
  },
  {
      id: "TAS",
      name: "Tashkent International Airport",
      area_code: "TAS",
      city_name: "Tashkent",
      country_code: "UZ"
  },
  {
      id: "AZN",
      name: "ANDIZHAN AIRPORT",
      area_code: "AZN",
      city_name: "Andizhan",
      country_code: "UZ"
  },
  {
      id: "BHK",
      name: "Bukhara International Airport",
      area_code: "BHK",
      city_name: "Bukhara",
      country_code: "UZ"
  },
  {
      id: "IBZ",
      name: "Ibiza Airport",
      area_code: "IBZ",
      city_name: "Ibiza",
      country_code: "ES"
  },
  {
      id: "MLN",
      name: "MELILLA AIRPORT",
      area_code: "MLN",
      city_name: "Melilla",
      country_code: "ES"
  },
  {
      id: "PMI",
      name: "Palma de Mallorca Airport",
      area_code: "PMI",
      city_name: "Palma de Mallorca",
      country_code: "ES"
  },
  {
      id: "MAD",
      name: "Barajas Airport",
      area_code: "MAD",
      city_name: "Madrid",
      country_code: "ES"
  },
  {
      id: "VIT",
      name: "Vitoria Airport",
      area_code: "VIT",
      city_name: "Vitoria",
      country_code: "ES"
  },
  {
      id: "VLC",
      name: "Valencia Airport",
      area_code: "VLC",
      city_name: "Valencia",
      country_code: "ES"
  },
  {
      id: "VLL",
      name: "Valladolid Airport",
      area_code: "VLL",
      city_name: "Valladolid",
      country_code: "ES"
  },
  {
      id: "ZAZ",
      name: "Zaragoza Airport",
      area_code: "ZAZ",
      city_name: "Zaragoza",
      country_code: "ES"
  },
  {
      id: "SVQ",
      name: "Seville Airport",
      area_code: "SVQ",
      city_name: "Sevilla",
      country_code: "ES"
  },
  {
      id: "AGP",
      name: "Malaga Airport",
      area_code: "AGP",
      city_name: "Malaga",
      country_code: "ES"
  },
  {
      id: "ALC",
      name: "Alicante Airport",
      area_code: "ALC",
      city_name: "Alicante",
      country_code: "ES"
  },
  {
      id: "BCN",
      name: "Barcelona Airport",
      area_code: "BCN",
      city_name: "Barcelona",
      country_code: "ES"
  },
  {
      id: "BIO",
      name: "Bilbao Airport",
      area_code: "BIO",
      city_name: "Bilbao",
      country_code: "ES"
  },
  {
      id: "BJZ",
      name: "Badajoz Airport",
      area_code: "BJZ",
      city_name: "Badajoz",
      country_code: "ES"
  },
  {
      id: "APW",
      name: "Faleolo International Airport",
      area_code: "APW",
      city_name: "Apia",
      country_code: "WS"
  },
  {
      id: "ATH",
      name: "Eleftherios Venizelos International Airport",
      area_code: "ATH",
      city_name: "Athens",
      country_code: "GR"
  },
  {
      id: "SIN",
      name: "Changi Airport",
      area_code: "SIN",
      city_name: "Singapore",
      country_code: "SG"
  },
  {
      id: "NOU",
      name: "La Tontouta International Airport",
      area_code: "NOU",
      city_name: "Noumea",
      country_code: "NC"
  },
  {
      id: "CHC",
      name: "Christchurch International Airport",
      area_code: "CHC",
      city_name: "Christchurch",
      country_code: "NZ"
  },
  {
      id: "CHT",
      name: "Karewa Airport",
      area_code: "CHT",
      city_name: "Chatham Island",
      country_code: "NZ"
  },
  {
      id: "DUD",
      name: "Dunedin Airport",
      area_code: "DUD",
      city_name: "Dunedin",
      country_code: "NZ"
  },
  {
      id: "GBZ",
      name: "Great Barrier Island",
      area_code: "GBZ",
      city_name: "Great Barrier Island",
      country_code: "NZ"
  },
  {
      id: "GIS",
      name: "GISBORNE AIRPORT",
      area_code: "GIS",
      city_name: "Gisborne",
      country_code: "NZ"
  },
  {
      id: "HKK",
      name: "HOKITIKA AIRPORT",
      area_code: "HKK",
      city_name: "Hokitika",
      country_code: "NZ"
  },
  {
      id: "KAT",
      name: "Kaitaia",
      area_code: "KAT",
      city_name: "Kaitaia",
      country_code: "NZ"
  },
  {
      id: "KBZ",
      name: "Kaikoura",
      area_code: "KBZ",
      city_name: "Kaikoura",
      country_code: "NZ"
  },
  {
      id: "NSN",
      name: "Nelson Airport",
      area_code: "NSN",
      city_name: "Nelson",
      country_code: "NZ"
  },
  {
      id: "ROT",
      name: "Rotorua Airport",
      area_code: "ROT",
      city_name: "Rotorua",
      country_code: "NZ"
  },
  {
      id: "TIU",
      name: "Richard Pearse Airport",
      area_code: "TIU",
      city_name: "Timaru",
      country_code: "NZ"
  },
  {
      id: "TRG",
      name: "TAURANGA AIRPORT",
      area_code: "TRG",
      city_name: "Tauranga",
      country_code: "NZ"
  },
  {
      id: "TUO",
      name: "TAUPO AIRPORT",
      area_code: "TUO",
      city_name: "Taupo",
      country_code: "NZ"
  },
  {
      id: "WLG",
      name: "Wellington International Airport",
      area_code: "WLG",
      city_name: "Wellington",
      country_code: "NZ"
  },
  {
      id: "WSZ",
      name: "WESTPORT AIRPORT",
      area_code: "WSZ",
      city_name: "Westport",
      country_code: "NZ"
  },
  {
      id: "ZQN",
      name: "Queenstown Airport",
      area_code: "ZQN",
      city_name: "Queenstown",
      country_code: "NZ"
  },
  {
      id: "AKL",
      name: "Auckland International Airport",
      area_code: "AKL",
      city_name: "Auckland",
      country_code: "NZ"
  },
  {
      id: "BHE",
      name: "Woodbourne Airport",
      area_code: "BHE",
      city_name: "Blenheim",
      country_code: "NZ"
  },
  {
      id: "BUD",
      name: "Liszt Ferenc International Airport",
      area_code: "BUD",
      city_name: "Budapest",
      country_code: "HU"
  },
  {
      id: "DAM",
      name: "Damascus International Airport",
      area_code: "DAM",
      city_name: "Damascus",
      country_code: "SY"
  },
  {
      id: "ALP",
      name: "International",
      area_code: "ALP",
      city_name: "Aleppo",
      country_code: "SY"
  },
  {
      id: "KIN",
      name: "Norman Manley International Airport",
      area_code: "KIN",
      city_name: "Kingston",
      country_code: "JM"
  },
  {
      id: "MBJ",
      name: "SANGSTER INTERNATIONAL AIRPORT",
      area_code: "MBJ",
      city_name: "Montego Bay",
      country_code: "JM"
  },
  {
      id: "EVN",
      name: "Zvartnots International Airport",
      area_code: "EVN",
      city_name: "Yerevan",
      country_code: "AM"
  },
  {
      id: "SAH",
      name: "Sana'a International Airport",
      area_code: "SAH",
      city_name: "Sana'a",
      country_code: "YE"
  },
  {
      id: "SCT",
      name: "Socotra",
      area_code: "SCT",
      city_name: "Socotra",
      country_code: "YE"
  },
  {
      id: "ADE",
      name: "Aden International Airport",
      area_code: "ADE",
      city_name: "Aden",
      country_code: "YE"
  },
  {
      id: "BGW",
      name: "Baghdad International Airport",
      area_code: "BGW",
      city_name: "Baghdad",
      country_code: "IQ"
  },
  {
      id: "BSR",
      name: "Basrah International Airport",
      area_code: "BSR",
      city_name: "Basrah",
      country_code: "IQ"
  },
  {
      id: "KER",
      name: "KERMAN AIRPORT",
      area_code: "KER",
      city_name: "Kerman",
      country_code: "IR"
  },
  {
      id: "MHD",
      name: "Mashhad Airport",
      area_code: "MHD",
      city_name: "Mashhad",
      country_code: "IR"
  },
  {
      id: "SYZ",
      name: "Shiraz International Airport",
      area_code: "SYZ",
      city_name: "Shiraz",
      country_code: "IR"
  },
  {
      id: "TBZ",
      name: "Tabriz International Airport",
      area_code: "TBZ",
      city_name: "Tabriz",
      country_code: "IR"
  },
  {
      id: "THR",
      name: "Mehrabad International Airport",
      area_code: "THR",
      city_name: "Tehran",
      country_code: "IR"
  },
  {
      id: "ZBR",
      name: "Chah-Bahar Airport",
      area_code: "ZBR",
      city_name: "Chah Bahar",
      country_code: "IR"
  },
  {
      id: "ABD",
      name: "ABADAN AIRPORT",
      area_code: "ABD",
      city_name: "Abadan",
      country_code: "IR"
  },
  {
      id: "ADU",
      name: "ARDABIL AIRPORT",
      area_code: "ADU",
      city_name: "Ardabil",
      country_code: "IR"
  },
  {
      id: "AZD",
      name: "Yazd Airport",
      area_code: "AZD",
      city_name: "Yazd",
      country_code: "IR"
  },
  {
      id: "BND",
      name: "Bandar Abbas International Airport",
      area_code: "BND",
      city_name: "Bandar Abbas",
      country_code: "IR"
  },
  {
      id: "HEA",
      name: "HERAT AIRPORT",
      area_code: "HEA",
      city_name: "Herat",
      country_code: "AF"
  },
  {
      id: "TLV",
      name: "Ben Gurion International Airport",
      area_code: "TLV",
      city_name: "Tel Aviv-Yafo",
      country_code: "IL"
  },
  {
      id: "CIA",
      name: "Ciampino Airport",
      area_code: "ROM",
      city_name: "Rome",
      country_code: "IT"
  },
  {
      id: "CTA",
      name: "Fontanarossa Airport",
      area_code: "CTA",
      city_name: "Catania",
      country_code: "IT"
  },
  {
      id: "FCO",
      name: "FIUMICINO AIRPORT",
      area_code: "ROM",
      city_name: "Rome",
      country_code: "IT"
  },
  {
      id: "FLR",
      name: "Peretola Airport",
      area_code: "FLR",
      city_name: "Florence",
      country_code: "IT"
  },
  {
      id: "GOA",
      name: "Cristoforo Colombo Airport",
      area_code: "GOA",
      city_name: "Genoa",
      country_code: "IT"
  },
  {
      id: "LIN",
      name: "Linate Airport",
      area_code: "MIL",
      city_name: "Milan",
      country_code: "IT"
  },
  {
      id: "MXP",
      name: "Malpensa Airport",
      area_code: "MIL",
      city_name: "Milan",
      country_code: "IT"
  },
  {
      id: "NAP",
      name: "Naples Airport",
      area_code: "NAP",
      city_name: "Naples",
      country_code: "IT"
  },
  {
      id: "PMF",
      name: "Parma Airport",
      area_code: "MIL",
      city_name: "Milan",
      country_code: "IT"
  },
  {
      id: "PSA",
      name: "Galileo Galilei Airport",
      area_code: "PSA",
      city_name: "Pisa",
      country_code: "IT"
  },
  {
      id: "RMI",
      name: "Miramare Airport",
      area_code: "RMI",
      city_name: "Rimini",
      country_code: "IT"
  },
  {
      id: "TRN",
      name: "Turin Airport",
      area_code: "TRN",
      city_name: "Turin",
      country_code: "IT"
  },
  {
      id: "TRS",
      name: "Friuli Venezia Giulia Airport",
      area_code: "TRS",
      city_name: "Trieste",
      country_code: "IT"
  },
  {
      id: "VCE",
      name: "Marco Polo Airport",
      area_code: "VCE",
      city_name: "Venice",
      country_code: "IT"
  },
  {
      id: "PMO",
      name: "Punta Raisi Airport",
      area_code: "PMO",
      city_name: "Palermo",
      country_code: "IT"
  },
  {
      id: "AOI",
      name: "Falconara Airport",
      area_code: "AOI",
      city_name: "Ancona",
      country_code: "IT"
  },
  {
      id: "BDS",
      name: "Casale Airport",
      area_code: "BDS",
      city_name: "Brindisi",
      country_code: "IT"
  },
  {
      id: "BLQ",
      name: "Guglielmo Marconi Airport",
      area_code: "BLQ",
      city_name: "Bologna",
      country_code: "IT"
  },
  {
      id: "BRI",
      name: "Palese Airport",
      area_code: "BRI",
      city_name: "Bari",
      country_code: "IT"
  },
  {
      id: "CAG",
      name: "ELMAS AIRPORT",
      area_code: "CAG",
      city_name: "Cagliari",
      country_code: "IT"
  },
  {
      id: "CCU",
      name: "Kolkata Airport",
      area_code: "CCU",
      city_name: "Kolkata",
      country_code: "IN"
  },
  {
      id: "CJB",
      name: "Coimbatore International Airport",
      area_code: "CJB",
      city_name: "Coimbatore",
      country_code: "IN"
  },
  {
      id: "COK",
      name: "Kochi International Airport",
      area_code: "COK",
      city_name: "Kochi",
      country_code: "IN"
  },
  {
      id: "DEL",
      name: "Indira Gandhi International Airport",
      area_code: "DEL",
      city_name: "Delhi",
      country_code: "IN"
  },
  {
      id: "GAU",
      name: "Gopinath Bordoloi International Airport",
      area_code: "GAU",
      city_name: "Guwahati",
      country_code: "IN"
  },
  {
      id: "GOI",
      name: "Dabolim Airport",
      area_code: "GOI",
      city_name: "Goa",
      country_code: "IN"
  },
  {
      id: "GWL",
      name: "Gwalior Airport",
      area_code: "GWL",
      city_name: "Gwalior",
      country_code: "IN"
  },
  {
      id: "IDR",
      name: "Devi Ahilyabai Holkar Airport",
      area_code: "IDR",
      city_name: "Indore",
      country_code: "IN"
  },
  {
      id: "IMF",
      name: "Imphal Airport",
      area_code: "IMF",
      city_name: "Imphal",
      country_code: "IN"
  },
  {
      id: "IXD",
      name: "Allahabad Airport",
      area_code: "IXD",
      city_name: "Allahabad",
      country_code: "IN"
  },
  {
      id: "IXE",
      name: "Mangalore Airport",
      area_code: "IXE",
      city_name: "Mangalore",
      country_code: "IN"
  },
  {
      id: "IXJ",
      name: "SATWARI AIRPORT",
      area_code: "IXJ",
      city_name: "Jammu",
      country_code: "IN"
  },
  {
      id: "IXM",
      name: "Madurai Airport",
      area_code: "IXM",
      city_name: "Madurai",
      country_code: "IN"
  },
  {
      id: "IXR",
      name: "Birsa Munda Airport",
      area_code: "IXR",
      city_name: "Ranchi",
      country_code: "IN"
  },
  {
      id: "IXS",
      name: "Kumbhirgram Airport",
      area_code: "IXS",
      city_name: "Silchar",
      country_code: "IN"
  },
  {
      id: "IXZ",
      name: "PORT BLAIR AIRPORT",
      area_code: "IXZ",
      city_name: "Port Blair",
      country_code: "IN"
  },
  {
      id: "JAI",
      name: "Jaipur Airport",
      area_code: "JAI",
      city_name: "Jaipur",
      country_code: "IN"
  },
  {
      id: "JDH",
      name: "JODHPUR AIRPORT",
      area_code: "JDH",
      city_name: "Jodhpur",
      country_code: "IN"
  },
  {
      id: "JGA",
      name: "Govardhanpur Airport",
      area_code: "JGA",
      city_name: "Jamnagar",
      country_code: "IN"
  },
  {
      id: "JLR",
      name: "JABALPUR AIRPORT",
      area_code: "JLR",
      city_name: "Jabalpur",
      country_code: "IN"
  },
  {
      id: "LKO",
      name: "Amausi Airport",
      area_code: "LKO",
      city_name: "Lucknow",
      country_code: "IN"
  },
  {
      id: "MAA",
      name: "Chennai International Airport",
      area_code: "MAA",
      city_name: "Chennai",
      country_code: "IN"
  },
  {
      id: "PAT",
      name: "Patna Airport",
      area_code: "PAT",
      city_name: "Patna",
      country_code: "IN"
  },
  {
      id: "RAJ",
      name: "Rajkot Airport",
      area_code: "RAJ",
      city_name: "Rajkot",
      country_code: "IN"
  },
  {
      id: "RPR",
      name: "Swami Vivekananda Airport",
      area_code: "RPR",
      city_name: "Raipur",
      country_code: "IN"
  },
  {
      id: "SXR",
      name: "Srinagar Airport",
      area_code: "SXR",
      city_name: "Srinagar",
      country_code: "IN"
  },
  {
      id: "TRV",
      name: "TRIVANDRUM INTERNATIONAL AIRPORT",
      area_code: "TRV",
      city_name: "Thiruvananthapuram",
      country_code: "IN"
  },
  {
      id: "VNS",
      name: "VARANASI AIRPORT",
      area_code: "VNS",
      city_name: "Varanasi",
      country_code: "IN"
  },
  {
      id: "PNQ",
      name: "Pune Airport",
      area_code: "PNQ",
      city_name: "Pune",
      country_code: "IN"
  },
  {
      id: "KNU",
      name: "KANPUR AIRPORT",
      area_code: "KNU",
      city_name: "Kanpur",
      country_code: "IN"
  },
  {
      id: "LUH",
      name: "Ludhiana Airport",
      area_code: "LUH",
      city_name: "Ludhiana",
      country_code: "IN"
  },
  {
      id: "NGA",
      name: "Young",
      area_code: "NGA",
      city_name: "Young",
      country_code: "AU"
  },
  {
      id: "SSE",
      name: "Solapur",
      area_code: "SSE",
      city_name: "Solapur",
      country_code: "IN"
  },
  {
      id: "AGR",
      name: "Agra Airport",
      area_code: "AGR",
      city_name: "Agra",
      country_code: "IN"
  },
  {
      id: "ATQ",
      name: "Sri Guru Ram Dass Jee International Airport",
      area_code: "ATQ",
      city_name: "Amritsar",
      country_code: "IN"
  },
  {
      id: "BHJ",
      name: "Bhuj Airport",
      area_code: "BHJ",
      city_name: "Bhuj",
      country_code: "IN"
  },
  {
      id: "BHO",
      name: "BHOPAL AIRPORT",
      area_code: "BHO",
      city_name: "Bhopal",
      country_code: "IN"
  },
  {
      id: "BHU",
      name: "BHAVNAGAR AIRPORT",
      area_code: "BHU",
      city_name: "Bhavnagar",
      country_code: "IN"
  },
  {
      id: "BLR",
      name: "Bengaluru Airport",
      area_code: "BLR",
      city_name: "Bengaluru",
      country_code: "IN"
  },
  {
      id: "BOM",
      name: "Chhatrapati Shivaji Airport",
      area_code: "BOM",
      city_name: "Mumbai",
      country_code: "IN"
  },
  {
      id: "CGK",
      name: "Soekarno-Hatta international Airport",
      area_code: "JKT",
      city_name: "Jakarta",
      country_code: "ID"
  },
  {
      id: "CXP",
      name: "Tunggul Wulung",
      area_code: "CXP",
      city_name: "Cilacap",
      country_code: "ID"
  },
  {
      id: "DJB",
      name: "Sultan Thaha Airport",
      area_code: "DJB",
      city_name: "Jambi",
      country_code: "ID"
  },
  {
      id: "DJJ",
      name: "SENTANI AIRPORT",
      area_code: "DJJ",
      city_name: "Jayapura",
      country_code: "ID"
  },
  {
      id: "DPS",
      name: "Ngurah Rai Airport",
      area_code: "DPS",
      city_name: "Denpasar-Bali",
      country_code: "ID"
  },
  {
      id: "ENE",
      name: "Ende Airport",
      area_code: "ENE",
      city_name: "Ende",
      country_code: "ID"
  },
  {
      id: "FKQ",
      name: "Fak Fak Airport",
      area_code: "FKQ",
      city_name: "Fak Fak",
      country_code: "ID"
  },
  {
      id: "HLP",
      name: "Halim Perdana Kusuma Airport",
      area_code: "JKT",
      city_name: "Jakarta",
      country_code: "ID"
  },
  {
      id: "JOG",
      name: "Adisutjipto Airport",
      area_code: "JOG",
      city_name: "Yogyakarta",
      country_code: "ID"
  },
  {
      id: "KDI",
      name: "WOLTER MONGINSIDI AIRPORT",
      area_code: "KDI",
      city_name: "Kendari",
      country_code: "ID"
  },
  {
      id: "KOE",
      name: "El Tari Airport",
      area_code: "KOE",
      city_name: "Kupang",
      country_code: "ID"
  },
  {
      id: "MDC",
      name: "Sam Ratulangi Airport",
      area_code: "MDC",
      city_name: "Manado",
      country_code: "ID"
  },
  {
      id: "MES",
      name: "Polonia",
      area_code: "MES",
      city_name: "Medan",
      country_code: "ID"
  },
  {
      id: "MOF",
      name: "Fransiskus X.Seda Airport",
      area_code: "MOF",
      city_name: "Maumere",
      country_code: "ID"
  },
  {
      id: "RTG",
      name: "Ruteng Airport",
      area_code: "RTG",
      city_name: "Ruteng",
      country_code: "ID"
  },
  {
      id: "RTI",
      name: "Roti Airport",
      area_code: "RTI",
      city_name: "Roti",
      country_code: "ID"
  },
  {
      id: "SAU",
      name: "Tardamu",
      area_code: "SAU",
      city_name: "Sawu",
      country_code: "ID"
  },
  {
      id: "SOC",
      name: "Adi Sumarmo Airport",
      area_code: "SOC",
      city_name: "Surakarta (Solo)",
      country_code: "ID"
  },
  {
      id: "SOQ",
      name: "Domine Eduard Osok Airport",
      area_code: "SOQ",
      city_name: "Sorong",
      country_code: "ID"
  },
  {
      id: "SRG",
      name: "Achmad Yani International Airport",
      area_code: "SRG",
      city_name: "Semarang",
      country_code: "ID"
  },
  {
      id: "SRI",
      name: "Samarinda Airport",
      area_code: "SRI",
      city_name: "Samarinda",
      country_code: "ID"
  },
  {
      id: "SWQ",
      name: "Sultan Muhammad Kaharuddin III Airport",
      area_code: "SWQ",
      city_name: "Sumbawa",
      country_code: "ID"
  },
  {
      id: "TLI",
      name: "Tolitoli Airport",
      area_code: "TLI",
      city_name: "Tolitoli",
      country_code: "ID"
  },
  {
      id: "TRK",
      name: "Juwata International Airport",
      area_code: "TRK",
      city_name: "Tarakan",
      country_code: "ID"
  },
  {
      id: "TTE",
      name: "Babullah Airport",
      area_code: "TTE",
      city_name: "Ternate",
      country_code: "ID"
  },
  {
      id: "WGP",
      name: "Waingapu Airport",
      area_code: "WGP",
      city_name: "Waingapu",
      country_code: "ID"
  },
  {
      id: "ZRM",
      name: "Orai",
      area_code: "ZRM",
      city_name: "Sarmi",
      country_code: "ID"
  },
  {
      id: "SUB",
      name: "Juanda Airport",
      area_code: "SUB",
      city_name: "Surabaya",
      country_code: "ID"
  },
  {
      id: "MLG",
      name: "Abdul Rachman Saleh Airport",
      area_code: "MLG",
      city_name: "Malang",
      country_code: "ID"
  },
  {
      id: "PDG",
      name: "Minangkabau Airport",
      area_code: "PDG",
      city_name: "Padang",
      country_code: "ID"
  },
  {
      id: "AMI",
      name: "Selaparang",
      area_code: "AMI",
      city_name: "Mataram",
      country_code: "ID"
  },
  {
      id: "AMQ",
      name: "PATTIMURA AIRPORT",
      area_code: "AMQ",
      city_name: "Ambon",
      country_code: "ID"
  },
  {
      id: "ARD",
      name: "Alor Island Airport",
      area_code: "ARD",
      city_name: "ALOR ISLAND",
      country_code: "ID"
  },
  {
      id: "BDJ",
      name: "Syamsudin Noor Airport",
      area_code: "BDJ",
      city_name: "Banjarmasin",
      country_code: "ID"
  },
  {
      id: "BDO",
      name: "Hussein Sastranegara Airport",
      area_code: "BDO",
      city_name: "Bandung",
      country_code: "ID"
  },
  {
      id: "BEJ",
      name: "Kalimarau Airport",
      area_code: "BEJ",
      city_name: "Tanjung Redeb",
      country_code: "ID"
  },
  {
      id: "BIK",
      name: "Mokmer Airport",
      area_code: "BIK",
      city_name: "Biak",
      country_code: "ID"
  },
  {
      id: "BKS",
      name: "Bengkulu Airport",
      area_code: "BKS",
      city_name: "Bengkulu",
      country_code: "ID"
  },
  {
      id: "BMU",
      name: "Sultan Muhammad Salahudin Airport",
      area_code: "BMU",
      city_name: "Bima",
      country_code: "ID"
  },
  {
      id: "BPN",
      name: "Sepingan Airport",
      area_code: "BPN",
      city_name: "Balikpapan",
      country_code: "ID"
  },
  {
      id: "CWL",
      name: "Cardiff Airport",
      area_code: "CWL",
      city_name: "Cardiff",
      country_code: "GB"
  },
  {
      id: "DND",
      name: "Dundee Airport",
      area_code: "DND",
      city_name: "Dundee",
      country_code: "GB"
  },
  {
      id: "EDI",
      name: "Edinburgh Airport",
      area_code: "EDI",
      city_name: "Edinburgh",
      country_code: "GB"
  },
  {
      id: "EXT",
      name: "Exeter Airport",
      area_code: "EXT",
      city_name: "Exeter",
      country_code: "GB"
  },
  {
      id: "FIE",
      name: "Fair Isle Airport",
      area_code: "FIE",
      city_name: "Fair Isle",
      country_code: "GB"
  },
  {
      id: "GLA",
      name: "International",
      area_code: "GLA",
      city_name: "Glasgow",
      country_code: "GB"
  },
  {
      id: "ILY",
      name: "GLENEGEDALE AIRPORT",
      area_code: "ILY",
      city_name: "Islay",
      country_code: "GB"
  },
  {
      id: "INV",
      name: "Inverness Airport",
      area_code: "INV",
      city_name: "Inverness",
      country_code: "GB"
  },
  {
      id: "IOM",
      name: "Ronaldsway Airport",
      area_code: "IOM",
      city_name: "Isle of Man",
      country_code: "GB"
  },
  {
      id: "JER",
      name: "Jersey Airport",
      area_code: "JER",
      city_name: "Jersey",
      country_code: "GB"
  },
  {
      id: "KOI",
      name: "KIRKWALL AIRPORT",
      area_code: "KOI",
      city_name: "Kirkwall",
      country_code: "GB"
  },
  {
      id: "LCY",
      name: "London City Airport",
      area_code: "LON",
      city_name: "London",
      country_code: "GB"
  },
  {
      id: "LDY",
      name: "Eglinton Airport",
      area_code: "LDY",
      city_name: "Derry",
      country_code: "GB"
  },
  {
      id: "LGW",
      name: "Gatwick Airport",
      area_code: "LON",
      city_name: "London",
      country_code: "GB"
  },
  {
      id: "LHR",
      name: "Heathrow Airport",
      area_code: "LON",
      city_name: "London",
      country_code: "GB"
  },
  {
      id: "LPL",
      name: "John Lennon Airport",
      area_code: "LPL",
      city_name: "Liverpool",
      country_code: "GB"
  },
  {
      id: "LTN",
      name: "LUTON AIRPORT",
      area_code: "LON",
      city_name: "London",
      country_code: "GB"
  },
  {
      id: "MAN",
      name: "Manchester Airport",
      area_code: "MAN",
      city_name: "Manchester",
      country_code: "GB"
  },
  {
      id: "NCL",
      name: "Newcastle International Airport",
      area_code: "NCL",
      city_name: "Newcastle",
      country_code: "GB"
  },
  {
      id: "NWI",
      name: "Norwich International Airport",
      area_code: "NWI",
      city_name: "Norwich",
      country_code: "GB"
  },
  {
      id: "PIK",
      name: "PRESTWICK AIRPORT",
      area_code: "GLA",
      city_name: "Glasgow",
      country_code: "GB"
  },
  {
      id: "PLH",
      name: "City Airport",
      area_code: "PLH",
      city_name: "Yverdon",
      country_code: "CH"
  },
  {
      id: "PZE",
      name: "PENZANCE",
      area_code: "PZE",
      city_name: "PENZANCE",
      country_code: "GB"
  },
  {
      id: "SOU",
      name: "Southampton Airport",
      area_code: "SOU",
      city_name: "Southampton",
      country_code: "GB"
  },
  {
      id: "STN",
      name: "Stansted Airport",
      area_code: "LON",
      city_name: "London",
      country_code: "GB"
  },
  {
      id: "SYY",
      name: "STORNOWAY AIRPORT",
      area_code: "SYY",
      city_name: "Stornoway",
      country_code: "GB"
  },
  {
      id: "TRE",
      name: "TIREE AIRPORT",
      area_code: "TRE",
      city_name: "Tiree",
      country_code: "GB"
  },
  {
      id: "UNT",
      name: "Baltasound",
      area_code: "UNT",
      city_name: "Unst",
      country_code: "GB"
  },
  {
      id: "WIC",
      name: "WICK AIRPORT",
      area_code: "WIC",
      city_name: "Wick",
      country_code: "GB"
  },
  {
      id: "WRY",
      name: "WESTRAY AIRPORT",
      area_code: "WRY",
      city_name: "Westray",
      country_code: "GB"
  },
  {
      id: "LBA",
      name: "Leeds Airport",
      area_code: "LBA",
      city_name: "Leeds/Bradford",
      country_code: "GB"
  },
  {
      id: "SZD",
      name: "SHEFFIELD CITY AIRPORT",
      area_code: "SZD",
      city_name: "Herning City",
      country_code: "DK"
  },
  {
      id: "ABZ",
      name: "Aberdeen Airport",
      area_code: "ABZ",
      city_name: "Aberdeen",
      country_code: "GB"
  },
  {
      id: "BFS",
      name: "International",
      area_code: "BFS",
      city_name: "Belfast",
      country_code: "GB"
  },
  {
      id: "BHD",
      name: "Belfast City Airport",
      area_code: "BFS",
      city_name: "Belfast",
      country_code: "GB"
  },
  {
      id: "BHX",
      name: "Birmingham Airport",
      area_code: "BHX",
      city_name: "Birmingham",
      country_code: "GB"
  },
  {
      id: "BRR",
      name: "Barra Airport",
      area_code: "BRR",
      city_name: "Barra",
      country_code: "GB"
  },
  {
      id: "BRS",
      name: "Bristol Airport",
      area_code: "BRS",
      city_name: "Bristol",
      country_code: "GB"
  },
  {
      id: "CBG",
      name: "CAMBRIDGE AIRPORT",
      area_code: "CBG",
      city_name: "Cambridge",
      country_code: "GB"
  },
  {
      id: "ADJ",
      name: "Marka Intl",
      area_code: "AMM",
      city_name: "Amman",
      country_code: "JO"
  },
  {
      id: "AMM",
      name: "Queen Alia International Airport",
      area_code: "AMM",
      city_name: "Amman",
      country_code: "JO"
  },
  {
      id: "AQJ",
      name: "King Hussein International Airport",
      area_code: "AQJ",
      city_name: "Aqaba",
      country_code: "JO"
  },
  {
      id: "DIN",
      name: "Dien Bien Phu Airport",
      area_code: "DIN",
      city_name: "Dien Bien Phu",
      country_code: "VN"
  },
  {
      id: "HAN",
      name: "Noi Bai International Airport",
      area_code: "HAN",
      city_name: "Hanoi",
      country_code: "VN"
  },
  {
      id: "HPH",
      name: "Cat Bi International Airport",
      area_code: "HPH",
      city_name: "Haiphong",
      country_code: "VN"
  },
  {
      id: "NHA",
      name: "Nha Trang Air Base",
      area_code: "NHA",
      city_name: "Nha Trang",
      country_code: "VN"
  },
  {
      id: "PXU",
      name: "PLEIKU AIRPORT",
      area_code: "PXU",
      city_name: "Pleiku",
      country_code: "VN"
  },
  {
      id: "SGN",
      name: "Tan Son Nhat International Airport",
      area_code: "SGN",
      city_name: "Ho Chi Minh City",
      country_code: "VN"
  },
  {
      id: "BMV",
      name: "PHUNG-DUC AIRPORT",
      area_code: "BMV",
      city_name: "Buon Ma Thuot",
      country_code: "VN"
  },
  {
      id: "LUN",
      name: "Lusaka International Airport",
      area_code: "LUN",
      city_name: "Lusaka",
      country_code: "ZM"
  },
  {
      id: "LVI",
      name: "LIVINGSTONE AIRPORT",
      area_code: "LVI",
      city_name: "Livingstone",
      country_code: "ZM"
  },
  {
      id: "FKI",
      name: "Bangoka International Airport",
      area_code: "FKI",
      city_name: "Kisangani",
      country_code: "CD"
  },
  {
      id: "KND",
      name: "Kindu Airport",
      area_code: "KND",
      city_name: "Kindu",
      country_code: "CD"
  },
  {
      id: "MDK",
      name: "Mbandaka Airport",
      area_code: "MDK",
      city_name: "Mbandaka",
      country_code: "CD"
  },
  {
      id: "NDJ",
      name: "N'Djamena International Airport",
      area_code: "NDJ",
      city_name: "N'Djamena",
      country_code: "TD"
  },
  {
      id: "AEH",
      name: "Abeche",
      area_code: "AEH",
      city_name: "Abeche",
      country_code: "TD"
  },
  {
      id: "GIB",
      name: "Gibraltar Airport",
      area_code: "GIB",
      city_name: "Gibraltar",
      country_code: "GI"
  },
  {
      id: "CPO",
      name: "Copiapo Airport",
      area_code: "CPO",
      city_name: "Copiapo",
      country_code: "CL"
  },
  {
      id: "ESR",
      name: "Ricardo Garcia Posada",
      area_code: "ESR",
      city_name: "El Salvador",
      country_code: "CL"
  },
  {
      id: "IQQ",
      name: "Diego Aracena International Airport",
      area_code: "IQQ",
      city_name: "Iquique",
      country_code: "CL"
  },
  {
      id: "SCL",
      name: "Arturo Merino Benitez Airport",
      area_code: "SCL",
      city_name: "Santiago",
      country_code: "CL"
  },
  {
      id: "ZAL",
      name: "Pichoy Airport",
      area_code: "ZAL",
      city_name: "Valdivia",
      country_code: "CL"
  },
  {
      id: "ZCO",
      name: "Temuco Airport",
      area_code: "ZCO",
      city_name: "Temuco",
      country_code: "CL"
  },
  {
      id: "ARI",
      name: "Chacalluta Airport",
      area_code: "ARI",
      city_name: "Arica",
      country_code: "CL"
  },
  {
      id: "CCP",
      name: "Carriel Sur Airport",
      area_code: "CCP",
      city_name: "Concepcion",
      country_code: "CL"
  },
  {
      id: "BGF",
      name: "Bangui Airport",
      area_code: "BGF",
      city_name: "Bangui",
      country_code: "CF"
  },
  {
      id: "CGO",
      name: "Xinzheng International Airport",
      area_code: "CGO",
      city_name: "Zhengzhou",
      country_code: "CN"
  },
  {
      id: "CGQ",
      name: "Longjia International Airport",
      area_code: "CGQ",
      city_name: "Changchun",
      country_code: "CN"
  },
  {
      id: "CSX",
      name: "Huanghua International Airport",
      area_code: "CSX",
      city_name: "Changsha",
      country_code: "CN"
  },
  {
      id: "CTU",
      name: "Shuangliu International Airport",
      area_code: "CTU",
      city_name: "Chengdu",
      country_code: "CN"
  },
  {
      id: "CZX",
      name: "Benniu Airport",
      area_code: "CZX",
      city_name: "Changzhou",
      country_code: "CN"
  },
  {
      id: "DLC",
      name: "Zhoushuizi International Airport",
      area_code: "DLC",
      city_name: "Dalian",
      country_code: "CN"
  },
  {
      id: "DLU",
      name: "Dali Airport",
      area_code: "DLU",
      city_name: "Dali",
      country_code: "CN"
  },
  {
      id: "ENY",
      name: "NanNiWan Airport",
      area_code: "ENY",
      city_name: "Yan'an",
      country_code: "CN"
  },
  {
      id: "FOC",
      name: "Changle International Airport",
      area_code: "FOC",
      city_name: "Fuzhou",
      country_code: "CN"
  },
  {
      id: "FYN",
      name: "Fuyun Airport",
      area_code: "FYN",
      city_name: "Fuyun",
      country_code: "CN"
  },
  {
      id: "HAK",
      name: "Meilan International Airport",
      area_code: "HAK",
      city_name: "Haikou",
      country_code: "CN"
  },
  {
      id: "HGH",
      name: "Xiaoshan International Airport",
      area_code: "HGH",
      city_name: "Hangzhou",
      country_code: "CN"
  },
  {
      id: "HLD",
      name: "Dongshan Airport",
      area_code: "HLD",
      city_name: "Hailar",
      country_code: "CN"
  },
  {
      id: "HRB",
      name: "Taiping International Airport",
      area_code: "HRB",
      city_name: "Harbin",
      country_code: "CN"
  },
  {
      id: "HTN",
      name: "Hotan Airport",
      area_code: "HTN",
      city_name: "Hotan",
      country_code: "CN"
  },
  {
      id: "HUZ",
      name: "Pingtan Airport",
      area_code: "HUZ",
      city_name: "Huizhou",
      country_code: "CN"
  },
  {
      id: "HYN",
      name: "Luqiao Airport",
      area_code: "HYN",
      city_name: "Taizhou",
      country_code: "CN"
  },
  {
      id: "HZG",
      name: "Chenggu Airport",
      area_code: "HZG",
      city_name: "Hanzhong",
      country_code: "CN"
  },
  {
      id: "INC",
      name: "Hedong Airport",
      area_code: "INC",
      city_name: "Yinchuan",
      country_code: "CN"
  },
  {
      id: "IQM",
      name: "Qiemo Airport",
      area_code: "IQM",
      city_name: "Qiemo",
      country_code: "CN"
  },
  {
      id: "JGN",
      name: "Jiayuguan Airport",
      area_code: "JGN",
      city_name: "Jiayuguan",
      country_code: "CN"
  },
  {
      id: "JIL",
      name: "Ertaizi",
      area_code: "JIL",
      city_name: "Jilin",
      country_code: "CN"
  },
  {
      id: "JIU",
      name: "Lushan Airport",
      area_code: "JIU",
      city_name: "Jiujiang",
      country_code: "CN"
  },
  {
      id: "JMU",
      name: "Dongjiao Airport",
      area_code: "JMU",
      city_name: "Jiamusi",
      country_code: "CN"
  },
  {
      id: "JNZ",
      name: "Jinzhouwan Airport",
      area_code: "JNZ",
      city_name: "Jinzhou",
      country_code: "CN"
  },
  {
      id: "KCA",
      name: "Qiuci Airport",
      area_code: "KCA",
      city_name: "Kuqa",
      country_code: "CN"
  },
  {
      id: "KMG",
      name: "Changshui International Airport",
      area_code: "KMG",
      city_name: "Kunming",
      country_code: "CN"
  },
  {
      id: "KOW",
      name: "Huangjin Airport",
      area_code: "KOW",
      city_name: "Ganzhou",
      country_code: "CN"
  },
  {
      id: "KRL",
      name: "Korla Airport",
      area_code: "KRL",
      city_name: "Korla",
      country_code: "CN"
  },
  {
      id: "KRY",
      name: "Karamay Airport",
      area_code: "KRY",
      city_name: "Karamay",
      country_code: "CN"
  },
  {
      id: "KWL",
      name: "Liangjiang International Airport",
      area_code: "KWL",
      city_name: "Guilin",
      country_code: "CN"
  },
  {
      id: "LHW",
      name: "Zhongchuan Airport",
      area_code: "LHW",
      city_name: "Lanzhou",
      country_code: "CN"
  },
  {
      id: "LXA",
      name: "Gonggar Airport",
      area_code: "LXA",
      city_name: "Lhasa/Lasa",
      country_code: "CN"
  },
  {
      id: "LYA",
      name: "Beijiao Airport",
      area_code: "LYA",
      city_name: "Luoyang",
      country_code: "CN"
  },
  {
      id: "LYG",
      name: "Baitabu Airport",
      area_code: "LYG",
      city_name: "Lianyungang",
      country_code: "CN"
  },
  {
      id: "LZH",
      name: "Bailian Airport",
      area_code: "LZH",
      city_name: "Liuzhou",
      country_code: "CN"
  },
  {
      id: "LZO",
      name: "Yunlong Airport",
      area_code: "LZO",
      city_name: "Luzhou",
      country_code: "CN"
  },
  {
      id: "MDG",
      name: "Hailang Airport",
      area_code: "MDG",
      city_name: "Mudanjiang",
      country_code: "CN"
  },
  {
      id: "NAO",
      name: "Gaoping Airport",
      area_code: "NAO",
      city_name: "Nanchong",
      country_code: "CN"
  },
  {
      id: "NAY",
      name: "Nanyuan Airport",
      area_code: "BJS",
      city_name: "Beijing",
      country_code: "CN"
  },
  {
      id: "NDG",
      name: "Sanjiazi Airport",
      area_code: "NDG",
      city_name: "Qiqihar",
      country_code: "CN"
  },
  {
      id: "ENH",
      name: "Xujiaping Airport",
      area_code: "ENH",
      city_name: "Enshi",
      country_code: "CN"
  },
  {
      id: "NGB",
      name: "Lishe International Airport",
      area_code: "NGB",
      city_name: "Ningbo",
      country_code: "CN"
  },
  {
      id: "NKG",
      name: "Lukou International Airport",
      area_code: "NKG",
      city_name: "Nanjing",
      country_code: "CN"
  },
  {
      id: "NNG",
      name: "Wuxu International Airport",
      area_code: "NNG",
      city_name: "Nanning",
      country_code: "CN"
  },
  {
      id: "NNY",
      name: "Jiangying Airport",
      area_code: "NNY",
      city_name: "Nanyang",
      country_code: "CN"
  },
  {
      id: "NTG",
      name: "Xingdong International Airport",
      area_code: "NTG",
      city_name: "Nantong",
      country_code: "CN"
  },
  {
      id: "PVG",
      name: "Pudong International Airport",
      area_code: "SHA",
      city_name: "Shanghai",
      country_code: "CN"
  },
  {
      id: "SHE",
      name: "Taoxian International Airport",
      area_code: "SHE",
      city_name: "Shenyang",
      country_code: "CN"
  },
  {
      id: "SHP",
      name: "Qinhuangdao Shanhaiguan Airport",
      area_code: "SHP",
      city_name: "",
      country_code: ""
  },
  {
      id: "SHS",
      name: "Shashi",
      area_code: "SHS",
      city_name: "Jingzhou",
      country_code: "CN"
  },
  {
      id: "SWA",
      name: "Chaoshan Airport",
      area_code: "SWA",
      city_name: "Shantou",
      country_code: "CN"
  },
  {
      id: "SYX",
      name: "Phoenix International Airport",
      area_code: "SYX",
      city_name: "Sanya",
      country_code: "CN"
  },
  {
      id: "SZV",
      name: "Guangfu",
      area_code: "SZV",
      city_name: "Suzhou",
      country_code: "CN"
  },
  {
      id: "SZX",
      name: "Bao'an International Airport",
      area_code: "SZX",
      city_name: "Shenzhen",
      country_code: "CN"
  },
  {
      id: "TAO",
      name: "Liuting International Airport",
      area_code: "TAO",
      city_name: "Qingdao",
      country_code: "CN"
  },
  {
      id: "SHA",
      name: "Hongqiao International Airport",
      area_code: "SHA",
      city_name: "Shanghai",
      country_code: "CN"
  },
  {
      id: "TNA",
      name: "Yaoqiang International Airport",
      area_code: "TNA",
      city_name: "Jinan",
      country_code: "CN"
  },
  {
      id: "TNH",
      name: "Sanyuanpu Airport",
      area_code: "TNH",
      city_name: "Tonghua",
      country_code: "CN"
  },
  {
      id: "TSN",
      name: "Binhai International Airport",
      area_code: "TSN",
      city_name: "Tianjin",
      country_code: "CN"
  },
  {
      id: "TXN",
      name: "Tunxi Airport",
      area_code: "TXN",
      city_name: "Huangshan",
      country_code: "CN"
  },
  {
      id: "TYN",
      name: "Wusu International Airport",
      area_code: "TYN",
      city_name: "Taiyuan",
      country_code: "CN"
  },
  {
      id: "URC",
      name: "Diwopu International Airport",
      area_code: "URC",
      city_name: "Urumqi",
      country_code: "CN"
  },
  {
      id: "WEF",
      name: "Weifang Airport",
      area_code: "WEF",
      city_name: "Weifang",
      country_code: "CN"
  },
  {
      id: "WEH",
      name: "Dashuibo Airport",
      area_code: "WEH",
      city_name: "Weihai",
      country_code: "CN"
  },
  {
      id: "WHU",
      name: "Wuhu",
      area_code: "WHU",
      city_name: "Wuhu",
      country_code: "CN"
  },
  {
      id: "WNZ",
      name: "Longwan International Airport",
      area_code: "WNZ",
      city_name: "Wenzhou",
      country_code: "CN"
  },
  {
      id: "WUH",
      name: "Tianhe International Airport",
      area_code: "WUH",
      city_name: "Wuhan",
      country_code: "CN"
  },
  {
      id: "WUS",
      name: "Wuyishan Airport",
      area_code: "WUS",
      city_name: "Wuyishan",
      country_code: "CN"
  },
  {
      id: "WUX",
      name: "Sunan Shuofang International Airport",
      area_code: "WUX",
      city_name: "Wuxi",
      country_code: "CN"
  },
  {
      id: "WUZ",
      name: "Xijiang Airport",
      area_code: "WUZ",
      city_name: "Wuzhou",
      country_code: "CN"
  },
  {
      id: "XFN",
      name: "Liuji Airport",
      area_code: "XFN",
      city_name: "Xiangyang",
      country_code: "CN"
  },
  {
      id: "XIY",
      name: "Xianyang International Airport",
      area_code: "SIA",
      city_name: "Xi'an",
      country_code: "CN"
  },
  {
      id: "XMN",
      name: "Gaoqi International Airport",
      area_code: "XMN",
      city_name: "Xiamen",
      country_code: "CN"
  },
  {
      id: "XNN",
      name: "Caojiabao Airport",
      area_code: "XNN",
      city_name: "Xining",
      country_code: "CN"
  },
  {
      id: "XNT",
      name: "Dalian",
      area_code: "XNT",
      city_name: "Xingtai",
      country_code: "CN"
  },
  {
      id: "XUZ",
      name: "Guanyin Airport",
      area_code: "XUZ",
      city_name: "Xuzhou",
      country_code: "CN"
  },
  {
      id: "YIN",
      name: "Yining Airport",
      area_code: "YIN",
      city_name: "Yining",
      country_code: "CN"
  },
  {
      id: "YNT",
      name: "Penglai International Airport",
      area_code: "YNT",
      city_name: "Yantai",
      country_code: "CN"
  },
  {
      id: "ZAT",
      name: "Zhaotong Airport",
      area_code: "ZAT",
      city_name: "Zhaotong",
      country_code: "CN"
  },
  {
      id: "CKG",
      name: "Jiangbei International Airport",
      area_code: "CKG",
      city_name: "Chongqing",
      country_code: "CN"
  },
  {
      id: "ZHA",
      name: "Zhanjiang Airport",
      area_code: "ZHA",
      city_name: "Zhanjiang",
      country_code: "CN"
  },
  {
      id: "ZUH",
      name: "Jinwan Airport",
      area_code: "ZUH",
      city_name: "Zhuhai",
      country_code: "CN"
  },
  {
      id: "AAT",
      name: "Altay Airport",
      area_code: "AAT",
      city_name: "Altay",
      country_code: "CN"
  },
  {
      id: "AKA",
      name: "Wulipu",
      area_code: "AKA",
      city_name: "Ankang",
      country_code: "CN"
  },
  {
      id: "AKU",
      name: "Aksu Airport",
      area_code: "AKU",
      city_name: "Aksu",
      country_code: "CN"
  },
  {
      id: "AOG",
      name: "Teng'ao Airport",
      area_code: "AOG",
      city_name: "Anshan",
      country_code: "CN"
  },
  {
      id: "AQG",
      name: "Tianzhushan Airport",
      area_code: "AQG",
      city_name: "Anqing",
      country_code: "CN"
  },
  {
      id: "BAV",
      name: "Donghe Airport",
      area_code: "BAV",
      city_name: "Baotou",
      country_code: "CN"
  },
  {
      id: "BFU",
      name: "Bengbu",
      area_code: "BFU",
      city_name: "Bengbu",
      country_code: "CN"
  },
  {
      id: "BHY",
      name: "Fucheng Airport",
      area_code: "BHY",
      city_name: "Beihai",
      country_code: "CN"
  },
  {
      id: "PEK",
      name: "Capital International Airport",
      area_code: "BJS",
      city_name: "Beijing",
      country_code: "CN"
  },
  {
      id: "BSD",
      name: "Baoshan Airport",
      area_code: "BSD",
      city_name: "Baoshan",
      country_code: "CN"
  },
  {
      id: "CAN",
      name: "Baiyun International Airport",
      area_code: "CAN",
      city_name: "Guangzhou",
      country_code: "CN"
  },
  {
      id: "MFM",
      name: "Macau International Airport",
      area_code: "MFM",
      city_name: "Macau",
      country_code: "MO"
  },
  {
      id: "CYI",
      name: "CHIAYI AIRPORT",
      area_code: "CYI",
      city_name: "Chiayi",
      country_code: "TW"
  },
  {
      id: "HUN",
      name: "Hualien Airport",
      area_code: "HUN",
      city_name: "Hualien",
      country_code: "TW"
  },
  {
      id: "KHH",
      name: "Kaohsiung International Airport",
      area_code: "KHH",
      city_name: "Kaohsiung",
      country_code: "TW"
  },
  {
      id: "KNH",
      name: "Shangyi Airport",
      area_code: "KNH",
      city_name: "Kinmen",
      country_code: "TW"
  },
  {
      id: "MZG",
      name: "Magong Airport",
      area_code: "MZG",
      city_name: "Penghu",
      country_code: "TW"
  },
  {
      id: "PIF",
      name: "Pingtung South",
      area_code: "PIF",
      city_name: "Pingtung",
      country_code: "TW"
  },
  {
      id: "TNN",
      name: "TAINAN AIRPORT",
      area_code: "TNN",
      city_name: "Tainan",
      country_code: "TW"
  },
  {
      id: "TPE",
      name: "Taoyuan Airport",
      area_code: "TPE",
      city_name: "Taipei",
      country_code: "TW"
  },
  {
      id: "TSA",
      name: "Songshan Airport",
      area_code: "TPE",
      city_name: "Taipei",
      country_code: "TW"
  },
  {
      id: "TTT",
      name: "TAITUNG AIRPORT",
      area_code: "TTT",
      city_name: "Taitung",
      country_code: "TW"
  },
  {
      id: "HKG",
      name: "Hong Kong International Airport",
      area_code: "HKG",
      city_name: "Hong Kong",
      country_code: "HK"
  },
  {
      id: "ACX",
      name: "Xingyi Airport",
      area_code: "ACX",
      city_name: "Xingyi",
      country_code: "CN"
  },
  {
      id: "AEB",
      name: "Baise Airport",
      area_code: "AEB",
      city_name: "Baise",
      country_code: "CN"
  },
  {
      id: "AVA",
      name: "Huangguoshu Airport",
      area_code: "AVA",
      city_name: "Anshun",
      country_code: "CN"
  },
  {
      id: "AXW",
      name: "Anxi Airport",
      area_code: "AXW",
      city_name: "ANXI",
      country_code: "CN"
  },
  {
      id: "AYN",
      name: "Anyang",
      area_code: "AYN",
      city_name: "Anyang",
      country_code: "CN"
  },
  {
      id: "BDW",
      name: "Bedford Downs",
      area_code: "BDW",
      city_name: "Bedford Downs",
      country_code: "AU"
  },
  {
      id: "BPL",
      name: "Bole Airport",
      area_code: "BPL",
      city_name: "Bole",
      country_code: "CN"
  },
  {
      id: "BPX",
      name: "Bangda Airport",
      area_code: "BPX",
      city_name: "Qamdo/Changdu",
      country_code: "CN"
  },
  {
      id: "BYW",
      name: "Blakely Island",
      area_code: "BYW",
      city_name: "Blakely Island",
      country_code: "CN"
  },
  {
      id: "CCC",
      name: "Cayo Coco Airport",
      area_code: "CCC",
      city_name: "Cayo Coco",
      country_code: "CU"
  },
  {
      id: "CEH",
      name: "Chelinda",
      area_code: "CEH",
      city_name: "Chelinda",
      country_code: "MW"
  },
  {
      id: "CGD",
      name: "Taohuayuan Airport",
      area_code: "CGD",
      city_name: "Changde",
      country_code: "CN"
  },
  {
      id: "CHG",
      name: "Chaoyang Airport",
      area_code: "CHG",
      city_name: "Chaoyang (Liaoning)",
      country_code: "CN"
  },
  {
      id: "CHW",
      name: "Jiuquan Airport",
      area_code: "CHW",
      city_name: "CHAOHU",
      country_code: "CN"
  },
  {
      id: "CIF",
      name: "Yulong Airport",
      area_code: "CIF",
      city_name: "Chifeng",
      country_code: "CN"
  },
  {
      id: "CIH",
      name: "ChangZhi Airport",
      area_code: "CIH",
      city_name: "Changzhi",
      country_code: "CN"
  },
  {
      id: "CNI",
      name: "Changhai",
      area_code: "CNI",
      city_name: "Changhai",
      country_code: "CN"
  },
  {
      id: "CSW",
      name: "Changshu Airport",
      area_code: "CSW",
      city_name: "CHANGSHU",
      country_code: "CN"
  },
  {
      id: "CZW",
      name: "Rudniki",
      area_code: "CZW",
      city_name: "Czestochowa",
      country_code: "PL"
  },
  {
      id: "CZZ",
      name: "Chuzhou Airport",
      area_code: "CZZ",
      city_name: "CHUZHOU",
      country_code: "CN"
  },
  {
      id: "DAT",
      name: "Yungang Airport",
      area_code: "DAT",
      city_name: "Datong",
      country_code: "CN"
  },
  {
      id: "DAX",
      name: "Heshi Airport",
      area_code: "DAX",
      city_name: "Dazhou",
      country_code: "CN"
  },
  {
      id: "DDG",
      name: "Langtou Airport",
      area_code: "DDG",
      city_name: "Dandong",
      country_code: "CN"
  },
  {
      id: "DIG",
      name: "Shangri La Airport",
      area_code: "DIG",
      city_name: "Deqen",
      country_code: "CN"
  },
  {
      id: "DNH",
      name: "Dunhuang Airport",
      area_code: "DNH",
      city_name: "Dunhuang",
      country_code: "CN"
  },
  {
      id: "DOY",
      name: "Shengli Airport",
      area_code: "DOY",
      city_name: "Dongying",
      country_code: "CN"
  },
  {
      id: "DQA",
      name: "Saertu Airport",
      area_code: "DQA",
      city_name: "Daqing",
      country_code: "CN"
  },
  {
      id: "DSN",
      name: "Ejin Horo Airport",
      area_code: "DSN",
      city_name: "Ordos",
      country_code: "CN"
  },
  {
      id: "DSW",
      name: "Dongshan Airport",
      area_code: "DSW",
      city_name: "DONGSHAN",
      country_code: "CN"
  },
  {
      id: "DGM",
      name: "Dandugama SPB",
      area_code: "CMB",
      city_name: "Colombo",
      country_code: "LK"
  },
  {
      id: "DYG",
      name: "Hehua Airport",
      area_code: "DYG",
      city_name: "Zhangjiajie",
      country_code: "CN"
  },
  {
      id: "DYW",
      name: "Daly Waters",
      area_code: "DYW",
      city_name: "Daly Waters",
      country_code: "AU"
  },
  {
      id: "DYY",
      name: "Danyang Airport",
      area_code: "DYY",
      city_name: "DANYANG",
      country_code: "CN"
  },
  {
      id: "DZU",
      name: "Dazu",
      area_code: "DZU",
      city_name: "Dazu",
      country_code: "CN"
  },
  {
      id: "FQW",
      name: "Fuqing Airport",
      area_code: "FQW",
      city_name: "FUQING",
      country_code: "CN"
  },
  {
      id: "FUG",
      name: "Xiguan Airport",
      area_code: "FUG",
      city_name: "Fuyang",
      country_code: "CN"
  },
  {
      id: "FUO",
      name: "Shadi Airport",
      area_code: "FUO",
      city_name: "Foshan",
      country_code: "CN"
  },
  {
      id: "FZW",
      name: "Fuzhou Airport",
      area_code: "FZW",
      city_name: "FUZHOU",
      country_code: "CN"
  },
  {
      id: "GHN",
      name: "Guanghan",
      area_code: "GHN",
      city_name: "Guanghan",
      country_code: "CN"
  },
  {
      id: "GOQ",
      name: "Golmud Airport",
      area_code: "GOQ",
      city_name: "Golmud",
      country_code: "CN"
  },
  {
      id: "GYS",
      name: "Panlong Airport",
      area_code: "GYS",
      city_name: "Guangyuan",
      country_code: "CN"
  },
  {
      id: "GYW",
      name: "Gaoyou Airport",
      area_code: "GYW",
      city_name: "GAOYOU",
      country_code: "CN"
  },
  {
      id: "HAA",
      name: "HASVIK AIRPORT",
      area_code: "HAA",
      city_name: "Hasvik",
      country_code: "NO"
  },
  {
      id: "HAW",
      name: "Haverfordwest",
      area_code: "HAW",
      city_name: "Haverfordwest",
      country_code: "GB"
  },
  {
      id: "HDG",
      name: "Handan Airport",
      area_code: "HDG",
      city_name: "Handan",
      country_code: "CN"
  },
  {
      id: "HEK",
      name: "Heihe Airport",
      area_code: "HEK",
      city_name: "Heihe",
      country_code: "CN"
  },
  {
      id: "HET",
      name: "Baita International Airport",
      area_code: "HET",
      city_name: "Hohhot",
      country_code: "CN"
  },
  {
      id: "HJJ",
      name: "Zhijiang Airport",
      area_code: "HJJ",
      city_name: "Huaihua",
      country_code: "CN"
  },
  {
      id: "HLH",
      name: "Ulanhot Airport",
      area_code: "HLH",
      city_name: "Ulanhot",
      country_code: "CN"
  },
  {
      id: "HMI",
      name: "Hami Airport",
      area_code: "HMI",
      city_name: "Hami",
      country_code: "CN"
  },
  {
      id: "HMW",
      name: "Haimen Airport",
      area_code: "HMW",
      city_name: "HAIMEN",
      country_code: "CN"
  },
  {
      id: "HNN",
      name: "Honinabi",
      area_code: "HNN",
      city_name: "Honinabi",
      country_code: "PG"
  },
  {
      id: "HNW",
      name: "Haining Airport",
      area_code: "HNW",
      city_name: "HAINING",
      country_code: "CN"
  },
  {
      id: "HNY",
      name: "Nanyue Airport",
      area_code: "HNY",
      city_name: "Hengyang",
      country_code: "CN"
  },
  {
      id: "HSN",
      name: "Putuoshan Airport",
      area_code: "HSN",
      city_name: "Zhoushan",
      country_code: "CN"
  },
  {
      id: "HYW",
      name: "Conway-Horry County Airport",
      area_code: "HYW",
      city_name: "HAIYAN",
      country_code: "CN"
  },
  {
      id: "HYY",
      name: "Huaiyin Airport",
      area_code: "HYY",
      city_name: "HUAIYIN",
      country_code: "CN"
  },
  {
      id: "HZH",
      name: "Liping Airport",
      area_code: "HZH",
      city_name: "Liping",
      country_code: "CN"
  },
  {
      id: "HZW",
      name: "Huzhou Airport",
      area_code: "HZW",
      city_name: "HUZHOU",
      country_code: "CN"
  },
  {
      id: "IQN",
      name: "Qingyang Airport",
      area_code: "IQN",
      city_name: "Qingyang",
      country_code: "CN"
  },
  {
      id: "JDW",
      name: "Jiangdu Airport",
      area_code: "JDW",
      city_name: "JIANGDU",
      country_code: "CN"
  },
  {
      id: "JDZ",
      name: "Jingdezhen Airport",
      area_code: "JDZ",
      city_name: "Jingdezhen",
      country_code: "CN"
  },
  {
      id: "JGS",
      name: "Jinggangshan Airport",
      area_code: "JGS",
      city_name: "Ji'an",
      country_code: "CN"
  },
  {
      id: "JJN",
      name: "Jinjiang Airport",
      area_code: "JJN",
      city_name: "Quanzhou",
      country_code: "CN"
  },
  {
      id: "JJW",
      name: "Jingjiang Airport",
      area_code: "JJW",
      city_name: "",
      country_code: ""
  },
  {
      id: "JMW",
      name: "Jiangmen Airport",
      area_code: "JMW",
      city_name: "JIANGMEN",
      country_code: "CN"
  },
  {
      id: "JNG",
      name: "Qufu Airport",
      area_code: "JNG",
      city_name: "Jining",
      country_code: "CN"
  },
  {
      id: "JSW",
      name: "Jiashan Airport",
      area_code: "JSW",
      city_name: "JIASHAN",
      country_code: "CN"
  },
  {
      id: "JTW",
      name: "Jintan Airport",
      area_code: "JTW",
      city_name: "JINTAN",
      country_code: "CN"
  },
  {
      id: "JUZ",
      name: "Quzhou Airport",
      area_code: "JUZ",
      city_name: "Quzhou",
      country_code: "CN"
  },
  {
      id: "JXA",
      name: "Xingkaihu Airport",
      area_code: "JXA",
      city_name: "Jixi",
      country_code: "CN"
  },
  {
      id: "JXW",
      name: "Jiaxing Airport",
      area_code: "JXW",
      city_name: "JIAXING",
      country_code: "CN"
  },
  {
      id: "JYW",
      name: "Jiangyin Airport",
      area_code: "JYW",
      city_name: "JIANGYIN",
      country_code: "CN"
  },
  {
      id: "JZH",
      name: "Huanglong Airport",
      area_code: "JZH",
      city_name: "Jiuzhaigou",
      country_code: "CN"
  },
  {
      id: "KGT",
      name: "Kangding Airport",
      area_code: "KGT",
      city_name: "Kangding",
      country_code: "CN"
  },
  {
      id: "KHG",
      name: "Kashi Airport",
      area_code: "KHG",
      city_name: "Kashi",
      country_code: "CN"
  },
  {
      id: "KHN",
      name: "Changbei International Airport",
      area_code: "KHN",
      city_name: "Nanchang",
      country_code: "CN"
  },
  {
      id: "KJI",
      name: "Kanas Airport",
      area_code: "KJI",
      city_name: "Burqin",
      country_code: "CN"
  },
  {
      id: "KNC",
      name: "Ji'an Airport",
      area_code: "KNC",
      city_name: "JIAN",
      country_code: "CN"
  },
  {
      id: "KSW",
      name: "Kiryat Shmona",
      area_code: "KSW",
      city_name: "Kiryat Shmona",
      country_code: "IL"
  },
  {
      id: "KWE",
      name: "Longdongbao International Airport",
      area_code: "KWE",
      city_name: "Guiyang",
      country_code: "CN"
  },
  {
      id: "LCX",
      name: "Guanzhishan Airport",
      area_code: "LCX",
      city_name: "Longyan",
      country_code: "CN"
  },
  {
      id: "LDG",
      name: "Leshukonskoye",
      area_code: "LDG",
      city_name: "Leshukonskoye",
      country_code: "RU"
  },
  {
      id: "LDS",
      name: "Lindu Airport",
      area_code: "LDS",
      city_name: "Yichun (Heilongjiang)",
      country_code: "CN"
  },
  {
      id: "LFF",
      name: "Linfen Airport",
      area_code: "LFF",
      city_name: "",
      country_code: ""
  },
  {
      id: "LHK",
      name: "Laohekou",
      area_code: "LHK",
      city_name: "Laohekou",
      country_code: "CN"
  },
  {
      id: "LIA",
      name: "Liangping",
      area_code: "LIA",
      city_name: "Liangping",
      country_code: "CN"
  },
  {
      id: "LJG",
      name: "Sanyi Airport",
      area_code: "LJG",
      city_name: "Lijiang",
      country_code: "CN"
  },
  {
      id: "LKW",
      name: "Lekhwair",
      area_code: "LKW",
      city_name: "Lekhwair",
      country_code: "OM"
  },
  {
      id: "LLB",
      name: "Libo Airport",
      area_code: "LLB",
      city_name: "Libo",
      country_code: "CN"
  },
  {
      id: "LLF",
      name: "Lingling Airport",
      area_code: "LLF",
      city_name: "Yongzhou",
      country_code: "CN"
  },
  {
      id: "LNJ",
      name: "Lincang Airport",
      area_code: "LNJ",
      city_name: "Lincang",
      country_code: "CN"
  },
  {
      id: "LUM",
      name: "Dehong Mangshi Airport",
      area_code: "LUM",
      city_name: "Mangshi",
      country_code: "CN"
  },
  {
      id: "LXI",
      name: "Linxi Airport",
      area_code: "LXI",
      city_name: "Linxi",
      country_code: "CN"
  },
  {
      id: "LXW",
      name: "Laixi Airport",
      area_code: "LXW",
      city_name: "LAIXI",
      country_code: "CN"
  },
  {
      id: "LYI",
      name: "Shubuling Airport",
      area_code: "LYI",
      city_name: "Linyi",
      country_code: "CN"
  },
  {
      id: "LYW",
      name: "Liyang Airport",
      area_code: "LYW",
      city_name: "LIYANG",
      country_code: "CN"
  },
  {
      id: "LYY",
      name: "Longyan Airport",
      area_code: "LYY",
      city_name: "LONGYAN",
      country_code: "CN"
  },
  {
      id: "LZD",
      name: "Lanzhou East Airport",
      area_code: "LZD",
      city_name: "LANZHOUDONG",
      country_code: "CN"
  },
  {
      id: "LZY",
      name: "Mainling Airport",
      area_code: "LZY",
      city_name: "Nyingchi/Linzhi",
      country_code: "CN"
  },
  {
      id: "MIG",
      name: "Nanjiao Airport",
      area_code: "MIG",
      city_name: "Mianyang",
      country_code: "CN"
  },
  {
      id: "MXZ",
      name: "Meixian Airport",
      area_code: "MXZ",
      city_name: "Meixian",
      country_code: "CN"
  },
  {
      id: "NAW",
      name: "Narathiwat Airport",
      area_code: "NAW",
      city_name: "Narathiwat",
      country_code: "TH"
  },
  {
      id: "NBS",
      name: "Changbaishan Airport",
      area_code: "NBS",
      city_name: "Baishan",
      country_code: "CN"
  },
  {
      id: "NHW",
      name: "Nanhai Airport",
      area_code: "NHW",
      city_name: "NANHAI",
      country_code: "CN"
  },
  {
      id: "NLT",
      name: "Nalati Airport",
      area_code: "NLT",
      city_name: "Xinyuan",
      country_code: "CN"
  },
  {
      id: "NZH",
      name: "Xijiao Airport",
      area_code: "NZH",
      city_name: "Manzhouli",
      country_code: "CN"
  },
  {
      id: "OHE",
      name: "Mohe Airport",
      area_code: "OHE",
      city_name: "Mohe",
      country_code: "CN"
  },
  {
      id: "PTW",
      name: "Heritage Field",
      area_code: "PTW",
      city_name: "Pottstown",
      country_code: "US"
  },
  {
      id: "PYW",
      name: "Panyu Airport",
      area_code: "PYW",
      city_name: "PANYU",
      country_code: "CN"
  },
  {
      id: "PZI",
      name: "Baoanying Airport",
      area_code: "PZI",
      city_name: "Panzhihua",
      country_code: "CN"
  },
  {
      id: "QDW",
      name: "Qidong Airport",
      area_code: "QDW",
      city_name: "QIDONG",
      country_code: "CN"
  },
  {
      id: "RUG",
      name: "Rugao",
      area_code: "RUG",
      city_name: "Rugao",
      country_code: "CN"
  },
  {
      id: "SDW",
      name: "Shunde Airport",
      area_code: "SDW",
      city_name: "SHUNDE",
      country_code: "CN"
  },
  {
      id: "SJW",
      name: "Zhengding International Airport",
      area_code: "SJW",
      city_name: "Shijiazhuang",
      country_code: "CN"
  },
  {
      id: "SMW",
      name: "Semara",
      area_code: "SMW",
      city_name: "Semara",
      country_code: "MA"
  },
  {
      id: "SRW",
      name: "Rowan County",
      area_code: "SRW",
      city_name: "Salisbury",
      country_code: "US"
  },
  {
      id: "SSW",
      name: "Airpark",
      area_code: "SSW",
      city_name: "Stuart Island",
      country_code: "CN"
  },
  {
      id: "SXJ",
      name: "Shanshan",
      area_code: "SXJ",
      city_name: "Shanshan",
      country_code: "CN"
  },
  {
      id: "SXW",
      name: "Sauren",
      area_code: "SXW",
      city_name: "Sauren",
      country_code: "PG"
  },
  {
      id: "SYM",
      name: "Simao Airport",
      area_code: "SYM",
      city_name: "Simao",
      country_code: "CN"
  },
  {
      id: "TCG",
      name: "Tacheng Airport",
      area_code: "TCG",
      city_name: "Tacheng",
      country_code: "CN"
  },
  {
      id: "TCZ",
      name: "Tuofeng Airport",
      area_code: "TCZ",
      city_name: "Tengchong",
      country_code: "CN"
  },
  {
      id: "TEN",
      name: "Tongren Fenghuang Airport",
      area_code: "TEN",
      city_name: "Tongren",
      country_code: "CN"
  },
  {
      id: "TGO",
      name: "Tongliao Airport",
      area_code: "TGO",
      city_name: "Tongliao",
      country_code: "CN"
  },
  {
      id: "THQ",
      name: "Maijishan Airport",
      area_code: "THQ",
      city_name: "Tianshui",
      country_code: "CN"
  },
  {
      id: "TLW",
      name: "Talasea",
      area_code: "TLW",
      city_name: "Talasea",
      country_code: "PG"
  },
  {
      id: "TVS",
      name: "Sannuhe Airport",
      area_code: "TVS",
      city_name: "Tangshan",
      country_code: "CN"
  },
  {
      id: "TXW",
      name: "Taixing Airport",
      area_code: "TXW",
      city_name: "TAIXING",
      country_code: "CN"
  },
  {
      id: "YTY",
      name: "YANGZHOUTAIZHOU AIRPORT",
      area_code: "YTY",
      city_name: "Yangzhou",
      country_code: "CN"
  },
  {
      id: "UYN",
      name: "Yuyang Airport",
      area_code: "UYN",
      city_name: "Yulin",
      country_code: "CN"
  },
  {
      id: "WJW",
      name: "Wujiang Airport",
      area_code: "WJW",
      city_name: "",
      country_code: ""
  },
  {
      id: "WLW",
      name: "Glenn County",
      area_code: "WLW",
      city_name: "Willows",
      country_code: "US"
  },
  {
      id: "WNH",
      name: "Puzhehei Airport",
      area_code: "WNH",
      city_name: "Wenshan",
      country_code: "CN"
  },
  {
      id: "WTW",
      name: "Wutong Airport",
      area_code: "WTW",
      city_name: "WUTONG",
      country_code: "CN"
  },
  {
      id: "WUA",
      name: "Wuhai Airport",
      area_code: "WUA",
      city_name: "Wuhai",
      country_code: "CN"
  },
  {
      id: "WUT",
      name: "WUTAISHAN AIRPORT",
      area_code: "WUT",
      city_name: "Xinzhou",
      country_code: "CN"
  },
  {
      id: "WWW",
      name: "Wuwei Airport",
      area_code: "WWW",
      city_name: "WUWEI",
      country_code: "CN"
  },
  {
      id: "XEN",
      name: "Xingcheng",
      area_code: "XEN",
      city_name: "Xingcheng",
      country_code: "CN"
  },
  {
      id: "XIC",
      name: "Qingshan Airport",
      area_code: "XIC",
      city_name: "Xichang",
      country_code: "CN"
  },
  {
      id: "XIL",
      name: "Xilinhot Airport",
      area_code: "XIL",
      city_name: "Xilinhot",
      country_code: "CN"
  },
  {
      id: "XIN",
      name: "Xingning",
      area_code: "XIN",
      city_name: "Xingning",
      country_code: "CN"
  },
  {
      id: "XYW",
      name: "Xiaoyi Airport",
      area_code: "XYW",
      city_name: "XIAOYI",
      country_code: "CN"
  },
  {
      id: "XYY",
      name: "Xinyu Airport",
      area_code: "XYY",
      city_name: "Wittenberg",
      country_code: "DE"
  },
  {
      id: "XZW",
      name: "Xinzhou Airport",
      area_code: "XZW",
      city_name: "XINZHOU",
      country_code: "CN"
  },
  {
      id: "XZZ",
      name: "Xuanzhou Airport",
      area_code: "XZZ",
      city_name: "XUANZHOU",
      country_code: "CN"
  },
  {
      id: "YBP",
      name: "Caiba Airport",
      area_code: "YBP",
      city_name: "Yibin",
      country_code: "CN"
  },
  {
      id: "YCU",
      name: "Guangong Airport",
      area_code: "YCU",
      city_name: "Yuncheng",
      country_code: "CN"
  },
  {
      id: "YCY",
      name: "CLYDE RIVER AIRPORT",
      area_code: "YCY",
      city_name: "Clyde River",
      country_code: "CA"
  },
  {
      id: "YHW",
      name: "Yuhang Airport",
      area_code: "YHW",
      city_name: "YUHANG",
      country_code: "CN"
  },
  {
      id: "YIH",
      name: "Sanxia Airport",
      area_code: "YIH",
      city_name: "Yichang",
      country_code: "CN"
  },
  {
      id: "YIW",
      name: "Yiwu Airport",
      area_code: "YIW",
      city_name: "Yiwu",
      country_code: "CN"
  },
  {
      id: "YKW",
      name: "Yongkang Airport",
      area_code: "YKW",
      city_name: "YONGKANG",
      country_code: "CN"
  },
  {
      id: "YLN",
      name: "Yilan",
      area_code: "YLN",
      city_name: "Yilan",
      country_code: "CN"
  },
  {
      id: "YNJ",
      name: "Chaoyangchuan Airport",
      area_code: "YNJ",
      city_name: "Yanji",
      country_code: "CN"
  },
  {
      id: "YNZ",
      name: "Nanyang Airport",
      area_code: "YNZ",
      city_name: "Yancheng",
      country_code: "CN"
  },
  {
      id: "YQW",
      name: "Cameron McIntosh",
      area_code: "YQW",
      city_name: "North Battleford",
      country_code: "CA"
  },
  {
      id: "YTW",
      name: "Yingtan Airport",
      area_code: "YTW",
      city_name: "YINGTAN",
      country_code: "CN"
  },
  {
      id: "YUA",
      name: "Yuanmou",
      area_code: "YUA",
      city_name: "Yuanmou",
      country_code: "CN"
  },
  {
      id: "YUS",
      name: "Batang Airport",
      area_code: "YUS",
      city_name: "Yushu",
      country_code: "CN"
  },
  {
      id: "YXG",
      name: "Xisha Airport",
      area_code: "YXG",
      city_name: "yangxin",
      country_code: "CN"
  },
  {
      id: "YXW",
      name: "Yixing Airport",
      area_code: "YXW",
      city_name: "YIXING",
      country_code: "CN"
  },
  {
      id: "YXX",
      name: "Abbotsford International Airport",
      area_code: "YXX",
      city_name: "Abbotsford",
      country_code: "CA"
  },
  {
      id: "YYW",
      name: "Armstrong",
      area_code: "YYW",
      city_name: "Armstrong",
      country_code: "CA"
  },
  {
      id: "YZW",
      name: "Teslin",
      area_code: "YZW",
      city_name: "Teslin",
      country_code: "CA"
  },
  {
      id: "ZHY",
      name: "Xiangshan Airport",
      area_code: "ZHY",
      city_name: "Zhongwei",
      country_code: "CN"
  },
  {
      id: "ZJG",
      name: "Jenpeg",
      area_code: "ZJG",
      city_name: "Jenpeg",
      country_code: "CA"
  },
  {
      id: "ZJJ",
      name: "Procida Harbour Airport",
      area_code: "ZJJ",
      city_name: "ZHUJI",
      country_code: "CN"
  },
  {
      id: "ZJW",
      name: "Zhenjiang Airport",
      area_code: "ZJW",
      city_name: "Arnhem City",
      country_code: "NL"
  },
  {
      id: "ZSW",
      name: "Seal Cove SPB",
      area_code: "YPR",
      city_name: "Prince Rupert",
      country_code: "CA"
  },
  {
      id: "ZYI",
      name: "Xinzhou Airport",
      area_code: "ZYI",
      city_name: "Zunyi",
      country_code: "CN"
  },
  {
      id: "ZYW",
      name: "Zhaoyuan Airport",
      area_code: "ZYW",
      city_name: "ZHAOYUAN",
      country_code: "CN"
  },
  {
      id: "ZZW",
      name: "Zhangzhou Airport",
      area_code: "ZZW",
      city_name: "ZHANGZHOU",
      country_code: "CN"
  },
  {
      id: "HIA",
      name: "Lianshui Airport",
      area_code: "HIA",
      city_name: "Huai'an",
      country_code: "CN"
  },
  {
      id: "RLK",
      name: "Tianjitai Airport",
      area_code: "RLK",
      city_name: "Bayannur",
      country_code: "CN"
  },
  {
      id: "JIQ",
      name: "Wulingshan Airport",
      area_code: "JIQ",
      city_name: "Qianjiang",
      country_code: "CN"
  },
  {
      id: "YIE",
      name: "Yiershi Airport",
      area_code: "YIE",
      city_name: "Aershan",
      country_code: "CN"
  },
  {
      id: "RKZ",
      name: "Peace Airport",
      area_code: "RKZ",
      city_name: "Xigaze/Rikaze",
      country_code: "CN"
  },
  {
      id: "JUB",
      name: "JUBA AIRPORT",
      area_code: "JUB",
      city_name: "Juba",
      country_code: "SS"
  },
  {
      id: "JIC",
      name: "Jinchuan Airport",
      area_code: "JIC",
      city_name: "Jinchang",
      country_code: "CN"
  },
  {
      id: "YZY",
      name: "Ganzhou Airport",
      area_code: "YZY",
      city_name: "Zhangye",
      country_code: "CN"
  },
  {
      id: "TLQ",
      name: "Jiaohe Airport",
      area_code: "TLQ",
      city_name: "Turpan",
      country_code: "CN"
  },
  {
      id: "WXN",
      name: "Wuqiao Airport",
      area_code: "WXN",
      city_name: "Wanzhou",
      country_code: "CN"
  },
  {
      id: "ERL",
      name: "Saiwusu International Airport",
      area_code: "ERL",
      city_name: "Erenhot",
      country_code: "CN"
  },
  {
      id: "NGQ",
      name: "Ngari Gunsa Airport",
      area_code: "NGQ",
      city_name: "Shiquanhe",
      country_code: "CN"
  },
  {
      id: "GYU",
      name: "Liupanshan Airport",
      area_code: "GYU",
      city_name: "Guyuan",
      country_code: "CN"
  },
  {
      id: "HND",
      name: "Haneda Airport",
      area_code: "TYO",
      city_name: "Tokyo",
      country_code: "JP"
  },
  {
      id: "AMD",
      name: "Sardar Vallabhbhai Patel International Airport",
      area_code: "AMD",
      city_name: "Ahmedabad",
      country_code: "IN"
  },
  {
      id: "ARU",
      name: "Aracatuba Airport",
      area_code: "ARU",
      city_name: "Aracatuba",
      country_code: "BR"
  },
  {
      id: "FOR",
      name: "Pinto Martins International Airport",
      area_code: "FOR",
      city_name: "Fortaleza",
      country_code: "BR"
  },
  {
      id: "JOI",
      name: "Lauro Carneiro de Loyola Airport",
      area_code: "JOI",
      city_name: "Joinville",
      country_code: "BR"
  },
  {
      id: "LDB",
      name: "Londrina Airport",
      area_code: "LDB",
      city_name: "Londrina",
      country_code: "BR"
  },
  {
      id: "AAL",
      name: "Aalborg Airport",
      area_code: "AAL",
      city_name: "Aalborg",
      country_code: "DK"
  },
  {
      id: "IKA",
      name: "Imam Khomeini International Airport",
      area_code: "THR",
      city_name: "Tehran",
      country_code: "IR"
  },
  {
      id: "HFE",
      name: "Xinqiao International Airport",
      area_code: "HFE",
      city_name: "Hefei",
      country_code: "CN"
  },
  {
      id: "BFJ",
      name: "FEIXIONG Airport",
      area_code: "BFJ",
      city_name: "Bijie",
      country_code: "CN"
  },
  {
      id: "GTO",
      name: "TOLOTIO AIRPORT",
      area_code: "GTO",
      city_name: "Gorontalo",
      country_code: "ID"
  },
  {
      id: "BTH",
      name: "Hang Nadim Airport",
      area_code: "BTH",
      city_name: "Batam",
      country_code: "ID"
  },
  {
      id: "FKS",
      name: "Fukushima Airport",
      area_code: "FKS",
      city_name: "Fukushima",
      country_code: "JP"
  },
  {
      id: "WKJ",
      name: "WAKKANAI AIRPORT",
      area_code: "WKJ",
      city_name: "Wakkanai",
      country_code: "JP"
  },
  {
      id: "MYJ",
      name: "Matsuyama Airport",
      area_code: "MYJ",
      city_name: "Matsuyama",
      country_code: "JP"
  },
  {
      id: "KWJ",
      name: "Gwangju Airport",
      area_code: "KWJ",
      city_name: "Gwangju",
      country_code: "KR"
  },
  {
      id: "REP",
      name: "Angkor International Airport",
      area_code: "REP",
      city_name: "Siem Reap",
      country_code: "KH"
  },
  {
      id: "UAI",
      name: "Suai",
      area_code: "UAI",
      city_name: "Suai",
      country_code: "TL"
  },
  {
      id: "PLM",
      name: "Sultan Mahmud Badaruddin II Airport",
      area_code: "PLM",
      city_name: "Palembang",
      country_code: "ID"
  },
  {
      id: "PNK",
      name: "SUPADIO AIRPORT",
      area_code: "PNK",
      city_name: "Pontianak",
      country_code: "ID"
  },
  {
      id: "UPG",
      name: "Sultan Hasanuddin International Airport",
      area_code: "UPG",
      city_name: "Makassar",
      country_code: "ID"
  },
  {
      id: "MYY",
      name: "Miri Airport",
      area_code: "MYY",
      city_name: "Miri",
      country_code: "MY"
  },
  {
      id: "PBH",
      name: "PARO AIRPORT",
      area_code: "PBH",
      city_name: "Paro",
      country_code: "BT"
  },
  {
      id: "CCJ",
      name: "Kozhikode Airport",
      area_code: "CCJ",
      city_name: "Kozhikode",
      country_code: "IN"
  },
  {
      id: "HYD",
      name: "Rajiv Gandhi International Airport",
      area_code: "HYD",
      city_name: "Hyderabad",
      country_code: "IN"
  },
  {
      id: "LBD",
      name: "Khujand Airport",
      area_code: "LBD",
      city_name: "Khujand",
      country_code: "TJ"
  },
  {
      id: "DMM",
      name: "King Fahd International Airport",
      area_code: "DMM",
      city_name: "Dammam",
      country_code: "SA"
  },
  {
      id: "RIY",
      name: "Riyan",
      area_code: "RIY",
      city_name: "Mukalla",
      country_code: "YE"
  },
  {
      id: "LST",
      name: "Launceston Airport",
      area_code: "LST",
      city_name: "Launceston",
      country_code: "AU"
  },
  {
      id: "ZNE",
      name: "NEWMAN AIRPORT",
      area_code: "ZNE",
      city_name: "Newman",
      country_code: "AU"
  },
  {
      id: "PHE",
      name: "Port Hedland International Airport",
      area_code: "PHE",
      city_name: "Port Hedland",
      country_code: "AU"
  },
  {
      id: "ALR",
      name: "Alexandra",
      area_code: "ALR",
      city_name: "Alexandra",
      country_code: "NZ"
  },
  {
      id: "AMZ",
      name: "Ardmore",
      area_code: "AMZ",
      city_name: "Ardmore",
      country_code: "NZ"
  },
  {
      id: "HLZ",
      name: "Hamilton International Airport",
      area_code: "HLZ",
      city_name: "Hamilton",
      country_code: "NZ"
  },
  {
      id: "NPE",
      name: "Hawke's Bay Airport",
      area_code: "NPE",
      city_name: "Napier/Hastings",
      country_code: "NZ"
  },
  {
      id: "NPL",
      name: "New Plymouth Airport",
      area_code: "NPL",
      city_name: "New Plymouth",
      country_code: "NZ"
  },
  {
      id: "LAE",
      name: "Nadzab Airport",
      area_code: "LAE",
      city_name: "Lae",
      country_code: "PG"
  },
  {
      id: "TFM",
      name: "Telefomin",
      area_code: "TFM",
      city_name: "Telefomin",
      country_code: "PG"
  },
  {
      id: "TBU",
      name: "Fua'amotu International Airport",
      area_code: "TBU",
      city_name: "Nuku'alofa",
      country_code: "TO"
  },
  {
      id: "CXI",
      name: "Cassidy International Airport",
      area_code: "CXI",
      city_name: "Kiritimati",
      country_code: "KI"
  },
  {
      id: "MRV",
      name: "MINERALNYE VODY AIRPORT",
      area_code: "MRV",
      city_name: "Mineralnye Vody",
      country_code: "RU"
  },
  {
      id: "DNK",
      name: "Dnepropetrovsk Airport",
      area_code: "DNK",
      city_name: "Dnipropetrovsk",
      country_code: "UA"
  },
  {
      id: "DOK",
      name: "International",
      area_code: "DOK",
      city_name: "Donetsk",
      country_code: "UA"
  },
  {
      id: "IFO",
      name: "Ivano-Frankovsk Airport",
      area_code: "IFO",
      city_name: "Ivano-Frankivsk",
      country_code: "UA"
  },
  {
      id: "LWO",
      name: "Lviv Airport",
      area_code: "LWO",
      city_name: "Lviv",
      country_code: "UA"
  },
  {
      id: "ODS",
      name: "Odessa International Airport",
      area_code: "ODS",
      city_name: "Odesa",
      country_code: "UA"
  },
  {
      id: "HUY",
      name: "Humberside Airport",
      area_code: "HUY",
      city_name: "Humberside",
      country_code: "GB"
  },
  {
      id: "MME",
      name: "Tees Valley Airport",
      area_code: "MME",
      city_name: "Durham",
      country_code: "GB"
  },
  {
      id: "VBS",
      name: "Brescia/Montichiari",
      area_code: "VRN",
      city_name: "Verona",
      country_code: "IT"
  },
  {
      id: "XIX",
      name: "PORTA NUOVA RAIL STATION",
      area_code: "VRN",
      city_name: "Verona",
      country_code: "IT"
  },
  {
      id: "VRN",
      name: "Verona Airport",
      area_code: "VRN",
      city_name: "Verona",
      country_code: "IT"
  },
  {
      id: "LEI",
      name: "Almeria Airport",
      area_code: "LEI",
      city_name: "Almeria",
      country_code: "ES"
  },
  {
      id: "GRO",
      name: "Costa Brava Airport",
      area_code: "GRO",
      city_name: "Girona",
      country_code: "ES"
  },
  {
      id: "GRX",
      name: "Granada Airport",
      area_code: "GRX",
      city_name: "Granada",
      country_code: "ES"
  },
  {
      id: "XRY",
      name: "Jerez Airport",
      area_code: "XRY",
      city_name: "Jerez",
      country_code: "ES"
  },
  {
      id: "LCG",
      name: "La Coruna Airport",
      area_code: "LCG",
      city_name: "A Coruna",
      country_code: "ES"
  },
  {
      id: "ACE",
      name: "Lanzarote Airport",
      area_code: "ACE",
      city_name: "Lanzarote",
      country_code: "ES"
  },
  {
      id: "LPA",
      name: "Gran Canaria Airport",
      area_code: "LPA",
      city_name: "Gran Canaria",
      country_code: "ES"
  },
  {
      id: "LEN",
      name: "LEON AIRPORT",
      area_code: "LEN",
      city_name: "Leon",
      country_code: "ES"
  },
  {
      id: "MAH",
      name: "Menorca Airport",
      area_code: "MAH",
      city_name: "Menorca",
      country_code: "ES"
  },
  {
      id: "MJV",
      name: "San Javier Airport",
      area_code: "MJV",
      city_name: "Murcia",
      country_code: "ES"
  },
  {
      id: "OVD",
      name: "Asturias Airport",
      area_code: "OVD",
      city_name: "Asturias",
      country_code: "ES"
  },
  {
      id: "PNA",
      name: "PAMPLONA AIRPORT",
      area_code: "PNA",
      city_name: "Pamplona",
      country_code: "ES"
  },
  {
      id: "FUE",
      name: "Fuerteventura Airport",
      area_code: "FUE",
      city_name: "Fuerteventura",
      country_code: "ES"
  },
  {
      id: "REU",
      name: "REUS AIRPORT",
      area_code: "REU",
      city_name: "Reus",
      country_code: "ES"
  },
  {
      id: "EAS",
      name: "SAN SEBASTIAN AIRPORT",
      area_code: "EAS",
      city_name: "San Sebastian",
      country_code: "ES"
  },
  {
      id: "GMZ",
      name: "LA GOMERA AIRPORT",
      area_code: "GMZ",
      city_name: "San Sebastian/Gomera",
      country_code: "ES"
  },
  {
      id: "SPC",
      name: "LA PALMA AIRPORT",
      area_code: "SPC",
      city_name: "Santa Cruz de la Palma",
      country_code: "ES"
  },
  {
      id: "SDR",
      name: "Santander Airport",
      area_code: "SDR",
      city_name: "Santander",
      country_code: "ES"
  },
  {
      id: "SCQ",
      name: "Santiago de Compostela Airport",
      area_code: "SCQ",
      city_name: "Santiago de Compostela",
      country_code: "ES"
  },
  {
      id: "TFN",
      name: "Tenerife North Airport",
      area_code: "TCI",
      city_name: "Tenerife",
      country_code: "ES"
  },
  {
      id: "TFS",
      name: "Tenerife South Airport",
      area_code: "TCI",
      city_name: "Tenerife",
      country_code: "ES"
  },
  {
      id: "VDE",
      name: "El Hierro Airport",
      area_code: "VDE",
      city_name: "Valverde",
      country_code: "ES"
  },
  {
      id: "VGO",
      name: "Vigo Airport",
      area_code: "VGO",
      city_name: "Vigo",
      country_code: "ES"
  },
  {
      id: "FAE",
      name: "VAGAR AIRPORT",
      area_code: "FAE",
      city_name: "Faroe Islands",
      country_code: "FO"
  },
  {
      id: "TDJ",
      name: "Tadjoura",
      area_code: "TDJ",
      city_name: "Tadjoura",
      country_code: "DJ"
  },
  {
      id: "BDI",
      name: "Bird Island",
      area_code: "BDI",
      city_name: "Bird Island",
      country_code: "SC"
  },
  {
      id: "DEI",
      name: "Denis Island",
      area_code: "DEI",
      city_name: "Denis Island",
      country_code: "SC"
  },
  {
      id: "DES",
      name: "Desroches Island",
      area_code: "DES",
      city_name: "Desroches Island",
      country_code: "SC"
  },
  {
      id: "FRK",
      name: "Fregate Island",
      area_code: "FRK",
      city_name: "Fregate Island",
      country_code: "SC"
  },
  {
      id: "SEZ",
      name: "Seychelles International Airport",
      area_code: "SEZ",
      city_name: "Mahe Island",
      country_code: "SC"
  },
  {
      id: "PRI",
      name: "PRASLIN ISLAND AIRPORT",
      area_code: "PRI",
      city_name: "Praslin Island",
      country_code: "SC"
  },
  {
      id: "HGA",
      name: "Hargeisa Airport",
      area_code: "HGA",
      city_name: "Hargeisa",
      country_code: "SO"
  },
  {
      id: "SID",
      name: "Amilcar Cabral International Airport",
      area_code: "SID",
      city_name: "Sal Island",
      country_code: "CV"
  },
  {
      id: "PHC",
      name: "Port Hedland International Airport",
      area_code: "PHC",
      city_name: "Port Harcourt",
      country_code: "NG"
  },
  {
      id: "BOY",
      name: "Bobo Dioulasso Airport",
      area_code: "BOY",
      city_name: "Bobo Dioulasso",
      country_code: "BF"
  },
  {
      id: "MJN",
      name: "Amborovy Airport",
      area_code: "MJN",
      city_name: "Mahajanga",
      country_code: "MG"
  },
  {
      id: "RUN",
      name: "Roland Garros Airport",
      area_code: "RUN",
      city_name: "St-Denis",
      country_code: "RE"
  },
  {
      id: "MUB",
      name: "MAUN AIRPORT",
      area_code: "MUB",
      city_name: "Maun",
      country_code: "BW"
  },
  {
      id: "MTS",
      name: "Matsapha International",
      area_code: "MTS",
      city_name: "Manzini",
      country_code: "SZ"
  },
  {
      id: "ASI",
      name: "Ascension Island",
      area_code: "ASI",
      city_name: "Ascension Island",
      country_code: "SH"
  },
  {
      id: "SSH",
      name: "Sharm el Sheikh International Airport",
      area_code: "SSH",
      city_name: "Sharm el-Sheikh",
      country_code: "EG"
  },
  {
      id: "BEN",
      name: "Benina International Airport",
      area_code: "BEN",
      city_name: "Benghazi",
      country_code: "LY"
  },
  {
      id: "PNR",
      name: "POINTE NOIRE AIRPORT",
      area_code: "PNR",
      city_name: "Pointe-Noire",
      country_code: "CG"
  },
  {
      id: "POG",
      name: "Port Gentil Airport",
      area_code: "POG",
      city_name: "Port-Gentil",
      country_code: "GA"
  },
  {
      id: "CUN",
      name: "Cancun International Airport",
      area_code: "CUN",
      city_name: "Cancun",
      country_code: "MX"
  },
  {
      id: "ZIH",
      name: "Ixtapa-Zihuatanejo International Airport",
      area_code: "ZIH",
      city_name: "Ixtapa/Zihuatanejo",
      country_code: "MX"
  },
  {
      id: "PVR",
      name: "Gustavo Diaz Ordaz International Airport",
      area_code: "PVR",
      city_name: "Puerto Vallarta",
      country_code: "MX"
  },
  {
      id: "SJD",
      name: "Los Cabos International Airport",
      area_code: "SJD",
      city_name: "San Jose del Cabo",
      country_code: "MX"
  },
  {
      id: "LCE",
      name: "Goloson International Airport",
      area_code: "LCE",
      city_name: "La Ceiba",
      country_code: "HN"
  },
  {
      id: "SAP",
      name: "Ramon Villeda Morales Airport",
      area_code: "SAP",
      city_name: "San Pedro Sula",
      country_code: "HN"
  },
  {
      id: "POP",
      name: "Gregorio Luperon International Airport",
      area_code: "POP",
      city_name: "Puerto Plata",
      country_code: "DO"
  },
  {
      id: "POS",
      name: "Piarco International Airport",
      area_code: "POS",
      city_name: "Port of Spain",
      country_code: "TT"
  },
  {
      id: "NGD",
      name: "Auguste George Airport",
      area_code: "NGD",
      city_name: "Anegada",
      country_code: "VG"
  },
  {
      id: "JCD",
      name: "DOWNTOWN HELIPORT",
      area_code: "JCD",
      city_name: "U.S. Virgin Islands",
      country_code: "US"
  },
  {
      id: "PLI",
      name: "Palm Island",
      area_code: "PLI",
      city_name: "Palm Island",
      country_code: "VC"
  },
  {
      id: "PLU",
      name: "Pampulha Airport",
      area_code: "BHZ",
      city_name: "Belo Horizonte",
      country_code: "BR"
  },
  {
      id: "CNF",
      name: "Tancredo Neves International Airport",
      area_code: "BHZ",
      city_name: "Belo Horizonte",
      country_code: "BR"
  },
  {
      id: "CXJ",
      name: "Caxias do Sul Airport",
      area_code: "CXJ",
      city_name: "Caxias do Sul",
      country_code: "BR"
  },
  {
      id: "FLN",
      name: "Hercilio Luz International Airport",
      area_code: "FLN",
      city_name: "Florianopolis",
      country_code: "BR"
  },
  {
      id: "NAT",
      name: "Augusto Severo International Airport",
      area_code: "NAT",
      city_name: "Natal",
      country_code: "BR"
  },
  {
      id: "PMW",
      name: "Palmas Airport",
      area_code: "PMW",
      city_name: "Palmas",
      country_code: "BR"
  },
  {
      id: "BPS",
      name: "PORTO SEGURO AIRPORT",
      area_code: "BPS",
      city_name: "Porto Seguro",
      country_code: "BR"
  },
  {
      id: "PVH",
      name: "J.T. de Oliveira International Airport",
      area_code: "PVH",
      city_name: "Porto Velho",
      country_code: "BR"
  },
  {
      id: "RAO",
      name: "LEITE LOPES AIRPORT",
      area_code: "RAO",
      city_name: "Ribeirao Preto",
      country_code: "BR"
  },
  {
      id: "SJP",
      name: "SAO JOSE DO RIO PRETO AIRPORT",
      area_code: "SJP",
      city_name: "Sao Jose do Rio Preto",
      country_code: "BR"
  },
  {
      id: "UNA",
      name: "Ilha Comandatuba/Una",
      area_code: "UNA",
      city_name: "Ilha Comandatuba/Una",
      country_code: "BR"
  },
  {
      id: "MDZ",
      name: "EL PLUMERILLO AIRPORT",
      area_code: "MDZ",
      city_name: "Mendoza",
      country_code: "AR"
  },
  {
      id: "RES",
      name: "RESISTENCIA AIRPORT",
      area_code: "RES",
      city_name: "RESISTENCIA",
      country_code: "AR"
  },
  {
      id: "BRC",
      name: "San Carlos de Bariloche Airport",
      area_code: "BRC",
      city_name: "San Carlos Bariloche",
      country_code: "AR"
  },
  {
      id: "PMC",
      name: "El Tepual Airport",
      area_code: "PMC",
      city_name: "Puerto Montt",
      country_code: "CL"
  },
  {
      id: "PUQ",
      name: "Pres Ibanez Airport",
      area_code: "PUQ",
      city_name: "Punta Arenas",
      country_code: "CL"
  },
  {
      id: "YYG",
      name: "Charlottetown Airport",
      area_code: "YYG",
      city_name: "Charlottetown",
      country_code: "CA"
  },
  {
      id: "XNA",
      name: "Northwest Arkansas Regional Airport",
      area_code: "FYV",
      city_name: "Fayetteville/Springd.",
      country_code: "US"
  },
  {
      id: "PRC",
      name: "Prescott Airport",
      area_code: "PRC",
      city_name: "Prescott",
      country_code: "US"
  },
  {
      id: "CSL",
      name: "O SULLIVAN ARMY AIR FIELD",
      area_code: "CSL",
      city_name: "San Luis Obispo",
      country_code: "US"
  },
  {
      id: "JLB",
      name: "LONG BEACH HELIPORT",
      area_code: "LGB",
      city_name: "Long Beach",
      country_code: "US"
  },
  {
      id: "JCE",
      name: "CONVENTION CENTER HELIPORT",
      area_code: "OAK",
      city_name: "Oakland",
      country_code: "US"
  },
  {
      id: "SCK",
      name: "Stockton Metropolitan Airport",
      area_code: "SAC",
      city_name: "Sacramento",
      country_code: "US"
  },
  {
      id: "ASE",
      name: "Pitkin County Airport",
      area_code: "ASE",
      city_name: "Aspen",
      country_code: "US"
  },
  {
      id: "GJT",
      name: "Grand Junction Regional Airport",
      area_code: "GJT",
      city_name: "Grand Junction",
      country_code: "US"
  },
  {
      id: "MTJ",
      name: "Montrose Regional Airport",
      area_code: "MTJ",
      city_name: "Montrose",
      country_code: "US"
  },
  {
      id: "TEX",
      name: "Telluride Regional Airport",
      area_code: "TEX",
      city_name: "Telluride",
      country_code: "US"
  },
  {
      id: "DAB",
      name: "Daytona Beach International Airport",
      area_code: "DAB",
      city_name: "Daytona Beach",
      country_code: "US"
  },
  {
      id: "RSW",
      name: "Southwest Florida International Airport",
      area_code: "FMY",
      city_name: "Fort Myers",
      country_code: "US"
  },
  {
      id: "FMY",
      name: "Page Field",
      area_code: "FMY",
      city_name: "Fort Myers",
      country_code: "US"
  },
  {
      id: "BMI",
      name: "Central Illinois Regional Airport",
      area_code: "BMI",
      city_name: "Bloomington/Normal",
      country_code: "US"
  },
  {
      id: "FEP",
      name: "Albertus",
      area_code: "FEP",
      city_name: "Freeport",
      country_code: "US"
  },
  {
      id: "RID",
      name: "Municipal",
      area_code: "RID",
      city_name: "Richmond",
      country_code: "US"
  },
  {
      id: "ESF",
      name: "Esler Regional",
      area_code: "AEX",
      city_name: "Alexandria",
      country_code: "US"
  },
  {
      id: "AEX",
      name: "Alexandria International Airport",
      area_code: "AEX",
      city_name: "Alexandria",
      country_code: "US"
  },
  {
      id: "APN",
      name: "ALPENA COUNTY REGIONAL AIRPORT",
      area_code: "APN",
      city_name: "Alpena",
      country_code: "US"
  },
  {
      id: "HBG",
      name: "Bobby L. Chain Muni",
      area_code: "LUL",
      city_name: "Hattiesburg/Laurel",
      country_code: "US"
  },
  {
      id: "ELM",
      name: "Elmira Regional Airport",
      area_code: "ELM",
      city_name: "Elmira/Corning",
      country_code: "US"
  },
  {
      id: "HPN",
      name: "Westchester County Airport",
      area_code: "HPN",
      city_name: "White Plains",
      country_code: "US"
  },
  {
      id: "LBE",
      name: "Arnold Palmer Regional Airport",
      area_code: "LBE",
      city_name: "Latrobe",
      country_code: "US"
  },
  {
      id: "CUB",
      name: "J.Hamilton-L.B. Owens",
      area_code: "CAE",
      city_name: "Columbia",
      country_code: "US"
  },
  {
      id: "CLL",
      name: "Easterwood Field",
      area_code: "CLL",
      city_name: "College Station",
      country_code: "US"
  },
  {
      id: "CRP",
      name: "Corpus Christi International Airport",
      area_code: "CRP",
      city_name: "Corpus Christi",
      country_code: "US"
  },
  {
      id: "CUX",
      name: "Cuddihy Field",
      area_code: "CRP",
      city_name: "Corpus Christi",
      country_code: "US"
  },
  {
      id: "ILE",
      name: "Skylark Field",
      area_code: "ILE",
      city_name: "Killeen/Fort Hood",
      country_code: "US"
  },
  {
      id: "MFE",
      name: "Miller International Airport",
      area_code: "MFE",
      city_name: "McAllen/Mission",
      country_code: "US"
  },
  {
      id: "TYR",
      name: "Pounds Regional Airport",
      area_code: "TYR",
      city_name: "Tyler",
      country_code: "US"
  },
  {
      id: "RMQ",
      name: "Ching Chuang Kang Airport",
      area_code: "RMQ",
      city_name: "Taichung",
      country_code: "TW"
  },
  {
      id: "DME",
      name: "Domodedovo International Airport",
      area_code: "MOW",
      city_name: "Moscow",
      country_code: "RU"
  },
  {
      id: "FSZ",
      name: "Mount Fuji Airport",
      area_code: "FSZ",
      city_name: "Shizuoka",
      country_code: "JP"
  },
  {
      id: "YOK",
      name: "YOKOHOMA AIRPORT",
      area_code: "YOK",
      city_name: "Yokohama",
      country_code: "JP"
  },
  {
      id: "LBU",
      name: "Labuan Airport",
      area_code: "LBU",
      city_name: "Labuan",
      country_code: "MY"
  },
  {
      id: "SBW",
      name: "Sibu Airport",
      area_code: "SBW",
      city_name: "Sibu",
      country_code: "MY"
  },
  {
      id: "TGG",
      name: "Sultan Mahmud Airport",
      area_code: "TGG",
      city_name: "Kuala Terengganu",
      country_code: "MY"
  },
  {
      id: "AJL",
      name: "Aizawl Airport",
      area_code: "AJL",
      city_name: "Aizawl",
      country_code: "IN"
  },
  {
      id: "BBI",
      name: "Biju Patnaik Airport",
      area_code: "BBI",
      city_name: "Bhubaneshwar",
      country_code: "IN"
  },
  {
      id: "BDQ",
      name: "Vadodara Airport",
      area_code: "BDQ",
      city_name: "Vadodara",
      country_code: "IN"
  },
  {
      id: "CJJ",
      name: "Cheong Ju International Airport",
      area_code: "CJJ",
      city_name: "Cheongju",
      country_code: "KR"
  },
  {
      id: "CRK",
      name: "Clark International Airport",
      area_code: "CRK",
      city_name: "Angeles/Mabalacat",
      country_code: "PH"
  },
  {
      id: "DAD",
      name: "Da Nang International Airport",
      area_code: "DAD",
      city_name: "Da Nang",
      country_code: "VN"
  },
  {
      id: "PQC",
      name: "Phu Quoc International Airport",
      area_code: "PQC",
      city_name: "Phu Quoc Island",
      country_code: "VN"
  },
  {
      id: "HUI",
      name: "Phu Bai International Airport",
      area_code: "HUI",
      city_name: "Hue",
      country_code: "VN"
  },
  {
      id: "RBE",
      name: "Ratanakiri",
      area_code: "RBE",
      city_name: "Ratanakiri",
      country_code: "KH"
  },
  {
      id: "HDY",
      name: "Hat Yai International Airport",
      area_code: "HDY",
      city_name: "Hat Yai",
      country_code: "TH"
  },
  {
      id: "URT",
      name: "Surat Thani Airport",
      area_code: "URT",
      city_name: "Surat Thani",
      country_code: "TH"
  },
  {
      id: "MED",
      name: "Mohammad Bin Abdulaziz Airport",
      area_code: "MED",
      city_name: "Madinah",
      country_code: "SA"
  },
  {
      id: "GZA",
      name: "Yasser Arafat Intl",
      area_code: "GZA",
      city_name: "Gaza",
      country_code: "PS"
  },
  {
      id: "ECN",
      name: "Ercan Airport",
      area_code: "ECN",
      city_name: "Ercan",
      country_code: "CY"
  },
  {
      id: "PFO",
      name: "Paphos International Airport",
      area_code: "PFO",
      city_name: "Paphos",
      country_code: "CY"
  },
  {
      id: "ADF",
      name: "ADIYAMAN AIRPORT",
      area_code: "ADF",
      city_name: "Adiyaman",
      country_code: "TR"
  },
  {
      id: "AJI",
      name: "AGRI AIRPORT",
      area_code: "AJI",
      city_name: "Agri",
      country_code: "TR"
  },
  {
      id: "BJV",
      name: "Milas Airport",
      area_code: "BJV",
      city_name: "Bodrum",
      country_code: "TR"
  },
  {
      id: "CKZ",
      name: "CANAKKALE AIRPORT",
      area_code: "CKZ",
      city_name: "Canakkale",
      country_code: "TR"
  },
  {
      id: "DLM",
      name: "Dalaman Airport",
      area_code: "DLM",
      city_name: "Dalaman",
      country_code: "TR"
  },
  {
      id: "DNZ",
      name: "Cardak Airport",
      area_code: "DNZ",
      city_name: "Denizli",
      country_code: "TR"
  },
  {
      id: "ERC",
      name: "ERZINCAN AIRPORT",
      area_code: "ERC",
      city_name: "Erzincan",
      country_code: "TR"
  },
  {
      id: "EZS",
      name: "ELAZIG AIRPORT",
      area_code: "EZS",
      city_name: "Elazig",
      country_code: "TR"
  },
  {
      id: "KSY",
      name: "KARS AIRPORT",
      area_code: "KSY",
      city_name: "Kars",
      country_code: "TR"
  },
  {
      id: "KYA",
      name: "Konya Airport",
      area_code: "KYA",
      city_name: "Konya",
      country_code: "TR"
  },
  {
      id: "MQM",
      name: "Mardin Airport",
      area_code: "MQM",
      city_name: "Mardin",
      country_code: "TR"
  },
  {
      id: "MSR",
      name: "MUS AIRPORT",
      area_code: "MSR",
      city_name: "Mus",
      country_code: "TR"
  },
  {
      id: "NAV",
      name: "Kapadokya Airport",
      area_code: "NAV",
      city_name: "Nevsehir",
      country_code: "TR"
  },
  {
      id: "SFQ",
      name: "Sanliurfa Airport",
      area_code: "SFQ",
      city_name: "Sanliurfa",
      country_code: "TR"
  },
  {
      id: "SZF",
      name: "Carsamba Airport",
      area_code: "SZF",
      city_name: "Samsun",
      country_code: "TR"
  },
  {
      id: "TZX",
      name: "Trabzon Airport",
      area_code: "TZX",
      city_name: "Trabzon",
      country_code: "TR"
  },
  {
      id: "VAS",
      name: "SIVAS AIRPORT",
      area_code: "VAS",
      city_name: "Sivas",
      country_code: "TR"
  },
  {
      id: "GYD",
      name: "Heydar Aliyev International Airport",
      area_code: "BAK",
      city_name: "Baku",
      country_code: "AZ"
  },
  {
      id: "LPQ",
      name: "Luang Prabang International Airport",
      area_code: "LPQ",
      city_name: "Luang Prabang",
      country_code: "LA"
  },
  {
      id: "MMX",
      name: "Malmo Airport",
      area_code: "MMA",
      city_name: "Malmo",
      country_code: "SE"
  },
  {
      id: "BLL",
      name: "Billund Airport",
      area_code: "BLL",
      city_name: "Billund",
      country_code: "DK"
  },
  {
      id: "BFD",
      name: "BRADFORD AIRPORT",
      area_code: "BFD",
      city_name: "Bradford",
      country_code: "US"
  },
  {
      id: "KJA",
      name: "Yemelyanovo Airport",
      area_code: "KJA",
      city_name: "Krasnoyarsk",
      country_code: "RU"
  },
  {
      id: "BGY",
      name: "Bergamo Airport",
      area_code: "MIL",
      city_name: "Milan",
      country_code: "IT"
  },
  {
      id: "NSI",
      name: "Nsimalen International Airport",
      area_code: "YAO",
      city_name: "Yaounde",
      country_code: "CM"
  },
  {
      id: "LAU",
      name: "Lamu Airport",
      area_code: "LAU",
      city_name: "Lamu",
      country_code: "KE"
  },
  {
      id: "MYD",
      name: "MALINDI AIRPORT",
      area_code: "MYD",
      city_name: "Malindi",
      country_code: "KE"
  },
  {
      id: "ROB",
      name: "Roberts International Airport",
      area_code: "MLW",
      city_name: "Monrovia",
      country_code: "LR"
  },
  {
      id: "GRJ",
      name: "George Airport",
      area_code: "GRJ",
      city_name: "George",
      country_code: "ZA"
  },
  {
      id: "MQP",
      name: "Kruger Mpumalanga International Airport",
      area_code: "NLP",
      city_name: "Nelspruit",
      country_code: "ZA"
  },
  {
      id: "PLZ",
      name: "Port Elizabeth Airport",
      area_code: "PLZ",
      city_name: "Port Elizabeth",
      country_code: "ZA"
  },
  {
      id: "MWZ",
      name: "MWANZA AIRPORT",
      area_code: "MWZ",
      city_name: "Mwanza",
      country_code: "TZ"
  },
  {
      id: "NLA",
      name: "Ndola Airport",
      area_code: "NLA",
      city_name: "Ndola",
      country_code: "ZM"
  },
  {
      id: "ZSE",
      name: "Pierrefonds Airport",
      area_code: "ZSE",
      city_name: "St-Pierre",
      country_code: "RE"
  },
  {
      id: "LEV",
      name: "Bureta",
      area_code: "LEV",
      city_name: "Levuka",
      country_code: "FJ"
  },
  {
      id: "VAV",
      name: "Vava'U",
      area_code: "VAV",
      city_name: "Vava'u",
      country_code: "TO"
  },
  {
      id: "NHV",
      name: "NUKU HIVA AIRPORT",
      area_code: "NHV",
      city_name: "Nuku Hiva",
      country_code: "PF"
  },
  {
      id: "NUK",
      name: "NUKUTAVAKE AIRPORT",
      area_code: "NUK",
      city_name: "Nukutavake",
      country_code: "PF"
  },
  {
      id: "FUT",
      name: "Futuna Island Airport",
      area_code: "FUT",
      city_name: "Futuna Island",
      country_code: "WF"
  },
  {
      id: "SUN",
      name: "Friedman Memorial Airport",
      area_code: "SUN",
      city_name: "Hailey/Sun Valley",
      country_code: "US"
  },
  {
      id: "GTR",
      name: "Golden Triangle Regional Airport",
      area_code: "UBS",
      city_name: "Columbus",
      country_code: "US"
  },
  {
      id: "BDL",
      name: "Bradley International Airport",
      area_code: "HFD",
      city_name: "Hartford",
      country_code: "US"
  },
  {
      id: "MSL",
      name: "Northwest Alabama Regional Airport",
      area_code: "MSL",
      city_name: "Muscle Shoals/Florence",
      country_code: "US"
  },
  {
      id: "PIB",
      name: "Hattiesburg–Laurel Regional Airport",
      area_code: "LUL",
      city_name: "Hattiesburg/Laurel",
      country_code: "US"
  },
  {
      id: "KGD",
      name: "Khrabrovo Airport",
      area_code: "KGD",
      city_name: "Kaliningrad",
      country_code: "RU"
  },
  {
      id: "ISU",
      name: "SULAYMANIYAH INTERNATIONAL AIRPORT",
      area_code: "ISU",
      city_name: "Sulaymaniyah",
      country_code: "IQ"
  },
  {
      id: "KLO",
      name: "Kalibo Airport",
      area_code: "KLO",
      city_name: "Kalibo",
      country_code: "PH"
  },
  {
      id: "JUH",
      name: "Jiuhuashan Airport",
      area_code: "JUH",
      city_name: "Chizhou",
      country_code: "CN"
  },
  {
      id: "YIC",
      name: "MINGYUESHAN Airport",
      area_code: "YIC",
      city_name: "Yichun (Jiangxi)",
      country_code: "CN"
  },
  {
      id: "KSC",
      name: "Kosice Airport",
      area_code: "KSC",
      city_name: "Kosice",
      country_code: "SK"
  },
  {
      id: "OSM",
      name: "Mosul International Airport",
      area_code: "OSM",
      city_name: "Mosul",
      country_code: "IQ"
  },
  {
      id: "JGD",
      name: "JIAGEDAQI AIRPORT",
      area_code: "JGD",
      city_name: "Jiagedaqi",
      country_code: "CN"
  },
  {
      id: "AXF",
      name: "Alashanzuoqi Airport",
      area_code: "AXF",
      city_name: "Alxa Left Banner",
      country_code: "CN"
  },
  {
      id: "ZQZ",
      name: "Zhangjiakou Airport",
      area_code: "ZQZ",
      city_name: "Zhangjiakou",
      country_code: "CN"
  },
  {
      id: "DIU",
      name: "DIU AIRPORT",
      area_code: "DIU",
      city_name: "Diu",
      country_code: "IN"
  },
  {
      id: "BQS",
      name: "Blagoveschensk Airport",
      area_code: "BQS",
      city_name: "Blagoveshchensk",
      country_code: "RU"
  },
  {
      id: "DCY",
      name: "Yading Airport",
      area_code: "DCY",
      city_name: "Daocheng",
      country_code: "CN"
  },
  {
      id: "LLV",
      name: "Lvliang Airport",
      area_code: "LLV",
      city_name: "Luliang",
      country_code: "CN"
  },
  {
      id: "HPG",
      name: "Hongping Airport",
      area_code: "HPG",
      city_name: "Shennongjia",
      country_code: "CN"
  },
  {
      id: "AHJ",
      name: "Aba Hongyuan Airport",
      area_code: "AHJ",
      city_name: "Aba/Hongyuan",
      country_code: "CN"
  },
  {
      id: "HXD",
      name: "Delingha Airport",
      area_code: "HXD",
      city_name: "Delingha",
      country_code: "CN"
  },
  {
      id: "NIG",
      name: "Nikunau",
      area_code: "NIG",
      city_name: "Nikunau",
      country_code: "KI"
  },
  {
      id: "AAA",
      name: "ANAA AIRPORT",
      area_code: "AAA",
      city_name: "Anaa",
      country_code: "PF"
  },
  {
      id: "AAC",
      name: "International",
      area_code: "AAC",
      city_name: "El Arish",
      country_code: "EG"
  },
  {
      id: "AAF",
      name: "Regional",
      area_code: "AAF",
      city_name: "Apalachicola",
      country_code: "US"
  },
  {
      id: "AAG",
      name: "Avelino Vieira",
      area_code: "AAG",
      city_name: "Arapoti",
      country_code: "BR"
  },
  {
      id: "AAH",
      name: "Merzbrueck",
      area_code: "AAH",
      city_name: "Aachen",
      country_code: "DE"
  },
  {
      id: "AAM",
      name: "Mala Mala",
      area_code: "AAM",
      city_name: "Mala Mala",
      country_code: "ZA"
  },
  {
      id: "AAQ",
      name: "ANAPA AIRPORT",
      area_code: "AAQ",
      city_name: "Anapa",
      country_code: "RU"
  },
  {
      id: "AAR",
      name: "Aarhus Airport",
      area_code: "AAR",
      city_name: "Aarhus",
      country_code: "DK"
  },
  {
      id: "AAX",
      name: "ARAXA AIRPORT",
      area_code: "AAX",
      city_name: "Araxa",
      country_code: "BR"
  },
  {
      id: "AAY",
      name: "Al Ghaydah",
      area_code: "AAY",
      city_name: "Al Ghaydah",
      country_code: "YE"
  },
  {
      id: "ABB",
      name: "Asaba International Airport",
      area_code: "ABB",
      city_name: "Asaba",
      country_code: "NG"
  },
  {
      id: "ABC",
      name: "Airport",
      area_code: "ABC",
      city_name: "Albacete",
      country_code: "ES"
  },
  {
      id: "DYS",
      name: "Dyess AFB",
      area_code: "ABI",
      city_name: "Abilene",
      country_code: "US"
  },
  {
      id: "ABL",
      name: "AMBLER AIRPORT",
      area_code: "ABL",
      city_name: "Ambler",
      country_code: "US"
  },
  {
      id: "ABM",
      name: "Bamaga Airport",
      area_code: "ABM",
      city_name: "Bamaga",
      country_code: "AU"
  },
  {
      id: "ABS",
      name: "ABU SIMBEL AIRPORT",
      area_code: "ABS",
      city_name: "Abu Simbel",
      country_code: "EG"
  },
  {
      id: "ABT",
      name: "Al-aqiq Airport",
      area_code: "ABT",
      city_name: "Al Baha",
      country_code: "SA"
  },
  {
      id: "ACD",
      name: "Alcides Fernandez",
      area_code: "ACD",
      city_name: "Acandi",
      country_code: "CO"
  },
  {
      id: "ACH",
      name: "St Gallen Altenrhein Airport",
      area_code: "ACH",
      city_name: "Altenrhein",
      country_code: "CH"
  },
  {
      id: "ACI",
      name: "Alderney Airport",
      area_code: "ACI",
      city_name: "Alderney",
      country_code: "GB"
  },
  {
      id: "ACR",
      name: "ARARACUARA AIRPORT",
      area_code: "ACR",
      city_name: "Araracuara",
      country_code: "CO"
  },
  {
      id: "CNW",
      name: "TSTC Waco",
      area_code: "ACT",
      city_name: "Waco",
      country_code: "US"
  },
  {
      id: "ADG",
      name: "Lenawee County",
      area_code: "ADG",
      city_name: "Adrian",
      country_code: "US"
  },
  {
      id: "ADK",
      name: "Adak Island Airport",
      area_code: "ADK",
      city_name: "Adak Island",
      country_code: "US"
  },
  {
      id: "ADM",
      name: "Municipal",
      area_code: "ADM",
      city_name: "Ardmore",
      country_code: "US"
  },
  {
      id: "AHD",
      name: "Downtown Executive",
      area_code: "ADM",
      city_name: "Ardmore",
      country_code: "US"
  },
  {
      id: "KDK",
      name: "Municipal",
      area_code: "ADQ",
      city_name: "Kodiak",
      country_code: "US"
  },
  {
      id: "ADT",
      name: "Municipal",
      area_code: "ADT",
      city_name: "Ada",
      country_code: "US"
  },
  {
      id: "ADW",
      name: "Joint Base Andrews",
      area_code: "ADW",
      city_name: "Camp Springs",
      country_code: "US"
  },
  {
      id: "ADX",
      name: "Leuchars RAF",
      area_code: "ADX",
      city_name: "St Andrews",
      country_code: "GB"
  },
  {
      id: "AEL",
      name: "Municipal",
      area_code: "AEL",
      city_name: "Albert Lea",
      country_code: "US"
  },
  {
      id: "AER",
      name: "Sochi International Airport",
      area_code: "AER",
      city_name: "Sochi",
      country_code: "RU"
  },
  {
      id: "AES",
      name: "Vigra Airport",
      area_code: "AES",
      city_name: "Alesund",
      country_code: "NO"
  },
  {
      id: "AFA",
      name: "SAN RAFAEL AIRPORT",
      area_code: "AFA",
      city_name: "San Rafael",
      country_code: "AR"
  },
  {
      id: "AFK",
      name: "Kondavattavan Tank SPB",
      area_code: "ADP",
      city_name: "Ampara",
      country_code: "LK"
  },
  {
      id: "AFL",
      name: "Alta Floresta Airport",
      area_code: "AFL",
      city_name: "Alta Floresta",
      country_code: "BR"
  },
  {
      id: "AFS",
      name: "Zarafshan",
      area_code: "AFS",
      city_name: "Zarafshan",
      country_code: "UZ"
  },
  {
      id: "AFY",
      name: "Afyon",
      area_code: "AFY",
      city_name: "Afyon",
      country_code: "TR"
  },
  {
      id: "AGE",
      name: "Wangerooge",
      area_code: "AGE",
      city_name: "Wangerooge",
      country_code: "DE"
  },
  {
      id: "AGH",
      name: "ANGELHOLM AIRPORT",
      area_code: "AGH",
      city_name: "Angelholm/Helsingborg",
      country_code: "SE"
  },
  {
      id: "AGN",
      name: "Angoon Seaplane Base",
      area_code: "AGN",
      city_name: "Angoon",
      country_code: "US"
  },
  {
      id: "AGO",
      name: "Municipal",
      area_code: "AGO",
      city_name: "Magnolia",
      country_code: "US"
  },
  {
      id: "AGQ",
      name: "G.Karaiskakis",
      area_code: "AGQ",
      city_name: "Agrinion",
      country_code: "GR"
  },
  {
      id: "DNL",
      name: "Daniel Field",
      area_code: "AGS",
      city_name: "Augusta",
      country_code: "US"
  },
  {
      id: "AGT",
      name: "Guarani International Airport",
      area_code: "AGT",
      city_name: "Ciudad del Este",
      country_code: "PY"
  },
  {
      id: "AGU",
      name: "Jesus Teran Peredo International Airport",
      area_code: "AGU",
      city_name: "Aguascalientes",
      country_code: "MX"
  },
  {
      id: "AGX",
      name: "AGATTI ISLAND AIRPORT",
      area_code: "AGX",
      city_name: "Agatti Island",
      country_code: "IN"
  },
  {
      id: "AHC",
      name: "Amedee AAF",
      area_code: "AHC",
      city_name: "Herlong",
      country_code: "US"
  },
  {
      id: "AHI",
      name: "Amahai",
      area_code: "AHI",
      city_name: "Amahai",
      country_code: "ID"
  },
  {
      id: "AHN",
      name: "Ben Epps",
      area_code: "AHN",
      city_name: "Athens",
      country_code: "US"
  },
  {
      id: "AHO",
      name: "Fertilia Airport",
      area_code: "AHO",
      city_name: "Alghero",
      country_code: "IT"
  },
  {
      id: "AHU",
      name: "Charif Al Idrissi Airport",
      area_code: "AHU",
      city_name: "Al Hoceima",
      country_code: "MA"
  },
  {
      id: "AID",
      name: "Municipal",
      area_code: "AID",
      city_name: "Anderson",
      country_code: "US"
  },
  {
      id: "AIK",
      name: "Municipal",
      area_code: "AIK",
      city_name: "Aiken",
      country_code: "US"
  },
  {
      id: "AIO",
      name: "Municipal",
      area_code: "AIO",
      city_name: "Atlantic",
      country_code: "US"
  },
  {
      id: "AIT",
      name: "Aitutaki Airport",
      area_code: "AIT",
      city_name: "Aitutaki Island",
      country_code: "CK"
  },
  {
      id: "AIV",
      name: "George Downer",
      area_code: "AIV",
      city_name: "Aliceville",
      country_code: "US"
  },
  {
      id: "AIZ",
      name: "Lee C Fine Memorial",
      area_code: "AIZ",
      city_name: "Kaiser/Lake Ozark",
      country_code: "US"
  },
  {
      id: "AJF",
      name: "JOUF AIRPORT",
      area_code: "AJF",
      city_name: "Jouf",
      country_code: "SA"
  },
  {
      id: "AJR",
      name: "ARVIDSJAUR AIRPORT",
      area_code: "AJR",
      city_name: "Arvidsjaur",
      country_code: "SE"
  },
  {
      id: "AKB",
      name: "ATKA AIRPORT",
      area_code: "AKB",
      city_name: "Atka",
      country_code: "US"
  },
  {
      id: "AKD",
      name: "Akola",
      area_code: "AKD",
      city_name: "Akola",
      country_code: "IN"
  },
  {
      id: "AKK",
      name: "Akhiok Airport",
      area_code: "AKK",
      city_name: "Akhiok",
      country_code: "US"
  },
  {
      id: "AKN",
      name: "KING SALMON AIRPORT",
      area_code: "AKN",
      city_name: "King Salmon",
      country_code: "US"
  },
  {
      id: "AKO",
      name: "Colorado Plains Rgnl",
      area_code: "AKO",
      city_name: "Akron",
      country_code: "US"
  },
  {
      id: "AKS",
      name: "Gwaunaru'u Airport",
      area_code: "AKS",
      city_name: "Auki",
      country_code: "SB"
  },
  {
      id: "AKV",
      name: "AKULIVIK AIRPORT",
      area_code: "AKV",
      city_name: "Akulivik",
      country_code: "CA"
  },
  {
      id: "AKX",
      name: "Aktobe Airport",
      area_code: "AKX",
      city_name: "Aktobe",
      country_code: "KZ"
  },
  {
      id: "ALE",
      name: "Casparis Municipal",
      area_code: "ALE",
      city_name: "Alpine",
      country_code: "US"
  },
  {
      id: "ALF",
      name: "ALTA AIRPORT",
      area_code: "ALF",
      city_name: "Alta",
      country_code: "NO"
  },
  {
      id: "ALL",
      name: "Albenga",
      area_code: "ALL",
      city_name: "Albenga",
      country_code: "IT"
  },
  {
      id: "HMN",
      name: "Holloman AFB",
      area_code: "ALM",
      city_name: "Alamogordo",
      country_code: "US"
  },
  {
      id: "ALS",
      name: "San Luis Valley Regional Airport",
      area_code: "ALS",
      city_name: "Alamosa",
      country_code: "US"
  },
  {
      id: "ALX",
      name: "Thomas C Russell Field",
      area_code: "ALX",
      city_name: "Alexander City",
      country_code: "US"
  },
  {
      id: "TDW",
      name: "Tradewind",
      area_code: "AMA",
      city_name: "Amarillo",
      country_code: "US"
  },
  {
      id: "AMH",
      name: "Arba Minch Airport",
      area_code: "AMH",
      city_name: "Arba Minch",
      country_code: "ET"
  },
  {
      id: "AMW",
      name: "Municipal",
      area_code: "AMW",
      city_name: "Ames",
      country_code: "US"
  },
  {
      id: "EDF",
      name: "Elmendorf AFB",
      area_code: "ANC",
      city_name: "Anchorage",
      country_code: "US"
  },
  {
      id: "MRI",
      name: "Merrill Field",
      area_code: "ANC",
      city_name: "Anchorage",
      country_code: "US"
  },
  {
      id: "AND",
      name: "Regional",
      area_code: "AND",
      city_name: "Anderson",
      country_code: "US"
  },
  {
      id: "ANE",
      name: "Angers Airport",
      area_code: "ANE",
      city_name: "Angers",
      country_code: "FR"
  },
  {
      id: "ANF",
      name: "Cerro Moreno Airport",
      area_code: "ANF",
      city_name: "Antofagasta",
      country_code: "CL"
  },
  {
      id: "ANI",
      name: "ANIAK AIRPORT",
      area_code: "ANI",
      city_name: "Aniak",
      country_code: "US"
  },
  {
      id: "ANN",
      name: "Annette Island",
      area_code: "ANN",
      city_name: "Annette Island",
      country_code: "US"
  },
  {
      id: "ANP",
      name: "Lee",
      area_code: "ANP",
      city_name: "Annapolis",
      country_code: "US"
  },
  {
      id: "ANW",
      name: "Regional",
      area_code: "ANW",
      city_name: "Ainsworth",
      country_code: "US"
  },
  {
      id: "ANX",
      name: "Andenes Airport",
      area_code: "ANX",
      city_name: "Andenes",
      country_code: "NO"
  },
  {
      id: "AOC",
      name: "Nobitz",
      area_code: "AOC",
      city_name: "Altenburg",
      country_code: "DE"
  },
  {
      id: "AOH",
      name: "Allen County",
      area_code: "AOH",
      city_name: "Lima",
      country_code: "US"
  },
  {
      id: "AOK",
      name: "KARPATHOS AIRPORT",
      area_code: "AOK",
      city_name: "Karpathos",
      country_code: "GR"
  },
  {
      id: "AOL",
      name: "Paso de los Libres",
      area_code: "AOL",
      city_name: "Paso de los Libres",
      country_code: "AR"
  },
  {
      id: "AOS",
      name: "Amook Bay Seaplane Base",
      area_code: "AOS",
      city_name: "Amook Bay",
      country_code: "US"
  },
  {
      id: "AOT",
      name: "Corrado Gex",
      area_code: "AOT",
      city_name: "Aosta",
      country_code: "IT"
  },
  {
      id: "APC",
      name: "Napa County",
      area_code: "APC",
      city_name: "Napa",
      country_code: "US"
  },
  {
      id: "APF",
      name: "Naples Airport",
      area_code: "APF",
      city_name: "Naples",
      country_code: "US"
  },
  {
      id: "APG",
      name: "Phillips AAF",
      area_code: "APG",
      city_name: "Aberdeen",
      country_code: "US"
  },
  {
      id: "APO",
      name: "Apartado Airport",
      area_code: "APO",
      city_name: "Apartado",
      country_code: "CO"
  },
  {
      id: "APS",
      name: "Anapolis",
      area_code: "APS",
      city_name: "Anapolis",
      country_code: "BR"
  },
  {
      id: "APV",
      name: "Apple Valley",
      area_code: "APV",
      city_name: "Apple Valley",
      country_code: "US"
  },
  {
      id: "FGI",
      name: "Fagali I Airport",
      area_code: "APW",
      city_name: "Apia",
      country_code: "WS"
  },
  {
      id: "AQA",
      name: "Bartolomeu de Gusmao",
      area_code: "AQA",
      city_name: "Araraquara",
      country_code: "BR"
  },
  {
      id: "AQI",
      name: "Qaisumah Airport",
      area_code: "AQI",
      city_name: "Qaisumah",
      country_code: "SA"
  },
  {
      id: "ARA",
      name: "Acadiana Regional",
      area_code: "ARA",
      city_name: "New Iberia",
      country_code: "US"
  },
  {
      id: "ARB",
      name: "Municipal",
      area_code: "ARB",
      city_name: "Ann Arbor",
      country_code: "US"
  },
  {
      id: "ARG",
      name: "Regional",
      area_code: "ARG",
      city_name: "Walnut Ridge",
      country_code: "US"
  },
  {
      id: "ARH",
      name: "Talagi Airport",
      area_code: "ARH",
      city_name: "Arkhangelsk",
      country_code: "RU"
  },
  {
      id: "ARK",
      name: "ARUSHA AIRPORT",
      area_code: "ARK",
      city_name: "Arusha",
      country_code: "TZ"
  },
  {
      id: "ARV",
      name: "Lakeland",
      area_code: "ARV",
      city_name: "Minocqua/Woodruff",
      country_code: "US"
  },
  {
      id: "ARY",
      name: "Ararat",
      area_code: "ARY",
      city_name: "Ararat",
      country_code: "AU"
  },
  {
      id: "ASH",
      name: "Boire Field",
      area_code: "ASH",
      city_name: "Nashua",
      country_code: "US"
  },
  {
      id: "ASJ",
      name: "Amami O Shima Airport",
      area_code: "ASJ",
      city_name: "Amami",
      country_code: "JP"
  },
  {
      id: "ASL",
      name: "Harrison County",
      area_code: "ASL",
      city_name: "Marshall",
      country_code: "US"
  },
  {
      id: "ASN",
      name: "Municipal",
      area_code: "ASN",
      city_name: "Talladega",
      country_code: "US"
  },
  {
      id: "ASO",
      name: "ASOSA AIRPORT",
      area_code: "ASO",
      city_name: "Asosa",
      country_code: "ET"
  },
  {
      id: "AST",
      name: "Regional",
      area_code: "AST",
      city_name: "Astoria",
      country_code: "US"
  },
  {
      id: "ATA",
      name: "Anta Airport",
      area_code: "ATA",
      city_name: "Huaraz",
      country_code: "PE"
  },
  {
      id: "ATC",
      name: "Arthur's Town Airport",
      area_code: "ATC",
      city_name: "Arthur's Town",
      country_code: "BS"
  },
  {
      id: "ATI",
      name: "Artigas",
      area_code: "ATI",
      city_name: "Artigas",
      country_code: "UY"
  },
  {
      id: "ATK",
      name: "Edward Burnell Sr. Memorial Airport",
      area_code: "ATK",
      city_name: "Atqasuk",
      country_code: "US"
  },
  {
      id: "FTY",
      name: "Fulton County",
      area_code: "ATL",
      city_name: "Atlanta",
      country_code: "US"
  },
  {
      id: "JAE",
      name: "Shumba Airport",
      area_code: "JAE",
      city_name: "Jaen",
      country_code: "PE"
  },
  {
      id: "JAO",
      name: "Beaver Ruin Airport",
      area_code: "ATL",
      city_name: "Atlanta",
      country_code: "US"
  },
  {
      id: "PDK",
      name: "Peachtree-Dekalb Airport",
      area_code: "ATL",
      city_name: "Atlanta",
      country_code: "US"
  },
  {
      id: "ATM",
      name: "ALTAMIRA AIRPORT",
      area_code: "ATM",
      city_name: "Altamira",
      country_code: "BR"
  },
  {
      id: "ATO",
      name: "Ohio University",
      area_code: "ATO",
      city_name: "Athens",
      country_code: "US"
  },
  {
      id: "ATP",
      name: "Aitape Airstrip",
      area_code: "ATP",
      city_name: "Aitape",
      country_code: "PG"
  },
  {
      id: "TAJ",
      name: "Tadji",
      area_code: "ATP",
      city_name: "Aitape",
      country_code: "PG"
  },
  {
      id: "ATU",
      name: "Casco Cove CGS",
      area_code: "ATU",
      city_name: "Attu Island",
      country_code: "US"
  },
  {
      id: "ATZ",
      name: "Assiut Airport",
      area_code: "ATZ",
      city_name: "Asyut",
      country_code: "EG"
  },
  {
      id: "AUC",
      name: "Arauca Airport",
      area_code: "AUC",
      city_name: "Arauca",
      country_code: "CO"
  },
  {
      id: "AUF",
      name: "Branches",
      area_code: "AUF",
      city_name: "Auxerre",
      country_code: "FR"
  },
  {
      id: "AZI",
      name: "Bateen",
      area_code: "AUH",
      city_name: "Abu Dhabi",
      country_code: "AE"
  },
  {
      id: "DHF",
      name: "Al Dhafra AB",
      area_code: "AUH",
      city_name: "Abu Dhabi",
      country_code: "AE"
  },
  {
      id: "AUI",
      name: "Aua Island",
      area_code: "AUI",
      city_name: "Aua Island",
      country_code: "PG"
  },
  {
      id: "AUM",
      name: "Municipal",
      area_code: "AUM",
      city_name: "Austin",
      country_code: "US"
  },
  {
      id: "AUN",
      name: "Municipal",
      area_code: "AUN",
      city_name: "Auburn",
      country_code: "US"
  },
  {
      id: "AUO",
      name: "Auburn University Rgnl",
      area_code: "AUO",
      city_name: "Auburn",
      country_code: "US"
  },
  {
      id: "AUQ",
      name: "Atuona",
      area_code: "AUQ",
      city_name: "Hiva Oa",
      country_code: "PF"
  },
  {
      id: "AUR",
      name: "Aurillac Airport",
      area_code: "AUR",
      city_name: "Aurillac",
      country_code: "FR"
  },
  {
      id: "AUU",
      name: "Aurukun",
      area_code: "AUU",
      city_name: "Aurukun",
      country_code: "AU"
  },
  {
      id: "AUW",
      name: "Downtown Apt",
      area_code: "AUW",
      city_name: "Wausau",
      country_code: "US"
  },
  {
      id: "AUZ",
      name: "Municipal",
      area_code: "AUZ",
      city_name: "Aurora",
      country_code: "US"
  },
  {
      id: "AVB",
      name: "Air Base",
      area_code: "AVB",
      city_name: "Aviano",
      country_code: "IT"
  },
  {
      id: "AVP",
      name: "Wilkes-Barre International Airport",
      area_code: "AVP",
      city_name: "Wilkes-Barre/Scranton",
      country_code: "US"
  },
  {
      id: "SCR",
      name: "Scranton Municipal Airport",
      area_code: "AVP",
      city_name: "Wilkes-Barre/Scranton",
      country_code: "US"
  },
  {
      id: "WBW",
      name: "Wyoming Valley",
      area_code: "AVP",
      city_name: "Wilkes-Barre/Scranton",
      country_code: "US"
  },
  {
      id: "AWB",
      name: "Awaba",
      area_code: "AWB",
      city_name: "Awaba",
      country_code: "PG"
  },
  {
      id: "AWM",
      name: "Municipal",
      area_code: "AWM",
      city_name: "West Memphis",
      country_code: "US"
  },
  {
      id: "AWZ",
      name: "AHWAZ AIRPORT",
      area_code: "AWZ",
      city_name: "Ahwaz",
      country_code: "IR"
  },
  {
      id: "AXD",
      name: "Dimokritos Airport",
      area_code: "AXD",
      city_name: "Alexandroupolis",
      country_code: "GR"
  },
  {
      id: "AXN",
      name: "Chandler Field",
      area_code: "AXN",
      city_name: "Alexandria",
      country_code: "US"
  },
  {
      id: "AXU",
      name: "AXUM AIRPORT",
      area_code: "AXU",
      city_name: "Axum",
      country_code: "ET"
  },
  {
      id: "AXV",
      name: "Neil Armstrong",
      area_code: "AXV",
      city_name: "Wapakoneta",
      country_code: "US"
  },
  {
      id: "AYR",
      name: "Ayr",
      area_code: "AYR",
      city_name: "Ayr",
      country_code: "AU"
  },
  {
      id: "AYS",
      name: "Ware County",
      area_code: "AYS",
      city_name: "Waycross",
      country_code: "US"
  },
  {
      id: "AZG",
      name: "Pablo L. Sidar",
      area_code: "AZG",
      city_name: "Apatzingan",
      country_code: "MX"
  },
  {
      id: "AZS",
      name: "Samana International Airport",
      area_code: "AZS",
      city_name: "Samana",
      country_code: "DO"
  },
  {
      id: "ZXT",
      name: "Zabrat",
      area_code: "BAK",
      city_name: "Baku",
      country_code: "AZ"
  },
  {
      id: "BAL",
      name: "Batman Airport",
      area_code: "BAL",
      city_name: "Batman",
      country_code: "TR"
  },
  {
      id: "BAO",
      name: "BAN MAK KHAENG",
      area_code: "BAO",
      city_name: "Ban Mak Khaen",
      country_code: "TH"
  },
  {
      id: "BAS",
      name: "Balalae Airport",
      area_code: "BAS",
      city_name: "Balalae",
      country_code: "SB"
  },
  {
      id: "BAT",
      name: "Chafei Amsei",
      area_code: "BAT",
      city_name: "Barretos",
      country_code: "BR"
  },
  {
      id: "JTC",
      name: "Arealva Airport",
      area_code: "JTC",
      city_name: "Bauru",
      country_code: "BR"
  },
  {
      id: "BAY",
      name: "Baia Mare",
      area_code: "BAY",
      city_name: "Baia Mare",
      country_code: "RO"
  },
  {
      id: "BAZ",
      name: "Barcelos",
      area_code: "BAZ",
      city_name: "Barcelos",
      country_code: "BR"
  },
  {
      id: "BBA",
      name: "Balmaceda Airport",
      area_code: "BBA",
      city_name: "Balmaceda",
      country_code: "CL"
  },
  {
      id: "BBH",
      name: "Barth",
      area_code: "BBH",
      city_name: "Stralsund",
      country_code: "DE"
  },
  {
      id: "BBJ",
      name: "Bitburg",
      area_code: "BBJ",
      city_name: "Bitburg",
      country_code: "DE"
  },
  {
      id: "BBK",
      name: "Kasane Airport",
      area_code: "BBK",
      city_name: "Kasane",
      country_code: "BW"
  },
  {
      id: "BBN",
      name: "BARIO AIRPORT",
      area_code: "BBN",
      city_name: "Bario",
      country_code: "MY"
  },
  {
      id: "BBW",
      name: "Municipal",
      area_code: "BBW",
      city_name: "Broken Bow",
      country_code: "US"
  },
  {
      id: "BCB",
      name: "Virginia Tech",
      area_code: "BCB",
      city_name: "Blacksburg",
      country_code: "US"
  },
  {
      id: "BCE",
      name: "Bryce Canyon",
      area_code: "BCE",
      city_name: "Bryce Canyon",
      country_code: "US"
  },
  {
      id: "BCH",
      name: "Cakung",
      area_code: "BCH",
      city_name: "Baucau",
      country_code: "TL"
  },
  {
      id: "BCM",
      name: "Bacau International Airport",
      area_code: "BCM",
      city_name: "Bacau",
      country_code: "RO"
  },
  {
      id: "BCT",
      name: "Boca Raton",
      area_code: "BCT",
      city_name: "Boca Raton",
      country_code: "US"
  },
  {
      id: "BDD",
      name: "Badu Island",
      area_code: "BDD",
      city_name: "Badu Island",
      country_code: "AU"
  },
  {
      id: "BDU",
      name: "BARDUFOSS AIRPORT",
      area_code: "BDU",
      city_name: "Bardufoss",
      country_code: "NO"
  },
  {
      id: "BEB",
      name: "BENBECULA AIRPORT",
      area_code: "BEB",
      city_name: "Benbecula",
      country_code: "GB"
  },
  {
      id: "BED",
      name: "Laurence G Hanscom Fld",
      area_code: "BED",
      city_name: "Bedford",
      country_code: "US"
  },
  {
      id: "BJY",
      name: "Batajnica",
      area_code: "BEG",
      city_name: "Belgrade",
      country_code: "RS"
  },
  {
      id: "BEK",
      name: "Bareilly",
      area_code: "BEK",
      city_name: "Bareilly",
      country_code: "IN"
  },
  {
      id: "BEM",
      name: "Beni Mellal Airport",
      area_code: "BEM",
      city_name: "Beni Mellal",
      country_code: "MA"
  },
  {
      id: "BEP",
      name: "Bellary",
      area_code: "BEP",
      city_name: "Bellary",
      country_code: "IN"
  },
  {
      id: "BEQ",
      name: "Honington RAF",
      area_code: "BEQ",
      city_name: "Bury St Edmunds",
      country_code: "GB"
  },
  {
      id: "BET",
      name: "Bethel Airport",
      area_code: "BET",
      city_name: "Bethel",
      country_code: "US"
  },
  {
      id: "JBT",
      name: "Sea Plane Base",
      area_code: "BET",
      city_name: "Bethel",
      country_code: "US"
  },
  {
      id: "BEU",
      name: "BEDOURIE AIRPORT",
      area_code: "BEU",
      city_name: "Bedourie",
      country_code: "AU"
  },
  {
      id: "BEZ",
      name: "Beru",
      area_code: "BEZ",
      city_name: "Beru",
      country_code: "KI"
  },
  {
      id: "BFC",
      name: "Bloomfield River",
      area_code: "BFC",
      city_name: "Bloomfield River",
      country_code: "AU"
  },
  {
      id: "BFF",
      name: "SCOTTS BLUFF COUNTY AIRPORT",
      area_code: "BFF",
      city_name: "Scottsbluff",
      country_code: "US"
  },
  {
      id: "BFG",
      name: "Bullfrog Basin",
      area_code: "BFG",
      city_name: "Glen Canyon",
      country_code: "US"
  },
  {
      id: "BFP",
      name: "Beaver County",
      area_code: "BFP",
      city_name: "Beaver Falls",
      country_code: "US"
  },
  {
      id: "BFQ",
      name: "Bahia Pinas Airport",
      area_code: "BFQ",
      city_name: "Bahia Pina",
      country_code: "PA"
  },
  {
      id: "BFR",
      name: "Virgil I Grissom Muni",
      area_code: "BFR",
      city_name: "Bedford",
      country_code: "US"
  },
  {
      id: "BFT",
      name: "Beaufort County",
      area_code: "BFT",
      city_name: "Beaufort",
      country_code: "US"
  },
  {
      id: "BFV",
      name: "Buri Ram Airport",
      area_code: "BFV",
      city_name: "Buriram",
      country_code: "TH"
  },
  {
      id: "BGG",
      name: "Bingol Airport",
      area_code: "BGG",
      city_name: "Bingol",
      country_code: "TR"
  },
  {
      id: "BGQ",
      name: "Big Lake",
      area_code: "BGQ",
      city_name: "Big Lake",
      country_code: "US"
  },
  {
      id: "BGT",
      name: "Bagdad",
      area_code: "BGT",
      city_name: "Bagdad",
      country_code: "US"
  },
  {
      id: "BGV",
      name: "Bento Goncalves",
      area_code: "BGV",
      city_name: "Bento Goncalves",
      country_code: "BR"
  },
  {
      id: "BHB",
      name: "Hancock County Airport",
      area_code: "BHB",
      city_name: "Bar Harbor",
      country_code: "US"
  },
  {
      id: "BHL",
      name: "Bahia de los Angeles",
      area_code: "BHL",
      city_name: "Bahia de los Angeles",
      country_code: "MX"
  },
  {
      id: "BHV",
      name: "BAHAWALPUR AIRPORT",
      area_code: "BHV",
      city_name: "Bahawalpur",
      country_code: "PK"
  },
  {
      id: "BID",
      name: "State",
      area_code: "BID",
      city_name: "Block Island",
      country_code: "US"
  },
  {
      id: "BIG",
      name: "Allen AAF",
      area_code: "DJN",
      city_name: "Delta Junction",
      country_code: "US"
  },
  {
      id: "BIH",
      name: "Eastern Sierra Rgnl",
      area_code: "BIH",
      city_name: "Bishop",
      country_code: "US"
  },
  {
      id: "BII",
      name: "Enyu",
      area_code: "BII",
      city_name: "Bikini Atoll",
      country_code: "MH"
  },
  {
      id: "BIM",
      name: "Bimini International Airport",
      area_code: "BIM",
      city_name: "Bimini",
      country_code: "BS"
  },
  {
      id: "NSB",
      name: "North Seaplane Base",
      area_code: "BIM",
      city_name: "Bimini",
      country_code: "BS"
  },
  {
      id: "BIY",
      name: "Bhisho",
      area_code: "BIY",
      city_name: "Bhisho",
      country_code: "ZA"
  },
  {
      id: "BJA",
      name: "Bejaia Airport",
      area_code: "BJA",
      city_name: "Bejaia",
      country_code: "DZ"
  },
  {
      id: "BJC",
      name: "Rocky Mountain Metrop.",
      area_code: "DEN",
      city_name: "Denver",
      country_code: "US"
  },
  {
      id: "BJF",
      name: "Batsfjord Airport",
      area_code: "BJF",
      city_name: "Batsfjord",
      country_code: "NO"
  },
  {
      id: "BJR",
      name: "BAHAR DAR AIRPORT",
      area_code: "BJR",
      city_name: "Bahar Dar",
      country_code: "ET"
  },
  {
      id: "BJT",
      name: "Bentota River SPB",
      area_code: "BJT",
      city_name: "Bentota",
      country_code: "LK"
  },
  {
      id: "BKE",
      name: "Municipal",
      area_code: "BKE",
      city_name: "Baker City",
      country_code: "US"
  },
  {
      id: "BKG",
      name: "BRANSON AIRPORT",
      area_code: "BKG",
      city_name: "Branson",
      country_code: "US"
  },
  {
      id: "DMK",
      name: "Don Mueang International Airport",
      area_code: "BKK",
      city_name: "Bangkok",
      country_code: "TH"
  },
  {
      id: "BKM",
      name: "Bakalalan Airport",
      area_code: "BKM",
      city_name: "Bakelalan",
      country_code: "MY"
  },
  {
      id: "BKX",
      name: "Regional",
      area_code: "BKX",
      city_name: "Brookings",
      country_code: "US"
  },
  {
      id: "BKZ",
      name: "BUKOBA AIRPORT",
      area_code: "BKZ",
      city_name: "Bukoba",
      country_code: "TZ"
  },
  {
      id: "BLE",
      name: "Dala Airport",
      area_code: "BLE",
      city_name: "Borlange/Falun",
      country_code: "SE"
  },
  {
      id: "BLH",
      name: "Blythe",
      area_code: "BLH",
      city_name: "Blythe",
      country_code: "US"
  },
  {
      id: "BLJ",
      name: "Batna Airport",
      area_code: "BLJ",
      city_name: "Batna",
      country_code: "DZ"
  },
  {
      id: "BLK",
      name: "BLACKPOOL AIRPORT",
      area_code: "BLK",
      city_name: "Blackpool",
      country_code: "GB"
  },
  {
      id: "BLT",
      name: "Blackwater",
      area_code: "BLT",
      city_name: "Blackwater",
      country_code: "AU"
  },
  {
      id: "BLU",
      name: "Blue Canyon-Nyack",
      area_code: "BLU",
      city_name: "Emigrant Gap",
      country_code: "US"
  },
  {
      id: "BLV",
      name: "Scott Air Force Base",
      area_code: "BLV",
      city_name: "Belleville",
      country_code: "US"
  },
  {
      id: "BLX",
      name: "Belluno",
      area_code: "BLX",
      city_name: "Belluno",
      country_code: "IT"
  },
  {
      id: "BML",
      name: "Regional",
      area_code: "BML",
      city_name: "Berlin",
      country_code: "US"
  },
  {
      id: "BMP",
      name: "Brampton Island",
      area_code: "BMP",
      city_name: "Brampton Island",
      country_code: "AU"
  },
  {
      id: "BNF",
      name: "Warm Spring Bay SPB",
      area_code: "BNF",
      city_name: "Baranof",
      country_code: "US"
  },
  {
      id: "BNG",
      name: "Municipal",
      area_code: "BNG",
      city_name: "Banning",
      country_code: "US"
  },
  {
      id: "BNI",
      name: "Benin City Airport",
      area_code: "BNI",
      city_name: "Benin City",
      country_code: "NG"
  },
  {
      id: "BNN",
      name: "Bronnoy",
      area_code: "BNN",
      city_name: "Bronnoysund",
      country_code: "NO"
  },
  {
      id: "BNO",
      name: "Municipal",
      area_code: "BNO",
      city_name: "Burns",
      country_code: "US"
  },
  {
      id: "BNX",
      name: "Banja Luka International Airport",
      area_code: "BNX",
      city_name: "Banja Luka",
      country_code: "BA"
  },
  {
      id: "BOB",
      name: "Motu Mute Airport",
      area_code: "BOB",
      city_name: "Bora Bora",
      country_code: "PF"
  },
  {
      id: "BOC",
      name: "Bocas Del Toro Airport",
      area_code: "BOC",
      city_name: "Bocas del Toro",
      country_code: "PA"
  },
  {
      id: "BOH",
      name: "Bournemouth Airport",
      area_code: "BOH",
      city_name: "Bournemouth",
      country_code: "GB"
  },
  {
      id: "BOJ",
      name: "Burgas Airport",
      area_code: "BOJ",
      city_name: "Burgas",
      country_code: "BG"
  },
  {
      id: "BOK",
      name: "Brookings",
      area_code: "BOK",
      city_name: "Brookings",
      country_code: "US"
  },
  {
      id: "BOT",
      name: "Boset",
      area_code: "BOT",
      city_name: "Boset",
      country_code: "PG"
  },
  {
      id: "BOU",
      name: "Bourges",
      area_code: "BOU",
      city_name: "Bourges",
      country_code: "FR"
  },
  {
      id: "BOW",
      name: "Municipal",
      area_code: "BOW",
      city_name: "Bartow",
      country_code: "US"
  },
  {
      id: "BMT",
      name: "Beaumont Municipal",
      area_code: "BPT",
      city_name: "Beaumont/Port Arthur",
      country_code: "US"
  },
  {
      id: "BQB",
      name: "Busselton Airport",
      area_code: "BQB",
      city_name: "Busselton",
      country_code: "AU"
  },
  {
      id: "BQJ",
      name: "Batagay Airport",
      area_code: "BQJ",
      city_name: "Batagay",
      country_code: "RU"
  },
  {
      id: "BQK",
      name: "Golden Isles Airport",
      area_code: "SSI",
      city_name: "Brunswick",
      country_code: "US"
  },
  {
      id: "BQL",
      name: "BOULIA AIRPORT",
      area_code: "BQL",
      city_name: "Boulia",
      country_code: "AU"
  },
  {
      id: "BQN",
      name: "Rafael Hernandez Airport",
      area_code: "BQN",
      city_name: "Aguadilla",
      country_code: "PR"
  },
  {
      id: "BYO",
      name: "BONITO AIRPORT",
      area_code: "BYO",
      city_name: "Bonito",
      country_code: "BR"
  },
  {
      id: "BRT",
      name: "Bathurst Island",
      area_code: "BRT",
      city_name: "Bathurst Island",
      country_code: "AU"
  },
  {
      id: "CRL",
      name: "South Charleroi Airport",
      area_code: "BRU",
      city_name: "Brussels",
      country_code: "BE"
  },
  {
      id: "BRV",
      name: "Bremerhaven",
      area_code: "BRV",
      city_name: "Bremerhaven",
      country_code: "DE"
  },
  {
      id: "BRX",
      name: "Maria Montez Intl",
      area_code: "BRX",
      city_name: "Barahona",
      country_code: "DO"
  },
  {
      id: "BSC",
      name: "Bahia Solano Airport",
      area_code: "BSC",
      city_name: "Bahia Solano",
      country_code: "CO"
  },
  {
      id: "BSI",
      name: "Balesin Island",
      area_code: "BSI",
      city_name: "Polilio",
      country_code: "PH"
  },
  {
      id: "BSJ",
      name: "Bairnsdale",
      area_code: "BSJ",
      city_name: "Bairnsdale",
      country_code: "AU"
  },
  {
      id: "BSO",
      name: "BASCO AIRPORT",
      area_code: "BSO",
      city_name: "Basco",
      country_code: "PH"
  },
  {
      id: "BSQ",
      name: "Bisbee Municipal",
      area_code: "DUG",
      city_name: "Douglas/Bisbee",
      country_code: "US"
  },
  {
      id: "BTJ",
      name: "Banda Aceh Airport",
      area_code: "BTJ",
      city_name: "Banda Aceh",
      country_code: "ID"
  },
  {
      id: "BTK",
      name: "Bratsk Airport",
      area_code: "BTK",
      city_name: "Bratsk",
      country_code: "RU"
  },
  {
      id: "BTL",
      name: "W.K. Kellogg Regional",
      area_code: "AZO",
      city_name: "Kalamazoo/Battle Creek",
      country_code: "US"
  },
  {
      id: "BTP",
      name: "Butler County",
      area_code: "BTP",
      city_name: "Butler",
      country_code: "US"
  },
  {
      id: "BUA",
      name: "Buka Airport",
      area_code: "BUA",
      city_name: "Buka",
      country_code: "PG"
  },
  {
      id: "AEP",
      name: "Jorge Newbery Airport",
      area_code: "BUE",
      city_name: "Buenos Aires",
      country_code: "AR"
  },
  {
      id: "BUL",
      name: "Bulolo",
      area_code: "BUL",
      city_name: "Bulolo",
      country_code: "PG"
  },
  {
      id: "BUN",
      name: "Buenaventura Airport",
      area_code: "BUN",
      city_name: "Buenaventura",
      country_code: "CO"
  },
  {
      id: "BUP",
      name: "Bhatinda",
      area_code: "BUP",
      city_name: "Bathinda",
      country_code: "IN"
  },
  {
      id: "BUQ",
      name: "Bulawayo Airport",
      area_code: "BUQ",
      city_name: "Bulawayo",
      country_code: "ZW"
  },
  {
      id: "BUW",
      name: "Baubau Airport",
      area_code: "BUW",
      city_name: "Baubau",
      country_code: "ID"
  },
  {
      id: "BUY",
      name: "Bunbury",
      area_code: "BUY",
      city_name: "Bunbury",
      country_code: "AU"
  },
  {
      id: "BUZ",
      name: "BUSHEHR AIRPORT",
      area_code: "BUZ",
      city_name: "Bushehr",
      country_code: "IR"
  },
  {
      id: "BVA",
      name: "Beauvais Tille Airport",
      area_code: "PAR",
      city_name: "Paris",
      country_code: "FR"
  },
  {
      id: "BVE",
      name: "Brive-La-Gaillarde Airport",
      area_code: "BVE",
      city_name: "Brive-la-Gaillarde",
      country_code: "FR"
  },
  {
      id: "BVH",
      name: "Vilhena Airport",
      area_code: "BVH",
      city_name: "Vilhena",
      country_code: "BR"
  },
  {
      id: "BVO",
      name: "Muncipal",
      area_code: "BVO",
      city_name: "Bartlesville",
      country_code: "US"
  },
  {
      id: "BVR",
      name: "Esperadinha",
      area_code: "BVR",
      city_name: "Brava Island",
      country_code: "CV"
  },
  {
      id: "BVU",
      name: "Beluga",
      area_code: "BVU",
      city_name: "Beluga",
      country_code: "US"
  },
  {
      id: "BVV",
      name: "Burevestnik AFB",
      area_code: "ITU",
      city_name: "Iturup Island",
      country_code: "RU"
  },
  {
      id: "BVX",
      name: "Regional",
      area_code: "BVX",
      city_name: "Batesville",
      country_code: "US"
  },
  {
      id: "BVY",
      name: "Municipal",
      area_code: "BVY",
      city_name: "Beverly",
      country_code: "US"
  },
  {
      id: "BWC",
      name: "Municipal",
      area_code: "BWC",
      city_name: "Brawley",
      country_code: "US"
  },
  {
      id: "BWD",
      name: "Regional",
      area_code: "BWD",
      city_name: "Brownwood",
      country_code: "US"
  },
  {
      id: "BWF",
      name: "Walney Island",
      area_code: "BWF",
      city_name: "Barrow-in-Furness",
      country_code: "GB"
  },
  {
      id: "BWG",
      name: "Warren County Regional",
      area_code: "BWG",
      city_name: "Bowling Green",
      country_code: "US"
  },
  {
      id: "BWU",
      name: "Bankstown",
      area_code: "SYD",
      city_name: "Sydney",
      country_code: "AU"
  },
  {
      id: "BWX",
      name: "Blimbingsari Airport",
      area_code: "BWX",
      city_name: "Banyuwangi",
      country_code: "ID"
  },
  {
      id: "BXK",
      name: "Municipal",
      area_code: "BXK",
      city_name: "Buckeye",
      country_code: "US"
  },
  {
      id: "BXN",
      name: "Imsik",
      area_code: "BJV",
      city_name: "Bodrum",
      country_code: "TR"
  },
  {
      id: "BXO",
      name: "Buochs",
      area_code: "BXO",
      city_name: "Buochs",
      country_code: "CH"
  },
  {
      id: "BXS",
      name: "Borrego Valley",
      area_code: "BXS",
      city_name: "Borrego Springs",
      country_code: "US"
  },
  {
      id: "BYF",
      name: "Bray",
      area_code: "BYF",
      city_name: "Albert",
      country_code: "FR"
  },
  {
      id: "BYI",
      name: "Burley Municipal",
      area_code: "BYI",
      city_name: "Burley/Rupert",
      country_code: "US"
  },
  {
      id: "BYJ",
      name: "International",
      area_code: "BYJ",
      city_name: "Beja",
      country_code: "PT"
  },
  {
      id: "BYM",
      name: "Carlos Manuel de Cespedes Airport",
      area_code: "BYM",
      city_name: "Bayamo",
      country_code: "CU"
  },
  {
      id: "BYR",
      name: "Laeso",
      area_code: "BYR",
      city_name: "Laeso",
      country_code: "DK"
  },
  {
      id: "BYS",
      name: "Bicycle Lake AAF",
      area_code: "BYS",
      city_name: "Fort Irwin/Barstow",
      country_code: "US"
  },
  {
      id: "BYU",
      name: "Bindlacher Berg",
      area_code: "BYU",
      city_name: "Bayreuth",
      country_code: "DE"
  },
  {
      id: "BZC",
      name: "Umberto Modiano",
      area_code: "BZC",
      city_name: "Buzios",
      country_code: "BR"
  },
  {
      id: "TZA",
      name: "Belize City Municipal Airport",
      area_code: "BZE",
      city_name: "Belize City",
      country_code: "BZ"
  },
  {
      id: "BZG",
      name: "Ignacy Jan Paderewski Airport",
      area_code: "BZG",
      city_name: "Bydgoszcz",
      country_code: "PL"
  },
  {
      id: "BZH",
      name: "Bumi Hills",
      area_code: "BZH",
      city_name: "Bumi Hills",
      country_code: "ZW"
  },
  {
      id: "BZI",
      name: "Balikesir",
      area_code: "BZI",
      city_name: "Balikesir",
      country_code: "TR"
  },
  {
      id: "MQJ",
      name: "Khonuu Airport",
      area_code: "MQJ",
      city_name: "Khonu",
      country_code: "RU"
  },
  {
      id: "BZK",
      name: "Briansk Airport",
      area_code: "BZK",
      city_name: "Bryansk",
      country_code: "RU"
  },
  {
      id: "BZO",
      name: "Dolomiti",
      area_code: "BZO",
      city_name: "Bolzano/Bozen",
      country_code: "IT"
  },
  {
      id: "BZR",
      name: "Vias Airport",
      area_code: "BZR",
      city_name: "Beziers",
      country_code: "FR"
  },
  {
      id: "BZT",
      name: "Eagle Air Park",
      area_code: "BZT",
      city_name: "Brazoria",
      country_code: "US"
  },
  {
      id: "CAC",
      name: "Cascavel Airport",
      area_code: "CAC",
      city_name: "Cascavel",
      country_code: "BR"
  },
  {
      id: "CAD",
      name: "Wexford County",
      area_code: "CAD",
      city_name: "Cadillac",
      country_code: "US"
  },
  {
      id: "MMT",
      name: "McEntire JNGB",
      area_code: "CAE",
      city_name: "Columbia",
      country_code: "US"
  },
  {
      id: "CAL",
      name: "Campbeltown Airport",
      area_code: "CAL",
      city_name: "Campbeltown",
      country_code: "GB"
  },
  {
      id: "CAM",
      name: "Camiri",
      area_code: "CAM",
      city_name: "Camiri",
      country_code: "BO"
  },
  {
      id: "CAQ",
      name: "Juan H. White",
      area_code: "CAQ",
      city_name: "Caucasia",
      country_code: "CO"
  },
  {
      id: "CAR",
      name: "Municipal",
      area_code: "CAR",
      city_name: "Caribou",
      country_code: "US"
  },
  {
      id: "CAU",
      name: "Oscar Laranjeiras",
      area_code: "CAU",
      city_name: "Caruaru",
      country_code: "BR"
  },
  {
      id: "CAX",
      name: "Carlisle",
      area_code: "CAX",
      city_name: "Carlisle",
      country_code: "GB"
  },
  {
      id: "CBD",
      name: "AFB",
      area_code: "CBD",
      city_name: "Car Nicobar Island",
      country_code: "IN"
  },
  {
      id: "CBF",
      name: "Municipal",
      area_code: "CBF",
      city_name: "Council Bluffs",
      country_code: "US"
  },
  {
      id: "CBK",
      name: "Shalz Field",
      area_code: "CBK",
      city_name: "Colby",
      country_code: "US"
  },
  {
      id: "CBN",
      name: "Cakrabhuwana",
      area_code: "CBN",
      city_name: "Cirebon",
      country_code: "ID"
  },
  {
      id: "CBP",
      name: "Coimbra",
      area_code: "CBP",
      city_name: "Coimbra",
      country_code: "PT"
  },
  {
      id: "CBQ",
      name: "Calabar Airport",
      area_code: "CBQ",
      city_name: "Calabar",
      country_code: "NG"
  },
  {
      id: "CBS",
      name: "Oro Negro",
      area_code: "CBS",
      city_name: "Cabimas",
      country_code: "VE"
  },
  {
      id: "CCA",
      name: "Fort Chaffee",
      area_code: "CCA",
      city_name: "Chimore",
      country_code: "US"
  },
  {
      id: "CCF",
      name: "Salvaza Airport",
      area_code: "CCF",
      city_name: "Carcassonne",
      country_code: "FR"
  },
  {
      id: "CCM",
      name: "Criciuma Airport",
      area_code: "CCM",
      city_name: "Criciuma",
      country_code: "BR"
  },
  {
      id: "CCR",
      name: "Buchanan Field",
      area_code: "CCR",
      city_name: "Concord",
      country_code: "US"
  },
  {
      id: "CCV",
      name: "CRAIG COVE AIRPORT",
      area_code: "CCV",
      city_name: "Craig Cove",
      country_code: "VU"
  },
  {
      id: "CDA",
      name: "Cooinda",
      area_code: "CDA",
      city_name: "Cooinda",
      country_code: "AU"
  },
  {
      id: "CDD",
      name: "Cauquira",
      area_code: "CDD",
      city_name: "Cauquira",
      country_code: "HN"
  },
  {
      id: "CDH",
      name: "Harrell Field",
      area_code: "CDH",
      city_name: "Camden",
      country_code: "US"
  },
  {
      id: "CDI",
      name: "Cachoeiro Itapemirim",
      area_code: "CDI",
      city_name: "Cachoeiro Itapemirim",
      country_code: "BR"
  },
  {
      id: "CDL",
      name: "Candle",
      area_code: "CDL",
      city_name: "Candle",
      country_code: "US"
  },
  {
      id: "CDP",
      name: "Cuddapah",
      area_code: "CDP",
      city_name: "Cuddapah",
      country_code: "IN"
  },
  {
      id: "CDV",
      name: "Mudhole Smith Airport",
      area_code: "CDV",
      city_name: "Cordova",
      country_code: "US"
  },
  {
      id: "CKU",
      name: "Municipal",
      area_code: "CDV",
      city_name: "Cordova",
      country_code: "US"
  },
  {
      id: "CDW",
      name: "Essex County",
      area_code: "CDW",
      city_name: "Caldwell",
      country_code: "US"
  },
  {
      id: "CEC",
      name: "Crescent City Airport",
      area_code: "CEC",
      city_name: "Crescent City",
      country_code: "US"
  },
  {
      id: "CEG",
      name: "Chester Airport",
      area_code: "CEG",
      city_name: "Chester",
      country_code: "GB"
  },
  {
      id: "CEK",
      name: "Chelyabinsk Airport",
      area_code: "CEK",
      city_name: "Chelyabinsk",
      country_code: "RU"
  },
  {
      id: "CES",
      name: "Cessnock",
      area_code: "CES",
      city_name: "Cessnock",
      country_code: "AU"
  },
  {
      id: "CET",
      name: "Le Pontreau",
      area_code: "CET",
      city_name: "Cholet",
      country_code: "FR"
  },
  {
      id: "CEU",
      name: "Oconee County Regional",
      area_code: "CEU",
      city_name: "Clemson",
      country_code: "US"
  },
  {
      id: "CEX",
      name: "Chena Hot Springs",
      area_code: "CEX",
      city_name: "Chena Hot Springs",
      country_code: "US"
  },
  {
      id: "CEY",
      name: "Calloway County",
      area_code: "CEY",
      city_name: "Murray",
      country_code: "US"
  },
  {
      id: "CFB",
      name: "Cabo Frio Airport",
      area_code: "CFB",
      city_name: "Cabo Frio",
      country_code: "BR"
  },
  {
      id: "CFC",
      name: "C.A. da Costa Neves",
      area_code: "CFC",
      city_name: "Cacador",
      country_code: "BR"
  },
  {
      id: "CFG",
      name: "Jaime Gonzalez Airport",
      area_code: "CFG",
      city_name: "Cienfuegos",
      country_code: "CU"
  },
  {
      id: "CFK",
      name: "Chlef Airport",
      area_code: "CFK",
      city_name: "Chlef",
      country_code: "DZ"
  },
  {
      id: "CFQ",
      name: "Creston",
      area_code: "CFQ",
      city_name: "Creston",
      country_code: "CA"
  },
  {
      id: "CFT",
      name: "Greenlee County",
      area_code: "CFT",
      city_name: "Clifton/Morenci",
      country_code: "US"
  },
  {
      id: "CFU",
      name: "KAPODISTRIAS AIRPORT",
      area_code: "CFU",
      city_name: "Kerkyra",
      country_code: "GR"
  },
  {
      id: "CGA",
      name: "Craig Seaplane Base",
      area_code: "CGA",
      city_name: "Craig",
      country_code: "US"
  },
  {
      id: "CGI",
      name: "Cape Girardeau Regional Airport",
      area_code: "CGI",
      city_name: "Cape Girardeau",
      country_code: "US"
  },
  {
      id: "CGY",
      name: "Laguindingan International Airport",
      area_code: "CGY",
      city_name: "Cagayan de Oro",
      country_code: "PH"
  },
  {
      id: "CGZ",
      name: "Municipal",
      area_code: "CGZ",
      city_name: "Casa Grande",
      country_code: "US"
  },
  {
      id: "CHO",
      name: "Albemarle Airport",
      area_code: "CHO",
      city_name: "Charlottesville",
      country_code: "US"
  },
  {
      id: "CHP",
      name: "Circle Hot Springs",
      area_code: "CHP",
      city_name: "Circle Hot Springs",
      country_code: "US"
  },
  {
      id: "CHQ",
      name: "SOUDA AIRPORT",
      area_code: "CHQ",
      city_name: "Chania",
      country_code: "GR"
  },
  {
      id: "CHR",
      name: "Chateauroux Airport",
      area_code: "CHR",
      city_name: "Chateauroux",
      country_code: "FR"
  },
  {
      id: "CHU",
      name: "CHUATHBALUK AIRPORT",
      area_code: "CHU",
      city_name: "Chuathbaluk",
      country_code: "US"
  },
  {
      id: "CHV",
      name: "Chaves",
      area_code: "CHV",
      city_name: "Chaves",
      country_code: "PT"
  },
  {
      id: "CHX",
      name: "Changuinola Airport",
      area_code: "CHX",
      city_name: "Changuinola",
      country_code: "PA"
  },
  {
      id: "CIG",
      name: "Craig-Moffat",
      area_code: "CIG",
      city_name: "Craig",
      country_code: "US"
  },
  {
      id: "CIL",
      name: "Council",
      area_code: "CIL",
      city_name: "Council",
      country_code: "US"
  },
  {
      id: "CIO",
      name: "Carmelo Peralta",
      area_code: "CIO",
      city_name: "Concepcion",
      country_code: "PY"
  },
  {
      id: "CIT",
      name: "Shymkent International Airport",
      area_code: "CIT",
      city_name: "Shymkent",
      country_code: "KZ"
  },
  {
      id: "CIW",
      name: "Canouan",
      area_code: "CIW",
      city_name: "Canouan",
      country_code: "VC"
  },
  {
      id: "CIY",
      name: "Comiso Airport",
      area_code: "CIY",
      city_name: "Comiso",
      country_code: "IT"
  },
  {
      id: "CJC",
      name: "EL LOA AIRPORT",
      area_code: "CJC",
      city_name: "Calama",
      country_code: "CL"
  },
  {
      id: "CJM",
      name: "CHUMPHON AIRPORT",
      area_code: "CJM",
      city_name: "Chumphon",
      country_code: "TH"
  },
  {
      id: "CJN",
      name: "Nusawiru",
      area_code: "CJN",
      city_name: "Cijulang",
      country_code: "ID"
  },
  {
      id: "CKC",
      name: "International",
      area_code: "CKC",
      city_name: "Cherkasy",
      country_code: "UA"
  },
  {
      id: "CKD",
      name: "CROOKED CREEK AIRPORT",
      area_code: "CKD",
      city_name: "Crooked Creek",
      country_code: "US"
  },
  {
      id: "CKE",
      name: "Lampson Field",
      area_code: "CKE",
      city_name: "Lakeport",
      country_code: "US"
  },
  {
      id: "CKM",
      name: "Fletcher Field",
      area_code: "CKM",
      city_name: "Clarksdale",
      country_code: "US"
  },
  {
      id: "CKS",
      name: "CARAJAS AIRPORT",
      area_code: "CKS",
      city_name: "Parauapebas",
      country_code: "BR"
  },
  {
      id: "CKV",
      name: "Montgomery County Rgnl",
      area_code: "CKV",
      city_name: "Clarksville",
      country_code: "US"
  },
  {
      id: "BKL",
      name: "BURKE LAKEFRONT AIRPORT",
      area_code: "CLE",
      city_name: "Cleveland",
      country_code: "US"
  },
  {
      id: "CGF",
      name: "Cuyahoga County",
      area_code: "CLE",
      city_name: "Cleveland",
      country_code: "US"
  },
  {
      id: "CLG",
      name: "Municipal",
      area_code: "CLG",
      city_name: "Coalinga",
      country_code: "US"
  },
  {
      id: "CLQ",
      name: "Miguel de la Madrid Airport",
      area_code: "CLQ",
      city_name: "Colima",
      country_code: "MX"
  },
  {
      id: "CLR",
      name: "Cliff Hatfield Mem.",
      area_code: "CLR",
      city_name: "Calipatria",
      country_code: "US"
  },
  {
      id: "QWG",
      name: "Wilgrove Air Park",
      area_code: "CLT",
      city_name: "Charlotte",
      country_code: "US"
  },
  {
      id: "CLU",
      name: "Municipal",
      area_code: "CLU",
      city_name: "Columbus",
      country_code: "US"
  },
  {
      id: "CLV",
      name: "Caldas Novas Airport",
      area_code: "CLV",
      city_name: "Caldas Novas",
      country_code: "BR"
  },
  {
      id: "CLW",
      name: "Clearwater Air Park",
      area_code: "TPA",
      city_name: "Tampa",
      country_code: "US"
  },
  {
      id: "RML",
      name: "Ratmalana",
      area_code: "CMB",
      city_name: "Colombo",
      country_code: "LK"
  },
  {
      id: "CMF",
      name: "CHAMBERY AIRPORT",
      area_code: "CMF",
      city_name: "Chambery/Aix-les-Bains",
      country_code: "FR"
  },
  {
      id: "CMG",
      name: "Corumba Airport",
      area_code: "CMG",
      city_name: "Corumba",
      country_code: "BR"
  },
  {
      id: "LCK",
      name: "Rickenbacker International Airport",
      area_code: "CMH",
      city_name: "Columbus",
      country_code: "US"
  },
  {
      id: "OSU",
      name: "Ohio State University",
      area_code: "CMH",
      city_name: "Columbus",
      country_code: "US"
  },
  {
      id: "CMQ",
      name: "Clermont",
      area_code: "CMQ",
      city_name: "Clermont",
      country_code: "AU"
  },
  {
      id: "CMR",
      name: "Houssen",
      area_code: "CMR",
      city_name: "Colmar",
      country_code: "FR"
  },
  {
      id: "CMU",
      name: "Chimbu",
      area_code: "CMU",
      city_name: "Kundiawa",
      country_code: "PG"
  },
  {
      id: "CMV",
      name: "Coromandel",
      area_code: "CMV",
      city_name: "Coromandel",
      country_code: "NZ"
  },
  {
      id: "CMY",
      name: "Fort McCoy",
      area_code: "CMY",
      city_name: "Sparta",
      country_code: "US"
  },
  {
      id: "CNC",
      name: "Coconut Island",
      area_code: "CNC",
      city_name: "Coconut Island",
      country_code: "AU"
  },
  {
      id: "CNE",
      name: "Fremont County",
      area_code: "CNE",
      city_name: "Canon City",
      country_code: "US"
  },
  {
      id: "CNG",
      name: "Chateaubernard",
      area_code: "CNG",
      city_name: "Cognac",
      country_code: "FR"
  },
  {
      id: "CNY",
      name: "Canyonlands Field",
      area_code: "CNY",
      city_name: "Moab",
      country_code: "US"
  },
  {
      id: "COA",
      name: "Columbia",
      area_code: "COA",
      city_name: "Columbia",
      country_code: "US"
  },
  {
      id: "COC",
      name: "Comodoro Pierrestegui",
      area_code: "COC",
      city_name: "Concordia",
      country_code: "AR"
  },
  {
      id: "COD",
      name: "YELLOWSTONE REGIONAL AIRPORT",
      area_code: "COD",
      city_name: "Cody",
      country_code: "US"
  },
  {
      id: "COE",
      name: "Pappy Boyington Field",
      area_code: "COE",
      city_name: "Coeur d'Alene",
      country_code: "US"
  },
  {
      id: "COG",
      name: "Condoto",
      area_code: "COG",
      city_name: "Condoto",
      country_code: "CO"
  },
  {
      id: "COH",
      name: "Cooch Behar",
      area_code: "COH",
      city_name: "Cooch Behar",
      country_code: "IN"
  },
  {
      id: "COF",
      name: "Patrick AFB",
      area_code: "COF",
      city_name: "Cocoa Beach",
      country_code: "US"
  },
  {
      id: "COI",
      name: "Merritt Island",
      area_code: "COI",
      city_name: "Merritt Island",
      country_code: "US"
  },
  {
      id: "COJ",
      name: "Coonabarabran",
      area_code: "COJ",
      city_name: "Coonabarabran",
      country_code: "AU"
  },
  {
      id: "CON",
      name: "Municipal",
      area_code: "CON",
      city_name: "Concord",
      country_code: "US"
  },
  {
      id: "AFF",
      name: "USAF Academy Airfield",
      area_code: "COS",
      city_name: "Colorado Springs",
      country_code: "US"
  },
  {
      id: "FCS",
      name: "Fort Carson/Butts AAF",
      area_code: "COS",
      city_name: "Colorado Springs",
      country_code: "US"
  },
  {
      id: "CPB",
      name: "Capurgana",
      area_code: "CPB",
      city_name: "Capurgana",
      country_code: "CO"
  },
  {
      id: "CPM",
      name: "Woodley",
      area_code: "CPM",
      city_name: "Compton",
      country_code: "US"
  },
  {
      id: "CPX",
      name: "Culebra Airport",
      area_code: "CPX",
      city_name: "Culebra",
      country_code: "PR"
  },
  {
      id: "CQD",
      name: "Shahre Kord",
      area_code: "CQD",
      city_name: "Shahre Kord",
      country_code: "IR"
  },
  {
      id: "CQF",
      name: "Airport",
      area_code: "CQF",
      city_name: "Calais/Dunkerque",
      country_code: "FR"
  },
  {
      id: "CQM",
      name: "Central",
      area_code: "CQM",
      city_name: "Ciudad Real",
      country_code: "ES"
  },
  {
      id: "CRA",
      name: "CRAIOVA AIRPORT",
      area_code: "CRA",
      city_name: "Craiova",
      country_code: "RO"
  },
  {
      id: "CRC",
      name: "Santa Ana",
      area_code: "CRC",
      city_name: "Cartago",
      country_code: "CO"
  },
  {
      id: "CRI",
      name: "Crooked Island Airport",
      area_code: "CRI",
      city_name: "Crooked Island",
      country_code: "BS"
  },
  {
      id: "NCP",
      name: "CUBI PT NAVAL AIR STATION",
      area_code: "CRK",
      city_name: "Angeles/Mabalacat",
      country_code: "PH"
  },
  {
      id: "CRM",
      name: "NATIONAL AIRPORT",
      area_code: "CRM",
      city_name: "Catarman",
      country_code: "PH"
  },
  {
      id: "CRO",
      name: "Corcoran",
      area_code: "CRO",
      city_name: "Corcoran",
      country_code: "US"
  },
  {
      id: "NGP",
      name: "NAS (Truax Field)",
      area_code: "CRP",
      city_name: "Corpus Christi",
      country_code: "US"
  },
  {
      id: "NGW",
      name: "Cabaniss Field NOLF",
      area_code: "CRP",
      city_name: "Corpus Christi",
      country_code: "US"
  },
  {
      id: "CRS",
      name: "C. David Campbell Fld",
      area_code: "CRS",
      city_name: "Corsicana",
      country_code: "US"
  },
  {
      id: "CRT",
      name: "Z.M. Jack Stell Field",
      area_code: "CRT",
      city_name: "Crossett",
      country_code: "US"
  },
  {
      id: "CRV",
      name: "Crotone",
      area_code: "CRV",
      city_name: "Crotone",
      country_code: "IT"
  },
  {
      id: "CRX",
      name: "Roscoe Turner",
      area_code: "CRX",
      city_name: "Corinth",
      country_code: "US"
  },
  {
      id: "CRZ",
      name: "Turkmenabat Airport",
      area_code: "CRZ",
      city_name: "Turkmenabat",
      country_code: "TM"
  },
  {
      id: "CSF",
      name: "Creil",
      area_code: "CSF",
      city_name: "Creil",
      country_code: "FR"
  },
  {
      id: "LSF",
      name: "Lawson AAF-Ft Benning",
      area_code: "CSG",
      city_name: "Columbus",
      country_code: "US"
  },
  {
      id: "CLK",
      name: "Regional",
      area_code: "CSM",
      city_name: "Clinton",
      country_code: "US"
  },
  {
      id: "CSM",
      name: "Sherman",
      area_code: "CSM",
      city_name: "Clinton",
      country_code: "US"
  },
  {
      id: "CSN",
      name: "Carson",
      area_code: "CSN",
      city_name: "Carson City",
      country_code: "US"
  },
  {
      id: "CSO",
      name: "Cochstedt",
      area_code: "CSO",
      city_name: "Magdeburg",
      country_code: "DE"
  },
  {
      id: "CSU",
      name: "Santa Cruz do Sul",
      area_code: "CSU",
      city_name: "Santa Cruz do Sul",
      country_code: "BR"
  },
  {
      id: "CSV",
      name: "Memorial-Whitson Field",
      area_code: "CSV",
      city_name: "Crossville",
      country_code: "US"
  },
  {
      id: "CTD",
      name: "Alonso Valderrama Airport",
      area_code: "CTD",
      city_name: "Chitre",
      country_code: "PA"
  },
  {
      id: "CTM",
      name: "Chetumal International Airport",
      area_code: "CTM",
      city_name: "Chetumal",
      country_code: "MX"
  },
  {
      id: "CTW",
      name: "Cottonwood",
      area_code: "CTW",
      city_name: "Cottonwood",
      country_code: "US"
  },
  {
      id: "CUA",
      name: "Ciudad Constitucion",
      area_code: "CUA",
      city_name: "Ciudad Constitucion",
      country_code: "MX"
  },
  {
      id: "CUC",
      name: "Camilo Daza Airport",
      area_code: "CUC",
      city_name: "Cucuta",
      country_code: "CO"
  },
  {
      id: "CUF",
      name: "Levaldigi Airport",
      area_code: "CUF",
      city_name: "Cuneo",
      country_code: "IT"
  },
  {
      id: "CUG",
      name: "Cudal",
      area_code: "CUG",
      city_name: "Cudal",
      country_code: "AU"
  },
  {
      id: "CUQ",
      name: "Coen",
      area_code: "CUQ",
      city_name: "Coen",
      country_code: "AU"
  },
  {
      id: "CUR",
      name: "Hato International Airport",
      area_code: "CUR",
      city_name: "Curacao",
      country_code: "CW"
  },
  {
      id: "CUS",
      name: "Municipal",
      area_code: "CUS",
      city_name: "Columbus",
      country_code: "US"
  },
  {
      id: "CVF",
      name: "Courchevel",
      area_code: "CVF",
      city_name: "Courchevel",
      country_code: "FR"
  },
  {
      id: "LUK",
      name: "Cincinnati Municipal Airport",
      area_code: "CVG",
      city_name: "Cincinnati",
      country_code: "US"
  },
  {
      id: "CVJ",
      name: "Mariano Matamoros",
      area_code: "CVJ",
      city_name: "Cuernavaca",
      country_code: "MX"
  },
  {
      id: "CVS",
      name: "Cannon AFB",
      area_code: "CVN",
      city_name: "Clovis",
      country_code: "US"
  },
  {
      id: "CVO",
      name: "Corvallis Municipal",
      area_code: "CVO",
      city_name: "Corvallis/Albany",
      country_code: "US"
  },
  {
      id: "WLP",
      name: "West Angelas Airport",
      area_code: "WLP",
      city_name: "West Angelas",
      country_code: "AU"
  },
  {
      id: "CVT",
      name: "Coventry",
      area_code: "CVT",
      city_name: "Coventry",
      country_code: "GB"
  },
  {
      id: "CVU",
      name: "Corvo Island Airport",
      area_code: "CVU",
      city_name: "Corvo Island",
      country_code: "PT"
  },
  {
      id: "BFH",
      name: "Bacacheri",
      area_code: "CWB",
      city_name: "Curitiba",
      country_code: "BR"
  },
  {
      id: "CWC",
      name: "Chernivtsi International Airport",
      area_code: "CWC",
      city_name: "Chernivtsi",
      country_code: "UA"
  },
  {
      id: "CWI",
      name: "Municipal",
      area_code: "CWI",
      city_name: "Clinton",
      country_code: "US"
  },
  {
      id: "CWW",
      name: "Corowa",
      area_code: "CWW",
      city_name: "Corowa",
      country_code: "AU"
  },
  {
      id: "CWX",
      name: "Cochise County",
      area_code: "CWX",
      city_name: "Willcox",
      country_code: "US"
  },
  {
      id: "CXC",
      name: "Chitina",
      area_code: "CXC",
      city_name: "Chitina",
      country_code: "US"
  },
  {
      id: "CXF",
      name: "COLDFOOT AIRPORT",
      area_code: "CXF",
      city_name: "Coldfoot",
      country_code: "US"
  },
  {
      id: "CXL",
      name: "International",
      area_code: "CXL",
      city_name: "Calexico",
      country_code: "US"
  },
  {
      id: "CXO",
      name: "Lone Star Executive",
      area_code: "HOU",
      city_name: "Houston",
      country_code: "US"
  },
  {
      id: "CXR",
      name: "Cam Ranh Airport",
      area_code: "NHA",
      city_name: "Nha Trang",
      country_code: "VN"
  },
  {
      id: "CYD",
      name: "Town Airstrip",
      area_code: "SQS",
      city_name: "San Ignacio",
      country_code: "BZ"
  },
  {
      id: "SQS",
      name: "Matthew Spain",
      area_code: "SQS",
      city_name: "San Ignacio",
      country_code: "BZ"
  },
  {
      id: "CYM",
      name: "Sea Plane Base",
      area_code: "CYM",
      city_name: "Chatham",
      country_code: "US"
  },
  {
      id: "CYO",
      name: "Cayo Largo Del Sur Airport",
      area_code: "CYO",
      city_name: "Cayo Largo del Sur",
      country_code: "CU"
  },
  {
      id: "CYP",
      name: "CALBAYOG AIRPORT",
      area_code: "CYP",
      city_name: "Calbayog",
      country_code: "PH"
  },
  {
      id: "CYR",
      name: "Laguna de los Patos",
      area_code: "CYR",
      city_name: "Colonia del Sacramento",
      country_code: "UY"
  },
  {
      id: "CYT",
      name: "Yakataga",
      area_code: "CYT",
      city_name: "Yakataga",
      country_code: "US"
  },
  {
      id: "CZA",
      name: "International",
      area_code: "CZA",
      city_name: "Chichen-Itza",
      country_code: "MX"
  },
  {
      id: "CZC",
      name: "Copper Center 2",
      area_code: "CZC",
      city_name: "Copper Center",
      country_code: "US"
  },
  {
      id: "CZJ",
      name: "Corazon de Jesus",
      area_code: "CZJ",
      city_name: "Corazon de Jesus",
      country_code: "PA"
  },
  {
      id: "DAG",
      name: "Barstow-Daggett",
      area_code: "DAG",
      city_name: "Daggett",
      country_code: "US"
  },
  {
      id: "DAK",
      name: "Dakhla Oasis",
      area_code: "DAK",
      city_name: "Dakhla Oasis",
      country_code: "EG"
  },
  {
      id: "DAN",
      name: "Regional",
      area_code: "DAN",
      city_name: "Danville",
      country_code: "US"
  },
  {
      id: "DAU",
      name: "Daru Airport",
      area_code: "DAU",
      city_name: "Daru",
      country_code: "PG"
  },
  {
      id: "DAV",
      name: "Enrique Malek International Airport",
      area_code: "DAV",
      city_name: "David",
      country_code: "PA"
  },
  {
      id: "FFO",
      name: "Wright-Patterson AFB",
      area_code: "DAY",
      city_name: "Dayton",
      country_code: "US"
  },
  {
      id: "MGY",
      name: "Wright Brothers",
      area_code: "DAY",
      city_name: "Dayton",
      country_code: "US"
  },
  {
      id: "DBN",
      name: "W.H. \"\"Bud\"\" Barron",
      area_code: "DBN",
      city_name: "Dublin",
      country_code: "US"
  },
  {
      id: "DBV",
      name: "Dubrovnik Airport",
      area_code: "DBV",
      city_name: "Dubrovnik",
      country_code: "HR"
  },
  {
      id: "DBY",
      name: "Dalby",
      area_code: "DBY",
      city_name: "Dalby",
      country_code: "AU"
  },
  {
      id: "DCM",
      name: "Mazamet Airport",
      area_code: "DCM",
      city_name: "Castres",
      country_code: "FR"
  },
  {
      id: "DCU",
      name: "Pryor Field Regional",
      area_code: "DCU",
      city_name: "Decatur",
      country_code: "US"
  },
  {
      id: "DDC",
      name: "Dodge City Regional Airport",
      area_code: "DDC",
      city_name: "Dodge City",
      country_code: "US"
  },
  {
      id: "DEB",
      name: "Debrecen Airport",
      area_code: "DEB",
      city_name: "Debrecen",
      country_code: "HU"
  },
  {
      id: "DED",
      name: "Dehradun Airport",
      area_code: "DED",
      city_name: "Dehra Dun",
      country_code: "IN"
  },
  {
      id: "DEE",
      name: "Yuzhno-Kurilsk Airport",
      area_code: "DEE",
      city_name: "Yuzhno-Kurilsk",
      country_code: "RU"
  },
  {
      id: "DEH",
      name: "Municipal",
      area_code: "DEH",
      city_name: "Decorah",
      country_code: "US"
  },
  {
      id: "APA",
      name: "Centennial",
      area_code: "DEN",
      city_name: "Denver",
      country_code: "US"
  },
  {
      id: "BFK",
      name: "Aurora/Buckley AFB",
      area_code: "DEN",
      city_name: "Denver",
      country_code: "US"
  },
  {
      id: "DEZ",
      name: "Deir ez Zor",
      area_code: "DEZ",
      city_name: "Deir ez Zor",
      country_code: "SY"
  },
  {
      id: "DFI",
      name: "Memorial",
      area_code: "DFI",
      city_name: "Defiance",
      country_code: "US"
  },
  {
      id: "ADS",
      name: "Addison",
      area_code: "DFW",
      city_name: "Dallas",
      country_code: "US"
  },
  {
      id: "FWH",
      name: "NAS JRB",
      area_code: "FTW",
      city_name: "Fort Worth",
      country_code: "US"
  },
  {
      id: "RBD",
      name: "Executive",
      area_code: "DFW",
      city_name: "Dallas",
      country_code: "US"
  },
  {
      id: "DGE",
      name: "Mudgee Airport",
      area_code: "DGE",
      city_name: "Mudgee",
      country_code: "AU"
  },
  {
      id: "DGP",
      name: "International",
      area_code: "DGP",
      city_name: "Daugavpils",
      country_code: "LV"
  },
  {
      id: "DIB",
      name: "Dibrugarh Airport",
      area_code: "DIB",
      city_name: "Dibrugarh",
      country_code: "IN"
  },
  {
      id: "DIE",
      name: "Arrachart Airport",
      area_code: "DIE",
      city_name: "Antsiranana",
      country_code: "MG"
  },
  {
      id: "DIW",
      name: "Mawella Lagoon Seaplane Base",
      area_code: "DIW",
      city_name: "Dikwella",
      country_code: "LK"
  },
  {
      id: "DJE",
      name: "Zarzis International Airport",
      area_code: "DJE",
      city_name: "Djerba",
      country_code: "TN"
  },
  {
      id: "DJN",
      name: "Delta Junction Airport",
      area_code: "DJN",
      city_name: "Delta Junction",
      country_code: "US"
  },
  {
      id: "DKA",
      name: "Katsina",
      area_code: "DKA",
      city_name: "Katsina",
      country_code: "NG"
  },
  {
      id: "DKI",
      name: "Dunk Island",
      area_code: "DKI",
      city_name: "Dunk Island",
      country_code: "AU"
  },
  {
      id: "DLE",
      name: "Tavaux Airport",
      area_code: "DLE",
      city_name: "Dole",
      country_code: "FR"
  },
  {
      id: "DLG",
      name: "Dillingham Airport",
      area_code: "DLG",
      city_name: "Dillingham",
      country_code: "US"
  },
  {
      id: "DLI",
      name: "Lien Khuong Airport",
      area_code: "DLI",
      city_name: "Da Lat",
      country_code: "VN"
  },
  {
      id: "DLL",
      name: "Dillon County",
      area_code: "DLL",
      city_name: "Dillon",
      country_code: "US"
  },
  {
      id: "DLS",
      name: "Columbia Gorge Rgnl",
      area_code: "DLS",
      city_name: "The Dalles",
      country_code: "US"
  },
  {
      id: "DMD",
      name: "DOOMADGEE AIRPORT",
      area_code: "DMD",
      city_name: "Doomadgee",
      country_code: "AU"
  },
  {
      id: "DMO",
      name: "Regional",
      area_code: "DMO",
      city_name: "Sedalia",
      country_code: "US"
  },
  {
      id: "DMU",
      name: "DIMAPUR AIRPORT",
      area_code: "DMU",
      city_name: "Dimapur",
      country_code: "IN"
  },
  {
      id: "DNM",
      name: "Denham",
      area_code: "DNM",
      city_name: "Denham",
      country_code: "AU"
  },
  {
      id: "DNN",
      name: "Municipal",
      area_code: "DNN",
      city_name: "Dalton",
      country_code: "US"
  },
  {
      id: "DNR",
      name: "Pleurtuit Airport",
      area_code: "DNR",
      city_name: "Dinard/St-Malo",
      country_code: "FR"
  },
  {
      id: "DOB",
      name: "Dobo Airport",
      area_code: "DOB",
      city_name: "Dobo",
      country_code: "ID"
  },
  {
      id: "DOC",
      name: "Dornoch",
      area_code: "DOC",
      city_name: "Dornoch",
      country_code: "GB"
  },
  {
      id: "DOL",
      name: "St. Gatien Airport",
      area_code: "DOL",
      city_name: "Deauville",
      country_code: "FR"
  },
  {
      id: "DCF",
      name: "Canefield Airport",
      area_code: "DOM",
      city_name: "Dominica",
      country_code: "DM"
  },
  {
      id: "DOM",
      name: "MELVILLE HALL AIRPORT",
      area_code: "DOM",
      city_name: "Dominica",
      country_code: "DM"
  },
  {
      id: "DOU",
      name: "Dourados Airport",
      area_code: "DOU",
      city_name: "Dourados",
      country_code: "BR"
  },
  {
      id: "DOV",
      name: "Dover AFB",
      area_code: "DOV",
      city_name: "Dover",
      country_code: "US"
  },
  {
      id: "DOX",
      name: "Dongara",
      area_code: "DOX",
      city_name: "Dongara",
      country_code: "AU"
  },
  {
      id: "DPE",
      name: "St Aubin",
      area_code: "DPE",
      city_name: "Dieppe",
      country_code: "FR"
  },
  {
      id: "DPL",
      name: "DIPOLOG AIRPORT",
      area_code: "DPL",
      city_name: "Dipolog",
      country_code: "PH"
  },
  {
      id: "DCN",
      name: "RAAF Curtin",
      area_code: "DRB",
      city_name: "Derby",
      country_code: "AU"
  },
  {
      id: "DRF",
      name: "Drift River",
      area_code: "DRF",
      city_name: "Drift River",
      country_code: "US"
  },
  {
      id: "DRK",
      name: "Drake Bay Airport",
      area_code: "DRK",
      city_name: "Drake Bay",
      country_code: "CR"
  },
  {
      id: "DRN",
      name: "Dirranbandi",
      area_code: "DRN",
      city_name: "Dirranbandi",
      country_code: "AU"
  },
  {
      id: "AMK",
      name: "Animas Air Park",
      area_code: "DRO",
      city_name: "Durango",
      country_code: "US"
  },
  {
      id: "DLF",
      name: "Laughlin AFB",
      area_code: "DRT",
      city_name: "Del Rio",
      country_code: "US"
  },
  {
      id: "DRT",
      name: "International",
      area_code: "DRT",
      city_name: "Del Rio",
      country_code: "US"
  },
  {
      id: "DSA",
      name: "Sheffield Airport",
      area_code: "DSA",
      city_name: "Doncaster/Sheffield",
      country_code: "GB"
  },
  {
      id: "DSD",
      name: "Grande Anse",
      area_code: "DSD",
      city_name: "La Desirade",
      country_code: "GP"
  },
  {
      id: "DSI",
      name: "Destin Airport",
      area_code: "VPS",
      city_name: "Destin/Ft Walton Beach",
      country_code: "US"
  },
  {
      id: "DTH",
      name: "Furnance Creek",
      area_code: "DTH",
      city_name: "Death Valley Nat. Park",
      country_code: "US"
  },
  {
      id: "DET",
      name: "Coleman A. Young Muni",
      area_code: "DTT",
      city_name: "Detroit",
      country_code: "US"
  },
  {
      id: "YIP",
      name: "Willow Run",
      area_code: "DTT",
      city_name: "Detroit",
      country_code: "US"
  },
  {
      id: "DUC",
      name: "Halliburton Field",
      area_code: "DUC",
      city_name: "Duncan",
      country_code: "US"
  },
  {
      id: "DGL",
      name: "Douglas Municipal",
      area_code: "DUG",
      city_name: "Douglas/Bisbee",
      country_code: "US"
  },
  {
      id: "DUG",
      name: "Bisbee-Douglas Intl",
      area_code: "DUG",
      city_name: "Douglas/Bisbee",
      country_code: "US"
  },
  {
      id: "DUJ",
      name: "Dubois Regional Airport",
      area_code: "DUJ",
      city_name: "DuBois",
      country_code: "US"
  },
  {
      id: "DUQ",
      name: "Duncan",
      area_code: "DUQ",
      city_name: "Duncan",
      country_code: "CA"
  },
  {
      id: "VIR",
      name: "Virginia",
      area_code: "DUR",
      city_name: "Durban",
      country_code: "ZA"
  },
  {
      id: "MGL",
      name: "Moenchengladbach",
      area_code: "DUS",
      city_name: "Duesseldorf",
      country_code: "DE"
  },
  {
      id: "NRN",
      name: "Weeze Airport",
      area_code: "DUS",
      city_name: "Duesseldorf",
      country_code: "DE"
  },
  {
      id: "DUT",
      name: "Dutch Harbor Airport",
      area_code: "DUT",
      city_name: "Dutch Harbor",
      country_code: "US"
  },
  {
      id: "DVL",
      name: "Devils Lake Regional Airport",
      area_code: "DVL",
      city_name: "Devils Lake",
      country_code: "US"
  },
  {
      id: "DVN",
      name: "Municipal",
      area_code: "DVN",
      city_name: "Davenport",
      country_code: "US"
  },
  {
      id: "DWC",
      name: "Al Maktoum International Airport",
      area_code: "DXB",
      city_name: "Dubai",
      country_code: "AE"
  },
  {
      id: "XMB",
      name: "M'BAHIAKRO AIRPORT",
      area_code: "DXB",
      city_name: "Dubai",
      country_code: "AE"
  },
  {
      id: "DXR",
      name: "Municipal",
      area_code: "DXR",
      city_name: "Danbury",
      country_code: "US"
  },
  {
      id: "DYL",
      name: "Doylestown",
      area_code: "DYL",
      city_name: "Doylestown",
      country_code: "US"
  },
  {
      id: "DYR",
      name: "Anadyr Airport",
      area_code: "DYR",
      city_name: "Anadyr",
      country_code: "RU"
  },
  {
      id: "DZA",
      name: "Pamandzi International Airport",
      area_code: "DZA",
      city_name: "Dzaoudzi",
      country_code: "YT"
  },
  {
      id: "DZN",
      name: "Zhezkazgan Airport",
      area_code: "DZN",
      city_name: "Zhezkazgan",
      country_code: "KZ"
  },
  {
      id: "EAA",
      name: "EAGLE AIRPORT",
      area_code: "EAA",
      city_name: "Eagle",
      country_code: "US"
  },
  {
      id: "EAB",
      name: "Abbs",
      area_code: "EAB",
      city_name: "Abbs",
      country_code: "YE"
  },
  {
      id: "EAM",
      name: "Nejran Airport",
      area_code: "EAM",
      city_name: "Nejran",
      country_code: "SA"
  },
  {
      id: "EAN",
      name: "Phifer Airfield",
      area_code: "EAN",
      city_name: "Wheatland",
      country_code: "US"
  },
  {
      id: "EAR",
      name: "Kearney Regional Airport",
      area_code: "EAR",
      city_name: "Kearney",
      country_code: "US"
  },
  {
      id: "EBA",
      name: "MARINA DI CAMPO AIRPORT",
      area_code: "EBA",
      city_name: "Elba Island",
      country_code: "IT"
  },
  {
      id: "EBG",
      name: "El Bagre",
      area_code: "EBG",
      city_name: "El Bagre",
      country_code: "CO"
  },
  {
      id: "EBL",
      name: "ERBIL INTERNATIONAL AIRPORT",
      area_code: "EBL",
      city_name: "Erbil",
      country_code: "IQ"
  },
  {
      id: "EBO",
      name: "Ebon Island",
      area_code: "EBO",
      city_name: "Ebon Island",
      country_code: "MH"
  },
  {
      id: "ECG",
      name: "Regional",
      area_code: "ECG",
      city_name: "Elizabeth City",
      country_code: "US"
  },
  {
      id: "ECH",
      name: "Echuca",
      area_code: "ECH",
      city_name: "Echuca",
      country_code: "AU"
  },
  {
      id: "EDA",
      name: "Edna Bay Seaplane Base",
      area_code: "EDA",
      city_name: "Edna Bay",
      country_code: "US"
  },
  {
      id: "EDE",
      name: "Edenton Municipal Airport",
      area_code: "EDE",
      city_name: "Edenton",
      country_code: "US"
  },
  {
      id: "EDL",
      name: "Eldoret International Airport",
      area_code: "EDL",
      city_name: "Eldoret",
      country_code: "KE"
  },
  {
      id: "EDM",
      name: "Les Ajoncs",
      area_code: "EDM",
      city_name: "La Roche-sur-Yon",
      country_code: "FR"
  },
  {
      id: "EDO",
      name: "Edremit Airport",
      area_code: "EDO",
      city_name: "Edremit",
      country_code: "TR"
  },
  {
      id: "EDR",
      name: "Edward River Airport",
      area_code: "EDR",
      city_name: "Pormpuraaw",
      country_code: "AU"
  },
  {
      id: "EED",
      name: "Needles",
      area_code: "EED",
      city_name: "Needles",
      country_code: "US"
  },
  {
      id: "EEK",
      name: "EEK AIRPORT",
      area_code: "EEK",
      city_name: "Eek",
      country_code: "US"
  },
  {
      id: "EEN",
      name: "Dillant-Hopkins",
      area_code: "EEN",
      city_name: "Keene",
      country_code: "US"
  },
  {
      id: "EFG",
      name: "Efogi",
      area_code: "EFG",
      city_name: "Efogi",
      country_code: "PG"
  },
  {
      id: "EFL",
      name: "Kefallinia Airport",
      area_code: "EFL",
      city_name: "Kefallinia",
      country_code: "GR"
  },
  {
      id: "EGC",
      name: "ROUMANIERES AIRPORT",
      area_code: "EGC",
      city_name: "Bergerac",
      country_code: "FR"
  },
  {
      id: "EGE",
      name: "Eagle County Regional Airport",
      area_code: "EGE",
      city_name: "Vail/Eagle",
      country_code: "US"
  },
  {
      id: "EGM",
      name: "Sege Airport",
      area_code: "EGM",
      city_name: "Seghe",
      country_code: "SB"
  },
  {
      id: "EGP",
      name: "Maverick Co. Mem. Intl",
      area_code: "EGP",
      city_name: "Eagle Pass",
      country_code: "US"
  },
  {
      id: "EGS",
      name: "EGILSSTADIR AIRPORT",
      area_code: "EGS",
      city_name: "Egilsstadir",
      country_code: "IS"
  },
  {
      id: "EGV",
      name: "Union",
      area_code: "EGV",
      city_name: "Eagle River",
      country_code: "US"
  },
  {
      id: "EHL",
      name: "El Bolson",
      area_code: "EHL",
      city_name: "El Bolson",
      country_code: "AR"
  },
  {
      id: "EIB",
      name: "Kindel",
      area_code: "EIB",
      city_name: "Eisenach",
      country_code: "DE"
  },
  {
      id: "EIS",
      name: "Terrance B. Lettsome Airport",
      area_code: "TOV",
      city_name: "Tortola",
      country_code: "VG"
  },
  {
      id: "TOV",
      name: "West End SPB",
      area_code: "TOV",
      city_name: "Tortola",
      country_code: "VG"
  },
  {
      id: "EJA",
      name: "Yariguies Airport",
      area_code: "EJA",
      city_name: "Barrancabermeja",
      country_code: "CO"
  },
  {
      id: "EKA",
      name: "Murray Field",
      area_code: "ACV",
      city_name: "Arcata/Eureka",
      country_code: "US"
  },
  {
      id: "EKI",
      name: "Municipal",
      area_code: "EKI",
      city_name: "Elkhart",
      country_code: "US"
  },
  {
      id: "EKN",
      name: "Randolph County",
      area_code: "EKN",
      city_name: "Elkins",
      country_code: "US"
  },
  {
      id: "EKS",
      name: "Shakhtyorsk Airport",
      area_code: "EKS",
      city_name: "Shakhtersk",
      country_code: "RU"
  },
  {
      id: "EKT",
      name: "Airport",
      area_code: "EKT",
      city_name: "Eskilstuna",
      country_code: "SE"
  },
  {
      id: "EKX",
      name: "Regional-Addington Fld",
      area_code: "EKX",
      city_name: "Elizabethtown",
      country_code: "US"
  },
  {
      id: "ELC",
      name: "ELCHO ISLAND AIRPORT",
      area_code: "ELC",
      city_name: "Elcho Island",
      country_code: "AU"
  },
  {
      id: "ELH",
      name: "NORTH ELEUTHERA INTERNATIONAL AIRPORT",
      area_code: "ELH",
      city_name: "North Eleuthera",
      country_code: "BS"
  },
  {
      id: "ELK",
      name: "Regional Business",
      area_code: "ELK",
      city_name: "Elk City",
      country_code: "US"
  },
  {
      id: "ELL",
      name: "Matimba",
      area_code: "ELL",
      city_name: "Ellisras",
      country_code: "ZA"
  },
  {
      id: "BIF",
      name: "Biggs AAF",
      area_code: "ELP",
      city_name: "El Paso",
      country_code: "US"
  },
  {
      id: "ELQ",
      name: "Gassim Airport",
      area_code: "ELQ",
      city_name: "Gassim",
      country_code: "SA"
  },
  {
      id: "ELW",
      name: "Sea Plane Base",
      area_code: "ELW",
      city_name: "Ellamar",
      country_code: "US"
  },
  {
      id: "ELZ",
      name: "Municipal",
      area_code: "ELZ",
      city_name: "Wellsville",
      country_code: "US"
  },
  {
      id: "EMP",
      name: "Municipal",
      area_code: "EMP",
      city_name: "Emporia",
      country_code: "US"
  },
  {
      id: "EMT",
      name: "San Gabriel Valley",
      area_code: "EMT",
      city_name: "El Monte",
      country_code: "US"
  },
  {
      id: "ENA",
      name: "Kenai Municipal Airport",
      area_code: "ENA",
      city_name: "Kenai",
      country_code: "US"
  },
  {
      id: "ENC",
      name: "Nancy-Essey",
      area_code: "ETZ",
      city_name: "Metz/Nancy",
      country_code: "FR"
  },
  {
      id: "ENK",
      name: "St Angelo",
      area_code: "ENK",
      city_name: "Enniskillen",
      country_code: "GB"
  },
  {
      id: "ENL",
      name: "Municipal",
      area_code: "ENL",
      city_name: "Centralia",
      country_code: "US"
  },
  {
      id: "ENV",
      name: "Wendover",
      area_code: "ENV",
      city_name: "Wendover",
      country_code: "US"
  },
  {
      id: "ENW",
      name: "Regional",
      area_code: "ENW",
      city_name: "Kenosha",
      country_code: "US"
  },
  {
      id: "EOI",
      name: "EDAY AIRPORT",
      area_code: "EOI",
      city_name: "Eday",
      country_code: "GB"
  },
  {
      id: "EOK",
      name: "Municipal",
      area_code: "EOK",
      city_name: "Keokuk",
      country_code: "US"
  },
  {
      id: "EPL",
      name: "Mirecourt Airport",
      area_code: "EPL",
      city_name: "Epinal",
      country_code: "FR"
  },
  {
      id: "EPS",
      name: "El Portillo",
      area_code: "AZS",
      city_name: "Samana",
      country_code: "DO"
  },
  {
      id: "EPU",
      name: "Parnu",
      area_code: "EPU",
      city_name: "Parnu",
      country_code: "EE"
  },
  {
      id: "ERH",
      name: "Errachidia Airport",
      area_code: "ERH",
      city_name: "Errachidia",
      country_code: "MA"
  },
  {
      id: "ERM",
      name: "Erechim",
      area_code: "ERM",
      city_name: "Erechim",
      country_code: "BR"
  },
  {
      id: "ERT",
      name: "Erdenet",
      area_code: "ERT",
      city_name: "Erdenet",
      country_code: "MN"
  },
  {
      id: "ERV",
      name: "Municipal",
      area_code: "ERV",
      city_name: "Kerrville",
      country_code: "US"
  },
  {
      id: "ESD",
      name: "Orcas Island Airport",
      area_code: "ESD",
      city_name: "Eastsound",
      country_code: "US"
  },
  {
      id: "WSX",
      name: "Sea Plane Base",
      area_code: "WSX",
      city_name: "West Sound",
      country_code: "US"
  },
  {
      id: "ESE",
      name: "Ensenada",
      area_code: "ESE",
      city_name: "Ensenada",
      country_code: "MX"
  },
  {
      id: "ESH",
      name: "Shoreham-by-Sea",
      area_code: "ESH",
      city_name: "Shoreham-by-Sea",
      country_code: "GB"
  },
  {
      id: "AOE",
      name: "Anadolu",
      area_code: "ESK",
      city_name: "Eskisehir",
      country_code: "TR"
  },
  {
      id: "ESK",
      name: "Eskisehir Airport",
      area_code: "ESK",
      city_name: "Eskisehir",
      country_code: "TR"
  },
  {
      id: "ESM",
      name: "Carlos Concha Torres International Airport",
      area_code: "ESM",
      city_name: "Esmeraldas",
      country_code: "EC"
  },
  {
      id: "ESN",
      name: "Newnam Field",
      area_code: "ESN",
      city_name: "Easton",
      country_code: "US"
  },
  {
      id: "ESP",
      name: "Stroudsburg-Pocono",
      area_code: "ESP",
      city_name: "East Stroudsburg",
      country_code: "US"
  },
  {
      id: "ESU",
      name: "Mogador Airport",
      area_code: "ESU",
      city_name: "Essaouira",
      country_code: "MA"
  },
  {
      id: "ETB",
      name: "Municipal",
      area_code: "ETB",
      city_name: "West Bend",
      country_code: "US"
  },
  {
      id: "ETH",
      name: "Eilat AIRPORT",
      area_code: "ETH",
      city_name: "Eilat",
      country_code: "IL"
  },
  {
      id: "ETN",
      name: "Municipal",
      area_code: "ETN",
      city_name: "Eastland",
      country_code: "US"
  },
  {
      id: "ETR",
      name: "Santa Rosa International Airport",
      area_code: "ETR",
      city_name: "Santa Rosa",
      country_code: "EC"
  },
  {
      id: "ETS",
      name: "Municipal",
      area_code: "ETS",
      city_name: "Enterprise",
      country_code: "US"
  },
  {
      id: "EUF",
      name: "Weedon Field",
      area_code: "EUF",
      city_name: "Eufaula",
      country_code: "US"
  },
  {
      id: "EUM",
      name: "Neumuenster",
      area_code: "EUM",
      city_name: "Neumuenster",
      country_code: "DE"
  },
  {
      id: "EUN",
      name: "HASSAN I AIRPORT",
      area_code: "EUN",
      city_name: "Laayoune",
      country_code: "MA"
  },
  {
      id: "EUX",
      name: "F. D. Roosevelt Airport",
      area_code: "EUX",
      city_name: "St Eustatius",
      country_code: "AN"
  },
  {
      id: "EVE",
      name: "Evenes Airport",
      area_code: "EVE",
      city_name: "Harstad/Narvik",
      country_code: "NO"
  },
  {
      id: "EVG",
      name: "SVEG AIRPORT",
      area_code: "EVG",
      city_name: "Sveg",
      country_code: "SE"
  },
  {
      id: "EVM",
      name: "Eveleth-Virginia Muni",
      area_code: "EVM",
      city_name: "Eveleth",
      country_code: "US"
  },
  {
      id: "EVW",
      name: "Uinta County",
      area_code: "EVW",
      city_name: "Evanston",
      country_code: "US"
  },
  {
      id: "EVX",
      name: "Fauville",
      area_code: "EVX",
      city_name: "Evreux",
      country_code: "FR"
  },
  {
      id: "EWK",
      name: "City-County",
      area_code: "EWK",
      city_name: "Newton",
      country_code: "US"
  },
  {
      id: "EXI",
      name: "Excursion Inlet Seaplane Base",
      area_code: "EXI",
      city_name: "Excursion Inlet",
      country_code: "US"
  },
  {
      id: "EXM",
      name: "Exmouth",
      area_code: "EXM",
      city_name: "Exmouth",
      country_code: "AU"
  },
  {
      id: "EYK",
      name: "Beloyarsky Airport",
      area_code: "EYK",
      city_name: "Beloyarsky",
      country_code: "RU"
  },
  {
      id: "EYP",
      name: "El Yopal Airport",
      area_code: "EYP",
      city_name: "Yopal",
      country_code: "CO"
  },
  {
      id: "NQX",
      name: "NAS (Boca Chica Field)",
      area_code: "EYW",
      city_name: "Key West",
      country_code: "US"
  },
  {
      id: "EZV",
      name: "Berezovo Airport",
      area_code: "EZV",
      city_name: "Berezovo",
      country_code: "RU"
  },
  {
      id: "FAB",
      name: "Farnborough",
      area_code: "FAB",
      city_name: "Farnborough",
      country_code: "GB"
  },
  {
      id: "EIL",
      name: "Eielson AFB",
      area_code: "FAI",
      city_name: "Fairbanks",
      country_code: "US"
  },
  {
      id: "FBK",
      name: "Ladd AAF",
      area_code: "FAI",
      city_name: "Fairbanks",
      country_code: "US"
  },
  {
      id: "MTX",
      name: "Metro Field",
      area_code: "FAI",
      city_name: "Fairbanks",
      country_code: "US"
  },
  {
      id: "FAJ",
      name: "Diego Jimenez Torres",
      area_code: "FAJ",
      city_name: "Fajardo",
      country_code: "PR"
  },
  {
      id: "FAK",
      name: "Sea Plane Base",
      area_code: "FAK",
      city_name: "False Island",
      country_code: "US"
  },
  {
      id: "FAO",
      name: "Faro Airport",
      area_code: "FAO",
      city_name: "Faro",
      country_code: "PT"
  },
  {
      id: "FCH",
      name: "Chandler Executive",
      area_code: "FAT",
      city_name: "Fresno",
      country_code: "US"
  },
  {
      id: "POB",
      name: "Pope Field",
      area_code: "FBG",
      city_name: "Fort Bragg",
      country_code: "US"
  },
  {
      id: "FBD",
      name: "FAIZABAD AIRPORT",
      area_code: "FBD",
      city_name: "Faizabad",
      country_code: "AF"
  },
  {
      id: "FBE",
      name: "Paulo Abdala",
      area_code: "FBE",
      city_name: "Francisco Beltrao",
      country_code: "BR"
  },
  {
      id: "FCB",
      name: "Ficksburg",
      area_code: "FCB",
      city_name: "Ficksburg",
      country_code: "ZA"
  },
  {
      id: "FCN",
      name: "Nordholz",
      area_code: "FCN",
      city_name: "Cuxhaven",
      country_code: "DE"
  },
  {
      id: "FCY",
      name: "Municipal",
      area_code: "FCY",
      city_name: "Forrest City",
      country_code: "US"
  },
  {
      id: "FDE",
      name: "Bringeland Airport",
      area_code: "FDE",
      city_name: "Forde",
      country_code: "NO"
  },
  {
      id: "FDH",
      name: "Friedrichshafen Airport",
      area_code: "FDH",
      city_name: "Friedrichshafen",
      country_code: "DE"
  },
  {
      id: "FDK",
      name: "Municipal",
      area_code: "FDK",
      city_name: "Frederick",
      country_code: "US"
  },
  {
      id: "FDY",
      name: "Findlay",
      area_code: "FDY",
      city_name: "Findlay",
      country_code: "US"
  },
  {
      id: "FEN",
      name: "FERNANDO DE NORONHA AIRPORT",
      area_code: "FEN",
      city_name: "Fernando de Noronha",
      country_code: "BR"
  },
  {
      id: "FFM",
      name: "Municipal",
      area_code: "FFM",
      city_name: "Fergus Falls",
      country_code: "US"
  },
  {
      id: "FHU",
      name: "Municipal/Libby AAF",
      area_code: "FHU",
      city_name: "Sierra Vista",
      country_code: "US"
  },
  {
      id: "NLO",
      name: "N'dolo",
      area_code: "FIH",
      city_name: "Kinshasa",
      country_code: "CD"
  },
  {
      id: "FJR",
      name: "International",
      area_code: "FJR",
      city_name: "Fujairah",
      country_code: "AE"
  },
  {
      id: "FKB",
      name: "Baden Airport",
      area_code: "FKB",
      city_name: "Karlsruhe/Baden-Baden",
      country_code: "DE"
  },
  {
      id: "FKL",
      name: "Venango Regional Airport",
      area_code: "FKL",
      city_name: "Franklin",
      country_code: "US"
  },
  {
      id: "FLA",
      name: "Gustavo A. Paredes Airport",
      area_code: "FLA",
      city_name: "Florencia",
      country_code: "CO"
  },
  {
      id: "FLB",
      name: "Cangapara",
      area_code: "FLB",
      city_name: "Floriano",
      country_code: "BR"
  },
  {
      id: "FLD",
      name: "Fond du Lac County Airport",
      area_code: "FLD",
      city_name: "Fond du Lac",
      country_code: "US"
  },
  {
      id: "FLF",
      name: "Schaeferhaus",
      area_code: "FLF",
      city_name: "Flensburg",
      country_code: "DE"
  },
  {
      id: "FLG",
      name: "Flagstaff Pulliam Airport",
      area_code: "GCN",
      city_name: "Grand Canyon",
      country_code: "US"
  },
  {
      id: "FLO",
      name: "Florence Airport",
      area_code: "FLO",
      city_name: "Florence",
      country_code: "US"
  },
  {
      id: "FLP",
      name: "Marion County Regional",
      area_code: "FLP",
      city_name: "Flippin",
      country_code: "US"
  },
  {
      id: "FLW",
      name: "Santa Cruz Airport",
      area_code: "FLW",
      city_name: "Flores Island",
      country_code: "PT"
  },
  {
      id: "FLZ",
      name: "Sibolga",
      area_code: "FLZ",
      city_name: "Sibolga",
      country_code: "ID"
  },
  {
      id: "FMH",
      name: "Cape Cod Coast Guard",
      area_code: "FMH",
      city_name: "Falmouth",
      country_code: "US"
  },
  {
      id: "FMS",
      name: "Municipal",
      area_code: "FMS",
      city_name: "Fort Madison",
      country_code: "US"
  },
  {
      id: "HGS",
      name: "Hastings",
      area_code: "FNA",
      city_name: "Freetown",
      country_code: "SL"
  },
  {
      id: "FNB",
      name: "Trollenhagen",
      area_code: "FNB",
      city_name: "Neubrandenburg",
      country_code: "DE"
  },
  {
      id: "FNC",
      name: "Cristiano Ronaldo International Airport",
      area_code: "FNC",
      city_name: "Funchal",
      country_code: "PT"
  },
  {
      id: "FNE",
      name: "Fane",
      area_code: "FNE",
      city_name: "Fane",
      country_code: "PG"
  },
  {
      id: "FNL",
      name: "Municipal",
      area_code: "FNL",
      city_name: "Portsmouth",
      country_code: "US"
  },
  {
      id: "FOG",
      name: "Gino Lisa Airport",
      area_code: "FOG",
      city_name: "Foggia",
      country_code: "IT"
  },
  {
      id: "FOK",
      name: "Francis S. Gabreski",
      area_code: "FOK",
      city_name: "Westhampton Beach",
      country_code: "US"
  },
  {
      id: "FON",
      name: "Fortuna Airport",
      area_code: "FON",
      city_name: "Fortuna",
      country_code: "CR"
  },
  {
      id: "FOT",
      name: "Forster",
      area_code: "FOT",
      city_name: "Forster",
      country_code: "AU"
  },
  {
      id: "FPR",
      name: "St Lucie County Intl",
      area_code: "FPR",
      city_name: "Fort Pierce",
      country_code: "US"
  },
  {
      id: "HHN",
      name: "HAHN AIRPORT",
      area_code: "FRA",
      city_name: "Frankfurt",
      country_code: "DE"
  },
  {
      id: "FRC",
      name: "Tenente Lund Presetto",
      area_code: "FRC",
      city_name: "Franca",
      country_code: "BR"
  },
  {
      id: "FRD",
      name: "Friday Harbor Airport",
      area_code: "FRD",
      city_name: "Friday Harbor",
      country_code: "US"
  },
  {
      id: "FRG",
      name: "Republic",
      area_code: "FRG",
      city_name: "Farmingdale",
      country_code: "US"
  },
  {
      id: "FRH",
      name: "Municipal",
      area_code: "FRH",
      city_name: "French Lick",
      country_code: "US"
  },
  {
      id: "FRL",
      name: "Luigi Ridolfi",
      area_code: "FRL",
      city_name: "Forli",
      country_code: "IT"
  },
  {
      id: "FRM",
      name: "Municipal",
      area_code: "FRM",
      city_name: "Fairmont",
      country_code: "US"
  },
  {
      id: "FRO",
      name: "Floro Airport",
      area_code: "FRO",
      city_name: "Floro",
      country_code: "NO"
  },
  {
      id: "FRR",
      name: "Warren County",
      area_code: "FRR",
      city_name: "Front Royal",
      country_code: "US"
  },
  {
      id: "FRS",
      name: "Flores International Airport",
      area_code: "FRS",
      city_name: "Flores",
      country_code: "GT"
  },
  {
      id: "FRZ",
      name: "Fritzlar",
      area_code: "FRZ",
      city_name: "Fritzlar",
      country_code: "DE"
  },
  {
      id: "FSC",
      name: "SUD CORSE AIRPORT",
      area_code: "FSC",
      city_name: "Figari",
      country_code: "FR"
  },
  {
      id: "FSP",
      name: "Saint Pierre Airport",
      area_code: "FSP",
      city_name: "St-Pierre",
      country_code: "PM"
  },
  {
      id: "FST",
      name: "Pecos County",
      area_code: "FST",
      city_name: "Fort Stockton",
      country_code: "US"
  },
  {
      id: "FTE",
      name: "El Calafate Airport",
      area_code: "FTE",
      city_name: "El Calafate",
      country_code: "AR"
  },
  {
      id: "FTU",
      name: "MARILLAC AIRPORT",
      area_code: "FTU",
      city_name: "Tolanaro",
      country_code: "MG"
  },
  {
      id: "FTW",
      name: "Meacham International",
      area_code: "FTW",
      city_name: "Fort Worth",
      country_code: "US"
  },
  {
      id: "FUL",
      name: "Municipal",
      area_code: "FUL",
      city_name: "Fullerton",
      country_code: "US"
  },
  {
      id: "SMD",
      name: "Smith Field",
      area_code: "FWA",
      city_name: "Fort Wayne",
      country_code: "US"
  },
  {
      id: "GAD",
      name: "Northeast Alabama Rgnl",
      area_code: "GAD",
      city_name: "Gadsden",
      country_code: "US"
  },
  {
      id: "GAI",
      name: "Montgomery Co Airpark",
      area_code: "GAI",
      city_name: "Gaithersburg",
      country_code: "US"
  },
  {
      id: "GAL",
      name: "Galena Airport",
      area_code: "GAL",
      city_name: "Galena",
      country_code: "US"
  },
  {
      id: "GAN",
      name: "Gan International Airport",
      area_code: "GAN",
      city_name: "Gan Island",
      country_code: "MV"
  },
  {
      id: "GAO",
      name: "Guantanamo Bay Naval Base",
      area_code: "GAO",
      city_name: "Guantanamo",
      country_code: "CU"
  },
  {
      id: "NBW",
      name: "Mariana Grajales Airport",
      area_code: "GAO",
      city_name: "Guantanamo",
      country_code: "CU"
  },
  {
      id: "GAT",
      name: "Tallard",
      area_code: "GAT",
      city_name: "Gap",
      country_code: "FR"
  },
  {
      id: "GAY",
      name: "Gaya Airport",
      area_code: "GAY",
      city_name: "Gaya",
      country_code: "IN"
  },
  {
      id: "GBD",
      name: "Municipal",
      area_code: "GBD",
      city_name: "Great Bend",
      country_code: "US"
  },
  {
      id: "GBK",
      name: "Gbangbatok",
      area_code: "GBK",
      city_name: "Gbangbatok",
      country_code: "SL"
  },
  {
      id: "GBR",
      name: "Walter J. Koladza",
      area_code: "GBR",
      city_name: "Great Barrington",
      country_code: "US"
  },
  {
      id: "GCC",
      name: "CAMPBELL COUNTY AIRPORT",
      area_code: "GCC",
      city_name: "Gillette",
      country_code: "US"
  },
  {
      id: "GCI",
      name: "Guernsey Airport",
      area_code: "GCI",
      city_name: "Guernsey",
      country_code: "GB"
  },
  {
      id: "GCY",
      name: "Greene County Muni",
      area_code: "GCY",
      city_name: "Greeneville",
      country_code: "US"
  },
  {
      id: "GDE",
      name: "Gode Airport",
      area_code: "GDE",
      city_name: "Gode",
      country_code: "ET"
  },
  {
      id: "GDT",
      name: "Grand Turk Airport",
      area_code: "GDT",
      city_name: "Grand Turk",
      country_code: "TC"
  },
  {
      id: "GDZ",
      name: "Gelendzhik Airport",
      area_code: "GDZ",
      city_name: "Gelendzhik",
      country_code: "RU"
  },
  {
      id: "GED",
      name: "Sussex County",
      area_code: "GED",
      city_name: "Georgetown",
      country_code: "US"
  },
  {
      id: "SFF",
      name: "Felts Field",
      area_code: "GEG",
      city_name: "Spokane",
      country_code: "US"
  },
  {
      id: "SKA",
      name: "Fairchild AFB",
      area_code: "GEG",
      city_name: "Spokane",
      country_code: "US"
  },
  {
      id: "GEN",
      name: "Puente Genil Airport",
      area_code: "GEN",
      city_name: "Puente Genil",
      country_code: "ES"
  },
  {
      id: "OGL",
      name: "Ogle Airport",
      area_code: "GEO",
      city_name: "Georgetown",
      country_code: "GY"
  },
  {
      id: "GER",
      name: "RAFAEL CABRERA AIRPORT",
      area_code: "GER",
      city_name: "Nueva Gerona",
      country_code: "CU"
  },
  {
      id: "GES",
      name: "General Santos International Airport",
      area_code: "GES",
      city_name: "General Santos",
      country_code: "PH"
  },
  {
      id: "GEX",
      name: "Geelong",
      area_code: "GEX",
      city_name: "Geelong",
      country_code: "AU"
  },
  {
      id: "GEY",
      name: "South Big Horn County",
      area_code: "GEY",
      city_name: "Greybull",
      country_code: "US"
  },
  {
      id: "GFL",
      name: "Floyd Bennett Memorial",
      area_code: "GFL",
      city_name: "Glens Falls",
      country_code: "US"
  },
  {
      id: "GGM",
      name: "Kakamega Airport",
      area_code: "GGM",
      city_name: "Kakamega",
      country_code: "KE"
  },
  {
      id: "GHB",
      name: "Governor's Harbour Airport",
      area_code: "GHB",
      city_name: "Governor's Harbour",
      country_code: "BS"
  },
  {
      id: "GIC",
      name: "Boigu Island",
      area_code: "GIC",
      city_name: "Boigu Island",
      country_code: "AU"
  },
  {
      id: "GIF",
      name: "Winter Haven's Gilbert",
      area_code: "GIF",
      city_name: "Winter Haven",
      country_code: "US"
  },
  {
      id: "GIR",
      name: "Santiago Vila",
      area_code: "GIR",
      city_name: "Girardot",
      country_code: "CO"
  },
  {
      id: "GIZ",
      name: "Jazan Airport",
      area_code: "GIZ",
      city_name: "Gizan",
      country_code: "SA"
  },
  {
      id: "GKA",
      name: "Goroka Airport",
      area_code: "GKA",
      city_name: "Goroka",
      country_code: "PG"
  },
  {
      id: "GKK",
      name: "Kooddoo Airport",
      area_code: "GKK",
      city_name: "Kooddoo Island",
      country_code: "MV"
  },
  {
      id: "GKN",
      name: "Gulkana",
      area_code: "GKN",
      city_name: "Gulkana",
      country_code: "US"
  },
  {
      id: "GKT",
      name: "Gatlinburg",
      area_code: "GKT",
      city_name: "Sevierville",
      country_code: "US"
  },
  {
      id: "GLB",
      name: "San Carlos Apache",
      area_code: "GLB",
      city_name: "Globe",
      country_code: "US"
  },
  {
      id: "GLD",
      name: "Renner Field",
      area_code: "GLD",
      city_name: "Goodland",
      country_code: "US"
  },
  {
      id: "GLE",
      name: "Municipal",
      area_code: "GLE",
      city_name: "Gainesville",
      country_code: "US"
  },
  {
      id: "GLL",
      name: "Klanten",
      area_code: "GLL",
      city_name: "Gol",
      country_code: "NO"
  },
  {
      id: "GLO",
      name: "GLOUCESTERSHIRE AIRPORT",
      area_code: "GLO",
      city_name: "Gloucester/Cheltenham",
      country_code: "GB"
  },
  {
      id: "GLR",
      name: "Regional",
      area_code: "GLR",
      city_name: "Gaylord",
      country_code: "US"
  },
  {
      id: "GLS",
      name: "Sholes Intl",
      area_code: "GLS",
      city_name: "Galveston",
      country_code: "US"
  },
  {
      id: "GLW",
      name: "Municipal",
      area_code: "GLW",
      city_name: "Glasgow",
      country_code: "US"
  },
  {
      id: "GMB",
      name: "GAMBELA AIRPORT",
      area_code: "GMB",
      city_name: "Gambela",
      country_code: "ET"
  },
  {
      id: "GMO",
      name: "Gombe Lawanti Airport",
      area_code: "GMO",
      city_name: "Gombe",
      country_code: "NG"
  },
  {
      id: "GMR",
      name: "Totegegie Airport",
      area_code: "GMR",
      city_name: "Totegegie",
      country_code: "PF"
  },
  {
      id: "GNA",
      name: "Grodno Airport",
      area_code: "GNA",
      city_name: "Grodno",
      country_code: "BY"
  },
  {
      id: "GNF",
      name: "Gansner Field",
      area_code: "GNF",
      city_name: "Quincy",
      country_code: "US"
  },
  {
      id: "GNR",
      name: "Dr. Arturo U. Illia",
      area_code: "GNR",
      city_name: "General Roca",
      country_code: "AR"
  },
  {
      id: "GNS",
      name: "Gunungsitoli Airport",
      area_code: "GNS",
      city_name: "Gunung Sitoli",
      country_code: "ID"
  },
  {
      id: "GNT",
      name: "Grants-Milan Municipal",
      area_code: "GNT",
      city_name: "Grants",
      country_code: "US"
  },
  {
      id: "GNV",
      name: "Gainesville Regional Airport",
      area_code: "GNV",
      city_name: "Gainesville",
      country_code: "US"
  },
  {
      id: "GNY",
      name: "Sanliurfa Airport",
      area_code: "SFQ",
      city_name: "Sanliurfa",
      country_code: "TR"
  },
  {
      id: "GOH",
      name: "Nuuk Airport",
      area_code: "GOH",
      city_name: "Nuuk",
      country_code: "GL"
  },
  {
      id: "GOJ",
      name: "Nizhniy Novgorod Airport",
      area_code: "GOJ",
      city_name: "Nizhny Novgorod",
      country_code: "RU"
  },
  {
      id: "GOL",
      name: "Municipal",
      area_code: "GOL",
      city_name: "Gold Beach",
      country_code: "US"
  },
  {
      id: "GOM",
      name: "Goma International Airport",
      area_code: "GOM",
      city_name: "Goma",
      country_code: "CD"
  },
  {
      id: "GON",
      name: "Airport",
      area_code: "GON",
      city_name: "Groton/New London",
      country_code: "US"
  },
  {
      id: "GOO",
      name: "Goondiwindi",
      area_code: "GOO",
      city_name: "Goondiwindi",
      country_code: "AU"
  },
  {
      id: "GOP",
      name: "GORAKHPUR AIRPORT",
      area_code: "GOP",
      city_name: "Gorakhpur",
      country_code: "IN"
  },
  {
      id: "GOS",
      name: "Gosford",
      area_code: "GOS",
      city_name: "Gosford",
      country_code: "AU"
  },
  {
      id: "GSE",
      name: "Gothenburg City Airport",
      area_code: "GOT",
      city_name: "Goteborg",
      country_code: "SE"
  },
  {
      id: "GOU",
      name: "Garoua International Airport",
      area_code: "GOU",
      city_name: "Garoua",
      country_code: "CM"
  },
  {
      id: "GOV",
      name: "Gove Airport",
      area_code: "GOV",
      city_name: "Nhulunbuy",
      country_code: "AU"
  },
  {
      id: "GOZ",
      name: "Gorna Oryahovitsa",
      area_code: "GOZ",
      city_name: "Gorna Oryahovitsa",
      country_code: "BG"
  },
  {
      id: "GPA",
      name: "ARAXOS AIRPORT",
      area_code: "GPA",
      city_name: "Patrai",
      country_code: "GR"
  },
  {
      id: "GPI",
      name: "Guapi Airport",
      area_code: "GPI",
      city_name: "Guapi",
      country_code: "CO"
  },
  {
      id: "GPS",
      name: "Seymour Airport",
      area_code: "GPS",
      city_name: "Baltra Island",
      country_code: "EC"
  },
  {
      id: "GQQ",
      name: "Municipal",
      area_code: "GQQ",
      city_name: "Galion",
      country_code: "US"
  },
  {
      id: "GIA",
      name: "Girona-GIA Airport",
      area_code: "GRO",
      city_name: "Girona",
      country_code: "ES"
  },
  {
      id: "GRS",
      name: "Corrado Baccarini",
      area_code: "GRS",
      city_name: "Grosseto",
      country_code: "IT"
  },
  {
      id: "GRV",
      name: "Grozny Airport",
      area_code: "GRV",
      city_name: "Grozny",
      country_code: "RU"
  },
  {
      id: "GRW",
      name: "GRACIOSA ISLAND AIRPORT",
      area_code: "GRW",
      city_name: "Graciosa Island",
      country_code: "PT"
  },
  {
      id: "GSB",
      name: "Seymour Johnson AFB",
      area_code: "GSB",
      city_name: "Goldsboro",
      country_code: "US"
  },
  {
      id: "GDC",
      name: "Donaldson Field",
      area_code: "GSP",
      city_name: "Greenville/Spartanburg",
      country_code: "US"
  },
  {
      id: "GMU",
      name: "Greenville Downtown Ap",
      area_code: "GSP",
      city_name: "Greenville/Spartanburg",
      country_code: "US"
  },
  {
      id: "SPA",
      name: "Spartanb. Downtown Mem",
      area_code: "GSP",
      city_name: "Greenville/Spartanburg",
      country_code: "US"
  },
  {
      id: "BQV",
      name: "Bartlett Cove SPB",
      area_code: "GST",
      city_name: "Gustavus",
      country_code: "US"
  },
  {
      id: "GST",
      name: "Gustavus Airport",
      area_code: "GST",
      city_name: "Gustavus",
      country_code: "US"
  },
  {
      id: "GTA",
      name: "Gatokae",
      area_code: "GTA",
      city_name: "Gatokae",
      country_code: "SB"
  },
  {
      id: "GTI",
      name: "Guettin",
      area_code: "GTI",
      city_name: "Guettin",
      country_code: "DE"
  },
  {
      id: "GTW",
      name: "Holesov",
      area_code: "GTW",
      city_name: "Zlin",
      country_code: "CZ"
  },
  {
      id: "GTY",
      name: "Regional",
      area_code: "GTY",
      city_name: "Gettysburg",
      country_code: "US"
  },
  {
      id: "GUC",
      name: "Gunnison Airport",
      area_code: "GUC",
      city_name: "Gunnison",
      country_code: "US"
  },
  {
      id: "GUF",
      name: "Jack Edwards",
      area_code: "GUF",
      city_name: "Gulf Shores",
      country_code: "US"
  },
  {
      id: "GUH",
      name: "Gunnedah",
      area_code: "GUH",
      city_name: "Gunnedah",
      country_code: "AU"
  },
  {
      id: "UAM",
      name: "Andersen AFB",
      area_code: "GUM",
      city_name: "Guam",
      country_code: "GU"
  },
  {
      id: "GUR",
      name: "Gurney Airport",
      area_code: "GUR",
      city_name: "Alotau",
      country_code: "PG"
  },
  {
      id: "GUT",
      name: "Airport",
      area_code: "GUT",
      city_name: "Guetersloh",
      country_code: "DE"
  },
  {
      id: "GUW",
      name: "ATYRAU AIRPORT",
      area_code: "GUW",
      city_name: "Atyrau",
      country_code: "KZ"
  },
  {
      id: "GUY",
      name: "Municipal",
      area_code: "GUY",
      city_name: "Guymon",
      country_code: "US"
  },
  {
      id: "GVL",
      name: "Lee Gilmer Memorial",
      area_code: "GVL",
      city_name: "Gainesville",
      country_code: "US"
  },
  {
      id: "GVR",
      name: "Governador Valadares Airport",
      area_code: "GVR",
      city_name: "Governador Valadares",
      country_code: "BR"
  },
  {
      id: "GWE",
      name: "Gweru",
      area_code: "GWE",
      city_name: "Gweru",
      country_code: "ZW"
  },
  {
      id: "GWO",
      name: "Leflore",
      area_code: "GWO",
      city_name: "Greenwood",
      country_code: "US"
  },
  {
      id: "GWS",
      name: "Municipal",
      area_code: "GWS",
      city_name: "Glenwood Springs",
      country_code: "US"
  },
  {
      id: "GWT",
      name: "SYLT AIRPORT",
      area_code: "GWT",
      city_name: "Westerland",
      country_code: "DE"
  },
  {
      id: "GXH",
      name: "XIAHE Airport",
      area_code: "GXH",
      city_name: "Xiahe",
      country_code: "CN"
  },
  {
      id: "GXQ",
      name: "Teniente Vidal",
      area_code: "GXQ",
      city_name: "Coyhaique",
      country_code: "CL"
  },
  {
      id: "GXY",
      name: "Weld County",
      area_code: "GXY",
      city_name: "Greeley",
      country_code: "US"
  },
  {
      id: "GYI",
      name: "Gisenyi",
      area_code: "GYI",
      city_name: "Gisenyi",
      country_code: "RW"
  },
  {
      id: "GYP",
      name: "Gympie",
      area_code: "GYP",
      city_name: "Gympie",
      country_code: "AU"
  },
  {
      id: "GYR",
      name: "Phoenix Goodyear",
      area_code: "GYR",
      city_name: "Goodyear",
      country_code: "US"
  },
  {
      id: "GZO",
      name: "Gizo Airport",
      area_code: "GZO",
      city_name: "Gizo",
      country_code: "SB"
  },
  {
      id: "GZP",
      name: "Gazipasa Airport",
      area_code: "GZP",
      city_name: "Gazipasa",
      country_code: "TR"
  },
  {
      id: "HAB",
      name: "Marion Co. Rankin Fite",
      area_code: "HAB",
      city_name: "Hamilton",
      country_code: "US"
  },
  {
      id: "HAC",
      name: "Hachijo Jima Airport",
      area_code: "HAC",
      city_name: "Hachijojima",
      country_code: "JP"
  },
  {
      id: "HAD",
      name: "HALMSTAD AIRPORT",
      area_code: "HAD",
      city_name: "Halmstad",
      country_code: "SE"
  },
  {
      id: "HAF",
      name: "Half Moon Bay",
      area_code: "HAF",
      city_name: "Half Moon Bay",
      country_code: "US"
  },
  {
      id: "XFW",
      name: "Finkenwerder Airport",
      area_code: "HAM",
      city_name: "Hamburg",
      country_code: "DE"
  },
  {
      id: "HAY",
      name: "Haycock-HAY Airport",
      area_code: "HAY",
      city_name: "Haycock",
      country_code: "US"
  },
  {
      id: "HBE",
      name: "Borg El Arab Airport",
      area_code: "ALY",
      city_name: "Alexandria",
      country_code: "EG"
  },
  {
      id: "HBH",
      name: "Sea Plane Base",
      area_code: "HBH",
      city_name: "Entrance Island",
      country_code: "US"
  },
  {
      id: "HBX",
      name: "HUBLI AIRPORT",
      area_code: "HBX",
      city_name: "Hubli",
      country_code: "IN"
  },
  {
      id: "HCA",
      name: "McMahon-Wrinkle",
      area_code: "HCA",
      city_name: "Big Spring",
      country_code: "US"
  },
  {
      id: "HCJ",
      name: "Jinchengjiang Airport",
      area_code: "HCJ",
      city_name: "Hechi",
      country_code: "CN"
  },
  {
      id: "HCN",
      name: "Hengchun",
      area_code: "HCN",
      city_name: "Hengchun",
      country_code: "CN"
  },
  {
      id: "HCQ",
      name: "Halls Creek Airport",
      area_code: "HCQ",
      city_name: "Halls Creek",
      country_code: "AU"
  },
  {
      id: "HDB",
      name: "AAF",
      area_code: "HDB",
      city_name: "Heidelberg",
      country_code: "DE"
  },
  {
      id: "HDF",
      name: "HERINGSDORF AIRPORT",
      area_code: "HDF",
      city_name: "Heringsdorf",
      country_code: "DE"
  },
  {
      id: "HDN",
      name: "YAMPA VALLEY AIRPORT",
      area_code: "HDN",
      city_name: "Hayden",
      country_code: "US"
  },
  {
      id: "HDS",
      name: "HOEDSPRUIT AIRPORT",
      area_code: "HDS",
      city_name: "Hoedspruit",
      country_code: "ZA"
  },
  {
      id: "HED",
      name: "Herendeen Bay",
      area_code: "HED",
      city_name: "Herendeen Bay",
      country_code: "US"
  },
  {
      id: "HEE",
      name: "Thompson-Robbins",
      area_code: "HEE",
      city_name: "Helena-West Helena",
      country_code: "US"
  },
  {
      id: "HEH",
      name: "HEHO AIRPORT",
      area_code: "HEH",
      city_name: "Heho",
      country_code: "MM"
  },
  {
      id: "HEI",
      name: "Heide/Buesum",
      area_code: "HEI",
      city_name: "Heide/Buesum",
      country_code: "DE"
  },
  {
      id: "HEM",
      name: "Helsinki-Malmi",
      area_code: "HEL",
      city_name: "Helsinki",
      country_code: "FI"
  },
  {
      id: "HER",
      name: "N. Kazantzakis Airport",
      area_code: "HER",
      city_name: "Irakleion",
      country_code: "GR"
  },
  {
      id: "HEZ",
      name: "Adams County",
      area_code: "HEZ",
      city_name: "Natchez",
      country_code: "US"
  },
  {
      id: "HFA",
      name: "Haifa",
      area_code: "HFA",
      city_name: "Haifa",
      country_code: "IL"
  },
  {
      id: "BNH",
      name: "Harbor SPB",
      area_code: "BOS",
      city_name: "Boston",
      country_code: "US"
  },
  {
      id: "HFN",
      name: "Hofn",
      area_code: "HFN",
      city_name: "Hornafjordur",
      country_code: "IS"
  },
  {
      id: "HGN",
      name: "MAE HONG SON AIRPORT",
      area_code: "HGN",
      city_name: "Mae Hong Son",
      country_code: "TH"
  },
  {
      id: "HGU",
      name: "Kagamuga Airport",
      area_code: "HGU",
      city_name: "Mount Hagen",
      country_code: "PG"
  },
  {
      id: "HGZ",
      name: "Hog River",
      area_code: "HGZ",
      city_name: "Hogatza",
      country_code: "US"
  },
  {
      id: "HHE",
      name: "Air Base",
      area_code: "HHE",
      city_name: "Hachinohe",
      country_code: "JP"
  },
  {
      id: "HHH",
      name: "Hilton Head Airport",
      area_code: "HHH",
      city_name: "Hilton Head Island",
      country_code: "US"
  },
  {
      id: "HHQ",
      name: "HUA HIN AIRPORT",
      area_code: "HHQ",
      city_name: "Hua Hin",
      country_code: "TH"
  },
  {
      id: "HHR",
      name: "Hawthorne",
      area_code: "HHR",
      city_name: "Hawthorne",
      country_code: "US"
  },
  {
      id: "HID",
      name: "HORN ISLAND AIRPORT",
      area_code: "HID",
      city_name: "Horn Island",
      country_code: "AU"
  },
  {
      id: "HIE",
      name: "Mount Washington Rgnl",
      area_code: "HIE",
      city_name: "Whitefield",
      country_code: "US"
  },
  {
      id: "HII",
      name: "Lake Havasu City",
      area_code: "HII",
      city_name: "Lake Havasu City",
      country_code: "US"
  },
  {
      id: "HIW",
      name: "Hiroshimanishi",
      area_code: "HIJ",
      city_name: "Hiroshima",
      country_code: "JP"
  },
  {
      id: "HIO",
      name: "Hillsboro",
      area_code: "PDX",
      city_name: "Portland",
      country_code: "US"
  },
  {
      id: "HJR",
      name: "KHAJURAHO AIRPORT",
      area_code: "HJR",
      city_name: "Khajuraho",
      country_code: "IN"
  },
  {
      id: "BYH",
      name: "Arkansas Intl",
      area_code: "BYH",
      city_name: "Blytheville",
      country_code: "US"
  },
  {
      id: "HKA",
      name: "Municipal",
      area_code: "BYH",
      city_name: "Blytheville",
      country_code: "US"
  },
  {
      id: "HKB",
      name: "HEALY LAKE AIRPORT",
      area_code: "HKB",
      city_name: "Healy Lake",
      country_code: "US"
  },
  {
      id: "HKN",
      name: "Hoskins Airport",
      area_code: "HKN",
      city_name: "Hoskins",
      country_code: "PG"
  },
  {
      id: "HKY",
      name: "Regional",
      area_code: "HKY",
      city_name: "Hickory",
      country_code: "US"
  },
  {
      id: "HLB",
      name: "Batesville",
      area_code: "HLB",
      city_name: "Batesville",
      country_code: "US"
  },
  {
      id: "HLF",
      name: "Hultsfred/Vimmerby",
      area_code: "HLF",
      city_name: "Hultsfred/Vimmerby",
      country_code: "SE"
  },
  {
      id: "HLI",
      name: "Municipal",
      area_code: "HLI",
      city_name: "Hollister",
      country_code: "US"
  },
  {
      id: "HLM",
      name: "Park Township",
      area_code: "HLM",
      city_name: "Holland",
      country_code: "US"
  },
  {
      id: "HLS",
      name: "St Helens",
      area_code: "HLS",
      city_name: "St Helens",
      country_code: "AU"
  },
  {
      id: "HLW",
      name: "Hluhluwe",
      area_code: "HLW",
      city_name: "Hluhluwe",
      country_code: "ZA"
  },
  {
      id: "HMA",
      name: "Khanty-Mansiysk Airport",
      area_code: "HMA",
      city_name: "Khanty-Mansiysk",
      country_code: "RU"
  },
  {
      id: "HMB",
      name: "Sohag International Airport",
      area_code: "HMB",
      city_name: "Sohag",
      country_code: "EG"
  },
  {
      id: "HME",
      name: "Oued Irara Airport",
      area_code: "HME",
      city_name: "Hassi Messaoud",
      country_code: "DZ"
  },
  {
      id: "HMR",
      name: "Stafsberg",
      area_code: "HMR",
      city_name: "Hamar",
      country_code: "NO"
  },
  {
      id: "HMV",
      name: "Hemavan/Tarnaby",
      area_code: "HMV",
      city_name: "Hemavan/Tarnaby",
      country_code: "SE"
  },
  {
      id: "HMY",
      name: "Seosan",
      area_code: "HMY",
      city_name: "Seosan",
      country_code: "KR"
  },
  {
      id: "HNB",
      name: "Huntingburg",
      area_code: "HNB",
      city_name: "Huntingburg",
      country_code: "US"
  },
  {
      id: "HNC",
      name: "Billy Mitchell",
      area_code: "HNC",
      city_name: "Hatteras",
      country_code: "US"
  },
  {
      id: "HNH",
      name: "HOONAH AIRPORT",
      area_code: "HNH",
      city_name: "Hoonah",
      country_code: "US"
  },
  {
      id: "HNS",
      name: "Haines Airport",
      area_code: "HNS",
      city_name: "Haines",
      country_code: "US"
  },
  {
      id: "HBB",
      name: "Industrial Airpark",
      area_code: "HOB",
      city_name: "Hobbs",
      country_code: "US"
  },
  {
      id: "HOD",
      name: "International",
      area_code: "HOD",
      city_name: "Hodeidah",
      country_code: "YE"
  },
  {
      id: "HOE",
      name: "Houeisay Airport",
      area_code: "HOE",
      city_name: "Houeisay",
      country_code: "LA"
  },
  {
      id: "HOF",
      name: "ALAHSA AIRPORT",
      area_code: "HOF",
      city_name: "Hofuf",
      country_code: "SA"
  },
  {
      id: "HOH",
      name: "Airport",
      area_code: "HOH",
      city_name: "Hohenems/Dornbirn",
      country_code: "AT"
  },
  {
      id: "HOI",
      name: "Hao Island Airport",
      area_code: "HOI",
      city_name: "Hao",
      country_code: "PF"
  },
  {
      id: "HOP",
      name: "Campbell AAF",
      area_code: "HOP",
      city_name: "Hopkinsville",
      country_code: "US"
  },
  {
      id: "HOQ",
      name: "Plauen",
      area_code: "HOQ",
      city_name: "Hof",
      country_code: "DE"
  },
  {
      id: "HOR",
      name: "HORTA AIRPORT",
      area_code: "HOR",
      city_name: "Horta",
      country_code: "PT"
  },
  {
      id: "DWH",
      name: "David Wayne Hooks Mem.",
      area_code: "HOU",
      city_name: "Houston",
      country_code: "US"
  },
  {
      id: "SPX",
      name: "Sphinx International",
      area_code: "SPX",
      city_name: "Giza",
      country_code: "EG"
  },
  {
      id: "HOV",
      name: "Hovden Airport",
      area_code: "HOV",
      city_name: "Orsta/Volda",
      country_code: "NO"
  },
  {
      id: "HPA",
      name: "Lifuka",
      area_code: "HPA",
      city_name: "Ha'apai",
      country_code: "TO"
  },
  {
      id: "HPV",
      name: "Princeville",
      area_code: "HPV",
      city_name: "Hanalei",
      country_code: "US"
  },
  {
      id: "HPY",
      name: "Baytown",
      area_code: "HPY",
      city_name: "Baytown",
      country_code: "US"
  },
  {
      id: "HRG",
      name: "Hurghada International Airport",
      area_code: "HRG",
      city_name: "Hurghada",
      country_code: "EG"
  },
  {
      id: "HRI",
      name: "MATTALA RAJAPAKSA INTERNATIONAL AIRPORT",
      area_code: "HRI",
      city_name: "Hambantota",
      country_code: "LK"
  },
  {
      id: "HRK",
      name: "Kharkiv Airport",
      area_code: "HRK",
      city_name: "Kharkiv",
      country_code: "UA"
  },
  {
      id: "HRO",
      name: "BOONE COUNTY AIRPORT",
      area_code: "HRO",
      city_name: "Harrison",
      country_code: "US"
  },
  {
      id: "HRS",
      name: "Harrismith",
      area_code: "HRS",
      city_name: "Harrismith",
      country_code: "ZA"
  },
  {
      id: "HRT",
      name: "Linton-on-Ouse RAF",
      area_code: "HRT",
      city_name: "Harrogate",
      country_code: "GB"
  },
  {
      id: "HRZ",
      name: "Walter Bundchen",
      area_code: "HRZ",
      city_name: "Horizontina",
      country_code: "BR"
  },
  {
      id: "HSG",
      name: "SAGA AIRPORT",
      area_code: "HSG",
      city_name: "Saga",
      country_code: "JP"
  },
  {
      id: "HSK",
      name: "Pirineos",
      area_code: "HSK",
      city_name: "Huesca",
      country_code: "ES"
  },
  {
      id: "HSL",
      name: "HUSLIA AIRPORT",
      area_code: "HSL",
      city_name: "Huslia",
      country_code: "US"
  },
  {
      id: "HSP",
      name: "Ingalls Field",
      area_code: "HSP",
      city_name: "Hot Springs",
      country_code: "US"
  },
  {
      id: "HST",
      name: "ARB",
      area_code: "HST",
      city_name: "Homestead",
      country_code: "US"
  },
  {
      id: "HSZ",
      name: "Hsinchu",
      area_code: "HSZ",
      city_name: "Hsinchu",
      country_code: "CN"
  },
  {
      id: "HTO",
      name: "East Hampton",
      area_code: "HTO",
      city_name: "East Hampton",
      country_code: "US"
  },
  {
      id: "PMH",
      name: "Regional",
      area_code: "PMH",
      city_name: "Portsmouth",
      country_code: "US"
  },
  {
      id: "HTV",
      name: "Bruce Brothers Rgnl",
      area_code: "HTV",
      city_name: "Huntsville",
      country_code: "US"
  },
  {
      id: "HTY",
      name: "Hatay Airport",
      area_code: "HTY",
      city_name: "Hatay",
      country_code: "TR"
  },
  {
      id: "HUC",
      name: "Humacao",
      area_code: "HUC",
      city_name: "Humacao",
      country_code: "PR"
  },
  {
      id: "HUH",
      name: "Huahine Fare Airport",
      area_code: "HUH",
      city_name: "Huahine",
      country_code: "PF"
  },
  {
      id: "HUL",
      name: "International",
      area_code: "HUL",
      city_name: "Houlton",
      country_code: "US"
  },
  {
      id: "HUM",
      name: "Terrebonne",
      area_code: "HUM",
      city_name: "Houma",
      country_code: "US"
  },
  {
      id: "HUS",
      name: "Hughes Airport",
      area_code: "HUS",
      city_name: "Hughes",
      country_code: "US"
  },
  {
      id: "HUT",
      name: "Municipal",
      area_code: "HUT",
      city_name: "Hutchinson",
      country_code: "US"
  },
  {
      id: "HUV",
      name: "Hudiksvall",
      area_code: "HUV",
      city_name: "Hudiksvall",
      country_code: "SE"
  },
  {
      id: "HUX",
      name: "Huatulco International Airport",
      area_code: "HUX",
      city_name: "Huatulco",
      country_code: "MX"
  },
  {
      id: "HVB",
      name: "HERVEY BAY AIRPORT",
      area_code: "HVB",
      city_name: "Hervey Bay",
      country_code: "AU"
  },
  {
      id: "HVE",
      name: "Hanksville",
      area_code: "HVE",
      city_name: "Hanksville",
      country_code: "US"
  },
  {
      id: "HVG",
      name: "Valan Airport",
      area_code: "HVG",
      city_name: "Honningsvag",
      country_code: "NO"
  },
  {
      id: "HVN",
      name: "Tweed-New Haven Airport",
      area_code: "HVN",
      city_name: "New Haven",
      country_code: "US"
  },
  {
      id: "HWD",
      name: "Executive",
      area_code: "HWD",
      city_name: "Hayward",
      country_code: "US"
  },
  {
      id: "HWN",
      name: "Hwange National Park",
      area_code: "HWN",
      city_name: "Hwange National Park",
      country_code: "ZW"
  },
  {
      id: "BPM",
      name: "Begumpet",
      area_code: "HYD",
      city_name: "Hyderabad",
      country_code: "IN"
  },
  {
      id: "HYL",
      name: "Clark Bay Seaplane Base",
      area_code: "HYL",
      city_name: "Hollis",
      country_code: "US"
  },
  {
      id: "HYR",
      name: "Sawyer County",
      area_code: "HYR",
      city_name: "Hayward",
      country_code: "US"
  },
  {
      id: "HYS",
      name: "Hays Regional Airport",
      area_code: "HYS",
      city_name: "Hays",
      country_code: "US"
  },
  {
      id: "HZB",
      name: "Merville-Colonne",
      area_code: "HZB",
      city_name: "Hazebrouck",
      country_code: "FR"
  },
  {
      id: "HZK",
      name: "Husavik",
      area_code: "HZK",
      city_name: "Husavik",
      country_code: "IS"
  },
  {
      id: "HZV",
      name: "Hazyview",
      area_code: "HZV",
      city_name: "Hazyview",
      country_code: "ZA"
  },
  {
      id: "IAA",
      name: "IGARKA AIRPORT",
      area_code: "IAA",
      city_name: "Igarka",
      country_code: "RU"
  },
  {
      id: "IAG",
      name: "Niagara Falls International Airport",
      area_code: "IAG",
      city_name: "Niagara Falls",
      country_code: "US"
  },
  {
      id: "IAO",
      name: "Del Carmen",
      area_code: "IAO",
      city_name: "Del Carmen (Siargao)",
      country_code: "PH"
  },
  {
      id: "IAR",
      name: "Tunoshna Airport",
      area_code: "IAR",
      city_name: "Yaroslavl",
      country_code: "RU"
  },
  {
      id: "IBA",
      name: "IBADAN AIRPORT",
      area_code: "IBA",
      city_name: "Ibadan",
      country_code: "NG"
  },
  {
      id: "BEC",
      name: "Beech Factory Apt",
      area_code: "ICT",
      city_name: "Wichita",
      country_code: "US"
  },
  {
      id: "CEA",
      name: "Cessna Aircraft Field",
      area_code: "ICT",
      city_name: "Wichita",
      country_code: "US"
  },
  {
      id: "IAB",
      name: "McConnell AFB",
      area_code: "ICT",
      city_name: "Wichita",
      country_code: "US"
  },
  {
      id: "ICY",
      name: "Icy Bay",
      area_code: "ICY",
      city_name: "Icy Bay",
      country_code: "US"
  },
  {
      id: "IDI",
      name: "Indiana County",
      area_code: "IDI",
      city_name: "Indiana",
      country_code: "US"
  },
  {
      id: "IEG",
      name: "Babimost Airport",
      area_code: "IEG",
      city_name: "Zielona Gora",
      country_code: "PL"
  },
  {
      id: "IES",
      name: "Goehlis",
      area_code: "IES",
      city_name: "Riesa",
      country_code: "DE"
  },
  {
      id: "IFN",
      name: "Isfahan Airport",
      area_code: "IFN",
      city_name: "Esfahan",
      country_code: "IR"
  },
  {
      id: "IFP",
      name: "Laughlin/Bullhead Intl",
      area_code: "IFP",
      city_name: "Bullhead City",
      country_code: "US"
  },
  {
      id: "IGA",
      name: "Inagua Airport",
      area_code: "IGA",
      city_name: "Inagua",
      country_code: "BS"
  },
  {
      id: "IGD",
      name: "Igdir Airport Airport",
      area_code: "IGD",
      city_name: "Igdir",
      country_code: "TR"
  },
  {
      id: "IGH",
      name: "Ingham",
      area_code: "IGH",
      city_name: "Ingham",
      country_code: "AU"
  },
  {
      id: "IGO",
      name: "Jaime Ortis Betancur",
      area_code: "IGO",
      city_name: "Chigorodo",
      country_code: "CO"
  },
  {
      id: "IGR",
      name: "Cataratas del Iguazu Airport",
      area_code: "IGR",
      city_name: "Iguazu",
      country_code: "AR"
  },
  {
      id: "IGT",
      name: "Magas Airport",
      area_code: "IGT",
      city_name: "Nazran",
      country_code: "RU"
  },
  {
      id: "IJX",
      name: "Municipal",
      area_code: "IJX",
      city_name: "Jacksonville",
      country_code: "US"
  },
  {
      id: "IKK",
      name: "Greater Kankakee",
      area_code: "IKK",
      city_name: "Kankakee",
      country_code: "US"
  },
  {
      id: "IKO",
      name: "Air Station",
      area_code: "IKO",
      city_name: "Le Creusot",
      country_code: "FR"
  },
  {
      id: "ILD",
      name: "Lleida",
      area_code: "ILD",
      city_name: "Lleida",
      country_code: "ES"
  },
  {
      id: "GRK",
      name: "Gray Army Air Field",
      area_code: "ILE",
      city_name: "Killeen/Fort Hood",
      country_code: "US"
  },
  {
      id: "HLR",
      name: "Hood AAF",
      area_code: "ILE",
      city_name: "Killeen/Fort Hood",
      country_code: "US"
  },
  {
      id: "ILF",
      name: "Ilford",
      area_code: "ILF",
      city_name: "Ilford",
      country_code: "CA"
  },
  {
      id: "ILG",
      name: "New Castle",
      area_code: "ILG",
      city_name: "Wilmington",
      country_code: "US"
  },
  {
      id: "ILM",
      name: "Wilmington International Airport",
      area_code: "ILM",
      city_name: "Wilmington",
      country_code: "US"
  },
  {
      id: "ILP",
      name: "Ile Des Pins Airport",
      area_code: "ILP",
      city_name: "Ile des Pins",
      country_code: "NC"
  },
  {
      id: "ILZ",
      name: "Zilina",
      area_code: "ILZ",
      city_name: "Zilina",
      country_code: "SK"
  },
  {
      id: "IMO",
      name: "Zemio",
      area_code: "IMO",
      city_name: "Zemio",
      country_code: "CF"
  },
  {
      id: "IMP",
      name: "Imperatriz Airport",
      area_code: "IMP",
      city_name: "Imperatriz",
      country_code: "BR"
  },
  {
      id: "INH",
      name: "Inhambane Airport",
      area_code: "INH",
      city_name: "Inhambane",
      country_code: "MZ"
  },
  {
      id: "INI",
      name: "Nis Airport",
      area_code: "INI",
      city_name: "Nis",
      country_code: "RS"
  },
  {
      id: "INJ",
      name: "Injune",
      area_code: "INJ",
      city_name: "Injune",
      country_code: "AU"
  },
  {
      id: "INT",
      name: "Smith-Reynolds Airport",
      area_code: "INT",
      city_name: "Winston-Salem",
      country_code: "US"
  },
  {
      id: "INW",
      name: "Lindbergh Regional",
      area_code: "INW",
      city_name: "Winslow",
      country_code: "US"
  },
  {
      id: "IOA",
      name: "Ioannina Airport",
      area_code: "IOA",
      city_name: "Ioannina",
      country_code: "GR"
  },
  {
      id: "IOW",
      name: "Municipal",
      area_code: "IOW",
      city_name: "Iowa City",
      country_code: "US"
  },
  {
      id: "IPA",
      name: "IPOTA AIRPORT",
      area_code: "IPA",
      city_name: "Ipota",
      country_code: "VU"
  },
  {
      id: "IPC",
      name: "Mataveri International Airport",
      area_code: "IPC",
      city_name: "Easter Island",
      country_code: "CL"
  },
  {
      id: "IPI",
      name: "San Luis Airport",
      area_code: "IPI",
      city_name: "Ipiales",
      country_code: "CO"
  },
  {
      id: "IPL",
      name: "Imperial County Airport",
      area_code: "IPL",
      city_name: "Imperial/El Centro",
      country_code: "US"
  },
  {
      id: "NJK",
      name: "El Centro NAF",
      area_code: "IPL",
      city_name: "Imperial/El Centro",
      country_code: "US"
  },
  {
      id: "IPN",
      name: "USIMINAS AIRPORT",
      area_code: "IPN",
      city_name: "Ipatinga",
      country_code: "BR"
  },
  {
      id: "IRC",
      name: "Circle City Airport",
      area_code: "IRC",
      city_name: "Circle",
      country_code: "US"
  },
  {
      id: "IRG",
      name: "Lockhart River",
      area_code: "IRG",
      city_name: "Lockhart River",
      country_code: "AU"
  },
  {
      id: "IRJ",
      name: "La Rioja Airport",
      area_code: "IRJ",
      city_name: "La Rioja",
      country_code: "AR"
  },
  {
      id: "IRM",
      name: "Igrim Airport",
      area_code: "IRM",
      city_name: "Igrim",
      country_code: "RU"
  },
  {
      id: "IRS",
      name: "Kirsch Municipal",
      area_code: "IRS",
      city_name: "Sturgis",
      country_code: "US"
  },
  {
      id: "ISA",
      name: "MOUNT ISA AIRPORT",
      area_code: "ISA",
      city_name: "Mount Isa",
      country_code: "AU"
  },
  {
      id: "ISC",
      name: "St Mary's",
      area_code: "ISC",
      city_name: "Isles of Scilly",
      country_code: "GB"
  },
  {
      id: "ISE",
      name: "Isparta Airport",
      area_code: "ISE",
      city_name: "Isparta",
      country_code: "TR"
  },
  {
      id: "ISG",
      name: "Ishigaki Airport",
      area_code: "ISG",
      city_name: "Ishigaki",
      country_code: "JP"
  },
  {
      id: "ISJ",
      name: "Isla Mujeres",
      area_code: "ISJ",
      city_name: "Isla Mujeres",
      country_code: "MX"
  },
  {
      id: "ISK",
      name: "Gandhinagar",
      area_code: "ISK",
      city_name: "Nasik",
      country_code: "IN"
  },
  {
      id: "ISM",
      name: "Kissimmee Gateway",
      area_code: "ORL",
      city_name: "Orlando",
      country_code: "US"
  },
  {
      id: "ISO",
      name: "Regional Jetport",
      area_code: "ISO",
      city_name: "Kinston",
      country_code: "US"
  },
  {
      id: "ISQ",
      name: "Schoolcraft County",
      area_code: "ISQ",
      city_name: "Manistique",
      country_code: "US"
  },
  {
      id: "ISS",
      name: "Wiscasset",
      area_code: "ISS",
      city_name: "Wiscasset",
      country_code: "US"
  },
  {
      id: "SAW",
      name: "Sabiha Gokcen Airport",
      area_code: "IST",
      city_name: "Istanbul",
      country_code: "TR"
  },
  {
      id: "ISW",
      name: "South Wood County",
      area_code: "ISW",
      city_name: "Wisconsin Rapids",
      country_code: "US"
  },
  {
      id: "ITB",
      name: "Itaituba",
      area_code: "ITB",
      city_name: "Itaituba",
      country_code: "BR"
  },
  {
      id: "ITH",
      name: "Tompkins Regional Airport",
      area_code: "ITH",
      city_name: "Ithaca",
      country_code: "US"
  },
  {
      id: "ITK",
      name: "Itokama",
      area_code: "ITK",
      city_name: "Itokama",
      country_code: "PG"
  },
  {
      id: "ITP",
      name: "Itaperuna",
      area_code: "ITP",
      city_name: "Itaperuna",
      country_code: "BR"
  },
  {
      id: "IUE",
      name: "Hanan Airport",
      area_code: "IUE",
      city_name: "Niue Island",
      country_code: "NU"
  },
  {
      id: "IVC",
      name: "Invercargill Airport",
      area_code: "IVC",
      city_name: "Invercargill",
      country_code: "NZ"
  },
  {
      id: "IVL",
      name: "Ivalo Airport",
      area_code: "IVL",
      city_name: "Ivalo",
      country_code: "FI"
  },
  {
      id: "IVR",
      name: "Inverell",
      area_code: "IVR",
      city_name: "Inverell",
      country_code: "AU"
  },
  {
      id: "IWA",
      name: "IVANOVO AIRPORT",
      area_code: "IWA",
      city_name: "Ivanovo",
      country_code: "RU"
  },
  {
      id: "IWJ",
      name: "Iwami Airport",
      area_code: "IWJ",
      city_name: "Masuda",
      country_code: "JP"
  },
  {
      id: "IWK",
      name: "Iwakuni",
      area_code: "IWK",
      city_name: "Iwakuni",
      country_code: "JP"
  },
  {
      id: "IXA",
      name: "Agartala Airport",
      area_code: "IXA",
      city_name: "Agartala",
      country_code: "IN"
  },
  {
      id: "IXB",
      name: "BAGDOGRA AIRPORT",
      area_code: "IXB",
      city_name: "Bagdogra",
      country_code: "IN"
  },
  {
      id: "IXC",
      name: "CHANDIGARH AIRPORT",
      area_code: "IXC",
      city_name: "Chandigarh",
      country_code: "IN"
  },
  {
      id: "IXG",
      name: "SAMBRE AIRPORT",
      area_code: "IXG",
      city_name: "Belgaum",
      country_code: "IN"
  },
  {
      id: "IXH",
      name: "Kailashahar",
      area_code: "IXH",
      city_name: "Kailashahar",
      country_code: "IN"
  },
  {
      id: "IXI",
      name: "LILABARI AIRPORT",
      area_code: "IXI",
      city_name: "Lilabari",
      country_code: "IN"
  },
  {
      id: "IXK",
      name: "Keshod",
      area_code: "IXK",
      city_name: "Keshod",
      country_code: "IN"
  },
  {
      id: "IXL",
      name: "LEH AIRPORT",
      area_code: "IXL",
      city_name: "Leh",
      country_code: "IN"
  },
  {
      id: "IXN",
      name: "Khowai",
      area_code: "IXN",
      city_name: "Khowai",
      country_code: "IN"
  },
  {
      id: "IXP",
      name: "Pathankot",
      area_code: "IXP",
      city_name: "Pathankot",
      country_code: "IN"
  },
  {
      id: "IXQ",
      name: "Kamalpur",
      area_code: "IXQ",
      city_name: "Kamalpur",
      country_code: "IN"
  },
  {
      id: "IXT",
      name: "Pasighat",
      area_code: "IXT",
      city_name: "Pasighat",
      country_code: "IN"
  },
  {
      id: "IXU",
      name: "Aurangabad Airport",
      area_code: "IXU",
      city_name: "Aurangabad",
      country_code: "IN"
  },
  {
      id: "IXV",
      name: "Along",
      area_code: "IXV",
      city_name: "Along",
      country_code: "IN"
  },
  {
      id: "IXW",
      name: "Sonari",
      area_code: "IXW",
      city_name: "Jamshedpur",
      country_code: "IN"
  },
  {
      id: "IXY",
      name: "Kandla",
      area_code: "IXY",
      city_name: "Kandla",
      country_code: "IN"
  },
  {
      id: "IYK",
      name: "Inyokern",
      area_code: "IYK",
      city_name: "Inyokern",
      country_code: "US"
  },
  {
      id: "IGL",
      name: "Cigli AB",
      area_code: "IZM",
      city_name: "Izmir",
      country_code: "TR"
  },
  {
      id: "IZO",
      name: "IZUMO AIRPORT",
      area_code: "IZO",
      city_name: "Izumo",
      country_code: "JP"
  },
  {
      id: "IZT",
      name: "Ixtepec",
      area_code: "IZT",
      city_name: "Ixtepec",
      country_code: "MX"
  },
  {
      id: "JAB",
      name: "Jabiru",
      area_code: "JAB",
      city_name: "Jabiru",
      country_code: "AU"
  },
  {
      id: "JAL",
      name: "El Lencero",
      area_code: "JAL",
      city_name: "Jalapa",
      country_code: "MX"
  },
  {
      id: "HKS",
      name: "Hawkins Field",
      area_code: "JAN",
      city_name: "Jackson",
      country_code: "US"
  },
  {
      id: "JAP",
      name: "Chacarita",
      area_code: "JAP",
      city_name: "Puntarenas",
      country_code: "CR"
  },
  {
      id: "JAV",
      name: "Ilulissat Airport",
      area_code: "JAV",
      city_name: "Ilulissat",
      country_code: "GL"
  },
  {
      id: "CRG",
      name: "Executive (Craig)",
      area_code: "JAX",
      city_name: "Jacksonville",
      country_code: "US"
  },
  {
      id: "NIP",
      name: "NAS (Towers Field)",
      area_code: "JAX",
      city_name: "Jacksonville",
      country_code: "US"
  },
  {
      id: "NZC",
      name: "Maria Reiche Neuman",
      area_code: "NZC",
      city_name: "Nazca",
      country_code: "PE"
  },
  {
      id: "JBB",
      name: "Jember Airport",
      area_code: "JBB",
      city_name: "Jember",
      country_code: "ID"
  },
  {
      id: "JBR",
      name: "Jonesboro Airport",
      area_code: "JBR",
      city_name: "Jonesboro",
      country_code: "US"
  },
  {
      id: "JBS",
      name: "Pleasanton-JBS Airport",
      area_code: "JBS",
      city_name: "Pleasanton",
      country_code: "US"
  },
  {
      id: "JCB",
      name: "Santa Terezinha",
      area_code: "JCB",
      city_name: "Joacaba",
      country_code: "BR"
  },
  {
      id: "JCK",
      name: "JULIA CREEK AIRPORT",
      area_code: "JCK",
      city_name: "Julia Creek",
      country_code: "AU"
  },
  {
      id: "IZA",
      name: "Presidente I. Franco",
      area_code: "JDF",
      city_name: "Juiz de Fora",
      country_code: "BR"
  },
  {
      id: "JDF",
      name: "Francisco de Assis",
      area_code: "JDF",
      city_name: "Juiz de Fora",
      country_code: "BR"
  },
  {
      id: "JEF",
      name: "Memorial",
      area_code: "JEF",
      city_name: "Jefferson City",
      country_code: "US"
  },
  {
      id: "JEG",
      name: "Aasiaat Airport",
      area_code: "JEG",
      city_name: "Aasiaat",
      country_code: "GL"
  },
  {
      id: "JGB",
      name: "Jagdalpur",
      area_code: "JGB",
      city_name: "Jagdalpur",
      country_code: "IN"
  },
  {
      id: "JHM",
      name: "KAPALUA AIRPORT",
      area_code: "JHM",
      city_name: "Kapalua",
      country_code: "US"
  },
  {
      id: "JHS",
      name: "Sisimiut Airport",
      area_code: "JHS",
      city_name: "Sisimiut",
      country_code: "GL"
  },
  {
      id: "JIJ",
      name: "Jijiga Airport",
      area_code: "JIJ",
      city_name: "Jijiga",
      country_code: "ET"
  },
  {
      id: "JIK",
      name: "Ikaria Airport",
      area_code: "JIK",
      city_name: "Ikaria Island",
      country_code: "GR"
  },
  {
      id: "JIM",
      name: "Jimma Airport",
      area_code: "JIM",
      city_name: "Jimma",
      country_code: "ET"
  },
  {
      id: "JKG",
      name: "Jonkoping Airport",
      area_code: "JKG",
      city_name: "Jonkoping",
      country_code: "SE"
  },
  {
      id: "JKH",
      name: "Chios Airport",
      area_code: "JKH",
      city_name: "Chios",
      country_code: "GR"
  },
  {
      id: "JKL",
      name: "Kalymnos Island National Airport",
      area_code: "JKL",
      city_name: "Kalymnos Island",
      country_code: "GR"
  },
  {
      id: "JKV",
      name: "Cherokee County",
      area_code: "JKV",
      city_name: "Jacksonville",
      country_code: "US"
  },
  {
      id: "JMK",
      name: "Mykonos Airport",
      area_code: "JMK",
      city_name: "Mykonos",
      country_code: "GR"
  },
  {
      id: "GCJ",
      name: "Grand Central",
      area_code: "JNB",
      city_name: "Johannesburg",
      country_code: "ZA"
  },
  {
      id: "HLA",
      name: "LANSERIA AIRPORT",
      area_code: "JNB",
      city_name: "Johannesburg",
      country_code: "ZA"
  },
  {
      id: "QRA",
      name: "Rand",
      area_code: "JNB",
      city_name: "Johannesburg",
      country_code: "ZA"
  },
  {
      id: "JNJ",
      name: "Jaaluni",
      area_code: "DQM",
      city_name: "Duqm",
      country_code: "OM"
  },
  {
      id: "JNX",
      name: "NAXOS AIRPORT",
      area_code: "JNX",
      city_name: "Naxos",
      country_code: "GR"
  },
  {
      id: "JOE",
      name: "Joensuu Airport",
      area_code: "JOE",
      city_name: "Joensuu",
      country_code: "FI"
  },
  {
      id: "JOK",
      name: "Joshkar-Ola Airport",
      area_code: "JOK",
      city_name: "Yoshkar-Ola",
      country_code: "RU"
  },
  {
      id: "JOL",
      name: "Jolo",
      area_code: "JOL",
      city_name: "Jolo",
      country_code: "PH"
  },
  {
      id: "JOT",
      name: "Regional",
      area_code: "JOT",
      city_name: "Joliet",
      country_code: "US"
  },
  {
      id: "JPR",
      name: "Ji-Parana Airport",
      area_code: "JPR",
      city_name: "Ji-Parana",
      country_code: "BR"
  },
  {
      id: "JQA",
      name: "Qaarsut Airport",
      area_code: "JQA",
      city_name: "Qaarsut",
      country_code: "GL"
  },
  {
      id: "JRF",
      name: "Kalaeloa",
      area_code: "JRF",
      city_name: "Kapolei",
      country_code: "US"
  },
  {
      id: "JRH",
      name: "ROWRIAH AIRPORT",
      area_code: "JRH",
      city_name: "Jorhat",
      country_code: "IN"
  },
  {
      id: "JSH",
      name: "Sitia Airport",
      area_code: "JSH",
      city_name: "Siteia",
      country_code: "GR"
  },
  {
      id: "JSI",
      name: "SKIATHOS AIRPORT",
      area_code: "JSI",
      city_name: "Skiathos",
      country_code: "GR"
  },
  {
      id: "JSO",
      name: "Sodertalje-JSO Airport",
      area_code: "JSO",
      city_name: "Sodertalje",
      country_code: "SE"
  },
  {
      id: "JSU",
      name: "Maniitsoq Heliport",
      area_code: "JSU",
      city_name: "Maniitsoq",
      country_code: "GL"
  },
  {
      id: "JSY",
      name: "SYROS ISLAND AIRPORT",
      area_code: "JSY",
      city_name: "Syros Island",
      country_code: "GR"
  },
  {
      id: "JTR",
      name: "Santorini Airport",
      area_code: "JTR",
      city_name: "Thira",
      country_code: "GR"
  },
  {
      id: "JTY",
      name: "Astypalaia Airport",
      area_code: "JTY",
      city_name: "Astypalaia Island",
      country_code: "GR"
  },
  {
      id: "JUI",
      name: "Juist",
      area_code: "JUI",
      city_name: "Juist",
      country_code: "DE"
  },
  {
      id: "JUJ",
      name: "Jujuy Airport",
      area_code: "JUJ",
      city_name: "Jujuy",
      country_code: "AR"
  },
  {
      id: "JUO",
      name: "Jurado",
      area_code: "JUO",
      city_name: "Jurado",
      country_code: "CO"
  },
  {
      id: "JVL",
      name: "Southern Wisconsin Rgn",
      area_code: "JVL",
      city_name: "Janesville",
      country_code: "US"
  },
  {
      id: "JWN",
      name: "Zanjan",
      area_code: "JWN",
      city_name: "Zanjan",
      country_code: "IR"
  },
  {
      id: "JXN",
      name: "Jackson County",
      area_code: "JXN",
      city_name: "Jackson",
      country_code: "US"
  },
  {
      id: "KAE",
      name: "Kake Sea Plane Base",
      area_code: "KAE",
      city_name: "Kake",
      country_code: "US"
  },
  {
      id: "KAJ",
      name: "Kajaani Airport",
      area_code: "KAJ",
      city_name: "Kajaani",
      country_code: "FI"
  },
  {
      id: "KAO",
      name: "Kuusamo Airport",
      area_code: "KAO",
      city_name: "Kuusamo",
      country_code: "FI"
  },
  {
      id: "KAZ",
      name: "Kau Airport",
      area_code: "KAZ",
      city_name: "Kau",
      country_code: "ID"
  },
  {
      id: "KBJ",
      name: "Kings Canyon",
      area_code: "KBJ",
      city_name: "Kings Canyon",
      country_code: "AU"
  },
  {
      id: "KBU",
      name: "Kotabaru Airport",
      area_code: "KBU",
      city_name: "Kotabaru",
      country_code: "ID"
  },
  {
      id: "KBV",
      name: "Krabi Airport",
      area_code: "KBV",
      city_name: "Krabi",
      country_code: "TH"
  },
  {
      id: "KCE",
      name: "Collinsville",
      area_code: "KCE",
      city_name: "Collinsville",
      country_code: "AU"
  },
  {
      id: "KBW",
      name: "Chignik Bay SPB",
      area_code: "KCL",
      city_name: "Chignik",
      country_code: "US"
  },
  {
      id: "KCG",
      name: "Chignik Airport",
      area_code: "KCL",
      city_name: "Chignik",
      country_code: "US"
  },
  {
      id: "KCL",
      name: "Lagoon Airport",
      area_code: "KCL",
      city_name: "Chignik",
      country_code: "US"
  },
  {
      id: "KCQ",
      name: "CHIGNIK LAKE AIRPORT",
      area_code: "KCL",
      city_name: "Chignik",
      country_code: "US"
  },
  {
      id: "KCM",
      name: "KAHRAMANMARAS AIRPORT",
      area_code: "KCM",
      city_name: "Kahramanmaras",
      country_code: "TR"
  },
  {
      id: "KCN",
      name: "Sea Plane Base",
      area_code: "KCN",
      city_name: "Chernofski Harbor",
      country_code: "US"
  },
  {
      id: "KCO",
      name: "Cengiz Topel Airport",
      area_code: "KCO",
      city_name: "Kocaeli",
      country_code: "TR"
  },
  {
      id: "KCT",
      name: "KOGGALA AIRPORT",
      area_code: "KCT",
      city_name: "Koggala",
      country_code: "LK"
  },
  {
      id: "KDO",
      name: "KADHDHOO AIRPORT",
      area_code: "KDO",
      city_name: "Kadhdhoo Island",
      country_code: "MV"
  },
  {
      id: "KDU",
      name: "SKARDU AIRPORT",
      area_code: "KDU",
      city_name: "Skardu",
      country_code: "PK"
  },
  {
      id: "KDV",
      name: "Kadavu Island Airport",
      area_code: "KDV",
      city_name: "Kadavu Island",
      country_code: "FJ"
  },
  {
      id: "KEB",
      name: "English Bay Airport",
      area_code: "KEB",
      city_name: "Nanwalek",
      country_code: "US"
  },
  {
      id: "KEJ",
      name: "Kemerovo Airport",
      area_code: "KEJ",
      city_name: "Kemerovo",
      country_code: "RU"
  },
  {
      id: "KFS",
      name: "KASTAMONU AIRPORT",
      area_code: "KFS",
      city_name: "Kastamonu",
      country_code: "TR"
  },
  {
      id: "KGC",
      name: "KINGSCOTE AIRPORT",
      area_code: "KGC",
      city_name: "Kingscote",
      country_code: "AU"
  },
  {
      id: "KGF",
      name: "Sary Arka Airport",
      area_code: "KGF",
      city_name: "Karaganda",
      country_code: "KZ"
  },
  {
      id: "KGP",
      name: "KOGALYM INTERNATIONAL AIRPORT",
      area_code: "KGP",
      city_name: "Kogalym",
      country_code: "RU"
  },
  {
      id: "KGS",
      name: "Kos Airport",
      area_code: "KGS",
      city_name: "Kos",
      country_code: "GR"
  },
  {
      id: "KGW",
      name: "Kagi",
      area_code: "KGW",
      city_name: "Kagi",
      country_code: "PG"
  },
  {
      id: "KHD",
      name: "Khorramabad Airport",
      area_code: "KHD",
      city_name: "Khorramabad",
      country_code: "IR"
  },
  {
      id: "KHS",
      name: "Khasab Airport",
      area_code: "KHS",
      city_name: "Khasab",
      country_code: "OM"
  },
  {
      id: "KIC",
      name: "Mesa Del Rey",
      area_code: "KIC",
      city_name: "King City",
      country_code: "US"
  },
  {
      id: "KID",
      name: "KRISTIANSTAD AIRPORT",
      area_code: "KID",
      city_name: "Kristianstad",
      country_code: "SE"
  },
  {
      id: "KIF",
      name: "Kingfisher Lake",
      area_code: "KIF",
      city_name: "Kingfisher Lake",
      country_code: "CA"
  },
  {
      id: "KIM",
      name: "KIMBERLEY AIRPORT",
      area_code: "KIM",
      city_name: "Kimberley",
      country_code: "ZA"
  },
  {
      id: "KTP",
      name: "Tinson Pen",
      area_code: "KIN",
      city_name: "Kingston",
      country_code: "JM"
  },
  {
      id: "KIR",
      name: "KERRY COUNTY AIRPORT",
      area_code: "KIR",
      city_name: "Kerry",
      country_code: "IE"
  },
  {
      id: "KIT",
      name: "Kithira Airport",
      area_code: "KIT",
      city_name: "Kythira",
      country_code: "GR"
  },
  {
      id: "KJH",
      name: "Huangping Airport",
      area_code: "KJH",
      city_name: "Huang Ping",
      country_code: "CN"
  },
  {
      id: "KJK",
      name: "Wevelgem",
      area_code: "KJK",
      city_name: "Kortrijk",
      country_code: "BE"
  },
  {
      id: "KKA",
      name: "Alfred Adams Airport",
      area_code: "KKA",
      city_name: "Koyuk",
      country_code: "US"
  },
  {
      id: "KKE",
      name: "KERIKERI AIRPORT",
      area_code: "KKE",
      city_name: "Kerikeri",
      country_code: "NZ"
  },
  {
      id: "KKI",
      name: "Akiachak Airport",
      area_code: "KKI",
      city_name: "Akiachak",
      country_code: "US"
  },
  {
      id: "KKK",
      name: "Air Station",
      area_code: "KKK",
      city_name: "Kalakaket Creek",
      country_code: "US"
  },
  {
      id: "KKL",
      name: "Sea Plane Base",
      area_code: "KKL",
      city_name: "Karluk Lake",
      country_code: "US"
  },
  {
      id: "KKT",
      name: "Municipal",
      area_code: "KKT",
      city_name: "Kentland",
      country_code: "US"
  },
  {
      id: "KLD",
      name: "Migalovo",
      area_code: "KLD",
      city_name: "Tver",
      country_code: "RU"
  },
  {
      id: "KLF",
      name: "Grabtsevo Airport",
      area_code: "KLF",
      city_name: "Kaluga",
      country_code: "RU"
  },
  {
      id: "KLG",
      name: "Kalskag Airport",
      area_code: "KLG",
      city_name: "Kalskag",
      country_code: "US"
  },
  {
      id: "KLH",
      name: "Kolhapur",
      area_code: "KLH",
      city_name: "Kolhapur",
      country_code: "IN"
  },
  {
      id: "KLN",
      name: "Larsen Bay Airport",
      area_code: "KLN",
      city_name: "Larsen Bay",
      country_code: "US"
  },
  {
      id: "KLP",
      name: "Kuala Pembuang",
      area_code: "KLP",
      city_name: "Seruyan",
      country_code: "ID"
  },
  {
      id: "KLR",
      name: "Kalmar Airport",
      area_code: "KLR",
      city_name: "Kalmar",
      country_code: "SE"
  },
  {
      id: "KLS",
      name: "SW Washington Regional",
      area_code: "KLS",
      city_name: "Kelso",
      country_code: "US"
  },
  {
      id: "KLU",
      name: "Klagenfurt Airport",
      area_code: "KLU",
      city_name: "Klagenfurt",
      country_code: "AT"
  },
  {
      id: "KLV",
      name: "Karlovy Vary Airport",
      area_code: "KLV",
      city_name: "Karlovy Vary",
      country_code: "CZ"
  },
  {
      id: "KLW",
      name: "KLAWOCK AIRPORT",
      area_code: "KLW",
      city_name: "Klawock",
      country_code: "US"
  },
  {
      id: "KLX",
      name: "KALAMATA AIRPORT",
      area_code: "KLX",
      city_name: "Kalamata",
      country_code: "GR"
  },
  {
      id: "KMA",
      name: "Kerema",
      area_code: "KMA",
      city_name: "Kerema",
      country_code: "PG"
  },
  {
      id: "KMC",
      name: "King Khalid Mil. City",
      area_code: "KMC",
      city_name: "King Khalid Mil. City",
      country_code: "SA"
  },
  {
      id: "KME",
      name: "KAMEMBE AIRPORT",
      area_code: "KME",
      city_name: "Cyangugu",
      country_code: "RW"
  },
  {
      id: "KMH",
      name: "Johan Pienaar",
      area_code: "KMH",
      city_name: "Kuruman",
      country_code: "ZA"
  },
  {
      id: "KMS",
      name: "KUMASI AIRPORT",
      area_code: "KMS",
      city_name: "Kumasi",
      country_code: "GH"
  },
  {
      id: "KMW",
      name: "Kostroma",
      area_code: "KMW",
      city_name: "Kostroma",
      country_code: "RU"
  },
  {
      id: "KMX",
      name: "King Khalid AB",
      area_code: "KMX",
      city_name: "Khamis Mushait",
      country_code: "SA"
  },
  {
      id: "KMY",
      name: "Sea Plane Base",
      area_code: "KMY",
      city_name: "Moser Bay",
      country_code: "US"
  },
  {
      id: "KNA",
      name: "Vina del Mar",
      area_code: "KNA",
      city_name: "Vina del Mar",
      country_code: "CL"
  },
  {
      id: "KNF",
      name: "Marham RAF",
      area_code: "KNF",
      city_name: "King's Lynn",
      country_code: "GB"
  },
  {
      id: "KNG",
      name: "KAIMANA AIRPORT",
      area_code: "KNG",
      city_name: "Utarom",
      country_code: "ID"
  },
  {
      id: "KNK",
      name: "Kakhonak Airport",
      area_code: "KNK",
      city_name: "Kokhanok",
      country_code: "US"
  },
  {
      id: "KNT",
      name: "Memorial",
      area_code: "KNT",
      city_name: "Kennett",
      country_code: "US"
  },
  {
      id: "KOA",
      name: "Kona Airport",
      area_code: "KOA",
      city_name: "Kona",
      country_code: "US"
  },
  {
      id: "KOC",
      name: "KOUMAC AIRPORT",
      area_code: "KOC",
      city_name: "Koumac",
      country_code: "NC"
  },
  {
      id: "KOF",
      name: "Komatipoort",
      area_code: "KOF",
      city_name: "Komatipoort",
      country_code: "ZA"
  },
  {
      id: "KOP",
      name: "NAKHON PHANOM AIRPORT",
      area_code: "KOP",
      city_name: "Nakhon Phanom",
      country_code: "TH"
  },
  {
      id: "KOR",
      name: "Kakoro",
      area_code: "KOR",
      city_name: "Kakoro",
      country_code: "PG"
  },
  {
      id: "KPB",
      name: "Point Baker Seaplane Base",
      area_code: "KPB",
      city_name: "Point Baker",
      country_code: "US"
  },
  {
      id: "KPN",
      name: "Kipnuk Airport",
      area_code: "KPN",
      city_name: "Kipnuk",
      country_code: "US"
  },
  {
      id: "KPO",
      name: "POHANG AIRPORT",
      area_code: "KPO",
      city_name: "Pohang",
      country_code: "KR"
  },
  {
      id: "KQT",
      name: "Qurghonteppa International Airport",
      area_code: "KQT",
      city_name: "Qurghonteppa",
      country_code: "TJ"
  },
  {
      id: "KRF",
      name: "Kramfors Airport",
      area_code: "KRF",
      city_name: "Kramfors/Solleftea",
      country_code: "SE"
  },
  {
      id: "KRK",
      name: "Krakow Airport",
      area_code: "KRK",
      city_name: "Krakow",
      country_code: "PL"
  },
  {
      id: "KRP",
      name: "Karup Airport",
      area_code: "KRP",
      city_name: "Karup",
      country_code: "DK"
  },
  {
      id: "KRR",
      name: "Krasnodar Airport",
      area_code: "KRR",
      city_name: "Krasnodar",
      country_code: "RU"
  },
  {
      id: "KRS",
      name: "Kjevik Airport",
      area_code: "KRS",
      city_name: "Kristiansand",
      country_code: "NO"
  },
  {
      id: "KRW",
      name: "Turkmenbashi Airport",
      area_code: "KRW",
      city_name: "Turkmenbashi",
      country_code: "TM"
  },
  {
      id: "KSF",
      name: "Kassel-Calden Airport",
      area_code: "KSF",
      city_name: "Kassel",
      country_code: "DE"
  },
  {
      id: "KSH",
      name: "Kermanshah Airport",
      area_code: "KSH",
      city_name: "Kermanshah",
      country_code: "IR"
  },
  {
      id: "KSJ",
      name: "KASOS ISLAND AIRPORT",
      area_code: "KSJ",
      city_name: "Kasos Island",
      country_code: "GR"
  },
  {
      id: "KSN",
      name: "KOSTANAY AIRPORT",
      area_code: "KSN",
      city_name: "Kostanay",
      country_code: "KZ"
  },
  {
      id: "KSO",
      name: "Aristotlis Airport",
      area_code: "KSO",
      city_name: "Kastoria",
      country_code: "GR"
  },
  {
      id: "KSU",
      name: "KVERNBERGET AIRPORT",
      area_code: "KSU",
      city_name: "Kristiansund",
      country_code: "NO"
  },
  {
      id: "KTA",
      name: "KARRATHA AIRPORT",
      area_code: "KTA",
      city_name: "Karratha",
      country_code: "AU"
  },
  {
      id: "KTE",
      name: "Kerteh Airport",
      area_code: "KTE",
      city_name: "Kerteh",
      country_code: "MY"
  },
  {
      id: "KTG",
      name: "Ketapang Airport",
      area_code: "KTG",
      city_name: "Ketapang",
      country_code: "ID"
  },
  {
      id: "KTL",
      name: "KITALE AIRPORT",
      area_code: "KTL",
      city_name: "Kitale",
      country_code: "KE"
  },
  {
      id: "WFB",
      name: "Harbor SPB",
      area_code: "KTN",
      city_name: "Ketchikan",
      country_code: "US"
  },
  {
      id: "KTS",
      name: "Brevig Mission Airport",
      area_code: "KTS",
      city_name: "Brevig Mission",
      country_code: "US"
  },
  {
      id: "KTT",
      name: "Kittila Airport",
      area_code: "KTT",
      city_name: "Kittila",
      country_code: "FI"
  },
  {
      id: "KTU",
      name: "Kota",
      area_code: "KTU",
      city_name: "Kota",
      country_code: "IN"
  },
  {
      id: "KUF",
      name: "Kurumoch Airport",
      area_code: "KUF",
      city_name: "Samara",
      country_code: "RU"
  },
  {
      id: "KUK",
      name: "KASIGLUK AIRPORT",
      area_code: "KUK",
      city_name: "Kasigluk",
      country_code: "US"
  },
  {
      id: "SZB",
      name: "Sultan Abdul Aziz Shah Airport",
      area_code: "KUL",
      city_name: "Kuala Lumpur",
      country_code: "MY"
  },
  {
      id: "KUS",
      name: "Kulusuk Airport",
      area_code: "KUS",
      city_name: "Kulusuk",
      country_code: "GL"
  },
  {
      id: "KUT",
      name: "Kutaisi Airport",
      area_code: "KUT",
      city_name: "Kutaisi",
      country_code: "GE"
  },
  {
      id: "KUU",
      name: "BHUNTAR AIRPORT",
      area_code: "KUU",
      city_name: "Kullu",
      country_code: "IN"
  },
  {
      id: "KUV",
      name: "GUNSAN AIRPORT",
      area_code: "KUV",
      city_name: "Gunsan",
      country_code: "KR"
  },
  {
      id: "KVA",
      name: "MEGAS ALEXANDROS AIRPORT",
      area_code: "KVA",
      city_name: "Kavala",
      country_code: "GR"
  },
  {
      id: "KVB",
      name: "Skovde",
      area_code: "KVB",
      city_name: "Skovde",
      country_code: "SE"
  },
  {
      id: "KVG",
      name: "Kavieng Airport",
      area_code: "KVG",
      city_name: "Kavieng",
      country_code: "PG"
  },
  {
      id: "KVX",
      name: "Kirov Airport",
      area_code: "KVX",
      city_name: "Kirov",
      country_code: "RU"
  },
  {
      id: "KWA",
      name: "Kwajalein Airport",
      area_code: "KWA",
      city_name: "Kwajalein Island",
      country_code: "MH"
  },
  {
      id: "KWF",
      name: "Waterfall Seaplane Base",
      area_code: "KWF",
      city_name: "Waterfall",
      country_code: "US"
  },
  {
      id: "KWG",
      name: "Lozuvatka Intl",
      area_code: "KWG",
      city_name: "Krivyi Rih",
      country_code: "UA"
  },
  {
      id: "KXK",
      name: "Komsomolsk Na Amure Airport",
      area_code: "KXK",
      city_name: "Komsomolsk-on-Amur",
      country_code: "RU"
  },
  {
      id: "KZO",
      name: "Kyzylorda Airport",
      area_code: "KZO",
      city_name: "Kyzylorda",
      country_code: "KZ"
  },
  {
      id: "KZR",
      name: "Zafer Airport",
      area_code: "KZR",
      city_name: "Kutahya",
      country_code: "TR"
  },
  {
      id: "KZS",
      name: "Kastelorizo Airport",
      area_code: "KZS",
      city_name: "Megisti",
      country_code: "GR"
  },
  {
      id: "LAJ",
      name: "Lages Airport",
      area_code: "LAJ",
      city_name: "Lages",
      country_code: "BR"
  },
  {
      id: "LAL",
      name: "Linder Regional",
      area_code: "LAL",
      city_name: "Lakeland",
      country_code: "US"
  },
  {
      id: "LAM",
      name: "Los Alamos",
      area_code: "LAM",
      city_name: "Los Alamos",
      country_code: "US"
  },
  {
      id: "SAA",
      name: "Shively Field",
      area_code: "SAA",
      city_name: "Saratoga",
      country_code: "US"
  },
  {
      id: "LSV",
      name: "Nellis AFB",
      area_code: "LAS",
      city_name: "Las Vegas",
      country_code: "US"
  },
  {
      id: "JIO",
      name: "Jos Orno Imsula Airport",
      area_code: "JIO",
      city_name: "Tiakur",
      country_code: "ID"
  },
  {
      id: "SMO",
      name: "Municipal",
      area_code: "SMO",
      city_name: "Santa Monica",
      country_code: "US"
  },
  {
      id: "VNY",
      name: "Van Nuys",
      area_code: "LAX",
      city_name: "Los Angeles",
      country_code: "US"
  },
  {
      id: "WHP",
      name: "Whiteman",
      area_code: "LAX",
      city_name: "Los Angeles",
      country_code: "US"
  },
  {
      id: "LAY",
      name: "Ladysmith",
      area_code: "LAY",
      city_name: "Ladysmith",
      country_code: "ZA"
  },
  {
      id: "REE",
      name: "Reese Airpark",
      area_code: "LBB",
      city_name: "Lubbock",
      country_code: "US"
  },
  {
      id: "LBC",
      name: "Luebeck-Blankensee",
      area_code: "HAM",
      city_name: "Hamburg",
      country_code: "DE"
  },
  {
      id: "LBI",
      name: "Le Sequestre",
      area_code: "LBI",
      city_name: "Albi",
      country_code: "FR"
  },
  {
      id: "LBJ",
      name: "Komodo Airport",
      area_code: "LBJ",
      city_name: "Labuan Bajo",
      country_code: "ID"
  },
  {
      id: "LBP",
      name: "Long Banga Airport",
      area_code: "LBP",
      city_name: "Long Banga",
      country_code: "MY"
  },
  {
      id: "LBS",
      name: "LABASA AIRPORT",
      area_code: "LBS",
      city_name: "Labasa",
      country_code: "FJ"
  },
  {
      id: "LBT",
      name: "Municipal",
      area_code: "LBT",
      city_name: "Lumberton",
      country_code: "US"
  },
  {
      id: "LBY",
      name: "La Baule-Escoublac",
      area_code: "LBY",
      city_name: "La Baule-Escoublac",
      country_code: "FR"
  },
  {
      id: "LCC",
      name: "Galatina",
      area_code: "LCC",
      city_name: "Lecce",
      country_code: "IT"
  },
  {
      id: "LCF",
      name: "Las Vegas",
      area_code: "LCF",
      city_name: "Rio Dulce",
      country_code: "GT"
  },
  {
      id: "LCI",
      name: "Municipal",
      area_code: "LCI",
      city_name: "Laconia",
      country_code: "US"
  },
  {
      id: "LCJ",
      name: "Wladyslaw Reymont Airport",
      area_code: "LCJ",
      city_name: "Lodz",
      country_code: "PL"
  },
  {
      id: "LCR",
      name: "LA CHORRERA AIRPORT",
      area_code: "LCR",
      city_name: "La Chorrera",
      country_code: "CO"
  },
  {
      id: "LCV",
      name: "Tassignano",
      area_code: "LCV",
      city_name: "Lucca",
      country_code: "IT"
  },
  {
      id: "LDA",
      name: "Malda",
      area_code: "LDA",
      city_name: "Malda",
      country_code: "IN"
  },
  {
      id: "LDE",
      name: "Pyrenees Airport",
      area_code: "LDE",
      city_name: "Lourdes/Tarbes",
      country_code: "FR"
  },
  {
      id: "LDH",
      name: "LORD HOWE ISLAND AIRPORT",
      area_code: "LDH",
      city_name: "Lord Howe Island",
      country_code: "AU"
  },
  {
      id: "LDJ",
      name: "Linden",
      area_code: "LDJ",
      city_name: "Linden",
      country_code: "US"
  },
  {
      id: "LDK",
      name: "Hovby",
      area_code: "LDK",
      city_name: "Lidkoping",
      country_code: "SE"
  },
  {
      id: "LDX",
      name: "St-Laurent du Maroni",
      area_code: "LDX",
      city_name: "St-Laurent du Maroni",
      country_code: "GF"
  },
  {
      id: "LEA",
      name: "LEARMONTH AIRPORT",
      area_code: "LEA",
      city_name: "Learmonth",
      country_code: "AU"
  },
  {
      id: "CNH",
      name: "Municipal",
      area_code: "CNH",
      city_name: "Claremont",
      country_code: "US"
  },
  {
      id: "LEC",
      name: "Coronel Horacio de Mattos Airport",
      area_code: "LEC",
      city_name: "Lencois",
      country_code: "BR"
  },
  {
      id: "RVH",
      name: "Rzhevka",
      area_code: "LED",
      city_name: "St Petersburg",
      country_code: "RU"
  },
  {
      id: "LEE",
      name: "International",
      area_code: "LEE",
      city_name: "Leesburg",
      country_code: "US"
  },
  {
      id: "LEQ",
      name: "St Just",
      area_code: "LEQ",
      city_name: "Land's End",
      country_code: "GB"
  },
  {
      id: "LER",
      name: "Leinster",
      area_code: "LER",
      city_name: "Leinster",
      country_code: "AU"
  },
  {
      id: "LES",
      name: "Lesobeng",
      area_code: "LES",
      city_name: "Lesobeng",
      country_code: "LS"
  },
  {
      id: "LET",
      name: "Leticia Airport",
      area_code: "LET",
      city_name: "Leticia",
      country_code: "CO"
  },
  {
      id: "LEW",
      name: "Municipal",
      area_code: "LEW",
      city_name: "Lewiston/Auburn",
      country_code: "US"
  },
  {
      id: "LEY",
      name: "Lelystad",
      area_code: "LEY",
      city_name: "Lelystad",
      country_code: "NL"
  },
  {
      id: "LFI",
      name: "Langley AFB",
      area_code: "LFI",
      city_name: "Hampton",
      country_code: "US"
  },
  {
      id: "LFK",
      name: "Angelina County",
      area_code: "LFK",
      city_name: "Lufkin",
      country_code: "US"
  },
  {
      id: "LGC",
      name: "Callaway",
      area_code: "LGC",
      city_name: "La Grange",
      country_code: "US"
  },
  {
      id: "LGD",
      name: "Union County",
      area_code: "LGD",
      city_name: "La Grande",
      country_code: "US"
  },
  {
      id: "LGG",
      name: "Liege Airport",
      area_code: "LGG",
      city_name: "Liege",
      country_code: "BE"
  },
  {
      id: "LGI",
      name: "Deadmans Cay Airport",
      area_code: "LGI",
      city_name: "Deadmans Cay",
      country_code: "BS"
  },
  {
      id: "LGL",
      name: "LONG LELLANG AIRPORT",
      area_code: "LGL",
      city_name: "Long Lellang",
      country_code: "MY"
  },
  {
      id: "LGS",
      name: "Comodoro D.R. Salomon",
      area_code: "LGS",
      city_name: "Malargue",
      country_code: "AR"
  },
  {
      id: "LGU",
      name: "Logan-Cache",
      area_code: "LGU",
      city_name: "Logan",
      country_code: "US"
  },
  {
      id: "LHA",
      name: "Black Forest",
      area_code: "LHA",
      city_name: "Lahr",
      country_code: "DE"
  },
  {
      id: "LHG",
      name: "Lightning Ridge",
      area_code: "LHG",
      city_name: "Lightning Ridge",
      country_code: "AU"
  },
  {
      id: "LIC",
      name: "Municipal",
      area_code: "LIC",
      city_name: "Limon",
      country_code: "US"
  },
  {
      id: "LIF",
      name: "Lifou Airport",
      area_code: "LIF",
      city_name: "Lifou",
      country_code: "NC"
  },
  {
      id: "LIO",
      name: "International",
      area_code: "LIO",
      city_name: "Limon",
      country_code: "CR"
  },
  {
      id: "LIV",
      name: "Livengood Camp",
      area_code: "LIV",
      city_name: "Livengood",
      country_code: "US"
  },
  {
      id: "LJN",
      name: "Texas Gulf Coast Rgnl",
      area_code: "LJN",
      city_name: "Angleton/Lake Jackson",
      country_code: "US"
  },
  {
      id: "LKG",
      name: "Lokichogio",
      area_code: "LKG",
      city_name: "Lokichogio",
      country_code: "KE"
  },
  {
      id: "LKH",
      name: "LONG AKAH AIRPORT",
      area_code: "LKH",
      city_name: "Long Akah",
      country_code: "MY"
  },
  {
      id: "LKL",
      name: "BANAK AIRPORT",
      area_code: "LKL",
      city_name: "Lakselv",
      country_code: "NO"
  },
  {
      id: "LKN",
      name: "Leknes Airport",
      area_code: "LKN",
      city_name: "Leknes",
      country_code: "NO"
  },
  {
      id: "LLE",
      name: "Malelane",
      area_code: "LLE",
      city_name: "Malelane",
      country_code: "ZA"
  },
  {
      id: "LLI",
      name: "LALIBELA AIRPORT",
      area_code: "LLI",
      city_name: "Lalibela",
      country_code: "ET"
  },
  {
      id: "LLK",
      name: "Lankaran International Airport",
      area_code: "LLK",
      city_name: "Lankaran",
      country_code: "AZ"
  },
  {
      id: "LLO",
      name: "Palopo",
      area_code: "LLO",
      city_name: "Palopo",
      country_code: "ID"
  },
  {
      id: "LLY",
      name: "South Jersey Regional",
      area_code: "LLY",
      city_name: "Mount Holly",
      country_code: "US"
  },
  {
      id: "LMB",
      name: "Salima",
      area_code: "LMB",
      city_name: "Salima",
      country_code: "MW"
  },
  {
      id: "LMC",
      name: "La Macarena",
      area_code: "LMC",
      city_name: "La Macarena",
      country_code: "CO"
  },
  {
      id: "LME",
      name: "Arnage",
      area_code: "LME",
      city_name: "Le Mans",
      country_code: "FR"
  },
  {
      id: "LMM",
      name: "Federal Airport",
      area_code: "LMM",
      city_name: "Los Mochis",
      country_code: "MX"
  },
  {
      id: "LMN",
      name: "LIMBANG AIRPORT",
      area_code: "LMN",
      city_name: "Limbang",
      country_code: "MY"
  },
  {
      id: "LMP",
      name: "LAMPEDUSA AIRPORT",
      area_code: "LMP",
      city_name: "Lampedusa",
      country_code: "IT"
  },
  {
      id: "LMS",
      name: "Winston County",
      area_code: "LMS",
      city_name: "Louisville",
      country_code: "US"
  },
  {
      id: "LNO",
      name: "Leonora Airport",
      area_code: "LNO",
      city_name: "Leonora",
      country_code: "AU"
  },
  {
      id: "LNV",
      name: "Lihir Island Airport",
      area_code: "LNV",
      city_name: "Lihir Island",
      country_code: "PG"
  },
  {
      id: "LOD",
      name: "LONGANA AIRPORT",
      area_code: "LOD",
      city_name: "Longana",
      country_code: "VU"
  },
  {
      id: "LOE",
      name: "LOEI AIRPORT",
      area_code: "LOE",
      city_name: "Loei",
      country_code: "TH"
  },
  {
      id: "LOH",
      name: "Loja Airport",
      area_code: "LOH",
      city_name: "Loja",
      country_code: "EC"
  },
  {
      id: "LOK",
      name: "LODWAR AIRPORT",
      area_code: "LOK",
      city_name: "Lodwar",
      country_code: "KE"
  },
  {
      id: "LOM",
      name: "F. Primo de Verdad",
      area_code: "LOM",
      city_name: "Lagos de Moreno",
      country_code: "MX"
  },
  {
      id: "BQH",
      name: "Biggin Hill",
      area_code: "LON",
      city_name: "London",
      country_code: "GB"
  },
  {
      id: "LOP",
      name: "Lombok International Airport",
      area_code: "LOP",
      city_name: "Praya",
      country_code: "ID"
  },
  {
      id: "LOV",
      name: "Monclova Airport",
      area_code: "LOV",
      city_name: "Monclova",
      country_code: "MX"
  },
  {
      id: "LOY",
      name: "Loyengalani",
      area_code: "LOY",
      city_name: "Loyengalani",
      country_code: "KE"
  },
  {
      id: "LOZ",
      name: "Magee Field",
      area_code: "LOZ",
      city_name: "London/Corbin",
      country_code: "US"
  },
  {
      id: "LPC",
      name: "Lompoc Apt",
      area_code: "LPC",
      city_name: "Lompoc",
      country_code: "US"
  },
  {
      id: "VBG",
      name: "Vandenberg AFB",
      area_code: "LPC",
      city_name: "Lompoc",
      country_code: "US"
  },
  {
      id: "LPD",
      name: "LA PEDRERA AIRPORT",
      area_code: "LPD",
      city_name: "La Pedrera",
      country_code: "CO"
  },
  {
      id: "LPG",
      name: "La Plata",
      area_code: "LPG",
      city_name: "La Plata",
      country_code: "AR"
  },
  {
      id: "LPI",
      name: "Linkoping City Airport",
      area_code: "LPI",
      city_name: "Linkoping",
      country_code: "SE"
  },
  {
      id: "LPK",
      name: "LIPETSK AIRPORT",
      area_code: "LPK",
      city_name: "Lipetsk",
      country_code: "RU"
  },
  {
      id: "LPM",
      name: "Lamap Airport",
      area_code: "LPM",
      city_name: "Lamap",
      country_code: "VU"
  },
  {
      id: "LPO",
      name: "Municipal",
      area_code: "LPO",
      city_name: "LaPorte",
      country_code: "US"
  },
  {
      id: "LPP",
      name: "LAPPEENRANTA AIRPORT",
      area_code: "LPP",
      city_name: "Lappeenranta",
      country_code: "FI"
  },
  {
      id: "LPS",
      name: "Lopez Island",
      area_code: "LPS",
      city_name: "Lopez Island",
      country_code: "US"
  },
  {
      id: "LPX",
      name: "Liepaya",
      area_code: "LPX",
      city_name: "Liepaja",
      country_code: "LV"
  },
  {
      id: "LPY",
      name: "Loudes Airport",
      area_code: "LPY",
      city_name: "Le Puy",
      country_code: "FR"
  },
  {
      id: "LQM",
      name: "Puerto Leguizamo Airport",
      area_code: "LQM",
      city_name: "Puerto Leguizamo",
      country_code: "CO"
  },
  {
      id: "LRA",
      name: "Thessalia",
      area_code: "LRA",
      city_name: "Larisa",
      country_code: "GR"
  },
  {
      id: "LRF",
      name: "Little Rock AFB",
      area_code: "LRF",
      city_name: "Jacksonville",
      country_code: "US"
  },
  {
      id: "LRM",
      name: "La Romana Airport",
      area_code: "LRM",
      city_name: "La Romana",
      country_code: "DO"
  },
  {
      id: "LRS",
      name: "LEROS AIRPORT",
      area_code: "LRS",
      city_name: "Leros",
      country_code: "GR"
  },
  {
      id: "LRU",
      name: "International",
      area_code: "LRU",
      city_name: "Las Cruces",
      country_code: "US"
  },
  {
      id: "LSA",
      name: "Losuia Airport",
      area_code: "LSA",
      city_name: "Kiriwina Island",
      country_code: "PG"
  },
  {
      id: "LSC",
      name: "LA FLORIDA AIRPORT",
      area_code: "LSC",
      city_name: "La Serena",
      country_code: "CL"
  },
  {
      id: "LSI",
      name: "SUMBURGH AIRPORT",
      area_code: "SDZ",
      city_name: "Shetland Islands",
      country_code: "GB"
  },
  {
      id: "LWK",
      name: "Lerwick/Tingwall",
      area_code: "SDZ",
      city_name: "Shetland Islands",
      country_code: "GB"
  },
  {
      id: "LSN",
      name: "Municipal",
      area_code: "LSN",
      city_name: "Los Banos",
      country_code: "US"
  },
  {
      id: "LSO",
      name: "Talmont",
      area_code: "LSO",
      city_name: "Les Sables-d'Olonne",
      country_code: "FR"
  },
  {
      id: "LSP",
      name: "Josefa Camejo Airport",
      area_code: "LSP",
      city_name: "Las Piedras",
      country_code: "VE"
  },
  {
      id: "LSQ",
      name: "Maria Dolores",
      area_code: "LSQ",
      city_name: "Los Angeles",
      country_code: "CL"
  },
  {
      id: "LSY",
      name: "LISMORE AIRPORT",
      area_code: "LSY",
      city_name: "Lismore",
      country_code: "AU"
  },
  {
      id: "LTA",
      name: "Tzaneen",
      area_code: "LTA",
      city_name: "Tzaneen",
      country_code: "ZA"
  },
  {
      id: "LTK",
      name: "Bassel Al-Assad International Airport",
      area_code: "LTK",
      city_name: "Latakia",
      country_code: "SY"
  },
  {
      id: "LTO",
      name: "Loreto Airport",
      area_code: "LTO",
      city_name: "Loreto",
      country_code: "MX"
  },
  {
      id: "LTQ",
      name: "Le Touquet-Paris-Plage",
      area_code: "LTQ",
      city_name: "Le Touquet-Paris-Plage",
      country_code: "FR"
  },
  {
      id: "AXS",
      name: "Quartz Mountain Rgnl",
      area_code: "LTS",
      city_name: "Altus",
      country_code: "US"
  },
  {
      id: "LTS",
      name: "Altus Air Force Base",
      area_code: "LTS",
      city_name: "Altus",
      country_code: "US"
  },
  {
      id: "LTT",
      name: "La Mole Airport",
      area_code: "LTT",
      city_name: "SAITT TROPEZ LA MOLE",
      country_code: "FR"
  },
  {
      id: "LTU",
      name: "Latur",
      area_code: "LTU",
      city_name: "Latur",
      country_code: "IN"
  },
  {
      id: "LTX",
      name: "Cotapaxi International",
      area_code: "LTX",
      city_name: "Latacunga",
      country_code: "EC"
  },
  {
      id: "LUG",
      name: "Lugano Airport",
      area_code: "LUG",
      city_name: "Lugano",
      country_code: "CH"
  },
  {
      id: "LUL",
      name: "Hesler-Noble Field",
      area_code: "LUL",
      city_name: "Hattiesburg/Laurel",
      country_code: "US"
  },
  {
      id: "LUP",
      name: "Kalaupapa",
      area_code: "LUP",
      city_name: "Kalaupapa",
      country_code: "US"
  },
  {
      id: "LUQ",
      name: "San Luis Airport",
      area_code: "LUQ",
      city_name: "San Luis",
      country_code: "AR"
  },
  {
      id: "LUW",
      name: "Luwuk Airport",
      area_code: "LUW",
      city_name: "Luwuk",
      country_code: "ID"
  },
  {
      id: "LUZ",
      name: "Lublin Airport",
      area_code: "LUZ",
      city_name: "Lublin",
      country_code: "PL"
  },
  {
      id: "LVA",
      name: "Entrammes",
      area_code: "LVA",
      city_name: "Laval",
      country_code: "FR"
  },
  {
      id: "LVD",
      name: "Lime Village",
      area_code: "LVD",
      city_name: "Lime Village",
      country_code: "US"
  },
  {
      id: "LVK",
      name: "Municipal",
      area_code: "LVK",
      city_name: "Livermore",
      country_code: "US"
  },
  {
      id: "LVM",
      name: "Mission Field",
      area_code: "LVM",
      city_name: "Livingston",
      country_code: "US"
  },
  {
      id: "LVS",
      name: "Municipal",
      area_code: "LVS",
      city_name: "Las Vegas",
      country_code: "US"
  },
  {
      id: "TCC",
      name: "Municipal",
      area_code: "TCC",
      city_name: "Tucumcari",
      country_code: "US"
  },
  {
      id: "LWB",
      name: "GREENBRIER VALLEY AIRPORT",
      area_code: "LWB",
      city_name: "Lewisburg",
      country_code: "US"
  },
  {
      id: "LWC",
      name: "Municipal",
      area_code: "LWC",
      city_name: "Lawrence",
      country_code: "US"
  },
  {
      id: "LWM",
      name: "Municipal",
      area_code: "LWM",
      city_name: "Lawrence",
      country_code: "US"
  },
  {
      id: "LWY",
      name: "LAWAS AIRPORT",
      area_code: "LWY",
      city_name: "Lawas",
      country_code: "MY"
  },
  {
      id: "LXG",
      name: "Luang Namtha Airport",
      area_code: "LXG",
      city_name: "Luang Namtha",
      country_code: "LA"
  },
  {
      id: "LXN",
      name: "Jim Kelly Field",
      area_code: "LXN",
      city_name: "Lexington",
      country_code: "US"
  },
  {
      id: "LXS",
      name: "Limnos Airport",
      area_code: "LXS",
      city_name: "Limnos",
      country_code: "GR"
  },
  {
      id: "LXV",
      name: "Lake County",
      area_code: "LXV",
      city_name: "Leadville",
      country_code: "US"
  },
  {
      id: "LYB",
      name: "Edward Bodden Airfield",
      area_code: "LYB",
      city_name: "Little Cayman",
      country_code: "KY"
  },
  {
      id: "LYC",
      name: "LYCKSELE AIRPORT",
      area_code: "LYC",
      city_name: "Lycksele",
      country_code: "SE"
  },
  {
      id: "LYR",
      name: "SVALBARD AIRPORT",
      area_code: "LYR",
      city_name: "Longyearbyen",
      country_code: "NO"
  },
  {
      id: "LYN",
      name: "Bron",
      area_code: "LYS",
      city_name: "Lyon",
      country_code: "FR"
  },
  {
      id: "LYX",
      name: "London Ashford",
      area_code: "LYX",
      city_name: "Lydd",
      country_code: "GB"
  },
  {
      id: "LZC",
      name: "LAZARO CARDENAS AIRPORT",
      area_code: "LZC",
      city_name: "Lazaro Cardenas",
      country_code: "MX"
  },
  {
      id: "LZR",
      name: "Lizard Island",
      area_code: "LZR",
      city_name: "Lizard Island",
      country_code: "AU"
  },
  {
      id: "MAB",
      name: "Maraba Airport",
      area_code: "MAB",
      city_name: "Maraba",
      country_code: "BR"
  },
  {
      id: "TOJ",
      name: "Torrejon",
      area_code: "MAD",
      city_name: "Madrid",
      country_code: "ES"
  },
  {
      id: "MAE",
      name: "Municipal",
      area_code: "MAE",
      city_name: "Madera",
      country_code: "US"
  },
  {
      id: "MDD",
      name: "Airpark",
      area_code: "MAF",
      city_name: "Midland",
      country_code: "US"
  },
  {
      id: "MAK",
      name: "Malakal",
      area_code: "MAK",
      city_name: "Malakal",
      country_code: "SD"
  },
  {
      id: "MAM",
      name: "General Servando Canales International Airport",
      area_code: "MAM",
      city_name: "Matamoros",
      country_code: "MX"
  },
  {
      id: "MAQ",
      name: "MAE SOT AIRPORT",
      area_code: "MAQ",
      city_name: "Mae Sot",
      country_code: "TH"
  },
  {
      id: "MAS",
      name: "Momote Airport",
      area_code: "MAS",
      city_name: "Manus Island",
      country_code: "PG"
  },
  {
      id: "MAU",
      name: "MAUPITI AIRPORT",
      area_code: "MAU",
      city_name: "Maupiti",
      country_code: "PF"
  },
  {
      id: "MAZ",
      name: "Eugenio M. de Hostos Airport",
      area_code: "MAZ",
      city_name: "Mayaguez",
      country_code: "PR"
  },
  {
      id: "MBD",
      name: "Mmabatho International Airport",
      area_code: "MBD",
      city_name: "Mmabatho/Mafikeng",
      country_code: "ZA"
  },
  {
      id: "MBT",
      name: "Masbate Airport",
      area_code: "MBT",
      city_name: "Masbate",
      country_code: "PH"
  },
  {
      id: "MBW",
      name: "Moorabbin",
      area_code: "MEL",
      city_name: "Melbourne",
      country_code: "AU"
  },
  {
      id: "MBX",
      name: "Maribor Airport",
      area_code: "MBX",
      city_name: "Maribor",
      country_code: "SI"
  },
  {
      id: "MCE",
      name: "Merced Regional Airport",
      area_code: "MCE",
      city_name: "Merced",
      country_code: "US"
  },
  {
      id: "MER",
      name: "Castle",
      area_code: "MCE",
      city_name: "Merced",
      country_code: "US"
  },
  {
      id: "MAC",
      name: "Downtown Apt",
      area_code: "MCN",
      city_name: "Macon",
      country_code: "US"
  },
  {
      id: "WRB",
      name: "Robins AFB",
      area_code: "WRB",
      city_name: "Warner Robins",
      country_code: "US"
  },
  {
      id: "MCQ",
      name: "Miskolc",
      area_code: "MCQ",
      city_name: "Miskolc",
      country_code: "HU"
  },
  {
      id: "MCV",
      name: "McArthur River Mine Airport",
      area_code: "MCV",
      city_name: "McArthur River Mine",
      country_code: "AU"
  },
  {
      id: "MCY",
      name: "MAROOCHYDORE AIRPORT",
      area_code: "MCY",
      city_name: "Sunshine Coast",
      country_code: "AU"
  },
  {
      id: "EOH",
      name: "Enrique Olaya Herrera Airport",
      area_code: "MDE",
      city_name: "Medellin",
      country_code: "CO"
  },
  {
      id: "MDH",
      name: "Southern Illinois",
      area_code: "MDH",
      city_name: "Carbondale/Murphysboro",
      country_code: "US"
  },
  {
      id: "MDI",
      name: "Makurdi",
      area_code: "MDI",
      city_name: "Makurdi",
      country_code: "NG"
  },
  {
      id: "MDO",
      name: "Middleton Island",
      area_code: "MDO",
      city_name: "Middleton Island",
      country_code: "US"
  },
  {
      id: "MDQ",
      name: "Mar Del Plata Airport",
      area_code: "MDQ",
      city_name: "Mar del Plata",
      country_code: "AR"
  },
  {
      id: "MDU",
      name: "Mendi Airport",
      area_code: "MDU",
      city_name: "Mendi",
      country_code: "PG"
  },
  {
      id: "MDY",
      name: "Henderson Field",
      area_code: "MDY",
      city_name: "Sand Island",
      country_code: "US"
  },
  {
      id: "MEA",
      name: "Benedito Lacerda",
      area_code: "MEA",
      city_name: "Macae",
      country_code: "BR"
  },
  {
      id: "MEC",
      name: "Eloy Alfaro International Airport",
      area_code: "MEC",
      city_name: "Manta",
      country_code: "EC"
  },
  {
      id: "MEH",
      name: "Mehamn Airport",
      area_code: "MEH",
      city_name: "Mehamn",
      country_code: "NO"
  },
  {
      id: "AVV",
      name: "AVALON AIRPORT",
      area_code: "MEL",
      city_name: "Melbourne",
      country_code: "AU"
  },
  {
      id: "MEB",
      name: "ESSENDON AIRPORT",
      area_code: "MEL",
      city_name: "Melbourne",
      country_code: "AU"
  },
  {
      id: "NQA",
      name: "Regional Jetport",
      area_code: "NQA",
      city_name: "Millington",
      country_code: "US"
  },
  {
      id: "MEN",
      name: "Brenoux",
      area_code: "MEN",
      city_name: "Mende",
      country_code: "FR"
  },
  {
      id: "MEO",
      name: "Dare County Regional",
      area_code: "MEO",
      city_name: "Manteo",
      country_code: "US"
  },
  {
      id: "KNO",
      name: "KUALANAMU INTERNATIONAL AIRPORT",
      area_code: "MES",
      city_name: "Medan",
      country_code: "ID"
  },
  {
      id: "MEU",
      name: "Serra do Areao",
      area_code: "MEU",
      city_name: "Monte Dourado",
      country_code: "BR"
  },
  {
      id: "MEV",
      name: "Minden-Tahoe",
      area_code: "MEV",
      city_name: "Minden",
      country_code: "US"
  },
  {
      id: "AZP",
      name: "Atizapan-Jimenez Cantu",
      area_code: "MEX",
      city_name: "Mexico City",
      country_code: "MX"
  },
  {
      id: "NLU",
      name: "Santa Lucia AFB",
      area_code: "MEX",
      city_name: "Mexico City",
      country_code: "MX"
  },
  {
      id: "MEZ",
      name: "Messina",
      area_code: "MEZ",
      city_name: "Messina",
      country_code: "ZA"
  },
  {
      id: "MFD",
      name: "Lahm Regional",
      area_code: "MFD",
      city_name: "Mansfield",
      country_code: "US"
  },
  {
      id: "MFH",
      name: "Mesquite",
      area_code: "MFH",
      city_name: "Mesquite",
      country_code: "US"
  },
  {
      id: "MFI",
      name: "Municipal",
      area_code: "MFI",
      city_name: "Marshfield",
      country_code: "US"
  },
  {
      id: "MFK",
      name: "Matsu Airport",
      area_code: "MFK",
      city_name: "Matsu (Beigan)",
      country_code: "TW"
  },
  {
      id: "MFN",
      name: "Milford Sound",
      area_code: "MFN",
      city_name: "Milford Sound",
      country_code: "NZ"
  },
  {
      id: "MFU",
      name: "MFUWE AIRPORT",
      area_code: "MFU",
      city_name: "Mfuwe",
      country_code: "ZM"
  },
  {
      id: "MGB",
      name: "MOUNT GAMBIER AIRPORT",
      area_code: "MGB",
      city_name: "Mount Gambier",
      country_code: "AU"
  },
  {
      id: "MGC",
      name: "Municipal",
      area_code: "MGC",
      city_name: "Michigan City",
      country_code: "US"
  },
  {
      id: "MGE",
      name: "Dobbins ARB",
      area_code: "MGE",
      city_name: "Marietta",
      country_code: "US"
  },
  {
      id: "MGF",
      name: "Regional De Maringa Airport",
      area_code: "MGF",
      city_name: "Maringa",
      country_code: "BR"
  },
  {
      id: "MGH",
      name: "MARGATE AIRPORT",
      area_code: "MGH",
      city_name: "Margate",
      country_code: "ZA"
  },
  {
      id: "MGJ",
      name: "Orange County",
      area_code: "MGJ",
      city_name: "Montgomery",
      country_code: "US"
  },
  {
      id: "MXF",
      name: "Maxwell AFB",
      area_code: "MGM",
      city_name: "Montgomery",
      country_code: "US"
  },
  {
      id: "MGR",
      name: "Municipal",
      area_code: "MGR",
      city_name: "Moultrie",
      country_code: "US"
  },
  {
      id: "MUL",
      name: "Spence",
      area_code: "MGR",
      city_name: "Moultrie",
      country_code: "US"
  },
  {
      id: "MGV",
      name: "Margaret River Station",
      area_code: "MGV",
      city_name: "Margaret River Station",
      country_code: "AU"
  },
  {
      id: "MGW",
      name: "Morgantown Municipal Airport",
      area_code: "MGW",
      city_name: "Morgantown",
      country_code: "US"
  },
  {
      id: "MHG",
      name: "Mannheim City Airport",
      area_code: "MHG",
      city_name: "Mannheim",
      country_code: "DE"
  },
  {
      id: "MHH",
      name: "Marsh Harbour Airport",
      area_code: "MHH",
      city_name: "Marsh Harbour",
      country_code: "BS"
  },
  {
      id: "MHK",
      name: "Manhattan Regional Airport",
      area_code: "MHK",
      city_name: "Manhattan",
      country_code: "US"
  },
  {
      id: "MHQ",
      name: "Mariehamn Airport",
      area_code: "MHQ",
      city_name: "Mariehamn",
      country_code: "FI"
  },
  {
      id: "MHU",
      name: "Mount Hotham",
      area_code: "MHU",
      city_name: "Mount Hotham",
      country_code: "AU"
  },
  {
      id: "MHV",
      name: "Mojave",
      area_code: "MHV",
      city_name: "Mojave",
      country_code: "US"
  },
  {
      id: "MHZ",
      name: "RAF Station",
      area_code: "MHZ",
      city_name: "Mildenhall",
      country_code: "GB"
  },
  {
      id: "HWO",
      name: "North Perry",
      area_code: "HWO",
      city_name: "Hollywood",
      country_code: "US"
  },
  {
      id: "TNT",
      name: "Dade-Collier T&T Apt",
      area_code: "MIA",
      city_name: "Miami",
      country_code: "US"
  },
  {
      id: "MII",
      name: "Dr Gastao Vidigal Airport",
      area_code: "MII",
      city_name: "Marilia",
      country_code: "BR"
  },
  {
      id: "MIK",
      name: "Mikkeli",
      area_code: "MIK",
      city_name: "Mikkeli",
      country_code: "FI"
  },
  {
      id: "MIM",
      name: "MERIMBULA AIRPORT",
      area_code: "MIM",
      city_name: "Merimbula",
      country_code: "AU"
  },
  {
      id: "MIR",
      name: "Habib Bourguiba International Airport",
      area_code: "MIR",
      city_name: "Monastir",
      country_code: "TN"
  },
  {
      id: "MIS",
      name: "Misima Island Airport",
      area_code: "MIS",
      city_name: "Misima Island",
      country_code: "PG"
  },
  {
      id: "MIV",
      name: "Municipal",
      area_code: "MIV",
      city_name: "Millville",
      country_code: "US"
  },
  {
      id: "MIW",
      name: "Municipal",
      area_code: "MIW",
      city_name: "Marshalltown",
      country_code: "US"
  },
  {
      id: "MJD",
      name: "Mohenjodaro Airport",
      area_code: "MJD",
      city_name: "Moenjodaro",
      country_code: "PK"
  },
  {
      id: "MJF",
      name: "Kjaerstad Airport",
      area_code: "MJF",
      city_name: "Mosjoen",
      country_code: "NO"
  },
  {
      id: "MJK",
      name: "Shark Bay Airport",
      area_code: "MJK",
      city_name: "Monkey Mia",
      country_code: "AU"
  },
  {
      id: "MJR",
      name: "Miramar",
      area_code: "MJR",
      city_name: "Miramar",
      country_code: "AR"
  },
  {
      id: "MJT",
      name: "Mytilene Airport",
      area_code: "MJT",
      city_name: "Mytilini",
      country_code: "GR"
  },
  {
      id: "MJW",
      name: "Mahenye",
      area_code: "MJW",
      city_name: "Mahenye",
      country_code: "ZW"
  },
  {
      id: "MJX",
      name: "Ocean County",
      area_code: "MJX",
      city_name: "Toms River",
      country_code: "US"
  },
  {
      id: "MJZ",
      name: "Mirny Airport",
      area_code: "MJZ",
      city_name: "Mirny",
      country_code: "RU"
  },
  {
      id: "MKA",
      name: "Marianske Lazne",
      area_code: "MKA",
      city_name: "Marianske Lazne",
      country_code: "CZ"
  },
  {
      id: "JCI",
      name: "New Century Aircenter",
      area_code: "MKC",
      city_name: "Kansas City",
      country_code: "US"
  },
  {
      id: "OJC",
      name: "Johnson Co Executive",
      area_code: "MKC",
      city_name: "Kansas City",
      country_code: "US"
  },
  {
      id: "MWC",
      name: "Lawrence J Timmerman",
      area_code: "MKE",
      city_name: "Milwaukee",
      country_code: "US"
  },
  {
      id: "MKK",
      name: "Molokai Airport",
      area_code: "MKK",
      city_name: "Hoolehua",
      country_code: "US"
  },
  {
      id: "MKM",
      name: "MUKAH AIRPORT",
      area_code: "MKM",
      city_name: "Mukah",
      country_code: "MY"
  },
  {
      id: "MKO",
      name: "Davis Field",
      area_code: "MKO",
      city_name: "Muskogee",
      country_code: "US"
  },
  {
      id: "MKQ",
      name: "Mopah Airport",
      area_code: "MKQ",
      city_name: "Merauke",
      country_code: "ID"
  },
  {
      id: "MKT",
      name: "Regional",
      area_code: "MKT",
      city_name: "Mankato",
      country_code: "US"
  },
  {
      id: "MKW",
      name: "RENDANI AIRPORT",
      area_code: "MKW",
      city_name: "Manokwari",
      country_code: "ID"
  },
  {
      id: "MLC",
      name: "Regional",
      area_code: "MLC",
      city_name: "McAlester",
      country_code: "US"
  },
  {
      id: "KDM",
      name: "KAADEDHDHOO AIRPORT",
      area_code: "KDM",
      city_name: "Kaadedhdhoo Island",
      country_code: "MV"
  },
  {
      id: "MLT",
      name: "Municipal",
      area_code: "MLT",
      city_name: "Millinocket",
      country_code: "US"
  },
  {
      id: "MLZ",
      name: "Cerro Largo Intl Airport",
      area_code: "MLZ",
      city_name: "Melo",
      country_code: "UY"
  },
  {
      id: "MMB",
      name: "Memanbetsu Airport",
      area_code: "MMB",
      city_name: "Memanbetsu",
      country_code: "JP"
  },
  {
      id: "MMC",
      name: "Ciudad Mante",
      area_code: "MMC",
      city_name: "Ciudad Mante",
      country_code: "MX"
  },
  {
      id: "MMG",
      name: "Mount Magnet Airport",
      area_code: "MMG",
      city_name: "MOUNT MAGNET",
      country_code: "AU"
  },
  {
      id: "MMH",
      name: "Mammoth Yosemite Airport",
      area_code: "MMH",
      city_name: "Mammoth Lakes",
      country_code: "US"
  },
  {
      id: "MMI",
      name: "McMinn County Airport",
      area_code: "MMI",
      city_name: "Athens",
      country_code: "US"
  },
  {
      id: "MMK",
      name: "MURMANSK AIRPORT",
      area_code: "MMK",
      city_name: "Murmansk",
      country_code: "RU"
  },
  {
      id: "MML",
      name: "Marshall-MML Airport",
      area_code: "MML",
      city_name: "Marshall",
      country_code: "US"
  },
  {
      id: "MMM",
      name: "Middlemount",
      area_code: "MMM",
      city_name: "Middlemount",
      country_code: "AU"
  },
  {
      id: "MMO",
      name: "Maio Island Airport",
      area_code: "MMO",
      city_name: "Maio Island",
      country_code: "CV"
  },
  {
      id: "MMU",
      name: "Morristown Municipal Airport",
      area_code: "MMU",
      city_name: "Morristown",
      country_code: "US"
  },
  {
      id: "MNG",
      name: "MANINGRIDA AIRPORT",
      area_code: "MNG",
      city_name: "Maningrida",
      country_code: "AU"
  },
  {
      id: "MNK",
      name: "Marshall Airport",
      area_code: "MNK",
      city_name: "Maiana",
      country_code: "KI"
  },
  {
      id: "SGL",
      name: "Danilo Atienza AB",
      area_code: "MNL",
      city_name: "Manila",
      country_code: "PH"
  },
  {
      id: "MNM",
      name: "Menominee-Marinette Twin County Airport",
      area_code: "MNM",
      city_name: "Menominee",
      country_code: "US"
  },
  {
      id: "MNO",
      name: "Manono Airport",
      area_code: "MNO",
      city_name: "Manono",
      country_code: "CD"
  },
  {
      id: "MNQ",
      name: "Monto Airport",
      area_code: "MNQ",
      city_name: "Monto",
      country_code: "AU"
  },
  {
      id: "MNT",
      name: "Al Wright Airport",
      area_code: "MNT",
      city_name: "Minto",
      country_code: "US"
  },
  {
      id: "MNZ",
      name: "Regional",
      area_code: "MNZ",
      city_name: "Manassas",
      country_code: "US"
  },
  {
      id: "BFM",
      name: "Downtown Arpt",
      area_code: "MOB",
      city_name: "Mobile",
      country_code: "US"
  },
  {
      id: "MOC",
      name: "Montes Claros Airport",
      area_code: "MOC",
      city_name: "Montes Claros",
      country_code: "BR"
  },
  {
      id: "MOD",
      name: "City-County",
      area_code: "MOD",
      city_name: "Modesto",
      country_code: "US"
  },
  {
      id: "MOI",
      name: "Nukuroa",
      area_code: "MOI",
      city_name: "Mitiaro Island",
      country_code: "CK"
  },
  {
      id: "MOL",
      name: "ARO AIRPORT",
      area_code: "MOL",
      city_name: "Molde",
      country_code: "NO"
  },
  {
      id: "GTN",
      name: "Glentanner",
      area_code: "MON",
      city_name: "Mount Cook",
      country_code: "NZ"
  },
  {
      id: "MON",
      name: "Mount Cook Airport",
      area_code: "MON",
      city_name: "Mount Cook",
      country_code: "NZ"
  },
  {
      id: "TWZ",
      name: "Pukaki (Twizel)",
      area_code: "MON",
      city_name: "Mount Cook",
      country_code: "NZ"
  },
  {
      id: "MOP",
      name: "Municipal",
      area_code: "MOP",
      city_name: "Mount Pleasant",
      country_code: "US"
  },
  {
      id: "MOQ",
      name: "MORONDAVA AIRPORT",
      area_code: "MOQ",
      city_name: "Morondava",
      country_code: "MG"
  },
  {
      id: "MOR",
      name: "Moore-Murrell",
      area_code: "MOR",
      city_name: "Morristown",
      country_code: "US"
  },
  {
      id: "MIB",
      name: "AFB",
      area_code: "MOT",
      city_name: "Minot",
      country_code: "US"
  },
  {
      id: "MOU",
      name: "MOUNTAIN VILLAGE AIRPORT",
      area_code: "MOU",
      city_name: "Mountain Village",
      country_code: "US"
  },
  {
      id: "MOV",
      name: "MORANBAH AIRPORT",
      area_code: "MOV",
      city_name: "Moranbah",
      country_code: "AU"
  },
  {
      id: "VKO",
      name: "Vnukovo Airport",
      area_code: "MOW",
      city_name: "Moscow",
      country_code: "RU"
  },
  {
      id: "MOZ",
      name: "TEMAE AIRPORT",
      area_code: "MOZ",
      city_name: "Moorea",
      country_code: "PF"
  },
  {
      id: "MPH",
      name: "Godofredo P. Ramos Airport",
      area_code: "MPH",
      city_name: "Caticlan",
      country_code: "PH"
  },
  {
      id: "MPN",
      name: "Mount Pleasant Airport",
      area_code: "MPN",
      city_name: "Mount Pleasant",
      country_code: "FK"
  },
  {
      id: "MPQ",
      name: "Maan",
      area_code: "MPQ",
      city_name: "Maan",
      country_code: "JO"
  },
  {
      id: "MPR",
      name: "McPherson",
      area_code: "MPR",
      city_name: "McPherson",
      country_code: "US"
  },
  {
      id: "MPS",
      name: "Regional",
      area_code: "MPS",
      city_name: "Mount Pleasant",
      country_code: "US"
  },
  {
      id: "MPV",
      name: "Edward F. Knapp State",
      area_code: "MPV",
      city_name: "Barre/Montpelier",
      country_code: "US"
  },
  {
      id: "MQF",
      name: "Magnitogorsk Airport",
      area_code: "MQF",
      city_name: "Magnitogorsk",
      country_code: "RU"
  },
  {
      id: "MQK",
      name: "San Matias",
      area_code: "MQK",
      city_name: "San Matias",
      country_code: "BO"
  },
  {
      id: "MQL",
      name: "MILDURA AIRPORT",
      area_code: "MQL",
      city_name: "Mildura",
      country_code: "AU"
  },
  {
      id: "MQN",
      name: "Mo I Rana Airport",
      area_code: "MQN",
      city_name: "Mo i Rana",
      country_code: "NO"
  },
  {
      id: "MQS",
      name: "Mustique",
      area_code: "MQS",
      city_name: "Mustique",
      country_code: "VC"
  },
  {
      id: "MQX",
      name: "Makele Alula Aba Nega Airport",
      area_code: "MQX",
      city_name: "Mekele",
      country_code: "ET"
  },
  {
      id: "MQY",
      name: "Smyrna",
      area_code: "MQY",
      city_name: "Smyrna",
      country_code: "US"
  },
  {
      id: "MQZ",
      name: "Margaret River",
      area_code: "MQZ",
      city_name: "Margaret River",
      country_code: "AU"
  },
  {
      id: "MRA",
      name: "MISURATA AIRPORT",
      area_code: "MRA",
      city_name: "Misurata",
      country_code: "LY"
  },
  {
      id: "MRB",
      name: "Eastern W.V. Regional",
      area_code: "MRB",
      city_name: "Martinsburg",
      country_code: "US"
  },
  {
      id: "MRC",
      name: "Maury County",
      area_code: "MRC",
      city_name: "Columbia/Mt Pleasant",
      country_code: "US"
  },
  {
      id: "MRE",
      name: "Mara Serena Airport",
      area_code: "MRE",
      city_name: "Maasai Mara",
      country_code: "KE"
  },
  {
      id: "MRO",
      name: "Masterton",
      area_code: "MRO",
      city_name: "Masterton",
      country_code: "NZ"
  },
  {
      id: "OAR",
      name: "Marina Municipal",
      area_code: "MRY",
      city_name: "Monterey/Carmel",
      country_code: "US"
  },
  {
      id: "MRZ",
      name: "MOREE AIRPORT",
      area_code: "MRZ",
      city_name: "Moree",
      country_code: "AU"
  },
  {
      id: "MSA",
      name: "Muskrat Dam",
      area_code: "MSA",
      city_name: "Muskrat Dam",
      country_code: "CA"
  },
  {
      id: "MSC",
      name: "Falcon Field",
      area_code: "PHX",
      city_name: "Phoenix",
      country_code: "US"
  },
  {
      id: "MSE",
      name: "Kent International",
      area_code: "MSE",
      city_name: "Manston",
      country_code: "GB"
  },
  {
      id: "MSJ",
      name: "Misawa Airport",
      area_code: "MSJ",
      city_name: "Misawa",
      country_code: "JP"
  },
  {
      id: "FCM",
      name: "Flying Cloud",
      area_code: "MSP",
      city_name: "Minneapolis/St Paul",
      country_code: "US"
  },
  {
      id: "MHP",
      name: "Minsk -1",
      area_code: "MSQ",
      city_name: "Minsk",
      country_code: "BY"
  },
  {
      id: "MSS",
      name: "Massena International Airport",
      area_code: "MSS",
      city_name: "Massena",
      country_code: "US"
  },
  {
      id: "MST",
      name: "Maastricht Aachen Airport",
      area_code: "MST",
      city_name: "Maastricht",
      country_code: "NL"
  },
  {
      id: "MSV",
      name: "Sullivan County Intl",
      area_code: "MSV",
      city_name: "Monticello",
      country_code: "US"
  },
  {
      id: "NBG",
      name: "NAS JRB/A.Callender",
      area_code: "MSY",
      city_name: "New Orleans",
      country_code: "US"
  },
  {
      id: "NEW",
      name: "Lakefront",
      area_code: "MSY",
      city_name: "New Orleans",
      country_code: "US"
  },
  {
      id: "MTC",
      name: "Selfridge ANGB",
      area_code: "MTC",
      city_name: "Mount Clemens",
      country_code: "US"
  },
  {
      id: "MTH",
      name: "The Florida Keys",
      area_code: "MTH",
      city_name: "Marathon",
      country_code: "US"
  },
  {
      id: "MTL",
      name: "Maitland",
      area_code: "MTL",
      city_name: "Maitland",
      country_code: "AU"
  },
  {
      id: "MTP",
      name: "Montauk",
      area_code: "MTP",
      city_name: "Montauk",
      country_code: "US"
  },
  {
      id: "MTT",
      name: "Coatzacoalcos Airport",
      area_code: "MTT",
      city_name: "Minatitlan",
      country_code: "MX"
  },
  {
      id: "MTW",
      name: "Manitowoc County",
      area_code: "MTW",
      city_name: "Manitowoc",
      country_code: "US"
  },
  {
      id: "NTR",
      name: "Del Norte Intl",
      area_code: "MTY",
      city_name: "Monterrey",
      country_code: "MX"
  },
  {
      id: "MUA",
      name: "MUNDA AIRPORT",
      area_code: "MUA",
      city_name: "Munda",
      country_code: "SB"
  },
  {
      id: "MUE",
      name: "Kamuela Airport",
      area_code: "MUE",
      city_name: "Kamuela",
      country_code: "US"
  },
  {
      id: "MUH",
      name: "MERSA MATRUH AIRPORT",
      area_code: "MUH",
      city_name: "Mersa Matruh",
      country_code: "EG"
  },
  {
      id: "MUN",
      name: "Jose Tadeo Monagas International Airport",
      area_code: "MUN",
      city_name: "Maturin",
      country_code: "VE"
  },
  {
      id: "MUO",
      name: "AFB",
      area_code: "MUO",
      city_name: "Mountain Home",
      country_code: "US"
  },
  {
      id: "MUR",
      name: "MARUDI AIRPORT",
      area_code: "MUR",
      city_name: "Marudi",
      country_code: "MY"
  },
  {
      id: "MUZ",
      name: "Musoma Airport",
      area_code: "MUZ",
      city_name: "Musoma",
      country_code: "TZ"
  },
  {
      id: "MVB",
      name: "Mvengue Airport",
      area_code: "MVB",
      city_name: "Franceville",
      country_code: "GA"
  },
  {
      id: "MVC",
      name: "Monroe County",
      area_code: "MVC",
      city_name: "Monroeville",
      country_code: "US"
  },
  {
      id: "MVF",
      name: "Dix-Sept Rosado",
      area_code: "MVF",
      city_name: "Mossoro",
      country_code: "BR"
  },
  {
      id: "MVN",
      name: "Mount Vernon",
      area_code: "MVN",
      city_name: "Mount Vernon",
      country_code: "US"
  },
  {
      id: "MVP",
      name: "Mitu Airport",
      area_code: "MVP",
      city_name: "Mitu",
      country_code: "CO"
  },
  {
      id: "MVQ",
      name: "Mogilev",
      area_code: "MVQ",
      city_name: "Mogilev",
      country_code: "BY"
  },
  {
      id: "MVR",
      name: "Salak Airport",
      area_code: "MVR",
      city_name: "Maroua",
      country_code: "CM"
  },
  {
      id: "MVV",
      name: "Megeve",
      area_code: "MVV",
      city_name: "Megeve",
      country_code: "FR"
  },
  {
      id: "MVY",
      name: "Martha's Vineyard Airport",
      area_code: "MVY",
      city_name: "Martha's Vineyard",
      country_code: "US"
  },
  {
      id: "MVZ",
      name: "Masvingo",
      area_code: "MVZ",
      city_name: "Masvingo",
      country_code: "ZW"
  },
  {
      id: "MWL",
      name: "Mineral Wells",
      area_code: "MWL",
      city_name: "Mineral Wells",
      country_code: "US"
  },
  {
      id: "MWO",
      name: "Regional (Hook Field)",
      area_code: "MWO",
      city_name: "Middletown",
      country_code: "US"
  },
  {
      id: "MXA",
      name: "Municipal",
      area_code: "MXA",
      city_name: "Manila",
      country_code: "US"
  },
  {
      id: "MXN",
      name: "Ploujean",
      area_code: "MXN",
      city_name: "Morlaix",
      country_code: "FR"
  },
  {
      id: "MXS",
      name: "Maota",
      area_code: "MXS",
      city_name: "Maota",
      country_code: "AS"
  },
  {
      id: "MXW",
      name: "Mandalgovi",
      area_code: "MXW",
      city_name: "Mandalgovi",
      country_code: "MN"
  },
  {
      id: "MXX",
      name: "Siljan Airport",
      area_code: "MXX",
      city_name: "Mora",
      country_code: "SE"
  },
  {
      id: "MXY",
      name: "McCarthy",
      area_code: "MXY",
      city_name: "McCarthy",
      country_code: "US"
  },
  {
      id: "MYA",
      name: "MORUYA AIRPORT",
      area_code: "MYA",
      city_name: "Moruya",
      country_code: "AU"
  },
  {
      id: "MYC",
      name: "Mariscal Sucre",
      area_code: "MYC",
      city_name: "Maracay",
      country_code: "VE"
  },
  {
      id: "MYE",
      name: "Miyakejima",
      area_code: "MYE",
      city_name: "Miyakejima",
      country_code: "JP"
  },
  {
      id: "MYH",
      name: "Marble Canyon",
      area_code: "MYH",
      city_name: "Marble Canyon",
      country_code: "US"
  },
  {
      id: "MYK",
      name: "May Creek",
      area_code: "MYK",
      city_name: "May Creek",
      country_code: "US"
  },
  {
      id: "MYL",
      name: "Municipal",
      area_code: "MYL",
      city_name: "McCall",
      country_code: "US"
  },
  {
      id: "MYP",
      name: "Mary Airport",
      area_code: "MYP",
      city_name: "Mary",
      country_code: "TM"
  },
  {
      id: "MYQ",
      name: "Mandakalli",
      area_code: "MYQ",
      city_name: "Mysore",
      country_code: "IN"
  },
  {
      id: "CRE",
      name: "Grand Strand",
      area_code: "MYR",
      city_name: "Myrtle Beach",
      country_code: "US"
  },
  {
      id: "GGE",
      name: "Georgetown County",
      area_code: "GGE",
      city_name: "Georgetown",
      country_code: "US"
  },
  {
      id: "MYR",
      name: "Myrtle Beach International Airport",
      area_code: "MYR",
      city_name: "Myrtle Beach",
      country_code: "US"
  },
  {
      id: "BAB",
      name: "Beale AFB",
      area_code: "MYV",
      city_name: "Marysville",
      country_code: "US"
  },
  {
      id: "MYV",
      name: "Yuba County",
      area_code: "MYV",
      city_name: "Marysville",
      country_code: "US"
  },
  {
      id: "MYW",
      name: "MTWARA AIRPORT",
      area_code: "MYW",
      city_name: "Mtwara",
      country_code: "TZ"
  },
  {
      id: "MZH",
      name: "MERZIFON AIRPORT",
      area_code: "MZH",
      city_name: "Amasya",
      country_code: "TR"
  },
  {
      id: "MZI",
      name: "Ambodedjo",
      area_code: "MZI",
      city_name: "Mopti",
      country_code: "ML"
  },
  {
      id: "MZJ",
      name: "Pinal Airpark",
      area_code: "MZJ",
      city_name: "Marana",
      country_code: "US"
  },
  {
      id: "MZL",
      name: "La Nubia Airport",
      area_code: "MZL",
      city_name: "Manizales",
      country_code: "CO"
  },
  {
      id: "MZM",
      name: "Metz-Frescaty",
      area_code: "ETZ",
      city_name: "Metz/Nancy",
      country_code: "FR"
  },
  {
      id: "MZO",
      name: "SIERRA MAESTRA AIRPORT",
      area_code: "MZO",
      city_name: "Manzanillo",
      country_code: "CU"
  },
  {
      id: "MZR",
      name: "Mazar-e Sharif Airport",
      area_code: "MZR",
      city_name: "Mazar-e Sharif",
      country_code: "AF"
  },
  {
      id: "MZU",
      name: "Muzaffarpur",
      area_code: "MZU",
      city_name: "Muzaffarpur",
      country_code: "IN"
  },
  {
      id: "MZV",
      name: "MULU AIRPORT",
      area_code: "MZV",
      city_name: "Mulu",
      country_code: "MY"
  },
  {
      id: "NAC",
      name: "Naracoorte",
      area_code: "NAC",
      city_name: "Naracoorte",
      country_code: "AU"
  },
  {
      id: "NAG",
      name: "Dr. Babasaheb Ambedkar International Airport",
      area_code: "NAG",
      city_name: "Nagpur",
      country_code: "IN"
  },
  {
      id: "NAJ",
      name: "Nakhichevan Airport",
      area_code: "NAJ",
      city_name: "Nakchivan",
      country_code: "AZ"
  },
  {
      id: "NAK",
      name: "Nakhon Ratchasima",
      area_code: "NAK",
      city_name: "Nakhon Ratchasima",
      country_code: "TH"
  },
  {
      id: "NAL",
      name: "NALCHIK AIRPORT",
      area_code: "NAL",
      city_name: "Nalchik",
      country_code: "RU"
  },
  {
      id: "NAM",
      name: "Namlea",
      area_code: "NAM",
      city_name: "Namlea",
      country_code: "ID"
  },
  {
      id: "NAR",
      name: "Puerto Nare",
      area_code: "NAR",
      city_name: "Puerto Nare",
      country_code: "CO"
  },
  {
      id: "NBC",
      name: "Begishevo Airport",
      area_code: "NBC",
      city_name: "Nizhnekamsk",
      country_code: "RU"
  },
  {
      id: "NBE",
      name: "Hammamet Airport",
      area_code: "NBE",
      city_name: "Enfidha",
      country_code: "TN"
  },
  {
      id: "WIL",
      name: "WILSON AIRPORT",
      area_code: "NBO",
      city_name: "Nairobi",
      country_code: "KE"
  },
  {
      id: "NCA",
      name: "North Caicos",
      area_code: "NCA",
      city_name: "North Caicos",
      country_code: "BS"
  },
  {
      id: "NCI",
      name: "Necocli",
      area_code: "NCI",
      city_name: "Necocli",
      country_code: "CO"
  },
  {
      id: "NCS",
      name: "Newcastle",
      area_code: "NCS",
      city_name: "Newcastle",
      country_code: "ZA"
  },
  {
      id: "NCU",
      name: "NUKUS AIRPORT",
      area_code: "NCU",
      city_name: "Nukus",
      country_code: "UZ"
  },
  {
      id: "NDB",
      name: "NOUADHIBOU AIRPORT",
      area_code: "NDB",
      city_name: "Nouadhibou",
      country_code: "MR"
  },
  {
      id: "NDC",
      name: "NANDED AIRPORT",
      area_code: "NDC",
      city_name: "Nanded",
      country_code: "IN"
  },
  {
      id: "NDK",
      name: "Namdrik Island",
      area_code: "NDK",
      city_name: "Namdrik Island",
      country_code: "MH"
  },
  {
      id: "NDR",
      name: "NADOR AIRPORT",
      area_code: "NDR",
      city_name: "Nador",
      country_code: "MA"
  },
  {
      id: "NDU",
      name: "RUNDU AIRPORT",
      area_code: "NDU",
      city_name: "Rundu",
      country_code: "NA"
  },
  {
      id: "NDY",
      name: "SANDAY AIRPORT",
      area_code: "NDY",
      city_name: "Sanday",
      country_code: "GB"
  },
  {
      id: "NEG",
      name: "Negril",
      area_code: "NEG",
      city_name: "Negril",
      country_code: "JM"
  },
  {
      id: "NEV",
      name: "Vance W. Amory International Airport",
      area_code: "NEV",
      city_name: "Nevis",
      country_code: "KN"
  },
  {
      id: "NFG",
      name: "Nefteyugansk",
      area_code: "NFG",
      city_name: "Nefteyugansk",
      country_code: "RU"
  },
  {
      id: "FLX",
      name: "Municipal",
      area_code: "NFL",
      city_name: "Fallon",
      country_code: "US"
  },
  {
      id: "NFL",
      name: "NAS-Van Voorhis Field",
      area_code: "NFL",
      city_name: "Fallon",
      country_code: "US"
  },
  {
      id: "NGE",
      name: "NGAOUNDERE AIRPORT",
      area_code: "NGE",
      city_name: "Ngaoundere",
      country_code: "CM"
  },
  {
      id: "NGK",
      name: "Nogliki Airport",
      area_code: "NGK",
      city_name: "Nogliki",
      country_code: "RU"
  },
  {
      id: "NKM",
      name: "Komaki Air Force Base",
      area_code: "NGO",
      city_name: "Nagoya",
      country_code: "JP"
  },
  {
      id: "NHZ",
      name: "Executive",
      area_code: "NHZ",
      city_name: "Brunswick",
      country_code: "US"
  },
  {
      id: "NIN",
      name: "Ninilchik",
      area_code: "NIN",
      city_name: "Ninilchik",
      country_code: "US"
  },
  {
      id: "NIT",
      name: "Souche",
      area_code: "NIT",
      city_name: "Niort",
      country_code: "FR"
  },
  {
      id: "NJA",
      name: "NAF",
      area_code: "NJA",
      city_name: "Atsugi",
      country_code: "JP"
  },
  {
      id: "NJC",
      name: "NIZHNEVARTOVSK AIRPORT",
      area_code: "NJC",
      city_name: "Nizhnevartovsk",
      country_code: "RU"
  },
  {
      id: "NJF",
      name: "Al Najaf International Airport",
      area_code: "NJF",
      city_name: "Al Najaf",
      country_code: "IQ"
  },
  {
      id: "NKI",
      name: "Naukati Seaplane Base",
      area_code: "NKI",
      city_name: "Naukiti Bay",
      country_code: "US"
  },
  {
      id: "NLC",
      name: "NAS (Reeves Field)",
      area_code: "NLC",
      city_name: "Lemoore",
      country_code: "US"
  },
  {
      id: "NLD",
      name: "Quetzalcoatl International Airport",
      area_code: "NLD",
      city_name: "Nuevo Laredo",
      country_code: "MX"
  },
  {
      id: "NLG",
      name: "NELSON LAGOON AIRPORT",
      area_code: "NLG",
      city_name: "Nelson Lagoon",
      country_code: "US"
  },
  {
      id: "NLK",
      name: "NORFOLK ISLAND AIRPORT",
      area_code: "NLK",
      city_name: "Norfolk Island",
      country_code: "NF"
  },
  {
      id: "NLP",
      name: "Nelspruit Airport",
      area_code: "NLP",
      city_name: "Nelspruit",
      country_code: "ZA"
  },
  {
      id: "NMA",
      name: "NAMANGAN AIRPORT",
      area_code: "NMA",
      city_name: "Namangan",
      country_code: "UZ"
  },
  {
      id: "NMB",
      name: "Daman",
      area_code: "NMB",
      city_name: "Daman",
      country_code: "IN"
  },
  {
      id: "NNL",
      name: "NONDALTON AIRPORT",
      area_code: "NNL",
      city_name: "Nondalton",
      country_code: "US"
  },
  {
      id: "NNM",
      name: "NARYAN-MAR AIRPORT",
      area_code: "NNM",
      city_name: "Naryan-Mar",
      country_code: "RU"
  },
  {
      id: "NNR",
      name: "Connemara",
      area_code: "NNR",
      city_name: "Spiddal",
      country_code: "IE"
  },
  {
      id: "NNT",
      name: "Nan Nakhon Airport",
      area_code: "NNT",
      city_name: "Nan",
      country_code: "TH"
  },
  {
      id: "NOA",
      name: "Nowra",
      area_code: "NOA",
      city_name: "Nowra",
      country_code: "AU"
  },
  {
      id: "NOB",
      name: "Nosara Beach Airport",
      area_code: "NOB",
      city_name: "Nosara",
      country_code: "CR"
  },
  {
      id: "NOC",
      name: "Ireland West Airport Knock",
      area_code: "NOC",
      city_name: "Knock",
      country_code: "IE"
  },
  {
      id: "NOG",
      name: "International",
      area_code: "NOG",
      city_name: "Nogales",
      country_code: "MX"
  },
  {
      id: "NOJ",
      name: "Nojabrxsk Airport",
      area_code: "NOJ",
      city_name: "Noyabrsk",
      country_code: "RU"
  },
  {
      id: "NOP",
      name: "Sinop",
      area_code: "NOP",
      city_name: "Sinop",
      country_code: "TR"
  },
  {
      id: "NOS",
      name: "Fascene Airport",
      area_code: "NOS",
      city_name: "Nosy-Be",
      country_code: "MG"
  },
  {
      id: "NOT",
      name: "Marin County",
      area_code: "NOT",
      city_name: "Novato",
      country_code: "US"
  },
  {
      id: "GEA",
      name: "MAGENTA AIRPORT",
      area_code: "NOU",
      city_name: "Noumea",
      country_code: "NC"
  },
  {
      id: "NOZ",
      name: "Novokuznetsk Airport",
      area_code: "NOZ",
      city_name: "Novokuznetsk",
      country_code: "RU"
  },
  {
      id: "NPT",
      name: "State",
      area_code: "NPT",
      city_name: "Newport",
      country_code: "US"
  },
  {
      id: "NQI",
      name: "NAS",
      area_code: "NQI",
      city_name: "Kingsville",
      country_code: "US"
  },
  {
      id: "NQN",
      name: "Presidente Peron International Airport",
      area_code: "NQN",
      city_name: "Neuquen",
      country_code: "AR"
  },
  {
      id: "EMA",
      name: "East Midlands Airport",
      area_code: "NQT",
      city_name: "Nottingham",
      country_code: "GB"
  },
  {
      id: "NQT",
      name: "Nottingham Airport",
      area_code: "NQT",
      city_name: "Nottingham",
      country_code: "GB"
  },
  {
      id: "NQU",
      name: "Reyes Murillo Airport",
      area_code: "NQU",
      city_name: "Nuqui",
      country_code: "CO"
  },
  {
      id: "NQY",
      name: "Cornwall Airport",
      area_code: "NQY",
      city_name: "Newquay",
      country_code: "GB"
  },
  {
      id: "NRA",
      name: "NARRANDERA AIRPORT",
      area_code: "NRA",
      city_name: "Narrandera",
      country_code: "AU"
  },
  {
      id: "NRC",
      name: "Crows Landing",
      area_code: "NRC",
      city_name: "Crows Landing",
      country_code: "US"
  },
  {
      id: "NRK",
      name: "Kungsangen Airport",
      area_code: "NRK",
      city_name: "Norrkoping",
      country_code: "SE"
  },
  {
      id: "NRL",
      name: "NORTH RONALDSAY AIRPORT",
      area_code: "NRL",
      city_name: "North Ronaldsay",
      country_code: "GB"
  },
  {
      id: "NRS",
      name: "NOLF (Ream Field)",
      area_code: "NRS",
      city_name: "Imperial Beach",
      country_code: "US"
  },
  {
      id: "NSE",
      name: "Whiting Fid NAS North",
      area_code: "NSE",
      city_name: "Milton",
      country_code: "US"
  },
  {
      id: "NSK",
      name: "Alykel Airport",
      area_code: "NSK",
      city_name: "Norilsk",
      country_code: "RU"
  },
  {
      id: "NSM",
      name: "Norseman",
      area_code: "NSM",
      city_name: "Norseman",
      country_code: "AU"
  },
  {
      id: "NSO",
      name: "Scone",
      area_code: "NSO",
      city_name: "Scone",
      country_code: "AU"
  },
  {
      id: "NST",
      name: "NAKHON SI THAMMARAT AIRPORT",
      area_code: "NST",
      city_name: "Nakhon Si Thammarat",
      country_code: "TH"
  },
  {
      id: "NTB",
      name: "Notodden Airport",
      area_code: "NTB",
      city_name: "Notodden",
      country_code: "NO"
  },
  {
      id: "NTD",
      name: "NAS",
      area_code: "NTD",
      city_name: "Port Mugu",
      country_code: "US"
  },
  {
      id: "NTN",
      name: "NORMANTON AIRPORT",
      area_code: "NTN",
      city_name: "Normanton",
      country_code: "AU"
  },
  {
      id: "NTO",
      name: "Agostinho Neto",
      area_code: "NTO",
      city_name: "Santo Antao Island",
      country_code: "CV"
  },
  {
      id: "NTQ",
      name: "NOTO AIRPORT",
      area_code: "NTQ",
      city_name: "Wajima",
      country_code: "JP"
  },
  {
      id: "NTY",
      name: "Pilanesberg International Airport",
      area_code: "NTY",
      city_name: "Sun City",
      country_code: "ZA"
  },
  {
      id: "NUA",
      name: "Gregory Lake SPB",
      area_code: "NUA",
      city_name: "Nuwara Eliya",
      country_code: "LK"
  },
  {
      id: "NUJ",
      name: "Nogeh",
      area_code: "NUJ",
      city_name: "Nogeh",
      country_code: "IR"
  },
  {
      id: "NUL",
      name: "NULATO AIRPORT",
      area_code: "NUL",
      city_name: "Nulato",
      country_code: "US"
  },
  {
      id: "NUQ",
      name: "Moffett Federal Airfld",
      area_code: "NUQ",
      city_name: "Mountain View",
      country_code: "US"
  },
  {
      id: "NUR",
      name: "Nullarbor Motel",
      area_code: "NUR",
      city_name: "Nullarbor",
      country_code: "AU"
  },
  {
      id: "NUS",
      name: "NORSUP AIRPORT",
      area_code: "NUS",
      city_name: "Norsup",
      country_code: "VU"
  },
  {
      id: "NUU",
      name: "Nakuru",
      area_code: "NUU",
      city_name: "Nakuru",
      country_code: "KE"
  },
  {
      id: "NUX",
      name: "Novy Urengoy Airport",
      area_code: "NUX",
      city_name: "Novy Urengoy",
      country_code: "RU"
  },
  {
      id: "NVI",
      name: "Navoi International Airport",
      area_code: "NVI",
      city_name: "Navoi",
      country_code: "UZ"
  },
  {
      id: "NVS",
      name: "Fourchambault",
      area_code: "NVS",
      city_name: "Nevers",
      country_code: "FR"
  },
  {
      id: "NVT",
      name: "Navegantes Airport",
      area_code: "NVT",
      city_name: "Navegantes",
      country_code: "BR"
  },
  {
      id: "NYA",
      name: "Nyagan Airport",
      area_code: "NYA",
      city_name: "Nyagan",
      country_code: "RU"
  },
  {
      id: "JRB",
      name: "DOWNTOWN MANHATT",
      area_code: "NYC",
      city_name: "New York",
      country_code: "US"
  },
  {
      id: "NYE",
      name: "Nyeri",
      area_code: "NYE",
      city_name: "Nyeri",
      country_code: "KE"
  },
  {
      id: "NYK",
      name: "NANYUKI AIRPORT",
      area_code: "NYK",
      city_name: "Nanyuki",
      country_code: "KE"
  },
  {
      id: "NYR",
      name: "Nyurba Airport",
      area_code: "NYR",
      city_name: "Nyurba",
      country_code: "RU"
  },
  {
      id: "NYT",
      name: "Nay Pyi Taw International Airport",
      area_code: "NYT",
      city_name: "Nay Pyi Taw",
      country_code: "MM"
  },
  {
      id: "NYU",
      name: "Nyaung-u Airport",
      area_code: "NYU",
      city_name: "Bagan/Nyaung-u",
      country_code: "MM"
  },
  {
      id: "OAL",
      name: "Cacoal Airport",
      area_code: "OAL",
      city_name: "Cacoal",
      country_code: "BR"
  },
  {
      id: "OAM",
      name: "Oamaru",
      area_code: "OAM",
      city_name: "Oamaru",
      country_code: "NZ"
  },
  {
      id: "OBE",
      name: "Okeechobee County",
      area_code: "OBE",
      city_name: "Okeechobee",
      country_code: "US"
  },
  {
      id: "OBN",
      name: "North Connel",
      area_code: "OBN",
      city_name: "Oban",
      country_code: "GB"
  },
  {
      id: "OBS",
      name: "Ardeche Meridionale",
      area_code: "OBS",
      city_name: "Aubenas",
      country_code: "FR"
  },
  {
      id: "OBU",
      name: "Kobuk Airport",
      area_code: "OBU",
      city_name: "Kobuk",
      country_code: "US"
  },
  {
      id: "OCA",
      name: "Ocean Reef Club",
      area_code: "OCA",
      city_name: "Key Largo",
      country_code: "US"
  },
  {
      id: "OCC",
      name: "Coca Airport",
      area_code: "OCC",
      city_name: "Coca",
      country_code: "EC"
  },
  {
      id: "OCE",
      name: "Ocean City Municipal",
      area_code: "SBY",
      city_name: "Salisbury/Ocean City",
      country_code: "US"
  },
  {
      id: "OCF",
      name: "Intl (Jim Taylor Fld)",
      area_code: "OCF",
      city_name: "Ocala",
      country_code: "US"
  },
  {
      id: "OCH",
      name: "A.L. Mangham Jr. Rgnl",
      area_code: "OCH",
      city_name: "Nacogdoches",
      country_code: "US"
  },
  {
      id: "OCJ",
      name: "Ian Fleming Intl",
      area_code: "OCJ",
      city_name: "Ocho Rios",
      country_code: "JM"
  },
  {
      id: "OCN",
      name: "Municipal",
      area_code: "OCN",
      city_name: "Oceanside",
      country_code: "US"
  },
  {
      id: "OCV",
      name: "Aguas Claras",
      area_code: "OCV",
      city_name: "Ocana",
      country_code: "CO"
  },
  {
      id: "ODB",
      name: "Airport",
      area_code: "ODB",
      city_name: "Cordoba",
      country_code: "ES"
  },
  {
      id: "ODN",
      name: "LONG SERIDAN AIRPORT",
      area_code: "ODN",
      city_name: "Long Seridan",
      country_code: "MY"
  },
  {
      id: "ODO",
      name: "Bodaybo Airport",
      area_code: "ODO",
      city_name: "Bodaybo",
      country_code: "RU"
  },
  {
      id: "ODW",
      name: "A.J. Eisenberg",
      area_code: "ODW",
      city_name: "Oak Harbor",
      country_code: "US"
  },
  {
      id: "OER",
      name: "Ornskoldsvik Airport",
      area_code: "OER",
      city_name: "Ornskoldsvik",
      country_code: "SE"
  },
  {
      id: "OGA",
      name: "Searle Field",
      area_code: "OGA",
      city_name: "Ogallala",
      country_code: "US"
  },
  {
      id: "OGB",
      name: "Municipal",
      area_code: "OGB",
      city_name: "Orangeburg",
      country_code: "US"
  },
  {
      id: "HIF",
      name: "Hill AFB",
      area_code: "OGD",
      city_name: "Ogden",
      country_code: "US"
  },
  {
      id: "OGD",
      name: "Hinckley Airport",
      area_code: "OGD",
      city_name: "Ogden",
      country_code: "US"
  },
  {
      id: "OGG",
      name: "Kahului Airport",
      area_code: "OGG",
      city_name: "Kahului",
      country_code: "US"
  },
  {
      id: "OGM",
      name: "Ogobsucum Airport",
      area_code: "OGM",
      city_name: "Ustupo Island",
      country_code: "PA"
  },
  {
      id: "OGN",
      name: "Yonaguni",
      area_code: "OGN",
      city_name: "Yonaguni",
      country_code: "JP"
  },
  {
      id: "OGZ",
      name: "Beslan Airport",
      area_code: "OGZ",
      city_name: "Vladikavkaz",
      country_code: "RU"
  },
  {
      id: "OHA",
      name: "RNZAF Base",
      area_code: "OHA",
      city_name: "Ohakea",
      country_code: "NZ"
  },
  {
      id: "OHD",
      name: "Ohrid Airport",
      area_code: "OHD",
      city_name: "Ohrid",
      country_code: "MK"
  },
  {
      id: "OHH",
      name: "Okha Airport",
      area_code: "OHH",
      city_name: "Okha",
      country_code: "RU"
  },
  {
      id: "OIA",
      name: "Ourilandia do Norte",
      area_code: "OIA",
      city_name: "Ourilandia do Norte",
      country_code: "BR"
  },
  {
      id: "OIM",
      name: "Oshima",
      area_code: "OIM",
      city_name: "Oshima",
      country_code: "JP"
  },
  {
      id: "DNA",
      name: "Kadena AB",
      area_code: "OKA",
      city_name: "Okinawa",
      country_code: "JP"
  },
  {
      id: "PWA",
      name: "Wiley Post",
      area_code: "OKC",
      city_name: "Oklahoma City",
      country_code: "US"
  },
  {
      id: "TIK",
      name: "Tinker AFB",
      area_code: "OKC",
      city_name: "Oklahoma City",
      country_code: "US"
  },
  {
      id: "OKE",
      name: "Okinoerabu Airport",
      area_code: "OKE",
      city_name: "Okierabu",
      country_code: "JP"
  },
  {
      id: "OKK",
      name: "Municipal",
      area_code: "OKK",
      city_name: "Kokomo",
      country_code: "US"
  },
  {
      id: "OLB",
      name: "Costa Smeralda Airport",
      area_code: "OLB",
      city_name: "Olbia",
      country_code: "IT"
  },
  {
      id: "OLK",
      name: "Fuerte Olimpo",
      area_code: "OLK",
      city_name: "Fuerte Olimpo",
      country_code: "PY"
  },
  {
      id: "OLM",
      name: "Regional",
      area_code: "OLM",
      city_name: "Olympia",
      country_code: "US"
  },
  {
      id: "OLP",
      name: "OLYMPIC DAM AIRPORT",
      area_code: "OLP",
      city_name: "Olympic Dam",
      country_code: "AU"
  },
  {
      id: "OLS",
      name: "International",
      area_code: "OLS",
      city_name: "Nogales",
      country_code: "US"
  },
  {
      id: "OLU",
      name: "Municipal",
      area_code: "OLU",
      city_name: "Columbus",
      country_code: "US"
  },
  {
      id: "MIQ",
      name: "Millard",
      area_code: "OMA",
      city_name: "Omaha",
      country_code: "US"
  },
  {
      id: "OMD",
      name: "Oranjemund Airport",
      area_code: "OMD",
      city_name: "Oranjemund",
      country_code: "NA"
  },
  {
      id: "OMO",
      name: "Mostar Airport",
      area_code: "OMO",
      city_name: "Mostar",
      country_code: "BA"
  },
  {
      id: "OMR",
      name: "ORADEA AIRPORT",
      area_code: "OMR",
      city_name: "Oradea",
      country_code: "RO"
  },
  {
      id: "OND",
      name: "Ondangwa Airport",
      area_code: "OND",
      city_name: "Ondangwa",
      country_code: "NA"
  },
  {
      id: "ONG",
      name: "Mornington Airport",
      area_code: "ONG",
      city_name: "Mornington Island",
      country_code: "AU"
  },
  {
      id: "ONH",
      name: "Municipal",
      area_code: "ONH",
      city_name: "Oneonta",
      country_code: "US"
  },
  {
      id: "ONJ",
      name: "Odate Noshiro Airport",
      area_code: "ONJ",
      city_name: "Odate/Noshiro",
      country_code: "JP"
  },
  {
      id: "ONK",
      name: "Olenyok Airport",
      area_code: "ONK",
      city_name: "Olenek",
      country_code: "RU"
  },
  {
      id: "ONM",
      name: "Municipal",
      area_code: "ONM",
      city_name: "Socorro",
      country_code: "US"
  },
  {
      id: "ONO",
      name: "Municipal",
      area_code: "ONO",
      city_name: "Ontario",
      country_code: "US"
  },
  {
      id: "ONP",
      name: "Municipal",
      area_code: "ONP",
      city_name: "Newport",
      country_code: "US"
  },
  {
      id: "ONQ",
      name: "Zonguldak Airport",
      area_code: "ONQ",
      city_name: "Zonguldak",
      country_code: "TR"
  },
  {
      id: "ONS",
      name: "ONSLOW AIRPORT",
      area_code: "ONS",
      city_name: "Onslow",
      country_code: "AU"
  },
  {
      id: "OOM",
      name: "Snowy Mountains Airport",
      area_code: "OOM",
      city_name: "Cooma",
      country_code: "AU"
  },
  {
      id: "OPA",
      name: "Kopasker",
      area_code: "OPA",
      city_name: "Kopasker",
      country_code: "IS"
  },
  {
      id: "OPS",
      name: "Sinop Airport",
      area_code: "OPS",
      city_name: "Sinop",
      country_code: "BR"
  },
  {
      id: "OPU",
      name: "Balimo",
      area_code: "OPU",
      city_name: "Balimo",
      country_code: "PG"
  },
  {
      id: "ORE",
      name: "St Denis de l'Hotel",
      area_code: "ORE",
      city_name: "Orleans",
      country_code: "FR"
  },
  {
      id: "NGU",
      name: "NS (Chambers Field)",
      area_code: "ORF",
      city_name: "Norfolk",
      country_code: "US"
  },
  {
      id: "ORK",
      name: "Cork Airport",
      area_code: "ORK",
      city_name: "Cork",
      country_code: "IE"
  },
  {
      id: "DWS",
      name: "Walt Disney World",
      area_code: "ORL",
      city_name: "Orlando",
      country_code: "US"
  },
  {
      id: "ORM",
      name: "Sywell",
      area_code: "ORM",
      city_name: "Northampton",
      country_code: "GB"
  },
  {
      id: "TAF",
      name: "Tafaraoui",
      area_code: "ORN",
      city_name: "Oran",
      country_code: "DZ"
  },
  {
      id: "ORS",
      name: "Waterport SPB",
      area_code: "ORS",
      city_name: "Orpheus Island",
      country_code: "AU"
  },
  {
      id: "ORT",
      name: "Northway",
      area_code: "ORT",
      city_name: "Northway",
      country_code: "US"
  },
  {
      id: "ORU",
      name: "Juan Mendoza Airport",
      area_code: "ORU",
      city_name: "Oruro",
      country_code: "BO"
  },
  {
      id: "ORV",
      name: "Curtis Memorial Airport",
      area_code: "ORV",
      city_name: "Noorvik",
      country_code: "US"
  },
  {
      id: "RYG",
      name: "Moss-Rygge",
      area_code: "OSL",
      city_name: "Oslo",
      country_code: "NO"
  },
  {
      id: "TRF",
      name: "Sandefjord Airport",
      area_code: "OSL",
      city_name: "Oslo",
      country_code: "NO"
  },
  {
      id: "OST",
      name: "Oostende Brugge International Airport",
      area_code: "OST",
      city_name: "Oostende/Brugge",
      country_code: "BE"
  },
  {
      id: "OTD",
      name: "Contadora Airport",
      area_code: "OTD",
      city_name: "Contadora Island",
      country_code: "PA"
  },
  {
      id: "OTG",
      name: "Municipal",
      area_code: "OTG",
      city_name: "Worthington",
      country_code: "US"
  },
  {
      id: "OTM",
      name: "Regional",
      area_code: "OTM",
      city_name: "Ottumwa",
      country_code: "US"
  },
  {
      id: "OTS",
      name: "Anacortes",
      area_code: "OTS",
      city_name: "Anacortes",
      country_code: "US"
  },
  {
      id: "OTU",
      name: "Otu",
      area_code: "OTU",
      city_name: "Remedios",
      country_code: "CO"
  },
  {
      id: "OUH",
      name: "Oudtshoorn",
      area_code: "OUH",
      city_name: "Oudtshoorn",
      country_code: "ZA"
  },
  {
      id: "OUZ",
      name: "Tazadit Airport",
      area_code: "OUZ",
      city_name: "Zouerate",
      country_code: "MR"
  },
  {
      id: "OVE",
      name: "Municipal",
      area_code: "OVE",
      city_name: "Oroville",
      country_code: "US"
  },
  {
      id: "OVS",
      name: "Sovetsky Airport",
      area_code: "OVS",
      city_name: "Sovetsky",
      country_code: "RU"
  },
  {
      id: "OWA",
      name: "Degner Regional",
      area_code: "OWA",
      city_name: "Owatonna",
      country_code: "US"
  },
  {
      id: "OWD",
      name: "Memorial",
      area_code: "OWD",
      city_name: "Norwood",
      country_code: "US"
  },
  {
      id: "BZZ",
      name: "RAF Station",
      area_code: "BZZ",
      city_name: "Brize Norton",
      country_code: "GB"
  },
  {
      id: "OXF",
      name: "London Oxford",
      area_code: "OXF",
      city_name: "Oxford",
      country_code: "GB"
  },
  {
      id: "OXR",
      name: "Oxnard Apt",
      area_code: "OXR",
      city_name: "Oxnard/Ventura",
      country_code: "US"
  },
  {
      id: "OYO",
      name: "Tres Arroyos",
      area_code: "OYO",
      city_name: "Tres Arroyos",
      country_code: "AR"
  },
  {
      id: "OZC",
      name: "LABO AIRPORT",
      area_code: "OZC",
      city_name: "Ozamiz",
      country_code: "PH"
  },
  {
      id: "OZG",
      name: "Zagora Airport",
      area_code: "OZG",
      city_name: "Zagora",
      country_code: "MA"
  },
  {
      id: "OZH",
      name: "Zaporozhye Airport",
      area_code: "OZH",
      city_name: "Zaporizhzhia",
      country_code: "UA"
  },
  {
      id: "OZR",
      name: "Cairns AAF",
      area_code: "OZR",
      city_name: "Fort Rucker/Ozark",
      country_code: "US"
  },
  {
      id: "OZZ",
      name: "OUARZAZATE AIRPORT",
      area_code: "OZZ",
      city_name: "Ouarzazate",
      country_code: "MA"
  },
  {
      id: "PAB",
      name: "Bilaspur",
      area_code: "PAB",
      city_name: "Bilaspur",
      country_code: "IN"
  },
  {
      id: "PAD",
      name: "Paderborn Airport",
      area_code: "PAD",
      city_name: "Paderborn/Lippstadt",
      country_code: "DE"
  },
  {
      id: "PAE",
      name: "Snohomish County",
      area_code: "PAE",
      city_name: "Everett",
      country_code: "US"
  },
  {
      id: "PAO",
      name: "Santa Clara County",
      area_code: "PAO",
      city_name: "Palo Alto",
      country_code: "US"
  },
  {
      id: "PAQ",
      name: "Municipal",
      area_code: "PAQ",
      city_name: "Palmer",
      country_code: "US"
  },
  {
      id: "VIY",
      name: "Villacoublay-Velizy",
      area_code: "PAR",
      city_name: "Paris",
      country_code: "FR"
  },
  {
      id: "XCR",
      name: "Chalons Vatry Airport",
      area_code: "PAR",
      city_name: "Paris",
      country_code: "FR"
  },
  {
      id: "XEX",
      name: "AEROGARE DES INCALIDES",
      area_code: "PAR",
      city_name: "Paris",
      country_code: "FR"
  },
  {
      id: "PAS",
      name: "PAROS AIRPORT",
      area_code: "PAS",
      city_name: "Paros",
      country_code: "GR"
  },
  {
      id: "PAU",
      name: "Pauk",
      area_code: "PAU",
      city_name: "Pauk",
      country_code: "MM"
  },
  {
      id: "PAZ",
      name: "El Tajin Airport",
      area_code: "PAZ",
      city_name: "Poza Rica",
      country_code: "MX"
  },
  {
      id: "PBD",
      name: "PORBANDAR AIRPORT",
      area_code: "PBD",
      city_name: "Porbandar",
      country_code: "IN"
  },
  {
      id: "PBE",
      name: "Puerto Berrio",
      area_code: "PBE",
      city_name: "Puerto Berrio",
      country_code: "CO"
  },
  {
      id: "PBF",
      name: "Grider Field",
      area_code: "PBF",
      city_name: "Pine Bluff",
      country_code: "US"
  },
  {
      id: "PLB",
      name: "Plattsburgh",
      area_code: "PBG",
      city_name: "Plattsburgh",
      country_code: "US"
  },
  {
      id: "PBL",
      name: "Bartolome Salom Intl",
      area_code: "PBL",
      city_name: "Puerto Cabello",
      country_code: "VE"
  },
  {
      id: "ORG",
      name: "Zorg en Hoop",
      area_code: "PBM",
      city_name: "Paramaribo",
      country_code: "SR"
  },
  {
      id: "PBO",
      name: "PARABURDOO AIRPORT",
      area_code: "PBO",
      city_name: "Paraburdoo",
      country_code: "AU"
  },
  {
      id: "PBR",
      name: "Puerto Barrios",
      area_code: "PBR",
      city_name: "Puerto Barrios",
      country_code: "GT"
  },
  {
      id: "PBZ",
      name: "Plettenberg Bay Airport",
      area_code: "PBZ",
      city_name: "Plettenberg Bay",
      country_code: "ZA"
  },
  {
      id: "PCD",
      name: "Municipal",
      area_code: "PCD",
      city_name: "Prairie du Chien",
      country_code: "US"
  },
  {
      id: "PCJ",
      name: "Puerto La Victoria",
      area_code: "PCJ",
      city_name: "Puerto La Victoria",
      country_code: "PY"
  },
  {
      id: "PCL",
      name: "Captain Rolden Airport",
      area_code: "PCL",
      city_name: "Pucallpa",
      country_code: "PE"
  },
  {
      id: "PCM",
      name: "Playa del Carmen",
      area_code: "PCM",
      city_name: "Playa del Carmen",
      country_code: "MX"
  },
  {
      id: "PCN",
      name: "Picton Airport",
      area_code: "PCN",
      city_name: "Picton",
      country_code: "NZ"
  },
  {
      id: "PCR",
      name: "Puerto Carreno Airport",
      area_code: "PCR",
      city_name: "Puerto Carreno",
      country_code: "CO"
  },
  {
      id: "PCT",
      name: "Princeton",
      area_code: "PCT",
      city_name: "Princeton/Rocky Hill",
      country_code: "US"
  },
  {
      id: "PCW",
      name: "Carl R. Keller Field Airport/Erie–Ottawa International Airport",
      area_code: "PCW",
      city_name: "Port Clinton",
      country_code: "GB"
  },
  {
      id: "PDA",
      name: "Puerto Inirida Airport",
      area_code: "PDA",
      city_name: "Puerto Inirida",
      country_code: "CO"
  },
  {
      id: "PDL",
      name: "Joao Paulo II Airport",
      area_code: "PDL",
      city_name: "Ponta Delgada",
      country_code: "PT"
  },
  {
      id: "PDP",
      name: "Punta Del Este Airport",
      area_code: "PDP",
      city_name: "Punta del Este",
      country_code: "UY"
  },
  {
      id: "PDS",
      name: "Piedras Negras International Airport",
      area_code: "PDS",
      city_name: "Piedras Negras",
      country_code: "MX"
  },
  {
      id: "PDU",
      name: "Tydeo Larre Borges",
      area_code: "PDU",
      city_name: "Paysandu",
      country_code: "UY"
  },
  {
      id: "PDV",
      name: "Plovdiv Airport",
      area_code: "PDV",
      city_name: "Plovdiv",
      country_code: "BG"
  },
  {
      id: "PED",
      name: "Pardubice Airport",
      area_code: "PED",
      city_name: "Pardubice",
      country_code: "CZ"
  },
  {
      id: "PEE",
      name: "Perm Airport",
      area_code: "PEE",
      city_name: "Perm",
      country_code: "RU"
  },
  {
      id: "PEF",
      name: "Peenemuende",
      area_code: "PEF",
      city_name: "Peenemuende",
      country_code: "DE"
  },
  {
      id: "PEG",
      name: "Perugia Airport",
      area_code: "PEG",
      city_name: "Perugia",
      country_code: "IT"
  },
  {
      id: "PEI",
      name: "Matecana Airport",
      area_code: "PEI",
      city_name: "Pereira",
      country_code: "CO"
  },
  {
      id: "PEM",
      name: "Puerto Maldonado Airport",
      area_code: "PEM",
      city_name: "Puerto Maldonado",
      country_code: "PE"
  },
  {
      id: "PEQ",
      name: "Municipal",
      area_code: "PEQ",
      city_name: "Pecos",
      country_code: "US"
  },
  {
      id: "PES",
      name: "Petrozavodsk Airport",
      area_code: "PES",
      city_name: "Petrozavodsk",
      country_code: "RU"
  },
  {
      id: "PET",
      name: "Pelotas International Airport",
      area_code: "PET",
      city_name: "Pelotas",
      country_code: "BR"
  },
  {
      id: "PEV",
      name: "Pogany",
      area_code: "PEV",
      city_name: "Lund C",
      country_code: "SE"
  },
  {
      id: "PEZ",
      name: "Penza Airport",
      area_code: "PEZ",
      city_name: "Penza",
      country_code: "RU"
  },
  {
      id: "PFB",
      name: "Lauro Kurtz Airport",
      area_code: "PFB",
      city_name: "Passo Fundo",
      country_code: "BR"
  },
  {
      id: "ECP",
      name: "Northwest Florida Beaches International Airport",
      area_code: "ECP",
      city_name: "Panama City",
      country_code: "US"
  },
  {
      id: "PAM",
      name: "Tyndall AFB",
      area_code: "ECP",
      city_name: "Panama City",
      country_code: "US"
  },
  {
      id: "PGA",
      name: "Page Municipal Airport",
      area_code: "PGA",
      city_name: "Page",
      country_code: "US"
  },
  {
      id: "PGD",
      name: "Punta Gorda Airport",
      area_code: "PGD",
      city_name: "Punta Gorda",
      country_code: "US"
  },
  {
      id: "PGK",
      name: "Pangkalpinang Airport",
      area_code: "PGK",
      city_name: "Pangkalpinang",
      country_code: "ID"
  },
  {
      id: "PGL",
      name: "Trent Lott Intl",
      area_code: "PGL",
      city_name: "Pascagoula",
      country_code: "US"
  },
  {
      id: "PGM",
      name: "PORT GRAHAM AIRPORT",
      area_code: "PGM",
      city_name: "Port Graham",
      country_code: "US"
  },
  {
      id: "PGO",
      name: "Stevens Field",
      area_code: "PGO",
      city_name: "Pagosa Springs",
      country_code: "US"
  },
  {
      id: "PGR",
      name: "Kirk Field",
      area_code: "PGR",
      city_name: "Paragould",
      country_code: "US"
  },
  {
      id: "PGX",
      name: "Perigueux Airport",
      area_code: "PGX",
      city_name: "Perigueux",
      country_code: "FR"
  },
  {
      id: "PHB",
      name: "Joao Silva Filho International Airport",
      area_code: "PHB",
      city_name: "Parnaiba",
      country_code: "BR"
  },
  {
      id: "PHG",
      name: "City Airport",
      area_code: "PHC",
      city_name: "Port Harcourt",
      country_code: "NG"
  },
  {
      id: "PHK",
      name: "Palm Beach Co Glades",
      area_code: "PHK",
      city_name: "Pahokee",
      country_code: "US"
  },
  {
      id: "PNE",
      name: "Northeast Philadelphia",
      area_code: "PHL",
      city_name: "Philadelphia",
      country_code: "US"
  },
  {
      id: "PSQ",
      name: "Essington SPB",
      area_code: "PHL",
      city_name: "Philadelphia",
      country_code: "US"
  },
  {
      id: "PHN",
      name: "St Clair County Intl",
      area_code: "PHN",
      city_name: "Port Huron",
      country_code: "US"
  },
  {
      id: "PHS",
      name: "PHITSANULOK AIRPORT",
      area_code: "PHS",
      city_name: "Phitsanulok",
      country_code: "TH"
  },
  {
      id: "PHT",
      name: "Henry County",
      area_code: "PHT",
      city_name: "Paris",
      country_code: "US"
  },
  {
      id: "PHW",
      name: "Hendrik Van Eck Airport",
      area_code: "PHW",
      city_name: "Phalaborwa",
      country_code: "ZA"
  },
  {
      id: "AZA",
      name: "Mesa Gateway Airport",
      area_code: "PHX",
      city_name: "Phoenix",
      country_code: "US"
  },
  {
      id: "DVT",
      name: "Deer Valley",
      area_code: "PHX",
      city_name: "Phoenix",
      country_code: "US"
  },
  {
      id: "LUF",
      name: "Luke AFB",
      area_code: "PHX",
      city_name: "Phoenix",
      country_code: "US"
  },
  {
      id: "PIN",
      name: "Julio Belem",
      area_code: "PIN",
      city_name: "Parintins",
      country_code: "BR"
  },
  {
      id: "PIO",
      name: "Pisco",
      area_code: "PIO",
      city_name: "Pisco",
      country_code: "PE"
  },
  {
      id: "PIP",
      name: "Pilot Point Airport",
      area_code: "PIP",
      city_name: "Pilot Point",
      country_code: "US"
  },
  {
      id: "UGB",
      name: "Ugashik Bay Airport",
      area_code: "PIP",
      city_name: "Pilot Point",
      country_code: "US"
  },
  {
      id: "AGC",
      name: "Allegheny County",
      area_code: "PIT",
      city_name: "Pittsburgh",
      country_code: "US"
  },
  {
      id: "PIU",
      name: "Piura Airport",
      area_code: "PIU",
      city_name: "Piura",
      country_code: "PE"
  },
  {
      id: "PIW",
      name: "Pikwitonei",
      area_code: "PIW",
      city_name: "Pikwitonei",
      country_code: "CA"
  },
  {
      id: "PIX",
      name: "Pico Island Airport",
      area_code: "PIX",
      city_name: "Pico Island",
      country_code: "PT"
  },
  {
      id: "PIZ",
      name: "Point Lay Airport",
      area_code: "PIZ",
      city_name: "Point Lay",
      country_code: "US"
  },
  {
      id: "PJA",
      name: "Pajala Airport",
      area_code: "PJA",
      city_name: "Pajala",
      country_code: "SE"
  },
  {
      id: "PJB",
      name: "Payson",
      area_code: "PJB",
      city_name: "Payson",
      country_code: "US"
  },
  {
      id: "PJM",
      name: "PUERTO JIMENEZ AIRPORT",
      area_code: "PJM",
      city_name: "Puerto Jimenez",
      country_code: "CR"
  },
  {
      id: "PKA",
      name: "Napaskiak Airport",
      area_code: "PKA",
      city_name: "Napaskiak",
      country_code: "US"
  },
  {
      id: "PKB",
      name: "Parkersburg Airport",
      area_code: "PKB",
      city_name: "Parkersburg",
      country_code: "US"
  },
  {
      id: "PKC",
      name: "Yelizovo Airport",
      area_code: "PKC",
      city_name: "Petropavlovsk",
      country_code: "RU"
  },
  {
      id: "PKD",
      name: "Municipal-Konshok Fld",
      area_code: "PKD",
      city_name: "Park Rapids",
      country_code: "US"
  },
  {
      id: "PKE",
      name: "PARKES AIRPORT",
      area_code: "PKE",
      city_name: "Parkes",
      country_code: "AU"
  },
  {
      id: "PKG",
      name: "Pangkor",
      area_code: "PKG",
      city_name: "Pangkor",
      country_code: "MY"
  },
  {
      id: "PKH",
      name: "Kanaris",
      area_code: "PKH",
      city_name: "Portochelion",
      country_code: "GR"
  },
  {
      id: "PKJ",
      name: "Playa Grande",
      area_code: "PKJ",
      city_name: "Playa Grande",
      country_code: "GT"
  },
  {
      id: "PKN",
      name: "Iskandar Airport",
      area_code: "PKN",
      city_name: "Pangkalanbuun",
      country_code: "ID"
  },
  {
      id: "PKR",
      name: "POKHARA AIRPORT",
      area_code: "PKR",
      city_name: "Pokhara",
      country_code: "NP"
  },
  {
      id: "PKU",
      name: "Sultan Syarif Kasim II Airport",
      area_code: "PKU",
      city_name: "Pekanbaru",
      country_code: "ID"
  },
  {
      id: "PKV",
      name: "Pskov Airport",
      area_code: "PKV",
      city_name: "Pskov",
      country_code: "RU"
  },
  {
      id: "PKW",
      name: "Selebi-Phikwe",
      area_code: "PKW",
      city_name: "Selebi-Phikwe",
      country_code: "BW"
  },
  {
      id: "PKY",
      name: "Tjilik Riwut Airport",
      area_code: "PKY",
      city_name: "Palangkaraya",
      country_code: "ID"
  },
  {
      id: "PKZ",
      name: "Pakse International Airport",
      area_code: "PKZ",
      city_name: "Pakse",
      country_code: "LA"
  },
  {
      id: "PLD",
      name: "Playa Samara/Carrillo",
      area_code: "PLD",
      city_name: "Playa Samara/Carrillo",
      country_code: "CR"
  },
  {
      id: "PLJ",
      name: "PLACENCIA AIRPORT",
      area_code: "PLJ",
      city_name: "Placencia",
      country_code: "BZ"
  },
  {
      id: "PLK",
      name: "M. Graham Clark Downtn",
      area_code: "BKG",
      city_name: "Branson",
      country_code: "US"
  },
  {
      id: "PLQ",
      name: "PALANGA INTERNATIONAL AIRPORT",
      area_code: "PLQ",
      city_name: "Klaipeda/Palanga",
      country_code: "LT"
  },
  {
      id: "PLR",
      name: "St Clair County",
      area_code: "PLR",
      city_name: "Pell City",
      country_code: "US"
  },
  {
      id: "PLS",
      name: "Providenciales International Airport",
      area_code: "PLS",
      city_name: "Providenciales",
      country_code: "TC"
  },
  {
      id: "PLV",
      name: "Poltava",
      area_code: "PLV",
      city_name: "Poltava",
      country_code: "UA"
  },
  {
      id: "PLW",
      name: "Mutiara Airport",
      area_code: "PLW",
      city_name: "Palu",
      country_code: "ID"
  },
  {
      id: "PLX",
      name: "Semey Airport",
      area_code: "PLX",
      city_name: "Semey",
      country_code: "KZ"
  },
  {
      id: "PLY",
      name: "Municipal",
      area_code: "PLY",
      city_name: "Plymouth",
      country_code: "US"
  },
  {
      id: "PMD",
      name: "LA/Palmdale Regional",
      area_code: "PMD",
      city_name: "Palmdale",
      country_code: "US"
  },
  {
      id: "PMG",
      name: "International",
      area_code: "PMG",
      city_name: "Ponta Pora",
      country_code: "BR"
  },
  {
      id: "PMK",
      name: "Palm Island Airport",
      area_code: "PMK",
      city_name: "Palm Island",
      country_code: "AU"
  },
  {
      id: "PMR",
      name: "Palmerston North Airport",
      area_code: "PMR",
      city_name: "Palmerston North",
      country_code: "NZ"
  },
  {
      id: "PMV",
      name: "Delcaribe Gen S Marino Airport",
      area_code: "PMV",
      city_name: "Porlamar",
      country_code: "VE"
  },
  {
      id: "PMY",
      name: "EL TEHUELCHE AIRPORT",
      area_code: "PMY",
      city_name: "Puerto Madryn",
      country_code: "AR"
  },
  {
      id: "PMZ",
      name: "Palmar Sur",
      area_code: "PMZ",
      city_name: "Palmar Sur",
      country_code: "CR"
  },
  {
      id: "PNC",
      name: "Regional",
      area_code: "PNC",
      city_name: "Ponca City",
      country_code: "US"
  },
  {
      id: "PNG",
      name: "Santos Dumont",
      area_code: "PNG",
      city_name: "Paranagua",
      country_code: "BR"
  },
  {
      id: "PNI",
      name: "Pohnpei International Airport",
      area_code: "PNI",
      city_name: "Pohnpei",
      country_code: "FM"
  },
  {
      id: "PNJ",
      name: "Shahekou",
      area_code: "PNJ",
      city_name: "Penglai",
      country_code: "CN"
  },
  {
      id: "PNL",
      name: "Pantelleria Airport",
      area_code: "PNL",
      city_name: "Pantelleria",
      country_code: "IT"
  },
  {
      id: "PNP",
      name: "GIRUA AIRPORT",
      area_code: "PNP",
      city_name: "Popondetta",
      country_code: "PG"
  },
  {
      id: "NPA",
      name: "NAS (F.Sherman Field)",
      area_code: "PNS",
      city_name: "Pensacola",
      country_code: "US"
  },
  {
      id: "NUN",
      name: "Saufley Field NOLF",
      area_code: "PNS",
      city_name: "Pensacola",
      country_code: "US"
  },
  {
      id: "PNT",
      name: "Teniente Gallardo Airport",
      area_code: "PNT",
      city_name: "Puerto Natales",
      country_code: "CL"
  },
  {
      id: "PNX",
      name: "North Texas Regional",
      area_code: "PNX",
      city_name: "Sherman/Denison",
      country_code: "US"
  },
  {
      id: "PNY",
      name: "Puducherry Airport",
      area_code: "PNY",
      city_name: "Pondicherry",
      country_code: "IN"
  },
  {
      id: "PNZ",
      name: "Petrolina International Airport",
      area_code: "PNZ",
      city_name: "Petrolina",
      country_code: "BR"
  },
  {
      id: "POC",
      name: "Brackett Field",
      area_code: "POC",
      city_name: "La Verne",
      country_code: "US"
  },
  {
      id: "POE",
      name: "Polk AAF",
      area_code: "POE",
      city_name: "Fort Polk",
      country_code: "US"
  },
  {
      id: "POF",
      name: "Municipal",
      area_code: "POF",
      city_name: "Poplar Bluff",
      country_code: "US"
  },
  {
      id: "POI",
      name: "Potosi Airport",
      area_code: "POI",
      city_name: "Potosi",
      country_code: "BO"
  },
  {
      id: "POJ",
      name: "P.Pereira dos Santos",
      area_code: "POJ",
      city_name: "Patos de Minas",
      country_code: "BR"
  },
  {
      id: "POL",
      name: "PEMBA AIRPORT",
      area_code: "POL",
      city_name: "Pemba",
      country_code: "MZ"
  },
  {
      id: "POR",
      name: "PORI AIRPORT",
      area_code: "POR",
      city_name: "Pori",
      country_code: "FI"
  },
  {
      id: "POW",
      name: "Portoroz",
      area_code: "POW",
      city_name: "Portoroz",
      country_code: "SI"
  },
  {
      id: "POX",
      name: "Pontoise-Cormeilles",
      area_code: "PAR",
      city_name: "Paris",
      country_code: "FR"
  },
  {
      id: "POZ",
      name: "lawica Airport",
      area_code: "POZ",
      city_name: "Poznan",
      country_code: "PL"
  },
  {
      id: "PPB",
      name: "Presidente Prudente Airport",
      area_code: "PPB",
      city_name: "Presidente Prudente",
      country_code: "BR"
  },
  {
      id: "PPE",
      name: "International",
      area_code: "PPE",
      city_name: "Puerto Penasco",
      country_code: "MX"
  },
  {
      id: "PPF",
      name: "Tri-City",
      area_code: "PPF",
      city_name: "Parsons",
      country_code: "US"
  },
  {
      id: "PPI",
      name: "Port Pirie",
      area_code: "PPI",
      city_name: "Port Pirie",
      country_code: "AU"
  },
  {
      id: "PPK",
      name: "Petropavlovsk Airport",
      area_code: "PPK",
      city_name: "Petropavlovsk",
      country_code: "KZ"
  },
  {
      id: "PPL",
      name: "PHAPLU AIRPORT",
      area_code: "PPL",
      city_name: "Phaplu",
      country_code: "NP"
  },
  {
      id: "PPM",
      name: "Airpark",
      area_code: "PPM",
      city_name: "Pompano Beach",
      country_code: "US"
  },
  {
      id: "PPN",
      name: "Popayan Airport",
      area_code: "PPN",
      city_name: "Popayan",
      country_code: "CO"
  },
  {
      id: "PPP",
      name: "Whitsunday Coast Airport",
      area_code: "PPP",
      city_name: "Proserpine",
      country_code: "AU"
  },
  {
      id: "PPQ",
      name: "PARAPARAUMU AIRPORT",
      area_code: "PPQ",
      city_name: "Paraparaumu",
      country_code: "NZ"
  },
  {
      id: "PPS",
      name: "PUERTO PRINCESA AIRPORT",
      area_code: "PPS",
      city_name: "Puerto Princesa",
      country_code: "PH"
  },
  {
      id: "PPV",
      name: "Port Protection Seaplane Base",
      area_code: "PPV",
      city_name: "Port Protection",
      country_code: "US"
  },
  {
      id: "PPW",
      name: "PAPA WESTRAY AIRPORT",
      area_code: "PPW",
      city_name: "Papa Westray",
      country_code: "GB"
  },
  {
      id: "PQM",
      name: "Palenque International Airport",
      area_code: "PQM",
      city_name: "Palenque",
      country_code: "MX"
  },
  {
      id: "PQQ",
      name: "PORT MACQUARIE AIRPORT",
      area_code: "PQQ",
      city_name: "Port Macquarie",
      country_code: "AU"
  },
  {
      id: "PRA",
      name: "Parana Airport",
      area_code: "PRA",
      city_name: "Parana",
      country_code: "AR"
  },
  {
      id: "PRB",
      name: "Municipal",
      area_code: "PRB",
      city_name: "Paso Robles",
      country_code: "US"
  },
  {
      id: "PRH",
      name: "PHRAE AIRPORT",
      area_code: "PRH",
      city_name: "Phrae",
      country_code: "TH"
  },
  {
      id: "PRM",
      name: "Portimao Airport",
      area_code: "PRM",
      city_name: "Portimao",
      country_code: "PT"
  },
  {
      id: "PRN",
      name: "Pristina International Airport",
      area_code: "PRN",
      city_name: "Pristina",
      country_code: "XK"
  },
  {
      id: "PRP",
      name: "Propriano",
      area_code: "PRP",
      city_name: "Propriano",
      country_code: "FR"
  },
  {
      id: "PRX",
      name: "Cox Field",
      area_code: "PRX",
      city_name: "Paris",
      country_code: "US"
  },
  {
      id: "PSB",
      name: "Mid-State",
      area_code: "PSB",
      city_name: "Philipsburg",
      country_code: "US"
  },
  {
      id: "PSE",
      name: "MERCEDITA AIRPORT",
      area_code: "PSE",
      city_name: "Ponce",
      country_code: "PR"
  },
  {
      id: "PSF",
      name: "Municipal",
      area_code: "PSF",
      city_name: "Pittsfield",
      country_code: "US"
  },
  {
      id: "PSG",
      name: "James A. Johnson Airport",
      area_code: "PSG",
      city_name: "Petersburg",
      country_code: "US"
  },
  {
      id: "PSK",
      name: "New River Valley",
      area_code: "PSK",
      city_name: "Dublin",
      country_code: "US"
  },
  {
      id: "PSL",
      name: "Scone",
      area_code: "PSL",
      city_name: "Perth",
      country_code: "GB"
  },
  {
      id: "PSM",
      name: "Portsmouth International Airport",
      area_code: "BOS",
      city_name: "Boston",
      country_code: "US"
  },
  {
      id: "PSO",
      name: "Pasto Airport",
      area_code: "PSO",
      city_name: "Pasto",
      country_code: "CO"
  },
  {
      id: "PSR",
      name: "Abruzzo Airport",
      area_code: "PSR",
      city_name: "Pescara",
      country_code: "IT"
  },
  {
      id: "PSS",
      name: "Jose de San Martin Airport",
      area_code: "PSS",
      city_name: "Posadas",
      country_code: "AR"
  },
  {
      id: "PSZ",
      name: "PUERTO SUAREZ AIRPORT",
      area_code: "PSZ",
      city_name: "Puerto Suarez",
      country_code: "BO"
  },
  {
      id: "PTB",
      name: "Dinwiddie County",
      area_code: "PTB",
      city_name: "Petersburg",
      country_code: "US"
  },
  {
      id: "PTC",
      name: "Sea Plane Base",
      area_code: "PTC",
      city_name: "Port Alice",
      country_code: "US"
  },
  {
      id: "PTD",
      name: "Sea Plane Base",
      area_code: "PTD",
      city_name: "Port Alexander",
      country_code: "US"
  },
  {
      id: "PTF",
      name: "Malolo Lailai Island",
      area_code: "PTF",
      city_name: "Malolo Lailai Island",
      country_code: "FJ"
  },
  {
      id: "PTJ",
      name: "PORTLAND AIRPORT",
      area_code: "PTJ",
      city_name: "Portland",
      country_code: "AU"
  },
  {
      id: "PTK",
      name: "Oakland County Airport",
      area_code: "PTK",
      city_name: "Pontiac",
      country_code: "US"
  },
  {
      id: "PTL",
      name: "PORT ARMSTRONG",
      area_code: "PTL",
      city_name: "Port Armstrong",
      country_code: "US"
  },
  {
      id: "PTO",
      name: "J.Loureiro Cardoso",
      area_code: "PTO",
      city_name: "Pato Branco",
      country_code: "BR"
  },
  {
      id: "PTS",
      name: "Atkinson Municipal",
      area_code: "PTS",
      city_name: "Pittsburg",
      country_code: "US"
  },
  {
      id: "PTV",
      name: "Municipal",
      area_code: "PTV",
      city_name: "Porterville",
      country_code: "US"
  },
  {
      id: "TAD",
      name: "Perry Stokes",
      area_code: "TAD",
      city_name: "Trinidad",
      country_code: "US"
  },
  {
      id: "PUC",
      name: "Carbon County Regional",
      area_code: "PUC",
      city_name: "Price",
      country_code: "US"
  },
  {
      id: "PUG",
      name: "PORT AUGUSTA AIRPORT",
      area_code: "PUG",
      city_name: "Port Augusta",
      country_code: "AU"
  },
  {
      id: "PUI",
      name: "Pureni",
      area_code: "PUI",
      city_name: "Pureni",
      country_code: "PG"
  },
  {
      id: "PUJ",
      name: "Punta Cana International Airport",
      area_code: "PUJ",
      city_name: "Punta Cana",
      country_code: "DO"
  },
  {
      id: "PUU",
      name: "Puerto Asis Airport",
      area_code: "PUU",
      city_name: "Puerto Asis",
      country_code: "CO"
  },
  {
      id: "PUX",
      name: "El Mirador",
      area_code: "PUX",
      city_name: "Puerto Varas",
      country_code: "CL"
  },
  {
      id: "PUY",
      name: "PULA AIRPORT",
      area_code: "PUY",
      city_name: "Pula",
      country_code: "HR"
  },
  {
      id: "PVA",
      name: "El Embrujo",
      area_code: "PVA",
      city_name: "Providencia Island",
      country_code: "CO"
  },
  {
      id: "PVC",
      name: "Provincetown Municipal Airport",
      area_code: "PVC",
      city_name: "Provincetown",
      country_code: "US"
  },
  {
      id: "PVK",
      name: "AKTION AIRPORT",
      area_code: "PVK",
      city_name: "Preveza/Lefkada",
      country_code: "GR"
  },
  {
      id: "PVL",
      name: "Pike County Regional",
      area_code: "PVL",
      city_name: "Pikeville",
      country_code: "US"
  },
  {
      id: "PVU",
      name: "Provo Municipal Airport",
      area_code: "PVU",
      city_name: "Provo",
      country_code: "US"
  },
  {
      id: "PVW",
      name: "Hale County",
      area_code: "PVW",
      city_name: "Plainview",
      country_code: "US"
  },
  {
      id: "PWQ",
      name: "PAVLODAR AIRPORT",
      area_code: "PWQ",
      city_name: "Pavlodar",
      country_code: "KZ"
  },
  {
      id: "PWR",
      name: "Sea Plane Base",
      area_code: "PWR",
      city_name: "Port Walter",
      country_code: "US"
  },
  {
      id: "PWT",
      name: "National",
      area_code: "PWT",
      city_name: "Bremerton",
      country_code: "US"
  },
  {
      id: "PXL",
      name: "Polacca",
      area_code: "PXL",
      city_name: "Polacca",
      country_code: "US"
  },
  {
      id: "PXM",
      name: "Puerto Escondido Airport",
      area_code: "PXM",
      city_name: "Puerto Escondido",
      country_code: "MX"
  },
  {
      id: "PYH",
      name: "Cacique Aramare",
      area_code: "PYH",
      city_name: "Puerto Ayacucho",
      country_code: "VE"
  },
  {
      id: "PYM",
      name: "Municipal",
      area_code: "PYM",
      city_name: "Plymouth",
      country_code: "US"
  },
  {
      id: "PYY",
      name: "Pai Airport",
      area_code: "PYY",
      city_name: "Pai",
      country_code: "TH"
  },
  {
      id: "PZB",
      name: "PIETERMARITZBURG AIRPORT",
      area_code: "PZB",
      city_name: "Pietermaritzburg",
      country_code: "ZA"
  },
  {
      id: "PZO",
      name: "Puerto Ordaz Airport",
      area_code: "PZO",
      city_name: "Puerto Ordaz",
      country_code: "VE"
  },
  {
      id: "PZY",
      name: "Piestany",
      area_code: "PZY",
      city_name: "Piestany",
      country_code: "SK"
  },
  {
      id: "QBC",
      name: "BELLA COOLA AIRPORT",
      area_code: "QBC",
      city_name: "Bella Coola",
      country_code: "CA"
  },
  {
      id: "QOW",
      name: "Owerri Airport",
      area_code: "QOW",
      city_name: "Owerri",
      country_code: "NG"
  },
  {
      id: "QRW",
      name: "Warri Airport",
      area_code: "QRW",
      city_name: "Warri",
      country_code: "NG"
  },
  {
      id: "QSC",
      name: "Mario Pereira Lopes",
      area_code: "QSC",
      city_name: "Sao Carlos",
      country_code: "BR"
  },
  {
      id: "QSF",
      name: "Setif Airport",
      area_code: "QSF",
      city_name: "Setif",
      country_code: "DZ"
  },
  {
      id: "JDR",
      name: "O. de Almeida Neves",
      area_code: "JDR",
      city_name: "Sao Joao del Rei",
      country_code: "BR"
  },
  {
      id: "QSR",
      name: "Pontecagnano",
      area_code: "QSR",
      city_name: "Salerno",
      country_code: "IT"
  },
  {
      id: "RAB",
      name: "Tokua Airport",
      area_code: "RAB",
      city_name: "Rabaul",
      country_code: "PG"
  },
  {
      id: "RAC",
      name: "John H. Batten",
      area_code: "RAC",
      city_name: "Racine",
      country_code: "US"
  },
  {
      id: "RAE",
      name: "ARAR AIRPORT",
      area_code: "RAE",
      city_name: "Arar",
      country_code: "SA"
  },
  {
      id: "RAL",
      name: "Municipal",
      area_code: "RAL",
      city_name: "Riverside",
      country_code: "US"
  },
  {
      id: "RCA",
      name: "Ellsworth AFB",
      area_code: "RAP",
      city_name: "Rapid City",
      country_code: "US"
  },
  {
      id: "RAR",
      name: "Rarotonga Airport",
      area_code: "RAR",
      city_name: "Rarotonga Island",
      country_code: "CK"
  },
  {
      id: "RBF",
      name: "Big Bear City",
      area_code: "RBF",
      city_name: "Big Bear City",
      country_code: "US"
  },
  {
      id: "RBG",
      name: "Regional",
      area_code: "RBG",
      city_name: "Roseburg",
      country_code: "US"
  },
  {
      id: "RBM",
      name: "Wallmuehle",
      area_code: "RBM",
      city_name: "Straubing",
      country_code: "DE"
  },
  {
      id: "RBR",
      name: "Rio Branco Airport",
      area_code: "RBR",
      city_name: "Rio Branco",
      country_code: "BR"
  },
  {
      id: "RBW",
      name: "Lowcountry Regional",
      area_code: "RBW",
      city_name: "Walterboro",
      country_code: "US"
  },
  {
      id: "RCB",
      name: "RICHARDS BAY AIRPORT",
      area_code: "RCB",
      city_name: "Richards Bay",
      country_code: "ZA"
  },
  {
      id: "RCE",
      name: "Sea Plane Base",
      area_code: "RCE",
      city_name: "Roche Harbor",
      country_code: "US"
  },
  {
      id: "RCM",
      name: "RICHMOND AIRPORT",
      area_code: "RCM",
      city_name: "Richmond",
      country_code: "AU"
  },
  {
      id: "RCO",
      name: "Saint Agnant",
      area_code: "RCO",
      city_name: "Rochefort",
      country_code: "FR"
  },
  {
      id: "RCS",
      name: "Rochester",
      area_code: "RCS",
      city_name: "Rochester",
      country_code: "GB"
  },
  {
      id: "RCU",
      name: "Rio Cuarto Airport",
      area_code: "RCU",
      city_name: "Rio Cuarto",
      country_code: "AR"
  },
  {
      id: "RCY",
      name: "Rum Cay Airport",
      area_code: "RCY",
      city_name: "Rum Cay",
      country_code: "BS"
  },
  {
      id: "RBL",
      name: "Municipal",
      area_code: "RBL",
      city_name: "Red Bluff",
      country_code: "US"
  },
  {
      id: "RDG",
      name: "Regional",
      area_code: "RDG",
      city_name: "Reading",
      country_code: "US"
  },
  {
      id: "RDN",
      name: "Redang Island",
      area_code: "RDN",
      city_name: "Redang Island",
      country_code: "MY"
  },
  {
      id: "REG",
      name: "Reggio Calabria Airport",
      area_code: "REG",
      city_name: "Reggio di Calabria",
      country_code: "IT"
  },
  {
      id: "REL",
      name: "Trelew Airport",
      area_code: "REL",
      city_name: "Trelew",
      country_code: "AR"
  },
  {
      id: "RER",
      name: "Retalhuleu",
      area_code: "RER",
      city_name: "Retalhuleu",
      country_code: "GT"
  },
  {
      id: "REZ",
      name: "Resende",
      area_code: "REZ",
      city_name: "Resende",
      country_code: "BR"
  },
  {
      id: "RFG",
      name: "Rooke Field",
      area_code: "RFG",
      city_name: "Refugio",
      country_code: "US"
  },
  {
      id: "RFP",
      name: "Raiatea Airport",
      area_code: "RFP",
      city_name: "Raiatea",
      country_code: "PF"
  },
  {
      id: "RGH",
      name: "Balurghat",
      area_code: "RGH",
      city_name: "Balurghat",
      country_code: "IN"
  },
  {
      id: "RGI",
      name: "RANGIROA AIRPORT",
      area_code: "RGI",
      city_name: "Rangiroa",
      country_code: "PF"
  },
  {
      id: "RGK",
      name: "Gorno-Altaysk Airport",
      area_code: "RGK",
      city_name: "Gorno-Altaysk",
      country_code: "RU"
  },
  {
      id: "RGL",
      name: "Rio Gallegos International Airport",
      area_code: "RGL",
      city_name: "Rio Gallegos",
      country_code: "AR"
  },
  {
      id: "RGR",
      name: "Municipal",
      area_code: "RGR",
      city_name: "Ranger",
      country_code: "US"
  },
  {
      id: "RGS",
      name: "Burgos Airport",
      area_code: "RGS",
      city_name: "Burgos",
      country_code: "ES"
  },
  {
      id: "RHD",
      name: "Rio Hondo Airport",
      area_code: "RHD",
      city_name: "Termas de Rio Hondo",
      country_code: "AR"
  },
  {
      id: "RHN",
      name: "Skorpion Mine",
      area_code: "RHN",
      city_name: "Rosh Pinah",
      country_code: "NA"
  },
  {
      id: "RHO",
      name: "Diagoras Airport",
      area_code: "RHO",
      city_name: "Rhodes",
      country_code: "GR"
  },
  {
      id: "RHT",
      name: "Alashanyouqi Airport",
      area_code: "RHT",
      city_name: "Alxa Right Banner",
      country_code: "CN"
  },
  {
      id: "RIB",
      name: "Riberalta Airport",
      area_code: "RIB",
      city_name: "Riberalta",
      country_code: "BO"
  },
  {
      id: "RIF",
      name: "Municipal",
      area_code: "RIF",
      city_name: "Richfield",
      country_code: "US"
  },
  {
      id: "RIH",
      name: "Scarlett Martinez Intl",
      area_code: "RIH",
      city_name: "Rio Hato",
      country_code: "PA"
  },
  {
      id: "RIL",
      name: "Garfield County Rgnl",
      area_code: "RIL",
      city_name: "Rifle",
      country_code: "US"
  },
  {
      id: "RJA",
      name: "RAJAHMUNDRY AIRPORT",
      area_code: "RJA",
      city_name: "Rajahmundry",
      country_code: "IN"
  },
  {
      id: "RJL",
      name: "AGONCILLO AIRPORT",
      area_code: "RJL",
      city_name: "Logrono",
      country_code: "ES"
  },
  {
      id: "RKD",
      name: "KNOX COUNTY REGIONAL AIRPORT",
      area_code: "RKD",
      city_name: "Rockland",
      country_code: "US"
  },
  {
      id: "RKE",
      name: "Roskilde Airport",
      area_code: "CPH",
      city_name: "Copenhagen",
      country_code: "DK"
  },
  {
      id: "RKH",
      name: "York County-Bryant Fld",
      area_code: "RKH",
      city_name: "Rock Hill",
      country_code: "US"
  },
  {
      id: "RKP",
      name: "Aransas County",
      area_code: "RKP",
      city_name: "Rockport",
      country_code: "US"
  },
  {
      id: "RKS",
      name: "Sweetwater County Airport",
      area_code: "RKS",
      city_name: "Rock Springs",
      country_code: "US"
  },
  {
      id: "RKT",
      name: "Ras Al Khaimah International Airport",
      area_code: "RKT",
      city_name: "Ras al Khaimah",
      country_code: "AE"
  },
  {
      id: "RKW",
      name: "Municipal",
      area_code: "RKW",
      city_name: "Rockwood",
      country_code: "US"
  },
  {
      id: "RLD",
      name: "Richland",
      area_code: "RLD",
      city_name: "Richland",
      country_code: "US"
  },
  {
      id: "RLG",
      name: "LAAGE AIRPORT",
      area_code: "RLG",
      city_name: "Rostock",
      country_code: "DE"
  },
  {
      id: "RLO",
      name: "Valle del Conlara",
      area_code: "RLO",
      city_name: "Merlo",
      country_code: "AR"
  },
  {
      id: "RMF",
      name: "Marsa Alam Airport",
      area_code: "RMF",
      city_name: "Marsa Alam",
      country_code: "EG"
  },
  {
      id: "RMG",
      name: "Richard B Russell Rgnl",
      area_code: "RMG",
      city_name: "Rome",
      country_code: "US"
  },
  {
      id: "RMS",
      name: "Air Base",
      area_code: "RMS",
      city_name: "Ramstein",
      country_code: "DE"
  },
  {
      id: "RMT",
      name: "Rimatara Airport",
      area_code: "RMT",
      city_name: "Rimatara",
      country_code: "PF"
  },
  {
      id: "RMY",
      name: "Mariposa-Yosemite",
      area_code: "RMY",
      city_name: "Mariposa",
      country_code: "US"
  },
  {
      id: "RNB",
      name: "Kallinge Airport",
      area_code: "RNB",
      city_name: "Ronneby/Karlskrona",
      country_code: "SE"
  },
  {
      id: "RNE",
      name: "Renaison",
      area_code: "RNE",
      city_name: "Roanne",
      country_code: "FR"
  },
  {
      id: "RNH",
      name: "Regional",
      area_code: "RNH",
      city_name: "New Richmond",
      country_code: "US"
  },
  {
      id: "ROG",
      name: "Municipal - Carter Fld",
      area_code: "ROG",
      city_name: "Rogers",
      country_code: "US"
  },
  {
      id: "ROI",
      name: "ROI ET AIRPORT",
      area_code: "ROI",
      city_name: "Roi Et",
      country_code: "TH"
  },
  {
      id: "RON",
      name: "Juan Jose Rondon",
      area_code: "RON",
      city_name: "Paipa",
      country_code: "CO"
  },
  {
      id: "ROO",
      name: "Maestro Marinho Franco Airport",
      area_code: "ROO",
      city_name: "Rondonopolis",
      country_code: "BR"
  },
  {
      id: "ROP",
      name: "Rota International Airport",
      area_code: "ROP",
      city_name: "Rota",
      country_code: "US"
  },
  {
      id: "ROU",
      name: "Shtruklevo",
      area_code: "ROU",
      city_name: "Ruse",
      country_code: "BG"
  },
  {
      id: "ROZ",
      name: "US Naval Station",
      area_code: "ROZ",
      city_name: "Rota",
      country_code: "ES"
  },
  {
      id: "RPN",
      name: "Ben Ya'akov",
      area_code: "RPN",
      city_name: "Rosh Pina",
      country_code: "IL"
  },
  {
      id: "RRG",
      name: "Rodrigues Island Airport",
      area_code: "RRG",
      city_name: "Rodrigues Island",
      country_code: "MU"
  },
  {
      id: "RRK",
      name: "Rourkela",
      area_code: "RRK",
      city_name: "Rourkela",
      country_code: "IN"
  },
  {
      id: "RRS",
      name: "ROROS AIRPORT",
      area_code: "RRS",
      city_name: "Roros",
      country_code: "NO"
  },
  {
      id: "RSD",
      name: "Rock Sound Airport",
      area_code: "RSD",
      city_name: "Rock Sound",
      country_code: "BS"
  },
  {
      id: "RSH",
      name: "Russian Mission Airport",
      area_code: "RSH",
      city_name: "Russian Mission",
      country_code: "US"
  },
  {
      id: "RSN",
      name: "Regional",
      area_code: "RSN",
      city_name: "Ruston",
      country_code: "US"
  },
  {
      id: "RSU",
      name: "Yeosu Airport",
      area_code: "RSU",
      city_name: "Yeosu/Suncheon",
      country_code: "KR"
  },
  {
      id: "RTB",
      name: "Roatan Airport",
      area_code: "RTB",
      city_name: "Roatan",
      country_code: "HN"
  },
  {
      id: "RTC",
      name: "Ratnagiri",
      area_code: "RTC",
      city_name: "Ratnagiri",
      country_code: "IN"
  },
  {
      id: "RTE",
      name: "Marguerite Bay Airport",
      area_code: "RTE",
      city_name: "Marguerite Bay",
      country_code: "US"
  },
  {
      id: "RTN",
      name: "Municipal (Crews Fld)",
      area_code: "RTN",
      city_name: "Raton",
      country_code: "US"
  },
  {
      id: "RTS",
      name: "Rottnest Island",
      area_code: "RTS",
      city_name: "Rottnest Island",
      country_code: "AU"
  },
  {
      id: "RTW",
      name: "Tsentralny Airport",
      area_code: "RTW",
      city_name: "Saratov",
      country_code: "RU"
  },
  {
      id: "RUI",
      name: "Sierra Blanca Regional",
      area_code: "RUI",
      city_name: "Ruidoso",
      country_code: "US"
  },
  {
      id: "RUP",
      name: "RUPSI AIRPORT",
      area_code: "RUP",
      city_name: "Rupsi",
      country_code: "IN"
  },
  {
      id: "RUT",
      name: "RUTLAND AIRPORT",
      area_code: "RUT",
      city_name: "Rutland",
      country_code: "US"
  },
  {
      id: "RUY",
      name: "Ruinas de Copan",
      area_code: "RUY",
      city_name: "Copan",
      country_code: "HN"
  },
  {
      id: "RVD",
      name: "Rio Verde Airport",
      area_code: "RVD",
      city_name: "Rio Verde",
      country_code: "BR"
  },
  {
      id: "RVE",
      name: "Saravena Airport",
      area_code: "RVE",
      city_name: "Saravena",
      country_code: "CO"
  },
  {
      id: "RVK",
      name: "Roervik Airport",
      area_code: "RVK",
      city_name: "Rorvik",
      country_code: "NO"
  },
  {
      id: "RVN",
      name: "Rovaniemi Airport",
      area_code: "RVN",
      city_name: "Rovaniemi",
      country_code: "FI"
  },
  {
      id: "RVR",
      name: "Municipal",
      area_code: "RVR",
      city_name: "Green River",
      country_code: "US"
  },
  {
      id: "RVT",
      name: "RAVENSTHORPE AIRPORT",
      area_code: "RVT",
      city_name: "Ravensthorpe",
      country_code: "AU"
  },
  {
      id: "RWL",
      name: "Municipal (Harvey Fld)",
      area_code: "RWL",
      city_name: "Rawlins",
      country_code: "US"
  },
  {
      id: "RWN",
      name: "International",
      area_code: "RWN",
      city_name: "Rivne",
      country_code: "UA"
  },
  {
      id: "RYB",
      name: "Staroselye",
      area_code: "RYB",
      city_name: "Rybinsk",
      country_code: "RU"
  },
  {
      id: "RYK",
      name: "RAHIM YAR KHAN AIRPORT",
      area_code: "RYK",
      city_name: "Rahim Yar Khan",
      country_code: "PK"
  },
  {
      id: "JEK",
      name: "Jeki Airport",
      area_code: "RYL",
      city_name: "Lower Zambezi Nat.Park",
      country_code: "ZM"
  },
  {
      id: "RYL",
      name: "Royal Airport",
      area_code: "RYL",
      city_name: "Lower Zambezi Nat.Park",
      country_code: "ZM"
  },
  {
      id: "RZE",
      name: "Jasionka Airport",
      area_code: "RZE",
      city_name: "Rzeszow",
      country_code: "PL"
  },
  {
      id: "RZZ",
      name: "Halifax-Northampton",
      area_code: "RZZ",
      city_name: "Roanoke Rapids",
      country_code: "US"
  },
  {
      id: "SAB",
      name: "Yrausquin Airport",
      area_code: "SAB",
      city_name: "Saba",
      country_code: "NL"
  },
  {
      id: "MCC",
      name: "McClellan",
      area_code: "SAC",
      city_name: "Sacramento",
      country_code: "US"
  },
  {
      id: "MHR",
      name: "Mather",
      area_code: "SAC",
      city_name: "Sacramento",
      country_code: "US"
  },
  {
      id: "SAG",
      name: "Shirdi Airport",
      area_code: "SAG",
      city_name: "Shirdi",
      country_code: "IN"
  },
  {
      id: "SAK",
      name: "Alexander",
      area_code: "SAK",
      city_name: "Saudarkrokur",
      country_code: "IS"
  },
  {
      id: "SAM",
      name: "Salamo",
      area_code: "SAM",
      city_name: "Salamo",
      country_code: "PG"
  },
  {
      id: "MYF",
      name: "Montgomery Field",
      area_code: "SAN",
      city_name: "San Diego",
      country_code: "US"
  },
  {
      id: "NKX",
      name: "Miramar MCAS",
      area_code: "SAN",
      city_name: "San Diego",
      country_code: "US"
  },
  {
      id: "NZY",
      name: "North Island NAS",
      area_code: "SAN",
      city_name: "San Diego",
      country_code: "US"
  },
  {
      id: "SDM",
      name: "Brown Field Municipal",
      area_code: "SAN",
      city_name: "San Diego",
      country_code: "US"
  },
  {
      id: "SEE",
      name: "Gillespie Field",
      area_code: "SAN",
      city_name: "San Diego",
      country_code: "US"
  },
  {
      id: "SAR",
      name: "Sparta Community",
      area_code: "SAR",
      city_name: "Sparta",
      country_code: "US"
  },
  {
      id: "SAS",
      name: "Salton Sea",
      area_code: "SAS",
      city_name: "Salton City",
      country_code: "US"
  },
  {
      id: "RND",
      name: "Randolph AFB",
      area_code: "SAT",
      city_name: "San Antonio",
      country_code: "US"
  },
  {
      id: "SKF",
      name: "Lackland AFB-Kelly Fld",
      area_code: "SAT",
      city_name: "San Antonio",
      country_code: "US"
  },
  {
      id: "SSF",
      name: "Stinson Municipal",
      area_code: "SAT",
      city_name: "San Antonio",
      country_code: "US"
  },
  {
      id: "SVN",
      name: "Hunter AAF",
      area_code: "SAV",
      city_name: "Savannah",
      country_code: "US"
  },
  {
      id: "SAY",
      name: "Ampugnano",
      area_code: "SAY",
      city_name: "Siena",
      country_code: "IT"
  },
  {
      id: "SAZ",
      name: "Sasstown",
      area_code: "SAZ",
      city_name: "Sasstown",
      country_code: "LR"
  },
  {
      id: "SZN",
      name: "Santa Cruz Island",
      area_code: "SZN",
      city_name: "Santa Cruz Island",
      country_code: "US"
  },
  {
      id: "SBH",
      name: "St. Barthelemy Airport",
      area_code: "SBH",
      city_name: "St Barthelemy",
      country_code: "GP"
  },
  {
      id: "SBK",
      name: "Armor",
      area_code: "SBK",
      city_name: "St-Brieuc",
      country_code: "FR"
  },
  {
      id: "SBM",
      name: "Sheboygan County Mem.",
      area_code: "SBM",
      city_name: "Sheboygan",
      country_code: "US"
  },
  {
      id: "SBS",
      name: "Bob Adams Field",
      area_code: "SBS",
      city_name: "Steamboat Springs",
      country_code: "US"
  },
  {
      id: "SBD",
      name: "International",
      area_code: "SBD",
      city_name: "San Bernardino",
      country_code: "US"
  },
  {
      id: "SBU",
      name: "Springbok",
      area_code: "SBU",
      city_name: "Springbok",
      country_code: "ZA"
  },
  {
      id: "SBY",
      name: "Wicomico Regional Airport",
      area_code: "SBY",
      city_name: "Salisbury/Ocean City",
      country_code: "US"
  },
  {
      id: "SCF",
      name: "Scottsdale Apt",
      area_code: "PHX",
      city_name: "Phoenix",
      country_code: "US"
  },
  {
      id: "SCH",
      name: "Schenectady County",
      area_code: "SCH",
      city_name: "Schenectady",
      country_code: "US"
  },
  {
      id: "SCI",
      name: "Paramillo",
      area_code: "SCI",
      city_name: "San Cristobal",
      country_code: "VE"
  },
  {
      id: "SCW",
      name: "SYKTYVKAR AIRPORT",
      area_code: "SCW",
      city_name: "Syktyvkar",
      country_code: "RU"
  },
  {
      id: "SCX",
      name: "Salina Cruz",
      area_code: "SCX",
      city_name: "Salina Cruz",
      country_code: "MX"
  },
  {
      id: "SCY",
      name: "San Cristobal Airport",
      area_code: "SCY",
      city_name: "San Cristobal Island",
      country_code: "EC"
  },
  {
      id: "SCZ",
      name: "Santa Cruz Island Airport",
      area_code: "SCZ",
      city_name: "Santa Cruz Island",
      country_code: "SB"
  },
  {
      id: "SDB",
      name: "Langebaanweg AFB",
      area_code: "SDB",
      city_name: "Saldanha Bay",
      country_code: "ZA"
  },
  {
      id: "SDD",
      name: "Lubango Airport",
      area_code: "SDD",
      city_name: "Lubango",
      country_code: "AO"
  },
  {
      id: "SDE",
      name: "Santiago Del Estero Airport",
      area_code: "SDE",
      city_name: "Santiago del Estero",
      country_code: "AR"
  },
  {
      id: "LOU",
      name: "Bowman Field",
      area_code: "SDF",
      city_name: "Louisville",
      country_code: "US"
  },
  {
      id: "SDN",
      name: "Sandane Airport",
      area_code: "SDN",
      city_name: "Sandane",
      country_code: "NO"
  },
  {
      id: "SDP",
      name: "Sand Point Airport",
      area_code: "SDP",
      city_name: "Sand Point",
      country_code: "US"
  },
  {
      id: "JBQ",
      name: "La Isabela International Airport",
      area_code: "SDQ",
      city_name: "Santo Domingo",
      country_code: "DO"
  },
  {
      id: "SDX",
      name: "Sedona",
      area_code: "SDX",
      city_name: "Sedona",
      country_code: "US"
  },
  {
      id: "SDY",
      name: "Richland Municipal Airport",
      area_code: "SDY",
      city_name: "Sidney",
      country_code: "US"
  },
  {
      id: "SCS",
      name: "Scatsta",
      area_code: "SDZ",
      city_name: "Shetland Islands",
      country_code: "GB"
  },
  {
      id: "BFI",
      name: "Boeing Field International Airport",
      area_code: "SEA",
      city_name: "Seattle",
      country_code: "US"
  },
  {
      id: "KEH",
      name: "Kenmore Air Harbor SPB",
      area_code: "KEH",
      city_name: "Kenmore",
      country_code: "US"
  },
  {
      id: "LKE",
      name: "Lake Union SPB",
      area_code: "SEA",
      city_name: "Seattle",
      country_code: "US"
  },
  {
      id: "SEF",
      name: "Regional",
      area_code: "SEF",
      city_name: "Sebring",
      country_code: "US"
  },
  {
      id: "SEK",
      name: "Srednekolymsk Airport",
      area_code: "SEK",
      city_name: "Srednekolymsk",
      country_code: "RU"
  },
  {
      id: "SSN",
      name: "Air Base",
      area_code: "SEL",
      city_name: "Seoul",
      country_code: "KR"
  },
  {
      id: "SEN",
      name: "Southend Airport",
      area_code: "LON",
      city_name: "London",
      country_code: "GB"
  },
  {
      id: "SEP",
      name: "Clark Field Regional",
      area_code: "SEP",
      city_name: "Stephenville",
      country_code: "US"
  },
  {
      id: "SEM",
      name: "Craig Field",
      area_code: "SEM",
      city_name: "Selma",
      country_code: "US"
  },
  {
      id: "SEU",
      name: "SERONERA AIRPORT",
      area_code: "SEU",
      city_name: "Seronera",
      country_code: "TZ"
  },
  {
      id: "SFB",
      name: "Sanford International Airport",
      area_code: "ORL",
      city_name: "Orlando",
      country_code: "US"
  },
  {
      id: "SFE",
      name: "San Fernando",
      area_code: "SFE",
      city_name: "San Fernando",
      country_code: "PH"
  },
  {
      id: "CCE",
      name: "Capital International",
      area_code: "CAI",
      city_name: "Cairo",
      country_code: "EG"
  },
  {
      id: "SFG",
      name: "Esperance Airport",
      area_code: "SFG",
      city_name: "St Martin",
      country_code: "GP"
  },
  {
      id: "SFH",
      name: "International",
      area_code: "SFH",
      city_name: "San Felipe",
      country_code: "MX"
  },
  {
      id: "SFJ",
      name: "KANGERLUSSUAQ AIRPORT",
      area_code: "SFJ",
      city_name: "Kangerlussuaq",
      country_code: "GL"
  },
  {
      id: "SFL",
      name: "SAO FILIPE AIRPORT",
      area_code: "SFL",
      city_name: "Sao Filipe",
      country_code: "CV"
  },
  {
      id: "SFM",
      name: "Seacoast Regional",
      area_code: "SFM",
      city_name: "Sanford",
      country_code: "US"
  },
  {
      id: "SFS",
      name: "Subic Bay",
      area_code: "SFS",
      city_name: "Subic Bay",
      country_code: "PH"
  },
  {
      id: "SFX",
      name: "Macagua",
      area_code: "SFX",
      city_name: "San Felix",
      country_code: "VE"
  },
  {
      id: "CEF",
      name: "Westover Metropolitan",
      area_code: "SFY",
      city_name: "Springfield",
      country_code: "US"
  },
  {
      id: "SFZ",
      name: "North Central State",
      area_code: "SFZ",
      city_name: "Pawtucket",
      country_code: "US"
  },
  {
      id: "SGC",
      name: "Surgut Airport",
      area_code: "SGC",
      city_name: "Surgut",
      country_code: "RU"
  },
  {
      id: "SGD",
      name: "Sonderborg Airport",
      area_code: "SGD",
      city_name: "Sonderborg",
      country_code: "DK"
  },
  {
      id: "SGE",
      name: "Siegerland",
      area_code: "SGE",
      city_name: "Siegen",
      country_code: "DE"
  },
  {
      id: "SGH",
      name: "Beckley Municipal",
      area_code: "SGH",
      city_name: "Springfield",
      country_code: "US"
  },
  {
      id: "BHW",
      name: "Bhagatanwala",
      area_code: "SGI",
      city_name: "Sargodha",
      country_code: "PK"
  },
  {
      id: "SGI",
      name: "Mushaf AB",
      area_code: "SGI",
      city_name: "Sargodha",
      country_code: "PK"
  },
  {
      id: "SGR",
      name: "Sugar Land Regional",
      area_code: "HOU",
      city_name: "Houston",
      country_code: "US"
  },
  {
      id: "SGT",
      name: "Municipal",
      area_code: "SGT",
      city_name: "Stuttgart",
      country_code: "US"
  },
  {
      id: "SHB",
      name: "Nakashibetsu Airport",
      area_code: "SHB",
      city_name: "Nakashibetsu",
      country_code: "JP"
  },
  {
      id: "SHC",
      name: "Indaselassie Airport",
      area_code: "SHC",
      city_name: "Inda Selassie",
      country_code: "ET"
  },
  {
      id: "SHL",
      name: "Shillong Airport",
      area_code: "SHL",
      city_name: "Shillong",
      country_code: "IN"
  },
  {
      id: "SHO",
      name: "King Mswati III International Airport",
      area_code: "MTS",
      city_name: "Manzini",
      country_code: "SZ"
  },
  {
      id: "SHT",
      name: "Shepparton",
      area_code: "SHT",
      city_name: "Shepparton",
      country_code: "AU"
  },
  {
      id: "BAD",
      name: "Barksdale AFB",
      area_code: "SHV",
      city_name: "Shreveport",
      country_code: "US"
  },
  {
      id: "DTN",
      name: "Downtown Apt",
      area_code: "SHV",
      city_name: "Shreveport",
      country_code: "US"
  },
  {
      id: "SHW",
      name: "SHARURAH AIRPORT",
      area_code: "SHW",
      city_name: "Sharurah",
      country_code: "SA"
  },
  {
      id: "SHY",
      name: "Ibadakuli",
      area_code: "SHY",
      city_name: "Shinyanga",
      country_code: "TZ"
  },
  {
      id: "SIA",
      name: "Xiguan",
      area_code: "SIA",
      city_name: "Xi'an",
      country_code: "CN"
  },
  {
      id: "QPG",
      name: "Paya Lebar",
      area_code: "SIN",
      city_name: "Singapore",
      country_code: "SG"
  },
  {
      id: "XSP",
      name: "Seletar",
      area_code: "SIN",
      city_name: "Singapore",
      country_code: "SG"
  },
  {
      id: "SIR",
      name: "Sion Airport",
      area_code: "SIR",
      city_name: "Sion",
      country_code: "CH"
  },
  {
      id: "SIY",
      name: "Siskiyou County",
      area_code: "SIY",
      city_name: "Montague/Yreka",
      country_code: "US"
  },
  {
      id: "SJE",
      name: "San Jose Del Gua Airport",
      area_code: "SJE",
      city_name: "San Jose del Guaviare",
      country_code: "CO"
  },
  {
      id: "SJF",
      name: "Cruz Bay SPB",
      area_code: "SJF",
      city_name: "St John Island",
      country_code: "VI"
  },
  {
      id: "SJK",
      name: "U.E. Stumpf Intl",
      area_code: "SJK",
      city_name: "Sao Jose dos Campos",
      country_code: "BR"
  },
  {
      id: "SJL",
      name: "Sao Gabriel Cachoeira",
      area_code: "SJL",
      city_name: "Sao Gabriel Cachoeira",
      country_code: "BR"
  },
  {
      id: "SYQ",
      name: "Tobias Bolanos International Airport",
      area_code: "SJO",
      city_name: "San Jose",
      country_code: "CR"
  },
  {
      id: "SJY",
      name: "Seinajoki",
      area_code: "SJY",
      city_name: "Seinajoki",
      country_code: "FI"
  },
  {
      id: "SJZ",
      name: "San Jorge Island Airport",
      area_code: "SJZ",
      city_name: "Sao Jorge Island",
      country_code: "PT"
  },
  {
      id: "SKB",
      name: "Robert L. Bradshaw International Airport",
      area_code: "SKB",
      city_name: "St Kitts",
      country_code: "KN"
  },
  {
      id: "SKE",
      name: "Geiteryggen",
      area_code: "SKE",
      city_name: "Skien",
      country_code: "NO"
  },
  {
      id: "SKG",
      name: "Makedonia Airport",
      area_code: "SKG",
      city_name: "Thessaloniki",
      country_code: "GR"
  },
  {
      id: "SKK",
      name: "SHAKTOOLIK AIRPORT",
      area_code: "SKK",
      city_name: "Shaktoolik",
      country_code: "US"
  },
  {
      id: "SKL",
      name: "Broadford",
      area_code: "SKL",
      city_name: "Skye",
      country_code: "GB"
  },
  {
      id: "SKN",
      name: "SKAGEN AIRPORT",
      area_code: "SKN",
      city_name: "Stokmarknes",
      country_code: "NO"
  },
  {
      id: "SKS",
      name: "Skrydstrup",
      area_code: "SKS",
      city_name: "Vojens",
      country_code: "DK"
  },
  {
      id: "SKT",
      name: "Sialkot Airport",
      area_code: "SKT",
      city_name: "Sialkot",
      country_code: "PK"
  },
  {
      id: "SKU",
      name: "Skiros Airport",
      area_code: "SKU",
      city_name: "Skyros",
      country_code: "GR"
  },
  {
      id: "SKY",
      name: "Griffing Sandusky",
      area_code: "SKY",
      city_name: "Sandusky",
      country_code: "US"
  },
  {
      id: "SKZ",
      name: "SUKKUR AIRPORT",
      area_code: "SKZ",
      city_name: "Sukkur",
      country_code: "PK"
  },
  {
      id: "SLB",
      name: "Municipal",
      area_code: "SLB",
      city_name: "Storm Lake",
      country_code: "US"
  },
  {
      id: "SLD",
      name: "SLIAC AIRPORT",
      area_code: "SLD",
      city_name: "Sliac",
      country_code: "SK"
  },
  {
      id: "SLG",
      name: "Smith Field",
      area_code: "SLG",
      city_name: "Siloam Springs",
      country_code: "US"
  },
  {
      id: "SLI",
      name: "SOLWEZI AIRPORT",
      area_code: "SLI",
      city_name: "Solwezi",
      country_code: "ZM"
  },
  {
      id: "SLK",
      name: "Adirondack Regional Airport",
      area_code: "SLK",
      city_name: "Saranac Lake",
      country_code: "US"
  },
  {
      id: "SLM",
      name: "Salamanca Airport",
      area_code: "SLM",
      city_name: "Salamanca",
      country_code: "ES"
  },
  {
      id: "SLP",
      name: "Ponciano Arriaga International Airport",
      area_code: "SLP",
      city_name: "San Luis Potosi",
      country_code: "MX"
  },
  {
      id: "SLT",
      name: "Harriet Alexander Fld",
      area_code: "SLT",
      city_name: "Salida",
      country_code: "US"
  },
  {
      id: "SLV",
      name: "Shimla",
      area_code: "SLV",
      city_name: "Shimla",
      country_code: "IN"
  },
  {
      id: "SLY",
      name: "Salekhard Airport",
      area_code: "SLY",
      city_name: "Salekhard",
      country_code: "RU"
  },
  {
      id: "SMA",
      name: "Santa Maria Airport",
      area_code: "SMA",
      city_name: "Santa Maria Island",
      country_code: "PT"
  },
  {
      id: "SME",
      name: "Lake Cumberland Rgnl",
      area_code: "SME",
      city_name: "Somerset",
      country_code: "US"
  },
  {
      id: "SMI",
      name: "Samos Airport",
      area_code: "SMI",
      city_name: "Samos",
      country_code: "GR"
  },
  {
      id: "SML",
      name: "Stella Maris Airport",
      area_code: "SML",
      city_name: "Stella Maris",
      country_code: "BS"
  },
  {
      id: "SMR",
      name: "Simon Bolivar Airport",
      area_code: "SMR",
      city_name: "Santa Marta",
      country_code: "CO"
  },
  {
      id: "SMV",
      name: "Engadin",
      area_code: "SMV",
      city_name: "St Moritz",
      country_code: "CH"
  },
  {
      id: "SNC",
      name: "Salinas Airport",
      area_code: "SNC",
      city_name: "Salinas",
      country_code: "EC"
  },
  {
      id: "SNE",
      name: "Preguica Airport",
      area_code: "SNE",
      city_name: "Sao Nicolau Island",
      country_code: "CV"
  },
  {
      id: "SNF",
      name: "Nestor Arias",
      area_code: "SNF",
      city_name: "San Felipe",
      country_code: "VE"
  },
  {
      id: "SNH",
      name: "Stanthorpe",
      area_code: "SNH",
      city_name: "Stanthorpe",
      country_code: "AU"
  },
  {
      id: "SNI",
      name: "R.E. Murray",
      area_code: "SNI",
      city_name: "Greenville",
      country_code: "LR"
  },
  {
      id: "SNL",
      name: "Regional",
      area_code: "SNL",
      city_name: "Shawnee",
      country_code: "US"
  },
  {
      id: "SNQ",
      name: "San Quintin",
      area_code: "SNQ",
      city_name: "San Quintin",
      country_code: "MX"
  },
  {
      id: "SNR",
      name: "Montoir Airport",
      area_code: "SNR",
      city_name: "St-Nazaire",
      country_code: "FR"
  },
  {
      id: "SNS",
      name: "Municipal",
      area_code: "SNS",
      city_name: "Salinas",
      country_code: "US"
  },
  {
      id: "SNU",
      name: "Abel Santamaria Airport",
      area_code: "SNU",
      city_name: "Santa Clara",
      country_code: "CU"
  },
  {
      id: "SNV",
      name: "Santa Elena de Uairen",
      area_code: "SNV",
      city_name: "Santa Elena de Uairen",
      country_code: "VE"
  },
  {
      id: "SNW",
      name: "THANDWE AIRPORT",
      area_code: "SNW",
      city_name: "Thandwe",
      country_code: "MM"
  },
  {
      id: "SNY",
      name: "Municipal",
      area_code: "SNY",
      city_name: "Sidney",
      country_code: "US"
  },
  {
      id: "SOB",
      name: "Balaton Airport",
      area_code: "SOB",
      city_name: "Heviz",
      country_code: "HU"
  },
  {
      id: "SOD",
      name: "Bertram Luiz Leupolz",
      area_code: "SOD",
      city_name: "Sorocaba",
      country_code: "BR"
  },
  {
      id: "SOG",
      name: "Haukasen",
      area_code: "SOG",
      city_name: "Sogndal",
      country_code: "NO"
  },
  {
      id: "SOL",
      name: "State Field",
      area_code: "SOL",
      city_name: "Solomon",
      country_code: "US"
  },
  {
      id: "SOM",
      name: "San Tome Airport",
      area_code: "SOM",
      city_name: "San Tome",
      country_code: "VE"
  },
  {
      id: "SON",
      name: "Pekoa Airport",
      area_code: "SON",
      city_name: "Luganville",
      country_code: "VU"
  },
  {
      id: "SOP",
      name: "Moore County",
      area_code: "SOP",
      city_name: "Pinehurst/South. Pines",
      country_code: "US"
  },
  {
      id: "SOV",
      name: "SELDOVIA AIRPORT",
      area_code: "SOV",
      city_name: "Seldovia",
      country_code: "US"
  },
  {
      id: "SOW",
      name: "Show Low Regional Airport",
      area_code: "SOW",
      city_name: "Show Low",
      country_code: "US"
  },
  {
      id: "SOY",
      name: "STRONSAY AIRPORT",
      area_code: "SOY",
      city_name: "Stronsay",
      country_code: "GB"
  },
  {
      id: "SPF",
      name: "Black Hills",
      area_code: "SPF",
      city_name: "Spearfish",
      country_code: "US"
  },
  {
      id: "SPG",
      name: "St Pete-Albert Whitted",
      area_code: "TPA",
      city_name: "Tampa",
      country_code: "US"
  },
  {
      id: "SPJ",
      name: "Sparta",
      area_code: "SPJ",
      city_name: "Sparta",
      country_code: "GR"
  },
  {
      id: "OKD",
      name: "Sapporo Okadama Apt",
      area_code: "SPK",
      city_name: "Sapporo",
      country_code: "JP"
  },
  {
      id: "SPM",
      name: "Air Base",
      area_code: "SPM",
      city_name: "Spangdahlem",
      country_code: "DE"
  },
  {
      id: "KIP",
      name: "Kickapoo Downtown Apt",
      area_code: "SPS",
      city_name: "Wichita Falls",
      country_code: "US"
  },
  {
      id: "SPS",
      name: "Sheppard Air Force Base",
      area_code: "SPS",
      city_name: "Wichita Falls",
      country_code: "US"
  },
  {
      id: "SPZ",
      name: "Springdale Municipal",
      area_code: "FYV",
      city_name: "Fayetteville/Springd.",
      country_code: "US"
  },
  {
      id: "SQA",
      name: "Santa Ynez",
      area_code: "SQA",
      city_name: "Santa Ynez",
      country_code: "US"
  },
  {
      id: "SQH",
      name: "Na San",
      area_code: "SQH",
      city_name: "Son La",
      country_code: "VN"
  },
  {
      id: "SQL",
      name: "San Carlos",
      area_code: "SQL",
      city_name: "San Carlos",
      country_code: "US"
  },
  {
      id: "SQO",
      name: "Storuman",
      area_code: "SQO",
      city_name: "Storuman",
      country_code: "SE"
  },
  {
      id: "SQW",
      name: "Airport",
      area_code: "SQW",
      city_name: "Skive",
      country_code: "DK"
  },
  {
      id: "SRC",
      name: "Municipal",
      area_code: "SRC",
      city_name: "Searcy",
      country_code: "US"
  },
  {
      id: "SRF",
      name: "Airport",
      area_code: "SRF",
      city_name: "San Rafael",
      country_code: "US"
  },
  {
      id: "SRL",
      name: "Palo Verde",
      area_code: "SRL",
      city_name: "Santa Rosalia",
      country_code: "MX"
  },
  {
      id: "SRP",
      name: "Stord Airport",
      area_code: "SRP",
      city_name: "Stord",
      country_code: "NO"
  },
  {
      id: "SRU",
      name: "Santa Cruz Airport",
      area_code: "SRU",
      city_name: "Santa Cruz",
      country_code: "US"
  },
  {
      id: "VVI",
      name: "VIRU VIRU INTERNATIONAL AIRPORT",
      area_code: "SRZ",
      city_name: "Santa Cruz",
      country_code: "BO"
  },
  {
      id: "SSC",
      name: "Shaw AFB",
      area_code: "SSC",
      city_name: "Sumter",
      country_code: "US"
  },
  {
      id: "SUM",
      name: "Sumter Apt",
      area_code: "SSC",
      city_name: "Sumter",
      country_code: "US"
  },
  {
      id: "SSJ",
      name: "Stokka",
      area_code: "SSJ",
      city_name: "Sandnessjoen",
      country_code: "NO"
  },
  {
      id: "SSQ",
      name: "La Sarre",
      area_code: "SSQ",
      city_name: "La Sarre",
      country_code: "CA"
  },
  {
      id: "SSR",
      name: "SARA AIRPORT",
      area_code: "SSR",
      city_name: "Sara",
      country_code: "VU"
  },
  {
      id: "STB",
      name: "Miguel U. Fernandez",
      area_code: "STB",
      city_name: "Santa Barbara Zulia",
      country_code: "VE"
  },
  {
      id: "JSK",
      name: "Jask",
      area_code: "JSK",
      city_name: "Jask",
      country_code: "IR"
  },
  {
      id: "STE",
      name: "Municipal",
      area_code: "STE",
      city_name: "Stevens Point",
      country_code: "US"
  },
  {
      id: "STI",
      name: "Santiago Municipal Airport",
      area_code: "STI",
      city_name: "Santiago",
      country_code: "DO"
  },
  {
      id: "STJ",
      name: "Rosecrans Memorial",
      area_code: "STJ",
      city_name: "St Joseph",
      country_code: "US"
  },
  {
      id: "CPS",
      name: "Downtown Airport",
      area_code: "STL",
      city_name: "St Louis",
      country_code: "US"
  },
  {
      id: "SUS",
      name: "Spirit Of St Louis",
      area_code: "STL",
      city_name: "St Louis",
      country_code: "US"
  },
  {
      id: "BMA",
      name: "BROMMA AIRPORT",
      area_code: "STO",
      city_name: "Stockholm",
      country_code: "SE"
  },
  {
      id: "NYO",
      name: "Skavsta Airport",
      area_code: "STO",
      city_name: "Stockholm",
      country_code: "SE"
  },
  {
      id: "STP",
      name: "St Paul Downtown Apt",
      area_code: "MSP",
      city_name: "Minneapolis/St Paul",
      country_code: "US"
  },
  {
      id: "SPB",
      name: "Charlotte Amalie SPB",
      area_code: "STT",
      city_name: "St Thomas Island",
      country_code: "VI"
  },
  {
      id: "STT",
      name: "CYRIL E. KING AIRPORT",
      area_code: "STT",
      city_name: "St Thomas Island",
      country_code: "VI"
  },
  {
      id: "STV",
      name: "Surat Airport",
      area_code: "STV",
      city_name: "Surat",
      country_code: "IN"
  },
  {
      id: "STW",
      name: "Shpakovskoye Airport",
      area_code: "STW",
      city_name: "Stavropol",
      country_code: "RU"
  },
  {
      id: "SSB",
      name: "Christiansted SPB",
      area_code: "STX",
      city_name: "St Croix Island",
      country_code: "VI"
  },
  {
      id: "STX",
      name: "Henry E. Rohlsen Airport",
      area_code: "STX",
      city_name: "St Croix Island",
      country_code: "VI"
  },
  {
      id: "STY",
      name: "Nueva Hesperides",
      area_code: "STY",
      city_name: "Salto",
      country_code: "UY"
  },
  {
      id: "SUA",
      name: "Witham Field",
      area_code: "SUA",
      city_name: "Stuart",
      country_code: "US"
  },
  {
      id: "SUE",
      name: "Door County Cherryland",
      area_code: "SUE",
      city_name: "Sturgeon Bay",
      country_code: "US"
  },
  {
      id: "SUF",
      name: "Lamezia Terme Airport",
      area_code: "SUF",
      city_name: "Lamezia Terme",
      country_code: "IT"
  },
  {
      id: "SUG",
      name: "SURIGAO AIRPORT",
      area_code: "SUG",
      city_name: "Surigao",
      country_code: "PH"
  },
  {
      id: "SUH",
      name: "Sur",
      area_code: "SUH",
      city_name: "Sur",
      country_code: "OM"
  },
  {
      id: "SUJ",
      name: "SATU MARE AIRPORT",
      area_code: "SUJ",
      city_name: "Satu Mare",
      country_code: "RO"
  },
  {
      id: "SUR",
      name: "Summer Beaver",
      area_code: "SUR",
      city_name: "Summer Beaver",
      country_code: "CA"
  },
  {
      id: "SUU",
      name: "Travis AFB",
      area_code: "SUU",
      city_name: "Fairfield",
      country_code: "US"
  },
  {
      id: "SUW",
      name: "Richard I Bong",
      area_code: "SUW",
      city_name: "Superior",
      country_code: "US"
  },
  {
      id: "SUY",
      name: "Sudureyri Airport",
      area_code: "SUY",
      city_name: "Suntar",
      country_code: "RU"
  },
  {
      id: "SVB",
      name: "SAMBAVA AIRPORT",
      area_code: "SVB",
      city_name: "Sambava",
      country_code: "MG"
  },
  {
      id: "SVD",
      name: "Joshua Airport",
      area_code: "SVD",
      city_name: "St Vincent",
      country_code: "VC"
  },
  {
      id: "SVH",
      name: "Regional",
      area_code: "SVH",
      city_name: "Statesville",
      country_code: "US"
  },
  {
      id: "SVI",
      name: "San Vicente Airport",
      area_code: "SVI",
      city_name: "San Vicente del Caguan",
      country_code: "CO"
  },
  {
      id: "SVJ",
      name: "Helle Airport",
      area_code: "SVJ",
      city_name: "Svolvaer",
      country_code: "NO"
  },
  {
      id: "SVL",
      name: "SAVONLINNA AIRPORT",
      area_code: "SVL",
      city_name: "Savonlinna",
      country_code: "FI"
  },
  {
      id: "SVU",
      name: "SAVUSAVU AIRPORT",
      area_code: "SVU",
      city_name: "Savusavu",
      country_code: "FJ"
  },
  {
      id: "SVW",
      name: "Sparrevohn",
      area_code: "SVW",
      city_name: "Sparrevohn",
      country_code: "US"
  },
  {
      id: "SVX",
      name: "Koltsovo Airport",
      area_code: "SVX",
      city_name: "Yekaterinburg",
      country_code: "RU"
  },
  {
      id: "SWH",
      name: "Swan Hill",
      area_code: "SWH",
      city_name: "Swan Hill",
      country_code: "AU"
  },
  {
      id: "SWO",
      name: "Stillwater Regional Airport",
      area_code: "SWO",
      city_name: "Stillwater",
      country_code: "US"
  },
  {
      id: "SWP",
      name: "Airport",
      area_code: "SWP",
      city_name: "Swakopmund",
      country_code: "NA"
  },
  {
      id: "SWS",
      name: "Swansea",
      area_code: "SWS",
      city_name: "Swansea",
      country_code: "GB"
  },
  {
      id: "SWU",
      name: "Air Base",
      area_code: "SWU",
      city_name: "Suwon",
      country_code: "KR"
  },
  {
      id: "SWW",
      name: "Avenger Field",
      area_code: "SWW",
      city_name: "Sweetwater",
      country_code: "US"
  },
  {
      id: "SXM",
      name: "Princess Juliana International Airport",
      area_code: "SXM",
      city_name: "St Maarten",
      country_code: "SX"
  },
  {
      id: "SXV",
      name: "Salem",
      area_code: "SXV",
      city_name: "Salem",
      country_code: "IN"
  },
  {
      id: "LBH",
      name: "Palm Beach SPB",
      area_code: "SYD",
      city_name: "Sydney",
      country_code: "AU"
  },
  {
      id: "RSE",
      name: "Rose Bay SPB",
      area_code: "SYD",
      city_name: "Sydney",
      country_code: "AU"
  },
  {
      id: "SYI",
      name: "Municipal-Bomar Field",
      area_code: "SYI",
      city_name: "Shelbyville",
      country_code: "US"
  },
  {
      id: "SYO",
      name: "SHONAI AIRPORT",
      area_code: "SYO",
      city_name: "Shonai",
      country_code: "JP"
  },
  {
      id: "SYS",
      name: "Saskylakh Airport",
      area_code: "SYS",
      city_name: "Saskylakh",
      country_code: "RU"
  },
  {
      id: "SZJ",
      name: "Siguanea",
      area_code: "SZJ",
      city_name: "Siguanea",
      country_code: "CU"
  },
  {
      id: "SZK",
      name: "SKUKUZA AIRPORT",
      area_code: "SZK",
      city_name: "Skukuza",
      country_code: "ZA"
  },
  {
      id: "SZP",
      name: "Santa Paula",
      area_code: "SZP",
      city_name: "Santa Paula",
      country_code: "US"
  },
  {
      id: "SZR",
      name: "Kolyu Ganchevo",
      area_code: "SZR",
      city_name: "Stara Zagora",
      country_code: "BG"
  },
  {
      id: "SZY",
      name: "Olsztyn-Mazury Airport",
      area_code: "SZY",
      city_name: "Szczytno",
      country_code: "PL"
  },
  {
      id: "TAH",
      name: "Whitegrass Airport",
      area_code: "TAH",
      city_name: "Tanna Island",
      country_code: "VU"
  },
  {
      id: "TAI",
      name: "International",
      area_code: "TAI",
      city_name: "Taizz",
      country_code: "YE"
  },
  {
      id: "TAR",
      name: "Grottaglie",
      area_code: "TAR",
      city_name: "Taranto",
      country_code: "IT"
  },
  {
      id: "TAT",
      name: "Tatry Airport",
      area_code: "TAT",
      city_name: "Poprad",
      country_code: "SK"
  },
  {
      id: "TAU",
      name: "Tauramena",
      area_code: "TAU",
      city_name: "Tauramena",
      country_code: "CO"
  },
  {
      id: "TAY",
      name: "Tartu Airport",
      area_code: "TAY",
      city_name: "Tartu",
      country_code: "EE"
  },
  {
      id: "TAZ",
      name: "Dashoguz Airport",
      area_code: "TAZ",
      city_name: "Dashoguz",
      country_code: "TM"
  },
  {
      id: "TBB",
      name: "Dong Tac Airport",
      area_code: "TBB",
      city_name: "Tuy Hoa",
      country_code: "VN"
  },
  {
      id: "TBC",
      name: "Tuba City",
      area_code: "TBC",
      city_name: "Tuba City",
      country_code: "US"
  },
  {
      id: "TBG",
      name: "Tabubil Airport",
      area_code: "TBG",
      city_name: "Tabubil",
      country_code: "PG"
  },
  {
      id: "TBI",
      name: "New Bight Airport",
      area_code: "TBI",
      city_name: "The Bight",
      country_code: "BS"
  },
  {
      id: "TBJ",
      name: "A?n Draham Airport",
      area_code: "TBJ",
      city_name: "Tabarka",
      country_code: "TN"
  },
  {
      id: "TBN",
      name: "Waynesville St. Robert Regional Airport",
      area_code: "TBN",
      city_name: "Fort Leonard Wood",
      country_code: "US"
  },
  {
      id: "TBP",
      name: "Tumbes Airport",
      area_code: "TBP",
      city_name: "Tumbes",
      country_code: "PE"
  },
  {
      id: "TBT",
      name: "Tabatinga International Airport",
      area_code: "TBT",
      city_name: "Tabatinga",
      country_code: "BR"
  },
  {
      id: "TBW",
      name: "Donskoye Airport",
      area_code: "TBW",
      city_name: "Tambov",
      country_code: "RU"
  },
  {
      id: "TCA",
      name: "Tennant Creek Airport",
      area_code: "TCA",
      city_name: "Tennant Creek",
      country_code: "AU"
  },
  {
      id: "TCB",
      name: "Treasure Cay Airport",
      area_code: "TCB",
      city_name: "Treasure Cay",
      country_code: "BS"
  },
  {
      id: "TCD",
      name: "TARAPACA AIRPORT",
      area_code: "TCD",
      city_name: "Tarapaca",
      country_code: "CO"
  },
  {
      id: "TCE",
      name: "Delta Dunarii",
      area_code: "TCE",
      city_name: "Tulcea",
      country_code: "RO"
  },
  {
      id: "TCL",
      name: "Regional",
      area_code: "TCL",
      city_name: "Tuscaloosa",
      country_code: "US"
  },
  {
      id: "TCN",
      name: "Tehuacan",
      area_code: "TCN",
      city_name: "Tehuacan",
      country_code: "MX"
  },
  {
      id: "TCP",
      name: "International",
      area_code: "TCP",
      city_name: "Taba",
      country_code: "EG"
  },
  {
      id: "TCR",
      name: "TUTICORIN AIRPORT",
      area_code: "TCR",
      city_name: "Tuticorin",
      country_code: "IN"
  },
  {
      id: "TCU",
      name: "Thaba Nchu",
      area_code: "TCU",
      city_name: "Thaba Nchu",
      country_code: "ZA"
  },
  {
      id: "TCW",
      name: "Tocumwal",
      area_code: "TCW",
      city_name: "Tocumwal",
      country_code: "AU"
  },
  {
      id: "TDK",
      name: "Taldykorgan Airport",
      area_code: "TDK",
      city_name: "Taldykorgan",
      country_code: "KZ"
  },
  {
      id: "TDX",
      name: "TRAT AIRPORT",
      area_code: "TDX",
      city_name: "Trat",
      country_code: "TH"
  },
  {
      id: "TEA",
      name: "Tela",
      area_code: "TEA",
      city_name: "Tela",
      country_code: "HN"
  },
  {
      id: "TEB",
      name: "TETERBORO AIRPORT",
      area_code: "TEB",
      city_name: "Teterboro",
      country_code: "US"
  },
  {
      id: "TEC",
      name: "Telemaco Borba",
      area_code: "TEC",
      city_name: "Telemaco Borba",
      country_code: "BR"
  },
  {
      id: "TED",
      name: "Thisted",
      area_code: "TED",
      city_name: "Thisted",
      country_code: "DK"
  },
  {
      id: "TEH",
      name: "Tetlin",
      area_code: "TEH",
      city_name: "Tetlin",
      country_code: "US"
  },
  {
      id: "TEI",
      name: "Tezu",
      area_code: "TEI",
      city_name: "Tezu",
      country_code: "IN"
  },
  {
      id: "TEK",
      name: "Tatitlek",
      area_code: "TEK",
      city_name: "Tatitlek",
      country_code: "US"
  },
  {
      id: "TEQ",
      name: "Corlu Airport",
      area_code: "TEQ",
      city_name: "Tekirdag",
      country_code: "TR"
  },
  {
      id: "TER",
      name: "LAJES AIRPORT",
      area_code: "TER",
      city_name: "Terceira Island",
      country_code: "PT"
  },
  {
      id: "TET",
      name: "Matundo Airport",
      area_code: "TET",
      city_name: "Tete",
      country_code: "MZ"
  },
  {
      id: "TEU",
      name: "Manapouri",
      area_code: "TEU",
      city_name: "Te Anau",
      country_code: "NZ"
  },
  {
      id: "TEZ",
      name: "Tezpur Airport",
      area_code: "TEZ",
      city_name: "Tezpur",
      country_code: "IN"
  },
  {
      id: "TFF",
      name: "TEFE AIRPORT",
      area_code: "TFF",
      city_name: "Tefe",
      country_code: "BR"
  },
  {
      id: "TFI",
      name: "Tufi Airport",
      area_code: "TFI",
      city_name: "Tufi",
      country_code: "PG"
  },
  {
      id: "TGC",
      name: "Tanjung Manis Airport",
      area_code: "TGC",
      city_name: "Tanjung Manis",
      country_code: "MY"
  },
  {
      id: "TGD",
      name: "Podgorica Airport",
      area_code: "TGD",
      city_name: "Podgorica",
      country_code: "ME"
  },
  {
      id: "TGE",
      name: "Sharpe Field",
      area_code: "TGE",
      city_name: "Tuskegee",
      country_code: "US"
  },
  {
      id: "TGK",
      name: "Taganrog",
      area_code: "TGK",
      city_name: "Taganrog",
      country_code: "RU"
  },
  {
      id: "TGM",
      name: "Tirgu Mures Airport",
      area_code: "TGM",
      city_name: "Targu-Mures",
      country_code: "RO"
  },
  {
      id: "TGN",
      name: "Latrobe Valley",
      area_code: "TGN",
      city_name: "Traralgon",
      country_code: "AU"
  },
  {
      id: "TGP",
      name: "Podkamennaya Tunguska Airport",
      area_code: "TGP",
      city_name: "Bor",
      country_code: "RU"
  },
  {
      id: "TGT",
      name: "TANGA AIRPORT",
      area_code: "TGT",
      city_name: "Tanga",
      country_code: "TZ"
  },
  {
      id: "TGZ",
      name: "Angel Albino Corzo International Airport",
      area_code: "TGZ",
      city_name: "Tuxtla Gutierrez",
      country_code: "MX"
  },
  {
      id: "THC",
      name: "Tchien",
      area_code: "THC",
      city_name: "Tchien",
      country_code: "LR"
  },
  {
      id: "THD",
      name: "Tho Xuan Airport",
      area_code: "THD",
      city_name: "Thanh Hoa",
      country_code: "VN"
  },
  {
      id: "THE",
      name: "Senador Petronio Portella Airport",
      area_code: "THE",
      city_name: "Teresina",
      country_code: "BR"
  },
  {
      id: "THG",
      name: "Thangool",
      area_code: "THG",
      city_name: "Thangool",
      country_code: "AU"
  },
  {
      id: "THP",
      name: "Hot Springs County",
      area_code: "THP",
      city_name: "Thermopolis",
      country_code: "US"
  },
  {
      id: "THS",
      name: "SUKHOTHAI AIRPORT",
      area_code: "THS",
      city_name: "Sukhothai",
      country_code: "TH"
  },
  {
      id: "THV",
      name: "York",
      area_code: "THV",
      city_name: "York",
      country_code: "US"
  },
  {
      id: "THX",
      name: "Turukhansk Airport",
      area_code: "THX",
      city_name: "Turukhansk",
      country_code: "RU"
  },
  {
      id: "THY",
      name: "P.R. Mphephu",
      area_code: "THY",
      city_name: "Thohoyandou",
      country_code: "ZA"
  },
  {
      id: "TIF",
      name: "TAIF AIRPORT",
      area_code: "TIF",
      city_name: "Taif",
      country_code: "SA"
  },
  {
      id: "TIG",
      name: "Tingwon",
      area_code: "TIG",
      city_name: "Tingwon",
      country_code: "PG"
  },
  {
      id: "TIH",
      name: "Tikehau Airport",
      area_code: "TIH",
      city_name: "Tikehau",
      country_code: "PF"
  },
  {
      id: "TIM",
      name: "TIMIKA AIRPORT",
      area_code: "TIM",
      city_name: "Tembagapura/Timika",
      country_code: "ID"
  },
  {
      id: "TIQ",
      name: "International",
      area_code: "TIQ",
      city_name: "Tinian",
      country_code: "US"
  },
  {
      id: "TIR",
      name: "TIRUPATI AIRPORT",
      area_code: "TIR",
      city_name: "Tirupati",
      country_code: "IN"
  },
  {
      id: "TIV",
      name: "TIVAT AIRPORT",
      area_code: "TIV",
      city_name: "Tivat",
      country_code: "ME"
  },
  {
      id: "GRF",
      name: "Gray AAF",
      area_code: "TIW",
      city_name: "Tacoma",
      country_code: "US"
  },
  {
      id: "TCM",
      name: "McChord Field",
      area_code: "TIW",
      city_name: "Tacoma",
      country_code: "US"
  },
  {
      id: "TIW",
      name: "Tacoma Narrows",
      area_code: "TIW",
      city_name: "Tacoma",
      country_code: "US"
  },
  {
      id: "TIX",
      name: "Space Coast Regional",
      area_code: "TIX",
      city_name: "Titusville",
      country_code: "US"
  },
  {
      id: "TIZ",
      name: "Tari Airport",
      area_code: "TIZ",
      city_name: "Tari",
      country_code: "PG"
  },
  {
      id: "TJA",
      name: "Tarija Airport",
      area_code: "TJA",
      city_name: "Tarija",
      country_code: "BO"
  },
  {
      id: "TJL",
      name: "Tres Lagoas Airport",
      area_code: "TJL",
      city_name: "Tres Lagoas",
      country_code: "BR"
  },
  {
      id: "TJQ",
      name: "H.A.S. Hanandjoeddin Airport",
      area_code: "TJQ",
      city_name: "Tanjung Pandan",
      country_code: "ID"
  },
  {
      id: "TJU",
      name: "Kulob Airport",
      area_code: "TJU",
      city_name: "Kulob",
      country_code: "TJ"
  },
  {
      id: "TJV",
      name: "Thanjavur",
      area_code: "TJV",
      city_name: "Thanjavur",
      country_code: "IN"
  },
  {
      id: "TKA",
      name: "Talkeetna",
      area_code: "TKA",
      city_name: "Talkeetna",
      country_code: "US"
  },
  {
      id: "TKF",
      name: "Truckee-Tahoe",
      area_code: "TKF",
      city_name: "Truckee",
      country_code: "US"
  },
  {
      id: "TKG",
      name: "Radin Inten II Airport",
      area_code: "TKG",
      city_name: "Bandar Lampung",
      country_code: "ID"
  },
  {
      id: "TKI",
      name: "Sea Plane Base",
      area_code: "TKI",
      city_name: "Tokeen",
      country_code: "US"
  },
  {
      id: "TKJ",
      name: "Tok Junction Airport",
      area_code: "TKJ",
      city_name: "Tok",
      country_code: "US"
  },
  {
      id: "TKK",
      name: "Chuuk International Airport",
      area_code: "TKK",
      city_name: "Chuuk",
      country_code: "FM"
  },
  {
      id: "TKL",
      name: "Sea Plane Base",
      area_code: "TKL",
      city_name: "Taku Lodge",
      country_code: "US"
  },
  {
      id: "TKN",
      name: "Tokunoshima Airport",
      area_code: "TKN",
      city_name: "Tokunoshima",
      country_code: "JP"
  },
  {
      id: "TLC",
      name: "Toluca Airport",
      area_code: "MEX",
      city_name: "Mexico City",
      country_code: "MX"
  },
  {
      id: "TLD",
      name: "Tuli Lodge",
      area_code: "TLD",
      city_name: "Tuli Lodge",
      country_code: "BW"
  },
  {
      id: "TLF",
      name: "Telida",
      area_code: "TLF",
      city_name: "Telida",
      country_code: "US"
  },
  {
      id: "TLM",
      name: "Zenata Airport",
      area_code: "TLM",
      city_name: "Tlemcen",
      country_code: "DZ"
  },
  {
      id: "TLR",
      name: "Mefford Field",
      area_code: "TLR",
      city_name: "Tulare",
      country_code: "US"
  },
  {
      id: "TLU",
      name: "Tolu airport",
      area_code: "TLU",
      city_name: "Tolu",
      country_code: "CO"
  },
  {
      id: "SDV",
      name: "SDE DOV AIRPORT",
      area_code: "TLV",
      city_name: "Tel Aviv-Yafo",
      country_code: "IL"
  },
  {
      id: "TMA",
      name: "Henry Tift Myers",
      area_code: "TMA",
      city_name: "Tifton",
      country_code: "US"
  },
  {
      id: "TMC",
      name: "Tambolaka Airport",
      area_code: "TMC",
      city_name: "Tambolaka",
      country_code: "ID"
  },
  {
      id: "TME",
      name: "Gabriel Vargas Santos Airport",
      area_code: "TME",
      city_name: "Tame",
      country_code: "CO"
  },
  {
      id: "TMF",
      name: "Thimarafushi Airport",
      area_code: "TMF",
      city_name: "Thimarafushi",
      country_code: "MV"
  },
  {
      id: "TMG",
      name: "Tomanggong",
      area_code: "TMG",
      city_name: "Tomanggong",
      country_code: "MY"
  },
  {
      id: "TMJ",
      name: "TERMEZ AIRPORT",
      area_code: "TMJ",
      city_name: "Termez",
      country_code: "UZ"
  },
  {
      id: "TML",
      name: "TAMALE AIRPORT",
      area_code: "TML",
      city_name: "Tamale",
      country_code: "GH"
  },
  {
      id: "TMM",
      name: "Toamasina Airport",
      area_code: "TMM",
      city_name: "Toamasina",
      country_code: "MG"
  },
  {
      id: "TMT",
      name: "Trombetas",
      area_code: "TMT",
      city_name: "Trombetas",
      country_code: "BR"
  },
  {
      id: "TMU",
      name: "TAMBOR AIRPORT",
      area_code: "TMU",
      city_name: "Tambor",
      country_code: "CR"
  },
  {
      id: "TMZ",
      name: "Thames",
      area_code: "TMZ",
      city_name: "Thames",
      country_code: "NZ"
  },
  {
      id: "TND",
      name: "Alberto Delgado",
      area_code: "TND",
      city_name: "Trinidad",
      country_code: "CU"
  },
  {
      id: "TNF",
      name: "Toussus-le-Noble",
      area_code: "TNF",
      city_name: "Toussus-le-Noble",
      country_code: "FR"
  },
  {
      id: "TNI",
      name: "Satna",
      area_code: "TNI",
      city_name: "Satna",
      country_code: "IN"
  },
  {
      id: "TNO",
      name: "TAMARINDO AIRPORT",
      area_code: "TNO",
      city_name: "Tamarindo",
      country_code: "CR"
  },
  {
      id: "TNU",
      name: "Municipal",
      area_code: "TNU",
      city_name: "Newton",
      country_code: "US"
  },
  {
      id: "TNW",
      name: "Jumandy",
      area_code: "TNW",
      city_name: "Tena",
      country_code: "EC"
  },
  {
      id: "TOB",
      name: "Tobruk Airport",
      area_code: "TOB",
      city_name: "Tobruk",
      country_code: "LY"
  },
  {
      id: "TOG",
      name: "Togiak Airport",
      area_code: "TOG",
      city_name: "Togiak",
      country_code: "US"
  },
  {
      id: "TOI",
      name: "Municipal",
      area_code: "TOI",
      city_name: "Troy",
      country_code: "US"
  },
  {
      id: "TDZ",
      name: "Executive",
      area_code: "TOL",
      city_name: "Toledo",
      country_code: "US"
  },
  {
      id: "FOE",
      name: "Forbes Field",
      area_code: "TOP",
      city_name: "Topeka",
      country_code: "US"
  },
  {
      id: "TOR",
      name: "Municipal",
      area_code: "TOR",
      city_name: "Torrington",
      country_code: "US"
  },
  {
      id: "RAD",
      name: "Road Town SPB",
      area_code: "TOV",
      city_name: "Tortola",
      country_code: "VG"
  },
  {
      id: "TOX",
      name: "Tobolsk",
      area_code: "TOX",
      city_name: "Tobolsk",
      country_code: "RU"
  },
  {
      id: "KYO",
      name: "North Aero Park",
      area_code: "TPA",
      city_name: "Tampa",
      country_code: "US"
  },
  {
      id: "MCF",
      name: "MacDill AFB",
      area_code: "TPA",
      city_name: "Tampa",
      country_code: "US"
  },
  {
      id: "TPF",
      name: "Peter O. Knight",
      area_code: "TPA",
      city_name: "Tampa",
      country_code: "US"
  },
  {
      id: "TPH",
      name: "Tonopah Arpt",
      area_code: "TPH",
      city_name: "Tonopah",
      country_code: "US"
  },
  {
      id: "XSD",
      name: "Test Range",
      area_code: "TPH",
      city_name: "Tonopah",
      country_code: "US"
  },
  {
      id: "TPL",
      name: "Draughon-Miller",
      area_code: "TPL",
      city_name: "Temple",
      country_code: "US"
  },
  {
      id: "TPP",
      name: "Tarapoto Airport",
      area_code: "TPP",
      city_name: "Tarapoto",
      country_code: "PE"
  },
  {
      id: "TPQ",
      name: "Tepic Airport",
      area_code: "TPQ",
      city_name: "Tepic",
      country_code: "MX"
  },
  {
      id: "TPR",
      name: "Tom Price",
      area_code: "TPR",
      city_name: "Tom Price",
      country_code: "AU"
  },
  {
      id: "TPS",
      name: "Birgi Airport",
      area_code: "TPS",
      city_name: "Trapani",
      country_code: "IT"
  },
  {
      id: "TQL",
      name: "Tarko-Sale",
      area_code: "TQL",
      city_name: "Tarko-Sale",
      country_code: "RU"
  },
  {
      id: "TRB",
      name: "Gonzalo Mejia",
      area_code: "TRB",
      city_name: "Turbo",
      country_code: "CO"
  },
  {
      id: "TRH",
      name: "Trona",
      area_code: "TRH",
      city_name: "Trona",
      country_code: "US"
  },
  {
      id: "TRL",
      name: "Municipal",
      area_code: "TRL",
      city_name: "Terrell",
      country_code: "US"
  },
  {
      id: "TRM",
      name: "Thermal",
      area_code: "TRM",
      city_name: "Thermal",
      country_code: "US"
  },
  {
      id: "TRZ",
      name: "Tiruchirapalli Airport",
      area_code: "TRZ",
      city_name: "Tiruchchirappalli",
      country_code: "IN"
  },
  {
      id: "TSF",
      name: "Treviso Airport",
      area_code: "VCE",
      city_name: "Venice",
      country_code: "IT"
  },
  {
      id: "TSM",
      name: "Regional",
      area_code: "TSM",
      city_name: "Taos",
      country_code: "US"
  },
  {
      id: "TST",
      name: "Trang Airport",
      area_code: "TST",
      city_name: "Trang",
      country_code: "TH"
  },
  {
      id: "TTB",
      name: "Arbatax",
      area_code: "TTB",
      city_name: "Tortoli",
      country_code: "IT"
  },
  {
      id: "TTD",
      name: "Troutdale",
      area_code: "PDX",
      city_name: "Portland",
      country_code: "US"
  },
  {
      id: "TTQ",
      name: "Tortuquero Airport",
      area_code: "TTQ",
      city_name: "Tortuguero",
      country_code: "CR"
  },
  {
      id: "TTX",
      name: "Truscott-Mungalalu",
      area_code: "TTX",
      city_name: "Truscott-Mungalalu",
      country_code: "AU"
  },
  {
      id: "TUA",
      name: "Luis A. Mantilla Intl",
      area_code: "TUA",
      city_name: "Tulcan",
      country_code: "EC"
  },
  {
      id: "TUI",
      name: "TURAIF AIRPORT",
      area_code: "TUI",
      city_name: "Turaif",
      country_code: "SA"
  },
  {
      id: "TUK",
      name: "TURBAT AIRPORT",
      area_code: "TUK",
      city_name: "Turbat",
      country_code: "PK"
  },
  {
      id: "RVS",
      name: "Richard Lloyd Jones Jr",
      area_code: "TUL",
      city_name: "Tulsa",
      country_code: "US"
  },
  {
      id: "OLV",
      name: "Olive Branch",
      area_code: "OLV",
      city_name: "Olive Branch",
      country_code: "US"
  },
  {
      id: "AVW",
      name: "Marana Regional",
      area_code: "TUS",
      city_name: "Tucson",
      country_code: "US"
  },
  {
      id: "DMA",
      name: "Davis-Monthan AFB",
      area_code: "TUS",
      city_name: "Tucson",
      country_code: "US"
  },
  {
      id: "TUY",
      name: "Tulum",
      area_code: "TUY",
      city_name: "Tulum",
      country_code: "MX"
  },
  {
      id: "TVL",
      name: "Lake Tahoe",
      area_code: "TVL",
      city_name: "South Lake Tahoe",
      country_code: "US"
  },
  {
      id: "TVU",
      name: "Matei Airport",
      area_code: "TVU",
      city_name: "Taveuni Island",
      country_code: "FJ"
  },
  {
      id: "TWA",
      name: "TWIN HILLS AIRPORT",
      area_code: "TWA",
      city_name: "Twin Hills",
      country_code: "US"
  },
  {
      id: "WTB",
      name: "Brisbane West Wellcamp Airport",
      area_code: "TWB",
      city_name: "Toowoomba",
      country_code: "AU"
  },
  {
      id: "TWE",
      name: "Taylor",
      area_code: "TWE",
      city_name: "Taylor",
      country_code: "US"
  },
  {
      id: "TYE",
      name: "Tyonek",
      area_code: "TYE",
      city_name: "Tyonek",
      country_code: "US"
  },
  {
      id: "TYF",
      name: "TORSBY AIRPORT",
      area_code: "TYF",
      city_name: "Torsby",
      country_code: "SE"
  },
  {
      id: "OKO",
      name: "Yokota AB",
      area_code: "TYO",
      city_name: "Tokyo",
      country_code: "JP"
  },
  {
      id: "TYZ",
      name: "Taylor",
      area_code: "TYZ",
      city_name: "Taylor",
      country_code: "US"
  },
  {
      id: "TZL",
      name: "Tuzla International Airport",
      area_code: "TZL",
      city_name: "Tuzla",
      country_code: "BA"
  },
  {
      id: "TZM",
      name: "Cupul",
      area_code: "TZM",
      city_name: "Tizimin",
      country_code: "MX"
  },
  {
      id: "TZN",
      name: "Congo Town",
      area_code: "TZN",
      city_name: "South Andros",
      country_code: "BS"
  },
  {
      id: "UAC",
      name: "San Luis Rio Colorado",
      area_code: "UAC",
      city_name: "San Luis Rio Colorado",
      country_code: "MX"
  },
  {
      id: "UAK",
      name: "Narsarsuaq Airport",
      area_code: "UAK",
      city_name: "Narsarsuaq",
      country_code: "GL"
  },
  {
      id: "UAP",
      name: "UA POU AIRPORT",
      area_code: "UAP",
      city_name: "Ua Pou",
      country_code: "PF"
  },
  {
      id: "UAS",
      name: "Samburu Airport",
      area_code: "UAS",
      city_name: "Samburu",
      country_code: "KE"
  },
  {
      id: "CBM",
      name: "AFB",
      area_code: "UBS",
      city_name: "Columbus",
      country_code: "US"
  },
  {
      id: "UCK",
      name: "Lutsk",
      area_code: "UCK",
      city_name: "Lutsk",
      country_code: "UA"
  },
  {
      id: "UDD",
      name: "Bermuda Dunes",
      area_code: "PSP",
      city_name: "Palm Springs",
      country_code: "US"
  },
  {
      id: "UDE",
      name: "Volkel AB",
      area_code: "UDE",
      city_name: "Uden",
      country_code: "NL"
  },
  {
      id: "UDJ",
      name: "International",
      area_code: "UDJ",
      city_name: "Uzhhorod",
      country_code: "UA"
  },
  {
      id: "UDN",
      name: "Campoformido",
      area_code: "UDN",
      city_name: "Udine",
      country_code: "IT"
  },
  {
      id: "UDR",
      name: "Maharana Pratap Airport",
      area_code: "UDR",
      city_name: "Udaipur",
      country_code: "IN"
  },
  {
      id: "UEE",
      name: "Queenstown",
      area_code: "UEE",
      city_name: "Queenstown",
      country_code: "AU"
  },
  {
      id: "UEO",
      name: "Kumejima Airport",
      area_code: "UEO",
      city_name: "Kumejima",
      country_code: "JP"
  },
  {
      id: "UGC",
      name: "Urgench Airport",
      area_code: "UGC",
      city_name: "Urgench",
      country_code: "UZ"
  },
  {
      id: "UGN",
      name: "Regional",
      area_code: "UGN",
      city_name: "Waukegan",
      country_code: "US"
  },
  {
      id: "UHE",
      name: "Kunovice",
      area_code: "UHE",
      city_name: "Uherske Hradiste",
      country_code: "CZ"
  },
  {
      id: "UIB",
      name: "El Carano Airport",
      area_code: "UIB",
      city_name: "Quibdo",
      country_code: "CO"
  },
  {
      id: "UIH",
      name: "Phu Cat Airport",
      area_code: "UIH",
      city_name: "Qui Nhon",
      country_code: "VN"
  },
  {
      id: "UIR",
      name: "Quirindi",
      area_code: "UIR",
      city_name: "Quirindi",
      country_code: "AU"
  },
  {
      id: "UKA",
      name: "Ukunda Airport",
      area_code: "UKA",
      city_name: "Ukunda",
      country_code: "KE"
  },
  {
      id: "UKI",
      name: "Municipal",
      area_code: "UKI",
      city_name: "Ukiah",
      country_code: "US"
  },
  {
      id: "UKK",
      name: "Ust-Kamenogorsk Airport",
      area_code: "UKK",
      city_name: "Ust-Kamenogorsk",
      country_code: "KZ"
  },
  {
      id: "UKS",
      name: "Belbek",
      area_code: "UKS",
      city_name: "Sevastopol",
      country_code: "UA"
  },
  {
      id: "UKT",
      name: "Quakertown",
      area_code: "UKT",
      city_name: "Quakertown",
      country_code: "US"
  },
  {
      id: "UKX",
      name: "UST-KUT AIRPORT",
      area_code: "UKX",
      city_name: "Ust-Kut",
      country_code: "RU"
  },
  {
      id: "ULH",
      name: "Prince Abdul Majeed Bin Abdulaziz Airport",
      area_code: "ULH",
      city_name: "Al Ula",
      country_code: "SA"
  },
  {
      id: "ULK",
      name: "Lensk Airport",
      area_code: "ULK",
      city_name: "Lensk",
      country_code: "RU"
  },
  {
      id: "ULM",
      name: "Municipal",
      area_code: "ULM",
      city_name: "New Ulm",
      country_code: "US"
  },
  {
      id: "ULP",
      name: "QUILPIE AIRPORT",
      area_code: "ULP",
      city_name: "Quilpie",
      country_code: "AU"
  },
  {
      id: "ULV",
      name: "Baratayevka Airport",
      area_code: "ULY",
      city_name: "Ulyanovsk",
      country_code: "RU"
  },
  {
      id: "ULY",
      name: "Vostochny",
      area_code: "ULY",
      city_name: "Ulyanovsk",
      country_code: "RU"
  },
  {
      id: "UME",
      name: "Umea Airport",
      area_code: "UME",
      city_name: "Umea",
      country_code: "SE"
  },
  {
      id: "UMM",
      name: "Summit",
      area_code: "UMM",
      city_name: "Summit",
      country_code: "US"
  },
  {
      id: "UMT",
      name: "Umiat",
      area_code: "UMT",
      city_name: "Umiat",
      country_code: "US"
  },
  {
      id: "UMU",
      name: "Orlando de Carvalho",
      area_code: "UMU",
      city_name: "Umuarama",
      country_code: "BR"
  },
  {
      id: "UMY",
      name: "Sumy",
      area_code: "UMY",
      city_name: "Sumy",
      country_code: "UA"
  },
  {
      id: "UNG",
      name: "Kiunga Airport",
      area_code: "UNG",
      city_name: "Kiunga",
      country_code: "PG"
  },
  {
      id: "UNI",
      name: "Union Island",
      area_code: "UNI",
      city_name: "Union Island",
      country_code: "VC"
  },
  {
      id: "UNK",
      name: "UNALAKLEET AIRPORT",
      area_code: "UNK",
      city_name: "Unalakleet",
      country_code: "US"
  },
  {
      id: "UNN",
      name: "RANONG AIRPORT",
      area_code: "UNN",
      city_name: "Ranong",
      country_code: "TH"
  },
  {
      id: "UOX",
      name: "University-Oxford",
      area_code: "UOX",
      city_name: "Oxford",
      country_code: "US"
  },
  {
      id: "UPN",
      name: "Uruapan Airport",
      area_code: "UPN",
      city_name: "Uruapan",
      country_code: "MX"
  },
  {
      id: "URA",
      name: "Oral Ak Zhol Airport",
      area_code: "URA",
      city_name: "Uralsk",
      country_code: "KZ"
  },
  {
      id: "URE",
      name: "Kuressaare Airport",
      area_code: "URE",
      city_name: "Kuressaare",
      country_code: "EE"
  },
  {
      id: "URG",
      name: "Rubem Berta International Airport",
      area_code: "URG",
      city_name: "Uruguaiana",
      country_code: "BR"
  },
  {
      id: "URJ",
      name: "Uraj Airport",
      area_code: "URJ",
      city_name: "Uray",
      country_code: "RU"
  },
  {
      id: "URO",
      name: "Boos Airport",
      area_code: "URO",
      city_name: "Rouen",
      country_code: "FR"
  },
  {
      id: "URR",
      name: "Urrao",
      area_code: "URR",
      city_name: "Urrao",
      country_code: "CO"
  },
  {
      id: "URS",
      name: "Vostochny Airport",
      area_code: "URS",
      city_name: "Kursk",
      country_code: "RU"
  },
  {
      id: "URY",
      name: "GURAYAT AIRPORT",
      area_code: "URY",
      city_name: "Gurayat",
      country_code: "SA"
  },
  {
      id: "USA",
      name: "Concord",
      area_code: "USA",
      city_name: "Concord",
      country_code: "US"
  },
  {
      id: "USH",
      name: "Malvinas Argentinas International Airport",
      area_code: "USH",
      city_name: "Ushuaia",
      country_code: "AR"
  },
  {
      id: "USK",
      name: "USINSK AIRPORT",
      area_code: "USK",
      city_name: "Usinsk",
      country_code: "RU"
  },
  {
      id: "USL",
      name: "Useless Loop",
      area_code: "USL",
      city_name: "Useless Loop",
      country_code: "AU"
  },
  {
      id: "USR",
      name: "Ust-Nera Airport",
      area_code: "USR",
      city_name: "Ust-Nera",
      country_code: "RU"
  },
  {
      id: "USS",
      name: "Sancti Spiritus",
      area_code: "USS",
      city_name: "Sancti Spiritus",
      country_code: "CU"
  },
  {
      id: "UST",
      name: "Northeast Florida Regional Airport",
      area_code: "UST",
      city_name: "St Augustine",
      country_code: "US"
  },
  {
      id: "USU",
      name: "Francisco B.Reyes Airport",
      area_code: "USU",
      city_name: "Busuanga",
      country_code: "PH"
  },
  {
      id: "UTA",
      name: "Mutare",
      area_code: "UTA",
      city_name: "Mutare",
      country_code: "ZW"
  },
  {
      id: "UTC",
      name: "Soesterberg",
      area_code: "UTC",
      city_name: "Utrecht",
      country_code: "NL"
  },
  {
      id: "UTH",
      name: "Udon Thani International Airport",
      area_code: "UTH",
      city_name: "Udon Thani",
      country_code: "TH"
  },
  {
      id: "UTI",
      name: "Utti",
      area_code: "UTI",
      city_name: "Utti",
      country_code: "FI"
  },
  {
      id: "UTM",
      name: "Municipal",
      area_code: "UTM",
      city_name: "Tunica",
      country_code: "US"
  },
  {
      id: "UTN",
      name: "UPINGTON AIRPORT",
      area_code: "UTN",
      city_name: "Upington",
      country_code: "ZA"
  },
  {
      id: "UTP",
      name: "Utapao Airport",
      area_code: "UTP",
      city_name: "U-Tapao",
      country_code: "TH"
  },
  {
      id: "UTT",
      name: "UMTATA AIRPORT",
      area_code: "UTT",
      city_name: "Umtata",
      country_code: "ZA"
  },
  {
      id: "UTW",
      name: "Queenstown",
      area_code: "UTW",
      city_name: "Queenstown",
      country_code: "ZA"
  },
  {
      id: "UUD",
      name: "Baikal International Airport",
      area_code: "UUD",
      city_name: "Ulan-Ude",
      country_code: "RU"
  },
  {
      id: "UUK",
      name: "Ugnu-Kuparuk",
      area_code: "UUK",
      city_name: "Kuparuk",
      country_code: "US"
  },
  {
      id: "UUS",
      name: "Yuzhno-Sakhalinsk Airport",
      area_code: "UUS",
      city_name: "Yuzhno-Sakhalinsk",
      country_code: "RU"
  },
  {
      id: "UVF",
      name: "Hewanorra International Airport",
      area_code: "SLU",
      city_name: "St Lucia",
      country_code: "LC"
  },
  {
      id: "UVL",
      name: "Kharga",
      area_code: "UVL",
      city_name: "Kharga",
      country_code: "EG"
  },
  {
      id: "WIE",
      name: "AAF",
      area_code: "WIE",
      city_name: "Wiesbaden",
      country_code: "DE"
  },
  {
      id: "UZC",
      name: "Ponikve",
      area_code: "UZC",
      city_name: "Uzice",
      country_code: "RS"
  },
  {
      id: "UZR",
      name: "Urzhar Airport",
      area_code: "UZR",
      city_name: "Urdzhar",
      country_code: "KZ"
  },
  {
      id: "VAF",
      name: "Chabeuil",
      area_code: "VAF",
      city_name: "Valence",
      country_code: "FR"
  },
  {
      id: "VAG",
      name: "Major Brigadeiro Trompowsky Airport",
      area_code: "VAG",
      city_name: "Varginha",
      country_code: "BR"
  },
  {
      id: "VAI",
      name: "Vanimo Airport",
      area_code: "VAI",
      city_name: "Vanimo",
      country_code: "PG"
  },
  {
      id: "VAM",
      name: "Maamigili Airport",
      area_code: "VAM",
      city_name: "Maamigili Island",
      country_code: "MV"
  },
  {
      id: "VAW",
      name: "Vardoe Airport",
      area_code: "VAW",
      city_name: "Vardo",
      country_code: "NO"
  },
  {
      id: "VCA",
      name: "Can tho International Airport",
      area_code: "VCA",
      city_name: "Can Tho",
      country_code: "VN"
  },
  {
      id: "VCL",
      name: "Chu Lai Airport",
      area_code: "VCL",
      city_name: "Chu Lai",
      country_code: "VN"
  },
  {
      id: "VCS",
      name: "Con Dao Airport",
      area_code: "VCS",
      city_name: "Con Dao Island",
      country_code: "VN"
  },
  {
      id: "VCV",
      name: "S.Calif. Logistics Apt",
      area_code: "VCV",
      city_name: "Victorville",
      country_code: "US"
  },
  {
      id: "VDA",
      name: "OVDA AIRPORT",
      area_code: "VDA",
      city_name: "Ovda",
      country_code: "IL"
  },
  {
      id: "VDB",
      name: "Leirin",
      area_code: "VDB",
      city_name: "Fagernes",
      country_code: "NO"
  },
  {
      id: "VDC",
      name: "Vitoria Da Conquista Airport",
      area_code: "VDC",
      city_name: "Vitoria da Conquista",
      country_code: "BR"
  },
  {
      id: "VDH",
      name: "Dong Hoi Airport",
      area_code: "VDH",
      city_name: "Dong Hoi",
      country_code: "VN"
  },
  {
      id: "VDI",
      name: "Regional",
      area_code: "VDI",
      city_name: "Vidalia",
      country_code: "US"
  },
  {
      id: "VEL",
      name: "Vernal Regional Airport",
      area_code: "VEL",
      city_name: "Vernal",
      country_code: "US"
  },
  {
      id: "VEY",
      name: "Vestmannaeyjar",
      area_code: "VEY",
      city_name: "Vestmannaeyjar",
      country_code: "IS"
  },
  {
      id: "VGA",
      name: "VIJAYAWADA AIRPORT",
      area_code: "VGA",
      city_name: "Vijayawada",
      country_code: "IN"
  },
  {
      id: "VGD",
      name: "Vologda",
      area_code: "VGD",
      city_name: "Vologda",
      country_code: "RU"
  },
  {
      id: "VGZ",
      name: "VILLAGARZON AIRPORT",
      area_code: "VGZ",
      city_name: "Villagarzon",
      country_code: "CO"
  },
  {
      id: "VHM",
      name: "VILHELMINA AIRPORT",
      area_code: "VHM",
      city_name: "Vilhelmina",
      country_code: "SE"
  },
  {
      id: "VHN",
      name: "Culberson County",
      area_code: "VHN",
      city_name: "Van Horn",
      country_code: "US"
  },
  {
      id: "VHY",
      name: "Charmeil",
      area_code: "VHY",
      city_name: "Vichy",
      country_code: "FR"
  },
  {
      id: "VIB",
      name: "Villa Constitucion",
      area_code: "VIB",
      city_name: "Villa Constitucion",
      country_code: "MX"
  },
  {
      id: "VIC",
      name: "Airport",
      area_code: "VIC",
      city_name: "Vicenza",
      country_code: "IT"
  },
  {
      id: "VIG",
      name: "Juan Pablo Perez Alfonso Airport",
      area_code: "VIG",
      city_name: "El Vigia",
      country_code: "VE"
  },
  {
      id: "VII",
      name: "Vinh City Airport",
      area_code: "VII",
      city_name: "Vinh",
      country_code: "VN"
  },
  {
      id: "VIJ",
      name: "Virgin Gorda Airport",
      area_code: "VIJ",
      city_name: "Virgin Gorda",
      country_code: "VG"
  },
  {
      id: "VIN",
      name: "Vinnytsia Airport",
      area_code: "VIN",
      city_name: "Vinnytsia",
      country_code: "UA"
  },
  {
      id: "VIS",
      name: "Municipal",
      area_code: "VIS",
      city_name: "Visalia",
      country_code: "US"
  },
  {
      id: "VJI",
      name: "Virginia Highlands",
      area_code: "VJI",
      city_name: "Abingdon",
      country_code: "US"
  },
  {
      id: "VKG",
      name: "RACH GIA AIRPORT",
      area_code: "VKG",
      city_name: "Rach Gia",
      country_code: "VN"
  },
  {
      id: "VKS",
      name: "Municipal",
      area_code: "VKS",
      city_name: "Vicksburg",
      country_code: "US"
  },
  {
      id: "VAD",
      name: "Moody AFB",
      area_code: "VLD",
      city_name: "Valdosta",
      country_code: "US"
  },
  {
      id: "VLD",
      name: "Valdosta Regional Airport",
      area_code: "VLD",
      city_name: "Valdosta",
      country_code: "US"
  },
  {
      id: "VLE",
      name: "Valle",
      area_code: "GCN",
      city_name: "Grand Canyon",
      country_code: "US"
  },
  {
      id: "VLG",
      name: "Villa Gesell Airport",
      area_code: "VLG",
      city_name: "Villa Gesell",
      country_code: "AR"
  },
  {
      id: "VLR",
      name: "Vallenar",
      area_code: "VLR",
      city_name: "Vallenar",
      country_code: "CL"
  },
  {
      id: "VLV",
      name: "Valera Airport",
      area_code: "VLV",
      city_name: "Valera",
      country_code: "VE"
  },
  {
      id: "VME",
      name: "Villa Reynolds",
      area_code: "VME",
      city_name: "Villa Mercedes",
      country_code: "AR"
  },
  {
      id: "VMI",
      name: "Dr.Juan Plate",
      area_code: "VMI",
      city_name: "Puerto Valle Mi",
      country_code: "PY"
  },
  {
      id: "VMU",
      name: "Baimuru",
      area_code: "VMU",
      city_name: "Baimuru",
      country_code: "PG"
  },
  {
      id: "VNC",
      name: "Municipal",
      area_code: "VNC",
      city_name: "Venice",
      country_code: "US"
  },
  {
      id: "VNT",
      name: "Ventspils",
      area_code: "VNT",
      city_name: "Ventspils",
      country_code: "LV"
  },
  {
      id: "VNX",
      name: "Vilanculos Airport",
      area_code: "VNX",
      city_name: "Vilankulos",
      country_code: "MZ"
  },
  {
      id: "VOG",
      name: "Volgograd Airport",
      area_code: "VOG",
      city_name: "Volgograd",
      country_code: "RU"
  },
  {
      id: "VOL",
      name: "NEA ANCHIALOS AIRPORT",
      area_code: "VOL",
      city_name: "Volos",
      country_code: "GR"
  },
  {
      id: "VOZ",
      name: "Voronezh Airport",
      area_code: "VOZ",
      city_name: "Voronezh",
      country_code: "RU"
  },
  {
      id: "EGI",
      name: "Duke Field",
      area_code: "CEW",
      city_name: "Crestview",
      country_code: "US"
  },
  {
      id: "VPY",
      name: "CHIMOIO AIRPORT",
      area_code: "VPY",
      city_name: "Chimoio",
      country_code: "MZ"
  },
  {
      id: "VPZ",
      name: "Porter Co. Municipal",
      area_code: "VPZ",
      city_name: "Valparaiso",
      country_code: "US"
  },
  {
      id: "VQS",
      name: "Antonio Rivera Rodriguez Airport",
      area_code: "VQS",
      city_name: "Vieques",
      country_code: "PR"
  },
  {
      id: "VRA",
      name: "Juan Gualberto Gomez Airport",
      area_code: "VRA",
      city_name: "Varadero",
      country_code: "CU"
  },
  {
      id: "VRB",
      name: "Vero Beach",
      area_code: "VRB",
      city_name: "Vero Beach",
      country_code: "US"
  },
  {
      id: "VRK",
      name: "Varkaus",
      area_code: "VRK",
      city_name: "Varkaus",
      country_code: "FI"
  },
  {
      id: "VRL",
      name: "Vila Real Airport",
      area_code: "VRL",
      city_name: "Vila Real",
      country_code: "PT"
  },
  {
      id: "VRO",
      name: "Kawama",
      area_code: "VRA",
      city_name: "Varadero",
      country_code: "CU"
  },
  {
      id: "VSA",
      name: "Carlos Rovirosa Perez International Airport",
      area_code: "VSA",
      city_name: "Villahermosa",
      country_code: "MX"
  },
  {
      id: "VSG",
      name: "International",
      area_code: "VSG",
      city_name: "Luhansk",
      country_code: "UA"
  },
  {
      id: "VTB",
      name: "Vitebsk",
      area_code: "VTB",
      city_name: "Vitebsk",
      country_code: "BY"
  },
  {
      id: "VTZ",
      name: "Visakhapatnam Airport",
      area_code: "VTZ",
      city_name: "Vishakhapatnam",
      country_code: "IN"
  },
  {
      id: "VUP",
      name: "Valledupar Airport",
      area_code: "VUP",
      city_name: "Valledupar",
      country_code: "CO"
  },
  {
      id: "VVC",
      name: "La Vanguardia Airport",
      area_code: "VVC",
      city_name: "Villavicencio",
      country_code: "CO"
  },
  {
      id: "VVZ",
      name: "Illizi Airport",
      area_code: "VVZ",
      city_name: "Illizi",
      country_code: "DZ"
  },
  {
      id: "VXC",
      name: "Lichinga Airport",
      area_code: "VXC",
      city_name: "Lichinga",
      country_code: "MZ"
  },
  {
      id: "VXE",
      name: "San Pedro Airport",
      area_code: "VXE",
      city_name: "Sao Vicente Island",
      country_code: "CV"
  },
  {
      id: "VXO",
      name: "Smaland Airport",
      area_code: "VXO",
      city_name: "Vaxjo",
      country_code: "SE"
  },
  {
      id: "VYD",
      name: "Vryheid",
      area_code: "VYD",
      city_name: "Vryheid",
      country_code: "ZA"
  },
  {
      id: "VYS",
      name: "Illinois Valley Rgnl",
      area_code: "VYS",
      city_name: "Peru",
      country_code: "US"
  },
  {
      id: "WAE",
      name: "Wadi Ad Dawasir Airport",
      area_code: "WAE",
      city_name: "Wadi al Dawaser",
      country_code: "SA"
  },
  {
      id: "WAG",
      name: "Wanganui Airport",
      area_code: "WAG",
      city_name: "Whanganui",
      country_code: "NZ"
  },
  {
      id: "WAL",
      name: "Wallops Flt Facility",
      area_code: "WAL",
      city_name: "Wallops Island",
      country_code: "US"
  },
  {
      id: "WAT",
      name: "WATERFORD AIRPORT",
      area_code: "WAT",
      city_name: "Waterford",
      country_code: "IE"
  },
  {
      id: "WAY",
      name: "Greene County",
      area_code: "WAY",
      city_name: "Waynesburg",
      country_code: "US"
  },
  {
      id: "WAZ",
      name: "Warwick",
      area_code: "WAZ",
      city_name: "Warwick",
      country_code: "AU"
  },
  {
      id: "WBM",
      name: "WAPENAMANDA AIRPORT",
      area_code: "WBM",
      city_name: "Wapenamanda",
      country_code: "PG"
  },
  {
      id: "WBU",
      name: "Municipal",
      area_code: "WBU",
      city_name: "Boulder",
      country_code: "US"
  },
  {
      id: "MHC",
      name: "Mocopulli Airport",
      area_code: "WCA",
      city_name: "Castro",
      country_code: "CL"
  },
  {
      id: "WCA",
      name: "Gamboa",
      area_code: "WCA",
      city_name: "Castro",
      country_code: "CL"
  },
  {
      id: "WCR",
      name: "Chandalar Lake",
      area_code: "WCR",
      city_name: "Chandalar Lake",
      country_code: "US"
  },
  {
      id: "END",
      name: "Vance AFB",
      area_code: "WDG",
      city_name: "Enid",
      country_code: "US"
  },
  {
      id: "WEA",
      name: "Parker County",
      area_code: "WEA",
      city_name: "Weatherford",
      country_code: "US"
  },
  {
      id: "WEI",
      name: "WEIPA AIRPORT",
      area_code: "WEI",
      city_name: "Weipa",
      country_code: "AU"
  },
  {
      id: "WEL",
      name: "Welkom",
      area_code: "WEL",
      city_name: "Welkom",
      country_code: "ZA"
  },
  {
      id: "WEW",
      name: "Wee Waa",
      area_code: "WEW",
      city_name: "Wee Waa",
      country_code: "AU"
  },
  {
      id: "WGA",
      name: "Wagga Wagga Airport",
      area_code: "WGA",
      city_name: "Wagga Wagga",
      country_code: "AU"
  },
  {
      id: "WGC",
      name: "Warangal",
      area_code: "WGC",
      city_name: "Warangal",
      country_code: "IN"
  },
  {
      id: "WGE",
      name: "Walgett",
      area_code: "WGE",
      city_name: "Walgett",
      country_code: "AU"
  },
  {
      id: "WGO",
      name: "Regional",
      area_code: "WGO",
      city_name: "Winchester",
      country_code: "US"
  },
  {
      id: "WGT",
      name: "Wangaratta",
      area_code: "WGT",
      city_name: "Wangaratta",
      country_code: "AU"
  },
  {
      id: "WHD",
      name: "Sea Plane Base",
      area_code: "WHD",
      city_name: "Hyder",
      country_code: "US"
  },
  {
      id: "WHK",
      name: "WHAKATANE AIRPORT",
      area_code: "WHK",
      city_name: "Whakatane",
      country_code: "NZ"
  },
  {
      id: "WHL",
      name: "Welshpool",
      area_code: "WHL",
      city_name: "Welshpool",
      country_code: "AU"
  },
  {
      id: "RZH",
      name: "Preobrazheniye",
      area_code: "RZH",
      city_name: "Preobrazheniye",
      country_code: "RU"
  },
  {
      id: "WJF",
      name: "General William J. Fox",
      area_code: "WJF",
      city_name: "Lancaster",
      country_code: "US"
  },
  {
      id: "WKA",
      name: "Wanaka",
      area_code: "WKA",
      city_name: "Wanaka",
      country_code: "NZ"
  },
  {
      id: "WKF",
      name: "Waterkloof AFB",
      area_code: "PRY",
      city_name: "Pretoria",
      country_code: "ZA"
  },
  {
      id: "WLD",
      name: "Strother Field",
      area_code: "WLD",
      city_name: "Winfield/Arkansas City",
      country_code: "US"
  },
  {
      id: "WLH",
      name: "WALAHA AIRPORT",
      area_code: "WLH",
      city_name: "Walaha",
      country_code: "VU"
  },
  {
      id: "WLK",
      name: "SELAWIK AIRPORT",
      area_code: "WLK",
      city_name: "Selawik",
      country_code: "US"
  },
  {
      id: "WLS",
      name: "Hihifo Airport",
      area_code: "WLS",
      city_name: "Wallis Island",
      country_code: "WF"
  },
  {
      id: "WMC",
      name: "Municipal",
      area_code: "WMC",
      city_name: "Winnemucca",
      country_code: "US"
  },
  {
      id: "WMH",
      name: "Ozark Regional",
      area_code: "WMH",
      city_name: "Mountain Home",
      country_code: "US"
  },
  {
      id: "WMI",
      name: "Modlin Airport",
      area_code: "WMI",
      city_name: "Nowy Dwor Mazowiecki",
      country_code: "PL"
  },
  {
      id: "WMN",
      name: "MAROANTSETRA AIRPORT",
      area_code: "WMN",
      city_name: "Maroantsetra",
      country_code: "MG"
  },
  {
      id: "WNA",
      name: "Napakiak Airport",
      area_code: "WNA",
      city_name: "Napakiak",
      country_code: "US"
  },
  {
      id: "WNN",
      name: "Wunnummin Lake",
      area_code: "WNN",
      city_name: "Wunnummin Lake",
      country_code: "CA"
  },
  {
      id: "WNP",
      name: "NAGA AIRPORT",
      area_code: "WNP",
      city_name: "Naga",
      country_code: "PH"
  },
  {
      id: "WNR",
      name: "WINDORAH AIRPORT",
      area_code: "WNR",
      city_name: "Windorah",
      country_code: "AU"
  },
  {
      id: "WOL",
      name: "Illawarra Airport",
      area_code: "WOL",
      city_name: "Wollongong",
      country_code: "AU"
  },
  {
      id: "WOW",
      name: "Willow",
      area_code: "WOW",
      city_name: "Willow",
      country_code: "US"
  },
  {
      id: "WPA",
      name: "Cabo Juan Roman",
      area_code: "WPA",
      city_name: "Puerto Aisen",
      country_code: "CL"
  },
  {
      id: "WPM",
      name: "Wipim",
      area_code: "WPM",
      city_name: "Wipim",
      country_code: "PG"
  },
  {
      id: "WPO",
      name: "North Fork Valley",
      area_code: "WPO",
      city_name: "Paonia",
      country_code: "US"
  },
  {
      id: "WRE",
      name: "WHANGAREI AIRPORT",
      area_code: "WRE",
      city_name: "Whangarei",
      country_code: "NZ"
  },
  {
      id: "WRL",
      name: "Municipal",
      area_code: "WRL",
      city_name: "Worland",
      country_code: "US"
  },
  {
      id: "WSB",
      name: "Sea Plane Base",
      area_code: "WSB",
      city_name: "Steamboat Bay",
      country_code: "US"
  },
  {
      id: "WSG",
      name: "Washington County",
      area_code: "WSG",
      city_name: "Washington",
      country_code: "US"
  },
  {
      id: "WSH",
      name: "Brookhaven",
      area_code: "WSH",
      city_name: "Shirley",
      country_code: "US"
  },
  {
      id: "WSM",
      name: "Wiseman",
      area_code: "WSM",
      city_name: "Wiseman",
      country_code: "US"
  },
  {
      id: "WST",
      name: "State",
      area_code: "WST",
      city_name: "Westerly",
      country_code: "US"
  },
  {
      id: "WSY",
      name: "Whitsunday",
      area_code: "WSY",
      city_name: "Airlie Beach/Shute Hbr",
      country_code: "AU"
  },
  {
      id: "WTR",
      name: "Whiteriver",
      area_code: "WTR",
      city_name: "Whiteriver",
      country_code: "US"
  },
  {
      id: "WUN",
      name: "Wiluna Airport",
      area_code: "WUN",
      city_name: "Wiluna",
      country_code: "AU"
  },
  {
      id: "WUU",
      name: "Wau Airport",
      area_code: "WUU",
      city_name: "Wau",
      country_code: "SS"
  },
  {
      id: "WVB",
      name: "Walvis Bay Airport",
      area_code: "WVB",
      city_name: "Walvis Bay",
      country_code: "NA"
  },
  {
      id: "WVI",
      name: "Municipal",
      area_code: "WVI",
      city_name: "Watsonville",
      country_code: "US"
  },
  {
      id: "WVL",
      name: "Robert LaFleur",
      area_code: "WVL",
      city_name: "Waterville",
      country_code: "US"
  },
  {
      id: "WVN",
      name: "Mariensiel",
      area_code: "WVN",
      city_name: "Wilhelmshaven",
      country_code: "DE"
  },
  {
      id: "WWA",
      name: "Wasilla",
      area_code: "WWA",
      city_name: "Wasilla",
      country_code: "US"
  },
  {
      id: "WWD",
      name: "Cape May County",
      area_code: "WWD",
      city_name: "Wildwood",
      country_code: "US"
  },
  {
      id: "WWP",
      name: "North Whale Seaplane Base",
      area_code: "WWP",
      city_name: "Whale Pass",
      country_code: "US"
  },
  {
      id: "WWR",
      name: "West Woodward",
      area_code: "WWR",
      city_name: "Woodward",
      country_code: "US"
  },
  {
      id: "WYA",
      name: "WHYALLA AIRPORT",
      area_code: "WYA",
      city_name: "Whyalla",
      country_code: "AU"
  },
  {
      id: "WYB",
      name: "Yes Bay Lodge SPB",
      area_code: "WYB",
      city_name: "Yes Bay",
      country_code: "US"
  },
  {
      id: "WYS",
      name: "YELLOWSTONE AIRPORT",
      area_code: "WYS",
      city_name: "West Yellowstone",
      country_code: "US"
  },
  {
      id: "XAL",
      name: "Alamos",
      area_code: "XAL",
      city_name: "Alamos",
      country_code: "MX"
  },
  {
      id: "XAP",
      name: "Chapeco Airport",
      area_code: "XAP",
      city_name: "Chapeco",
      country_code: "BR"
  },
  {
      id: "XBE",
      name: "Bearskin Lake Airport",
      area_code: "XBE",
      city_name: "Bearskin Lake",
      country_code: "CA"
  },
  {
      id: "XBR",
      name: "Regional Tackaberry",
      area_code: "XBR",
      city_name: "Brockville",
      country_code: "CA"
  },
  {
      id: "XCH",
      name: "CHRISTMAS ISLAND AIRPORT",
      area_code: "XCH",
      city_name: "Christmas Island",
      country_code: "CX"
  },
  {
      id: "XCM",
      name: "Chatham-Kent",
      area_code: "XCM",
      city_name: "Chatham",
      country_code: "CA"
  },
  {
      id: "XCO",
      name: "Colac",
      area_code: "XCO",
      city_name: "Colac",
      country_code: "AU"
  },
  {
      id: "XGR",
      name: "KANGIQSUALUJJUAQ AIRPORT",
      area_code: "XGR",
      city_name: "Kangiqsualujjuaq",
      country_code: "CA"
  },
  {
      id: "XKH",
      name: "XIENG KHOUANG AIRPORT",
      area_code: "XKH",
      city_name: "Phonsavan",
      country_code: "LA"
  },
  {
      id: "XKS",
      name: "Kasabonika",
      area_code: "XKS",
      city_name: "Kasabonika",
      country_code: "CA"
  },
  {
      id: "XMS",
      name: "Edmundo Carvajal",
      area_code: "XMS",
      city_name: "Macas",
      country_code: "EC"
  },
  {
      id: "XMY",
      name: "Yam Island",
      area_code: "XMY",
      city_name: "Yam Island",
      country_code: "AU"
  },
  {
      id: "XPK",
      name: "Pukatawagan",
      area_code: "XPK",
      city_name: "Pukatawagan",
      country_code: "CA"
  },
  {
      id: "XPL",
      name: "Enrique Soto Cano",
      area_code: "XPL",
      city_name: "Comayagua",
      country_code: "HN"
  },
  {
      id: "XPP",
      name: "Poplar River",
      area_code: "XPP",
      city_name: "Poplar River",
      country_code: "CA"
  },
  {
      id: "XQP",
      name: "Quepos Airport",
      area_code: "XQP",
      city_name: "Quepos",
      country_code: "CR"
  },
  {
      id: "XQU",
      name: "Qualicum Beach",
      area_code: "XQU",
      city_name: "Qualicum Beach",
      country_code: "CA"
  },
  {
      id: "XSC",
      name: "South Caicos International Airport",
      area_code: "XSC",
      city_name: "South Caicos",
      country_code: "TC"
  },
  {
      id: "XSI",
      name: "South Indian Lake Airport",
      area_code: "XSI",
      city_name: "South Indian Lake",
      country_code: "CA"
  },
  {
      id: "XTG",
      name: "THARGOMINDAH AIRPORT",
      area_code: "XTG",
      city_name: "Thargomindah",
      country_code: "AU"
  },
  {
      id: "XTL",
      name: "Tadoule Lake Airport",
      area_code: "XTL",
      city_name: "Tadoule Lake",
      country_code: "CA"
  },
  {
      id: "XWA",
      name: "Watford Rail Station",
      area_code: "XWA",
      city_name: "Watford",
      country_code: "CA"
  },
  {
      id: "YAC",
      name: "Cat Lake Airport",
      area_code: "YAC",
      city_name: "Cat Lake",
      country_code: "CA"
  },
  {
      id: "YAG",
      name: "Fort Frances Municipal Airport",
      area_code: "YAG",
      city_name: "Fort Frances",
      country_code: "CA"
  },
  {
      id: "YAO",
      name: "Yaounde-Ville Apt",
      area_code: "YAO",
      city_name: "Yaounde",
      country_code: "CM"
  },
  {
      id: "YAP",
      name: "Yap International Airport",
      area_code: "YAP",
      city_name: "Yap",
      country_code: "FM"
  },
  {
      id: "YAT",
      name: "ATTAWAPISKAT AIRPORT",
      area_code: "YAT",
      city_name: "Attawapiskat",
      country_code: "CA"
  },
  {
      id: "YAV",
      name: "Miners Bay SPB",
      area_code: "YAV",
      city_name: "Mayne Island",
      country_code: "CA"
  },
  {
      id: "YAY",
      name: "St. Anthony Airport",
      area_code: "YAY",
      city_name: "St Anthony",
      country_code: "CA"
  },
  {
      id: "YAZ",
      name: "TOFINO AIRPORT",
      area_code: "YAZ",
      city_name: "Tofino",
      country_code: "CA"
  },
  {
      id: "YTP",
      name: "Harbour SPB",
      area_code: "YAZ",
      city_name: "Tofino",
      country_code: "CA"
  },
  {
      id: "YBA",
      name: "Banff",
      area_code: "YBA",
      city_name: "Banff",
      country_code: "CA"
  },
  {
      id: "YBB",
      name: "Kugaaruk Airport",
      area_code: "YBB",
      city_name: "Kugaaruk",
      country_code: "CA"
  },
  {
      id: "YBC",
      name: "Baie Comeau Airport",
      area_code: "YBC",
      city_name: "Baie-Comeau",
      country_code: "CA"
  },
  {
      id: "YBG",
      name: "Bagotville Airport",
      area_code: "YBG",
      city_name: "Bagotville",
      country_code: "CA"
  },
  {
      id: "YBK",
      name: "BAKER LAKE AIRPORT",
      area_code: "YBK",
      city_name: "Baker Lake",
      country_code: "CA"
  },
  {
      id: "YBL",
      name: "Airport",
      area_code: "YBL",
      city_name: "Campbell River",
      country_code: "CA"
  },
  {
      id: "YHH",
      name: "Campbell River Sea Plane Base",
      area_code: "YBL",
      city_name: "Campbell River",
      country_code: "CA"
  },
  {
      id: "YBR",
      name: "Brandon Airport",
      area_code: "YBR",
      city_name: "Brandon",
      country_code: "CA"
  },
  {
      id: "YBT",
      name: "Brochet Airport",
      area_code: "YBT",
      city_name: "Brochet",
      country_code: "CA"
  },
  {
      id: "YBV",
      name: "BERENS RIVER AIRPORT",
      area_code: "YBV",
      city_name: "Berens River",
      country_code: "CA"
  },
  {
      id: "YBW",
      name: "SPB",
      area_code: "YBW",
      city_name: "Bedwell Harbour",
      country_code: "CA"
  },
  {
      id: "YBX",
      name: "Lourdes-de-Blanc-Sablon Airport",
      area_code: "YBX",
      city_name: "Blanc-Sablon",
      country_code: "CA"
  },
  {
      id: "YCA",
      name: "Airpark",
      area_code: "YCA",
      city_name: "Courtenay",
      country_code: "CA"
  },
  {
      id: "YCB",
      name: "CAMBRIDGE BAY AIRPORT",
      area_code: "YCB",
      city_name: "Cambridge Bay",
      country_code: "CA"
  },
  {
      id: "YCC",
      name: "Regional",
      area_code: "YCC",
      city_name: "Cornwall",
      country_code: "CA"
  },
  {
      id: "ZNA",
      name: "Harbour SPB",
      area_code: "YCD",
      city_name: "Nanaimo",
      country_code: "CA"
  },
  {
      id: "YCF",
      name: "SPB",
      area_code: "YCF",
      city_name: "Cortes Bay",
      country_code: "CA"
  },
  {
      id: "YCG",
      name: "West Kootenay Regional Airport",
      area_code: "YCG",
      city_name: "Castlegar",
      country_code: "CA"
  },
  {
      id: "YCH",
      name: "Airport",
      area_code: "YCH",
      city_name: "Miramichi",
      country_code: "CA"
  },
  {
      id: "YCK",
      name: "Colville Lake Airport",
      area_code: "YCK",
      city_name: "Colville Lake",
      country_code: "CA"
  },
  {
      id: "YCL",
      name: "CHARLO AIRPORT",
      area_code: "YCL",
      city_name: "Charlo",
      country_code: "CA"
  },
  {
      id: "YCN",
      name: "Cochrane",
      area_code: "YCN",
      city_name: "Cochrane",
      country_code: "CA"
  },
  {
      id: "YCO",
      name: "KUGLUKTUK AIRPORT",
      area_code: "YCO",
      city_name: "Kugluktuk",
      country_code: "CA"
  },
  {
      id: "YCR",
      name: "Cross Lake Airport",
      area_code: "YCR",
      city_name: "Cross Lake",
      country_code: "CA"
  },
  {
      id: "YCS",
      name: "CHESTERFIELD INLET AIRPORT",
      area_code: "YCS",
      city_name: "Chesterfield Inlet",
      country_code: "CA"
  },
  {
      id: "YCW",
      name: "Chilliwack",
      area_code: "YCW",
      city_name: "Chilliwack",
      country_code: "CA"
  },
  {
      id: "YCZ",
      name: "Fairmount Hot Springs",
      area_code: "YCZ",
      city_name: "Fairmount Hot Springs",
      country_code: "CA"
  },
  {
      id: "YDA",
      name: "Dawson City Airport",
      area_code: "YDA",
      city_name: "Dawson City",
      country_code: "CA"
  },
  {
      id: "YDF",
      name: "Deer Lake Regional Airport",
      area_code: "YDF",
      city_name: "Deer Lake",
      country_code: "CA"
  },
  {
      id: "YDL",
      name: "Dease Lake",
      area_code: "YDL",
      city_name: "Dease Lake",
      country_code: "CA"
  },
  {
      id: "YDN",
      name: "Lt. W.G. (Billy)Barker",
      area_code: "YDN",
      city_name: "Dauphin",
      country_code: "CA"
  },
  {
      id: "YDP",
      name: "NAIN AIRPORT",
      area_code: "YDP",
      city_name: "Nain",
      country_code: "CA"
  },
  {
      id: "YDQ",
      name: "DAWSON CREEK AIRPORT",
      area_code: "YDQ",
      city_name: "Dawson Creek",
      country_code: "CA"
  },
  {
      id: "YDV",
      name: "Bloodvein River",
      area_code: "YDV",
      city_name: "Bloodvein",
      country_code: "CA"
  },
  {
      id: "YEB",
      name: "Bar River",
      area_code: "YEB",
      city_name: "Bar River",
      country_code: "CA"
  },
  {
      id: "YEH",
      name: "Yinchuan",
      area_code: "YEH",
      city_name: "Yueyahu",
      country_code: "CN"
  },
  {
      id: "YEI",
      name: "Yenisehir Airport",
      area_code: "YEI",
      city_name: "Bursa",
      country_code: "TR"
  },
  {
      id: "YEK",
      name: "ARVIAT AIRPORT",
      area_code: "YEK",
      city_name: "Arviat",
      country_code: "CA"
  },
  {
      id: "YEL",
      name: "Municipal",
      area_code: "YEL",
      city_name: "Elliot Lake",
      country_code: "CA"
  },
  {
      id: "YEN",
      name: "Regional",
      area_code: "YEN",
      city_name: "Estevan",
      country_code: "CA"
  },
  {
      id: "YEO",
      name: "Royal Naval Air Stn",
      area_code: "YEO",
      city_name: "Yeovilton",
      country_code: "GB"
  },
  {
      id: "YER",
      name: "Fort Severn",
      area_code: "YER",
      city_name: "Fort Severn",
      country_code: "CA"
  },
  {
      id: "YET",
      name: "Edson",
      area_code: "YET",
      city_name: "Edson",
      country_code: "CA"
  },
  {
      id: "YEV",
      name: "Inuvik/Mike Zubko Airport",
      area_code: "YEV",
      city_name: "Inuvik",
      country_code: "CA"
  },
  {
      id: "YEY",
      name: "Magny",
      area_code: "YEY",
      city_name: "Amos",
      country_code: "CA"
  },
  {
      id: "YFB",
      name: "IQALUIT AIRPORT",
      area_code: "YFB",
      city_name: "Iqaluit",
      country_code: "CA"
  },
  {
      id: "YFH",
      name: "Fort Hope",
      area_code: "YFH",
      city_name: "Fort Hope",
      country_code: "CA"
  },
  {
      id: "YFO",
      name: "FLIN FLON AIRPORT",
      area_code: "YFO",
      city_name: "Flin Flon",
      country_code: "CA"
  },
  {
      id: "YFX",
      name: "St Lewis (Fox Harbour)",
      area_code: "YFX",
      city_name: "St Lewis (Fox Harbour)",
      country_code: "CA"
  },
  {
      id: "YGJ",
      name: "Miho Airport",
      area_code: "YGJ",
      city_name: "Yonago",
      country_code: "JP"
  },
  {
      id: "YGL",
      name: "La Grande Airport",
      area_code: "YGL",
      city_name: "La Grande Riviere",
      country_code: "CA"
  },
  {
      id: "YGO",
      name: "Gods Lake Narrows Airport",
      area_code: "YGO",
      city_name: "Gods Lake Narrows",
      country_code: "CA"
  },
  {
      id: "YGP",
      name: "Michel-Pouliot Airport",
      area_code: "YGP",
      city_name: "Gaspe",
      country_code: "CA"
  },
  {
      id: "YGQ",
      name: "Greenstone Regional",
      area_code: "YGQ",
      city_name: "Geraldton",
      country_code: "CA"
  },
  {
      id: "YGR",
      name: "ILES DE LA MADELEINE AIRPORT",
      area_code: "YGR",
      city_name: "Iles de La Madeleine",
      country_code: "CA"
  },
  {
      id: "YGT",
      name: "IGLOOLIK AIRPORT",
      area_code: "YGT",
      city_name: "Igloolik",
      country_code: "CA"
  },
  {
      id: "YGV",
      name: "Havre St. Pierre Airport",
      area_code: "YGV",
      city_name: "Havre-St-Pierre",
      country_code: "CA"
  },
  {
      id: "YGW",
      name: "KUUJJUARAPIK AIRPORT",
      area_code: "YGW",
      city_name: "Kuujjuarapik",
      country_code: "CA"
  },
  {
      id: "YGX",
      name: "GILLAM AIRPORT",
      area_code: "YGX",
      city_name: "Gillam",
      country_code: "CA"
  },
  {
      id: "YHA",
      name: "Port Hope Simpson",
      area_code: "YHA",
      city_name: "Port Hope Simpson",
      country_code: "CA"
  },
  {
      id: "YHB",
      name: "Hudson Bay",
      area_code: "YHB",
      city_name: "Hudson Bay",
      country_code: "CA"
  },
  {
      id: "YHD",
      name: "Dryden Regional Airport",
      area_code: "YHD",
      city_name: "Dryden",
      country_code: "CA"
  },
  {
      id: "YHE",
      name: "Hope",
      area_code: "YHE",
      city_name: "Hope",
      country_code: "CA"
  },
  {
      id: "YHF",
      name: "Rene Fontaine Muni",
      area_code: "YHF",
      city_name: "Hearst",
      country_code: "CA"
  },
  {
      id: "YHG",
      name: "Off-line Point",
      area_code: "YHG",
      city_name: "Charlottetown",
      country_code: "CA"
  },
  {
      id: "YHK",
      name: "GJOA HAVEN AIRPORT",
      area_code: "YHK",
      city_name: "Gjoa Haven",
      country_code: "CA"
  },
  {
      id: "YHM",
      name: "Hamilton Airport",
      area_code: "YTO",
      city_name: "Toronto",
      country_code: "CA"
  },
  {
      id: "YHN",
      name: "Municipal",
      area_code: "YHN",
      city_name: "Hornepayne",
      country_code: "CA"
  },
  {
      id: "YHO",
      name: "HOPEDALE AIRPORT",
      area_code: "YHO",
      city_name: "Hopedale",
      country_code: "CA"
  },
  {
      id: "YHP",
      name: "Poplar Hill",
      area_code: "YHP",
      city_name: "Poplar Hill",
      country_code: "CA"
  },
  {
      id: "YHR",
      name: "CHEVERY AIRPORT",
      area_code: "YHR",
      city_name: "Chevery",
      country_code: "CA"
  },
  {
      id: "YHS",
      name: "Sechelt",
      area_code: "YHS",
      city_name: "Sechelt",
      country_code: "CA"
  },
  {
      id: "YHY",
      name: "Hay River Airport",
      area_code: "YHY",
      city_name: "Hay River",
      country_code: "CA"
  },
  {
      id: "YIB",
      name: "Municipal",
      area_code: "YIB",
      city_name: "Atikokan",
      country_code: "CA"
  },
  {
      id: "YIF",
      name: "Pakuashipi Airport",
      area_code: "YIF",
      city_name: "St-Augustin/Pakuashipi",
      country_code: "CA"
  },
  {
      id: "YIK",
      name: "IVUJIVIK AIRPORT",
      area_code: "YIK",
      city_name: "Ivujivik",
      country_code: "CA"
  },
  {
      id: "YIO",
      name: "POND INLET AIRPORT",
      area_code: "YIO",
      city_name: "Pond Inlet",
      country_code: "CA"
  },
  {
      id: "YIV",
      name: "Island Lake/Garden Hill Airport",
      area_code: "YIV",
      city_name: "Island Lk/Garden Hill",
      country_code: "CA"
  },
  {
      id: "YJA",
      name: "Airport",
      area_code: "YJA",
      city_name: "Jasper",
      country_code: "CA"
  },
  {
      id: "YJT",
      name: "Stephenville Airport",
      area_code: "YJT",
      city_name: "Stephenville",
      country_code: "CA"
  },
  {
      id: "YKD",
      name: "Kincardine",
      area_code: "YKD",
      city_name: "Kincardine",
      country_code: "CA"
  },
  {
      id: "YKF",
      name: "Region of Waterloo International Airport",
      area_code: "YTO",
      city_name: "Toronto",
      country_code: "CA"
  },
  {
      id: "YKG",
      name: "KANGIRSUK AIRPORT",
      area_code: "YKG",
      city_name: "Kangirsuk",
      country_code: "CA"
  },
  {
      id: "YKL",
      name: "SCHEFFERVILLE AIRPORT",
      area_code: "YKL",
      city_name: "Schefferville",
      country_code: "CA"
  },
  {
      id: "YKQ",
      name: "WASKAGANISH AIRPORT",
      area_code: "YKQ",
      city_name: "Waskaganish",
      country_code: "CA"
  },
  {
      id: "OLZ",
      name: "Olyokminsk Airport",
      area_code: "OLZ",
      city_name: "Olekminsk",
      country_code: "RU"
  },
  {
      id: "YKU",
      name: "CHISASIBI AIRPORT",
      area_code: "YKU",
      city_name: "Chisasibi",
      country_code: "CA"
  },
  {
      id: "YKX",
      name: "Kirkland Lake",
      area_code: "YKX",
      city_name: "Kirkland Lake",
      country_code: "CA"
  },
  {
      id: "YLC",
      name: "Kimmirut Airport",
      area_code: "YLC",
      city_name: "Kimmirut",
      country_code: "CA"
  },
  {
      id: "YLD",
      name: "Chapleau",
      area_code: "YLD",
      city_name: "Chapleau",
      country_code: "CA"
  },
  {
      id: "YLI",
      name: "Ylivieska",
      area_code: "YLI",
      city_name: "Ylivieska",
      country_code: "FI"
  },
  {
      id: "YLJ",
      name: "Meadow Lake",
      area_code: "YLJ",
      city_name: "Meadow Lake",
      country_code: "CA"
  },
  {
      id: "YLL",
      name: "LLOYDMINSTER AIRPORT",
      area_code: "YLL",
      city_name: "Lloydminster",
      country_code: "CA"
  },
  {
      id: "YLQ",
      name: "La Tuque",
      area_code: "YLQ",
      city_name: "La Tuque",
      country_code: "CA"
  },
  {
      id: "YLY",
      name: "Regional",
      area_code: "YLY",
      city_name: "Langley",
      country_code: "CA"
  },
  {
      id: "YMB",
      name: "Merritt",
      area_code: "YMB",
      city_name: "Merritt",
      country_code: "CA"
  },
  {
      id: "YME",
      name: "Matane",
      area_code: "YME",
      city_name: "Matane",
      country_code: "CA"
  },
  {
      id: "YMG",
      name: "Manitouwadge",
      area_code: "YMG",
      city_name: "Manitouwadge",
      country_code: "CA"
  },
  {
      id: "YMH",
      name: "Mary's Harbour",
      area_code: "YMH",
      city_name: "Mary's Harbour",
      country_code: "CA"
  },
  {
      id: "YMK",
      name: "Mys-Kamenny",
      area_code: "YMK",
      city_name: "Mys-Kamenny",
      country_code: "RU"
  },
  {
      id: "YMM",
      name: "FORT MCMURRAY AIRPORT",
      area_code: "YMM",
      city_name: "Fort McMurray",
      country_code: "CA"
  },
  {
      id: "YMN",
      name: "MAKKOVIK AIRPORT",
      area_code: "YMN",
      city_name: "Makkovik",
      country_code: "CA"
  },
  {
      id: "YMO",
      name: "MOOSONEE AIRPORT",
      area_code: "YMO",
      city_name: "Moosonee",
      country_code: "CA"
  },
  {
      id: "YHU",
      name: "Saint Hubert Airport",
      area_code: "YMQ",
      city_name: "Montreal",
      country_code: "CA"
  },
  {
      id: "YMT",
      name: "Chibougamau Airport",
      area_code: "YMT",
      city_name: "Chibougamau",
      country_code: "CA"
  },
  {
      id: "YNA",
      name: "NATASHQUAN AIRPORT",
      area_code: "YNA",
      city_name: "Natashquan",
      country_code: "CA"
  },
  {
      id: "YND",
      name: "Ottawa Gatineau Airport",
      area_code: "YOW",
      city_name: "Ottawa",
      country_code: "CA"
  },
  {
      id: "YNE",
      name: "Norway House Airport",
      area_code: "YNE",
      city_name: "Norway House",
      country_code: "CA"
  },
  {
      id: "YNN",
      name: "Yandicoogina",
      area_code: "YNN",
      city_name: "Yandicoogina",
      country_code: "AU"
  },
  {
      id: "YNO",
      name: "North Spirit Lake Airport",
      area_code: "YNO",
      city_name: "North Spirit Lake",
      country_code: "CA"
  },
  {
      id: "YNP",
      name: "NATUASHISH AIRPORT",
      area_code: "YNP",
      city_name: "Natuashish",
      country_code: "CA"
  },
  {
      id: "YOD",
      name: "R.W. McNair",
      area_code: "YOD",
      city_name: "Cold Lake",
      country_code: "CA"
  },
  {
      id: "YOJ",
      name: "High Level Airport",
      area_code: "YOJ",
      city_name: "High Level",
      country_code: "CA"
  },
  {
      id: "YOO",
      name: "Oshawa",
      area_code: "YOO",
      city_name: "Oshawa",
      country_code: "CA"
  },
  {
      id: "YOP",
      name: "Rainbow Lake",
      area_code: "YOP",
      city_name: "Rainbow Lake",
      country_code: "CA"
  },
  {
      id: "YOS",
      name: "Billy Bishop Regional",
      area_code: "YOS",
      city_name: "Owen Sound",
      country_code: "CA"
  },
  {
      id: "YOT",
      name: "Yotvata",
      area_code: "YOT",
      city_name: "Yotvata",
      country_code: "IL"
  },
  {
      id: "YRO",
      name: "Rockcliffe",
      area_code: "YOW",
      city_name: "Ottawa",
      country_code: "CA"
  },
  {
      id: "YPA",
      name: "Glass Field",
      area_code: "YPA",
      city_name: "Prince Albert",
      country_code: "CA"
  },
  {
      id: "YPB",
      name: "Alberni Valley Rgnl",
      area_code: "YPB",
      city_name: "Port Alberni",
      country_code: "CA"
  },
  {
      id: "YPD",
      name: "Parry Sound Area Muni",
      area_code: "YPD",
      city_name: "Parry Sound",
      country_code: "CA"
  },
  {
      id: "YPE",
      name: "Peace River",
      area_code: "YPE",
      city_name: "Peace River",
      country_code: "CA"
  },
  {
      id: "YPG",
      name: "Southport",
      area_code: "YPG",
      city_name: "Portage La Prairie",
      country_code: "CA"
  },
  {
      id: "YPH",
      name: "INUKJUAK AIRPORT",
      area_code: "YPH",
      city_name: "Inukjuak",
      country_code: "CA"
  },
  {
      id: "YPJ",
      name: "AUPALUK AIRPORT",
      area_code: "YPJ",
      city_name: "Aupaluk",
      country_code: "CA"
  },
  {
      id: "YPL",
      name: "Pickle Lake",
      area_code: "YPL",
      city_name: "Pickle Lake",
      country_code: "CA"
  },
  {
      id: "YPM",
      name: "PIKANGIKUM AIRPORT",
      area_code: "YPM",
      city_name: "Pikangikum",
      country_code: "CA"
  },
  {
      id: "YPN",
      name: "Port Menier Airport",
      area_code: "YPN",
      city_name: "Port-Menier",
      country_code: "CA"
  },
  {
      id: "YPQ",
      name: "Peterborough",
      area_code: "YPQ",
      city_name: "Peterborough",
      country_code: "CA"
  },
  {
      id: "YPR",
      name: "Prince Rupert Airport",
      area_code: "YPR",
      city_name: "Prince Rupert",
      country_code: "CA"
  },
  {
      id: "YPS",
      name: "Port Hawkesbury",
      area_code: "YPS",
      city_name: "Port Hawkesbury",
      country_code: "CA"
  },
  {
      id: "YPW",
      name: "POWELL RIVER AIRPORT",
      area_code: "YPW",
      city_name: "Powell River",
      country_code: "CA"
  },
  {
      id: "YPX",
      name: "PUVIRNITUQ AIRPORT",
      area_code: "YPX",
      city_name: "Puvirnituq",
      country_code: "CA"
  },
  {
      id: "YPZ",
      name: "Burns Lake",
      area_code: "YPZ",
      city_name: "Burns Lake",
      country_code: "CA"
  },
  {
      id: "YQA",
      name: "Muskoka",
      area_code: "YQA",
      city_name: "Muskoka",
      country_code: "CA"
  },
  {
      id: "YQC",
      name: "QUAQTAQ AIRPORT",
      area_code: "YQC",
      city_name: "Quaqtaq",
      country_code: "CA"
  },
  {
      id: "YQD",
      name: "The Pas Airport",
      area_code: "YQD",
      city_name: "The Pas",
      country_code: "CA"
  },
  {
      id: "YQF",
      name: "Red Deer Regional Airport",
      area_code: "YQF",
      city_name: "Red Deer",
      country_code: "CA"
  },
  {
      id: "YQH",
      name: "Watson Lake",
      area_code: "YQH",
      city_name: "Watson Lake",
      country_code: "CA"
  },
  {
      id: "YQI",
      name: "Yarmouth",
      area_code: "YQI",
      city_name: "Yarmouth",
      country_code: "CA"
  },
  {
      id: "YQK",
      name: "KENORA AIRPORT",
      area_code: "YQK",
      city_name: "Kenora",
      country_code: "CA"
  },
  {
      id: "YQL",
      name: "Lethbridge County Airport",
      area_code: "YQL",
      city_name: "Lethbridge",
      country_code: "CA"
  },
  {
      id: "YQM",
      name: "Greater Moncton International Airport",
      area_code: "YQM",
      city_name: "Moncton",
      country_code: "CA"
  },
  {
      id: "YQN",
      name: "Nakina",
      area_code: "YQN",
      city_name: "Nakina",
      country_code: "CA"
  },
  {
      id: "YQQ",
      name: "Comox Airport",
      area_code: "YQQ",
      city_name: "Comox",
      country_code: "CA"
  },
  {
      id: "YMJ",
      name: "C.M. McEwen",
      area_code: "YMJ",
      city_name: "Moose Jaw",
      country_code: "CA"
  },
  {
      id: "YQS",
      name: "Municipal",
      area_code: "YQS",
      city_name: "St Thomas",
      country_code: "CA"
  },
  {
      id: "YQU",
      name: "Grande Prairie Airport",
      area_code: "YQU",
      city_name: "Grande Prairie",
      country_code: "CA"
  },
  {
      id: "YQV",
      name: "Municipal",
      area_code: "YQV",
      city_name: "Yorkton",
      country_code: "CA"
  },
  {
      id: "YQZ",
      name: "QUESNEL AIRPORT",
      area_code: "YQZ",
      city_name: "Quesnel",
      country_code: "CA"
  },
  {
      id: "YRB",
      name: "Resolute Airport",
      area_code: "YRB",
      city_name: "Resolute",
      country_code: "CA"
  },
  {
      id: "YRF",
      name: "Cartwright",
      area_code: "YRF",
      city_name: "Cartwright",
      country_code: "CA"
  },
  {
      id: "YRG",
      name: "RIGOLET AIRPORT",
      area_code: "YRG",
      city_name: "Rigolet",
      country_code: "CA"
  },
  {
      id: "YRI",
      name: "Riviere-du-Loup",
      area_code: "YRI",
      city_name: "Riviere-du-Loup",
      country_code: "CA"
  },
  {
      id: "YRJ",
      name: "Roberval",
      area_code: "YRJ",
      city_name: "Roberval",
      country_code: "CA"
  },
  {
      id: "YRL",
      name: "RED LAKE AIRPORT",
      area_code: "YRL",
      city_name: "Red Lake",
      country_code: "CA"
  },
  {
      id: "YRQ",
      name: "Trois-Rivieres",
      area_code: "YRQ",
      city_name: "Trois-Rivieres",
      country_code: "CA"
  },
  {
      id: "YRS",
      name: "Red Sucker Lake Airport",
      area_code: "YRS",
      city_name: "Red Sucker Lake",
      country_code: "CA"
  },
  {
      id: "YRT",
      name: "Rankin Inlet Airport",
      area_code: "YRT",
      city_name: "Rankin Inlet",
      country_code: "CA"
  },
  {
      id: "YSB",
      name: "Sudbury Airport",
      area_code: "YSB",
      city_name: "Sudbury",
      country_code: "CA"
  },
  {
      id: "YSC",
      name: "Sherbrooke",
      area_code: "YSC",
      city_name: "Sherbrooke",
      country_code: "CA"
  },
  {
      id: "YSH",
      name: "Montague",
      area_code: "YSH",
      city_name: "Smiths Falls",
      country_code: "CA"
  },
  {
      id: "YSK",
      name: "SANIKILUAQ AIRPORT",
      area_code: "YSK",
      city_name: "Sanikiluaq",
      country_code: "CA"
  },
  {
      id: "YSL",
      name: "St Leonard",
      area_code: "YSL",
      city_name: "St Leonard",
      country_code: "CA"
  },
  {
      id: "YSM",
      name: "FORT SMITH AIRPORT",
      area_code: "YSM",
      city_name: "Fort Smith",
      country_code: "CA"
  },
  {
      id: "YSN",
      name: "Salmon Arm",
      area_code: "YSN",
      city_name: "Salmon Arm",
      country_code: "CA"
  },
  {
      id: "YSO",
      name: "POSTVILLE AIRPORT",
      area_code: "YSO",
      city_name: "Postville",
      country_code: "CA"
  },
  {
      id: "YSP",
      name: "Marathon",
      area_code: "YSP",
      city_name: "Marathon",
      country_code: "CA"
  },
  {
      id: "YST",
      name: "St. Theresa Point Airport",
      area_code: "YST",
      city_name: "St Theresa Point",
      country_code: "CA"
  },
  {
      id: "YTA",
      name: "Pembroke",
      area_code: "YTA",
      city_name: "Pembroke",
      country_code: "CA"
  },
  {
      id: "YTD",
      name: "Thicket Portage",
      area_code: "YTD",
      city_name: "Thicket Portage",
      country_code: "CA"
  },
  {
      id: "YTE",
      name: "CAPE DORSET AIRPORT",
      area_code: "YTE",
      city_name: "Cape Dorset",
      country_code: "CA"
  },
  {
      id: "YTF",
      name: "Alma",
      area_code: "YTF",
      city_name: "Alma",
      country_code: "CA"
  },
  {
      id: "YTH",
      name: "THOMPSON AIRPORT",
      area_code: "YTH",
      city_name: "Thompson",
      country_code: "CA"
  },
  {
      id: "YTL",
      name: "Big Trout Lake",
      area_code: "YTL",
      city_name: "Big Trout Lake",
      country_code: "CA"
  },
  {
      id: "YTM",
      name: "Mont Tremblant International Airport",
      area_code: "YTM",
      city_name: "Mont-Tremblant",
      country_code: "CA"
  },
  {
      id: "YKZ",
      name: "Buttonville Municipal",
      area_code: "YTO",
      city_name: "Toronto",
      country_code: "CA"
  },
  {
      id: "YTZ",
      name: "Toronto Island Airport",
      area_code: "YTO",
      city_name: "Toronto",
      country_code: "CA"
  },
  {
      id: "YTQ",
      name: "Tasiujaq Airport",
      area_code: "YTQ",
      city_name: "Tasiujaq",
      country_code: "CA"
  },
  {
      id: "YTR",
      name: "Trenton",
      area_code: "YTR",
      city_name: "Trenton",
      country_code: "CA"
  },
  {
      id: "YTS",
      name: "TIMMINS AIRPORT",
      area_code: "YTS",
      city_name: "Timmins",
      country_code: "CA"
  },
  {
      id: "YUB",
      name: "Tuktoyaktuk Airport",
      area_code: "YUB",
      city_name: "Tuktoyaktuk",
      country_code: "CA"
  },
  {
      id: "YUD",
      name: "Umiujaq Airport",
      area_code: "YUD",
      city_name: "Umiujaq",
      country_code: "CA"
  },
  {
      id: "LGF",
      name: "Laguna AAF",
      area_code: "YUM",
      city_name: "Yuma",
      country_code: "US"
  },
  {
      id: "YUM",
      name: "Yuma International Airport",
      area_code: "YUM",
      city_name: "Yuma",
      country_code: "US"
  },
  {
      id: "YUX",
      name: "HALL BEACH AIRPORT",
      area_code: "YUX",
      city_name: "Hall Beach",
      country_code: "CA"
  },
  {
      id: "YUY",
      name: "Rouyn-Noranda Airport",
      area_code: "YUY",
      city_name: "Rouyn-Noranda",
      country_code: "CA"
  },
  {
      id: "YVB",
      name: "BONAVENTURE AIRPORT",
      area_code: "YVB",
      city_name: "Bonaventure",
      country_code: "CA"
  },
  {
      id: "YVE",
      name: "Regional",
      area_code: "YVE",
      city_name: "Vernon",
      country_code: "CA"
  },
  {
      id: "YVG",
      name: "Vermilion",
      area_code: "YVG",
      city_name: "Vermilion",
      country_code: "CA"
  },
  {
      id: "YVM",
      name: "Qikiqtarjuaq Airport",
      area_code: "YVM",
      city_name: "Qikiqtarjuaq",
      country_code: "CA"
  },
  {
      id: "YVO",
      name: "Val D'Or Airport",
      area_code: "YVO",
      city_name: "Val d'Or",
      country_code: "CA"
  },
  {
      id: "YVP",
      name: "KUUJJUAQ AIRPORT",
      area_code: "YVP",
      city_name: "Kuujjuaq",
      country_code: "CA"
  },
  {
      id: "YVQ",
      name: "NORMAN WELLS AIRPORT",
      area_code: "YVQ",
      city_name: "Norman Wells",
      country_code: "CA"
  },
  {
      id: "CXH",
      name: "Harbour SPB",
      area_code: "YVR",
      city_name: "Vancouver",
      country_code: "CA"
  },
  {
      id: "YDT",
      name: "Boundary Bay",
      area_code: "YVR",
      city_name: "Vancouver",
      country_code: "CA"
  },
  {
      id: "YVZ",
      name: "Deer Lake Airport",
      area_code: "YVZ",
      city_name: "Deer Lake",
      country_code: "CA"
  },
  {
      id: "YWB",
      name: "Wakeham Bay Airport",
      area_code: "YWB",
      city_name: "Kangiqsujuaq",
      country_code: "CA"
  },
  {
      id: "YWK",
      name: "Wabush Airport",
      area_code: "YWK",
      city_name: "Wabush",
      country_code: "CA"
  },
  {
      id: "YWL",
      name: "WILLIAMS LAKE AIRPORT",
      area_code: "YWL",
      city_name: "Williams Lake",
      country_code: "CA"
  },
  {
      id: "YWP",
      name: "Webequie",
      area_code: "YWP",
      city_name: "Webequie",
      country_code: "CA"
  },
  {
      id: "YWR",
      name: "SPB",
      area_code: "YWR",
      city_name: "White River",
      country_code: "CA"
  },
  {
      id: "YWS",
      name: "Green Lake SPB",
      area_code: "YWS",
      city_name: "Whistler",
      country_code: "CA"
  },
  {
      id: "YXC",
      name: "Cranbrook Airport",
      area_code: "YXC",
      city_name: "Cranbrook",
      country_code: "CA"
  },
  {
      id: "YKY",
      name: "Regional",
      area_code: "YKY",
      city_name: "Kindersley",
      country_code: "CA"
  },
  {
      id: "YXH",
      name: "MEDICINE HAT AIRPORT",
      area_code: "YXH",
      city_name: "Medicine Hat",
      country_code: "CA"
  },
  {
      id: "YXK",
      name: "Rimouski",
      area_code: "YXK",
      city_name: "Rimouski",
      country_code: "CA"
  },
  {
      id: "YXL",
      name: "SIOUX LOOKOUT AIRPORT",
      area_code: "YXL",
      city_name: "Sioux Lookout",
      country_code: "CA"
  },
  {
      id: "YXN",
      name: "WHALE COVE AIRPORT",
      area_code: "YXN",
      city_name: "Whale Cove",
      country_code: "CA"
  },
  {
      id: "YXP",
      name: "PANGNIRTUNG AIRPORT",
      area_code: "YXP",
      city_name: "Pangnirtung",
      country_code: "CA"
  },
  {
      id: "YXT",
      name: "Northwest Regional Airport",
      area_code: "YXT",
      city_name: "Terrace",
      country_code: "CA"
  },
  {
      id: "YXZ",
      name: "Wawa",
      area_code: "YXZ",
      city_name: "Wawa",
      country_code: "CA"
  },
  {
      id: "YYB",
      name: "North Bay Airport",
      area_code: "YYB",
      city_name: "North Bay",
      country_code: "CA"
  },
  {
      id: "YYD",
      name: "SMITHERS AIRPORT",
      area_code: "YYD",
      city_name: "Smithers",
      country_code: "CA"
  },
  {
      id: "YYE",
      name: "Fort Nelson Airport",
      area_code: "YYE",
      city_name: "Fort Nelson",
      country_code: "CA"
  },
  {
      id: "YYF",
      name: "Penticton Regional Airport",
      area_code: "YYF",
      city_name: "Penticton",
      country_code: "CA"
  },
  {
      id: "YYH",
      name: "TALOYOAK AIRPORT",
      area_code: "YYH",
      city_name: "Taloyoak",
      country_code: "CA"
  },
  {
      id: "YYI",
      name: "Rivers",
      area_code: "YYI",
      city_name: "Rivers",
      country_code: "CA"
  },
  {
      id: "YWH",
      name: "Inner Harbor Airport",
      area_code: "YYJ",
      city_name: "Victoria",
      country_code: "CA"
  },
  {
      id: "YYL",
      name: "Lynn Lake",
      area_code: "YYL",
      city_name: "Lynn Lake",
      country_code: "CA"
  },
  {
      id: "YYN",
      name: "Swift Current",
      area_code: "YYN",
      city_name: "Swift Current",
      country_code: "CA"
  },
  {
      id: "YYQ",
      name: "Churchill Airport",
      area_code: "YYQ",
      city_name: "Churchill",
      country_code: "CA"
  },
  {
      id: "YYR",
      name: "GOOSE BAY AIRPORT",
      area_code: "YYR",
      city_name: "Goose Bay",
      country_code: "CA"
  },
  {
      id: "YYU",
      name: "Kapuskasing",
      area_code: "YYU",
      city_name: "Kapuskasing",
      country_code: "CA"
  },
  {
      id: "YYY",
      name: "Mont-Joli Airport",
      area_code: "YYY",
      city_name: "Mont-Joli",
      country_code: "CA"
  },
  {
      id: "YZA",
      name: "Regional",
      area_code: "YZA",
      city_name: "Cache Creek/Ashcroft",
      country_code: "CA"
  },
  {
      id: "YZF",
      name: "YELLOWKNIFE AIRPORT",
      area_code: "YZF",
      city_name: "Yellowknife",
      country_code: "CA"
  },
  {
      id: "YZG",
      name: "Salluit",
      area_code: "YSW",
      city_name: "Salluit",
      country_code: "CA"
  },
  {
      id: "YZP",
      name: "SANDSPIT AIRPORT",
      area_code: "YZP",
      city_name: "Sandspit",
      country_code: "CA"
  },
  {
      id: "YZR",
      name: "Chris Hadfield Airport",
      area_code: "YZR",
      city_name: "Sarnia",
      country_code: "CA"
  },
  {
      id: "YZS",
      name: "CORAL HARBOUR AIRPORT",
      area_code: "YZS",
      city_name: "Coral Harbour",
      country_code: "CA"
  },
  {
      id: "YZT",
      name: "PORT HARDY AIRPORT",
      area_code: "YZT",
      city_name: "Port Hardy",
      country_code: "CA"
  },
  {
      id: "YZV",
      name: "Sept-Iles Airport",
      area_code: "YZV",
      city_name: "Sept-Iles",
      country_code: "CA"
  },
  {
      id: "YZX",
      name: "Greenwood",
      area_code: "YZX",
      city_name: "Greenwood",
      country_code: "CA"
  },
  {
      id: "ZAC",
      name: "York Landing Airport",
      area_code: "ZAC",
      city_name: "York Landing",
      country_code: "CA"
  },
  {
      id: "ZAO",
      name: "Lalbenque",
      area_code: "ZAO",
      city_name: "Cahors",
      country_code: "FR"
  },
  {
      id: "ZBL",
      name: "Biloela Airport",
      area_code: "ZBL",
      city_name: "Biloela",
      country_code: "AU"
  },
  {
      id: "ZBM",
      name: "Roland-Desourdy",
      area_code: "ZBM",
      city_name: "Bromont",
      country_code: "CA"
  },
  {
      id: "PZS",
      name: "Maquehue",
      area_code: "ZCO",
      city_name: "Temuco",
      country_code: "CL"
  },
  {
      id: "ZEC",
      name: "Secunda",
      area_code: "ZEC",
      city_name: "Secunda",
      country_code: "ZA"
  },
  {
      id: "ZER",
      name: "Ziro",
      area_code: "ZER",
      city_name: "Ziro",
      country_code: "IN"
  },
  {
      id: "ZGI",
      name: "Gods River Airport",
      area_code: "ZGI",
      city_name: "Gods River",
      country_code: "CA"
  },
  {
      id: "ZGR",
      name: "Little Grand Rapids",
      area_code: "ZGR",
      city_name: "Little Grand Rapids",
      country_code: "CA"
  },
  {
      id: "ZGS",
      name: "La Romaine Airport",
      area_code: "ZGS",
      city_name: "La Romaine",
      country_code: "CA"
  },
  {
      id: "ZIX",
      name: "Zhigansk Airport",
      area_code: "ZIX",
      city_name: "Zhigansk",
      country_code: "RU"
  },
  {
      id: "ZJN",
      name: "Swan River",
      area_code: "ZJN",
      city_name: "Swan River",
      country_code: "CA"
  },
  {
      id: "ZKE",
      name: "Kashechewan Airport",
      area_code: "ZKE",
      city_name: "Kashechewan",
      country_code: "CA"
  },
  {
      id: "ZKG",
      name: "KEGASKA AIRPORT",
      area_code: "ZKG",
      city_name: "Kegaska",
      country_code: "CA"
  },
  {
      id: "ZKP",
      name: "Zyryanka Airport",
      area_code: "ZKP",
      city_name: "Zyryanka",
      country_code: "RU"
  },
  {
      id: "ZLT",
      name: "LA TABATIERE AIRPORT",
      area_code: "ZLT",
      city_name: "La Tabatiere",
      country_code: "CA"
  },
  {
      id: "ZLX",
      name: "Zalengei",
      area_code: "ZLX",
      city_name: "Zalengei",
      country_code: "SD"
  },
  {
      id: "IGS",
      name: "Manching",
      area_code: "IGS",
      city_name: "Ingolstadt",
      country_code: "DE"
  },
  {
      id: "ZOS",
      name: "Canal Bajo Airport",
      area_code: "ZOS",
      city_name: "Osorno",
      country_code: "CL"
  },
  {
      id: "ZPB",
      name: "SACHIGO LAKE AIRPORT",
      area_code: "ZPB",
      city_name: "Sachigo Lake",
      country_code: "CA"
  },
  {
      id: "ZQS",
      name: "SPB",
      area_code: "ZQS",
      city_name: "Queen Charlotte",
      country_code: "CA"
  },
  {
      id: "ZSJ",
      name: "SANDY LAKE AIRPORT",
      area_code: "ZSJ",
      city_name: "Sandy Lake",
      country_code: "CA"
  },
  {
      id: "SZW",
      name: "Parchim",
      area_code: "SZW",
      city_name: "Schwerin",
      country_code: "DE"
  },
  {
      id: "ZTB",
      name: "Tete-a-la-Baleine Airport",
      area_code: "ZTB",
      city_name: "Tete-a-la Baleine",
      country_code: "CA"
  },
  {
      id: "ZTH",
      name: "Zakynthos Airport",
      area_code: "ZTH",
      city_name: "Zakynthos Island",
      country_code: "GR"
  },
  {
      id: "ZTM",
      name: "Shamattawa Airport",
      area_code: "ZTM",
      city_name: "Shamattawa",
      country_code: "CA"
  },
  {
      id: "ZUM",
      name: "CHURCHILL FALLS AIRPORT",
      area_code: "ZUM",
      city_name: "Churchill Falls",
      country_code: "CA"
  },
  {
      id: "ZVK",
      name: "SAVANNAKHET AIRPORT",
      area_code: "ZVK",
      city_name: "Savannakhet",
      country_code: "LA"
  },
  {
      id: "ZYL",
      name: "Osmani International Airport",
      area_code: "ZYL",
      city_name: "Sylhet",
      country_code: "BD"
  },
  {
      id: "ZZV",
      name: "Municipal",
      area_code: "ZZV",
      city_name: "Zanesville",
      country_code: "US"
  },
  {
      id: "MWX",
      name: "Muan International Airport",
      area_code: "MWX",
      city_name: "Muan",
      country_code: "KR"
  },
  {
      id: "SOJ",
      name: "Sorkjosen Airport",
      area_code: "SOJ",
      city_name: "Sorkjosen",
      country_code: "NO"
  },
  {
      id: "RIZ",
      name: "Shanzihe Airport",
      area_code: "RIZ",
      city_name: "Rizhao",
      country_code: "CN"
  },
  {
      id: "AMJ",
      name: "Cirilo Queiroz",
      area_code: "AMJ",
      city_name: "Almenara",
      country_code: "BR"
  },
  {
      id: "KEP",
      name: "NEPALGANJ AIRPORT",
      area_code: "KEP",
      city_name: "Nepalganj",
      country_code: "NP"
  },
  {
      id: "TVY",
      name: "Dawei Airport",
      area_code: "TVY",
      city_name: "Dawei",
      country_code: "MM"
  },
  {
      id: "UYU",
      name: "UYUNI AIRPORT",
      area_code: "UYU",
      city_name: "Uyuni",
      country_code: "BO"
  },
  {
      id: "PBG",
      name: "Plattsburgh International Airport",
      area_code: "PBG",
      city_name: "Plattsburgh",
      country_code: "US"
  },
  {
      id: "ZIA",
      name: "Zhukovskiy Airport",
      area_code: "ZIA",
      city_name: "Zhukovsky",
      country_code: "RU"
  },
  {
      id: "KOS",
      name: "Sihanoukville International Airport",
      area_code: "KOS",
      city_name: "Sihanoukville",
      country_code: "KH"
  },
  {
      id: "YNY",
      name: "Yangyang International Airport",
      area_code: "YNY",
      city_name: "Yangyang",
      country_code: "KR"
  },
  {
      id: "COQ",
      name: "CHOIBALSAN AIRPORT",
      area_code: "COQ",
      city_name: "Choibalsan",
      country_code: "MN"
  },
  {
      id: "DIQ",
      name: "Divinopolis Airport",
      area_code: "DIQ",
      city_name: "Divinopolis",
      country_code: "BR"
  },
  {
      id: "JSR",
      name: "Jessore AIRPORT",
      area_code: "JSR",
      city_name: "Jessore",
      country_code: "BD"
  },
  {
      id: "LUA",
      name: "LUKLA AIRPORT",
      area_code: "LUA",
      city_name: "Lukla",
      country_code: "NP"
  },
  {
      id: "DSS",
      name: "Blaise Diagne International Airport",
      area_code: "DKR",
      city_name: "Dakar",
      country_code: "SN"
  },
  {
      id: "LZN",
      name: "Matsu (Nangan)",
      area_code: "LZN",
      city_name: "Matsu (Nangan)",
      country_code: "TW"
  },
  {
      id: "LSH",
      name: "LASHIO AIRPORT",
      area_code: "LSH",
      city_name: "Lashio",
      country_code: "MM"
  },
  {
      id: "OHS",
      name: "Sohar",
      area_code: "OHS",
      city_name: "Sohar",
      country_code: "OM"
  },
  {
      id: "KVK",
      name: "Kirovsk Airport",
      area_code: "KVK",
      city_name: "Kirovsk/Apatity",
      country_code: "RU"
  },
  {
      id: "MYT",
      name: "MYITKYINA AIRPORT",
      area_code: "MYT",
      city_name: "Myitkyina",
      country_code: "MM"
  },
  {
      id: "KMV",
      name: "KALEMYO AIRPORT",
      area_code: "KMV",
      city_name: "Kalemyo",
      country_code: "MM"
  },
  {
      id: "BPE",
      name: "BEIDAIHE AIRPORT",
      area_code: "BPE",
      city_name: "Qinhuangdao",
      country_code: "CN"
  },
  {
      id: "BYK",
      name: "BOUAKE AIRPORT",
      area_code: "BYK",
      city_name: "Bouake",
      country_code: "CI"
  },
  {
      id: "CSY",
      name: "CHEBOKSARY AIRPORT",
      area_code: "CSY",
      city_name: "Cheboksary",
      country_code: "RU"
  },
  {
      id: "CXB",
      name: "Cox's Bazar Airport",
      area_code: "CXB",
      city_name: "Cox's Bazar",
      country_code: "BD"
  },
  {
      id: "DHM",
      name: "GAGGAL AIRPORT",
      area_code: "DHM",
      city_name: "Dharamsala",
      country_code: "IN"
  },
  {
      id: "FAV",
      name: "FAKARAVA AIRPORT",
      area_code: "FAV",
      city_name: "Fakarava",
      country_code: "PF"
  },
  {
      id: "JAU",
      name: "Francisco Carle Airport",
      area_code: "JAU",
      city_name: "Jauja",
      country_code: "PE"
  },
  {
      id: "KAW",
      name: "KAWTHAUNG AIRPORT",
      area_code: "KAW",
      city_name: "Kawthaung",
      country_code: "MM"
  },
  {
      id: "KYP",
      name: "KYAUKPYU AIRPORT",
      area_code: "KYP",
      city_name: "Kyaukpyu",
      country_code: "MM"
  },
  {
      id: "LAQ",
      name: "La Braq Airport",
      area_code: "LAQ",
      city_name: "Beida",
      country_code: "LY"
  },
  {
      id: "LFQ",
      name: "Qiaoli Airport",
      area_code: "LFQ",
      city_name: "Linfen",
      country_code: "CN"
  },
  {
      id: "LGQ",
      name: "Lago Agrio Airport",
      area_code: "LGQ",
      city_name: "Lago Agrio",
      country_code: "EC"
  },
  {
      id: "LPF",
      name: "Yuezhao Airport",
      area_code: "LPF",
      city_name: "Liupanshui",
      country_code: "CN"
  },
  {
      id: "MJI",
      name: "Mitiga Airport",
      area_code: "TIP",
      city_name: "Tripoli",
      country_code: "LY"
  },
  {
      id: "MJU",
      name: "Tampa Padang Airport",
      area_code: "MJU",
      city_name: "Mamuju",
      country_code: "ID"
  },
  {
      id: "MLO",
      name: "MILOS AIRPORT",
      area_code: "MLO",
      city_name: "Milos",
      country_code: "GR"
  },
  {
      id: "MNU",
      name: "Mawlamyine Airport",
      area_code: "MNU",
      city_name: "Mawlamyine",
      country_code: "MM"
  },
  {
      id: "MSZ",
      name: "Namibe Airport",
      area_code: "MSZ",
      city_name: "Namibe",
      country_code: "AO"
  },
  {
      id: "NBX",
      name: "Nabire Airport",
      area_code: "NBX",
      city_name: "Nabire",
      country_code: "ID"
  },
  {
      id: "PGH",
      name: "PANTNAGAR AIRPORT",
      area_code: "PGH",
      city_name: "Pantnagar",
      country_code: "IN"
  },
  {
      id: "PJG",
      name: "PANJGUR AIRPORT",
      area_code: "PJG",
      city_name: "Panjgur",
      country_code: "PK"
  },
  {
      id: "RAH",
      name: "RAFHA AIRPORT",
      area_code: "RAH",
      city_name: "Rafha",
      country_code: "SA"
  },
  {
      id: "RBQ",
      name: "RURRENABAQUE AIRPORT",
      area_code: "RBQ",
      city_name: "Rurrenabaque",
      country_code: "BO"
  },
  {
      id: "SHH",
      name: "SHISHMAREF AIRPORT",
      area_code: "SHH",
      city_name: "Shishmaref",
      country_code: "US"
  },
  {
      id: "SHM",
      name: "Shirahama Airport",
      area_code: "SHM",
      city_name: "Shirahama",
      country_code: "JP"
  },
  {
      id: "SIS",
      name: "SISHEN AIRPORT",
      area_code: "SIS",
      city_name: "Sishen",
      country_code: "ZA"
  },
  {
      id: "SKX",
      name: "SARANSK AIRPORT",
      area_code: "SKX",
      city_name: "Saransk",
      country_code: "RU"
  },
  {
      id: "SXK",
      name: "Saumlaki Airport",
      area_code: "SXK",
      city_name: "Saumlaki",
      country_code: "ID"
  },
  {
      id: "TKD",
      name: "TAKORADI AIRPORT",
      area_code: "TKD",
      city_name: "Takoradi",
      country_code: "GH"
  },
  {
      id: "TNJ",
      name: "Raja Haji Fisabilillah Airport",
      area_code: "TNJ",
      city_name: "Tanjung Pinang",
      country_code: "ID"
  },
  {
      id: "TTA",
      name: "Tan Tan Airport",
      area_code: "TTA",
      city_name: "Tan-Tan",
      country_code: "MA"
  },
  {
      id: "VKT",
      name: "VORKUTA AIRPORT",
      area_code: "VKT",
      city_name: "Vorkuta",
      country_code: "RU"
  },
  {
      id: "YKH",
      name: "LANQI AIRPORT",
      area_code: "YKH",
      city_name: "Yingkou",
      country_code: "CN"
  },
  {
      id: "DEA",
      name: "DERA GHAZI KHAN AIRPORT",
      area_code: "DEA",
      city_name: "Dera Ghazi Khan",
      country_code: "PK"
  },
  {
      id: "NER",
      name: "Neryungri Airport",
      area_code: "NER",
      city_name: "Neryungri",
      country_code: "RU"
  },
  {
      id: "KDW",
      name: "Victoria Reservoir SPB",
      area_code: "KDW",
      city_name: "Kandy",
      country_code: "LK"
  },
  {
      id: "KDZ",
      name: "Katugastota Airport",
      area_code: "KDW",
      city_name: "Kandy",
      country_code: "LK"
  },
  {
      id: "UAH",
      name: "UA HUKA AIRPORT",
      area_code: "UAH",
      city_name: "Ua Huka",
      country_code: "PF"
  },
  {
      id: "OUT",
      name: "Bousso",
      area_code: "OUT",
      city_name: "Bousso",
      country_code: "TD"
  },
  {
      id: "QSZ",
      name: "Shache Airport",
      area_code: "QSZ",
      city_name: "Shache",
      country_code: "CN"
  },
  {
      id: "ALD",
      name: "Alerta",
      area_code: "ALD",
      city_name: "Alerta",
      country_code: "PE"
  },
  {
      id: "KCF",
      name: "Kadanwari Airport",
      area_code: "KCF",
      city_name: "Kadanwari",
      country_code: "PK"
  },
  {
      id: "VEV",
      name: "Barakoma",
      area_code: "VEV",
      city_name: "Barakoma",
      country_code: "SB"
  },
  {
      id: "SQJ",
      name: "SHAXIAN AIRPORT",
      area_code: "SQJ",
      city_name: "Sanming",
      country_code: "CN"
  },
  {
      id: "JAF",
      name: "Kankesanturai",
      area_code: "JAF",
      city_name: "Jaffna",
      country_code: "LK"
  },
  {
      id: "OHO",
      name: "Okhotsk",
      area_code: "OHO",
      city_name: "Okhotsk",
      country_code: "RU"
  },
  {
      id: "GFE",
      name: "Grenfell",
      area_code: "GFE",
      city_name: "Grenfell",
      country_code: "AU"
  },
  {
      id: "DMB",
      name: "Taraz Airport",
      area_code: "DMB",
      city_name: "Taraz",
      country_code: "KZ"
  },
  {
      id: "KKZ",
      name: "Koh Kong",
      area_code: "KKZ",
      city_name: "Koh Kong",
      country_code: "KH"
  },
  {
      id: "TXF",
      name: "TEIXEIRA DE FREITAS AIRPORT",
      area_code: "TXF",
      city_name: "Teixeira de Freitas",
      country_code: "BR"
  },
  {
      id: "KXF",
      name: "Koro Island Airport",
      area_code: "KXF",
      city_name: "Koro Island",
      country_code: "FJ"
  },
  {
      id: "BAR",
      name: "BO AO AIRPORT",
      area_code: "BAR",
      city_name: "Qionghai",
      country_code: "CN"
  },
  {
      id: "ZAR",
      name: "Zaria",
      area_code: "ZAR",
      city_name: "Zaria",
      country_code: "NG"
  },
  {
      id: "CHF",
      name: "Jinhae",
      area_code: "CHF",
      city_name: "Jinhae",
      country_code: "KR"
  },
  {
      id: "BMN",
      name: "Bamarni",
      area_code: "BMN",
      city_name: "Bamarni",
      country_code: "IQ"
  },
  {
      id: "AUD",
      name: "Augustus Downs",
      area_code: "AUD",
      city_name: "Augustus Downs",
      country_code: "AU"
  },
  {
      id: "VIA",
      name: "Angelo Ponzoni",
      area_code: "VIA",
      city_name: "Videira",
      country_code: "BR"
  },
  {
      id: "BWA",
      name: "BHAIRAWA AIRPORT",
      area_code: "BWA",
      city_name: "Bhairahawa",
      country_code: "NP"
  },
  {
      id: "BUT",
      name: "Bathpalathang Airport",
      area_code: "BUT",
      city_name: "Jakar",
      country_code: "BT"
  },
  {
      id: "GLU",
      name: "Gelephu Airport",
      area_code: "GLU",
      city_name: "Gelephu",
      country_code: "BT"
  },
  {
      id: "FVM",
      name: "Fuvahmulah Airport",
      area_code: "FVM",
      city_name: "Fuvahmulak Island",
      country_code: "MV"
  },
  {
      id: "HAQ",
      name: "Hanimaadhoo Airport",
      area_code: "HAQ",
      city_name: "Hanimaadhoo Island",
      country_code: "MV"
  },
  {
      id: "IFU",
      name: "Ifuru Airport",
      area_code: "IFU",
      city_name: "Ifuru",
      country_code: "MV"
  },
  {
      id: "GHK",
      name: "Gahcho Kue",
      area_code: "GHK",
      city_name: "Kennedy Lake",
      country_code: "CA"
  },
  {
      id: "CWJ",
      name: "WASHAN AIRPORT",
      area_code: "CWJ",
      city_name: "Cangyuan",
      country_code: "CN"
  },
  {
      id: "XES",
      name: "Grand Geneva Resort",
      area_code: "XES",
      city_name: "Lake Geneva",
      country_code: "US"
  },
  {
      id: "CAH",
      name: "CA MAU AIRPORT",
      area_code: "CAH",
      city_name: "Ca Mau",
      country_code: "VN"
  },
  {
      id: "PUT",
      name: "Sri Sathya Sai",
      area_code: "PUT",
      city_name: "Puttaparthi",
      country_code: "IN"
  },
  {
      id: "CIR",
      name: "Regional",
      area_code: "CIR",
      city_name: "Cairo",
      country_code: "US"
  },
  {
      id: "SQD",
      name: "Sanqingshan Airport",
      area_code: "SQD",
      city_name: "Shangrao",
      country_code: "CN"
  },
  {
      id: "WDS",
      name: "WUDANGSHAN AIRPORT",
      area_code: "WDS",
      city_name: "Shiyan",
      country_code: "CN"
  },
  {
      id: "TJS",
      name: "Tanjung Selor Airport",
      area_code: "TJS",
      city_name: "Tanjung Selor",
      country_code: "ID"
  },
  {
      id: "HVD",
      name: "KHOVD AIRPORT",
      area_code: "HVD",
      city_name: "Khovd",
      country_code: "MN"
  },
  {
      id: "ULO",
      name: "ULAANGOM AIRPORT",
      area_code: "ULO",
      city_name: "Ulaangom",
      country_code: "MN"
  },
  {
      id: "ULG",
      name: "Ulgit Airport",
      area_code: "ULG",
      city_name: "Olgii",
      country_code: "MN"
  },
  {
      id: "ULZ",
      name: "Donoi Airport",
      area_code: "ULZ",
      city_name: "Uliastai",
      country_code: "MN"
  },
  {
      id: "LTI",
      name: "Altai Airport",
      area_code: "LTI",
      city_name: "Altai",
      country_code: "MN"
  },
  {
      id: "MXV",
      name: "MORON AIRPORT",
      area_code: "MXV",
      city_name: "Moron",
      country_code: "MN"
  },
  {
      id: "PAG",
      name: "PAGADIAN AIRPORT",
      area_code: "PAG",
      city_name: "Pagadian",
      country_code: "PH"
  },
  {
      id: "CNN",
      name: "Kannur Airport",
      area_code: "CNN",
      city_name: "Kannur",
      country_code: "IN"
  },
  {
      id: "EPA",
      name: "El Palomar",
      area_code: "EPA",
      city_name: "El Palomar",
      country_code: "AR"
  },
  {
      id: "PKX",
      name: "Daxing Intl. Airport",
      area_code: "BJS",
      city_name: "Beijing",
      country_code: "CN"
  },
  {
      id: "WMT",
      name: "Maotai Airport",
      area_code: "ZYI",
      city_name: "Zunyi",
      country_code: "CN"
  },
  {
      id: "AFR",
      name: "Afore",
      area_code: "AFR",
      city_name: "Afore",
      country_code: "PG"
  },
  {
      id: "THA",
      name: "Regional",
      area_code: "THA",
      city_name: "Tullahoma",
      country_code: "US"
  },
  {
      id: "IHR",
      name: "Iranshahr",
      area_code: "IHR",
      city_name: "Iranshahr",
      country_code: "IR"
  },
  {
      id: "SIC",
      name: "Sinop Airport",
      area_code: "SIC",
      city_name: "San Jose Island",
      country_code: "PA"
  },
  {
      id: "AAB",
      name: "Arrabury",
      area_code: "AAB",
      city_name: "Arrabury",
      country_code: "AU"
  },
  {
      id: "AAD",
      name: "Adado Airport",
      area_code: "AAD",
      city_name: "Adado",
      country_code: "SO"
  },
  {
      id: "AAI",
      name: "Arraias",
      area_code: "AAI",
      city_name: "Arraias",
      country_code: "BR"
  },
  {
      id: "AAJ",
      name: "Cajana",
      area_code: "AAJ",
      city_name: "Awaradam",
      country_code: "SR"
  },
  {
      id: "AAK",
      name: "Aranuka",
      area_code: "AAK",
      city_name: "Aranuka",
      country_code: "KI"
  },
  {
      id: "AAO",
      name: "Anaco",
      area_code: "AAO",
      city_name: "Anaco",
      country_code: "VE"
  },
  {
      id: "AAP",
      name: "AP Tumenggung Pranoto",
      area_code: "SRI",
      city_name: "Samarinda",
      country_code: "ID"
  },
  {
      id: "AAS",
      name: "Apalapsili",
      area_code: "AAS",
      city_name: "Apalapsili",
      country_code: "ID"
  },
  {
      id: "AAU",
      name: "Asau",
      area_code: "AAU",
      city_name: "Asau",
      country_code: "WS"
  },
  {
      id: "AAV",
      name: "Allah Valley",
      area_code: "AAV",
      city_name: "Surallah",
      country_code: "PH"
  },
  {
      id: "AAZ",
      name: "Quetzaltenango",
      area_code: "AAZ",
      city_name: "Quetzaltenango",
      country_code: "GT"
  },
  {
      id: "ABF",
      name: "Abaiang",
      area_code: "ABF",
      city_name: "Abaiang",
      country_code: "KI"
  },
  {
      id: "ABG",
      name: "Abingdon Downs",
      area_code: "ABG",
      city_name: "Abingdon Downs",
      country_code: "AU"
  },
  {
      id: "ABH",
      name: "Alpha",
      area_code: "ABH",
      city_name: "Alpha",
      country_code: "AU"
  },
  {
      id: "ABK",
      name: "Kebri Dehar",
      area_code: "ABK",
      city_name: "Kebri Dehar",
      country_code: "ET"
  },
  {
      id: "ABN",
      name: "Albina",
      area_code: "ABN",
      city_name: "Albina",
      country_code: "SR"
  },
  {
      id: "ABO",
      name: "Aboisso",
      area_code: "ABO",
      city_name: "Aboisso",
      country_code: "CI"
  },
  {
      id: "ABP",
      name: "Atkamba",
      area_code: "ABP",
      city_name: "Atkamba",
      country_code: "PG"
  },
  {
      id: "ABU",
      name: "Atambua Airport",
      area_code: "ABU",
      city_name: "Atambua",
      country_code: "ID"
  },
  {
      id: "ABW",
      name: "Abau",
      area_code: "ABW",
      city_name: "Abau",
      country_code: "PG"
  },
  {
      id: "ACB",
      name: "Antrim County",
      area_code: "ACB",
      city_name: "Bellaire",
      country_code: "US"
  },
  {
      id: "ACJ",
      name: "Anuradhapura",
      area_code: "ACJ",
      city_name: "Anuradhapura",
      country_code: "LK"
  },
  {
      id: "ACL",
      name: "Aguaclara",
      area_code: "ACL",
      city_name: "Aguaclara",
      country_code: "CO"
  },
  {
      id: "ACM",
      name: "Arica",
      area_code: "ACM",
      city_name: "Arica",
      country_code: "CO"
  },
  {
      id: "ACN",
      name: "International",
      area_code: "ACN",
      city_name: "Ciudad Acuna",
      country_code: "MX"
  },
  {
      id: "ACP",
      name: "Maragheh",
      area_code: "ACP",
      city_name: "Maragheh",
      country_code: "IR"
  },
  {
      id: "ACS",
      name: "Achinsk",
      area_code: "ACS",
      city_name: "Achinsk",
      country_code: "RU"
  },
  {
      id: "ACU",
      name: "ACHUTUPO AIRPORT",
      area_code: "ACU",
      city_name: "Achutupo",
      country_code: "PA"
  },
  {
      id: "ACZ",
      name: "Zabol Airport",
      area_code: "ACZ",
      city_name: "Zabol",
      country_code: "IR"
  },
  {
      id: "ADC",
      name: "Andakombe",
      area_code: "ADC",
      city_name: "Andakombe",
      country_code: "PG"
  },
  {
      id: "ADH",
      name: "ALDAN AIRPORT",
      area_code: "ADH",
      city_name: "Aldan",
      country_code: "RU"
  },
  {
      id: "ADI",
      name: "Arandis",
      area_code: "ADI",
      city_name: "Arandis",
      country_code: "NA"
  },
  {
      id: "ADN",
      name: "Andes",
      area_code: "ADN",
      city_name: "Andes",
      country_code: "CO"
  },
  {
      id: "ADO",
      name: "Andamooka",
      area_code: "ADO",
      city_name: "Andamooka",
      country_code: "AU"
  },
  {
      id: "ADP",
      name: "Gal Oya",
      area_code: "ADP",
      city_name: "Ampara",
      country_code: "LK"
  },
  {
      id: "ADR",
      name: "Robert F. Swinnie",
      area_code: "ADR",
      city_name: "Andrews",
      country_code: "US"
  },
  {
      id: "ADV",
      name: "Addeain",
      area_code: "ADV",
      city_name: "Addeain",
      country_code: "SD"
  },
  {
      id: "ADY",
      name: "Alldays",
      area_code: "ADY",
      city_name: "Alldays",
      country_code: "ZA"
  },
  {
      id: "AEA",
      name: "Abemama",
      area_code: "AEA",
      city_name: "Abemama",
      country_code: "KI"
  },
  {
      id: "AEE",
      name: "Adareil",
      area_code: "AEE",
      city_name: "Adareil",
      country_code: "SS"
  },
  {
      id: "AEG",
      name: "Aek Godang Airport",
      area_code: "AEG",
      city_name: "Padangsidempuan",
      country_code: "ID"
  },
  {
      id: "AEK",
      name: "Aseki",
      area_code: "AEK",
      city_name: "Aseki",
      country_code: "PG"
  },
  {
      id: "AEM",
      name: "Amgu",
      area_code: "AEM",
      city_name: "Amgu",
      country_code: "RU"
  },
  {
      id: "AEO",
      name: "Aioun El Atrouss",
      area_code: "AEO",
      city_name: "Aioun el Atrouss",
      country_code: "MR"
  },
  {
      id: "AEQ",
      name: "Ar Horqin",
      area_code: "CIF",
      city_name: "Chifeng",
      country_code: "CN"
  },
  {
      id: "AET",
      name: "ALLAKAKET AIRPORT",
      area_code: "AET",
      city_name: "Allakaket",
      country_code: "US"
  },
  {
      id: "AEU",
      name: "Abu Musa Airport",
      area_code: "AEU",
      city_name: "Abu Musa Island",
      country_code: "IR"
  },
  {
      id: "AFD",
      name: "Port Alfred",
      area_code: "AFD",
      city_name: "Port Alfred",
      country_code: "ZA"
  },
  {
      id: "AFI",
      name: "Amalfi",
      area_code: "AFI",
      city_name: "Amalfi",
      country_code: "CO"
  },
  {
      id: "AFN",
      name: "Silver Ranch",
      area_code: "AFN",
      city_name: "Jaffrey",
      country_code: "US"
  },
  {
      id: "AFO",
      name: "Municipal",
      area_code: "AFO",
      city_name: "Afton",
      country_code: "US"
  },
  {
      id: "AFT",
      name: "Afutara",
      area_code: "AFT",
      city_name: "Afutara",
      country_code: "SB"
  },
  {
      id: "AFW",
      name: "Alliance",
      area_code: "FTW",
      city_name: "Fort Worth",
      country_code: "US"
  },
  {
      id: "AFZ",
      name: "Sabzevar Airport",
      area_code: "AFZ",
      city_name: "Sabzevar",
      country_code: "IR"
  },
  {
      id: "AGD",
      name: "Anggi",
      area_code: "AGD",
      city_name: "Anggi",
      country_code: "ID"
  },
  {
      id: "AGG",
      name: "Angoram",
      area_code: "AGG",
      city_name: "Angoram",
      country_code: "PG"
  },
  {
      id: "AGI",
      name: "Wageningen",
      area_code: "AGI",
      city_name: "Wageningen",
      country_code: "SR"
  },
  {
      id: "AGJ",
      name: "Aguni",
      area_code: "AGJ",
      city_name: "Aguni",
      country_code: "JP"
  },
  {
      id: "AGK",
      name: "Kagua",
      area_code: "AGK",
      city_name: "Kagua",
      country_code: "PG"
  },
  {
      id: "AGL",
      name: "Wanigela",
      area_code: "AGL",
      city_name: "Wanigela",
      country_code: "PG"
  },
  {
      id: "AGW",
      name: "Agnew",
      area_code: "AGW",
      city_name: "Agnew",
      country_code: "AU"
  },
  {
      id: "AGZ",
      name: "Aggeneys",
      area_code: "AGZ",
      city_name: "Aggeneys",
      country_code: "ZA"
  },
  {
      id: "AHE",
      name: "AHE AIRPORT",
      area_code: "AHE",
      city_name: "Ahe",
      country_code: "PF"
  },
  {
      id: "AHF",
      name: "Municipal",
      area_code: "AHF",
      city_name: "Arapahoe",
      country_code: "US"
  },
  {
      id: "AHH",
      name: "Municipal",
      area_code: "AHH",
      city_name: "Amery",
      country_code: "US"
  },
  {
      id: "AHL",
      name: "Aishalton",
      area_code: "AHL",
      city_name: "Aishalton",
      country_code: "GY"
  },
  {
      id: "AHM",
      name: "Municipal",
      area_code: "AHM",
      city_name: "Ashland",
      country_code: "US"
  },
  {
      id: "AHS",
      name: "Ahuas",
      area_code: "AHS",
      city_name: "Ahuas",
      country_code: "HN"
  },
  {
      id: "AHW",
      name: "Saih Rawl",
      area_code: "AHW",
      city_name: "Saih Rawl",
      country_code: "OM"
  },
  {
      id: "AHY",
      name: "Ambatolahy",
      area_code: "AHY",
      city_name: "Ambatolahy",
      country_code: "MG"
  },
  {
      id: "AHZ",
      name: "L'Alpe-d'Huez",
      area_code: "AHZ",
      city_name: "L'Alpe-d'Huez",
      country_code: "FR"
  },
  {
      id: "AIC",
      name: "Ailinglapalap",
      area_code: "AIC",
      city_name: "Airok",
      country_code: "MH"
  },
  {
      id: "AIE",
      name: "Aiome",
      area_code: "AIE",
      city_name: "Aiome",
      country_code: "PG"
  },
  {
      id: "AIF",
      name: "Marcelo P. Halzhausen",
      area_code: "AIF",
      city_name: "Assis",
      country_code: "BR"
  },
  {
      id: "AIG",
      name: "Yalinga",
      area_code: "AIG",
      city_name: "Yalinga",
      country_code: "CF"
  },
  {
      id: "AIH",
      name: "Aiambak",
      area_code: "AIH",
      city_name: "Aiambak",
      country_code: "PG"
  },
  {
      id: "AII",
      name: "Ali Sabieh",
      area_code: "AII",
      city_name: "Ali Sabieh",
      country_code: "DJ"
  },
  {
      id: "AIL",
      name: "Ailigandi",
      area_code: "AIL",
      city_name: "Ailigandi",
      country_code: "PA"
  },
  {
      id: "AIM",
      name: "Ailuk Island",
      area_code: "AIM",
      city_name: "Ailuk Island",
      country_code: "MH"
  },
  {
      id: "AIP",
      name: "Adampur",
      area_code: "AIP",
      city_name: "Adampur",
      country_code: "MH"
  },
  {
      id: "AIR",
      name: "Aripuana",
      area_code: "AIR",
      city_name: "Aripuana",
      country_code: "BR"
  },
  {
      id: "AIS",
      name: "Arorae",
      area_code: "AIS",
      city_name: "Arorae",
      country_code: "KI"
  },
  {
      id: "AIU",
      name: "Atiu Island",
      area_code: "AIU",
      city_name: "Atiu Island",
      country_code: "CK"
  },
  {
      id: "AIW",
      name: "Ai-Ais",
      area_code: "AIW",
      city_name: "Ai-Ais",
      country_code: "NA"
  },
  {
      id: "AJJ",
      name: "Akjoujt",
      area_code: "AJJ",
      city_name: "Akjoujt",
      country_code: "MR"
  },
  {
      id: "AJK",
      name: "ARAAK AIRPORT",
      area_code: "AJK",
      city_name: "Arak",
      country_code: "IR"
  },
  {
      id: "AJN",
      name: "OUANI AIRPORT",
      area_code: "AJN",
      city_name: "Anjouan",
      country_code: "KM"
  },
  {
      id: "AJS",
      name: "Punta Abreojos",
      area_code: "AJS",
      city_name: "Punta Abreojos",
      country_code: "MX"
  },
  {
      id: "AJY",
      name: "Agades Airport",
      area_code: "AJY",
      city_name: "Agadez",
      country_code: "NE"
  },
  {
      id: "AKE",
      name: "Akieni",
      area_code: "AKE",
      city_name: "Akieni",
      country_code: "GA"
  },
  {
      id: "AKF",
      name: "Kufrah Airport",
      area_code: "AKF",
      city_name: "Kufra",
      country_code: "LY"
  },
  {
      id: "AKG",
      name: "Anguganak",
      area_code: "AKG",
      city_name: "Anguganak",
      country_code: "PG"
  },
  {
      id: "AKH",
      name: "Prince Sultan AB",
      area_code: "AKH",
      city_name: "Al Kharj",
      country_code: "SA"
  },
  {
      id: "AKI",
      name: "AKIAK AIRPORT",
      area_code: "AKI",
      city_name: "Akiak",
      country_code: "US"
  },
  {
      id: "AKM",
      name: "Zakouma",
      area_code: "AKM",
      city_name: "Zakouma",
      country_code: "TD"
  },
  {
      id: "AKP",
      name: "Anaktuvuk Pass Airport",
      area_code: "AKP",
      city_name: "Anaktuvuk Pass",
      country_code: "US"
  },
  {
      id: "AKQ",
      name: "Astra Ksetra",
      area_code: "AKQ",
      city_name: "Menggala",
      country_code: "ID"
  },
  {
      id: "AKR",
      name: "Akure",
      area_code: "AKR",
      city_name: "Akure",
      country_code: "NG"
  },
  {
      id: "AKT",
      name: "RAF Station",
      area_code: "AKT",
      city_name: "Akrotiri",
      country_code: "CY"
  },
  {
      id: "AKW",
      name: "Aghajari",
      area_code: "AKW",
      city_name: "Aghajari",
      country_code: "IR"
  },
  {
      id: "ALI",
      name: "International",
      area_code: "ALI",
      city_name: "Alice",
      country_code: "US"
  },
  {
      id: "ALK",
      name: "Asela",
      area_code: "ALK",
      city_name: "Asela",
      country_code: "ET"
  },
  {
      id: "ALN",
      name: "St Louis Regional",
      area_code: "ALN",
      city_name: "Alton",
      country_code: "US"
  },
  {
      id: "ALQ",
      name: "G.Machado Ramos",
      area_code: "ALQ",
      city_name: "Alegrete",
      country_code: "BR"
  },
  {
      id: "ALT",
      name: "Alenquer",
      area_code: "ALT",
      city_name: "Alenquer",
      country_code: "BR"
  },
  {
      id: "ALU",
      name: "Alula",
      area_code: "ALU",
      city_name: "Alula",
      country_code: "SO"
  },
  {
      id: "ALZ",
      name: "Sea Plane Base",
      area_code: "ALZ",
      city_name: "Alitak",
      country_code: "US"
  },
  {
      id: "AMB",
      name: "Ambilobe",
      area_code: "AMB",
      city_name: "Ambilobe",
      country_code: "MG"
  },
  {
      id: "AMC",
      name: "Am Timan",
      area_code: "AMC",
      city_name: "Am Timan",
      country_code: "TD"
  },
  {
      id: "AME",
      name: "Alto Molocue",
      area_code: "AME",
      city_name: "Alto Molocue",
      country_code: "MZ"
  },
  {
      id: "AMF",
      name: "Ama",
      area_code: "AMF",
      city_name: "Ama",
      country_code: "PG"
  },
  {
      id: "AMG",
      name: "Amboin",
      area_code: "AMG",
      city_name: "Amboin",
      country_code: "PG"
  },
  {
      id: "AML",
      name: "Puerto Armuelles",
      area_code: "AML",
      city_name: "Puerto Armuelles",
      country_code: "PA"
  },
  {
      id: "AMN",
      name: "Gratiot Community",
      area_code: "AMN",
      city_name: "Alma",
      country_code: "US"
  },
  {
      id: "AMO",
      name: "Mao",
      area_code: "AMO",
      city_name: "Mao",
      country_code: "TD"
  },
  {
      id: "AMP",
      name: "Ampanihy",
      area_code: "AMP",
      city_name: "Ampanihy",
      country_code: "MG"
  },
  {
      id: "AMT",
      name: "Amata",
      area_code: "AMT",
      city_name: "Amata",
      country_code: "AU"
  },
  {
      id: "AMU",
      name: "Amanab",
      area_code: "AMU",
      city_name: "Amanab",
      country_code: "PG"
  },
  {
      id: "AMV",
      name: "Amderma",
      area_code: "AMV",
      city_name: "Amderma",
      country_code: "RU"
  },
  {
      id: "AMX",
      name: "Ammaroo",
      area_code: "AMX",
      city_name: "Ammaroo",
      country_code: "AU"
  },
  {
      id: "AMY",
      name: "Ambatomainty",
      area_code: "AMY",
      city_name: "Ambatomainty",
      country_code: "MG"
  },
  {
      id: "ANA",
      name: "Anaheim",
      area_code: "MRE",
      city_name: "Maasai Mara",
      country_code: "KE"
  },
  {
      id: "ANJ",
      name: "Zanaga",
      area_code: "ANJ",
      city_name: "Zanaga",
      country_code: "CG"
  },
  {
      id: "ANL",
      name: "Andulo",
      area_code: "ANL",
      city_name: "Andulo",
      country_code: "AO"
  },
  {
      id: "ANM",
      name: "Antsirabato",
      area_code: "ANM",
      city_name: "Antalaha",
      country_code: "MG"
  },
  {
      id: "ANO",
      name: "Angoche",
      area_code: "ANO",
      city_name: "Angoche",
      country_code: "MZ"
  },
  {
      id: "ANQ",
      name: "Tri-State Steuben Co.",
      area_code: "ANQ",
      city_name: "Angola",
      country_code: "US"
  },
  {
      id: "ANS",
      name: "ANDAHUAYLAS AIRPORT",
      area_code: "ANS",
      city_name: "Andahuaylas",
      country_code: "PE"
  },
  {
      id: "ANV",
      name: "ANVIK AIRPORT",
      area_code: "ANV",
      city_name: "Anvik",
      country_code: "US"
  },
  {
      id: "ANY",
      name: "Municipal",
      area_code: "ANY",
      city_name: "Anthony",
      country_code: "US"
  },
  {
      id: "ANZ",
      name: "Angus Downs Station",
      area_code: "ANZ",
      city_name: "Angus Downs Station",
      country_code: "AU"
  },
  {
      id: "AOA",
      name: "Aroa",
      area_code: "AOA",
      city_name: "Aroa",
      country_code: "PG"
  },
  {
      id: "AOB",
      name: "Annanberg",
      area_code: "AOB",
      city_name: "Annanberg",
      country_code: "PG"
  },
  {
      id: "AOD",
      name: "Abou Deia",
      area_code: "AOD",
      city_name: "Abou Deia",
      country_code: "TD"
  },
  {
      id: "AOM",
      name: "Adam",
      area_code: "AOM",
      city_name: "Adam",
      country_code: "OM"
  },
  {
      id: "AON",
      name: "Arona",
      area_code: "AON",
      city_name: "Arona",
      country_code: "PG"
  },
  {
      id: "AOP",
      name: "Alfredo V. Sara Bauer",
      area_code: "AOP",
      city_name: "Andoas",
      country_code: "PE"
  },
  {
      id: "AOU",
      name: "Attapeu",
      area_code: "AOU",
      city_name: "Attapeu",
      country_code: "LA"
  },
  {
      id: "APB",
      name: "Apolo",
      area_code: "APB",
      city_name: "Apolo",
      country_code: "BO"
  },
  {
      id: "APH",
      name: "A.P. Hill AAF",
      area_code: "APH",
      city_name: "Bowling Green",
      country_code: "US"
  },
  {
      id: "API",
      name: "Luis F. Gomez Nino AB",
      area_code: "API",
      city_name: "Apiay",
      country_code: "CO"
  },
  {
      id: "APK",
      name: "APATAKI AIRPORT",
      area_code: "APK",
      city_name: "Apataki",
      country_code: "PF"
  },
  {
      id: "APP",
      name: "Asapa",
      area_code: "APP",
      city_name: "Asapa",
      country_code: "PG"
  },
  {
      id: "APQ",
      name: "Arapiraca",
      area_code: "APQ",
      city_name: "Arapiraca",
      country_code: "BR"
  },
  {
      id: "APR",
      name: "April River",
      area_code: "APR",
      city_name: "April River",
      country_code: "PG"
  },
  {
      id: "APT",
      name: "Marion County",
      area_code: "APT",
      city_name: "Jasper",
      country_code: "US"
  },
  {
      id: "APU",
      name: "Captain Joao Busse",
      area_code: "APU",
      city_name: "Apucarana",
      country_code: "BR"
  },
  {
      id: "APX",
      name: "Alberto Bertelli",
      area_code: "APX",
      city_name: "Arapongas",
      country_code: "BR"
  },
  {
      id: "APY",
      name: "Alto Parnaiba",
      area_code: "APY",
      city_name: "Alto Parnaiba",
      country_code: "BR"
  },
  {
      id: "APZ",
      name: "Zapala",
      area_code: "APZ",
      city_name: "Zapala",
      country_code: "AR"
  },
  {
      id: "AQB",
      name: "Quiche",
      area_code: "AQB",
      city_name: "Quiche",
      country_code: "GT"
  },
  {
      id: "AQM",
      name: "Ariquemes",
      area_code: "AQM",
      city_name: "Ariquemes",
      country_code: "BR"
  },
  {
      id: "AQS",
      name: "Saqani",
      area_code: "AQS",
      city_name: "Saqani",
      country_code: "FJ"
  },
  {
      id: "AQY",
      name: "Girdwood",
      area_code: "AQY",
      city_name: "Girdwood",
      country_code: "US"
  },
  {
      id: "ARE",
      name: "Antonio Juarbe Pol",
      area_code: "ARE",
      city_name: "Arecibo",
      country_code: "PR"
  },
  {
      id: "ARF",
      name: "Acaricuara",
      area_code: "ARF",
      city_name: "Acaricuara",
      country_code: "CO"
  },
  {
      id: "ARJ",
      name: "Arso",
      area_code: "ARJ",
      city_name: "Arso",
      country_code: "ID"
  },
  {
      id: "ARL",
      name: "Arly",
      area_code: "ARL",
      city_name: "Arly",
      country_code: "BF"
  },
  {
      id: "ARO",
      name: "Arboletes",
      area_code: "ARO",
      city_name: "Arboletes",
      country_code: "CO"
  },
  {
      id: "ARP",
      name: "Aragip",
      area_code: "ARP",
      city_name: "Aragip",
      country_code: "PG"
  },
  {
      id: "ARQ",
      name: "El Troncal",
      area_code: "ARQ",
      city_name: "Arauquita",
      country_code: "CO"
  },
  {
      id: "ARR",
      name: "Alto Rio Senguer",
      area_code: "ARR",
      city_name: "Alto Rio Senguer",
      country_code: "AR"
  },
  {
      id: "ARS",
      name: "Aragarcas",
      area_code: "ARS",
      city_name: "Aragarcas",
      country_code: "BR"
  },
  {
      id: "ARX",
      name: "Dragao do Mar",
      area_code: "ARX",
      city_name: "Aracati",
      country_code: "US"
  },
  {
      id: "ARZ",
      name: "N'zeto",
      area_code: "ARZ",
      city_name: "N'zeto",
      country_code: "AO"
  },
  {
      id: "ASC",
      name: "Ascension",
      area_code: "ASC",
      city_name: "Ascension",
      country_code: "BO"
  },
  {
      id: "ASG",
      name: "Ashburton",
      area_code: "ASG",
      city_name: "Ashburton",
      country_code: "NZ"
  },
  {
      id: "ASQ",
      name: "Austin",
      area_code: "ASQ",
      city_name: "Austin",
      country_code: "US"
  },
  {
      id: "ASS",
      name: "Arathusa Safari Lodge Airport",
      area_code: "ASS",
      city_name: "Arathusa Safari Lodge",
      country_code: "ZA"
  },
  {
      id: "ASV",
      name: "AMBOSELI AIRPORT",
      area_code: "ASV",
      city_name: "Amboseli",
      country_code: "KE"
  },
  {
      id: "ASX",
      name: "John F. Kennedy Mem.",
      area_code: "ASX",
      city_name: "Ashland",
      country_code: "US"
  },
  {
      id: "ASY",
      name: "Municipal",
      area_code: "ASY",
      city_name: "Ashley",
      country_code: "US"
  },
  {
      id: "ASZ",
      name: "Asirim",
      area_code: "ASZ",
      city_name: "Asirim",
      country_code: "PG"
  },
  {
      id: "ATD",
      name: "Atoifi Airport",
      area_code: "ATD",
      city_name: "Atoifi",
      country_code: "SB"
  },
  {
      id: "ATE",
      name: "Municipal",
      area_code: "ATE",
      city_name: "Antlers",
      country_code: "US"
  },
  {
      id: "ATF",
      name: "Chachoan",
      area_code: "ATF",
      city_name: "Ambato",
      country_code: "EC"
  },
  {
      id: "ATG",
      name: "Minhas",
      area_code: "ATG",
      city_name: "Attock",
      country_code: "PK"
  },
  {
      id: "ATJ",
      name: "Antsirabe",
      area_code: "ATJ",
      city_name: "Antsirabe",
      country_code: "MG"
  },
  {
      id: "ATN",
      name: "Namatanai",
      area_code: "ATN",
      city_name: "Namatanai",
      country_code: "PG"
  },
  {
      id: "ATS",
      name: "Municipal",
      area_code: "ATS",
      city_name: "Artesia",
      country_code: "US"
  },
  {
      id: "ATT",
      name: "ATMAUTLUAK AIRPORT",
      area_code: "ATT",
      city_name: "Atmautluak",
      country_code: "US"
  },
  {
      id: "ATV",
      name: "Ati",
      area_code: "ATV",
      city_name: "Ati",
      country_code: "TD"
  },
  {
      id: "ATX",
      name: "Atbasar",
      area_code: "ATX",
      city_name: "Atbasar",
      country_code: "KZ"
  },
  {
      id: "AUE",
      name: "Abu Rudeis",
      area_code: "AUE",
      city_name: "Abu Rudeis",
      country_code: "EG"
  },
  {
      id: "AUJ",
      name: "Ambunti",
      area_code: "AUJ",
      city_name: "Ambunti",
      country_code: "PG"
  },
  {
      id: "AUK",
      name: "ALAKANUK AIRPORT",
      area_code: "AUK",
      city_name: "Alakanuk",
      country_code: "US"
  },
  {
      id: "AUL",
      name: "Aur Island",
      area_code: "AUL",
      city_name: "Aur Island",
      country_code: "MH"
  },
  {
      id: "AUP",
      name: "Agaun",
      area_code: "AUP",
      city_name: "Agaun",
      country_code: "PG"
  },
  {
      id: "AUT",
      name: "Atauro",
      area_code: "AUT",
      city_name: "Atauro",
      country_code: "TL"
  },
  {
      id: "AUV",
      name: "Aumo",
      area_code: "AUV",
      city_name: "Aumo",
      country_code: "PG"
  },
  {
      id: "AUY",
      name: "Aneityum Airport",
      area_code: "AUY",
      city_name: "Anatom Island",
      country_code: "VU"
  },
  {
      id: "AVG",
      name: "Auvergne Station",
      area_code: "AVG",
      city_name: "Auvergne Station",
      country_code: "AU"
  },
  {
      id: "AVK",
      name: "Arvaikheer",
      area_code: "AVK",
      city_name: "Arvaikheer",
      country_code: "MN"
  },
  {
      id: "AVO",
      name: "Executive",
      area_code: "AVO",
      city_name: "Avon Park",
      country_code: "US"
  },
  {
      id: "AVR",
      name: "Alverca",
      area_code: "AVR",
      city_name: "Alverca",
      country_code: "PT"
  },
  {
      id: "AVU",
      name: "Avu Avu",
      area_code: "AVU",
      city_name: "Avu Avu",
      country_code: "SB"
  },
  {
      id: "AVX",
      name: "Catalina",
      area_code: "AVX",
      city_name: "Avalon",
      country_code: "US"
  },
  {
      id: "AWA",
      name: "Awassa Airport",
      area_code: "AWA",
      city_name: "Awasa",
      country_code: "ET"
  },
  {
      id: "AWD",
      name: "Aniwa Airport",
      area_code: "AWD",
      city_name: "Aniwa Island",
      country_code: "VU"
  },
  {
      id: "AWE",
      name: "Alowe",
      area_code: "AWE",
      city_name: "Alowe",
      country_code: "GA"
  },
  {
      id: "AWK",
      name: "Wake Island",
      area_code: "AWK",
      city_name: "Wake Island",
      country_code: "US"
  },
  {
      id: "AWN",
      name: "Alton Downs",
      area_code: "AWN",
      city_name: "Alton Downs",
      country_code: "AU"
  },
  {
      id: "AWP",
      name: "Austral Downs Station",
      area_code: "AWP",
      city_name: "Austral Downs Station",
      country_code: "AU"
  },
  {
      id: "AWR",
      name: "Awar",
      area_code: "AWR",
      city_name: "Awar",
      country_code: "PG"
  },
  {
      id: "AXB",
      name: "Maxson Airfield",
      area_code: "AXB",
      city_name: "Alexandria Bay",
      country_code: "US"
  },
  {
      id: "AXC",
      name: "Aramac",
      area_code: "AXC",
      city_name: "Aramac",
      country_code: "AU"
  },
  {
      id: "AXE",
      name: "Joao Winckler",
      area_code: "AXE",
      city_name: "Xanxere",
      country_code: "BR"
  },
  {
      id: "AXG",
      name: "Municipal",
      area_code: "AXG",
      city_name: "Algona",
      country_code: "US"
  },
  {
      id: "AXJ",
      name: "Amakusa Airport",
      area_code: "AXJ",
      city_name: "Amakusa",
      country_code: "JP"
  },
  {
      id: "AXK",
      name: "Ataq",
      area_code: "AXK",
      city_name: "Ataq",
      country_code: "YE"
  },
  {
      id: "AXL",
      name: "Alexandria Station",
      area_code: "AXL",
      city_name: "Alexandria Station",
      country_code: "AU"
  },
  {
      id: "AXP",
      name: "Spring Point Airport",
      area_code: "AXP",
      city_name: "Spring Point",
      country_code: "BS"
  },
  {
      id: "AXR",
      name: "ARUTUA AIRPORT",
      area_code: "AXR",
      city_name: "Arutua",
      country_code: "PF"
  },
  {
      id: "AXX",
      name: "Angel Fire",
      area_code: "AXX",
      city_name: "Angel Fire",
      country_code: "US"
  },
  {
      id: "AYA",
      name: "El Cebruno",
      area_code: "AYA",
      city_name: "Ayapel",
      country_code: "CO"
  },
  {
      id: "AYC",
      name: "La Gloria",
      area_code: "AYC",
      city_name: "Ayacucho",
      country_code: "CO"
  },
  {
      id: "AYD",
      name: "Alroy Downs",
      area_code: "AYD",
      city_name: "Alroy Downs",
      country_code: "AU"
  },
  {
      id: "AYG",
      name: "Yaguara II",
      area_code: "AYG",
      city_name: "Yaguara II",
      country_code: "CO"
  },
  {
      id: "AYI",
      name: "Yari",
      area_code: "AYI",
      city_name: "Yari",
      country_code: "CO"
  },
  {
      id: "AYK",
      name: "Arkalyk",
      area_code: "AYK",
      city_name: "Arkalyk",
      country_code: "KZ"
  },
  {
      id: "AYL",
      name: "Anthony Lagoon",
      area_code: "AYL",
      city_name: "Anthony Lagoon",
      country_code: "AU"
  },
  {
      id: "AYM",
      name: "Yas Island SPB",
      area_code: "AUH",
      city_name: "Abu Dhabi",
      country_code: "AE"
  },
  {
      id: "AYO",
      name: "Juan de Ayolas",
      area_code: "AYO",
      city_name: "Ayolas",
      country_code: "PY"
  },
  {
      id: "AYU",
      name: "Aiyura",
      area_code: "AYU",
      city_name: "Aiyura",
      country_code: "PG"
  },
  {
      id: "AYW",
      name: "Ayawasi",
      area_code: "AYW",
      city_name: "Ayawasi",
      country_code: "ID"
  },
  {
      id: "AYY",
      name: "Arugam Bay SPB",
      area_code: "AYY",
      city_name: "Pottuvil",
      country_code: "LK"
  },
  {
      id: "AZB",
      name: "Amazon Bay",
      area_code: "AZB",
      city_name: "Amazon Bay",
      country_code: "PG"
  },
  {
      id: "AZL",
      name: "Fazenda Tucunare",
      area_code: "AZL",
      city_name: "Sapezal",
      country_code: "BR"
  },
  {
      id: "AZT",
      name: "Zapatoca",
      area_code: "AZT",
      city_name: "Zapatoca",
      country_code: "CO"
  },
  {
      id: "AZZ",
      name: "Ambriz",
      area_code: "AZZ",
      city_name: "Ambriz",
      country_code: "AO"
  },
  {
      id: "BAA",
      name: "Bialla",
      area_code: "BAA",
      city_name: "Bialla",
      country_code: "PG"
  },
  {
      id: "BAC",
      name: "Guadalito",
      area_code: "BAC",
      city_name: "Barranca de Upia",
      country_code: "CO"
  },
  {
      id: "BAE",
      name: "St-Pons",
      area_code: "BAE",
      city_name: "Barcelonnette",
      country_code: "FR"
  },
  {
      id: "BAF",
      name: "Westfield-Barnes Rgnl",
      area_code: "SFY",
      city_name: "Springfield",
      country_code: "US"
  },
  {
      id: "BAI",
      name: "Buenos Aires",
      area_code: "BAI",
      city_name: "Buenos Aires",
      country_code: "CR"
  },
  {
      id: "BAJ",
      name: "Bali",
      area_code: "BAJ",
      city_name: "Bali",
      country_code: "PG"
  },
  {
      id: "BAM",
      name: "Battle Mountain",
      area_code: "BAM",
      city_name: "Battle Mountain",
      country_code: "US"
  },
  {
      id: "BAN",
      name: "Basongo",
      area_code: "BAN",
      city_name: "Basongo",
      country_code: "CG"
  },
  {
      id: "BAP",
      name: "Baibara",
      area_code: "BAP",
      city_name: "Baibara",
      country_code: "PG"
  },
  {
      id: "BAW",
      name: "Biawonque",
      area_code: "BAW",
      city_name: "Biawonque",
      country_code: "GA"
  },
  {
      id: "BBB",
      name: "Municipal",
      area_code: "BBB",
      city_name: "Benson",
      country_code: "US"
  },
  {
      id: "BBC",
      name: "Municipal",
      area_code: "BBC",
      city_name: "Bay City",
      country_code: "US"
  },
  {
      id: "BBD",
      name: "Curtis Field",
      area_code: "BBD",
      city_name: "Brady",
      country_code: "US"
  },
  {
      id: "BBG",
      name: "Butaritari",
      area_code: "BBG",
      city_name: "Butaritari",
      country_code: "KI"
  },
  {
      id: "BBL",
      name: "Ballera",
      area_code: "BBL",
      city_name: "Ballera",
      country_code: "AU"
  },
  {
      id: "BBP",
      name: "Bembridge",
      area_code: "BBP",
      city_name: "Bembridge",
      country_code: "GB"
  },
  {
      id: "BBS",
      name: "Blackbushe",
      area_code: "BBS",
      city_name: "Blackbushe",
      country_code: "GB"
  },
  {
      id: "BBT",
      name: "Berberati",
      area_code: "BBT",
      city_name: "Berberati",
      country_code: "CF"
  },
  {
      id: "BBV",
      name: "Nero-Mer",
      area_code: "BBV",
      city_name: "Grand Bereby",
      country_code: "CI"
  },
  {
      id: "BBX",
      name: "Wings Field",
      area_code: "PHL",
      city_name: "Philadelphia",
      country_code: "US"
  },
  {
      id: "BBY",
      name: "Bambari",
      area_code: "BBY",
      city_name: "Bambari",
      country_code: "CF"
  },
  {
      id: "BBZ",
      name: "Zambezi",
      area_code: "BBZ",
      city_name: "Zambezi",
      country_code: "ZM"
  },
  {
      id: "BCC",
      name: "Bear Creek 3",
      area_code: "BCC",
      city_name: "Bear Creek",
      country_code: "US"
  },
  {
      id: "BCF",
      name: "Bouca",
      area_code: "BCF",
      city_name: "Bouca",
      country_code: "CF"
  },
  {
      id: "BCG",
      name: "Bemichi",
      area_code: "BCG",
      city_name: "Bemichi",
      country_code: "GY"
  },
  {
      id: "BCK",
      name: "Bolwarra",
      area_code: "BCK",
      city_name: "Bolwarra",
      country_code: "AU"
  },
  {
      id: "BCL",
      name: "Barra del Colorado",
      area_code: "BCL",
      city_name: "Barra del Colorado",
      country_code: "CR"
  },
  {
      id: "BCO",
      name: "Jinka",
      area_code: "BCO",
      city_name: "Jinka",
      country_code: "ET"
  },
  {
      id: "BCP",
      name: "Bambu",
      area_code: "BCP",
      city_name: "Bambu",
      country_code: "PG"
  },
  {
      id: "BCQ",
      name: "Brach",
      area_code: "BCQ",
      city_name: "Brach",
      country_code: "LY"
  },
  {
      id: "BCR",
      name: "Novo Campo",
      area_code: "BCR",
      city_name: "Boca do Acre",
      country_code: "BR"
  },
  {
      id: "BCS",
      name: "Southern Seaplane Apt",
      area_code: "BCS",
      city_name: "Belle Chasse",
      country_code: "US"
  },
  {
      id: "BCU",
      name: "Bauchi",
      area_code: "BCU",
      city_name: "Bauchi",
      country_code: "NG"
  },
  {
      id: "BCW",
      name: "Benguerra Island",
      area_code: "BCW",
      city_name: "Benguerra Island",
      country_code: "MZ"
  },
  {
      id: "BCX",
      name: "Beloretsk",
      area_code: "BCX",
      city_name: "Beloretsk",
      country_code: "RU"
  },
  {
      id: "BCY",
      name: "Bulchi",
      area_code: "BCY",
      city_name: "Bulchi",
      country_code: "ET"
  },
  {
      id: "BCZ",
      name: "Bickerton Island",
      area_code: "BCZ",
      city_name: "Bickerton Island",
      country_code: "AU"
  },
  {
      id: "BDC",
      name: "Barra do Corda",
      area_code: "BDC",
      city_name: "Barra do Corda",
      country_code: "BR"
  },
  {
      id: "BDE",
      name: "International",
      area_code: "BDE",
      city_name: "Baudette",
      country_code: "US"
  },
  {
      id: "BDF",
      name: "Rinkenberger",
      area_code: "BDF",
      city_name: "Bradford",
      country_code: "US"
  },
  {
      id: "BDG",
      name: "Municipal",
      area_code: "BDG",
      city_name: "Blanding",
      country_code: "US"
  },
  {
      id: "BDH",
      name: "BANDAR LENGEH AIRPORT",
      area_code: "BDH",
      city_name: "Bandar Lengeh",
      country_code: "IR"
  },
  {
      id: "BDK",
      name: "Soko",
      area_code: "BDK",
      city_name: "Bondoukou",
      country_code: "CI"
  },
  {
      id: "BDM",
      name: "Bandirma",
      area_code: "BDM",
      city_name: "Bandirma",
      country_code: "TR"
  },
  {
      id: "BDN",
      name: "Talhar",
      area_code: "BDN",
      city_name: "Badin",
      country_code: "PK"
  },
  {
      id: "BDP",
      name: "BHADRAPUR AIRPORT",
      area_code: "BDP",
      city_name: "Bhadrapur",
      country_code: "NP"
  },
  {
      id: "BDT",
      name: "Gbadolite",
      area_code: "BDT",
      city_name: "Gbadolite",
      country_code: "CD"
  },
  {
      id: "BDV",
      name: "Moba",
      area_code: "BDV",
      city_name: "Moba",
      country_code: "CD"
  },
  {
      id: "BDX",
      name: "Broadus",
      area_code: "BDX",
      city_name: "Broadus",
      country_code: "US"
  },
  {
      id: "BDY",
      name: "State",
      area_code: "BDY",
      city_name: "Bandon",
      country_code: "US"
  },
  {
      id: "BDZ",
      name: "Baindoung",
      area_code: "BDZ",
      city_name: "Baindoung",
      country_code: "PG"
  },
  {
      id: "BEA",
      name: "Bereina",
      area_code: "BEA",
      city_name: "Bereina",
      country_code: "PG"
  },
  {
      id: "BEE",
      name: "Beagle Bay",
      area_code: "BEE",
      city_name: "Beagle Bay",
      country_code: "AU"
  },
  {
      id: "BEI",
      name: "Beica",
      area_code: "BEI",
      city_name: "Beica",
      country_code: "ET"
  },
  {
      id: "BEV",
      name: "Beersheba",
      area_code: "BEV",
      city_name: "Beersheba",
      country_code: "IL"
  },
  {
      id: "BEX",
      name: "RAF Station",
      area_code: "BEX",
      city_name: "Benson",
      country_code: "GB"
  },
  {
      id: "BFA",
      name: "Bahia Negra",
      area_code: "BFA",
      city_name: "Bahia Negra",
      country_code: "PY"
  },
  {
      id: "BFO",
      name: "Buffalo Range",
      area_code: "BFO",
      city_name: "Chiredzi",
      country_code: "ZW"
  },
  {
      id: "BFW",
      name: "Sidi Bel Abbes",
      area_code: "BFW",
      city_name: "Sidi Bel Abbes",
      country_code: "DZ"
  },
  {
      id: "BFX",
      name: "Bafoussam Airport",
      area_code: "BFX",
      city_name: "Bafoussam",
      country_code: "CM"
  },
  {
      id: "BGB",
      name: "Booue",
      area_code: "BGB",
      city_name: "Booue",
      country_code: "GA"
  },
  {
      id: "BGC",
      name: "Braganca Airport",
      area_code: "BGC",
      city_name: "Braganca",
      country_code: "PT"
  },
  {
      id: "BGD",
      name: "Hutchinson County",
      area_code: "BGD",
      city_name: "Borger",
      country_code: "US"
  },
  {
      id: "BGE",
      name: "Decatur Co Ind. Air Pk",
      area_code: "BGE",
      city_name: "Bainbridge",
      country_code: "US"
  },
  {
      id: "BGH",
      name: "Abbaye",
      area_code: "BGH",
      city_name: "Bogue",
      country_code: "MR"
  },
  {
      id: "BGJ",
      name: "Borgarfjordur eystri",
      area_code: "BGJ",
      city_name: "Borgarfjordur eystri",
      country_code: "IS"
  },
  {
      id: "BGK",
      name: "Big Creek",
      area_code: "BGK",
      city_name: "Big Creek",
      country_code: "BZ"
  },
  {
      id: "BGL",
      name: "Balewa",
      area_code: "BGL",
      city_name: "Baglung",
      country_code: "NP"
  },
  {
      id: "BGN",
      name: "Belaya Gora Airport",
      area_code: "BGN",
      city_name: "Belaya Gora",
      country_code: "RU"
  },
  {
      id: "BGP",
      name: "Bongo",
      area_code: "BGP",
      city_name: "Bongo",
      country_code: "GA"
  },
  {
      id: "BGU",
      name: "Bangassou",
      area_code: "BGU",
      city_name: "Bangassou",
      country_code: "CF"
  },
  {
      id: "BGX",
      name: "Gustavo Kraemer",
      area_code: "BGX",
      city_name: "Bage",
      country_code: "BR"
  },
  {
      id: "BGZ",
      name: "Braga",
      area_code: "BGZ",
      city_name: "Braga",
      country_code: "PT"
  },
  {
      id: "BHA",
      name: "Los Perales",
      area_code: "BHA",
      city_name: "Bahia de Caraquez",
      country_code: "EC"
  },
  {
      id: "BHF",
      name: "Cupica",
      area_code: "BHF",
      city_name: "Cupica",
      country_code: "CO"
  },
  {
      id: "BHG",
      name: "Brus Laguna",
      area_code: "BHG",
      city_name: "Brus Laguna",
      country_code: "HN"
  },
  {
      id: "BHN",
      name: "Beihan",
      area_code: "BHN",
      city_name: "Beihan",
      country_code: "YE"
  },
  {
      id: "BHP",
      name: "Bhojpur",
      area_code: "BHP",
      city_name: "Bhojpur",
      country_code: "NP"
  },
  {
      id: "BHR",
      name: "BHARATPUR AIRPORT",
      area_code: "BHR",
      city_name: "Bharatpur",
      country_code: "NP"
  },
  {
      id: "BHT",
      name: "Brighton Downs",
      area_code: "BHT",
      city_name: "Brighton Downs",
      country_code: "AU"
  },
  {
      id: "BIB",
      name: "Baidoa",
      area_code: "BIB",
      city_name: "Baidoa",
      country_code: "SO"
  },
  {
      id: "BIE",
      name: "Municipal",
      area_code: "BIE",
      city_name: "Beatrice",
      country_code: "US"
  },
  {
      id: "BIJ",
      name: "Biliau",
      area_code: "BIJ",
      city_name: "Biliau",
      country_code: "PG"
  },
  {
      id: "BIN",
      name: "BAMIYAN AIRPORT",
      area_code: "BIN",
      city_name: "Bamyan",
      country_code: "AF"
  },
  {
      id: "BIP",
      name: "Bulimba",
      area_code: "BIP",
      city_name: "Bulimba",
      country_code: "AU"
  },
  {
      id: "BIR",
      name: "BIRATNAGAR AIRPORT",
      area_code: "BIR",
      city_name: "Biratnagar",
      country_code: "NP"
  },
  {
      id: "BIT",
      name: "Patan",
      area_code: "BIT",
      city_name: "Baitadi",
      country_code: "NP"
  },
  {
      id: "BIU",
      name: "Bildudalur",
      area_code: "BIU",
      city_name: "Bildudalur",
      country_code: "IS"
  },
  {
      id: "BIV",
      name: "Bria",
      area_code: "BIV",
      city_name: "Bria",
      country_code: "CF"
  },
  {
      id: "BIW",
      name: "Billiluna",
      area_code: "BIW",
      city_name: "Billiluna",
      country_code: "AU"
  },
  {
      id: "BIX",
      name: "Keesler AFB",
      area_code: "GPT",
      city_name: "Gulfport/Biloxi",
      country_code: "US"
  },
  {
      id: "BIZ",
      name: "Bimin",
      area_code: "BIZ",
      city_name: "Bimin",
      country_code: "PG"
  },
  {
      id: "BJB",
      name: "Bojnord Airport",
      area_code: "BJB",
      city_name: "Bojnurd",
      country_code: "IR"
  },
  {
      id: "BJD",
      name: "Bakkafjordur",
      area_code: "BJD",
      city_name: "Bakkafjordur",
      country_code: "IS"
  },
  {
      id: "BJE",
      name: "Baleela",
      area_code: "BJE",
      city_name: "Baleela",
      country_code: "SD"
  },
  {
      id: "BJG",
      name: "Mopait",
      area_code: "BJG",
      city_name: "Kota Mobagu",
      country_code: "ID"
  },
  {
      id: "BJH",
      name: "Bajhang",
      area_code: "BJH",
      city_name: "Bajhang",
      country_code: "NP"
  },
  {
      id: "BJJ",
      name: "Wayne County",
      area_code: "BJJ",
      city_name: "Wooster",
      country_code: "US"
  },
  {
      id: "BJK",
      name: "Benjina",
      area_code: "BJK",
      city_name: "Nangasuri Aru Island",
      country_code: "ID"
  },
  {
      id: "BJN",
      name: "Bajone",
      area_code: "BJN",
      city_name: "Bajone",
      country_code: "MZ"
  },
  {
      id: "BJO",
      name: "Bermejo",
      area_code: "BJO",
      city_name: "Bermejo",
      country_code: "BO"
  },
  {
      id: "BJP",
      name: "Arthur Siqueira",
      area_code: "BJP",
      city_name: "Braganca Paulista",
      country_code: "BR"
  },
  {
      id: "BJQ",
      name: "Bahja",
      area_code: "BJQ",
      city_name: "Bahja",
      country_code: "OM"
  },
  {
      id: "BJU",
      name: "Bajura",
      area_code: "BJU",
      city_name: "Bajura",
      country_code: "NP"
  },
  {
      id: "BJW",
      name: "Bajawa Airport",
      area_code: "BJW",
      city_name: "Bajawa",
      country_code: "ID"
  },
  {
      id: "BKB",
      name: "Bikaner",
      area_code: "BKB",
      city_name: "Bikaner",
      country_code: "IN"
  },
  {
      id: "BKC",
      name: "BUCKLAND AIRPORT",
      area_code: "BKC",
      city_name: "Buckland",
      country_code: "US"
  },
  {
      id: "BKD",
      name: "Stephens County",
      area_code: "BKD",
      city_name: "Breckenridge",
      country_code: "US"
  },
  {
      id: "BKF",
      name: "Lake Brooks SPB",
      area_code: "BKF",
      city_name: "Katmai National Park",
      country_code: "US"
  },
  {
      id: "BKH",
      name: "Barking Sands",
      area_code: "BKH",
      city_name: "Kekaha",
      country_code: "US"
  },
  {
      id: "BKJ",
      name: "Baralande",
      area_code: "BKJ",
      city_name: "Boke",
      country_code: "GN"
  },
  {
      id: "BKN",
      name: "Balkanabat",
      area_code: "BKN",
      city_name: "Balkanabat",
      country_code: "TM"
  },
  {
      id: "BKP",
      name: "Barkly Downs",
      area_code: "BKP",
      city_name: "Barkly Downs",
      country_code: "AU"
  },
  {
      id: "BKR",
      name: "Bokoro",
      area_code: "BKR",
      city_name: "Bokoro",
      country_code: "TD"
  },
  {
      id: "BKT",
      name: "Blackstone AAF",
      area_code: "BKT",
      city_name: "Blackstone/Ft Pickett",
      country_code: "US"
  },
  {
      id: "BKU",
      name: "Betioky",
      area_code: "BKU",
      city_name: "Betioky",
      country_code: "MG"
  },
  {
      id: "BKV",
      name: "Bailing",
      area_code: "BKV",
      city_name: "Damao Banner",
      country_code: "US"
  },
  {
      id: "BKY",
      name: "Kavumu Airport",
      area_code: "BKY",
      city_name: "Bukavu",
      country_code: "CD"
  },
  {
      id: "BLB",
      name: "Panama Pacifico International Airport",
      area_code: "BLB",
      city_name: "Balboa",
      country_code: "PA"
  },
  {
      id: "BLC",
      name: "Bali",
      area_code: "BLC",
      city_name: "Bali",
      country_code: "CM"
  },
  {
      id: "BLD",
      name: "Boulder City Municipal Airport",
      area_code: "LAS",
      city_name: "Las Vegas",
      country_code: "US"
  },
  {
      id: "BLM",
      name: "Monmouth Executive",
      area_code: "BLM",
      city_name: "Belmar/Farmingdale",
      country_code: "US"
  },
  {
      id: "BLN",
      name: "Benalla",
      area_code: "BLN",
      city_name: "Benalla",
      country_code: "AU"
  },
  {
      id: "BLO",
      name: "Blonduos",
      area_code: "BLO",
      city_name: "Blonduos",
      country_code: "IS"
  },
  {
      id: "BLP",
      name: "Huallaga",
      area_code: "BLP",
      city_name: "Bellavista",
      country_code: "PE"
  },
  {
      id: "BLS",
      name: "Bollon",
      area_code: "BLS",
      city_name: "Bollon",
      country_code: "AU"
  },
  {
      id: "BLW",
      name: "Beletwene",
      area_code: "BLW",
      city_name: "Beletwene",
      country_code: "SO"
  },
  {
      id: "BLY",
      name: "Belmullet",
      area_code: "BLY",
      city_name: "Belmullet",
      country_code: "IE"
  },
  {
      id: "BMB",
      name: "Bumba",
      area_code: "BMB",
      city_name: "Bumba",
      country_code: "CD"
  },
  {
      id: "BMC",
      name: "Brigham City",
      area_code: "BMC",
      city_name: "Brigham City",
      country_code: "US"
  },
  {
      id: "BMD",
      name: "Belo Tsiribihina",
      area_code: "BMD",
      city_name: "Belo Tsiribihina",
      country_code: "MG"
  },
  {
      id: "BMF",
      name: "Bakouma",
      area_code: "BMF",
      city_name: "Bakouma",
      country_code: "CF"
  },
  {
      id: "BMH",
      name: "Bomai",
      area_code: "BMH",
      city_name: "Bomai",
      country_code: "PG"
  },
  {
      id: "BMJ",
      name: "Baramita",
      area_code: "BMJ",
      city_name: "Baramita",
      country_code: "GY"
  },
  {
      id: "BMK",
      name: "Borkum",
      area_code: "BMK",
      city_name: "Borkum",
      country_code: "DE"
  },
  {
      id: "BMM",
      name: "Bitam",
      area_code: "BMM",
      city_name: "Bitam",
      country_code: "GA"
  },
  {
      id: "BMQ",
      name: "Bamburi",
      area_code: "BMQ",
      city_name: "Bamburi",
      country_code: "KE"
  },
  {
      id: "BMR",
      name: "Baltrum",
      area_code: "BMR",
      city_name: "Baltrum",
      country_code: "DE"
  },
  {
      id: "BMS",
      name: "S.Mariani Bittencourt",
      area_code: "BMS",
      city_name: "Brumado",
      country_code: "BR"
  },
  {
      id: "BMW",
      name: "Bordj Badji Mokhtar Airport",
      area_code: "BMW",
      city_name: "Bordj Mokhtar",
      country_code: "DZ"
  },
  {
      id: "BMX",
      name: "Big Mountain",
      area_code: "BMX",
      city_name: "Big Mountain",
      country_code: "US"
  },
  {
      id: "BMY",
      name: "Waala Airport",
      area_code: "BMY",
      city_name: "Ile Art",
      country_code: "NC"
  },
  {
      id: "BMZ",
      name: "Bamu",
      area_code: "BMZ",
      city_name: "Bamu",
      country_code: "PG"
  },
  {
      id: "BNB",
      name: "Boende",
      area_code: "BNB",
      city_name: "Boende",
      country_code: "CG"
  },
  {
      id: "BNC",
      name: "Beni Airport",
      area_code: "BNC",
      city_name: "Beni",
      country_code: "CD"
  },
  {
      id: "BNL",
      name: "Regional",
      area_code: "BNL",
      city_name: "Barnwell",
      country_code: "US"
  },
  {
      id: "BNM",
      name: "Bodinumu",
      area_code: "BNM",
      city_name: "Bodinumu",
      country_code: "PG"
  },
  {
      id: "BNP",
      name: "Bannu",
      area_code: "BNP",
      city_name: "Bannu",
      country_code: "PK"
  },
  {
      id: "BNQ",
      name: "Baganga",
      area_code: "BNQ",
      city_name: "Baganga",
      country_code: "PH"
  },
  {
      id: "BNR",
      name: "Banfora",
      area_code: "BNR",
      city_name: "Banfora",
      country_code: "BF"
  },
  {
      id: "BNT",
      name: "Bundi",
      area_code: "BNT",
      city_name: "Bundi",
      country_code: "PG"
  },
  {
      id: "BNV",
      name: "Boana",
      area_code: "BNV",
      city_name: "Boana",
      country_code: "PG"
  },
  {
      id: "BNW",
      name: "Municipal",
      area_code: "BNW",
      city_name: "Boone",
      country_code: "US"
  },
  {
      id: "BNY",
      name: "Bellona Airport",
      area_code: "BNY",
      city_name: "Bellona",
      country_code: "SB"
  },
  {
      id: "BNZ",
      name: "Banz",
      area_code: "BNZ",
      city_name: "Banz",
      country_code: "PG"
  },
  {
      id: "BOA",
      name: "Boma",
      area_code: "BOA",
      city_name: "Boma",
      country_code: "CG"
  },
  {
      id: "BOE",
      name: "Boundji",
      area_code: "BOE",
      city_name: "Boundji",
      country_code: "CG"
  },
  {
      id: "BOL",
      name: "Ballykelly",
      area_code: "BOL",
      city_name: "Ballykelly",
      country_code: "GB"
  },
  {
      id: "BOP",
      name: "Bouar",
      area_code: "BOP",
      city_name: "Bouar",
      country_code: "CF"
  },
  {
      id: "BOQ",
      name: "Boku",
      area_code: "BOQ",
      city_name: "Boku",
      country_code: "PG"
  },
  {
      id: "BOV",
      name: "Boang Island",
      area_code: "BOV",
      city_name: "Boang Island",
      country_code: "PG"
  },
  {
      id: "BOZ",
      name: "Bozoum",
      area_code: "BOZ",
      city_name: "Bozoum",
      country_code: "CF"
  },
  {
      id: "BPB",
      name: "Boridi",
      area_code: "BPB",
      city_name: "Boridi",
      country_code: "PG"
  },
  {
      id: "BPC",
      name: "Bamenda",
      area_code: "BPC",
      city_name: "Bamenda",
      country_code: "CM"
  },
  {
      id: "BPD",
      name: "Bapi",
      area_code: "BPD",
      city_name: "Bapi",
      country_code: "PG"
  },
  {
      id: "BPF",
      name: "Batuna",
      area_code: "BPF",
      city_name: "Batuna",
      country_code: "SB"
  },
  {
      id: "BPG",
      name: "Barra Do Garcas",
      area_code: "BPG",
      city_name: "Barra do Garcas",
      country_code: "BR"
  },
  {
      id: "BPH",
      name: "Bislig",
      area_code: "BPH",
      city_name: "Bislig",
      country_code: "PH"
  },
  {
      id: "BPI",
      name: "Miley Memorial Field",
      area_code: "BPI",
      city_name: "Big Piney/Marbleton",
      country_code: "US"
  },
  {
      id: "BPK",
      name: "Biangabip",
      area_code: "BPK",
      city_name: "Biangabip",
      country_code: "PG"
  },
  {
      id: "BPY",
      name: "Besalampy",
      area_code: "BPY",
      city_name: "Besalampy",
      country_code: "MG"
  },
  {
      id: "BQA",
      name: "Dr. Juan C. Angara",
      area_code: "BQA",
      city_name: "Baler",
      country_code: "PH"
  },
  {
      id: "BQE",
      name: "Bubaque",
      area_code: "BQE",
      city_name: "Bubaque",
      country_code: "GW"
  },
  {
      id: "BQG",
      name: "Bogorodskoye Airport",
      area_code: "BQG",
      city_name: "Bogorodskoye",
      country_code: "RU"
  },
  {
      id: "BQI",
      name: "Bagani",
      area_code: "BQI",
      city_name: "Bagani",
      country_code: "NA"
  },
  {
      id: "BQO",
      name: "Tehini",
      area_code: "BQO",
      city_name: "Bouna",
      country_code: "CI"
  },
  {
      id: "BQQ",
      name: "Barra",
      area_code: "BQQ",
      city_name: "Barra",
      country_code: "BR"
  },
  {
      id: "BQU",
      name: "J.F. Mitchell",
      area_code: "BQU",
      city_name: "Bequia",
      country_code: "ZA"
  },
  {
      id: "BQW",
      name: "Balgo Hill",
      area_code: "BQW",
      city_name: "Balgo Hill",
      country_code: "AU"
  },
  {
      id: "BRB",
      name: "Barreirinhas",
      area_code: "BRB",
      city_name: "Barreirinhas",
      country_code: "BR"
  },
  {
      id: "BRH",
      name: "Braham",
      area_code: "BRH",
      city_name: "Braham",
      country_code: "PG"
  },
  {
      id: "BRJ",
      name: "Bright",
      area_code: "BRJ",
      city_name: "Bright",
      country_code: "AU"
  },
  {
      id: "BRP",
      name: "Biaru",
      area_code: "BRP",
      city_name: "Biaru",
      country_code: "PG"
  },
  {
      id: "BRY",
      name: "Samuels Field",
      area_code: "BRY",
      city_name: "Bardstown",
      country_code: "US"
  },
  {
      id: "BSE",
      name: "Sematan",
      area_code: "BSE",
      city_name: "Sematan",
      country_code: "MY"
  },
  {
      id: "BSF",
      name: "Bradshaw AAF",
      area_code: "BSF",
      city_name: "Pohakuloa",
      country_code: "US"
  },
  {
      id: "BSM",
      name: "Bisheh Kola",
      area_code: "BSM",
      city_name: "Bisheh Kola",
      country_code: "IR"
  },
  {
      id: "BSN",
      name: "Bossangoa",
      area_code: "BSN",
      city_name: "Bossangoa",
      country_code: "CF"
  },
  {
      id: "BSP",
      name: "Bensbach",
      area_code: "BSP",
      city_name: "Bensbach",
      country_code: "PG"
  },
  {
      id: "BSS",
      name: "Balsas",
      area_code: "BSS",
      city_name: "Balsas",
      country_code: "BR"
  },
  {
      id: "BST",
      name: "BOST AIRPORT",
      area_code: "BST",
      city_name: "Lashkar Gah",
      country_code: "AF"
  },
  {
      id: "BSU",
      name: "Basankusu",
      area_code: "BSU",
      city_name: "Basankusu",
      country_code: "CG"
  },
  {
      id: "BSV",
      name: "Besakoa",
      area_code: "BSV",
      city_name: "Besakoa",
      country_code: "MG"
  },
  {
      id: "BSW",
      name: "Boswell Bay",
      area_code: "BSW",
      city_name: "Boswell Bay",
      country_code: "US"
  },
  {
      id: "BSX",
      name: "Pathein",
      area_code: "BSX",
      city_name: "Pathein",
      country_code: "MM"
  },
  {
      id: "BSY",
      name: "Bardera",
      area_code: "BSY",
      city_name: "Bardera",
      country_code: "SO"
  },
  {
      id: "BTA",
      name: "Bertoua",
      area_code: "BTA",
      city_name: "Bertoua",
      country_code: "CM"
  },
  {
      id: "BTB",
      name: "Betou",
      area_code: "BTB",
      city_name: "Betou",
      country_code: "CG"
  },
  {
      id: "BTC",
      name: "Batticaloa Airport",
      area_code: "BTC",
      city_name: "Batticaloa",
      country_code: "LK"
  },
  {
      id: "BTD",
      name: "Brunette Downs",
      area_code: "BTD",
      city_name: "Brunette Downs",
      country_code: "AU"
  },
  {
      id: "BTE",
      name: "Sherbro Intl",
      area_code: "BTE",
      city_name: "Bonthe",
      country_code: "SL"
  },
  {
      id: "BTF",
      name: "Skypark",
      area_code: "BTF",
      city_name: "Bountiful",
      country_code: "US"
  },
  {
      id: "BTG",
      name: "Batangafo",
      area_code: "BTG",
      city_name: "Batangafo",
      country_code: "CF"
  },
  {
      id: "BTI",
      name: "BARTER ISLAND AIRPORT",
      area_code: "BTI",
      city_name: "Barter Island",
      country_code: "US"
  },
  {
      id: "BTN",
      name: "Marlboro Co. Jetport",
      area_code: "BTN",
      city_name: "Bennettsville",
      country_code: "US"
  },
  {
      id: "BTO",
      name: "Botopasi",
      area_code: "BTO",
      city_name: "Botopasi",
      country_code: "SR"
  },
  {
      id: "BTQ",
      name: "Butare",
      area_code: "BTQ",
      city_name: "Butare",
      country_code: "RW"
  },
  {
      id: "BTT",
      name: "BETTLES AIRPORT",
      area_code: "BTT",
      city_name: "Bettles",
      country_code: "US"
  },
  {
      id: "BTW",
      name: "Batu Licin",
      area_code: "BTW",
      city_name: "Batu Licin",
      country_code: "ID"
  },
  {
      id: "BTX",
      name: "Betoota",
      area_code: "BTX",
      city_name: "Betoota",
      country_code: "AU"
  },
  {
      id: "BTY",
      name: "Beatty",
      area_code: "BTY",
      city_name: "Beatty",
      country_code: "US"
  },
  {
      id: "BUB",
      name: "Cram Field",
      area_code: "BUB",
      city_name: "Burwell",
      country_code: "US"
  },
  {
      id: "BUI",
      name: "Bokondini",
      area_code: "BUI",
      city_name: "Bokondini",
      country_code: "ID"
  },
  {
      id: "BUJ",
      name: "Bou Saada",
      area_code: "BUJ",
      city_name: "Bou Saada",
      country_code: "DZ"
  },
  {
      id: "BUK",
      name: "Al Buq",
      area_code: "BUK",
      city_name: "Al Buq",
      country_code: "YE"
  },
  {
      id: "BUM",
      name: "Memorial",
      area_code: "BUM",
      city_name: "Butler",
      country_code: "US"
  },
  {
      id: "BUO",
      name: "Burao",
      area_code: "BUO",
      city_name: "Burao",
      country_code: "SO"
  },
  {
      id: "BUU",
      name: "Muara Bungo",
      area_code: "BUU",
      city_name: "Muara Bungo",
      country_code: "ID"
  },
  {
      id: "BUV",
      name: "Placeres",
      area_code: "BUV",
      city_name: "Bella Union",
      country_code: "UY"
  },
  {
      id: "BUX",
      name: "BUNIA AIRPORT",
      area_code: "BUX",
      city_name: "Bunia",
      country_code: "CD"
  },
  {
      id: "BVF",
      name: "Dama",
      area_code: "BVF",
      city_name: "Bua",
      country_code: "FJ"
  },
  {
      id: "BVG",
      name: "BERLEVAG AIRPORT",
      area_code: "BVG",
      city_name: "Berlevag",
      country_code: "NO"
  },
  {
      id: "BVK",
      name: "Huacaraje",
      area_code: "BVK",
      city_name: "Huacaraje",
      country_code: "BO"
  },
  {
      id: "BVL",
      name: "Baures",
      area_code: "BVL",
      city_name: "Baures",
      country_code: "BO"
  },
  {
      id: "BVM",
      name: "Belmonte",
      area_code: "BVM",
      city_name: "Belmonte",
      country_code: "BR"
  },
  {
      id: "BVP",
      name: "Bolovip",
      area_code: "BVP",
      city_name: "Bolovip",
      country_code: "PG"
  },
  {
      id: "BVS",
      name: "Breves",
      area_code: "BVS",
      city_name: "Breves",
      country_code: "BR"
  },
  {
      id: "BVW",
      name: "Batavia Downs",
      area_code: "BVW",
      city_name: "Batavia Downs",
      country_code: "AU"
  },
  {
      id: "BVZ",
      name: "Beverley Springs",
      area_code: "BVZ",
      city_name: "Beverley Springs",
      country_code: "AU"
  },
  {
      id: "BWB",
      name: "Barrow Island",
      area_code: "BWB",
      city_name: "Barrow Island",
      country_code: "AU"
  },
  {
      id: "BWH",
      name: "Butterworth",
      area_code: "BWH",
      city_name: "Butterworth",
      country_code: "MY"
  },
  {
      id: "BWJ",
      name: "Bawan",
      area_code: "BWJ",
      city_name: "Bawan",
      country_code: "PG"
  },
  {
      id: "BWK",
      name: "BOL AIRPORT",
      area_code: "BWK",
      city_name: "Brac",
      country_code: "HR"
  },
  {
      id: "BWL",
      name: "Blackwell-Tonkawa Muni",
      area_code: "BWL",
      city_name: "Blackwell",
      country_code: "US"
  },
  {
      id: "BWM",
      name: "Municipal",
      area_code: "BWM",
      city_name: "Bowman",
      country_code: "US"
  },
  {
      id: "BWO",
      name: "Balakovo",
      area_code: "BWO",
      city_name: "Balakovo",
      country_code: "RU"
  },
  {
      id: "BWP",
      name: "Bewani",
      area_code: "BWP",
      city_name: "Bewani",
      country_code: "PG"
  },
  {
      id: "BWW",
      name: "Las Brujas",
      area_code: "BWW",
      city_name: "Cayo las Brujas",
      country_code: "CU"
  },
  {
      id: "BXA",
      name: "George R. Carr Mem.",
      area_code: "BXA",
      city_name: "Bogalusa",
      country_code: "US"
  },
  {
      id: "BXB",
      name: "Babo Airport",
      area_code: "BXB",
      city_name: "Irarutu",
      country_code: "ID"
  },
  {
      id: "BXD",
      name: "Bade",
      area_code: "BXD",
      city_name: "Bade",
      country_code: "ID"
  },
  {
      id: "BXE",
      name: "Bakel",
      area_code: "BXE",
      city_name: "Bakel",
      country_code: "SN"
  },
  {
      id: "BXF",
      name: "Bellburn",
      area_code: "BXF",
      city_name: "Bellburn",
      country_code: "AU"
  },
  {
      id: "BXG",
      name: "Bendigo",
      area_code: "BXG",
      city_name: "Bendigo",
      country_code: "AU"
  },
  {
      id: "BXI",
      name: "Boundiali",
      area_code: "BXI",
      city_name: "Boundiali",
      country_code: "CI"
  },
  {
      id: "BXJ",
      name: "Borolday",
      area_code: "ALA",
      city_name: "Almaty",
      country_code: "KZ"
  },
  {
      id: "BXL",
      name: "Blue Lagoon SPB",
      area_code: "BXL",
      city_name: "Nanuya Lailai",
      country_code: "FJ"
  },
  {
      id: "BXM",
      name: "Batom",
      area_code: "BXM",
      city_name: "Batom",
      country_code: "ID"
  },
  {
      id: "BXP",
      name: "Biala Podlaska",
      area_code: "BXP",
      city_name: "Biala Podlaska",
      country_code: "PL"
  },
  {
      id: "BXR",
      name: "Bam Airport",
      area_code: "BXR",
      city_name: "Bam",
      country_code: "IR"
  },
  {
      id: "BXT",
      name: "Bontang",
      area_code: "BXT",
      city_name: "Bontang",
      country_code: "ID"
  },
  {
      id: "BXV",
      name: "Breiddalsvik",
      area_code: "BXV",
      city_name: "Breiddalsvik",
      country_code: "IS"
  },
  {
      id: "BXW",
      name: "Bawean",
      area_code: "BXW",
      city_name: "Bawean",
      country_code: "ID"
  },
  {
      id: "BXX",
      name: "Borama",
      area_code: "BXX",
      city_name: "Borama",
      country_code: "SO"
  },
  {
      id: "BXY",
      name: "Krayniy Airport",
      area_code: "BXY",
      city_name: "Baykonur",
      country_code: "KZ"
  },
  {
      id: "BXZ",
      name: "Bunsil",
      area_code: "BXZ",
      city_name: "Bunsil",
      country_code: "PG"
  },
  {
      id: "BYA",
      name: "Boundary",
      area_code: "BYA",
      city_name: "Boundary",
      country_code: "US"
  },
  {
      id: "BYB",
      name: "Diba al Bayah",
      area_code: "BYB",
      city_name: "Diba al Bayah",
      country_code: "OM"
  },
  {
      id: "BYC",
      name: "YACUIBA AIRPORT",
      area_code: "BYC",
      city_name: "Yacuiba",
      country_code: "BO"
  },
  {
      id: "BYD",
      name: "Al Bayda",
      area_code: "BYD",
      city_name: "Al Bayda",
      country_code: "YE"
  },
  {
      id: "BYG",
      name: "Johnson County",
      area_code: "BYG",
      city_name: "Buffalo",
      country_code: "US"
  },
  {
      id: "BYL",
      name: "Belle Yella",
      area_code: "BYL",
      city_name: "Belle Yella",
      country_code: "LR"
  },
  {
      id: "BYN",
      name: "Bayankhongor",
      area_code: "BYN",
      city_name: "Bayankhongor",
      country_code: "MN"
  },
  {
      id: "BYP",
      name: "Barimunya Airport",
      area_code: "BYP",
      city_name: "Barimunya",
      country_code: "AU"
  },
  {
      id: "BYQ",
      name: "Bunyu",
      area_code: "BYQ",
      city_name: "Bunyu",
      country_code: "ID"
  },
  {
      id: "BYT",
      name: "Bantry",
      area_code: "BYT",
      city_name: "Bantry",
      country_code: "IE"
  },
  {
      id: "BYV",
      name: "Beira Lake SPB",
      area_code: "CMB",
      city_name: "Colombo",
      country_code: "LK"
  },
  {
      id: "BYX",
      name: "Baniyala",
      area_code: "BYX",
      city_name: "Baniyala",
      country_code: "AU"
  },
  {
      id: "BZA",
      name: "San Pedro",
      area_code: "BZA",
      city_name: "Bonanza",
      country_code: "NI"
  },
  {
      id: "BZB",
      name: "Bazaruto Island",
      area_code: "BZB",
      city_name: "Bazaruto Island",
      country_code: "MZ"
  },
  {
      id: "BZD",
      name: "Balranald",
      area_code: "BZD",
      city_name: "Balranald",
      country_code: "AU"
  },
  {
      id: "BZF",
      name: "Benton Field",
      area_code: "RDD",
      city_name: "Redding",
      country_code: "US"
  },
  {
      id: "BZM",
      name: "Bemolanga",
      area_code: "BZM",
      city_name: "Bemolanga",
      country_code: "MG"
  },
  {
      id: "BZP",
      name: "Bizant",
      area_code: "BZP",
      city_name: "Bizant",
      country_code: "AU"
  },
  {
      id: "BZU",
      name: "Zega",
      area_code: "BZU",
      city_name: "Buta",
      country_code: "CG"
  },
  {
      id: "BZX",
      name: "Enyang Airport",
      area_code: "BZX",
      city_name: "Bazhong",
      country_code: "CN"
  },
  {
      id: "BZY",
      name: "International",
      area_code: "BZY",
      city_name: "Balti",
      country_code: "MD"
  },
  {
      id: "CAA",
      name: "El Aguacate",
      area_code: "CAA",
      city_name: "Catacamas",
      country_code: "HN"
  },
  {
      id: "CAF",
      name: "Carauari",
      area_code: "CAF",
      city_name: "Carauari",
      country_code: "BR"
  },
  {
      id: "CAJ",
      name: "Canaima",
      area_code: "CAJ",
      city_name: "Canaima",
      country_code: "VE"
  },
  {
      id: "CAO",
      name: "Municipal Airpark",
      area_code: "CAO",
      city_name: "Clayton",
      country_code: "US"
  },
  {
      id: "CAT",
      name: "Tires",
      area_code: "CAT",
      city_name: "Cascais",
      country_code: "PT"
  },
  {
      id: "CAV",
      name: "Cazombo",
      area_code: "CAV",
      city_name: "Cazombo",
      country_code: "AO"
  },
  {
      id: "CBC",
      name: "Cherrabun",
      area_code: "CBC",
      city_name: "Cherrabun",
      country_code: "AU"
  },
  {
      id: "CBH",
      name: "Bechar Airport",
      area_code: "CBH",
      city_name: "Bechar",
      country_code: "DZ"
  },
  {
      id: "CBJ",
      name: "Cabo Rojo",
      area_code: "CBJ",
      city_name: "Pedernales",
      country_code: "DO"
  },
  {
      id: "CBT",
      name: "CATUMBELA AIRPORT",
      area_code: "CBT",
      city_name: "Catumbela",
      country_code: "AO"
  },
  {
      id: "CBU",
      name: "Drewitz",
      area_code: "CBU",
      city_name: "Cottbus",
      country_code: "DE"
  },
  {
      id: "CBV",
      name: "Coban",
      area_code: "CBV",
      city_name: "Coban",
      country_code: "GT"
  },
  {
      id: "CBW",
      name: "G.Guias de Aquino",
      area_code: "CBW",
      city_name: "Campo Mourao",
      country_code: "BR"
  },
  {
      id: "CBX",
      name: "Condobolin",
      area_code: "CBX",
      city_name: "Condobolin",
      country_code: "AU"
  },
  {
      id: "CBY",
      name: "Canobie",
      area_code: "CBY",
      city_name: "Canobie",
      country_code: "AU"
  },
  {
      id: "CCB",
      name: "Cable",
      area_code: "CCB",
      city_name: "Upland",
      country_code: "US"
  },
  {
      id: "CCG",
      name: "Crane County",
      area_code: "CCG",
      city_name: "Crane",
      country_code: "US"
  },
  {
      id: "CCH",
      name: "Chile Chico",
      area_code: "CCH",
      city_name: "Chile Chico",
      country_code: "CL"
  },
  {
      id: "CCI",
      name: "Olavo Cecco Rigon",
      area_code: "CCI",
      city_name: "Concordia",
      country_code: "BR"
  },
  {
      id: "CCL",
      name: "Chinchilla",
      area_code: "CCL",
      city_name: "Chinchilla",
      country_code: "AU"
  },
  {
      id: "CCN",
      name: "Chakcharan Airport",
      area_code: "CCN",
      city_name: "Chaghcharan",
      country_code: "AF"
  },
  {
      id: "CCO",
      name: "Carimagua",
      area_code: "CCO",
      city_name: "Carimagua",
      country_code: "CO"
  },
  {
      id: "CCT",
      name: "Colonia Catriel",
      area_code: "CCT",
      city_name: "Catriel",
      country_code: "AR"
  },
  {
      id: "CCW",
      name: "Cowell",
      area_code: "CCW",
      city_name: "Cowell",
      country_code: "AU"
  },
  {
      id: "CCX",
      name: "Caceres",
      area_code: "CCX",
      city_name: "Caceres",
      country_code: "BR"
  },
  {
      id: "CCY",
      name: "Northeast Iowa Rgnl",
      area_code: "CCY",
      city_name: "Charles City",
      country_code: "US"
  },
  {
      id: "CCZ",
      name: "International",
      area_code: "CCZ",
      city_name: "Chub Cay",
      country_code: "BS"
  },
  {
      id: "CDB",
      name: "COLD BAY AIRPORT",
      area_code: "CDB",
      city_name: "Cold Bay",
      country_code: "US"
  },
  {
      id: "CDE",
      name: "Puning Airport",
      area_code: "CDE",
      city_name: "Chengde",
      country_code: "CN"
  },
  {
      id: "CDJ",
      name: "Conceicao do Araguaia",
      area_code: "CDJ",
      city_name: "Conceicao do Araguaia",
      country_code: "BR"
  },
  {
      id: "CDK",
      name: "George T. Lewis",
      area_code: "CDK",
      city_name: "Cedar Key",
      country_code: "US"
  },
  {
      id: "CDN",
      name: "Woodward Field",
      area_code: "CDN",
      city_name: "Camden",
      country_code: "US"
  },
  {
      id: "CDO",
      name: "Cradock",
      area_code: "CDO",
      city_name: "Cradock",
      country_code: "ZA"
  },
  {
      id: "CDQ",
      name: "Croydon",
      area_code: "CDQ",
      city_name: "Croydon",
      country_code: "AU"
  },
  {
      id: "CDS",
      name: "Municipal",
      area_code: "CDS",
      city_name: "Childress",
      country_code: "US"
  },
  {
      id: "CDT",
      name: "Castellon de la Plana Airport",
      area_code: "CDT",
      city_name: "Castellon de la Plana",
      country_code: "ES"
  },
  {
      id: "CDU",
      name: "Camden",
      area_code: "CDU",
      city_name: "Camden",
      country_code: "AU"
  },
  {
      id: "CDY",
      name: "Cagayan de Sulu",
      area_code: "CDY",
      city_name: "Mapun",
      country_code: "PH"
  },
  {
      id: "CEE",
      name: "Cherepovets Airport",
      area_code: "CEE",
      city_name: "Cherepovets",
      country_code: "RU"
  },
  {
      id: "CEM",
      name: "CENTRAL AIRPORT",
      area_code: "CEM",
      city_name: "Central",
      country_code: "US"
  },
  {
      id: "CEO",
      name: "Waku-Kungo",
      area_code: "CEO",
      city_name: "Waku-Kungo",
      country_code: "AO"
  },
  {
      id: "CEP",
      name: "Concepcion",
      area_code: "CEP",
      city_name: "Concepcion",
      country_code: "BO"
  },
  {
      id: "CEV",
      name: "Mettel Field",
      area_code: "CEV",
      city_name: "Connersville",
      country_code: "US"
  },
  {
      id: "CEW",
      name: "Bob Sikes",
      area_code: "CEW",
      city_name: "Crestview",
      country_code: "US"
  },
  {
      id: "CFD",
      name: "Coulter Field",
      area_code: "CFD",
      city_name: "Bryan",
      country_code: "US"
  },
  {
      id: "CFF",
      name: "Cafunfo",
      area_code: "CFF",
      city_name: "Cafunfo",
      country_code: "AO"
  },
  {
      id: "CFH",
      name: "Clifton Hills",
      area_code: "CFH",
      city_name: "Clifton Hills",
      country_code: "AU"
  },
  {
      id: "CFI",
      name: "Camfield Station",
      area_code: "CFI",
      city_name: "Camfield Station",
      country_code: "AU"
  },
  {
      id: "CFM",
      name: "Leismer",
      area_code: "CFM",
      city_name: "Conklin",
      country_code: "CA"
  },
  {
      id: "CFO",
      name: "Confresa",
      area_code: "CFO",
      city_name: "Confresa",
      country_code: "BR"
  },
  {
      id: "CFP",
      name: "Carpentaria Downs",
      area_code: "CFP",
      city_name: "Carpentaria Downs",
      country_code: "AU"
  },
  {
      id: "CFV",
      name: "Municipal",
      area_code: "CFV",
      city_name: "Coffeyville",
      country_code: "US"
  },
  {
      id: "CGC",
      name: "Cape Gloucester",
      area_code: "CGC",
      city_name: "Cape Gloucester",
      country_code: "PG"
  },
  {
      id: "CGE",
      name: "Cambridge-Dorchester",
      area_code: "CGE",
      city_name: "Cambridge",
      country_code: "US"
  },
  {
      id: "CGG",
      name: "Casiguran",
      area_code: "CGG",
      city_name: "Casiguran",
      country_code: "PH"
  },
  {
      id: "CGJ",
      name: "Kasompe",
      area_code: "CGJ",
      city_name: "Chingola",
      country_code: "ZM"
  },
  {
      id: "CGM",
      name: "MAMBAJAO AIRPORT",
      area_code: "CGM",
      city_name: "Camiguin Island",
      country_code: "PH"
  },
  {
      id: "CGS",
      name: "College Park",
      area_code: "CGS",
      city_name: "College Park",
      country_code: "US"
  },
  {
      id: "CGT",
      name: "Chinguetti",
      area_code: "CGT",
      city_name: "Chinguetti",
      country_code: "MR"
  },
  {
      id: "CGV",
      name: "Caiguna",
      area_code: "CGV",
      city_name: "Caiguna",
      country_code: "AU"
  },
  {
      id: "CHB",
      name: "Chilas",
      area_code: "CHB",
      city_name: "Chilas",
      country_code: "PK"
  },
  {
      id: "CHH",
      name: "Chachapoyas",
      area_code: "CHH",
      city_name: "Chachapoyas",
      country_code: "PE"
  },
  {
      id: "CHJ",
      name: "Chipinge",
      area_code: "CHJ",
      city_name: "Chipinge",
      country_code: "ZW"
  },
  {
      id: "CHK",
      name: "Municipal",
      area_code: "CHK",
      city_name: "Chickasha",
      country_code: "US"
  },
  {
      id: "CHL",
      name: "Challis",
      area_code: "CHL",
      city_name: "Challis",
      country_code: "US"
  },
  {
      id: "CHN",
      name: "Jeonju",
      area_code: "CHN",
      city_name: "Jeonju",
      country_code: "KR"
  },
  {
      id: "CHZ",
      name: "State",
      area_code: "CHZ",
      city_name: "Chiloquin",
      country_code: "US"
  },
  {
      id: "CIE",
      name: "Collie",
      area_code: "CIE",
      city_name: "Collie",
      country_code: "AU"
  },
  {
      id: "CII",
      name: "Cildir",
      area_code: "CII",
      city_name: "Aydin",
      country_code: "TR"
  },
  {
      id: "CIK",
      name: "CHALKYITSIK AIRPORT",
      area_code: "CIK",
      city_name: "Chalkyitsik",
      country_code: "US"
  },
  {
      id: "CIM",
      name: "Cimitarra",
      area_code: "CIM",
      city_name: "Cimitarra",
      country_code: "CO"
  },
  {
      id: "CIN",
      name: "Arthur N. Neu",
      area_code: "CIN",
      city_name: "Carroll",
      country_code: "US"
  },
  {
      id: "CIP",
      name: "Chipata",
      area_code: "CIP",
      city_name: "Chipata",
      country_code: "ZM"
  },
  {
      id: "CIQ",
      name: "Chiquimula",
      area_code: "CIQ",
      city_name: "Chiquimula",
      country_code: "GT"
  },
  {
      id: "CIS",
      name: "Canton Island",
      area_code: "CIS",
      city_name: "Canton Island",
      country_code: "KI"
  },
  {
      id: "CIZ",
      name: "Coari",
      area_code: "CIZ",
      city_name: "Coari",
      country_code: "BR"
  },
  {
      id: "CJD",
      name: "Candilejas",
      area_code: "CJD",
      city_name: "Candilejas",
      country_code: "CO"
  },
  {
      id: "CJF",
      name: "Coondewanna Airport",
      area_code: "CJF",
      city_name: "Coondewanna",
      country_code: "AU"
  },
  {
      id: "CJH",
      name: "Tsylos Park Lodge",
      area_code: "CJH",
      city_name: "Chilko Lake",
      country_code: "CA"
  },
  {
      id: "CJT",
      name: "Copalar",
      area_code: "CJT",
      city_name: "Comitan",
      country_code: "MX"
  },
  {
      id: "CKA",
      name: "Kegelman AF Aux Field",
      area_code: "CKA",
      city_name: "Cherokee",
      country_code: "US"
  },
  {
      id: "CKH",
      name: "Chokurdah Airport",
      area_code: "CKH",
      city_name: "Chokurdakh",
      country_code: "RU"
  },
  {
      id: "CKI",
      name: "Croker Island",
      area_code: "CKI",
      city_name: "Croker Island",
      country_code: "AU"
  },
  {
      id: "CKK",
      name: "Sharp County Regional",
      area_code: "CKK",
      city_name: "Ash Flat",
      country_code: "US"
  },
  {
      id: "CKL",
      name: "Chkalovsky",
      area_code: "CKL",
      city_name: "Chkalovsky",
      country_code: "RU"
  },
  {
      id: "CKN",
      name: "Municipal",
      area_code: "CKN",
      city_name: "Crookston",
      country_code: "US"
  },
  {
      id: "CKO",
      name: "Francisco Lacerda Jr.",
      area_code: "CKO",
      city_name: "Cornelio Procopio",
      country_code: "BR"
  },
  {
      id: "CKR",
      name: "Crane Island",
      area_code: "CKR",
      city_name: "Crane Island",
      country_code: "US"
  },
  {
      id: "CKT",
      name: "Sarakhs",
      area_code: "CKT",
      city_name: "Sarakhs",
      country_code: "IR"
  },
  {
      id: "CKW",
      name: "Christmas Creek Mine",
      area_code: "CKW",
      city_name: "Christmas Creek Mine",
      country_code: "AU"
  },
  {
      id: "CKX",
      name: "CHICKEN AIRPORT",
      area_code: "CKX",
      city_name: "Chicken",
      country_code: "US"
  },
  {
      id: "CLA",
      name: "Comilla",
      area_code: "CLA",
      city_name: "Comilla",
      country_code: "BD"
  },
  {
      id: "CLH",
      name: "Coolah",
      area_code: "CLH",
      city_name: "Coolah",
      country_code: "AU"
  },
  {
      id: "CLI",
      name: "Municipal",
      area_code: "CLI",
      city_name: "Clintonville",
      country_code: "US"
  },
  {
      id: "CLN",
      name: "Carolina",
      area_code: "CLN",
      city_name: "Carolina",
      country_code: "BR"
  },
  {
      id: "CLP",
      name: "CLARKS POINT AIRPORT",
      area_code: "CLP",
      city_name: "Clarks Point",
      country_code: "US"
  },
  {
      id: "CLS",
      name: "Chehalis-Centralia",
      area_code: "CLS",
      city_name: "Chehalis",
      country_code: "US"
  },
  {
      id: "CLX",
      name: "Clorinda",
      area_code: "CLX",
      city_name: "Clorinda",
      country_code: "AR"
  },
  {
      id: "CLZ",
      name: "Calabozo",
      area_code: "CLZ",
      city_name: "Calabozo",
      country_code: "VE"
  },
  {
      id: "CMC",
      name: "Camocim",
      area_code: "CMC",
      city_name: "Camocim",
      country_code: "BR"
  },
  {
      id: "CMJ",
      name: "Qimei",
      area_code: "CMJ",
      city_name: "Qimei",
      country_code: "CN"
  },
  {
      id: "CMK",
      name: "Club Makokola",
      area_code: "CMK",
      city_name: "Club Makokola",
      country_code: "MW"
  },
  {
      id: "CML",
      name: "Camooweal",
      area_code: "CML",
      city_name: "Camooweal",
      country_code: "AU"
  },
  {
      id: "CMM",
      name: "Carmelita",
      area_code: "CMM",
      city_name: "Carmelita",
      country_code: "GT"
  },
  {
      id: "CMO",
      name: "Obbia",
      area_code: "CMO",
      city_name: "Obbia",
      country_code: "SO"
  },
  {
      id: "CMP",
      name: "Santana do Araguaia",
      area_code: "CMP",
      city_name: "Santana do Araguaia",
      country_code: "BR"
  },
  {
      id: "CMS",
      name: "Scusciuban",
      area_code: "CMS",
      city_name: "Scusciuban",
      country_code: "SO"
  },
  {
      id: "CMT",
      name: "Cameta",
      area_code: "CMT",
      city_name: "Cameta",
      country_code: "BR"
  },
  {
      id: "CMZ",
      name: "Caia",
      area_code: "CMZ",
      city_name: "Caia",
      country_code: "MZ"
  },
  {
      id: "CNA",
      name: "Cananea",
      area_code: "CNA",
      city_name: "Cananea",
      country_code: "MX"
  },
  {
      id: "CNK",
      name: "Blosser Municipal",
      area_code: "CNK",
      city_name: "Concordia",
      country_code: "US"
  },
  {
      id: "CNL",
      name: "Sindal",
      area_code: "CNL",
      city_name: "Sindal",
      country_code: "DK"
  },
  {
      id: "CNO",
      name: "Chino",
      area_code: "CNO",
      city_name: "Chino",
      country_code: "US"
  },
  {
      id: "CNP",
      name: "NEERLERIT INAAT AIRPORT",
      area_code: "CNP",
      city_name: "Neerlerit Inaat",
      country_code: "GL"
  },
  {
      id: "CNR",
      name: "Chanaral",
      area_code: "CNR",
      city_name: "Chanaral",
      country_code: "CL"
  },
  {
      id: "CNT",
      name: "Charata",
      area_code: "CNT",
      city_name: "Charata",
      country_code: "AR"
  },
  {
      id: "CNU",
      name: "Martin Johnson",
      area_code: "CNU",
      city_name: "Chanute",
      country_code: "US"
  },
  {
      id: "CNV",
      name: "Socrates Rezende",
      area_code: "CNV",
      city_name: "Canavieiras",
      country_code: "BR"
  },
  {
      id: "CNZ",
      name: "Cangamba",
      area_code: "CNZ",
      city_name: "Cangamba",
      country_code: "AO"
  },
  {
      id: "COB",
      name: "Coolibah",
      area_code: "COB",
      city_name: "Coolibah",
      country_code: "AU"
  },
  {
      id: "COL",
      name: "Coll Island",
      area_code: "COL",
      city_name: "Coll Island",
      country_code: "GB"
  },
  {
      id: "COM",
      name: "Municipal",
      area_code: "COM",
      city_name: "Coleman",
      country_code: "US"
  },
  {
      id: "COP",
      name: "Westville",
      area_code: "COP",
      city_name: "Cooperstown",
      country_code: "US"
  },
  {
      id: "COT",
      name: "La Salle County",
      area_code: "COT",
      city_name: "Cotulla",
      country_code: "US"
  },
  {
      id: "COW",
      name: "Tambillos",
      area_code: "COW",
      city_name: "Coquimbo",
      country_code: "CL"
  },
  {
      id: "COY",
      name: "Coolawanyah Station",
      area_code: "COY",
      city_name: "Coolawanyah Station",
      country_code: "AU"
  },
  {
      id: "COZ",
      name: "14 de Junio",
      area_code: "COZ",
      city_name: "Constanza",
      country_code: "DO"
  },
  {
      id: "CPA",
      name: "Cape Palmas",
      area_code: "CPA",
      city_name: "Harper",
      country_code: "LR"
  },
  {
      id: "CPF",
      name: "Nglonram",
      area_code: "CPF",
      city_name: "Cepu",
      country_code: "ID"
  },
  {
      id: "CPG",
      name: "Carmen de Patagones",
      area_code: "CPG",
      city_name: "Carmen de Patagones",
      country_code: "AR"
  },
  {
      id: "CPI",
      name: "Cape Orford",
      area_code: "CPI",
      city_name: "Cape Orford",
      country_code: "PG"
  },
  {
      id: "CPL",
      name: "General Navas Pardo",
      area_code: "CPL",
      city_name: "Chaparral",
      country_code: "CO"
  },
  {
      id: "CPN",
      name: "Cape Rodney",
      area_code: "CPN",
      city_name: "Cape Rodney",
      country_code: "PG"
  },
  {
      id: "CPP",
      name: "Coposa",
      area_code: "CPP",
      city_name: "Pica",
      country_code: "CL"
  },
  {
      id: "CPU",
      name: "Cururupu",
      area_code: "CPU",
      city_name: "Cururupu",
      country_code: "BR"
  },
  {
      id: "CQA",
      name: "Canarana",
      area_code: "CQA",
      city_name: "Canarana",
      country_code: "BR"
  },
  {
      id: "CQP",
      name: "Cape Flattery",
      area_code: "CQP",
      city_name: "Cape Flattery",
      country_code: "AU"
  },
  {
      id: "CQS",
      name: "Costa Marques",
      area_code: "CQS",
      city_name: "Costa Marques",
      country_code: "BR"
  },
  {
      id: "CQT",
      name: "Caquetania",
      area_code: "CQT",
      city_name: "Caquetania",
      country_code: "CO"
  },
  {
      id: "CRB",
      name: "Collarenebri",
      area_code: "CRB",
      city_name: "Collarenebri",
      country_code: "AU"
  },
  {
      id: "CRF",
      name: "Carnot",
      area_code: "CRF",
      city_name: "Carnot",
      country_code: "CF"
  },
  {
      id: "CRH",
      name: "Cherrabah",
      area_code: "CRH",
      city_name: "Cherrabah",
      country_code: "AU"
  },
  {
      id: "CRJ",
      name: "Coorabie",
      area_code: "CRJ",
      city_name: "Coorabie",
      country_code: "AU"
  },
  {
      id: "CRR",
      name: "Ceres",
      area_code: "CRR",
      city_name: "Ceres",
      country_code: "AR"
  },
  {
      id: "CRY",
      name: "Carlton Hill",
      area_code: "CRY",
      city_name: "Carlton Hill",
      country_code: "AU"
  },
  {
      id: "CSA",
      name: "Colonsay",
      area_code: "CSA",
      city_name: "Colonsay",
      country_code: "GB"
  },
  {
      id: "CSB",
      name: "Caransebes",
      area_code: "CSB",
      city_name: "Caransebes",
      country_code: "RO"
  },
  {
      id: "CSC",
      name: "Mojica",
      area_code: "CSC",
      city_name: "Canas",
      country_code: "CR"
  },
  {
      id: "CSD",
      name: "Cresswell Downs",
      area_code: "CSD",
      city_name: "Cresswell Downs",
      country_code: "AU"
  },
  {
      id: "CSE",
      name: "Buckhorn Ranch",
      area_code: "CSE",
      city_name: "Crested Butte",
      country_code: "US"
  },
  {
      id: "CSH",
      name: "Solovetsky Airport",
      area_code: "CSH",
      city_name: "Solovetsky",
      country_code: "RU"
  },
  {
      id: "CSK",
      name: "Cap Skirring Airport",
      area_code: "CSK",
      city_name: "Cap Skiring",
      country_code: "SN"
  },
  {
      id: "CSQ",
      name: "Municipal",
      area_code: "CSQ",
      city_name: "Creston",
      country_code: "US"
  },
  {
      id: "CSR",
      name: "Casuarito",
      area_code: "CSR",
      city_name: "Casuarito",
      country_code: "CO"
  },
  {
      id: "CSS",
      name: "Cassilandia",
      area_code: "CSS",
      city_name: "Cassilandia",
      country_code: "BR"
  },
  {
      id: "CST",
      name: "SPB",
      area_code: "CST",
      city_name: "Castaway Island",
      country_code: "FJ"
  },
  {
      id: "CSZ",
      name: "Brigadier Hector Ruiz",
      area_code: "CSZ",
      city_name: "Coronel Suarez",
      country_code: "AR"
  },
  {
      id: "CTB",
      name: "International",
      area_code: "CTB",
      city_name: "Cut Bank",
      country_code: "US"
  },
  {
      id: "CTE",
      name: "Carti",
      area_code: "CTE",
      city_name: "Carti",
      country_code: "PA"
  },
  {
      id: "CTF",
      name: "Coatepeque",
      area_code: "CTF",
      city_name: "Coatepeque",
      country_code: "GT"
  },
  {
      id: "CTH",
      name: "Chester County",
      area_code: "CTH",
      city_name: "Coatesville",
      country_code: "US"
  },
  {
      id: "CTI",
      name: "Cuito Cuanavale",
      area_code: "CTI",
      city_name: "Cuito Cuanavale",
      country_code: "AO"
  },
  {
      id: "CTK",
      name: "Municipal",
      area_code: "CTK",
      city_name: "Canton",
      country_code: "US"
  },
  {
      id: "CTO",
      name: "Executive Airpark",
      area_code: "CTO",
      city_name: "Calverton",
      country_code: "US"
  },
  {
      id: "CTP",
      name: "Carutapera",
      area_code: "CTP",
      city_name: "Carutapera",
      country_code: "BR"
  },
  {
      id: "CTQ",
      name: "Santa Vitoria Palmar",
      area_code: "CTQ",
      city_name: "Santa Vitoria Palmar",
      country_code: "BR"
  },
  {
      id: "CTR",
      name: "Cattle Creek",
      area_code: "CTR",
      city_name: "Cattle Creek",
      country_code: "AU"
  },
  {
      id: "CTT",
      name: "Le Castellet",
      area_code: "CTT",
      city_name: "Le Castellet",
      country_code: "FR"
  },
  {
      id: "CTX",
      name: "Cortland County",
      area_code: "CTX",
      city_name: "Cortland",
      country_code: "US"
  },
  {
      id: "CTY",
      name: "Cross City",
      area_code: "CTY",
      city_name: "Cross City",
      country_code: "US"
  },
  {
      id: "CTZ",
      name: "Sampson County",
      area_code: "CTZ",
      city_name: "Clinton",
      country_code: "US"
  },
  {
      id: "CUD",
      name: "Caloundra",
      area_code: "CUD",
      city_name: "Caloundra",
      country_code: "AU"
  },
  {
      id: "CUH",
      name: "Municipal",
      area_code: "CUH",
      city_name: "Cushing",
      country_code: "US"
  },
  {
      id: "CUI",
      name: "Currillo",
      area_code: "CUI",
      city_name: "Currillo",
      country_code: "CO"
  },
  {
      id: "CUK",
      name: "CAYE CAULKER AIRPORT",
      area_code: "CUK",
      city_name: "Caye Caulker",
      country_code: "BZ"
  },
  {
      id: "CUO",
      name: "Caruru",
      area_code: "CUO",
      city_name: "Caruru",
      country_code: "CO"
  },
  {
      id: "CUP",
      name: "Jose F. Bermudez",
      area_code: "CUP",
      city_name: "Carupano",
      country_code: "VE"
  },
  {
      id: "CUT",
      name: "Cutral-Co",
      area_code: "CUT",
      city_name: "Cutral-Co",
      country_code: "AR"
  },
  {
      id: "CUV",
      name: "Casigua El Cubo",
      area_code: "CUV",
      city_name: "Casigua El Cubo",
      country_code: "VE"
  },
  {
      id: "CVB",
      name: "Chungrebu",
      area_code: "CVB",
      city_name: "Chungrebu",
      country_code: "PG"
  },
  {
      id: "CVC",
      name: "Cleve",
      area_code: "CVC",
      city_name: "Cleve",
      country_code: "AU"
  },
  {
      id: "CVE",
      name: "Covenas",
      area_code: "CVE",
      city_name: "Covenas",
      country_code: "CO"
  },
  {
      id: "CVH",
      name: "Caviahue",
      area_code: "CVH",
      city_name: "Caviahue",
      country_code: "AR"
  },
  {
      id: "CVI",
      name: "Caleta Olivia",
      area_code: "CVI",
      city_name: "Caleta Olivia",
      country_code: "AR"
  },
  {
      id: "CVL",
      name: "Cape Vogel",
      area_code: "CVL",
      city_name: "Cape Vogel",
      country_code: "PG"
  },
  {
      id: "CWF",
      name: "Chennault Intl",
      area_code: "LCH",
      city_name: "Lake Charles",
      country_code: "US"
  },
  {
      id: "CWR",
      name: "Cowarie",
      area_code: "CWR",
      city_name: "Cowarie",
      country_code: "AU"
  },
  {
      id: "CWS",
      name: "Center Island",
      area_code: "CWS",
      city_name: "Center Island",
      country_code: "US"
  },
  {
      id: "CXA",
      name: "Caicara de Orinoco",
      area_code: "CXA",
      city_name: "Caicara de Orinoco",
      country_code: "VE"
  },
  {
      id: "CXN",
      name: "Candala",
      area_code: "CXN",
      city_name: "Candala",
      country_code: "SO"
  },
  {
      id: "CXQ",
      name: "Christmas Creek Stn",
      area_code: "CXQ",
      city_name: "Christmas Creek Stn",
      country_code: "AU"
  },
  {
      id: "CXT",
      name: "Charters Towers",
      area_code: "CXT",
      city_name: "Charters Towers",
      country_code: "AU"
  },
  {
      id: "CXY",
      name: "Cat Cays",
      area_code: "CXY",
      city_name: "Cat Cays",
      country_code: "BS"
  },
  {
      id: "CYA",
      name: "Antoine-Simon",
      area_code: "CYA",
      city_name: "Les Cayes",
      country_code: "HT"
  },
  {
      id: "CYC",
      name: "Caye Chapel Airport",
      area_code: "CYC",
      city_name: "Caye Chapel",
      country_code: "BZ"
  },
  {
      id: "CYF",
      name: "Chefornak Sea Plane Base",
      area_code: "CYF",
      city_name: "Chefornak",
      country_code: "US"
  },
  {
      id: "CYG",
      name: "Corryong",
      area_code: "CYG",
      city_name: "Corryong",
      country_code: "AU"
  },
  {
      id: "CYL",
      name: "Coyoles",
      area_code: "CYL",
      city_name: "Coyoles",
      country_code: "HN"
  },
  {
      id: "CYU",
      name: "Cuyo",
      area_code: "CYU",
      city_name: "Cuyo",
      country_code: "PH"
  },
  {
      id: "CYW",
      name: "Capt. Rogelio Castillo",
      area_code: "CYW",
      city_name: "Celaya",
      country_code: "MX"
  },
  {
      id: "CYX",
      name: "Cherskiy Airport",
      area_code: "CYX",
      city_name: "Chersky",
      country_code: "RU"
  },
  {
      id: "CYZ",
      name: "CAUAYAN AIRPORT",
      area_code: "CYZ",
      city_name: "Cauayan",
      country_code: "PH"
  },
  {
      id: "CZB",
      name: "Carlos Ruhl",
      area_code: "CZB",
      city_name: "Cruz Alta",
      country_code: "BR"
  },
  {
      id: "CZF",
      name: "Cape Romanzof",
      area_code: "CZF",
      city_name: "Cape Romanzof",
      country_code: "US"
  },
  {
      id: "CZK",
      name: "State",
      area_code: "CZK",
      city_name: "Cascade Locks",
      country_code: "US"
  },
  {
      id: "CZN",
      name: "Chisana",
      area_code: "CZN",
      city_name: "Chisana",
      country_code: "US"
  },
  {
      id: "CZO",
      name: "Chistochina",
      area_code: "CZO",
      city_name: "Chistochina",
      country_code: "US"
  },
  {
      id: "CZP",
      name: "Sea Plane Base",
      area_code: "CZP",
      city_name: "Cape Pole",
      country_code: "US"
  },
  {
      id: "CZT",
      name: "Dimmit County",
      area_code: "CZT",
      city_name: "Carrizo Springs",
      country_code: "US"
  },
  {
      id: "CZY",
      name: "Cluny",
      area_code: "CZY",
      city_name: "Cluny",
      country_code: "AU"
  },
  {
      id: "DAA",
      name: "Davison AAF",
      area_code: "DAA",
      city_name: "Fort Belvoir",
      country_code: "US"
  },
  {
      id: "DAF",
      name: "Daup",
      area_code: "DAF",
      city_name: "Daup",
      country_code: "PG"
  },
  {
      id: "DAH",
      name: "Dathina",
      area_code: "DAH",
      city_name: "Dathina",
      country_code: "YE"
  },
  {
      id: "DAO",
      name: "Dabo",
      area_code: "DAO",
      city_name: "Dabo",
      country_code: "PG"
  },
  {
      id: "DAP",
      name: "Darchula",
      area_code: "DAP",
      city_name: "Darchula",
      country_code: "NP"
  },
  {
      id: "DAS",
      name: "Great Bear Lake",
      area_code: "DAS",
      city_name: "Great Bear Lake",
      country_code: "CA"
  },
  {
      id: "DAZ",
      name: "Darwaz",
      area_code: "DAZ",
      city_name: "Darwaz",
      country_code: "AF"
  },
  {
      id: "DBB",
      name: "Al Alamain Intl",
      area_code: "DBB",
      city_name: "Dabah",
      country_code: "EG"
  },
  {
      id: "DBC",
      name: "Chang'an Airport",
      area_code: "DBC",
      city_name: "Baicheng",
      country_code: "CN"
  },
  {
      id: "DBD",
      name: "Dhanbad",
      area_code: "DBD",
      city_name: "Dhanbad",
      country_code: "IN"
  },
  {
      id: "DBK",
      name: "Dutch Bay SPB",
      area_code: "DBK",
      city_name: "Kalpitiya",
      country_code: "LK"
  },
  {
      id: "DBM",
      name: "Debre Markos",
      area_code: "DBM",
      city_name: "Debre Markos",
      country_code: "ET"
  },
  {
      id: "DBP",
      name: "Debepare",
      area_code: "DBP",
      city_name: "Debepare",
      country_code: "PG"
  },
  {
      id: "DBS",
      name: "Municipal",
      area_code: "DBS",
      city_name: "Dubois",
      country_code: "US"
  },
  {
      id: "DBT",
      name: "Debre Tabor",
      area_code: "DBT",
      city_name: "Debre Tabor",
      country_code: "ET"
  },
  {
      id: "DBU",
      name: "Dambulu Oya Tank SPB",
      area_code: "DBU",
      city_name: "Dambulla",
      country_code: "LK"
  },
  {
      id: "DCG",
      name: "Dubai Creek SPB",
      area_code: "DXB",
      city_name: "Dubai",
      country_code: "AE"
  },
  {
      id: "DCI",
      name: "Air Base",
      area_code: "DCI",
      city_name: "Decimomannu",
      country_code: "IT"
  },
  {
      id: "DCK",
      name: "Dahl Creek",
      area_code: "DCK",
      city_name: "Dahl Creek",
      country_code: "US"
  },
  {
      id: "DCT",
      name: "Duncan Town",
      area_code: "DCT",
      city_name: "Duncan Town",
      country_code: "BS"
  },
  {
      id: "DDD",
      name: "Dhaalu Atoll Airport",
      area_code: "DDD",
      city_name: "Kudahuvadhoo",
      country_code: "MV"
  },
  {
      id: "DDM",
      name: "Dodoima",
      area_code: "DDM",
      city_name: "Dodoima",
      country_code: "PG"
  },
  {
      id: "DDN",
      name: "Delta Downs",
      area_code: "DDN",
      city_name: "Delta Downs",
      country_code: "AU"
  },
  {
      id: "DDU",
      name: "Dadu",
      area_code: "DDU",
      city_name: "Dadu",
      country_code: "PK"
  },
  {
      id: "DEF",
      name: "DEZFUL AIRPORT",
      area_code: "DEF",
      city_name: "Dezful",
      country_code: "IR"
  },
  {
      id: "DEM",
      name: "Dembi Dolo",
      area_code: "DEM",
      city_name: "Dembi Dolo",
      country_code: "ET"
  },
  {
      id: "DEP",
      name: "Deparijo",
      area_code: "DEP",
      city_name: "Deparijo",
      country_code: "IN"
  },
  {
      id: "DER",
      name: "Derim",
      area_code: "DER",
      city_name: "Derim",
      country_code: "PG"
  },
  {
      id: "DEX",
      name: "Yahukimo Airport",
      area_code: "DEX",
      city_name: "Yahukimo",
      country_code: "ID"
  },
  {
      id: "DFP",
      name: "Drumduff",
      area_code: "DFP",
      city_name: "Drumduff",
      country_code: "AU"
  },
  {
      id: "DGA",
      name: "DANGRIGA AIRPORT",
      area_code: "DGA",
      city_name: "Dangriga",
      country_code: "BZ"
  },
  {
      id: "DGC",
      name: "Degeh Bur",
      area_code: "DGC",
      city_name: "Degeh Bur",
      country_code: "ET"
  },
  {
      id: "DGD",
      name: "Dalgaranga Gold Mine",
      area_code: "DGD",
      city_name: "Dalgaranga Gold Mine",
      country_code: "AU"
  },
  {
      id: "DGF",
      name: "Douglas Lake",
      area_code: "DGF",
      city_name: "Douglas Lake",
      country_code: "CA"
  },
  {
      id: "DGK",
      name: "Dugong Beach Lodge",
      area_code: "DGK",
      city_name: "Dugong Beach Lodge",
      country_code: "MZ"
  },
  {
      id: "DGN",
      name: "NSWC",
      area_code: "DGN",
      city_name: "Dahlgren",
      country_code: "US"
  },
  {
      id: "DGR",
      name: "Dargaville",
      area_code: "DGR",
      city_name: "Dargaville",
      country_code: "NZ"
  },
  {
      id: "DGU",
      name: "Dedougou",
      area_code: "DGU",
      city_name: "Dedougou",
      country_code: "BF"
  },
  {
      id: "DGW",
      name: "Converse County",
      area_code: "DGW",
      city_name: "Douglas",
      country_code: "US"
  },
  {
      id: "DHB",
      name: "Sea Plane Base",
      area_code: "DHB",
      city_name: "Deer Harbor",
      country_code: "US"
  },
  {
      id: "DHD",
      name: "Durham Downs",
      area_code: "DHD",
      city_name: "Durham Downs",
      country_code: "AU"
  },
  {
      id: "DHG",
      name: "Dalnegorsk Airport",
      area_code: "DHG",
      city_name: "Dalnegorsk",
      country_code: "RU"
  },
  {
      id: "DHI",
      name: "DHANGARHI AIRPORT",
      area_code: "DHI",
      city_name: "Dhangarhi",
      country_code: "NP"
  },
  {
      id: "DHL",
      name: "Dhala",
      area_code: "DHL",
      city_name: "Dhala",
      country_code: "YE"
  },
  {
      id: "DHR",
      name: "De Kooy",
      area_code: "DHR",
      city_name: "Den Helder",
      country_code: "NL"
  },
  {
      id: "DHT",
      name: "Municipal",
      area_code: "DHT",
      city_name: "Dalhart",
      country_code: "US"
  },
  {
      id: "DIA",
      name: "Doha International",
      area_code: "DOH",
      city_name: "Doha",
      country_code: "QA"
  },
  {
      id: "DIM",
      name: "Ville",
      area_code: "DIM",
      city_name: "Dimbokro",
      country_code: "CI"
  },
  {
      id: "DIP",
      name: "Diapaga",
      area_code: "DIP",
      city_name: "Diapaga",
      country_code: "BF"
  },
  {
      id: "DIS",
      name: "Dolisie",
      area_code: "DIS",
      city_name: "Dolisie",
      country_code: "CG"
  },
  {
      id: "DIV",
      name: "Divo",
      area_code: "DIV",
      city_name: "Divo",
      country_code: "CI"
  },
  {
      id: "DJA",
      name: "Djougou",
      area_code: "DJA",
      city_name: "Djougou",
      country_code: "BJ"
  },
  {
      id: "DJH",
      name: "Jebel Ali SPB",
      area_code: "DXB",
      city_name: "Dubai",
      country_code: "AE"
  },
  {
      id: "DJM",
      name: "Djambala",
      area_code: "DJM",
      city_name: "Djambala",
      country_code: "CG"
  },
  {
      id: "DJO",
      name: "Daloa",
      area_code: "DJO",
      city_name: "Daloa",
      country_code: "CI"
  },
  {
      id: "DJU",
      name: "Djupivogur",
      area_code: "DJU",
      city_name: "Djupivogur",
      country_code: "IS"
  },
  {
      id: "DKK",
      name: "Chautauqua County",
      area_code: "DKK",
      city_name: "Dunkirk",
      country_code: "US"
  },
  {
      id: "DKS",
      name: "Dikson Airport",
      area_code: "DKS",
      city_name: "Dikson",
      country_code: "RU"
  },
  {
      id: "DKV",
      name: "Docker River",
      area_code: "DKV",
      city_name: "Docker River",
      country_code: "AU"
  },
  {
      id: "DLB",
      name: "D'Albertis",
      area_code: "DLB",
      city_name: "D'Albertis",
      country_code: "PG"
  },
  {
      id: "DLK",
      name: "Dulkaninna",
      area_code: "DLK",
      city_name: "Dulkaninna",
      country_code: "AU"
  },
  {
      id: "DLN",
      name: "Dillon",
      area_code: "DLN",
      city_name: "Dillon",
      country_code: "US"
  },
  {
      id: "DLR",
      name: "Dalnerechensk Airport",
      area_code: "DLR",
      city_name: "Dalnerechensk",
      country_code: "RU"
  },
  {
      id: "DLV",
      name: "Delissaville",
      area_code: "DLV",
      city_name: "Delissaville",
      country_code: "AU"
  },
  {
      id: "DLY",
      name: "Dillon's Bay Airport",
      area_code: "DLY",
      city_name: "Dillon's Bay",
      country_code: "VU"
  },
  {
      id: "DLZ",
      name: "DALANZADGAD AIRPORT",
      area_code: "DLZ",
      city_name: "Dalanzadgad",
      country_code: "MN"
  },
  {
      id: "DMN",
      name: "Municipal",
      area_code: "DMN",
      city_name: "Deming",
      country_code: "US"
  },
  {
      id: "DMR",
      name: "Dhamar",
      area_code: "DMR",
      city_name: "Dhamar",
      country_code: "YE"
  },
  {
      id: "DMT",
      name: "Diamantino",
      area_code: "DMT",
      city_name: "Diamantino",
      country_code: "BR"
  },
  {
      id: "DNB",
      name: "Dunbar",
      area_code: "DNB",
      city_name: "Dunbar",
      country_code: "AU"
  },
  {
      id: "DNF",
      name: "Martuba",
      area_code: "DNF",
      city_name: "Derna",
      country_code: "LY"
  },
  {
      id: "DNG",
      name: "Doongan",
      area_code: "DNG",
      city_name: "Doongan",
      country_code: "AU"
  },
  {
      id: "DNI",
      name: "Wad Madani",
      area_code: "DNI",
      city_name: "Wad Madani",
      country_code: "SD"
  },
  {
      id: "DNO",
      name: "Dianopolis",
      area_code: "DNO",
      city_name: "Dianopolis",
      country_code: "BR"
  },
  {
      id: "DNP",
      name: "Tribhuvannagar",
      area_code: "DNP",
      city_name: "Dang",
      country_code: "NP"
  },
  {
      id: "DNS",
      name: "Municipal",
      area_code: "DNS",
      city_name: "Denison",
      country_code: "US"
  },
  {
      id: "DNU",
      name: "Dinangat",
      area_code: "DNU",
      city_name: "Dinangat",
      country_code: "PG"
  },
  {
      id: "DNX",
      name: "Galegu",
      area_code: "DNX",
      city_name: "Dinder",
      country_code: "SD"
  },
  {
      id: "DOA",
      name: "Doany",
      area_code: "DOA",
      city_name: "Doany",
      country_code: "MG"
  },
  {
      id: "DOD",
      name: "DODOMA AIRPORT",
      area_code: "DOD",
      city_name: "Dodoma",
      country_code: "TZ"
  },
  {
      id: "DOE",
      name: "Djoemoe",
      area_code: "DOE",
      city_name: "Djoemoe",
      country_code: "SR"
  },
  {
      id: "DOI",
      name: "Doini Island",
      area_code: "DOI",
      city_name: "Doini Island",
      country_code: "PG"
  },
  {
      id: "DON",
      name: "Dos Lagunas",
      area_code: "DON",
      city_name: "Dos Lagunas",
      country_code: "GT"
  },
  {
      id: "DOO",
      name: "Dorobisoro",
      area_code: "DOO",
      city_name: "Dorobisoro",
      country_code: "PG"
  },
  {
      id: "DOP",
      name: "Dolpa",
      area_code: "DOP",
      city_name: "Dolpa",
      country_code: "NP"
  },
  {
      id: "DOR",
      name: "Dori",
      area_code: "DOR",
      city_name: "Dori",
      country_code: "BF"
  },
  {
      id: "DOS",
      name: "Dios",
      area_code: "DOS",
      city_name: "Dios",
      country_code: "PG"
  },
  {
      id: "DPA",
      name: "DuPage",
      area_code: "CHI",
      city_name: "Chicago",
      country_code: "US"
  },
  {
      id: "DPB",
      name: "Pampa Guanaco",
      area_code: "DPB",
      city_name: "Cameron",
      country_code: "CL"
  },
  {
      id: "DPG",
      name: "Michael AAF",
      area_code: "DPG",
      city_name: "Dugway",
      country_code: "US"
  },
  {
      id: "DPT",
      name: "Deputatsky Airport",
      area_code: "DPT",
      city_name: "Deputatsky",
      country_code: "RU"
  },
  {
      id: "DPU",
      name: "Dumpu",
      area_code: "DPU",
      city_name: "Dumpu",
      country_code: "PG"
  },
  {
      id: "DQM",
      name: "Duqm International Airport",
      area_code: "DQM",
      city_name: "Duqm",
      country_code: "OM"
  },
  {
      id: "DRA",
      name: "Desert Rock",
      area_code: "DRA",
      city_name: "Mercury",
      country_code: "US"
  },
  {
      id: "DRC",
      name: "Dirico",
      area_code: "DRC",
      city_name: "Dirico",
      country_code: "AO"
  },
  {
      id: "DRD",
      name: "Dorunda",
      area_code: "DRD",
      city_name: "Dorunda",
      country_code: "AU"
  },
  {
      id: "DRE",
      name: "Drummond Island",
      area_code: "DRE",
      city_name: "Drummond Island",
      country_code: "US"
  },
  {
      id: "DRG",
      name: "DEERING AIRPORT",
      area_code: "DRG",
      city_name: "Deering",
      country_code: "US"
  },
  {
      id: "DRH",
      name: "Dabra",
      area_code: "DRH",
      city_name: "Dabra",
      country_code: "ID"
  },
  {
      id: "DRI",
      name: "Beauregard Regional",
      area_code: "DRI",
      city_name: "DeRidder",
      country_code: "US"
  },
  {
      id: "DRJ",
      name: "Drietabbetje",
      area_code: "DRJ",
      city_name: "Drietabbetje",
      country_code: "SR"
  },
  {
      id: "DRR",
      name: "Durrie",
      area_code: "DRR",
      city_name: "Durrie",
      country_code: "AU"
  },
  {
      id: "DRU",
      name: "Drummond",
      area_code: "DRU",
      city_name: "Drummond",
      country_code: "US"
  },
  {
      id: "DRV",
      name: "Dharavandhoo Island Airport",
      area_code: "DRV",
      city_name: "Dharavandhoo Island",
      country_code: "MV"
  },
  {
      id: "DRY",
      name: "Drysdale River",
      area_code: "DRY",
      city_name: "Drysdale River",
      country_code: "AU"
  },
  {
      id: "DSC",
      name: "Dschang",
      area_code: "DSC",
      city_name: "Dschang",
      country_code: "CM"
  },
  {
      id: "DSE",
      name: "Combolcha Airport",
      area_code: "DSE",
      city_name: "Dessie",
      country_code: "ET"
  },
  {
      id: "DSG",
      name: "Dilasag",
      area_code: "DSG",
      city_name: "Dilasag",
      country_code: "PH"
  },
  {
      id: "DSK",
      name: "Dera Ismail Khan",
      area_code: "DSK",
      city_name: "Dera Ismail Khan",
      country_code: "PK"
  },
  {
      id: "DSO",
      name: "Sondok",
      area_code: "DSO",
      city_name: "Sondok",
      country_code: "KP"
  },
  {
      id: "DSV",
      name: "Municipal",
      area_code: "DSV",
      city_name: "Dansville",
      country_code: "US"
  },
  {
      id: "DSX",
      name: "Dongsha Island",
      area_code: "DSX",
      city_name: "Dongsha Island",
      country_code: "CN"
  },
  {
      id: "DTA",
      name: "Municipal",
      area_code: "DTA",
      city_name: "Delta",
      country_code: "US"
  },
  {
      id: "DTB",
      name: "Silangit",
      area_code: "DTB",
      city_name: "Siborong-Borong",
      country_code: "ID"
  },
  {
      id: "DTD",
      name: "Datadawai",
      area_code: "DTD",
      city_name: "Datadawai",
      country_code: "ID"
  },
  {
      id: "DTE",
      name: "Bagasbas",
      area_code: "DTE",
      city_name: "Daet",
      country_code: "PH"
  },
  {
      id: "DTI",
      name: "Diamantina",
      area_code: "DTI",
      city_name: "Diamantina",
      country_code: "BR"
  },
  {
      id: "DTL",
      name: "Wething Field",
      area_code: "DTL",
      city_name: "Detroit Lakes",
      country_code: "US"
  },
  {
      id: "DTR",
      name: "Decatur Shores",
      area_code: "DTR",
      city_name: "Decatur Island",
      country_code: "US"
  },
  {
      id: "DTU",
      name: "Dedu Airport",
      area_code: "DTU",
      city_name: "Wudalianchi",
      country_code: "CN"
  },
  {
      id: "DUA",
      name: "Regional - Eaker Field",
      area_code: "DUA",
      city_name: "Durant",
      country_code: "US"
  },
  {
      id: "DUE",
      name: "Dundo",
      area_code: "DUE",
      city_name: "Dundo",
      country_code: "AO"
  },
  {
      id: "DUF",
      name: "Pine Island",
      area_code: "DUF",
      city_name: "Corolla",
      country_code: "US"
  },
  {
      id: "DUK",
      name: "Dukuduku",
      area_code: "DUK",
      city_name: "Mtubatuba",
      country_code: "ZA"
  },
  {
      id: "DUM",
      name: "Pinang Kampai Airport",
      area_code: "DUM",
      city_name: "Dumai",
      country_code: "ID"
  },
  {
      id: "DVD",
      name: "Andavadoaka",
      area_code: "DVD",
      city_name: "Andavadoaka",
      country_code: "MG"
  },
  {
      id: "DVK",
      name: "Diavik",
      area_code: "DVK",
      city_name: "Diavik",
      country_code: "CA"
  },
  {
      id: "DVP",
      name: "Davenport Downs",
      area_code: "DVP",
      city_name: "Davenport Downs",
      country_code: "AU"
  },
  {
      id: "DVR",
      name: "Daly River Mission",
      area_code: "DVR",
      city_name: "Daly River Mission",
      country_code: "AU"
  },
  {
      id: "DWA",
      name: "Dwangwa",
      area_code: "DWA",
      city_name: "Dwangwa",
      country_code: "MW"
  },
  {
      id: "DWB",
      name: "Soalala",
      area_code: "DWB",
      city_name: "Soalala",
      country_code: "MG"
  },
  {
      id: "DWD",
      name: "Dawadmi Airport",
      area_code: "DWD",
      city_name: "Dawadmi",
      country_code: "SA"
  },
  {
      id: "DWO",
      name: "Diyawanna Oya Seaplane Base",
      area_code: "DWO",
      city_name: "Kotte",
      country_code: "LK"
  },
  {
      id: "DWR",
      name: "Air Base",
      area_code: "DWR",
      city_name: "Dwyer",
      country_code: "AF"
  },
  {
      id: "DXD",
      name: "Dixie",
      area_code: "DXD",
      city_name: "Dixie",
      country_code: "AU"
  },
  {
      id: "DXE",
      name: "Bruce Campbell Field",
      area_code: "DXE",
      city_name: "Madison",
      country_code: "US"
  },
  {
      id: "DYA",
      name: "Dysart",
      area_code: "DYA",
      city_name: "Dysart",
      country_code: "AU"
  },
  {
      id: "DYM",
      name: "Diamantina Lakes",
      area_code: "DYM",
      city_name: "Diamantina Lakes",
      country_code: "AU"
  },
  {
      id: "DZI",
      name: "Las Flores",
      area_code: "DZI",
      city_name: "Agustin Codazzi",
      country_code: "CO"
  },
  {
      id: "DZO",
      name: "Santa Bernardina",
      area_code: "DZO",
      city_name: "Durazno",
      country_code: "UY"
  },
  {
      id: "EAE",
      name: "Emae Airport",
      area_code: "EAE",
      city_name: "Emae Island",
      country_code: "VU"
  },
  {
      id: "EAL",
      name: "Elenak",
      area_code: "EAL",
      city_name: "Elenak",
      country_code: "MH"
  },
  {
      id: "EBH",
      name: "El Bayadh Airport",
      area_code: "EBH",
      city_name: "El Bayadh",
      country_code: "DZ"
  },
  {
      id: "EBM",
      name: "El Borma",
      area_code: "EBM",
      city_name: "El Borma",
      country_code: "TN"
  },
  {
      id: "EBN",
      name: "Ebadon Island",
      area_code: "EBN",
      city_name: "Ebadon Island",
      country_code: "MH"
  },
  {
      id: "EBS",
      name: "Municipal",
      area_code: "EBS",
      city_name: "Webster City",
      country_code: "US"
  },
  {
      id: "EBW",
      name: "Ebolowa",
      area_code: "EBW",
      city_name: "Ebolowa",
      country_code: "CM"
  },
  {
      id: "ECA",
      name: "Iosco County",
      area_code: "ECA",
      city_name: "East Tawas",
      country_code: "US"
  },
  {
      id: "ECI",
      name: "Costa Esmeralda",
      area_code: "ECI",
      city_name: "Rivas",
      country_code: "NI"
  },
  {
      id: "ECO",
      name: "El Encanto",
      area_code: "ECO",
      city_name: "El Encanto",
      country_code: "CO"
  },
  {
      id: "ECR",
      name: "El Charco",
      area_code: "ECR",
      city_name: "El Charco",
      country_code: "CO"
  },
  {
      id: "ECS",
      name: "Mondell Field",
      area_code: "ECS",
      city_name: "Newcastle",
      country_code: "US"
  },
  {
      id: "EDB",
      name: "El Debba",
      area_code: "EDB",
      city_name: "El Debba",
      country_code: "SD"
  },
  {
      id: "EDD",
      name: "Erldunda",
      area_code: "EDD",
      city_name: "Erldunda",
      country_code: "AU"
  },
  {
      id: "EDK",
      name: "Capt. Jack Thomas Mem.",
      area_code: "EDK",
      city_name: "El Dorado",
      country_code: "US"
  },
  {
      id: "EDN",
      name: "Yedinka",
      area_code: "EDN",
      city_name: "Yedinka",
      country_code: "RU"
  },
  {
      id: "EDQ",
      name: "Erandique",
      area_code: "EDQ",
      city_name: "Erandique",
      country_code: "HN"
  },
  {
      id: "EDW",
      name: "Edwards AFB",
      area_code: "EDW",
      city_name: "Edwards",
      country_code: "US"
  },
  {
      id: "EFK",
      name: "State",
      area_code: "EFK",
      city_name: "Newport",
      country_code: "US"
  },
  {
      id: "EFW",
      name: "Municipal",
      area_code: "EFW",
      city_name: "Jefferson",
      country_code: "US"
  },
  {
      id: "EGA",
      name: "Engati",
      area_code: "EGA",
      city_name: "Engati",
      country_code: "PG"
  },
  {
      id: "EGL",
      name: "Negele",
      area_code: "EGL",
      city_name: "Negele",
      country_code: "ET"
  },
  {
      id: "EGX",
      name: "EGEGIK AIRPORT",
      area_code: "EGX",
      city_name: "Egegik",
      country_code: "US"
  },
  {
      id: "EHM",
      name: "Cape Newenham",
      area_code: "EHM",
      city_name: "Cape Newenham",
      country_code: "US"
  },
  {
      id: "EIA",
      name: "Popondetta",
      area_code: "EIA",
      city_name: "Eia",
      country_code: "PG"
  },
  {
      id: "EIE",
      name: "Yeniseysk",
      area_code: "EIE",
      city_name: "Yeniseysk",
      country_code: "RU"
  },
  {
      id: "EIH",
      name: "Einasleigh",
      area_code: "EIH",
      city_name: "Einasleigh",
      country_code: "AU"
  },
  {
      id: "EIK",
      name: "Yeysk",
      area_code: "EIK",
      city_name: "Yeysk",
      country_code: "RU"
  },
  {
      id: "EIY",
      name: "Ein Yahav",
      area_code: "EIY",
      city_name: "Ein Yahav",
      country_code: "IL"
  },
  {
      id: "EJH",
      name: "WEDJH AIRPORT",
      area_code: "EJH",
      city_name: "Wedjh",
      country_code: "SA"
  },
  {
      id: "EJN",
      name: "Taolai Airport",
      area_code: "EJN",
      city_name: "Ejina Banner",
      country_code: "CN"
  },
  {
      id: "EJT",
      name: "Enejet Island",
      area_code: "EJT",
      city_name: "Enejet Island",
      country_code: "MH"
  },
  {
      id: "EKB",
      name: "Ekibastuz",
      area_code: "EKB",
      city_name: "Ekibastuz",
      country_code: "KZ"
  },
  {
      id: "EKD",
      name: "Elkedra",
      area_code: "EKD",
      city_name: "Elkedra",
      country_code: "AU"
  },
  {
      id: "EKE",
      name: "Ekereku",
      area_code: "EKE",
      city_name: "Ekereku",
      country_code: "GY"
  },
  {
      id: "ELA",
      name: "Eagle Lake",
      area_code: "ELA",
      city_name: "Eagle Lake",
      country_code: "US"
  },
  {
      id: "ELB",
      name: "Las Flores",
      area_code: "ELB",
      city_name: "El Banco",
      country_code: "CO"
  },
  {
      id: "ELG",
      name: "EL GOLEA AIRPORT",
      area_code: "ELG",
      city_name: "El Golea",
      country_code: "DZ"
  },
  {
      id: "ELJ",
      name: "El Recreo",
      area_code: "ELJ",
      city_name: "El Recreo",
      country_code: "CO"
  },
  {
      id: "ELN",
      name: "Bowers Field",
      area_code: "ELN",
      city_name: "Ellensburg",
      country_code: "US"
  },
  {
      id: "ELO",
      name: "Eldorado",
      area_code: "ELO",
      city_name: "Eldorado",
      country_code: "AR"
  },
  {
      id: "ELR",
      name: "Elelim",
      area_code: "ELR",
      city_name: "Elelim",
      country_code: "ID"
  },
  {
      id: "ELT",
      name: "El-Tor",
      area_code: "ELT",
      city_name: "El-Tor",
      country_code: "EG"
  },
  {
      id: "ELU",
      name: "GUEMAR AIRPORT",
      area_code: "ELU",
      city_name: "El Oued",
      country_code: "DZ"
  },
  {
      id: "ELV",
      name: "Elfin Cove Seaplane Base",
      area_code: "ELV",
      city_name: "Elfin Cove",
      country_code: "US"
  },
  {
      id: "ELX",
      name: "El Tigre",
      area_code: "ELX",
      city_name: "El Tigre",
      country_code: "VE"
  },
  {
      id: "EMG",
      name: "Empangeni",
      area_code: "EMG",
      city_name: "Empangeni",
      country_code: "ZA"
  },
  {
      id: "EMI",
      name: "Emirau Island",
      area_code: "EMI",
      city_name: "Emirau Island",
      country_code: "PG"
  },
  {
      id: "EMK",
      name: "EMMONAK AIRPORT",
      area_code: "EMK",
      city_name: "Emmonak",
      country_code: "US"
  },
  {
      id: "EML",
      name: "Emmen",
      area_code: "EML",
      city_name: "Emmen",
      country_code: "CH"
  },
  {
      id: "EMM",
      name: "Municipal",
      area_code: "EMM",
      city_name: "Kemmerer",
      country_code: "US"
  },
  {
      id: "EMO",
      name: "Emo",
      area_code: "EMO",
      city_name: "Emo",
      country_code: "PG"
  },
  {
      id: "EMS",
      name: "Embessa",
      area_code: "EMS",
      city_name: "Embessa",
      country_code: "PG"
  },
  {
      id: "EMX",
      name: "El Maiten",
      area_code: "EMX",
      city_name: "El Maiten",
      country_code: "AR"
  },
  {
      id: "EMY",
      name: "El-Minya",
      area_code: "EMY",
      city_name: "El-Minya",
      country_code: "EG"
  },
  {
      id: "ENB",
      name: "Eneabba",
      area_code: "ENB",
      city_name: "Eneabba",
      country_code: "AU"
  },
  {
      id: "ENF",
      name: "ENONTEKIO AIRPORT",
      area_code: "ENF",
      city_name: "Enontekio",
      country_code: "FI"
  },
  {
      id: "ENI",
      name: "El Nido",
      area_code: "ENI",
      city_name: "El Nido",
      country_code: "PH"
  },
  {
      id: "ENJ",
      name: "El Naranjo",
      area_code: "ENJ",
      city_name: "El Naranjo",
      country_code: "GT"
  },
  {
      id: "ENN",
      name: "Municipal",
      area_code: "ENN",
      city_name: "Nenana",
      country_code: "US"
  },
  {
      id: "ENT",
      name: "Enewetak Island",
      area_code: "ENT",
      city_name: "Enewetak Island",
      country_code: "MH"
  },
  {
      id: "EOR",
      name: "El Dorado",
      area_code: "EOR",
      city_name: "El Dorado",
      country_code: "VE"
  },
  {
      id: "EOS",
      name: "Hugh Robinson",
      area_code: "EOS",
      city_name: "Neosho",
      country_code: "US"
  },
  {
      id: "EOZ",
      name: "Elorza",
      area_code: "EOZ",
      city_name: "Elorza",
      country_code: "VE"
  },
  {
      id: "EPG",
      name: "Browns",
      area_code: "EPG",
      city_name: "Weeping Water",
      country_code: "US"
  },
  {
      id: "EPH",
      name: "Municipal",
      area_code: "EPH",
      city_name: "Ephrata",
      country_code: "US"
  },
  {
      id: "EPN",
      name: "Epena",
      area_code: "EPN",
      city_name: "Epena",
      country_code: "CG"
  },
  {
      id: "EPT",
      name: "Eliptamin",
      area_code: "EPT",
      city_name: "Eliptamin",
      country_code: "PG"
  },
  {
      id: "ERA",
      name: "Erigavo",
      area_code: "ERA",
      city_name: "Erigavo",
      country_code: "SO"
  },
  {
      id: "ERB",
      name: "Ernabella",
      area_code: "ERB",
      city_name: "Ernabella",
      country_code: "AU"
  },
  {
      id: "ERD",
      name: "Berdiansk",
      area_code: "ERD",
      city_name: "Berdiansk",
      country_code: "UA"
  },
  {
      id: "ERE",
      name: "Erave",
      area_code: "ERE",
      city_name: "Erave",
      country_code: "PG"
  },
  {
      id: "ERG",
      name: "Yerbogachen Airport Airport",
      area_code: "ERG",
      city_name: "Yerbogachen",
      country_code: "RU"
  },
  {
      id: "ERQ",
      name: "Elrose Mine",
      area_code: "ERQ",
      city_name: "Elrose Mine",
      country_code: "AU"
  },
  {
      id: "ERR",
      name: "Errol",
      area_code: "ERR",
      city_name: "Errol",
      country_code: "US"
  },
  {
      id: "ERU",
      name: "Erume",
      area_code: "ERU",
      city_name: "Erume",
      country_code: "PG"
  },
  {
      id: "ESA",
      name: "Esa'ala",
      area_code: "ESA",
      city_name: "Esa'ala",
      country_code: "PG"
  },
  {
      id: "ESG",
      name: "Luis Maria Argana Intl",
      area_code: "ESG",
      city_name: "Mariscal Estigarribia",
      country_code: "PY"
  },
  {
      id: "ESI",
      name: "Espinosa",
      area_code: "ESI",
      city_name: "Espinosa",
      country_code: "BR"
  },
  {
      id: "ESO",
      name: "Ohkay Owingeh",
      area_code: "ESO",
      city_name: "Espanola",
      country_code: "US"
  },
  {
      id: "EST",
      name: "Municipal",
      area_code: "EST",
      city_name: "Estherville",
      country_code: "US"
  },
  {
      id: "ESW",
      name: "State",
      area_code: "ESW",
      city_name: "Easton",
      country_code: "US"
  },
  {
      id: "ETD",
      name: "Etadunna",
      area_code: "ETD",
      city_name: "Etadunna",
      country_code: "AU"
  },
  {
      id: "ETE",
      name: "Genda Wuha",
      area_code: "ETE",
      city_name: "Metema",
      country_code: "ET"
  },
  {
      id: "ETL",
      name: "Svetlaya",
      area_code: "ETL",
      city_name: "Svetlaya",
      country_code: "RU"
  },
  {
      id: "ETM",
      name: "Ramon International",
      area_code: "ETH",
      city_name: "Eilat",
      country_code: "IL"
  },
  {
      id: "EUA",
      name: "Kaufana",
      area_code: "EUA",
      city_name: "Eua",
      country_code: "TO"
  },
  {
      id: "EUC",
      name: "Eucla",
      area_code: "EUC",
      city_name: "Eucla",
      country_code: "AU"
  },
  {
      id: "EUE",
      name: "Eureka",
      area_code: "EUE",
      city_name: "Eureka",
      country_code: "US"
  },
  {
      id: "EUO",
      name: "Paratebueno",
      area_code: "EUO",
      city_name: "Paratebueno",
      country_code: "CO"
  },
  {
      id: "EUQ",
      name: "Evelio Javier",
      area_code: "EUQ",
      city_name: "Antique",
      country_code: "PH"
  },
  {
      id: "EVA",
      name: "Ben Bruce Mem. Airpark",
      area_code: "EVA",
      city_name: "Evadale",
      country_code: "US"
  },
  {
      id: "EVD",
      name: "Eva Downs",
      area_code: "EVD",
      city_name: "Eva Downs",
      country_code: "AU"
  },
  {
      id: "EVH",
      name: "Evans Head",
      area_code: "EVH",
      city_name: "Evans Head",
      country_code: "AU"
  },
  {
      id: "EWE",
      name: "Ewer",
      area_code: "EWE",
      city_name: "Ewer",
      country_code: "ID"
  },
  {
      id: "EWI",
      name: "Enarotali",
      area_code: "EWI",
      city_name: "Enarotali",
      country_code: "ID"
  },
  {
      id: "EWO",
      name: "Ewo",
      area_code: "EWO",
      city_name: "Ewo",
      country_code: "CG"
  },
  {
      id: "EYL",
      name: "Yelimane",
      area_code: "EYL",
      city_name: "Yelimane",
      country_code: "ML"
  },
  {
      id: "EYR",
      name: "Municipal",
      area_code: "EYR",
      city_name: "Yerington",
      country_code: "US"
  },
  {
      id: "EYS",
      name: "Eliye Springs",
      area_code: "EYS",
      city_name: "Eliye Springs",
      country_code: "KE"
  },
  {
      id: "FAA",
      name: "Badala",
      area_code: "FAA",
      city_name: "Faranah",
      country_code: "GN"
  },
  {
      id: "FAC",
      name: "FAAITE AIRPORT",
      area_code: "FAC",
      city_name: "Faaite",
      country_code: "PF"
  },
  {
      id: "FAF",
      name: "Felker AAF",
      area_code: "FAF",
      city_name: "Fort Eustis",
      country_code: "US"
  },
  {
      id: "FAG",
      name: "Fagurholsmyri",
      area_code: "FAG",
      city_name: "Fagurholsmyri",
      country_code: "IS"
  },
  {
      id: "FAH",
      name: "FARAH AIRPORT",
      area_code: "FAH",
      city_name: "Farah",
      country_code: "AF"
  },
  {
      id: "FAM",
      name: "Regional",
      area_code: "FAM",
      city_name: "Farmington",
      country_code: "US"
  },
  {
      id: "FAQ",
      name: "Freida River",
      area_code: "FAQ",
      city_name: "Freida River",
      country_code: "PG"
  },
  {
      id: "FAS",
      name: "Faskrudsfjordur",
      area_code: "FAS",
      city_name: "Faskrudsfjordur",
      country_code: "IS"
  },
  {
      id: "FAU",
      name: "Fahud",
      area_code: "FAU",
      city_name: "Fahud",
      country_code: "OM"
  },
  {
      id: "FAZ",
      name: "Fasa",
      area_code: "FAZ",
      city_name: "Fasa",
      country_code: "IR"
  },
  {
      id: "FBA",
      name: "Fonte Boa",
      area_code: "FBA",
      city_name: "Fonte Boa",
      country_code: "BR"
  },
  {
      id: "FBG",
      name: "Simmons AAF",
      area_code: "FBG",
      city_name: "Fort Bragg",
      country_code: "US"
  },
  {
      id: "FBL",
      name: "Municipal",
      area_code: "FBL",
      city_name: "Faribault",
      country_code: "US"
  },
  {
      id: "FBR",
      name: "Fort Bridger",
      area_code: "FBR",
      city_name: "Fort Bridger",
      country_code: "US"
  },
  {
      id: "FBS",
      name: "Sea Plane Base",
      area_code: "FRD",
      city_name: "Friday Harbor",
      country_code: "US"
  },
  {
      id: "FBY",
      name: "Municipal",
      area_code: "FBY",
      city_name: "Fairbury",
      country_code: "US"
  },
  {
      id: "FDR",
      name: "Regional",
      area_code: "FDR",
      city_name: "Frederick",
      country_code: "US"
  },
  {
      id: "FDU",
      name: "Bandundu",
      area_code: "FDU",
      city_name: "Bandundu",
      country_code: "CD"
  },
  {
      id: "FEA",
      name: "Fetlar",
      area_code: "FEA",
      city_name: "Fetlar",
      country_code: "GB"
  },
  {
      id: "FEB",
      name: "Sanfebagar",
      area_code: "FEB",
      city_name: "Sanfebagar",
      country_code: "NP"
  },
  {
      id: "FEC",
      name: "Feira De Santana Airport",
      area_code: "FEC",
      city_name: "Feira de Santana",
      country_code: "BR"
  },
  {
      id: "FEJ",
      name: "Feijo",
      area_code: "FEJ",
      city_name: "Feijo",
      country_code: "BR"
  },
  {
      id: "FEK",
      name: "Ferkessedougou",
      area_code: "FEK",
      city_name: "Ferkessedougou",
      country_code: "CI"
  },
  {
      id: "FEL",
      name: "Fuerstenfeldbruck",
      area_code: "FEL",
      city_name: "Fuerstenfeldbruck",
      country_code: "DE"
  },
  {
      id: "FET",
      name: "Municipal",
      area_code: "FET",
      city_name: "Fremont",
      country_code: "US"
  },
  {
      id: "FFA",
      name: "First Flight",
      area_code: "FFA",
      city_name: "Kill Devil Hills",
      country_code: "US"
  },
  {
      id: "FFD",
      name: "RAF Station",
      area_code: "FFD",
      city_name: "Fairford",
      country_code: "GB"
  },
  {
      id: "FFL",
      name: "Municipal",
      area_code: "FFL",
      city_name: "Fairfield",
      country_code: "US"
  },
  {
      id: "FFT",
      name: "Capital City",
      area_code: "FFT",
      city_name: "Frankfort",
      country_code: "US"
  },
  {
      id: "FFU",
      name: "Futaleufu",
      area_code: "FFU",
      city_name: "Futaleufu",
      country_code: "CL"
  },
  {
      id: "FGD",
      name: "Fderik",
      area_code: "FGD",
      city_name: "Fderik",
      country_code: "MR"
  },
  {
      id: "FGU",
      name: "FANGATAU AIRPORT",
      area_code: "FGU",
      city_name: "Fangatau",
      country_code: "PF"
  },
  {
      id: "FHZ",
      name: "FAKAHINA AIRPORT",
      area_code: "FHZ",
      city_name: "Fakahina",
      country_code: "PF"
  },
  {
      id: "FID",
      name: "Elizabeth Field",
      area_code: "FID",
      city_name: "Fishers Island",
      country_code: "US"
  },
  {
      id: "FIG",
      name: "Katourou",
      area_code: "FIG",
      city_name: "Fria",
      country_code: "GN"
  },
  {
      id: "FIK",
      name: "Finke",
      area_code: "FIK",
      city_name: "Finke",
      country_code: "AU"
  },
  {
      id: "FIL",
      name: "Municipal",
      area_code: "FIL",
      city_name: "Fillmore",
      country_code: "US"
  },
  {
      id: "FIN",
      name: "Finschhafen",
      area_code: "FIN",
      city_name: "Finschhafen",
      country_code: "PG"
  },
  {
      id: "FIZ",
      name: "Fitzroy Crossing Airport",
      area_code: "FIZ",
      city_name: "Fitzroy Crossing",
      country_code: "AU"
  },
  {
      id: "FKJ",
      name: "Fukui",
      area_code: "FKJ",
      city_name: "Fukui",
      country_code: "JP"
  },
  {
      id: "FKN",
      name: "Municipal-John B. Rose",
      area_code: "FKN",
      city_name: "Franklin",
      country_code: "US"
  },
  {
      id: "FLH",
      name: "Flotta",
      area_code: "FLH",
      city_name: "Flotta",
      country_code: "GB"
  },
  {
      id: "FLI",
      name: "Holt",
      area_code: "FLI",
      city_name: "Flateyri",
      country_code: "IS"
  },
  {
      id: "FLM",
      name: "Filadelfia",
      area_code: "FLM",
      city_name: "Filadelfia",
      country_code: "PY"
  },
  {
      id: "FLT",
      name: "Flat",
      area_code: "FLT",
      city_name: "Flat",
      country_code: "US"
  },
  {
      id: "FLV",
      name: "Sherman AAF",
      area_code: "FLV",
      city_name: "Fort Leavenworth",
      country_code: "US"
  },
  {
      id: "FLY",
      name: "Finley",
      area_code: "FLY",
      city_name: "Finley",
      country_code: "AU"
  },
  {
      id: "FME",
      name: "Tipton",
      area_code: "FME",
      city_name: "Fort Meade/Odenton",
      country_code: "US"
  },
  {
      id: "FMI",
      name: "KALEMIE AIRPORT",
      area_code: "FMI",
      city_name: "Kalemie",
      country_code: "CD"
  },
  {
      id: "FMM",
      name: "Memmingen Airport",
      area_code: "FMM",
      city_name: "Memmingen",
      country_code: "DE"
  },
  {
      id: "FMU",
      name: "Municipal",
      area_code: "FMU",
      city_name: "Florence",
      country_code: "US"
  },
  {
      id: "FNG",
      name: "Fada N'Gourma",
      area_code: "FNG",
      city_name: "Fada N'Gourma",
      country_code: "BF"
  },
  {
      id: "FNH",
      name: "Fincha'a",
      area_code: "FNH",
      city_name: "Fincha'a",
      country_code: "ET"
  },
  {
      id: "FNR",
      name: "Sea Plane Base",
      area_code: "FNR",
      city_name: "Funter Bay",
      country_code: "US"
  },
  {
      id: "FNU",
      name: "Fenosu",
      area_code: "FNU",
      city_name: "Oristano",
      country_code: "IT"
  },
  {
      id: "FOA",
      name: "Foula",
      area_code: "FOA",
      city_name: "Foula",
      country_code: "GB"
  },
  {
      id: "FOB",
      name: "Fort Bragg",
      area_code: "FOB",
      city_name: "Fort Bragg",
      country_code: "US"
  },
  {
      id: "FOM",
      name: "Nkounja",
      area_code: "FOM",
      city_name: "Foumban",
      country_code: "CM"
  },
  {
      id: "FOO",
      name: "Numfoor",
      area_code: "FOO",
      city_name: "Numfoor",
      country_code: "ID"
  },
  {
      id: "FOU",
      name: "Fougamou",
      area_code: "FOU",
      city_name: "Fougamou",
      country_code: "GA"
  },
  {
      id: "FOY",
      name: "Foya",
      area_code: "FOY",
      city_name: "Foya",
      country_code: "LR"
  },
  {
      id: "FPY",
      name: "Perry-Foley",
      area_code: "FPY",
      city_name: "Perry",
      country_code: "US"
  },
  {
      id: "FRE",
      name: "Fera Island Airport",
      area_code: "FRE",
      city_name: "Fera Island",
      country_code: "SB"
  },
  {
      id: "FRI",
      name: "Marshall AAF",
      area_code: "FRI",
      city_name: "Fort Riley",
      country_code: "US"
  },
  {
      id: "FRN",
      name: "Bryant AAF",
      area_code: "ANC",
      city_name: "Anchorage",
      country_code: "US"
  },
  {
      id: "FRQ",
      name: "Feramin",
      area_code: "FRQ",
      city_name: "Feramin",
      country_code: "PG"
  },
  {
      id: "FRT",
      name: "Frutillar",
      area_code: "FRT",
      city_name: "Frutillar",
      country_code: "CL"
  },
  {
      id: "FRY",
      name: "Eastern Slopes Rgnl",
      area_code: "FRY",
      city_name: "Fryeburg",
      country_code: "US"
  },
  {
      id: "FSI",
      name: "Henry Post AAF",
      area_code: "LAW",
      city_name: "Lawton/Fort Sill",
      country_code: "US"
  },
  {
      id: "FSK",
      name: "Municipal",
      area_code: "FSK",
      city_name: "Fort Scott",
      country_code: "US"
  },
  {
      id: "FSL",
      name: "Fossil Downs Station",
      area_code: "FSL",
      city_name: "Fossil Downs Station",
      country_code: "AU"
  },
  {
      id: "FSS",
      name: "Kinloss RAF",
      area_code: "FSS",
      city_name: "Forres",
      country_code: "GB"
  },
  {
      id: "FSU",
      name: "Municipal",
      area_code: "FSU",
      city_name: "Fort Sumner",
      country_code: "US"
  },
  {
      id: "FTA",
      name: "Futuna Airport",
      area_code: "FTA",
      city_name: "Futuna Island",
      country_code: "VU"
  },
  {
      id: "FTI",
      name: "Fitiuta",
      area_code: "FTI",
      city_name: "Fitiuta",
      country_code: "AS"
  },
  {
      id: "FTK",
      name: "Godman AAF",
      area_code: "FTK",
      city_name: "Fort Knox",
      country_code: "US"
  },
  {
      id: "FTX",
      name: "Owando",
      area_code: "FTX",
      city_name: "Owando",
      country_code: "CG"
  },
  {
      id: "FUB",
      name: "Fulleborn",
      area_code: "FUB",
      city_name: "Fulleborn",
      country_code: "PG"
  },
  {
      id: "FUM",
      name: "Fuma",
      area_code: "FUM",
      city_name: "Fuma",
      country_code: "PG"
  },
  {
      id: "FVL",
      name: "Flora Valley",
      area_code: "FVL",
      city_name: "Flora Valley",
      country_code: "AU"
  },
  {
      id: "FVR",
      name: "Forrest River Mission",
      area_code: "FVR",
      city_name: "Forrest River Mission",
      country_code: "AU"
  },
  {
      id: "FWL",
      name: "Farewell",
      area_code: "FWL",
      city_name: "Farewell",
      country_code: "US"
  },
  {
      id: "FXE",
      name: "Executive Airport",
      area_code: "FLL",
      city_name: "Fort Lauderdale",
      country_code: "US"
  },
  {
      id: "FXO",
      name: "Cuamba",
      area_code: "FXO",
      city_name: "Cuamba",
      country_code: "MZ"
  },
  {
      id: "FXY",
      name: "Municipal",
      area_code: "FXY",
      city_name: "Forest City",
      country_code: "US"
  },
  {
      id: "FYJ",
      name: "Dongji Airport",
      area_code: "FYJ",
      city_name: "Fuyuan",
      country_code: "CN"
  },
  {
      id: "FYM",
      name: "Municipal",
      area_code: "FYM",
      city_name: "Fayetteville",
      country_code: "US"
  },
  {
      id: "FYT",
      name: "Faya-Largeau",
      area_code: "FYT",
      city_name: "Faya-Largeau",
      country_code: "TD"
  },
  {
      id: "FZO",
      name: "Filton",
      area_code: "FZO",
      city_name: "Filton",
      country_code: "GB"
  },
  {
      id: "GAA",
      name: "Guamal",
      area_code: "GAA",
      city_name: "Guamal",
      country_code: "CO"
  },
  {
      id: "GAB",
      name: "Gabbs",
      area_code: "GAB",
      city_name: "Gabbs",
      country_code: "US"
  },
  {
      id: "GAC",
      name: "Gracias",
      area_code: "GAC",
      city_name: "Gracias",
      country_code: "HN"
  },
  {
      id: "GAE",
      name: "Matmata Airport",
      area_code: "GAE",
      city_name: "Gabes",
      country_code: "TN"
  },
  {
      id: "GAF",
      name: "Ksar",
      area_code: "GAF",
      city_name: "Gafsa",
      country_code: "TN"
  },
  {
      id: "GAG",
      name: "Gage",
      area_code: "GAG",
      city_name: "Gage",
      country_code: "US"
  },
  {
      id: "GAH",
      name: "Gayndah",
      area_code: "GAH",
      city_name: "Gayndah",
      country_code: "AU"
  },
  {
      id: "GAM",
      name: "GAMBELL AIRPORT",
      area_code: "GAM",
      city_name: "Gambell",
      country_code: "US"
  },
  {
      id: "GAP",
      name: "Gusap",
      area_code: "GAP",
      city_name: "Gusap",
      country_code: "PG"
  },
  {
      id: "GAS",
      name: "Garissa",
      area_code: "GAS",
      city_name: "Garissa",
      country_code: "KE"
  },
  {
      id: "GAV",
      name: "Gag Island",
      area_code: "GAV",
      city_name: "Gag Island",
      country_code: "ID"
  },
  {
      id: "GAW",
      name: "Gangaw",
      area_code: "GAW",
      city_name: "Gangaw",
      country_code: "MM"
  },
  {
      id: "GAX",
      name: "Yenzi",
      area_code: "GAX",
      city_name: "Gamba",
      country_code: "GA"
  },
  {
      id: "GAZ",
      name: "Guasopa",
      area_code: "GAZ",
      city_name: "Guasopa",
      country_code: "PG"
  },
  {
      id: "GBA",
      name: "Cotswold",
      area_code: "GBA",
      city_name: "Kemble",
      country_code: "GB"
  },
  {
      id: "GBB",
      name: "Gara-Djebilet Airport",
      area_code: "GBB",
      city_name: "Gabala",
      country_code: "AZ"
  },
  {
      id: "GBC",
      name: "Gasuke",
      area_code: "GBC",
      city_name: "Gasuke",
      country_code: "PG"
  },
  {
      id: "GBF",
      name: "Negarbo",
      area_code: "GBF",
      city_name: "Negarbo",
      country_code: "PG"
  },
  {
      id: "GBH",
      name: "Galbraith Lake",
      area_code: "GBH",
      city_name: "Galbraith Lake",
      country_code: "US"
  },
  {
      id: "GBJ",
      name: "Marie-Galante",
      area_code: "GBJ",
      city_name: "Marie-Galante",
      country_code: "GP"
  },
  {
      id: "GBL",
      name: "South Goulburn Island",
      area_code: "GBL",
      city_name: "South Goulburn Island",
      country_code: "AU"
  },
  {
      id: "GBM",
      name: "Garbaharey",
      area_code: "GBM",
      city_name: "Garbaharey",
      country_code: "SO"
  },
  {
      id: "GBP",
      name: "Gamboola",
      area_code: "GBP",
      city_name: "Gamboola",
      country_code: "AU"
  },
  {
      id: "GBT",
      name: "Gorgan Airport",
      area_code: "GBT",
      city_name: "Gorgan",
      country_code: "IR"
  },
  {
      id: "GBU",
      name: "Khashm el Girba",
      area_code: "GBU",
      city_name: "Khashm el Girba",
      country_code: "SD"
  },
  {
      id: "GBV",
      name: "Gibb River",
      area_code: "GBV",
      city_name: "Gibb River",
      country_code: "AU"
  },
  {
      id: "GBW",
      name: "Ginbata",
      area_code: "GBW",
      city_name: "Ginbata",
      country_code: "AU"
  },
  {
      id: "GCA",
      name: "Guacamayas",
      area_code: "GCA",
      city_name: "Guacamayas",
      country_code: "CO"
  },
  {
      id: "GCD",
      name: "Grand Coulee Dam",
      area_code: "GCD",
      city_name: "Electric City",
      country_code: "US"
  },
  {
      id: "GCH",
      name: "Gachsaran Airport",
      area_code: "GCH",
      city_name: "Gachsaran",
      country_code: "IR"
  },
  {
      id: "GCT",
      name: "Bar Ten Ranch Airport",
      area_code: "GCN",
      city_name: "Grand Canyon",
      country_code: "US"
  },
  {
      id: "GCW",
      name: "Grand Canyon West",
      area_code: "GCW",
      city_name: "Grand Canyon West",
      country_code: "US"
  },
  {
      id: "GDA",
      name: "Gounda",
      area_code: "GDA",
      city_name: "Gounda",
      country_code: "CF"
  },
  {
      id: "GDD",
      name: "Gordon Downs",
      area_code: "GDD",
      city_name: "Gordon Downs",
      country_code: "AU"
  },
  {
      id: "GDG",
      name: "Magdagachi",
      area_code: "GDG",
      city_name: "Magdagachi",
      country_code: "RU"
  },
  {
      id: "GDH",
      name: "Golden Horn Lodge SPB",
      area_code: "GDH",
      city_name: "Golden Horn",
      country_code: "US"
  },
  {
      id: "GDI",
      name: "Gordil",
      area_code: "GDI",
      city_name: "Melle",
      country_code: "CF"
  },
  {
      id: "GDJ",
      name: "Gandajika",
      area_code: "GDJ",
      city_name: "Gandajika",
      country_code: "CD"
  },
  {
      id: "GDM",
      name: "Municipal",
      area_code: "GDM",
      city_name: "Gardner",
      country_code: "US"
  },
  {
      id: "GDO",
      name: "Guasdualito",
      area_code: "GDO",
      city_name: "Guasdualito",
      country_code: "VE"
  },
  {
      id: "GDP",
      name: "Guadalupe",
      area_code: "GDP",
      city_name: "Guadalupe",
      country_code: "BR"
  },
  {
      id: "GDW",
      name: "Zettel Memorial",
      area_code: "GDW",
      city_name: "Gladwin",
      country_code: "US"
  },
  {
      id: "GEB",
      name: "Gebe",
      area_code: "GEB",
      city_name: "Gebe",
      country_code: "ID"
  },
  {
      id: "GEC",
      name: "Gecitkale",
      area_code: "GEC",
      city_name: "Gecitkale",
      country_code: "CY"
  },
  {
      id: "GEE",
      name: "George Town",
      area_code: "GEE",
      city_name: "George Town",
      country_code: "AU"
  },
  {
      id: "GEF",
      name: "Geva",
      area_code: "GEF",
      city_name: "Geva",
      country_code: "SB"
  },
  {
      id: "GEL",
      name: "Sepe Tiaraju Airport",
      area_code: "GEL",
      city_name: "Santo Angelo",
      country_code: "BR"
  },
  {
      id: "GEM",
      name: "Mongomeyen Airport",
      area_code: "GEM",
      city_name: "Mongomo",
      country_code: "GQ"
  },
  {
      id: "GEW",
      name: "Gewoia",
      area_code: "GEW",
      city_name: "Gewoia",
      country_code: "PG"
  },
  {
      id: "GFD",
      name: "Pope Field",
      area_code: "GFD",
      city_name: "Greenfield",
      country_code: "US"
  },
  {
      id: "GFO",
      name: "Bartica",
      area_code: "GFO",
      city_name: "Bartica",
      country_code: "GY"
  },
  {
      id: "GFR",
      name: "Granville",
      area_code: "GFR",
      city_name: "Granville",
      country_code: "FR"
  },
  {
      id: "GFY",
      name: "Grootfontein",
      area_code: "GFY",
      city_name: "Grootfontein",
      country_code: "NA"
  },
  {
      id: "GGC",
      name: "Lumbala N'guimbo",
      area_code: "GGC",
      city_name: "Lumbala N'guimbo",
      country_code: "AO"
  },
  {
      id: "GGD",
      name: "Gregory Downs",
      area_code: "GGD",
      city_name: "Gregory Downs",
      country_code: "AU"
  },
  {
      id: "GGL",
      name: "Gilgal",
      area_code: "GGL",
      city_name: "Gilgal",
      country_code: "CO"
  },
  {
      id: "GGN",
      name: "Gagnoa",
      area_code: "GGN",
      city_name: "Gagnoa",
      country_code: "CI"
  },
  {
      id: "GGO",
      name: "Guiglo",
      area_code: "GGO",
      city_name: "Guiglo",
      country_code: "CI"
  },
  {
      id: "GGR",
      name: "Garowe",
      area_code: "GGR",
      city_name: "Garowe",
      country_code: "SO"
  },
  {
      id: "GGS",
      name: "Gobernador Gregores",
      area_code: "GGS",
      city_name: "Gobernador Gregores",
      country_code: "AR"
  },
  {
      id: "GHC",
      name: "Great Harbour Cay",
      area_code: "GHC",
      city_name: "Great Harbour Cay",
      country_code: "BS"
  },
  {
      id: "GHE",
      name: "Garachine",
      area_code: "GHE",
      city_name: "Garachine",
      country_code: "PA"
  },
  {
      id: "GHF",
      name: "Giebelstadt",
      area_code: "GHF",
      city_name: "Giebelstadt",
      country_code: "DE"
  },
  {
      id: "GHM",
      name: "Municipal",
      area_code: "GHM",
      city_name: "Centerville",
      country_code: "US"
  },
  {
      id: "GHS",
      name: "Melalan",
      area_code: "GHS",
      city_name: "West Kutai",
      country_code: "ID"
  },
  {
      id: "GHT",
      name: "Ghat",
      area_code: "GHT",
      city_name: "Ghat",
      country_code: "LY"
  },
  {
      id: "GHU",
      name: "Gualeguaychu",
      area_code: "GHU",
      city_name: "Gualeguaychu",
      country_code: "AR"
  },
  {
      id: "GID",
      name: "Gitega",
      area_code: "GID",
      city_name: "Gitega",
      country_code: "BI"
  },
  {
      id: "GII",
      name: "Siguiri",
      area_code: "GII",
      city_name: "Siguiri",
      country_code: "GN"
  },
  {
      id: "GIM",
      name: "Miele Mimbale",
      area_code: "GIM",
      city_name: "Miele Mimbale",
      country_code: "GA"
  },
  {
      id: "GIT",
      name: "Geita",
      area_code: "GIT",
      city_name: "Geita",
      country_code: "TZ"
  },
  {
      id: "GIU",
      name: "Sigiriya Airport",
      area_code: "GIU",
      city_name: "Sigiriya",
      country_code: "LK"
  },
  {
      id: "GIY",
      name: "Giyani",
      area_code: "GIY",
      city_name: "Giyani",
      country_code: "ZA"
  },
  {
      id: "GJA",
      name: "Guanaja",
      area_code: "GJA",
      city_name: "Guanaja",
      country_code: "HN"
  },
  {
      id: "GJL",
      name: "Ferhat Abbas Airport",
      area_code: "GJL",
      city_name: "Jijel",
      country_code: "DZ"
  },
  {
      id: "GJM",
      name: "Guajara-Mirim",
      area_code: "GJM",
      city_name: "Guajara-Mirim",
      country_code: "BR"
  },
  {
      id: "GJR",
      name: "Gjogur",
      area_code: "GJR",
      city_name: "Gjogur",
      country_code: "IS"
  },
  {
      id: "GKD",
      name: "Gokceada",
      area_code: "GKD",
      city_name: "Gokceada",
      country_code: "TR"
  },
  {
      id: "GKE",
      name: "Air Base",
      area_code: "GKE",
      city_name: "Geilenkirchen",
      country_code: "DE"
  },
  {
      id: "GKH",
      name: "Palungtar",
      area_code: "GKH",
      city_name: "Gorkha",
      country_code: "NP"
  },
  {
      id: "GKL",
      name: "Great Keppel Island",
      area_code: "GKL",
      city_name: "Great Keppel Island",
      country_code: "AU"
  },
  {
      id: "GKO",
      name: "Kongo Boumba",
      area_code: "GKO",
      city_name: "Kongo Boumba",
      country_code: "GA"
  },
  {
      id: "GLC",
      name: "Geladi",
      area_code: "GLC",
      city_name: "Geladi",
      country_code: "ET"
  },
  {
      id: "GLF",
      name: "GOLFITO AIRPORT",
      area_code: "GLF",
      city_name: "Golfito",
      country_code: "CR"
  },
  {
      id: "GLG",
      name: "Glengyle",
      area_code: "GLG",
      city_name: "Glengyle",
      country_code: "AU"
  },
  {
      id: "GLK",
      name: "GALCAIO AIRPORT",
      area_code: "GLK",
      city_name: "Galcaio",
      country_code: "SO"
  },
  {
      id: "GLM",
      name: "Glenormiston",
      area_code: "GLM",
      city_name: "Glenormiston",
      country_code: "AU"
  },
  {
      id: "GLN",
      name: "Goulimime Airport",
      area_code: "GLN",
      city_name: "Guelmime",
      country_code: "MA"
  },
  {
      id: "GLP",
      name: "Gulgubip",
      area_code: "GLP",
      city_name: "Gulgubip",
      country_code: "PG"
  },
  {
      id: "GLV",
      name: "GOLOVIN AIRPORT",
      area_code: "GLV",
      city_name: "Golovin",
      country_code: "US"
  },
  {
      id: "GLX",
      name: "Galela Airport",
      area_code: "GLX",
      city_name: "Galela",
      country_code: "ID"
  },
  {
      id: "GLY",
      name: "Mount Goldsworthy",
      area_code: "GLY",
      city_name: "Mount Goldsworthy",
      country_code: "AU"
  },
  {
      id: "GLZ",
      name: "Gilze-Rijen AB",
      area_code: "GLZ",
      city_name: "Breda",
      country_code: "NL"
  },
  {
      id: "GMA",
      name: "GEMENA AIRPORT",
      area_code: "GMA",
      city_name: "Gemena",
      country_code: "CD"
  },
  {
      id: "GMC",
      name: "Guerima",
      area_code: "GMC",
      city_name: "Guerima",
      country_code: "CO"
  },
  {
      id: "GMD",
      name: "Ben Slimane",
      area_code: "GMD",
      city_name: "Ben Slimane",
      country_code: "MA"
  },
  {
      id: "GMI",
      name: "Gasmata",
      area_code: "GMI",
      city_name: "Gasmata",
      country_code: "PG"
  },
  {
      id: "GMM",
      name: "Gamboma",
      area_code: "GMM",
      city_name: "Gamboma",
      country_code: "CG"
  },
  {
      id: "GMN",
      name: "Greymouth",
      area_code: "GMN",
      city_name: "Greymouth",
      country_code: "NZ"
  },
  {
      id: "GMQ",
      name: "Maqin Airport",
      area_code: "GMQ",
      city_name: "Golog",
      country_code: "CN"
  },
  {
      id: "GMS",
      name: "Antonio Guerreiro",
      area_code: "GMS",
      city_name: "Guimaraes",
      country_code: "BR"
  },
  {
      id: "GMT",
      name: "Air Station",
      area_code: "GMT",
      city_name: "Granite Mountain",
      country_code: "US"
  },
  {
      id: "GMV",
      name: "Monument Valley",
      area_code: "GMV",
      city_name: "Monument Valley",
      country_code: "US"
  },
  {
      id: "GNG",
      name: "Municipal",
      area_code: "GNG",
      city_name: "Gooding",
      country_code: "US"
  },
  {
      id: "GNI",
      name: "Lyudao (Green Island)",
      area_code: "GNI",
      city_name: "Lyudao (Green Island)",
      country_code: "CN"
  },
  {
      id: "GNM",
      name: "Guanambi",
      area_code: "GNM",
      city_name: "Guanambi",
      country_code: "BR"
  },
  {
      id: "GNN",
      name: "Ginir",
      area_code: "GNN",
      city_name: "Ginir",
      country_code: "ET"
  },
  {
      id: "GNU",
      name: "GOODNEWS BAY AIRPORT",
      area_code: "GNU",
      city_name: "Goodnews Bay",
      country_code: "US"
  },
  {
      id: "GNZ",
      name: "Ghanzi",
      area_code: "GNZ",
      city_name: "Ghanzi",
      country_code: "BW"
  },
  {
      id: "GOB",
      name: "Robe",
      area_code: "GOB",
      city_name: "Goba",
      country_code: "ET"
  },
  {
      id: "GOC",
      name: "Gora",
      area_code: "GOC",
      city_name: "Gora",
      country_code: "PG"
  },
  {
      id: "GOE",
      name: "Gonaili",
      area_code: "GOE",
      city_name: "Gonaili",
      country_code: "PG"
  },
  {
      id: "GOG",
      name: "Gobabis",
      area_code: "GOG",
      city_name: "Gobabis",
      country_code: "NA"
  },
  {
      id: "GOK",
      name: "Guthrie-Edmond Rgnl",
      area_code: "GOK",
      city_name: "Guthrie",
      country_code: "US"
  },
  {
      id: "GOR",
      name: "Gore",
      area_code: "GOR",
      city_name: "Gore",
      country_code: "ET"
  },
  {
      id: "GPB",
      name: "T.Thomas de Faria",
      area_code: "GPB",
      city_name: "Guarapuava",
      country_code: "BR"
  },
  {
      id: "GPD",
      name: "Mount Gordon Mine",
      area_code: "GPD",
      city_name: "Mount Gordon Mine",
      country_code: "AU"
  },
  {
      id: "GPL",
      name: "Guapiles",
      area_code: "GPL",
      city_name: "Guapiles",
      country_code: "CR"
  },
  {
      id: "GPN",
      name: "Garden Point",
      area_code: "GPN",
      city_name: "Garden Point",
      country_code: "AU"
  },
  {
      id: "GPO",
      name: "General Pico",
      area_code: "GPO",
      city_name: "General Pico",
      country_code: "AR"
  },
  {
      id: "GRA",
      name: "Gamarra",
      area_code: "GRA",
      city_name: "Gamarra",
      country_code: "CO"
  },
  {
      id: "GRC",
      name: "Grand Cess",
      area_code: "GRC",
      city_name: "Grand Cess",
      country_code: "LR"
  },
  {
      id: "GRD",
      name: "Greenwood County",
      area_code: "GRD",
      city_name: "Greenwood",
      country_code: "US"
  },
  {
      id: "GRE",
      name: "Greenville",
      area_code: "GRE",
      city_name: "Greenville",
      country_code: "US"
  },
  {
      id: "GRG",
      name: "Gardez",
      area_code: "GRG",
      city_name: "Gardez",
      country_code: "AF"
  },
  {
      id: "GRH",
      name: "Garuahi",
      area_code: "GRH",
      city_name: "Garuahi",
      country_code: "PG"
  },
  {
      id: "GRL",
      name: "Garasa",
      area_code: "GRL",
      city_name: "Garasa",
      country_code: "PG"
  },
  {
      id: "GRM",
      name: "Cook County",
      area_code: "GRM",
      city_name: "Grand Marais",
      country_code: "US"
  },
  {
      id: "GRN",
      name: "Municipal",
      area_code: "GRN",
      city_name: "Gordon",
      country_code: "US"
  },
  {
      id: "GRP",
      name: "Gurupi",
      area_code: "GRP",
      city_name: "Gurupi",
      country_code: "BR"
  },
  {
      id: "GRT",
      name: "Gujrat",
      area_code: "GRT",
      city_name: "Gujrat",
      country_code: "PK"
  },
  {
      id: "GRY",
      name: "Grimsey",
      area_code: "GRY",
      city_name: "Grimsey",
      country_code: "IS"
  },
  {
      id: "GSA",
      name: "Long Pasia",
      area_code: "GSA",
      city_name: "Long Pasia",
      country_code: "MY"
  },
  {
      id: "GSC",
      name: "Gascoyne Junction",
      area_code: "GSC",
      city_name: "Gascoyne Junction",
      country_code: "AU"
  },
  {
      id: "GSH",
      name: "Municipal",
      area_code: "GSH",
      city_name: "Goshen",
      country_code: "US"
  },
  {
      id: "GSI",
      name: "Grand-Santi",
      area_code: "GSI",
      city_name: "Grand-Santi",
      country_code: "GF"
  },
  {
      id: "GSJ",
      name: "Puerto San Jose",
      area_code: "GSJ",
      city_name: "Puerto San Jose",
      country_code: "GT"
  },
  {
      id: "GSL",
      name: "Taltheilei Narrows",
      area_code: "GSL",
      city_name: "Taltheilei Narrows",
      country_code: "CA"
  },
  {
      id: "GSM",
      name: "Gheshm Airport",
      area_code: "GSM",
      city_name: "Gheshm Island",
      country_code: "IR"
  },
  {
      id: "GSN",
      name: "Mount Gunson",
      area_code: "GSN",
      city_name: "Mount Gunson",
      country_code: "AU"
  },
  {
      id: "GSQ",
      name: "International",
      area_code: "GSQ",
      city_name: "Shark el-Owainat",
      country_code: "EG"
  },
  {
      id: "GSR",
      name: "Gardo",
      area_code: "GSR",
      city_name: "Gardo",
      country_code: "SO"
  },
  {
      id: "GSS",
      name: "Sabi Sabi",
      area_code: "GSS",
      city_name: "Sabie",
      country_code: "ZA"
  },
  {
      id: "GSU",
      name: "Gedaref",
      area_code: "GSU",
      city_name: "Gedaref",
      country_code: "SD"
  },
  {
      id: "GTG",
      name: "Municipal",
      area_code: "GTG",
      city_name: "Grantsburg",
      country_code: "US"
  },
  {
      id: "GTP",
      name: "Grants Pass",
      area_code: "GTP",
      city_name: "Grants Pass",
      country_code: "US"
  },
  {
      id: "GTS",
      name: "The Granites Airport",
      area_code: "GTS",
      city_name: "The Granites",
      country_code: "AU"
  },
  {
      id: "GTT",
      name: "Georgetown",
      area_code: "GTT",
      city_name: "Georgetown",
      country_code: "AU"
  },
  {
      id: "GTZ",
      name: "Kirawira airport",
      area_code: "GTZ",
      city_name: "Grumeti",
      country_code: "TZ"
  },
  {
      id: "GUB",
      name: "Guerrero Negro",
      area_code: "GUB",
      city_name: "Guerrero Negro",
      country_code: "MX"
  },
  {
      id: "GUD",
      name: "Goundam",
      area_code: "GUD",
      city_name: "Goundam",
      country_code: "ML"
  },
  {
      id: "GUE",
      name: "Guriaso",
      area_code: "GUE",
      city_name: "Guriaso",
      country_code: "PG"
  },
  {
      id: "GUG",
      name: "Guari",
      area_code: "GUG",
      city_name: "Guari",
      country_code: "PG"
  },
  {
      id: "GUI",
      name: "Guiria",
      area_code: "GUI",
      city_name: "Guiria",
      country_code: "VE"
  },
  {
      id: "GUJ",
      name: "Guaratingueta",
      area_code: "GUJ",
      city_name: "Guaratingueta",
      country_code: "BR"
  },
  {
      id: "GUL",
      name: "Goulburn",
      area_code: "GUL",
      city_name: "Goulburn",
      country_code: "AU"
  },
  {
      id: "GUO",
      name: "Guriel Airport",
      area_code: "GUO",
      city_name: "Guriel",
      country_code: "SO"
  },
  {
      id: "GUQ",
      name: "Guanare",
      area_code: "GUQ",
      city_name: "Guanare",
      country_code: "VE"
  },
  {
      id: "GUS",
      name: "Grissom ARB",
      area_code: "GUS",
      city_name: "Peru",
      country_code: "US"
  },
  {
      id: "GUU",
      name: "Grundarfjordur",
      area_code: "GUU",
      city_name: "Grundarfjordur",
      country_code: "IS"
  },
  {
      id: "GUV",
      name: "Mougulu",
      area_code: "GUV",
      city_name: "Mougulu",
      country_code: "PG"
  },
  {
      id: "GUX",
      name: "Guna",
      area_code: "GUX",
      city_name: "Guna",
      country_code: "IN"
  },
  {
      id: "GUZ",
      name: "Guarapari",
      area_code: "GUZ",
      city_name: "Guarapari",
      country_code: "BR"
  },
  {
      id: "GVE",
      name: "Municipal",
      area_code: "GVE",
      city_name: "Gordonsville",
      country_code: "US"
  },
  {
      id: "GVI",
      name: "Green River",
      area_code: "GVI",
      city_name: "Green River",
      country_code: "PG"
  },
  {
      id: "GVN",
      name: "May-Gatka",
      area_code: "GVN",
      city_name: "Sovetskaya Gavan",
      country_code: "RU"
  },
  {
      id: "GVP",
      name: "Greenvale",
      area_code: "GVP",
      city_name: "Greenvale",
      country_code: "AU"
  },
  {
      id: "GVT",
      name: "Majors",
      area_code: "GVT",
      city_name: "Greenville",
      country_code: "US"
  },
  {
      id: "GWA",
      name: "Gwa",
      area_code: "GWA",
      city_name: "Gwa",
      country_code: "MM"
  },
  {
      id: "GWN",
      name: "Gnarowein",
      area_code: "GWN",
      city_name: "Gnarowein",
      country_code: "PG"
  },
  {
      id: "GWV",
      name: "Fokker Field",
      area_code: "GWV",
      city_name: "Glendale",
      country_code: "US"
  },
  {
      id: "GXA",
      name: "Beringin",
      area_code: "GXA",
      city_name: "Muara Teweh",
      country_code: "ID"
  },
  {
      id: "GXF",
      name: "Seiyun Airport",
      area_code: "GXF",
      city_name: "Sayun",
      country_code: "YE"
  },
  {
      id: "GXG",
      name: "Negage",
      area_code: "GXG",
      city_name: "Negage",
      country_code: "AO"
  },
  {
      id: "GXX",
      name: "Yagoua",
      area_code: "GXX",
      city_name: "Yagoua",
      country_code: "CM"
  },
  {
      id: "GYG",
      name: "Magan Airport",
      area_code: "GYG",
      city_name: "Magan",
      country_code: "RU"
  },
  {
      id: "GYL",
      name: "ARGYLE AIRPORT",
      area_code: "GYL",
      city_name: "Argyle",
      country_code: "AU"
  },
  {
      id: "GZI",
      name: "Ghazni",
      area_code: "GZI",
      city_name: "Ghazni",
      country_code: "AF"
  },
  {
      id: "GZW",
      name: "Ghazvin",
      area_code: "GZW",
      city_name: "Ghazvin",
      country_code: "IR"
  },
  {
      id: "HAI",
      name: "Municipal Dr. Haines",
      area_code: "HAI",
      city_name: "Three Rivers",
      country_code: "US"
  },
  {
      id: "HAL",
      name: "Halali",
      area_code: "HAL",
      city_name: "Halali",
      country_code: "NA"
  },
  {
      id: "HAO",
      name: "Butler County Regional",
      area_code: "HAO",
      city_name: "Hamilton",
      country_code: "US"
  },
  {
      id: "HAT",
      name: "Heathlands",
      area_code: "HAT",
      city_name: "Heathlands",
      country_code: "AU"
  },
  {
      id: "HAZ",
      name: "Hatzfeldthaven",
      area_code: "HAZ",
      city_name: "Hatzfeldthaven",
      country_code: "PG"
  },
  {
      id: "HBD",
      name: "Habi",
      area_code: "HBD",
      city_name: "Habi",
      country_code: "PG"
  },
  {
      id: "HBK",
      name: "Municipal",
      area_code: "HBK",
      city_name: "Holbrook",
      country_code: "US"
  },
  {
      id: "HBQ",
      name: "Haibei Airport",
      area_code: "HBQ",
      city_name: "Qilian",
      country_code: "CN"
  },
  {
      id: "HBR",
      name: "Regional",
      area_code: "HBR",
      city_name: "Hobart",
      country_code: "US"
  },
  {
      id: "HBT",
      name: "SPB",
      area_code: "HRI",
      city_name: "Hambantota",
      country_code: "LK"
  },
  {
      id: "HBU",
      name: "Bulgan (Khovd)",
      area_code: "HBU",
      city_name: "Bulgan (Khovd)",
      country_code: "MN"
  },
  {
      id: "HCC",
      name: "Columbia County",
      area_code: "HCC",
      city_name: "Hudson",
      country_code: "US"
  },
  {
      id: "HCM",
      name: "Eil",
      area_code: "HCM",
      city_name: "Eil",
      country_code: "SO"
  },
  {
      id: "HCW",
      name: "Municipal",
      area_code: "HCW",
      city_name: "Cheraw",
      country_code: "US"
  },
  {
      id: "HDE",
      name: "Brewster Field",
      area_code: "HDE",
      city_name: "Holdrege",
      country_code: "US"
  },
  {
      id: "HDH",
      name: "Dillingham",
      area_code: "HDH",
      city_name: "Mokuleia",
      country_code: "US"
  },
  {
      id: "HDM",
      name: "HAMADAN AIRPORT",
      area_code: "HDM",
      city_name: "Hamadan",
      country_code: "IR"
  },
  {
      id: "HDR",
      name: "Havadarya",
      area_code: "HDR",
      city_name: "Havadarya",
      country_code: "IR"
  },
  {
      id: "HEB",
      name: "Hinthada",
      area_code: "HEB",
      city_name: "Hinthada",
      country_code: "MM"
  },
  {
      id: "HEO",
      name: "Haelogo",
      area_code: "HEO",
      city_name: "Haelogo",
      country_code: "PG"
  },
  {
      id: "HES",
      name: "Municipal",
      area_code: "HES",
      city_name: "Hermiston",
      country_code: "US"
  },
  {
      id: "HFF",
      name: "Mackall AAF",
      area_code: "HFF",
      city_name: "Camp Mackall",
      country_code: "US"
  },
  {
      id: "HFS",
      name: "HAGFORS AIRPORT",
      area_code: "HFS",
      city_name: "Hagfors",
      country_code: "SE"
  },
  {
      id: "HGE",
      name: "Higuerote",
      area_code: "HGE",
      city_name: "Higuerote",
      country_code: "VE"
  },
  {
      id: "HGO",
      name: "KORHOGO AIRPORT",
      area_code: "HGO",
      city_name: "Korhogo",
      country_code: "CI"
  },
  {
      id: "HHI",
      name: "Wheeler AAF",
      area_code: "HHI",
      city_name: "Wahiawa",
      country_code: "US"
  },
  {
      id: "HHZ",
      name: "HIKUERU AIRPORT",
      area_code: "HHZ",
      city_name: "Hikueru",
      country_code: "PF"
  },
  {
      id: "HIG",
      name: "Highbury",
      area_code: "HIG",
      city_name: "Highbury",
      country_code: "AU"
  },
  {
      id: "HIL",
      name: "Shilabo",
      area_code: "HIL",
      city_name: "Shilabo",
      country_code: "ET"
  },
  {
      id: "HIM",
      name: "Minneriya",
      area_code: "HIM",
      city_name: "Hingurakgoda",
      country_code: "LK"
  },
  {
      id: "HIP",
      name: "Headingly",
      area_code: "HIP",
      city_name: "Headingly",
      country_code: "AU"
  },
  {
      id: "HIT",
      name: "Haivaro",
      area_code: "HIT",
      city_name: "Haivaro",
      country_code: "PG"
  },
  {
      id: "HJT",
      name: "Khujirt",
      area_code: "HJT",
      city_name: "Khujirt",
      country_code: "MN"
  },
  {
      id: "HKR",
      name: "Mara North",
      area_code: "MRE",
      city_name: "Maasai Mara",
      country_code: "KE"
  },
  {
      id: "HKV",
      name: "Malevo",
      area_code: "HKV",
      city_name: "Haskovo",
      country_code: "BG"
  },
  {
      id: "HLC",
      name: "Municipal",
      area_code: "HLC",
      city_name: "Hill City",
      country_code: "US"
  },
  {
      id: "HLE",
      name: "Saint Helena",
      area_code: "HLE",
      city_name: "Jamestown",
      country_code: "SH"
  },
  {
      id: "HLG",
      name: "Ohio County",
      area_code: "HLG",
      city_name: "Wheeling",
      country_code: "US"
  },
  {
      id: "HLL",
      name: "Hillside",
      area_code: "HLL",
      city_name: "Hillside",
      country_code: "AU"
  },
  {
      id: "HLT",
      name: "Hamilton",
      area_code: "HLT",
      city_name: "Hamilton",
      country_code: "AU"
  },
  {
      id: "HLV",
      name: "Helenvale",
      area_code: "HLV",
      city_name: "Helenvale",
      country_code: "AU"
  },
  {
      id: "HMG",
      name: "Hermannsburg",
      area_code: "HMG",
      city_name: "Hermannsburg",
      country_code: "AU"
  },
  {
      id: "HMJ",
      name: "International",
      area_code: "HMJ",
      city_name: "Khmelnytskyi",
      country_code: "UA"
  },
  {
      id: "HMT",
      name: "Ryan",
      area_code: "HMT",
      city_name: "Hemet",
      country_code: "US"
  },
  {
      id: "HNI",
      name: "Heiweni",
      area_code: "HNI",
      city_name: "Heiweni",
      country_code: "PG"
  },
  {
      id: "HOA",
      name: "Hola",
      area_code: "HOA",
      city_name: "Hola",
      country_code: "KE"
  },
  {
      id: "HOC",
      name: "Komako",
      area_code: "HOC",
      city_name: "Komako",
      country_code: "PG"
  },
  {
      id: "HOK",
      name: "Hooker Creek",
      area_code: "HOK",
      city_name: "Lajamanu",
      country_code: "AU"
  },
  {
      id: "HOO",
      name: "Nhon Co",
      area_code: "HOO",
      city_name: "Quang Duc",
      country_code: "VN"
  },
  {
      id: "HOS",
      name: "Chos Malal",
      area_code: "HOS",
      city_name: "Chos Malal",
      country_code: "AR"
  },
  {
      id: "HOX",
      name: "Homalin Airport",
      area_code: "HOX",
      city_name: "Homalin",
      country_code: "MM"
  },
  {
      id: "HOY",
      name: "Longhope",
      area_code: "HOY",
      city_name: "Hoy",
      country_code: "GB"
  },
  {
      id: "HPB",
      name: "HOOPER BAY AIRPORT",
      area_code: "HPB",
      city_name: "Hooper Bay",
      country_code: "US"
  },
  {
      id: "HPE",
      name: "Hopevale",
      area_code: "HPE",
      city_name: "Hopevale",
      country_code: "AU"
  },
  {
      id: "HPT",
      name: "Municipal",
      area_code: "HPT",
      city_name: "Hampton",
      country_code: "US"
  },
  {
      id: "HQM",
      name: "Bowerman",
      area_code: "HQM",
      city_name: "Hoquiam/Aberdeen",
      country_code: "US"
  },
  {
      id: "HRC",
      name: "Sary Su",
      area_code: "HRC",
      city_name: "Zhayrem",
      country_code: "KZ"
  },
  {
      id: "HRM",
      name: "Tilrempt",
      area_code: "HRM",
      city_name: "Hassi R'mel",
      country_code: "DZ"
  },
  {
      id: "HRR",
      name: "La Herrera",
      area_code: "HRR",
      city_name: "La Herrera",
      country_code: "CO"
  },
  {
      id: "HRY",
      name: "Henbury",
      area_code: "HRY",
      city_name: "Henbury",
      country_code: "AU"
  },
  {
      id: "HSB",
      name: "Raleigh",
      area_code: "HSB",
      city_name: "Harrisburg",
      country_code: "US"
  },
  {
      id: "HSC",
      name: "Guitou",
      area_code: "HSC",
      city_name: "Shaoguan",
      country_code: "CN"
  },
  {
      id: "HSJ",
      name: "Shangjie",
      area_code: "CGO",
      city_name: "Zhengzhou",
      country_code: "CN"
  },
  {
      id: "HSM",
      name: "Horsham",
      area_code: "HSM",
      city_name: "Horsham",
      country_code: "AU"
  },
  {
      id: "HSS",
      name: "Hisar",
      area_code: "HSS",
      city_name: "Hisar",
      country_code: "IN"
  },
  {
      id: "HTG",
      name: "Khatanga Airport",
      area_code: "HTG",
      city_name: "Khatanga",
      country_code: "RU"
  },
  {
      id: "HTH",
      name: "Industrial",
      area_code: "HTH",
      city_name: "Hawthorne",
      country_code: "US"
  },
  {
      id: "HTL",
      name: "Roscommon County",
      area_code: "HTL",
      city_name: "Houghton Lake",
      country_code: "US"
  },
  {
      id: "HTM",
      name: "Khatgal",
      area_code: "HTM",
      city_name: "Khatgal",
      country_code: "MN"
  },
  {
      id: "HTR",
      name: "Hateruma",
      area_code: "HTR",
      city_name: "Hateruma",
      country_code: "JP"
  },
  {
      id: "HTT",
      name: "Huatugou Airport",
      area_code: "HTT",
      city_name: "Huatugou",
      country_code: "CN"
  },
  {
      id: "HTU",
      name: "Hopetoun",
      area_code: "HTU",
      city_name: "Hopetoun",
      country_code: "AU"
  },
  {
      id: "HTW",
      name: "Lawrence Co. Airpark",
      area_code: "HTW",
      city_name: "Chesapeake",
      country_code: "US"
  },
  {
      id: "HTZ",
      name: "Hato Corozal",
      area_code: "HTZ",
      city_name: "Hato Corozal",
      country_code: "CO"
  },
  {
      id: "HUA",
      name: "Redstone AAF",
      area_code: "HSV",
      city_name: "Huntsville",
      country_code: "US"
  },
  {
      id: "HUB",
      name: "Humbert River",
      area_code: "HUB",
      city_name: "Humbert River",
      country_code: "AU"
  },
  {
      id: "HUD",
      name: "Municipal",
      area_code: "HUD",
      city_name: "Humboldt",
      country_code: "US"
  },
  {
      id: "HUE",
      name: "HUMERA AIRPORT",
      area_code: "HUE",
      city_name: "Humera",
      country_code: "ET"
  },
  {
      id: "HUG",
      name: "Huehuetenango",
      area_code: "HUG",
      city_name: "Huehuetenango",
      country_code: "GT"
  },
  {
      id: "HUJ",
      name: "Stan Stamper Municipal",
      area_code: "HUJ",
      city_name: "Hugo",
      country_code: "US"
  },
  {
      id: "HUK",
      name: "Hukuntsi",
      area_code: "HUK",
      city_name: "Hukuntsi",
      country_code: "BW"
  },
  {
      id: "HUO",
      name: "Huolinhe Airport",
      area_code: "HUO",
      city_name: "Huolinguole",
      country_code: "CN"
  },
  {
      id: "HUQ",
      name: "Hon",
      area_code: "HUQ",
      city_name: "Hon",
      country_code: "LY"
  },
  {
      id: "HUW",
      name: "F.Correa da Cruz",
      area_code: "HUW",
      city_name: "Humaita",
      country_code: "BR"
  },
  {
      id: "HVA",
      name: "Analalava",
      area_code: "HVA",
      city_name: "Analalava",
      country_code: "MG"
  },
  {
      id: "HVK",
      name: "Holmavik",
      area_code: "HVK",
      city_name: "Holmavik",
      country_code: "IS"
  },
  {
      id: "HVS",
      name: "Regional",
      area_code: "HVS",
      city_name: "Hartsville",
      country_code: "US"
  },
  {
      id: "HWA",
      name: "Hawabango",
      area_code: "HWA",
      city_name: "Hawabango",
      country_code: "PG"
  },
  {
      id: "HWI",
      name: "Sea Plane Base",
      area_code: "HWI",
      city_name: "Hawk Inlet",
      country_code: "US"
  },
  {
      id: "HWK",
      name: "Hawker",
      area_code: "HWK",
      city_name: "Hawker",
      country_code: "AU"
  },
  {
      id: "HXX",
      name: "Hay",
      area_code: "HXX",
      city_name: "Hay",
      country_code: "AU"
  },
  {
      id: "HYC",
      name: "Air Park/Booker",
      area_code: "HYC",
      city_name: "High Wycombe",
      country_code: "GB"
  },
  {
      id: "HYF",
      name: "Hayfield",
      area_code: "HYF",
      city_name: "Hayfield",
      country_code: "PG"
  },
  {
      id: "HYG",
      name: "Hydaburg Seaplane Base",
      area_code: "HYG",
      city_name: "Hydaburg",
      country_code: "US"
  },
  {
      id: "HYV",
      name: "Hyvinkaa",
      area_code: "HYV",
      city_name: "Hyvinkaa",
      country_code: "FI"
  },
  {
      id: "HZL",
      name: "Regional",
      area_code: "HZL",
      city_name: "Hazleton",
      country_code: "US"
  },
  {
      id: "HZP",
      name: "Horizon",
      area_code: "HZP",
      city_name: "Fort MacKay",
      country_code: "CA"
  },
  {
      id: "IAM",
      name: "In Amenas Airport",
      area_code: "IAM",
      city_name: "In Amenas",
      country_code: "DZ"
  },
  {
      id: "IAN",
      name: "Bob Baker Memorial Airport",
      area_code: "IAN",
      city_name: "Kiana",
      country_code: "US"
  },
  {
      id: "IAQ",
      name: "Bahregan",
      area_code: "IAQ",
      city_name: "Bahregan",
      country_code: "IR"
  },
  {
      id: "IAU",
      name: "Iaura",
      area_code: "IAU",
      city_name: "Iaura",
      country_code: "PG"
  },
  {
      id: "IBB",
      name: "General Villamil",
      area_code: "IBB",
      city_name: "Isabela Island",
      country_code: "EC"
  },
  {
      id: "IBI",
      name: "Iboki",
      area_code: "IBI",
      city_name: "Iboki",
      country_code: "PG"
  },
  {
      id: "IBL",
      name: "Indigo Bay Lodge",
      area_code: "IBL",
      city_name: "Indigo Bay Lodge",
      country_code: "MZ"
  },
  {
      id: "IBO",
      name: "Ibo",
      area_code: "IBO",
      city_name: "Ibo",
      country_code: "MZ"
  },
  {
      id: "IBP",
      name: "Iberia",
      area_code: "IBP",
      city_name: "Iberia",
      country_code: "PE"
  },
  {
      id: "ICA",
      name: "Icabaru",
      area_code: "ICA",
      city_name: "Icabaru",
      country_code: "VE"
  },
  {
      id: "ICC",
      name: "A.M. Salazar Marcano",
      area_code: "ICC",
      city_name: "Isla de Coche",
      country_code: "VE"
  },
  {
      id: "ICI",
      name: "Cicia Airport",
      area_code: "ICI",
      city_name: "Cicia Island",
      country_code: "FJ"
  },
  {
      id: "ICK",
      name: "Majoor Henry Fernandes",
      area_code: "ICK",
      city_name: "Nieuw Nickerie",
      country_code: "SR"
  },
  {
      id: "ICL",
      name: "Schenck Field",
      area_code: "ICL",
      city_name: "Clarinda",
      country_code: "US"
  },
  {
      id: "ICO",
      name: "Sicogon Island",
      area_code: "ICO",
      city_name: "Sicogon Island",
      country_code: "PH"
  },
  {
      id: "ICS",
      name: "Cascade",
      area_code: "ICS",
      city_name: "Cascade",
      country_code: "US"
  },
  {
      id: "IDB",
      name: "Idre",
      area_code: "IDB",
      city_name: "Idre",
      country_code: "SE"
  },
  {
      id: "IDC",
      name: "Chilonzuine Island",
      area_code: "IDC",
      city_name: "Chilonzuine Island",
      country_code: "MZ"
  },
  {
      id: "IDF",
      name: "Idiofa",
      area_code: "IDF",
      city_name: "Idiofa",
      country_code: "CD"
  },
  {
      id: "IDG",
      name: "Municipal",
      area_code: "IDG",
      city_name: "Ida Grove",
      country_code: "US"
  },
  {
      id: "IDH",
      name: "Idaho County",
      area_code: "IDH",
      city_name: "Grangeville",
      country_code: "US"
  },
  {
      id: "IDK",
      name: "Indulkana",
      area_code: "IDK",
      city_name: "Indulkana",
      country_code: "AU"
  },
  {
      id: "IDN",
      name: "Indagen",
      area_code: "IDN",
      city_name: "Indagen",
      country_code: "PG"
  },
  {
      id: "IDO",
      name: "Santa Isabel Do Morro",
      area_code: "IDO",
      city_name: "Santa Isabel do Morro",
      country_code: "BR"
  },
  {
      id: "IDP",
      name: "Municipal",
      area_code: "IDP",
      city_name: "Independence",
      country_code: "US"
  },
  {
      id: "IDY",
      name: "Ile d'Yeu",
      area_code: "IDY",
      city_name: "Ile d'Yeu",
      country_code: "FR"
  },
  {
      id: "IEJ",
      name: "Iejima",
      area_code: "IEJ",
      city_name: "Iejima",
      country_code: "JP"
  },
  {
      id: "IFA",
      name: "Municipal",
      area_code: "IFA",
      city_name: "Iowa Falls",
      country_code: "US"
  },
  {
      id: "IFF",
      name: "Iffley",
      area_code: "IFF",
      city_name: "Iffley",
      country_code: "AU"
  },
  {
      id: "IFH",
      name: "Hesa",
      area_code: "IFH",
      city_name: "Hesa",
      country_code: "IR"
  },
  {
      id: "IFL",
      name: "Innisfail",
      area_code: "IFL",
      city_name: "Innisfail",
      country_code: "AU"
  },
  {
      id: "IGB",
      name: "Ingeniero Jacobacci",
      area_code: "IGB",
      city_name: "Ingeniero Jacobacci",
      country_code: "AR"
  },
  {
      id: "IGE",
      name: "Tchongorove",
      area_code: "IGE",
      city_name: "Iguela",
      country_code: "GA"
  },
  {
      id: "IGG",
      name: "IGIUGIG AIRPORT",
      area_code: "IGG",
      city_name: "Igiugig",
      country_code: "US"
  },
  {
      id: "IGN",
      name: "Maria Cristina",
      area_code: "IGN",
      city_name: "Iligan",
      country_code: "PH"
  },
  {
      id: "IHC",
      name: "Inhaca",
      area_code: "IHC",
      city_name: "Inhaca",
      country_code: "MZ"
  },
  {
      id: "IHN",
      name: "Qishn",
      area_code: "IHN",
      city_name: "Qishn",
      country_code: "YE"
  },
  {
      id: "IHO",
      name: "Ihosy",
      area_code: "IHO",
      city_name: "Ihosy",
      country_code: "MG"
  },
  {
      id: "IHU",
      name: "Ihu",
      area_code: "IHU",
      city_name: "Ihu",
      country_code: "PG"
  },
  {
      id: "IIA",
      name: "Inishmaan",
      area_code: "IIA",
      city_name: "Inishmaan",
      country_code: "IE"
  },
  {
      id: "IIL",
      name: "Ilaam Airport",
      area_code: "IIL",
      city_name: "Ilam",
      country_code: "IR"
  },
  {
      id: "IIS",
      name: "Nissan Island",
      area_code: "IIS",
      city_name: "Nissan Island",
      country_code: "PG"
  },
  {
      id: "IJU",
      name: "J.Batista Bos Filho",
      area_code: "IJU",
      city_name: "Ijui",
      country_code: "BR"
  },
  {
      id: "IKB",
      name: "Wilkes County",
      area_code: "IKB",
      city_name: "Wilkesboro",
      country_code: "US"
  },
  {
      id: "IKL",
      name: "Ikela",
      area_code: "IKL",
      city_name: "Ikela",
      country_code: "CD"
  },
  {
      id: "IKP",
      name: "Inkerman",
      area_code: "IKP",
      city_name: "Inkerman",
      country_code: "AU"
  },
  {
      id: "IKS",
      name: "TIKSI AIRPORT",
      area_code: "IKS",
      city_name: "Tiksi",
      country_code: "RU"
  },
  {
      id: "IKU",
      name: "Issyk-Kul",
      area_code: "IKU",
      city_name: "Tamchy",
      country_code: "KG"
  },
  {
      id: "ILA",
      name: "Illaga",
      area_code: "ILA",
      city_name: "Illaga",
      country_code: "ID"
  },
  {
      id: "ILH",
      name: "Illesheim",
      area_code: "ILH",
      city_name: "Illesheim",
      country_code: "DE"
  },
  {
      id: "ILI",
      name: "ILIAMNA AIRPORT",
      area_code: "ILI",
      city_name: "Iliamna",
      country_code: "US"
  },
  {
      id: "ILK",
      name: "Ilaka Atsinanana",
      area_code: "ILK",
      city_name: "Ilaka Atsinanana",
      country_code: "MG"
  },
  {
      id: "ILL",
      name: "Municipal",
      area_code: "ILL",
      city_name: "Willmar",
      country_code: "US"
  },
  {
      id: "ILN",
      name: "Air Park",
      area_code: "ILN",
      city_name: "Wilmington",
      country_code: "US"
  },
  {
      id: "ILQ",
      name: "Ilo",
      area_code: "ILQ",
      city_name: "Ilo",
      country_code: "PE"
  },
  {
      id: "ILR",
      name: "Ilorin Airport",
      area_code: "ILR",
      city_name: "Ilorin",
      country_code: "NG"
  },
  {
      id: "ILS",
      name: "Ilopango",
      area_code: "SAL",
      city_name: "San Salvador",
      country_code: "SV"
  },
  {
      id: "ILU",
      name: "Kilaguni",
      area_code: "ILU",
      city_name: "Kilaguni",
      country_code: "KE"
  },
  {
      id: "ILX",
      name: "Ileg",
      area_code: "ILX",
      city_name: "Ileg",
      country_code: "PG"
  },
  {
      id: "IMA",
      name: "Iamalele",
      area_code: "IMA",
      city_name: "Iamalele",
      country_code: "PG"
  },
  {
      id: "IMB",
      name: "Imbaimadai",
      area_code: "IMB",
      city_name: "Imbaimadai",
      country_code: "GY"
  },
  {
      id: "IMD",
      name: "Imonda",
      area_code: "IMD",
      city_name: "Imonda",
      country_code: "PG"
  },
  {
      id: "IMG",
      name: "Inhaminga",
      area_code: "IMG",
      city_name: "Inhaminga",
      country_code: "MZ"
  },
  {
      id: "IMI",
      name: "Ine Island",
      area_code: "IMI",
      city_name: "Ine Island",
      country_code: "MH"
  },
  {
      id: "IMK",
      name: "Simikot",
      area_code: "IMK",
      city_name: "Simikot",
      country_code: "NP"
  },
  {
      id: "IML",
      name: "Municipal",
      area_code: "IML",
      city_name: "Imperial",
      country_code: "US"
  },
  {
      id: "IMM",
      name: "Regional",
      area_code: "IMM",
      city_name: "Immokalee",
      country_code: "US"
  },
  {
      id: "IMN",
      name: "Imane",
      area_code: "IMN",
      city_name: "Imane",
      country_code: "PG"
  },
  {
      id: "IMQ",
      name: "Maku",
      area_code: "IMQ",
      city_name: "Maku",
      country_code: "IR"
  },
  {
      id: "INA",
      name: "Inta Airport",
      area_code: "INA",
      city_name: "Inta",
      country_code: "RU"
  },
  {
      id: "INB",
      name: "INDEPENDENCE AIRPORT",
      area_code: "INB",
      city_name: "Independence",
      country_code: "BZ"
  },
  {
      id: "INE",
      name: "Chinde",
      area_code: "INE",
      city_name: "Chinde",
      country_code: "MZ"
  },
  {
      id: "INF",
      name: "In Guezzam",
      area_code: "INF",
      city_name: "In Guezzam",
      country_code: "DZ"
  },
  {
      id: "INK",
      name: "Winkler County",
      area_code: "INK",
      city_name: "Wink",
      country_code: "US"
  },
  {
      id: "INM",
      name: "Innamincka",
      area_code: "INM",
      city_name: "Innamincka",
      country_code: "AU"
  },
  {
      id: "INO",
      name: "Inongo",
      area_code: "INO",
      city_name: "Inongo",
      country_code: "CD"
  },
  {
      id: "INQ",
      name: "Inisheer",
      area_code: "INQ",
      city_name: "Inisheer",
      country_code: "IE"
  },
  {
      id: "INS",
      name: "Creech AFB",
      area_code: "INS",
      city_name: "Indian Springs",
      country_code: "US"
  },
  {
      id: "INX",
      name: "Inanwatan",
      area_code: "INX",
      city_name: "Inanwatan",
      country_code: "ID"
  },
  {
      id: "INY",
      name: "Inyati",
      area_code: "INY",
      city_name: "Inyati",
      country_code: "ZA"
  },
  {
      id: "INZ",
      name: "IN SALAH AIRPORT",
      area_code: "INZ",
      city_name: "In Salah",
      country_code: "DZ"
  },
  {
      id: "IOK",
      name: "Iokea",
      area_code: "IOK",
      city_name: "Iokea",
      country_code: "PG"
  },
  {
      id: "ION",
      name: "Impfondo",
      area_code: "ION",
      city_name: "Impfondo",
      country_code: "CG"
  },
  {
      id: "IOP",
      name: "Ioma",
      area_code: "IOP",
      city_name: "Ioma",
      country_code: "PG"
  },
  {
      id: "IOR",
      name: "Kilronan",
      area_code: "IOR",
      city_name: "Inishmore",
      country_code: "IE"
  },
  {
      id: "IPE",
      name: "Ipil",
      area_code: "IPE",
      city_name: "Ipil",
      country_code: "PH"
  },
  {
      id: "IPG",
      name: "Ipiranga",
      area_code: "IPG",
      city_name: "Ipiranga",
      country_code: "BR"
  },
  {
      id: "IPU",
      name: "Ipiau",
      area_code: "IPU",
      city_name: "Ipiau",
      country_code: "BR"
  },
  {
      id: "IPZ",
      name: "San Isidro del General Airport",
      area_code: "IPZ",
      city_name: "San Isidro de El Gener",
      country_code: "CR"
  },
  {
      id: "IQA",
      name: "Air Base",
      area_code: "IQA",
      city_name: "Al Asad",
      country_code: "IQ"
  },
  {
      id: "IRA",
      name: "KIRAKIRA AIRPORT",
      area_code: "IRA",
      city_name: "Kirakira",
      country_code: "SB"
  },
  {
      id: "IRB",
      name: "Municipal",
      area_code: "IRB",
      city_name: "Iraan",
      country_code: "US"
  },
  {
      id: "IRD",
      name: "Ishurdi",
      area_code: "IRD",
      city_name: "Ishurdi",
      country_code: "BD"
  },
  {
      id: "IRE",
      name: "Irece",
      area_code: "IRE",
      city_name: "Irece",
      country_code: "BR"
  },
  {
      id: "IRI",
      name: "Iringa Airport",
      area_code: "IRI",
      city_name: "Iringa",
      country_code: "TZ"
  },
  {
      id: "IRN",
      name: "Iriona",
      area_code: "IRN",
      city_name: "Iriona",
      country_code: "HN"
  },
  {
      id: "IRO",
      name: "Birao",
      area_code: "IRO",
      city_name: "Birao",
      country_code: "CF"
  },
  {
      id: "IRP",
      name: "Matari Airport",
      area_code: "IRP",
      city_name: "Isiro",
      country_code: "CD"
  },
  {
      id: "IRU",
      name: "SPB",
      area_code: "IRU",
      city_name: "Iranamadu",
      country_code: "LK"
  },
  {
      id: "IRZ",
      name: "Tapuruquara",
      area_code: "IRZ",
      city_name: "Santa Isabel Rio Negro",
      country_code: "BR"
  },
  {
      id: "ISD",
      name: "Iscuande",
      area_code: "ISD",
      city_name: "Iscuande",
      country_code: "CO"
  },
  {
      id: "ISI",
      name: "Isisford",
      area_code: "ISI",
      city_name: "Isisford",
      country_code: "AU"
  },
  {
      id: "ITA",
      name: "Itacoatiara",
      area_code: "ITA",
      city_name: "Itacoatiara",
      country_code: "BR"
  },
  {
      id: "ITE",
      name: "Itubera",
      area_code: "ITE",
      city_name: "Itubera",
      country_code: "BR"
  },
  {
      id: "ITI",
      name: "Fazenda Americana",
      area_code: "ITI",
      city_name: "Itambacuri",
      country_code: "BR"
  },
  {
      id: "ITN",
      name: "Itabuna",
      area_code: "ITN",
      city_name: "Itabuna",
      country_code: "BR"
  },
  {
      id: "ITQ",
      name: "Itaqui",
      area_code: "ITQ",
      city_name: "Itaqui",
      country_code: "BR"
  },
  {
      id: "ITR",
      name: "F.Vilela do Amaral",
      area_code: "ITR",
      city_name: "Itumbiara",
      country_code: "BR"
  },
  {
      id: "ITU",
      name: "Iturup Airport",
      area_code: "ITU",
      city_name: "Iturup Island",
      country_code: "RU"
  },
  {
      id: "IUL",
      name: "Ilu",
      area_code: "IUL",
      city_name: "Ilu",
      country_code: "ID"
  },
  {
      id: "IUS",
      name: "Inus",
      area_code: "IUS",
      city_name: "Inus",
      country_code: "PG"
  },
  {
      id: "IVA",
      name: "Ampampamena",
      area_code: "IVA",
      city_name: "Ambanja",
      country_code: "MG"
  },
  {
      id: "IVG",
      name: "Dolac",
      area_code: "IVG",
      city_name: "Berane",
      country_code: "ME"
  },
  {
      id: "IVI",
      name: "Viveros Island",
      area_code: "IVI",
      city_name: "Viveros Island",
      country_code: "PA"
  },
  {
      id: "IVO",
      name: "Chivolo",
      area_code: "IVO",
      city_name: "Chivolo",
      country_code: "CO"
  },
  {
      id: "IVW",
      name: "Inverway",
      area_code: "IVW",
      city_name: "Inverway",
      country_code: "AU"
  },
  {
      id: "IWO",
      name: "Iwoto (Iwo Jima)",
      area_code: "IWO",
      city_name: "Iwoto (Iwo Jima)",
      country_code: "JP"
  },
  {
      id: "IWS",
      name: "West Houston",
      area_code: "HOU",
      city_name: "Houston",
      country_code: "US"
  },
  {
      id: "JAA",
      name: "Jalalabad",
      area_code: "JAA",
      city_name: "Jalalabad",
      country_code: "AF"
  },
  {
      id: "JAD",
      name: "Jandakot",
      area_code: "PER",
      city_name: "Perth",
      country_code: "AU"
  },
  {
      id: "JAK",
      name: "Jacmel",
      area_code: "JAK",
      city_name: "Jacmel",
      country_code: "HT"
  },
  {
      id: "JAM",
      name: "Bezmer",
      area_code: "JAM",
      city_name: "Jambol",
      country_code: "BG"
  },
  {
      id: "JAQ",
      name: "Jacquinot Bay",
      area_code: "JAQ",
      city_name: "Jacquinot Bay",
      country_code: "PG"
  },
  {
      id: "JAR",
      name: "Jahrom",
      area_code: "JAR",
      city_name: "Jahrom",
      country_code: "IR"
  },
  {
      id: "JAS",
      name: "Jasper County-Bell Fld",
      area_code: "JAS",
      city_name: "Jasper",
      country_code: "US"
  },
  {
      id: "JAT",
      name: "Jabot Island",
      area_code: "JAT",
      city_name: "Jabot Island",
      country_code: "MH"
  },
  {
      id: "JCM",
      name: "Jacobina",
      area_code: "JCM",
      city_name: "Jacobina",
      country_code: "BR"
  },
  {
      id: "JCR",
      name: "Jacareacanga",
      area_code: "JCR",
      city_name: "Jacareacanga",
      country_code: "BR"
  },
  {
      id: "JCT",
      name: "Kimble County",
      area_code: "JCT",
      city_name: "Junction",
      country_code: "US"
  },
  {
      id: "JCY",
      name: "LBJ Ranch",
      area_code: "JCY",
      city_name: "Johnson City",
      country_code: "US"
  },
  {
      id: "JDA",
      name: "Grant County Regional",
      area_code: "JDA",
      city_name: "John Day",
      country_code: "US"
  },
  {
      id: "JDG",
      name: "Jeongseok",
      area_code: "JDG",
      city_name: "Jeongseok",
      country_code: "KR"
  },
  {
      id: "JDN",
      name: "Jordan",
      area_code: "JDN",
      city_name: "Jordan",
      country_code: "US"
  },
  {
      id: "JEE",
      name: "Jeremie",
      area_code: "JEE",
      city_name: "Jeremie",
      country_code: "HT"
  },
  {
      id: "JEJ",
      name: "Jeh Island",
      area_code: "JEJ",
      city_name: "Jeh Island",
      country_code: "MH"
  },
  {
      id: "JEQ",
      name: "Jequie",
      area_code: "JEQ",
      city_name: "Jequie",
      country_code: "BR"
  },
  {
      id: "JFN",
      name: "Northeast Ohio Rgnl",
      area_code: "JFN",
      city_name: "Ashtabula",
      country_code: "US"
  },
  {
      id: "JFR",
      name: "Paamiut Airport",
      area_code: "JFR",
      city_name: "Paamiut",
      country_code: "GL"
  },
  {
      id: "JHL",
      name: "Albian",
      area_code: "HZP",
      city_name: "Fort MacKay",
      country_code: "CA"
  },
  {
      id: "JIA",
      name: "Juina",
      area_code: "JIA",
      city_name: "Juina",
      country_code: "BR"
  },
  {
      id: "JIP",
      name: "Jipijapa",
      area_code: "JIP",
      city_name: "Jipijapa",
      country_code: "EC"
  },
  {
      id: "JIR",
      name: "Jiri",
      area_code: "JIR",
      city_name: "Jiri",
      country_code: "NP"
  },
  {
      id: "JIW",
      name: "Jiwani",
      area_code: "JIW",
      city_name: "Jiwani",
      country_code: "PK"
  },
  {
      id: "JJA",
      name: "Jajao",
      area_code: "JJA",
      city_name: "Jajao",
      country_code: "SB"
  },
  {
      id: "JJD",
      name: "Jericoacoara Airport",
      area_code: "JJD",
      city_name: "Cruz",
      country_code: "BR"
  },
  {
      id: "JJG",
      name: "Jaguaruna Airport",
      area_code: "JJG",
      city_name: "Jaguaruna",
      country_code: "BR"
  },
  {
      id: "JJI",
      name: "Juanjui",
      area_code: "JJI",
      city_name: "Juanjui",
      country_code: "PE"
  },
  {
      id: "JJM",
      name: "Mulika Lodge",
      area_code: "JJM",
      city_name: "Meru-Kinna",
      country_code: "KE"
  },
  {
      id: "JKR",
      name: "JANAKPUR AIRPORT",
      area_code: "JKR",
      city_name: "Janakpur",
      country_code: "NP"
  },
  {
      id: "JLA",
      name: "Quartz Creek",
      area_code: "JLA",
      city_name: "Cooper Landing",
      country_code: "US"
  },
  {
      id: "JLS",
      name: "Jales",
      area_code: "JLS",
      city_name: "Jales",
      country_code: "BR"
  },
  {
      id: "JMB",
      name: "Jamba",
      area_code: "JMB",
      city_name: "Jamba",
      country_code: "AO"
  },
  {
      id: "JMJ",
      name: "Jingmai Airport",
      area_code: "JMJ",
      city_name: "Lancang",
      country_code: "CN"
  },
  {
      id: "JMO",
      name: "Jomsom",
      area_code: "JMO",
      city_name: "Jomsom",
      country_code: "NP"
  },
  {
      id: "JNA",
      name: "Januaria",
      area_code: "JNA",
      city_name: "Januaria",
      country_code: "BR"
  },
  {
      id: "JNI",
      name: "Junin",
      area_code: "JNI",
      city_name: "Junin",
      country_code: "AR"
  },
  {
      id: "JOH",
      name: "Port Saint John's",
      area_code: "JOH",
      city_name: "Port Saint John's",
      country_code: "ZA"
  },
  {
      id: "JOJ",
      name: "Doris Lake Ice A/P",
      area_code: "UZM",
      city_name: "Hope Bay",
      country_code: "CA"
  },
  {
      id: "JOM",
      name: "Njombe",
      area_code: "JOM",
      city_name: "Njombe",
      country_code: "TZ"
  },
  {
      id: "JOP",
      name: "Josephstaal",
      area_code: "JOP",
      city_name: "Josephstaal",
      country_code: "PG"
  },
  {
      id: "JQE",
      name: "JAQUE AIRPORT",
      area_code: "JQE",
      city_name: "Jaque",
      country_code: "PA"
  },
  {
      id: "JRG",
      name: "Veer Surendra Sai",
      area_code: "JRG",
      city_name: "Jharsuguda",
      country_code: "IN"
  },
  {
      id: "JRN",
      name: "Juruena",
      area_code: "JRN",
      city_name: "Juruena",
      country_code: "BR"
  },
  {
      id: "JSA",
      name: "Jaisalmer",
      area_code: "JSA",
      city_name: "Jaisalmer",
      country_code: "IN"
  },
  {
      id: "JSJ",
      name: "Jiansanjiang Airport",
      area_code: "JSJ",
      city_name: "Jiansanjiang",
      country_code: "CN"
  },
  {
      id: "JSM",
      name: "Jose de San Martin",
      area_code: "JSM",
      city_name: "Jose de San Martin",
      country_code: "AR"
  },
  {
      id: "JTI",
      name: "Jatai",
      area_code: "JTI",
      city_name: "Jatai",
      country_code: "BR"
  },
  {
      id: "JUA",
      name: "I. Luiz do Nascimento",
      area_code: "JUA",
      city_name: "Juara",
      country_code: "BR"
  },
  {
      id: "JUN",
      name: "Jundah",
      area_code: "JUN",
      city_name: "Jundah",
      country_code: "AU"
  },
  {
      id: "JUR",
      name: "Jurien Bay",
      area_code: "JUR",
      city_name: "Jurien Bay",
      country_code: "AU"
  },
  {
      id: "JUT",
      name: "Juticalpa",
      area_code: "JUT",
      city_name: "Juticalpa",
      country_code: "HN"
  },
  {
      id: "JVA",
      name: "Ankavandra",
      area_code: "JVA",
      city_name: "Ankavandra",
      country_code: "MG"
  },
  {
      id: "JVI",
      name: "Central Jersey Rgnl",
      area_code: "JVI",
      city_name: "Manville",
      country_code: "US"
  },
  {
      id: "JWA",
      name: "Jwaneng",
      area_code: "JWA",
      city_name: "Jwaneng",
      country_code: "BW"
  },
  {
      id: "JWO",
      name: "Jungwon AB",
      area_code: "JWO",
      city_name: "Chungju",
      country_code: "KR"
  },
  {
      id: "JYR",
      name: "Jiroft",
      area_code: "JYR",
      city_name: "Jiroft",
      country_code: "IR"
  },
  {
      id: "KAA",
      name: "KASAMA AIRPORT",
      area_code: "KAA",
      city_name: "Kasama",
      country_code: "ZM"
  },
  {
      id: "KAC",
      name: "Kamishly Airport",
      area_code: "KAC",
      city_name: "Kamishly",
      country_code: "SY"
  },
  {
      id: "KAF",
      name: "Karato",
      area_code: "KAF",
      city_name: "Karato",
      country_code: "PG"
  },
  {
      id: "KAG",
      name: "Air Base",
      area_code: "KAG",
      city_name: "Gangneung",
      country_code: "KR"
  },
  {
      id: "KAI",
      name: "Kaieteur",
      area_code: "KAI",
      city_name: "Kaieteur",
      country_code: "GY"
  },
  {
      id: "KAK",
      name: "Kar",
      area_code: "KAK",
      city_name: "Kar",
      country_code: "PG"
  },
  {
      id: "KAL",
      name: "KALTAG AIRPORT",
      area_code: "KAL",
      city_name: "Kaltag",
      country_code: "US"
  },
  {
      id: "KAM",
      name: "Kamaran Island",
      area_code: "KAM",
      city_name: "Kamaran Island",
      country_code: "YE"
  },
  {
      id: "KAP",
      name: "Kapanga",
      area_code: "KAP",
      city_name: "Kapanga",
      country_code: "CD"
  },
  {
      id: "KAQ",
      name: "Kamulai",
      area_code: "KAQ",
      city_name: "Kamulai",
      country_code: "PG"
  },
  {
      id: "KAR",
      name: "Kamarang",
      area_code: "KAR",
      city_name: "Kamarang",
      country_code: "GY"
  },
  {
      id: "KAS",
      name: "Karasburg",
      area_code: "KAS",
      city_name: "Karasburg",
      country_code: "NA"
  },
  {
      id: "KAU",
      name: "Kauhava",
      area_code: "KAU",
      city_name: "Kauhava",
      country_code: "FI"
  },
  {
      id: "KAV",
      name: "Kanavayen",
      area_code: "KAV",
      city_name: "Kanavayen",
      country_code: "VE"
  },
  {
      id: "KAX",
      name: "Kalbarri",
      area_code: "KAX",
      city_name: "Kalbarri",
      country_code: "AU"
  },
  {
      id: "KAY",
      name: "Wakaya Island",
      area_code: "KAY",
      city_name: "Wakaya Island",
      country_code: "FJ"
  },
  {
      id: "KBA",
      name: "Kabala",
      area_code: "KBA",
      city_name: "Kabala",
      country_code: "SL"
  },
  {
      id: "KBB",
      name: "Kirkimbie Station",
      area_code: "KBB",
      city_name: "Kirkimbie Station",
      country_code: "AU"
  },
  {
      id: "KBC",
      name: "BIRCH CREEK AIRPORT",
      area_code: "KBC",
      city_name: "Birch Creek",
      country_code: "US"
  },
  {
      id: "KBD",
      name: "Kimberley Downs Stn",
      area_code: "KBD",
      city_name: "Kimberley Downs Stn",
      country_code: "AU"
  },
  {
      id: "KBE",
      name: "Hot Springs SPB",
      area_code: "KBE",
      city_name: "Bell Island",
      country_code: "US"
  },
  {
      id: "KBF",
      name: "Karubaga",
      area_code: "KBF",
      city_name: "Karubaga",
      country_code: "ID"
  },
  {
      id: "KBG",
      name: "Kabalega Falls",
      area_code: "KBG",
      city_name: "Kabalega Falls",
      country_code: "UG"
  },
  {
      id: "KBH",
      name: "Buzwagi Airport",
      area_code: "KBH",
      city_name: "Kahama",
      country_code: "TZ"
  },
  {
      id: "KBI",
      name: "Kribi",
      area_code: "KBI",
      city_name: "Kribi",
      country_code: "CM"
  },
  {
      id: "KBM",
      name: "Kabwum",
      area_code: "KBM",
      city_name: "Kabwum",
      country_code: "PG"
  },
  {
      id: "KBN",
      name: "Tunta",
      area_code: "KBN",
      city_name: "Kabinda",
      country_code: "CD"
  },
  {
      id: "KBO",
      name: "Kabalo",
      area_code: "KBO",
      city_name: "Kabalo",
      country_code: "CD"
  },
  {
      id: "KBQ",
      name: "Kasungu",
      area_code: "KBQ",
      city_name: "Kasungu",
      country_code: "MW"
  },
  {
      id: "KBS",
      name: "Bo",
      area_code: "KBS",
      city_name: "Bo",
      country_code: "SL"
  },
  {
      id: "KBT",
      name: "Kaben Island",
      area_code: "KBT",
      city_name: "Kaben Island",
      country_code: "MH"
  },
  {
      id: "KBX",
      name: "Kambuaya",
      area_code: "KBX",
      city_name: "Ayamaru",
      country_code: "ID"
  },
  {
      id: "KBY",
      name: "Streaky Bay",
      area_code: "KBY",
      city_name: "Streaky Bay",
      country_code: "AU"
  },
  {
      id: "KCB",
      name: "Tepoe",
      area_code: "KCB",
      city_name: "Kasikasima",
      country_code: "SR"
  },
  {
      id: "KCC",
      name: "Coffman Cove Seaplane Base",
      area_code: "KCC",
      city_name: "Coffman Cove",
      country_code: "US"
  },
  {
      id: "KCD",
      name: "Kamur",
      area_code: "KCD",
      city_name: "Kamur",
      country_code: "ID"
  },
  {
      id: "KCJ",
      name: "Komaio",
      area_code: "KCJ",
      city_name: "Komaio",
      country_code: "PG"
  },
  {
      id: "KCK",
      name: "Kirensk Airport",
      area_code: "KCK",
      city_name: "Kirensk",
      country_code: "RU"
  },
  {
      id: "KCP",
      name: "Kamianets-Podilskiy",
      area_code: "KCP",
      city_name: "Kamianets-Podilskiy",
      country_code: "UA"
  },
  {
      id: "KCR",
      name: "Colorado Creek",
      area_code: "KCR",
      city_name: "Colorado Creek",
      country_code: "US"
  },
  {
      id: "KCS",
      name: "Kings Creek Station",
      area_code: "KCS",
      city_name: "Kings Creek Station",
      country_code: "AU"
  },
  {
      id: "KCU",
      name: "Masindi",
      area_code: "KCU",
      city_name: "Masindi",
      country_code: "UG"
  },
  {
      id: "KDA",
      name: "Kolda",
      area_code: "KDA",
      city_name: "Kolda",
      country_code: "SN"
  },
  {
      id: "KDB",
      name: "Kambalda",
      area_code: "KDB",
      city_name: "Kambalda",
      country_code: "AU"
  },
  {
      id: "KDC",
      name: "Kandi",
      area_code: "KDC",
      city_name: "Kandi",
      country_code: "BJ"
  },
  {
      id: "KDD",
      name: "Khuzdar",
      area_code: "KDD",
      city_name: "Khuzdar",
      country_code: "PK"
  },
  {
      id: "KDE",
      name: "Koroba",
      area_code: "KDE",
      city_name: "Koroba",
      country_code: "PG"
  },
  {
      id: "KDJ",
      name: "Ndjole-Ville",
      area_code: "KDJ",
      city_name: "Ndjole",
      country_code: "GA"
  },
  {
      id: "KDL",
      name: "Kardla Airport",
      area_code: "KDL",
      city_name: "Kardla",
      country_code: "EE"
  },
  {
      id: "KDN",
      name: "Ndende",
      area_code: "KDN",
      city_name: "Ndende",
      country_code: "GA"
  },
  {
      id: "KDP",
      name: "Kandep",
      area_code: "KDP",
      city_name: "Kandep",
      country_code: "PG"
  },
  {
      id: "KDQ",
      name: "Kamberatoro",
      area_code: "KDQ",
      city_name: "Kamberatoro",
      country_code: "PG"
  },
  {
      id: "KDR",
      name: "Kandrian",
      area_code: "KDR",
      city_name: "Kandrian",
      country_code: "PG"
  },
  {
      id: "KDS",
      name: "Kamaran Downs",
      area_code: "KDS",
      city_name: "Kamaran Downs",
      country_code: "AU"
  },
  {
      id: "KDT",
      name: "Kamphaeng Saen",
      area_code: "KDT",
      city_name: "Kamphaeng Saen",
      country_code: "TH"
  },
  {
      id: "KDX",
      name: "Kadugli",
      area_code: "KDX",
      city_name: "Kadugli",
      country_code: "SD"
  },
  {
      id: "KDY",
      name: "TEPLY KLYUCH AIRPORT",
      area_code: "KDY",
      city_name: "Khandyga",
      country_code: "RU"
  },
  {
      id: "KEC",
      name: "Kasenga",
      area_code: "KEC",
      city_name: "Kasenga",
      country_code: "CD"
  },
  {
      id: "KED",
      name: "Kaedi",
      area_code: "KED",
      city_name: "Kaedi",
      country_code: "MR"
  },
  {
      id: "KEE",
      name: "Kelle",
      area_code: "KEE",
      city_name: "Kelle",
      country_code: "CG"
  },
  {
      id: "KEG",
      name: "Keglsugl",
      area_code: "KEG",
      city_name: "Keglsugl",
      country_code: "PG"
  },
  {
      id: "KEI",
      name: "Kepi",
      area_code: "KEI",
      city_name: "Kepi",
      country_code: "ID"
  },
  {
      id: "KEK",
      name: "EKWOK AIRPORT",
      area_code: "KEK",
      city_name: "Ekwok",
      country_code: "US"
  },
  {
      id: "KEN",
      name: "Kenema",
      area_code: "KEN",
      city_name: "Kenema",
      country_code: "SL"
  },
  {
      id: "KEO",
      name: "Odienne Airport",
      area_code: "KEO",
      city_name: "Odienne",
      country_code: "CI"
  },
  {
      id: "KEQ",
      name: "Kebar",
      area_code: "KEQ",
      city_name: "Kebar",
      country_code: "ID"
  },
  {
      id: "KES",
      name: "Kelsey",
      area_code: "KES",
      city_name: "Kelsey",
      country_code: "CA"
  },
  {
      id: "KEU",
      name: "Keekorok",
      area_code: "MRE",
      city_name: "Maasai Mara",
      country_code: "KE"
  },
  {
      id: "KEV",
      name: "Halli",
      area_code: "KEV",
      city_name: "Halli",
      country_code: "FI"
  },
  {
      id: "KEW",
      name: "Keewaywin",
      area_code: "KEW",
      city_name: "Keewaywin",
      country_code: "CA"
  },
  {
      id: "KEX",
      name: "Kanabea",
      area_code: "KEX",
      city_name: "Kanabea",
      country_code: "PG"
  },
  {
      id: "KEY",
      name: "Kericho",
      area_code: "KEY",
      city_name: "Kericho",
      country_code: "KE"
  },
  {
      id: "KEZ",
      name: "Kelani-Peliyagoda SPB",
      area_code: "CMB",
      city_name: "Colombo",
      country_code: "LK"
  },
  {
      id: "KFE",
      name: "Fortescue Dave Forrest Airport",
      area_code: "KFE",
      city_name: "Cloudbreak",
      country_code: "AU"
  },
  {
      id: "KFG",
      name: "Kalkgurung",
      area_code: "KFG",
      city_name: "Kalkgurung",
      country_code: "AU"
  },
  {
      id: "KFP",
      name: "FALSE PASS AIRPORT",
      area_code: "KFP",
      city_name: "False Pass",
      country_code: "US"
  },
  {
      id: "KGA",
      name: "KANANGA AIRPORT",
      area_code: "KGA",
      city_name: "Kananga",
      country_code: "CD"
  },
  {
      id: "KGB",
      name: "Konge",
      area_code: "KGB",
      city_name: "Konge",
      country_code: "PG"
  },
  {
      id: "KGE",
      name: "Kagau Airport",
      area_code: "KGE",
      city_name: "Kaghau Island",
      country_code: "SB"
  },
  {
      id: "KGG",
      name: "Kedougou",
      area_code: "KGG",
      city_name: "Kedougou",
      country_code: "SN"
  },
  {
      id: "KGH",
      name: "Yongai",
      area_code: "KGH",
      city_name: "Yongai",
      country_code: "PG"
  },
  {
      id: "KGJ",
      name: "Karonga",
      area_code: "KGJ",
      city_name: "Karonga",
      country_code: "MW"
  },
  {
      id: "KGK",
      name: "Koliganek Airport",
      area_code: "KGK",
      city_name: "Koliganek",
      country_code: "US"
  },
  {
      id: "KGM",
      name: "Kungim",
      area_code: "KGM",
      city_name: "Kungim",
      country_code: "PG"
  },
  {
      id: "KGN",
      name: "Kasongo-Lunda",
      area_code: "KGN",
      city_name: "Kasongo-Lunda",
      country_code: "CD"
  },
  {
      id: "KGO",
      name: "Kirovohrad",
      area_code: "KGO",
      city_name: "Kirovohrad",
      country_code: "UA"
  },
  {
      id: "KGR",
      name: "Kulgera Roadhouse",
      area_code: "KGR",
      city_name: "Kulgera",
      country_code: "AU"
  },
  {
      id: "KGU",
      name: "Keningau",
      area_code: "KGU",
      city_name: "Keningau",
      country_code: "MY"
  },
  {
      id: "KGX",
      name: "GRAYLING AIRPORT",
      area_code: "KGX",
      city_name: "Grayling",
      country_code: "US"
  },
  {
      id: "KGY",
      name: "Kingaroy",
      area_code: "KGY",
      city_name: "Kingaroy",
      country_code: "AU"
  },
  {
      id: "KGZ",
      name: "Glacier Creek",
      area_code: "KGZ",
      city_name: "Glacier Creek",
      country_code: "US"
  },
  {
      id: "KHA",
      name: "Khaneh",
      area_code: "KHA",
      city_name: "Khaneh",
      country_code: "IR"
  },
  {
      id: "KHC",
      name: "International",
      area_code: "KHC",
      city_name: "Kerch",
      country_code: "UA"
  },
  {
      id: "KHJ",
      name: "Kauhajoki",
      area_code: "KHJ",
      city_name: "Kauhajoki",
      country_code: "FI"
  },
  {
      id: "KHK",
      name: "Khark Island",
      area_code: "KHK",
      city_name: "Khark Island",
      country_code: "IR"
  },
  {
      id: "KHM",
      name: "Khamti Airport",
      area_code: "KHM",
      city_name: "Khamti",
      country_code: "MM"
  },
  {
      id: "KHO",
      name: "Khoka Moya",
      area_code: "KHO",
      city_name: "Manyeleti",
      country_code: "ZA"
  },
  {
      id: "KHR",
      name: "Kharkhorin",
      area_code: "KHR",
      city_name: "Kharkhorin",
      country_code: "MN"
  },
  {
      id: "KHT",
      name: "Chapman",
      area_code: "KHT",
      city_name: "Khost",
      country_code: "AF"
  },
  {
      id: "KHU",
      name: "Kremenchuk",
      area_code: "KHU",
      city_name: "Kremenchuk",
      country_code: "UA"
  },
  {
      id: "KHW",
      name: "Khwai River Lodge",
      area_code: "KHW",
      city_name: "Khwai River Lodge",
      country_code: "BW"
  },
  {
      id: "KHX",
      name: "Kihihi Airstrip",
      area_code: "KHX",
      city_name: "Kihihi",
      country_code: "UG"
  },
  {
      id: "KHY",
      name: "Khoy Airport",
      area_code: "KHY",
      city_name: "Khoy",
      country_code: "IR"
  },
  {
      id: "KHZ",
      name: "Kauehi Airport",
      area_code: "KHZ",
      city_name: "Kauehi",
      country_code: "PF"
  },
  {
      id: "KIA",
      name: "Kaiapit",
      area_code: "KIA",
      city_name: "Kaiapit",
      country_code: "PG"
  },
  {
      id: "KIB",
      name: "Sea Plane Base",
      area_code: "KIB",
      city_name: "Ivanof Bay",
      country_code: "US"
  },
  {
      id: "KIE",
      name: "Aropa Airport",
      area_code: "KIE",
      city_name: "Kieta",
      country_code: "PG"
  },
  {
      id: "KIG",
      name: "Koingnaas",
      area_code: "KIG",
      city_name: "Koingnaas",
      country_code: "ZA"
  },
  {
      id: "KIH",
      name: "KISH ISLAND AIRPORT",
      area_code: "KIH",
      city_name: "Kish Island",
      country_code: "IR"
  },
  {
      id: "KII",
      name: "Kibuli",
      area_code: "KII",
      city_name: "Kibuli",
      country_code: "PG"
  },
  {
      id: "KIK",
      name: "Air Base",
      area_code: "KIK",
      city_name: "Kirkuk",
      country_code: "IQ"
  },
  {
      id: "KIL",
      name: "Kilwa",
      area_code: "KIL",
      city_name: "Kilwa",
      country_code: "CD"
  },
  {
      id: "KIO",
      name: "Kili Island",
      area_code: "KIO",
      city_name: "Kili Island",
      country_code: "MH"
  },
  {
      id: "KIQ",
      name: "Kira",
      area_code: "KIQ",
      city_name: "Kira",
      country_code: "PG"
  },
  {
      id: "KIU",
      name: "Kiunga",
      area_code: "KIU",
      city_name: "Kiunga",
      country_code: "KE"
  },
  {
      id: "KIW",
      name: "Southdowns",
      area_code: "KIW",
      city_name: "Kitwe",
      country_code: "ZM"
  },
  {
      id: "KIY",
      name: "Kilwa Airport",
      area_code: "KIY",
      city_name: "Kilwa Masoko",
      country_code: "TZ"
  },
  {
      id: "KIZ",
      name: "Kikinonda",
      area_code: "KIZ",
      city_name: "Kikinonda",
      country_code: "PG"
  },
  {
      id: "KJP",
      name: "Kerama",
      area_code: "KJP",
      city_name: "Kerama",
      country_code: "JP"
  },
  {
      id: "KJT",
      name: "Kertajati Intl",
      area_code: "KJT",
      city_name: "Majalengka",
      country_code: "ID"
  },
  {
      id: "KJU",
      name: "Kamiraba",
      area_code: "KJU",
      city_name: "Kamiraba",
      country_code: "PG"
  },
  {
      id: "KJX",
      name: "Kuala Batu",
      area_code: "KJX",
      city_name: "Blang Pidie",
      country_code: "ID"
  },
  {
      id: "KKB",
      name: "Sea Plane Base",
      area_code: "KKB",
      city_name: "Kitoi Bay",
      country_code: "US"
  },
  {
      id: "KKG",
      name: "Konawaruk",
      area_code: "KKG",
      city_name: "Konawaruk",
      country_code: "GY"
  },
  {
      id: "KKH",
      name: "KONGIGANAK AIRPORT",
      area_code: "KKH",
      city_name: "Kongiganak",
      country_code: "US"
  },
  {
      id: "KKM",
      name: "Khok Kathiam",
      area_code: "KKM",
      city_name: "Lop Buri",
      country_code: "TH"
  },
  {
      id: "KKO",
      name: "Kaikohe",
      area_code: "KKO",
      city_name: "Kaikohe",
      country_code: "NZ"
  },
  {
      id: "KKP",
      name: "Koolburra",
      area_code: "KKP",
      city_name: "Koolburra",
      country_code: "AU"
  },
  {
      id: "KKQ",
      name: "Krasnoselkup",
      area_code: "KKQ",
      city_name: "Krasnoselkup",
      country_code: "RU"
  },
  {
      id: "KKR",
      name: "Kaukura Atoll Airport",
      area_code: "KKR",
      city_name: "Kaukura",
      country_code: "PF"
  },
  {
      id: "KKS",
      name: "Kashan Airport",
      area_code: "KKS",
      city_name: "Kashan",
      country_code: "IR"
  },
  {
      id: "KKU",
      name: "Ekuk",
      area_code: "KKU",
      city_name: "Ekuk",
      country_code: "US"
  },
  {
      id: "KKW",
      name: "Kikwit",
      area_code: "KKW",
      city_name: "Kikwit",
      country_code: "CD"
  },
  {
      id: "KKX",
      name: "Kikai",
      area_code: "KKX",
      city_name: "Kikai",
      country_code: "JP"
  },
  {
      id: "KKY",
      name: "Kilkenny",
      area_code: "KKY",
      city_name: "Kilkenny",
      country_code: "IE"
  },
  {
      id: "KLB",
      name: "Kalabo",
      area_code: "KLB",
      city_name: "Kalabo",
      country_code: "ZM"
  },
  {
      id: "KLC",
      name: "Kaolack",
      area_code: "KLC",
      city_name: "Kaolack",
      country_code: "SN"
  },
  {
      id: "KLE",
      name: "Kaele",
      area_code: "KLE",
      city_name: "Kaele",
      country_code: "CM"
  },
  {
      id: "KLI",
      name: "Kotakoli",
      area_code: "KLI",
      city_name: "Kotakoli",
      country_code: "CD"
  },
  {
      id: "KLK",
      name: "Fergusons Gulf",
      area_code: "KLK",
      city_name: "Kalokol",
      country_code: "KE"
  },
  {
      id: "KLL",
      name: "LEVELOCK AIRPORT",
      area_code: "KLL",
      city_name: "Levelock",
      country_code: "US"
  },
  {
      id: "KLM",
      name: "Kalaleh",
      area_code: "KLM",
      city_name: "Kalaleh",
      country_code: "IR"
  },
  {
      id: "KLQ",
      name: "Keluang",
      area_code: "KLQ",
      city_name: "Keluang",
      country_code: "ID"
  },
  {
      id: "KLY",
      name: "Kalima",
      area_code: "KLY",
      city_name: "Kalima",
      country_code: "CD"
  },
  {
      id: "KLZ",
      name: "Kleinsee",
      area_code: "KLZ",
      city_name: "Kleinsee",
      country_code: "ZA"
  },
  {
      id: "KMB",
      name: "Koinambe",
      area_code: "KMB",
      city_name: "Koinambe",
      country_code: "PG"
  },
  {
      id: "KMF",
      name: "Kamina",
      area_code: "KMF",
      city_name: "Kamina",
      country_code: "PG"
  },
  {
      id: "KMK",
      name: "Makabana",
      area_code: "KMK",
      city_name: "Makabana",
      country_code: "CG"
  },
  {
      id: "KML",
      name: "Kamileroi",
      area_code: "KML",
      city_name: "Kamileroi",
      country_code: "AU"
  },
  {
      id: "KMM",
      name: "Kimam",
      area_code: "KMM",
      city_name: "Kimam",
      country_code: "ID"
  },
  {
      id: "KMN",
      name: "Kamina-Ville",
      area_code: "KMN",
      city_name: "Kamina",
      country_code: "CD"
  },
  {
      id: "KMO",
      name: "Manokotak Airport",
      area_code: "KMO",
      city_name: "Manokotak",
      country_code: "US"
  },
  {
      id: "KMP",
      name: "Keetmanshoop",
      area_code: "KMP",
      city_name: "Keetmanshoop",
      country_code: "ZA"
  },
  {
      id: "KMR",
      name: "Karimui",
      area_code: "KMR",
      city_name: "Karimui",
      country_code: "PG"
  },
  {
      id: "KMT",
      name: "Kampot",
      area_code: "KMT",
      city_name: "Kampot",
      country_code: "KH"
  },
  {
      id: "KMU",
      name: "Kisimayu Airport",
      area_code: "KMU",
      city_name: "Kisimayu",
      country_code: "SO"
  },
  {
      id: "KMZ",
      name: "Kaoma",
      area_code: "KMZ",
      city_name: "Kaoma",
      country_code: "ZM"
  },
  {
      id: "KNB",
      name: "Municipal",
      area_code: "KNB",
      city_name: "Kanab",
      country_code: "US"
  },
  {
      id: "KNE",
      name: "Kanainj",
      area_code: "KNE",
      city_name: "Kanainj",
      country_code: "PG"
  },
  {
      id: "KNI",
      name: "Katanning",
      area_code: "KNI",
      city_name: "Katanning",
      country_code: "AU"
  },
  {
      id: "KNJ",
      name: "Kindamba",
      area_code: "KNJ",
      city_name: "Kindamba",
      country_code: "CG"
  },
  {
      id: "KNL",
      name: "Kelanoa",
      area_code: "KNL",
      city_name: "Kelanoa",
      country_code: "PG"
  },
  {
      id: "KNM",
      name: "Kaniama",
      area_code: "KNM",
      city_name: "Kaniama",
      country_code: "CD"
  },
  {
      id: "KNN",
      name: "Diankana",
      area_code: "KNN",
      city_name: "Kankan",
      country_code: "GN"
  },
  {
      id: "KNP",
      name: "Capanda",
      area_code: "KNP",
      city_name: "Capanda",
      country_code: "AO"
  },
  {
      id: "KNQ",
      name: "KONE AIRPORT",
      area_code: "KNQ",
      city_name: "Kone",
      country_code: "NC"
  },
  {
      id: "KNR",
      name: "Jam",
      area_code: "KNR",
      city_name: "Kangan",
      country_code: "IR"
  },
  {
      id: "KNW",
      name: "NEW STUYAHOK AIRPORT",
      area_code: "KNW",
      city_name: "New Stuyahok",
      country_code: "US"
  },
  {
      id: "KNZ",
      name: "Kenieba",
      area_code: "KNZ",
      city_name: "Kenieba",
      country_code: "ML"
  },
  {
      id: "KOD",
      name: "Kotabangun",
      area_code: "KOD",
      city_name: "Kotabangun",
      country_code: "ID"
  },
  {
      id: "KOG",
      name: "Khong",
      area_code: "KOG",
      city_name: "Khong",
      country_code: "LA"
  },
  {
      id: "KOH",
      name: "Koolatah",
      area_code: "KOH",
      city_name: "Koolatah",
      country_code: "AU"
  },
  {
      id: "KOL",
      name: "Koumala",
      area_code: "KOL",
      city_name: "Koumala",
      country_code: "CF"
  },
  {
      id: "KOM",
      name: "Komo",
      area_code: "KOM",
      city_name: "Komo",
      country_code: "PG"
  },
  {
      id: "KON",
      name: "Kon Tum",
      area_code: "KON",
      city_name: "Kon Tum",
      country_code: "VN"
  },
  {
      id: "KOO",
      name: "Kongolo Airport",
      area_code: "KOO",
      city_name: "Kongolo",
      country_code: "CD"
  },
  {
      id: "KOQ",
      name: "Koethen",
      area_code: "KOQ",
      city_name: "Koethen",
      country_code: "DE"
  },
  {
      id: "KOT",
      name: "KOTLIK AIRPORT",
      area_code: "KOT",
      city_name: "Kotlik",
      country_code: "US"
  },
  {
      id: "KOU",
      name: "Koulamoutou",
      area_code: "KOU",
      city_name: "Koulamoutou",
      country_code: "GA"
  },
  {
      id: "KOX",
      name: "Kokonao",
      area_code: "KOX",
      city_name: "Kokonao",
      country_code: "ID"
  },
  {
      id: "KOY",
      name: "Sea Plane Base",
      area_code: "KOY",
      city_name: "Olga Bay",
      country_code: "US"
  },
  {
      id: "KOZ",
      name: "Ouzinkie Airport",
      area_code: "KOZ",
      city_name: "Ouzinkie",
      country_code: "US"
  },
  {
      id: "KPA",
      name: "Kopiago",
      area_code: "KPA",
      city_name: "Kopiago",
      country_code: "PG"
  },
  {
      id: "KPC",
      name: "Coast Guard Station",
      area_code: "KPC",
      city_name: "Port Clarence",
      country_code: "US"
  },
  {
      id: "KPE",
      name: "Yapsiei",
      area_code: "KPE",
      city_name: "Yapsiei",
      country_code: "PG"
  },
  {
      id: "KPF",
      name: "Kondubol",
      area_code: "KPF",
      city_name: "Kondubol",
      country_code: "PG"
  },
  {
      id: "KPG",
      name: "Kurupung",
      area_code: "KPG",
      city_name: "Kurupung",
      country_code: "GY"
  },
  {
      id: "KPI",
      name: "Kapit",
      area_code: "KPI",
      city_name: "Kapit",
      country_code: "MY"
  },
  {
      id: "KPL",
      name: "Kapal",
      area_code: "KPL",
      city_name: "Kapal",
      country_code: "PG"
  },
  {
      id: "KPM",
      name: "Kompiam",
      area_code: "KPM",
      city_name: "Kompiam",
      country_code: "PG"
  },
  {
      id: "KPP",
      name: "Kalpowar",
      area_code: "KPP",
      city_name: "Kalpowar",
      country_code: "AU"
  },
  {
      id: "KPR",
      name: "Sea Plane Base",
      area_code: "KPR",
      city_name: "Port Williams",
      country_code: "US"
  },
  {
      id: "KPT",
      name: "Hayden Field",
      area_code: "KPT",
      city_name: "Jackpot",
      country_code: "US"
  },
  {
      id: "KPW",
      name: "Keperveyem",
      area_code: "KPW",
      city_name: "Keperveyem",
      country_code: "RU"
  },
  {
      id: "KPY",
      name: "Sea Plane Base",
      area_code: "KPY",
      city_name: "Port Bailey",
      country_code: "US"
  },
  {
      id: "KQA",
      name: "Akutan",
      area_code: "KQA",
      city_name: "Bresciaa",
      country_code: "IT"
  },
  {
      id: "KQH",
      name: "Ajmer",
      area_code: "KQH",
      city_name: "Kishangarh",
      country_code: "IN"
  },
  {
      id: "KQL",
      name: "Kol",
      area_code: "KQL",
      city_name: "Kol",
      country_code: "PG"
  },
  {
      id: "KQR",
      name: "Karara",
      area_code: "KQR",
      city_name: "Karara",
      country_code: "AU"
  },
  {
      id: "KRA",
      name: "Kerang",
      area_code: "KRA",
      city_name: "Kerang",
      country_code: "AU"
  },
  {
      id: "KRC",
      name: "Kerinci",
      area_code: "KRC",
      city_name: "Kerinci",
      country_code: "ID"
  },
  {
      id: "KRD",
      name: "Kurundi Station",
      area_code: "KRD",
      city_name: "Kurundi Station",
      country_code: "AU"
  },
  {
      id: "KRE",
      name: "Kirundo",
      area_code: "KRE",
      city_name: "Kirundo",
      country_code: "BI"
  },
  {
      id: "KRJ",
      name: "Karawari",
      area_code: "KRJ",
      city_name: "Karawari",
      country_code: "PG"
  },
  {
      id: "KRM",
      name: "Karanambo",
      area_code: "KRM",
      city_name: "Karanambo",
      country_code: "GY"
  },
  {
      id: "KRQ",
      name: "Kramatorsk",
      area_code: "KRQ",
      city_name: "Kramatorsk",
      country_code: "UA"
  },
  {
      id: "KRU",
      name: "Kerau",
      area_code: "KRU",
      city_name: "Kerau",
      country_code: "PG"
  },
  {
      id: "KRV",
      name: "Kerio Valley",
      area_code: "KRV",
      city_name: "Kimwarer",
      country_code: "KE"
  },
  {
      id: "KRX",
      name: "Karkar Island",
      area_code: "KRX",
      city_name: "Karkar Island",
      country_code: "PG"
  },
  {
      id: "KRZ",
      name: "Kiri",
      area_code: "KRZ",
      city_name: "Kiri",
      country_code: "CD"
  },
  {
      id: "KSB",
      name: "Kasanombe",
      area_code: "KSB",
      city_name: "Kasanombe",
      country_code: "PG"
  },
  {
      id: "KSE",
      name: "KASESE AIRPORT",
      area_code: "KSE",
      city_name: "Kasese",
      country_code: "UG"
  },
  {
      id: "KSG",
      name: "Kisengam",
      area_code: "KSG",
      city_name: "Kisengam",
      country_code: "PG"
  },
  {
      id: "KSI",
      name: "Kissidougou",
      area_code: "KSI",
      city_name: "Kissidougou",
      country_code: "GN"
  },
  {
      id: "KSK",
      name: "Karlskoga",
      area_code: "KSK",
      city_name: "Karlskoga",
      country_code: "SE"
  },
  {
      id: "KSL",
      name: "Kassala Airport",
      area_code: "KSL",
      city_name: "Kassala",
      country_code: "SD"
  },
  {
      id: "KSM",
      name: "Saint Mary's Airport",
      area_code: "KSM",
      city_name: "St Mary's",
      country_code: "US"
  },
  {
      id: "KSP",
      name: "Kosipe",
      area_code: "KSP",
      city_name: "Kosipe",
      country_code: "PG"
  },
  {
      id: "KSR",
      name: "H. Aroeppala",
      area_code: "KSR",
      city_name: "Selayar Islands",
      country_code: "ID"
  },
  {
      id: "KSS",
      name: "Dignangan",
      area_code: "KSS",
      city_name: "Sikasso",
      country_code: "ML"
  },
  {
      id: "KST",
      name: "Rabak",
      area_code: "KST",
      city_name: "Kosti",
      country_code: "SD"
  },
  {
      id: "KSV",
      name: "Springvale",
      area_code: "KSV",
      city_name: "Springvale",
      country_code: "AU"
  },
  {
      id: "KSX",
      name: "Yasuru",
      area_code: "KSX",
      city_name: "Yasuru",
      country_code: "PG"
  },
  {
      id: "KTB",
      name: "Thorne Bay Seaplane Base",
      area_code: "KTB",
      city_name: "Thorne Bay",
      country_code: "US"
  },
  {
      id: "KTC",
      name: "Katiola",
      area_code: "KTC",
      city_name: "Katiola",
      country_code: "CI"
  },
  {
      id: "KTD",
      name: "Kitadaito",
      area_code: "KTD",
      city_name: "Kitadaito",
      country_code: "JP"
  },
  {
      id: "KTF",
      name: "Takaka",
      area_code: "KTF",
      city_name: "Takaka",
      country_code: "NZ"
  },
  {
      id: "KTH",
      name: "Tikchik Lodge SPB",
      area_code: "KTH",
      city_name: "Tikchik",
      country_code: "US"
  },
  {
      id: "KTI",
      name: "Kratie",
      area_code: "KTI",
      city_name: "Kratie",
      country_code: "KH"
  },
  {
      id: "KTJ",
      name: "Kichwa Tembo",
      area_code: "MRE",
      city_name: "Maasai Mara",
      country_code: "KE"
  },
  {
      id: "KTK",
      name: "Kunua",
      area_code: "KTK",
      city_name: "Kunua",
      country_code: "PG"
  },
  {
      id: "KTO",
      name: "Kato",
      area_code: "KTO",
      city_name: "Kato",
      country_code: "GY"
  },
  {
      id: "KTQ",
      name: "Kitee",
      area_code: "KTQ",
      city_name: "Kitee",
      country_code: "FI"
  },
  {
      id: "KTV",
      name: "Kamarata",
      area_code: "KTV",
      city_name: "Kamarata",
      country_code: "VE"
  },
  {
      id: "KTX",
      name: "Koutiala",
      area_code: "KTX",
      city_name: "Koutiala",
      country_code: "ML"
  },
  {
      id: "KTY",
      name: "Katukurunda",
      area_code: "KTY",
      city_name: "Katukurunda",
      country_code: "LK"
  },
  {
      id: "KUC",
      name: "Kuria",
      area_code: "KUC",
      city_name: "Kuria",
      country_code: "KI"
  },
  {
      id: "KUE",
      name: "Kukundu",
      area_code: "KUE",
      city_name: "Kukundu",
      country_code: "SB"
  },
  {
      id: "KUG",
      name: "Kubin",
      area_code: "KUG",
      city_name: "Kubin",
      country_code: "AU"
  },
  {
      id: "KUP",
      name: "Kupiano",
      area_code: "KUP",
      city_name: "Kupiano",
      country_code: "PG"
  },
  {
      id: "KUQ",
      name: "Kuri",
      area_code: "KUQ",
      city_name: "Kuri",
      country_code: "PG"
  },
  {
      id: "KUR",
      name: "Razer",
      area_code: "KUR",
      city_name: "Kiran wa Munjan",
      country_code: "AF"
  },
  {
      id: "KUX",
      name: "Kuyol",
      area_code: "KUX",
      city_name: "Kuyol",
      country_code: "PG"
  },
  {
      id: "KUY",
      name: "Kamusi",
      area_code: "KUY",
      city_name: "Kamusi",
      country_code: "PG"
  },
  {
      id: "KVC",
      name: "KING COVE AIRPORT",
      area_code: "KVC",
      city_name: "King Cove",
      country_code: "US"
  },
  {
      id: "KVE",
      name: "Kitava Island",
      area_code: "KVE",
      city_name: "Kitava Island",
      country_code: "PG"
  },
  {
      id: "KVL",
      name: "KIVALINA AIRPORT",
      area_code: "KVL",
      city_name: "Kivalina",
      country_code: "US"
  },
  {
      id: "KVM",
      name: "Markovo",
      area_code: "KVM",
      city_name: "Markovo",
      country_code: "RU"
  },
  {
      id: "KVR",
      name: "Kavalerovo Airport",
      area_code: "KVR",
      city_name: "Kavalerovo",
      country_code: "RU"
  },
  {
      id: "KVU",
      name: "SPB",
      area_code: "KVU",
      city_name: "Korolevu",
      country_code: "FJ"
  },
  {
      id: "KWB",
      name: "Dewadaru",
      area_code: "KWB",
      city_name: "Karimunjawa",
      country_code: "ID"
  },
  {
      id: "KWD",
      name: "Kavadja",
      area_code: "KWD",
      city_name: "Kavadja",
      country_code: "CF"
  },
  {
      id: "KWH",
      name: "Khwahan",
      area_code: "KWH",
      city_name: "Khwahan",
      country_code: "AF"
  },
  {
      id: "KWK",
      name: "KWIGILLINGOK AIRPORT",
      area_code: "KWK",
      city_name: "Kwigillingok",
      country_code: "US"
  },
  {
      id: "KWM",
      name: "KOWANYAMA AIRPORT",
      area_code: "KWM",
      city_name: "KOWANYAMA",
      country_code: "AU"
  },
  {
      id: "KWN",
      name: "Quinhagak Airport",
      area_code: "KWN",
      city_name: "Quinhagak",
      country_code: "US"
  },
  {
      id: "KWO",
      name: "Kawito",
      area_code: "KWO",
      city_name: "Kawito",
      country_code: "PG"
  },
  {
      id: "KWP",
      name: "West Point Village SPB",
      area_code: "KWP",
      city_name: "West Point",
      country_code: "US"
  },
  {
      id: "KWR",
      name: "Kwai Harbour",
      area_code: "KWR",
      city_name: "Kwai Harbour",
      country_code: "SB"
  },
  {
      id: "KWS",
      name: "Kwailibesi",
      area_code: "KWS",
      city_name: "Kwailibesi",
      country_code: "SB"
  },
  {
      id: "KWT",
      name: "KWETHLUK AIRPORT",
      area_code: "KWT",
      city_name: "Kwethluk",
      country_code: "US"
  },
  {
      id: "KWV",
      name: "Kurwina",
      area_code: "KWV",
      city_name: "Kurwina",
      country_code: "PG"
  },
  {
      id: "KWX",
      name: "Kiwai Island",
      area_code: "KWX",
      city_name: "Kiwai Island",
      country_code: "PG"
  },
  {
      id: "KWY",
      name: "Kiwayu",
      area_code: "KWY",
      city_name: "Kiwayu",
      country_code: "KE"
  },
  {
      id: "KWZ",
      name: "Kolwezi",
      area_code: "KWZ",
      city_name: "Kolwezi",
      country_code: "CD"
  },
  {
      id: "KXA",
      name: "Kasaan Seaplane Base",
      area_code: "KXA",
      city_name: "Kasaan",
      country_code: "US"
  },
  {
      id: "KXD",
      name: "Kondinskoye",
      area_code: "KXD",
      city_name: "Kondinskoye",
      country_code: "RU"
  },
  {
      id: "KXE",
      name: "P.C. Pelser",
      area_code: "KXE",
      city_name: "Klerksdorp",
      country_code: "ZA"
  },
  {
      id: "KXR",
      name: "Karoola",
      area_code: "KXR",
      city_name: "Karoola",
      country_code: "PG"
  },
  {
      id: "KXU",
      name: "KATIU AIRPORT",
      area_code: "KXU",
      city_name: "Katiu",
      country_code: "PF"
  },
  {
      id: "KYD",
      name: "Lanyu (Orchid Island)",
      area_code: "KYD",
      city_name: "Lanyu (Orchid Island)",
      country_code: "CN"
  },
  {
      id: "KYE",
      name: "Rene Mouawad AB",
      area_code: "KYE",
      city_name: "Tripoli",
      country_code: "LB"
  },
  {
      id: "KYF",
      name: "Yeelirrie",
      area_code: "KYF",
      city_name: "Yeelirrie",
      country_code: "AU"
  },
  {
      id: "KYI",
      name: "Yalata Mission",
      area_code: "KYI",
      city_name: "Yalata Mission",
      country_code: "AU"
  },
  {
      id: "KYK",
      name: "KARLUK AIRPORT",
      area_code: "KYK",
      city_name: "Karluk",
      country_code: "US"
  },
  {
      id: "KYS",
      name: "Dag-Dag",
      area_code: "KYS",
      city_name: "Kayes",
      country_code: "ML"
  },
  {
      id: "KYT",
      name: "Kyauktu",
      area_code: "KYT",
      city_name: "Kyauktu",
      country_code: "MM"
  },
  {
      id: "KYU",
      name: "KOYUKUK AIRPORT",
      area_code: "KYU",
      city_name: "Koyukuk",
      country_code: "US"
  },
  {
      id: "KYX",
      name: "Yalumet",
      area_code: "KYX",
      city_name: "Yalumet",
      country_code: "PG"
  },
  {
      id: "KYZ",
      name: "KYZYL AIRPORT",
      area_code: "KYZ",
      city_name: "Kyzyl",
      country_code: "RU"
  },
  {
      id: "KZB",
      name: "Sea Plane Base",
      area_code: "KZB",
      city_name: "Zachar Bay",
      country_code: "US"
  },
  {
      id: "KZC",
      name: "Kampong Chhnang",
      area_code: "KZC",
      city_name: "Kampong Chhnang",
      country_code: "KH"
  },
  {
      id: "KZD",
      name: "Krakor",
      area_code: "KZD",
      city_name: "Krakor",
      country_code: "KH"
  },
  {
      id: "KZF",
      name: "Kaintiba",
      area_code: "KZF",
      city_name: "Kaintiba",
      country_code: "PG"
  },
  {
      id: "KZG",
      name: "Kitzingen",
      area_code: "KZG",
      city_name: "Kitzingen",
      country_code: "DE"
  },
  {
      id: "KZI",
      name: "Philippos Airport",
      area_code: "KZI",
      city_name: "Kozani",
      country_code: "GR"
  },
  {
      id: "LAB",
      name: "Lab Lab",
      area_code: "LAB",
      city_name: "Lab Lab",
      country_code: "PG"
  },
  {
      id: "LAC",
      name: "Layang-Layang Island",
      area_code: "LAC",
      city_name: "Layang-Layang Island",
      country_code: "MY"
  },
  {
      id: "LAH",
      name: "LABUHA AIRPORT",
      area_code: "LAH",
      city_name: "Labuha",
      country_code: "ID"
  },
  {
      id: "LAK",
      name: "Aklavik Airport",
      area_code: "LAK",
      city_name: "Aklavik",
      country_code: "CA"
  },
  {
      id: "LAZ",
      name: "Bom Jesus da Lapa",
      area_code: "LAZ",
      city_name: "Bom Jesus da Lapa",
      country_code: "BR"
  },
  {
      id: "LBG",
      name: "Le Bourget",
      area_code: "PAR",
      city_name: "Paris",
      country_code: "FR"
  },
  {
      id: "LBK",
      name: "Liboi",
      area_code: "LBK",
      city_name: "Liboi",
      country_code: "KE"
  },
  {
      id: "LBM",
      name: "Luabo",
      area_code: "LBM",
      city_name: "Luabo",
      country_code: "MZ"
  },
  {
      id: "LBN",
      name: "Lake Baringo",
      area_code: "LBN",
      city_name: "Lake Baringo",
      country_code: "KE"
  },
  {
      id: "LBO",
      name: "Lusambo",
      area_code: "LBO",
      city_name: "Lusambo",
      country_code: "CD"
  },
  {
      id: "LBQ",
      name: "Lambarene",
      area_code: "LBQ",
      city_name: "Lambarene",
      country_code: "GA"
  },
  {
      id: "LBR",
      name: "Labrea",
      area_code: "LBR",
      city_name: "Labrea",
      country_code: "BR"
  },
  {
      id: "LBW",
      name: "Yuvai Semaring",
      area_code: "LBW",
      city_name: "Long Bawan",
      country_code: "ID"
  },
  {
      id: "LBZ",
      name: "Lucapa",
      area_code: "LBZ",
      city_name: "Lucapa",
      country_code: "AO"
  },
  {
      id: "LCB",
      name: "Pontes e Lacerda",
      area_code: "LCB",
      city_name: "Pontes e Lacerda",
      country_code: "BR"
  },
  {
      id: "LCD",
      name: "Louis Trichardt",
      area_code: "LCD",
      city_name: "Louis Trichardt",
      country_code: "ZA"
  },
  {
      id: "LCL",
      name: "La Coloma",
      area_code: "LCL",
      city_name: "Pinar del Rio",
      country_code: "CU"
  },
  {
      id: "LCM",
      name: "La Cumbre",
      area_code: "LCM",
      city_name: "La Cumbre",
      country_code: "AR"
  },
  {
      id: "LCN",
      name: "Balcanoona",
      area_code: "LCN",
      city_name: "Balcanoona",
      country_code: "AU"
  },
  {
      id: "LCO",
      name: "Lage",
      area_code: "LCO",
      city_name: "Lage",
      country_code: "CG"
  },
  {
      id: "LCP",
      name: "Loncopue",
      area_code: "LCP",
      city_name: "Loncopue",
      country_code: "AR"
  },
  {
      id: "LCQ",
      name: "Lake City Gateway",
      area_code: "LCQ",
      city_name: "Lake City",
      country_code: "US"
  },
  {
      id: "LDC",
      name: "Lindeman Island",
      area_code: "LDC",
      city_name: "Lindeman Island",
      country_code: "AU"
  },
  {
      id: "LDM",
      name: "Mason County",
      area_code: "LDM",
      city_name: "Ludington",
      country_code: "US"
  },
  {
      id: "LDN",
      name: "Lamidanda",
      area_code: "LDN",
      city_name: "Lamidanda",
      country_code: "NP"
  },
  {
      id: "LDO",
      name: "Laduani",
      area_code: "LDO",
      city_name: "Aurora",
      country_code: "SR"
  },
  {
      id: "LDR",
      name: "Lawdar",
      area_code: "LDR",
      city_name: "Lawdar",
      country_code: "YE"
  },
  {
      id: "LDV",
      name: "Landivisiau",
      area_code: "LDV",
      city_name: "Landivisiau",
      country_code: "FR"
  },
  {
      id: "LDW",
      name: "Lansdowne Station",
      area_code: "LDW",
      city_name: "Lansdowne Station",
      country_code: "AU"
  },
  {
      id: "LDZ",
      name: "Londolozi Airport",
      area_code: "LDZ",
      city_name: "Londolozi",
      country_code: "ZA"
  },
  {
      id: "LEF",
      name: "Lebakeng",
      area_code: "LEF",
      city_name: "Lebakeng",
      country_code: "LS"
  },
  {
      id: "LEG",
      name: "Aleg",
      area_code: "LEG",
      city_name: "Aleg",
      country_code: "MR"
  },
  {
      id: "LEK",
      name: "Tata",
      area_code: "LEK",
      city_name: "Labe",
      country_code: "GN"
  },
  {
      id: "LEL",
      name: "Lake Evella",
      area_code: "LEL",
      city_name: "Gapuwiyak",
      country_code: "AU"
  },
  {
      id: "LEM",
      name: "Municipal",
      area_code: "LEM",
      city_name: "Lemmon",
      country_code: "US"
  },
  {
      id: "LEO",
      name: "Lekoni",
      area_code: "LEO",
      city_name: "Lekoni",
      country_code: "GA"
  },
  {
      id: "LEP",
      name: "Leopoldina",
      area_code: "LEP",
      city_name: "Leopoldina",
      country_code: "BR"
  },
  {
      id: "LEU",
      name: "Seo de Urgel",
      area_code: "LEU",
      city_name: "Seo de Urgel",
      country_code: "ES"
  },
  {
      id: "LEZ",
      name: "La Esperanza",
      area_code: "LEZ",
      city_name: "La Esperanza",
      country_code: "HN"
  },
  {
      id: "LFB",
      name: "Lumbo",
      area_code: "LFB",
      city_name: "Lumbo",
      country_code: "MZ"
  },
  {
      id: "LFM",
      name: "LAMERD AIRPORT",
      area_code: "LFM",
      city_name: "Lamerd",
      country_code: "IR"
  },
  {
      id: "LFN",
      name: "Triangle North Exec.",
      area_code: "LFN",
      city_name: "Louisburg",
      country_code: "US"
  },
  {
      id: "LFO",
      name: "Kelafo",
      area_code: "LFO",
      city_name: "Kelafo",
      country_code: "ET"
  },
  {
      id: "LFP",
      name: "Lakefield",
      area_code: "LFP",
      city_name: "Lakefield",
      country_code: "AU"
  },
  {
      id: "LFR",
      name: "LA FRIA AIRPORT",
      area_code: "LFR",
      city_name: "La Fria",
      country_code: "VE"
  },
  {
      id: "LGE",
      name: "Mulan",
      area_code: "LGE",
      city_name: "Lake Gregory",
      country_code: "AU"
  },
  {
      id: "LGM",
      name: "Laiagam",
      area_code: "LGM",
      city_name: "Laiagam",
      country_code: "PG"
  },
  {
      id: "LGN",
      name: "Linga Linga",
      area_code: "LGN",
      city_name: "Linga Linga",
      country_code: "PG"
  },
  {
      id: "LGO",
      name: "Langeoog",
      area_code: "LGO",
      city_name: "Langeoog",
      country_code: "DE"
  },
  {
      id: "LGR",
      name: "Cochrane",
      area_code: "LGR",
      city_name: "Cochrane",
      country_code: "CL"
  },
  {
      id: "LGT",
      name: "Las Gaviotas",
      area_code: "LGT",
      city_name: "Las Gaviotas",
      country_code: "CO"
  },
  {
      id: "LGX",
      name: "Lugh Ganane",
      area_code: "LGX",
      city_name: "Lugh Ganane",
      country_code: "SO"
  },
  {
      id: "LGY",
      name: "Lagunillas",
      area_code: "LGY",
      city_name: "Lagunillas",
      country_code: "VE"
  },
  {
      id: "LHI",
      name: "Lereh",
      area_code: "LHI",
      city_name: "Lereh",
      country_code: "ID"
  },
  {
      id: "LHP",
      name: "Lehu",
      area_code: "LHP",
      city_name: "Lehu",
      country_code: "PG"
  },
  {
      id: "LHS",
      name: "Las Heras",
      area_code: "LHS",
      city_name: "Las Heras",
      country_code: "AR"
  },
  {
      id: "LHU",
      name: "Lianshulu",
      area_code: "LHU",
      city_name: "Lianshulu",
      country_code: "NA"
  },
  {
      id: "LHV",
      name: "Lock Haven",
      area_code: "LHV",
      city_name: "Lock Haven",
      country_code: "US"
  },
  {
      id: "LIB",
      name: "Limbunya Station",
      area_code: "LIB",
      city_name: "Limbunya Station",
      country_code: "AU"
  },
  {
      id: "LIE",
      name: "Libenge",
      area_code: "LIE",
      city_name: "Libenge",
      country_code: "CD"
  },
  {
      id: "LII",
      name: "Mulia",
      area_code: "LII",
      city_name: "Mulia",
      country_code: "ID"
  },
  {
      id: "LIK",
      name: "Likiep Island",
      area_code: "LIK",
      city_name: "Likiep Island",
      country_code: "MH"
  },
  {
      id: "LIP",
      name: "Lucas Nogueira Garcez",
      area_code: "LIP",
      city_name: "Lins",
      country_code: "BR"
  },
  {
      id: "LIQ",
      name: "Lisala",
      area_code: "LIQ",
      city_name: "Lisala",
      country_code: "CD"
  },
  {
      id: "LIW",
      name: "Loikaw Airport",
      area_code: "LIW",
      city_name: "Loikaw",
      country_code: "MM"
  },
  {
      id: "LIX",
      name: "Likoma Island",
      area_code: "LIX",
      city_name: "Likoma Island",
      country_code: "MW"
  },
  {
      id: "LIY",
      name: "MidCoast Regional",
      area_code: "LIY",
      city_name: "Hinesville/Ft Stewart",
      country_code: "US"
  },
  {
      id: "LIZ",
      name: "Loring Intl",
      area_code: "LIZ",
      city_name: "Limestone",
      country_code: "US"
  },
  {
      id: "LJA",
      name: "LODJA AIRPORT",
      area_code: "LJA",
      city_name: "Lodja",
      country_code: "CD"
  },
  {
      id: "LKA",
      name: "Larantuka Airport",
      area_code: "LKA",
      city_name: "Larantuka",
      country_code: "ID"
  },
  {
      id: "LKB",
      name: "Lakeba Airport",
      area_code: "LKB",
      city_name: "Lakeba Island",
      country_code: "FJ"
  },
  {
      id: "LKC",
      name: "Lekana",
      area_code: "LKC",
      city_name: "Lekana",
      country_code: "CG"
  },
  {
      id: "LKD",
      name: "Lakeland Downs",
      area_code: "LKD",
      city_name: "Lakeland Downs",
      country_code: "AU"
  },
  {
      id: "LKK",
      name: "Kulik Lake",
      area_code: "LKK",
      city_name: "Kulik Lake",
      country_code: "US"
  },
  {
      id: "LKP",
      name: "Lake Placid",
      area_code: "LKP",
      city_name: "Lake Placid",
      country_code: "US"
  },
  {
      id: "LKR",
      name: "Las Khoreh",
      area_code: "LKR",
      city_name: "Las Khoreh",
      country_code: "SO"
  },
  {
      id: "LKU",
      name: "Lake Turkana",
      area_code: "LKU",
      city_name: "Lake Turkana",
      country_code: "KE"
  },
  {
      id: "LKV",
      name: "Lake County",
      area_code: "LKV",
      city_name: "Lakeview",
      country_code: "US"
  },
  {
      id: "LKY",
      name: "LAKE MANYARA AIRPORT",
      area_code: "LKY",
      city_name: "Lake Manyara",
      country_code: "TZ"
  },
  {
      id: "LKZ",
      name: "Lakenheath RAF",
      area_code: "LKZ",
      city_name: "Brandon",
      country_code: "GB"
  },
  {
      id: "LLC",
      name: "Cagayan North Intl Airport",
      area_code: "LLC",
      city_name: "Lal-lo",
      country_code: "PH"
  },
  {
      id: "LLG",
      name: "Chillagoe",
      area_code: "LLG",
      city_name: "Chillagoe",
      country_code: "AU"
  },
  {
      id: "LLH",
      name: "Reginaldo Hammer",
      area_code: "LLH",
      city_name: "Las Limas",
      country_code: "HN"
  },
  {
      id: "LLJ",
      name: "Silampari",
      area_code: "LLJ",
      city_name: "Lubuk Linggau",
      country_code: "GB"
  },
  {
      id: "LLL",
      name: "Lissadell",
      area_code: "LLL",
      city_name: "Lissadell",
      country_code: "AU"
  },
  {
      id: "LLM",
      name: "Lomlom",
      area_code: "LLM",
      city_name: "Lomlom",
      country_code: "SB"
  },
  {
      id: "LLN",
      name: "Kelila",
      area_code: "LLN",
      city_name: "Kelila",
      country_code: "ID"
  },
  {
      id: "LLP",
      name: "Linda Downs",
      area_code: "LLP",
      city_name: "Linda Downs",
      country_code: "AU"
  },
  {
      id: "LLS",
      name: "Alferez A. Rodriguez",
      area_code: "LLS",
      city_name: "Las Lomitas",
      country_code: "AR"
  },
  {
      id: "LLT",
      name: "Lobito",
      area_code: "LLT",
      city_name: "Lobito",
      country_code: "AO"
  },
  {
      id: "LLX",
      name: "Caledonia County",
      area_code: "LLX",
      city_name: "Lyndonville",
      country_code: "US"
  },
  {
      id: "LMA",
      name: "LAKE MINCHUMINA AIRPORT",
      area_code: "LMA",
      city_name: "Lake Minchumina",
      country_code: "US"
  },
  {
      id: "LMD",
      name: "Los Menucos",
      area_code: "LMD",
      city_name: "Los Menucos",
      country_code: "AR"
  },
  {
      id: "LMG",
      name: "Lamassa",
      area_code: "LMG",
      city_name: "Lamassa",
      country_code: "PG"
  },
  {
      id: "LMH",
      name: "Limon",
      area_code: "LMH",
      city_name: "Limon",
      country_code: "HN"
  },
  {
      id: "LMI",
      name: "Lumi",
      area_code: "LMI",
      city_name: "Lumi",
      country_code: "PG"
  },
  {
      id: "LML",
      name: "Lae Island",
      area_code: "LML",
      city_name: "Lae Island",
      country_code: "MH"
  },
  {
      id: "LMO",
      name: "RAF Station",
      area_code: "LMO",
      city_name: "Lossiemouth",
      country_code: "GB"
  },
  {
      id: "LMQ",
      name: "Marsa Brega",
      area_code: "LMQ",
      city_name: "Marsa Brega",
      country_code: "LY"
  },
  {
      id: "LMR",
      name: "Finsch Mine",
      area_code: "LMR",
      city_name: "Lime Acres",
      country_code: "ZA"
  },
  {
      id: "LMU",
      name: "Letung",
      area_code: "LMU",
      city_name: "Jemaja Island",
      country_code: "ID"
  },
  {
      id: "LMX",
      name: "Lopez de Micay",
      area_code: "LMX",
      city_name: "El Trapiche",
      country_code: "CO"
  },
  {
      id: "LMY",
      name: "Lake Murray",
      area_code: "LMY",
      city_name: "Lake Murray",
      country_code: "PG"
  },
  {
      id: "LMZ",
      name: "Palma",
      area_code: "LMZ",
      city_name: "Palma",
      country_code: "MZ"
  },
  {
      id: "LNA",
      name: "Palm Beach County Park",
      area_code: "PBI",
      city_name: "West Palm Beach",
      country_code: "US"
  },
  {
      id: "LNB",
      name: "LAMEN BAY AIRPORT",
      area_code: "LNB",
      city_name: "Lamen Bay",
      country_code: "VU"
  },
  {
      id: "LNC",
      name: "Lengbati",
      area_code: "LNC",
      city_name: "Lengbati",
      country_code: "PG"
  },
  {
      id: "LND",
      name: "Hunt Field",
      area_code: "LND",
      city_name: "Lander",
      country_code: "US"
  },
  {
      id: "LNE",
      name: "LONORORE AIRPORT",
      area_code: "LNE",
      city_name: "Lonorore",
      country_code: "VU"
  },
  {
      id: "LNF",
      name: "Munbil",
      area_code: "LNF",
      city_name: "Munbil",
      country_code: "PG"
  },
  {
      id: "LNG",
      name: "Lese",
      area_code: "LNG",
      city_name: "Lese",
      country_code: "PG"
  },
  {
      id: "LNH",
      name: "Lake Nash",
      area_code: "LNH",
      city_name: "Lake Nash",
      country_code: "AU"
  },
  {
      id: "LNI",
      name: "Air Station",
      area_code: "LNI",
      city_name: "Lonely",
      country_code: "US"
  },
  {
      id: "LNL",
      name: "ChengXian Airport",
      area_code: "LNL",
      city_name: "Longnan",
      country_code: "CN"
  },
  {
      id: "LNM",
      name: "Langimar",
      area_code: "LNM",
      city_name: "Langimar",
      country_code: "PG"
  },
  {
      id: "LNN",
      name: "Lost Nation Municipal",
      area_code: "LNN",
      city_name: "Willoughby",
      country_code: "US"
  },
  {
      id: "LNP",
      name: "Lonesome Pine",
      area_code: "LNP",
      city_name: "Wise",
      country_code: "US"
  },
  {
      id: "LNQ",
      name: "Loani",
      area_code: "LNQ",
      city_name: "Loani",
      country_code: "PG"
  },
  {
      id: "LNR",
      name: "Tri-County Regional",
      area_code: "LNR",
      city_name: "Lone Rock",
      country_code: "US"
  },
  {
      id: "LNU",
      name: "Kolonel RA Bessing",
      area_code: "LNU",
      city_name: "Malinau",
      country_code: "ID"
  },
  {
      id: "LNX",
      name: "Smolensk",
      area_code: "LNX",
      city_name: "Smolensk",
      country_code: "RU"
  },
  {
      id: "LOA",
      name: "Lorraine",
      area_code: "LOA",
      city_name: "Lorraine",
      country_code: "AU"
  },
  {
      id: "LOB",
      name: "San Rafael",
      area_code: "LOB",
      city_name: "Los Andes",
      country_code: "CL"
  },
  {
      id: "LOC",
      name: "Lock",
      area_code: "LOC",
      city_name: "Lock",
      country_code: "AU"
  },
  {
      id: "LOF",
      name: "Loen Island",
      area_code: "LOF",
      city_name: "Loen Island",
      country_code: "MH"
  },
  {
      id: "LOI",
      name: "Helmuth Baungartem",
      area_code: "LOI",
      city_name: "Lontras",
      country_code: "BR"
  },
  {
      id: "LOL",
      name: "Derby Field",
      area_code: "LOL",
      city_name: "Lovelock",
      country_code: "US"
  },
  {
      id: "LOO",
      name: "L'Mekrareg Airport",
      area_code: "LOO",
      city_name: "Laghouat",
      country_code: "DZ"
  },
  {
      id: "LOQ",
      name: "Lobatse",
      area_code: "LOQ",
      city_name: "Lobatse",
      country_code: "BW"
  },
  {
      id: "LOT",
      name: "Lewis University",
      area_code: "LOT",
      city_name: "Romeoville",
      country_code: "US"
  },
  {
      id: "LOW",
      name: "Louisa Co.-Freeman Fld",
      area_code: "LOW",
      city_name: "Louisa",
      country_code: "US"
  },
  {
      id: "LOX",
      name: "Los Tablones",
      area_code: "LOX",
      city_name: "Los Tablones",
      country_code: "GT"
  },
  {
      id: "LPE",
      name: "La Primavera",
      area_code: "LPE",
      city_name: "La Primavera",
      country_code: "CO"
  },
  {
      id: "LPJ",
      name: "Armando Schwarck",
      area_code: "LPJ",
      city_name: "Los Pijiguaos",
      country_code: "VE"
  },
  {
      id: "LPN",
      name: "Leron Plains",
      area_code: "LPN",
      city_name: "Leron Plains",
      country_code: "PG"
  },
  {
      id: "LPU",
      name: "Long Apung",
      area_code: "LPU",
      city_name: "Long Apung",
      country_code: "ID"
  },
  {
      id: "LQK",
      name: "Pickens County",
      area_code: "LQK",
      city_name: "Pickens",
      country_code: "US"
  },
  {
      id: "LQN",
      name: "Qalah-ye Now",
      area_code: "LQN",
      city_name: "Qalah-ye Now",
      country_code: "AF"
  },
  {
      id: "LRB",
      name: "Leribe",
      area_code: "LRB",
      city_name: "Leribe",
      country_code: "LS"
  },
  {
      id: "LRG",
      name: "Loralai",
      area_code: "LRG",
      city_name: "Loralai",
      country_code: "PK"
  },
  {
      id: "LRI",
      name: "Lorica",
      area_code: "LRI",
      city_name: "Lorica",
      country_code: "CO"
  },
  {
      id: "LRJ",
      name: "Municipal",
      area_code: "LRJ",
      city_name: "Le Mars",
      country_code: "US"
  },
  {
      id: "LRL",
      name: "International",
      area_code: "LRL",
      city_name: "Niamtougou",
      country_code: "TG"
  },
  {
      id: "LRQ",
      name: "Laurie River",
      area_code: "LRQ",
      city_name: "Laurie River",
      country_code: "CA"
  },
  {
      id: "LRR",
      name: "LAR AIRPORT",
      area_code: "LRR",
      city_name: "Lar",
      country_code: "IR"
  },
  {
      id: "LRV",
      name: "Los Roques",
      area_code: "LRV",
      city_name: "Los Roques",
      country_code: "VE"
  },
  {
      id: "LSB",
      name: "Municipal",
      area_code: "LSB",
      city_name: "Lordsburg",
      country_code: "US"
  },
  {
      id: "LSJ",
      name: "Long Island",
      area_code: "LSJ",
      city_name: "Long Island",
      country_code: "PG"
  },
  {
      id: "LSK",
      name: "Municipal",
      area_code: "LSK",
      city_name: "Lusk",
      country_code: "US"
  },
  {
      id: "LSL",
      name: "Los Chiles",
      area_code: "LSL",
      city_name: "Los Chiles",
      country_code: "CR"
  },
  {
      id: "LSM",
      name: "Long Semado",
      area_code: "LSM",
      city_name: "Long Semado",
      country_code: "MY"
  },
  {
      id: "LSS",
      name: "Les Saintes",
      area_code: "LSS",
      city_name: "Terre-de-Haut",
      country_code: "GP"
  },
  {
      id: "LSU",
      name: "Long Sukang",
      area_code: "LSU",
      city_name: "Long Sukang",
      country_code: "MY"
  },
  {
      id: "LSW",
      name: "Lhoksumawe Airport",
      area_code: "LSW",
      city_name: "Lhok Seumawe",
      country_code: "ID"
  },
  {
      id: "LSX",
      name: "Lhok Sukon",
      area_code: "LSX",
      city_name: "Lhok Sukon",
      country_code: "ID"
  },
  {
      id: "LSZ",
      name: "Mali Losinj",
      area_code: "LSZ",
      city_name: "Mali Losinj",
      country_code: "HR"
  },
  {
      id: "LTB",
      name: "Latrobe",
      area_code: "LTB",
      city_name: "Latrobe",
      country_code: "AU"
  },
  {
      id: "LTC",
      name: "Lai",
      area_code: "LTC",
      city_name: "Lai",
      country_code: "TD"
  },
  {
      id: "LTD",
      name: "Ghadames",
      area_code: "LTD",
      city_name: "Ghadames",
      country_code: "LY"
  },
  {
      id: "LTF",
      name: "Leitre",
      area_code: "LTF",
      city_name: "Leitre",
      country_code: "PG"
  },
  {
      id: "LTG",
      name: "Langtang",
      area_code: "LTG",
      city_name: "Langtang",
      country_code: "NP"
  },
  {
      id: "LTL",
      name: "Lastoursville",
      area_code: "LTL",
      city_name: "Lastoursville",
      country_code: "GA"
  },
  {
      id: "LTM",
      name: "Lethem",
      area_code: "LTM",
      city_name: "Lethem",
      country_code: "GY"
  },
  {
      id: "LTP",
      name: "Lyndhurst Station",
      area_code: "LTP",
      city_name: "Lyndhurst Station",
      country_code: "AU"
  },
  {
      id: "LTR",
      name: "Letterkenny",
      area_code: "LTR",
      city_name: "Letterkenny",
      country_code: "IE"
  },
  {
      id: "LTV",
      name: "Lotus Vale Station",
      area_code: "LTV",
      city_name: "Lotus Vale Station",
      country_code: "AU"
  },
  {
      id: "LTW",
      name: "St Mary's County Rgnl",
      area_code: "LTW",
      city_name: "Leonardtown",
      country_code: "US"
  },
  {
      id: "LUB",
      name: "Lumidpau",
      area_code: "LUB",
      city_name: "Lumidpau",
      country_code: "GY"
  },
  {
      id: "LUC",
      name: "Laucala Island",
      area_code: "LUC",
      city_name: "Laucala Island",
      country_code: "FJ"
  },
  {
      id: "LUE",
      name: "Lucenec",
      area_code: "LUE",
      city_name: "Lucenec",
      country_code: "SK"
  },
  {
      id: "LUI",
      name: "La Union",
      area_code: "LUI",
      city_name: "La Union",
      country_code: "HN"
  },
  {
      id: "LUO",
      name: "LUENA AIRPORT",
      area_code: "LUO",
      city_name: "Luena",
      country_code: "AO"
  },
  {
      id: "LUR",
      name: "CAPE LISBURNE AIRPORT",
      area_code: "LUR",
      city_name: "Cape Lisburne",
      country_code: "US"
  },
  {
      id: "LUS",
      name: "Lusanga",
      area_code: "LUS",
      city_name: "Lusanga",
      country_code: "CD"
  },
  {
      id: "LUT",
      name: "New Laura",
      area_code: "LUT",
      city_name: "New Laura",
      country_code: "AU"
  },
  {
      id: "LUU",
      name: "Laura",
      area_code: "LUU",
      city_name: "Laura",
      country_code: "AU"
  },
  {
      id: "LUV",
      name: "Langgur Airport, Dumatubin",
      area_code: "LUV",
      city_name: "Langgur",
      country_code: "ID"
  },
  {
      id: "LVB",
      name: "Santana do Livramento",
      area_code: "LVB",
      city_name: "Santana do Livramento",
      country_code: "BR"
  },
  {
      id: "LVL",
      name: "Brunswick Municipal",
      area_code: "LVL",
      city_name: "Lawrenceville",
      country_code: "US"
  },
  {
      id: "LVP",
      name: "Lavan Island",
      area_code: "LVP",
      city_name: "Lavan Island",
      country_code: "IR"
  },
  {
      id: "LVR",
      name: "Bom Futuro",
      area_code: "LVR",
      city_name: "Lucas do Rio Verde",
      country_code: "BR"
  },
  {
      id: "LWA",
      name: "Lebak",
      area_code: "LWA",
      city_name: "Lebak",
      country_code: "PH"
  },
  {
      id: "LWE",
      name: "Wunopito",
      area_code: "LWE",
      city_name: "Lewoleba",
      country_code: "ID"
  },
  {
      id: "LWH",
      name: "Lawn Hill",
      area_code: "LWH",
      city_name: "Lawn Hill",
      country_code: "AU"
  },
  {
      id: "LWI",
      name: "Lowai",
      area_code: "LWI",
      city_name: "Lowai",
      country_code: "PG"
  },
  {
      id: "LWL",
      name: "Municipal-Harriet Fld",
      area_code: "LWL",
      city_name: "Wells",
      country_code: "US"
  },
  {
      id: "LWN",
      name: "Leninakan Airport",
      area_code: "LWN",
      city_name: "Gyumri",
      country_code: "AM"
  },
  {
      id: "LWR",
      name: "Air Base",
      area_code: "LWR",
      city_name: "Leeuwarden",
      country_code: "NL"
  },
  {
      id: "LWV",
      name: "Vincennes Intl",
      area_code: "LWV",
      city_name: "Lawrenceville",
      country_code: "US"
  },
  {
      id: "LXU",
      name: "Lukulu",
      area_code: "LXU",
      city_name: "Lukulu",
      country_code: "ZM"
  },
  {
      id: "LYE",
      name: "RAF Station",
      area_code: "LYE",
      city_name: "Lyneham",
      country_code: "GB"
  },
  {
      id: "LYK",
      name: "Lunyuk",
      area_code: "SWQ",
      city_name: "Sumbawa",
      country_code: "ID"
  },
  {
      id: "LYO",
      name: "Rice County Municipal",
      area_code: "LYO",
      city_name: "Lyons",
      country_code: "US"
  },
  {
      id: "LYT",
      name: "Lady Elliot Island",
      area_code: "LYT",
      city_name: "Lady Elliot Island",
      country_code: "AU"
  },
  {
      id: "LZA",
      name: "Luiza",
      area_code: "LZA",
      city_name: "Luiza",
      country_code: "CD"
  },
  {
      id: "LZI",
      name: "Luozi",
      area_code: "LZI",
      city_name: "Luozi",
      country_code: "CD"
  },
  {
      id: "LZM",
      name: "Luzamba",
      area_code: "LZM",
      city_name: "Luzamba",
      country_code: "AO"
  },
  {
      id: "LZU",
      name: "Gwinnett County",
      area_code: "LZU",
      city_name: "Lawrenceville",
      country_code: "US"
  },
  {
      id: "MAI",
      name: "Mangochi",
      area_code: "MAI",
      city_name: "Mangochi",
      country_code: "MW"
  },
  {
      id: "MAL",
      name: "Falabisahaya",
      area_code: "MAL",
      city_name: "Mangole",
      country_code: "ID"
  },
  {
      id: "MAP",
      name: "Mamai",
      area_code: "MAP",
      city_name: "Mamai",
      country_code: "PG"
  },
  {
      id: "MAT",
      name: "Tshimpi",
      area_code: "MAT",
      city_name: "Matadi",
      country_code: "CG"
  },
  {
      id: "MAV",
      name: "Taroa Island",
      area_code: "MAV",
      city_name: "Maloelap Atoll",
      country_code: "MH"
  },
  {
      id: "MAW",
      name: "Regional",
      area_code: "MAW",
      city_name: "Malden",
      country_code: "US"
  },
  {
      id: "MAX",
      name: "Ouro Sogui",
      area_code: "MAX",
      city_name: "Matam",
      country_code: "SN"
  },
  {
      id: "MAY",
      name: "Clarence A. Bain",
      area_code: "MAY",
      city_name: "Mangrove Cay",
      country_code: "BS"
  },
  {
      id: "MBB",
      name: "Marble Bar",
      area_code: "MBB",
      city_name: "Marble Bar",
      country_code: "AU"
  },
  {
      id: "MBC",
      name: "Mbigou",
      area_code: "MBC",
      city_name: "Mbigou",
      country_code: "GA"
  },
  {
      id: "MBF",
      name: "Porepunkah",
      area_code: "MBF",
      city_name: "Mount Buffalo",
      country_code: "AU"
  },
  {
      id: "MBG",
      name: "Municipal",
      area_code: "MBG",
      city_name: "Mobridge",
      country_code: "US"
  },
  {
      id: "MBI",
      name: "MBEYA AIRPORT",
      area_code: "MBI",
      city_name: "Mbeya",
      country_code: "TZ"
  },
  {
      id: "MBK",
      name: "Orlando Villas-Boas",
      area_code: "MBK",
      city_name: "Matupa",
      country_code: "BR"
  },
  {
      id: "MBM",
      name: "Mkambati",
      area_code: "MBM",
      city_name: "Mkambati",
      country_code: "ZA"
  },
  {
      id: "MBN",
      name: "Mount Barnett",
      area_code: "MBN",
      city_name: "Mount Barnett",
      country_code: "AU"
  },
  {
      id: "MBO",
      name: "Mamburao",
      area_code: "MBO",
      city_name: "Mamburao",
      country_code: "PH"
  },
  {
      id: "MBQ",
      name: "Mbarara",
      area_code: "MBQ",
      city_name: "Mbarara",
      country_code: "UG"
  },
  {
      id: "MBR",
      name: "Mbout",
      area_code: "MBR",
      city_name: "Mbout",
      country_code: "MR"
  },
  {
      id: "MBU",
      name: "Mbambanakira",
      area_code: "MBU",
      city_name: "Mbambanakira",
      country_code: "SB"
  },
  {
      id: "MBV",
      name: "Masa",
      area_code: "MBV",
      city_name: "Masa",
      country_code: "PG"
  },
  {
      id: "MBY",
      name: "Omar N. Bradley",
      area_code: "MBY",
      city_name: "Moberly",
      country_code: "US"
  },
  {
      id: "MBZ",
      name: "Maues",
      area_code: "MBZ",
      city_name: "Maues",
      country_code: "BR"
  },
  {
      id: "MCA",
      name: "Macenta",
      area_code: "MCA",
      city_name: "Macenta",
      country_code: "GN"
  },
  {
      id: "MCB",
      name: "Pike County",
      area_code: "MCB",
      city_name: "McComb",
      country_code: "US"
  },
  {
      id: "MCD",
      name: "Mackinac Island",
      area_code: "MCD",
      city_name: "Mackinac Island",
      country_code: "US"
  },
  {
      id: "MCH",
      name: "General Manuel Serrano",
      area_code: "MCH",
      city_name: "Machala",
      country_code: "EC"
  },
  {
      id: "MCJ",
      name: "Jorge Isaacs",
      area_code: "MCJ",
      city_name: "Maicao",
      country_code: "CO"
  },
  {
      id: "MCL",
      name: "National Park",
      area_code: "MCL",
      city_name: "McKinley",
      country_code: "US"
  },
  {
      id: "MCS",
      name: "Monte Caseros",
      area_code: "MCS",
      city_name: "Monte Caseros",
      country_code: "AR"
  },
  {
      id: "MDB",
      name: "Melinda",
      area_code: "MDB",
      city_name: "Melinda",
      country_code: "BZ"
  },
  {
      id: "MDF",
      name: "Taylor County",
      area_code: "MDF",
      city_name: "Medford",
      country_code: "US"
  },
  {
      id: "MDJ",
      name: "Municipal",
      area_code: "MDJ",
      city_name: "Madras",
      country_code: "US"
  },
  {
      id: "MDM",
      name: "Munduku",
      area_code: "MDM",
      city_name: "Munduku",
      country_code: "PG"
  },
  {
      id: "MDN",
      name: "Municipal",
      area_code: "MDN",
      city_name: "Madison",
      country_code: "US"
  },
  {
      id: "MDP",
      name: "Mindiptana",
      area_code: "MDP",
      city_name: "Mindiptana",
      country_code: "ID"
  },
  {
      id: "MDR",
      name: "Musiara",
      area_code: "MRE",
      city_name: "Maasai Mara",
      country_code: "KE"
  },
  {
      id: "MDS",
      name: "Middle Caicos",
      area_code: "MDS",
      city_name: "Middle Caicos",
      country_code: "TC"
  },
  {
      id: "MDV",
      name: "Medouneu",
      area_code: "MDV",
      city_name: "Medouneu",
      country_code: "GA"
  },
  {
      id: "MDX",
      name: "Mercedes",
      area_code: "MDX",
      city_name: "Mercedes",
      country_code: "AR"
  },
  {
      id: "MEE",
      name: "Mare Airport",
      area_code: "MEE",
      city_name: "Mare",
      country_code: "NC"
  },
  {
      id: "MEF",
      name: "Melfi",
      area_code: "MEF",
      city_name: "Melfi",
      country_code: "TD"
  },
  {
      id: "MEG",
      name: "Malanje",
      area_code: "MEG",
      city_name: "Malanje",
      country_code: "AO"
  },
  {
      id: "MEJ",
      name: "Port Meadville",
      area_code: "MEJ",
      city_name: "Meadville",
      country_code: "US"
  },
  {
      id: "MEK",
      name: "Royal AFB",
      area_code: "MEK",
      city_name: "Meknes",
      country_code: "MA"
  },
  {
      id: "MEP",
      name: "Mersing",
      area_code: "MEP",
      city_name: "Mersing",
      country_code: "MY"
  },
  {
      id: "MEQ",
      name: "Seunagan Airport",
      area_code: "MEQ",
      city_name: "Cut Nyak Dien",
      country_code: "ID"
  },
  {
      id: "MET",
      name: "Moreton",
      area_code: "MET",
      city_name: "Moreton",
      country_code: "AU"
  },
  {
      id: "MEW",
      name: "Mweka",
      area_code: "MEW",
      city_name: "Mweka",
      country_code: "CD"
  },
  {
      id: "MEY",
      name: "Meghauli",
      area_code: "MEY",
      city_name: "Meghauli",
      country_code: "NP"
  },
  {
      id: "MFA",
      name: "Mafia Airport",
      area_code: "MFA",
      city_name: "Mafia Island",
      country_code: "TZ"
  },
  {
      id: "MFB",
      name: "Monfort",
      area_code: "MFB",
      city_name: "Monfort",
      country_code: "CO"
  },
  {
      id: "MFC",
      name: "Mafeteng",
      area_code: "MFC",
      city_name: "Mafeteng",
      country_code: "LS"
  },
  {
      id: "MFF",
      name: "Moanda",
      area_code: "MFF",
      city_name: "Moanda",
      country_code: "GA"
  },
  {
      id: "MFG",
      name: "Muzaffarabad",
      area_code: "MFG",
      city_name: "Muzaffarabad",
      country_code: "PK"
  },
  {
      id: "MFJ",
      name: "Moala Island",
      area_code: "MFJ",
      city_name: "Moala Island",
      country_code: "FJ"
  },
  {
      id: "MFL",
      name: "Mount Full Stop",
      area_code: "MFL",
      city_name: "Wando Vale",
      country_code: "AU"
  },
  {
      id: "MFO",
      name: "Manguna",
      area_code: "MFO",
      city_name: "Manguna",
      country_code: "PG"
  },
  {
      id: "MFP",
      name: "Manners Creek Station",
      area_code: "MFP",
      city_name: "Manners Creek Station",
      country_code: "AU"
  },
  {
      id: "MFQ",
      name: "Maradi",
      area_code: "MFQ",
      city_name: "Maradi",
      country_code: "NE"
  },
  {
      id: "MFS",
      name: "Miraflores",
      area_code: "MFS",
      city_name: "Miraflores",
      country_code: "CO"
  },
  {
      id: "MFV",
      name: "Accomack County",
      area_code: "MFV",
      city_name: "Melfa",
      country_code: "US"
  },
  {
      id: "MFW",
      name: "Magaruque Island",
      area_code: "MFW",
      city_name: "Magaruque Island",
      country_code: "MZ"
  },
  {
      id: "MFX",
      name: "Meribel",
      area_code: "MFX",
      city_name: "Meribel",
      country_code: "FR"
  },
  {
      id: "MFY",
      name: "Mayfa'ah",
      area_code: "MFY",
      city_name: "Mayfa'ah",
      country_code: "YE"
  },
  {
      id: "MFZ",
      name: "Meselia",
      area_code: "MFZ",
      city_name: "Meselia",
      country_code: "PG"
  },
  {
      id: "MGG",
      name: "Margarima",
      area_code: "MGG",
      city_name: "Margarima",
      country_code: "PG"
  },
  {
      id: "MGI",
      name: "National Wildlife Ref.",
      area_code: "MGI",
      city_name: "Matagorda Island",
      country_code: "US"
  },
  {
      id: "MGK",
      name: "Mong Ton",
      area_code: "MGK",
      city_name: "Mong Ton",
      country_code: "MM"
  },
  {
      id: "MGN",
      name: "Baracoa",
      area_code: "MGN",
      city_name: "Magangue",
      country_code: "CO"
  },
  {
      id: "MGO",
      name: "Manega",
      area_code: "MGO",
      city_name: "Manega",
      country_code: "GA"
  },
  {
      id: "MGP",
      name: "Manga",
      area_code: "MGP",
      city_name: "Manga",
      country_code: "PG"
  },
  {
      id: "MGS",
      name: "Auau",
      area_code: "MGS",
      city_name: "Mangaia Island",
      country_code: "CK"
  },
  {
      id: "MGT",
      name: "Milingimbi Airport",
      area_code: "MGT",
      city_name: "Milingimbi Island",
      country_code: "AU"
  },
  {
      id: "MGU",
      name: "Manaung Airport",
      area_code: "MGU",
      city_name: "Manaung",
      country_code: "MM"
  },
  {
      id: "MGX",
      name: "Moabi",
      area_code: "MGX",
      city_name: "Moabi",
      country_code: "GA"
  },
  {
      id: "MGZ",
      name: "MYEIK AIRPORT",
      area_code: "MGZ",
      city_name: "Myeik",
      country_code: "MM"
  },
  {
      id: "MHA",
      name: "Mahdia",
      area_code: "MHA",
      city_name: "Mahdia",
      country_code: "GY"
  },
  {
      id: "MHF",
      name: "Morichal",
      area_code: "MHF",
      city_name: "Morichal",
      country_code: "CO"
  },
  {
      id: "MHI",
      name: "Moucha Island",
      area_code: "MHI",
      city_name: "Moucha Island",
      country_code: "DJ"
  },
  {
      id: "MHL",
      name: "Memorial Municipal",
      area_code: "MHL",
      city_name: "Marshall",
      country_code: "US"
  },
  {
      id: "MHM",
      name: "Manaoba",
      area_code: "MHM",
      city_name: "Malaita",
      country_code: "US"
  },
  {
      id: "MHN",
      name: "Hooker County",
      area_code: "MHN",
      city_name: "Mullen",
      country_code: "US"
  },
  {
      id: "MHO",
      name: "Mount House",
      area_code: "MHO",
      city_name: "Mount House",
      country_code: "AU"
  },
  {
      id: "MHS",
      name: "Dunsmuir Municipal",
      area_code: "MHS",
      city_name: "Mount Shasta",
      country_code: "US"
  },
  {
      id: "MHW",
      name: "Monteagudo",
      area_code: "MHW",
      city_name: "Monteagudo",
      country_code: "BO"
  },
  {
      id: "MHX",
      name: "Manihiki Atoll",
      area_code: "MHX",
      city_name: "Manihiki Atoll",
      country_code: "CK"
  },
  {
      id: "MHY",
      name: "Morehead",
      area_code: "MHY",
      city_name: "Morehead",
      country_code: "PG"
  },
  {
      id: "MIC",
      name: "Crystal",
      area_code: "MSP",
      city_name: "Minneapolis/St Paul",
      country_code: "US"
  },
  {
      id: "MIF",
      name: "Roy Hurd Memorial",
      area_code: "MIF",
      city_name: "Monahans",
      country_code: "US"
  },
  {
      id: "MIH",
      name: "Mitchell Plateau",
      area_code: "MIH",
      city_name: "Mitchell Plateau",
      country_code: "AU"
  },
  {
      id: "MIJ",
      name: "Mili Island",
      area_code: "MIJ",
      city_name: "Mili Island",
      country_code: "MH"
  },
  {
      id: "MIN",
      name: "Minnipa",
      area_code: "MIN",
      city_name: "Minnipa",
      country_code: "AU"
  },
  {
      id: "MIO",
      name: "Municipal",
      area_code: "MIO",
      city_name: "Miami",
      country_code: "US"
  },
  {
      id: "MIP",
      name: "Mitzpe Ramon",
      area_code: "MIP",
      city_name: "Mitzpe Ramon",
      country_code: "IL"
  },
  {
      id: "MIT",
      name: "Minter Field",
      area_code: "MIT",
      city_name: "Shafter",
      country_code: "US"
  },
  {
      id: "MIU",
      name: "Maiduguri International Airport",
      area_code: "MIU",
      city_name: "Maiduguri",
      country_code: "NG"
  },
  {
      id: "MIX",
      name: "Miriti Parana",
      area_code: "MIX",
      city_name: "Miriti Parana",
      country_code: "CO"
  },
  {
      id: "MIY",
      name: "Mittiebah",
      area_code: "MIY",
      city_name: "Mittiebah",
      country_code: "AU"
  },
  {
      id: "MIZ",
      name: "Mainoru",
      area_code: "MIZ",
      city_name: "Mainoru",
      country_code: "AU"
  },
  {
      id: "MJA",
      name: "Manja",
      area_code: "MJA",
      city_name: "Manja",
      country_code: "MG"
  },
  {
      id: "MJB",
      name: "Mejit Island",
      area_code: "MJB",
      city_name: "Mejit Island",
      country_code: "MH"
  },
  {
      id: "MJC",
      name: "MAN AIRPORT",
      area_code: "MJC",
      city_name: "Man",
      country_code: "CI"
  },
  {
      id: "MJE",
      name: "Majkin",
      area_code: "MJE",
      city_name: "Majkin",
      country_code: "MH"
  },
  {
      id: "MJJ",
      name: "Moki",
      area_code: "MJJ",
      city_name: "Moki",
      country_code: "PG"
  },
  {
      id: "MJL",
      name: "Mouila-Ville",
      area_code: "MJL",
      city_name: "Mouila",
      country_code: "GA"
  },
  {
      id: "MJM",
      name: "Mbuji Mayi Airport",
      area_code: "MJM",
      city_name: "Mbuji-Mayi",
      country_code: "CD"
  },
  {
      id: "MJO",
      name: "Mount Etjo",
      area_code: "MJO",
      city_name: "Mount Etjo",
      country_code: "NA"
  },
  {
      id: "MJP",
      name: "Manjimup",
      area_code: "MJP",
      city_name: "Manjimup",
      country_code: "AU"
  },
  {
      id: "MJQ",
      name: "Municipal",
      area_code: "MJQ",
      city_name: "Jackson",
      country_code: "US"
  },
  {
      id: "MJS",
      name: "Maganja da Costa",
      area_code: "MJS",
      city_name: "Maganja da Costa",
      country_code: "MZ"
  },
  {
      id: "MKB",
      name: "Mekambo",
      area_code: "MKB",
      city_name: "Mekambo",
      country_code: "GA"
  },
  {
      id: "MKH",
      name: "Mokhotlong",
      area_code: "MKH",
      city_name: "Mokhotlong",
      country_code: "LS"
  },
  {
      id: "MKI",
      name: "Mboki",
      area_code: "MKI",
      city_name: "Obo",
      country_code: "CF"
  },
  {
      id: "MKJ",
      name: "Makoua",
      area_code: "MKJ",
      city_name: "Makoua",
      country_code: "CG"
  },
  {
      id: "MKN",
      name: "Malekolon",
      area_code: "MKN",
      city_name: "Malekolon",
      country_code: "PG"
  },
  {
      id: "MKP",
      name: "MAKEMO AIRPORT",
      area_code: "MKP",
      city_name: "Makemo",
      country_code: "PF"
  },
  {
      id: "MKS",
      name: "Mekane Selam",
      area_code: "MKS",
      city_name: "Mekane Selam",
      country_code: "ET"
  },
  {
      id: "MKU",
      name: "Makokou",
      area_code: "MKU",
      city_name: "Makokou",
      country_code: "GA"
  },
  {
      id: "MKV",
      name: "Mount Cavenagh",
      area_code: "MKV",
      city_name: "Mount Cavenagh",
      country_code: "AU"
  },
  {
      id: "MLD",
      name: "Malad City",
      area_code: "MLD",
      city_name: "Malad City",
      country_code: "US"
  },
  {
      id: "MLF",
      name: "Municipal",
      area_code: "MLF",
      city_name: "Milford",
      country_code: "US"
  },
  {
      id: "MLJ",
      name: "Baldwin County",
      area_code: "MLJ",
      city_name: "Milledgeville",
      country_code: "US"
  },
  {
      id: "MLK",
      name: "Malta",
      area_code: "MLK",
      city_name: "Malta",
      country_code: "US"
  },
  {
      id: "MLP",
      name: "Malabang",
      area_code: "MLP",
      city_name: "Malabang",
      country_code: "PH"
  },
  {
      id: "MLQ",
      name: "Malalaua",
      area_code: "MLQ",
      city_name: "Malalaua",
      country_code: "PG"
  },
  {
      id: "MLR",
      name: "Millicent",
      area_code: "MLR",
      city_name: "Millicent",
      country_code: "AU"
  },
  {
      id: "MLY",
      name: "MANLEY HOT SPRINGS AIRPORT",
      area_code: "MLY",
      city_name: "Manley Hot Springs",
      country_code: "US"
  },
  {
      id: "MMD",
      name: "Minamidaito",
      area_code: "MMD",
      city_name: "Minamidaito",
      country_code: "JP"
  },
  {
      id: "MMQ",
      name: "Mbala",
      area_code: "MMQ",
      city_name: "Mbala",
      country_code: "ZM"
  },
  {
      id: "MMS",
      name: "Selfs",
      area_code: "MMS",
      city_name: "Marks",
      country_code: "US"
  },
  {
      id: "MMV",
      name: "Mal Island",
      area_code: "MMV",
      city_name: "Mal Island",
      country_code: "PG"
  },
  {
      id: "MMW",
      name: "Moma",
      area_code: "MMW",
      city_name: "Moma",
      country_code: "MZ"
  },
  {
      id: "MMZ",
      name: "Maimana Airport",
      area_code: "MMZ",
      city_name: "Maimanah",
      country_code: "AF"
  },
  {
      id: "MNA",
      name: "Melangguane Airport",
      area_code: "MNA",
      city_name: "Melangguane",
      country_code: "ID"
  },
  {
      id: "MNB",
      name: "Muanda",
      area_code: "MNB",
      city_name: "Muanda",
      country_code: "CG"
  },
  {
      id: "MNC",
      name: "Nacala Airport",
      area_code: "MNC",
      city_name: "Nacala",
      country_code: "MZ"
  },
  {
      id: "MND",
      name: "Medina",
      area_code: "MND",
      city_name: "Medina",
      country_code: "CO"
  },
  {
      id: "MNE",
      name: "Mungeranie",
      area_code: "MNE",
      city_name: "Mungeranie",
      country_code: "AU"
  },
  {
      id: "MNF",
      name: "Mana Island",
      area_code: "MNF",
      city_name: "Mana Island",
      country_code: "FJ"
  },
  {
      id: "MNV",
      name: "Mount Valley",
      area_code: "MNV",
      city_name: "Mount Valley",
      country_code: "AU"
  },
  {
      id: "MNW",
      name: "MacDonald Downs",
      area_code: "MNW",
      city_name: "MacDonald Downs",
      country_code: "AU"
  },
  {
      id: "MNX",
      name: "Manicore",
      area_code: "MNX",
      city_name: "Manicore",
      country_code: "BR"
  },
  {
      id: "MNY",
      name: "Mono Island",
      area_code: "MNY",
      city_name: "Mono Island",
      country_code: "SB"
  },
  {
      id: "MOA",
      name: "ORESTES ACOSTA AIRPORT",
      area_code: "MOA",
      city_name: "Moa",
      country_code: "CU"
  },
  {
      id: "MOE",
      name: "Momeik",
      area_code: "MOE",
      city_name: "Momeik",
      country_code: "MM"
  },
  {
      id: "MOG",
      name: "Mong Hsat Airport",
      area_code: "MOG",
      city_name: "Mong Hsat",
      country_code: "MM"
  },
  {
      id: "MOH",
      name: "Morowali",
      area_code: "MOH",
      city_name: "Morowali",
      country_code: "ID"
  },
  {
      id: "MOJ",
      name: "Moengo",
      area_code: "MOJ",
      city_name: "Moengo",
      country_code: "SR"
  },
  {
      id: "MOM",
      name: "Letfotar",
      area_code: "MOM",
      city_name: "Moudjeria",
      country_code: "MR"
  },
  {
      id: "MOO",
      name: "Moomba",
      area_code: "MOO",
      city_name: "Moomba",
      country_code: "AU"
  },
  {
      id: "MOS",
      name: "Moses Point",
      area_code: "MOS",
      city_name: "Moses Point",
      country_code: "US"
  },
  {
      id: "MOX",
      name: "Municipal",
      area_code: "MOX",
      city_name: "Morris",
      country_code: "US"
  },
  {
      id: "MOY",
      name: "Monterrey",
      area_code: "MOY",
      city_name: "Monterrey",
      country_code: "CO"
  },
  {
      id: "MPA",
      name: "Mpacha Airport",
      area_code: "MPA",
      city_name: "Katima Mulilo",
      country_code: "NA"
  },
  {
      id: "MPC",
      name: "Muko-Muko",
      area_code: "MPC",
      city_name: "Muko-Muko",
      country_code: "ID"
  },
  {
      id: "MPD",
      name: "Sindhri",
      area_code: "MPD",
      city_name: "Mirpur Khas",
      country_code: "PK"
  },
  {
      id: "MPF",
      name: "Mapoda",
      area_code: "MPF",
      city_name: "Mapoda",
      country_code: "PG"
  },
  {
      id: "MPG",
      name: "Makini",
      area_code: "MPG",
      city_name: "Makini",
      country_code: "PG"
  },
  {
      id: "MPI",
      name: "Mamitupo",
      area_code: "MPI",
      city_name: "Mamitupo",
      country_code: "PA"
  },
  {
      id: "MPO",
      name: "Pocono Mountains Muni",
      area_code: "MPO",
      city_name: "Mount Pocono",
      country_code: "US"
  },
  {
      id: "MPP",
      name: "MULATUPO AIRPORT",
      area_code: "MPP",
      city_name: "Mulatupo",
      country_code: "PA"
  },
  {
      id: "MPT",
      name: "Maliana",
      area_code: "MPT",
      city_name: "Maliana",
      country_code: "TL"
  },
  {
      id: "MPU",
      name: "Mabua",
      area_code: "MPU",
      city_name: "Mabua",
      country_code: "PG"
  },
  {
      id: "MPX",
      name: "Miyanmin",
      area_code: "MPX",
      city_name: "Miyanmin",
      country_code: "PG"
  },
  {
      id: "MPY",
      name: "Maripasoula",
      area_code: "MPY",
      city_name: "Maripasoula",
      country_code: "GF"
  },
  {
      id: "MPZ",
      name: "Municipal",
      area_code: "MPZ",
      city_name: "Mount Pleasant",
      country_code: "US"
  },
  {
      id: "MQA",
      name: "Mandora Station",
      area_code: "MQA",
      city_name: "Mandora Station",
      country_code: "AU"
  },
  {
      id: "MQB",
      name: "Municipal",
      area_code: "MQB",
      city_name: "Macomb",
      country_code: "US"
  },
  {
      id: "MQC",
      name: "Miquelon",
      area_code: "MQC",
      city_name: "Miquelon",
      country_code: "PM"
  },
  {
      id: "MQD",
      name: "Maquinchao",
      area_code: "MQD",
      city_name: "Maquinchao",
      country_code: "AR"
  },
  {
      id: "MQE",
      name: "Marqua",
      area_code: "MQE",
      city_name: "Marqua",
      country_code: "AU"
  },
  {
      id: "MQG",
      name: "Midgard",
      area_code: "MQG",
      city_name: "Midgard",
      country_code: "NA"
  },
  {
      id: "MQH",
      name: "Minacu",
      area_code: "MQH",
      city_name: "Minacu",
      country_code: "BR"
  },
  {
      id: "MQO",
      name: "Malam",
      area_code: "MQO",
      city_name: "Malam",
      country_code: "PG"
  },
  {
      id: "MQQ",
      name: "Moundou",
      area_code: "MQQ",
      city_name: "Moundou",
      country_code: "TD"
  },
  {
      id: "MQR",
      name: "Mosquera",
      area_code: "MQR",
      city_name: "Mosquera",
      country_code: "CO"
  },
  {
      id: "MQU",
      name: "Jose Celestino Mutis",
      area_code: "MQU",
      city_name: "Mariquita",
      country_code: "CO"
  },
  {
      id: "MQV",
      name: "Mostaganem",
      area_code: "MQV",
      city_name: "Mostaganem",
      country_code: "DZ"
  },
  {
      id: "MQW",
      name: "Telfair-Wheeler",
      area_code: "MQW",
      city_name: "McRae",
      country_code: "US"
  },
  {
      id: "MRF",
      name: "Municipal",
      area_code: "MRF",
      city_name: "Marfa",
      country_code: "US"
  },
  {
      id: "MRG",
      name: "Mareeba",
      area_code: "MRG",
      city_name: "Mareeba",
      country_code: "AU"
  },
  {
      id: "MRH",
      name: "May River",
      area_code: "MRH",
      city_name: "May River",
      country_code: "PG"
  },
  {
      id: "MRJ",
      name: "Marcala",
      area_code: "MRJ",
      city_name: "Marcala",
      country_code: "HN"
  },
  {
      id: "MRK",
      name: "Marco Island",
      area_code: "MRK",
      city_name: "Marco Island",
      country_code: "US"
  },
  {
      id: "MRL",
      name: "Miners Lake",
      area_code: "MRL",
      city_name: "Miners Lake",
      country_code: "AU"
  },
  {
      id: "MRM",
      name: "Manari",
      area_code: "MRM",
      city_name: "Manari",
      country_code: "PG"
  },
  {
      id: "MRN",
      name: "Foothills Regional",
      area_code: "MRN",
      city_name: "Morganton",
      country_code: "US"
  },
  {
      id: "MRP",
      name: "Marla",
      area_code: "MRP",
      city_name: "Marla",
      country_code: "AU"
  },
  {
      id: "MRQ",
      name: "Marinduque Island",
      area_code: "MRQ",
      city_name: "Marinduque Island",
      country_code: "PH"
  },
  {
      id: "MRR",
      name: "J.M. Velasco Ibarra",
      area_code: "MRR",
      city_name: "Macara",
      country_code: "EC"
  },
  {
      id: "MRT",
      name: "Moroak",
      area_code: "MRT",
      city_name: "Moroak",
      country_code: "AU"
  },
  {
      id: "MRW",
      name: "Lolland Falster",
      area_code: "MRW",
      city_name: "Maribo",
      country_code: "DK"
  },
  {
      id: "MRX",
      name: "Mahshahr Airport",
      area_code: "MRX",
      city_name: "Bandar Mahshahr",
      country_code: "IR"
  },
  {
      id: "MSD",
      name: "Mount Pleasant",
      area_code: "MSD",
      city_name: "Mount Pleasant",
      country_code: "US"
  },
  {
      id: "MSF",
      name: "Mount Swan",
      area_code: "MSF",
      city_name: "Mount Swan",
      country_code: "AU"
  },
  {
      id: "MSG",
      name: "Matsaile",
      area_code: "MSG",
      city_name: "Matsaile",
      country_code: "LS"
  },
  {
      id: "MSH",
      name: "Air Base",
      area_code: "MSH",
      city_name: "Masirah",
      country_code: "OM"
  },
  {
      id: "MSI",
      name: "Masalembo",
      area_code: "MSI",
      city_name: "Masalembo",
      country_code: "ID"
  },
  {
      id: "MSM",
      name: "Masi-Manimba",
      area_code: "MSM",
      city_name: "Masi-Manimba",
      country_code: "CD"
  },
  {
      id: "MSW",
      name: "Massawa Airport",
      area_code: "MSW",
      city_name: "Massawa",
      country_code: "ER"
  },
  {
      id: "MSX",
      name: "Mossendjo",
      area_code: "MSX",
      city_name: "Mossendjo",
      country_code: "CG"
  },
  {
      id: "MTA",
      name: "Matamata",
      area_code: "MTA",
      city_name: "Matamata",
      country_code: "NZ"
  },
  {
      id: "MTB",
      name: "Montelibano",
      area_code: "MTB",
      city_name: "Montelibano",
      country_code: "CO"
  },
  {
      id: "MTD",
      name: "Mount Sandford Station",
      area_code: "MTD",
      city_name: "Mount Sandford Station",
      country_code: "AU"
  },
  {
      id: "MTE",
      name: "Monte Alegre",
      area_code: "MTE",
      city_name: "Monte Alegre",
      country_code: "BR"
  },
  {
      id: "MTF",
      name: "Mizan Teferi",
      area_code: "MTF",
      city_name: "Mizan Teferi",
      country_code: "ET"
  },
  {
      id: "MTG",
      name: "Mato Grosso",
      area_code: "MTG",
      city_name: "Mato Grosso",
      country_code: "BR"
  },
  {
      id: "MTI",
      name: "Mosteiros",
      area_code: "MTI",
      city_name: "Mosteiros",
      country_code: "CV"
  },
  {
      id: "MTK",
      name: "Makin",
      area_code: "MTK",
      city_name: "Makin",
      country_code: "KI"
  },
  {
      id: "MTM",
      name: "Metlakatla Seaplane Base",
      area_code: "MTM",
      city_name: "Metlakatla",
      country_code: "US"
  },
  {
      id: "MTN",
      name: "Martin State",
      area_code: "BWI",
      city_name: "Baltimore",
      country_code: "US"
  },
  {
      id: "MTQ",
      name: "Mitchell",
      area_code: "MTQ",
      city_name: "Mitchell",
      country_code: "AU"
  },
  {
      id: "MTU",
      name: "Montepuez",
      area_code: "MTU",
      city_name: "Montepuez",
      country_code: "MZ"
  },
  {
      id: "MTV",
      name: "Mota Lava Airport",
      area_code: "MTV",
      city_name: "Mota Lava Island",
      country_code: "VU"
  },
  {
      id: "MTZ",
      name: "Bar Yehuda",
      area_code: "MTZ",
      city_name: "Masada",
      country_code: "IL"
  },
  {
      id: "MUD",
      name: "Mueda",
      area_code: "MUD",
      city_name: "Mueda",
      country_code: "MZ"
  },
  {
      id: "MUF",
      name: "Muting",
      area_code: "MUF",
      city_name: "Muting",
      country_code: "ID"
  },
  {
      id: "MUG",
      name: "Mulege",
      area_code: "MUG",
      city_name: "Mulege",
      country_code: "MX"
  },
  {
      id: "MUI",
      name: "Muir AAF",
      area_code: "MUI",
      city_name: "Fort Indiantown Gap",
      country_code: "US"
  },
  {
      id: "MUJ",
      name: "Mui",
      area_code: "MUJ",
      city_name: "Mui",
      country_code: "ET"
  },
  {
      id: "MUK",
      name: "Mauke Island",
      area_code: "MUK",
      city_name: "Mauke Island",
      country_code: "CK"
  },
  {
      id: "MUP",
      name: "Mulga Park",
      area_code: "MUP",
      city_name: "Mulga Park",
      country_code: "AU"
  },
  {
      id: "MUQ",
      name: "Muccan Station",
      area_code: "MUQ",
      city_name: "Muccan Station",
      country_code: "AU"
  },
  {
      id: "MUS",
      name: "Minamitorishima",
      area_code: "MUS",
      city_name: "Minamitorishima",
      country_code: "JP"
  },
  {
      id: "MUT",
      name: "Municipal",
      area_code: "MUT",
      city_name: "Muscatine",
      country_code: "US"
  },
  {
      id: "MUW",
      name: "Ghriss",
      area_code: "MUW",
      city_name: "Mascara",
      country_code: "DZ"
  },
  {
      id: "MUY",
      name: "Mouyondzi",
      area_code: "MUY",
      city_name: "Mouyondzi",
      country_code: "CG"
  },
  {
      id: "MVA",
      name: "Reykjahlid",
      area_code: "MVA",
      city_name: "Myvatn",
      country_code: "IS"
  },
  {
      id: "MVE",
      name: "Chippewa County",
      area_code: "MVE",
      city_name: "Montevideo",
      country_code: "US"
  },
  {
      id: "MVG",
      name: "Mevang",
      area_code: "MVG",
      city_name: "Mevang",
      country_code: "GA"
  },
  {
      id: "MVH",
      name: "Macksville",
      area_code: "MVH",
      city_name: "Macksville",
      country_code: "AU"
  },
  {
      id: "MVI",
      name: "Manetai",
      area_code: "MVI",
      city_name: "Manetai",
      country_code: "PG"
  },
  {
      id: "MVK",
      name: "Mulka",
      area_code: "MVK",
      city_name: "Mulka",
      country_code: "AU"
  },
  {
      id: "MVL",
      name: "State",
      area_code: "MVL",
      city_name: "Morrisville/Stowe",
      country_code: "US"
  },
  {
      id: "MVM",
      name: "Kayenta",
      area_code: "MVM",
      city_name: "Kayenta",
      country_code: "US"
  },
  {
      id: "MVO",
      name: "Mongo",
      area_code: "MVO",
      city_name: "Mongo",
      country_code: "TD"
  },
  {
      id: "MVS",
      name: "Mucuri",
      area_code: "MVS",
      city_name: "Mucuri",
      country_code: "BR"
  },
  {
      id: "MVT",
      name: "MATAIVA AIRPORT",
      area_code: "MVT",
      city_name: "Mataiva",
      country_code: "PF"
  },
  {
      id: "MVU",
      name: "Musgrave",
      area_code: "MVU",
      city_name: "Musgrave",
      country_code: "AU"
  },
  {
      id: "MVW",
      name: "Skagit Regional",
      area_code: "MVW",
      city_name: "Burlington/Mt Vernon",
      country_code: "US"
  },
  {
      id: "MVX",
      name: "Minvoul",
      area_code: "MVX",
      city_name: "Minvoul",
      country_code: "GA"
  },
  {
      id: "MWB",
      name: "Morawa",
      area_code: "MWB",
      city_name: "Morawa",
      country_code: "AU"
  },
  {
      id: "MWD",
      name: "Mianwali",
      area_code: "MWD",
      city_name: "Mianwali",
      country_code: "PK"
  },
  {
      id: "MWE",
      name: "Merowe",
      area_code: "MWE",
      city_name: "Merowe",
      country_code: "SD"
  },
  {
      id: "MWF",
      name: "Maewo Airport",
      area_code: "MWF",
      city_name: "Maewo Island",
      country_code: "VU"
  },
  {
      id: "MWG",
      name: "Marawaka",
      area_code: "MWG",
      city_name: "Marawaka",
      country_code: "PG"
  },
  {
      id: "MWI",
      name: "Maramuni",
      area_code: "MWI",
      city_name: "Maramuni",
      country_code: "PG"
  },
  {
      id: "MWJ",
      name: "Matthews Ridge",
      area_code: "MWJ",
      city_name: "Matthews Ridge",
      country_code: "GY"
  },
  {
      id: "MWK",
      name: "Matak",
      area_code: "MWK",
      city_name: "Matak",
      country_code: "ID"
  },
  {
      id: "MWM",
      name: "Municipal",
      area_code: "MWM",
      city_name: "Windom",
      country_code: "US"
  },
  {
      id: "MWN",
      name: "Mwadui",
      area_code: "MWN",
      city_name: "Mwadui",
      country_code: "TZ"
  },
  {
      id: "MWQ",
      name: "Magway",
      area_code: "MWQ",
      city_name: "Magway",
      country_code: "MM"
  },
  {
      id: "MWR",
      name: "Motswari",
      area_code: "MWR",
      city_name: "Motswari",
      country_code: "ZA"
  },
  {
      id: "MWT",
      name: "Moolawatana",
      area_code: "MWT",
      city_name: "Moolawatana",
      country_code: "AU"
  },
  {
      id: "MWU",
      name: "Mussau Island",
      area_code: "MWU",
      city_name: "Mussau Island",
      country_code: "PG"
  },
  {
      id: "MWV",
      name: "Mondulkiri",
      area_code: "MWV",
      city_name: "Mondulkiri",
      country_code: "KH"
  },
  {
      id: "MWY",
      name: "Miranda Downs",
      area_code: "MWY",
      city_name: "Miranda Downs",
      country_code: "AU"
  },
  {
      id: "MXB",
      name: "Andi Jemma",
      area_code: "MXB",
      city_name: "Masamba",
      country_code: "ID"
  },
  {
      id: "MXC",
      name: "Monticello",
      area_code: "MXC",
      city_name: "Monticello",
      country_code: "US"
  },
  {
      id: "MXD",
      name: "Marion Downs",
      area_code: "MXD",
      city_name: "Marion Downs",
      country_code: "AU"
  },
  {
      id: "MXE",
      name: "Laurinburg/Maxton",
      area_code: "MXE",
      city_name: "Laurinburg/Maxton",
      country_code: "US"
  },
  {
      id: "MXG",
      name: "Marlborough",
      area_code: "MXG",
      city_name: "Marlborough",
      country_code: "US"
  },
  {
      id: "MXH",
      name: "Moro Airport",
      area_code: "MXH",
      city_name: "Moro",
      country_code: "PG"
  },
  {
      id: "MXI",
      name: "Imelda R. Marcos",
      area_code: "MXI",
      city_name: "Mati",
      country_code: "PH"
  },
  {
      id: "MXJ",
      name: "Minna",
      area_code: "MXJ",
      city_name: "Minna",
      country_code: "NG"
  },
  {
      id: "MXK",
      name: "Mindik",
      area_code: "MXK",
      city_name: "Mindik",
      country_code: "PG"
  },
  {
      id: "MXM",
      name: "Morombe",
      area_code: "MXM",
      city_name: "Morombe",
      country_code: "MG"
  },
  {
      id: "MXO",
      name: "Regional",
      area_code: "MXO",
      city_name: "Monticello",
      country_code: "US"
  },
  {
      id: "MXR",
      name: "Myrhorod",
      area_code: "MXR",
      city_name: "Myrhorod",
      country_code: "UA"
  },
  {
      id: "MXT",
      name: "Maintirano",
      area_code: "MXT",
      city_name: "Maintirano",
      country_code: "MG"
  },
  {
      id: "MXU",
      name: "Mullewa",
      area_code: "MXU",
      city_name: "Mullewa",
      country_code: "AU"
  },
  {
      id: "MYB",
      name: "Mayumba",
      area_code: "MYB",
      city_name: "Mayumba",
      country_code: "GA"
  },
  {
      id: "MYG",
      name: "MAYAGUANA AIRPORT",
      area_code: "MYG",
      city_name: "Mayaguana",
      country_code: "BS"
  },
  {
      id: "MYI",
      name: "Murray Island",
      area_code: "MYI",
      city_name: "Murray Island",
      country_code: "AU"
  },
  {
      id: "MYM",
      name: "Monkey Mountain",
      area_code: "MYM",
      city_name: "Monkey Mountain",
      country_code: "GY"
  },
  {
      id: "MYN",
      name: "Marib",
      area_code: "MYN",
      city_name: "Marib",
      country_code: "YE"
  },
  {
      id: "MYO",
      name: "Myroodah Station",
      area_code: "MYO",
      city_name: "Myroodah Station",
      country_code: "AU"
  },
  {
      id: "MYU",
      name: "Mekoryuk Airport",
      area_code: "MYU",
      city_name: "Mekoryuk",
      country_code: "US"
  },
  {
      id: "MYX",
      name: "Menyamya",
      area_code: "MYX",
      city_name: "Menyamya",
      country_code: "PG"
  },
  {
      id: "MYZ",
      name: "Monkey Bay",
      area_code: "MYZ",
      city_name: "Monkey Bay",
      country_code: "MW"
  },
  {
      id: "MZA",
      name: "Manuel Prado",
      area_code: "MZA",
      city_name: "Mazamari",
      country_code: "PE"
  },
  {
      id: "MZB",
      name: "Mocimboa da Praia",
      area_code: "MZB",
      city_name: "Mocimboa da Praia",
      country_code: "MZ"
  },
  {
      id: "MZC",
      name: "Mitzic",
      area_code: "MZC",
      city_name: "Mitzic",
      country_code: "GA"
  },
  {
      id: "MZD",
      name: "Mendez",
      area_code: "MZD",
      city_name: "Mendez",
      country_code: "EC"
  },
  {
      id: "MZE",
      name: "Manatee",
      area_code: "MZE",
      city_name: "Manatee",
      country_code: "BZ"
  },
  {
      id: "MZF",
      name: "Wild Coast Sun",
      area_code: "MZF",
      city_name: "Mzamba",
      country_code: "ZA"
  },
  {
      id: "MZK",
      name: "Marakei",
      area_code: "MZK",
      city_name: "Marakei",
      country_code: "KI"
  },
  {
      id: "MZN",
      name: "Minj",
      area_code: "MZN",
      city_name: "Minj",
      country_code: "PG"
  },
  {
      id: "MZP",
      name: "Motueka",
      area_code: "MZP",
      city_name: "Motueka",
      country_code: "NZ"
  },
  {
      id: "MZQ",
      name: "Mkuze",
      area_code: "MZQ",
      city_name: "Mkuze",
      country_code: "ZA"
  },
  {
      id: "MZW",
      name: "Mecheria",
      area_code: "MZW",
      city_name: "Mecheria",
      country_code: "DZ"
  },
  {
      id: "MZX",
      name: "Mena",
      area_code: "MZX",
      city_name: "Mena",
      country_code: "ET"
  },
  {
      id: "MZY",
      name: "Mossel Bay",
      area_code: "MZY",
      city_name: "Mossel Bay",
      country_code: "ZA"
  },
  {
      id: "MZZ",
      name: "Municipal",
      area_code: "MZZ",
      city_name: "Marion",
      country_code: "US"
  },
  {
      id: "NAD",
      name: "Macanal",
      area_code: "NAD",
      city_name: "Macanal",
      country_code: "CO"
  },
  {
      id: "NAE",
      name: "Natitingou",
      area_code: "NAE",
      city_name: "Natitingou",
      country_code: "BJ"
  },
  {
      id: "NAF",
      name: "Banaina",
      area_code: "NAF",
      city_name: "Banaina",
      country_code: "ID"
  },
  {
      id: "NAH",
      name: "Naha Airport",
      area_code: "NAH",
      city_name: "Tahuna",
      country_code: "ID"
  },
  {
      id: "NAI",
      name: "Annai",
      area_code: "NAI",
      city_name: "Annai",
      country_code: "GY"
  },
  {
      id: "NAQ",
      name: "QAANAAQ AIRPORT",
      area_code: "NAQ",
      city_name: "Qaanaaq",
      country_code: "GL"
  },
  {
      id: "NAU",
      name: "Napuka Airport",
      area_code: "NAU",
      city_name: "Napuka",
      country_code: "PF"
  },
  {
      id: "NAZ",
      name: "Nana",
      area_code: "NAZ",
      city_name: "Star Harbour",
      country_code: "SB"
  },
  {
      id: "NBA",
      name: "Nambaiyufa",
      area_code: "NBA",
      city_name: "Nambaiyufa",
      country_code: "PG"
  },
  {
      id: "NBB",
      name: "Barranco Minas",
      area_code: "NBB",
      city_name: "Barranco Minas",
      country_code: "CO"
  },
  {
      id: "NBH",
      name: "Nambucca",
      area_code: "NBH",
      city_name: "Nambucca",
      country_code: "AU"
  },
  {
      id: "NBL",
      name: "Wannukandi",
      area_code: "NBL",
      city_name: "San Blas",
      country_code: "PA"
  },
  {
      id: "NBN",
      name: "Annobon Airport",
      area_code: "NBN",
      city_name: "Annobon",
      country_code: "GQ"
  },
  {
      id: "NCG",
      name: "Nuevo Casas Grandes",
      area_code: "NCG",
      city_name: "Nuevo Casas Grandes",
      country_code: "MX"
  },
  {
      id: "NCH",
      name: "Nachingwea",
      area_code: "NCH",
      city_name: "Nachingwea",
      country_code: "TZ"
  },
  {
      id: "NCJ",
      name: "Sunchales",
      area_code: "NCJ",
      city_name: "Sunchales",
      country_code: "AR"
  },
  {
      id: "NCN",
      name: "Chenega Bay",
      area_code: "NCN",
      city_name: "Chenega",
      country_code: "US"
  },
  {
      id: "NCO",
      name: "Quonset State",
      area_code: "NCO",
      city_name: "North Kingstown",
      country_code: "US"
  },
  {
      id: "NCT",
      name: "Nicoya",
      area_code: "NCT",
      city_name: "Nicoya",
      country_code: "CR"
  },
  {
      id: "NDA",
      name: "Bandaneira",
      area_code: "NDA",
      city_name: "Banda Island",
      country_code: "ID"
  },
  {
      id: "NDD",
      name: "Sumbe",
      area_code: "NDD",
      city_name: "Sumbe",
      country_code: "AO"
  },
  {
      id: "NDE",
      name: "Mandera",
      area_code: "NDE",
      city_name: "Mandera",
      country_code: "KE"
  },
  {
      id: "NDF",
      name: "N'dalatando",
      area_code: "NDF",
      city_name: "N'dalatando",
      country_code: "AO"
  },
  {
      id: "NDI",
      name: "Namudi",
      area_code: "NDI",
      city_name: "Namudi",
      country_code: "PG"
  },
  {
      id: "NDL",
      name: "Ndele",
      area_code: "NDL",
      city_name: "Ndele",
      country_code: "CF"
  },
  {
      id: "NDM",
      name: "Masslo",
      area_code: "NDM",
      city_name: "Mendi",
      country_code: "ET"
  },
  {
      id: "NDN",
      name: "Nadunumu",
      area_code: "NDN",
      city_name: "Nadunumu",
      country_code: "PG"
  },
  {
      id: "NDS",
      name: "Sandstone",
      area_code: "NDS",
      city_name: "Sandstone",
      country_code: "AU"
  },
  {
      id: "NEC",
      name: "Necochea",
      area_code: "NEC",
      city_name: "Necochea",
      country_code: "AR"
  },
  {
      id: "NEF",
      name: "Neftekamsk",
      area_code: "NEF",
      city_name: "Neftekamsk",
      country_code: "RU"
  },
  {
      id: "NEI",
      name: "Terney Airport",
      area_code: "NEI",
      city_name: "Terney",
      country_code: "RU"
  },
  {
      id: "NEJ",
      name: "Nejo",
      area_code: "NEJ",
      city_name: "Nejo",
      country_code: "ET"
  },
  {
      id: "NEK",
      name: "Nekemte",
      area_code: "NEK",
      city_name: "Nekemte",
      country_code: "ET"
  },
  {
      id: "NEL",
      name: "Maxfield Field",
      area_code: "NEL",
      city_name: "Lakehurst",
      country_code: "US"
  },
  {
      id: "NEN",
      name: "Whitehouse NOLF",
      area_code: "JAX",
      city_name: "Jacksonville",
      country_code: "US"
  },
  {
      id: "NEU",
      name: "Sam Neua Airport",
      area_code: "NEU",
      city_name: "Sam Neua",
      country_code: "LA"
  },
  {
      id: "NFO",
      name: "Mata'aho",
      area_code: "NFO",
      city_name: "Niuafo'ou",
      country_code: "TO"
  },
  {
      id: "NFR",
      name: "Nafoora",
      area_code: "NFR",
      city_name: "Nafoora",
      country_code: "LY"
  },
  {
      id: "NGF",
      name: "Kaneohe Bay MCAS",
      area_code: "NGF",
      city_name: "Kaneohe",
      country_code: "US"
  },
  {
      id: "NGI",
      name: "Gau Island",
      area_code: "NGI",
      city_name: "Gau Island",
      country_code: "FJ"
  },
  {
      id: "NGL",
      name: "Ngala",
      area_code: "NGL",
      city_name: "Ngala",
      country_code: "ZA"
  },
  {
      id: "NGN",
      name: "Nargana",
      area_code: "NGN",
      city_name: "Nargana",
      country_code: "PA"
  },
  {
      id: "NGR",
      name: "Ningerum",
      area_code: "NGR",
      city_name: "Ningerum",
      country_code: "PG"
  },
  {
      id: "NGX",
      name: "Manang",
      area_code: "NGX",
      city_name: "Manang",
      country_code: "NP"
  },
  {
      id: "NHD",
      name: "Minhad AB",
      area_code: "DXB",
      city_name: "Dubai",
      country_code: "AE"
  },
  {
      id: "NHF",
      name: "New Halfa",
      area_code: "NHF",
      city_name: "New Halfa",
      country_code: "SD"
  },
  {
      id: "NHK",
      name: "NAS (Trapnell Field)",
      area_code: "NHK",
      city_name: "Patuxent River",
      country_code: "US"
  },
  {
      id: "NHS",
      name: "Nushki",
      area_code: "NHS",
      city_name: "Nushki",
      country_code: "PK"
  },
  {
      id: "NHT",
      name: "RAF Station",
      area_code: "NHT",
      city_name: "Northolt",
      country_code: "GB"
  },
  {
      id: "NHX",
      name: "Barin NOLF",
      area_code: "NHX",
      city_name: "Foley",
      country_code: "US"
  },
  {
      id: "NIA",
      name: "Nimba",
      area_code: "NIA",
      city_name: "Nimba",
      country_code: "LR"
  },
  {
      id: "NIB",
      name: "NIKOLAI AIRPORT",
      area_code: "NIB",
      city_name: "Nikolai",
      country_code: "US"
  },
  {
      id: "NIF",
      name: "NIFTY AIRPORT",
      area_code: "NIF",
      city_name: "Nifty",
      country_code: "AU"
  },
  {
      id: "NIK",
      name: "Niokolo-Koba",
      area_code: "NIK",
      city_name: "Niokolo-Koba",
      country_code: "SN"
  },
  {
      id: "NIO",
      name: "Nioki",
      area_code: "NIO",
      city_name: "Nioki",
      country_code: "CD"
  },
  {
      id: "NIR",
      name: "Chase Field Industrial",
      area_code: "NIR",
      city_name: "Beeville",
      country_code: "US"
  },
  {
      id: "NIS",
      name: "Simberi Island",
      area_code: "NIS",
      city_name: "Simberi Island",
      country_code: "PG"
  },
  {
      id: "NIU",
      name: "Niau Airport",
      area_code: "NIU",
      city_name: "Niau",
      country_code: "PF"
  },
  {
      id: "NIX",
      name: "Nioro du Sahel",
      area_code: "NIX",
      city_name: "Nioro du Sahel",
      country_code: "ML"
  },
  {
      id: "NJJ",
      name: "Nenjiang Melgen",
      area_code: "HEK",
      city_name: "Heihe",
      country_code: "CN"
  },
  {
      id: "NKA",
      name: "Nkan",
      area_code: "NKA",
      city_name: "Nkan",
      country_code: "GA"
  },
  {
      id: "NKB",
      name: "Noonkanbah",
      area_code: "NKB",
      city_name: "Noonkanbah",
      country_code: "AU"
  },
  {
      id: "NKD",
      name: "Sinak",
      area_code: "NKD",
      city_name: "Sinak",
      country_code: "ID"
  },
  {
      id: "NKL",
      name: "Fuma",
      area_code: "NKL",
      city_name: "Kolo",
      country_code: "CD"
  },
  {
      id: "NKN",
      name: "Nankina",
      area_code: "NKN",
      city_name: "Nankina",
      country_code: "PG"
  },
  {
      id: "NKO",
      name: "Ankokoambo",
      area_code: "NKO",
      city_name: "Ankokoambo",
      country_code: "MG"
  },
  {
      id: "NKP",
      name: "Nukutepipi",
      area_code: "NKP",
      city_name: "Nukutepipi",
      country_code: "PF"
  },
  {
      id: "NKS",
      name: "Nkongsamba",
      area_code: "NKS",
      city_name: "Nkongsamba",
      country_code: "CM"
  },
  {
      id: "NKT",
      name: "Sirnak Airport",
      area_code: "NKT",
      city_name: "Sirnak",
      country_code: "TR"
  },
  {
      id: "NKU",
      name: "Nkau",
      area_code: "NKU",
      city_name: "Nkau",
      country_code: "LS"
  },
  {
      id: "NKY",
      name: "Yokangassi",
      area_code: "NKY",
      city_name: "N'kayi",
      country_code: "CG"
  },
  {
      id: "NLE",
      name: "Jerry Tyler Memorial",
      area_code: "NLE",
      city_name: "Niles",
      country_code: "US"
  },
  {
      id: "NLF",
      name: "Darnley Island",
      area_code: "NLF",
      city_name: "Darnley Island",
      country_code: "AU"
  },
  {
      id: "NLH",
      name: "Luguhu Airport",
      area_code: "NLH",
      city_name: "Ninglang",
      country_code: "CN"
  },
  {
      id: "NLI",
      name: "Nikolayevsk-na-Amure",
      area_code: "NLI",
      city_name: "Nikolayevsk-na-Amure",
      country_code: "RU"
  },
  {
      id: "NLL",
      name: "Nullagine",
      area_code: "NLL",
      city_name: "Nullagine",
      country_code: "AU"
  },
  {
      id: "NLN",
      name: "Kneeland",
      area_code: "ACV",
      city_name: "Arcata/Eureka",
      country_code: "US"
  },
  {
      id: "NLS",
      name: "Nicholson",
      area_code: "NLS",
      city_name: "Nicholson",
      country_code: "AU"
  },
  {
      id: "NLV",
      name: "International",
      area_code: "NLV",
      city_name: "Mykolaiv",
      country_code: "UA"
  },
  {
      id: "NMC",
      name: "Norman's Cay",
      area_code: "NMC",
      city_name: "Norman's Cay",
      country_code: "BS"
  },
  {
      id: "NME",
      name: "NIGHTMUTE AIRPORT",
      area_code: "NME",
      city_name: "Nightmute",
      country_code: "US"
  },
  {
      id: "NMG",
      name: "San Miguel",
      area_code: "NMG",
      city_name: "San Miguel",
      country_code: "PA"
  },
  {
      id: "NML",
      name: "Mildred Lake",
      area_code: "YMM",
      city_name: "Fort McMurray",
      country_code: "CA"
  },
  {
      id: "NMN",
      name: "Nomane",
      area_code: "NMN",
      city_name: "Nomane",
      country_code: "PG"
  },
  {
      id: "NMP",
      name: "New Moon",
      area_code: "NMP",
      city_name: "New Moon",
      country_code: "AU"
  },
  {
      id: "NMR",
      name: "Nappa Merrie",
      area_code: "NMR",
      city_name: "Nappa Merrie",
      country_code: "AU"
  },
  {
      id: "NMS",
      name: "Nansang",
      area_code: "NMS",
      city_name: "Nansang",
      country_code: "MM"
  },
  {
      id: "NMT",
      name: "Namtu",
      area_code: "NMT",
      city_name: "Namtu",
      country_code: "MM"
  },
  {
      id: "NNA",
      name: "Royal AFB",
      area_code: "NNA",
      city_name: "Kenitra",
      country_code: "MA"
  },
  {
      id: "NNB",
      name: "Santa Ana Airport",
      area_code: "NNB",
      city_name: "Santa Ana Island",
      country_code: "SB"
  },
  {
      id: "NND",
      name: "Nangade",
      area_code: "NND",
      city_name: "Nangade",
      country_code: "MZ"
  },
  {
      id: "NNI",
      name: "Namutoni",
      area_code: "NNI",
      city_name: "Namutoni",
      country_code: "NA"
  },
  {
      id: "NNU",
      name: "Nanuque",
      area_code: "NNU",
      city_name: "Nanuque",
      country_code: "BR"
  },
  {
      id: "NNX",
      name: "NUNUKAN AIRPORT",
      area_code: "NNX",
      city_name: "Nunukan",
      country_code: "ID"
  },
  {
      id: "NOD",
      name: "Norddeich",
      area_code: "NOD",
      city_name: "Norden",
      country_code: "DE"
  },
  {
      id: "NOI",
      name: "Krymska",
      area_code: "NOI",
      city_name: "Novorossiysk",
      country_code: "RU"
  },
  {
      id: "NOK",
      name: "Nova Xavantina",
      area_code: "NOK",
      city_name: "Nova Xavantina",
      country_code: "BR"
  },
  {
      id: "NOM",
      name: "Nomad River",
      area_code: "NOM",
      city_name: "Nomad River",
      country_code: "PG"
  },
  {
      id: "NON",
      name: "Nonouti",
      area_code: "NON",
      city_name: "Nonouti",
      country_code: "KI"
  },
  {
      id: "NOO",
      name: "Naoro",
      area_code: "NOO",
      city_name: "Naoro",
      country_code: "PG"
  },
  {
      id: "NOR",
      name: "Nordfjordur",
      area_code: "NOR",
      city_name: "Nordfjordur",
      country_code: "IS"
  },
  {
      id: "NOV",
      name: "Albano Machado Airport",
      area_code: "NOV",
      city_name: "Huambo",
      country_code: "AO"
  },
  {
      id: "NPG",
      name: "Nipa",
      area_code: "NPG",
      city_name: "Nipa",
      country_code: "PG"
  },
  {
      id: "NPH",
      name: "Municipal",
      area_code: "NPH",
      city_name: "Nephi",
      country_code: "US"
  },
  {
      id: "NPO",
      name: "Nangapinoh",
      area_code: "NPO",
      city_name: "Nangapinoh",
      country_code: "ID"
  },
  {
      id: "NPP",
      name: "Napperby",
      area_code: "NPP",
      city_name: "Napperby",
      country_code: "AU"
  },
  {
      id: "NPR",
      name: "Novo Progresso",
      area_code: "NPR",
      city_name: "Novo Progresso",
      country_code: "BR"
  },
  {
      id: "NPU",
      name: "San Pedro de Uraba",
      area_code: "NPU",
      city_name: "San Pedro de Uraba",
      country_code: "CO"
  },
  {
      id: "NQL",
      name: "Niquelandia",
      area_code: "NQL",
      city_name: "Niquelandia",
      country_code: "BR"
  },
  {
      id: "NRB",
      name: "Naval Station",
      area_code: "NRB",
      city_name: "Mayport",
      country_code: "US"
  },
  {
      id: "NRD",
      name: "Norderney",
      area_code: "NRD",
      city_name: "Norderney",
      country_code: "DE"
  },
  {
      id: "NRE",
      name: "Namrole",
      area_code: "NRE",
      city_name: "Namrole",
      country_code: "ID"
  },
  {
      id: "NRG",
      name: "Narrogin",
      area_code: "NRG",
      city_name: "Narrogin",
      country_code: "AU"
  },
  {
      id: "NRI",
      name: "Grand Lake Regional",
      area_code: "NRI",
      city_name: "Afton",
      country_code: "US"
  },
  {
      id: "NRM",
      name: "Keibane",
      area_code: "NRM",
      city_name: "Nara",
      country_code: "ML"
  },
  {
      id: "NRR",
      name: "Jose Aponte de la Torre Airport",
      area_code: "NRR",
      city_name: "Ceiba",
      country_code: "PR"
  },
  {
      id: "NRY",
      name: "Newry",
      area_code: "NRY",
      city_name: "Newry",
      country_code: "AU"
  },
  {
      id: "NSH",
      name: "NOW SHAHR AIRPORT",
      area_code: "NSH",
      city_name: "Now Shahr",
      country_code: "IR"
  },
  {
      id: "NSL",
      name: "Municipal",
      area_code: "NSL",
      city_name: "Slayton",
      country_code: "US"
  },
  {
      id: "NSV",
      name: "Noosa",
      area_code: "NSV",
      city_name: "Noosaville",
      country_code: "AU"
  },
  {
      id: "NSY",
      name: "NAS",
      area_code: "NSY",
      city_name: "Sigonella",
      country_code: "IT"
  },
  {
      id: "NTA",
      name: "Natadola",
      area_code: "NTA",
      city_name: "Natadola",
      country_code: "FJ"
  },
  {
      id: "NTC",
      name: "Santa Carolina",
      area_code: "NTC",
      city_name: "Santa Carolina",
      country_code: "MZ"
  },
  {
      id: "NTI",
      name: "Stenkol",
      area_code: "NTI",
      city_name: "Bintuni",
      country_code: "ID"
  },
  {
      id: "NTJ",
      name: "Manti/Ephraim",
      area_code: "NTJ",
      city_name: "Manti/Ephraim",
      country_code: "US"
  },
  {
      id: "NTM",
      name: "Miracema do Tocantins",
      area_code: "NTM",
      city_name: "Miracema do Tocantins",
      country_code: "BR"
  },
  {
      id: "NTT",
      name: "Kuini Lavenia",
      area_code: "NTT",
      city_name: "Niuatoputapu",
      country_code: "TO"
  },
  {
      id: "NTU",
      name: "Oceana NAS",
      area_code: "NTU",
      city_name: "Virginia Beach",
      country_code: "US"
  },
  {
      id: "NTX",
      name: "Ranai Airport",
      area_code: "NTX",
      city_name: "Natuna",
      country_code: "ID"
  },
  {
      id: "NUB",
      name: "Numbulwar",
      area_code: "NUB",
      city_name: "Numbulwar",
      country_code: "AU"
  },
  {
      id: "NUD",
      name: "En Nahud",
      area_code: "NUD",
      city_name: "En Nahud",
      country_code: "SD"
  },
  {
      id: "NUF",
      name: "Hatton Spb",
      area_code: "NUF",
      city_name: "Hatton",
      country_code: "LK"
  },
  {
      id: "NUG",
      name: "Nuguria Islands",
      area_code: "NUG",
      city_name: "Nuguria Islands",
      country_code: "PG"
  },
  {
      id: "NUH",
      name: "Nunchia",
      area_code: "NUH",
      city_name: "Nunchia",
      country_code: "CO"
  },
  {
      id: "NUI",
      name: "NUIQSUT AIRPORT",
      area_code: "NUI",
      city_name: "Nuiqsut",
      country_code: "US"
  },
  {
      id: "NUP",
      name: "NUNAPITCHUK AIRPORT",
      area_code: "NUP",
      city_name: "Nunapitchuk",
      country_code: "US"
  },
  {
      id: "NUT",
      name: "Nutuve",
      area_code: "NUT",
      city_name: "Nutuve",
      country_code: "PG"
  },
  {
      id: "NUW",
      name: "Whidbey Island NAS",
      area_code: "ODW",
      city_name: "Oak Harbor",
      country_code: "US"
  },
  {
      id: "NVD",
      name: "Municipal",
      area_code: "NVD",
      city_name: "Nevada",
      country_code: "US"
  },
  {
      id: "NVG",
      name: "Nueva Guinea",
      area_code: "NVG",
      city_name: "Nueva Guinea",
      country_code: "NI"
  },
  {
      id: "NVN",
      name: "Nervino",
      area_code: "NVN",
      city_name: "Beckwourth",
      country_code: "US"
  },
  {
      id: "NVP",
      name: "Novo Aripuana",
      area_code: "NVP",
      city_name: "Novo Aripuana",
      country_code: "BR"
  },
  {
      id: "NVR",
      name: "Novgorod",
      area_code: "NVR",
      city_name: "Novgorod",
      country_code: "RU"
  },
  {
      id: "NVY",
      name: "Neyveli",
      area_code: "NVY",
      city_name: "Neyveli",
      country_code: "IN"
  },
  {
      id: "NWA",
      name: "Moheli Bandar Es Eslam Airport",
      area_code: "NWA",
      city_name: "Moheli",
      country_code: "KM"
  },
  {
      id: "NWH",
      name: "Parlin Field",
      area_code: "NWH",
      city_name: "Newport",
      country_code: "US"
  },
  {
      id: "NWT",
      name: "Nowata",
      area_code: "NWT",
      city_name: "Nowata",
      country_code: "PG"
  },
  {
      id: "NYG",
      name: "MCAF (Turner Field)",
      area_code: "NYG",
      city_name: "Quantico",
      country_code: "US"
  },
  {
      id: "NYI",
      name: "SUNYANI AIRPORT",
      area_code: "NYI",
      city_name: "Sunyani",
      country_code: "GH"
  },
  {
      id: "NYS",
      name: "Skyports SPB",
      area_code: "NYC",
      city_name: "New York",
      country_code: "US"
  },
  {
      id: "NYW",
      name: "Monywa Airport",
      area_code: "NYW",
      city_name: "Monywa",
      country_code: "MM"
  },
  {
      id: "NZA",
      name: "Nzagi",
      area_code: "NZA",
      city_name: "Nzagi",
      country_code: "AO"
  },
  {
      id: "NZE",
      name: "Nzerekore",
      area_code: "NZE",
      city_name: "Nzerekore",
      country_code: "GN"
  },
  {
      id: "NZL",
      name: "Chengjisihan Airport",
      area_code: "NZL",
      city_name: "Zhalantun",
      country_code: "CN"
  },
  {
      id: "OAA",
      name: "Air Base",
      area_code: "OAA",
      city_name: "Shank",
      country_code: "US"
  },
  {
      id: "OAH",
      name: "Air Base",
      area_code: "OAH",
      city_name: "Shindand",
      country_code: "AF"
  },
  {
      id: "OAI",
      name: "Bagram",
      area_code: "OAI",
      city_name: "Bagram",
      country_code: "AF"
  },
  {
      id: "OAN",
      name: "El Arrayan",
      area_code: "OAN",
      city_name: "Olanchito",
      country_code: "HN"
  },
  {
      id: "OAS",
      name: "Sharana Air Base",
      area_code: "OAS",
      city_name: "Sharana",
      country_code: "AF"
  },
  {
      id: "OAZ",
      name: "Camp Bastion",
      area_code: "OAZ",
      city_name: "Camp Bastion",
      country_code: "AF"
  },
  {
      id: "OBA",
      name: "Oban",
      area_code: "OBA",
      city_name: "Oban",
      country_code: "AU"
  },
  {
      id: "OBC",
      name: "Obock",
      area_code: "OBC",
      city_name: "Obock",
      country_code: "DJ"
  },
  {
      id: "OBD",
      name: "Obano",
      area_code: "OBD",
      city_name: "Obano",
      country_code: "ID"
  },
  {
      id: "OBF",
      name: "Oberpfaffenhofen",
      area_code: "OBF",
      city_name: "Oberpfaffenhofen",
      country_code: "DE"
  },
  {
      id: "OBI",
      name: "Obidos",
      area_code: "OBI",
      city_name: "Obidos",
      country_code: "BR"
  },
  {
      id: "OBL",
      name: "Oostmalle",
      area_code: "OBL",
      city_name: "Zoersel",
      country_code: "BE"
  },
  {
      id: "OBM",
      name: "Morobe",
      area_code: "OBM",
      city_name: "Morobe",
      country_code: "PG"
  },
  {
      id: "OBX",
      name: "Obo",
      area_code: "OBX",
      city_name: "Obo",
      country_code: "PG"
  },
  {
      id: "OCM",
      name: "Boolgeeda Airport",
      area_code: "OCM",
      city_name: "Boolgeeda",
      country_code: "AU"
  },
  {
      id: "OCS",
      name: "Corisco Island",
      area_code: "OCS",
      city_name: "Corisco Island",
      country_code: "GQ"
  },
  {
      id: "OCW",
      name: "Warren Field",
      area_code: "OCW",
      city_name: "Washington",
      country_code: "US"
  },
  {
      id: "ODA",
      name: "Ouadda",
      area_code: "ODA",
      city_name: "Ouadda",
      country_code: "CF"
  },
  {
      id: "ODC",
      name: "Oakdale",
      area_code: "ODC",
      city_name: "Oakdale",
      country_code: "US"
  },
  {
      id: "ODD",
      name: "Oodnadatta",
      area_code: "ODD",
      city_name: "Oodnadatta",
      country_code: "AU"
  },
  {
      id: "ODH",
      name: "RAF Station",
      area_code: "ODH",
      city_name: "Odiham",
      country_code: "GB"
  },
  {
      id: "ODJ",
      name: "Ouanda Djalle",
      area_code: "ODJ",
      city_name: "Ouanda Djalle",
      country_code: "CF"
  },
  {
      id: "ODL",
      name: "Cordillo Downs",
      area_code: "ODL",
      city_name: "Cordillo Downs",
      country_code: "AU"
  },
  {
      id: "ODM",
      name: "Garrett County",
      area_code: "ODM",
      city_name: "Oakland",
      country_code: "US"
  },
  {
      id: "ODR",
      name: "Ord River",
      area_code: "ODR",
      city_name: "Ord River",
      country_code: "AU"
  },
  {
      id: "ODY",
      name: "Oudomxay Airport",
      area_code: "ODY",
      city_name: "Muang Xay",
      country_code: "LA"
  },
  {
      id: "OEC",
      name: "Oecussi",
      area_code: "OEC",
      city_name: "Oecussi",
      country_code: "TL"
  },
  {
      id: "OEL",
      name: "Yuzhny",
      area_code: "OEL",
      city_name: "Orel",
      country_code: "RU"
  },
  {
      id: "OEM",
      name: "Vincent Fayks",
      area_code: "OEM",
      city_name: "Paloemeu",
      country_code: "SR"
  },
  {
      id: "OEO",
      name: "L.O. Simenstad Muni",
      area_code: "OEO",
      city_name: "Osceola",
      country_code: "US"
  },
  {
      id: "OES",
      name: "Antoine de St Exupery",
      area_code: "OES",
      city_name: "San Antonio Oeste",
      country_code: "AR"
  },
  {
      id: "OFF",
      name: "Offutt AFB",
      area_code: "OMA",
      city_name: "Omaha",
      country_code: "US"
  },
  {
      id: "OFI",
      name: "Ouango Fitini",
      area_code: "OFI",
      city_name: "Ouango Fitini",
      country_code: "CI"
  },
  {
      id: "OFJ",
      name: "Olafsfjordur",
      area_code: "OFJ",
      city_name: "Olafsfjordur",
      country_code: "IS"
  },
  {
      id: "OFU",
      name: "Ofu Island",
      area_code: "OFU",
      city_name: "Ofu Island",
      country_code: "AS"
  },
  {
      id: "OGE",
      name: "Ogeramnang",
      area_code: "OGE",
      city_name: "Ogeramnang",
      country_code: "PG"
  },
  {
      id: "OGO",
      name: "Abengourou",
      area_code: "OGO",
      city_name: "Abengourou",
      country_code: "CI"
  },
  {
      id: "OGR",
      name: "Bongor",
      area_code: "OGR",
      city_name: "Bongor",
      country_code: "TD"
  },
  {
      id: "OGU",
      name: "Ordu-Giresun Airport",
      area_code: "OGU",
      city_name: "Ordu/Giresun",
      country_code: "TR"
  },
  {
      id: "OGV",
      name: "Ongava",
      area_code: "OGV",
      city_name: "Ongava",
      country_code: "NA"
  },
  {
      id: "OHB",
      name: "Ambohibary",
      area_code: "OHB",
      city_name: "Moramanga",
      country_code: "MG"
  },
  {
      id: "OHI",
      name: "Oshakati",
      area_code: "OHI",
      city_name: "Oshakati",
      country_code: "NA"
  },
  {
      id: "OHR",
      name: "Wyk Auf Foehr",
      area_code: "OHR",
      city_name: "Wyk auf Foehr",
      country_code: "DE"
  },
  {
      id: "OHT",
      name: "Kohat",
      area_code: "OHT",
      city_name: "Kohat",
      country_code: "PK"
  },
  {
      id: "OIC",
      name: "Lt. Warren Eaton",
      area_code: "OIC",
      city_name: "Norwich",
      country_code: "US"
  },
  {
      id: "OIR",
      name: "Okushiri Airport",
      area_code: "OIR",
      city_name: "Okushiri",
      country_code: "JP"
  },
  {
      id: "OJU",
      name: "Tanjung Api",
      area_code: "OJU",
      city_name: "Tojo Una Una",
      country_code: "ID"
  },
  {
      id: "OKB",
      name: "Orchid Beach",
      area_code: "OKB",
      city_name: "Fraser Island",
      country_code: "AU"
  },
  {
      id: "OKF",
      name: "Okaukuejo",
      area_code: "OKF",
      city_name: "Okaukuejo",
      country_code: "NA"
  },
  {
      id: "OKG",
      name: "Okoyo",
      area_code: "OKG",
      city_name: "Okoyo",
      country_code: "CG"
  },
  {
      id: "OKH",
      name: "Cottesmore RAF",
      area_code: "OKH",
      city_name: "Oakham",
      country_code: "GB"
  },
  {
      id: "OKI",
      name: "Oki Airport",
      area_code: "OKI",
      city_name: "Oki",
      country_code: "JP"
  },
  {
      id: "OKL",
      name: "Oksibil Airport",
      area_code: "OKL",
      city_name: "Oksibil",
      country_code: "ID"
  },
  {
      id: "OKM",
      name: "Regional",
      area_code: "OKM",
      city_name: "Okmulgee",
      country_code: "US"
  },
  {
      id: "OKN",
      name: "Okondja",
      area_code: "OKN",
      city_name: "Okondja",
      country_code: "GA"
  },
  {
      id: "OKP",
      name: "Oksapmin",
      area_code: "OKP",
      city_name: "Oksapmin",
      country_code: "PG"
  },
  {
      id: "OKQ",
      name: "Okaba",
      area_code: "OKQ",
      city_name: "Okaba",
      country_code: "ID"
  },
  {
      id: "OKR",
      name: "Yorke Island",
      area_code: "OKR",
      city_name: "Yorke Island",
      country_code: "AU"
  },
  {
      id: "OKS",
      name: "Garden County",
      area_code: "OKS",
      city_name: "Oshkosh",
      country_code: "US"
  },
  {
      id: "OKT",
      name: "Oktiabrsky",
      area_code: "OKT",
      city_name: "Oktiabrsky",
      country_code: "RU"
  },
  {
      id: "OKU",
      name: "Mokuti Lodge",
      area_code: "OKU",
      city_name: "Mokuti Lodge",
      country_code: "NA"
  },
  {
      id: "OKV",
      name: "Okao",
      area_code: "OKV",
      city_name: "Okao",
      country_code: "PG"
  },
  {
      id: "OKY",
      name: "Army Aviation Centre",
      area_code: "OKY",
      city_name: "Oakey",
      country_code: "AU"
  },
  {
      id: "OLA",
      name: "ORLAND AIRPORT",
      area_code: "OLA",
      city_name: "Orland",
      country_code: "NO"
  },
  {
      id: "OLC",
      name: "Sen. Eunice Micheles",
      area_code: "OLC",
      city_name: "Sao Paulo de Olivenca",
      country_code: "BR"
  },
  {
      id: "OLD",
      name: "Dewitt Fld Municipal",
      area_code: "OLD",
      city_name: "Old Town",
      country_code: "US"
  },
  {
      id: "OLE",
      name: "Cattaraugus County",
      area_code: "OLE",
      city_name: "Olean",
      country_code: "US"
  },
  {
      id: "OLF",
      name: "L. M. Clayton Airport",
      area_code: "OLF",
      city_name: "Wolf Point",
      country_code: "US"
  },
  {
      id: "OLG",
      name: "Olare",
      area_code: "MRE",
      city_name: "Maasai Mara",
      country_code: "KE"
  },
  {
      id: "OLH",
      name: "Old Harbor",
      area_code: "OLH",
      city_name: "Disneyland Paris Rail",
      country_code: "FR"
  },
  {
      id: "OLI",
      name: "Rif",
      area_code: "OLI",
      city_name: "Olafsvik",
      country_code: "IS"
  },
  {
      id: "OLJ",
      name: "North West Santo",
      area_code: "OLJ",
      city_name: "Olpoi",
      country_code: "VU"
  },
  {
      id: "OLL",
      name: "Ollombo",
      area_code: "OLL",
      city_name: "Oyo",
      country_code: "CG"
  },
  {
      id: "OLN",
      name: "Lago Musters",
      area_code: "OLN",
      city_name: "Sarmiento",
      country_code: "AR"
  },
  {
      id: "OLO",
      name: "Neredin",
      area_code: "OLO",
      city_name: "Olomouc",
      country_code: "CZ"
  },
  {
      id: "OLQ",
      name: "Olsobip",
      area_code: "OLQ",
      city_name: "Olsobip",
      country_code: "PG"
  },
  {
      id: "OLR",
      name: "Air Base",
      area_code: "OLR",
      city_name: "Salerno",
      country_code: "AF"
  },
  {
      id: "OLX",
      name: "Olkiombo",
      area_code: "MRE",
      city_name: "Maasai Mara",
      country_code: "KE"
  },
  {
      id: "OLY",
      name: "Olney/Noble",
      area_code: "OLY",
      city_name: "Olney/Noble",
      country_code: "US"
  },
  {
      id: "OMB",
      name: "Omboue-Hopital",
      area_code: "OMB",
      city_name: "Omboue",
      country_code: "GA"
  },
  {
      id: "OMC",
      name: "Ormoc Airport",
      area_code: "OMC",
      city_name: "Ormoc",
      country_code: "PH"
  },
  {
      id: "OMF",
      name: "King Hussein",
      area_code: "OMF",
      city_name: "Mafraq",
      country_code: "JO"
  },
  {
      id: "OMG",
      name: "Omega",
      area_code: "OMG",
      city_name: "Omega",
      country_code: "NA"
  },
  {
      id: "OMH",
      name: "Urumiyeh Airport",
      area_code: "OMH",
      city_name: "Urumiyeh",
      country_code: "IR"
  },
  {
      id: "OMI",
      name: "Omidiyeh",
      area_code: "OMI",
      city_name: "Omidiyeh",
      country_code: "IR"
  },
  {
      id: "OMJ",
      name: "Omura",
      area_code: "NGS",
      city_name: "Nagasaki",
      country_code: "JP"
  },
  {
      id: "OMK",
      name: "Omak",
      area_code: "OMK",
      city_name: "Omak",
      country_code: "US"
  },
  {
      id: "OML",
      name: "Omkalai",
      area_code: "OML",
      city_name: "Omkalai",
      country_code: "PG"
  },
  {
      id: "OMM",
      name: "Marmul",
      area_code: "OMM",
      city_name: "Marmul",
      country_code: "OM"
  },
  {
      id: "OMY",
      name: "Preah Vinhear",
      area_code: "OMY",
      city_name: "Thbeng Meanchey",
      country_code: "KH"
  },
  {
      id: "ONA",
      name: "Municipal",
      area_code: "ONA",
      city_name: "Winona",
      country_code: "US"
  },
  {
      id: "ONB",
      name: "Ononge",
      area_code: "ONB",
      city_name: "Ononge",
      country_code: "PG"
  },
  {
      id: "ONE",
      name: "Onepusu",
      area_code: "ONE",
      city_name: "Onepusu",
      country_code: "SB"
  },
  {
      id: "ONI",
      name: "Moanamani",
      area_code: "ONI",
      city_name: "Moanamani",
      country_code: "ID"
  },
  {
      id: "ONL",
      name: "O'Neill Municipal Airport",
      area_code: "ONL",
      city_name: "O'Neill",
      country_code: "US"
  },
  {
      id: "ONR",
      name: "Monkira",
      area_code: "ONR",
      city_name: "Monkira",
      country_code: "AU"
  },
  {
      id: "ONU",
      name: "Ono-i-Lau",
      area_code: "ONU",
      city_name: "Ono-i-Lau",
      country_code: "FJ"
  },
  {
      id: "ONX",
      name: "Enrique Adolfo Jimenez",
      area_code: "ONX",
      city_name: "Colon",
      country_code: "PA"
  },
  {
      id: "ONY",
      name: "Municipal",
      area_code: "ONY",
      city_name: "Olney",
      country_code: "US"
  },
  {
      id: "OOA",
      name: "Municipal",
      area_code: "OOA",
      city_name: "Oskaloosa",
      country_code: "US"
  },
  {
      id: "OOK",
      name: "TOKSOOK BAY AIRPORT",
      area_code: "OOK",
      city_name: "Toksook Bay",
      country_code: "US"
  },
  {
      id: "OOR",
      name: "Mooraberree",
      area_code: "OOR",
      city_name: "Mooraberree",
      country_code: "AU"
  },
  {
      id: "OOT",
      name: "Onotoa",
      area_code: "OOT",
      city_name: "Onotoa",
      country_code: "KI"
  },
  {
      id: "OPB",
      name: "Open Bay",
      area_code: "OPB",
      city_name: "Open Bay",
      country_code: "PG"
  },
  {
      id: "OPF",
      name: "Opa-locka Executive",
      area_code: "MIA",
      city_name: "Miami",
      country_code: "US"
  },
  {
      id: "OPI",
      name: "Oenpelli",
      area_code: "OPI",
      city_name: "Oenpelli",
      country_code: "AU"
  },
  {
      id: "OPL",
      name: "St Landry Parish",
      area_code: "OPL",
      city_name: "Opelousas",
      country_code: "US"
  },
  {
      id: "OPW",
      name: "Opuwo",
      area_code: "OPW",
      city_name: "Opuwo",
      country_code: "NA"
  },
  {
      id: "ORA",
      name: "Oran",
      area_code: "ORA",
      city_name: "Oran",
      country_code: "AR"
  },
  {
      id: "ORC",
      name: "Orocue",
      area_code: "ORC",
      city_name: "Orocue",
      country_code: "CO"
  },
  {
      id: "ORI",
      name: "Port Lions Airport",
      area_code: "ORI",
      city_name: "Port Lions",
      country_code: "US"
  },
  {
      id: "ORJ",
      name: "Orinduik",
      area_code: "ORJ",
      city_name: "Orinduik",
      country_code: "GY"
  },
  {
      id: "ORO",
      name: "Yoro",
      area_code: "ORO",
      city_name: "Yoro",
      country_code: "HN"
  },
  {
      id: "ORP",
      name: "Orapa",
      area_code: "ORP",
      city_name: "Orapa",
      country_code: "BW"
  },
  {
      id: "ORR",
      name: "Yorketown",
      area_code: "ORR",
      city_name: "Yorketown",
      country_code: "AU"
  },
  {
      id: "ORX",
      name: "Oriximina",
      area_code: "ORX",
      city_name: "Oriximina",
      country_code: "BR"
  },
  {
      id: "ORZ",
      name: "ORANGE WALK AIRPORT",
      area_code: "ORZ",
      city_name: "Orange Walk",
      country_code: "BZ"
  },
  {
      id: "OSB",
      name: "Grand Glaize",
      area_code: "OSB",
      city_name: "Osage Beach",
      country_code: "US"
  },
  {
      id: "OSC",
      name: "Wurtsmith",
      area_code: "OSC",
      city_name: "Oscoda",
      country_code: "US"
  },
  {
      id: "OSE",
      name: "Omora",
      area_code: "OSE",
      city_name: "Omora",
      country_code: "PG"
  },
  {
      id: "OSF",
      name: "Ostafyevo",
      area_code: "OSF",
      city_name: "Podolsk",
      country_code: "RU"
  },
  {
      id: "OSG",
      name: "Ossima",
      area_code: "OSG",
      city_name: "Ossima",
      country_code: "PG"
  },
  {
      id: "OSJ",
      name: "Ol Seki",
      area_code: "MRE",
      city_name: "Maasai Mara",
      country_code: "KE"
  },
  {
      id: "OSN",
      name: "Air Base",
      area_code: "OSN",
      city_name: "Osan",
      country_code: "KR"
  },
  {
      id: "OSO",
      name: "Osborne Mine Airport",
      area_code: "OSO",
      city_name: "Osborne Mine",
      country_code: "AU"
  },
  {
      id: "OSX",
      name: "Attala County",
      area_code: "OSX",
      city_name: "Kosciusko",
      country_code: "US"
  },
  {
      id: "OSZ",
      name: "Zegrze Pomorskie AB",
      area_code: "OSZ",
      city_name: "Koszalin",
      country_code: "PL"
  },
  {
      id: "OTA",
      name: "Mota",
      area_code: "OTA",
      city_name: "Mota",
      country_code: "ET"
  },
  {
      id: "OTC",
      name: "Berim",
      area_code: "OTC",
      city_name: "Bol",
      country_code: "TD"
  },
  {
      id: "OTI",
      name: "Pitu Airport",
      area_code: "OTI",
      city_name: "Morotai Island",
      country_code: "ID"
  },
  {
      id: "OTJ",
      name: "Otjiwarongo",
      area_code: "OTJ",
      city_name: "Otjiwarongo",
      country_code: "NA"
  },
  {
      id: "OTK",
      name: "Tillamook",
      area_code: "OTK",
      city_name: "Tillamook",
      country_code: "US"
  },
  {
      id: "OTL",
      name: "Boutilimit",
      area_code: "OTL",
      city_name: "Boutilimit",
      country_code: "MR"
  },
  {
      id: "OTN",
      name: "Ed-Air",
      area_code: "OTN",
      city_name: "Oaktown",
      country_code: "US"
  },
  {
      id: "OTR",
      name: "Coto 47",
      area_code: "OTR",
      city_name: "Coto 47",
      country_code: "CR"
  },
  {
      id: "OTT",
      name: "Andre Maggi",
      area_code: "OTT",
      city_name: "Cotriguacu",
      country_code: "BR"
  },
  {
      id: "OTV",
      name: "Ontong Java Atoll",
      area_code: "OTV",
      city_name: "Ontong Java Atoll",
      country_code: "SB"
  },
  {
      id: "OTY",
      name: "Oria",
      area_code: "OTY",
      city_name: "Oria",
      country_code: "PG"
  },
  {
      id: "OUG",
      name: "Ouahigouya",
      area_code: "OUG",
      city_name: "Ouahigouya",
      country_code: "BF"
  },
  {
      id: "OUK",
      name: "Out Skerries",
      area_code: "OUK",
      city_name: "Out Skerries",
      country_code: "GB"
  },
  {
      id: "OUM",
      name: "Oum Hadjer",
      area_code: "OUM",
      city_name: "Oum Hadjer",
      country_code: "TD"
  },
  {
      id: "OUN",
      name: "Max Westheimer",
      area_code: "OUN",
      city_name: "Norman",
      country_code: "US"
  },
  {
      id: "OUR",
      name: "Batouri",
      area_code: "OUR",
      city_name: "Batouri",
      country_code: "CM"
  },
  {
      id: "OUS",
      name: "Benedito Pimentel",
      area_code: "OUS",
      city_name: "Ourinhos",
      country_code: "BR"
  },
  {
      id: "OUU",
      name: "Ouanga",
      area_code: "OUU",
      city_name: "Ouanga",
      country_code: "GA"
  },
  {
      id: "OVA",
      name: "Bekily",
      area_code: "OVA",
      city_name: "Bekily",
      country_code: "MG"
  },
  {
      id: "OVG",
      name: "AFB",
      area_code: "OVG",
      city_name: "Overberg",
      country_code: "ZA"
  },
  {
      id: "OVL",
      name: "El Tuqui",
      area_code: "OVL",
      city_name: "Ovalle",
      country_code: "CL"
  },
  {
      id: "OVR",
      name: "Olavarria",
      area_code: "OVR",
      city_name: "Olavarria",
      country_code: "AR"
  },
  {
      id: "OWE",
      name: "Owendo",
      area_code: "OWE",
      city_name: "Owendo",
      country_code: "GA"
  },
  {
      id: "OWK",
      name: "Central Maine",
      area_code: "OWK",
      city_name: "Norridgewock",
      country_code: "US"
  },
  {
      id: "OXC",
      name: "Waterbury-Oxford",
      area_code: "OXC",
      city_name: "Oxford",
      country_code: "US"
  },
  {
      id: "OXD",
      name: "Miami University",
      area_code: "OXD",
      city_name: "Oxford",
      country_code: "US"
  },
  {
      id: "OXO",
      name: "Orientos",
      area_code: "OXO",
      city_name: "Orientos",
      country_code: "AU"
  },
  {
      id: "OXY",
      name: "Morney",
      area_code: "OXY",
      city_name: "Morney",
      country_code: "AU"
  },
  {
      id: "OYA",
      name: "Goya",
      area_code: "OYA",
      city_name: "Goya",
      country_code: "AR"
  },
  {
      id: "OYE",
      name: "Oyem",
      area_code: "OYE",
      city_name: "Oyem",
      country_code: "GA"
  },
  {
      id: "OYG",
      name: "Moyo",
      area_code: "OYG",
      city_name: "Moyo",
      country_code: "UG"
  },
  {
      id: "OYK",
      name: "Oiapoque",
      area_code: "OYK",
      city_name: "Oiapoque",
      country_code: "BR"
  },
  {
      id: "OYL",
      name: "Moyale",
      area_code: "OYL",
      city_name: "Moyale",
      country_code: "KE"
  },
  {
      id: "OYN",
      name: "Ouyen",
      area_code: "OYN",
      city_name: "Ouyen",
      country_code: "AU"
  },
  {
      id: "OYP",
      name: "St-Georges (Oyapock)",
      area_code: "OYP",
      city_name: "St-Georges (Oyapock)",
      country_code: "GF"
  },
  {
      id: "OZA",
      name: "Municipal",
      area_code: "OZA",
      city_name: "Ozona",
      country_code: "US"
  },
  {
      id: "OZP",
      name: "Air Base",
      area_code: "OZP",
      city_name: "Moron",
      country_code: "ES"
  },
  {
      id: "PAA",
      name: "Hpa-an",
      area_code: "PAA",
      city_name: "Hpa-an",
      country_code: "MM"
  },
  {
      id: "PAF",
      name: "PAKUBA AIRPORT",
      area_code: "PAF",
      city_name: "Pakuba",
      country_code: "UG"
  },
  {
      id: "PAJ",
      name: "Parachinar",
      area_code: "PAJ",
      city_name: "Parachinar",
      country_code: "PK"
  },
  {
      id: "PAK",
      name: "Port Allen",
      area_code: "PAK",
      city_name: "Hanapepe",
      country_code: "US"
  },
  {
      id: "PAL",
      name: "German Olano Moreno AB",
      area_code: "PAL",
      city_name: "Palanquero",
      country_code: "CO"
  },
  {
      id: "PAN",
      name: "Pattani",
      area_code: "PAN",
      city_name: "Pattani",
      country_code: "TH"
  },
  {
      id: "PAV",
      name: "PAULO AFONSO AIRPORT",
      area_code: "PAV",
      city_name: "Paulo Afonso",
      country_code: "BR"
  },
  {
      id: "PAW",
      name: "Pambwa",
      area_code: "PAW",
      city_name: "Pambwa",
      country_code: "PG"
  },
  {
      id: "PAX",
      name: "Port-de-Paix",
      area_code: "PAX",
      city_name: "Port-de-Paix",
      country_code: "HT"
  },
  {
      id: "PAY",
      name: "Pamol",
      area_code: "PAY",
      city_name: "Pamol",
      country_code: "MY"
  },
  {
      id: "PBB",
      name: "Paranaiba",
      area_code: "PBB",
      city_name: "Paranaiba",
      country_code: "BR"
  },
  {
      id: "PBJ",
      name: "Paama Airport",
      area_code: "PBJ",
      city_name: "Paama Island",
      country_code: "VU"
  },
  {
      id: "PBN",
      name: "Porto Amboim",
      area_code: "PBN",
      city_name: "Porto Amboim",
      country_code: "AO"
  },
  {
      id: "PBP",
      name: "Islita",
      area_code: "PBP",
      city_name: "Islita",
      country_code: "CR"
  },
  {
      id: "PBQ",
      name: "Pimenta Bueno",
      area_code: "PBQ",
      city_name: "Pimenta Bueno",
      country_code: "BR"
  },
  {
      id: "PBT",
      name: "Puerto Leda",
      area_code: "PBT",
      city_name: "Puerto Leda",
      country_code: "PY"
  },
  {
      id: "PBU",
      name: "PUTAO AIRPORT",
      area_code: "PBU",
      city_name: "Putao",
      country_code: "MM"
  },
  {
      id: "PBV",
      name: "Porto dos Gauchos",
      area_code: "PBV",
      city_name: "Porto dos Gauchos",
      country_code: "BR"
  },
  {
      id: "PBX",
      name: "Fazenda Piraguassu",
      area_code: "PBX",
      city_name: "Porto Alegre do Norte",
      country_code: "BR"
  },
  {
      id: "PCA",
      name: "Portage Creek",
      area_code: "PCA",
      city_name: "Portage Creek",
      country_code: "US"
  },
  {
      id: "PCB",
      name: "Pondok Cabe",
      area_code: "PCB",
      city_name: "Pondok Cabe",
      country_code: "ID"
  },
  {
      id: "PCC",
      name: "Puerto Rico",
      area_code: "PCC",
      city_name: "Puerto Rico",
      country_code: "CO"
  },
  {
      id: "PCF",
      name: "Potchefstroom",
      area_code: "PCF",
      city_name: "Potchefstroom",
      country_code: "ZA"
  },
  {
      id: "PCH",
      name: "Palacios",
      area_code: "PCH",
      city_name: "Palacios",
      country_code: "HN"
  },
  {
      id: "PCO",
      name: "Punta Colorada",
      area_code: "PCO",
      city_name: "La Ribera",
      country_code: "MX"
  },
  {
      id: "PCP",
      name: "Principe Island",
      area_code: "PCP",
      city_name: "Principe Island",
      country_code: "ST"
  },
  {
      id: "PCQ",
      name: "Boun Neua Airport",
      area_code: "PCQ",
      city_name: "Phongsaly",
      country_code: "LA"
  },
  {
      id: "PCS",
      name: "Picos",
      area_code: "PCS",
      city_name: "Picos",
      country_code: "BR"
  },
  {
      id: "PCU",
      name: "Pearl River County",
      area_code: "PCU",
      city_name: "Poplarville",
      country_code: "US"
  },
  {
      id: "PCV",
      name: "Punta Chivato",
      area_code: "PCV",
      city_name: "Punta Chivato",
      country_code: "MX"
  },
  {
      id: "PDB",
      name: "PEDRO BAY AIRPORT",
      area_code: "PDB",
      city_name: "Pedro Bay",
      country_code: "US"
  },
  {
      id: "PDC",
      name: "Mueo",
      area_code: "PDC",
      city_name: "Mueo",
      country_code: "NC"
  },
  {
      id: "PDD",
      name: "Ponta do Ouro",
      area_code: "PDD",
      city_name: "Ponta do Ouro",
      country_code: "MZ"
  },
  {
      id: "PDE",
      name: "Pandie Pandie",
      area_code: "PDE",
      city_name: "Pandie Pandie",
      country_code: "AU"
  },
  {
      id: "PDF",
      name: "Prado",
      area_code: "PDF",
      city_name: "Prado",
      country_code: "BR"
  },
  {
      id: "PDI",
      name: "Pindiu",
      area_code: "PDI",
      city_name: "Pindiu",
      country_code: "PG"
  },
  {
      id: "PDM",
      name: "Capt. J. Montenegro",
      area_code: "PDM",
      city_name: "Pedasi",
      country_code: "PA"
  },
  {
      id: "PDN",
      name: "Parndana",
      area_code: "PDN",
      city_name: "Parndana",
      country_code: "AU"
  },
  {
      id: "PDO",
      name: "Pendopo",
      area_code: "PDO",
      city_name: "Pendopo",
      country_code: "ID"
  },
  {
      id: "PDR",
      name: "Presidente Dutra",
      area_code: "PDR",
      city_name: "Presidente Dutra",
      country_code: "BR"
  },
  {
      id: "PDZ",
      name: "Pedernales",
      area_code: "PDZ",
      city_name: "Pedernales",
      country_code: "VE"
  },
  {
      id: "PEA",
      name: "Penneshaw",
      area_code: "PEA",
      city_name: "Penneshaw",
      country_code: "AU"
  },
  {
      id: "PEB",
      name: "Pebane",
      area_code: "PEB",
      city_name: "Pebane",
      country_code: "MZ"
  },
  {
      id: "PEC",
      name: "Sea Plane Base",
      area_code: "PEC",
      city_name: "Reims Champagne Ardenne",
      country_code: "FR"
  },
  {
      id: "PEH",
      name: "Comodoro P. Zanni",
      area_code: "PEH",
      city_name: "Pehuajo",
      country_code: "AR"
  },
  {
      id: "PEL",
      name: "Pelaneng",
      area_code: "PEL",
      city_name: "Pelaneng",
      country_code: "LS"
  },
  {
      id: "PEP",
      name: "Peppimenarti",
      area_code: "PEP",
      city_name: "Peppimenarti",
      country_code: "AU"
  },
  {
      id: "PEU",
      name: "PUERTO LEMPIRA AIRPORT",
      area_code: "PEU",
      city_name: "Puerto Lempira",
      country_code: "HN"
  },
  {
      id: "PEX",
      name: "Pechora Airport",
      area_code: "PEX",
      city_name: "Pechora",
      country_code: "RU"
  },
  {
      id: "PEY",
      name: "Penong",
      area_code: "PEY",
      city_name: "Penong",
      country_code: "AU"
  },
  {
      id: "PFC",
      name: "State",
      area_code: "PFC",
      city_name: "Pacific City",
      country_code: "US"
  },
  {
      id: "PFJ",
      name: "Patreksfjordur",
      area_code: "PFJ",
      city_name: "Patreksfjordur",
      country_code: "IS"
  },
  {
      id: "PFM",
      name: "Primrose",
      area_code: "PFM",
      city_name: "Primrose",
      country_code: "CA"
  },
  {
      id: "PFQ",
      name: "Parsabad Airport",
      area_code: "PFQ",
      city_name: "Parsabad",
      country_code: "IR"
  },
  {
      id: "PFR",
      name: "Ilebo",
      area_code: "PFR",
      city_name: "Ilebo",
      country_code: "CD"
  },
  {
      id: "PGB",
      name: "Pangoa",
      area_code: "PGB",
      city_name: "Pangoa",
      country_code: "PG"
  },
  {
      id: "PGC",
      name: "Grant County",
      area_code: "PGC",
      city_name: "Petersburg",
      country_code: "US"
  },
  {
      id: "PGE",
      name: "Yegepa",
      area_code: "PGE",
      city_name: "Yegepa",
      country_code: "PG"
  },
  {
      id: "PGI",
      name: "Chitato",
      area_code: "PGI",
      city_name: "Chitato",
      country_code: "AO"
  },
  {
      id: "PGN",
      name: "Pangia",
      area_code: "PGN",
      city_name: "Pangia",
      country_code: "PG"
  },
  {
      id: "PGQ",
      name: "Buli",
      area_code: "PGQ",
      city_name: "Pekaulang",
      country_code: "ID"
  },
  {
      id: "PGS",
      name: "Grand Canyon Caverns",
      area_code: "PGS",
      city_name: "Peach Springs",
      country_code: "US"
  },
  {
      id: "PGU",
      name: "Persian Gulf International Airport",
      area_code: "PGU",
      city_name: "Asaloyeh",
      country_code: "IR"
  },
  {
      id: "PGZ",
      name: "Ponta Grossa",
      area_code: "PGZ",
      city_name: "Ponta Grossa",
      country_code: "BR"
  },
  {
      id: "PHA",
      name: "Air Base",
      area_code: "PHA",
      city_name: "Phan Rang",
      country_code: "VN"
  },
  {
      id: "PHD",
      name: "Harry Clever Field",
      area_code: "PHD",
      city_name: "New Philadelphia",
      country_code: "US"
  },
  {
      id: "PHI",
      name: "Pinheiro",
      area_code: "PHI",
      city_name: "Pinheiro",
      country_code: "BR"
  },
  {
      id: "PHO",
      name: "POINT HOPE AIRPORT",
      area_code: "PHO",
      city_name: "Point Hope",
      country_code: "US"
  },
  {
      id: "PHP",
      name: "Philip",
      area_code: "PHP",
      city_name: "Philip",
      country_code: "US"
  },
  {
      id: "PHQ",
      name: "The Monument",
      area_code: "PHQ",
      city_name: "Phosphate Hill",
      country_code: "AU"
  },
  {
      id: "PHR",
      name: "Pacific Harbour",
      area_code: "PHR",
      city_name: "Pacific Harbour",
      country_code: "FJ"
  },
  {
      id: "PHU",
      name: "Phuoc Vinh",
      area_code: "PHU",
      city_name: "Phuoc Vinh",
      country_code: "VN"
  },
  {
      id: "PHY",
      name: "Phetchabun",
      area_code: "PHY",
      city_name: "Phetchabun",
      country_code: "TH"
  },
  {
      id: "PIC",
      name: "Pine Cay",
      area_code: "PIC",
      city_name: "Pine Cay",
      country_code: "TC"
  },
  {
      id: "PIL",
      name: "Carlos Miguel Jimenez",
      area_code: "PIL",
      city_name: "Pilar",
      country_code: "PY"
  },
  {
      id: "PIM",
      name: "Harris County",
      area_code: "PIM",
      city_name: "Pine Mountain",
      country_code: "US"
  },
  {
      id: "PIQ",
      name: "Pipillipai",
      area_code: "PIQ",
      city_name: "Pipillipai",
      country_code: "GY"
  },
  {
      id: "PIV",
      name: "Pirapora",
      area_code: "PIV",
      city_name: "Pirapora",
      country_code: "BR"
  },
  {
      id: "PJC",
      name: "Augusto Roberto Fuster",
      area_code: "PJC",
      city_name: "Pedro Juan Caballero",
      country_code: "PY"
  },
  {
      id: "PKF",
      name: "Municipal",
      area_code: "PKF",
      city_name: "Park Falls",
      country_code: "US"
  },
  {
      id: "PKK",
      name: "Pakokku",
      area_code: "PKK",
      city_name: "Pakokku",
      country_code: "MM"
  },
  {
      id: "PKM",
      name: "Port Kaituma",
      area_code: "PKM",
      city_name: "Port Kaituma",
      country_code: "GY"
  },
  {
      id: "PKO",
      name: "Parakou",
      area_code: "PKO",
      city_name: "Parakou",
      country_code: "BJ"
  },
  {
      id: "PKP",
      name: "PUKA PUKA AIRPORT",
      area_code: "PKP",
      city_name: "Puka Puka",
      country_code: "PF"
  },
  {
      id: "PKT",
      name: "Port Keats",
      area_code: "PKT",
      city_name: "Port Keats",
      country_code: "AU"
  },
  {
      id: "PLA",
      name: "Planadas",
      area_code: "PLA",
      city_name: "Planadas",
      country_code: "CO"
  },
  {
      id: "PLC",
      name: "Planeta Rica",
      area_code: "PLC",
      city_name: "Planeta Rica",
      country_code: "CO"
  },
  {
      id: "PLE",
      name: "Paiela",
      area_code: "PLE",
      city_name: "Paiela",
      country_code: "PG"
  },
  {
      id: "PLF",
      name: "Pala",
      area_code: "PLF",
      city_name: "Pala",
      country_code: "TD"
  },
  {
      id: "PLL",
      name: "Ponta Pelada AB",
      area_code: "MAO",
      city_name: "Manaus",
      country_code: "BR"
  },
  {
      id: "PLP",
      name: "Ramon Xatruch",
      area_code: "PLP",
      city_name: "La Palma",
      country_code: "PA"
  },
  {
      id: "PLT",
      name: "Plato",
      area_code: "PLT",
      city_name: "Plato",
      country_code: "CO"
  },
  {
      id: "PMA",
      name: "Pemba Airport",
      area_code: "PMA",
      city_name: "Pemba",
      country_code: "TZ"
  },
  {
      id: "PMB",
      name: "Municipal",
      area_code: "PMB",
      city_name: "Pembina",
      country_code: "US"
  },
  {
      id: "PML",
      name: "Port Moller Airport",
      area_code: "PML",
      city_name: "Port Moller",
      country_code: "US"
  },
  {
      id: "PMN",
      name: "Pumani",
      area_code: "PMN",
      city_name: "Pumani",
      country_code: "PG"
  },
  {
      id: "PMP",
      name: "Pimaga",
      area_code: "PMP",
      city_name: "Pimaga",
      country_code: "PG"
  },
  {
      id: "PMQ",
      name: "Perito Moreno",
      area_code: "PMQ",
      city_name: "Perito Moreno",
      country_code: "AR"
  },
  {
      id: "PMS",
      name: "Palmyra",
      area_code: "PMS",
      city_name: "Palmyra",
      country_code: "SY"
  },
  {
      id: "PMT",
      name: "Paramakatoi",
      area_code: "PMT",
      city_name: "Paramakatoi",
      country_code: "GY"
  },
  {
      id: "PMX",
      name: "Metropolitan",
      area_code: "PMX",
      city_name: "Palmer",
      country_code: "US"
  },
  {
      id: "PNB",
      name: "Porto Nacional",
      area_code: "PNB",
      city_name: "Porto Nacional",
      country_code: "BR"
  },
  {
      id: "PND",
      name: "PUNTA GORDA AIRPORT",
      area_code: "PND",
      city_name: "Punta Gorda",
      country_code: "BZ"
  },
  {
      id: "PNN",
      name: "Municipal",
      area_code: "PNN",
      city_name: "Princeton",
      country_code: "US"
  },
  {
      id: "PNU",
      name: "Municipal",
      area_code: "PNU",
      city_name: "Panguitch",
      country_code: "US"
  },
  {
      id: "PNV",
      name: "Pajuostis",
      area_code: "PNV",
      city_name: "Panevezys",
      country_code: "LT"
  },
  {
      id: "POD",
      name: "Podor",
      area_code: "POD",
      city_name: "Podor",
      country_code: "SN"
  },
  {
      id: "POH",
      name: "Municipal",
      area_code: "POH",
      city_name: "Pocahontas",
      country_code: "US"
  },
  {
      id: "PON",
      name: "Poptun",
      area_code: "PON",
      city_name: "Poptun",
      country_code: "GT"
  },
  {
      id: "POO",
      name: "Walther Moreira Salles",
      area_code: "POO",
      city_name: "Pocos de Caldas",
      country_code: "BR"
  },
  {
      id: "POT",
      name: "Ken Jones",
      area_code: "POT",
      city_name: "Port Antonio",
      country_code: "JM"
  },
  {
      id: "POV",
      name: "Presov",
      area_code: "POV",
      city_name: "Presov",
      country_code: "SK"
  },
  {
      id: "POY",
      name: "Powell Municipal",
      area_code: "POY",
      city_name: "Powell/Lovell",
      country_code: "US"
  },
  {
      id: "PPA",
      name: "Perry Lefors Field",
      area_code: "PPA",
      city_name: "Pampa",
      country_code: "US"
  },
  {
      id: "PPC",
      name: "Prospect Creek",
      area_code: "PPC",
      city_name: "Prospect Creek",
      country_code: "US"
  },
  {
      id: "PPH",
      name: "Peraitepuy",
      area_code: "PPH",
      city_name: "Peraitepuy",
      country_code: "VE"
  },
  {
      id: "PPJ",
      name: "Panjang Island",
      area_code: "PPJ",
      city_name: "Panjang Island",
      country_code: "ID"
  },
  {
      id: "PPR",
      name: "Pasir Pangarayan",
      area_code: "PPR",
      city_name: "Pasir Pangarayan",
      country_code: "ID"
  },
  {
      id: "PPU",
      name: "Papun",
      area_code: "PPU",
      city_name: "Papun",
      country_code: "MM"
  },
  {
      id: "PPX",
      name: "Param",
      area_code: "PPX",
      city_name: "Param",
      country_code: "PG"
  },
  {
      id: "PPY",
      name: "Pouso Alegre",
      area_code: "PPY",
      city_name: "Pouso Alegre",
      country_code: "BR"
  },
  {
      id: "PPZ",
      name: "Puerto Paez",
      area_code: "PPZ",
      city_name: "Puerto Paez",
      country_code: "VE"
  },
  {
      id: "PQD",
      name: "Passikudah SPB",
      area_code: "BTC",
      city_name: "Batticaloa",
      country_code: "LK"
  },
  {
      id: "PQS",
      name: "PILOT STATION AIRPORT",
      area_code: "PQS",
      city_name: "Pilot Station",
      country_code: "US"
  },
  {
      id: "PRD",
      name: "Pardoo Station",
      area_code: "PRD",
      city_name: "Pardoo Station",
      country_code: "AU"
  },
  {
      id: "PRE",
      name: "Pore",
      area_code: "PRE",
      city_name: "Pore",
      country_code: "CO"
  },
  {
      id: "PRK",
      name: "Prieska",
      area_code: "PRK",
      city_name: "Prieska",
      country_code: "ZA"
  },
  {
      id: "PRO",
      name: "Municipal",
      area_code: "PRO",
      city_name: "Perry",
      country_code: "US"
  },
  {
      id: "PRQ",
      name: "Pres. Roque Saenz Pena",
      area_code: "PRQ",
      city_name: "Pres. Roque Saenz Pena",
      country_code: "AR"
  },
  {
      id: "PRR",
      name: "Paruima",
      area_code: "PRR",
      city_name: "Paruima",
      country_code: "GY"
  },
  {
      id: "PRS",
      name: "Parasi",
      area_code: "PRS",
      city_name: "Parasi",
      country_code: "SB"
  },
  {
      id: "PRU",
      name: "Pyay",
      area_code: "PRU",
      city_name: "Pyay",
      country_code: "MM"
  },
  {
      id: "PRV",
      name: "Prerov",
      area_code: "PRV",
      city_name: "Prerov",
      country_code: "CZ"
  },
  {
      id: "PRW",
      name: "Prentice",
      area_code: "PRW",
      city_name: "Prentice",
      country_code: "US"
  },
  {
      id: "PRZ",
      name: "Prineville",
      area_code: "PRZ",
      city_name: "Prineville",
      country_code: "US"
  },
  {
      id: "PSD",
      name: "Port Said",
      area_code: "PSD",
      city_name: "Port Said",
      country_code: "EG"
  },
  {
      id: "PSH",
      name: "St Peter-Ording",
      area_code: "PSH",
      city_name: "St Peter-Ording",
      country_code: "DE"
  },
  {
      id: "PSI",
      name: "Pasni",
      area_code: "PSI",
      city_name: "Pasni",
      country_code: "PK"
  },
  {
      id: "PSJ",
      name: "Kasinguncu",
      area_code: "PSJ",
      city_name: "Poso",
      country_code: "ID"
  },
  {
      id: "PSN",
      name: "Municipal",
      area_code: "PSN",
      city_name: "Palestine",
      country_code: "US"
  },
  {
      id: "PSU",
      name: "Putussibau Airport",
      area_code: "PSU",
      city_name: "Putussibau",
      country_code: "ID"
  },
  {
      id: "PSV",
      name: "Papa Stour",
      area_code: "PSV",
      city_name: "Papa Stour",
      country_code: "GB"
  },
  {
      id: "PSW",
      name: "Jose Figueiredo",
      area_code: "PSW",
      city_name: "Passos",
      country_code: "BR"
  },
  {
      id: "PSX",
      name: "Municipal",
      area_code: "PSX",
      city_name: "Palacios",
      country_code: "US"
  },
  {
      id: "PSY",
      name: "Port Stanley",
      area_code: "PSY",
      city_name: "Stanley",
      country_code: "AR"
  },
  {
      id: "PTA",
      name: "PORT ALSWORTH AIRPORT",
      area_code: "PTA",
      city_name: "Port Alsworth",
      country_code: "US"
  },
  {
      id: "PTH",
      name: "PORT HEIDEN AIRPORT",
      area_code: "PTH",
      city_name: "Port Heiden",
      country_code: "US"
  },
  {
      id: "PTM",
      name: "Palmarito",
      area_code: "PTM",
      city_name: "Palmarito",
      country_code: "VE"
  },
  {
      id: "PTN",
      name: "Harry P. Williams Mem.",
      area_code: "PTN",
      city_name: "Patterson",
      country_code: "US"
  },
  {
      id: "PTQ",
      name: "Porto de Moz",
      area_code: "PTQ",
      city_name: "Porto de Moz",
      country_code: "BR"
  },
  {
      id: "PTT",
      name: "Regional",
      area_code: "PTT",
      city_name: "Pratt",
      country_code: "US"
  },
  {
      id: "PTU",
      name: "PLATINUM AIRPORT",
      area_code: "PTU",
      city_name: "Platinum",
      country_code: "US"
  },
  {
      id: "PTX",
      name: "Pitalito Airport",
      area_code: "PTX",
      city_name: "Pitalito",
      country_code: "CO"
  },
  {
      id: "PTZ",
      name: "Rio Amazonas",
      area_code: "PTZ",
      city_name: "Shell Mera",
      country_code: "EC"
  },
  {
      id: "PUA",
      name: "Puas",
      area_code: "PUA",
      city_name: "Puas",
      country_code: "PG"
  },
  {
      id: "PUD",
      name: "Puerto Deseado",
      area_code: "PUD",
      city_name: "Puerto Deseado",
      country_code: "AR"
  },
  {
      id: "PUE",
      name: "Puerto Obaldia Airport",
      area_code: "PUE",
      city_name: "Puerto Obaldia",
      country_code: "PA"
  },
  {
      id: "PUK",
      name: "PUKARUA AIRPORT",
      area_code: "PUK",
      city_name: "Pukarua",
      country_code: "PF"
  },
  {
      id: "PUL",
      name: "Marina Moorage SPB",
      area_code: "PUL",
      city_name: "Poulsbo",
      country_code: "US"
  },
  {
      id: "PUM",
      name: "Pomala Airport",
      area_code: "PUM",
      city_name: "Kolaka",
      country_code: "ID"
  },
  {
      id: "PUN",
      name: "Punia",
      area_code: "PUN",
      city_name: "Punia",
      country_code: "CD"
  },
  {
      id: "PUP",
      name: "Po",
      area_code: "PUP",
      city_name: "Po",
      country_code: "BF"
  },
  {
      id: "PUR",
      name: "Puerto Rico",
      area_code: "PUR",
      city_name: "Puerto Rico",
      country_code: "BO"
  },
  {
      id: "PUV",
      name: "Malabou",
      area_code: "PUV",
      city_name: "Poum",
      country_code: "NC"
  },
  {
      id: "PUZ",
      name: "Puerto Cabezas",
      area_code: "PUZ",
      city_name: "Puerto Cabezas",
      country_code: "NI"
  },
  {
      id: "PVE",
      name: "El Porvenir",
      area_code: "PVE",
      city_name: "El Porvenir",
      country_code: "PA"
  },
  {
      id: "PVF",
      name: "Placerville",
      area_code: "PVF",
      city_name: "Placerville",
      country_code: "US"
  },
  {
      id: "PVI",
      name: "Edu Chaves",
      area_code: "PVI",
      city_name: "Paranavai",
      country_code: "BR"
  },
  {
      id: "PVO",
      name: "Reales Tamarindos",
      area_code: "PVO",
      city_name: "Portoviejo",
      country_code: "EC"
  },
  {
      id: "PVS",
      name: "Provideniya Bay",
      area_code: "PVS",
      city_name: "Provideniya",
      country_code: "RU"
  },
  {
      id: "PWD",
      name: "Sher-Wood",
      area_code: "PWD",
      city_name: "Plentywood",
      country_code: "US"
  },
  {
      id: "PWE",
      name: "PEVEK AIRPORT",
      area_code: "PWE",
      city_name: "Pevek",
      country_code: "RU"
  },
  {
      id: "PWI",
      name: "Beles",
      area_code: "PWI",
      city_name: "Pawe",
      country_code: "ET"
  },
  {
      id: "PWL",
      name: "Wirasaba",
      area_code: "PWL",
      city_name: "Purwokerto",
      country_code: "ID"
  },
  {
      id: "PWN",
      name: "Pitts Town",
      area_code: "PWN",
      city_name: "Pitts Town",
      country_code: "BS"
  },
  {
      id: "PWO",
      name: "Pweto",
      area_code: "PWO",
      city_name: "Pweto",
      country_code: "CD"
  },
  {
      id: "PWY",
      name: "Ralph Wenz Field",
      area_code: "PWY",
      city_name: "Pinedale",
      country_code: "US"
  },
  {
      id: "PXA",
      name: "Pagar Alam Airport",
      area_code: "PXA",
      city_name: "Lahat",
      country_code: "ID"
  },
  {
      id: "PXH",
      name: "Prominent Hill",
      area_code: "PXH",
      city_name: "Prominent Hill",
      country_code: "AU"
  },
  {
      id: "PXR",
      name: "Surin",
      area_code: "PXR",
      city_name: "Surin",
      country_code: "TH"
  },
  {
      id: "PYA",
      name: "Velasquez",
      area_code: "PYA",
      city_name: "Puerto Boyaca",
      country_code: "CO"
  },
  {
      id: "PYB",
      name: "Jeypore",
      area_code: "PYB",
      city_name: "Jeypore",
      country_code: "IN"
  },
  {
      id: "PYC",
      name: "Playon Chico Airport",
      area_code: "PYC",
      city_name: "Playon Chico",
      country_code: "PA"
  },
  {
      id: "PYE",
      name: "Penrhyn Atoll",
      area_code: "PYE",
      city_name: "Penrhyn Atoll",
      country_code: "CK"
  },
  {
      id: "PYG",
      name: "Pakyong Airport",
      area_code: "PYG",
      city_name: "Gangtok",
      country_code: "IN"
  },
  {
      id: "PYJ",
      name: "Polyarny Airport",
      area_code: "PYJ",
      city_name: "Polyarny",
      country_code: "RU"
  },
  {
      id: "PYK",
      name: "Payam",
      area_code: "PYK",
      city_name: "Karaj",
      country_code: "IR"
  },
  {
      id: "PYL",
      name: "Sea Plane Base",
      area_code: "PYL",
      city_name: "Perry Island",
      country_code: "US"
  },
  {
      id: "PYN",
      name: "Payan",
      area_code: "PYN",
      city_name: "Payan",
      country_code: "CO"
  },
  {
      id: "PYO",
      name: "Putumayo",
      area_code: "PYO",
      city_name: "Putumayo",
      country_code: "EC"
  },
  {
      id: "PYR",
      name: "Andravida AB",
      area_code: "PYR",
      city_name: "Pyrgos",
      country_code: "GR"
  },
  {
      id: "PYS",
      name: "Skypark",
      area_code: "PYS",
      city_name: "Paradise",
      country_code: "US"
  },
  {
      id: "PYV",
      name: "Yaviza",
      area_code: "PYV",
      city_name: "Yaviza",
      country_code: "PA"
  },
  {
      id: "PZA",
      name: "Paz de Ariporo",
      area_code: "PZA",
      city_name: "Paz de Ariporo",
      country_code: "CO"
  },
  {
      id: "PZK",
      name: "Pukapuka Island",
      area_code: "PZK",
      city_name: "Pukapuka Island",
      country_code: "CK"
  },
  {
      id: "PZL",
      name: "Phinda Game Reserve",
      area_code: "PZL",
      city_name: "Phinda",
      country_code: "ZA"
  },
  {
      id: "QAQ",
      name: "L'Aquila",
      area_code: "QAQ",
      city_name: "L'Aquila",
      country_code: "IT"
  },
  {
      id: "QCY",
      name: "RAF Station",
      area_code: "QCY",
      city_name: "Coningsby",
      country_code: "GB"
  },
  {
      id: "QHU",
      name: "Schwesing",
      area_code: "QHU",
      city_name: "Husum",
      country_code: "DE"
  },
  {
      id: "QLR",
      name: "Monte Real",
      area_code: "QLR",
      city_name: "Leiria",
      country_code: "PT"
  },
  {
      id: "QUB",
      name: "Ubari",
      area_code: "QUB",
      city_name: "Ubari",
      country_code: "LY"
  },
  {
      id: "QUG",
      name: "Goodwood",
      area_code: "QUG",
      city_name: "Chichester",
      country_code: "GB"
  },
  {
      id: "QUO",
      name: "Akwa Ibom International Airport",
      area_code: "QUO",
      city_name: "Uyo",
      country_code: "NG"
  },
  {
      id: "QUY",
      name: "RAF Station",
      area_code: "QUY",
      city_name: "Wyton",
      country_code: "GB"
  },
  {
      id: "RAA",
      name: "Rakanda",
      area_code: "RAA",
      city_name: "Rakanda",
      country_code: "PG"
  },
  {
      id: "RAF",
      name: "Rafaela",
      area_code: "RAF",
      city_name: "Rafaela",
      country_code: "AR"
  },
  {
      id: "RAG",
      name: "Raglan",
      area_code: "RAG",
      city_name: "Raglan",
      country_code: "NZ"
  },
  {
      id: "RAM",
      name: "Ramingining",
      area_code: "RAM",
      city_name: "Ramingining",
      country_code: "AU"
  },
  {
      id: "RAN",
      name: "Ravenna",
      area_code: "RAN",
      city_name: "Ravenna",
      country_code: "IT"
  },
  {
      id: "RAQ",
      name: "Muna",
      area_code: "RAQ",
      city_name: "Muna",
      country_code: "ID"
  },
  {
      id: "RAS",
      name: "Rasht Airport",
      area_code: "RAS",
      city_name: "Rasht",
      country_code: "IR"
  },
  {
      id: "RAT",
      name: "Raduzhny",
      area_code: "RAT",
      city_name: "Raduzhny",
      country_code: "RU"
  },
  {
      id: "RAV",
      name: "Cravo Norte",
      area_code: "RAV",
      city_name: "Cravo Norte",
      country_code: "CO"
  },
  {
      id: "RAW",
      name: "Arawa",
      area_code: "RAW",
      city_name: "Arawa",
      country_code: "PG"
  },
  {
      id: "RAX",
      name: "Oram",
      area_code: "RAX",
      city_name: "Oram",
      country_code: "PG"
  },
  {
      id: "RAZ",
      name: "Rawala Kot",
      area_code: "RAZ",
      city_name: "Rawala Kot",
      country_code: "PK"
  },
  {
      id: "RBB",
      name: "Borba",
      area_code: "RBB",
      city_name: "Borba",
      country_code: "BR"
  },
  {
      id: "RBC",
      name: "Robinvale",
      area_code: "RBC",
      city_name: "Robinvale",
      country_code: "AU"
  },
  {
      id: "RBI",
      name: "Rabi Island",
      area_code: "RBI",
      city_name: "Rabi Island",
      country_code: "FJ"
  },
  {
      id: "RBJ",
      name: "Rebun",
      area_code: "RBJ",
      city_name: "Rebun",
      country_code: "JP"
  },
  {
      id: "RBK",
      name: "French Valley",
      area_code: "RBK",
      city_name: "Murrieta/Temecula",
      country_code: "US"
  },
  {
      id: "RBO",
      name: "Robore",
      area_code: "RBO",
      city_name: "Robore",
      country_code: "BO"
  },
  {
      id: "RBP",
      name: "Raba Raba",
      area_code: "RBP",
      city_name: "Raba Raba",
      country_code: "PG"
  },
  {
      id: "RBS",
      name: "Orbost",
      area_code: "RBS",
      city_name: "Orbost",
      country_code: "AU"
  },
  {
      id: "RBT",
      name: "Marsabit",
      area_code: "RBT",
      city_name: "Marsabit",
      country_code: "KE"
  },
  {
      id: "RBU",
      name: "Roebourne",
      area_code: "RBU",
      city_name: "Roebourne",
      country_code: "AU"
  },
  {
      id: "RBV",
      name: "Ramata Airport",
      area_code: "RBV",
      city_name: "Ramata Island",
      country_code: "SB"
  },
  {
      id: "RBX",
      name: "Rumbek",
      area_code: "RBX",
      city_name: "Rumbek",
      country_code: "SS"
  },
  {
      id: "RBY",
      name: "RUBY AIRPORT",
      area_code: "RBY",
      city_name: "Ruby",
      country_code: "US"
  },
  {
      id: "RCK",
      name: "H.H. Coffield Regional",
      area_code: "RCK",
      city_name: "Rockdale",
      country_code: "US"
  },
  {
      id: "RCL",
      name: "Redcliffe",
      area_code: "RCL",
      city_name: "Redcliffe",
      country_code: "VU"
  },
  {
      id: "RCN",
      name: "American River",
      area_code: "RCN",
      city_name: "American River",
      country_code: "AU"
  },
  {
      id: "RCQ",
      name: "Reconquista",
      area_code: "RCQ",
      city_name: "Reconquista",
      country_code: "AR"
  },
  {
      id: "RCR",
      name: "Fulton County",
      area_code: "RCR",
      city_name: "Rochester",
      country_code: "US"
  },
  {
      id: "RCT",
      name: "Nartron Field",
      area_code: "RCT",
      city_name: "Reed City",
      country_code: "US"
  },
  {
      id: "RDA",
      name: "Rockhampton Downs",
      area_code: "RDA",
      city_name: "Rockhampton Downs",
      country_code: "AU"
  },
  {
      id: "RDB",
      name: "RED DOG AIRPORT",
      area_code: "RDB",
      city_name: "Red Dog",
      country_code: "US"
  },
  {
      id: "RDC",
      name: "Redencao",
      area_code: "RDC",
      city_name: "Redencao",
      country_code: "BR"
  },
  {
      id: "RDE",
      name: "Jahabra",
      area_code: "RDE",
      city_name: "Merdey",
      country_code: "ID"
  },
  {
      id: "RDO",
      name: "Radom Airport",
      area_code: "RDO",
      city_name: "Radom",
      country_code: "PL"
  },
  {
      id: "RDP",
      name: "Kazi Nazrul Islam",
      area_code: "RDP",
      city_name: "Durgapur",
      country_code: "IN"
  },
  {
      id: "RDR",
      name: "AFB",
      area_code: "GFK",
      city_name: "Grand Forks",
      country_code: "US"
  },
  {
      id: "RDS",
      name: "Rincon de Los Sauces",
      area_code: "RDS",
      city_name: "Rincon de los Sauces",
      country_code: "AR"
  },
  {
      id: "RDT",
      name: "Richard-Toll",
      area_code: "RDT",
      city_name: "Richard-Toll",
      country_code: "SN"
  },
  {
      id: "RDV",
      name: "RED DEVIL AIRPORT",
      area_code: "RDV",
      city_name: "Red Devil",
      country_code: "US"
  },
  {
      id: "REA",
      name: "REAO AIRPORT",
      area_code: "REA",
      city_name: "Reao",
      country_code: "PF"
  },
  {
      id: "REB",
      name: "Laerz",
      area_code: "REB",
      city_name: "Rechlin",
      country_code: "DE"
  },
  {
      id: "RED",
      name: "Mifflin County",
      area_code: "RED",
      city_name: "Reedsville/Lewistown",
      country_code: "US"
  },
  {
      id: "REI",
      name: "Regina",
      area_code: "REI",
      city_name: "Regina",
      country_code: "GY"
  },
  {
      id: "REO",
      name: "State",
      area_code: "REO",
      city_name: "Rome",
      country_code: "US"
  },
  {
      id: "REQ",
      name: "Reko Diq",
      area_code: "REQ",
      city_name: "Reko Diq",
      country_code: "PK"
  },
  {
      id: "RET",
      name: "Stolport Airport",
      area_code: "RET",
      city_name: "Rost",
      country_code: "NO"
  },
  {
      id: "REW",
      name: "Chorhata",
      area_code: "REW",
      city_name: "Rewa",
      country_code: "IN"
  },
  {
      id: "REY",
      name: "Reyes",
      area_code: "REY",
      city_name: "Reyes",
      country_code: "BO"
  },
  {
      id: "RFA",
      name: "Rafai",
      area_code: "RFA",
      city_name: "Rafai",
      country_code: "CF"
  },
  {
      id: "RFK",
      name: "Rollang Field",
      area_code: "RFK",
      city_name: "Rolling Fork",
      country_code: "US"
  },
  {
      id: "RFN",
      name: "Raufarhofn",
      area_code: "RFN",
      city_name: "Raufarhofn",
      country_code: "IS"
  },
  {
      id: "RFR",
      name: "Rio Frio",
      area_code: "RFR",
      city_name: "Rio Frio",
      country_code: "CR"
  },
  {
      id: "RFS",
      name: "Rosita",
      area_code: "RFS",
      city_name: "Rosita",
      country_code: "NI"
  },
  {
      id: "RGE",
      name: "Porgera",
      area_code: "RGE",
      city_name: "Porgera",
      country_code: "PG"
  },
  {
      id: "RGO",
      name: "Orang",
      area_code: "RGO",
      city_name: "Chongjin",
      country_code: "KP"
  },
  {
      id: "RGT",
      name: "Japura",
      area_code: "RGT",
      city_name: "Rengat",
      country_code: "ID"
  },
  {
      id: "RHA",
      name: "Reykholar",
      area_code: "RHA",
      city_name: "Reykholar",
      country_code: "IS"
  },
  {
      id: "RHG",
      name: "Ruhengeri",
      area_code: "RHG",
      city_name: "Ruhengeri",
      country_code: "RW"
  },
  {
      id: "RHL",
      name: "Roy Hill Station",
      area_code: "RHL",
      city_name: "Roy Hill Station",
      country_code: "AU"
  },
  {
      id: "RHP",
      name: "Ramechhap",
      area_code: "RHP",
      city_name: "Ramechhap",
      country_code: "NP"
  },
  {
      id: "RHR",
      name: "Al Hamra SPB",
      area_code: "RKT",
      city_name: "Ras al Khaimah",
      country_code: "AE"
  },
  {
      id: "RHV",
      name: "Reid-Hillview",
      area_code: "SJC",
      city_name: "San Jose",
      country_code: "US"
  },
  {
      id: "RIE",
      name: "Regional-Carl's Field",
      area_code: "RIE",
      city_name: "Rice Lake",
      country_code: "US"
  },
  {
      id: "RIJ",
      name: "Juan Simons Vela",
      area_code: "RIJ",
      city_name: "Rioja",
      country_code: "PE"
  },
  {
      id: "RIM",
      name: "San Nicolas",
      area_code: "RIM",
      city_name: "Rodriguez de Mendoza",
      country_code: "PE"
  },
  {
      id: "RIN",
      name: "Ringgi Cove",
      area_code: "RIN",
      city_name: "Ringgi Cove",
      country_code: "SB"
  },
  {
      id: "RIR",
      name: "Flabob",
      area_code: "RAL",
      city_name: "Riverside",
      country_code: "US"
  },
  {
      id: "RIS",
      name: "RISHIRI AIRPORT",
      area_code: "RIS",
      city_name: "Rishiri",
      country_code: "JP"
  },
  {
      id: "RIV",
      name: "March JARB",
      area_code: "RAL",
      city_name: "Riverside",
      country_code: "US"
  },
  {
      id: "RJB",
      name: "Rajbiraj",
      area_code: "RJB",
      city_name: "Rajbiraj",
      country_code: "NP"
  },
  {
      id: "RJH",
      name: "Rajshahi Airport",
      area_code: "RJH",
      city_name: "Rajshahi",
      country_code: "BD"
  },
  {
      id: "RJI",
      name: "Rajauri",
      area_code: "RJI",
      city_name: "Rajauri",
      country_code: "IN"
  },
  {
      id: "RJM",
      name: "Waisai",
      area_code: "RJM",
      city_name: "Waisai",
      country_code: "ID"
  },
  {
      id: "RJN",
      name: "Rafsanjan",
      area_code: "RJN",
      city_name: "Rafsanjan",
      country_code: "IR"
  },
  {
      id: "RKA",
      name: "Aratika Airport",
      area_code: "RKA",
      city_name: "Aratika",
      country_code: "PF"
  },
  {
      id: "RKI",
      name: "Sipora",
      area_code: "RKI",
      city_name: "Rokot",
      country_code: "ID"
  },
  {
      id: "RKR",
      name: "Robert S Kerr",
      area_code: "RKR",
      city_name: "Poteau",
      country_code: "US"
  },
  {
      id: "RKU",
      name: "Kairuku",
      area_code: "RKU",
      city_name: "Yule Island",
      country_code: "PG"
  },
  {
      id: "RKY",
      name: "Rokeby",
      area_code: "RKY",
      city_name: "Rokeby",
      country_code: "AU"
  },
  {
      id: "RLP",
      name: "Rosella Plains",
      area_code: "RLP",
      city_name: "Rosella Plains",
      country_code: "AU"
  },
  {
      id: "RLR",
      name: "Relais de la Reine",
      area_code: "RLR",
      city_name: "Isalo",
      country_code: "MG"
  },
  {
      id: "RLT",
      name: "Arlit",
      area_code: "RLT",
      city_name: "Arlit",
      country_code: "NE"
  },
  {
      id: "RMB",
      name: "Buraimi",
      area_code: "RMB",
      city_name: "Buraimi",
      country_code: "OM"
  },
  {
      id: "RMD",
      name: "Basantnagar",
      area_code: "RMD",
      city_name: "Ramagundam",
      country_code: "IN"
  },
  {
      id: "RME",
      name: "Griffiss Intl",
      area_code: "RME",
      city_name: "Rome",
      country_code: "US"
  },
  {
      id: "RMN",
      name: "Rumginae",
      area_code: "RMN",
      city_name: "Rumginae",
      country_code: "PG"
  },
  {
      id: "RMP",
      name: "RAMPART AIRPORT",
      area_code: "RMP",
      city_name: "Rampart",
      country_code: "US"
  },
  {
      id: "RMU",
      name: "International",
      area_code: "RMU",
      city_name: "Corvera",
      country_code: "ES"
  },
  {
      id: "RNA",
      name: "ULAWA AIRPORT",
      area_code: "RNA",
      city_name: "Arona",
      country_code: "SB"
  },
  {
      id: "RNC",
      name: "Warren County Memorial",
      area_code: "RNC",
      city_name: "McMinnville",
      country_code: "US"
  },
  {
      id: "RNG",
      name: "Rangely",
      area_code: "RNG",
      city_name: "Rangely",
      country_code: "US"
  },
  {
      id: "RNI",
      name: "Corn Island",
      area_code: "RNI",
      city_name: "Corn Island",
      country_code: "NI"
  },
  {
      id: "RNJ",
      name: "Yoron Airport",
      area_code: "RNJ",
      city_name: "Yoron",
      country_code: "JP"
  },
  {
      id: "RNL",
      name: "Rennell Airport",
      area_code: "RNL",
      city_name: "Rennell Island",
      country_code: "SB"
  },
  {
      id: "RNM",
      name: "Qarn Alam",
      area_code: "RNM",
      city_name: "Qarn Alam",
      country_code: "OM"
  },
  {
      id: "RNP",
      name: "Rongelap Island",
      area_code: "RNP",
      city_name: "Rongelap Island",
      country_code: "MH"
  },
  {
      id: "RNR",
      name: "Robinson River",
      area_code: "RNR",
      city_name: "Robinson River",
      country_code: "PG"
  },
  {
      id: "RNT",
      name: "Municipal",
      area_code: "RNT",
      city_name: "Renton",
      country_code: "US"
  },
  {
      id: "RNU",
      name: "Ranau",
      area_code: "RNU",
      city_name: "Ranau",
      country_code: "MY"
  },
  {
      id: "RNZ",
      name: "Jasper County",
      area_code: "RNZ",
      city_name: "Rensselaer",
      country_code: "US"
  },
  {
      id: "ROD",
      name: "Robertson",
      area_code: "ROD",
      city_name: "Robertson",
      country_code: "ZA"
  },
  {
      id: "ROF",
      name: "Rohrer Field",
      area_code: "SIY",
      city_name: "Montague/Yreka",
      country_code: "US"
  },
  {
      id: "ROH",
      name: "Robinhood",
      area_code: "ROH",
      city_name: "Robinhood",
      country_code: "AU"
  },
  {
      id: "ROL",
      name: "Municipal",
      area_code: "ROL",
      city_name: "Roosevelt",
      country_code: "US"
  },
  {
      id: "ROY",
      name: "Rio Mayo",
      area_code: "ROY",
      city_name: "Rio Mayo",
      country_code: "AR"
  },
  {
      id: "RPA",
      name: "Rolpa",
      area_code: "RPA",
      city_name: "Rolpa",
      country_code: "NP"
  },
  {
      id: "RPB",
      name: "Roper Bar",
      area_code: "RPB",
      city_name: "Roper Bar",
      country_code: "AU"
  },
  {
      id: "RPM",
      name: "Ngukurr",
      area_code: "RPM",
      city_name: "Ngukurr",
      country_code: "AU"
  },
  {
      id: "RPV",
      name: "Roper Valley",
      area_code: "RPV",
      city_name: "Roper Valley",
      country_code: "AU"
  },
  {
      id: "RPX",
      name: "Roundup",
      area_code: "RPX",
      city_name: "Roundup",
      country_code: "US"
  },
  {
      id: "RQA",
      name: "Loulan Airport",
      area_code: "RQA",
      city_name: "Ruoqiang",
      country_code: "CN"
  },
  {
      id: "RQW",
      name: "West",
      area_code: "RQW",
      city_name: "Qayyarah",
      country_code: "IQ"
  },
  {
      id: "RRE",
      name: "Marree",
      area_code: "RRE",
      city_name: "Marree",
      country_code: "AU"
  },
  {
      id: "RRI",
      name: "Barora",
      area_code: "RRI",
      city_name: "Barora",
      country_code: "SB"
  },
  {
      id: "RRL",
      name: "Municipal",
      area_code: "RRL",
      city_name: "Merrill",
      country_code: "US"
  },
  {
      id: "RRM",
      name: "Marromeu",
      area_code: "RRM",
      city_name: "Marromeu",
      country_code: "MZ"
  },
  {
      id: "RRR",
      name: "Raroia Airport",
      area_code: "RRR",
      city_name: "Raroia",
      country_code: "PF"
  },
  {
      id: "RRT",
      name: "International Memorial",
      area_code: "RRT",
      city_name: "Warroad",
      country_code: "US"
  },
  {
      id: "RRV",
      name: "Robinson River",
      area_code: "RRV",
      city_name: "Robinson River",
      country_code: "AU"
  },
  {
      id: "RSB",
      name: "Roseberth",
      area_code: "RSB",
      city_name: "Roseberth",
      country_code: "AU"
  },
  {
      id: "RSK",
      name: "Abresso",
      area_code: "RSK",
      city_name: "Ransiki",
      country_code: "ID"
  },
  {
      id: "RSL",
      name: "Municipal",
      area_code: "RSL",
      city_name: "Russell",
      country_code: "US"
  },
  {
      id: "RSS",
      name: "Roseires Airport",
      area_code: "RSS",
      city_name: "Damazin",
      country_code: "SD"
  },
  {
      id: "RSX",
      name: "Seaplane Base",
      area_code: "RSX",
      city_name: "Rouses Point",
      country_code: "US"
  },
  {
      id: "RTL",
      name: "Municipal",
      area_code: "RTL",
      city_name: "Spirit Lake",
      country_code: "US"
  },
  {
      id: "RTP",
      name: "Rutland Plains",
      area_code: "RTP",
      city_name: "Rutland Plains",
      country_code: "AU"
  },
  {
      id: "RTY",
      name: "Merty Merty",
      area_code: "RTY",
      city_name: "Merty Merty",
      country_code: "AU"
  },
  {
      id: "RUA",
      name: "ARUA AIRPORT",
      area_code: "RUA",
      city_name: "Arua",
      country_code: "UG"
  },
  {
      id: "RUD",
      name: "Shahrud",
      area_code: "RUD",
      city_name: "Shahrud",
      country_code: "IR"
  },
  {
      id: "RUE",
      name: "Butembo",
      area_code: "RUE",
      city_name: "Butembo",
      country_code: "CD"
  },
  {
      id: "RUF",
      name: "Yuruf",
      area_code: "RUF",
      city_name: "Yuruf",
      country_code: "ID"
  },
  {
      id: "RUK",
      name: "Chaurjahari",
      area_code: "RUK",
      city_name: "Rukumkot",
      country_code: "NP"
  },
  {
      id: "RUM",
      name: "Rumjatar",
      area_code: "RUM",
      city_name: "Rumjatar",
      country_code: "NP"
  },
  {
      id: "RUR",
      name: "RURUTU AIRPORT",
      area_code: "RUR",
      city_name: "Rurutu",
      country_code: "PF"
  },
  {
      id: "RUS",
      name: "Marau Sound Airport",
      area_code: "RUS",
      city_name: "Marau",
      country_code: "SB"
  },
  {
      id: "RUU",
      name: "Ruti",
      area_code: "RUU",
      city_name: "Kawbenaberi",
      country_code: "PG"
  },
  {
      id: "RUV",
      name: "Rubelsanto",
      area_code: "RUV",
      city_name: "Rubelsanto",
      country_code: "GT"
  },
  {
      id: "RVA",
      name: "Farafangana",
      area_code: "RVA",
      city_name: "Farafangana",
      country_code: "MG"
  },
  {
      id: "RVC",
      name: "River Cess",
      area_code: "RVC",
      city_name: "River Cess",
      country_code: "LR"
  },
  {
      id: "RVI",
      name: "Rostov Airport",
      area_code: "ROV",
      city_name: "Rostov",
      country_code: "RU"
  },
  {
      id: "RVO",
      name: "Reivilo",
      area_code: "RVO",
      city_name: "Reivilo",
      country_code: "ZA"
  },
  {
      id: "RVV",
      name: "Raivavae Airport",
      area_code: "RVV",
      city_name: "Raivavae",
      country_code: "PF"
  },
  {
      id: "RVY",
      name: "Oscar D. Gestido",
      area_code: "RVY",
      city_name: "Rivera",
      country_code: "UY"
  },
  {
      id: "RWF",
      name: "Municipal",
      area_code: "RWF",
      city_name: "Redwood Falls",
      country_code: "US"
  },
  {
      id: "RXA",
      name: "Rawdah",
      area_code: "RXA",
      city_name: "Rawdah",
      country_code: "YE"
  },
  {
      id: "RXE",
      name: "Madison County",
      area_code: "RXE",
      city_name: "Rexburg",
      country_code: "US"
  },
  {
      id: "RYN",
      name: "Medis",
      area_code: "RYN",
      city_name: "Royan",
      country_code: "FR"
  },
  {
      id: "RYO",
      name: "Rio Turbio",
      area_code: "RYO",
      city_name: "Rio Turbio",
      country_code: "AR"
  },
  {
      id: "RZA",
      name: "Santa Cruz",
      area_code: "RZA",
      city_name: "Puerto Santa Cruz",
      country_code: "AR"
  },
  {
      id: "RZN",
      name: "Turlatovo",
      area_code: "RZN",
      city_name: "Ryazan",
      country_code: "RU"
  },
  {
      id: "RZP",
      name: "Cesar Lim Rodriguez",
      area_code: "RZP",
      city_name: "Taytay",
      country_code: "PH"
  },
  {
      id: "RZR",
      name: "RAMSAR AIRPORT",
      area_code: "RZR",
      city_name: "Ramsar",
      country_code: "IR"
  },
  {
      id: "RZS",
      name: "Sawan Airport",
      area_code: "RZS",
      city_name: "Sawan",
      country_code: "PK"
  },
  {
      id: "SAD",
      name: "Regional",
      area_code: "SAD",
      city_name: "Safford",
      country_code: "US"
  },
  {
      id: "SAQ",
      name: "San Andros",
      area_code: "SAQ",
      city_name: "San Andros",
      country_code: "BS"
  },
  {
      id: "SAX",
      name: "Sambu",
      area_code: "SAX",
      city_name: "Sambu",
      country_code: "PA"
  },
  {
      id: "SBB",
      name: "Santa Barbara Barinas",
      area_code: "SBB",
      city_name: "Santa Barbara Barinas",
      country_code: "VE"
  },
  {
      id: "SBC",
      name: "Selbang",
      area_code: "SBC",
      city_name: "Selbang",
      country_code: "PG"
  },
  {
      id: "SBE",
      name: "Suabi",
      area_code: "SBE",
      city_name: "Suabi",
      country_code: "PG"
  },
  {
      id: "SBF",
      name: "Sardeh Band",
      area_code: "SBF",
      city_name: "Sardeh Band",
      country_code: "AF"
  },
  {
      id: "SBG",
      name: "Maimun Saleh Airport",
      area_code: "SBG",
      city_name: "Sabang",
      country_code: "ID"
  },
  {
      id: "SBI",
      name: "Sambailo",
      area_code: "SBI",
      city_name: "Koundara",
      country_code: "GN"
  },
  {
      id: "SBJ",
      name: "Sao Mateus",
      area_code: "SBJ",
      city_name: "Sao Mateus",
      country_code: "BR"
  },
  {
      id: "SBL",
      name: "Santa Ana del Yacuma",
      area_code: "SBL",
      city_name: "Santa Ana del Yacuma",
      country_code: "BO"
  },
  {
      id: "SBO",
      name: "Salina/Gunnison",
      area_code: "SBO",
      city_name: "Salina/Gunnison",
      country_code: "US"
  },
  {
      id: "SBQ",
      name: "Sibi",
      area_code: "SBQ",
      city_name: "Sibi",
      country_code: "PK"
  },
  {
      id: "SBR",
      name: "Saibai Island",
      area_code: "SBR",
      city_name: "Saibai Island",
      country_code: "AU"
  },
  {
      id: "SBT",
      name: "San Bernardino Tri-City Apt",
      area_code: "SBT",
      city_name: "Sabetta",
      country_code: "RU"
  },
  {
      id: "SBV",
      name: "Sabah",
      area_code: "SBV",
      city_name: "Sabah",
      country_code: "PG"
  },
  {
      id: "SBX",
      name: "Shelby",
      area_code: "SBX",
      city_name: "Shelby",
      country_code: "US"
  },
  {
      id: "SCA",
      name: "Santa Catalina",
      area_code: "SCA",
      city_name: "Santa Catalina",
      country_code: "CO"
  },
  {
      id: "SCB",
      name: "State",
      area_code: "SCB",
      city_name: "Scribner",
      country_code: "US"
  },
  {
      id: "SCD",
      name: "Sulaco",
      area_code: "SCD",
      city_name: "Sulaco",
      country_code: "HN"
  },
  {
      id: "SCG",
      name: "Spring Creek",
      area_code: "SCG",
      city_name: "Spring Creek",
      country_code: "AU"
  },
  {
      id: "SCM",
      name: "Scammon Bay Airport",
      area_code: "SCM",
      city_name: "Scammon Bay",
      country_code: "US"
  },
  {
      id: "SCP",
      name: "St Crepin",
      area_code: "SCP",
      city_name: "Mont-Dauphin",
      country_code: "FR"
  },
  {
      id: "SDC",
      name: "Sand Creek",
      area_code: "SDC",
      city_name: "Sand Creek",
      country_code: "GY"
  },
  {
      id: "SDG",
      name: "SANANDAJ AIRPORT",
      area_code: "SDG",
      city_name: "Sanandaj",
      country_code: "IR"
  },
  {
      id: "SDH",
      name: "Santa Rosa de Copan",
      area_code: "SDH",
      city_name: "Santa Rosa de Copan",
      country_code: "HN"
  },
  {
      id: "SDI",
      name: "Saidor",
      area_code: "SDI",
      city_name: "Saidor",
      country_code: "PG"
  },
  {
      id: "SDS",
      name: "Sado",
      area_code: "SDS",
      city_name: "Sado",
      country_code: "JP"
  },
  {
      id: "SDT",
      name: "Saidu Sharif",
      area_code: "SDT",
      city_name: "Saidu Sharif",
      country_code: "PK"
  },
  {
      id: "SEB",
      name: "SEBHA AIRPORT",
      area_code: "SEB",
      city_name: "Sebha",
      country_code: "LY"
  },
  {
      id: "SED",
      name: "Min'hat Hashnayim",
      area_code: "SED",
      city_name: "Sedom",
      country_code: "IL"
  },
  {
      id: "SEG",
      name: "Penn Valley",
      area_code: "SEG",
      city_name: "Selinsgrove",
      country_code: "US"
  },
  {
      id: "SEH",
      name: "Senggeh",
      area_code: "SEH",
      city_name: "Senggeh",
      country_code: "ID"
  },
  {
      id: "SEO",
      name: "Seguela",
      area_code: "SEO",
      city_name: "Seguela",
      country_code: "CI"
  },
  {
      id: "SEQ",
      name: "Sei Pakning",
      area_code: "SEQ",
      city_name: "Bengkalis",
      country_code: "ID"
  },
  {
      id: "SER",
      name: "Freeman Municipal",
      area_code: "SER",
      city_name: "Seymour",
      country_code: "US"
  },
  {
      id: "SET",
      name: "Santa Magalhaes",
      area_code: "SET",
      city_name: "Serra Talhada",
      country_code: "BR"
  },
  {
      id: "SEV",
      name: "Severodonetsk",
      area_code: "SEV",
      city_name: "Severodonetsk",
      country_code: "UA"
  },
  {
      id: "SEW",
      name: "North",
      area_code: "SEW",
      city_name: "Siwa Oasis",
      country_code: "EG"
  },
  {
      id: "SFC",
      name: "St-Francois",
      area_code: "SFC",
      city_name: "St-Francois",
      country_code: "GP"
  },
  {
      id: "SFK",
      name: "Soure",
      area_code: "SFK",
      city_name: "Soure",
      country_code: "BR"
  },
  {
      id: "SFU",
      name: "Safia",
      area_code: "SFU",
      city_name: "Safia",
      country_code: "PG"
  },
  {
      id: "SFV",
      name: "Fazenda Tres Irmaos",
      area_code: "SFV",
      city_name: "Santa Fe do Sul",
      country_code: "BR"
  },
  {
      id: "SGA",
      name: "Sheghnan",
      area_code: "SGA",
      city_name: "Sheghnan",
      country_code: "AF"
  },
  {
      id: "SGB",
      name: "Singaua",
      area_code: "SGB",
      city_name: "Singaua",
      country_code: "PG"
  },
  {
      id: "SGG",
      name: "SERMILIGAAQ AIRPORT",
      area_code: "SGG",
      city_name: "Sermiligaaq",
      country_code: "GL"
  },
  {
      id: "SGJ",
      name: "Sagarai",
      area_code: "SGJ",
      city_name: "Sagarai",
      country_code: "PG"
  },
  {
      id: "SGK",
      name: "Sangapi",
      area_code: "SGK",
      city_name: "Sangapi",
      country_code: "PG"
  },
  {
      id: "SGM",
      name: "San Ignacio",
      area_code: "SGM",
      city_name: "San Ignacio",
      country_code: "MX"
  },
  {
      id: "SGP",
      name: "Shay Gap",
      area_code: "SGP",
      city_name: "Shay Gap",
      country_code: "AU"
  },
  {
      id: "SGQ",
      name: "Sangkimah",
      area_code: "SGQ",
      city_name: "Sangata",
      country_code: "ID"
  },
  {
      id: "SGV",
      name: "Sierra Grande",
      area_code: "SGV",
      city_name: "Sierra Grande",
      country_code: "AR"
  },
  {
      id: "SGW",
      name: "Saginaw SPB",
      area_code: "SGW",
      city_name: "Saginaw Bay",
      country_code: "US"
  },
  {
      id: "SGX",
      name: "SONGEA AIRPORT",
      area_code: "SGX",
      city_name: "Songea",
      country_code: "TZ"
  },
  {
      id: "SGZ",
      name: "Songkhla",
      area_code: "SGZ",
      city_name: "Songkhla",
      country_code: "TH"
  },
  {
      id: "SHF",
      name: "Huayuan Airport",
      area_code: "SHF",
      city_name: "Shihezi",
      country_code: "CN"
  },
  {
      id: "SHG",
      name: "SHUNGNAK AIRPORT",
      area_code: "SHG",
      city_name: "Shungnak",
      country_code: "US"
  },
  {
      id: "SHI",
      name: "Shimojishima",
      area_code: "SHI",
      city_name: "Shimojishima",
      country_code: "JP"
  },
  {
      id: "SHK",
      name: "Sehonghong",
      area_code: "SHK",
      city_name: "Sehonghong",
      country_code: "LS"
  },
  {
      id: "SHN",
      name: "Sanderson Field",
      area_code: "SHN",
      city_name: "Shelton",
      country_code: "US"
  },
  {
      id: "SHQ",
      name: "Southport",
      area_code: "SHQ",
      city_name: "Southport",
      country_code: "AU"
  },
  {
      id: "SHU",
      name: "Smith Point",
      area_code: "SHU",
      city_name: "Smith Point",
      country_code: "AU"
  },
  {
      id: "SHX",
      name: "SHAGELUK AIRPORT",
      area_code: "SHX",
      city_name: "Shageluk",
      country_code: "US"
  },
  {
      id: "SHZ",
      name: "Seshote",
      area_code: "SHZ",
      city_name: "Seshote",
      country_code: "LS"
  },
  {
      id: "SIB",
      name: "Sibiti",
      area_code: "SIB",
      city_name: "Sibiti",
      country_code: "CG"
  },
  {
      id: "SIE",
      name: "Sines",
      area_code: "SIE",
      city_name: "Sines",
      country_code: "PT"
  },
  {
      id: "SIF",
      name: "SIMARA AIRPORT",
      area_code: "SIF",
      city_name: "Simara",
      country_code: "NP"
  },
  {
      id: "SIH",
      name: "Doti",
      area_code: "SIH",
      city_name: "Dipayal Silgadhi",
      country_code: "NP"
  },
  {
      id: "SII",
      name: "Sania Ramel",
      area_code: "SII",
      city_name: "Sidi Ifni",
      country_code: "MA"
  },
  {
      id: "SIJ",
      name: "Siglufjordur",
      area_code: "SIJ",
      city_name: "Siglufjordur",
      country_code: "IS"
  },
  {
      id: "SIK",
      name: "Memorial Municipal",
      area_code: "SIK",
      city_name: "Sikeston",
      country_code: "US"
  },
  {
      id: "SIL",
      name: "Sila",
      area_code: "SIL",
      city_name: "Sila",
      country_code: "PG"
  },
  {
      id: "SIM",
      name: "Simbai",
      area_code: "SIM",
      city_name: "Simbai",
      country_code: "PG"
  },
  {
      id: "SIO",
      name: "Smithton",
      area_code: "SIO",
      city_name: "Smithton",
      country_code: "AU"
  },
  {
      id: "SIQ",
      name: "Dabo",
      area_code: "SIQ",
      city_name: "Singkep",
      country_code: "ID"
  },
  {
      id: "SIU",
      name: "Siuna",
      area_code: "SIU",
      city_name: "Siuna",
      country_code: "NI"
  },
  {
      id: "SIV",
      name: "Sullivan County",
      area_code: "SIV",
      city_name: "Sullivan",
      country_code: "US"
  },
  {
      id: "SIW",
      name: "Sibisa",
      area_code: "SIW",
      city_name: "Parapat",
      country_code: "ID"
  },
  {
      id: "SIZ",
      name: "Sissano",
      area_code: "SIZ",
      city_name: "Sissano",
      country_code: "PG"
  },
  {
      id: "SJA",
      name: "San Juan",
      area_code: "SJA",
      city_name: "San Juan",
      country_code: "PE"
  },
  {
      id: "SJB",
      name: "San Joaquin",
      area_code: "SJB",
      city_name: "San Joaquin",
      country_code: "BO"
  },
  {
      id: "SJG",
      name: "San Pedro de Jagua",
      area_code: "SJG",
      city_name: "San Pedro de Jagua",
      country_code: "CO"
  },
  {
      id: "SJH",
      name: "San Juan del Cesar",
      area_code: "SJH",
      city_name: "San Juan del Cesar",
      country_code: "CO"
  },
  {
      id: "SJI",
      name: "San Jose Airport",
      area_code: "SJI",
      city_name: "San Jose",
      country_code: "PH"
  },
  {
      id: "SJN",
      name: "Industrial Air Park",
      area_code: "SJN",
      city_name: "St Johns",
      country_code: "US"
  },
  {
      id: "SJQ",
      name: "Sesheke",
      area_code: "SJQ",
      city_name: "Sesheke",
      country_code: "ZM"
  },
  {
      id: "SJR",
      name: "San Juan de Uraba",
      area_code: "SJR",
      city_name: "San Juan de Uraba",
      country_code: "CO"
  },
  {
      id: "SJS",
      name: "San Jose de Chiquitos",
      area_code: "SJS",
      city_name: "San Jose de Chiquitos",
      country_code: "BO"
  },
  {
      id: "SJV",
      name: "San Javier",
      area_code: "SJV",
      city_name: "San Javier",
      country_code: "BO"
  },
  {
      id: "SJX",
      name: "Sarteneja",
      area_code: "SJX",
      city_name: "Sarteneja",
      country_code: "BZ"
  },
  {
      id: "SKC",
      name: "Suki",
      area_code: "SKC",
      city_name: "Suki",
      country_code: "PG"
  },
  {
      id: "SKH",
      name: "Surkhet",
      area_code: "SKH",
      city_name: "Surkhet",
      country_code: "NP"
  },
  {
      id: "SKI",
      name: "Skikda",
      area_code: "SKI",
      city_name: "Skikda",
      country_code: "DZ"
  },
  {
      id: "SKM",
      name: "Skeldon",
      area_code: "SKM",
      city_name: "Skeldon",
      country_code: "GY"
  },
  {
      id: "SKQ",
      name: "Sekake",
      area_code: "SKQ",
      city_name: "Sekake",
      country_code: "LS"
  },
  {
      id: "SKR",
      name: "Shakiso",
      area_code: "SKR",
      city_name: "Shakiso",
      country_code: "ET"
  },
  {
      id: "SKV",
      name: "International",
      area_code: "SKV",
      city_name: "St Catherine",
      country_code: "EG"
  },
  {
      id: "SKW",
      name: "Skwentna",
      area_code: "SKW",
      city_name: "Skwentna",
      country_code: "US"
  },
  {
      id: "SLF",
      name: "Sulayel",
      area_code: "SLF",
      city_name: "Sulayel",
      country_code: "SA"
  },
  {
      id: "SLH",
      name: "Vanua Lava Airport",
      area_code: "SLH",
      city_name: "Sola",
      country_code: "VU"
  },
  {
      id: "SLJ",
      name: "Solomon",
      area_code: "SLJ",
      city_name: "Solomon",
      country_code: "AU"
  },
  {
      id: "SLO",
      name: "Leckrone",
      area_code: "SLO",
      city_name: "Salem",
      country_code: "US"
  },
  {
      id: "SLQ",
      name: "SLEETMUTE AIRPORT",
      area_code: "SLQ",
      city_name: "Sleetmute",
      country_code: "US"
  },
  {
      id: "SLR",
      name: "Municipal",
      area_code: "SLR",
      city_name: "Sulphur Springs",
      country_code: "US"
  },
  {
      id: "SLS",
      name: "Polkovnik-Lambrinovo",
      area_code: "SLS",
      city_name: "Silistra",
      country_code: "BG"
  },
  {
      id: "SLX",
      name: "SALT CAY AIRPORT",
      area_code: "SLX",
      city_name: "Salt Cay",
      country_code: "TC"
  },
  {
      id: "SMB",
      name: "Franco Bianco",
      area_code: "SMB",
      city_name: "Cerro Sombrero",
      country_code: "CL"
  },
  {
      id: "SMC",
      name: "Santa Maria",
      area_code: "SMC",
      city_name: "Santa Maria",
      country_code: "CO"
  },
  {
      id: "SMG",
      name: "Santa Maria",
      area_code: "SMG",
      city_name: "Santa Maria",
      country_code: "PE"
  },
  {
      id: "SMH",
      name: "Sapmanga",
      area_code: "SMH",
      city_name: "Sapmanga",
      country_code: "PG"
  },
  {
      id: "SMJ",
      name: "Sim",
      area_code: "SMJ",
      city_name: "Sim",
      country_code: "PG"
  },
  {
      id: "SMM",
      name: "Semporna",
      area_code: "SMM",
      city_name: "Semporna",
      country_code: "MY"
  },
  {
      id: "SMN",
      name: "Lemhi County",
      area_code: "SMN",
      city_name: "Salmon",
      country_code: "US"
  },
  {
      id: "SMP",
      name: "Stockholm",
      area_code: "SMP",
      city_name: "Stockholm",
      country_code: "PG"
  },
  {
      id: "SMQ",
      name: "Sampit Airport",
      area_code: "SMQ",
      city_name: "Sampit",
      country_code: "ID"
  },
  {
      id: "SMT",
      name: "Sorriso Airport",
      area_code: "SMT",
      city_name: "Sorriso",
      country_code: "BR"
  },
  {
      id: "SMU",
      name: "Sheep Mountain",
      area_code: "SMU",
      city_name: "Sheep Mountain",
      country_code: "US"
  },
  {
      id: "SMY",
      name: "Simenti",
      area_code: "SMY",
      city_name: "Simenti",
      country_code: "SN"
  },
  {
      id: "SMZ",
      name: "Stoelmanseiland",
      area_code: "SMZ",
      city_name: "Stoelmanseiland",
      country_code: "SR"
  },
  {
      id: "SNB",
      name: "Snake Bay",
      area_code: "SNB",
      city_name: "Milikapiti",
      country_code: "AU"
  },
  {
      id: "SNG",
      name: "Cap.Juan Cochamanidis",
      area_code: "SNG",
      city_name: "San Ignacio de Velasco",
      country_code: "BO"
  },
  {
      id: "SNJ",
      name: "Air Base",
      area_code: "SNJ",
      city_name: "San Julian",
      country_code: "CU"
  },
  {
      id: "SNK",
      name: "Winston Field",
      area_code: "SNK",
      city_name: "Snyder",
      country_code: "US"
  },
  {
      id: "SNM",
      name: "San Ignacio de Moxos",
      area_code: "SNM",
      city_name: "San Ignacio de Moxos",
      country_code: "BO"
  },
  {
      id: "SNT",
      name: "Las Cruces",
      area_code: "SNT",
      city_name: "Sabana de Torres",
      country_code: "CO"
  },
  {
      id: "SNX",
      name: "Semnan",
      area_code: "SNX",
      city_name: "Semnan",
      country_code: "IR"
  },
  {
      id: "SNZ",
      name: "Santa Cruz AB",
      area_code: "RIO",
      city_name: "Rio de Janeiro",
      country_code: "BR"
  },
  {
      id: "SOA",
      name: "Soc Trang",
      area_code: "SOA",
      city_name: "Soc Trang",
      country_code: "VN"
  },
  {
      id: "SOE",
      name: "Souanke",
      area_code: "SOE",
      city_name: "Souanke",
      country_code: "CG"
  },
  {
      id: "SOH",
      name: "Solita",
      area_code: "SOH",
      city_name: "Solita",
      country_code: "CO"
  },
  {
      id: "SOK",
      name: "Semongkong",
      area_code: "SOK",
      city_name: "Semongkong",
      country_code: "LS"
  },
  {
      id: "SOR",
      name: "Al Thaurah",
      area_code: "SOR",
      city_name: "Al Thaurah",
      country_code: "SY"
  },
  {
      id: "SOT",
      name: "Sodankyla",
      area_code: "SOT",
      city_name: "Sodankyla",
      country_code: "FI"
  },
  {
      id: "SOZ",
      name: "Solenzara",
      area_code: "SOZ",
      city_name: "Solenzara",
      country_code: "FR"
  },
  {
      id: "SPE",
      name: "Sepulot",
      area_code: "SPE",
      city_name: "Sepulot",
      country_code: "MY"
  },
  {
      id: "SPH",
      name: "Sopu",
      area_code: "SPH",
      city_name: "Sopu",
      country_code: "PG"
  },
  {
      id: "SPP",
      name: "MENONGUE AIRPORT",
      area_code: "SPP",
      city_name: "Menongue",
      country_code: "AO"
  },
  {
      id: "SPT",
      name: "Sipitang",
      area_code: "SPT",
      city_name: "Sipitang",
      country_code: "MY"
  },
  {
      id: "SPV",
      name: "Sepik Plains",
      area_code: "SPV",
      city_name: "Sepik Plains",
      country_code: "PG"
  },
  {
      id: "SPY",
      name: "San Pedro Airport",
      area_code: "SPY",
      city_name: "San-Pedro",
      country_code: "CI"
  },
  {
      id: "SQB",
      name: "Santa Ana",
      area_code: "SQB",
      city_name: "Piedras",
      country_code: "CO"
  },
  {
      id: "SQE",
      name: "San Luis de Palenque",
      area_code: "SQE",
      city_name: "San Luis de Palenque",
      country_code: "CO"
  },
  {
      id: "SQF",
      name: "Solano",
      area_code: "SQF",
      city_name: "Solano",
      country_code: "CO"
  },
  {
      id: "SQG",
      name: "Sintang Airport",
      area_code: "SQG",
      city_name: "Sintang",
      country_code: "ID"
  },
  {
      id: "SQK",
      name: "Sidi Barrani",
      area_code: "SQK",
      city_name: "Sidi Barrani",
      country_code: "EG"
  },
  {
      id: "SQM",
      name: "Sao Miguel do Araguaia",
      area_code: "SQM",
      city_name: "Sao Miguel do Araguaia",
      country_code: "BR"
  },
  {
      id: "SQN",
      name: "Emalamo",
      area_code: "SQN",
      city_name: "Sanana",
      country_code: "ID"
  },
  {
      id: "SQQ",
      name: "International",
      area_code: "SQQ",
      city_name: "Siauliai",
      country_code: "LT"
  },
  {
      id: "SQR",
      name: "Soroako",
      area_code: "SQR",
      city_name: "Soroako",
      country_code: "ID"
  },
  {
      id: "SQT",
      name: "China Strait",
      area_code: "SQT",
      city_name: "Samarai Island",
      country_code: "PG"
  },
  {
      id: "SQU",
      name: "Saposoa",
      area_code: "SQU",
      city_name: "Saposoa",
      country_code: "PE"
  },
  {
      id: "SQV",
      name: "Sequim Valley",
      area_code: "SQV",
      city_name: "Sequim",
      country_code: "US"
  },
  {
      id: "SQX",
      name: "Helio Wasum",
      area_code: "SQX",
      city_name: "Sao Miguel do Oeste",
      country_code: "BR"
  },
  {
      id: "SQY",
      name: "Sao Lourenco do Sul",
      area_code: "SQY",
      city_name: "Sao Lourenco do Sul",
      country_code: "BR"
  },
  {
      id: "SQZ",
      name: "RAF Station",
      area_code: "SQZ",
      city_name: "Scampton",
      country_code: "GB"
  },
  {
      id: "SRB",
      name: "Santa Rosa",
      area_code: "SRB",
      city_name: "Santa Rosa",
      country_code: "BO"
  },
  {
      id: "SRD",
      name: "San Ramon",
      area_code: "SRD",
      city_name: "San Ramon",
      country_code: "BO"
  },
  {
      id: "SRH",
      name: "Sarh",
      area_code: "SRH",
      city_name: "Sarh",
      country_code: "TD"
  },
  {
      id: "SRJ",
      name: "German Quiroga Guardia",
      area_code: "SRJ",
      city_name: "San Borja",
      country_code: "BO"
  },
  {
      id: "SRM",
      name: "Sandringham Station",
      area_code: "SRM",
      city_name: "Sandringham Station",
      country_code: "AU"
  },
  {
      id: "SRO",
      name: "Santana Ramos",
      area_code: "SRO",
      city_name: "Santana Ramos",
      country_code: "CO"
  },
  {
      id: "SRR",
      name: "Dunwich",
      area_code: "SRR",
      city_name: "Stradbroke Island",
      country_code: "AU"
  },
  {
      id: "SRS",
      name: "San Marcos",
      area_code: "SRS",
      city_name: "San Marcos",
      country_code: "CO"
  },
  {
      id: "SRT",
      name: "Soroti",
      area_code: "SRT",
      city_name: "Soroti",
      country_code: "UG"
  },
  {
      id: "SRV",
      name: "STONY RIVER AIRPORT",
      area_code: "SRV",
      city_name: "Stony River",
      country_code: "US"
  },
  {
      id: "SRX",
      name: "Gardabya",
      area_code: "SRX",
      city_name: "Sirte",
      country_code: "LY"
  },
  {
      id: "SRY",
      name: "Dasht-e Naz Airport",
      area_code: "SRY",
      city_name: "Sari",
      country_code: "IR"
  },
  {
      id: "SSD",
      name: "San Felipe",
      area_code: "SSD",
      city_name: "San Felipe",
      country_code: "CO"
  },
  {
      id: "SSK",
      name: "Sturt Creek",
      area_code: "SSK",
      city_name: "Sturt Creek",
      country_code: "AU"
  },
  {
      id: "SSL",
      name: "Santa Rosalia",
      area_code: "SSL",
      city_name: "Santa Rosalia",
      country_code: "CO"
  },
  {
      id: "SSM",
      name: "Municipal",
      area_code: "SSM",
      city_name: "Sault Ste Marie",
      country_code: "US"
  },
  {
      id: "SSO",
      name: "Sao Lourenco",
      area_code: "SSO",
      city_name: "Sao Lourenco",
      country_code: "BR"
  },
  {
      id: "SSP",
      name: "Silver Plains",
      area_code: "SSP",
      city_name: "Silver Plains",
      country_code: "AU"
  },
  {
      id: "SSS",
      name: "Siassi",
      area_code: "SSS",
      city_name: "Siassi",
      country_code: "PG"
  },
  {
      id: "SST",
      name: "Santa Teresita",
      area_code: "SST",
      city_name: "Santa Teresita",
      country_code: "AR"
  },
  {
      id: "SSV",
      name: "Siasi",
      area_code: "SSV",
      city_name: "Siasi",
      country_code: "PH"
  },
  {
      id: "SSY",
      name: "M'banza Congo",
      area_code: "SSY",
      city_name: "M'banza Congo",
      country_code: "AO"
  },
  {
      id: "STA",
      name: "Stauning",
      area_code: "STA",
      city_name: "Stauning",
      country_code: "DK"
  },
  {
      id: "STF",
      name: "Stephens Island",
      area_code: "STF",
      city_name: "Stephens Island",
      country_code: "AU"
  },
  {
      id: "STH",
      name: "Strathmore",
      area_code: "STH",
      city_name: "Strathmore",
      country_code: "AU"
  },
  {
      id: "STK",
      name: "Municipal",
      area_code: "STK",
      city_name: "Sterling",
      country_code: "US"
  },
  {
      id: "STQ",
      name: "Municipal",
      area_code: "STQ",
      city_name: "St Marys",
      country_code: "US"
  },
  {
      id: "STZ",
      name: "Santa Terezinha",
      area_code: "STZ",
      city_name: "Santa Terezinha",
      country_code: "BR"
  },
  {
      id: "SUD",
      name: "Municipal",
      area_code: "SUD",
      city_name: "Stroud",
      country_code: "US"
  },
  {
      id: "SUI",
      name: "Dranda",
      area_code: "SUI",
      city_name: "Sokhumi",
      country_code: "GE"
  },
  {
      id: "SUK",
      name: "Sakkyryr Airport",
      area_code: "SUK",
      city_name: "Sakkyryr",
      country_code: "RU"
  },
  {
      id: "SUO",
      name: "Sunriver",
      area_code: "SUO",
      city_name: "Sunriver",
      country_code: "US"
  },
  {
      id: "SUP",
      name: "Sumenep",
      area_code: "SUP",
      city_name: "Sumenep",
      country_code: "ID"
  },
  {
      id: "SUQ",
      name: "Sucua",
      area_code: "SUQ",
      city_name: "Sucua",
      country_code: "EC"
  },
  {
      id: "SUT",
      name: "Sumbawanga",
      area_code: "SUT",
      city_name: "Sumbawanga",
      country_code: "TZ"
  },
  {
      id: "SUZ",
      name: "Suria",
      area_code: "SUZ",
      city_name: "Suria",
      country_code: "PG"
  },
  {
      id: "SVA",
      name: "SAVOONGA AIRPORT",
      area_code: "SVA",
      city_name: "Savoonga",
      country_code: "US"
  },
  {
      id: "SVE",
      name: "Municipal",
      area_code: "SVE",
      city_name: "Susanville",
      country_code: "US"
  },
  {
      id: "SVF",
      name: "Save",
      area_code: "SVF",
      city_name: "Save",
      country_code: "BJ"
  },
  {
      id: "SVK",
      name: "SILVER CREEK AIRPORT",
      area_code: "SVK",
      city_name: "Silver Creek",
      country_code: "BZ"
  },
  {
      id: "SVM",
      name: "St Pauls",
      area_code: "SVM",
      city_name: "St Pauls",
      country_code: "AU"
  },
  {
      id: "SVP",
      name: "KUITO AIRPORT",
      area_code: "SVP",
      city_name: "Kuito",
      country_code: "AO"
  },
  {
      id: "SVS",
      name: "STEVENS VILLAGE AIRPORT",
      area_code: "SVS",
      city_name: "Stevens Village",
      country_code: "US"
  },
  {
      id: "SVT",
      name: "Savuti",
      area_code: "SVT",
      city_name: "Savuti",
      country_code: "BW"
  },
  {
      id: "SVV",
      name: "San Salvador de Paul",
      area_code: "SVV",
      city_name: "San Salvador de Paul",
      country_code: "VE"
  },
  {
      id: "SVY",
      name: "Savo Island",
      area_code: "SVY",
      city_name: "Savo Island",
      country_code: "SB"
  },
  {
      id: "SWB",
      name: "Shaw River",
      area_code: "SWB",
      city_name: "Shaw River",
      country_code: "AU"
  },
  {
      id: "SWC",
      name: "Stawell",
      area_code: "SWC",
      city_name: "Stawell",
      country_code: "AU"
  },
  {
      id: "SWE",
      name: "Siwea",
      area_code: "SWE",
      city_name: "Siwea",
      country_code: "PG"
  },
  {
      id: "SWG",
      name: "Satwag",
      area_code: "SWG",
      city_name: "Satwag",
      country_code: "PG"
  },
  {
      id: "SWJ",
      name: "SOUTH WEST BAY AIRPORT",
      area_code: "SWJ",
      city_name: "South West Bay",
      country_code: "VU"
  },
  {
      id: "SWL",
      name: "San Vicente",
      area_code: "SWL",
      city_name: "San Vicente",
      country_code: "PH"
  },
  {
      id: "SWM",
      name: "Suia-Missu",
      area_code: "SWM",
      city_name: "Alto Boa Vista",
      country_code: "BR"
  },
  {
      id: "SWN",
      name: "Sahiwal",
      area_code: "SWN",
      city_name: "Sahiwal",
      country_code: "PK"
  },
  {
      id: "SWR",
      name: "Silur",
      area_code: "SWR",
      city_name: "Silur",
      country_code: "PG"
  },
  {
      id: "SWT",
      name: "Strezhevoy",
      area_code: "SWT",
      city_name: "Strezhevoy",
      country_code: "RU"
  },
  {
      id: "SWV",
      name: "Evensk",
      area_code: "SWV",
      city_name: "Evensk",
      country_code: "RU"
  },
  {
      id: "SWX",
      name: "Shakawe",
      area_code: "SWX",
      city_name: "Shakawe",
      country_code: "BW"
  },
  {
      id: "SWY",
      name: "Sitiawan",
      area_code: "SWY",
      city_name: "Sitiawan",
      country_code: "MY"
  },
  {
      id: "SXA",
      name: "Sialum",
      area_code: "SXA",
      city_name: "Sialum",
      country_code: "PG"
  },
  {
      id: "SXG",
      name: "Senanga",
      area_code: "SXG",
      city_name: "Senanga",
      country_code: "ZM"
  },
  {
      id: "SXH",
      name: "Sehulea",
      area_code: "SXH",
      city_name: "Sehulea",
      country_code: "PG"
  },
  {
      id: "SXI",
      name: "Sirri Island",
      area_code: "SXI",
      city_name: "Sirri Island",
      country_code: "IR"
  },
  {
      id: "SXN",
      name: "Sua Pan",
      area_code: "SXN",
      city_name: "Sua Pan",
      country_code: "BW"
  },
  {
      id: "SXO",
      name: "Sao Felix do Araguaia",
      area_code: "SXO",
      city_name: "Sao Felix do Araguaia",
      country_code: "BR"
  },
  {
      id: "SXP",
      name: "Sheldon Point Airport",
      area_code: "SXP",
      city_name: "Nunam Iqua",
      country_code: "US"
  },
  {
      id: "SXQ",
      name: "Soldotna",
      area_code: "SXQ",
      city_name: "Soldotna",
      country_code: "US"
  },
  {
      id: "SXS",
      name: "Sahabat",
      area_code: "SXS",
      city_name: "Sahabat",
      country_code: "MY"
  },
  {
      id: "SXT",
      name: "Sungai Tiang",
      area_code: "SXT",
      city_name: "Taman Negara",
      country_code: "MY"
  },
  {
      id: "SXU",
      name: "Sodo",
      area_code: "SXU",
      city_name: "Sodo",
      country_code: "ET"
  },
  {
      id: "SXX",
      name: "Sao Felix do Xingu",
      area_code: "SXX",
      city_name: "Sao Felix do Xingu",
      country_code: "BR"
  },
  {
      id: "SXY",
      name: "Municipal",
      area_code: "SXY",
      city_name: "Sidney",
      country_code: "US"
  },
  {
      id: "SXZ",
      name: "SIIRT AIRPORT",
      area_code: "SXZ",
      city_name: "Siirt",
      country_code: "TR"
  },
  {
      id: "SYA",
      name: "Eareckson Air Station",
      area_code: "SYA",
      city_name: "Shemya",
      country_code: "US"
  },
  {
      id: "SYB",
      name: "Sea Plane Base",
      area_code: "SYB",
      city_name: "Seal Bay",
      country_code: "US"
  },
  {
      id: "SYC",
      name: "Shiringayoc",
      area_code: "SYC",
      city_name: "Shiringayoc",
      country_code: "PE"
  },
  {
      id: "SYE",
      name: "Sadah",
      area_code: "SYE",
      city_name: "Sadah",
      country_code: "YE"
  },
  {
      id: "SYF",
      name: "Silva Bay SPB",
      area_code: "SYF",
      city_name: "Gabriola Island",
      country_code: "CA"
  },
  {
      id: "SYH",
      name: "Syangboche",
      area_code: "SYH",
      city_name: "Syangboche",
      country_code: "NP"
  },
  {
      id: "SYJ",
      name: "Sirjan",
      area_code: "SYJ",
      city_name: "Sirjan",
      country_code: "IR"
  },
  {
      id: "SYK",
      name: "Stykkisholmur",
      area_code: "SYK",
      city_name: "Stykkisholmur",
      country_code: "IS"
  },
  {
      id: "SYN",
      name: "Stanton",
      area_code: "SYN",
      city_name: "Stanton",
      country_code: "US"
  },
  {
      id: "SYP",
      name: "Ruben Cantu",
      area_code: "SYP",
      city_name: "Santiago",
      country_code: "PA"
  },
  {
      id: "SYT",
      name: "St-Yan",
      area_code: "SYT",
      city_name: "St-Yan",
      country_code: "FR"
  },
  {
      id: "SYU",
      name: "Warraber Island",
      area_code: "SYU",
      city_name: "Warraber Island",
      country_code: "AU"
  },
  {
      id: "SYV",
      name: "Sylvester",
      area_code: "SYV",
      city_name: "Sylvester",
      country_code: "US"
  },
  {
      id: "SYW",
      name: "Sehwan Sharif",
      area_code: "SYW",
      city_name: "Sehwan",
      country_code: "PK"
  },
  {
      id: "SZA",
      name: "SOYO AIRPORT",
      area_code: "SZA",
      city_name: "Soyo",
      country_code: "AO"
  },
  {
      id: "SZE",
      name: "Semera Airport",
      area_code: "SZE",
      city_name: "Semera",
      country_code: "ET"
  },
  {
      id: "SZI",
      name: "Zaisan Airport",
      area_code: "SZI",
      city_name: "Zaysan",
      country_code: "KZ"
  },
  {
      id: "SZL",
      name: "Whiteman AFB",
      area_code: "SZL",
      city_name: "Knob Noster",
      country_code: "US"
  },
  {
      id: "SZM",
      name: "Sesriem",
      area_code: "SZM",
      city_name: "Sesriem",
      country_code: "NA"
  },
  {
      id: "SZS",
      name: "Ryan's Creek",
      area_code: "SZS",
      city_name: "Stewart Island",
      country_code: "NZ"
  },
  {
      id: "SZU",
      name: "Segou",
      area_code: "SZU",
      city_name: "Segou",
      country_code: "ML"
  },
  {
      id: "TAA",
      name: "Tarapaina",
      area_code: "TAA",
      city_name: "Tarapaina",
      country_code: "SB"
  },
  {
      id: "TAN",
      name: "Tangalooma",
      area_code: "TAN",
      city_name: "Tangalooma",
      country_code: "AU"
  },
  {
      id: "TAQ",
      name: "Tarcoola",
      area_code: "TAQ",
      city_name: "Tarcoola",
      country_code: "AU"
  },
  {
      id: "TAV",
      name: "Tau",
      area_code: "TAV",
      city_name: "Tau",
      country_code: "AS"
  },
  {
      id: "TAW",
      name: "Tacuarembo",
      area_code: "TAW",
      city_name: "Tacuarembo",
      country_code: "UY"
  },
  {
      id: "TAX",
      name: "Taliabu",
      area_code: "TAX",
      city_name: "Taliabu",
      country_code: "ID"
  },
  {
      id: "TBA",
      name: "Tabibuga",
      area_code: "TBA",
      city_name: "Tabibuga",
      country_code: "PG"
  },
  {
      id: "TBD",
      name: "Timbiqui",
      area_code: "TBD",
      city_name: "Timbiqui",
      country_code: "CO"
  },
  {
      id: "TBE",
      name: "Timbunke",
      area_code: "TBE",
      city_name: "Timbunke",
      country_code: "PG"
  },
  {
      id: "TBF",
      name: "Tabiteuea North",
      area_code: "TBF",
      city_name: "Tabiteuea North",
      country_code: "KI"
  },
  {
      id: "TBH",
      name: "Tablas Airport",
      area_code: "TBH",
      city_name: "Tablas Island",
      country_code: "PH"
  },
  {
      id: "TBK",
      name: "Timber Creek",
      area_code: "TBK",
      city_name: "Timber Creek",
      country_code: "AU"
  },
  {
      id: "TBL",
      name: "Tableland Homestead",
      area_code: "TBL",
      city_name: "Tablelands",
      country_code: "AU"
  },
  {
      id: "TBM",
      name: "Tumbang Samba",
      area_code: "TBM",
      city_name: "Tumbang Samba",
      country_code: "ID"
  },
  {
      id: "TBQ",
      name: "Tarabo",
      area_code: "TBQ",
      city_name: "Tarabo",
      country_code: "PG"
  },
  {
      id: "TBR",
      name: "Bulloch County",
      area_code: "TBR",
      city_name: "Statesboro",
      country_code: "US"
  },
  {
      id: "TBV",
      name: "Tabal Island",
      area_code: "TBV",
      city_name: "Tabal Island",
      country_code: "MH"
  },
  {
      id: "TBY",
      name: "Tshabong",
      area_code: "TBY",
      city_name: "Tshabong",
      country_code: "BW"
  },
  {
      id: "TCF",
      name: "Tocoa",
      area_code: "TCF",
      city_name: "Tocoa",
      country_code: "HN"
  },
  {
      id: "TCH",
      name: "Tchibanga",
      area_code: "TCH",
      city_name: "Tchibanga",
      country_code: "GA"
  },
  {
      id: "TCJ",
      name: "Torembi",
      area_code: "TCJ",
      city_name: "Torembi",
      country_code: "PG"
  },
  {
      id: "TCK",
      name: "Tinboli",
      area_code: "TCK",
      city_name: "Tinboli",
      country_code: "PG"
  },
  {
      id: "TCS",
      name: "Municipal",
      area_code: "TCS",
      city_name: "Truth or Consequences",
      country_code: "US"
  },
  {
      id: "TCT",
      name: "TAKOTNA AIRPORT",
      area_code: "TCT",
      city_name: "Takotna",
      country_code: "US"
  },
  {
      id: "TCX",
      name: "Tabas",
      area_code: "TCX",
      city_name: "Tabas",
      country_code: "IR"
  },
  {
      id: "TCY",
      name: "Terrace Bay",
      area_code: "TCY",
      city_name: "Terrace Bay",
      country_code: "NA"
  },
  {
      id: "TDA",
      name: "Trinidad",
      area_code: "TDA",
      city_name: "Trinidad",
      country_code: "CO"
  },
  {
      id: "TDB",
      name: "Tetebedi",
      area_code: "TDB",
      city_name: "Tetebedi",
      country_code: "PG"
  },
  {
      id: "TDG",
      name: "TANDAG AIRPORT",
      area_code: "TDG",
      city_name: "Tandag",
      country_code: "PH"
  },
  {
      id: "TDN",
      name: "Theda Station",
      area_code: "TDN",
      city_name: "Theda Station",
      country_code: "AU"
  },
  {
      id: "TDO",
      name: "South Lewis County",
      area_code: "TDO",
      city_name: "Toledo",
      country_code: "US"
  },
  {
      id: "TDP",
      name: "Trompeteros",
      area_code: "TDP",
      city_name: "Trompeteros",
      country_code: "PE"
  },
  {
      id: "TDR",
      name: "Theodore",
      area_code: "TDR",
      city_name: "Theodore",
      country_code: "AU"
  },
  {
      id: "TDT",
      name: "Tanda Tula",
      area_code: "TDT",
      city_name: "Timbavati",
      country_code: "ZA"
  },
  {
      id: "TDV",
      name: "Samangoky",
      area_code: "TDV",
      city_name: "Tanandava",
      country_code: "MG"
  },
  {
      id: "TEE",
      name: "Tbessa Airport",
      area_code: "TEE",
      city_name: "Tebessa",
      country_code: "DZ"
  },
  {
      id: "TEF",
      name: "Telfer",
      area_code: "TEF",
      city_name: "Telfer",
      country_code: "AU"
  },
  {
      id: "TEG",
      name: "Tenkodogo",
      area_code: "TEG",
      city_name: "Tenkodogo",
      country_code: "BF"
  },
  {
      id: "TEL",
      name: "Telupid",
      area_code: "TEL",
      city_name: "Telupid",
      country_code: "MY"
  },
  {
      id: "TEM",
      name: "Temora",
      area_code: "TEM",
      city_name: "Temora",
      country_code: "AU"
  },
  {
      id: "TEO",
      name: "Terapo",
      area_code: "TEO",
      city_name: "Terapo",
      country_code: "PG"
  },
  {
      id: "TEP",
      name: "Teptep",
      area_code: "TEP",
      city_name: "Teptep",
      country_code: "PG"
  },
  {
      id: "TES",
      name: "Teseney",
      area_code: "TES",
      city_name: "Teseney",
      country_code: "ER"
  },
  {
      id: "TEV",
      name: "Airport",
      area_code: "TEV",
      city_name: "Teruel",
      country_code: "ES"
  },
  {
      id: "TEY",
      name: "Selardalur",
      area_code: "TEY",
      city_name: "Thingeyri",
      country_code: "IS"
  },
  {
      id: "TFB",
      name: "Tifalmin",
      area_code: "TFB",
      city_name: "Tifalmin",
      country_code: "PG"
  },
  {
      id: "TFL",
      name: "Juscelino Kubitscheck",
      area_code: "TFL",
      city_name: "Teofilo Otoni",
      country_code: "BR"
  },
  {
      id: "TFR",
      name: "Wadi al Jandali",
      area_code: "TFR",
      city_name: "Ramadan City",
      country_code: "EG"
  },
  {
      id: "TFT",
      name: "Taftan",
      area_code: "TFT",
      city_name: "Taftan",
      country_code: "PK"
  },
  {
      id: "TGA",
      name: "Tengah",
      area_code: "SIN",
      city_name: "Singapore",
      country_code: "SG"
  },
  {
      id: "TGB",
      name: "Tagbita",
      area_code: "TGB",
      city_name: "Rizal",
      country_code: "PH"
  },
  {
      id: "TGH",
      name: "Tongoa Airport",
      area_code: "TGH",
      city_name: "Tongoa Island",
      country_code: "VU"
  },
  {
      id: "TGI",
      name: "TINGO MARIA AIRPORT",
      area_code: "TGI",
      city_name: "Tingo Maria",
      country_code: "PE"
  },
  {
      id: "TGJ",
      name: "TIGA AIRPORT",
      area_code: "TGJ",
      city_name: "Tiga",
      country_code: "NC"
  },
  {
      id: "TGL",
      name: "Tagula",
      area_code: "TGL",
      city_name: "Tagula",
      country_code: "PG"
  },
  {
      id: "TGQ",
      name: "Tangara da Serra",
      area_code: "TGQ",
      city_name: "Tangara da Serra",
      country_code: "BR"
  },
  {
      id: "TGR",
      name: "Touggourt Airport",
      area_code: "TGR",
      city_name: "Touggourt",
      country_code: "DZ"
  },
  {
      id: "TGS",
      name: "Chokwe",
      area_code: "TGS",
      city_name: "Chokwe",
      country_code: "MZ"
  },
  {
      id: "TGV",
      name: "Bukhovtsi",
      area_code: "TGV",
      city_name: "Targovishte",
      country_code: "BG"
  },
  {
      id: "THB",
      name: "Thaba-Tseka",
      area_code: "THB",
      city_name: "Thaba-Tseka",
      country_code: "LS"
  },
  {
      id: "THH",
      name: "Taharoa",
      area_code: "THH",
      city_name: "Taharoa",
      country_code: "NZ"
  },
  {
      id: "THI",
      name: "Tichit",
      area_code: "THI",
      city_name: "Tichit",
      country_code: "MR"
  },
  {
      id: "THK",
      name: "Thakhek",
      area_code: "THK",
      city_name: "Thakhek",
      country_code: "LA"
  },
  {
      id: "THL",
      name: "TACHILEK AIRPORT",
      area_code: "THL",
      city_name: "Tachilek",
      country_code: "MM"
  },
  {
      id: "THM",
      name: "Thompson Falls",
      area_code: "THM",
      city_name: "Thompson Falls",
      country_code: "US"
  },
  {
      id: "THO",
      name: "Thorshofn",
      area_code: "THO",
      city_name: "Thorshofn",
      country_code: "IS"
  },
  {
      id: "THT",
      name: "Tamchakett",
      area_code: "THT",
      city_name: "Tamchakett",
      country_code: "MR"
  },
  {
      id: "THU",
      name: "Thule Air Base",
      area_code: "THU",
      city_name: "Pituffik",
      country_code: "GL"
  },
  {
      id: "THW",
      name: "Harbour SPB",
      area_code: "TRR",
      city_name: "Trincomalee",
      country_code: "LK"
  },
  {
      id: "THZ",
      name: "Tahoua",
      area_code: "THZ",
      city_name: "Tahoua",
      country_code: "NE"
  },
  {
      id: "TIB",
      name: "Tibu",
      area_code: "TIB",
      city_name: "Tibu",
      country_code: "CO"
  },
  {
      id: "TIC",
      name: "Tinak Island",
      area_code: "TIC",
      city_name: "Tinak Island",
      country_code: "MH"
  },
  {
      id: "TID",
      name: "Bou Chekif Airport",
      area_code: "TID",
      city_name: "Tiaret",
      country_code: "DZ"
  },
  {
      id: "TIE",
      name: "Tippi",
      area_code: "TIE",
      city_name: "Tippi",
      country_code: "ET"
  },
  {
      id: "TII",
      name: "Tirinkot Airport",
      area_code: "TII",
      city_name: "Tarin Kot",
      country_code: "AF"
  },
  {
      id: "TIL",
      name: "Cheadle",
      area_code: "TIL",
      city_name: "Cheadle",
      country_code: "CA"
  },
  {
      id: "TIO",
      name: "Tilin",
      area_code: "TIO",
      city_name: "Tilin",
      country_code: "MM"
  },
  {
      id: "TJB",
      name: "Sei Bati",
      area_code: "TJB",
      city_name: "Tanjung Balai",
      country_code: "ID"
  },
  {
      id: "TJC",
      name: "Ticantiqui",
      area_code: "TJC",
      city_name: "Ticantiqui",
      country_code: "PA"
  },
  {
      id: "TJG",
      name: "Tanjung Warukin",
      area_code: "TJG",
      city_name: "Tanjung Warukin",
      country_code: "ID"
  },
  {
      id: "TJH",
      name: "Tajima",
      area_code: "TJH",
      city_name: "Toyooka",
      country_code: "JP"
  },
  {
      id: "TJI",
      name: "Capiro",
      area_code: "TJI",
      city_name: "Trujillo",
      country_code: "HN"
  },
  {
      id: "TJK",
      name: "Tokat Airport",
      area_code: "TJK",
      city_name: "Tokat",
      country_code: "TR"
  },
  {
      id: "TJN",
      name: "TAKUME AIRPORT",
      area_code: "TJN",
      city_name: "Takume",
      country_code: "PF"
  },
  {
      id: "TKB",
      name: "Tekadu",
      area_code: "TKB",
      city_name: "Tekadu",
      country_code: "PG"
  },
  {
      id: "TKC",
      name: "Tiko",
      area_code: "TKC",
      city_name: "Tiko",
      country_code: "CM"
  },
  {
      id: "TKE",
      name: "Tenakee Seaplane Base",
      area_code: "TKE",
      city_name: "Tenakee Springs",
      country_code: "US"
  },
  {
      id: "TKH",
      name: "Takhli",
      area_code: "TKH",
      city_name: "Takhli",
      country_code: "TH"
  },
  {
      id: "TKO",
      name: "Tlokoeng",
      area_code: "TKO",
      city_name: "Tlokoeng",
      country_code: "LS"
  },
  {
      id: "TKP",
      name: "TAKAPOTO AIRPORT",
      area_code: "TKP",
      city_name: "Takapoto",
      country_code: "NZ"
  },
  {
      id: "TKR",
      name: "Thakurgaon",
      area_code: "TKR",
      city_name: "Thakurgaon",
      country_code: "BD"
  },
  {
      id: "TKT",
      name: "Tak",
      area_code: "TKT",
      city_name: "Tak",
      country_code: "TH"
  },
  {
      id: "TKV",
      name: "Tatakoto Airport",
      area_code: "TKV",
      city_name: "Tatakoto",
      country_code: "PF"
  },
  {
      id: "TKW",
      name: "Tekin",
      area_code: "TKW",
      city_name: "Tekin",
      country_code: "PG"
  },
  {
      id: "TKX",
      name: "TAKAROA AIRPORT",
      area_code: "TKX",
      city_name: "Takaroa",
      country_code: "PF"
  },
  {
      id: "TKY",
      name: "Turkey Creek",
      area_code: "TKY",
      city_name: "Turkey Creek",
      country_code: "AU"
  },
  {
      id: "TKZ",
      name: "Tokoroa",
      area_code: "TKZ",
      city_name: "Tokoroa",
      country_code: "NZ"
  },
  {
      id: "TLA",
      name: "TELLER AIRPORT",
      area_code: "TLA",
      city_name: "Teller",
      country_code: "US"
  },
  {
      id: "TLB",
      name: "Tarbela Dam",
      area_code: "TLB",
      city_name: "Tarbela Dam",
      country_code: "PK"
  },
  {
      id: "TLJ",
      name: "Tatalina Airport",
      area_code: "TLJ",
      city_name: "Tatalina",
      country_code: "US"
  },
  {
      id: "TLK",
      name: "Talakan Airport",
      area_code: "TLK",
      city_name: "Talakan",
      country_code: "RU"
  },
  {
      id: "TLO",
      name: "Tol",
      area_code: "TLO",
      city_name: "Tol",
      country_code: "PG"
  },
  {
      id: "TLP",
      name: "Tumolbil",
      area_code: "TLP",
      city_name: "Tumolbil",
      country_code: "PG"
  },
  {
      id: "TLT",
      name: "TULUKSAK AIRPORT",
      area_code: "TLT",
      city_name: "Tuluksak",
      country_code: "US"
  },
  {
      id: "TLX",
      name: "Panguilemo",
      area_code: "TLX",
      city_name: "Talca",
      country_code: "CL"
  },
  {
      id: "TLY",
      name: "Plastun Airport",
      area_code: "TLY",
      city_name: "Plastun",
      country_code: "RU"
  },
  {
      id: "TLZ",
      name: "Catalao",
      area_code: "TLZ",
      city_name: "Catalao",
      country_code: "BR"
  },
  {
      id: "TMB",
      name: "Miami Executive",
      area_code: "MIA",
      city_name: "Miami",
      country_code: "US"
  },
  {
      id: "TMD",
      name: "Timbedra",
      area_code: "TMD",
      city_name: "Timbedra",
      country_code: "MR"
  },
  {
      id: "TMH",
      name: "Tanahmerah",
      area_code: "TMH",
      city_name: "Tanahmerah",
      country_code: "ID"
  },
  {
      id: "TMI",
      name: "Tumlingtar Airport",
      area_code: "TMI",
      city_name: "Tumlingtar",
      country_code: "NP"
  },
  {
      id: "TMK",
      name: "Tam Ky",
      area_code: "TMK",
      city_name: "Tam Ky",
      country_code: "VN"
  },
  {
      id: "TMN",
      name: "Tamana",
      area_code: "TMN",
      city_name: "Tamana",
      country_code: "KI"
  },
  {
      id: "TMO",
      name: "Tumeremo",
      area_code: "TMO",
      city_name: "Tumeremo",
      country_code: "VE"
  },
  {
      id: "TMQ",
      name: "Tambao",
      area_code: "TMQ",
      city_name: "Tambao",
      country_code: "BF"
  },
  {
      id: "TMR",
      name: "Aguemar Airport",
      area_code: "TMR",
      city_name: "Tamanrasset",
      country_code: "DZ"
  },
  {
      id: "TMX",
      name: "TIMIMOUN AIRPORT",
      area_code: "TMX",
      city_name: "Timimoun",
      country_code: "DZ"
  },
  {
      id: "TMY",
      name: "Tiom",
      area_code: "TMY",
      city_name: "Tiom",
      country_code: "ID"
  },
  {
      id: "TNB",
      name: "Tanah Grogot",
      area_code: "TNB",
      city_name: "Tanah Grogot",
      country_code: "ID"
  },
  {
      id: "TNC",
      name: "Tin City Airport",
      area_code: "TNC",
      city_name: "Tin City",
      country_code: "US"
  },
  {
      id: "TNK",
      name: "TUNUNAK AIRPORT",
      area_code: "TNK",
      city_name: "Tununak",
      country_code: "US"
  },
  {
      id: "TNL",
      name: "International",
      area_code: "TNL",
      city_name: "Ternopil",
      country_code: "UA"
  },
  {
      id: "TNP",
      name: "Twentynine Palms",
      area_code: "TNP",
      city_name: "Twentynine Palms",
      country_code: "US"
  },
  {
      id: "TNQ",
      name: "Teraina",
      area_code: "TNQ",
      city_name: "Teraina",
      country_code: "KI"
  },
  {
      id: "TNS",
      name: "Cantung",
      area_code: "TNS",
      city_name: "Tungsten",
      country_code: "CA"
  },
  {
      id: "TNV",
      name: "Tabuaeran",
      area_code: "TNV",
      city_name: "Tabuaeran",
      country_code: "KI"
  },
  {
      id: "TNZ",
      name: "Tosontsengel",
      area_code: "TNZ",
      city_name: "Tosontsengel",
      country_code: "MN"
  },
  {
      id: "TOA",
      name: "Zamperini Field",
      area_code: "TOA",
      city_name: "Torrance",
      country_code: "US"
  },
  {
      id: "TOC",
      name: "R.G. LeTourneau Field",
      area_code: "TOC",
      city_name: "Toccoa",
      country_code: "US"
  },
  {
      id: "TOK",
      name: "Torokina",
      area_code: "TOK",
      city_name: "Torokina",
      country_code: "PG"
  },
  {
      id: "TON",
      name: "Tonu",
      area_code: "TON",
      city_name: "Tonu",
      country_code: "PG"
  },
  {
      id: "TOO",
      name: "San Vito",
      area_code: "TOO",
      city_name: "San Vito",
      country_code: "CR"
  },
  {
      id: "TOQ",
      name: "Barriles",
      area_code: "TOQ",
      city_name: "Tocopilla",
      country_code: "CL"
  },
  {
      id: "TOT",
      name: "Totness",
      area_code: "TOT",
      city_name: "Totness",
      country_code: "SR"
  },
  {
      id: "TOU",
      name: "TOUHO AIRPORT",
      area_code: "TOU",
      city_name: "Touho",
      country_code: "NC"
  },
  {
      id: "TOZ",
      name: "Mahana",
      area_code: "TOZ",
      city_name: "Touba",
      country_code: "CI"
  },
  {
      id: "TPC",
      name: "Tarapoa",
      area_code: "TPC",
      city_name: "Tarapoa",
      country_code: "EC"
  },
  {
      id: "TPG",
      name: "Taiping",
      area_code: "TPG",
      city_name: "Taiping",
      country_code: "MY"
  },
  {
      id: "TPI",
      name: "Tapini",
      area_code: "TPI",
      city_name: "Tapini",
      country_code: "PG"
  },
  {
      id: "TPJ",
      name: "Taplejung",
      area_code: "TPJ",
      city_name: "Taplejung",
      country_code: "NP"
  },
  {
      id: "TPK",
      name: "Teuku Cut Ali",
      area_code: "TPK",
      city_name: "Tapaktuan",
      country_code: "ID"
  },
  {
      id: "TPN",
      name: "Tiputini",
      area_code: "TPN",
      city_name: "Tiputini",
      country_code: "EC"
  },
  {
      id: "TPT",
      name: "Tapeta",
      area_code: "TPT",
      city_name: "Tapeta",
      country_code: "LR"
  },
  {
      id: "TPU",
      name: "Tikapur",
      area_code: "TPU",
      city_name: "Tikapur",
      country_code: "NP"
  },
  {
      id: "TPX",
      name: "Tupai",
      area_code: "TPX",
      city_name: "Tupai",
      country_code: "PF"
  },
  {
      id: "TQD",
      name: "Al Taqaddum AB",
      area_code: "TQD",
      city_name: "Al Fallujah",
      country_code: "IQ"
  },
  {
      id: "TQN",
      name: "Taloqan",
      area_code: "TQN",
      city_name: "Taloqan",
      country_code: "AF"
  },
  {
      id: "TQP",
      name: "Trepell",
      area_code: "TQP",
      city_name: "Trepell",
      country_code: "AU"
  },
  {
      id: "TQQ",
      name: "Maranggo",
      area_code: "TQQ",
      city_name: "Tomia",
      country_code: "ID"
  },
  {
      id: "TQS",
      name: "E. Esquerra Cubides AB",
      area_code: "TQS",
      city_name: "Tres Esquinas",
      country_code: "CO"
  },
  {
      id: "TRA",
      name: "Tarama",
      area_code: "TRA",
      city_name: "Tarama",
      country_code: "JP"
  },
  {
      id: "TRJ",
      name: "Tarakbits",
      area_code: "TRJ",
      city_name: "Tarakbits",
      country_code: "PG"
  },
  {
      id: "TRQ",
      name: "Jose Galera dos Santos",
      area_code: "TRQ",
      city_name: "Tarauaca",
      country_code: "BR"
  },
  {
      id: "TRR",
      name: "China Bay Airport",
      area_code: "TRR",
      city_name: "Trincomalee",
      country_code: "LK"
  },
  {
      id: "TRX",
      name: "Municipal",
      area_code: "TRX",
      city_name: "Trenton",
      country_code: "US"
  },
  {
      id: "TRY",
      name: "Tororo",
      area_code: "TRY",
      city_name: "Tororo",
      country_code: "UG"
  },
  {
      id: "TSB",
      name: "Tsumeb",
      area_code: "TSB",
      city_name: "Tsumeb",
      country_code: "NA"
  },
  {
      id: "TSC",
      name: "Taisha",
      area_code: "TSC",
      city_name: "Taisha",
      country_code: "EC"
  },
  {
      id: "TSD",
      name: "Tshipise",
      area_code: "TSD",
      city_name: "Tshipise",
      country_code: "ZA"
  },
  {
      id: "TSG",
      name: "Tanacross",
      area_code: "TSG",
      city_name: "Tanacross",
      country_code: "US"
  },
  {
      id: "TSH",
      name: "Tshikapa",
      area_code: "TSH",
      city_name: "Tshikapa",
      country_code: "CD"
  },
  {
      id: "TSI",
      name: "Tsile Tsile",
      area_code: "TSI",
      city_name: "Tsile Tsile",
      country_code: "PG"
  },
  {
      id: "TSK",
      name: "Taskul",
      area_code: "TSK",
      city_name: "Taskul",
      country_code: "PG"
  },
  {
      id: "TSL",
      name: "Tamuin",
      area_code: "TSL",
      city_name: "Tamuin",
      country_code: "MX"
  },
  {
      id: "TSP",
      name: "Municipal",
      area_code: "TSP",
      city_name: "Tehachapi",
      country_code: "US"
  },
  {
      id: "TSQ",
      name: "Torres",
      area_code: "TSQ",
      city_name: "Torres",
      country_code: "BR"
  },
  {
      id: "TSU",
      name: "Tabiteuea South",
      area_code: "TSU",
      city_name: "Tabiteuea South",
      country_code: "KI"
  },
  {
      id: "TSW",
      name: "Tsewi",
      area_code: "TSW",
      city_name: "Tsewi",
      country_code: "PG"
  },
  {
      id: "TSX",
      name: "Tanjung Santan",
      area_code: "TSX",
      city_name: "Tanjung Santan",
      country_code: "ID"
  },
  {
      id: "TSY",
      name: "Tasikmalaya",
      area_code: "TSY",
      city_name: "Tasikmalaya",
      country_code: "ID"
  },
  {
      id: "TSZ",
      name: "Tsetserleg",
      area_code: "TSZ",
      city_name: "Tsetserleg",
      country_code: "MN"
  },
  {
      id: "TTC",
      name: "Las Breas",
      area_code: "TTC",
      city_name: "Taltal",
      country_code: "CL"
  },
  {
      id: "TTG",
      name: "Gral. Enrique Mosconi",
      area_code: "TTG",
      city_name: "Tartagal",
      country_code: "AR"
  },
  {
      id: "TTH",
      name: "Air Base",
      area_code: "TTH",
      city_name: "Thumrait",
      country_code: "OM"
  },
  {
      id: "TTI",
      name: "Tetiaroa",
      area_code: "TTI",
      city_name: "Tetiaroa",
      country_code: "PF"
  },
  {
      id: "TTL",
      name: "Turtle Island SPB",
      area_code: "TTL",
      city_name: "Nanuya Levu",
      country_code: "FJ"
  },
  {
      id: "TTM",
      name: "Tablon de Tamara",
      area_code: "TTM",
      city_name: "Tablon de Tamara",
      country_code: "CO"
  },
  {
      id: "TTO",
      name: "Municipal",
      area_code: "TTO",
      city_name: "Britton",
      country_code: "US"
  },
  {
      id: "TTR",
      name: "Pongtiku",
      area_code: "TTR",
      city_name: "Tana Toraja",
      country_code: "ID"
  },
  {
      id: "TTS",
      name: "Tsaratanana",
      area_code: "TTS",
      city_name: "Tsaratanana",
      country_code: "MG"
  },
  {
      id: "TTW",
      name: "Tissa Tank SPB",
      area_code: "TTW",
      city_name: "Tissamaharama",
      country_code: "LK"
  },
  {
      id: "TUD",
      name: "Tambacounda",
      area_code: "TUD",
      city_name: "Tambacounda",
      country_code: "SN"
  },
  {
      id: "TUJ",
      name: "Tum",
      area_code: "TUJ",
      city_name: "Tum",
      country_code: "ET"
  },
  {
      id: "TUM",
      name: "Tumut",
      area_code: "TUM",
      city_name: "Tumut",
      country_code: "AU"
  },
  {
      id: "TUQ",
      name: "Tougan",
      area_code: "TUQ",
      city_name: "Tougan",
      country_code: "BF"
  },
  {
      id: "TUT",
      name: "Tauta",
      area_code: "TUT",
      city_name: "Tauta",
      country_code: "PG"
  },
  {
      id: "TUV",
      name: "San Rafael",
      area_code: "TUV",
      city_name: "Tucupita",
      country_code: "VE"
  },
  {
      id: "TUW",
      name: "Tubuala",
      area_code: "TUW",
      city_name: "Tubuala",
      country_code: "PA"
  },
  {
      id: "TUX",
      name: "Tumbler Ridge",
      area_code: "TUX",
      city_name: "Tumbler Ridge",
      country_code: "CA"
  },
  {
      id: "TUZ",
      name: "Tucuma",
      area_code: "TUZ",
      city_name: "Tucuma",
      country_code: "BR"
  },
  {
      id: "TVA",
      name: "Morafenobe",
      area_code: "TVA",
      city_name: "Morafenobe",
      country_code: "MG"
  },
  {
      id: "TVI",
      name: "Regional",
      area_code: "TVI",
      city_name: "Thomasville",
      country_code: "US"
  },
  {
      id: "TWC",
      name: "Tang Wangcheng Airport",
      area_code: "TWC",
      city_name: "Tumushuke",
      country_code: "CN"
  },
  {
      id: "TWD",
      name: "Jefferson County Intl",
      area_code: "TWD",
      city_name: "Port Townsend",
      country_code: "US"
  },
  {
      id: "TWN",
      name: "Tewantin",
      area_code: "TWN",
      city_name: "Tewantin",
      country_code: "AU"
  },
  {
      id: "TWP",
      name: "Torwood",
      area_code: "TWP",
      city_name: "Torwood",
      country_code: "AU"
  },
  {
      id: "TWT",
      name: "Tawitawi Airport",
      area_code: "TWT",
      city_name: "Tawi Tawi Island",
      country_code: "PH"
  },
  {
      id: "TWY",
      name: "Tawa",
      area_code: "TWY",
      city_name: "Tawa",
      country_code: "PG"
  },
  {
      id: "TXE",
      name: "Rembele Airport",
      area_code: "TXE",
      city_name: "Takengon",
      country_code: "ID"
  },
  {
      id: "TXM",
      name: "Teminabuan",
      area_code: "TXM",
      city_name: "Teminabuan",
      country_code: "ID"
  },
  {
      id: "TXR",
      name: "Tanbar",
      area_code: "TXR",
      city_name: "Tanbar",
      country_code: "AU"
  },
  {
      id: "TXU",
      name: "Tabou",
      area_code: "TXU",
      city_name: "Tabou",
      country_code: "CI"
  },
  {
      id: "TYA",
      name: "Klokovo",
      area_code: "TYA",
      city_name: "Tula",
      country_code: "RU"
  },
  {
      id: "TYB",
      name: "Tibooburra",
      area_code: "TYB",
      city_name: "Tibooburra",
      country_code: "AU"
  },
  {
      id: "TYD",
      name: "Tynda",
      area_code: "TYD",
      city_name: "Tynda",
      country_code: "RU"
  },
  {
      id: "TYG",
      name: "Thylungra",
      area_code: "TYG",
      city_name: "Thylungra",
      country_code: "AU"
  },
  {
      id: "TYM",
      name: "Staniel Cay",
      area_code: "TYM",
      city_name: "Staniel Cay",
      country_code: "BS"
  },
  {
      id: "TYP",
      name: "Tobermorey",
      area_code: "TYP",
      city_name: "Tobermorey",
      country_code: "AU"
  },
  {
      id: "TYT",
      name: "Treinta y Tres",
      area_code: "TYT",
      city_name: "Treinta y Tres",
      country_code: "UY"
  },
  {
      id: "TZC",
      name: "Tuscola Area",
      area_code: "TZC",
      city_name: "Caro",
      country_code: "US"
  },
  {
      id: "TZO",
      name: "Tsimiroro",
      area_code: "TZO",
      city_name: "Ankisatra",
      country_code: "MG"
  },
  {
      id: "UAB",
      name: "Incirlik AB",
      area_code: "ADA",
      city_name: "Adana",
      country_code: "TR"
  },
  {
      id: "UAE",
      name: "Mount Aue",
      area_code: "UAE",
      city_name: "Mount Aue",
      country_code: "PG"
  },
  {
      id: "UAL",
      name: "Luau",
      area_code: "UAL",
      city_name: "Luau",
      country_code: "AO"
  },
  {
      id: "UAR",
      name: "Bouarfa",
      area_code: "UAR",
      city_name: "Bouarfa",
      country_code: "MA"
  },
  {
      id: "UAX",
      name: "Uaxactun",
      area_code: "UAX",
      city_name: "Uaxactun",
      country_code: "GT"
  },
  {
      id: "UBB",
      name: "Mabuiag Island",
      area_code: "UBB",
      city_name: "Mabuiag Island",
      country_code: "AU"
  },
  {
      id: "UBI",
      name: "Buin",
      area_code: "UBI",
      city_name: "Buin",
      country_code: "PG"
  },
  {
      id: "UBN",
      name: "International",
      area_code: "ULN",
      city_name: "Ulaanbaatar",
      country_code: "MN"
  },
  {
      id: "UBR",
      name: "Ubrub",
      area_code: "UBR",
      city_name: "Ubrub",
      country_code: "ID"
  },
  {
      id: "UBT",
      name: "Gastao Madeira",
      area_code: "UBT",
      city_name: "Ubatuba",
      country_code: "BR"
  },
  {
      id: "UBU",
      name: "Kalumburu",
      area_code: "UBU",
      city_name: "Kalumburu",
      country_code: "AU"
  },
  {
      id: "UCB",
      name: "ULANQAB AIRPORT",
      area_code: "UCB",
      city_name: "Ulanqab",
      country_code: "CN"
  },
  {
      id: "UCC",
      name: "Yucca Airstrip",
      area_code: "DRA",
      city_name: "Mercury",
      country_code: "US"
  },
  {
      id: "UCE",
      name: "Eunice",
      area_code: "UCE",
      city_name: "Eunice",
      country_code: "US"
  },
  {
      id: "UCN",
      name: "Buchanan",
      area_code: "UCN",
      city_name: "Buchanan",
      country_code: "LR"
  },
  {
      id: "UCY",
      name: "Everett-Stewart Rgnl",
      area_code: "UCY",
      city_name: "Union City",
      country_code: "US"
  },
  {
      id: "UDA",
      name: "Undara",
      area_code: "UDA",
      city_name: "Undara",
      country_code: "AU"
  },
  {
      id: "UEN",
      name: "Urengoy",
      area_code: "UEN",
      city_name: "Urengoy",
      country_code: "RU"
  },
  {
      id: "UES",
      name: "Waukesha County",
      area_code: "UES",
      city_name: "Waukesha",
      country_code: "US"
  },
  {
      id: "UGA",
      name: "Bulgan",
      area_code: "UGA",
      city_name: "Bulgan",
      country_code: "MN"
  },
  {
      id: "UGI",
      name: "San Juan (Uganik) SPB",
      area_code: "UGI",
      city_name: "Uganik",
      country_code: "US"
  },
  {
      id: "UGS",
      name: "Ugashik",
      area_code: "UGS",
      city_name: "Ugashik",
      country_code: "US"
  },
  {
      id: "UGT",
      name: "Bulagtai",
      area_code: "UGT",
      city_name: "Bulagtai",
      country_code: "MN"
  },
  {
      id: "UGU",
      name: "Bilogai",
      area_code: "UGU",
      city_name: "Bilogai",
      country_code: "ID"
  },
  {
      id: "UII",
      name: "Utila Airport",
      area_code: "UII",
      city_name: "Utila",
      country_code: "HN"
  },
  {
      id: "UIK",
      name: "Ust-Ilimsk",
      area_code: "UIK",
      city_name: "Ust-Ilimsk",
      country_code: "RU"
  },
  {
      id: "UIL",
      name: "Quillayute",
      area_code: "UIL",
      city_name: "Forks",
      country_code: "US"
  },
  {
      id: "UIQ",
      name: "Quoin Hill",
      area_code: "UIQ",
      city_name: "Quoin Hill",
      country_code: "VU"
  },
  {
      id: "UIT",
      name: "Jaluit Island",
      area_code: "UIT",
      city_name: "Jaluit Island",
      country_code: "MH"
  },
  {
      id: "UJE",
      name: "Ujae Island",
      area_code: "UJE",
      city_name: "Ujae Island",
      country_code: "MH"
  },
  {
      id: "UJN",
      name: "Uljin",
      area_code: "UJN",
      city_name: "Uljin",
      country_code: "KR"
  },
  {
      id: "UJU",
      name: "Uiju",
      area_code: "UJU",
      city_name: "Uiju",
      country_code: "KP"
  },
  {
      id: "UKG",
      name: "Ust-Kuyga Airport",
      area_code: "UKG",
      city_name: "Ust-Kuyga",
      country_code: "RU"
  },
  {
      id: "UKH",
      name: "Mukhaizna",
      area_code: "UKH",
      city_name: "Mukhaizna",
      country_code: "OM"
  },
  {
      id: "UKN",
      name: "Municipal",
      area_code: "UKN",
      city_name: "Waukon",
      country_code: "US"
  },
  {
      id: "UKR",
      name: "Mukeiras",
      area_code: "UKR",
      city_name: "Mukeiras",
      country_code: "YE"
  },
  {
      id: "UKU",
      name: "Nuku",
      area_code: "UKU",
      city_name: "Nuku",
      country_code: "PG"
  },
  {
      id: "ULA",
      name: "Capitan J.D. Vazquez",
      area_code: "ULA",
      city_name: "San Julian",
      country_code: "AR"
  },
  {
      id: "ULB",
      name: "ULEI AIRPORT",
      area_code: "ULB",
      city_name: "Ulei",
      country_code: "VU"
  },
  {
      id: "ULD",
      name: "Ulundi Airport",
      area_code: "ULD",
      city_name: "Ulundi",
      country_code: "ZA"
  },
  {
      id: "ULE",
      name: "Sule",
      area_code: "ULE",
      city_name: "Sule",
      country_code: "PG"
  },
  {
      id: "ULI",
      name: "Ulithi",
      area_code: "ULI",
      city_name: "Ulithi",
      country_code: "FM"
  },
  {
      id: "ULL",
      name: "Mull",
      area_code: "ULL",
      city_name: "Mull",
      country_code: "GB"
  },
  {
      id: "ULQ",
      name: "Heriberto Gil Martinez",
      area_code: "ULQ",
      city_name: "Tulua",
      country_code: "CO"
  },
  {
      id: "ULS",
      name: "Mulatos",
      area_code: "ULS",
      city_name: "Mulatos",
      country_code: "CO"
  },
  {
      id: "ULX",
      name: "Ulusaba Airport",
      area_code: "ULX",
      city_name: "Ulusaba",
      country_code: "ZA"
  },
  {
      id: "UMA",
      name: "Punta de Maisi",
      area_code: "UMA",
      city_name: "Punta de Maisi",
      country_code: "CU"
  },
  {
      id: "UMC",
      name: "Umba",
      area_code: "UMC",
      city_name: "Umba",
      country_code: "PG"
  },
  {
      id: "UMI",
      name: "Quince Mil",
      area_code: "UMI",
      city_name: "Quince Mil",
      country_code: "PE"
  },
  {
      id: "UMR",
      name: "RAAF Base",
      area_code: "UMR",
      city_name: "Woomera",
      country_code: "AU"
  },
  {
      id: "UMS",
      name: "Ust-Maya Airport",
      area_code: "UMS",
      city_name: "Ust-Maya",
      country_code: "RU"
  },
  {
      id: "UMZ",
      name: "Intermountain Muni",
      area_code: "UMZ",
      city_name: "Mena",
      country_code: "US"
  },
  {
      id: "UNC",
      name: "Unguia",
      area_code: "UNC",
      city_name: "Unguia",
      country_code: "CO"
  },
  {
      id: "UND",
      name: "KUNDUZ AIRPORT",
      area_code: "UND",
      city_name: "Kunduz",
      country_code: "AF"
  },
  {
      id: "UNE",
      name: "Qacha's Nek",
      area_code: "UNE",
      city_name: "Qacha's Nek",
      country_code: "LS"
  },
  {
      id: "UNR",
      name: "Ondorkhaan",
      area_code: "UNR",
      city_name: "Ondorkhaan",
      country_code: "MN"
  },
  {
      id: "UNU",
      name: "Dodge County",
      area_code: "UNU",
      city_name: "Juneau",
      country_code: "US"
  },
  {
      id: "UOA",
      name: "Moruroa Atoll Airport",
      area_code: "UOA",
      city_name: "Moruroa Atoll",
      country_code: "PF"
  },
  {
      id: "UOL",
      name: "Buol Airport",
      area_code: "UOL",
      city_name: "Buol",
      country_code: "ID"
  },
  {
      id: "UOS",
      name: "Franklin County",
      area_code: "UOS",
      city_name: "Sewanee",
      country_code: "US"
  },
  {
      id: "UPB",
      name: "Playa Baracoa",
      area_code: "HAV",
      city_name: "Havana",
      country_code: "CU"
  },
  {
      id: "UPL",
      name: "Upala",
      area_code: "UPL",
      city_name: "Upala",
      country_code: "CR"
  },
  {
      id: "UPP",
      name: "Upolu",
      area_code: "UPP",
      city_name: "Hawi",
      country_code: "US"
  },
  {
      id: "UPR",
      name: "Upiara",
      area_code: "UPR",
      city_name: "Upiara",
      country_code: "PG"
  },
  {
      id: "UPV",
      name: "Upavon",
      area_code: "UPV",
      city_name: "Upavon",
      country_code: "GB"
  },
  {
      id: "URB",
      name: "Urubupunga",
      area_code: "URB",
      city_name: "Castilho",
      country_code: "BR"
  },
  {
      id: "URD",
      name: "Burg Feuerstein",
      area_code: "URD",
      city_name: "Burg Feuerstein",
      country_code: "DE"
  },
  {
      id: "URI",
      name: "Uribe",
      area_code: "URI",
      city_name: "Uribe",
      country_code: "CO"
  },
  {
      id: "URM",
      name: "Uriman",
      area_code: "URM",
      city_name: "Uriman",
      country_code: "VE"
  },
  {
      id: "URN",
      name: "Urgun",
      area_code: "URN",
      city_name: "Urgun",
      country_code: "AF"
  },
  {
      id: "URU",
      name: "Uroubi",
      area_code: "URU",
      city_name: "Uroubi",
      country_code: "PG"
  },
  {
      id: "URZ",
      name: "Uruzgan",
      area_code: "URZ",
      city_name: "Uruzgan",
      country_code: "AF"
  },
  {
      id: "USC",
      name: "Union County",
      area_code: "USC",
      city_name: "Union",
      country_code: "US"
  },
  {
      id: "USI",
      name: "Mabaruma",
      area_code: "USI",
      city_name: "Mabaruma",
      country_code: "GY"
  },
  {
      id: "USJ",
      name: "Usharal",
      area_code: "USJ",
      city_name: "Usharal",
      country_code: "KZ"
  },
  {
      id: "USO",
      name: "Usino",
      area_code: "USO",
      city_name: "Usino",
      country_code: "PG"
  },
  {
      id: "USQ",
      name: "Usak Airport",
      area_code: "USQ",
      city_name: "Usak",
      country_code: "TR"
  },
  {
      id: "UTB",
      name: "Muttaburra",
      area_code: "UTB",
      city_name: "Muttaburra",
      country_code: "AU"
  },
  {
      id: "UTD",
      name: "Nutwood Downs",
      area_code: "UTD",
      city_name: "Nutwood Downs",
      country_code: "AU"
  },
  {
      id: "UTG",
      name: "Quthing",
      area_code: "UTG",
      city_name: "Quthing",
      country_code: "LS"
  },
  {
      id: "UTK",
      name: "Utirik Island",
      area_code: "UTK",
      city_name: "Utirik Island",
      country_code: "MH"
  },
  {
      id: "UTO",
      name: "Indian Mountain",
      area_code: "UTO",
      city_name: "Utopia Creek",
      country_code: "US"
  },
  {
      id: "UTS",
      name: "Ust-Tsylma Airport",
      area_code: "UTS",
      city_name: "Ust-Tsilma",
      country_code: "RU"
  },
  {
      id: "UTU",
      name: "Ustupo",
      area_code: "UTU",
      city_name: "Ustupo",
      country_code: "PA"
  },
  {
      id: "UUA",
      name: "Bugulma Airport",
      area_code: "UUA",
      city_name: "Bugulma",
      country_code: "RU"
  },
  {
      id: "UUN",
      name: "Baruun-Urt",
      area_code: "UUN",
      city_name: "Baruun-Urt",
      country_code: "MN"
  },
  {
      id: "UUU",
      name: "Manumu",
      area_code: "UUU",
      city_name: "Manumu",
      country_code: "PG"
  },
  {
      id: "UVA",
      name: "Garner Field",
      area_code: "UVA",
      city_name: "Uvalde",
      country_code: "US"
  },
  {
      id: "UVE",
      name: "OUVEA AIRPORT",
      area_code: "UVE",
      city_name: "Ouvea",
      country_code: "NC"
  },
  {
      id: "UVO",
      name: "Uvol",
      area_code: "UVO",
      city_name: "Uvol",
      country_code: "PG"
  },
  {
      id: "UWA",
      name: "Ware",
      area_code: "UWA",
      city_name: "Ware",
      country_code: "US"
  },
  {
      id: "UYL",
      name: "NYALA AIRPORT",
      area_code: "UYL",
      city_name: "Nyala",
      country_code: "SD"
  },
  {
      id: "UZM",
      name: "Hope Bay Airport",
      area_code: "UZM",
      city_name: "Hope Bay",
      country_code: "CA"
  },
  {
      id: "UZU",
      name: "Curuzu Cuatia",
      area_code: "UZU",
      city_name: "Curuzu Cuatia",
      country_code: "AR"
  },
  {
      id: "VAB",
      name: "Yavarate",
      area_code: "VAB",
      city_name: "Yavarate",
      country_code: "CO"
  },
  {
      id: "VAC",
      name: "Varrelbusch",
      area_code: "VAC",
      city_name: "Varrelbusch",
      country_code: "DE"
  },
  {
      id: "VAH",
      name: "V.Villagomez Toledo",
      area_code: "VAH",
      city_name: "Vallegrande",
      country_code: "BO"
  },
  {
      id: "VAK",
      name: "Chevak Airport",
      area_code: "VAK",
      city_name: "Chevak",
      country_code: "US"
  },
  {
      id: "VAL",
      name: "VALENCA AIRPORT",
      area_code: "VAL",
      city_name: "Valenca",
      country_code: "BR"
  },
  {
      id: "VAO",
      name: "Suavanao Airport",
      area_code: "VAO",
      city_name: "Suavanao",
      country_code: "SB"
  },
  {
      id: "VAP",
      name: "Rodelillo",
      area_code: "VAP",
      city_name: "Valparaiso",
      country_code: "CL"
  },
  {
      id: "VAT",
      name: "Vatomandry",
      area_code: "VAT",
      city_name: "Vatomandry",
      country_code: "MG"
  },
  {
      id: "VAU",
      name: "Vatukoula",
      area_code: "VAU",
      city_name: "Vatukoula",
      country_code: "FJ"
  },
  {
      id: "VBA",
      name: "Ann Airport",
      area_code: "VBA",
      city_name: "Ann",
      country_code: "MM"
  },
  {
      id: "VBC",
      name: "Chan Mya Thazi",
      area_code: "MDL",
      city_name: "Mandalay",
      country_code: "MM"
  },
  {
      id: "VBP",
      name: "Bokpyin Airport",
      area_code: "VBP",
      city_name: "Bokpyin",
      country_code: "MM"
  },
  {
      id: "VBV",
      name: "Vanuabalavu Airport",
      area_code: "VBV",
      city_name: "Vanuabalavu Island",
      country_code: "FJ"
  },
  {
      id: "VCD",
      name: "Victoria River Downs",
      area_code: "VCD",
      city_name: "Victoria River Downs",
      country_code: "AU"
  },
  {
      id: "VCF",
      name: "Valcheta",
      area_code: "VCF",
      city_name: "Valcheta",
      country_code: "AR"
  },
  {
      id: "VCH",
      name: "Vichadero",
      area_code: "VCH",
      city_name: "Vichadero",
      country_code: "UY"
  },
  {
      id: "VCR",
      name: "Carora",
      area_code: "VCR",
      city_name: "Carora",
      country_code: "VE"
  },
  {
      id: "VDO",
      name: "Van Don International Airport",
      area_code: "VDO",
      city_name: "Quang Ninh",
      country_code: "VN"
  },
  {
      id: "VDP",
      name: "Valle de La Pascua",
      area_code: "VDP",
      city_name: "Valle de La Pascua",
      country_code: "VE"
  },
  {
      id: "VDR",
      name: "Villa Dolores",
      area_code: "VDR",
      city_name: "Villa Dolores",
      country_code: "AR"
  },
  {
      id: "VDY",
      name: "Jindal",
      area_code: "VDY",
      city_name: "Vidyanagar",
      country_code: "IN"
  },
  {
      id: "VEE",
      name: "VENETIE AIRPORT",
      area_code: "VEE",
      city_name: "Venetie",
      country_code: "US"
  },
  {
      id: "VEG",
      name: "Maikwak",
      area_code: "VEG",
      city_name: "Maikwak",
      country_code: "GY"
  },
  {
      id: "VEX",
      name: "Municipal",
      area_code: "VEX",
      city_name: "Tioga",
      country_code: "US"
  },
  {
      id: "VGS",
      name: "General Villegas",
      area_code: "VGS",
      city_name: "General Villegas",
      country_code: "AR"
  },
  {
      id: "VHC",
      name: "SAURIMO AIRPORT",
      area_code: "VHC",
      city_name: "Saurimo",
      country_code: "AO"
  },
  {
      id: "VHV",
      name: "Verkhnevilyuysk Airport",
      area_code: "VHV",
      city_name: "Verkhnevilyuysk",
      country_code: "RU"
  },
  {
      id: "VHZ",
      name: "VAHITAHI AIRPORT",
      area_code: "VHZ",
      city_name: "Vahitahi",
      country_code: "PF"
  },
  {
      id: "VIH",
      name: "Rolla National",
      area_code: "VIH",
      city_name: "Rolla/Vichy",
      country_code: "US"
  },
  {
      id: "VIQ",
      name: "Viqueque",
      area_code: "VIQ",
      city_name: "Viqueque",
      country_code: "TL"
  },
  {
      id: "VIU",
      name: "Viru Harbour",
      area_code: "VIU",
      city_name: "Viru Harbour",
      country_code: "SB"
  },
  {
      id: "VIV",
      name: "Vivigani",
      area_code: "VIV",
      city_name: "Vivigani",
      country_code: "PG"
  },
  {
      id: "VJB",
      name: "Xai-Xai",
      area_code: "VJB",
      city_name: "Xai-Xai",
      country_code: "MZ"
  },
  {
      id: "VJQ",
      name: "Gurue",
      area_code: "VJQ",
      city_name: "Gurue",
      country_code: "MZ"
  },
  {
      id: "VLA",
      name: "Municipal",
      area_code: "VLA",
      city_name: "Vandalia",
      country_code: "US"
  },
  {
      id: "VLK",
      name: "Volgodonsk",
      area_code: "VLK",
      city_name: "Volgodonsk",
      country_code: "RU"
  },
  {
      id: "VLM",
      name: "Rafael Pabon",
      area_code: "VLM",
      city_name: "Villamontes",
      country_code: "BO"
  },
  {
      id: "VLP",
      name: "Vila Rica",
      area_code: "VLP",
      city_name: "Vila Rica",
      country_code: "BR"
  },
  {
      id: "VLS",
      name: "VALESDIR AIRPORT",
      area_code: "VLS",
      city_name: "Valesdir",
      country_code: "VU"
  },
  {
      id: "VLU",
      name: "Velikiye Luki",
      area_code: "VLU",
      city_name: "Velikiye Luki",
      country_code: "RU"
  },
  {
      id: "VLY",
      name: "Anglesey",
      area_code: "VLY",
      city_name: "Anglesey",
      country_code: "GB"
  },
  {
      id: "VNA",
      name: "Salavan",
      area_code: "VNA",
      city_name: "Salavan",
      country_code: "LA"
  },
  {
      id: "VND",
      name: "Vangaindrano",
      area_code: "VND",
      city_name: "Vangaindrano",
      country_code: "MG"
  },
  {
      id: "VNR",
      name: "Vanrook Station",
      area_code: "VNR",
      city_name: "Vanrook Station",
      country_code: "AU"
  },
  {
      id: "VOD",
      name: "Vodochody",
      area_code: "PRG",
      city_name: "Prague",
      country_code: "CZ"
  },
  {
      id: "VOH",
      name: "Vohimarina",
      area_code: "VOH",
      city_name: "Vohimarina",
      country_code: "MG"
  },
  {
      id: "VOI",
      name: "Tenebu",
      area_code: "VOI",
      city_name: "Voinjama",
      country_code: "LR"
  },
  {
      id: "VOK",
      name: "Volk Field",
      area_code: "VOK",
      city_name: "Camp Douglas",
      country_code: "US"
  },
  {
      id: "VOT",
      name: "Domingos Pignatari",
      area_code: "VOT",
      city_name: "Votuporanga",
      country_code: "BR"
  },
  {
      id: "VPE",
      name: "Ongiva Airport",
      area_code: "VPE",
      city_name: "Ondjiva",
      country_code: "AO"
  },
  {
      id: "VPG",
      name: "Vipingo",
      area_code: "VPG",
      city_name: "Vipingo",
      country_code: "KE"
  },
  {
      id: "VPN",
      name: "Vopnafjordur",
      area_code: "VPN",
      city_name: "Vopnafjordur",
      country_code: "IS"
  },
  {
      id: "VQQ",
      name: "Cecil",
      area_code: "JAX",
      city_name: "Jacksonville",
      country_code: "US"
  },
  {
      id: "VRC",
      name: "VIRAC AIRPORT",
      area_code: "VRC",
      city_name: "Virac",
      country_code: "PH"
  },
  {
      id: "VRE",
      name: "Vredendal",
      area_code: "VRE",
      city_name: "Vredendal",
      country_code: "ZA"
  },
  {
      id: "VRI",
      name: "Varandey",
      area_code: "VRI",
      city_name: "Varandey",
      country_code: "RU"
  },
  {
      id: "VRS",
      name: "Roy Otten Memorial",
      area_code: "VRS",
      city_name: "Versailles",
      country_code: "US"
  },
  {
      id: "VRU",
      name: "Vryburg",
      area_code: "VRU",
      city_name: "Vryburg",
      country_code: "ZA"
  },
  {
      id: "VSE",
      name: "Viseu Airport",
      area_code: "VSE",
      city_name: "Viseu",
      country_code: "PT"
  },
  {
      id: "VSF",
      name: "Hartness State",
      area_code: "VSF",
      city_name: "Springfield",
      country_code: "US"
  },
  {
      id: "VSO",
      name: "Phuoc Long",
      area_code: "VSO",
      city_name: "Phuoc Long",
      country_code: "VN"
  },
  {
      id: "VTF",
      name: "Vatulele Island",
      area_code: "VTF",
      city_name: "Vatulele Island",
      country_code: "FJ"
  },
  {
      id: "VTG",
      name: "Vung Tau",
      area_code: "VTG",
      city_name: "Vung Tau",
      country_code: "VN"
  },
  {
      id: "VTL",
      name: "Champ de Courses",
      area_code: "VTL",
      city_name: "Vittel",
      country_code: "FR"
  },
  {
      id: "VTM",
      name: "AFB",
      area_code: "VTM",
      city_name: "Nevatim",
      country_code: "IL"
  },
  {
      id: "VTN",
      name: "Miller Field",
      area_code: "VTN",
      city_name: "Valentine",
      country_code: "US"
  },
  {
      id: "VTU",
      name: "Hermanos Ameijeiras",
      area_code: "VTU",
      city_name: "Las Tunas",
      country_code: "CU"
  },
  {
      id: "VUS",
      name: "Velikij Ustyug Airport",
      area_code: "VUS",
      city_name: "Veliky Ustyug",
      country_code: "RU"
  },
  {
      id: "VUU",
      name: "Mvuu Camp",
      area_code: "VUU",
      city_name: "Liwonde",
      country_code: "MW"
  },
  {
      id: "VVB",
      name: "Mahanoro",
      area_code: "VVB",
      city_name: "Mahanoro",
      country_code: "MG"
  },
  {
      id: "VVK",
      name: "Vastervik",
      area_code: "VVK",
      city_name: "Vastervik",
      country_code: "SE"
  },
  {
      id: "VVN",
      name: "Malvinas",
      area_code: "VVN",
      city_name: "Malvinas",
      country_code: "PE"
  },
  {
      id: "VYI",
      name: "Vilyuysk Airport",
      area_code: "VYI",
      city_name: "Vilyuysk",
      country_code: "RU"
  },
  {
      id: "WAB",
      name: "Wabag",
      area_code: "WAB",
      city_name: "Wabag",
      country_code: "PG"
  },
  {
      id: "WAC",
      name: "Waca",
      area_code: "WAC",
      city_name: "Waca",
      country_code: "ET"
  },
  {
      id: "WAD",
      name: "Andriamena",
      area_code: "WAD",
      city_name: "Andriamena",
      country_code: "MG"
  },
  {
      id: "WAF",
      name: "Wana",
      area_code: "WAF",
      city_name: "Wana",
      country_code: "PK"
  },
  {
      id: "WAH",
      name: "Harry Stern",
      area_code: "WAH",
      city_name: "Wahpeton",
      country_code: "US"
  },
  {
      id: "WAI",
      name: "Ambalabe",
      area_code: "WAI",
      city_name: "Antsohihy",
      country_code: "MG"
  },
  {
      id: "WAJ",
      name: "Wawoi Falls",
      area_code: "WAJ",
      city_name: "Wawoi Falls",
      country_code: "PG"
  },
  {
      id: "WAK",
      name: "Ankazoabo",
      area_code: "WAK",
      city_name: "Ankazoabo",
      country_code: "MG"
  },
  {
      id: "WAM",
      name: "Ambatondrazaka",
      area_code: "WAM",
      city_name: "Ambatondrazaka",
      country_code: "MG"
  },
  {
      id: "WAO",
      name: "Wabo",
      area_code: "WAO",
      city_name: "Wabo",
      country_code: "PG"
  },
  {
      id: "WAP",
      name: "Alto Palena",
      area_code: "WAP",
      city_name: "Alto Palena",
      country_code: "CL"
  },
  {
      id: "WAQ",
      name: "Antsalova",
      area_code: "WAQ",
      city_name: "Antsalova",
      country_code: "MG"
  },
  {
      id: "WAR",
      name: "Waris",
      area_code: "WAR",
      city_name: "Waris",
      country_code: "ID"
  },
  {
      id: "WAU",
      name: "Wauchope",
      area_code: "WAU",
      city_name: "Wauchope",
      country_code: "AU"
  },
  {
      id: "WAV",
      name: "Wave Hill",
      area_code: "WAV",
      city_name: "Wave Hill",
      country_code: "AU"
  },
  {
      id: "WAX",
      name: "Zuara",
      area_code: "WAX",
      city_name: "Zuara",
      country_code: "LY"
  },
  {
      id: "WBA",
      name: "Wahai",
      area_code: "WBA",
      city_name: "Seram Island",
      country_code: "ID"
  },
  {
      id: "WBB",
      name: "STEBBINS AIRPORT",
      area_code: "WBB",
      city_name: "Stebbins",
      country_code: "US"
  },
  {
      id: "WBC",
      name: "Wapolu",
      area_code: "WBC",
      city_name: "Wapolu",
      country_code: "PG"
  },
  {
      id: "WBD",
      name: "Befandriana",
      area_code: "WBD",
      city_name: "Befandriana",
      country_code: "MG"
  },
  {
      id: "WBE",
      name: "Ankaizina",
      area_code: "WBE",
      city_name: "Bealanana",
      country_code: "MG"
  },
  {
      id: "WBG",
      name: "Jagel",
      area_code: "WBG",
      city_name: "Schleswig",
      country_code: "DE"
  },
  {
      id: "WBK",
      name: "West Branch Community",
      area_code: "WBK",
      city_name: "West Branch",
      country_code: "US"
  },
  {
      id: "WBO",
      name: "Antsoa",
      area_code: "WBO",
      city_name: "Beroroha",
      country_code: "MG"
  },
  {
      id: "WBQ",
      name: "BEAVER AIRPORT",
      area_code: "WBQ",
      city_name: "Beaver",
      country_code: "US"
  },
  {
      id: "WBR",
      name: "Roben-Hood",
      area_code: "WBR",
      city_name: "Big Rapids",
      country_code: "US"
  },
  {
      id: "WCD",
      name: "Carosue Dam",
      area_code: "KGI",
      city_name: "Kalgoorlie-Boulder",
      country_code: "AU"
  },
  {
      id: "WCH",
      name: "Nuevo Chaiten",
      area_code: "WCH",
      city_name: "Chaiten",
      country_code: "CL"
  },
  {
      id: "WDA",
      name: "Wadi Ain",
      area_code: "WDA",
      city_name: "Wadi Ain",
      country_code: "YE"
  },
  {
      id: "WDI",
      name: "Wondai",
      area_code: "WDI",
      city_name: "Wondai",
      country_code: "AU"
  },
  {
      id: "WDN",
      name: "Waldronaire",
      area_code: "WDN",
      city_name: "Waldron Island",
      country_code: "US"
  },
  {
      id: "WDR",
      name: "Barrow County",
      area_code: "WDR",
      city_name: "Winder",
      country_code: "US"
  },
  {
      id: "WED",
      name: "Wedau",
      area_code: "WED",
      city_name: "Wedau",
      country_code: "PG"
  },
  {
      id: "WEP",
      name: "Weam",
      area_code: "WEP",
      city_name: "Weam",
      country_code: "PG"
  },
  {
      id: "WES",
      name: "Weasua",
      area_code: "WES",
      city_name: "Weasua",
      country_code: "LR"
  },
  {
      id: "WET",
      name: "Waghete",
      area_code: "WET",
      city_name: "Waghete",
      country_code: "ID"
  },
  {
      id: "WFD",
      name: "Woodford",
      area_code: "WFD",
      city_name: "Woodford",
      country_code: "GB"
  },
  {
      id: "WFI",
      name: "Fianarantsoa",
      area_code: "WFI",
      city_name: "Fianarantsoa",
      country_code: "MG"
  },
  {
      id: "WFK",
      name: "Northern Aroostook Rgl",
      area_code: "WFK",
      city_name: "Frenchville",
      country_code: "US"
  },
  {
      id: "WGN",
      name: "Wugang Airport",
      area_code: "WGN",
      city_name: "Shaoyang",
      country_code: "CN"
  },
  {
      id: "WGU",
      name: "Wagau",
      area_code: "WGU",
      city_name: "Wagau",
      country_code: "PG"
  },
  {
      id: "WGY",
      name: "Wagny",
      area_code: "WGY",
      city_name: "Wagny",
      country_code: "GA"
  },
  {
      id: "WHF",
      name: "Wadi Halfa",
      area_code: "WHF",
      city_name: "Wadi Halfa",
      country_code: "SD"
  },
  {
      id: "WHO",
      name: "Franz Josef Glacier",
      area_code: "WHO",
      city_name: "Franz Josef Glacier",
      country_code: "NZ"
  },
  {
      id: "WHS",
      name: "Whalsay",
      area_code: "WHS",
      city_name: "Whalsay",
      country_code: "GB"
  },
  {
      id: "WHT",
      name: "Regional",
      area_code: "WHT",
      city_name: "Wharton",
      country_code: "US"
  },
  {
      id: "WIB",
      name: "Wilbarger County",
      area_code: "WIB",
      city_name: "Vernon",
      country_code: "US"
  },
  {
      id: "WIK",
      name: "Waiheke Island",
      area_code: "WIK",
      city_name: "Waiheke Island",
      country_code: "NZ"
  },
  {
      id: "WIN",
      name: "WINTON AIRPORT",
      area_code: "WIN",
      city_name: "Winton",
      country_code: "AU"
  },
  {
      id: "WIO",
      name: "Wilcannia",
      area_code: "WIO",
      city_name: "Wilcannia",
      country_code: "AU"
  },
  {
      id: "WIR",
      name: "Wairoa",
      area_code: "WIR",
      city_name: "Wairoa",
      country_code: "NZ"
  },
  {
      id: "WIT",
      name: "Wittenoom",
      area_code: "WIT",
      city_name: "Wittenoom",
      country_code: "AU"
  },
  {
      id: "WIU",
      name: "Witu",
      area_code: "WIU",
      city_name: "Witu",
      country_code: "PG"
  },
  {
      id: "WJA",
      name: "Woja Island",
      area_code: "WJA",
      city_name: "Woja Island",
      country_code: "MH"
  },
  {
      id: "WJR",
      name: "WAJIR AIRPORT",
      area_code: "WJR",
      city_name: "Wajir",
      country_code: "KE"
  },
  {
      id: "WJU",
      name: "WONJU AIRPORT",
      area_code: "WJU",
      city_name: "Wonju",
      country_code: "KR"
  },
  {
      id: "WKB",
      name: "Warracknabeal",
      area_code: "WKB",
      city_name: "Warracknabeal",
      country_code: "AU"
  },
  {
      id: "WKI",
      name: "Hwange",
      area_code: "WKI",
      city_name: "Hwange",
      country_code: "ZW"
  },
  {
      id: "WKK",
      name: "Aleknagik",
      area_code: "WKK",
      city_name: "Aleknagik",
      country_code: "US"
  },
  {
      id: "WKN",
      name: "Wakunai",
      area_code: "WKN",
      city_name: "Wakunai",
      country_code: "PG"
  },
  {
      id: "WKR",
      name: "Walker's Cay",
      area_code: "WKR",
      city_name: "Walker's Cay",
      country_code: "BS"
  },
  {
      id: "WLA",
      name: "Wallal Downs",
      area_code: "WLA",
      city_name: "Wallal Downs",
      country_code: "AU"
  },
  {
      id: "WLC",
      name: "Walcha",
      area_code: "WLC",
      city_name: "Walcha",
      country_code: "AU"
  },
  {
      id: "WLE",
      name: "Miles",
      area_code: "WLE",
      city_name: "Miles",
      country_code: "AU"
  },
  {
      id: "WLL",
      name: "Wollogorang",
      area_code: "WLL",
      city_name: "Wollogorang",
      country_code: "AU"
  },
  {
      id: "WLO",
      name: "Waterloo",
      area_code: "WLO",
      city_name: "Waterloo",
      country_code: "AU"
  },
  {
      id: "WLR",
      name: "Sea Plane Base",
      area_code: "WLR",
      city_name: "Loring",
      country_code: "US"
  },
  {
      id: "WMA",
      name: "Mandritsara",
      area_code: "WMA",
      city_name: "Mandritsara",
      country_code: "MG"
  },
  {
      id: "WMB",
      name: "Warrnambool Airport",
      area_code: "WMB",
      city_name: "Warrnambool",
      country_code: "AU"
  },
  {
      id: "WMD",
      name: "Mandabe",
      area_code: "WMD",
      city_name: "Mandabe",
      country_code: "MG"
  },
  {
      id: "WME",
      name: "Mount Keith",
      area_code: "WME",
      city_name: "Mount Keith",
      country_code: "AU"
  },
  {
      id: "WMK",
      name: "Meyers Chuck Seaplane Base",
      area_code: "WMK",
      city_name: "Meyers Chuck",
      country_code: "US"
  },
  {
      id: "WML",
      name: "Malaimbandy",
      area_code: "WML",
      city_name: "Malaimbandy",
      country_code: "MG"
  },
  {
      id: "WMO",
      name: "WHITE MOUNTAIN AIRPORT",
      area_code: "WMO",
      city_name: "White Mountain",
      country_code: "US"
  },
  {
      id: "WMP",
      name: "Mampikony",
      area_code: "WMP",
      city_name: "Mampikony",
      country_code: "MG"
  },
  {
      id: "WMR",
      name: "Mananara Avaratra",
      area_code: "WMR",
      city_name: "Mananara Avaratra",
      country_code: "MG"
  },
  {
      id: "WMV",
      name: "Madirovalo",
      area_code: "WMV",
      city_name: "Madirovalo",
      country_code: "MG"
  },
  {
      id: "WMX",
      name: "Wamena Airport",
      area_code: "WMX",
      city_name: "Wamena",
      country_code: "ID"
  },
  {
      id: "WND",
      name: "Windarra",
      area_code: "WND",
      city_name: "Windarra",
      country_code: "AU"
  },
  {
      id: "WNE",
      name: "Wora na Yeno",
      area_code: "WNE",
      city_name: "Wora na Yeno",
      country_code: "GA"
  },
  {
      id: "WNI",
      name: "Matahora",
      area_code: "WNI",
      city_name: "Wangiwangi Island",
      country_code: "ID"
  },
  {
      id: "WNU",
      name: "Wanuma",
      area_code: "WNU",
      city_name: "Wanuma",
      country_code: "PG"
  },
  {
      id: "WOA",
      name: "Wonenara",
      area_code: "WOA",
      city_name: "Wonenara",
      country_code: "PG"
  },
  {
      id: "WOE",
      name: "Woensdrecht AB",
      area_code: "WOE",
      city_name: "Bergen op Zoom",
      country_code: "NL"
  },
  {
      id: "WOG",
      name: "Woodgreen",
      area_code: "WOG",
      city_name: "Woodgreen",
      country_code: "AU"
  },
  {
      id: "WOK",
      name: "Uonquen",
      area_code: "WOK",
      city_name: "Uonquen",
      country_code: "VE"
  },
  {
      id: "WON",
      name: "Wondoola",
      area_code: "WON",
      city_name: "Wondoola",
      country_code: "AU"
  },
  {
      id: "WOR",
      name: "Moramba",
      area_code: "WOR",
      city_name: "Ankorefo",
      country_code: "MG"
  },
  {
      id: "WOS",
      name: "Wonsan",
      area_code: "WOS",
      city_name: "Wonsan",
      country_code: "KP"
  },
  {
      id: "WOT",
      name: "Wang'an",
      area_code: "WOT",
      city_name: "Wang'an",
      country_code: "CN"
  },
  {
      id: "WPB",
      name: "Port Berge Vaovao",
      area_code: "WPB",
      city_name: "Port Berge Vaovao",
      country_code: "MG"
  },
  {
      id: "WPC",
      name: "Pincher Creek",
      area_code: "WPC",
      city_name: "Pincher Creek",
      country_code: "CA"
  },
  {
      id: "WPK",
      name: "Wrotham Park",
      area_code: "WPK",
      city_name: "Wrotham Park",
      country_code: "AU"
  },
  {
      id: "WPL",
      name: "Powell Lake SPB",
      area_code: "YPW",
      city_name: "Powell River",
      country_code: "CA"
  },
  {
      id: "WPR",
      name: "Porvenir Airport",
      area_code: "WPR",
      city_name: "Porvenir",
      country_code: "CL"
  },
  {
      id: "WPU",
      name: "Puerto Williams Airport",
      area_code: "WPU",
      city_name: "Puerto Williams",
      country_code: "CL"
  },
  {
      id: "WRA",
      name: "Warder",
      area_code: "WRA",
      city_name: "Warder",
      country_code: "ET"
  },
  {
      id: "WRI",
      name: "McGuire AFB",
      area_code: "WRI",
      city_name: "Wrightstown",
      country_code: "US"
  },
  {
      id: "WRN",
      name: "Windarling",
      area_code: "WRN",
      city_name: "Windarling",
      country_code: "AU"
  },
  {
      id: "WRT",
      name: "Warton",
      area_code: "WRT",
      city_name: "Warton",
      country_code: "GB"
  },
  {
      id: "WRW",
      name: "Warrawagine Station",
      area_code: "WRW",
      city_name: "Warrawagine Station",
      country_code: "AU"
  },
  {
      id: "WRZ",
      name: "Weerawila",
      area_code: "WRZ",
      city_name: "Weerawila",
      country_code: "LK"
  },
  {
      id: "WSA",
      name: "Wasua",
      area_code: "WSA",
      city_name: "Wasua",
      country_code: "PG"
  },
  {
      id: "WSD",
      name: "Condron AAF",
      area_code: "WSD",
      city_name: "White Sands",
      country_code: "US"
  },
  {
      id: "WSF",
      name: "Cape Sarichef",
      area_code: "WSF",
      city_name: "Cape Sarichef",
      country_code: "US"
  },
  {
      id: "WSK",
      name: "Chongqing",
      area_code: "WSK",
      city_name: "Wushan",
      country_code: "CN"
  },
  {
      id: "WSN",
      name: "SOUTH NAKNEK AIRPORT",
      area_code: "WSN",
      city_name: "South Naknek",
      country_code: "US"
  },
  {
      id: "WSO",
      name: "Wasjabo",
      area_code: "WSO",
      city_name: "Wasjabo",
      country_code: "SR"
  },
  {
      id: "WSP",
      name: "Waspam",
      area_code: "WSP",
      city_name: "Waspam",
      country_code: "NI"
  },
  {
      id: "WSR",
      name: "Wasior",
      area_code: "WSR",
      city_name: "Wasior",
      country_code: "ID"
  },
  {
      id: "WSU",
      name: "Wasu",
      area_code: "WSU",
      city_name: "Wasu",
      country_code: "PG"
  },
  {
      id: "WTA",
      name: "Tambohorano",
      area_code: "WTA",
      city_name: "Tambohorano",
      country_code: "MG"
  },
  {
      id: "WTD",
      name: "West End",
      area_code: "WTD",
      city_name: "West End",
      country_code: "BS"
  },
  {
      id: "WTE",
      name: "Wotje Island",
      area_code: "WTE",
      city_name: "Wotje Island",
      country_code: "MH"
  },
  {
      id: "WTK",
      name: "NOATAK AIRPORT",
      area_code: "WTK",
      city_name: "Noatak",
      country_code: "US"
  },
  {
      id: "WTN",
      name: "RAF Station",
      area_code: "WTN",
      city_name: "Waddington",
      country_code: "GB"
  },
  {
      id: "WTO",
      name: "Wotho Island",
      area_code: "WTO",
      city_name: "Wotho Island",
      country_code: "MH"
  },
  {
      id: "WTP",
      name: "Woitape",
      area_code: "WTP",
      city_name: "Woitape",
      country_code: "PG"
  },
  {
      id: "WTS",
      name: "Tsiroanomandidy",
      area_code: "WTS",
      city_name: "Tsiroanomandidy",
      country_code: "MG"
  },
  {
      id: "WTT",
      name: "Wantoat",
      area_code: "WTT",
      city_name: "Wantoat",
      country_code: "PG"
  },
  {
      id: "WTZ",
      name: "Whitianga",
      area_code: "WTZ",
      city_name: "Whitianga",
      country_code: "NZ"
  },
  {
      id: "WUD",
      name: "Wudinna",
      area_code: "WUD",
      city_name: "Wudinna",
      country_code: "AU"
  },
  {
      id: "WUG",
      name: "Wau",
      area_code: "WUG",
      city_name: "Wau",
      country_code: "PG"
  },
  {
      id: "WUI",
      name: "Murrin Murrin",
      area_code: "WUI",
      city_name: "Murrin Murrin",
      country_code: "AU"
  },
  {
      id: "WUM",
      name: "Wasum",
      area_code: "WUM",
      city_name: "Wasum",
      country_code: "PG"
  },
  {
      id: "WUV",
      name: "Wuvulu Island",
      area_code: "WUV",
      city_name: "Wuvulu Island",
      country_code: "PG"
  },
  {
      id: "WVK",
      name: "Manakara",
      area_code: "WVK",
      city_name: "Manakara",
      country_code: "MG"
  },
  {
      id: "WWI",
      name: "Woodie Woodie Airport",
      area_code: "WWI",
      city_name: "Woodie Woodie",
      country_code: "AU"
  },
  {
      id: "WWT",
      name: "NEWTOK AIRPORT",
      area_code: "WWT",
      city_name: "Newtok",
      country_code: "US"
  },
  {
      id: "WYE",
      name: "Yengema",
      area_code: "WYE",
      city_name: "Yengema",
      country_code: "SL"
  },
  {
      id: "WZQ",
      name: "Urad Middle Banner",
      area_code: "WZQ",
      city_name: "Urad Middle Banner",
      country_code: "CN"
  },
  {
      id: "XAI",
      name: "Minggang Airport",
      area_code: "XAI",
      city_name: "Xinyang",
      country_code: "CN"
  },
  {
      id: "XAR",
      name: "Aribinda",
      area_code: "XAR",
      city_name: "Aribinda",
      country_code: "BF"
  },
  {
      id: "XAU",
      name: "Saul",
      area_code: "XAU",
      city_name: "Saul",
      country_code: "GY"
  },
  {
      id: "XBB",
      name: "SPB",
      area_code: "XBB",
      city_name: "Blubber Bay",
      country_code: "CA"
  },
  {
      id: "XBG",
      name: "Bogande",
      area_code: "XBG",
      city_name: "Bogande",
      country_code: "BF"
  },
  {
      id: "XBJ",
      name: "BIRJAND AIRPORT",
      area_code: "XBJ",
      city_name: "Birjand",
      country_code: "IR"
  },
  {
      id: "XBL",
      name: "Buno Bedele",
      area_code: "XBL",
      city_name: "Bedele",
      country_code: "ET"
  },
  {
      id: "XBN",
      name: "Biniguni",
      area_code: "XBN",
      city_name: "Biniguni",
      country_code: "PG"
  },
  {
      id: "XBO",
      name: "Boulsa",
      area_code: "XBO",
      city_name: "Boulsa",
      country_code: "BF"
  },
  {
      id: "XCL",
      name: "Cluff Lake",
      area_code: "XCL",
      city_name: "Cluff Lake",
      country_code: "CA"
  },
  {
      id: "XCN",
      name: "Coron",
      area_code: "XCN",
      city_name: "Coron",
      country_code: "PH"
  },
  {
      id: "XDE",
      name: "Diebougou",
      area_code: "XDE",
      city_name: "Diebougou",
      country_code: "BF"
  },
  {
      id: "XDJ",
      name: "Djibo",
      area_code: "XDJ",
      city_name: "Djibo",
      country_code: "BF"
  },
  {
      id: "XGA",
      name: "Amilcar Cabral",
      area_code: "XGA",
      city_name: "Gaoua",
      country_code: "BF"
  },
  {
      id: "XGG",
      name: "Gorom-Gorom",
      area_code: "XGG",
      city_name: "Gorom-Gorom",
      country_code: "BF"
  },
  {
      id: "XGN",
      name: "Xangongo",
      area_code: "XGN",
      city_name: "Xangongo",
      country_code: "AO"
  },
  {
      id: "XIE",
      name: "Xienglom",
      area_code: "XIE",
      city_name: "Xienglom",
      country_code: "LA"
  },
  {
      id: "XIG",
      name: "Xinguara",
      area_code: "XIG",
      city_name: "Xinguara",
      country_code: "BR"
  },
  {
      id: "XIJ",
      name: "Air Base",
      area_code: "XIJ",
      city_name: "Ahmed al Jaber",
      country_code: "KW"
  },
  {
      id: "XJD",
      name: "Air Base",
      area_code: "XJD",
      city_name: "Al Udeid",
      country_code: "QA"
  },
  {
      id: "XJM",
      name: "Mangla",
      area_code: "XJM",
      city_name: "Mangla",
      country_code: "PK"
  },
  {
      id: "XKA",
      name: "Kantchari",
      area_code: "XKA",
      city_name: "Kantchari",
      country_code: "BF"
  },
  {
      id: "XKY",
      name: "Kaya",
      area_code: "XKY",
      city_name: "Kaya",
      country_code: "BF"
  },
  {
      id: "XLO",
      name: "Long Xuyen",
      area_code: "XLO",
      city_name: "Long Xuyen",
      country_code: "VN"
  },
  {
      id: "XLU",
      name: "Leo",
      area_code: "XLU",
      city_name: "Leo",
      country_code: "BF"
  },
  {
      id: "XLW",
      name: "Lemwerder",
      area_code: "XLW",
      city_name: "Lemwerder",
      country_code: "DE"
  },
  {
      id: "XMA",
      name: "Maramag",
      area_code: "XMA",
      city_name: "Maramag",
      country_code: "PH"
  },
  {
      id: "XMC",
      name: "Mallacoota",
      area_code: "XMC",
      city_name: "Mallacoota",
      country_code: "AU"
  },
  {
      id: "XMD",
      name: "Municipal",
      area_code: "XMD",
      city_name: "Madison",
      country_code: "US"
  },
  {
      id: "XMG",
      name: "Mahendranagar",
      area_code: "XMG",
      city_name: "Mahendranagar",
      country_code: "NP"
  },
  {
      id: "XMH",
      name: "MANIHI AIRPORT",
      area_code: "XMH",
      city_name: "Manihi",
      country_code: "PF"
  },
  {
      id: "XMI",
      name: "Masasi",
      area_code: "XMI",
      city_name: "Masasi",
      country_code: "TZ"
  },
  {
      id: "XML",
      name: "Minlaton",
      area_code: "XML",
      city_name: "Minlaton",
      country_code: "AU"
  },
  {
      id: "XMP",
      name: "Macmillan Pass",
      area_code: "XMP",
      city_name: "Macmillan Pass",
      country_code: "CA"
  },
  {
      id: "XNG",
      name: "Quang Ngai",
      area_code: "XNG",
      city_name: "Quang Ngai",
      country_code: "VN"
  },
  {
      id: "XNH",
      name: "Ali Air Base",
      area_code: "XNH",
      city_name: "Nasiriyah",
      country_code: "IQ"
  },
  {
      id: "XNU",
      name: "Nouna",
      area_code: "XNU",
      city_name: "Nouna",
      country_code: "BF"
  },
  {
      id: "XPA",
      name: "Pama",
      area_code: "XPA",
      city_name: "Pama",
      country_code: "BF"
  },
  {
      id: "XPR",
      name: "Pine Ridge",
      area_code: "XPR",
      city_name: "Pine Ridge",
      country_code: "US"
  },
  {
      id: "XRH",
      name: "RAAF Base",
      area_code: "XRH",
      city_name: "Richmond",
      country_code: "AU"
  },
  {
      id: "XRQ",
      name: "Xinbarag Youqi Baogede Airport",
      area_code: "XRQ",
      city_name: "Hulunbuir",
      country_code: "CN"
  },
  {
      id: "XSB",
      name: "Sir Bani Yas Island",
      area_code: "XSB",
      city_name: "Sir Bani Yas Island",
      country_code: "AE"
  },
  {
      id: "XSE",
      name: "Sebba",
      area_code: "XSE",
      city_name: "Sebba",
      country_code: "BF"
  },
  {
      id: "XSO",
      name: "Siocon",
      area_code: "XSO",
      city_name: "Siocon",
      country_code: "PH"
  },
  {
      id: "XTO",
      name: "Taroom",
      area_code: "XTO",
      city_name: "Taroom",
      country_code: "AU"
  },
  {
      id: "XTR",
      name: "Tara",
      area_code: "XTR",
      city_name: "Tara",
      country_code: "AU"
  },
  {
      id: "XVL",
      name: "Vinh Long",
      area_code: "XVL",
      city_name: "Vinh Long",
      country_code: "VN"
  },
  {
      id: "XYA",
      name: "Yandina",
      area_code: "XYA",
      city_name: "Yandina",
      country_code: "SB"
  },
  {
      id: "XYE",
      name: "Ye",
      area_code: "XYE",
      city_name: "Ye",
      country_code: "MM"
  },
  {
      id: "XYR",
      name: "Edwaki",
      area_code: "XYR",
      city_name: "Yellow River",
      country_code: "PG"
  },
  {
      id: "XZA",
      name: "Zabre",
      area_code: "XZA",
      city_name: "Zabre",
      country_code: "BF"
  },
  {
      id: "YAA",
      name: "ANAHIM LAKE AIRPORT",
      area_code: "YAA",
      city_name: "Anahim Lake",
      country_code: "CA"
  },
  {
      id: "YAD",
      name: "Moose Lake",
      area_code: "YAD",
      city_name: "Moose Lake",
      country_code: "CA"
  },
  {
      id: "YAH",
      name: "La Grande-4",
      area_code: "YAH",
      city_name: "La Grande-4",
      country_code: "CA"
  },
  {
      id: "YAI",
      name: "General B. O'Higgins",
      area_code: "YAI",
      city_name: "Chillan",
      country_code: "CL"
  },
  {
      id: "YAJ",
      name: "Lyall Harbour SPB",
      area_code: "YAJ",
      city_name: "Saturna Island",
      country_code: "CA"
  },
  {
      id: "YAL",
      name: "Alert Bay",
      area_code: "YAL",
      city_name: "Alert Bay",
      country_code: "CA"
  },
  {
      id: "YAN",
      name: "Yangambi",
      area_code: "YAN",
      city_name: "Yangambi",
      country_code: "CD"
  },
  {
      id: "YAQ",
      name: "SPB",
      area_code: "YAQ",
      city_name: "Maple Bay",
      country_code: "CA"
  },
  {
      id: "YAR",
      name: "La Grande-3",
      area_code: "YAR",
      city_name: "La Grande-3",
      country_code: "CA"
  },
  {
      id: "YAS",
      name: "Yasawa Island",
      area_code: "YAS",
      city_name: "Yasawa Island",
      country_code: "FJ"
  },
  {
      id: "YAU",
      name: "Donaldson",
      area_code: "YAU",
      city_name: "Kattiniq",
      country_code: "CA"
  },
  {
      id: "YAX",
      name: "Wapekeka",
      area_code: "YAX",
      city_name: "Angling Lake",
      country_code: "CA"
  },
  {
      id: "YBE",
      name: "Uranium City",
      area_code: "YBE",
      city_name: "Uranium City",
      country_code: "CA"
  },
  {
      id: "YBF",
      name: "SPB",
      area_code: "YBF",
      city_name: "Bamfield",
      country_code: "CA"
  },
  {
      id: "YBH",
      name: "SPB",
      area_code: "YBH",
      city_name: "Bull Harbour",
      country_code: "CA"
  },
  {
      id: "YBI",
      name: "Black Tickle",
      area_code: "YBI",
      city_name: "Black Tickle",
      country_code: "CA"
  },
  {
      id: "YBJ",
      name: "SPB",
      area_code: "YBJ",
      city_name: "Baie-Johan-Beetz",
      country_code: "CA"
  },
  {
      id: "YBO",
      name: "Bob Quinn Lake",
      area_code: "YBO",
      city_name: "Bob Quinn Lake",
      country_code: "CA"
  },
  {
      id: "YBQ",
      name: "Telegraph Harbour SPB",
      area_code: "YBQ",
      city_name: "Thetis Island",
      country_code: "CA"
  },
  {
      id: "YBS",
      name: "Opapamiskan Lake",
      area_code: "YBS",
      city_name: "Opapamiskan Lake",
      country_code: "CA"
  },
  {
      id: "YBY",
      name: "Bonnyville",
      area_code: "YBY",
      city_name: "Bonnyville",
      country_code: "CA"
  },
  {
      id: "YCE",
      name: "James T Field Memorial",
      area_code: "YCE",
      city_name: "Centralia",
      country_code: "CA"
  },
  {
      id: "YCM",
      name: "Niagara District",
      area_code: "YCM",
      city_name: "St Catharines",
      country_code: "CA"
  },
  {
      id: "YCQ",
      name: "Chetwynd",
      area_code: "YCQ",
      city_name: "Chetwynd",
      country_code: "CA"
  },
  {
      id: "YCT",
      name: "Coronation",
      area_code: "YCT",
      city_name: "Coronation",
      country_code: "CA"
  },
  {
      id: "YDB",
      name: "Burwash",
      area_code: "YDB",
      city_name: "Burwash Landing",
      country_code: "CA"
  },
  {
      id: "YDC",
      name: "Industrial",
      area_code: "YDC",
      city_name: "Drayton Valley",
      country_code: "CA"
  },
  {
      id: "YDE",
      name: "Paradise River",
      area_code: "YDE",
      city_name: "Paradise River",
      country_code: "CA"
  },
  {
      id: "YDG",
      name: "Annapolis Regional",
      area_code: "YDG",
      city_name: "Digby",
      country_code: "CA"
  },
  {
      id: "YDJ",
      name: "Hatchet Lake",
      area_code: "YDJ",
      city_name: "Hatchet Lake",
      country_code: "CA"
  },
  {
      id: "YDO",
      name: "St-Felicien",
      area_code: "YDO",
      city_name: "Dolbeau",
      country_code: "CA"
  },
  {
      id: "YDU",
      name: "Kasba Lake",
      area_code: "YDU",
      city_name: "Kasba Lake",
      country_code: "CA"
  },
  {
      id: "YDW",
      name: "North of Sixty",
      area_code: "YDW",
      city_name: "Obre Lake",
      country_code: "CA"
  },
  {
      id: "YEC",
      name: "Yecheon",
      area_code: "YEC",
      city_name: "Yecheon",
      country_code: "KR"
  },
  {
      id: "YEM",
      name: "Manitoulin East Muni",
      area_code: "YEM",
      city_name: "Manitowaning",
      country_code: "CA"
  },
  {
      id: "YEQ",
      name: "Yankisa",
      area_code: "YEQ",
      city_name: "Yankisa",
      country_code: "PG"
  },
  {
      id: "YES",
      name: "YASOUJ AIRPORT",
      area_code: "YES",
      city_name: "Yasouj",
      country_code: "IR"
  },
  {
      id: "YEU",
      name: "Eureka",
      area_code: "YEU",
      city_name: "Eureka",
      country_code: "CA"
  },
  {
      id: "YFA",
      name: "FORT ALBANY AIRPORT",
      area_code: "YFA",
      city_name: "Fort Albany",
      country_code: "CA"
  },
  {
      id: "YFE",
      name: "Forestville",
      area_code: "YFE",
      city_name: "Forestville",
      country_code: "CA"
  },
  {
      id: "YFG",
      name: "Fontanges",
      area_code: "YFG",
      city_name: "Fontanges",
      country_code: "CA"
  },
  {
      id: "YFI",
      name: "Firebag",
      area_code: "HZP",
      city_name: "Fort MacKay",
      country_code: "CA"
  },
  {
      id: "YFJ",
      name: "Snare Lake Airport",
      area_code: "YFJ",
      city_name: "Wekweeti",
      country_code: "CA"
  },
  {
      id: "YFL",
      name: "SPB",
      area_code: "YFL",
      city_name: "Fort Reliance",
      country_code: "CA"
  },
  {
      id: "YFR",
      name: "Fort Resolution",
      area_code: "YFR",
      city_name: "Fort Resolution",
      country_code: "CA"
  },
  {
      id: "YFS",
      name: "FORT SIMPSON AIRPORT",
      area_code: "YFS",
      city_name: "Fort Simpson",
      country_code: "CA"
  },
  {
      id: "YGB",
      name: "Gillies Bay",
      area_code: "YGB",
      city_name: "Gillies Bay",
      country_code: "CA"
  },
  {
      id: "YGC",
      name: "Grande Cache",
      area_code: "YGC",
      city_name: "Grande Cache",
      country_code: "CA"
  },
  {
      id: "YGE",
      name: "SPB",
      area_code: "YGE",
      city_name: "Gorge Harbour",
      country_code: "CA"
  },
  {
      id: "YGG",
      name: "SPB",
      area_code: "YGG",
      city_name: "Ganges",
      country_code: "CA"
  },
  {
      id: "YGH",
      name: "FORT GOOD HOPE AIRPORT",
      area_code: "YGH",
      city_name: "Fort Good Hope",
      country_code: "CA"
  },
  {
      id: "YGM",
      name: "Industrial Park",
      area_code: "YGM",
      city_name: "Gimli",
      country_code: "CA"
  },
  {
      id: "YGN",
      name: "SPB",
      area_code: "YGN",
      city_name: "Greenway Sound",
      country_code: "CA"
  },
  {
      id: "YGZ",
      name: "GRISE FIORD AIRPORT",
      area_code: "YGZ",
      city_name: "Grise Fiord",
      country_code: "CA"
  },
  {
      id: "YHC",
      name: "SPB",
      area_code: "YHC",
      city_name: "Hakai Passage",
      country_code: "CA"
  },
  {
      id: "YHI",
      name: "Holman Airport",
      area_code: "YHI",
      city_name: "Ulukhaktok",
      country_code: "CA"
  },
  {
      id: "YHT",
      name: "Haines Junction",
      area_code: "YHT",
      city_name: "Haines Junction",
      country_code: "CA"
  },
  {
      id: "YIA",
      name: "New Yogyakarta Int.",
      area_code: "JOG",
      city_name: "Yogyakarta",
      country_code: "ID"
  },
  {
      id: "YIG",
      name: "Big Bay SPB",
      area_code: "YRR",
      city_name: "Stuart Island",
      country_code: "CA"
  },
  {
      id: "YJF",
      name: "Fort Liard",
      area_code: "YJF",
      city_name: "Fort Liard",
      country_code: "CA"
  },
  {
      id: "YJN",
      name: "St-Jean",
      area_code: "YJN",
      city_name: "St-Jean",
      country_code: "CA"
  },
  {
      id: "YJP",
      name: "Jasper-Hinton",
      area_code: "YJP",
      city_name: "Hinton",
      country_code: "CA"
  },
  {
      id: "YJS",
      name: "Samjiyon",
      area_code: "YJS",
      city_name: "Samjiyon",
      country_code: "KP"
  },
  {
      id: "YKC",
      name: "Collins Bay",
      area_code: "YKC",
      city_name: "Collins Bay",
      country_code: "CA"
  },
  {
      id: "YKE",
      name: "Knee Lake",
      area_code: "YKE",
      city_name: "Knee Lake",
      country_code: "CA"
  },
  {
      id: "YKJ",
      name: "Key Lake",
      area_code: "YKJ",
      city_name: "Key Lake",
      country_code: "CA"
  },
  {
      id: "YKK",
      name: "SPB",
      area_code: "YKK",
      city_name: "Kitkatla",
      country_code: "CA"
  },
  {
      id: "YKO",
      name: "Yuksekova Airport",
      area_code: "YKO",
      city_name: "Hakkari",
      country_code: "TR"
  },
  {
      id: "YKT",
      name: "SPB",
      area_code: "YKT",
      city_name: "Klemtu",
      country_code: "CA"
  },
  {
      id: "YLB",
      name: "Lac La Biche",
      area_code: "YLB",
      city_name: "Lac La Biche",
      country_code: "CA"
  },
  {
      id: "YLE",
      name: "Wha Ti Airport",
      area_code: "YLE",
      city_name: "Whati",
      country_code: "CA"
  },
  {
      id: "YLG",
      name: "Yalgoo",
      area_code: "YLG",
      city_name: "Yalgoo",
      country_code: "AU"
  },
  {
      id: "YLH",
      name: "Lansdowne House",
      area_code: "YLH",
      city_name: "Lansdowne House",
      country_code: "CA"
  },
  {
      id: "YLK",
      name: "Lake Simcoe Regional",
      area_code: "YLK",
      city_name: "Barrie/Orillia",
      country_code: "CA"
  },
  {
      id: "YLM",
      name: "Clinton Creek",
      area_code: "YLM",
      city_name: "Clinton Creek",
      country_code: "CA"
  },
  {
      id: "YLP",
      name: "Mingan",
      area_code: "YLP",
      city_name: "Mingan",
      country_code: "CA"
  },
  {
      id: "YLR",
      name: "Leaf Rapids",
      area_code: "YLR",
      city_name: "Leaf Rapids",
      country_code: "CA"
  },
  {
      id: "YLS",
      name: "Lebel-sur-Quevillon",
      area_code: "YLS",
      city_name: "Lebel-sur-Quevillon",
      country_code: "CA"
  },
  {
      id: "YLT",
      name: "Alert",
      area_code: "YLT",
      city_name: "Alert",
      country_code: "CA"
  },
  {
      id: "YLV",
      name: "Yevlakh",
      area_code: "YLV",
      city_name: "Yevlakh",
      country_code: "AZ"
  },
  {
      id: "YMA",
      name: "Mayo",
      area_code: "YMA",
      city_name: "Mayo",
      country_code: "CA"
  },
  {
      id: "YMD",
      name: "Mould Bay",
      area_code: "YMD",
      city_name: "Mould Bay",
      country_code: "CA"
  },
  {
      id: "YMF",
      name: "Montague Harbour SPB",
      area_code: "YMF",
      city_name: "Galiano Island",
      country_code: "CA"
  },
  {
      id: "YML",
      name: "Charlevoix",
      area_code: "YML",
      city_name: "La Malbaie",
      country_code: "CA"
  },
  {
      id: "YMP",
      name: "Port McNeill",
      area_code: "YMP",
      city_name: "Port McNeill",
      country_code: "CA"
  },
  {
      id: "YMS",
      name: "M.Benzaquen Rengifo",
      area_code: "YMS",
      city_name: "Yurimaguas",
      country_code: "PE"
  },
  {
      id: "YMU",
      name: "SPB",
      area_code: "YMU",
      city_name: "Mansons Landing",
      country_code: "CA"
  },
  {
      id: "YMV",
      name: "Mary River",
      area_code: "YMV",
      city_name: "Mary River",
      country_code: "CA"
  },
  {
      id: "YMW",
      name: "Maniwaki",
      area_code: "YMW",
      city_name: "Maniwaki",
      country_code: "CA"
  },
  {
      id: "YNC",
      name: "WEMINDJI AIRPORT",
      area_code: "YNC",
      city_name: "Wemindji",
      country_code: "CA"
  },
  {
      id: "YNH",
      name: "Hudson's Hope",
      area_code: "YNH",
      city_name: "Hudson's Hope",
      country_code: "CA"
  },
  {
      id: "YNL",
      name: "Points North Landing",
      area_code: "YNL",
      city_name: "Points North Landing",
      country_code: "CA"
  },
  {
      id: "YNM",
      name: "Matagami",
      area_code: "YNM",
      city_name: "Matagami",
      country_code: "CA"
  },
  {
      id: "YNS",
      name: "NEMISCAU AIRPORT",
      area_code: "YNS",
      city_name: "Nemiscau",
      country_code: "CA"
  },
  {
      id: "YNX",
      name: "Snap Lake",
      area_code: "YNX",
      city_name: "Snap Lake",
      country_code: "CA"
  },
  {
      id: "YOA",
      name: "Ekati",
      area_code: "YOA",
      city_name: "Ekati",
      country_code: "CA"
  },
  {
      id: "YOC",
      name: "Old Crow Airport",
      area_code: "YOC",
      city_name: "Old Crow",
      country_code: "CA"
  },
  {
      id: "YOE",
      name: "Donnelly",
      area_code: "YOE",
      city_name: "Donnelly",
      country_code: "CA"
  },
  {
      id: "YOG",
      name: "Ogoki Post",
      area_code: "YOG",
      city_name: "Ogoki",
      country_code: "CA"
  },
  {
      id: "YOI",
      name: "Opinaca",
      area_code: "YOI",
      city_name: "Opinaca",
      country_code: "CA"
  },
  {
      id: "YOL",
      name: "YOLA AIRPORT",
      area_code: "YOL",
      city_name: "Yola",
      country_code: "NG"
  },
  {
      id: "YON",
      name: "Trashigang",
      area_code: "YON",
      city_name: "Trashigang",
      country_code: "BT"
  },
  {
      id: "YPC",
      name: "Paulatuk Airport",
      area_code: "YPC",
      city_name: "Paulatuk",
      country_code: "CA"
  },
  {
      id: "YPI",
      name: "SPB",
      area_code: "YPI",
      city_name: "Port Simpson",
      country_code: "CA"
  },
  {
      id: "YPO",
      name: "Peawanuck Airport",
      area_code: "YPO",
      city_name: "Peawanuck",
      country_code: "CA"
  },
  {
      id: "YPT",
      name: "SPB",
      area_code: "YPT",
      city_name: "Pender Harbour",
      country_code: "CA"
  },
  {
      id: "YPY",
      name: "FORT CHIPEWYAN AIRPORT",
      area_code: "YPY",
      city_name: "Fort Chipewyan",
      country_code: "CA"
  },
  {
      id: "YQJ",
      name: "April Point SPB",
      area_code: "YQJ",
      city_name: "Quadra Island",
      country_code: "CA"
  },
  {
      id: "YRA",
      name: "Rae Lakes Airport",
      area_code: "YRA",
      city_name: "Gameti",
      country_code: "CA"
  },
  {
      id: "YRC",
      name: "SPB",
      area_code: "YRC",
      city_name: "Refuge Cove",
      country_code: "CA"
  },
  {
      id: "YRD",
      name: "Dean River",
      area_code: "YRD",
      city_name: "Dean River",
      country_code: "CA"
  },
  {
      id: "YRM",
      name: "Rocky Mountain House",
      area_code: "YRM",
      city_name: "Rocky Mountain House",
      country_code: "CA"
  },
  {
      id: "YRN",
      name: "SPB",
      area_code: "YRN",
      city_name: "Rivers Inlet",
      country_code: "CA"
  },
  {
      id: "YRR",
      name: "Airstrip",
      area_code: "YRR",
      city_name: "Stuart Island",
      country_code: "CA"
  },
  {
      id: "YRV",
      name: "Revelstoke",
      area_code: "YRV",
      city_name: "Revelstoke",
      country_code: "CA"
  },
  {
      id: "YSA",
      name: "Sable Island",
      area_code: "YSA",
      city_name: "Sable Island",
      country_code: "CA"
  },
  {
      id: "YSE",
      name: "Squamish",
      area_code: "YSE",
      city_name: "Squamish",
      country_code: "CA"
  },
  {
      id: "YSF",
      name: "Stony Rapids",
      area_code: "YSF",
      city_name: "Stony Rapids",
      country_code: "CA"
  },
  {
      id: "YSG",
      name: "LUTSELKE AIRPORT",
      area_code: "YSG",
      city_name: "Lutselke",
      country_code: "CA"
  },
  {
      id: "YSI",
      name: "Sans Souci SPB",
      area_code: "YSI",
      city_name: "Frying Pan Island",
      country_code: "CA"
  },
  {
      id: "YSQ",
      name: "Chaganhu Airport",
      area_code: "YSQ",
      city_name: "Songyuan",
      country_code: "CN"
  },
  {
      id: "YSU",
      name: "Summerside",
      area_code: "YSU",
      city_name: "Summerside",
      country_code: "CA"
  },
  {
      id: "YSX",
      name: "Shearwater SPB",
      area_code: "ZEL",
      city_name: "Bella Bella",
      country_code: "CA"
  },
  {
      id: "YSY",
      name: "Sachs Harbour Airport",
      area_code: "YSY",
      city_name: "Sachs Harbour",
      country_code: "CA"
  },
  {
      id: "YTB",
      name: "SPB",
      area_code: "YTB",
      city_name: "Hartley Bay",
      country_code: "CA"
  },
  {
      id: "YTG",
      name: "SPB",
      area_code: "YTG",
      city_name: "Sullivan Bay",
      country_code: "CA"
  },
  {
      id: "YTT",
      name: "Tisdale",
      area_code: "YTT",
      city_name: "Tisdale",
      country_code: "CA"
  },
  {
      id: "YTU",
      name: "SPB",
      area_code: "YTU",
      city_name: "Tasu",
      country_code: "CA"
  },
  {
      id: "YTX",
      name: "Telegraph Creek",
      area_code: "YTX",
      city_name: "Telegraph Creek",
      country_code: "CA"
  },
  {
      id: "YUE",
      name: "Yuendumu",
      area_code: "YUE",
      city_name: "Yuendumu",
      country_code: "AU"
  },
  {
      id: "YUT",
      name: "REPULSE BAY AIRPORT",
      area_code: "YUT",
      city_name: "Repulse Bay",
      country_code: "CA"
  },
  {
      id: "YVC",
      name: "Barber Field",
      area_code: "YVC",
      city_name: "La Ronge",
      country_code: "CA"
  },
  {
      id: "YVD",
      name: "Yeva",
      area_code: "YVD",
      city_name: "Yeva",
      country_code: "PG"
  },
  {
      id: "YVN",
      name: "Cape Dyer",
      area_code: "YVN",
      city_name: "Cape Dyer",
      country_code: "CA"
  },
  {
      id: "YVT",
      name: "Buffalo Narrows",
      area_code: "YVT",
      city_name: "Buffalo Narrows",
      country_code: "CA"
  },
  {
      id: "YVV",
      name: "Wiarton",
      area_code: "YVV",
      city_name: "Wiarton",
      country_code: "CA"
  },
  {
      id: "YWA",
      name: "Petawawa",
      area_code: "YWA",
      city_name: "Petawawa",
      country_code: "CA"
  },
  {
      id: "YWJ",
      name: "Deline Airport",
      area_code: "YWJ",
      city_name: "Deline",
      country_code: "CA"
  },
  {
      id: "YWM",
      name: "Williams Harbour",
      area_code: "YWM",
      city_name: "Williams Harbour",
      country_code: "CA"
  },
  {
      id: "YWQ",
      name: "Lac Margane SPB",
      area_code: "YWQ",
      city_name: "Chute-des-Passes",
      country_code: "CA"
  },
  {
      id: "YWY",
      name: "Wrigley",
      area_code: "YWY",
      city_name: "Wrigley",
      country_code: "CA"
  },
  {
      id: "YXQ",
      name: "Beaver Creek",
      area_code: "YXQ",
      city_name: "Beaver Creek",
      country_code: "CA"
  },
  {
      id: "YXR",
      name: "Timiskaming Regional",
      area_code: "YXR",
      city_name: "Earlton",
      country_code: "CA"
  },
  {
      id: "YYA",
      name: "Sanhe Airport",
      area_code: "YYA",
      city_name: "Yueyang",
      country_code: "CN"
  },
  {
      id: "YYM",
      name: "Cowley",
      area_code: "YYM",
      city_name: "Cowley",
      country_code: "CA"
  },
  {
      id: "YZE",
      name: "Manitoulin",
      area_code: "YZE",
      city_name: "Gore Bay",
      country_code: "CA"
  },
  {
      id: "YZH",
      name: "Slave Lake",
      area_code: "YZH",
      city_name: "Slave Lake",
      country_code: "CA"
  },
  {
      id: "YZU",
      name: "Whitecourt",
      area_code: "YZU",
      city_name: "Whitecourt",
      country_code: "CA"
  },
  {
      id: "YZZ",
      name: "Trail Airport",
      area_code: "YZZ",
      city_name: "Trail",
      country_code: "CA"
  },
  {
      id: "ZAA",
      name: "Silver City SPB",
      area_code: "ZAA",
      city_name: "Alice Arm",
      country_code: "CA"
  },
  {
      id: "ZAH",
      name: "Zahedan Airport",
      area_code: "ZAH",
      city_name: "Zahedan",
      country_code: "IR"
  },
  {
      id: "ZAJ",
      name: "ZARANJ AIRPORT",
      area_code: "ZAJ",
      city_name: "Zaranj",
      country_code: "AF"
  },
  {
      id: "ZBE",
      name: "Zabreh",
      area_code: "ZBE",
      city_name: "Zabreh",
      country_code: "CZ"
  },
  {
      id: "ZBK",
      name: "Zabljak",
      area_code: "ZBK",
      city_name: "Zabljak",
      country_code: "ME"
  },
  {
      id: "ZBO",
      name: "Bowen",
      area_code: "ZBO",
      city_name: "Bowen",
      country_code: "AU"
  },
  {
      id: "ZBY",
      name: "Sayaboury",
      area_code: "ZBY",
      city_name: "Sayaboury",
      country_code: "LA"
  },
  {
      id: "ZDY",
      name: "Dalma Airport",
      area_code: "ZDY",
      city_name: "Delma Island",
      country_code: "AE"
  },
  {
      id: "ZEG",
      name: "Senggo",
      area_code: "ZEG",
      city_name: "Senggo",
      country_code: "ID"
  },
  {
      id: "ZEL",
      name: "Bella Bella Airport",
      area_code: "ZEL",
      city_name: "Bella Bella",
      country_code: "CA"
  },
  {
      id: "ZEM",
      name: "Eastmain River Airport",
      area_code: "ZEM",
      city_name: "Eastmain",
      country_code: "CA"
  },
  {
      id: "ZEN",
      name: "Zenag",
      area_code: "ZEN",
      city_name: "Zenag",
      country_code: "PG"
  },
  {
      id: "ZFA",
      name: "Faro",
      area_code: "ZFA",
      city_name: "Faro",
      country_code: "CA"
  },
  {
      id: "ZFN",
      name: "TULITA AIRPORT",
      area_code: "ZFN",
      city_name: "Tulita",
      country_code: "CA"
  },
  {
      id: "ZFW",
      name: "Fairview",
      area_code: "ZFW",
      city_name: "Fairview",
      country_code: "CA"
  },
  {
      id: "ZGF",
      name: "Grand Forks",
      area_code: "ZGF",
      city_name: "Grand Forks",
      country_code: "CA"
  },
  {
      id: "ZGL",
      name: "South Galway",
      area_code: "ZGL",
      city_name: "South Galway",
      country_code: "AU"
  },
  {
      id: "ZGM",
      name: "Ngoma",
      area_code: "ZGM",
      city_name: "Ngoma",
      country_code: "ZM"
  },
  {
      id: "ZGU",
      name: "Gaua Airport",
      area_code: "ZGU",
      city_name: "Gaua Island",
      country_code: "VU"
  },
  {
      id: "ZHM",
      name: "Shamshernagar",
      area_code: "ZHM",
      city_name: "Shamshernagar",
      country_code: "BD"
  },
  {
      id: "ZHP",
      name: "High Prairie",
      area_code: "ZHP",
      city_name: "High Prairie",
      country_code: "CA"
  },
  {
      id: "ZIC",
      name: "Victoria",
      area_code: "ZIC",
      city_name: "Victoria",
      country_code: "CL"
  },
  {
      id: "ZIS",
      name: "Zintan Airport",
      area_code: "ZIS",
      city_name: "Zintan",
      country_code: "LY"
  },
  {
      id: "ZIZ",
      name: "Zamzama",
      area_code: "ZIZ",
      city_name: "Zamzama",
      country_code: "PK"
  },
  {
      id: "ZKB",
      name: "Kasaba Bay",
      area_code: "ZKB",
      city_name: "Kasaba Bay",
      country_code: "ZM"
  },
  {
      id: "ZKL",
      name: "Fengming General",
      area_code: "ZKL",
      city_name: "Zigong",
      country_code: "CN"
  },
  {
      id: "ZMD",
      name: "Sena Madureira",
      area_code: "ZMD",
      city_name: "Sena Madureira",
      country_code: "BR"
  },
  {
      id: "ZMH",
      name: "South Cariboo Regional",
      area_code: "ZMH",
      city_name: "108 Mile Ranch",
      country_code: "CA"
  },
  {
      id: "ZMM",
      name: "Zamora",
      area_code: "ZMM",
      city_name: "Zamora",
      country_code: "MX"
  },
  {
      id: "ZMT",
      name: "MASSET AIRPORT",
      area_code: "ZMT",
      city_name: "Masset",
      country_code: "CA"
  },
  {
      id: "ZNC",
      name: "Nyac",
      area_code: "ZNC",
      city_name: "Nyac",
      country_code: "US"
  },
  {
      id: "ZND",
      name: "ZINDER AIRPORT",
      area_code: "ZND",
      city_name: "Zinder",
      country_code: "NE"
  },
  {
      id: "ZNU",
      name: "SPB",
      area_code: "ZNU",
      city_name: "Namu",
      country_code: "CA"
  },
  {
      id: "ZOF",
      name: "SPB",
      area_code: "ZOF",
      city_name: "Ocean Falls",
      country_code: "CA"
  },
  {
      id: "ZPC",
      name: "Pucon",
      area_code: "ZPC",
      city_name: "Pucon",
      country_code: "CL"
  },
  {
      id: "ZPH",
      name: "Municipal",
      area_code: "ZPH",
      city_name: "Zephyrhills",
      country_code: "US"
  },
  {
      id: "ZPO",
      name: "Pinehouse Lake",
      area_code: "ZPO",
      city_name: "Pinehouse",
      country_code: "CA"
  },
  {
      id: "ZQW",
      name: "Zweibruecken",
      area_code: "SCN",
      city_name: "Saarbruecken",
      country_code: "DE"
  },
  {
      id: "ZRI",
      name: "S.Condronegoro",
      area_code: "ZRI",
      city_name: "Serui",
      country_code: "ID"
  },
  {
      id: "ZRJ",
      name: "Round Lake Airport",
      area_code: "ZRJ",
      city_name: "Round Lake",
      country_code: "CA"
  },
  {
      id: "ZSS",
      name: "Sassandra",
      area_code: "ZSS",
      city_name: "Sassandra",
      country_code: "CI"
  },
  {
      id: "ZST",
      name: "Stewart",
      area_code: "ZST",
      city_name: "Stewart",
      country_code: "CA"
  },
  {
      id: "ZTA",
      name: "Tureira Airport",
      area_code: "ZTA",
      city_name: "Tureia",
      country_code: "PF"
  },
  {
      id: "ZTR",
      name: "Zhytomyr",
      area_code: "ZTR",
      city_name: "Zhytomyr",
      country_code: "UA"
  },
  {
      id: "ZTS",
      name: "SPB",
      area_code: "ZTS",
      city_name: "Tahsis",
      country_code: "CA"
  },
  {
      id: "ZTU",
      name: "Zaqatala",
      area_code: "ZTU",
      city_name: "Zaqatala",
      country_code: "AZ"
  },
  {
      id: "ZUC",
      name: "Municipal",
      area_code: "ZUC",
      city_name: "Ignace",
      country_code: "CA"
  },
  {
      id: "ZUD",
      name: "Pupelde",
      area_code: "ZUD",
      city_name: "Ancud",
      country_code: "CL"
  },
  {
      id: "ZUL",
      name: "Zilfi",
      area_code: "ZUL",
      city_name: "Zilfi",
      country_code: "SA"
  },
  {
      id: "ZVA",
      name: "Miandrivazo",
      area_code: "ZVA",
      city_name: "Miandrivazo",
      country_code: "MG"
  },
  {
      id: "ZVG",
      name: "Springvale",
      area_code: "ZVG",
      city_name: "Springvale",
      country_code: "AU"
  },
  {
      id: "ZWA",
      name: "Andapa",
      area_code: "ZWA",
      city_name: "Andapa",
      country_code: "MG"
  },
  {
      id: "ZZO",
      name: "Zonalnoye",
      area_code: "ZZO",
      city_name: "Zonalnoye",
      country_code: "RU"
  },
  {
      id: "ZZU",
      name: "Mzuzu",
      area_code: "ZZU",
      city_name: "Mzuzu",
      country_code: "MW"
  },
  {
      id: "MNN",
      name: "Municipal Airport",
      area_code: "MNN",
      city_name: "Marion",
      country_code: "US"
  },
  {
      id: "NQZ",
      name: "Nursultan Nazarbayev International Airport",
      area_code: "NQZ",
      city_name: "Nur-Sultan",
      country_code: "KZ"
  },
  {
      id: "GSV",
      name: "Gagarin International Airport",
      area_code: "RTW",
      city_name: "Saratov",
      country_code: "RU"
  },
  {
      id: "GBI",
      name: "Kalaburagi Airport",
      area_code: "GBI",
      city_name: "Guibarga",
      country_code: "IN"
  },
  {
      id: "YLX",
      name: "Yulin Fumian Airport",
      area_code: "YLX",
      city_name: "Yulin",
      country_code: "CN"
  },
  {
      id: "DBR",
      name: "Darbhanga Airport",
      area_code: "DBR",
      city_name: "Bihar",
      country_code: "IN"
  },
  {
      id: "TFU",
      name: "Tianfu International",
      area_code: "CTU",
      city_name: "Chengdu",
      country_code: "CN"
  },
  {
      id: "GZG",
      name: "Garze Gesar Airport",
      area_code: "GZG",
      city_name: "Ganzi",
      country_code: "CN"
  },
  {
      id: "HSA",
      name: "Turkistan International Airport",
      area_code: "HSA",
      city_name: "Turkistan",
      country_code: "KZ"
  },
  {
      id: "GNJ",
      name: "Ganja International Airport",
      area_code: "KVD",
      city_name: "Ganja",
      country_code: "AZ"
  },
  {
      id: "DGH",
      name: "Deoghar Airport",
      area_code: "DGH",
      city_name: "Deoghar",
      country_code: "IN"
  },
  {
      id: "KJB",
      name: "Kurnool Airport",
      area_code: "KJB",
      city_name: "Kurnool",
      country_code: "IN"
  },
  {
      id: "JLG",
      name: "Jalgaon Airport",
      area_code: "JLG",
      city_name: "Jalgaon",
      country_code: "IN"
  },
  {
      id: "KBK",
      name: "Kushinagar International Airport",
      area_code: "KBK",
      city_name: "Kushinagar",
      country_code: "IN"
  },
  {
      id: "RZV",
      name: "Rize Artvin Airport",
      area_code: "RZV",
      city_name: "Rize",
      country_code: "TR"
  },
  {
      id: "DRP",
      name: "Bicol International Airport",
      area_code: "DRP",
      city_name: "Daraga",
      country_code: "PH"
  },
  {
      id: "GOX",
      name: "Mopa Airport",
      area_code: "GOI",
      city_name: "Goa",
      country_code: "IN"
  },
  {
      id: "NMF",
      name: "Maafaru International Airport",
      area_code: "NMF",
      city_name: "Maafaru",
      country_code: "MV"
  },
  {
      id: "NUM",
      name: "Neom Bay Airport",
      area_code: "NUM",
      city_name: "Neom",
      country_code: "SA"
  },
  {
      id: "EAP",
      name: "EuroAirport Basel Mulhouse Freiburg",
      area_code: "EAP",
      city_name: "Basel/Mulhouse",
      country_code: "CH"
  },
  {
      id: "SAI",
      name: "Siem Reap Angkor",
      area_code: "REP",
      city_name: "Siem Reap",
      country_code: "KH"
  },
  {
      id: "HSR",
      name: "Rajkot Greenfield International Airport",
      area_code: "HSR",
      city_name: "Hirasar",
      country_code: "IN"
  },
  {
      id: "WHA",
      name: "Wuhu Xuanzhou Airport",
      area_code: "WHU",
      city_name: "Wuhu",
      country_code: "CN"
  },
  {
      id: "RSI",
      name: "Red Sea International Airport",
      area_code: "RSI",
      city_name: "Hanak",
      country_code: "SV"
  },
  {
      id: "AYJ",
      name: "Ayodhya International Airport",
      area_code: "AYJ",
      city_name: "Ayodhya",
      country_code: "IN"
  },
  {
      id: "KVO",
      name: "Morava Airport",
      area_code: "KVO",
      city_name: "Kraljevo",
      country_code: "RS"
  },
  {
      id: "RMO",
      name: "Chisinau International Airport",
      area_code: "RMO",
      city_name: "Chisinau",
      country_code: "MD"
  },
  {
      id: "TQO",
      name: "Felipe Carrillo Puerto",
      area_code: "TUY",
      city_name: "Tulum",
      country_code: "MX"
  }
]

