import { isMobile } from "react-device-detect";
import Banner from "../components/Banner";
import Copyrights from "../components/Copyrights";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Styles from "../components/Styles";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
// import Video from "../../src/assets/videos/toutorial.mp4"
export const AboutUsPage = () => {
  const translationContext = useTranslation();
  return (
    <div>
      <Styles />
      <Header
        translation={translationContext.t}
        translationContext={translationContext}
      />
      <Banner showSearchBar={false} minHeight={isMobile ? "120px" : "300px"} />
      <AboutUsPageContent />
      <Footer />
      <Copyrights />
    </div>
  );
};

const AboutUsPageContent = () => {

  return (
    <Fragment>
      <section
        className="content"
        style={{ padding: isMobile ? 0 : "95px 0px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-10 mt-lg-0 mt-md-0 mt-4 valuetabs col-md-8 col-12 whitebox pt-2" >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  minHeight: "300px",
                  justifyContent: "center",
                }}
              >
                <p>
                  At Fluturolire.com, we pride ourselves on being more than just
                  an online travel agency we're your dedicated travel
                  companion, ready to elevate your journey from start to finish.
                  With an extensive network encompassing 3,700 diverse
                  destinations and partnerships with over 750 reputable
                  airlines, we offer a world of possibilities at your
                  fingertips.
                </p>
                <p>
                  Our user-friendly platform empowers you to curate your travel
                  experience with ease. Whether you seek the thrill of exploring
                  hidden gems, the allure of cultural immersion, or the
                  convenience of seamless business travel, we've got you
                  covered. Navigate through our comprehensive selection of
                  flights, accommodations, and travel packages to tailor your
                  itinerary to perfection.
                </p>
                <p>
                  Behind the scenes, our team works tirelessly to ensure that
                  your travel dreams materialize effortlessly. We prioritize
                  transparency, offering detailed information about
                  destinations, airlines, and accommodation options. Our
                  commitment to customer satisfaction means you can trust us to
                  provide reliable and up-to-date information, enabling you to
                  make informed decisions.
                </p>
                <p>
                  Embark on a journey with Fluturolire.com, where your travel
                  aspirations meet unparalleled convenience. Our platform is
                  more than a booking tool, it's a gateway to exploration,
                  adventure, and unforgettable moments. Join us as we redefine
                  the way you experience the world, one destination at a time.
                </p>  
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default AboutUsPage;
