import Banner from "../../../components/Banner";
import Styles from "../../../components/Styles";
import Popular from "../../../components/Popular";
import Copyrights from "../../../components/Copyrights";
import Footer from "../../../components/Footer";
import { useTranslation } from "react-i18next";
import HotelHeader from "../../../components/hotel/Header";
import HotelBanner from "../../../components/hotel/Banner";
import Header from "../../../components/Header";

const Hotel = () => {
  const translationContext = useTranslation();
  return (
    <div>
      <Styles />
      <Header
        translation={translationContext.t}
        translationContext={translationContext}
      />
      <HotelBanner translation={translationContext.t} />
      {/* <Popular translation={translationContext.t} /> */}
      <Footer />
      <Copyrights />
    </div>
  );
};

export default Hotel;
