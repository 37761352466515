import { Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { GET } from "../../utils/api";
import { GET_LOCATIONS, ITRAVEL_KEY_API } from "../../utils/constants";
import { TRANSLATED_LOCATIONS } from "../../utils/enums";
import { useTranslation } from "react-i18next";
import { airports } from "../../utils/enums"; 
import { removeDuplicatesFromArray } from "../../common/methods";
export const LookupItems = ({
  id,
  searchValue,
  items = [],
  onClickHandler,
  openClass,
  inputFocus,
}) => {
  const translationContext = useTranslation();
  const currentLanguage = translationContext?.i18n?.language;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false); 

 async function searchAirports(param) { 
  let search=[]= airports.filter((el)=>
    el.id.toLocaleLowerCase().includes(param.toLocaleLowerCase())||
    el.id.toLocaleLowerCase().includes(param.toLocaleLowerCase().substring(0,3)) 
  
) 
search=search.concat(airports.filter((el)=> 
el.name.toLocaleLowerCase().includes(param.toLocaleLowerCase())||
el.city_name.toLocaleLowerCase().includes(param.toLocaleLowerCase())
))

  return removeDuplicatesFromArray(search)
  }

  useEffect(() => {
    setLoading(true);
    const getData = setTimeout(async () => {
      if (searchValue) {
        const currentSearch =
        currentLanguage === "al"
        ? TRANSLATED_LOCATIONS[searchValue?.toLowerCase()] || searchValue
        : searchValue;  
        const response = await searchAirports(currentSearch)

        setData(response);
      } else {
        setData([]);
      }
      setLoading(false);
    }, 200);

    return () => clearTimeout(getData);
  }, [currentLanguage, searchValue]);

  return (
    <div
      className={"destination-dropdown"}
      key={id}
      style={{
        display: "block",
      }}
    >
      {data?.length && inputFocus ? (
        <ul className="list-group">
          {data?.length ? (
            data?.map((item) => (
              <li key={item?.id}>
                <Button
                  onClick={() => {
                    setData([]);
                    onClickHandler(item);
                  }}
                  className="list-group-item d-flex justify-content-between align-items-center"
                  style={{
                    // fontSize: "15px"t
                  }}
                >
                  {item?.city_name +' - '+ item?.name}
                  <span className="badge badge-primary badge-pill">
                    {item?.city_name === "city" ? "All airports" : item?.id}
                  </span>
                </Button>
              </li>
            ))
          ) : (
            <Typography sx={{ padding: "20px" }}>
              {loading
                ? "Loading the results..."
                : "Start typing to get results"}
            </Typography>
          )}
        </ul>
      ) : (
        <div>
          {" "}
          <ul className="list-group">{loading && "Loading the results..."}</ul>
        </div>
      )}
    </div>
  );
};
