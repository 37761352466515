import { Close } from "@mui/icons-material";
import { BaggageSection } from "../../pages/flights/booking/BaggageSection";
import { IconButton, MenuItem, Select } from "@mui/material";
import { FieldFeedback, FieldFeedbacks } from "react-form-with-constraints";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";

export const AddPassenger = ({
  id,
  record,
  passengerNo,
  showForm = true,
  updateTotalPrice,
  handBagDimensions = [],
  holdBagDimensions = [],
  holdBagsToPay = [],
  countries = [],
  removePassengerFromList,
  onChangePassengerType,
  onChangeNoCheckedBaggage,
  passengerTypeValue,
  noCheckedBaggage = false,
  onChangeForm,
  setAddedBaggage,
  addedBaggage = [],
  removeWhenNoCheckedBaggage,
  currency,
  formRef,
  flight
  // currentBnum,
  // setBnum
}) => {
  const translation = useTranslation();
  return (
    <>
      {!!showForm && (
        <div class="row add-passenger ml-auto mr-auto w-100">
          <div class="col-lg-12 mt-5 mb-4 col-md-12 col-12 flex-row justify-content-space-between align-items-center">
            <p className="flex-column gap-10">
              {translation.t("passenger")} {passengerNo}
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={passengerTypeValue}
                onChange={(event) => {
                  onChangePassengerType(event.target.value, id, addedBaggage);
                  if (event.target.value === "infant") {
                    removeWhenNoCheckedBaggage(id, addedBaggage);
                  }
                }}
                sx={{
                  color: "#003663",
                }}
              >
                <MenuItem value={"adult"}>
                  {translation.t("adults")} (
                  {translation.t("12_years_and_older")})
                </MenuItem>
                <MenuItem value={"child"}>
                  {translation.t("children")} ({translation.t("2_to_11_years")})
                </MenuItem>
                <MenuItem value={"infant"}>
                  {translation.t("babies")} (
                  {translation.t("under_2_years_old")})
                </MenuItem>
              </Select>
            </p>
            <IconButton
              aria-label="delete"
              size="medium"
              onClick={() => removePassengerFromList(id, addedBaggage)}
              style={{
                width: "35px",
                height: "40px",
              }}
            >
              <Close />
            </IconButton>
          </div>
          <div class="col-lg-12 mb-4 col-md-12 col-12" >
            <div class="custom-control custom-radio tick normal d-inline-block w-auto">
              <input
                type="radio"
                id={`customRadio_${id}_mr`}
                value="mr"
                checked={record?.index === id && record?.form?.title === "mr"}
                defaultChecked="mr"
                name={`customRadio_${id}_mr`}
                class="custom-control-input form-control"
                onChange={(event) => {
                  onChangeForm(event.target.value, "title", id);
                }}
              />
              <label
                class="custom-control-label d-flex align-items-center"
                for={`customRadio_${id}_mr`}
              >
                {translation.t("mr")}
              </label>
            </div>
            <div class="custom-control custom-radio tick normal d-inline-block w-auto ml-3">
              <input
                type="radio"
                id={`customRadio_${id}_ms`}
                name={`customRadio_${id}_ms`}
                class="custom-control-input"
                value="ms"
                checked={record?.index === id && record?.form?.title === "ms"}
                onChange={(event) =>
                  onChangeForm(event.target.value, "title", id)
                }
              />
              <label
                class="custom-control-label d-flex align-items-center"
                for={`customRadio_${id}_ms`}
              >
                {translation.t("ms")}
              </label>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-12">
          {!isMobile && <label>{translation.t("first_name")} </label>}
            <input
              type="text"
              class="form-control"
              style={{
                marginBottom: isMobile && "0"
              }}
              pattern="[A-Za-z]+"
              placeholder={isMobile && translation.t("first_name")}
              name={`first_name_${id}`}
              required
              onChange={(event) => {
                onChangeForm(event.target.value, "name", id);
                formRef?.validateFields(event.target);
              }}
            />
            <FieldFeedbacks for={`first_name_${id}`}>
              <FieldFeedback when="*" >
                Please enter your first name
              </FieldFeedback>
            </FieldFeedbacks>
          </div>
          <div class="col-lg-6 col-md-6 col-12">
          {!isMobile && <label>{translation.t("last_name")}</label>}
            <input
              type="text"
              style={{
                marginBottom: isMobile && "0"
              }}
              class="form-control"
              placeholder={isMobile && translation.t("last_name")}
              name={`surname_${id}`}
              pattern="[A-Za-z]+"
              required
              onChange={(event) => {
                onChangeForm(event.target.value, "surname", id);
                formRef?.validateFields(event.target);
              }}
            />
            <FieldFeedbacks for={`surname_${id}`}>
              <FieldFeedback when="*" >
              Please enter your last name
              </FieldFeedback>
            </FieldFeedbacks>
          </div>
          {/* <div class="col-lg-12 col-md-12 col-12">
          
          </div> */}
          {!isMobile &&  <label>{translation.t("date_of_birth")}</label>}
          <div class="bg-white-box">
            <div class="row justify-content-between">
              <div class="col-lg-3 col-md-3 col-12">
                <input
                  type="number"
                  class="form-control datepicker"
                  placeholder={isMobile ? translation.t("DDL") : translation.t("DD")}
                  name={`day_of_birth_${id}`}
                  style={{
                    marginBottom: isMobile && "0"
                  }}
                  required
                  min={1}
                  onChange={(event) => {
                    onChangeForm(event.target.value, "dayOfBirth", id);
                    formRef?.validateFields(event.target);
                  }}
                />
                <FieldFeedbacks for={`day_of_birth_${id}`}>
                  <FieldFeedback when="*" />
                </FieldFeedbacks>
              </div>
              <div class="col-lg-5 col-md-5 col-12">
                <select
                  class="form-control"
                  onChange={(event) =>
                    onChangeForm(event.target.value, "monthOfBirth", id)
                  }
                  required
                  style={{
                    marginBottom: isMobile && "0"
                  }}
                >
                  <option value="January">{translation?.i18n?.language === "al" ? translation.t("jan") : "January"}</option>
                  <option value="February">{translation?.i18n?.language === "al" ? translation.t("feb") : "February"}</option>
                  <option value="March">{translation?.i18n?.language === "al" ? translation.t("mar") : "March"}</option>
                  <option value={"April"}>{translation?.i18n?.language === "al" ? translation.t("apr") : "April"}</option>
                  <option value={"May"}>{translation?.i18n?.language === "al"? translation.t("may") : "May"}</option>
                  <option value={"June"}>{translation?.i18n?.language === "al"? translation.t("jun") : "June"}</option>
                  <option value={"July"}>{translation?.i18n?.language === "al"? translation.t("jul") : "July"}</option>
                  <option value={"August"}>{translation?.i18n?.language === "al" ? translation.t("aug") : "August"}</option>
                  <option value={"September"}>{translation?.i18n?.language === "al" ? translation.t("sep") : "September"}</option>
                  <option value={"October"}>{translation?.i18n?.language === "al" ? translation.t("oct") : "October"}</option>
                  <option value={"November"}>{translation?.i18n?.language === "al" ? translation.t("nov") : "November"}</option>
                  <option value={"December"}>{translation?.i18n?.language  === "al"? translation.t("dec") : "December"}</option>
                </select>
              </div>
              <div class="col-lg-3 col-md-3 col-12">
                <input
                  type="number"
                  class="form-control"
                  placeholder={translation.t("year")}
                  name={`year_of_birth_${id}`}
                  min={1}
                  required
                  onChange={(event) => {
                    onChangeForm(event.target.value, "yearOfBirth", id);
                    formRef?.validateFields(event.target);
                  }}
                />
                <FieldFeedbacks for={`year_of_birth_${id}`}>
                  <FieldFeedback when="*" />
                </FieldFeedbacks>
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-12">
            {!isMobile && <label>{translation.t("nationality")}</label>}
            <select
              class="form-control"
              onChange={(event) => {
                const code = countries?.find(
                  (c) => c.name === event.target.value
                )?.code;
                onChangeForm(code, "nationality", id);
              }}
              style={{
                marginBottom: isMobile && "0"
              }}
            >
              {countries?.map((country) => (
                <option selected={country?.name === "Kosovo"} value={country?.name}>
                  {country?.name === "Kosovo"
                    ? "Kosovar"
                    : country?.name === "Albania"
                    ? "Shqiptar"
                    : country?.name}
                </option>
              ))}
            </select>
          </div>
          {/* <div class="col-lg-6 col-md-6 col-12">
            <label>Gender</label>
            <select
              defaultValue="M"
              class="form-control"
              onChange={(event) =>
                onChangeForm(event.target.value, "gender", id)
              }
            >
              <option value="M">Male</option>
              <option value="F">Female</option>
            </select>
          </div> */}
          <div class="col-lg-12 col-md-12 col-12">
            {!isMobile && <label>{translation.t("passaport_id_or_number")}</label>}
            <input
              type="text"
              class="form-control"
              placeholder={isMobile && translation.t("passaport_id_or_number")}
              name={`cardno_${id}`}
              style={{
                marginBottom: isMobile && "0"
              }}
              required
              onChange={(event) => {
                onChangeForm(event.target.value, "cardno", id);
                formRef?.validateFields(event.target);
              }}
            />
            <FieldFeedbacks for={`cardno_${id}`}>
              <FieldFeedback when="*" />
            </FieldFeedbacks>
          </div>
          {!isMobile && <label>{translation.t("passaport_id_or_number_expiry_date")}</label>}
          <div class="bg-white-box">
            <div class="row justify-content-between">
              <div class="col-lg-3 col-md-3 col-12">
                <input
                  type="number"
                  class="form-control datepicker"
                  placeholder={isMobile ? translation.t("day_of_expiry") : translation.t("DD")}
                  name={`day_of_expiry_${id}`}
                  required
                  min={1}
                  onChange={(event) => {
                    onChangeForm(event.target.value, "dayOfExpiry", id);
                    formRef?.validateFields(event.target);
                  }}
                  style={{
                    marginBottom: isMobile && "0"
                  }}
                />
                <FieldFeedbacks for={`day_of_expiry_${id}`}>
                  <FieldFeedback when="*" />
                </FieldFeedbacks>
              </div>
              <div class="col-lg-5 col-md-5 col-12">
                <select
                  class="form-control"
                  onChange={(event) =>
                    onChangeForm(event.target.value, "monthOfExpiry", id)
                  }
                  required
                  style={{
                    marginBottom: isMobile && "0"
                  }}
                >
                 <option value="January">{translation?.i18n?.language === "al" ? translation.t("jan") : "January"}</option>
                  <option value="February">{translation?.i18n?.language === "al" ? translation.t("feb") : "February"}</option>
                  <option value="March">{translation?.i18n?.language === "al" ? translation.t("mar") : "March"}</option>
                  <option value={"April"}>{translation?.i18n?.language === "al" ? translation.t("apr") : "April"}</option>
                  <option value={"May"}>{translation?.i18n?.language === "al"? translation.t("may") : "May"}</option>
                  <option value={"June"}>{translation?.i18n?.language === "al"? translation.t("jun") : "June"}</option>
                  <option value={"July"}>{translation?.i18n?.language === "al"? translation.t("jul") : "July"}</option>
                  <option value={"August"}>{translation?.i18n?.language === "al" ? translation.t("aug") : "August"}</option>
                  <option value={"September"}>{translation?.i18n?.language === "al" ? translation.t("sep") : "September"}</option>
                  <option value={"October"}>{translation?.i18n?.language === "al" ? translation.t("oct") : "October"}</option>
                  <option value={"November"}>{translation?.i18n?.language === "al" ? translation.t("nov") : "November"}</option>
                  <option value={"December"}>{translation?.i18n?.language  === "al"? translation.t("dec") : "December"}</option>
                </select>
              </div>
              <div class="col-lg-3 col-md-3 col-12">
                <input
                  type="number"
                  class="form-control"
                  placeholder={translation.t("year")}
                  name={`year_of_expiry_${id}`}
                  required
                  min={new Date().getFullYear()}
                  onChange={(event) => {
                    onChangeForm(event.target.value, "yearOfExpiry", id);
                    formRef?.validateFields(event.target);
                  }}
                />
                <FieldFeedbacks for={`year_of_expiry_${id}`}>
                  <FieldFeedback when="*" />
                </FieldFeedbacks>
              </div>
            </div>
          </div>
        </div>
      )}
      <BaggageSection
        id={id}
        key={id}
        handBagDimensions={handBagDimensions}
        holdBagDimensions={holdBagDimensions}
        holdBagsToPay={holdBagsToPay}
        setPrice={updateTotalPrice}
        hideBagsToPayOption={noCheckedBaggage}
        setAddedBaggage={setAddedBaggage}
        addedBaggage={addedBaggage}
        currency={currency}
        flight={flight}
        // currentBnum={currentBnum}
        // setBnum={setBnum}
      />
      {!record?.isInfant && (
        <div
          class="custom-control custom-radio custom-control tick col-lg-12 mt-1 col-md-12 col-12"
          style={{
            borderBottom: "none",
          }}
        >
          <div className="col-lg-12 mt-1 col-md-12 col-12">
            <input
              type="checkbox"
              id={`nocheckedbaggage_${id}`}
              name={`nocheckedbaggage_${id}`}
              class="custom-control-input"
              onChange={(event) => {
                onChangeNoCheckedBaggage(event.target.checked, id);
                if (removeWhenNoCheckedBaggage) {
                  removeWhenNoCheckedBaggage(id, addedBaggage);
                }
              }}
              checked={noCheckedBaggage}
            />
            {/* <label
              class="custom-control-label"
              for={`nocheckedbaggage_${id}`}
              style={{
                fontSize: "17px",
              }}
            >
              No Checked Baggage
            </label> */}
          </div>
        </div>
      )}
    </>
  );
};
