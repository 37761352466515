import { getDimensionsAndWeight } from "../../../common/methods";
import { AddBaggageRow } from "./AddBaggageRow";
import { useStaticImages } from "../../../hooks/useStaticImages";
import { useTranslation } from "react-i18next";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useStoreFly } from "../../../store";
import { Button } from "@mui/material";
export const BaggageSection = ({
  id,
  handBagDimensions = [],
  holdBagDimensions = [],
  holdBagsToPay = [],
  total,
  disableAddBaggage,
  setDisableAddBaggage,
  hideBagsToPayOption = false,
  addedBaggage = [],
  setAddedBaggage,
  currency,
  currentBnum,
  setBnum,
  flight,
  baggageInfo
}) => {
  const translation = useTranslation();
  const { luggage, suitcase ,disabledLuggage} = useStaticImages();
  const setAncillary = useStoreFly((state) => state.updateAncillary)
  const getAncillary = useStoreFly((state) => state.ancillary)
  const setBaggInfo = useStoreFly((state) => state.updateBaggInfo)
  const getBaggInfo = useStoreFly((state) => state.baggInfo)
  if (!baggageInfo && getBaggInfo) {
    baggageInfo = getBaggInfo
  }
  else {
    setBaggInfo(baggageInfo)
  }
  setBaggInfo(baggageInfo)
  function sortArr(arr) {
    const uniqueCombinationSet = new Set();

    const filteredArray = arr.filter(item => {
      const combination = `${item.id}-${item.segmentId}`;
      if (!uniqueCombinationSet.has(combination)) {
        uniqueCombinationSet.add(combination);
        return true;
      }
      return false;
    });
    return filteredArray
  }

  function handleChangeOnRadioButtons(id, segmentId, ancillary) {
    let pastArr = []

    pastArr = (getAncillary)
    let obj = { id: id, segmentId: segmentId, ancillary: ancillary }
    let tempArr = []
    if (pastArr && pastArr.length > 0) {
      let filteredArray = sortArr(pastArr)
      filteredArray = filteredArray.filter(item => !(item.id === id && item.segmentId === segmentId));
      filteredArray.push(obj)

      tempArr = filteredArray
      // tempArr.push(obj)
    }
    else {
      tempArr.push(obj)
    } 
    setAncillary(tempArr);
  }

  function returnImageCondition(string = '') {
    let finString = '';
    for (let i = 0; i <= string.length; i++) {

      if (!isNaN(Number(string.charAt(i)))) {
        finString += '' + string.charAt(i)
      }

    }
    return Number(finString) <= 20
  }
  function returnBaggageInfoLength(arr) {
    if (arr) {
      return arr.length > 0
    }
    else {
      return false
    }
  }
  return (

    <section>
      {baggageInfo && (

        <>
          <br />
          <p class="fw-bold  ml-3">
            Add-Ons
          </p>
          <div>

            {baggageInfo?.journey_0?.segmentIds?.map((segmentsJourn0, segIndex) => (
              <>
                {returnBaggageInfoLength(segmentsJourn0?.baggageInfo?.baggage) && (
                  <>
                    <div class="col-lg-12 col-md-12 col-12">

                      {/* <label>{translation.t("baggage")}</label> */}
                    </div><div class="col-lg-12 mt-1 col-md-12 col-12">
                      <div class="col-lg-12 whitebox baggageInfo col-md-12 col-12">
                        <p class="m-0 pt-2 padding10px"><span>{translation.t("add_bag")} -</span> <span class="okPaymentFont">{translation.t("best_price_buy_now")}</span></p>
                        <br />
                        <table class="table">
                          <tbody>
                            {/* <img srcSet={suitcase} alt="suitcase" /> {translation.t("personal_baggage")} */}



                            <FormControl>

                              <FormLabel id="demo-radio-buttons-group-label" class="mt-2">
                                {
                                  <span>

                                    {(baggageInfo?.journey_0.segmentIds[segIndex].isReturn ? 'Return route' : 'Departure route')}
                                    &nbsp;&nbsp;&nbsp;
                                    {baggageInfo?.journey_0.segmentIds[segIndex].departure + ' - ' + baggageInfo?.journey_0.segmentIds[segIndex].arrival}

                                  </span>

                                }
                              </FormLabel>
                              <RadioGroup

                                aria-labelledby="demo-radio-buttons-group-label"
                                name="radio-buttons-group"

                              >
                                {segmentsJourn0?.baggageInfo?.baggage?.map((handBag) => (
                                  <FormControlLabel


                                    onChange={(event) => {
                                      handleChangeOnRadioButtons(id, segmentsJourn0.segmentId, handBag)
                                      // var a = getAncillary;
                                      // if (a) { 


                                      //   a = a.filter((el) =>
                                      //      ( el.segmentId!==segmentsJourn0.segmentId&&id===el.id)||(el.segmentId===segmentsJourn0.segmentId&&id!==el.id)
                                      // )
                                      // } else {
                                      //   a = [];
                                      // }
                                      // a = a.concat({ id: id, ancillary: handBag ,segmentId:segmentsJourn0.segmentId});

                                      // console.log('AJA NFUND', a)

                                    }}
                                    defaultValue={getAncillary && getAncillary.length > 0 ?
                                      (getAncillary[0]?.ancillary.ancillaryKey) || getAncillary[1]?.ancillary.ancillaryKey :
                                      ''}
                                    checked={getAncillary && getAncillary.length <= 0 ? false : handBag.ancillaryKey == getAncillary[0]?.ancillary.ancillaryKey || handBag.ancillaryKey == getAncillary[1]?.ancillary.ancillaryKey}
                                    value={handBag.ancillaryKey} control={<Radio />} label={<span class="baggRadioCostum">
                                      {returnImageCondition(handBag.baggageWeight) ? (
                                        <img srcSet={suitcase} alt="suitcase" />
                                      ) : (
                                        <img srcSet={luggage} alt="luggage" />
                                      )}
                                      <div class="fw-bolder">{handBag.baggageWeight.toLowerCase()}</div>
                                      <div class="fw-bolder">{handBag.description ? handBag.description : 'Any dimensions up to ' + handBag.baggageWeight.toLowerCase()}</div>
                                      <div class="fw-bolder">{(Number(handBag.baggagePrice + (handBag.baggagePrice * 10) / 100).toFixed(2))}&nbsp;€</div>
                                      {/* <div class="fw-bolder">{handBag.baggageCurrency}</div> */}
                                    </span>} />
                                ))}
                              </RadioGroup>
                            </FormControl>
                          </tbody>
                        </table>


                      </div>
                    </div>
                  </>
                )}
              </>
            ))}
          </div>
          <div class="mt-3">

            {baggageInfo?.journey_1?.segmentIds?.map((segmentsJourn1, segIndex) => (
              <>
                {returnBaggageInfoLength(segmentsJourn1?.baggageInfo?.baggage) && (
                  <>

                    <div class="col-lg-12 col-md-12 col-12">
                      {/* <label>{translation.t("baggage")}</label> */}
                    </div><div class="col-lg-12 mt-1 col-md-12 col-12">
                      <div class="col-lg-12 whitebox baggageInfo col-md-12 col-12">
                        <p class="m-0 pt-2 padding10px"><span>{translation.t("add_bag")} -</span> <span class="okPaymentFont">{translation.t("best_price_buy_now")}</span></p>
                        <br />
                        <table class="table">
                          <tbody>
                            {/* <img srcSet={suitcase} alt="suitcase" /> {translation.t("personal_baggage")} */}



                            <FormControl>
                              <FormLabel id="demo-radio-buttons-group-label" class="mt-2">
                                {
                                  <span>

                                    {(baggageInfo?.journey_1.segmentIds[segIndex].isReturn ? 'Return route' : 'Departure route')}
                                    &nbsp;&nbsp;&nbsp;
                                    {baggageInfo?.journey_1.segmentIds[segIndex].departure + ' - ' + baggageInfo?.journey_1.segmentIds[segIndex].arrival}

                                  </span>

                                }
                              </FormLabel>
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                name="radio-buttons-group"

                              >
                                {segmentsJourn1?.baggageInfo?.baggage?.map((handBag) => (
                                  <FormControlLabel


                                    onChange={(event) => {
                                      handleChangeOnRadioButtons(id, segmentsJourn1.segmentId, handBag)
                                    }}
                                    checked={getAncillary && getAncillary.length <= 0 ? false : handBag.ancillaryKey == getAncillary[0]?.ancillary.ancillaryKey || handBag.ancillaryKey == getAncillary[1]?.ancillary.ancillaryKey}
                                    defaultValue={getAncillary && getAncillary.length > 0 ?
                                      (getAncillary[0]?.ancillary.ancillaryKey) || getAncillary[1]?.ancillary.ancillaryKey :
                                      ''}
                                    value={handBag.ancillaryKey} control={<Radio />} label={<span class="baggRadioCostum">
                                      {returnImageCondition(handBag.baggageWeight) ? (
                                        <img srcSet={suitcase} alt="suitcase" />
                                      ) : (
                                        <img srcSet={luggage} alt="luggage" />
                                      )}
                                      <div class="fw-bolder">{handBag.baggageWeight.toLowerCase()}</div>
                                      <div class="fw-bolder">{handBag.description ? handBag.description : 'Any dimensions up to ' + handBag.baggageWeight.toLowerCase()}</div>
                                      <div class="fw-bolder">{(Number(handBag.baggagePrice + (handBag.baggagePrice * 10) / 100).toFixed(2))}&nbsp;€</div>
                                      {/* <div class="fw-bolder">{handBag.baggageCurrency}</div> */}
                                    </span>} />
                                ))}
                              </RadioGroup>
                            </FormControl>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                )}
              </>
            ))}
          </div>
          <div class="d-flex m-3 justify-content-end">
          <div>
          <button 
            id="buttonPayment" 
            class="btn default-yellow-btn"
            style={
              true
                ? { height:'45px', fontWeight: "bold", fontSize: "15" }
                : {
                  padding: "10px 40px",
                  fontWeight: "bold",
                  fontSize: "15",
                  maxWidth: "265px",
                }
            }
            onClick={async (event) =>{
              event.preventDefault()
              setAncillary([])
            } }
          >
              <img srcSet={disabledLuggage} alt="luggage" />
            {translation.t("no_baggage")}
             
          </button>
          </div> 
          </div>
        </>
      )}
    </section>
  );
};
