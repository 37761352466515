import { isMobile } from "react-device-detect";
import Banner from "../components/Banner";
import Copyrights from "../components/Copyrights";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Styles from "../components/Styles";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Al_horizontal from '../../src/assets/videos/Al_horizontal.mp4'
import En_horizontal from '../../src/assets/videos/En_horizontal.mp4'
import Al_vertical from '../../src/assets/videos/Al_vertical.mp4'
import En_vertical from '../../src/assets/videos/En_vertical.mp4'
export const HowToPage = () => {
  const translationContext = useTranslation(); 
  return (
    <div>
      <Styles />
      <Header
        translation={translationContext.t}
        translationContext={translationContext}
      />
      <Banner showSearchBar={false} minHeight={isMobile ? "120px" : "300px"} />
      <HowToPageContent lang={translationContext?.i18n?.language}/>
      <Footer />
      <Copyrights />
    </div>
  );
};

const HowToPageContent = (lang) => {
  
  function returnContent(){
    if(lang.lang=='en'&&!isMobile){
      console.log('en desk', lang)
     return <video class="col-auto"    controls  >
     <source src={En_horizontal} type="video/mp4"/> 
     </video>
    }
    if(lang.lang=='en'&&isMobile){
      console.log('en mob', lang)
     return <video class="col-auto"    controls  >
     <source src={En_vertical} type="video/mp4"/> 
     </video>
    }
    if(lang.lang=='al'&&!isMobile){
      console.log('al desk', lang)
     return <video class="col-auto"    controls  >
     <source src={Al_horizontal} type="video/mp4"/> 
     </video>
    }
    if(lang.lang=='al'&&isMobile){
      console.log('al mob', lang)
     return <video class="col-auto"    controls  >
     <source src={Al_vertical} type="video/mp4"/> 
     </video>
    }
  }

  let a =returnContent()
  return (
    <Fragment>
      <section
        className="content"
        style={{ padding: isMobile ? 0 : "95px 0px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-10 mt-lg-0 mt-md-0 mt-4 valuetabs col-md-8 col-12 whitebox pt-2" >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  minHeight: "300px",
                  justifyContent: "center",
                }}
              > 
              <section>

                {a}
              </section>
               
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default HowToPage;
