const doFetch = async (url, options, apiKey) => {
  let payload = {
    ...options,
    headers: {
      "Content-type": "application/json",
    },
  };
  
  if (apiKey) {
    payload.headers.apikey = apiKey;
  }
  const response = await fetch(url, payload);
  return await response.json();
};

export const POST = async (url, options, apiKey) => {
  return await doFetch(
    url,
    {
      method: "POST",
      ...options,
      headers: {
        "Content-type": "application/json",
      },
    },
    apiKey
  );
};

export const GET = async (url, options, apiKey) => {
  let payload = {
    method: "GET",
    ...options,
    headers: {
      "content-type": "application/json",
      
    },
  };

  return await doFetch(url, payload, apiKey);
};
