import { useEffect } from "react";
import { useStoreFly } from "../store";
import { GET } from "../utils/api";
import { CITY_DETAILS_BASED_ON_GEOLOCATION } from "../utils/constants";

export const useGeolocation = () => {
  const setGeolocation = useStoreFly((state) => state.setGeolocation);

  useEffect(() => {
    const geo = async () => {
      const response = await navigator.permissions.query({
        name: "geolocation",
      });
      // if (response.state === "prompt") {
        await getLocation();
      // }

      // if (response.state === "granted") {
        const { coords } = await getLocation() || { coord: ""};
        const cityDetails = await GET(
          CITY_DETAILS_BASED_ON_GEOLOCATION(
            coords?.latitude,
            coords?.longitude
          ),
          {},
          ""
        );
        const cityName = cityDetails?.city;
        const code = cityDetails?.countryName;
        const isoCode = cityDetails.localityInfo.administrative[0]?.isoCode;
        // console.log({ coords, cityDetails });
        setGeolocation({
          latitude: coords?.latitude,
          longitude: coords?.longitude,
          city: code
            ? code
            : `${cityName?.toLowerCase()}_${isoCode?.toLowerCase()}`,
        });
      // }
      return response;
    };

  //   if (navigator.geolocation) {
      geo();
    // }
  }, [setGeolocation]);
};

let cachedLocation;
const getLocation = async () => {
  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  if (cachedLocation) {
    return Promise.resolve(cachedLocation);
  }

  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, options);

    setTimeout(() => {
      reject("Timeout exceeded");
    }, options.timeout);
  })
    .then((position) => {
      cachedLocation = position;
      return position;
    })
    .catch((error) => {
      console.error(error);
    });
};
