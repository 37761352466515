import { isMobile } from "react-device-detect";
import Banner from "../components/Banner";
import Copyrights from "../components/Copyrights";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Styles from "../components/Styles";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

export const VirtualInterliningPage = () => {
  const translationContext = useTranslation();
  return (
    <div>
      <Styles />
      <Header
        translation={translationContext.t}
        translationContext={translationContext}
      />
      <Banner showSearchBar={false} minHeight={isMobile ? "120px" : "300px"} />
      <VirtualInterliningContent />
      <Footer />
      <Copyrights />
    </div>
  );
};

const VirtualInterliningContent = () => {
  return (
    <Fragment>
      <section
        className="content"
        style={{ padding: isMobile ? 0 : "95px 0px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-10 mt-lg-0 mt-md-0 mt-4 valuetabs col-md-8 col-12 whitebox">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  minHeight: "300px",
                  justifyContent: "center",
                }}
              >
                <p>
                  Virtual Interlining (or VI) is an intricate term for a simple
                  idea &mdash; making planes, trains and buses connect, even
                  when the companies do not work together, and protecting you
                  from missed connections, delays or schedule changes.
                </p>
                <p>&nbsp;</p>
                <p>
                  Before a traveller had two choices. They could fly with a
                  regularly interlined legacy carrier, and pay a high price or
                  they could spend hours and hours searching every possible
                  airline, every connection for any reasonable price. It was
                  thought it couldn&rsquo;t be automated &mdash; the sums were
                  too complex and the travel industry too corporate to try
                  anything new.
                </p>
                <p>&nbsp;</p>
                  <p >
                    Infinite options at your fingertips.
                  </p>
                <div>
                  <p>
                    Suddenly the options become infinite, and the time it takes
                    and costs are significantly reduced.
                  </p>
                  <p>
                    The algorithm searches our own database, which is the
                    largest of its type. It is full of information provided by
                    the airlines we cooperate with, global distribution systems,
                    and more than 750 other data sources.
                  </p>
                </div>
                <p>
                  This is where our Virtual Interlining algorithm comes in. A
                  combination of talented developers and big data allowed us to
                  compute those sums. In six years, we have gone from a small
                  startup to one of the fastest-growing companies in the world
                  &mdash; all because we had this idea and made it work.
                </p>
                <p>
                  Virtual Interlining allows us to connect carriers to those
                  outside of their partner network, increasing your ability to
                  sell routes to passengers outside of your region. We started
                  by Virtually Interlining low-cost content, expanded this to
                  include full-service, and then to ground transport.&nbsp;
                </p>
                <p>&nbsp;</p>
                <p>Here's how it works:</p>
                <ol>
                  <li>
                    <p>
                      You book two or more separate flights with different
                      airlines to reach your destination.
                    </p>
                  </li>
                  <li>
                    <p>
                      You need to collect your baggage and clear customs, if
                      necessary, at each transit point and then recheck it for
                      your next flight.
                    </p>
                  </li>
                  <li>
                    <p>
                      You are responsible for ensuring you have enough time for
                      connections and that your baggage gets to the correct
                      airline for each segment of your journey.
                    </p>
                  </li>
                  <li>
                    <p>
                      Airlines are not obligated to assist with missed
                      connections or delays between different carriers since
                      they aren't formally cooperating.
                    </p>
                  </li>
                </ol>
                <p>
                  Now you have a better idea of what Virtual Interlining is, it
                  is time to start building your solutions. Have fun, experiment
                  and discover exactly what you can do.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default VirtualInterliningPage;
