import { useNavigate } from "react-router-dom";
import { useStaticImages } from "../../hooks/useStaticImages";

export const NoResultsFound = ({
  headerTile = "We,re sorry, but there is no availability for the selected flights",
  showRedirectButton = false,
  navigatePath = "/",
  customStyle = {},
  handleRedirectEvent
}) => {
  const { emptyIcon } = useStaticImages();
  const navigate = useNavigate();
  return (
    <div class="col-lg-12 col-md-8 col-12" style={customStyle}>
      <div class="col-lg-12 text-center whitebox smilemsg emptybox col-md-12 col-12">
        <div class="row justify-content-center">
          <div class="col-lg-10 col-md-12 col-12">
            <img srcSet={emptyIcon} alt="emptyIcon" />
            <h4 class="mt-4">{headerTile}</h4>
            <p class="mb-4">
              Don't give up on your travel plans just yet
              <br /> you can check for availability on other dates,
              <br /> or have a look at more of our great flights.
            </p>
          </div>
          {!!showRedirectButton && (
            <button
              style={{
                marginBottom: "30px",
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
              type="submit"
              onClick={() => {
                if(handleRedirectEvent) {
                  handleRedirectEvent();
                }
                navigate(navigatePath)}
              } 
              className="btn-submit default-yellow-btn"
            >
              Continue searching for other offers
              <img
                src={require("../../assets/img/icon-search.png")}
                alt="search"
                width={25}
                height={25}
              />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
