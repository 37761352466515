import Header from "../../../components/Header";
import Banner from "../../../components/Banner";
import Styles from "../../../components/Styles";
import Popular from "../../../components/Popular";
import Copyrights from "../../../components/Copyrights";
import Footer from "../../../components/Footer";
import { useTranslation } from 'react-i18next';
import { IsMobileOrBrowser } from "../../../hooks/isBrowserOrMobile";
import { useEffect } from "react";
// import { MobileHeader } from "../../../components/flight/MobileHeader";



const Home = () => {
  const translationContext = useTranslation();
  const isMobile = IsMobileOrBrowser();
  useEffect(() => {
    console.log('HINI NGUN');

    (function (w, d, s, l, i) {
      console.log('HINI NGUN 1 ');
      w[l] = w[l] || [];
      w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
      console.log('w', w)
      console.log('HINI NGUN 2');
      const f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l !== 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
      console.log('f', f)
      console.log('HINI NGUN 3');
    })(window, document, 'script', 'dataLayer', 'GTM-NDPX9NT');
  }, []);
  return (
    <div>
      <Styles />
      <Header translation={translationContext.t} translationContext={translationContext} />
      <Banner translation={translationContext.t} />
      <Popular translation={translationContext.t} style={{ paddingTop: isMobile ? 0 : "65px" }} />
      <Footer />
      <Copyrights />
      <div id="facebook-pixel-container">
      {/* Inject the script */}
      <script
        dangerouslySetInnerHTML={{
          __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '2325024577831536');
            fbq('track', 'PageView');
          `,
        }}
      />
      {/* NoScript fallback */}
      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: 'none' }}
          src="https://www.facebook.com/tr?id=2325024577831536&ev=PageView&noscript=1"
          alt="facebook-pixel"
        />
      </noscript>
    </div>

      <script async src="https://www.googletagmanager.com/gtag/js?id=AW-715107215"></script>

 
      {
        <script type="text/javascript">
          {(function (c, l, a, r, i, t, y) {
            c[a] = c[a] || function () {
              (c[a].q = c[a].q || []).push(arguments);
            };
            t = l.createElement(r);
            t.async = 1;
            t.src = "https://www.clarity.ms/tag/" + i;
            y = l.getElementsByTagName(r)[0];
            y.parentNode.insertBefore(t, y);
          })(window, document, "clarity", "script", "l5onqk3uin")}

        </script>

        
      }
    </div>
    
  );
  
};

export default Home;
