import moment from "moment";
import {
  combineDateAndTime,
  getFormattedTime,
  toHoursAndMinutes,
  tolocaleDate,
} from "../common/methods";

export const useTimeDifference = (flight) => {  

   
  
  const departureTime = getFormattedTime(combineDateAndTime(flight,true)); 
  const arrivalTime = getFormattedTime(combineDateAndTime(flight,false));
  const localDep = tolocaleDate(combineDateAndTime(flight,true));
  const localArr = tolocaleDate(combineDateAndTime(flight,false));
  const dep = moment(combineDateAndTime(flight,true));
  const arr = moment(combineDateAndTime(flight,false));

  const diffInHours = moment.duration(arr.diff(dep));
  const { hours, minutes } = toHoursAndMinutes(
    flight.flightTime
    // diffInHours.asMinutes()?.toFixed()
  );

  const timeFormatted = `${hours}h ${minutes}m`; 

  return {
    departureTime, 
    arrivalTime,
    localDep,
    localArr,
    hours,
    minutes,
    timeFormatted,
  };
};
