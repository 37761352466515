import "../assets/css/bootstrap.min.css"
import "../assets/css/animate.min.css"
import "../assets/css/boxicons.min.css"
import "../assets/css/magnific-popup.min.css"
import "../assets/css/font-awesome.css"
import "../assets/css/jquery-ui.css"
import "../assets/css/style.css"
import "../assets/css/responsive.css"

const Styles = () => {
    return (
        <div></div>
    )
}

export default Styles