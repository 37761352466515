const HotelHeader = () => {
  return (
    <section class="header">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 topbar text-right col-md-12 col-12">
            <a class="tel" href="tel:38349253035">
              +383 49 25 30 35
            </a>
            <div class="dropdown country">
              <button
                class="btn btn-secondary dropdown-toggle"
                type="button"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                <img src="assets/img/logo-country.png" alt="" /> SQ
                <img src="assets/img/down-arrow.png" alt="" />
              </button>
              <div class="dropdown-menu">
                <a class="dropdown-item" href="#">
                  Action
                </a>
                <a class="dropdown-item" href="#">
                  Another action
                </a>
                <a class="dropdown-item" href="#">
                  Something else here
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <nav class="navbar navbar-expand-lg">
            <a class="navbar-brand" href="index.html">
              <img src="assets/img/logo.png" />
            </a>
            <button
              class="navbar-toggler menu-icon"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
              <span class="navbar-toggler-icon"></span>
              <span class="navbar-toggler-icon"></span>
            </button>
            <div
              class="collapse justify-content-center navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul class="navbar-nav mr-auto">
                <li class="nav-item">
                  <a class="nav-link" href="about-us.html">
                    About Us
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="tours.html">
                    Tours
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="rental-cars.html">
                    Rental Cars
                  </a>
                </li>
                {/* <li class="nav-item">
                  <a class="nav-link" href="visit-dubai.html">
                    Visit Dubai
                  </a>
                </li> */}
                <li class="nav-item">
                  <a class="nav-link" href="blog.html">
                    Blog
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="contact-us.html">
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </section>
  );
};

export default HotelHeader;
