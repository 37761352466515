import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  FormControl,
  FormGroup,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import { useStoreFly } from "../../store";
import { AddSubtract } from "../common/AddSubstract";
import { Fragment, useEffect, useState } from "react";
import { RadioWithLabel } from "../common/RadioWithLabel";
import { DayTimes } from "../common/DayTimes";
import { CheckboxWithLabel } from "../common/CheckboxWithLabel";
import { FlightUtils } from "../../common/FlightUtils";
import {
  CURRENCY_FULL_NAME,
  CURRENCY_SIGN,
  IMAGE_NAME,
} from "../../utils/enums";
import { useFilters } from "../../hooks/useFilters";
import { useLocation, useNavigate } from "react-router-dom";
import { useBaggage } from "../../hooks/useBaggage";
import { SEARCH_API_QUERY_PARAMS } from "../../utils/constants";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useQueryParams } from "../../hooks/useQueryParams";
import { IsMobileOrBrowser } from "../../hooks/isBrowserOrMobile";
import { DrawerComponent } from "../common/Drawer";
import { nanoid } from "nanoid";
import { getItemFromLocalStorage } from "../../common/methods";
import { useFlights } from "../../hooks/useFlights";
const FlightUtil = new FlightUtils();

export const Filters = ({ translation }) => {
  const queryParams = useQueryParams();

  const setFilter = useStoreFly((state) => state.setFilter);
  const setBaggage = useStoreFly((state) => state.setBaggage);
  const filterAirlines = useStoreFly((state) => state.filterAirlines);
  const stops = useStoreFly((state) => state.filterStops);
  const baggages = useStoreFly((state) => state.baggage);
  const passengers = useStoreFly((state) => state.passengers);
  const filterFlightTimes = useStoreFly((state) => state.filterFlightTimes);
  const filterRoute = useStoreFly((state) => state.filterRoute);
  const resetFilters = useStoreFly((state) => state.resetFilters);
  const currency = useStoreFly((state) => state.currency);
  const location = useLocation();
  const navigate = useNavigate();
  const [disableCheckedBaggage, setDisableCheckedBaggage] = useState(false);
  const [disableCabinBaggage, setDisableCabinBaggage] = useState(false);
  const isMobile = IsMobileOrBrowser();
  const [openFilter, setOpenFilter] = useState(false);

  const setFlights = useStoreFly((state) => state.setSearchFlights);
  const setLoadingData = useStoreFly((state) => state.setLoadingData);
  const returnOrOneWay = useStoreFly((state) => state.returnOrOneWay);
  const setTotalFlights = useStoreFly((state) => state.setTotalFlights);
  const carriers = useStoreFly((state) => state.airlines);
  const { adultCabin, adultChecked } = useBaggage();

  const {
    dateFrom,
    dateTo,
    flyFrom,
    flyTo,
    adults,
    babies,
    children,
    selectedAirlines,
    maxStops,
    flightTimeGoing,
    flightTimeReturning,
    selectedClass,
  } = useFilters();

  const setFlightsHandler = async ({
    curr,
    path,
    currentValue,
    stops,
    route,
    currentFlightTime,
    airlines,
    navigate,
  }) => {
    const filter = getItemFromLocalStorage("filter");

    let cabinBaggage = [];
    let checkedBaggage = [];
    if (path === "cabin" && currentValue) {
      const adultCabin = FlightUtil.getAdultCabinBaggage(
        passengers?.adult,
        currentValue
      );
      cabinBaggage = adultCabin;
    }

    if (path === "checked" && currentValue) {
      const adultChecked = FlightUtil.getAdultCheckedBaggage(
        passengers?.adult,
        currentValue
      );
      checkedBaggage = adultChecked;
    }

    // const flyFrom = queryParams().get("fly_from");
    // const flyTo = queryParams().get("fly_to");
    // const dateFrom = queryParams().get("date_from");
    // const dateTo = queryParams().get("date_to");
    // const adults = queryParams().get("adults") || 1;
    // const babies = queryParams().get("babies") || 0;
    // const children = queryParams().get("children") || 0;
    // const returnOrOneWay = queryParams().get("return");
    // const selectedClass = queryParams().get("selected_class") || "M";
    const adultCabinCurrent = path === "cabin" ? cabinBaggage : adultCabin;
    const adultCheckedCurrent =
      path === "checked" ? checkedBaggage : adultChecked;
    const selectedAirlinesCurrent = airlines?.length
      ? airlines?.map((ar) => ar.id)
      : selectedAirlines || [];
    const maxStopsCurrent = stops || maxStops;
    const flightTimeGoingCurrent =
      route === "going" ? currentFlightTime : flightTimeGoing;
    const flightTimeReturningCurrent =
      route === "returning" ? currentFlightTime : flightTimeReturning;
    const currencyCurrent =
      curr?.curr || curr || filter?.curr || currency?.curr || "EUR";
    setLoadingData(true);

    localStorage.setItem(
      "filter",
      JSON.stringify({
        adultCabin: adultCabinCurrent,
        adultChecked: adultCheckedCurrent,
        curr: currencyCurrent,
        stops: maxStopsCurrent,
        flightTimeGoing: flightTimeGoingCurrent,
        flightTimeReturning: flightTimeReturningCurrent,
        selectedAirlines: selectedAirlinesCurrent,
      })
    );

    navigate(`/search?session=${nanoid()}`);
    // await FlightUtil.setFlights(
    //   setFlights,
    //   setTotalFlights,
    //   setFilter,
    //   {
    //     dateFrom,
    //     dateTo,
    //     flyFrom,
    //     flyTo,
    //     adults,
    //     babies,
    //     children,
    //     selectedClass: SELECTED_CABINS[selectedClass],
    //     adultCabin: path === "cabin" ? cabinBaggage : adultCabin,
    //     adultChecked: path === "checked" ? checkedBaggage : adultChecked,
    //     selectedAirlines: airlines?.length
    //       ? airlines?.map((ar) => ar.id)
    //       : selectedAirlines || [],
    //     maxStops: stops || maxStops,
    //     flightTimeGoing:
    //       route === "going" ? currentFlightTime : flightTimeGoing,
    //     flightTimeReturning:
    //       route === "returning" ? currentFlightTime : flightTimeReturning,
    //     returnOrOneWay,
    //     curr: curr || currency?.curr,
    //   },
    //   carriers,
    //   location.pathname,
    //   navigate
    // );
    setLoadingData(false);
  };

  useEffect(() => {
    const { totalForCabinBag, totalForCheckedBag } =
      FlightUtil.getMaximumBagCount(passengers || {});
    if (baggages.cabin === totalForCabinBag) {
      setDisableCabinBaggage(true);
    } else {
      setDisableCabinBaggage(false);
    }

    if (baggages.checked === totalForCheckedBag) {
      setDisableCheckedBaggage(true);
    } else {
      setDisableCheckedBaggage(false);
    }
  }, [baggages.cabin, baggages.checked, passengers]);

  const totalFlights = useStoreFly((state) => state.totalFlights);
  
  return (
    <div class="col-lg-3 filterbar pr-lg-0 col-md-4 col-12">
      {/*  Mobile view  */}

      <Accordion
        sx={{
          display: {
            xs: "block",
            md: "none",
            lg: "none",
          },
          background: "#fff",
          boxShadow: "0px 5px 2px 0px rgb(0 0 0 / 40%)",
          borderRadius: "10px",
        }}
        onClick={() => setOpenFilter(true)}
        expanded={false}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div>
            <label class="headi mt-0 mb-0">
              {translation("filter_results")}
            </label>
            <span class="text-blue d-block fw-medium">
              {totalFlights || translation("no")} {translation("results_found")}
            </span>
          </div>
        </AccordionSummary>
      </Accordion>

      {/*  Desktop view  */}
      {!isMobile ? (
        <div
          // className="sticky"
          style={
            {
              // overflow: "auto",
              // maxHeight: "100vh",
            }
          }
        >
          <label class="headi mt-0 mb-0">{translation("filter_results")}</label>
          <span class="text-blue d-block fw-medium">
            {totalFlights || translation("no")} {translation("results_found")}
          </span>
          <BaggageFilter
            translation={translation}
            baggages={baggages}
            setBaggage={setBaggage}
            disableCheckedBaggage={disableCheckedBaggage}
            disableCabinBaggage={disableCabinBaggage}
            setFlightsHandler={({ path, currentValue }) =>
              setFlightsHandler({ path, currentValue, navigate })
            }
          />
          <CurrencyFilter
            translation={translation}
            currency={currency}
            setFilter={setFilter}
            setFlightsHandler={(curr) => setFlightsHandler({ curr, navigate })}
          />
          <StopsFilter
            stops={stops}
            setFilter={setFilter}
            translation={translation}
            setFlightsHandler={(stops) =>
              setFlightsHandler({ stops, navigate })
            }
          />
          <FlightTimes
            destinationCity={filterRoute?.destination?.city?.name}
            fromCity={filterRoute?.origin?.city?.name}
            filterFlightTimes={filterFlightTimes}
            setFilter={setFilter}
            translation={translation}
            setFlightsHandler={({ route, currentFlightTime }) =>
              setFlightsHandler({ route, currentFlightTime, navigate })
            }
          />
          <AirlinesFilter
            filterAirlines={filterAirlines}
            setFilter={setFilter}
            translation={translation}
            setFlightsHandler={(airlines) =>
              setFlightsHandler({ airlines, navigate })
            }
          />
          <button
            className="default-white-btn mt-2"
            onClick={async (e) => {
              setLoadingData(true);
              localStorage.setItem("filter", "{}");
              resetFilters();
              navigate(`/search?session=${nanoid()}`);
            }}
          >
            {translation("clear_all_filter")}
          </button>
        </div>
      ) : (
        <DrawerComponent
          id="filter-drawer"
          key="filter-drawer"
          open={openFilter}
          setOpen={setOpenFilter}
          anchor="bottom"
          headerText={`${translation("filter_results")} (${
            totalFlights || 0
          } total)`}
        >
          <div className="col-lg-3 filterbar pr-lg-0 col-12">
            <BaggageFilter
              translation={translation}
              baggages={baggages}
              setBaggage={setBaggage}
              disableCheckedBaggage={disableCheckedBaggage}
              disableCabinBaggage={disableCabinBaggage}
              setFlightsHandler={({ path, currentValue }) =>
                setFlightsHandler({ path, currentValue, navigate })
              }
            />
            <CurrencyFilter
              translation={translation}
              currency={currency}
              setFilter={setFilter}
              setFlightsHandler={(curr) =>
                setFlightsHandler({ curr, navigate })
              }
            />
            <StopsFilter
              stops={stops}
              setFilter={setFilter}
              translation={translation}
              setFlightsHandler={(stops) =>
                setFlightsHandler({ stops, navigate })
              }
            />
            <FlightTimes
              destinationCity={filterRoute?.destination?.city?.name}
              fromCity={filterRoute?.origin?.city?.name}
              filterFlightTimes={filterFlightTimes}
              setFilter={setFilter}
              translation={translation}
              setFlightsHandler={({ route, currentFlightTime }) =>
                setFlightsHandler({ route, currentFlightTime, navigate })
              }
            />
            <AirlinesFilter
              filterAirlines={filterAirlines}
              setFilter={setFilter}
              translation={translation}
              setFlightsHandler={(airlines) =>
                setFlightsHandler({ airlines, navigate })
              }
            />
            <button
              className="default-white-btn mt-2"
              style={{
                backgroundColor: "rgb(3, 76, 136)",
                color: "#fff",
                marginBottom: "10px",
              }}
              onClick={async (e) => {
                setLoadingData(true);
                localStorage.setItem("filter", "{}");
                resetFilters();
                navigate(`/search?session=${nanoid()}`);
              }}
            >
              {translation("clear_all_filter")}
            </button>
          </div>
        </DrawerComponent>
      )}
    </div>
  );
};

const CurrencyFilter = ({
  currency,
  setFilter,
  setFlightsHandler,
  translation,
}) => {
  const currentFilter = getItemFromLocalStorage("filter");
  const currentCurrency = currentFilter?.curr || currency?.curr;

  return (
    <Fragment>
      <label class="headi d-block mt-3 pt-2">{translation("currency")}</label>
      <Accordion style={{
        background: "transparent"
      }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography style={{ color: "#003663" }}>
            {CURRENCY_SIGN[currentCurrency]}{" "}
            {CURRENCY_FULL_NAME[currentCurrency]}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: "flex",
            justifyContent: "start",
            padding: 0,
            width: "100%",
            flexDirection: "column",
            color: "#003663",
          }}
        >
          {currentCurrency !== 'EUR' && (
            <MenuItem
              MenuItem
              value={"EUR"}
              onClick={() => {
                setFilter("currency", "curr", "EUR");
                setFlightsHandler({ curr: "EUR" });
              }}
            >
              € Euro
            </MenuItem>
          )}
          {currentCurrency !== 'USD' && (
            <MenuItem
              value={"USD"}
              onClick={() => {
                setFilter("currency", "curr", "USD");
                setFlightsHandler({ curr: "USD" });
              }}
            >
              $ Dollar
            </MenuItem>
          )}
          {currentCurrency !== 'GBP' && (
            <MenuItem
              value={"GBP"}
              onClick={() => {
                setFilter("currency", "curr", "GBP");
                setFlightsHandler({ curr: "GBP" });
              }}
            >
              £ Pound
            </MenuItem>
          )}
          {currentCurrency !== 'CHF' && (
            <MenuItem
              value={"CHF"}
              onClick={() => {
                setFilter("currency", "curr", "CHF");
                setFlightsHandler({ curr: "CHF" });
              }}
            >
              ₣ Franc
            </MenuItem>
          )}
        </AccordionDetails>
      </Accordion>

      {/* <FormControl fullWidth>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={currentCurrency}
          onChange={(event, child) => {
            setFilter("currency", "curr", event.target.value);
            setFlightsHandler({ curr: event.target.value });
          }}
        >
          <MenuItem value={"EUR"}>€ Euro</MenuItem>
          <MenuItem value={"USD"}>$ Dollar</MenuItem>
          <MenuItem value={"GBP"}>£ Pound</MenuItem>
          <MenuItem value={"CHF"}>₣ Franc</MenuItem>
        </Select>
      </FormControl> */}
    </Fragment>
  );
};

const BaggageFilter = ({
  baggages,
  setBaggage,
  disableCabinBaggage,
  disableCheckedBaggage,
  translation,
  setFlightsHandler,
}) => {
  return (
    <Fragment>
      <label class="headi d-block mt-3 pt-2">{translation("baggage")}</label>
      <AddSubtract
        currentValue={baggages?.cabin}
        setCurrentValue={setBaggage}
        path="cabin"
        imageName={IMAGE_NAME.SUIT_CASE}
        label={translation("cabin_baggage")}
        // disabled={disableCabinBaggage}
        disabled={true}
        setFlightsHandler={setFlightsHandler}
      />

      <AddSubtract
        currentValue={baggages?.checked}
        setCurrentValue={setBaggage}
        path="checked"
        imageName={IMAGE_NAME.LUGGAGE}
        label={translation("checked_baggage")}
        // disabled={disableCheckedBaggage}
        disabled={true}
        setFlightsHandler={setFlightsHandler}
      />
    </Fragment>
  );
};

const StopsFilter = ({ stops, setFilter, translation, setFlightsHandler }) => {
  return (
    <Fragment>
      <label class="headi mt-5">{translation("STOPS")}</label>
      <FormGroup>
        <RadioWithLabel
          label={translation("any")}
          value="any"
          selectedValue={stops?.current}
          handleChange={() => {
            setFilter("filterStops", "current", "any");
            setFlightsHandler("any");
          }}
        />
        <RadioWithLabel
          label={translation("non_stop")}
          value="nonstop"
          selectedValue={stops?.current}
          handleChange={() => {
            setFilter("filterStops", "current", "nonstop");
            setFlightsHandler("nonstop");
          }}
        />
        {/* <RadioWithLabel
          label={translation("up_to_one")}
          value="upto1"
          selectedValue={stops?.current}
          handleChange={() => {
            setFilter("filterStops", "current", "upto1");
            setFlightsHandler("upto1");
          }}
        />
        <RadioWithLabel
          label={translation("up_to_two")}
          value="upto2"
          selectedValue={stops?.current}
          handleChange={() => {
            setFilter("filterStops", "current", "upto2");
            setFlightsHandler("upto2");
          }}
        /> */}

        {/* <div class="custom-control custom-radio">
          <input
            type="checkbox"
            id="overnight"
            name="overnight"
            class="custom-control-input"
            onChange={(event) => {
              setFilter("filterStops", "allowOvernight", event.target.checked);
            }}
            checked={stops?.allowOvernight}
          />
          <label class="custom-control-label" for="overnight">
            {translation("allow_overnight")}
          </label>
        </div> */}
      </FormGroup>
    </Fragment>
  );
};

const FlightTimes = ({
  destinationCity,
  fromCity,
  filterFlightTimes,
  setFilter,
  translation,
  setFlightsHandler,
}) => {
  const searchDetails = getItemFromLocalStorage("search")
  const { priceFlights } = useFlights();
  // console.log({priceFlights});
  const { cityFrom , cityTo } = priceFlights?.data?.[0] || {
    cityFrom: "",
    cityTo: ""
  };
  return (
    <Fragment class="cursorNone">
      <label class="headi mb-1 cursorNone">{translation("flight_times")}</label>
      <p class="text-blue subhead mb-2 incp cursorNone">
        <strong>{translation("going")}</strong> {translation("to")}{" "}
        {destinationCity || cityTo === "Pristina" ? "Prishtina" : cityTo}
      </p>
      <DayTimes
        route={"going"}
        filterFlightTimes={filterFlightTimes}
        setFilter={setFilter}
        translation={translation}
        setFlightsHandler={setFlightsHandler}
      />
      <p class="text-blue d-block w-100 mt-3 subhead mb-2 incp">
        <strong>{translation("returning")} </strong> {translation("to")}{" "}
        {fromCity || cityFrom === "Pristina" ? "Prishtina" : cityFrom }
      </p>
      <DayTimes
        route={"returning"}
        filterFlightTimes={filterFlightTimes}
        setFilter={setFilter}
        translation={translation}
        setFlightsHandler={setFlightsHandler}
      />
    </Fragment>
  );
};

const AirlinesFilter = ({
  filterAirlines,
  setFilter,
  translation,
  setFlightsHandler,
}) => {
  const selectedAirlines = filterAirlines?.selected || [];
  const unselectedAirlines = filterAirlines?.unselected || [];

  // const airlinesList = filterAirlines?.list || [];
  return (
    <Fragment>
      {selectedAirlines?.length ? (
        <label class="headi">{translation("airlines")}</label>
      ) : (
        ""
      )}
      <FormGroup>
        {selectedAirlines?.map((airline) => (
          <CheckboxWithLabel
            key={airline?.id}
            labelValue={airline?.name}
            id={airline?.id}
            handleChange={(event) => {
              const currentSelectedAirlines = selectedAirlines?.map((a) => {
                if (a.id === event.target.id) {
                  return {
                    ...a,
                    checked: event.target.checked,
                  };
                }
                return a;
              });

              const deselectedAirlines = selectedAirlines?.map((ar) => {
                if (ar.id === event.target.id) {
                  return {
                    ...ar,
                    checked: false,
                  };
                }
                return ar;
              });
              setFilter("filterAirlines", "selected", currentSelectedAirlines);
              setFilter("filterAirlines", "unselected", [
                ...unselectedAirlines,
                { ...airline, checked: event.target.checked },
              ]);
              const filter = getItemFromLocalStorage("filter");
              localStorage.setItem(
                "filter",
                JSON.stringify({
                  ...filter,
                  unselected: [
                    ...unselectedAirlines,
                    { ...airline, checked: event.target.checked },
                  ],
                })
              );
              setFlightsHandler(
                currentSelectedAirlines?.filter((a) => a.checked)
              );
            }}
            onlyThis={(event,id,checkBox) => {
              const currentSelectedAirlines = selectedAirlines?.map((a) => {
                if (a.id === id) {
                  
                  return {
                    ...a,
                    checked: true,
                  }; 
                }
                else{
                  
                  return {
                    ...a,
                    checked: false,
                  }; 
                }
                // console.log('AJA', a) 
              });
              // console.log('currentSelectedAirlines', currentSelectedAirlines)

              const deselectedAirlines = currentSelectedAirlines?.filter((ar) =>!ar.checked)  
              // console.log('deselectedAirlines', deselectedAirlines)
              setFilter("filterAirlines", "selected", currentSelectedAirlines);
              setFilter("filterAirlines", "unselected",deselectedAirlines  );
              const filter = getItemFromLocalStorage("filter");
              localStorage.setItem(
                "filter",
                JSON.stringify({
                  ...filter,
                  unselected: [
                    ...deselectedAirlines,
                    { ...airline, checked: true },
                  ],
                })
              );
              setFlightsHandler(
                currentSelectedAirlines?.filter((a) => a.checked)
              );
              // console.log('FILTERI)', currentSelectedAirlines?.filter((a) => a.checked))
            }}


            checked={airline?.checked}
          />
        ))}
      </FormGroup>
      {!!unselectedAirlines.length && unselectedAirlines?.length ? (
        <label class="headi">{"Other"}</label>
      ) : (
        ""
      )}
      <FormGroup>
        {!!unselectedAirlines.length &&
          unselectedAirlines?.map((airline) => (
            <CheckboxWithLabel
              key={airline?.id}
              labelValue={airline?.name}
              id={airline?.id}
              handleChange={(event) => {
                setFilter("filterAirlines", "selected", [
                  ...selectedAirlines,
                  { ...airline, checked: event.target.checked },
                ]);

                const deselectedAirlines = unselectedAirlines?.filter((ar) => {
                  if (ar.id !== event.target.id) {
                    return ar;
                  }
                  return null;
                });
                setFilter(
                  "filterAirlines",
                  "unselected",
                  deselectedAirlines?.filter((a) => !a.checked)
                );
                setFlightsHandler(
                  [
                    ...selectedAirlines,
                    { ...airline, checked: event.target.checked },
                  ]?.filter((a) => a.checked)
                );
              }}

              onlyThis={(event,id,checkBox) => {
                let allAirlLines=[...selectedAirlines,...unselectedAirlines]
                allAirlLines=allAirlLines.filter((al)=>{
                  if(al.id!==id){
                    al.checked=false
                    return al
                  }
                  else{
                    al.checked=true
                    return al
                  }
                })
                 
                setFilter("filterAirlines", "selected",[ ...allAirlLines ]?.filter((a) => a.checked));
                 
                setFilter(
                  "filterAirlines",
                  "unselected",
                  allAirlLines?.filter((a) => !a.checked )
                );
                setFlightsHandler(
                  [...allAirlLines]?.filter((a) => a.checked) );
              }}
              checked={airline?.checked}
            />
          ))}
      </FormGroup>
    </Fragment>
  );
};
