import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { MAIN_LAMBDA } from "../utils/constants";
import { POST } from "../utils/api";
import { Alert } from "@mui/material";

const ContactForm = () => {
  const translation = useTranslation();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [messageSubmitted, setMessageSubmitted] = useState("");
  const onSubmit = (data) => {
      POST(`${MAIN_LAMBDA}/contact-us`, {
        body: JSON.stringify(data),
      } ).then((response) => {
        // console.log({response});
        setMessageSubmitted(true);
      })
      setMessageSubmitted(true);
    
  };

  // console.log(watch("example")); // watch input value by passing the name of it

  return (
    /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
        paddingTop: "5%",
      }}
    >
      {/* register your input into the hook by invoking the "register" function */}
      <h4>{translation.t("contact_us")}</h4>
      <input
        className="form-control"
        type="text"
        placeholder={translation.t("first_name")}
        {...register("first_name", { required: true, maxLength: 80 })}
      />

      <input
        className="form-control"
        type="text"
        placeholder={translation.t("last_name")}
        {...register("last_name", { required: true, maxLength: 100 })}
      />
      <input
        className="form-control"
        type="text"
        placeholder={translation.t("Email")}
        {...register("email", { required: true, pattern: /^\S+@\S+$/i })}
      />
      <input
        className="form-control"
        type="tel"
        placeholder={translation.t("phone")}
        {...register("phone", {
          required: false,
          minLength: 6,
          maxLength: 12,
        })}
      />

      <textarea
        className="form-control"
        placeholder={translation.t("message")}
        {...register("message", { required: true })}
      />
      {/* errors will return when field validation fails  */}
      {/* {errors.exampleRequired && <span>This field is required</span>} */}

      {messageSubmitted === true && (
        <Alert severity="success">
          {translation.t("message_submitted")}
          <br />
        </Alert>
      )}

      {messageSubmitted === false && (
        <Alert severity="error">
        {translation.t("message_not_submitted")}
          <br />
        </Alert>
      )}

      <input
        className="btn-submit default-yellow-btn"
        value={translation.t("submit")}
        type="submit"
        style={{
          padding: "10px 40px",
          fontWeight: "bold",
          maxWidth: "265px",
          borderColor: "#fbd316",
        }}
      />
    </form>
  );
};

export default ContactForm;
