import { FlightUtils } from "../../../common/FlightUtils";
import { combineDateAndTime } from "../../../common/methods";
import { TimeDateAndStops } from "../../../components/common/TimeDateAndStops";
import { useFlightRouteDetails } from "../../../hooks/useFlightRouteDetails";
import { useStaticImages } from "../../../hooks/useStaticImages";
import { useTimeDifference } from "../../../hooks/useTimeDifference";
import { AIRLINES_IMAGE_ENDPOINT } from "../../../utils/constants";
import moment from "moment";
const FlightUtil = new FlightUtils();
const toHoursAndMinutes = (totalMinutes) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return { hours, minutes };
};
function isRefundable(arr){
  
  if(Array.isArray(arr)){ 
    return arr.forEach((obj)=>{ 
      if(obj.miniRules.find((el)=>el.penaltyType===0&&el.isPermited===1)){
        return true
      }
      else return false 
    })
  }

}
const TripSummaryItem = ({ flight, route, translation, carriers, type }) => {   
  function checkIfIsLastSegment(id,isReturn){
    if(isReturn){
      return flight?.journeys?.journey_1?.segmengtIds[flight?.journeys?.journey_1?.segmengtIds?.length-1]?.segmentId==id
      
    }
    else{
      return flight?.journeys?.journey_0?.segmengtIds[flight?.journeys?.journey_0?.segmengtIds?.length-1]?.segmentId==id 
    } 
  }
  function calculateDifferencePrv(date1,date2) {
    let date1Moment = moment(date1);
    const date2Moment = moment(date2);

     let diff = toHoursAndMinutes(Math.abs(date2Moment.diff(date1Moment ,'minutes'))); 
     let string = diff.hours + 'h ' + diff.minutes+'m';
     return (string)
  };
   
  function returnLayover(id,isReturn){
    if(isReturn){
      let nextRoute;
      flight?.journeys?.journey_1?.segmengtIds?.forEach((seg,index)=>{
        if(seg.segmentId==id){
          nextRoute=flight?.journeys?.journey_1?.segmengtIds[index+1]
        }
      })
      return nextRoute&&(route.isReturn ===nextRoute.isReturn)?calculateDifferencePrv(combineDateAndTime(route,false), combineDateAndTime(nextRoute,true)):'';
      
    }
    else{
      let nextRoute;
      flight?.journeys?.journey_0?.segmengtIds?.forEach((seg,index)=>{
        if(seg.segmentId==id){
          nextRoute=flight?.journeys?.journey_0?.segmengtIds[index+1]
        }
      })
      return nextRoute&&(route.isReturn ===nextRoute.isReturn)?calculateDifferencePrv(combineDateAndTime(route,false), combineDateAndTime(nextRoute,true)):'';
    } 
  }
  const isReturnDirect=flight?.journeys?.journey_1?.segmengtIds?.length;
  const isDepartureDirect=flight?.journeys?.journey_0?.segmengtIds?.length;
  const airlineContext = FlightUtil.getAirline(route?.airline, carriers);

  const { departFromAirportName: depAirport, arriveToAirportName: arrAirport } =
    FlightUtil.getAirportNameByCityCode(route?.flyFrom, route?.flyTo, []);
  const {
    departAtNumber,
    arriveAtNumber,
  } = useFlightRouteDetails(route);

  // console.log({ route });

  const { departureTime, arrivalTime, hours, minutes } =
    useTimeDifference(route);
   const { refund,
    nonrefund
  } = useStaticImages();
  return (
    <div class="col-lg-12 whitebox mt-3 tripinfo col-md-12 col-12">
      <div class="row">
        <div class="col-lg-3 left col-md-3 col-12">
          <span class="d-block fs-17 fw-medium text-blue">
            {translation.t(type)}
          </span>
          <span class="d-flex align-items-center justify-content-center w-100 mt-2 fs-17 text-blue">
            <img
              class="mr-1"
              srcSet={`${AIRLINES_IMAGE_ENDPOINT}/32x32/${airlineContext?.id}.png`}
              alt={airlineContext?.name}
            />{" "}
            {airlineContext?.name}
          </span>
        </div>
        <TimeDateAndStops
          translation={translation.t}
          key="time_date_and_stops_departure"
          departureTime={departureTime}
          arrivalTime={arrivalTime}
          cityCodeFrom={route.departure}
          cityCodeTo={route.arrival}
          ibLocalDeb={departAtNumber}
          ibLocalArr={arriveAtNumber}
          hours={hours}
          minutes={minutes}
          stops={0}
          className="col-lg-6 tinfo col-md-9 col-12"
        />
      </div>
      <div>
        <p>{isReturnDirect>1&&route.isReturn&&!checkIfIsLastSegment(route.segmentId,route.isReturn)?''+returnLayover(route.segmentId,route.isReturn)+' Layover':''}</p>
        <p>{isDepartureDirect>1&&!route.isReturn&&!checkIfIsLastSegment(route.segmentId,route.isReturn)?''+returnLayover(route.segmentId,route.isReturn)+' Layover':''}</p>
        <p class="mid text-left"><span  ><img height={25} width={25} src={isRefundable(flight.miniRuleMap)?refund:nonrefund}    /> {isRefundable(flight.miniRuleMap)?'Refund':'Non-refund'} </span> </p>
                     
      </div>
    </div>
  );
};

export default TripSummaryItem;
