import { Drawer } from "@mui/material";
import { Fragment } from "react";
import { useStaticImages } from "../../hooks/useStaticImages";

export const DrawerComponent = ({
  id,
  children,
  open = false,
  setOpen,
  anchor = "top",
  headerText = "",
  height = "100%"
}) => {
  return (
    <Fragment>
      <Drawer
        anchor={anchor}
        open={open}
        PaperProps={{
          sx: {
            height,
            minWidth: anchor === "right" ? "70%" : "100%",
            overflow: id === "filter-drawer" ? "scroll" : "hidden"
          },
        }}
      >
        <DrawerHeader headerText={headerText} setOpen={setOpen} />
        {children}
      </Drawer>
    </Fragment>
  );
};

const DrawerHeader = ({ headerText, setOpen }) => {
  const { cancel } = useStaticImages();
  return (
    <div
      className="flex-row justify-content-space-between align-items-center"
      style={{
        width: "100%",
        backgroundColor: "#003663",
        padding: "29px 28px",
      }}
    >
      <h5 className="text-white mb-0">{headerText}</h5>
      <span
        style={{
          color: "white",
        }}
        onClick={() => {
          setOpen(false);
        }}
      >
        <img srcSet={cancel} alt="close" style={{
          maxHeight: "25px"
        }}/>
      </span>
    </div>
  );
};
