import { Fragment, memo, useEffect, useRef, useState } from "react";
import "../assets/css/dates.css";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { useStoreFly } from "../store";
import { FlightUtils } from "../common/FlightUtils";
import { LookupItems } from "./common/LookupItems";
import { useFilters } from "../hooks/useFilters";
import { useNavigate, useLocation } from "react-router-dom";
import { SELECTED_CABINS, SELECTED_CLASS } from "../utils/enums";
import { useBaggage } from "../hooks/useBaggage";
import { RangePicker } from "./common/RangePicker";
import { SEARCH_API_QUERY_PARAMS } from "../utils/constants";
import moment from "moment";
import { DrawerComponent } from "./common/Drawer";
import { nanoid } from "nanoid";
import { Box } from "@mui/material";
import { IsMobileOrBrowser } from "../hooks/isBrowserOrMobile";
import {
  dateFromFormattedToMoment,
  getItemFromLocalStorage,
} from "../common/methods";
import SearchIcon from "@mui/icons-material/Search";
import HomeMobileSearch from "./flight/HomeMobileSearch";
import SwapHorizontalCircleIcon from "@mui/icons-material/SwapHorizontalCircle";
import { isTablet } from "react-device-detect";
import $ from "jquery";
const RangePickerMemo = memo(() => <RangePicker isSingleSelect={false} />);
// const SingleDatePicker = memo(({ isSingleSelect, placeholder, path }) => (
//   <RangePicker
//     isSingleSelect={isSingleSelect}
//     placeholder={placeholder}
//     path={path}
//   />
// ));

function clickDateRange() {

  let a = document.getElementsByClassName('date') 
  setTimeout(() => {
    for (let item of a) {
      console.log(item.focus())
    }
  }, 500);

}

const FlightUtil = new FlightUtils();

const Banner = ({ showSearchBar = true, minHeight = "425px", translation }) => {
  const currentSearch = getItemFromLocalStorage("search");

  const isMobile = IsMobileOrBrowser(); 
  const location = useLocation();

  useEffect(() => {
     

    if (location.pathname === "/") {
      localStorage.setItem("search", "{}");
      localStorage.setItem("filter", "{}");
    } 

  }, [location.pathname]);

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);
  const alertUser = (e) => {
    // localStorage.setItem("search", "{}");
    // localStorage.setItem("filter", "{}");
  };

  const [changeSearch, setChangeSearch] = useState(false);
  const navigate = useNavigate();
  const filterRoute = useStoreFly((state) => state.filterRoute);
  const setReturnOrOneWay = useStoreFly((state) => state.setReturnOrOneWay);
  const returnOrOneWay = useStoreFly((state) => state.returnOrOneWay);

  const resetFilterRoute = useStoreFly((state) => state.resetFilterRoute);
  const setFilterClass = useStoreFly((state) => state.setFilterClass);
  const { destination, origin, originInput, destinationInput } = filterRoute;

  const {
    dateFrom,
    dateTo,
    flyFrom,
    flyTo,
    adults,
    babies,
    children,
    selectedAirlines,
    maxStops,
    flightTimeGoing,
    flightTimeReturning,
    selectedClass,
  } = useFilters();  

  const travellerRef = useRef(null);

  const [travelerBlur, setTravelerBlur] = useState();

  const [blurBackground, setblurBackground] = useState(false); //set blue background
  const [destinationInputFocused, setDestinationInputFocused] = useState(false);
  const [viewOriginLookup, setViewOriginLookup] = useState(false);
  const [viewDestinationLookup, setViewDestinationLookup] = useState(false);
  const [travellerDropDownShow, setTravellerDropDownShow] = useState(false);
  const originRef = useRef(null);
  const destinationRef = useRef(null);
  const datePickerRef = useRef(null);

  const [options, setOptions] = useState({
    adult: adults || 1,
    children: children || 0,
    babies: babies || 0,
    class:
      SELECTED_CABINS[
      (location.pathname !== "/" && currentSearch?.selectedClass) ||
      selectedClass
      ] || "Economy",
  });

  const handleOption = (name, operation) => {
    setOptions((prev) => {
      return {
        ...prev,
        [name]: operation === "plus" ? options[name] + 1 : options[name] - 1,
      };
    });
    if (operation === "plus") {
      setPassengers(`${[name]}`, options[name] + 1);
    } else {
      setPassengers(`${[name]}`, options[name] - 1);
    }
  };

  const handleClass = (name) => {
    setOptions((prev) => {
      return {
        ...prev,
        class: SELECTED_CABINS[selectedClass] || name,
      };
    });
  };

  const setActionTravellerOnClick = () => {
    // setTravellerDropDownShow(!travellerDropDownShow);
    // setblurBackground(!travellerDropDownShow);
  };

  const setFlights = useStoreFly((state) => state.setSearchFlights);
  const setLoadingData = useStoreFly((state) => state.setLoadingData);
  // const setTopLocations = useStoreFly((state) => state.setTopLocations);
  const setTotalFlights = useStoreFly((state) => state.setTotalFlights);
  const carriers = useStoreFly((state) => state.airlines);
  const setFilter = useStoreFly((state) => state.setFilter);
  const resetFilters = useStoreFly((state) => state.resetFilters);
  const setPassengers = useStoreFly((state) => state.setPassengers);
  const { adultCabin, adultChecked } = useBaggage();

  const [originValue, setOriginValue] = useState(undefined);
  const [originCode, setOriginCode] = useState("");
  const [originCity, setOriginCity] = useState("");
  const [destinationCity, setDestinationCity] = useState("");
  const [destinationValue, setDestinationValue] = useState(undefined);
  const [destinationCode, setDestinationCode] = useState("");

  // const selectedCabinClass =
  //   SELECTED_CABINS[
  //     (location.pathname !== "/" && currentSearch?.selectedClass) ||
  //       selectedClass
  //   ];
  const locations = useStoreFly((state) => state.locations);
  useEffect(() => {
    if (location.pathname === "/") {
      resetFilterRoute();
      resetFilters();
    }

    
  }, [location.pathname, resetFilterRoute, resetFilters, setFilter]); 
  const currentSearchItem = getItemFromLocalStorage("search");

  const dateFromFormatted = moment(
    dateFromFormattedToMoment(
      currentSearchItem.dateFrom
    )
  ).format("MMM D")

  const dateToFormatted = moment(
    dateFromFormattedToMoment(
      currentSearchItem.returnToDate
    )
  ).format("MMM D")



  return (
    <section
      className="banner"
      style={{ minHeight: isMobile ? "100px" : minHeight }}
    >
      {showSearchBar && (
        <div className="container">
          <div className="row">
            <div className="col-lg-12 bot-minus col-md-12 col-12">
              <ul className="nav nav-tabs">
                <li>
                  <a href="/" className="active">
                    <img src={require("../assets/img/img-flight.png")} alt="" />{" "}
                    {translation("flights")}
                  </a>
                </li>
                <li>
                  <a href="hotels">
                    <img src={require("../assets/img/img-bed.png")} alt="" />{" "}
                    {translation("hotels")}
                  </a>
                </li>
              </ul>
              <div className="tab-content">
                <div
                  id="tab-1"
                  className="tab-pane fade in show active"
                  style={{
                    paddingTop: "15px",
                    minHeight: "55px",
                  }}
                >
                  <div class="col-lg-12 col-md-12 col-12">
                    {isMobile && location.pathname === "/" && (
                      <div class="col-lg-12 radiobg p-0 col-md-12 col-12">
                        <div class="custom-control custom-radio">
                          <input
                            type="radio"
                            id="return"
                            name="customRadio"
                            class="custom-control-input return-radio"
                            value="return"
                            checked={returnOrOneWay === "return"}
                            onChange={() => {

                              setReturnOrOneWay("return");
                            }}
                          />
                          <label class="custom-control-label" for="return">
                            {translation("return")}
                          </label>
                        </div>
                        <div class="custom-control custom-radio">
                          <input
                            type="radio"
                            id="oneway"
                            name="customRadio"
                            class="custom-control-input oneway-radio"
                            value="oneway"
                            checked={returnOrOneWay === "oneway"}
                            onChange={() => {
                              setReturnOrOneWay("oneway");

                            }}
                          />
                          <label class="custom-control-label" for="oneway">
                            {translation("one_way")}
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                  {isMobile && location.pathname !== "/" && (
                    <div
                      className="d-flex justify-content-between align-items-center"
                      style={{
                        color: "#fff",
                      }}
                    >
                      <div
                        style={{
                          background: "transparent",
                          color: "white",
                          display: "flex",
                        }}
                        onClick={() => {
                          setChangeSearch(true);
                        }}
                      >
                        <SearchIcon />
                        {translation("change")}
                      </div>
                      <div className="flex-column">
                        <div
                          className="d-flex justify-content-between align-items-center"
                          style={{ gap: "10px" }}
                        >
                          <span>{currentSearchItem.originCode.substring(0, 3) || currentSearchItem.cityNameFrom.substring(0, 3)}</span> -
                          <span>{currentSearchItem.destinationCode.substring(0, 3) || currentSearchItem.cityNameTo.substring(0, 3)}</span>
                        </div>
                        <div>
                          <span
                            style={
                              currentSearchItem.returnOrOneWay !== "oneway"
                                ? { textAlign: "center" }
                                : {}
                            }
                          >
                            {
                              translation(dateFromFormatted?.split(" ")[0]?.toLowerCase())
                            }
                            {" "}
                            {
                              dateFromFormatted?.split(" ")[1]
                            }
                            {" "}
                          </span>

                          {currentSearchItem.returnOrOneWay !== "oneway" && (
                            <>
                              {" "}-{" "}
                              <span>
                                {
                                  translation(dateToFormatted?.split(" ")[0]?.toLowerCase())
                                }
                                {" "}
                                {
                                  dateToFormatted?.split(" ")[1]
                                }
                              </span>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="row">
                    {!isMobile && (
                      <>
                        <div className="col-lg-12 col-md-12 col-12">
                          <FormControl>
                            <RadioGroup defaultValue="return" row>
                              <FormControlLabel
                                className="custom-control-label-react"
                                value="return"
                                checked={returnOrOneWay === "return"}
                                control={
                                  <Radio
                                    className="return-radio"
                                    sx={{
                                      color: "white",
                                      "&.Mui-checked": {
                                        color: "white",
                                      },
                                    }}
                                  />
                                }
                                label={translation("return")}
                                sx={{
                                  ".MuiTypography-root": {
                                    fontWeight: 500,
                                    fontSize: 18,
                                    fontFamily: "'Poppins', sans-serif",
                                  },
                                }}
                                onChange={() => {
                                  // const replaceQueryParams = queryParams.replace(
                                  //   "return=oneway",
                                  //   "return=return"
                                  // );
                                  // navigate(`/search${replaceQueryParams}`);
                                  setReturnOrOneWay("return");
                                }}
                              />
                              <FormControlLabel
                                className="custom-control-label-react"
                                value="oneway"
                                checked={returnOrOneWay === "oneway"}
                                control={
                                  <Radio
                                    className="oneway-radio"
                                    sx={{
                                      color: "white",
                                      "&.Mui-checked": {
                                        color: "white",
                                      },
                                    }}
                                    onChange={() => {
                                      // const replaceQueryParams =
                                      //   queryParams.replace(
                                      //     "return=return",
                                      //     "return=oneway"
                                      //   );
                                      // navigate(`/search${replaceQueryParams}`);
                                      setReturnOrOneWay("oneway");
                                    }}
                                  />
                                }
                                label={translation("one_way")}
                                sx={{
                                  ".MuiTypography-root": {
                                    fontWeight: 500,
                                    fontSize: 18,
                                    fontFamily: "'Poppins', sans-serif",
                                  },
                                }}
                              />

                              {/* <FormControlLabel
                            className="custom-control-label-react"
                            value="multi"
                            control={
                              <Radio
                                className="multi-radio"
                                sx={{
                                  color: "white",
                                  "&.Mui-checked": {
                                    color: "white",
                                  },
                                }}
                                onChange={() => setReturnOrOneWay("multiple")}
                              />
                            }
                            label="Multi-city"
                            sx={{
                              ".MuiTypography-root": {
                                fontWeight: 500,
                                fontSize: 18,
                                fontFamily: "'Poppins', sans-serif",
                              },
                            }}
                          /> */}
                            </RadioGroup>
                          </FormControl>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="row sprdiv align-items-end">
                    <div className="col-lg-9 col-md-9 col-12">
                      <div className="row one-way d-none ml-auto mr-auto w-100">
                        <div className="row sprdiv">
                          <div className="col-lg-6 col-md-6 col-12">
                            {/* <div className="form-group">
                              <input
                                type="text"
                                className="form-control flight1 custom-dropdown"
                                open-this="pickup-dropdown"
                                value={originValue}
                                defaultValue={
                                  location.pathname !== "/" && originCity
                                }
                                onChange={(event) => {
                                  setOriginValue(event.target.value);
                                  setFilter(
                                    "filterRoute",
                                    "originInput",
                                    event.target.value
                                  );
                                }}
                              />
                              <LookupItems
                                searchValue={originInput}
                                items={locations}
                                onClickHandler={(location) => {
                                  setOriginValue(location?.name);
                                  setFilter("filterRoute", "origin", location);
                                }}
                                openClass="pickup-dropdown"
                              />
                            </div> */}
                          </div>
                          <div className="col-lg-6 col-md-6 col-12"></div>
                          <div className="col-lg-6 col-md-6 col-12">
                            <div className="row daterow sprdiv">
                              <div className="col-lg-12  col-md-6 row ml-auto mr-auto w-100 datebox whitebox p-0">
                                <div className="col-lg-12 col-md-6 col-12">
                                  <div className="form-group m-0">
                                    {<RangePickerMemo />}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-12">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control bg-user"
                                open-this="traveller-dropdown"
                                onClick={() => setTravellerDropDownShow(true)}
                                value={`${Number(options.adult) +
                                  Number(options.children) +
                                  Number(options.babies)
                                  } , ${SELECTED_CABINS[selectedClass]}`}
                                placeholder={`${Number(options.adult) +
                                  Number(options.children) +
                                  Number(options.babies) +
                                  " " +
                                  translation("traveler") +
                                  ((options.adult > 1 ||
                                    options.children ||
                                    options.babies) &&
                                    translation("s"))
                                  } , ${translation(
                                    SELECTED_CABINS[selectedClass]
                                  )}`}
                                ref={travellerRef}
                              />
                              <div className="traveller-dropdown">
                                {travellerDropDownShow && (
                                  <ClickAwayListener
                                    onClickAway={(event) => { 
                                      if (event.target.className == "blurr2" || event.target.className == "btn-submit"
                                        
                                        || event.target.className == "date-picker date-picker-flight"
                                        || event.target.className == "form-control flight2 custom-dropdown"
                                        || event.target.className == "selected-date"
                                      ) {

                                        setTravellerDropDownShow(false);
                                        setblurBackground(false);
                                      }
                                    }}
                                  >
                                    <ul className="list-group">
                                      <div className="traveller-hearder">
                                        <h6 className="px-3 pt-3 pb-0">
                                          {translation("passenger_and_class")}
                                        </h6>
                                        <div className="closeTravellerWindow">
                                          <FontAwesomeIcon
                                            onClick={() => {
                                              setTravellerDropDownShow(false);
                                              setblurBackground(false);
                                            }}
                                            size="lg"
                                            icon={faXmark}
                                          />
                                        </div>
                                      </div>
                                      <hr className="mb-0" />
                                      <li>
                                        <a className="list-group-item d-flex justify-content-between align-items-center">
                                          {translation("adults").toUpperCase()}
                                          <br />
                                          {translation("12_years_and_older")}
                                          <div className="quantity">
                                            <button
                                              disabled={options.adult <= 1}
                                              className={
                                                options.adult <= 1
                                                  ? "disabled"
                                                  : "active"
                                              }
                                              onClick={() => {
                                                handleOption("adult", "minus");
                                              }}
                                            >
                                              -
                                            </button>
                                            <input
                                              data-value="true"
                                              type="text"
                                              readonly=""
                                              value={options.adult}
                                            />
                                            <button
                                              className="active"
                                              onClick={() =>
                                                handleOption("adult", "plus")
                                              }
                                            >
                                              +
                                            </button>
                                          </div>
                                        </a>
                                      </li>
                                      <li>
                                        <a className="list-group-item d-flex justify-content-between align-items-center">
                                          {translation(
                                            "children"
                                          ).toUpperCase()}
                                          <br />
                                          {translation("2_to_11_years")}
                                          <div className="quantity">
                                            <button
                                              disabled={options.children <= 0}
                                              className={
                                                options.children <= 0
                                                  ? "disabled"
                                                  : "active"
                                              }
                                              onClick={() =>
                                                handleOption(
                                                  "children",
                                                  "minus"
                                                )
                                              }
                                            >
                                              -
                                            </button>
                                            <input
                                              data-value="true"
                                              type="text"
                                              readonly=""
                                              value={options.children}
                                            />
                                            <button
                                              className="active"
                                              onClick={() =>
                                                handleOption("children", "plus")
                                              }
                                            >
                                              +
                                            </button>
                                          </div>
                                        </a>
                                      </li>
                                      <li>
                                        <a className="list-group-item d-flex justify-content-between align-items-center">
                                          {translation("babies").toUpperCase()}
                                          <br />
                                          {translation("under_2_years_old")}
                                          <div className="quantity">
                                            <button
                                              disabled={options.babies <= 0}
                                              className={
                                                options.babies <= 0
                                                  ? "disabled"
                                                  : "active"
                                              }
                                              onClick={() =>
                                                handleOption("babies", "minus")
                                              }
                                            >
                                              -
                                            </button>
                                            <input
                                              data-value="true"
                                              type="text"
                                              readonly=""
                                              value={options.babies}
                                            />
                                            <button
                                              className="active"
                                              onClick={() =>
                                                handleOption("babies", "plus")
                                              }
                                            >
                                              +
                                            </button>
                                          </div>
                                        </a>
                                      </li>
                                      <FormControl>
                                        <RadioGroup
                                          defaultValue={
                                            currentSearch?.selectedClass &&
                                              location.pathname !== "/"
                                              ? currentSearch?.selectedClass
                                              : selectedClass
                                          }
                                        >
                                          <li>
                                            <FormControlLabel
                                              className="custom-control-label-react"
                                              value="M"
                                              control={
                                                <Radio
                                                  className="economy-radio"
                                                  onChange={() => {
                                                    handleClass("Economy");
                                                    setFilterClass(
                                                      SELECTED_CLASS["Economy"]
                                                    );
                                                  }}
                                                  sx={{
                                                    color: "#003663",
                                                    "&.Mui-checked": {
                                                      color: "#003663",
                                                    },
                                                  }}
                                                />
                                              }
                                              label="Economy"
                                              sx={{
                                                ".MuiTypography-root": {
                                                  fontWeight: 500,
                                                  fontSize: 18,
                                                  fontFamily:
                                                    "'Poppins', sans-serif",
                                                  color: "#003663",
                                                },
                                                paddingLeft: 2,
                                              }}
                                            />
                                          </li>
                                          <li>
                                            <FormControlLabel
                                              className="custom-control-label-react"
                                              value="W"
                                              control={
                                                <Radio
                                                  className="economy-premium-radio"
                                                  onChange={() => {
                                                    handleClass(
                                                      "Economy Premium"
                                                    );
                                                    setFilterClass(
                                                      SELECTED_CLASS[
                                                      "Economy Premium"
                                                      ]
                                                    );
                                                  }}
                                                  sx={{
                                                    color: "#003663",
                                                    "&.Mui-checked": {
                                                      color: "#003663",
                                                    },
                                                  }}
                                                />
                                              }
                                              label="Economy Premium"
                                              sx={{
                                                ".MuiTypography-root": {
                                                  fontWeight: 500,
                                                  fontSize: 18,
                                                  fontFamily:
                                                    "'Poppins', sans-serif",
                                                  color: "#003663",
                                                },
                                                paddingLeft: 2,
                                              }}
                                            />
                                          </li>
                                          <li>
                                            <FormControlLabel
                                              className="custom-control-label-react"
                                              value="C"
                                              control={
                                                <Radio
                                                  className="business-radio"
                                                  onChange={() => {
                                                    handleClass("Business");
                                                    setFilterClass(
                                                      SELECTED_CLASS["Business"]
                                                    );
                                                  }}
                                                  sx={{
                                                    color: "#003663",
                                                    "&.Mui-checked": {
                                                      color: "#003663",
                                                    },
                                                  }}
                                                />
                                              }
                                              label="Business"
                                              sx={{
                                                ".MuiTypography-root": {
                                                  fontWeight: 500,
                                                  fontSize: 18,
                                                  fontFamily:
                                                    "'Poppins', sans-serif",
                                                  color: "#003663",
                                                },
                                                paddingLeft: 2,
                                              }}
                                            />
                                          </li>
                                        </RadioGroup>
                                      </FormControl>
                                    </ul>
                                  </ClickAwayListener>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Desktop */}
                    <div className="col-lg-9 col-md-9 col-12">
                      <div className="row default-div sprdiv">
                        {isMobile && (
                          <DrawerComponent
                            open={changeSearch}
                            id={"search"}
                            key={"search"}
                            setOpen={setChangeSearch}
                            headerText={translation("edit_your_search")}
                            anchor="top"
                            height="60vh"
                          >
                            <div>
                              <div class="col-lg-12 col-md-12 col-12">
                                <div class="col-lg-12 radiobg p-0 col-md-12 col-12">
                                  <div class="custom-control custom-radio">
                                    <input
                                      type="radio"
                                      id="return"
                                      name="customRadio"
                                      class="custom-control-input return-radio"
                                      value="return"
                                      checked={returnOrOneWay === "return"}
                                      onChange={() => {

                                        setReturnOrOneWay("return");
                                      }}
                                    />
                                    <label
                                      class="custom-control-label"
                                      for="return"
                                    >
                                      {translation("return")}
                                    </label>
                                  </div>
                                  <div class="custom-control custom-radio">
                                    <input
                                      type="radio"
                                      id="oneway"
                                      name="customRadio"
                                      class="custom-control-input oneway-radio"
                                      value="oneway"
                                      checked={returnOrOneWay === "oneway"}
                                      onChange={() => {
                                        setReturnOrOneWay("oneway");

                                      }}
                                    />
                                    <label
                                      class="custom-control-label"
                                      for="oneway"
                                    >
                                      {translation("one_way")}
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-6 ftinput col-md-6 col-12" >
                                <div className="form-group" style={{
                                  marginBottom: "10px"
                                }}>
                                  <input
                                    type="search"
                                    className="form-control flight1 custom-dropdown"
                                    // open-this="pickup-dropdown"
                                    value={
                                      originValue !== undefined
                                        ? originValue
                                        : (location.pathname !== "/" &&
                                          currentSearch?.cityNameFrom) ||
                                        ""
                                    }
                                    // disabled={isMobile ? true : false}
                                    onClick={() => {
                                      setViewOriginLookup(true);
                                    }}
                                    ref={originRef}
                                    onChange={(event) => {
                                      if (isMobile) {
                                        setViewOriginLookup(true);
                                      }
                                      setOriginValue(event.target.value);
                                      setFilter(
                                        "filterRoute",
                                        "originInput",
                                        event.target.value
                                      );
                                    }}
                                    id="viewOriginLookup"
                                    style={{
                                      caretColor: isMobile && "transparent",
                                      marginBottom: isMobile && "-1px",
                                    }}
                                    placeholder={translation("origin")}
                                  />
                                </div>
                                <span
                                  id="swap"
                                  style={{
                                    position: "absolute",
                                    background: "#003663",
                                    color: "#fff",
                                    borderRadius: "30px",
                                    bottom: "-16px",
                                    right: "18px",
                                    zIndex: 99,
                                    transform: "rotate(90deg)"
                                  }}
                                  onClick={() => {
                                    setViewDestinationLookup(false)
                                    setViewOriginLookup(false)
                                    setTravellerDropDownShow(false)
                                    const currentSearch =
                                      getItemFromLocalStorage("search");
                                    const cityNameFrom =
                                      currentSearch?.cityNameFrom || originValue;
                                    const cityNameTo =
                                      currentSearch?.cityNameTo ||
                                      destinationValue;
                                    const flyFrom =
                                      currentSearch?.flyFrom || originCode;
                                    const flyTo =
                                      currentSearch?.flyTo || destinationCode;

                                    localStorage.setItem(
                                      "search",
                                      JSON.stringify({
                                        ...currentSearch,
                                        flyTo: flyFrom,
                                        flyFrom: flyTo,
                                        cityNameFrom: cityNameTo,
                                        cityNameTo: cityNameFrom,
                                      })
                                    );

                                    setOriginValue(cityNameTo);
                                    setOriginCity(cityNameTo);
                                    setOriginCode(flyTo);

                                    setDestinationCity(cityNameFrom);
                                    setDestinationValue(cityNameFrom);
                                    setDestinationCode(flyFrom);
                                  }}
                                >
                                  <SwapHorizontalCircleIcon />
                                </span>
                              </div>

                              <div className="col-lg-6 ltinput col-md-6 col-12">
                                <div className="form-group" style={{
                                  marginBottom: "10px"
                                }}>
                                  <input
                                    type="search"
                                    className="form-control flight2 custom-dropdown"
                                    // open-this="pickup-dropdown"
                                    value={
                                      destinationValue !== undefined
                                        ? destinationValue
                                        : (location.pathname !== "/" &&
                                          currentSearch?.cityNameTo) ||
                                        ""
                                    }
                                    // disabled={viewDestinationLookup}
                                    onClick={() => {
                                      // setDestinationInputFocused(true);
                                      // setblurBackground(true);
                                      setViewDestinationLookup(true); 
                                    }}
                                    ref={destinationRef}
                                    onChange={(event) => {
                                      setViewDestinationLookup(true);
                                      setDestinationValue(event.target.value);
                                      setFilter(
                                        "filterRoute",
                                        "destinationInput",
                                        event.target.value
                                      );
                                    }}
                                    style={{
                                      caretColor: "transparent",
                                    }}
                                    placeholder={translation("destination")}
                                  />
                                </div>
                              </div>

                              <div
                                className="row daterow sprdiv"
                                style={{
                                  padding: "0 15px",
                                }}
                              >
                                <div
                                  className="col-lg-12 row ml-auto mr-auto w-100 datebox"
                                  style={{
                                    padding: "0 15px",
                                    border: "1px solid #ced4da",
                                    borderRadius: "6px",
                                  }}
                                >
                                  <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="form-group m-0">
                                      {/* This is being used */}

                                      <RangePickerMemo />
                                    </div>
                                  </div>
                                  {/* <div className="col-lg-6 col-md-6 col-6">
                              <div className="form-group m-0">
                              </div>
                            </div> */}
                                </div>
                              </div>

                              <div className="col-lg-6 col-md-6 col-12">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control bg-user"
                                    open-this="traveller-dropdown"
                                    onClick={() => {
                                      // setTravelerBlur(false)
                                      // setblurBackground(true)
                                      setTravellerDropDownShow(true);
                                    }}
                                    value={`${Number(options.adult) +
                                      Number(options.children) +
                                      Number(options.babies) +
                                      " " +
                                      translation("traveler") +
                                      (options.adult > 1 ||
                                        options.children ||
                                        options.babies
                                        ? translation("s")
                                        : "")
                                      } , ${translation(
                                        SELECTED_CABINS[selectedClass]
                                      )}`}
                                    placeholder={`${Number(options.adult) +
                                      Number(options.children) +
                                      Number(options.babies) +
                                      " " +
                                      translation("traveler") +
                                      ((options.adult > 1 ||
                                        options.children ||
                                        options.babies) &&
                                        translation("s"))
                                      } , ${translation(
                                        SELECTED_CABINS[selectedClass]
                                      )}`}
                                    ref={travellerRef}
                                    style={{
                                      caretColor: isMobile && "transparent",
                                    }}
                                  // onBlur={() => setTravelerBlur(false)}
                                  // onFocus={() => setTravelerBlur(false)}
                                  />
                                  <div className="traveller-dropdown">
                                    {travellerDropDownShow && (
                                      <ClickAwayListener
                                        onClickAway={(event) => { 
                                          if (event.target.className == "blurr2" || event.target.className == "btn-submit"
                                            || event.target.className == "date-picker date-picker-flight"
                                            || event.target.className == "form-control flight2 custom-dropdown"
                                            || event.target.className == "selected-date"
                                          ) {

                                            setTravellerDropDownShow(false);
                                            setblurBackground(false);
                                          }
                                        }}
                                      >
                                        <ul
                                          className="list-group"
                                          style={{
                                            display: "block",
                                            overflow: "hidden",
                                            minHeight: "400px",
                                          }}
                                        >
                                          <div className="traveller-hearder">
                                            <h6 className="px-3 pt-3 pb-0">
                                              {translation(
                                                "passenger_and_class"
                                              )}
                                            </h6>
                                            <div className="closeTravellerWindow">
                                              <FontAwesomeIcon
                                                onClick={() => {
                                                  setTravellerDropDownShow(
                                                    false
                                                  );
                                                  setblurBackground(false);
                                                }}
                                                size="lg"
                                                icon={faXmark}
                                              />
                                            </div>
                                          </div>
                                          <hr className="mb-0" />
                                          <li>
                                            <a className="list-group-item d-flex justify-content-between align-items-center">
                                              {translation(
                                                "adults"
                                              ).toUpperCase()}
                                              <br />
                                              {translation(
                                                "12_years_and_older"
                                              )}
                                              {/* here is the change */}
                                              <div className="quantity">
                                                <button
                                                  disabled={options.adult <= 1}
                                                  className={
                                                    options.adult <= 1
                                                      ? "disabled"
                                                      : "active"
                                                  }
                                                  onClick={() => {
                                                    handleOption(
                                                      "adult",
                                                      "minus"
                                                    );
                                                  }}
                                                >
                                                  -
                                                </button>
                                                <input
                                                  data-value="true"
                                                  type="text"
                                                  readonly=""
                                                  value={options.adult}
                                                />
                                                <button
                                                  className="active"
                                                  onClick={() =>
                                                    handleOption(
                                                      "adult",
                                                      "plus"
                                                    )
                                                  }
                                                >
                                                  +
                                                </button>
                                              </div>
                                            </a>
                                          </li>
                                          <li>
                                            <a className="list-group-item d-flex justify-content-between align-items-center">
                                              {translation(
                                                "children"
                                              ).toUpperCase()}
                                              <br />
                                              {translation("2_to_11_years")}
                                              <div className="quantity">
                                                <button
                                                  disabled={
                                                    options.children <= 0
                                                  }
                                                  className={
                                                    options.children <= 0
                                                      ? "disabled"
                                                      : "active"
                                                  }
                                                  onClick={() =>
                                                    handleOption(
                                                      "children",
                                                      "minus"
                                                    )
                                                  }
                                                >
                                                  -
                                                </button>
                                                <input
                                                  data-value="true"
                                                  type="text"
                                                  readonly=""
                                                  value={options.children}
                                                />
                                                <button
                                                  className="active"
                                                  onClick={() =>
                                                    handleOption(
                                                      "children",
                                                      "plus"
                                                    )
                                                  }
                                                >
                                                  +
                                                </button>
                                              </div>
                                            </a>
                                          </li>
                                          <li>
                                            <a className="list-group-item d-flex justify-content-between align-items-center">
                                              {translation(
                                                "babies"
                                              ).toUpperCase()}
                                              <br />
                                              {translation("under_2_years_old")}
                                              <div className="quantity">
                                                <button
                                                  disabled={options.babies <= 0}
                                                  className={
                                                    options.babies <= 0
                                                      ? "disabled"
                                                      : "active"
                                                  }
                                                  onClick={() =>
                                                    handleOption(
                                                      "babies",
                                                      "minus"
                                                    )
                                                  }
                                                >
                                                  -
                                                </button>
                                                <input
                                                  data-value="true"
                                                  type="text"
                                                  readonly=""
                                                  value={options.babies}
                                                />
                                                <button
                                                  className="active"
                                                  onClick={() =>
                                                    handleOption(
                                                      "babies",
                                                      "plus"
                                                    )
                                                  }
                                                >
                                                  +
                                                </button>
                                              </div>
                                            </a>
                                          </li>
                                          <FormControl>
                                            <RadioGroup
                                              defaultValue={
                                                currentSearch?.selectedClass &&
                                                  location.pathname !== "/"
                                                  ? currentSearch?.selectedClass
                                                  : selectedClass
                                              }
                                            >
                                              <li>
                                                <FormControlLabel
                                                  className="custom-control-label-react"
                                                  value="M"
                                                  control={
                                                    <Radio
                                                      className="economy-radio"
                                                      onChange={() => {
                                                        handleClass("Economy");
                                                        setFilterClass(
                                                          SELECTED_CLASS[
                                                          "Economy"
                                                          ]
                                                        );
                                                      }}
                                                      sx={{
                                                        color: "#003663",
                                                        "&.Mui-checked": {
                                                          color: "#003663",
                                                        },
                                                      }}
                                                    />
                                                  }
                                                  label={translation("Economy")}
                                                  sx={{
                                                    ".MuiTypography-root": {
                                                      fontWeight: 500,
                                                      fontSize: 18,
                                                      fontFamily:
                                                        "'Poppins', sans-serif",
                                                      color: "#003663",
                                                    },
                                                    paddingLeft: 2,
                                                  }}
                                                />
                                              </li>
                                              <li>
                                                <FormControlLabel
                                                  className="custom-control-label-react"
                                                  value="W"
                                                  control={
                                                    <Radio
                                                      className="economy-premium-radio"
                                                      onChange={() => {
                                                        handleClass(
                                                          "Economy Premium"
                                                        );
                                                        setFilterClass(
                                                          SELECTED_CLASS[
                                                          "Economy Premium"
                                                          ]
                                                        );
                                                      }}
                                                      sx={{
                                                        color: "#003663",
                                                        "&.Mui-checked": {
                                                          color: "#003663",
                                                        },
                                                      }}
                                                    />
                                                  }
                                                  label={translation(
                                                    "Premium Economy"
                                                  )}
                                                  sx={{
                                                    ".MuiTypography-root": {
                                                      fontWeight: 500,
                                                      fontSize: 18,
                                                      fontFamily:
                                                        "'Poppins', sans-serif",
                                                      color: "#003663",
                                                    },
                                                    paddingLeft: 2,
                                                  }}
                                                />
                                              </li>
                                              <li>
                                                <FormControlLabel
                                                  className="custom-control-label-react"
                                                  value="C"
                                                  control={
                                                    <Radio
                                                      className="business-radio"
                                                      onChange={() => {
                                                        handleClass("Business");
                                                        setFilterClass(
                                                          SELECTED_CLASS[
                                                          "Business"
                                                          ]
                                                        );
                                                      }}
                                                      sx={{
                                                        color: "#003663",
                                                        "&.Mui-checked": {
                                                          color: "#003663",
                                                        },
                                                      }}
                                                    />
                                                  }
                                                  label={translation(
                                                    "Business"
                                                  )}
                                                  sx={{
                                                    ".MuiTypography-root": {
                                                      fontWeight: 500,
                                                      fontSize: 18,
                                                      fontFamily:
                                                        "'Poppins', sans-serif",
                                                      color: "#003663",
                                                    },
                                                    paddingLeft: 2,
                                                  }}
                                                />
                                              </li>
                                            </RadioGroup>
                                          </FormControl>
                                        </ul>
                                      </ClickAwayListener>
                                    )}
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-3 col-md-3 col-12">
                                <div className="form-group">
                                  <button
                                    onClick={async (_) => {
                                      const currentSearch =
                                      getItemFromLocalStorage("search");
                                       
                                      const twoDaysFromNow = new Date(
                                        new Date()
                                      );
                                      twoDaysFromNow.setDate(
                                        new Date().getDate() + 2
                                      );
                                      const twoDaysFromNowDate = twoDaysFromNow;
                                      const isNotHomePage =
                                        location.pathname !== "/";
                                      const currentFlyFrom =
                                        flyFrom || currentSearch?.flyFrom || "";
                                      const currentFlyTo =
                                        flyTo || currentSearch?.flyTo || "";
                                      const currentDateFrom =
                                        dateFrom ||
                                        currentSearch?.dateFrom ||
                                        moment(new Date()).format("DD/MM/yyyy");
                                      const currentDateTo =
                                        dateTo ||
                                        currentSearch?.dateTo ||
                                        moment(twoDaysFromNowDate).format(
                                          "DD/MM/yyyy"
                                        );

                                      const currentReturnOrOneWay =
                                        isNotHomePage &&
                                          currentSearch?.returnOrOneWay
                                          ? currentSearch?.returnOrOneWay
                                          : returnOrOneWay;

                                      localStorage.setItem(
                                        "search",
                                        JSON.stringify({
                                          flyFrom: currentFlyFrom,
                                          flyTo: currentFlyTo,
                                          dateFrom: currentDateFrom,
                                          dateTo: currentDateTo,
                                          adults,
                                          babies,
                                          children,
                                          selectedClass,
                                          vehicleType: "aircraft",
                                          limit: 30,
                                          selectedAirlines:
                                            selectedAirlines || [],
                                          aTimeFrom: "0:00",
                                          aTimeTo: "24:00",
                                          dtimeFrom: "0:00",
                                          dtimeTo: "24:00",
                                          retAtimeFrom: null,
                                          retAtimeTo: null,
                                          retDtimeFrom: null,
                                          retDtimeTo: null,
                                          returnFromDate:
                                            currentReturnOrOneWay === "return"
                                              ? dateTo
                                              : null,
                                          returnToDate:
                                            currentReturnOrOneWay === "return"
                                              ? dateTo
                                              : null,
                                          returnOrOneWay:
                                            returnOrOneWay ||
                                            currentReturnOrOneWay,
                                          curr: "EUR",
                                          cityNameFrom:
                                            originCity ||
                                            currentSearch?.cityNameFrom,
                                          cityNameTo:
                                            destinationCity ||
                                            currentSearch?.cityNameTo,
                                          originCode:
                                            originCode ||
                                            currentSearch?.originCode,
                                          destinationCode:
                                            destinationCode ||
                                            currentSearch?.destinationCode,
                                        })
                                      );
                                      if (location.pathname === "/") {
                                        localStorage.setItem("filter", "{}");
                                      }
                                      setChangeSearch(false);
                                      navigate(`/search?session=${nanoid()}`);
                                    }}
                                    type="submit"
                                    className="btn-submit"
                                    disabled={
                                      (!originCity || !destinationCity) &&
                                      location.pathname === "/"
                                    }
                                  >
                                    {translation("search")}
                                    <img
                                      src={require("../assets/img/icon-search.png")}
                                      alt=""
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </DrawerComponent>
                        )}

                        {!isMobile && (
                          <Fragment>
                            <div
                              className="col-lg-6 ftinput col-md-6 col-12"
                              style={{
                                position: "relative",
                              }}
                            >
                              <div className="form-group">
                                <ClickAwayListener
                                  onClickAway={() => {
                                    if (!isMobile) {
                                      setViewOriginLookup(false);
                                      setblurBackground(false);
                                    }
                                    // setblurBackground(false);
                                  }}
                                // mouseEvent={false}
                                >
                                  <input
                                    type="search"
                                    className="form-control flight1 custom-dropdown"
                                    // open-this="pickup-dropdown"
                                    value={
                                      originValue !== undefined
                                        ? originValue
                                        : (location.pathname !== "/" &&
                                          currentSearch?.cityNameFrom) ||
                                        ""
                                    }
                                    onClick={() => {
                                      setViewOriginLookup(true);
                                      // setblurBackground(true)
                                    }}
                                    onBlur={() => {
                                      setViewOriginLookup(false);
                                      setblurBackground(false)
                                    }}
                                    ref={originRef}
                                    onChange={(event) => {
                                      setOriginValue(event.target.value);
                                      setFilter(
                                        "filterRoute",
                                        "originInput",
                                        event.target.value
                                      );
                                    }}
                                    placeholder={translation("origin")}
                                  />
                                </ClickAwayListener>
                                {originValue && (
                                  <LookupItems
                                    searchValue={originInput}
                                    items={locations}
                                    onClickHandler={(location) => {

                                      setOriginCity(location?.city_name + ' - ' + location?.name);
                                      setOriginValue(location?.city_name + ' - ' + location?.name);
                                      setOriginCode(location?.id);
                                      setFilter(
                                        "filterRoute",
                                        "origin",
                                        location
                                      );
                                      destinationRef.current.focus();
                                    }}
                                    inputFocus={true}
                                    openClass="pickup-dropdown"
                                  />
                                )}
                              </div>
                              <span
                                id="swap"
                                style={{
                                  position: "absolute",
                                  background: "#003663",
                                  color: "#fff",
                                  borderRadius: "30px",
                                  top: "20px",
                                  right: "-10px",
                                  zIndex: 99,
                                }}
                                onClick={() => {
                                  setViewDestinationLookup(false)
                                  setViewOriginLookup(false)
                                  setTravellerDropDownShow(false)
                                  const currentSearch =
                                    getItemFromLocalStorage("search");
                                  const cityNameFrom =
                                    currentSearch?.cityNameFrom || originValue;
                                  const cityNameTo =
                                    currentSearch?.cityNameTo ||
                                    destinationValue;
                                  const flyFrom =
                                    currentSearch?.flyFrom || originCode;
                                  const flyTo =
                                    currentSearch?.flyTo || destinationCode;  
                                  localStorage.setItem(
                                    "search",
                                    JSON.stringify({
                                      ...currentSearch,
                                      flyTo: flyFrom,
                                      flyFrom: flyTo,
                                      cityNameFrom: cityNameTo,
                                      cityNameTo: cityNameFrom,
                                    })
                                  );

                                  setOriginValue(cityNameTo);
                                  setOriginCity(cityNameTo);
                                  setOriginCode(flyTo);

                                  setDestinationCity(cityNameFrom);
                                  setDestinationValue(cityNameFrom);
                                  setDestinationCode(flyFrom);
                                }}
                              >
                                <SwapHorizontalCircleIcon />
                              </span>
                            </div>

                            <div className="col-lg-6 ltinput col-md-6 col-12">
                              <div className="form-group">
                                <ClickAwayListener
                                  onClickAway={() => {
                                    if (!isMobile) {
                                      setViewDestinationLookup(false);
                                      setblurBackground(false);
                                      setDestinationInputFocused(false);
                                    }
                                  }}
                                >
                                  <input
                                    type="search"
                                    className="form-control flight2 custom-dropdown"
                                    // open-this="pickup-dropdown"
                                    value={
                                      destinationValue !== undefined
                                        ? destinationValue
                                        : (location.pathname !== "/" &&
                                          currentSearch?.cityNameTo) ||
                                        ""
                                    }
                                    onFocus={() => {
                                      setViewDestinationLookup(true);
                                      setDestinationInputFocused(true);
                                    }}
                                    onClick={() => {
                                      setDestinationInputFocused(true);
                                      // if (!isMobile) {
                                      //   setblurBackground(true);
                                      // }
                                      setViewDestinationLookup(true);
                                    }}
                                    // onBlur={() => {
                                    //   setViewDestinationLookup(false);
                                    //   setblurBackground(false)
                                    // }}
                                    ref={destinationRef}
                                    onChange={(event) => {
                                      setViewDestinationLookup(true);
                                      setDestinationValue(event.target.value);
                                      setFilter(
                                        "filterRoute",
                                        "destinationInput",
                                        event.target.value
                                      );

                                    }}
                                    placeholder={translation("destination")}
                                  />
                                </ClickAwayListener>
                                {viewDestinationLookup && (
                                  <LookupItems
                                    searchValue={destinationInput}
                                    items={locations}
                                    onClickHandler={(location) => {

                                      setDestinationCity(location?.city_name + ' - ' + location?.name);
                                      setDestinationValue(location?.city_name + ' - ' + location?.name);
                                      setDestinationCode(location?.id);
                                      setFilter(
                                        "filterRoute",
                                        "destination",
                                        location
                                      );
                                      clickDateRange();

                                    }}
                                    visible={blurBackground}
                                    inputFocus={true}
                                    openClass="pickup-dropdown"
                                  />
                                )}
                              </div>
                            </div>
                          </Fragment>
                        )}

                        {!isMobile && (
                          <div className="col-lg-6 ftinput col-md-6 col-12">
                            <div
                              className="row daterow sprdiv"
                              style={
                                {
                                  // padding: "0 15px",
                                }
                              }
                            >
                              <div
                                className="col-lg-12 row ml-auto mr-auto w-100 datebox"
                                style={{
                                  // padding: "0 15px",
                                  // border: "1px solid #ced4da",
                                  borderRadius: "6px",
                                }}
                              >
                                <div className="col-lg-12 col-md-12 col-sm-12 w-100">
                                  <div className="form-group m-0" id="rangePickerId">
                                    {/* This is being used */}

                                    <RangePickerMemo
                                    />
                                  </div>
                                </div>
                                {/* <div className="col-lg-6 col-md-6 col-6">
                              <div className="form-group m-0">
                              </div>
                            </div> */}
                              </div>
                            </div>
                          </div>
                        )}

                        {!isMobile && (
                          <div className="col-lg-6 col-md-6 col-12">
                            <div className="form-group">
                              <input
                                id="traveller-dropdown-for-click"
                                type="text"
                                className="form-control bg-user"
                                open-this="traveller-dropdown"
                                onClick={() => {
                                  // setTravelerBlur(false)
                                  // setblurBackground(true)
                                  setTravellerDropDownShow(true);
                                }}
                                value={`${Number(options.adult) +
                                  Number(options.children) +
                                  Number(options.babies) +
                                  " " +
                                  translation("traveler") +
                                  (options.adult > 1 ||
                                    options.children ||
                                    options.babies
                                    ? translation("s")
                                    : "")
                                  } , ${translation(
                                    SELECTED_CABINS[selectedClass]
                                  )}`}
                                placeholder={`${Number(options.adult) +
                                  Number(options.children) +
                                  Number(options.babies) +
                                  " " +
                                  translation("traveler") +
                                  ((options.adult > 1 ||
                                    options.children ||
                                    options.babies) &&
                                    translation("s"))
                                  } , ${translation(
                                    SELECTED_CABINS[selectedClass]
                                  )}`}
                                ref={travellerRef}
                                style={{
                                  caretColor: isMobile && "transparent",
                                }}
                              // onBlur={() => setTravelerBlur(false)}
                              // onFocus={() => setTravelerBlur(false)}
                              />
                              <div className="traveller-dropdown">
                                {travellerDropDownShow && (
                                  <ClickAwayListener
                                    onClickAway={(event) => { 
                                      if (event.target.className == "blurr2" || event.target.className == "btn-submit"
                                        || event.target.className == "date-picker date-picker-flight"
                                        || event.target.className == "form-control flight2 custom-dropdown"
                                        || event.target.className == "selected-date"
                                      ) {

                                        setTravellerDropDownShow(false);
                                        setblurBackground(false);
                                      }
                                    }}
                                  >
                                    <ul
                                      className="list-group"
                                      style={{
                                        display: "block",
                                        overflow: "hidden",
                                        minHeight: "400px",
                                      }}
                                    >
                                      <div className="traveller-hearder">
                                        <h6 className="px-3 pt-3 pb-0">
                                          {translation("passenger_and_class")}
                                        </h6>
                                        <div className="closeTravellerWindow">
                                          <FontAwesomeIcon
                                            onClick={() => {
                                              setTravellerDropDownShow(false);
                                              setblurBackground(false);
                                            }}
                                            size="lg"
                                            icon={faXmark}
                                          />
                                        </div>
                                      </div>
                                      <hr className="mb-0" />
                                      <li>
                                        <a className="list-group-item d-flex justify-content-between align-items-center">
                                          {translation("adults").toUpperCase()}
                                          <br />
                                          {translation("12_years_and_older")}
                                          {/* here is the change */}
                                          <div className="quantity">
                                            <button
                                              disabled={options.adult <= 1}
                                              className={
                                                options.adult <= 1
                                                  ? "disabled"
                                                  : "active"
                                              }
                                              onClick={() => {
                                                handleOption("adult", "minus");
                                              }}
                                            >
                                              -
                                            </button>
                                            <input
                                              data-value="true"
                                              type="text"
                                              readonly=""
                                              value={options.adult}
                                            />
                                            <button
                                              className="active"
                                              onClick={() =>
                                                handleOption("adult", "plus")
                                              }
                                            >
                                              +
                                            </button>
                                          </div>
                                        </a>
                                      </li>
                                      <li>
                                        <a className="list-group-item d-flex justify-content-between align-items-center">
                                          {translation("children").toUpperCase()}
                                          <br />
                                          {translation("2_to_11_years")}
                                          <div className="quantity">
                                            <button
                                              disabled={options.children <= 0}
                                              className={
                                                options.children <= 0
                                                  ? "disabled"
                                                  : "active"
                                              }
                                              onClick={() =>
                                                handleOption(
                                                  "children",
                                                  "minus"
                                                )
                                              }
                                            >
                                              -
                                            </button>
                                            <input
                                              data-value="true"
                                              type="text"
                                              readonly=""
                                              value={options.children}
                                            />
                                            <button
                                              className="active"
                                              onClick={() =>
                                                handleOption("children", "plus")
                                              }
                                            >
                                              +
                                            </button>
                                          </div>
                                        </a>
                                      </li>
                                      <li>
                                        <a className="list-group-item d-flex justify-content-between align-items-center">
                                          {translation("babies").toUpperCase()}
                                          <br />
                                          {translation("under_2_years_old")}
                                          <div className="quantity">
                                            <button
                                              disabled={options.babies <= 0}
                                              className={
                                                options.babies <= 0
                                                  ? "disabled"
                                                  : "active"
                                              }
                                              onClick={() =>
                                                handleOption("babies", "minus")
                                              }
                                            >
                                              -
                                            </button>
                                            <input
                                              data-value="true"
                                              type="text"
                                              readonly=""
                                              value={options.babies}
                                            />
                                            <button
                                              className="active"
                                              onClick={() =>
                                                handleOption("babies", "plus")
                                              }
                                            >
                                              +
                                            </button>
                                          </div>
                                        </a>
                                      </li>
                                      <FormControl>
                                        <RadioGroup
                                          defaultValue={
                                            currentSearch?.selectedClass &&
                                              location.pathname !== "/"
                                              ? currentSearch?.selectedClass
                                              : selectedClass
                                          }
                                        >
                                          <li>
                                            <FormControlLabel
                                              className="custom-control-label-react"
                                              value="M"
                                              control={
                                                <Radio
                                                  className="economy-radio"
                                                  onChange={() => {
                                                    handleClass("Economy");
                                                    setFilterClass(
                                                      SELECTED_CLASS["Economy"]
                                                    );
                                                  }}
                                                  sx={{
                                                    color: "#003663",
                                                    "&.Mui-checked": {
                                                      color: "#003663",
                                                    },
                                                  }}
                                                />
                                              }
                                              label={translation("Economy")}
                                              sx={{
                                                ".MuiTypography-root": {
                                                  fontWeight: 500,
                                                  fontSize: 18,
                                                  fontFamily:
                                                    "'Poppins', sans-serif",
                                                  color: "#003663",
                                                },
                                                paddingLeft: 2,
                                              }}
                                            />
                                          </li>
                                          <li>
                                            <FormControlLabel
                                              className="custom-control-label-react"
                                              value="W"
                                              control={
                                                <Radio
                                                  className="economy-premium-radio"
                                                  onChange={() => {
                                                    handleClass(
                                                      "Economy Premium"
                                                    );
                                                    setFilterClass(
                                                      SELECTED_CLASS[
                                                      "Economy Premium"
                                                      ]
                                                    );
                                                  }}
                                                  sx={{
                                                    color: "#003663",
                                                    "&.Mui-checked": {
                                                      color: "#003663",
                                                    },
                                                  }}
                                                />
                                              }
                                              label={translation(
                                                "Premium Economy"
                                              )}
                                              sx={{
                                                ".MuiTypography-root": {
                                                  fontWeight: 500,
                                                  fontSize: 18,
                                                  fontFamily:
                                                    "'Poppins', sans-serif",
                                                  color: "#003663",
                                                },
                                                paddingLeft: 2,
                                              }}
                                            />
                                          </li>
                                          <li>
                                            <FormControlLabel
                                              className="custom-control-label-react"
                                              value="C"
                                              control={
                                                <Radio
                                                  className="business-radio"
                                                  onChange={() => {
                                                    handleClass("Business");
                                                    setFilterClass(
                                                      SELECTED_CLASS["Business"]
                                                    );
                                                  }}
                                                  sx={{
                                                    color: "#003663",
                                                    "&.Mui-checked": {
                                                      color: "#003663",
                                                    },
                                                  }}
                                                />
                                              }
                                              label={translation("Business")}
                                              sx={{
                                                ".MuiTypography-root": {
                                                  fontWeight: 500,
                                                  fontSize: 18,
                                                  fontFamily:
                                                    "'Poppins', sans-serif",
                                                  color: "#003663",
                                                },
                                                paddingLeft: 2,
                                              }}
                                            />
                                          </li>
                                        </RadioGroup>
                                      </FormControl>
                                    </ul>
                                  </ClickAwayListener>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                      {/* here */}

                      {isMobile && location.pathname === "/" && (
                        <div className="col-lg-6 ftinput col-md-6 col-12">
                          <div className="form-group">
                            <input
                              type="search"
                              className="form-control flight1 custom-dropdown"
                              // open-this="pickup-dropdown"
                              value={
                                originValue !== undefined
                                  ? originValue
                                  : (location.pathname !== "/" &&
                                    currentSearch?.cityNameFrom) ||
                                  ""
                              }
                              // disabled={isMobile ? true : false}
                              onClick={() => {
                                setViewOriginLookup(true);
                              }}
                              ref={originRef}
                              onChange={(event) => {
                                if (isMobile) {
                                  setViewOriginLookup(true);
                                }
                                setOriginValue(event.target.value);
                                setFilter(
                                  "filterRoute",
                                  "originInput",
                                  event.target.value
                                );
                              }}
                              id="viewOriginLookup"
                              style={{
                                caretColor: isMobile && "transparent",
                                marginBottom: isMobile && "-1px",
                              }}
                              placeholder={translation("origin")}
                            />
                          </div>
                          <span
                            id="swap"
                            style={{
                              position: "absolute",
                              background: "#003663",
                              color: "#fff",
                              borderRadius: "30px",
                              top: "36px",
                              right: "3px",
                              zIndex: 99,
                              transform: "rotate(90deg)",
                            }}
                            onClick={() => {
                              setViewDestinationLookup(false)
                              setViewOriginLookup(false)
                              setTravellerDropDownShow(false)
                              const currentSearch =
                                getItemFromLocalStorage("search");
                              const cityNameFrom =
                                currentSearch?.cityNameFrom || originValue;
                              const cityNameTo =
                                currentSearch?.cityNameTo || destinationValue;
                              const flyFrom =
                                currentSearch?.flyFrom || originCode;
                              const flyTo =
                                currentSearch?.flyTo || destinationCode;

                              localStorage.setItem(
                                "search",
                                JSON.stringify({
                                  ...currentSearch,
                                  flyTo: flyFrom,
                                  flyFrom: flyTo,
                                  cityNameFrom: cityNameTo,
                                  cityNameTo: cityNameFrom,
                                })
                              );

                              setOriginValue(cityNameTo);
                              setOriginCity(cityNameTo);
                              setOriginCode(flyTo);

                              setDestinationCity(cityNameFrom);
                              setDestinationValue(cityNameFrom);
                              setDestinationCode(flyFrom);
                            }}
                          >
                            <SwapHorizontalCircleIcon />
                          </span>
                        </div>
                      )}
                      <div className="row default-div sprdiv">
                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="col-lg-6 col-md-6 col-12">
                             
                          </div>

                          {location.pathname === "/" && isMobile && (
                            <div className="col-lg-6 ltinput col-md-6 col-12">
                              <div className="form-group">
                                <input
                                  type="search"
                                  className="form-control flight2 custom-dropdown"
                                  // open-this="pickup-dropdown"
                                  value={
                                    destinationValue !== undefined
                                      ? destinationValue
                                      : (location.pathname !== "/" &&
                                        currentSearch?.cityNameTo) ||
                                      ""
                                  }
                                  // disabled={viewDestinationLookup}
                                  onClick={() => {
                                    // setDestinationInputFocused(true);
                                    // setblurBackground(true);
                                    setViewDestinationLookup(true); 
                                  }}
                                  ref={destinationRef}
                                  onChange={(event) => {
                                    setViewDestinationLookup(true);
                                    setDestinationValue(event.target.value);
                                    setFilter(
                                      "filterRoute",
                                      "destinationInput",
                                      event.target.value
                                    );
                                  }}
                                  style={{
                                    caretColor: "transparent",
                                  }}
                                  placeholder={translation("destination")}
                                />
                              </div>
                            </div>
                          )}

                          {location.pathname === "/" && isMobile && (
                            <div className="row daterow sprdiv">
                              <div className="col-lg-12 row ml-auto mr-auto w-100 datebox whitebox p-0">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                  <div className="form-group m-0">
                                    {/* This is being used */}

                                    <RangePickerMemo />
                                  </div>
                                </div>
                                {/* <div className="col-lg-6 col-md-6 col-6">
                                        <div className="form-group m-0">
                                        </div>
                                      </div> */}
                              </div>
                            </div>
                          )}
                        </div>

                        {location.pathname === "/" && isMobile && (
                          <div className="col-lg-6 col-md-6 col-12">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control bg-user"
                                open-this="traveller-dropdown"
                                onClick={() => {
                                  // setTravelerBlur(false)
                                  // setblurBackground(true)
                                  setTravellerDropDownShow(true);
                                }}
                                value={`${Number(options.adult) +
                                  Number(options.children) +
                                  Number(options.babies) +
                                  " " +
                                  translation("traveler") +
                                  (options.adult > 1 ||
                                    options.children ||
                                    options.babies
                                    ? translation("s")
                                    : "")
                                  } , ${translation(
                                    SELECTED_CABINS[selectedClass]
                                  )}`}
                                placeholder={`${Number(options.adult) +
                                  Number(options.children) +
                                  Number(options.babies) +
                                  " " +
                                  translation("traveler") +
                                  ((options.adult > 1 ||
                                    options.children ||
                                    options.babies) &&
                                    translation("s"))
                                  } , ${translation(
                                    SELECTED_CABINS[selectedClass]
                                  )}`}
                                ref={travellerRef}
                                style={{
                                  caretColor: isMobile && "transparent",
                                }}
                              // onBlur={() => setTravelerBlur(false)}
                              // onFocus={() => setTravelerBlur(false)}
                              />
                              <div className="traveller-dropdown">
                                {travellerDropDownShow && (
                                  <ClickAwayListener
                                    onClickAway={(event) => { 
                                      if (event.target.className == "blurr2" || event.target.className == "btn-submit"
                                        || event.target.className == "date-picker date-picker-flight"
                                        || event.target.className == "form-control flight2 custom-dropdown"
                                        || event.target.className == "selected-date"
                                      ) {

                                        setTravellerDropDownShow(false);
                                        setblurBackground(false);
                                      }
                                    }}
                                  >
                                    <ul
                                      className="list-group"
                                      style={{
                                        display: "block",
                                        overflow: "hidden",
                                        minHeight: "400px",
                                      }}
                                    >
                                      <div className="traveller-hearder">
                                        <h6 className="px-3 pt-3 pb-0">
                                          {translation("passenger_and_class")}
                                        </h6>
                                        <div className="closeTravellerWindow">
                                          <FontAwesomeIcon
                                            onClick={() => {
                                              setTravellerDropDownShow(false);
                                              setblurBackground(false);
                                            }}
                                            size="lg"
                                            icon={faXmark}
                                          />
                                        </div>
                                      </div>
                                      <hr className="mb-0" />
                                      <li>
                                        <a className="list-group-item d-flex justify-content-between align-items-center">
                                          {translation("adults").toUpperCase()}
                                          <br />
                                          {translation("12_years_and_older")}
                                          {/* here is the change */}
                                          <div className="quantity">
                                            <button
                                              disabled={options.adult <= 1}
                                              className={
                                                options.adult <= 1
                                                  ? "disabled"
                                                  : "active"
                                              }
                                              onClick={() => {
                                                handleOption("adult", "minus");
                                              }}
                                            >
                                              -
                                            </button>
                                            <input
                                              data-value="true"
                                              type="text"
                                              readonly=""
                                              value={options.adult}
                                            />
                                            <button
                                              className="active"
                                              onClick={() =>
                                                handleOption("adult", "plus")
                                              }
                                            >
                                              +
                                            </button>
                                          </div>
                                        </a>
                                      </li>
                                      <li>
                                        <a className="list-group-item d-flex justify-content-between align-items-center">
                                          {translation("children").toUpperCase()}
                                          <br />
                                          {translation("2_to_11_years")}
                                          <div className="quantity">
                                            <button
                                              disabled={options.children <= 0}
                                              className={
                                                options.children <= 0
                                                  ? "disabled"
                                                  : "active"
                                              }
                                              onClick={() =>
                                                handleOption(
                                                  "children",
                                                  "minus"
                                                )
                                              }
                                            >
                                              -
                                            </button>
                                            <input
                                              data-value="true"
                                              type="text"
                                              readonly=""
                                              value={options.children}
                                            />
                                            <button
                                              className="active"
                                              onClick={() =>
                                                handleOption("children", "plus")
                                              }
                                            >
                                              +
                                            </button>
                                          </div>
                                        </a>
                                      </li>
                                      <li>
                                        <a className="list-group-item d-flex justify-content-between align-items-center">
                                          {translation("babies").toUpperCase()}
                                          <br />
                                          {translation("under_2_years_old")}
                                          <div className="quantity">
                                            <button
                                              disabled={options.babies <= 0}
                                              className={
                                                options.babies <= 0
                                                  ? "disabled"
                                                  : "active"
                                              }
                                              onClick={() =>
                                                handleOption("babies", "minus")
                                              }
                                            >
                                              -
                                            </button>
                                            <input
                                              data-value="true"
                                              type="text"
                                              readonly=""
                                              value={options.babies}
                                            />
                                            <button
                                              className="active"
                                              onClick={() =>
                                                handleOption("babies", "plus")
                                              }
                                            >
                                              +
                                            </button>
                                          </div>
                                        </a>
                                      </li>
                                      <FormControl>
                                        <RadioGroup
                                          defaultValue={
                                            currentSearch?.selectedClass &&
                                              location.pathname !== "/"
                                              ? currentSearch?.selectedClass
                                              : selectedClass
                                          }
                                        >
                                          <li>
                                            <FormControlLabel
                                              className="custom-control-label-react"
                                              value="M"
                                              control={
                                                <Radio
                                                  className="economy-radio"
                                                  onChange={() => {
                                                    handleClass("Economy");
                                                    setFilterClass(
                                                      SELECTED_CLASS["Economy"]
                                                    );
                                                  }}
                                                  sx={{
                                                    color: "#003663",
                                                    "&.Mui-checked": {
                                                      color: "#003663",
                                                    },
                                                  }}
                                                />
                                              }
                                              label={translation("Economy")}
                                              sx={{
                                                ".MuiTypography-root": {
                                                  fontWeight: 500,
                                                  fontSize: 18,
                                                  fontFamily:
                                                    "'Poppins', sans-serif",
                                                  color: "#003663",
                                                },
                                                paddingLeft: 2,
                                              }}
                                            />
                                          </li>
                                          <li>
                                            <FormControlLabel
                                              className="custom-control-label-react"
                                              value="W"
                                              control={
                                                <Radio
                                                  className="economy-premium-radio"
                                                  onChange={() => {
                                                    handleClass(
                                                      "Economy Premium"
                                                    );
                                                    setFilterClass(
                                                      SELECTED_CLASS[
                                                      "Economy Premium"
                                                      ]
                                                    );
                                                  }}
                                                  sx={{
                                                    color: "#003663",
                                                    "&.Mui-checked": {
                                                      color: "#003663",
                                                    },
                                                  }}
                                                />
                                              }
                                              label={translation(
                                                "Premium Economy"
                                              )}
                                              sx={{
                                                ".MuiTypography-root": {
                                                  fontWeight: 500,
                                                  fontSize: 18,
                                                  fontFamily:
                                                    "'Poppins', sans-serif",
                                                  color: "#003663",
                                                },
                                                paddingLeft: 2,
                                              }}
                                            />
                                          </li>
                                          <li>
                                            <FormControlLabel
                                              className="custom-control-label-react"
                                              value="C"
                                              control={
                                                <Radio
                                                  className="business-radio"
                                                  onChange={() => {
                                                    handleClass("Business");
                                                    setFilterClass(
                                                      SELECTED_CLASS["Business"]
                                                    );
                                                  }}
                                                  sx={{
                                                    color: "#003663",
                                                    "&.Mui-checked": {
                                                      color: "#003663",
                                                    },
                                                  }}
                                                />
                                              }
                                              label={translation("Business")}
                                              sx={{
                                                ".MuiTypography-root": {
                                                  fontWeight: 500,
                                                  fontSize: 18,
                                                  fontFamily:
                                                    "'Poppins', sans-serif",
                                                  color: "#003663",
                                                },
                                                paddingLeft: 2,
                                              }}
                                            />
                                          </li>
                                        </RadioGroup>
                                      </FormControl>
                                    </ul>
                                  </ClickAwayListener>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    {!isMobile && location.pathname !== "/" && (
                      <div className="col-lg-3 col-md-3 col-12">
                        <div className="form-group">
                          <button
                            onClick={async (_) => {
                              const currentSearch =
                                getItemFromLocalStorage("search");

                              const twoDaysFromNow = new Date(new Date());
                              twoDaysFromNow.setDate(new Date().getDate() + 2);
                              const twoDaysFromNowDate = twoDaysFromNow;
                              const isNotHomePage = location.pathname !== "/";
                              const currentFlyFrom =
                                flyFrom || currentSearch?.flyFrom || "";
                              const currentFlyTo =
                                flyTo || currentSearch?.flyTo || "";
                              const currentDateFrom =
                                dateFrom ||
                                currentSearch?.dateFrom ||
                                moment(new Date()).format("DD/MM/yyyy");
                              const currentDateTo =
                                dateTo ||
                                currentSearch?.dateTo ||
                                moment(twoDaysFromNowDate).format("DD/MM/yyyy");

                              const currentReturnOrOneWay =
                                isNotHomePage && currentSearch?.returnOrOneWay
                                  ? currentSearch?.returnOrOneWay
                                  : returnOrOneWay;

                              localStorage.setItem(
                                "search",
                                JSON.stringify({
                                  flyFrom: currentFlyFrom,
                                  flyTo: currentFlyTo,
                                  dateFrom: currentDateFrom,
                                  dateTo: currentDateTo,
                                  adults,
                                  babies,
                                  children,
                                  selectedClass,
                                  vehicleType: "aircraft",
                                  limit: 30,
                                  selectedAirlines: selectedAirlines || [],
                                  aTimeFrom: "0:00",
                                  aTimeTo: "24:00",
                                  dtimeFrom: "0:00",
                                  dtimeTo: "24:00",
                                  retAtimeFrom: null,
                                  retAtimeTo: null,
                                  retDtimeFrom: null,
                                  retDtimeTo: null,
                                  returnFromDate:
                                    currentReturnOrOneWay === "return"
                                      ? currentDateTo
                                      : null,
                                  returnToDate:
                                    currentReturnOrOneWay === "return"
                                      ? currentDateTo
                                      : null,
                                  returnOrOneWay:
                                    returnOrOneWay || currentReturnOrOneWay,
                                  curr: "EUR",
                                  cityNameFrom:
                                    originCity || currentSearch?.cityNameFrom,
                                  cityNameTo:
                                    destinationCity ||
                                    currentSearch?.cityNameTo,
                                  originCode:
                                    originCode || currentSearch?.originCode,
                                  destinationCode:
                                    destinationCode ||
                                    currentSearch?.destinationCode,
                                })
                              );
                              if (location.pathname === "/") {
                                localStorage.setItem("filter", "{}");
                              }

                              navigate(`/search?session=${nanoid()}`);
                            }}
                            type="submit"
                            className="btn-submit"
                            disabled={
                              (!originCity || !destinationCity) &&
                              location.pathname === "/"
                            }
                          >
                            {translation("search")}
                            <img
                              src={require("../assets/img/icon-search.png")}
                              alt=""
                            />
                          </button>
                        </div>
                      </div>
                    )}

                    {!changeSearch && location.pathname === "/" && (
                      <div className="col-lg-3 col-md-3 col-12">
                        <div className="form-group">
                          <button
                            onClick={async (_) => {
                              const currentSearch =
                                getItemFromLocalStorage("search");

                              const twoDaysFromNow = new Date(new Date());
                              twoDaysFromNow.setDate(new Date().getDate() + 2);
                              const twoDaysFromNowDate = twoDaysFromNow;
                              const isNotHomePage = location.pathname !== "/";
                              const currentFlyFrom =
                                flyFrom || currentSearch?.flyFrom || "";
                              const currentFlyTo =
                                flyTo || currentSearch?.flyTo || "";
                              const currentDateFrom =
                                dateFrom ||
                                currentSearch?.dateFrom ||
                                moment(new Date()).format("DD/MM/yyyy");
                              const currentDateTo =
                                dateTo ||
                                currentSearch?.dateTo ||
                                moment(twoDaysFromNowDate).format("DD/MM/yyyy");

                              const currentReturnOrOneWay =
                                isNotHomePage && currentSearch?.returnOrOneWay
                                  ? currentSearch?.returnOrOneWay
                                  : returnOrOneWay;

                              localStorage.setItem(
                                "search",
                                JSON.stringify({
                                  flyFrom: currentFlyFrom,
                                  flyTo: currentFlyTo,
                                  dateFrom: currentDateFrom,
                                  dateTo: currentDateTo,
                                  adults,
                                  babies,
                                  children,
                                  selectedClass,
                                  vehicleType: "aircraft",
                                  limit: 30,
                                  selectedAirlines: selectedAirlines || [],
                                  aTimeFrom: "0:00",
                                  aTimeTo: "24:00",
                                  dtimeFrom: "0:00",
                                  dtimeTo: "24:00",
                                  retAtimeFrom: null,
                                  retAtimeTo: null,
                                  retDtimeFrom: null,
                                  retDtimeTo: null,
                                  returnFromDate:
                                    currentReturnOrOneWay === "return"
                                      ? dateTo
                                      : null,
                                  returnToDate:
                                    currentReturnOrOneWay === "return"
                                      ? dateTo
                                      : null,
                                  returnOrOneWay:
                                    returnOrOneWay || currentReturnOrOneWay,
                                  curr: "EUR",
                                  cityNameFrom:
                                    originCity || currentSearch?.cityNameFrom,
                                  cityNameTo:
                                    destinationCity ||
                                    currentSearch?.cityNameTo,
                                  originCode:
                                    originCode || currentSearch?.originCode,
                                  destinationCode:
                                    destinationCode ||
                                    currentSearch?.destinationCode,
                                })
                              );
                              if (location.pathname === "/") {
                                localStorage.setItem("filter", "{}");
                              }

                              navigate(`/search?session=${nanoid()}`);
                            }}
                            type="submit"
                            className="btn-submit"
                            disabled={
                              (!originCity || !destinationCity) &&
                              location.pathname === "/"
                            }
                          >
                            {translation("search")}
                            <img
                              src={require("../assets/img/icon-search.png")}
                              alt=""
                            />
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div id="tab-2" className="tab-pane fade">
                  <div className="row pt-4 mt-2 sprdiv align-items-end">
                    <div className="col-lg-9 col-md-9 col-12">
                      <div className="row sprdiv">
                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="form-group"></div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control bg-user custom-dropdown"
                              open-this="departure-dropdown2"
                              placeholder="Guest’s citizenship"
                            />
                            <div className="departure-dropdown2">
                              <ul className="list-group">
                                <li>
                                  <a
                                    href="#"
                                    className="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    India
                                    <span className="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    className="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Australia
                                    <span className="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    className="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Canada
                                    <span className="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    className="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Pakistan
                                    <span className="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    className="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    London
                                    <span className="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    className="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    New Zealand
                                    <span className="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    className="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Bangladesh
                                    <span className="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    className="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    India
                                    <span className="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    className="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Australia
                                    <span className="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    className="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Canada
                                    <span className="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    className="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Pakistan
                                    <span className="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    className="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    London
                                    <span className="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    className="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    New Zealand
                                    <span className="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    className="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Bangladesh
                                    <span className="badge badge-primary badge-pill">
                                      AMS
                                    </span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row sprdiv">
                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="row daterow sprdiv">
                            <div className="col-lg-12 row ml-auto mr-auto w-100 datebox whitebox p-0">
                              <h1>asdfasdf</h1>
                              <div className="col-lg-6 col-md-6 col-6">
                                <div className="form-group m-0"></div>
                              </div>
                              <div className="col-lg-6 col-md-6 col-6">
                                <div className="form-group m-0">
                                  <input
                                    type="text"
                                    className="form-control bg-calendar to-date"
                                    placeholder="27 Sep"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control bg-user custom-dropdown"
                              open-this="traveller-dropdown2"
                              placeholder="2 Adults | 1 Children | 1 Room"
                            />
                            <div className="traveller-dropdown2">
                              <ul className="list-group">
                                <li>
                                  <a
                                    href="#"
                                    className="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    {translation("adults").toUpperCase()}
                                    <br />
                                    {translation("12_years_and_older")}
                                    <div className="quantity">
                                      <button className="disabled">-</button>
                                      <input
                                        data-value="true"
                                        type="text"
                                        readonly=""
                                        value="1"
                                      />
                                      <button className="active">+</button>
                                    </div>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    className="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    {translation("children").toUpperCase()}
                                    <br />
                                    {translation("2_to_11_years")}
                                    <div className="quantity">
                                      <button className="disabled">-</button>
                                      <input
                                        data-value="true"
                                        type="text"
                                        readonly=""
                                        value="1"
                                      />
                                      <button className="active">+</button>
                                    </div>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    className="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    {translation("babies").toUpperCase()}
                                    <br />
                                    {translation("under_2_years_old")}
                                    <div className="quantity">
                                      <button className="disabled">-</button>
                                      <input
                                        data-value="true"
                                        type="text"
                                        readonly=""
                                        value="1"
                                      />
                                      <button className="active">+</button>
                                    </div>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    className="list-group-item d-flex justify-content-between align-items-center"
                                  >
                                    Children and Rooms
                                    <br />
                                    {translation("under_2_years_old")}
                                    <div className="quantity">
                                      <button className="disabled">-</button>
                                      <input
                                        data-value="true"
                                        type="text"
                                        readonly=""
                                        value="1"
                                      />
                                      <button className="active">+</button>
                                    </div>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-12">
                      <div className="form-group">
                        <button type="submit" className="btn-submit">
                          Search{" "}
                          <img
                            src={require("../assets/img/icon-search.png")}
                            alt=""
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div class="blurr"></div>

      {/* Mobile view  */}
      {isMobile && (
        <Fragment>
          <DrawerComponent
            id={"origin"}
            key={"origin"}
            open={viewOriginLookup}
            setOpen={setViewOriginLookup}
            headerText={translation && translation("select_origin")}
            anchor="bottom"
          >
            <div
              style={{
                padding: "10px",
              }}
            >
              <input
                type="search"
                className="form-control flight1 custom-dropdown"
                value={
                  originValue !== undefined
                    ? originValue
                    : (location.pathname !== "/" &&
                      currentSearch?.cityNameFrom) ||
                    ""
                }
                autoFocus={true}
                onChange={(event) => {
                  setOriginValue(event.target.value);
                  setFilter("filterRoute", "originInput", event.target.value);
                }}
              // placeholder={translation("origin")}
              />
              <LookupItems
                searchValue={originInput}
                items={locations}
                onClickHandler={(location) => {
                  setblurBackground(false);
                  setOriginCity(location?.city_name + ' - ' + location?.name);
                  setOriginValue(location?.city_name + ' - ' + location?.name);
                  setOriginCode(location?.id);
                  setFilter("filterRoute", "origin", location);
                  // setViewOriginLookup(false);
                }}
                visible={blurBackground}
                inputFocus={true}
                openClass="pickup-dropdown"
              />
              <li
                class="d-block d-lg-none d-md-none"
                style={{
                  padding: " 0px 18px",
                }}
              >
                <button
                  type="submit"
                  class="btn-submit"
                  onClick={() => {
                    setViewOriginLookup(false);
                  }}
                >
                  {translation && translation("done")}
                </button>
              </li>
            </div>
          </DrawerComponent>

          <DrawerComponent
            id={"destination"}
            key={"destination"}
            open={viewDestinationLookup}
            headerText={translation && translation("select_destination")}
            anchor="bottom"
            setOpen={setViewDestinationLookup}
          >
            <div
              style={{
                padding: "10px",
              }}
            >
              <input
                type="search"
                className="form-control flight1 custom-dropdown"
                value={destinationValue}
                onChange={(event) => {
                  // setViewDestinationLookup(true);
                  setDestinationValue(event.target.value);
                  setFilter(
                    "filterRoute",
                    "destinationInput",
                    event.target.value
                  );
                }}
                autoFocus={true}
              />
              <LookupItems
                searchValue={destinationInput}
                items={locations}
                onClickHandler={(location) => {
                  setblurBackground(false);
                  setDestinationCity(location?.city_name + ' - ' + location?.name);
                  setDestinationValue(location?.city_name + ' - ' + location?.name);
                  setDestinationCode(location?.id);
                  setFilter("filterRoute", "destination", location);
                  // setViewDestinationLookup(false);
                }}
                visible={blurBackground}
                inputFocus={true}
                openClass="pickup-dropdown"
              />
              <li
                class="d-block d-lg-none d-md-none"
                style={{
                  padding: " 0px 18px",
                }}
              >
                <button
                  type="submit"
                  class="btn-submit"
                  onClick={() => {
                    setViewDestinationLookup(false);
                  }}
                >
                  {translation && translation("done")}
                </button>
              </li>
            </div>
          </DrawerComponent>

          <DrawerComponent
            id={"passenger"}
            key={"passenger"}
            headerText={translation && translation("passenger_and_class")}
            anchor="bottom"
            open={travellerDropDownShow}
            setOpen={setTravellerDropDownShow}
          >
            <div class="col-lg-6 col-md-6 col-12">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control bg-user custom-dropdown"
                  open-this="traveller-dropdown"
                  placeholder="1 Traveler, Economy"
                />
                <div class="traveller-dropdown p-class">
                  <ul class="list-group" style={{ marginTop: "85px" }}>
                    <li>
                      <div class="list-group-item d-flex justify-content-between align-items-center">
                        <span class="nme">
                          {translation && translation("adults")}
                          <br />
                          <span class="s2">
                            {translation && translation("12_years_and_older")}
                          </span>
                        </span>
                        <br />
                        <span class="quantity">
                          <button
                            disabled={options.adult <= 1}
                            className={
                              options.adult <= 1 ? "disabled" : "active"
                            }
                            onClick={() => {
                              handleOption("adult", "minus");
                            }}
                          >
                            -
                          </button>
                          <input
                            data-value="true"
                            type="text"
                            readonly=""
                            value={options.adult}
                            disabled
                          />
                          <button
                            className="active"
                            onClick={() => handleOption("adult", "plus")}
                          >
                            +
                          </button>
                        </span>
                      </div>
                    </li>
                    <li>
                      <div class="list-group-item d-flex justify-content-between align-items-center">
                        <span class="nme">
                          {translation && translation("children")}
                          <br />
                          <span class="s2">
                            {translation && translation("2_to_11_years")}
                          </span>
                        </span>
                        <span class="quantity">
                          <button
                            disabled={options.children <= 0}
                            className={
                              options.children <= 0 ? "disabled" : "active"
                            }
                            onClick={() => handleOption("children", "minus")}
                          >
                            -
                          </button>
                          <input
                            data-value="true"
                            type="text"
                            readonly=""
                            value={options.children}
                            disabled
                          />
                          <button
                            className="active"
                            onClick={() => handleOption("children", "plus")}
                          >
                            +
                          </button>
                        </span>
                      </div>
                    </li>
                    <li>
                      <div class="list-group-item d-flex justify-content-between align-items-center">
                        <span class="nme">
                          {translation && translation("babies")}
                          <br />
                          <span class="s2">
                            {translation && translation("under_2_years_old")}
                          </span>
                        </span>
                        <span class="quantity">
                          <button
                            data-decrease
                            disabled={options.babies <= 0}
                            className={
                              options.babies <= 0 ? "disabled" : "active"
                            }
                            onClick={() => handleOption("babies", "minus")}
                          >
                            -
                          </button>
                          <input
                            data-value="true"
                            type="text"
                            readonly=""
                            value={options.babies}
                            disabled
                          />
                          <button
                            className="active"
                            onClick={() => handleOption("babies", "plus")}
                          >
                            +
                          </button>
                        </span>
                      </div>
                    </li>
                    <li class="custom-control mt-sp custom-radio normal-radio">
                      <input
                        type="radio"
                        id="customRadio-val-1"
                        name="customRadio"
                        class="custom-control-input"
                        value="M"
                        checked={selectedClass === "M"}
                        onChange={() => {
                          handleClass("Economy");
                          setFilterClass(SELECTED_CLASS["Economy"]);
                        }}
                      />
                      <label
                        class="custom-control-label"
                        for="customRadio-val-1"
                        style={
                          selectedClass === "M"
                            ? {
                              background: "#003663",
                              color: "#fff",
                            }
                            : {}
                        }
                      >
                        {" "}
                        {translation && translation("Economy")}
                      </label>
                    </li>
                    <li class="custom-control custom-radio normal-radio">
                      <input
                        type="radio"
                        id="customRadio-val-2"
                        name="customRadio"
                        value="W"
                        onChange={() => {
                          handleClass("Economy Premium");
                          setFilterClass(SELECTED_CLASS["Economy Premium"]);
                        }}
                        checked={selectedClass === "W"}
                        class="custom-control-input"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadio-val-2"
                        style={
                          selectedClass === "W"
                            ? {
                              background: "#003663",
                              color: "#fff",
                            }
                            : {}
                        }
                      >
                        {" "}
                        {translation && translation("Premium Economy")}
                      </label>
                    </li>
                    <li class="custom-control custom-radio normal-radio">
                      <input
                        type="radio"
                        id="customRadio-val-3"
                        value="C"
                        onChange={() => {
                          handleClass("Business");
                          setFilterClass(SELECTED_CLASS["Business"]);
                        }}
                        checked={selectedClass === "C"}
                        name="customRadio"
                        class="custom-control-input"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadio-val-3"
                        style={
                          selectedClass === "C"
                            ? {
                              background: "#003663",
                              color: "#fff",
                            }
                            : {}
                        }
                      >
                        {" "}
                        {translation && translation("Business")}
                      </label>
                    </li>

                    <li class="d-block d-lg-none d-md-none">
                      <button
                        type="submit"
                        class="btn-submit"
                        onClick={() => {
                          setTravelerBlur(false);
                          setTravellerDropDownShow(false);
                        }}
                      >
                        {translation && translation("done")}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </DrawerComponent>
        </Fragment>
      )}

      {(viewDestinationLookup || viewOriginLookup || travellerDropDownShow) && (
        <div class="blurr2"></div>
      )}
    </section>
  );
};

export default Banner;
