import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Component } from "react";
import Home from "./pages/flights/home/Home";
import Search from "./pages/flights/search/Search";
import $ from "jquery";
import WOW from "wowjs";

import Booking from "./pages/flights/booking/Booking";
import { FlightDetails } from "./pages/flights/booking/FlightDetails";
import OkPayment from "./pages/OkPayment";
import FailedPayment from "./pages/FailedPayment";

import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Hotel from "./pages/hotels/home/Hotel";
import HotelSearch from "./pages/hotels/search/Search";
import HotelBooking from "./pages/hotels/search/Booking";
import BookingDetails from "./pages/flights/booking/BookingDetails";
import { PrivacyPage } from "./pages/Privacy";
import { InformationPage } from "./pages/InformationPage";
import VirtualInterliningPage from "./pages/virtualInterlining";
import ContactFormPage from "./pages/ContactFormPage";
import RrethNeshPage from "./pages/RrethNesh";
import AboutUsPage from "./pages/AboutUs";
import AboutUsDEPage from "./pages/AboutUsDE";
import HowToPage from "./pages/HowTo";


// Initialize i18next
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: require("./languages/en.json"),
      },
      al: {
        translation: require("./languages/al.json"),
      },
      de: {
        translation: require("./languages/de.json"),
      },
    },
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // React already escapes values
    },
  });

export default class App extends Component {
  jQueryCode = () => {
    // Navbar Menu JS
    $(".scroll-btn, .navbar .navbar-nav li a").on("click", function (e) {
      var anchor = $(this);
      $("html, body")
        .stop()
        .animate(
          {
            scrollTop: $(anchor.attr("href")).offset().top - 65,
          },
          1000
        );
      e.preventDefault();
    });
    $(".navbar .navbar-nav li a").on("click", function () {
      $(".navbar-collapse").collapse("hide");
    });

    // Menu Icon JS
    $(".menu-icon").on("click", function () {
      $(".menu-icon").toggleClass("active");
    });
    $(".menu-icon").on("click", function () {
      $(".sidebar").toggleClass("active");
    });

    // Preloader JS
    $(window).on("load", function () {
      $(".preloader").fadeOut();
    });

    // WOW Animation JS
    if ($(".wow").length) {
      var wow = new WOW({
        mobile: false,
      });
      wow.init();
    }

    $(".custom-dropdown").on("focus", function () {
      var dropdownClass = $(this).attr("open-this");
      $("." + dropdownClass + " ul").fadeIn();
      // $(".blurr").show();
    });

    $(".custom-dropdown").on("focusout", function () {
      var dropdownClass = $(this).attr("open-this");
      $("." + dropdownClass + " ul").fadeOut();
      $(".blurr").hide();
    });

    /*
    $("#date-from-id, #date-to-id").on("focus", function(){
      $(".blurr").show();
    });

    $("#date-from-id, #date-to-id").on("focusout", function(){
      $(".blurr").hide();
    });*/

    // $(".multi-radio").on("change", function () {
    //   if ($(".multi-radio > input:checked").is(":checked")) {
    //     $(".multi-city").removeClass("d-none");
    //     // $(".default-div").addClass("d-none");
    //     // $(".one-way").addClass("d-none");
    //   } else {
    //     // $(".multi-city").addClass("d-none");
    //     // $(".one-way").addClass("d-none");
    //     $(".default-div").removeClass("d-none");
    //   }
    // });

    // $(".oneway-radio").on("change", function () {
    //   if ($(".oneway-radio > input:checked").is(":checked")) {
    //     $(".one-way").removeClass("d-none");
    //     $(".default-div").addClass("d-none");
    //     $(".multi-city").addClass("d-none");
    //   } else {
    //     // $(".one-way").addClass("d-none");
    //     $(".default-div").removeClass("d-none");
    //     $(".multi-city").addClass("d-none");
    //   }
    // });

    // $(".return-radio").on("change", function () {
    //   if ($(".return-radio > input:checked").is(":checked")) {
    //     $(".multi-city").addClass("d-none");
    //     // $(".one-way").addClass("d-none");
    //     $(".default-div").removeClass("d-none");
    //   } else {
    //     $(".multi-city").removeClass("d-none");
    //     // $(".one-way").addClass("d-none");
    //     $(".default-div").addClass("d-none");
    //   }
    // });

    // console.log("slider", $(".slider-carousel"));
    if ($(".package-carousel").length) {
      // $(".slider-carousel").owlCarousel({
      //   margin: 10,
      //   loop: true,
      //   autoWidth: true,
      //   items: 4,
      // });
      // $(".img-carousel").owlCarousel({
      //   margin: 0,
      //   loop: true,
      //   dots: false,
      //   nav: true,
      //   items: 1,
      // });
      // $(".package-carousel").owlCarousel({
      //   margin: 0,
      //   loop: true,
      //   dots: false,
      //   nav: true,
      //   items: 1,
      // });
    }
  };

  componentDidMount() {
    this.jQueryCode();
  }

  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/hotels" element={<Hotel />}></Route>
          <Route path="/hotels/search" element={<HotelSearch />}></Route>
          <Route path="/hotels/details" element={<HotelSearch />}></Route>
          <Route path="/search" element={<Search />}></Route>
          <Route path="/reservation" element={<FlightDetails />}></Route>
          <Route path="/booking" element={<Booking />}></Route>
          <Route path="/bookingDetails" element={<BookingDetails />}></Route>
          <Route path="hotels/booking" element={<HotelBooking />}></Route>
          <Route path="/paymentOk" element={<OkPayment />}></Route>
          <Route path="/paymentFailed" element={<FailedPayment />}></Route>
          <Route path="/privacy" element={<PrivacyPage />}></Route>
          {/* <Route path="/terms-conditions" element={<TermsAndConditions />}></Route>  */}
          <Route path="/virtual-interlining" element={<VirtualInterliningPage />}></Route>
          {/* <Route path="/about-us" element={<InformationPage />} /> */}
          <Route path="/tours" element={<InformationPage />} />
          <Route path="/rental-cars" element={<InformationPage />} />
          <Route path="/visit-dubai" element={<InformationPage />} />
          <Route path="/blog" element={<InformationPage />} />
          <Route path="/contact-us" element={<ContactFormPage />} />
          <Route path="/rreth-nesh" element={<RrethNeshPage />} />
          <Route path="/about-us" element={<AboutUsPage />} />
          <Route path="/how-to" element={<HowToPage />} />
          <Route path="/about-us-de" element={<AboutUsDEPage />} />
        </Routes>
      </BrowserRouter>
    );
  }
}
