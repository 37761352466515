import { isMobile } from "react-device-detect";
import Banner from "../components/Banner";
import Copyrights from "../components/Copyrights";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Styles from "../components/Styles";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

export const RrethNeshPage = () => {
  const translationContext = useTranslation();
  return (
    <div>
      <Styles />
      <Header
        translation={translationContext.t}
        translationContext={translationContext}
      />
      <Banner showSearchBar={false} minHeight={isMobile ? "120px" : "300px"} />
      <RrethNeshPageContent />
      <Footer />
      <Copyrights />
    </div>
  );
};

const RrethNeshPageContent = () => {
  return (
    <Fragment>
      <section
        className="content"
        style={{ padding: isMobile ? 0 : "95px 0px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-10 mt-lg-0 mt-md-0 mt-4 valuetabs col-md-8 col-12 whitebox">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  minHeight: "300px",
                  justifyContent: "center",
                }}
              >
                <p>
                  Mirë se vini në Fluturolire.com, agjencia jonë e udhëtimit
                  online kryesore që ofron një gamë të gjerë destinacionesh dhe
                  zgjedhjeve ajrore. Me akses në 3,700 destinacione dhe mbi 750
                  linja ajrore, jemi të përkushtuar për të bërë eksperiencën
                  tuaj udhëtimi të lehtë dhe të paharrueshme. Qoftë se
                  planifikoni një pushim të ngadalshëm ose një udhëtim biznesi,
                  platforma jonë është e projektuar për t'ju siguruar
                  përgjegjësi maksimale dhe zgjedhje. Eksploroni botën me
                  vetëbesim, pasi ne punojmë për të ju lidhur me destinacionet
                  që ëndërroni, duke siguruar një proces rezervimi pa
                  vështirësi.
                </p>
                <p>
                  Në Fluturolire.com, ne krenohemi që jemi më shumë se një
                  agjenci udhëtimi online; jemi shoqëruesi juaj i përkushtuar
                  për të ngritur udhëtimin tuaj nga fillimi deri në fund. Me një
                  rrjet të gjerë që përfshin 3,700 destinacione të ndryshme dhe
                  partneritete me mbi 750 linja ajrore të besueshme, ofrojmë një
                  botë të mundësive në dorën tuaj.
                </p>
                <p>
                  Platforma jonë e lehtë për përdorim ju lejon të kursejëni
                  eksperiencën tuaj udhëtimi me lehtësi. Pavarësisht nëse
                  kërkoni emocionin e zbulimit të vendeve të fshehta, magjinë e
                  përfshirjes kulturore, ose konveniencën e udhëtimit të
                  paqëndrueshëm biznesi, ne kemi kujdes për ju. Kalojeni përmes
                  zgjedhjes sonë të gjërë të fluturimeve, akomodimeve, dhe
                  pakove udhëtimi për të formuar itinerarin tuaj në perfeksion.
                </p>
                <p>
                  Pas skenave, ekipi ynë punon palodhur për të siguruar që
                  ëndrrat tuaja udhëtimi materializohen lehtë. Ne vlerësojmë
                  transparencën, duke ofruar informacion të detajuar mbi
                  destinacionet, linjat ajrore dhe opcioneve për akomodim.
                  Përkushtimi ynë për kënaqësinë e klientit do të thotë që mund
                  të na besoni për të siguruar informacion të besueshëm dhe të
                  azhornuar, duke ju mundësuar të merrni vendime të informuara.
                </p>
                <p>
                  Nisni një udhëtim me Fluturolire.com, ku ëndrrat tuaja udhëtimi
                  takojnë përgjegjësi të padiskutueshme. Platforma jonë është më
                  shumë se një mjet rezervimi; është një hyrje në eksplorim,
                  aventurë dhe momente të paharrueshme. Bashkohuni me ne në
                  rrugën tonë për të riformatuar mënyrën si përjetoni botën, një
                  destinacion në një kohë.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default RrethNeshPage;
