import { useEffect, useState } from "react";
import { POST } from "../../utils/api";
import { Button, Typography } from "@mui/material";
import {
  RATE_HAWK_ENDPOINT_AUTOCOMPLETE,
  RATE_HAWK_KEY,
  RATE_HAWK_API_KEY,
} from "../../utils/constants";

export const AutoComplete = ({
  searchValue,
  onClickHandler,
  openClass,
}) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const getData = setTimeout(async () => {
      const token = btoa(RATE_HAWK_KEY + ":" + RATE_HAWK_API_KEY);
      if (searchValue) {
        const response = await POST(RATE_HAWK_ENDPOINT_AUTOCOMPLETE, {
          headers: {
            Authorization: "Basic " + token,
          },
          body: JSON.stringify({
            query: searchValue,
            language: "en",
          }),
        });
  
        setData([...response?.data?.hotels, ...response?.data?.regions] || []);
      } else {
        setData([]);
      }
    }, 500);

    return () => clearTimeout(getData);
  }, [searchValue]);

  return (
    <div className={openClass}>
      <ul className="list-group">
        {data?.length ? (
          data?.map((item) => (
            <li key={item?.id}>
              <Button
                onClick={() => onClickHandler(item)}
                className="list-group-item d-flex justify-content-between align-items-center"
              >
                {item?.name}
                <span className="badge badge-primary badge-pill">
                  {item?.type || "hotel"}
                </span>
              </Button>
            </li>
          ))
        ) : (
          <Typography sx={{ padding: "20px" }}>
            Start typing to get results
          </Typography>
        )}
      </ul>
    </div>
  );
};
