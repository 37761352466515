import Header from "../../../components/Header";
import Banner from "../../../components/Banner";
import Styles from "../../../components/Styles";
import Copyrights from "../../../components/Copyrights";
import Footer from "../../../components/Footer";
import { useStoreFly } from "../../../store";
import { FlightDetails } from "./FlightDetails";
import { NoResultsFound } from "../../../components/common/NoResultsFound";
import { useQueryParams } from "../../../hooks/useQueryParams";
import { IsMobileOrBrowser } from "../../../hooks/isBrowserOrMobile";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const Booking = () => {
  const translationContext = useTranslation();
  const currentFlightBooking = useStoreFly(
    (state) => state.currentFlightBooking
  ); 

  const [bookingDetails, setBookingDetails] = useState(currentFlightBooking); 

  const queryParams = useQueryParams();
  const flightId = bookingDetails?.solutionId; 

  const isMobile = IsMobileOrBrowser(); 

  useEffect(() => {
    if (currentFlightBooking) {
      localStorage.setItem(
        "currentBooking",
        JSON.stringify(currentFlightBooking)
      );
    }

    if (!currentFlightBooking) {
      const bookingDetails = JSON.parse(localStorage.getItem("currentBooking"));
      setBookingDetails(bookingDetails);
    }
  }, [currentFlightBooking]);

  useEffect(() => {
    localStorage.setItem("bnum", JSON.stringify({ checked: 0 }));
  }, []);
  const setCurrentFlightBooking = useStoreFly(
    (state) => state.setCurrentFlightBooking
  ); 
  return (
    <div>
      <Styles />
      <Header
        translation={translationContext.t}
        translationContext={translationContext}
      />
      <Banner showSearchBar={false} minHeight={isMobile ? "120px" : "300px"} />
      {flightId !== bookingDetails?.solutionId ? (
        <NoResultsFound
          headerTile="We,re sorry, but there are no details for the selected flight"
          showRedirectButton={true}
          navigatePath="/"
          customStyle={{ padding: isMobile ? "15px" : "100px" }}
          setCurrentFlightBooking={() => {
            setCurrentFlightBooking({});
          }}
        />
      ) : (
        <FlightDetails flight={bookingDetails} key="flight_details" />
      )}
      <Footer />
      <Copyrights />
    </div>
  );
};

export default Booking;
