import { useStoreFly } from "../store";

export const useFlights = () => {
  const searchedFlightsByPrice = 
    useStoreFly((state) => state.flightsByPrice) || [];
  const searchedFlightsByDuration =
    useStoreFly((state) => state.flightsByDuration) || [];
  const searchedFlightsByDate =
    useStoreFly((state) => state.flightsByDate) || [];
  const searchedFlightsByQuality =
    useStoreFly((state) => state.flightsByQuality) || [];
 
    // console.log({searchedFlightsByPrice, searchedFlightsByDuration, searchedFlightsByQuality});


  return {
    priceFlights: JSON.parse(localStorage.getItem("flightsByPrice") || "[]") || searchedFlightsByPrice,
    durationFlights: JSON.parse(localStorage.getItem("flightsByDuration") || "[]") || searchedFlightsByDuration,
    dateFlights:  searchedFlightsByDate,
    qualityFlights: JSON.parse(localStorage.getItem("flightsByQuality") || "[]") || searchedFlightsByQuality,
  };
};
