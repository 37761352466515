import { Fragment, useCallback, useEffect, useState } from "react";
import { AddPassenger } from "../../../components/common/AddPassengers";
import { Button } from "@mui/material";
import { Add } from "@mui/icons-material";
import { FlightUtils } from "../../../common/FlightUtils";
import { PASSENGER_TYPE } from "../../../utils/enums";
import { getItemFromLocalStorage } from "../../../common/methods";
import { useTranslation } from "react-i18next";
import { useStoreFly } from "../../../store";
const FlightUtil = new FlightUtils();

export const ManagePassengers = ({
  flight,
  adults = 0,
  children = 0,
  infants = 0,
  handBagDimensions = [],
  holdBagDimensions = [],
  holdBagsToPay = [],
  countries = [],
  addedBaggage = [],
  setAddedBaggage,
  removeWhenNoCheckedBaggage,
  setUpdatedFlight,
  setPassengers,
  currency,
  formRef,
  // currentBnum,
  // setBnum,
}) => {
  const updateFinalBag = useStoreFly((state) => state.updateAncillary)
  const getFinalBag = useStoreFly((state) => state.ancillary)
  const translation = useTranslation(); 
  const adultsList = Array.from({ length: adults - 1 }, (_, totAdults) => ({
    index: totAdults + 2,
    adults: totAdults + 1,
    passengerTypeDisplay: "Adult (12+ years)",
    passengerType: "adult",
    noCheckedBaggage: false,
  }));
  const childrenList = Array.from({ length: children }, (_, totChildren) => {
    const adultIndex = adults;
    return {
      index: adultIndex + totChildren + 1,
      children: totChildren + 1,
      passengerTypeDisplay: "Child (2-14 years)",
      passengerType: "child",
      noCheckedBaggage: false,
    };
  });
  const infantsList = Array.from({ length: infants }, (_, totInf) => {
    const childrenIndex = adults + children;
    return {
      index: childrenIndex + totInf + 1,
      infants: totInf + 1,
      passengerTypeDisplay: "Infant (under 2 years)",
      passengerType: "infant",
      isInfant: true,
      noCheckedBaggage: false,
    };
  });

  const [passengersList, setPassengersList] = useState([
    ...adultsList,
    ...childrenList,
    ...infantsList,
  ]); 

  const [totalPassengers, setCurrentTotalPassengers] = useState(
    [...adultsList, ...childrenList, ...infantsList]?.length
  );
 

  const [currentPassenger, setCurrentPassenger] = useState();

  const onChangePassengerType = (value, id, bags) => {
    const bnum = getItemFromLocalStorage("bnum");
    const quantity = bags?.find(el => el.id === id)?.quantity;
    if (value === "infant" && quantity) {
      localStorage.setItem(
        "bnum",
        JSON.stringify({
          ...bnum,
          checked: bnum?.checked - quantity,
        })
      );
    }

    const currentElement = passengersList?.find((el) => el.index === id);
    const updatedList = passengersList?.map((p) => {
      if (p.index === currentElement.index)
        return {
          ...p,
          passengerType: value,
          passengerTypeDisplay: PASSENGER_TYPE[value],
          isInfant: value === "infant",
        };
      return p;
    });
    setPassengers(updatedList);
    setPassengersList(updatedList);
  };

  const removePassengerFromList = (id, bags) => { 
    var a = getFinalBag; 
    if (a) {
 
      a = a.filter((el) => el.id !== id)
      // Array().filter((el)=>el.id!==id) 
      // a.forEach((el, i) => {
      //   if (el.id === id) {
      //   }
      // });
    } else {
      a = [];
    }  
    updateFinalBag(a);

    const bnum = getItemFromLocalStorage("bnum");
    const quantity = bags?.find(el => el.id === id)?.quantity;
    if (quantity) {
      localStorage.setItem(
        "bnum",
        JSON.stringify({
          ...bnum,
          checked: bnum?.checked - quantity,
        })
      );
    }

    setCurrentTotalPassengers(totalPassengers - 1);
    if (passengersList?.length === 0) {
      setPassengersList([]);
      setPassengers([]);
    } else {
      const filteredPassenger = passengersList?.filter((el) => el.index !== id);
      const updatedIndex = filteredPassenger?.map((it) => ({
        ...it,
        index: it.index > id ? it.index - 1 : it.index,
      }));
      setPassengers(updatedIndex);
      setPassengersList(updatedIndex);
    }
  };

  const onChangeUpdateByProp = (val, id, prop) => {
    const currentElement = passengersList?.find((el) => el.index === id);
    const updatedList = passengersList?.map((p) => {
      if (p.index === currentElement.index)
        return {
          ...p,
          [`${prop}`]: val,
        };
      return p;
    });
    setPassengers(updatedList);
    setPassengersList(updatedList);
  };

  const onChangeForm = (val, key, id) => {
    const currentElement = passengersList?.find((el) => el.index === id);
    const updatedList = passengersList?.map((p) => {
      if (p.index === currentElement.index)
        return {
          ...p,
          form: {
            ...p.form,
            [`${key}`]: val,
          },
        };
      return p;
    });

    setPassengers(updatedList);
    setPassengersList(updatedList);
  };

  useEffect(() => {
    const checkFlight = async () => {
      const list = passengersList?.map((item) => item.passengerType);
      const counts = list?.reduce((acc, value) => {
        acc[value] = (acc[value] || 0) + 1;
        return acc;
      }, {});

      const childCount = counts["child"] || 0;
      const adultCount = counts["adult"] || 0;
      const infantCount = counts["infant"] || 0;

      const resp = await JSON.parse(localStorage.getItem('preciseSearchData'))[0]|| undefined 

      let adultPrice= resp.adtFare+resp.adtTax+resp.tktFee;
      let childPrice= resp.chdFare+resp.chdTax+resp.tktFee;
      let infantPrice= (resp.infFare?resp.infFare:0)+(resp.infTax?resp.infTax:0)+resp.tktFee;
      let totalPrice=(adultPrice*(adultCount + 1 || 1))+(childPrice*resp.children)+(infantPrice*(resp.infant?resp.infant:0))+resp.platformServiceFee+resp.merchantFee;

      const updatedFlight = {
        adults: adultCount + 1 || 1,
        children: childCount,
        infants: infantCount,
        totalPassengers: adultCount + 1 + childCount + infantCount,
        totalPrice: totalPrice,
      };
      setUpdatedFlight(updatedFlight);
    };
    checkFlight();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPassengers, currentPassenger]);

  return (
    <Fragment>
      {passengersList?.map((item) => (
        <AddPassenger
          id={item.index}
          key={item?.index}
          formRef={formRef}
          record={item}
          passengerNo={item.index}
          passengerType={item.passengerType}
          handBagDimensions={handBagDimensions}
          holdBagDimensions={holdBagDimensions}
          flight={flight}
          holdBagsToPay={item?.isInfant ? [] : holdBagsToPay}
          removePassengerFromList={(id, bag) => {
            removeWhenNoCheckedBaggage(id, bag);
            removePassengerFromList(id, bag);
          }}
          onChangePassengerType={(value, id, bag) => {
            setCurrentPassenger(`${value}_${id}`);
            onChangePassengerType(value, id, bag);
          }}
          passengerTypeValue={item.passengerType}
          onChangeNoCheckedBaggage={(checked, id) =>
            onChangeUpdateByProp(checked, id, "noCheckedBaggage")
          }
          noCheckedBaggage={item.noCheckedBaggage}
          onChangeForm={(val, key, id) => onChangeForm(val, key, id)}
          countries={countries}
          setAddedBaggage={setAddedBaggage}
          addedBaggage={addedBaggage}
          removeWhenNoCheckedBaggage={(id, bag) => removeWhenNoCheckedBaggage(id, bag)}
          currency={currency}
        // currentBnum={currentBnum}
        // setBnum={setBnum}
        />
      ))}
      <div class="col-lg-12 mb-4 text-right col-md-12 col-12">
        {/* <Button
          variant="contained"
          startIcon={<Add />}
          sx={{
            borderRadius: "15px",
            background: "#003663",
            "&:hover": { background: "#003663;" },
          }}
          onClick={() => {
            const lastElement = passengersList[passengersList?.length - 1];
            const updatedList = [
              ...passengersList,
              {
                index: lastElement?.index + 1 || 2,
                adults: 1,
                passengerTypeDisplay: "Adult (12+ years)",
                passengerType: "adult",
                noCheckedBaggage: false,
              },
            ];
            setPassengersList(updatedList);
            setPassengers(updatedList);
            setCurrentTotalPassengers(totalPassengers + 1);
          }}
        >
          {translation?.t("add_passenger")}
        </Button> */}
      </div>
    </Fragment>
  );
};
