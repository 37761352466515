export const formatDateToDayMonth = (date) => {
  // Input date string
  const inputDateStr = date;

  // Convert the input string to a Date object
  const inputDate = new Date(inputDateStr);

  // Function to add leading zeros if the number is less than 10
  const addLeadingZero = (number) => {
    return number < 10 ? "0" + number : number;
  };

  // Format the Date object to the desired output format "Sun, 16.04"
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const formattedDate = `${daysOfWeek[inputDate.getUTCDay()]}, ${addLeadingZero(
    inputDate.getUTCDate()
  )}.${addLeadingZero(inputDate.getUTCMonth() + 1)}`;

  return formattedDate;
};

export const formatDateToDayMonthAndMonthNumber = (inputDateStr) => {
  if(inputDateStr) {

    const [day, month, year] = inputDateStr.split("/");
    const inputDate = new Date(`${year}-${month}-${day}`);
    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const dayOfWeekStr = daysOfWeek[inputDate.getDay()];
  
    const outputDateStr = `${dayOfWeekStr} ${inputDate.getDate()} ${inputDate.toLocaleString(
      "default",
      { month: "short" }
    )}`;
    return outputDateStr;
  }
};

export const TimeDateAndStops = ({
  translation,
  departureTime,
  cityCodeFrom,
  stops,
  hours,
  minutes,
  arrivalTime,
  obLocalDep,
  obLocalArr,
  ibLocalDeb,
  ibLocalArr,
  cityCodeTo,
  className = "col-lg-7 tinfo col-md-12 col-12",
}) => { 
  return (
    <div class={className}>
      <ul>
        <li class="text text-right">
          {obLocalDep
            ? translation && translation(formatDateToDayMonth(obLocalDep)?.split(",")?.[0]?.toLowerCase())
            : translation && translation(formatDateToDayMonth(ibLocalDeb)?.split(",")?.[0]?.toLowerCase())}
          <br />
          {departureTime}
          <br />
          {cityCodeFrom}
        </li>
        <li class="mid text-center">
          {hours}h {minutes}m
          <hr />
           {/* {stops > 1 ? stops+' '+translation("stops") : translation("direct")} */}
        </li>
        <li class="text">
          {obLocalDep
            ? translation && translation(formatDateToDayMonth(obLocalArr)?.split(",")?.[0]?.toLowerCase())
            : translation && translation(formatDateToDayMonth(ibLocalArr)?.split(",")?.[0]?.toLowerCase())}
          <br />
          {arrivalTime}
          <br />
          {cityCodeTo}
        </li>
      </ul>
    </div>
  );
};
