import { useEffect, useState } from "react";
import { FlightUtils } from "../common/FlightUtils";
import { useGeolocation } from "../hooks/useGeolocation";
import { useStoreFly } from "../store";
import { GET } from "../utils/api";
import {
  AIRLINES_ENDPOINT,
  GET_LIST_COUNTRY,
  ITRAVEL_KEY_API,
  LIST_COUNTRIES,
  MAIN_LAMBDA,
  TOP_LOCATIONS,
} from "../utils/constants";
import { DrawerComponent } from "./common/Drawer";
import {
  Divider,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
const flightUtil = new FlightUtils();

const Header = ({ translation, translationContext }) => {
  const location = useLocation();
  const pathname = location.pathname;
  useGeolocation();
  const setCitiesImageUrl = useStoreFly((state) => state.setCitiesImageUrl);
  const setTopLocationsForView = useStoreFly(
    (state) => state.setTopLocationsForView
  );
  const geolocation = useStoreFly((state) => state.geolocation);
  const setAirlines = useStoreFly((state) => state.setAirlines);
  const getAirlines = useStoreFly((state) => state.airlines);
  const setTopLocations = useStoreFly((state) => state.setTopLocations);
  const [topLocationList, setTopLocationList] = useState([]);
  const [popularFlightsLoading, setPopularFlightsLoading] = useState(false);
  const topLocations = useStoreFly((state) => state.topLocations);
  const topFlightsHome = useStoreFly((state) => state.topFlightsHome);
  const setTopFlightsHome = useStoreFly((state) => state.setTopFlightsHome);
  const returnOrOneWayTopLocations = useStoreFly(
    (state) => state.returnOrOneWayTopLocations
  );
  const topLocationsPassengers = useStoreFly(
    (state) => state.topLocationsPassengers
  );

  const isMobile = window.innerWidth < 801;
  const [selectLanguage, setSelectLanguage] = useState(false);
  const [currentLang, setCurrentLang] = useState(
    translationContext?.i18n?.language
  );

  const [openMenu, setOpenMenu] = useState(false);

  useEffect(() => {
    const carriers = async () => {
      const response = await GET(AIRLINES_ENDPOINT, {});
      if (response) setAirlines(response);
    };
    if (!getAirlines || !getAirlines.length) {
      carriers();
    }
  }, [setAirlines, getAirlines]);

  useEffect(() => {
    const locations = async () => {
      const response = await GET(
        GET_LIST_COUNTRY(geolocation?.city || "Republic of Kosovo")
      );
      // const locations = await GET(
      //   TOP_LOCATIONS(response?.[0]?.cca2 || "XK"),
      //   {},
      //   ITRAVEL_KEY_API
      // ); 
      // setTopLocations(locations);
      // setTopLocationList(locations);
    };

    if (geolocation?.city) {
      setPopularFlightsLoading(true);
      locations();
    }
  }, [geolocation, geolocation?.city, setTopLocations]);

  useEffect(() => {
    const { tab } = topFlightsHome;

    const multiCityFlights = async () => {
      await flightUtil.setPopularFlights(
        topLocationList,
        geolocation,
        setCitiesImageUrl,
        setTopLocationsForView,
        tab,
        setTopFlightsHome,
        returnOrOneWayTopLocations,
        topLocationsPassengers
      );
      setPopularFlightsLoading(false);
    };



    if (
      topLocationList?.locations?.length &&
      geolocation?.city &&
      topFlightsHome.loading
    ) {
      multiCityFlights();
    }
  }, [
    geolocation,
    geolocation?.city,
    popularFlightsLoading,
    returnOrOneWayTopLocations,
    setCitiesImageUrl,
    setTopFlightsHome,
    setTopLocationsForView,
    topFlightsHome,
    topLocationList,
    topLocationsPassengers,
  ]);
  const [dialogState, setDialogState] = useState(false);
  const [orderStatus, setOrderStatus] = useState('');
  return (
    <section className="header">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 topbar text-right col-md-12 col-12">
            <a className="tel" href="tel:38349253035">
              +383 49 25 30 35
            </a>
            {!isMobile && (
              <a class="m-0">
                <span
                  onClick={() => {
                    setDialogState(true);
                    // navigate("/");
                  }}
                  className="pointer ml-5 select"
                  style={{
                    borderRadius: "11px",
                    color: "#003663",
                    fontWeight: "bolder",
                    margin: 0
                  }}
                >
                  {/* <i class="fa light  fa-user" style={{ color: "white", fontSize: 20 }} /> */}
                  <img height={25} width={25} src={require("../assets/img/profileWhite.png")} />
                </span>
              </a>
            )}

            {!isMobile && (
              <div className="dropdown country">

                <button
                  className="btn btn-primary dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                  aria-expanded="true"
                  onClick={() => setSelectLanguage(!selectLanguage)}
                >
                  {currentLang === "al" && (
                    <>
                      SQ{" "}
                      <img
                        src={require("../assets/img/al-64.png")}
                        alt="sq"
                        width={32}
                        height={32}
                      />
                    </>
                  )}
                  {(currentLang === "en" || currentLang === 'en-US') && (
                    <>
                      EN
                      <img
                        src={require("../assets/img/en-64.png")}
                        alt="en"
                        width={32}
                        height={32}
                      />
                    </>
                  )}
                  {currentLang === "de" && (
                    <>
                      DE
                      <img
                        src={require("../assets/img/germany.png")}
                        alt="de"
                        width={32}
                        height={32}
                      />
                    </>
                  )}
                  {!currentLang && (
                    <>
                      EN
                      <img
                        src={require("../assets/img/en-64.png")}
                        alt="en"
                        width={32}
                        height={32}
                      />
                    </>
                  )}
                  <img src={require("../assets/img/down-arrow.png")} alt="" />
                </button>
                <div
                  className={`dropdown-menu ${selectLanguage && "show"}`}
                  style={{
                    left: "-50px",
                  }}
                >
                  <span
                    className="dropdown-item"
                    style={{
                      width: "100%",
                      cursor: "pointer",
                      paddingLeft: "10px",
                    }}
                    onClick={() => {
                      setCurrentLang("al");
                      translationContext.i18n.changeLanguage("al");
                      setSelectLanguage(false);
                      window.location.reload();
                    }}
                  >
                    <img
                      src={require("../assets/img/al-64.png")}
                      width={32}
                      height={32}
                      alt="en"
                    />{" "}
                    Shqip
                  </span>
                  <span
                    className="dropdown-item"
                    style={{
                      width: "100%",
                      cursor: "pointer",
                      paddingLeft: "10px",
                    }}
                    onClick={() => {
                      setCurrentLang("en");
                      translationContext.i18n.changeLanguage("en");
                      setSelectLanguage(false);
                      window.location.reload();
                    }}
                  >
                    <img
                      src={require("../assets/img/en-64.png")}
                      width={32}
                      height={32}
                      alt="en"
                    />{" "}
                    English
                  </span>
                  <span
                    className="dropdown-item"
                    style={{
                      width: "100%",
                      cursor: "pointer",
                      paddingLeft: "10px",
                    }}
                    onClick={() => {
                      setCurrentLang("de");
                      translationContext.i18n.changeLanguage("de");
                      setSelectLanguage(false);
                      window.location.reload();
                    }}
                  >
                    <img
                      src={require("../assets/img/germany.png")}
                      width={32}
                      height={32}
                      alt="de"
                    />{" "}
                    Deutsch
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">

          <nav className="navbar navbar-expand-lg">
            <a className="navbar-brand" href="/">
              <img src={require("../assets/img/logo.png")} alt="" />
            </a>
            <button
              className={`navbar-toggler menu-icon ${openMenu ? "active" : ""}`}
              type="button"
              // data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => {
                setOpenMenu(!openMenu);
              }}
            >
              <span className="navbar-toggler-icon"></span>
              <span className="navbar-toggler-icon"></span>
              <span className="navbar-toggler-icon"></span>
            </button>
          </nav>
        </div>

      </div>


      <Dialog
        onClose={() => { }}
        // aria-labelledby="customized-dialog-title"
        open={dialogState}
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: "5px"
          }
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          <span style={{
            color: "#003663",
            fontWeight: "bold"
          }} className="d-inline-block price-text w-100">{translation("search_booking_id")}</span>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setDialogState(false);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {/* <Illustration name="Time" size="small" /> */}
          <h6 class="mt-4" style={{
            color: "#003663"
          }}>{translation("search_dialog_content")}</h6>

          <input type="text" class="form-control" required="" id="orderId" placeholder="" name="orderId" />
          <Button
            autoFocus
            onClick={async () => {
              if (document.getElementById('orderId').value) {
                var myHeaders = new Headers();
                myHeaders.append("User-Agent", "Apifox/1.0.0 (https://apifox.com)");
                myHeaders.append("Content-Type", "application/json");

                var requestOptions = {
                  method: 'POST',
                  headers: myHeaders,
                  redirect: 'follow'
                };
                await fetch(MAIN_LAMBDA + "/check-order-status?bookingId=" + document.getElementById('orderId').value)
                  .then(response => response.json())
                  .then(async result => {
                    setOrderStatus(result.data.orderStatus)
                  })
                  .catch(error => console.log('error', error));
              }


            }}
            className="default-yellow-btn mt-3 select"
            style={{
              borderRadius: "11px",
              color: "#003663",
              background: "#fbd316",
              marginRight: "10px",
              marginBottom: "10px",
              fontWeight: "bolder"
            }}
          >
            {translation("search")}
          </Button>
          <h6 class="mt-4" style={{
            color: "#003663"
          }}>{orderStatus ? 'Order Status :' + orderStatus : ''} </h6>

        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              setDialogState(false);
              // navigate("/");
            }}
            className="default-yellow-btn mt-3 select"
            style={{
              borderRadius: "11px",
              color: "#003663",
              background: "#fbd316",
              marginRight: "10px",
              marginBottom: "10px",
              fontWeight: "bolder"
            }}
          >
            {translation("close")}
          </Button>
        </DialogActions>
      </Dialog>

      <DrawerComponent
        open={openMenu}
        setOpen={setOpenMenu}
        headerText="Menu"
        anchor="right"
      >
        <Paper>
          <MenuList>
            <MenuItem>
              <ListItemText>{translation("language")}</ListItemText>
              <div className="dropdown country">
                <button
                  className="btn "
                  type="button"
                  data-toggle="dropdown"
                  aria-expanded="true"
                  onClick={() => setSelectLanguage(!selectLanguage)}
                >
                  {currentLang === "al" && (
                    <>
                      SQ{" "}
                      <img
                        src={require("../assets/img/al-64.png")}
                        alt="sq"
                        width={32}
                        height={32}
                      />
                    </>
                  )}
                  {currentLang === "en" && (
                    <>
                      EN
                      <img
                        src={require("../assets/img/en-64.png")}
                        alt="en"
                        width={32}
                        height={32}
                      />
                    </>
                  )}
                  {currentLang === "de" && (
                    <>
                      DE
                      <img
                        src={require("../assets/img/germany.png")}
                        alt="de"
                        width={32}
                        height={32}
                      />
                    </>
                  )}
                  <img src={require("../assets/img/down-arrow.png")} alt="" />
                </button>
                <div
                  className={`dropdown-menu ${selectLanguage && "show"}`}
                  style={{
                    left: "-50px",
                  }}
                >
                  <span
                    className="dropdown-item"
                    style={{
                      width: "100%",
                      cursor: "pointer",
                      paddingLeft: "10px",
                    }}
                    onClick={() => {
                      setCurrentLang("al");
                      translationContext.i18n.changeLanguage("al");
                      setSelectLanguage(false);
                      window.location.reload();
                    }}
                  >
                    <img
                      src={require("../assets/img/al-64.png")}
                      width={32}
                      height={32}
                      alt="en"
                    />{" "}
                    Shqip
                  </span>
                  <span
                    className="dropdown-item"
                    style={{
                      width: "100%",
                      cursor: "pointer",
                      paddingLeft: "10px",
                    }}
                    onClick={() => {
                      setCurrentLang("en");
                      translationContext.i18n.changeLanguage("en");
                      setSelectLanguage(false);
                      window.location.reload();
                    }}
                  >
                    <img
                      src={require("../assets/img/en-64.png")}
                      width={32}
                      height={32}
                      alt="en"
                    />{" "}
                    English
                  </span>
                  <span
                    className="dropdown-item"
                    style={{
                      width: "100%",
                      cursor: "pointer",
                      paddingLeft: "10px",
                    }}
                    onClick={() => {
                      setCurrentLang("de");
                      translationContext.i18n.changeLanguage("de");
                      setSelectLanguage(false);
                      window.location.reload();
                    }}
                  >
                    <img
                      src={require("../assets/img/germany.png")}
                      width={32}
                      height={32}
                      alt="en"
                    />{" "}
                    Deutsch
                  </span>
                </div>
              </div>
            </MenuItem>
            <Divider />
            <MenuItem>
              <a
                 
                style={{
                  color:
                    pathname === "/about-us"
                      ? "#005dba"
                      : "rgba(0, 0, 0, 0.87)",
                }}
              >
                <ListItemText 
                onClick={() => {
                  setDialogState(true);
                  // navigate("/");
                }}
                >
                  <span onClick={() => {
                      setDialogState(true);
                      // navigate("/");
                    }}>
                  {translation("search_booking_id")}
                  </span>
                  <span
                    onClick={() => {
                      setDialogState(true);
                      // navigate("/");
                    }}
                    className="pointer ml-5 select"
                    style={{
                      borderRadius: "11px",
                      color: "#003663",
                      fontWeight: "bolder",
                      margin: 0
                    }}
                  >
                    {/* <i class="fa primary  fa-user" style={{ color: "black", fontSize: 20 }} /> */}
                    <img height={35} width={35} src={require("../assets/img/profile.png")} />
                  </span>
                </ListItemText>
              </a>
            </MenuItem>
            <Divider />
            <MenuItem>
              <a
                href={currentLang === "en-US" || currentLang === "en" ? '/about-us' : currentLang === "al" ? '/rreth-nesh' : '/about-us-de'}
                style={{
                  color:
                    pathname === "/about-us"
                      ? "#005dba"
                      : "rgba(0, 0, 0, 0.87)",
                }}
              >
                <ListItemText>{translation("about_us")}</ListItemText>
              </a>
            </MenuItem>
            <Divider />

            <MenuItem>
              <a
                href="/tours"
                style={{
                  color:
                    pathname === "/tours" ? "#005dba" : "rgba(0, 0, 0, 0.87)",
                }}
              >
                <ListItemText>{translation("tours")}</ListItemText>
              </a>
            </MenuItem>
            <Divider />
            <MenuItem>
              <a
                href="/rental-cars"
                style={{
                  color:
                    pathname === "/rental-cars"
                      ? "#005dba"
                      : "rgba(0, 0, 0, 0.87)",
                }}
              >
                <ListItemText>{translation("rental_cars")}</ListItemText>
              </a>
            </MenuItem>
            <Divider />
            <MenuItem>
              <a
                href="/how-to"
                style={{
                  color:
                    pathname === "/visit-dubai"
                      ? "#005dba"
                      : "rgba(0, 0, 0, 0.87)",
                }}
              >
                <ListItemText>{translation("howto")}</ListItemText>
              </a>
            </MenuItem>
            <Divider />
            <MenuItem>
              <a
                href="/blog"
                style={{
                  color:
                    pathname === "/blog" ? "#005dba" : "rgba(0, 0, 0, 0.87)",
                }}
              >
                <ListItemText>{translation("blog")}</ListItemText>
              </a>
            </MenuItem>
            <Divider />
            <MenuItem>
              <a
                href="/privacy"
                style={{
                  color:
                    pathname === "/privacy" ? "#005dba" : "rgba(0, 0, 0, 0.87)",
                }}
              >
                <ListItemText>{translation("privacy")}</ListItemText>
              </a>
            </MenuItem>
            <Divider />
            <MenuItem>
              <a
                href="/contact-us"
                style={{
                  color:
                    pathname === "/contact-us"
                      ? "#005dba"
                      : "rgba(0, 0, 0, 0.87)",
                }}
              >
                <ListItemText>{translation("contact_us")}</ListItemText>
              </a>
            </MenuItem>
            <Divider />
          </MenuList>
        </Paper>
      </DrawerComponent>
    </section>
  );
};

export default Header;
