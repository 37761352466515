import * as SuitCase from "../assets/img/32/suit-case-32.png";
import * as Luggage from "../assets/img/32/Checked-baggage.png";
import * as DisabledSuitCase from "../assets/img/32/No-Suitcase.png";
import * as DisabledLuggage from "../assets/img/32/No-Luggage.png";
import * as Morning from "../assets/img/32/Morning.png";
import * as EarlyMorning from "../assets/img/32/Early-Morning.png";
import * as Afternoon from "../assets/img/32/Afternoon.png";
import * as Evening from "../assets/img/32/Evening.png";
import * as ArrowDownBlue from "../assets/img/down-arrow-blue.png";
import * as Info from "../assets/img/32/Virtual-Interlining.png";
import * as SelfCare from "../assets/img/32/Self-transfer.png";
import * as Notification from "../assets/img/img-notification.png";
import * as Seat from "../assets/img/img-seat.png";
import * as Plug from "../assets/img/img-plug.png";
import * as LoadingImage from "../assets/img/icon-smile.png";
import * as EmptyIcon from "../assets/img/icon-empty.png";
import * as SslIcon from "../assets/img/icon-ssl.png";
import * as Passport from "../assets/img/passport.png";
import * as Cancel from "../assets/img/icon-cancel.png";
import * as Flight from "../assets/img/flight1.png";
import * as Refund from "../assets/img/REFUND-6-PNG.png";
import * as NonRefund from "../assets/img/NOT-REFUND-PNG.png";

export const useStaticImages = () => {
  return {
    refund: Refund.default,
    nonrefund: NonRefund.default,
    suitcase: SuitCase.default,
    luggage: Luggage.default,
    disabledSuitCase: DisabledSuitCase.default,
    disabledLuggage: DisabledLuggage.default,
    morning: Morning.default,
    earlyMorning: EarlyMorning.default,
    afternoon: Afternoon.default,
    evening: Evening.default,
    arrowDown: ArrowDownBlue.default,
    info: Info.default,
    selfCare: SelfCare.default,
    notification: Notification.default,
    seat: Seat.default,
    plug: Plug.default,
    loading: LoadingImage.default,
    emptyIcon: EmptyIcon.default,
    ssl: SslIcon.default,
    passport: Passport.default,
    cancel: Cancel.default,
    flight: Flight.default,
  };
};
