import { deepmerge } from "@mui/utils";
import { create } from "zustand";
import { persist } from "zustand/middleware";
import { getItemFromLocalStorage, updateObjectByPath } from "../common/methods";
import { CURRENCY_FULL_NAME, CURRENCY_ISO_CODE, MAXIMUM_STOPS, SELECTED_CLASS } from "../utils/enums";
const currentFilter = getItemFromLocalStorage("filter");
const currentSearch = getItemFromLocalStorage("search");
const isNotHome = window.location.pathname !== "/";
const cabin = currentFilter?.adultCabin?.length;
const checked = currentFilter?.adultChecked?.reduce(
  (acc, current) => acc + current,
  0
);
const stops = currentFilter?.stops;
const flightTimeGoing = currentFilter?.flightTimeGoing;
const flightTimeReturning = currentFilter?.flightTimeReturning;
const returnOrOneWay = isNotHome ? currentSearch?.returnOrOneWay : "return";
const selectedClass = isNotHome ? currentSearch?.selectedClass : SELECTED_CLASS.Economy;
const adults = isNotHome ? currentSearch?.adults : 1;
const children = isNotHome ? currentSearch?.children : 0;
const babies = isNotHome ? currentSearch?.babies : 0;

export const useStoreFly = create(
  // persist(
  (set) => ({
    currentFlightBooking: null,
    geolocation: {},
    loadingData: true,
    returnOrOneWay: returnOrOneWay,
    returnOrOneWayTopLocations: "",
    currency: {
      curr: "EUR",
      name: "Euro",
    },
    topLocationsPassengers: {
      adult: 1,
      children: 0,
      babies: 0,
    },
    topFlightsHome: {
      tab: "popular",
      loading: true,
    },
    baggage: {
      cabin: cabin || 0,
      checked: checked || 0,
      maximumOfCheckedBag: 2,
      maximumOfCabinBag: 1,
    },
    passengers: {
      adult: adults,
      children: children,
      babies: babies,
    },
    filterStops: {
      current: stops || "any",
      allowOvernight: true,
    },
    filterFlightTimes: {
      going: flightTimeGoing || "",
      returning: flightTimeReturning || "",
    },
    filterRoute: {
      originInput: "",
      destinationInput: "",
      origin: "",
      destination: "",
      dateFrom: "",
      dateTo: "",
    },
    filterDates: {
      dateFrom: "",
      dateTo: "",
    },
    filterDirection: "oneway",
    filterClass: selectedClass,
    airlines: [],
    filterAirlines: {
      list: [],
      selected: [],
      unselected: [],
    },
    topLocations: [],
    topLocationsForView: [],
    locations: [],
    totalFlights: 0,
    airportNamesByFlight: [],
    selectedFlightDetailsExpand: null,
    selectedFlightsByPrice: [],
    selectedFlightsByDuration: [],
    selectedFlightsByQuality: [],
    selectedFlightsByDate: [],
    citiesImageUrl: [],
    passengersList: [],
    ancillary: [],
    preciseSearchData: undefined,
    baggInfo: '',
    updatePassengersList: (passengersList) => set(() => ({ passengersList })),
    updatePreciseSearchData: (preciseSearchData) => set(() => ({ preciseSearchData })),
    updateAncillary: (ancillary) => set(() => ({ancillary:ancillary})),
    updateBaggInfo: (baggInfo) => set(() => ({ baggInfo })),
    setCitiesImageUrl: (listOfImageUrl) =>
      set(() => ({ citiesImageUrl: listOfImageUrl })),
    setTopLocationsForView: (topLocationsForView) =>
      set(() => ({ topLocationsForView })),
    setTopLocations: (locations) => set({ topLocations: locations }),
    setSearchFlights: (flights, sortType) => set({ [`${sortType}`]: flights }),
    setAirlines: (carriers) => set({ airlines: carriers }),
    setSelectedFlightDetailsExpand: (
      sortedFlight,
      flightId,
      selectedFlightType,
      selectedFlightDetails,
      path
    ) =>
      set({
        [`${path}`]: {
          ...sortedFlight,
          data: sortedFlight?.data?.map((flight) => {
            if (flight?.solutionId === flightId) {
              return {
                ...flight,
                selectedFlightType: selectedFlightType,
                selectedFlightDetails: selectedFlightDetails,
              };
            }
            return flight;
          }),
        },
      }),
    setAirportNamesByFlightId: (flightId, airportNames) =>
      set({
        airportNamesByFlight: [{ id: flightId, airportNames: airportNames }],
      }),
    setTotalFlights: (total) => set({ totalFlights: total }),
    setFilters: (path, value) =>
      set((state) => ({
        filters: getFilters(state, path, value),
      })),
    setBaggage: (key, value) =>
      set((state) => ({
        baggage: {
          ...state.baggage,
          [`${key}`]: value,
        },
      })),
    setPassengers: (key, value) =>
      set((state) => ({
        passengers: {
          ...state.passengers,
          [`${key}`]: value,
        },
      })),
    setFilter: (root, key, value) =>
      set((state) => ({
        [`${root}`]: {
          ...state[`${root}`],
          [`${key}`]: value,
        },
      })),
    resetFilters: () =>
      set((state) => ({
        baggage: {
          cabin: 0,
          checked: 0,
          maximumOfCheckedBag: 0,
          maximumOfCabinBag: 0,
        },
        passengers: {
          adult: 1,
          children: 0,
          babies: 0,
        },
        filterStops: {
          current: "any",
          allowOvernight: true,
        },
        filterFlightTimes: {
          going: "",
          returning: "",
        },
        filterAirlines: {
          list: [],
          selected: state.filterAirlines.list,
          unselected: [],
        },
        currency: {
          curr: "EUR",
          name: "Euro",
        },
      })),
    resetFilterRoute: () =>
      set(() => ({
        filterRoute: {
          originInput: "",
          destinationInput: "",
          origin: "",
          destination: "",
          dateFrom: "",
          dateTo: "",
        },
      })),
    setFilterClass: (classValue) => set(() => ({ filterClass: classValue })),
    setLoadingData: (loaded) => set(() => ({ loadingData: loaded })),
    setFilterDate: (dateFrom, dateTo) =>
      set(() => ({ filterDates: { dateFrom, dateTo } })),
    setReturnOrOneWay: (returnType) =>
      set(() => ({
        returnOrOneWay: returnType,
      })),
    setGeolocation: (cords) =>
      set(() => ({
        geolocation: cords,
      })),
    setTopFlightsHome: (tab, loading) =>
      set({
        topFlightsHome: {
          tab: tab,
          loading,
        },
      }),
    setReturnOrOneWayTopLocations: (type) =>
      set({ returnOrOneWayTopLocations: type }),
    setTopLocationsPassengers: (passenger, value) =>
      set((state) => ({
        topLocationsPassengers: {
          ...state.topLocationsPassengers,
          [passenger]: value,
        },
      })),
    setCurrentFlightBooking: (flightDetails) =>
      set({ currentFlightBooking: flightDetails }),
  })
  // {
  //   merge: (persistedState, currentState) =>
  //     deepmerge(currentState, persistedState),
  // },
  // {
  //   name: "fluturoLire-storage", // unique name
  //   //   getStorage: () => AsyncStorage, // Add this here!
  // }
  // )
);

const getFilters = (state, path, value) => {
  if (!path) return value;
  const filters = state.filters;
  const updatedFilters = updateObjectByPath(filters, path, value);
  return updatedFilters;
};
