import { Fragment } from "react";
import { useStaticImages } from "../../hooks/useStaticImages";
export const AddSubtract = ({
  label,
  currentValue = 0,
  imageName,
  setCurrentValue,
  path,
  disabled = false,
  setFlightsHandler,
}) => {
  const { [imageName]: imagePath } = useStaticImages();

  return (
    <Fragment>
      <div class="text-blue mb-2 incp flex-row justify-content-space-between">
        <div>
        <img class="ml-1 mr-1" srcSet={imagePath} alt="baggage" /> {label}
        </div>
        <span class="incr" style={{ display: "flex"}}>
          <button
            data-decrease
            disabled={currentValue === 0}
            onClick={() => {
              setCurrentValue(path, currentValue - 1);
              setFlightsHandler({ path, currentValue: currentValue - 1 });
            }}
            style={{
              verticalAlign: "middle",
            }}
          >
            -
          </button>
          <input value={currentValue || 0} style={{margin: "0 5px"}} />
          <button
            data-increase
            onClick={() => {
              setCurrentValue(path, currentValue + 1);
              setFlightsHandler({ path, currentValue: currentValue + 1 });
            }}
            disabled={disabled}
            style={{
              verticalAlign: "middle",
            }}
          >
            +
          </button>
        </span>
      </div>
    </Fragment>
  );
};
