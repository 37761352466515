import { useStaticImages } from "../../hooks/useStaticImages";

export const DayTimes = ({
  route,
  filterFlightTimes,
  setFilter,
  translation,
  setFlightsHandler,
}) => {
  const currentValue = filterFlightTimes[route];
  const { earlyMorning, morning, afternoon, evening } = useStaticImages();

  return (
    <div class="cursorNone col-lg-12 col-12 p-0 ">
      <div class="cursorNone custom-control timing-radio custom-radio">
        <input
          type="radio"
          id={`early_morning_${route}`}
          name={`early_morning_${route}`}
          class="cursorNone custom-control-input"
          checked={currentValue === "early_morning"}
          onClick={(event) => {
            if(event.target.checked) {
              setFilter("filterFlightTimes", route, "");
              setFlightsHandler({ route, currentFlightTime: "" });
            }
          }}
          onChange={(event) => {
            setFilter("filterFlightTimes", route, "early_morning");
            setFlightsHandler({ route, currentFlightTime: "early_morning" });
          }}
        />
        <label class="cursorNone custom-control-label" for={`early_morning_${route}`}>
          <img src={earlyMorning} alt={"morning"}  width={25}/>
          <span>
            <strong>{translation("early_morning")} </strong>
            <br /> (12:00am - 4:59am)
          </span>
        </label>
      </div>
      <div class="cursorNone custom-control timing-radio custom-radio">
        <input
          type="radio"
          id={`morning_${route}`}
          name={`morning_${route}`}
          class="cursorNone custom-control-input"
          checked={currentValue === "morning"}
          onClick={(event) => {
            if(event.target.checked) {
              setFilter("filterFlightTimes", route, "");
              setFlightsHandler({ route, currentFlightTime: "" });
            }
          }}
          onChange={() => {
            setFilter("filterFlightTimes", route, "morning");
            setFlightsHandler({ route, currentFlightTime: "morning" });
          }}
        />
        <label class="cursorNone custom-control-label" for={`morning_${route}`}>
          <img src={morning} alt="early_morning" width={25}/>
          <span>
            <strong>{translation("morning")}</strong>
            <br /> (5:00am - 11:59am)
          </span>
        </label>
      </div>
      <div class="cursorNone custom-control timing-radio custom-radio">
        <input
          type="radio"
          id={`afternoon_${route}`}
          name={`afternoon_${route}`}
          class="cursorNone custom-control-input"
          checked={currentValue === "afternoon"}
          onClick={(event) => {
            if(event.target.checked) {
              setFilter("filterFlightTimes", route, "");
              setFlightsHandler({ route, currentFlightTime: "" });
            }
          }}
          onChange={() => {
            setFilter("filterFlightTimes", route, "afternoon");
            setFlightsHandler({ route, currentFlightTime: "morning" });
          }}
        />
        <label class="cursorNone custom-control-label" for={`afternoon_${route}`}>
          <img src={afternoon} alt="afternoon"  width={25}/>
          <span>
            <strong>{translation("afternoon")}</strong>
            <br /> (12:00pm - 5:59pm)
          </span>
        </label>
      </div>
      <div class="cursorNone custom-control timing-radio custom-radio">
        <input
          type="radio"
          id={`evening_${route}`}
          name={`evening_${route}`}
          class="cursorNone custom-control-input"
          checked={currentValue === "evening"}
          onClick={(event) => {
            if(event.target.checked) {
              setFilter("filterFlightTimes", route, "");
              setFlightsHandler({ route, currentFlightTime: "" });
            }
          }}
          onChange={() => {
            setFilter("filterFlightTimes", route, "evening");
            setFlightsHandler({ route, currentFlightTime: "evening" });
          }}
        />
        <label class="cursorNone custom-control-label" for={`evening_${route}`}>
          <img src={evening} alt="evening"  width={25}/>
          <span>
            <strong>{translation("evening")} </strong>
            <br /> (6:00pm - 11:59pm)
          </span>
        </label>
      </div>
    </div>
  );
};
