import { event } from "jquery";
import { IsMobileOrBrowser } from "../../hooks/isBrowserOrMobile";
import {useState} from 'react';
export const CheckboxWithLabel = ({
  id,
  labelValue,
  checked,
  handleChange,
  onlyThis
}) => {
  const [MouseDidenter, setMouseDidenter] = useState(false);
  const [MouseDidenterId, setMouseDidenterId] = useState(0);
   
  const handleMouseOver = (e,id) => {  
    setMouseDidenterId(id)
    setMouseDidenter(true) 
    
  };
  const handleMouseLeave = (e,id) => { 
    setMouseDidenterId(id)
    setMouseDidenter(false) 
    
  };
  return (
    <div class="pointer custom-control custom-radio  d-flex flex-row justify-items-between justify-content-between w-100"
    onMouseOver={(event) => handleMouseOver(event, id)}
      onMouseOut={(event) => handleMouseLeave(event, id)}
    >
      <input 
      
        type="checkbox"
        id={id}
        name={labelValue}
        class="pointer custom-control-input"
        onChange={handleChange}
        checked={checked}
        
      />
      <label class="pointer custom-control-label" for={id}> 
        {labelValue} 
        
      </label>
      <div>
      {((MouseDidenter &&MouseDidenterId==id &&!IsMobileOrBrowser())||IsMobileOrBrowser()) && (
          <button class="onlyButton-kiwi" onClick={(event) => onlyThis(event, id,checked)}>Only</button>
        )}
      </div>
    </div>
  );
};
