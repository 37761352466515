import { Fragment, useState } from "react";
import { FlightUtils } from "../../common/FlightUtils";
import { useFlightRouteDetails } from "../../hooks/useFlightRouteDetails";
import { useTimeDifference } from "../../hooks/useTimeDifference";
import { useStoreFly } from "../../store";
import { AIRLINES_IMAGE_ENDPOINT } from "../../utils/constants";
import { useTranslation } from "react-i18next";
import {  capitalizeFirstLetter, combineDateAndTime, totalStops } from "../../common/methods";
import Alert from "@kiwicom/orbit-components/lib/Alert";
import { Chip } from "@mui/material";
import { BadgeList, BadgeListItem } from "@kiwicom/orbit-components";
import TimeLapse from "@kiwicom/orbit-components/lib/icons/Clock";
import { DrawerComponent } from "../common/Drawer";
import { IsMobileOrBrowser } from "../../hooks/isBrowserOrMobile"; 
import moment from "moment";
const flightUtil = new FlightUtils();

export const AccordionDetails = ({
  arrowDown,
  id,
  routes,
  setAirportNamesByFlightId,
  flights,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [collapsing, setCollapsed] = useState("collapse");
  const getAirportNamesByFlight =
  useStoreFly((state) => state.airportNamesByFlight) || []; 
  const currentAirportNames =
    getAirportNamesByFlight?.find((a) => a.id === id) || [];
  const airports = currentAirportNames?.airportNames?.locations?.map(
    (airport) => ({ id: airport.id, name: airport.name })
  );
  const translation = useTranslation() 
  const [openFilter, setOpenFilter] = useState(false);
  const isMobile = IsMobileOrBrowser()
  return (
    <div class="accordion" id="accordionExample" key={id}>
      <div class="card">
        <div class="card-header" id={`heading_${id}`}>
          <h2 class="mb-0">
            {!isMobile?(
            <button 
              class={`btn btn-link btn-block text-left ${
                !expanded ? "collapsed" : ""
              }`}
              type="button"
              data-toggle="collapse"
              data-target={`#collapse_${id}`}
              aria-expanded={expanded}
              aria-controls={`collapse_${id}`}
              onClick={async () => {
                
                if (!expanded) {
                  setCollapsed("collpasing");
                  await flightUtil.setAirportNames(
                    { data: flights },
                    id,
                    setAirportNamesByFlightId
                  );
                }

                setCollapsed("collapse show");
                setExpanded(!expanded);
                
              }} 
            >
              <span class="">{translation.t("close")} </span>{translation.t("flight_details")}{" "}
              <img src={arrowDown} alt="" />
            </button>):(
              <button

              class={`btn btn-link btn-block text-left ${
                !expanded ? "collapsed" : ""
              }`}

              onClick={async (event)=>{
                await flightUtil.setAirportNames(
                  { data: flights },
                  id,
                  setAirportNamesByFlightId
                );
                setOpenFilter(true)
              }}
              ><span class="">{translation.t("close")} </span>{translation.t("flight_details")}{" "}
              <img src={arrowDown} alt="" />
            </button>
              
            )}
            
          </h2>
        </div>
        <div
          id={`collapse_${id}`}
          class={`${expanded ? collapsing : "collapse"}`}
          aria-labelledby={`heading_${id}`}
          data-parent="#accordionExample"
          
        >
          
          {(!isMobile) ? (
            <div class="card-body"> 
              <AccordionItems routes={routes} airports={airports} />
            </div>
        ):openFilter&&(<AccordionItemsMobile routes={routes} airports={airports} openFilterState={openFilter} />) 
        }
            
          
        </div>
      </div>
    </div>
  );
};

const AccordionItems = ({ routes = [], airports = [] }) => {   
  const departureStops = totalStops(routes,false);  
  const returnStops = totalStops(routes,true); 
  // get previous route depart time and current route arrival time to calculate time difference

  
   

    return (
      <Fragment>
        {routes?.map((item, index, array) => {
          return (
  
            <AccordionItem
              key={item?.id}
              route={item}
              airports={airports}
              departureStops={departureStops}
              returnStops={returnStops}
              isLast={index === array.length - 1}
              index={index}
              items={array}
            />
          );
        })}
      </Fragment>
    ); 
};
const AccordionItemsMobile = ({ routes = [], airports = [],openFilterState}) => {  
   
  const departureStops = totalStops(routes,false);  
  const returnStops = totalStops(routes,true); 
  // get previous route depart time and current route arrival time to calculate time difference

  const [openFilter, setOpenFilter] = useState(openFilterState);
   

    return (
      <Fragment>
        <DrawerComponent
        id="accordion-details-drawer"
        key="accordion-details-drawer"
        open={openFilter} 
        setOpen={setOpenFilter}
        anchor="bottom"
        headerText="Flight details"
        >
          <div class="overflow-auto p-4">

        {routes?.map((item, index, array) => {
          return (
  
            <AccordionItem
              key={item?.id}
              route={item}
              airports={airports}
              departureStops={departureStops}
              returnStops={returnStops}
              isLast={index === array.length - 1}
              index={index}
              items={array}
            />
          );
        })}
          </div>

        </DrawerComponent>
      </Fragment>
    ); 
};

const AccordionItem = ({
  route,
  airports = [],
  returnStops,
  departureStops,
  isLast = false,
  index,
  items,
}) => {
  const translation = useTranslation();
  // const { seat, plug } = useStaticImages(); 
  const nextRoute = items[index + 1]; 
  
  const { departFromAirportName: depAirport, arriveToAirportName: arrAirport } =
    flightUtil.getAirportNameByCityCode(route?.departure, route?.arrival, airports);
  const {
    airline,
    flightNo,
    operatingFlightNo, 
    departAtDay,
    departAtMonth,
    departAtNumber,
    arriveAtDay,
    arriveAtMonth,
    arriveAtNumber,
  } = useFlightRouteDetails(route);


  const { departureTime, arrivalTime, timeFormatted } =
    useTimeDifference(route);

  // const { 
  // } = useTimeDifference(nextRoute);   

   const toHoursAndMinutes = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
  
    return { hours, minutes };
  };

  function calculateDifferencePrv(date1,date2) {
    let date1Moment = moment(date1);
    const date2Moment = moment(date2);

     let diff = toHoursAndMinutes(Math.abs(date2Moment.diff(date1Moment ,'minutes'))); 
     let string = diff.hours + 'h ' + diff.minutes+'m';
     return (string)
  };


   

  const timeDifference =nextRoute&&(route.isReturn ===nextRoute.isReturn)?calculateDifferencePrv(combineDateAndTime(route,false), combineDateAndTime(nextRoute,true)):'';
   
  const isAlbanian = translation?.i18n?.language === "al";
  const mobileAirlineDimensions = window.innerWidth < 801 ? "32x32" : "32x32"; 
  const isDirectDepartRoute = departureStops === 1    
  const isDirectReturnRoute = returnStops === 1    
 
  const arrivesNextDay = arriveAtNumber > departAtNumber;
  const arriveAtFullDate = arriveAtNumber + " " + translation.t(`${arriveAtMonth?.toLowerCase()}`);
  return (
    <Fragment key={route?.segmentId}>
      <div class="row">
        <div class="col-lg-12 col-12 pb-2" style={{
          float: "right",
        }}>
          {((isDirectReturnRoute&&route.isReturn) || (isDirectDepartRoute&&!route.isReturn)) && (
            
           
            
            <Chip label={translation.t("direct")} color="primary" sx={{
              float: "right",
              background: "#003663"
            }} />
            
          )}
          <h6 class="text-blue">
            {route.isReturn === true
              ? translation.t("return")
              : translation.t("depart")}{" "}
            {/* {translation.t(`${departAtDay.toLowerCase()}`)}{" "}
            {translation.t(`${departAtMonth.toLowerCase()}`)} {departAtNumber}{" "} */}
            {/* <span class="pl-4">This is an alternate date itinerary.</span> */}
          </h6>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-12">
          <div class="row pl-2">
            <div class="col-lg-6 deplocation d-flex align-items-start col-12">
              <img
                class="mr-1"
                srcSet={`${AIRLINES_IMAGE_ENDPOINT}/${mobileAirlineDimensions}/${airline?.id}.png`}
                alt={airline?.name}
              />{" "}
              <div>
                {airline?.name}
                <br />
                <span>
                  {translation.t("flight")} {flightNo} |{" "}
                  {translation.t("aircraft")} {operatingFlightNo || "N/A"}
                </span>
              </div>
            </div>
            <div class="col-lg-6 col-12">
              <h6 class="text-blue">
                {isAlbanian
                  ? capitalizeFirstLetter(
                      translation.t(`${departAtDay?.toLowerCase()}`)
                    )
                  : translation.t(`${departAtDay?.toLowerCase()}`)}{" "}
                {translation.t(`${departAtMonth?.toLowerCase()}`)}{" "}
                {departAtNumber}{" "}
              </h6>
              <h6 class="text-blue fw-medium">
                {departureTime}
                <br />
                <span class="text-blue fw-medium" style={{
                  fontSize:"14px",
                  marginTop:"10px"
                }}> {depAirport?.city_name}</span>
                <br />
                <span class="text-blue pt-1" style={{ fontSize: "14px" , fontWeight: "bold" }}>
                  {depAirport?.name}{" "} 
                </span>
               
              </h6>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-12">
          <div class="row pl-2">
            <div class="col-lg-6 col-12">
              <h6 class="text-blue fw-medium">
                {isAlbanian
                  ? capitalizeFirstLetter(
                      translation.t(`${arriveAtDay?.toLowerCase()}`)
                    )
                  : translation.t(`${arriveAtDay?.toLowerCase()}`)}{" "}
                {translation.t(`${arriveAtMonth?.toLowerCase()}`)}{" "}
                {arriveAtNumber}{" "}
              </h6>
              <h6 class="text-blue fw-medium">
                {arrivalTime}
                <br />
                <span class="text-blue fw-medium" style={{
                  fontSize:"14px",
                  marginTop:"10px"
                }}> {arrAirport?.city_name}</span>
                <br />
                <span class="pt-1" style={{ fontSize: "14px", fontWeight: "bold" }}>
                  {arrAirport?.name} {" "}
                </span>
              </h6>
            </div>
            {/* <div class="col-lg-6 text-right col-12">
              <span class="text-blue fw-medium fs-10 mb-2">
                Cabin: Coach
                <br /> Brand Name: Ecojet
              </span>
              <img class="mr-2" srcSet={seat} alt="seat" />
              <img class="mr-1" srcSet={plug} alt="plug" />
            </div> */}
          </div>
        </div>

        {/* <div class="col-lg-12 text-right col-12" style={{
          marginTop: "5px"
        }}>
          <span class="text-blue fs-13">
            {translation.t("total_trip_duration")}: {timeFormatted}
          </span>
        </div> */}
      </div>
      <div style={{
        display: "flex",
        justifyContent: "space-between",
        width:"100%",
        alignItems: "center"
      }}>
      {((timeDifference&&nextRoute)&&(route.isReturn ===nextRoute.isReturn)) && (
            <BadgeList>
      <BadgeListItem icon={<TimeLapse/>}>
        {timeDifference} {translation.t("layover")}
      </BadgeListItem>
    </BadgeList>
      )}
      <div class="col-lg-12 text-right col-12" style={{
          marginTop: "5px",
          maxWidth: "70%"
        }}>
          <span class="text-blue fs-13">
            {translation.t("total_trip_duration")}: {timeFormatted}
          </span>
        </div>
      </div>
      
      {arrivesNextDay && (
        <Alert title={`${translation.t("next_day")} (${arriveAtFullDate})` } type="warning" />
      )}
      {!isLast && (
        <div class="row">
          <div class="col-lg-12 col-12">
            <hr class="dashed" />
          </div>
        </div>
      )}
    </Fragment>
  );
};
