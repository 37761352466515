import { useStoreFly } from "../store";

export const useFilters = () => {
  const filterClass = useStoreFly((state) => state.filterClass);
  const filterRoute = useStoreFly((state) => state.filterRoute); 
  const passengers = useStoreFly((state) => state.passengers);
  const filterAirlines = useStoreFly((state) => state.filterAirlines);
  const filterStops = useStoreFly((state) => state.filterStops);
  const flightTimes = useStoreFly((state) => state.filterFlightTimes);

  const airlines = filterAirlines?.selected
    ?.filter((selectedAirline) => selectedAirline.checked)
    ?.map((item) => item.id);
  
  return {
    flyFrom: filterRoute?.origin?.city?.id,
    flyTo: filterRoute?.destination?.city?.id,
    dateFrom: filterRoute?.dateFrom,
    dateTo: filterRoute?.dateTo,
    adults: passengers?.adult,
    children: passengers?.children,
    babies: passengers?.babies,
    selectedClass: filterClass,
    selectedAirlines: airlines,
    maxStops: filterStops?.current,
    flightTimeGoing: flightTimes?.going,
    flightTimeReturning: flightTimes?.returning,
  };
};
