import { useStaticImages } from "../../hooks/useStaticImages";

export const NoResultsFoundWithButtons = () => {
  const { emptyIcon } = useStaticImages();
  return (
    <div class="col-lg-7 col-md-8 col-12">
      <div class="col-lg-12 text-center whitebox smilemsg emptybox col-md-12 col-12">
        <div class="row justify-content-center">
          <div class="col-lg-10 col-md-12 col-12">
            <img srctSe={emptyIcon} />
            <h4 class="mt-4">
              We,re sorry, but this deal is no longer available!
            </h4>
            <p class="mb-4">
              Don't give up on your travel plans just yet
              <br /> you can check for availability on other dates,
              <br /> or have a look at more of our great hotels.
            </p>
            <a class="default-yellow-btn small mx-2" href="#">
              See other hotel
            </a>
            <a class="default-yellow-btn small mx-2" href="#">
              Change dates
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
