// import { Navigation } from "swiper/modules";
// import "swiper/css";
// import "swiper/css/navigation";
import { useQueryParams } from "../../../hooks/useQueryParams";
import { Fragment, useEffect, useState } from "react";
import {
  RATE_HAWK_API_KEY,
  RATE_HAWK_ENDPOINT_HOTEL_PAGE,
  RATE_HAWK_KEY,
} from "../../../utils/constants";
import { POST } from "../../../utils/api";
import { NoResultsFoundWithButtons } from "../../../components/common/NoResultsFoundWithButton";

const HotelPage = ({ translation }) => {
  const query = useQueryParams();
  const id = query().get("id");
  const adults = Number(query().get("adults"));
  const children = Number(query().get("children"));
  const checkIn = query().get("checkIn");
  const checkOut = query().get("checkOut");
  const currency = query().get("currency");
  const regionId = Number(query().get("regionId"));
  // console.log(query().get("id"));

  const [hotelPageResults, setHotelPageResults] = useState([]);

  // console.log({ id, hotelPageResults });

  useEffect(() => {
    const fetchHotelSearchResults = async () => {
      const token = btoa(RATE_HAWK_KEY + ":" + RATE_HAWK_API_KEY);
      // console.log({ id });
      const body = {
        id,
        checkin: checkIn,
        checkout: checkOut,
        // residency: "gb",
        language: "en",
        guests: [
          {
            adults: adults,
            children: Array.from({ length: children }, (_, ch) => ch + 1) || [],
          },
        ],
        region_id: regionId,
        currency: currency,
      };
      const response = await POST(RATE_HAWK_ENDPOINT_HOTEL_PAGE, {
        headers: {
          Authorization: "Basic " + token,
        },
        body: JSON.stringify(body),
      });
      // console.log(response);
      setHotelPageResults(response?.data || []);
    };

    if (id) {
      fetchHotelSearchResults();
    }
  }, [id, adults, checkIn, checkOut, children, currency, regionId]);

  return (
    <Fragment>
      {hotelPageResults ? (
        <section class="content">
          <div class="container">
            <div class="row">
              <div class="col-lg-10 col-md-12 col-12">
                <div class="col-lg-12 whitebox cust-space pt-3 col-md-12 col-12">
                  <div class="row align-items-center">
                    <div class="col-lg-8 col-md-7 col-12">
                      <span class="stars fs-17 pb-1 d-block">
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                      </span>
                      <h6 class="text-blue fs-19 fw-semibold">
                        B&B Hotel Paris Porte De Bagnolet
                      </h6>
                      <p class="location">
                        <i class="fa fa-map-marker"></i> 44 Avenue Gambetta,
                        Bagnolet | Show on the map | What else is nearby?
                        <br /> 4.8 km from the city center | 406 m from Gallieni
                      </p>
                    </div>
                    <div class="col-lg-4 text-right col-md-5 col-12">
                      <span class="d-block text-blue fs-17 fw-medium">
                        from 369€
                      </span>
                      <a
                        class="default-yellow-btn small mt-1 m-width-175"
                        href="#"
                      >
                        Show rooms
                      </a>
                    </div>
                  </div>
                </div>
                <div class="owl-carousel mt-4 owl-theme img-carousel">
                  <div class="item">
                    <div class="col-lg-12 sps col-md-12 col-12">
                      <div class="row spdiv">
                        <div class="col-lg-7 mb-1 col-md-7 col-12">
                          <img
                            class="w-100 h-100"
                            src="assets/img/image-1.png"
                            alt=""
                          />
                        </div>
                        <div class="col-lg-5 col-md-5 col-12">
                          <div class="row spdiv">
                            <div class="col-lg-6 mb-1 col-md-6 col-12">
                              <img
                                class="w-100"
                                src="assets/img/image-2.png"
                                alt=""
                              />
                            </div>
                            <div class="col-lg-6 mb-1 col-md-6 col-12">
                              <img
                                class="w-100"
                                src="assets/img/image-3.png"
                                alt=""
                              />
                            </div>
                            <div class="col-lg-6 mb-1 col-md-6 col-12">
                              <img
                                class="w-100"
                                src="assets/img/image-4.png"
                                alt=""
                              />
                            </div>
                            <div class="col-lg-6 mb-1 col-md-6 col-12">
                              <img
                                class="w-100"
                                src="assets/img/image-5.png"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="item">
                    <div class="col-lg-12 sps col-md-12 col-12">
                      <div class="row spdiv">
                        <div class="col-lg-7 mb-1 col-md-7 col-12">
                          <img
                            class="w-100 h-100"
                            src="assets/img/image-1.png"
                            alt=""
                          />
                        </div>
                        <div class="col-lg-5 col-md-5 col-12">
                          <div class="row spdiv">
                            <div class="col-lg-6 mb-1 col-md-6 col-12">
                              <img
                                class="w-100"
                                src="assets/img/image-2.png"
                                alt=""
                              />
                            </div>
                            <div class="col-lg-6 mb-1 col-md-6 col-12">
                              <img
                                class="w-100"
                                src="assets/img/image-3.png"
                                alt=""
                              />
                            </div>
                            <div class="col-lg-6 mb-1 col-md-6 col-12">
                              <img
                                class="w-100"
                                src="assets/img/image-4.png"
                                alt=""
                              />
                            </div>
                            <div class="col-lg-6 mb-1 col-md-6 col-12">
                              <img
                                class="w-100"
                                src="assets/img/image-5.png"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12 mt-3 whitebox cust-space pb-0 col-md-12 col-12">
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-12">
                      <h6 class="text-blue fs-19 fw-semibold mb-0">
                        Available rooms
                      </h6>
                    </div>
                  </div>
                  <div class="row room-spce mt-3">
                    <div class="col-lg-3 arrow-link col-md-6 col-12">
                      <div>
                        Beds
                        <br />
                        <select
                          class="selectpicker"
                          aria-label="Default select example"
                          data-live-search="true"
                        >
                          <option>all options</option>
                          <option>double bed</option>
                          <option>seperate bed</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-lg-3 arrow-link col-md-6 col-12">
                      <div>
                        Meals
                        <br />
                        <select
                          class="selectpicker"
                          multiple
                          aria-label="Default select example"
                          data-live-search="true"
                        >
                          <option>all options</option>
                          <option>no meals</option>
                          <option>breakfast</option>
                          <option>breakfast + lunch or dinner</option>
                          <option>breakfast, lunch and dinner</option>
                          <option>all inclusive</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-lg-3 arrow-link col-md-6 col-12">
                      <div>
                        Cancellation policy
                        <br />
                        <select
                          class="selectpicker"
                          aria-label="Default select example"
                          data-live-search="true"
                        >
                          <option>all options</option>
                          <option>with free cancellation</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-lg-3 arrow-link col-md-6 col-12">
                      <div>
                        Payment
                        <br />
                        <select
                          class="selectpicker"
                          multiple
                          aria-label="Default select example"
                          data-live-search="true"
                        >
                          <option>pay now</option>
                          <option>pay on the spot</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-3 border-blue">
                    <div class="col-lg-2 pl-0 col-md-3 col-12">
                      <img class="w-100" src="assets/img/leftimg.png" alt="" />
                    </div>
                    <div class="col-lg-10 pt-3 col-md-9 col-12">
                      <h6 class="text-blue fs-19 fw-semibold mb-0">
                        Standard Double room
                      </h6>
                      <p class="text-blue fs-13">full double bed</p>
                    </div>
                  </div>
                  <div class="row tableinfo mt-3">
                    <div class="col-lg-12 p-0 col-md-12 col-12">
                      <div class="table-responsive">
                        <table class="table m-0">
                          <thead>
                            <tr>
                              <th>Room</th>
                              <th>Meals</th>
                              <th>Cancellation</th>
                              <th>Price</th>
                              <th>&nbsp;</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <img
                                  src="assets/img/img-destination.png"
                                  alt=""
                                />
                                <br />
                                Double Room (1 Double Bed)
                                <br />
                                Window
                                <br />
                                Non-smoking
                              </td>
                              <td>
                                Meals are not
                                <br />
                                included
                              </td>
                              <td>
                                0€ <br /> until Nov 24
                              </td>
                              <td class="pricetext">369€</td>
                              <td>
                                <a class="default-yellow-btn select" href="#">
                                  Select {">"}{" "}
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <img
                                  src="assets/img/img-destination.png"
                                  alt=""
                                />
                                <br />
                                Double Room (1 Double Bed)
                                <br />
                                Window
                                <br />
                                Non-smoking
                              </td>
                              <td>
                                Meals are not
                                <br />
                                included
                              </td>
                              <td>
                                0€ <br /> until Nov 24
                              </td>
                              <td class="pricetext">369€</td>
                              <td>
                                <a class="default-yellow-btn select" href="#">
                                  Select {">"}{" "}
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <img
                                  src="assets/img/img-destination.png"
                                  alt=""
                                />
                                <br />
                                Double Room (1 Double Bed)
                                <br />
                                Window
                                <br />
                                Non-smoking
                              </td>
                              <td>
                                Meals are not
                                <br />
                                included
                              </td>
                              <td>
                                0€ <br /> until Nov 24
                              </td>
                              <td class="pricetext">369€</td>
                              <td>
                                <a class="default-yellow-btn select" href="#">
                                  Select {">"}
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <img
                                  src="assets/img/img-destination.png"
                                  alt=""
                                />
                                <br />
                                Double Room (1 Double Bed)
                                <br />
                                Window
                                <br />
                                Non-smoking
                              </td>
                              <td>
                                Meals are not
                                <br />
                                included
                              </td>
                              <td>
                                0€ <br /> until Nov 24
                              </td>
                              <td class="pricetext">369€</td>
                              <td>
                                <a class="default-yellow-btn select" href="#">
                                  Select {">"}
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12 mb-2 text-center col-md-12 col-12">
                  <a class="show-more-btn fs-13" href="#">
                    <img
                      class="ml-0 mr-2"
                      src="assets/img/arrow-down-black.png"
                    />{" "}
                    Show 5 more rates
                  </a>
                </div>
                <div class="col-lg-12 mt-4 whitebox cust-space pb-3 col-md-12 col-12">
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-12">
                      <h6 class="text-blue fs-19 fw-semibold mb-0">Location</h6>
                      <p class="text-blue fs-13">
                        Bagnolet, 44 Avenue Gambetta
                      </p>
                    </div>
                    <div class="col-lg-12 mt-3 col-md-12 col-12">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d20996.25989647119!2d2.4077350197874896!3d48.86712475466757!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e66d6fcb478cb7%3A0x40b82c3688b3c70!2s93170%20Bagnolet%2C%20France!5e0!3m2!1sen!2sin!4v1671714924771!5m2!1sen!2sin"
                        width="100%"
                        height="290"
                        style="border:0;"
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                      ></iframe>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12 mt-4 whitebox cust-space pb-3 m-height col-md-12 col-12">
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-12">
                      <h6 class="text-blue fs-19 fw-semibold mb-0">
                        Hotel description
                      </h6>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-lg-8 col-md-9 col-12">
                      <p class="text-blue fs-13">
                        Location
                        <br /> «B&B Hotel Paris Porte De Bagnolet» is located in
                        Bagnolet. This hotel is located in the very center of
                        the city. In the morning, have a cup of coffee while
                        looking at the city from the window. You can take a walk
                        and explore the neighbourhood area of the hotel. Places
                        nearby: Gallieni, Notre Dame Cathedral and
                        Sainte-Chapelle.
                      </p>
                      <p class="text-blue fs-13">
                        At the hotel
                        <br />
                        Want to be always on-line? Wi-Fi is available. If you
                        travel by car, there’s a paid parking zone at the hotel.
                        You can take your pet with you for the journey. Pets are
                        allowed. Accessible for guests with disabilities: the
                        elevator helps them to go to the highest floors.
                      </p>
                    </div>
                    <div class="col-lg-3 ml-auto col-md-3 col-12">
                      <p class="text-blue fs-13">Hotel info</p>
                      <p class="text-blue fs-13">
                        Year of construction
                        <br />
                        2020
                        <br />
                        Socket type
                        <br />
                        French?
                        <br />
                        230 V / 50 Hz
                        <br />
                        Rooms number
                        <br />
                        108 rooms
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-2 pl-lg-0 pl-md-0 ml-auto mt-4 mt-md-4 mt-lg-0 col-md-4 col-12">
                <div class="col-lg-12 logobox text-center bg-white col-md-12 col-12">
                  <img src="assets/img/sims_marketing_agency_logo.png" />
                </div>
                <div class="col-lg-12 mt-4 whitebox text-center pt-4 pb-4 col-md-12 col-12">
                  <p class="mailp fw-medium">
                    <img src="assets/img/icon-mail.png" alt="" /> Receive
                    exclusive <br /> deals by email
                  </p>
                  <a class="default-yellow-btn tiny mt-2 mb-2" href="#">
                    Subscribe
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <NoResultsFoundWithButtons />
      )}
    </Fragment>
  );
};

export default HotelPage;
