export const RadioWithLabel = ({
  label,
  value,
  selectedValue,
  handleChange,
}) => {
  return (
    <div class="custom-control custom-radio any-input" key={value}>
      <input
        type="radio"
        id={label}
        name={label}
        class="pointer custom-control-input"
        checked={value === selectedValue}
        onChange={handleChange}
      ></input>
      <label class="pointer custom-control-label" for={label}>
        {label}
      </label>
    </div>
  );
};
