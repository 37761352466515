import {
  ClickAwayListener,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { SELECTED_CABINS, SELECTED_CLASS } from "../../utils/enums";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { getItemFromLocalStorage } from "../../common/methods";
import moment from "moment";
import { nanoid } from "nanoid";

const { Fragment } = require("react");

const HomeMobileSearch = ({
  changeSearch,
  setChangeSearch,
  returnOrOneWay,
  setReturnOrOneWay,
  translation,
  originValue,
  setOriginValue,
  destinationValue,
  setDestinationValue,
  originRef,
  destinationRef,
  travellerRef,
  travellerDropDownShow,
  setTravellerDropDownShow,
  handleOption,
  handleClass,
  options,
  selectedClass,
  setFilterClass,
  selectedAirlines,
  setFilter,
  currentSearch,
  originCity,
  destinationCity,
  originCode,
  destinationCode,
  origin,
  destination,
  flyFrom,
  flyTo,
  dateFrom,
  dateTo,
  adults,
  children,
  babies,
  isMobile,
  setblurBackground,
  setViewOriginLookup,
  setViewDestinationLookup,
  location,
  navigate,
  RangePickerMemo,
}) => {
  return (
    <div id="home_mobile_search">
      <div class="col-lg-12 col-md-12 col-12" id="return">
        <div class="col-lg-12 radiobg p-0 col-md-12 col-12">
          <div class="custom-control custom-radio">
            <input
              type="radio"
              id="return"
              name="customRadio"
              class="custom-control-input return-radio"
              value="return"
              checked={returnOrOneWay === "return"}
              onChange={() => {
                setReturnOrOneWay("return");
              }}
            />
            <label class="custom-control-label" for="return">
              {translation("return")}
            </label>
          </div>
          <div class="custom-control custom-radio">
            <input
              type="radio"
              id="oneway"
              name="customRadio"
              class="custom-control-input oneway-radio"
              value="oneway"
              checked={returnOrOneWay === "oneway"}
              onChange={() => {
                setReturnOrOneWay("oneway");
                // console.log("oneway");
              }}
            />
            <label class="custom-control-label" for="oneway">
              {translation("one_way")}
            </label>
          </div>
        </div>
      </div>

      <div className="col-lg-6 ftinput col-md-6 col-12">
        <div className="form-group">
          <input
            type="search"
            className="form-control flight1 custom-dropdown"
            // open-this="pickup-dropdown"
            value={
              originValue !== undefined
                ? originValue
                : (location.pathname !== "/" && currentSearch?.cityNameFrom) ||
                  ""
            }
            // disabled={isMobile ? true : false}
            onClick={() => {
              setViewOriginLookup(true);
            }}
            ref={originRef}
            onChange={(event) => {
              if (isMobile) {
                setViewOriginLookup(true);
              }
              setOriginValue(event.target.value);
              setFilter("filterRoute", "originInput", event.target.value);
            }}
            id="viewOriginLookup"
            style={{
              caretColor: isMobile && "transparent",
              marginBottom: isMobile && "-1px",
            }}
            placeholder={translation("origin")}
          />
        </div>
      </div>

      <div className="col-lg-6 ltinput col-md-6 col-12">
        <div className="form-group">
          <input
            type="text"
            className="form-control flight2 custom-dropdown"
            // open-this="pickup-dropdown"
            value={
              destinationValue !== undefined
                ? destinationValue
                : (location.pathname !== "/" && currentSearch?.cityNameTo) || ""
            }
            // disabled={viewDestinationLookup}
            onClick={() => {
              // setDestinationInputFocused(true);
              // setblurBackground(true);
              setViewDestinationLookup(true);
            }}
            ref={destinationRef}
            onChange={(event) => {
              setViewDestinationLookup(true);
              setDestinationValue(event.target.value);
              setFilter("filterRoute", "destinationInput", event.target.value);
            }}
            style={{
              caretColor: "transparent",
            }}
            placeholder={translation("destination")}
          />
        </div>
      </div>

      <div
        className="row daterow sprdiv"
        style={{
          padding: "0 15px",
        }}
      >
        <div
          className="col-lg-12 row ml-auto mr-auto w-100 datebox"
          style={{
            padding: "0 15px",
            border: "1px solid #ced4da",
            borderRadius: "6px",
          }}
        >
          <div className="col-lg-12 col-md-12 col-6">
            <div className="form-group m-0">
              {/* This is being used */}

              <RangePickerMemo />
            </div>
          </div>
          {/* <div className="col-lg-6 col-md-6 col-6">
                              <div className="form-group m-0">
                              </div>
                            </div> */}
        </div>
      </div>

      <div className="col-lg-6 col-md-6 col-12">
        <div className="form-group">
          <input
            type="text"
            className="form-control bg-user"
            open-this="traveller-dropdown"
            onClick={() => {
              // setTravelerBlur(false)
              // setblurBackground(true)
              setTravellerDropDownShow(true);
            }}
            value={`${
              Number(options.adult) +
              Number(options.children) +
              Number(options.babies) +
              " " +
              translation("traveler") +
              (options.adult > 1 || options.children || options.babies
                ? translation("s")
                : "")
            } , ${translation(SELECTED_CABINS[selectedClass])}`}
            placeholder={`${
              Number(options.adult) +
              Number(options.children) +
              Number(options.babies) +
              " " +
              translation("traveler") +
              ((options.adult > 1 || options.children || options.babies) &&
                translation("s"))
            } , ${translation(SELECTED_CABINS[selectedClass])}`}
            ref={travellerRef}
            style={{
              caretColor: isMobile && "transparent",
            }}
            // onBlur={() => setTravelerBlur(false)}
            // onFocus={() => setTravelerBlur(false)}
          />
          <div className="traveller-dropdown">
            {travellerDropDownShow && (
              <ClickAwayListener
                onClickAway={() => {
                  // setTravellerDropDownShow(false);
                  setblurBackground(false);
                }}
              >
                <ul
                  className="list-group"
                  style={{
                    display: "block",
                    overflow: "hidden",
                    minHeight: "400px",
                  }}
                >
                  <div className="traveller-hearder">
                    <h6 className="px-3 pt-3 pb-0">
                      {translation("passenger_and_class")}
                    </h6>
                    <div className="closeTravellerWindow">
                      <FontAwesomeIcon
                        onClick={() => {
                          setTravellerDropDownShow(false);
                          setblurBackground(false);
                        }}
                        size="lg"
                        icon={faXmark}
                      />
                    </div>
                  </div>
                  <hr className="mb-0" />
                  <li>
                    <a className="list-group-item d-flex justify-content-between align-items-center">
                      {translation("adults").toUpperCase()}
                      <br />
                      {translation("12_years_and_older")}
                      {/* here is the change */}
                      <div className="quantity">
                        <button
                          disabled={options.adult <= 1}
                          className={options.adult <= 1 ? "disabled" : "active"}
                          onClick={() => {
                            handleOption("adult", "minus");
                          }}
                        >
                          -
                        </button>
                        <input
                          data-value="true"
                          type="text"
                          readonly=""
                          value={options.adult}
                        />
                        <button
                          className="active"
                          onClick={() => handleOption("adult", "plus")}
                        >
                          +
                        </button>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="list-group-item d-flex justify-content-between align-items-center">
                      {translation("children").toUpperCase()}
                      <br />
                      {translation("2_to_11_years")}
                      <div className="quantity">
                        <button
                          disabled={options.children <= 0}
                          className={
                            options.children <= 0 ? "disabled" : "active"
                          }
                          onClick={() => handleOption("children", "minus")}
                        >
                          -
                        </button>
                        <input
                          data-value="true"
                          type="text"
                          readonly=""
                          value={options.children}
                        />
                        <button
                          className="active"
                          onClick={() => handleOption("children", "plus")}
                        >
                          +
                        </button>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="list-group-item d-flex justify-content-between align-items-center">
                      {translation("babies").toUpperCase()}
                      <br />
                      {translation("under_2_years_old")}
                      <div className="quantity">
                        <button
                          disabled={options.babies <= 0}
                          className={
                            options.babies <= 0 ? "disabled" : "active"
                          }
                          onClick={() => handleOption("babies", "minus")}
                        >
                          -
                        </button>
                        <input
                          data-value="true"
                          type="text"
                          readonly=""
                          value={options.babies}
                        />
                        <button
                          className="active"
                          onClick={() => handleOption("babies", "plus")}
                        >
                          +
                        </button>
                      </div>
                    </a>
                  </li>
                  <FormControl>
                    <RadioGroup
                      defaultValue={
                        currentSearch?.selectedClass &&
                        location.pathname !== "/"
                          ? currentSearch?.selectedClass
                          : selectedClass
                      }
                    >
                      <li>
                        <FormControlLabel
                          className="custom-control-label-react"
                          value="M"
                          control={
                            <Radio
                              className="economy-radio"
                              onChange={() => {
                                handleClass("Economy");
                                setFilterClass(SELECTED_CLASS["Economy"]);
                              }}
                              sx={{
                                color: "#003663",
                                "&.Mui-checked": {
                                  color: "#003663",
                                },
                              }}
                            />
                          }
                          label={translation("Economy")}
                          sx={{
                            ".MuiTypography-root": {
                              fontWeight: 500,
                              fontSize: 18,
                              fontFamily: "'Poppins', sans-serif",
                              color: "#003663",
                            },
                            paddingLeft: 2,
                          }}
                        />
                      </li>
                      <li>
                        <FormControlLabel
                          className="custom-control-label-react"
                          value="W"
                          control={
                            <Radio
                              className="economy-premium-radio"
                              onChange={() => {
                                handleClass("Economy Premium");
                                setFilterClass(
                                  SELECTED_CLASS["Economy Premium"]
                                );
                              }}
                              sx={{
                                color: "#003663",
                                "&.Mui-checked": {
                                  color: "#003663",
                                },
                              }}
                            />
                          }
                          label={translation("Premium Economy")}
                          sx={{
                            ".MuiTypography-root": {
                              fontWeight: 500,
                              fontSize: 18,
                              fontFamily: "'Poppins', sans-serif",
                              color: "#003663",
                            },
                            paddingLeft: 2,
                          }}
                        />
                      </li>
                      <li>
                        <FormControlLabel
                          className="custom-control-label-react"
                          value="C"
                          control={
                            <Radio
                              className="business-radio"
                              onChange={() => {
                                handleClass("Business");
                                setFilterClass(SELECTED_CLASS["Business"]);
                              }}
                              sx={{
                                color: "#003663",
                                "&.Mui-checked": {
                                  color: "#003663",
                                },
                              }}
                            />
                          }
                          label={translation("Business")}
                          sx={{
                            ".MuiTypography-root": {
                              fontWeight: 500,
                              fontSize: 18,
                              fontFamily: "'Poppins', sans-serif",
                              color: "#003663",
                            },
                            paddingLeft: 2,
                          }}
                        />
                      </li>
                    </RadioGroup>
                  </FormControl>
                </ul>
              </ClickAwayListener>
            )}
          </div>
        </div>
      </div>

      <div className="col-lg-3 col-md-3 col-12">
        <div className="form-group">
          <button
            onClick={async (_) => {
              const currentSearch = getItemFromLocalStorage("search");

              const twoDaysFromNow = new Date(new Date());
              twoDaysFromNow.setDate(new Date().getDate() + 2);
              const twoDaysFromNowDate = twoDaysFromNow;
              const isNotHomePage = location.pathname !== "/";
              const currentFlyFrom = flyFrom || currentSearch?.flyFrom || "";
              const currentFlyTo = flyTo || currentSearch?.flyTo || "";
              const currentDateFrom =
                dateFrom ||
                currentSearch?.dateFrom ||
                moment(new Date()).format("DD/MM/yyyy");
              const currentDateTo =
                dateTo ||
                currentSearch?.dateTo ||
                moment(twoDaysFromNowDate).format("DD/MM/yyyy");

              const currentReturnOrOneWay =
                isNotHomePage && currentSearch?.returnOrOneWay
                  ? currentSearch?.returnOrOneWay
                  : returnOrOneWay;

              localStorage.setItem(
                "search",
                JSON.stringify({
                  flyFrom: currentFlyFrom,
                  flyTo: currentFlyTo,
                  dateFrom: currentDateFrom,
                  dateTo: currentDateTo,
                  adults,
                  babies,
                  children,
                  selectedClass,
                  vehicleType: "aircraft",
                  limit: 30,
                  selectedAirlines: selectedAirlines || [],
                  aTimeFrom: "0:00",
                  aTimeTo: "24:00",
                  dtimeFrom: "0:00",
                  dtimeTo: "24:00",
                  retAtimeFrom: null,
                  retAtimeTo: null,
                  retDtimeFrom: null,
                  retDtimeTo: null,
                  returnFromDate:
                    currentReturnOrOneWay === "return" ? dateTo : null,
                  returnToDate:
                    currentReturnOrOneWay === "return" ? dateTo : null,
                  returnOrOneWay: returnOrOneWay || currentReturnOrOneWay,
                  curr: "EUR",
                  cityNameFrom: originCity || currentSearch?.cityNameFrom,
                  cityNameTo: destinationCity || currentSearch?.cityNameTo,
                  originCode: originCode || currentSearch?.originCode,
                  destinationCode:
                    destinationCode || currentSearch?.destinationCode,
                })
              );
              if (location.pathname === "/") {
                localStorage.setItem("filter", "{}");
              }
              setChangeSearch(false);
              navigate(`/search?session=${nanoid()}`);
            }}
            type="submit"
            className="btn-submit"
            disabled={
              (!originCity || !destinationCity) && location.pathname === "/"
            }
          >
            {translation("search")}
            <img src={require("../../assets/img/icon-search.png")} alt="" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default HomeMobileSearch;
