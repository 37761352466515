import { FlightUtils } from "../common/FlightUtils";
import { combineDateAndTime, dayOfWeekAsString, getDayValue, monthAsString } from "../common/methods";
import { useStoreFly } from "../store";
const FlightUtil = new FlightUtils();

export const useFlightRouteDetails = (route) => {
   
  const airlines =  useStoreFly((state) => state.airlines);
  const airline = FlightUtil.getAirline(route?.airline, airlines);
  const flightNo = route?.flightNum;
  const operatingFlightNo = route?.flightNum;
  const vehicleType = 'Aircraft'; 
  const departure = new Date(combineDateAndTime(route,true));
  const arrival = new Date(combineDateAndTime(route,false));  
  const departAt = `${dayOfWeekAsString(
    departure.getDay()
  )}, ${monthAsString(departure.getMonth())} ${getDayValue(departure)}`; 

  const arriveAt = `${dayOfWeekAsString(
    arrival.getDay()
  )}, ${monthAsString(arrival.getMonth())} ${getDayValue(arrival)}`; 

  

  return {
    airline,
    flightNo,
    operatingFlightNo,
    vehicleType,
    departAt,
    arriveAt,
    departure,
    arrival,
    departAtDay: dayOfWeekAsString(
      departure.getDay()
    ),
    departAtMonth: monthAsString(departure.getMonth()),
    departAtNumber: getDayValue(departure),
    arriveAtDay: dayOfWeekAsString(arrival.getDay()),
    arriveAtMonth: monthAsString(arrival.getMonth()),
    arriveAtNumber: getDayValue(arrival),
  };
};
