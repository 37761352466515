import moment from "moment";
import { getItemFromLocalStorage } from "../common/methods";
import { SELECTED_CLASS } from "./enums";
// export const MAIN_LAMBDA = "http://localhost:3001/dev"; 
export const MAIN_LAMBDA = "https://7p06sk4uo1.execute-api.eu-central-1.amazonaws.com/dev";  
export const KIWI_ENDPOINT = "https://api.tequila.kiwi.com";
export const SEARCH_API = `${KIWI_ENDPOINT}/v2/search`;
export const LOCATIONS_TOP_DESTINATIONS = `${KIWI_ENDPOINT}/locations/topdestinations`;
export const ITRAVEL_KEY_API = "g41gSKW6qUn9ixlyeNTjL4vuJkA41wiC";
export const MULTICITY_KEY_API = "u8zZE2SyYfjUukVnJG6CM8wwfNDtXIlA";
export const AIRLINES_ENDPOINT = "https://tequila-api.kiwi.com/carriers";
export const AIRLINES_IMAGE_ENDPOINT = "https://images.kiwi.com/airlines";
export const GET_LOCATION_BY_ID = `${KIWI_ENDPOINT}/locations/id`;
export const MULTI_CITY_ENDPOINT = `${KIWI_ENDPOINT}/flights/multicity`;
export const LIST_COUNTRIES = "https://restcountries.com/v3.1/all";
export const BOOKING_CHECK_FLIGHT = `${KIWI_ENDPOINT}/v2/booking/check_flights`;
export const BOOKING_SAVE = `${KIWI_ENDPOINT}/v2/booking/save_booking`;
export const BOOKING_CONFIRM_PAYMENT = `${KIWI_ENDPOINT}/v2/booking/confirm_payment`;
export const RATE_HAWK_ENDPOINT_AUTOCOMPLETE =
  "https://api.worldota.net/api/b2b/v3/search/multicomplete";
export const RATE_HAWK_ENDPOINT_HOTEL_REGION_SEARCH =
  "https://api.worldota.net/api/b2b/v3/search/serp/region/";
export const RATE_HAWK_ENDPOINT_HOTEL_PAGE  =  "https://api.worldota.net/api/b2b/v3/search/hp/"
export const RATE_HAWK_KEY = "5773";
export const RATE_HAWK_API_KEY = "401dbb47-ac32-4d20-b753-c9c4ff10ef21";

export const GET_LIST_COUNTRY = (countryName) =>
  `https://restcountries.com/v3.1/name/${countryName}`;
export const GET_LIST_COUNTRY_ALL = "https://restcountries.com/v3.1/all";
export const CITY_DETAILS_BASED_ON_GEOLOCATION = (latitude, longitude) =>
  `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}`;
export const TEB_PAYMENT_ENDPOINT =
  "https://ecommerce.teb-kos.com/fim/est3Dgate";
export const FLUTURO_LIRE_APPROVE =
  `${MAIN_LAMBDA}/approvePayment`;
export const FLUTURO_LIRE_GENERATE_HASH =
  `${MAIN_LAMBDA}/generateHash`;
export const FLUTURO_LIRE_REDIRECT =
  `${MAIN_LAMBDA}/redirect`;

export const GET_IMAGE_FOR_CITY = (size, city) =>
  `https://images.kiwi.com/photos/${size || "472x440"}/${city}.jpg`;


// KOMENT jon search parametrat kur te lyp per fluturime
// adultCabinBag edhe adultCheckedInBag  perdoren (dokumentimi qysh funksionon https://tequila.kiwi.com/portal/docs/tequila_api/search_api)
export const SEARCH_API_QUERY_PARAMS = ({
  flyFrom,
  flyTo,
  dateFrom,
  dateTo,
  adults,
  children,
  babies,
  vehicleType,
  dtimeFrom,
  dtimeTo,
  aTimeFrom,
  aTimeTo,
  retDtimeFrom,
  retDtimeTo,
  retAtimeFrom,
  retAtimeTo,
  sort,
  limit,
  returnFromDate,
  returnToDate,
  adultCabinBag,
  adultCheckedInBag,
  selectedAirlines,
  maxStops,
  selectedClass,
  curr,
}) => {
  const isReturn = getItemFromLocalStorage("search")?.returnOrOneWay === "return";
  // const isNotHome = window.location.pathname !== "/";
  
  let queryParams = "";

  const twoDaysFromNow = new Date(new Date());
  twoDaysFromNow.setDate(new Date().getDate() + 2);
  const twoDaysFromNowDate = twoDaysFromNow;

  // console.log({returnToDate});
  Object.entries({
    fly_from: flyFrom,
    fly_to: flyTo,
    date_from: dateFrom,
    date_to: dateFrom,
    selected_cabins: SELECTED_CLASS[selectedClass],
    vehicle_type: vehicleType,
    adults,
    children,
    infants: babies,
    adult_hold_bag: adultCheckedInBag,
    adult_hand_bag: adultCabinBag,
    dtime_from: dtimeFrom || "0:00",
    dtime_to: dtimeTo || "24:00",
    atime_from: aTimeFrom || "0:00",
    atime_to: aTimeTo || "24:00",
    ret_dtime_from: retDtimeFrom || (isReturn && "0:00") || undefined,
    ret_dtime_to: retDtimeTo || (isReturn && "24:00") || undefined ,
    ret_atime_from: retAtimeFrom || (isReturn && "0:00") || undefined,
    ret_atime_to: retAtimeTo || (isReturn && "24:00") || undefined,
    sort,
    limit,
    return_from: returnFromDate || (isReturn && returnFromDate) || (isReturn && moment(twoDaysFromNowDate).format("DD/MM/yyyy")) || undefined,
    return_to: returnFromDate || (isReturn && returnFromDate) || (isReturn && moment(twoDaysFromNowDate).format("DD/MM/yyyy")) || undefined,
    select_airlines: selectedAirlines || [],
    max_stopover: maxStops,
    curr: curr?.curr || curr || "EUR",
    // partner: "itravel"
  }).forEach(([key, prop], index, arrayOfParams) => {
    // console.log({key, prop});
    if (prop !== undefined) {
      queryParams += `${key}=${prop}`;
      // if (index < arrayOfParams.length - 1) {
        queryParams += "&";
      // }
    }
  });
  // console.log({queryParams});
  return queryParams;
};

export const GET_LOCATIONS_QUERY_PARAMS = (
  ids = [],
  locale = "en-US",
  activeOnly = true
) => {
  let queryParams = `?locale=${locale}&active_only=${activeOnly}&`;

  ids.forEach((id, index, arrayOfParams) => {
    if (arrayOfParams.length === index + 1 && id) {
      queryParams += `id=${id}`;
      return;
    }
    if (id) {
      queryParams += `id=${id}&`;
    }
  });
  return queryParams;
};

export const MAIN_PASSENGER_INITIAL_FORM = {
  name: "",
  surname: "",
  nationality: "",
  gender: "",
  cardno: "",
  dayOfBirth: "",
  monthOfBirth: "",
  yearOfBirth: "",
  email: "",
  phone: "",
  address: "",
  postcode: "",
  city: "",
  country: "",
  category: "adult",
};

export const GET_MULTICITY_REQUEST_PARAMS = ({
  curr = "EUR",
  sort = "price",
  adults = 1,
  children = 0,
  babies = 0,
  selectedCabin = "M",
  flights = [],
}) => {
  const queryParams = `?curr=${curr}&sort=${sort}`;
  const payload = {
    adults,
    selected_cabins: selectedCabin,
    vehicle_type: "aircraft",
    via_blocks: flights,
  };

  if (children) {
    payload["children"] = children;
  }

  if (babies) {
    payload["infants"] = babies;
  }

  return { queryParams, payload };
};

export const GET_LOCATIONS = (term) =>
  `${MAIN_LAMBDA}/locations?locale=en-US&active_only=true&limit=20&location_types=airport&term=${term}`;

export const TOP_LOCATIONS = (term = "PRN") =>
  `${KIWI_ENDPOINT}/locations/topdestinations?term=${term}&locale=en-US&limit=6&sort=price&active_only=true`;

export const GET_LOCATIONS_BY_BOX = (
  southWestLat,
  southWestLong,
  northEastLat,
  northEastLong
) =>
  `${KIWI_ENDPOINT}/locations/box?low_lat=${southWestLat}&low_lon=${southWestLong}&high_lat=${northEastLat}&high_lon=${northEastLong}&locale=en-US&location_types=airport&limit=6&active_only=true`;

export const GET_HOTEL_SEARCH_PARAMS = (
  adults,
  children,
  checkInDate,
  checkOutDate,
  regionId,
  currency = "EUR"
) =>
  `adults=${adults}&children=${children}&checkIn=${checkInDate}&checkOut=${checkOutDate}&regionId=${regionId}&currency=${currency}`;
