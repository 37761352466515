import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import {
  RangeDatePicker,
  SingleDatePicker,
} from "react-google-flight-datepicker";
import "react-google-flight-datepicker/dist/main.css";
import { useStoreFly } from "../../store";
import { getItemFromLocalStorage } from "../../common/methods";
import { useLocation } from "react-router-dom";

const toISODate = (inputDate = "") => {
  if (inputDate) {
    // Split the input date into day, month, and year components
    const [day, month, year] = inputDate.split("/");

    // Create a new Date object in the desired format "YYYY-MM-DD"
    const isoDate = `${year}-${month?.padStart(2, "0")}-${day?.padStart(2, "0")}`;

    return isoDate;
  }
};

export const RangePicker = ({
  isSingleSelect,
  placeholder,
  path,
  selectedDatesCallback,
  dateFormat,
}) => {
  const currentSearch = getItemFromLocalStorage("search");
  const [closeDatePicker, setCloseDatePicker] = useState(false);
  const setFilter = useStoreFly((state) => state.setFilter);
  const returnOrOneWay = useStoreFly((state) => state.returnOrOneWay);
  const location = useLocation()

  useEffect(() => {
    // console.log("changeeeeed", returnOrOneWay);
  }, [returnOrOneWay])

  let initDateFrom = toISODate(location.pathname !== "/" && currentSearch?.dateFrom) || "";
  let initDateTo = toISODate(location.pathname !== "/" && currentSearch?.returnToDate );

  const handleChange = (startDate, endDate) => {
    initDateFrom = moment(startDate).format(dateFormat || "DD/MM/yyyy");
    initDateTo = moment(endDate).format(dateFormat || "DD/MM/yyyy");

    if (startDate && endDate) {
      setFilter("filterRoute", "dateFrom", initDateFrom);
      setFilter("filterRoute", "dateTo", initDateTo);

      if (selectedDatesCallback) {
        selectedDatesCallback(initDateFrom, initDateTo);
      }
    }
 
  };
  function clickTravelers(e) {   
      if(e){

        let a = document.getElementById('traveller-dropdown-for-click')
        // console.log('a', a)
        setTimeout(() => {
          a.click()
          // for (let item of a) {
          //     console.log(item.focus());
          // }
        }, 300);
      }
  
  }
  

  const handleChangeSingleSelect = (startDate) => {
    if (startDate) {
      const date = moment(startDate).format("DD/MM/yyyy");
      setFilter("filterRoute", "dateFrom", date);
      setFilter("filterRoute", "dateTo", date);
    }
  };

  const currentDate = new Date();
  const twoDaysFromNow = new Date(currentDate);
  twoDaysFromNow.setDate(currentDate.getDate() + 2);
  const twoDaysFromNowDate = twoDaysFromNow;

  return (
    <Fragment>
      {returnOrOneWay === "oneway" && (
        <SingleDatePicker
          startDate={initDateFrom ? initDateFrom : new Date()}
          onChange={(startDate) => {
            handleChangeSingleSelect(startDate)
            setCloseDatePicker(true)
          } }
          onClose={clickTravelers()}
          minDate={new Date()}
          dateFormat="MM/DD/YYYY"
          monthFormat="MMM YYYY"
          onCloseCalendar={(event)=>clickTravelers(event)}
          startDatePlaceholder={placeholder}
          disabled={false}
          className="date-picker-flight"
          startWeekDay="monday"
        />
      )}
      {returnOrOneWay !== "oneway" && (
        <RangeDatePicker
          startDate={initDateFrom ? initDateFrom : new Date()}
          endDate={initDateTo ? initDateTo : twoDaysFromNowDate}
          onChange={(startDate, endDate) => handleChange(startDate, endDate)}
          minDate={new Date()}
          onCloseCalendar={(event)=>clickTravelers(event)}
          dateFormat="MM/DD/YYYY"
          monthFormat="MMM YYYY"
          startDatePlaceholder="Start Date"
          endDatePlaceholder="End Date"
          disabled={false}
          startWeekDay="monday"
          className="date-picker-flight"
          
        />
      )}
    </Fragment>
  );
};
