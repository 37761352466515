import { isMobile } from "react-device-detect";
import Banner from "../components/Banner";
import Copyrights from "../components/Copyrights";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Styles from "../components/Styles";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

export const AboutUsDEPage = () => {
  const translationContext = useTranslation();
  return (
    <div>
      <Styles />
      <Header
        translation={translationContext.t}
        translationContext={translationContext}
      />
      <Banner showSearchBar={false} minHeight={isMobile ? "120px" : "300px"} />
      <AboutUsDEPageContent />
      <Footer />
      <Copyrights />
    </div>
  );
};

const AboutUsDEPageContent = () => {
  return (
    <Fragment>
      <section
        className="content"
        style={{ padding: isMobile ? 0 : "95px 0px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-10 mt-lg-0 mt-md-0 mt-4 valuetabs col-md-8 col-12 whitebox">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  minHeight: "300px",
                  justifyContent: "center",
                }}
              >
                <p>
                  Herzlich willkommen bei Fluturolire.com, Ihrer führenden
                  Online-Reiseagentur mit einem umfangreichen Angebot an Zielen
                  und Flugmöglichkeiten. Mit Zugang zu 3.700 verschiedenen
                  Destinationen und über 750 Fluggesellschaften sind wir darauf
                  bedacht, Ihr Reiseerlebnis reibungslos und unvergesslich zu
                  gestalten. Ob Sie einen entspannten Urlaub oder eine
                  Geschäftsreise planen – unsere Plattform ist darauf ausgelegt,
                  Ihnen höchste Bequemlichkeit und Auswahl zu bieten. Erkunden
                  Sie die Welt mit Vertrauen, denn wir streben danach, Sie mit
                  den Destinationen zu verbinden, von denen Sie träumen, und
                  dabei einen mühelosen Buchungsvorgang sicherzustellen.
                </p>
                <p>
                  Bei Fluturolire.com sind wir stolz darauf, mehr als nur eine
                  Online-Reiseagentur zu sein; wir sind Ihr engagierter
                  Reisebegleiter, bereit, Ihre Reise von Anfang bis Ende zu
                  bereichern. Mit einem weitreichenden Netzwerk, das 3.700
                  verschiedene Ziele und Partnerschaften mit über 750
                  renommierten Fluggesellschaften umfasst, bieten wir Ihnen eine
                  Welt voller Möglichkeiten.
                </p>
                <p>
                  Unsere benutzerfreundliche Plattform ermöglicht es Ihnen, Ihr
                  Reiseerlebnis mühelos zu gestalten. Egal, ob Sie den
                  Nervenkitzel der Entdeckung versteckter Juwelen, die
                  Faszination kultureller Einblicke oder die Bequemlichkeit
                  nahtloser Geschäftsreisen suchen – wir haben für Sie die
                  passenden Optionen. Navigieren Sie durch unsere umfassende
                  Auswahl an Flügen, Unterkünften und Reisepaketen, um Ihre
                  Reiseroute perfekt auf Ihre Bedürfnisse abzustimmen.
                </p>
                <p>
                  Hinter den Kulissen arbeitet unser Team unermüdlich daran,
                  sicherzustellen, dass Ihre Reiseträume mühelos in Erfüllung
                  gehen. Wir legen Wert auf Transparenz und bieten detaillierte
                  Informationen zu Destinationen, Fluggesellschaften und
                  Unterkunftsoptionen. Unsere Verpflichtung zur
                  Kundenzufriedenheit bedeutet, dass Sie darauf vertrauen
                  können, dass wir zuverlässige und aktuelle Informationen
                  liefern, damit Sie informierte Entscheidungen treffen können.
                </p>
                <p>
                  Starten Sie Ihre Reise mit Fluturolire.com, wo Ihre
                  Reiseaspirationen über Horizonte hinausragen. Unsere Plattform
                  ist mehr als nur ein Buchungstool; sie ist ein Tor zur
                  Entdeckung, Abenteuer und unvergesslichen Momenten. Begleiten
                  Sie uns, während wir die Art und Weise, wie Sie die Welt
                  erleben, neu definieren – ein Ziel nach dem anderen.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default AboutUsDEPage;
