/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Skeleton,
  Typography,
} from "@mui/material";
import { Fragment, useState } from "react";
import { FlightUtils } from "../common/FlightUtils";
import { capitalizeFirstLetter, uniqueId } from "../common/methods";
import { useStoreFly } from "../store";
import { NoResultsFound } from "../components/common/NoResultsFound";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { nanoid } from "nanoid";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { useStaticImages } from "../hooks/useStaticImages";
import { isMobile } from "react-device-detect";
import { IsMobileOrBrowser } from "../hooks/isBrowserOrMobile";
const flightUtil = new FlightUtils();

const Popular = ({ translation }) => {
  const isNotMobile = window.innerWidth > 768;
  const [toggleOneWay, setToggleOneWay] = useState(false);
  const [togglePassengers, setTogglePassengers] = useState(false);
  const [toggleDate, setToggleDate] = useState(false);
  const citiesImageUrl = useStoreFly((state) => state.citiesImageUrl);
  const topLocationsForView = useStoreFly((state) => state.topLocationsForView);
  // console.log('topLocationsForView', topLocationsForView)

  const setTopFlightsHome = useStoreFly((state) => state.setTopFlightsHome);
  // console.log('setTopFlightsHome', setTopFlightsHome)
  const topFlightsHome = useStoreFly((state) => state.topFlightsHome);
  // console.log('topFlightsHome', topFlightsHome)
  const setReturnOrOneWayTopLocations = useStoreFly(
    (state) => state.setReturnOrOneWayTopLocations
  );
  const returnOrOneWayTopLocations = useStoreFly(
    (state) => state.returnOrOneWayTopLocations
  );
  const setTopLocationsPassengers = useStoreFly(
    (state) => state.setTopLocationsPassengers
  );
  const topLocationsPassengers = useStoreFly(
    (state) => state.topLocationsPassengers
  );
  /* 
  Dusseldorf Istanbul Milan Vienna Amsterdam Basel
  */
  const topLocationsGPT = [
    {
      cityTo: "Istanbul",
      cityName: "Istanbul",
      price: 150,
      image: "https://images.pexels.com/photos/8017047/pexels-photo-8017047.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      location: "Istanbul",
      flyTo:'IST'
    },
    {
      cityTo: "Dusseldorf",
      cityName: "Dusseldorf",
      price: 200,
      image: "https://images.pexels.com/photos/25034253/pexels-photo-25034253/free-photo-of-view-of-the-rhine-tower-and-part-of-the-rheinkniebrucke-in-dusseldorf-germany.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      location: "Dusseldorf",
      flyTo:'DUS'
    },
    {
      cityTo: "Milan",
      cityName: "Milan",
      price: 180,
      image: "https://cdn.pixabay.com/photo/2019/03/28/14/33/Milan-4087275_1280.jpg",
      location: "Milan",
      flyTo:'MXP'
    },
    {
      cityTo: "Vienna",
      cityName: "Vienna",
      price: 160,
      image: "https://cdn.pixabay.com/photo/2015/09/09/21/33/vienna-933500_1280.jpg",
      location: "Vienna",
      flyTo:'VIE'
    },
    {
      cityTo: "Amsterdam",
      cityName: "Amsterdam",
      price: 190,
      image: "https://images.pexels.com/photos/460672/pexels-photo-460672.jpeg",
      location: "Amsterdam",
      flyTo:'AMS'
    },
    {
      cityTo: "Basel",
      cityName: "Basel",
      price: 170,
      image: "https://images.pexels.com/photos/20495253/pexels-photo-20495253/free-photo-of-bird-eyes-view-of-basel-minster-in-basel-in-switzerland.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      location: "Basel",
      flyTo:'BSL'
    }
  ];
 
  const formattedTopLocations = topLocationsGPT?.map((location) => ({ 
    city: location.cityTo,
    cityName: location.cityTo,
    price: Math.floor(location?.price),
    image: location.image,
    flyFrom: 'PRN',
    flyTo: location.flyTo,
    topLocation: location,
  }));

  const filteredLocations = formattedTopLocations

  const navigate = useNavigate();
  const { flight } = useStaticImages();
  const isMobile = IsMobileOrBrowser();
  return (
    <section className="popular" style={
      isMobile ? { 
        padding: "none"
      } : {}
    }>
      <div className="container">
        <div className="row mt-2">
          <div className="col-lg-12 section-title mb-5 mt-5 text-center col-md-12 col-12">
            <h2>
              {translation("popular")} {" "} <span>{translation("from")}</span>{" "}
              <span className="line">{localStorage.getItem("capital")}</span>
            </h2>
          </div>
        </div>
        <div className="row ml-auto mr-auto w-100">
          <div className={"col-lg-12 linksbar d-flex align-items-center col-md-12 col-12"} style={{
            borderBottom: isNotMobile ? "none" : "1px solid #eaeaea"
          }}>
            {isMobile && (
              <Fragment>
                <Accordion
                  sx={{
                    width: "100%",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      <LocationOnOutlinedIcon />{" "}
                      {capitalizeFirstLetter(topFlightsHome?.tab)}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      padding: 0,
                    }}
                  >
                    <ul className="popular_list">
                      <li
                        onClick={() => setTopFlightsHome("popular", true)}
                        className={
                          topFlightsHome?.tab === "popular" ? "selected" : ""
                        }
                      >
                        Popular
                      </li>
                      {/* <li
                        onClick={() => setTopFlightsHome("europe", true)}
                        className={
                          topFlightsHome?.tab === "europe" ? "selected" : ""
                        }
                      >
                        Europe
                      </li>
                      <li
                        onClick={() => setTopFlightsHome("asia", true)}
                        className={
                          topFlightsHome?.tab === "asia" ? "selected" : ""
                        }
                      >
                        Asia
                      </li>
                      <li
                        onClick={() => setTopFlightsHome("world", true)}
                        className={
                          topFlightsHome?.tab === "world" ? "selected" : ""
                        }
                      >
                        World
                      </li> */}
                    </ul>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  sx={{
                    width: "100%",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      <img srcSet={flight} alt="flight" width={20} />{" "}
                      {returnOrOneWayTopLocations === "oneway"
                        ? translation("one_way")
                        : translation("return")}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails 
                    sx={{
                      padding: 0,
                    }}
                  >
                    <ul className="popular_list">
                      <li
                        onClick={() => {
                          setReturnOrOneWayTopLocations("return");
                          setTopFlightsHome(topFlightsHome?.tab, true);
                        }}
                        className={
                          returnOrOneWayTopLocations === "return" ||
                          !returnOrOneWayTopLocations
                            ? "selected"
                            : ""
                        }
                      >
                        {translation("return")}
                      </li>
                      <li
                        onClick={() => {
                          setReturnOrOneWayTopLocations("oneway");
                          setTopFlightsHome(topFlightsHome?.tab, true);
                        }}
                        className={
                          returnOrOneWayTopLocations === "oneway"
                            ? "selected"
                            : ""
                        }
                      >
                        {translation("one_way")}
                      </li>
                    </ul>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  sx={{
                    width: "100%",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      <img
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAcCAYAAAB75n/uAAAACXBIWXMAAAsTAAALEwEAmpwYAAACe0lEQVRIia3WT6hVVRQG8N+7p1cT0URLaWCPogwnCdGfI2gqOagwCkKkQJPyTLSMKChBbGCSIhYSSQelSVlCBYaQYOUkORpo4EAsUFADiwKFsEI75WCv83xd3r3cq/ebfHtv9v6+tffZa+0zVNe18ZDlRdO8C69iKSbG2I/4AO+Muxh1VYJWpwmBAj8FX8Y3+A4zsRWncXc3gW4Gz0WUv2EhpuIRzMUwXsEMHMPkvgyyvJiOD/E7bseBtin/SMfzKG7C3n538Ebwk/ir02Lswy7Mwex+DJbjIg52EW+wKfjpfgwm4UQP4nAueEY/BnT5cG0YDv67H4MjuKNHg3nBh/sxeDu4YyKNQTPn054N6qr8DEfxspRk42EIX2I6VkmXojeDwHyckZJtv3RlZ+FerMZ5LEaJ9zuJ3NDF4A8pyT7Cs1IWt+NFvNdFw1B7scvy4s6IfgK24xKm4SncE/1D+CKWTMYy6YgO4CRXi93/DLK8eBdr2oJ4ATs7BLhSOqKx2IY1jcHoEWV58TGeQYXNyGLxDmyRatMPMX02VkT0FyKIGq/hJdwSWmkHWV4swLf4qq7Kx8KQdAnW43Xc2BbpZalMvBniDfbicSyqq/LrZgfNESxuE/k3DNbjPleT75SUjOPhiTDcgZEhDzw/Db/g87oqRwvWmBftWrAbS3BbCw/H4K7rUWzDJ8HzW3goOscHaNBo3d+S3lc4O0CDn4NHWpgSnT8HaNC8gje3RD2vq/K/ARo0WsMtcd2yvOi1/veCkeDjLen/BvZkeTF1AOJTsCfa25pMXou3YvB7/HqN4rfiwWivq6tyw2ixy/JiCdZK9f56cAwb66rcDVcA8U+c682/nmgAAAAASUVORK5CYII="
                        alt="user"
                        width={15}
                      />
                      Travelers
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ul className="popular_list">
                      <li className="flex-row justify-content-space-between">
                        ADULTS
                        <br />
                        12 years and older
                        <div className="quantity">
                          <button
                            className="disabled"
                            onClick={() => {
                              setTopLocationsPassengers(
                                "adult",
                                topLocationsPassengers?.adult - 1
                              );
                              setTopFlightsHome(topFlightsHome?.tab, true);
                            }}
                          >
                            -
                          </button>
                          <input
                            data-value="true"
                            type="text"
                            readonly=""
                            value={topLocationsPassengers?.adult}
                            disabled
                          />
                          <button
                            className="active"
                            onClick={() => {
                              setTopLocationsPassengers(
                                "adult",
                                topLocationsPassengers?.adult + 1
                              );
                              setTopFlightsHome(topFlightsHome?.tab, true);
                            }}
                          >
                            +
                          </button>
                        </div>
                      </li>
                    </ul>
                  </AccordionDetails>
                </Accordion>
              </Fragment>
            )}

            {isNotMobile && (
              <Fragment>
              <div className="leftlinks">
              <ul>
                <li>
                  <a
                    className={
                      topFlightsHome?.tab === "popular" ? "active" : ""
                    }
                    onClick={async () => {
                      setTopFlightsHome("popular", true);
                    }}
                  >
                    Popular
                  </a>
                </li>
                {/* <li>
                  <a
                    className={topFlightsHome?.tab === "europe" ? "active" : ""}
                    onClick={async () => {
                      setTopFlightsHome("europe", true);
                    }}
                  >
                    Europe
                  </a>
                </li>
                <li>
                  <a
                    className={topFlightsHome?.tab === "asia" ? "active" : ""}
                    onClick={() => {
                      setTopFlightsHome("asia", true);
                    }}
                  >
                    Asia
                  </a>
                </li>
                <li>
                  <a
                    className={topFlightsHome?.tab === "world" ? "active" : ""}
                    onClick={() => {
                      setTopFlightsHome("world", true);
                    }}
                  >
                    World
                  </a>
                </li> */}
              </ul>
            </div>
            <div className="rightdiv ml-auto">
              <div className="dropdown normal-drop">
                <button
                  className="btn btn-secondary dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                  aria-expanded="false"
                  onClick={() => setToggleOneWay(!toggleOneWay)}
                >
                  <img src={require("../assets/img/flight1.png")} alt="" />{" "}
                  {returnOrOneWayTopLocations === "oneway"
                    ? "One-Way"
                    : "Round trip"}
                  <img
                    src={require("../assets/img/down-arrow-blue.png")}
                    alt=""
                  />
                </button>
                <div
                  className={`dropdown-menu bluedrop ${
                    toggleOneWay ? "show" : ""
                  }`}
                  class="collapse"
                >
                  <button
                    className="dropdown-item"
                    onClick={() => {
                      setToggleOneWay(false);
                      setReturnOrOneWayTopLocations("oneway");
                      setTopFlightsHome(topFlightsHome?.tab, true);
                    }}
                  >
                    One-Way
                  </button>
                  <button
                    className="dropdown-item"
                    onClick={() => {
                      setToggleOneWay(false);
                      setReturnOrOneWayTopLocations("return");
                      setTopFlightsHome(topFlightsHome?.tab, true);
                    }}
                  >
                    Round Trip
                  </button>
                </div>
              </div>
              <div className="normal-drop position-relative">
                <div className="form-group m-0">
                  <input
                    type="text"
                    className="form-control bg-user custom-dropdown drop-travellers"
                    open-this="traveller-dropdown4"
                    placeholder={`${
                      Number(topLocationsPassengers.adult) +
                      Number(topLocationsPassengers.children) +
                      Number(topLocationsPassengers.babies)
                    } Traveler(s)`}
                    onClick={() => setTogglePassengers(!togglePassengers)}
                  />
                  <div className="traveller-dropdown4">
                    <ul className="list-group">
                      <h6 className="px-3 pt-3 pb-0">Passengers</h6>
                      <hr className="mb-0" />
                      <li>
                        <a
                          // href="#"
                          className="list-group-item d-flex justify-content-between align-items-center"
                        >
                          ADULTS
                          <br />
                          12 years and older
                          <div className="quantity">
                            <button
                              className="disabled"
                              onClick={() => {
                                setTopLocationsPassengers(
                                  "adult",
                                  topLocationsPassengers?.adult - 1
                                );
                                setTopFlightsHome(topFlightsHome?.tab, true);
                              }}
                            >
                              -
                            </button>
                            <input
                              data-value="true"
                              type="text"
                              readonly=""
                              value={topLocationsPassengers?.adult}
                              disabled
                            />
                            <button
                              className="active"
                              onClick={() => {
                                setTopLocationsPassengers(
                                  "adult",
                                  topLocationsPassengers?.adult + 1
                                );
                                setTopFlightsHome(topFlightsHome?.tab, true);
                              }}
                            >
                              +
                            </button>
                          </div>
                        </a>
                      </li>
                      {/* <li>
                        <a
                          // href="#"
                          className="list-group-item d-flex justify-content-between align-items-center"
                        >
                          CHILDREN
                          <br />2 to 11 years
                          <div className="quantity">
                            <button
                              className="disabled"
                              onClick={() => {
                                setTopLocationsPassengers(
                                  "children",
                                  topLocationsPassengers?.children - 1
                                );
                                setTopFlightsHome(topFlightsHome?.tab, true);
                              }}
                            >
                              -
                            </button>
                            <input
                              data-value="true"
                              type="text"
                              readonly=""
                              value={topLocationsPassengers?.children}
                            />
                            <button
                              className="active"
                              onClick={() => {
                                setTopLocationsPassengers(
                                  "children",
                                  topLocationsPassengers?.children + 1
                                );
                                setTopFlightsHome(topFlightsHome?.tab, true);
                              }}
                            >
                              +
                            </button>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a
                          // href="#"
                          className="list-group-item d-flex justify-content-between align-items-center"
                        >
                          BABIES
                          <br />
                          Under 2 years old
                          <div className="quantity">
                            <button
                              className="disabled"
                              onClick={() => {
                                setTopLocationsPassengers(
                                  "babies",
                                  topLocationsPassengers?.babies - 1
                                );
                                setTopFlightsHome(topFlightsHome?.tab, true);
                              }}
                            >
                              -
                            </button>
                            <input
                              data-value="true"
                              type="text"
                              readonly=""
                              value={topLocationsPassengers?.babies}
                            />
                            <button
                              className="active"
                              onClick={() => {
                                setTopLocationsPassengers(
                                  "babies",
                                  topLocationsPassengers?.babies + 1
                                );
                                setTopFlightsHome(topFlightsHome?.tab, true);
                              }}
                            >
                              +
                            </button>
                          </div>
                        </a>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
              
            </div>
              </Fragment>
            ) }
            
          </div>
        </div>
        <div className="row package-space">
          {/* {formattedTopLocations &&
            formattedTopLocations.map(() => (
              <div className="col-lg-4 col-md-6 col-12">
                <Skeleton
                  key={uniqueId+1}
                  animation="wave"
                  className="package-box"
                  sx={{ height: "400px" }}
                />
              </div>
            ))} */}
          {formattedTopLocations?.length ? (
            formattedTopLocations?.map((formattedLocation) =>
              formattedLocation?.cityName ? (
                <a
                  style={{
                    cursor: "pointer",
                  }}
                  className="col-lg-4 col-md-6 col-12"
                  key={uniqueId}
                  onClick={async () => {
                    const formattedLocations = JSON.parse(
                      localStorage.getItem("formattedLocations") || "{}"
                    );
                    const flyFrom = formattedLocation?.flyFrom;
                    const flyTo = formattedLocation?.flyTo;
                    const flyToTime =moment().add(10,'days');
                    const flyToDate = moment().add(15,'days');
                    const departureDateUtc =''
                  
                    const arrivalDateUtc =''
                  
                    const dateFrom =  moment().add(10,'days').format("DD/MM/yyyy");
                    const dateTo =  moment().add(15,'days').format("DD/MM/yyyy");
                    const adults = topLocationsPassengers?.adult;
                    const cityNameFrom ="Pristina"
                    const cityNameTo =formattedLocation?.city
                   
                    localStorage.setItem(
                      "search",
                      JSON.stringify({
                        flyFrom,
                        flyTo,
                        dateFrom,
                        dateTo,
                        adults,
                        cityNameFrom,
                        cityNameTo,
                        // return: returnOrOneWayTopLocations,
                        aTimeFrom: "0:00",
                        aTimeTo: "24:00",
                        selectedClass: "M",
                        limit: 30,
                        returnFrom: returnOrOneWayTopLocations === "oneway" ? null : dateTo,
                        returnTo: returnOrOneWayTopLocations === "oneway" ? null : dateTo,
                        returnOrOneWay: returnOrOneWayTopLocations,
                      })
                    );
                     
                    navigate(`/search?session=${nanoid()}`);
                  }}
                >
                  <Fragment>
                    <div className="package-box">
                      <figure
                        style={{
                          background: `url(${
                            formattedLocation?.image
                          }) 50% 50% no-repeat`,
                          backgroundSize: 'cover',
                        }}
                      ></figure>
                      <span className="tag">
                        {formattedLocation?.cityName || "City"}
                      </span>
                      {/* <span className="pricetag">
                        {formattedLocation?.price || 100}€
                      </span> */}
                    </div>
                  </Fragment>
                </a>
              ) : null
            )
          ) : (
            <NoResultsFound />
          )}
        </div>
      </div>
    </section>
  );
};

export default Popular;
