import { FlightUtils } from "../common/FlightUtils";
import { useStoreFly } from "../store";
const FlightUtil = new FlightUtils();

export const useBaggage = () => {
  const baggage = useStoreFly((state) => state.baggage);
  const passengers = useStoreFly((state) => state.passengers);
    
  const adultChecked = FlightUtil.getAdultCheckedBaggage(
    passengers?.adult,
    baggage.checked
  );
  const adultCabin = FlightUtil.getAdultCabinBaggage(
    passengers?.adult,
    baggage.cabin
  );
  const childrenChecked = 0;
  const childrenCabin = 0;

  return {
    cabin: baggage.cabin,
    checked: baggage.checked,
    adultChecked: baggage.checked === 0 ? [] : adultChecked,
    adultCabin: baggage.cabin === 0 ? [] : adultCabin,
    childrenChecked,
    childrenCabin,
  };
};
