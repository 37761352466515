import Styles from "../../../components/Styles";
import Copyrights from "../../../components/Copyrights";
import Footer from "../../../components/Footer";
import { useTranslation } from "react-i18next";
import HotelBanner from "../../../components/hotel/Banner";
import Header from "../../../components/Header";
import HotelSearchResults from "./Results";
import { useState } from "react";
import HotelPage from "../hotelDetails/HotelDetails";
import { useLocation } from "react-router-dom";
import FilterAndMap from "../../../components/hotel/FilterAndMap";

const HotelSearch = () => {
  const translationContext = useTranslation();
  const [search, setSearch] = useState(null);
  const [showMap, setShowMap] = useState(false);
  const location = useLocation();
  const route = location.pathname;
  // console.log({route});
  return (
    <div>
      <Styles />
      <Header
        translation={translationContext.t}
        translationContext={translationContext}
      />
      
      <HotelBanner translation={translationContext.t} />
      {!!showMap && <FilterAndMap />}
      {route === "/hotels/search" && (
        <HotelSearchResults
          translation={translationContext.t}
          setSearch={setSearch}
        />
      )}
      {route === "/hotels/details" && (
        <HotelPage translation={translationContext.t} setSearch={setSearch} />
      )}
      <Footer />
      <Copyrights />
    </div>
  );
};

export default HotelSearch;
