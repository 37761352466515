import { useEffect, useState } from "react";
import { useStaticImages } from "../../hooks/useStaticImages";

export const Loading = ({
  fromCode,
  toCode,
  from,
  to,
  fromDate,
  toDate,
  loadingData,
}) => {
  const { loading } = useStaticImages();
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    let interval;
    if (loadingData) {
      interval = setInterval(() => {
        setProgress(progress + 10);
      }, 2000);
    }
    return () => {
      if (progress === 100) {
        clearInterval(interval);
      }
    };
  }, [progress, loadingData]);

  return (
    <div
      class="col-lg-12 col-md-8 col-12"
      style={{
        maxWidth: "100% !important",
        flex: "1 !important",
      }}
    >
      <div class="col-lg-12 text-center whitebox smilemsg col-md-12 col-12">
        <div class="row justify-content-center">
          <div class="col-lg-10 col-md-12 col-12">
            <img srcSet={loading} alt={"loading"} />
            <h4 class="mt-4">
              {fromCode} - {toCode}
            </h4>
            <p>
              {from} - {to}
              <br /> {fromDate} - {toDate}
            </p>
            <h4>Loading Flights...</h4>
            <div class="progress mt-4">
              <div
                class="progress-bar"
                role="progressbar"
                style={{ width: `${progress}%` }}
                aria-valuenow="20"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
