import { useTranslation } from "react-i18next";
import Banner from "../../../components/Banner";
import Copyrights from "../../../components/Copyrights";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import { SortBanner } from "../../../components/SortBanner";
import { useEffect, useState } from "react";
import { useQueryParams } from "../../../hooks/useQueryParams";
import { useStoreFly } from "../../../store";
import { FlightUtils } from "../../../common/FlightUtils";
import { SELECTED_CABINS } from "../../../utils/enums";
import { useBaggage } from "../../../hooks/useBaggage";
import { useLocation } from "react-router-dom";
import { getItemFromLocalStorage } from "../../../common/methods";
const FlightUtil = new FlightUtils();

const Search = () => {
  const setLoadingData = useStoreFly((state) => state.setLoadingData);
  const search = localStorage.getItem("search");
  

  const {
    flyFrom,
    flyTo,
    dateFrom,
    dateTo,
    curr,
    adults,
    children,
    babies,
    originCode,
    destinationCode,
    returnOrOneWay,
    selectedClass,
    returnFromDate,
  } = typeof localStorage.getItem("search") ? JSON.parse(search || "{}") : {};
  const { session } = useLocation();
  const setFilter = useStoreFly((state) => state.setFilter);
  const setFlights = useStoreFly((state) => state.setSearchFlights);
  const setTotalFlights = useStoreFly((state) => state.setTotalFlights);
  const carriers = useStoreFly((state) => state.airlines);

  const translationContext = useTranslation();
  const currentFilter = getItemFromLocalStorage("filter");

  const maxStops = currentFilter?.maxStops || currentFilter.stops;
  const selectedAirlines = currentFilter?.selectedAirlines;
  const flightTimeGoing = currentFilter?.flightTimeGoing;
  const flightTimeReturning = currentFilter?.flightTimeReturning;
  const adultChecked = currentFilter?.adultChecked || 0;
  const adultCabin = currentFilter?.adultCabin || 0;
  const currency = currentFilter?.curr || curr;
  // console.log({returnFromDate, dateTo, returnOrOneWay});
  useEffect(() => {
    const getFlights = async () => {
      setLoadingData(true);
      await FlightUtil.setFlights(
        setFlights,
        setTotalFlights,
        setFilter,
        {
          dateFrom,
          dateTo,
          flyFrom: originCode || flyFrom,
          flyTo: destinationCode || flyTo,
          adults,
          babies,
          children,
          selectedClass: SELECTED_CABINS[selectedClass],
          adultCabin: adultCabin,
          adultChecked: adultChecked,
          selectedAirlines,
          maxStops,
          flightTimeGoing,
          flightTimeReturning,
          curr: currency,
          returnOrOneWay,
          returnFromDate: returnOrOneWay === "return" ? returnFromDate : null,
          returnToDate: returnOrOneWay === "return" ? returnFromDate : null,
        },
        carriers,
        "/search"
      );
      setLoadingData(false);
    };
    // console.log("effeeeeeeeeeect", dateFrom , dateTo , flyFrom , flyTo, adults);
     
    if (dateFrom && dateTo  && adults) {
      getFlights();
    }
  }, [adults, babies, carriers, children, curr, dateFrom, dateTo, flyFrom, flyTo, returnOrOneWay, setFilter, setFlights, setLoadingData, setTotalFlights, session, selectedClass, selectedAirlines, maxStops, flightTimeGoing, flightTimeReturning, currency, adultChecked, adultCabin, originCode, destinationCode, returnFromDate]);

  return (
    <div className="bg-gray">
      <Header
        translation={translationContext.t}
        translationContext={translationContext}
      />
      <Banner translation={translationContext.t} />
      <SortBanner translation={translationContext.t} />
      <Footer />
      <Copyrights />
    </div>
  );
};
export default Search;
