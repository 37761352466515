// import { Swiper, SwiperSlide } from "swiper/react";
// import { Navigation } from "swiper/modules";
// import "swiper/css";
// import "swiper/css/navigation";
import { useQueryParams } from "../../../hooks/useQueryParams";
import { useEffect, useState } from "react";
import {
  RATE_HAWK_API_KEY,
  RATE_HAWK_ENDPOINT_HOTEL_REGION_SEARCH,
  RATE_HAWK_KEY,
} from "../../../utils/constants";
import { POST } from "../../../utils/api";
import { NoResultsFoundWithButtons } from "../../../components/common/NoResultsFoundWithButton";

const HotelSearchResults = ({ translation }) => {
  const query = useQueryParams();
  // console.log("adults", query().get("adults"));
  const adults = Number(query().get("adults"));
  const children = Number(query().get("children"));
  const checkIn = query().get("checkIn");
  const checkOut = query().get("checkOut");
  const currency = query().get("currency");
  const regionId = Number(query().get("regionId"));
  // console.log(query().get("adults"));

  const [hotelSearchResults, setHotelSearchResults] = useState([]);

  useEffect(() => {
    const fetchHotelSearchResults = async () => {
      const token = btoa(RATE_HAWK_KEY + ":" + RATE_HAWK_API_KEY);
      const body = {
        checkin: checkIn,
        checkout: checkOut,
        // residency: "gb",
        language: "en",
        guests: [
          {
            adults: adults,
            children: Array.from({ length: children }, (_, ch) => ch + 1) || [],
          },
        ],
        region_id: regionId,
        currency: currency,
      };
      const response = await POST(RATE_HAWK_ENDPOINT_HOTEL_REGION_SEARCH, {
        headers: {
          Authorization: "Basic " + token,
        },
        body: JSON.stringify(body),
      });
      // console.log(response);
      setHotelSearchResults(response?.data || []);
    };

    fetchHotelSearchResults();
  }, [adults, checkIn, checkOut, children, currency, regionId]);

  return (
    <div>
      {hotelSearchResults?.length ? (
        <section class="content">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 filterbar col-md-4 col-12">
                <a class="maplink mb-3" href="#">
                  <img src="assets/img/icon-map.png" alt="" /> Show in map
                </a>
                <label class="headi">Filter by:</label>
                <div class="custom-control custom-radio">
                  <input
                    type="radio"
                    id="customRadio-filter-1"
                    name="customRadio"
                    class="custom-control-input"
                  />
                  <label
                    class="custom-control-label"
                    for="customRadio-filter-1"
                  >
                    € 0 - € 50
                  </label>
                </div>
                <div class="custom-control custom-radio">
                  <input
                    type="radio"
                    id="customRadio-1"
                    name="customRadio"
                    class="custom-control-input"
                  />
                  <label class="custom-control-label" for="customRadio-1">
                    € 200 +
                  </label>
                </div>
                <label class="headi">Filter by:</label>
                <div class="custom-control custom-radio">
                  <input
                    type="radio"
                    id="customRadioin-1"
                    name="customRadioin"
                    class="custom-control-input"
                  />
                  <label class="custom-control-label" for="customRadioin-1">
                    Breakfast included
                  </label>
                </div>
                <div class="custom-control custom-radio">
                  <input
                    type="radio"
                    id="customRadioin-2"
                    name="customRadioin"
                    class="custom-control-input"
                  />
                  <label class="custom-control-label" for="customRadioin-2">
                    Hotels
                  </label>
                </div>
                <div class="custom-control custom-radio">
                  <input
                    type="radio"
                    id="customRadioin-3"
                    name="customRadioin"
                    class="custom-control-input"
                  />
                  <label class="custom-control-label" for="customRadioin-3">
                    Superb: 9+
                  </label>
                </div>
                <div class="custom-control custom-radio">
                  <input
                    type="radio"
                    id="customRadioin-4"
                    name="customRadioin"
                    class="custom-control-input"
                  />
                  <label class="custom-control-label" for="customRadioin-4">
                    Free WiFi
                  </label>
                </div>
                <div class="custom-control custom-radio">
                  <input
                    type="radio"
                    id="customRadioin-5"
                    name="customRadioin"
                    class="custom-control-input"
                  />
                  <label class="custom-control-label" for="customRadioin-5">
                    Apartments
                  </label>
                </div>
                <label class="headi">Health & safety</label>
                <div class="custom-control custom-radio">
                  <input
                    type="radio"
                    id="customRadiohealth-1"
                    name="customRadiohealth"
                    class="custom-control-input"
                  />
                  <label class="custom-control-label" for="customRadiohealth-1">
                    Properties that take health & safety measures
                  </label>
                </div>
                <label class="headi">Sustainability</label>
                <div class="custom-control custom-radio">
                  <input
                    type="radio"
                    id="customRadiosustain-1"
                    name="customRadiosustain"
                    class="custom-control-input"
                  />
                  <label
                    class="custom-control-label"
                    for="customRadiosustain-1"
                  >
                    Travel Sustainable properties
                  </label>
                </div>
                <label class="headi">Distance from centre of Paris</label>
                <div class="custom-control custom-radio">
                  <input
                    type="radio"
                    id="customRadiodistance-1"
                    name="customRadiodistance"
                    class="custom-control-input"
                  />
                  <label
                    class="custom-control-label"
                    for="customRadiodistance-1"
                  >
                    Less than 1 km
                  </label>
                </div>
                <div class="custom-control custom-radio">
                  <input
                    type="radio"
                    id="customRadiodistance-2"
                    name="customRadiodistance"
                    class="custom-control-input"
                  />
                  <label
                    class="custom-control-label"
                    for="customRadiodistance-2"
                  >
                    Less than 3 km
                  </label>
                </div>
                <div class="custom-control custom-radio">
                  <input
                    type="radio"
                    id="customRadiodistance-3"
                    name="customRadiodistance"
                    class="custom-control-input"
                  />
                  <label
                    class="custom-control-label"
                    for="customRadiodistance-3"
                  >
                    Less than 5 km
                  </label>
                </div>

                <label class="headi">Bed Filters</label>
                <div class="custom-control custom-radio">
                  <input
                    type="radio"
                    id="customRadio-bed1"
                    name="customRadiobed"
                    class="custom-control-input"
                  />
                  <label class="custom-control-label" for="customRadio-bed1">
                    Twin beds
                  </label>
                </div>
                <div class="custom-control custom-radio">
                  <input
                    type="radio"
                    id="customRadio-bed2"
                    name="customRadiobed"
                    class="custom-control-input"
                  />
                  <label class="custom-control-label" for="customRadio-bed2">
                    Double beds
                  </label>
                </div>
                <div class="custom-control custom-radio">
                  <input
                    type="radio"
                    id="customRadio-bed3"
                    name="customRadiobed"
                    class="custom-control-input"
                  />
                  <label class="custom-control-label" for="customRadio-bed3">
                    Separate beds
                  </label>
                </div>

                <label class="headi">Meals</label>
                <div class="custom-control custom-radio">
                  <input
                    type="radio"
                    id="customRadio-meal1"
                    name="customRadiomeals"
                    class="custom-control-input"
                  />
                  <label
                    class="custom-control-label w-100 d-block"
                    for="customRadio-meal1"
                  >
                    <span class="fs-13 text-dark pb-1">
                      No meals included <i class="fa fa-question-circle-o"></i>
                    </span>
                    <span class="star-count text-grey mt-1 fs-10 float-right">
                      1701
                    </span>
                  </label>
                </div>
                <div class="custom-control custom-radio">
                  <input
                    type="radio"
                    id="customRadio-meal2"
                    name="customRadiomeals"
                    class="custom-control-input"
                  />
                  <label
                    class="custom-control-label w-100 d-block"
                    for="customRadio-meal2"
                  >
                    <span class="fs-13 text-dark pb-1">
                      Breakfast Included <i class="fa fa-question-circle-o"></i>
                    </span>
                    <span class="star-count text-grey mt-1 fs-10 float-right">
                      1549
                    </span>
                  </label>
                </div>
                <div class="custom-control custom-radio">
                  <input
                    type="radio"
                    id="customRadio-meal3"
                    name="customRadiomeals"
                    class="custom-control-input"
                  />
                  <label
                    class="custom-control-label w-100 d-block"
                    for="customRadio-meal3"
                  >
                    <span class="fs-13 text-dark pb-1">
                      Breakfast + dinner or
                      <br /> lunch included{" "}
                      <i class="fa fa-question-circle-o"></i>
                    </span>
                    <span class="star-count text-grey mt-1 fs-10 float-right">
                      77
                    </span>
                  </label>
                </div>
                <div class="custom-control custom-radio">
                  <input
                    type="radio"
                    id="customRadio-meal4"
                    name="customRadiomeals"
                    class="custom-control-input"
                  />
                  <label
                    class="custom-control-label w-100 d-block"
                    for="customRadio-meal4"
                  >
                    <span class="fs-13 text-dark pb-1">
                      Breakfast,lunch and
                      <br />
                      dinner included <i class="fa fa-question-circle-o"></i>
                    </span>
                    <span class="star-count text-grey mt-1 fs-10 float-right">
                      15
                    </span>
                  </label>
                </div>
                <div class="custom-control custom-radio">
                  <input
                    type="radio"
                    id="customRadio-meal5"
                    name="customRadiomeals"
                    class="custom-control-input"
                  />
                  <label
                    class="custom-control-label w-100 d-block"
                    for="customRadio-meal5"
                  >
                    <span class="fs-13 text-dark pb-1">
                      All-inclusive <i class="fa fa-question-circle-o"></i>
                    </span>
                    <span class="star-count text-grey mt-1 fs-10 float-right">
                      2
                    </span>
                  </label>
                </div>

                <label class="headi">Star Rating</label>
                <div class="custom-control custom-radio">
                  <input
                    type="radio"
                    id="customRadio-fivestar"
                    name="customRadiorating"
                    class="custom-control-input"
                  />
                  <label
                    class="custom-control-label w-100 d-block"
                    for="customRadio-fivestar"
                  >
                    <span class="stars fs-17 pb-1">
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                    </span>
                    <span class="star-count text-grey mt-1 fs-10 float-right">
                      214
                    </span>
                  </label>
                </div>
                <div class="custom-control custom-radio">
                  <input
                    type="radio"
                    id="customRadio-fourstar"
                    name="customRadiorating"
                    class="custom-control-input"
                  />
                  <label
                    class="custom-control-label w-100 d-block"
                    for="customRadio-fourstar"
                  >
                    <span class="stars fs-17 pb-1">
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                    </span>
                    <span class="star-count text-grey mt-1 fs-10 float-right">
                      692
                    </span>
                  </label>
                </div>
                <div class="custom-control custom-radio">
                  <input
                    type="radio"
                    id="customRadio-threestar"
                    name="customRadiorating"
                    class="custom-control-input"
                  />
                  <label
                    class="custom-control-label w-100 d-block"
                    for="customRadio-threestar"
                  >
                    <span class="stars fs-17 pb-1">
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                    </span>
                    <span class="star-count text-grey mt-1 fs-10 float-right">
                      808
                    </span>
                  </label>
                </div>
                <div class="custom-control custom-radio">
                  <input
                    type="radio"
                    id="customRadio-twostar"
                    name="customRadiorating"
                    class="custom-control-input"
                  />
                  <label
                    class="custom-control-label w-100 d-block"
                    for="customRadio-twostar"
                  >
                    <span class="stars fs-17 pb-1">
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                    </span>
                    <span class="star-count text-grey mt-1 fs-10 float-right">
                      280
                    </span>
                  </label>
                </div>
                <div class="custom-control custom-radio">
                  <input
                    type="radio"
                    id="customRadio-onestar"
                    name="customRadiorating"
                    class="custom-control-input"
                  />
                  <label
                    class="custom-control-label w-100 d-block"
                    for="customRadio-onestar"
                  >
                    <span class="stars fs-17 pb-1">
                      <i class="fa fa-star"></i>
                    </span>
                    <span class="star-count text-grey mt-1 fs-10 float-right">
                      434
                    </span>
                  </label>
                </div>
                <div class="custom-control custom-radio">
                  <input
                    type="radio"
                    id="customRadio-onestar"
                    name="customRadiorating"
                    class="custom-control-input"
                  />
                  <label
                    class="custom-control-label w-100 d-block"
                    for="customRadio-onestar"
                  >
                    <span class="stars fs-17 pb-1">
                      <span class="star-count text-dark mt-1 fs-13">
                        Without star rating
                      </span>
                    </span>
                    <span class="star-count text-grey mt-1 fs-10 float-right">
                      404
                    </span>
                  </label>
                </div>
                <a class="default-white-btn mt-5" href="#">
                  Clear All Filters
                </a>
              </div>
              <div class="col-lg-7 mt-md-0 mt-4 mt-lg-0 slider-box col-md-8 col-12">
                {hotelSearchResults?.map((hotel) => (
                  <div class="col-lg-12 whitebox pb-0 mt-3 col-md-12 col-12">
                    <div class="row">
                      <div class="col-lg-5 p-0 col-md-6 col-12">
                        <div class="package-box">
                          {/* <Swiper
                            navigation={true}
                            modules={[Navigation]}
                            className="mySwiper"
                          >
                            <SwiperSlide>
                              <img
                                src={require("../../../assets/img/best-value.png")}
                                alt="1"
                              />
                            </SwiperSlide>
                            <SwiperSlide>
                              <img
                                src={require("../../../assets/img/breck-fast.png")}
                                alt="1"
                              />
                            </SwiperSlide>
                          </Swiper> */}
                          <span class="tag small best-value-tag">
                            Best Value
                          </span>
                        </div>
                      </div>
                      <div class="col-lg-7 pl-4 pt-4 pr-3 col-md-6 col-12">
                        <div class="col-lg-12 col-12 p-0">
                          <h5 class="text-blue fw-semibold">
                            Principi Di Piemonte - <br />
                            Una Esperienze
                          </h5>
                          <span class="stars fs-17 pb-1 d-block">
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                          </span>
                          <p class="text-blue">
                            <img
                              class="mr-1"
                              src={require("../../../assets/img/moon.png")}
                              alt="moon"
                            />{" "}
                            1 night | 20 oct - 21 oct
                          </p>
                        </div>
                        <div class="col-lg-12 mt-3 col-12 p-0 text-right">
                          <span class="text-blue fs-10 fw-medium d-block pb-2">
                            Total price
                          </span>
                          <span class="d-inline-block price-text w-100">
                            164.<sup>80</sup>€
                          </span>
                        </div>
                        <div class="col-lg-12 col-12 p-0">
                          <span class="d-inline-block tag-text w-100">
                            <span class="tagis">98</span>Excellent (2450)
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

                {/* <div class="col-lg-12 whitebox pb-0 mt-4 col-md-12 col-12">
                <div class="row">
                  <div class="col-lg-5 p-0 col-md-6 col-12">
                    <div class="package-box">
                      <div class="owl-carousel owl-theme package-carousel nav-arrows">
                        <div class="item">
                          <figure
                            class="m-0"
                            style={{
                              background: `url(${require("../../../assets/img/breck-fast.png")}) 50% 50% no-repeat`,
                              backgroundSize: "cover",
                            }}
                          ></figure>
                        </div>
                        <div class="item">
                          <figure
                            class="m-0"
                            style={{
                              background: `url(${require("../../../assets/img/breck-fast.png")}) 50% 50% no-repeat`,
                              backgroundSize: "cover",
                            }}
                          ></figure>
                        </div>
                        <div class="item">
                          <figure
                            class="m-0"
                            style={{
                              background: `url(${require("../../../assets/img/breck-fast.png")}) 50% 50% no-repeat`,
                              backgroundSize: "cover",
                            }}
                          ></figure>
                        </div>
                      </div>
                      <span class="tag small">Breakfast included</span>
                    </div>
                  </div>
                  <div class="col-lg-7 pl-4 pt-4 pr-3 col-md-6 col-12">
                    <div class="col-lg-12 col-12 p-0">
                      <h5 class="text-blue fw-semibold">
                        NH Collection Torino Piazza
                        <br />
                        Carlina
                      </h5>
                      <span class="stars fs-17 pb-1 d-block">
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                      </span>
                      <p class="text-blue">
                        <img
                          class="mr-1"
                          src="assets/img/moon.png"
                          alt="moon"
                        />{" "}
                        4 night | 20 oct - 24 oct
                      </p>
                    </div>
                    <div class="col-lg-12 mt-3 col-12 p-0 text-right">
                      <span class="text-blue fs-10 fw-medium d-block pb-2">
                        Total price
                      </span>
                      <span class="d-inline-block price-text w-100">
                        464.<sup>80</sup>€
                      </span>
                    </div>
                    <div class="col-lg-12 col-12 p-0">
                      <span class="d-inline-block tag-text w-100">
                        <span class="tagis">78</span>Very Good (1430)
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-12 whitebox pb-0 mt-4 col-md-12 col-12">
                <div class="row">
                  <div class="col-lg-5 p-0 col-md-6 col-12">
                    <div class="package-box">
                      <div class="owl-carousel owl-theme package-carousel nav-arrows">
                        <div class="item">
                          <figure
                            class="m-0"
                            style={{
                              background:
                                "url(assets/img/best-value.png) 50% 50% no-repeat",
                              backgroundSize: "cover",
                            }}
                          ></figure>
                        </div>
                        <div class="item">
                          <figure
                            class="m-0"
                            style={{
                              background:
                                "url(assets/img/best-value.png) 50% 50% no-repeat",
                              backgroundSize: "cover",
                            }}
                          ></figure>
                        </div>
                        <div class="item">
                          <figure
                            class="m-0"
                            style={{
                              background:
                                "url(assets/img/best-value.png) 50% 50% no-repeat",
                              backgroundSize: "cover",
                            }}
                          ></figure>
                        </div>
                      </div>
                      <span class="tag small">
                        Breakfast, lunch and dinner included
                      </span>
                    </div>
                  </div>
                  <div class="col-lg-7 pl-4 pt-4 pr-3 col-md-6 col-12">
                    <div class="col-lg-12 col-12 p-0">
                      <h5 class="text-blue fw-semibold">
                        Principi Di Piemonte - <br />
                        Una Esperienze
                      </h5>
                      <span class="stars fs-17 pb-1 d-block">
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                      </span>
                      <p class="text-blue">
                        <img
                          class="mr-1"
                          src={require("../../../assets/img/moon.png")}
                        />{" "}
                        1 night | 20 oct - 21 oct
                      </p>
                    </div>
                    <div class="col-lg-12 mt-3 col-12 p-0 text-right">
                      <span class="text-blue fs-10 fw-medium d-block pb-2">
                        Total price
                      </span>
                      <span class="d-inline-block price-text w-100">
                        164.<sup>80</sup>€
                      </span>
                    </div>
                    <div class="col-lg-12 col-12 p-0">
                      <span class="d-inline-block tag-text w-100">
                        <span class="tagis">98</span>Good (2450)
                      </span>
                    </div>
                  </div>
                </div>
              </div> */}
              </div>

              <div class="col-lg-2 mt-md-4 mt-4 mt-lg-0 ml-auto col-md-4 col-12">
                <div class="col-lg-12 logobox text-center bg-white col-md-12 col-12">
                  <img src="assets/img/sims_marketing_agency_logo.png" />
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <NoResultsFoundWithButtons />
      )}
    </div>
  );
};

export default HotelSearchResults;
