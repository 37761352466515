import {
  getAirlineNames,
  getInboundAndOutboundAirlineNames,
  getInboundAndOutboundRoutes,
} from "../common/methods";
import { useStoreFly } from "../store";

export const useFlightDetails = (flight) => {
  const passengers = useStoreFly((state) => state.passengers);
  const getAirlines = useStoreFly((state) => state.airlines);
  const { airlines = [] } = flight||[];
  const { inboundFlights = [], outboundFlights = [] } =
    getInboundAndOutboundRoutes(flight && flight.route);

  const airlinesWithNames = getAirlineNames(airlines, getAirlines);
  const { inboundAirlineNames = [], outboundAirLineNames = [] } =
    getInboundAndOutboundAirlineNames(
      airlinesWithNames,
      inboundFlights,
      outboundFlights
    );
   
  return {
    flight,
    airlinesWithNames,
    inboundFlights,
    outboundFlights,
    inboundAirlineNames,
    outboundAirLineNames,
    passengers
  };
};
