import { Fragment } from "react";

const HotelBooking = () => {
  <Fragment>
    <section class="info">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 payinfo mb-5 whitebox col-md-12 col-12">
            <div class="row align-items-center">
              <div class="col-lg-6 col-md-12 col-12">
                <h2 class="m-0">Add guest and payment details</h2>
              </div>
              <div class="col-lg-5 ml-auto text-right col-md-12 col-12">
                <p class="d-flex w-auto ml-auto align-items-center">
                  With SSL encryption, your personal data is always protected.
                  <img class="ml-4" src="assets/img/icon-ssl.png" />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-9 col-md-10 col-12">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-12">
                <p class="d-flex w-auto lg align-items-center">
                  <img class="mr-2" src="assets/img/passport.png" />
                  <span>
                    Check passenger details
                    <br />
                    <span>
                      Use all given names and surnames exactly as they appear on
                      your passport / ID to avoid departure complications
                    </span>
                  </span>
                </p>
              </div>
              <div class="col-lg-12 mt-4 col-md-12 col-12">
                <h4>Who's booking?</h4>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-8 col-md-12 col-12">
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-12 mb-3">
                    <div class="d-flex align-items-center">
                      <div class="custom-control custom-radio normal-radio">
                        <input
                          type="radio"
                          id="customRadio-val-1"
                          name="customRadio"
                          class="custom-control-input"
                        />
                        <label
                          class="custom-control-label"
                          for="customRadio-val-1"
                        >
                          Mr
                        </label>
                      </div>
                      <div class="custom-control custom-radio normal-radio ml-2">
                        <input
                          type="radio"
                          id="customRadio-val-2"
                          name="customRadio"
                          class="custom-control-input"
                        />
                        <label
                          class="custom-control-label"
                          for="customRadio-val-2"
                        >
                          Ms
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-12">
                    <label>First name</label>
                    <input type="text" class="form-control" placeholder="" />
                  </div>
                  <div class="col-lg-6 col-md-6 col-12">
                    <label>Surname</label>
                    <input type="text" class="form-control" placeholder="" />
                  </div>
                  <div class="col-lg-6 col-md-6 col-12">
                    <label>Email</label>
                    <input type="text" class="form-control" placeholder="" />
                  </div>
                  <div class="col-lg-6 col-md-6 col-12">
                    <label>Phone</label>
                    <div class="form-group">
                      <input
                        type="text"
                        id="mobile_code"
                        class="form-control"
                        placeholder="Phone Number"
                        name="name"
                      />
                    </div>
                  </div>
                  <div class="col-lg-12 mt-4 text-right col-md-12 col-12">
                    <p class="">
                      I'm booking for someone else.
                      <label class="switch ml-2">
                        <input id="booking-someone" type="checkbox" />
                        <span class="slider round"></span>
                      </label>
                    </p>
                  </div>
                  <div class="row booking-someone d-none ml-auto mr-auto w-100">
                    <div class="col-lg-12 mb-4 col-md-12 col-12">
                      <h4>Who's checking in?</h4>
                      <p>
                        Beach Villa
                        <br />
                        1 NightFull Board
                        <br />2 Adults
                      </p>
                    </div>
                    <div class="col-lg-12 col-md-12 col-12 mb-3">
                      <div class="d-flex align-items-center">
                        <div class="custom-control custom-radio normal-radio">
                          <input
                            type="radio"
                            id="customRadio-val-3"
                            name="customRadio"
                            class="custom-control-input"
                          />
                          <label
                            class="custom-control-label"
                            for="customRadio-val-3"
                          >
                            Mr
                          </label>
                        </div>
                        <div class="custom-control custom-radio normal-radio ml-2">
                          <input
                            type="radio"
                            id="customRadio-val-4"
                            name="customRadio"
                            class="custom-control-input"
                          />
                          <label
                            class="custom-control-label"
                            for="customRadio-val-4"
                          >
                            Ms
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-12">
                      <label>First name</label>
                      <input type="text" class="form-control" placeholder="" />
                    </div>
                    <div class="col-lg-6 col-md-6 col-12">
                      <label>Surname</label>
                      <input type="text" class="form-control" placeholder="" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-12 col-md-12 col-12">
                <h4>How would you like to pay?</h4>
                <p>
                  The data transmitted is coded using SSL (Secure Socket Layer)
                  technology: the session in progress on this site is secure and
                  protected by maximum privacy.
                </p>
              </div>
              <div class="col-lg-12 mt-4 pt-2 col-md-12 col-12">
                <div class="custom-control custom-radio tick">
                  <input
                    type="radio"
                    id="customRadio-filter-1"
                    name="customRadio"
                    class="custom-control-input"
                  />
                  <label
                    class="custom-control-label"
                    for="customRadio-filter-1"
                  >
                    Credit or debit card?
                    <span class="float-right">
                      <img src="assets/img/visa.png" alt="" />
                      <img class="ml-" src="assets/img/visacard.png" alt="" />
                    </span>
                  </label>
                </div>
              </div>
              <div class="col-lg-10 mt-3 col-md-12 col-12">
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-12">
                    <label>Card holder</label>
                    <input type="text" class="form-control" placeholder="" />
                  </div>
                  <div class="col-lg-6 col-md-12 col-12">
                    <label>Card number</label>
                    <input type="text" class="form-control" placeholder="" />
                  </div>
                  <div class="col-lg-3 col-md-12 col-12">
                    <label>Expiration date</label>
                    <div class="row exp-date whitebox">
                      <div class="col-lg-6 border-right col-md-6 col-6">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="MM"
                        />
                      </div>
                      <div class="col-lg-6 col-md-6 col-6">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="YY"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-12 col-12">
                    <label>
                      CVV{" "}
                      <img
                        class="ml-2"
                        src="assets/img/cvv.png"
                        alt=""
                        style="margin-top:-5px;"
                      />
                    </label>
                    <input type="text" class="form-control" placeholder="" />
                  </div>
                </div>
              </div>
              <div class="col-lg-12 mt-4 pt-2 col-md-12 col-12">
                <div class="custom-control custom-radio tick">
                  <input
                    type="radio"
                    id="customRadio-filter-2"
                    name="customRadio"
                    class="custom-control-input"
                  />
                  <label
                    class="custom-control-label"
                    for="customRadio-filter-2"
                  >
                    PayPal
                    <span class="float-right">
                      <img src="assets/img/paypal.png" alt="" />
                    </span>
                  </label>
                </div>
              </div>
              <div class="col-lg-12 mt-5 col-md-12 col-12">
                <div class="custom-control custom-radio tick normal">
                  <input
                    type="radio"
                    id="customRadio-filter-3"
                    name="customRadio-tickme"
                    class="custom-control-input"
                    onchange="document.getElementById('buttonPayment').disabled = !this.checked;"
                  />
                  <label
                    class="custom-control-label d-flex align-items-center"
                    for="customRadio-filter-3"
                  >
                    You consent, according to p. 3. F. of the Privacy Policy, to
                    receive automatically-generated and personalised
                    communications via post, email, SMS, telephone, or online
                    adverts, including deals and offers relating to our and
                    selected third parties' products and services (we do not
                    share your data collected here with these third parties).
                  </label>
                </div>
              </div>
              <div class="col-lg-12 mt-5 text-right col-md-12 col-12">
                <button
                  type="submit"
                  id="buttonPayment"
                  disabled
                  class="btn-submit default-yellow-btn"
                >
                  BOOK NOW{" "}
                  <span>
                    164.<sup>80</sup>€
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div class="col-lg-3 mt-3 mt-lg-0 mt-md-4 pt-2 col-md-6 ml-auto col-12">
            <div class="col-lg-12 whitebox pt-1 col-md-12 col-12">
              <div class="row">
                <div class="col-lg-12 space-tiny col-md-12 col-12">
                  <div class="col-lg-12 mb-3 imgbox col-md-12 col-12">
                    <h6>Principi Di Piemonte - Una Esperienze</h6>
                    <span>Nalaguraidhoo, Nalaguraidhoo</span>
                    <div class="stars">
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 space-tiny col-md-6 col-6">
                  <div class="whitebox inner">
                    <span>Check-in</span>
                    <p>
                      Wed,
                      <br /> 09 Nov 2022
                    </p>
                  </div>
                </div>
                <div class="col-lg-6 space-tiny text-right col-md-6 col-6">
                  <div class="whitebox inner">
                    <span>Check-out</span>
                    <p>
                      Thu,
                      <br /> 10 Nov 2022
                    </p>
                  </div>
                </div>
                <div class="col-lg-12 mt-3 mb-3 space-tiny col-md-12 col-12">
                  <div class="whitebox inner">
                    <p>
                      Beach Villa
                      <br />1 NightFull Board
                      <br />2 Adults
                    </p>
                  </div>
                </div>
                <div class="col-lg-12 mb-4 mt-3 total-text col-md-12 col-12">
                  <p>
                    Total price{" "}
                    <span>
                      164.<sup>80</sup>€
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Fragment>;
};

export default HotelBooking;
