import Header from "../../../components/Header";
import Banner from "../../../components/Banner";
import Styles from "../../../components/Styles";
import Copyrights from "../../../components/Copyrights";
import Footer from "../../../components/Footer";
import { useQueryParams } from "../../../hooks/useQueryParams";
import { useEffect, useState } from "react";
import { Fragment } from "react";
import { POST } from "../../../utils/api";
import { MAIN_LAMBDA } from "../../../utils/constants";
import { Alert, Tooltip } from "@mui/material";
import { goToTebPaymentPage } from "../../../common/methods";
import { CURRENCY_SIGN } from "../../../utils/enums";
import { useTranslation } from "react-i18next";

const BookingDetailsSection = ({ bookingId }) => {
  const [bookingDetails, setBookingDetails] = useState({});
  const [refundInfo, setRefundInfo] = useState({
    message: "",
    status: 0,
  });
  const [bookingStatus, setBookingStatus] = useState("");
  const [priceChangeStatus, setPriceChangeStatus] = useState({
    message: "",
    status: 0,
  });

  useEffect(() => {
    const getBookingDetails = async () => {
      try {
        const payload = {
          booking_id: bookingId,
        };

        const bookingDetailsResponse =
          {} | (await POST(`${MAIN_LAMBDA}/getBookingById`, payload));
        const kiwiPriceChangeDetails =
          typeof bookingDetailsResponse?.kiwi_webhook_price_changed_response ===
            "string" &&
          JSON.parse(
            bookingDetailsResponse?.kiwi_webhook_price_changed_response
          );

        const kiwiPriceChangeConfirmedData =
          typeof bookingDetailsResponse?.price_change_confirmed_kiwi_response ===
            "string" &&
          JSON.parse(
            bookingDetailsResponse?.price_change_confirmed_kiwi_response
          );
        const kiwiPriceChangeConfirmedStatus =
          bookingDetailsResponse?.price_change_confirmed_status;

        setBookingDetails({
          name: bookingDetailsResponse?.name || "Filan Fisteku",
          email: bookingDetailsResponse?.email || "filanfisteku@hotmail.com",
          currency: bookingDetailsResponse?.currency || "EUR",
          originalPrice:
            kiwiPriceChangeDetails?.price_change?.original_price || 120,
          newPrice: kiwiPriceChangeDetails?.price_change?.new_price || 100,
          priceDifference:
            kiwiPriceChangeDetails?.price_change?.price_difference || -1,
          priceChangeId: kiwiPriceChangeDetails?.price_change?.id || 0,
          priceChangeConfirmedData: kiwiPriceChangeConfirmedData,
          priceChangeConfirmedStatus: kiwiPriceChangeConfirmedStatus || "Price change request processed",
          voucherValue: bookingDetailsResponse?.voucher_value || "32.25-mahmutibesnik915@gmail.com",
          voucherApplied: bookingDetailsResponse?.voucher_applied || false,
        });

        const response = await POST(
          `${MAIN_LAMBDA}/bookingRefundStatus`,
          payload
        );
        if (response.data && response.data.status === "success") {
          setBookingStatus(response.data.statusDescription);
        } else {
          setBookingStatus("");
        }
      } catch (error) {
        // console.log(error);
      }
    };
    if (bookingId) {
      getBookingDetails();
    }
  }, [bookingId]);

  const refundBooking = async () => {
    const payload = {
      booking_id: bookingId,
    };
    try {
      const response = await POST(`${MAIN_LAMBDA}/bookingRefund`, payload);
      if (response.data && response.data.status === "success") {
        setRefundInfo({
          message: response.data.statusDescription,
          status: 200,
        });
      }
    } catch (error) {
      setRefundInfo({
        message: error.message,
        status: 400,
      });
    }
  };

  const acceptPriceChange = async () => {
    try {
      if (
        bookingDetails?.priceDifference > 0 &&
        bookingId &&
        bookingDetails?.priceChangeId
      ) {
        const amount = bookingDetails?.priceDifference;
        const okUrl = `${MAIN_LAMBDA}/acceptPriceChange?booking_id=${bookingId}&extra_id=${bookingDetails?.priceChangeId}&status=success`;
        const failUrl = `${MAIN_LAMBDA}/acceptPriceChange?booking_id=${bookingId}&extra_id=${bookingDetails?.priceChangeId}&status=fail`;
        await goToTebPaymentPage(
          amount,
          bookingDetails?.currency,
          okUrl,
          failUrl
        );
      } else {
        const payload = {
          booking_id: bookingId,
          amount: bookingDetails?.priceDifference,
          email: bookingDetails?.email,
        };
        const response = await POST(
          `${MAIN_LAMBDA}/save-voucher-for-price-change`,
          payload
        );
        if (response.data && response.data.voucher) {
          setPriceChangeStatus({
            message: response.data.statusDescription,
            status: 200,
          });
        }
      }
    } catch (error) {
      setPriceChangeStatus({
        message: error.message,
        status: 400,
      });
    }
  };

  // console.log(bookingDetails.name, bookingDetails.email);
  return (
    <Fragment>
      <section className="content">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 mt-lg-0 mt-md-0 mt-4 valuetabs col-md-8 col-12 whitebox">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  minHeight: "300px",
                  justifyContent: "center",
                }}
              >
                <img src={require("../../../assets/img/info.png")} alt="ok" />
                <h6 className="text-blue flex-gap-8">
                  <span className="font-weight-700"> Booking ID: </span>
                  {bookingId}
                </h6>
                <h6 className="text-blue flex-gap-8">
                  <span className="font-weight-700">Name: </span>{" "}
                  {bookingDetails?.name}{" "}
                </h6>
                <h6 className="text-blue flex-gap-8">
                  <span className="font-weight-700">Email: </span>
                  {bookingDetails?.email || "filanfisteku@hotmail.com"}
                </h6>
                <h6 className="text-blue flex-gap-8">
                  <span className="font-weight-700">Status: </span>
                  {bookingStatus || "Pending"}
                </h6>{" "}
                <button
                  className="default-yellow-btn mt-3 select"
                  style={{
                    padding: "0px 56px",
                  }}
                  onClick={async () => await refundBooking()}
                >
                  <span
                    style={{ color: "#003663" }}
                    className="font-weight-700"
                  >
                    Refund
                  </span>
                </button>
                {refundInfo?.message && (
                  <Alert
                    sx={{
                      marginTop: "20px",
                    }}
                    severity={refundInfo?.status === 200 ? "info" : "error"}
                  >
                    {refundInfo?.message}
                  </Alert>
                )}
                {!!bookingDetails?.priceChangeId && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      minHeight: "300px",
                      justifyContent: "center",
                    }}
                  >
                    <h5 className="text-blue font-weight-700">Price Changed</h5>
                    <h6 className="text-blue flex-gap-8">
                      <span className="font-weight-700">Original price: </span>{" "}
                      {bookingDetails.originalPrice}{" "}
                      {CURRENCY_SIGN[bookingDetails.currency]}
                    </h6>
                    <h6 className="text-blue flex-gap-8">
                      <span className="font-weight-700">New price: </span>
                      {bookingDetails.newPrice}{" "}
                      {CURRENCY_SIGN[bookingDetails.currency]}
                    </h6>
                    <h6 className="text-blue flex-gap-8">
                      <span className="font-weight-700">
                        Price difference:{" "}
                      </span>{" "}
                      {bookingDetails.priceDifference}{" "}
                      {CURRENCY_SIGN[bookingDetails.currency]}
                    </h6>

                    {bookingDetails?.priceDifference < 0 && (
                      <Alert severity="info">
                        Since the new price is less then the original price you
                        have the option to accept the price change and get a
                        voucher for the next booking.
                        <br />
                      </Alert>
                    )}

                    <div style={{ display: "flex", gap: 10 }}>
                      <button
                        className="default-yellow-btn mt-3 select"
                        style={{
                          background: "#9bdc00",
                          padding: "0px 56px",
                        }}
                        onClick={async () => await acceptPriceChange()}
                      >
                        <span
                          style={{ color: "#003663" }}
                          className="font-weight-700"
                        >
                          Accept
                        </span>
                      </button>
                      <Tooltip
                        title="If you do not want to accept a price change, 
                      whole booking will automatically be refunded to the original form of payment."
                      >
                        <button
                          className="default-yellow-btn mt-3 select"
                          style={{
                            background: "#eeeeee",
                            padding: "0px 56px",
                          }}
                        >
                          <span
                            style={{ color: "#003663" }}
                            className="font-weight-700"
                          >
                            Reject
                          </span>
                        </button>
                      </Tooltip>

                      {priceChangeStatus?.message && (
                        <Alert
                          sx={{
                            marginTop: "20px",
                          }}
                          severity={
                            priceChangeStatus?.status === 200 ? "info" : "error"
                          }
                        >
                          {priceChangeStatus?.message}
                        </Alert>
                      )}
                    </div>
                  </div>
                )}
                {!!bookingDetails?.priceChangeConfirmedStatus && (
                  <PriceConfirmedChangeStatus
                    status={bookingDetails?.priceChangeConfirmedStatus}
                    bookingDetails={bookingDetails}
                  />
                )}
              </div>
            </div>
            <div class="col-lg-2 mt-lg-0 mt-md-4  col-md-6 ml-auto col-12 ">
              <div class="col-lg-12 logobox text-center bg-white col-md-12 col-12 sticky">
                <img
                  src={require("../../../assets/img/sims_marketing_agency_logo.png")}
                  alt="agency"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

const PriceConfirmedChangeStatus = ({ status , bookingDetails }) => {
  const severity =
    status === "Price change request processed" ? "success" : "info";
  const priceDifference = bookingDetails?.priceDifference;
  const voucherValue = bookingDetails?.voucherValue;
  const voucherApplied = bookingDetails?.voucherApplied;
  const showVoucher = status === "Price change request processed" && !voucherApplied && voucherValue && priceDifference < 0
  
  
  return (
    <div style={{ marginTop: "10px", marginBottom: "10px" }}>
      <h6 className="text-blue flex-gap-8">
        <Alert severity={severity}>
        {status}
        <br />
        {!!showVoucher && (
        <span>
          Voucher you can use: 
          <span className="font-weight-700">
            {" "}
            {voucherValue}
          </span>
        </span>
      )}</Alert>
        
      </h6>
      
    </div>
  );
};

const BookingDetails = () => {
  let query = useQueryParams();
  const bookingId = query().get("booking_id");
  const translationContext = useTranslation();
  // console.log("bookingId", bookingId);
  return (
    <div>
      <Styles />
      <Header translation={translationContext.t} translationContext={translationContext}/>
      <Banner showSearchBar={false} minHeight="300px" />
      <BookingDetailsSection bookingId={bookingId} />
      <Footer
      // style={{
      //   position: "absolute",
      //   bottom: "112px",
      //   width: "100%",
      // }}
      />
      <Copyrights
      // style={{
      //   position: "absolute",
      //   bottom: "0",
      //   width: "100%",
      // }}
      />
    </div>
  );
};

export default BookingDetails;
